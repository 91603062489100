<template>
  <div style="width: 100%;height: 100%;">
    <a-card :bordered="false" :bodyStyle="{'padding': '0'}">
      <a-row>
        <a-col span="3">
          <div class="ahmux-sub-title" style="width: 100px;">
            报告信息
          </div>
        </a-col>
      </a-row>
      <a-row>
        <table class="mxtable">
          <tr>
            <td class="tdLabel">项目名称</td>
            <td>{{orderData.projName}}</td>
            <td class="tdLabel">项目编号</td>
            <td>{{orderData.projNo}}</td>
          </tr>
          <tr>
            <td class="tdLabel">报告类型</td>
            <td>{{orderData.projReportType | reportTypeFilter}}</td>
            <td class="tdLabel">领取方式</td>
            <td>
              <a-tag v-if="orderData.projReportReceive==='1'" color="blue">{{ reportReceiveDict[orderData.projReportReceive] }}</a-tag>
              <a-tag v-else-if="orderData.projReportReceive==='2'" color="green">{{ reportReceiveDict[orderData.projReportReceive] }}</a-tag>
              <a-tag v-else-if="orderData.projReportReceive==='9'" color="orange">{{ reportReceiveDict[orderData.projReportReceive] }}</a-tag>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">委托单位</td>
            <td>{{orderData.csrName}}</td>
            <td class="tdLabel">委托日期</td>
            <td>{{moment(orderData.projScheduledStart).format("YYYY-MM-DD")}}</td>
          </tr>
          <tr>
            <td class="tdLabel">报告负责人</td>
            <td>{{orderData.reportLeaderName}}</td>
            <td class="tdLabel">完成时间</td>
            <td>{{orderData.approvalTime}}</td>
          </tr>
          <tr v-if="orderData.reportUserName">
            <td class="tdLabel">报告编制人</td>
            <td colspan="3">{{orderData.reportUserName}}</td>
          </tr>
        </table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import { reportTypeApi } from '@/api/quote'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

var vm = {}
export default {
  name: 'ReportBaseInfo',
  props: {
    orderData: {
      type: Object,
      required: true
    }
  },
  components: {},

  filters: {
    reportTypeFilter (type) {
      if (typeof vm.reportType !== 'undefined') {
        return vm.reportType[type]
      }
      return ''
    }
  },
  created () {
    if (typeof this.reportType === 'undefined') {
      this.getReportType()
    }
  },
  data () {
    vm = this
    return {
      reportType: this.$store.state.code.cache.proj_report_type,
      reportReceiveDict: { '1': '邮寄', '2': '自取', '9': '其他' }
    }
  },
  methods: {
    moment,
    getReportType () {
      reportTypeApi.listReportType().then((res) => {
        if (res.code === 0) {
          const reportType = {}
          res.data.forEach(item => {
            reportType[item.itemValue] = item.itemText
          })
          this.reportType = reportType
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
