<template>
  <a-modal
    :title="title"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOK"
    :destroyOnClose="true"
  >
    <a-form :form="form">
      <a-form-item label="检测位置" v-bind="formItemLayout">
        <a-input
          type="text"
          placeholder="检测位置"
          v-decorator="['siteName', {initialValue: info.siteName,
          rules: [
            {required: true, message: '检测位置不能为空！'},
            {max: 100, message: '检测位置长度过长！'}
          ]}]"></a-input>
      </a-form-item>
      <a-form-item label="经纬度" v-bind="formItemLayout">
        <a-input-group>
          <a-col :span="11">
            <a-form-item>
              <a-input
                type="text"
                placeholder="经度"
                v-decorator="['siteLnt', {rules: [
                  {max: 12, message: '长度不能超过12位！'}
                ]}]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item>
              <a-input
                type="text"
                placeholder="纬度"
                v-decorator="['siteLat', {rules: [
                  {max: 12, message: '长度不能超过12位！'}
                ]}]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-icon style="cursor: pointer;" type="environment" theme="twoTone"/>
          </a-col>
        </a-input-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { projCheckSiteApi } from '@/api/project'
var vm = {}
export default {
  name: 'SiteForm',
  components: { },
  props: {
    siteList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    vm = this
    return {
      type: 'add',
      title: '添加检测位置',
      visible: false,
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      info: {},
      projId: null,
      form: this.$form.createForm(this),
      selectSiteStatus: 'success'
    }
  },
  methods: {
    add (projId) {
      this.type = 'add'
      this.title = '添加检测位置'
      this.visible = true
    },
    edit (site) {
      this.info = site
      this.type = 'edit'
      this.title = '修改检测位置'
      this.visible = true
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      const self = this
      var saveApi = projCheckSiteApi.add
      if (self.type === 'add') {
        saveApi = projCheckSiteApi.add
      } else {
        saveApi = projCheckSiteApi.edit
      }
      this.form.validateFields((error, values) => {
        const formData = Object.assign(values, {
          id: self.info.id
        })
        if (error == null) {
          saveApi(formData).then(result => {
            if (result.code === 0) {
              this.$message.success(`${self.title}成功`)
              this.updateSite(result.data)
              this.handleCancel()
            } else {
              this.$message.error(result.msg)
            }
          }).catch(error => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    updateSite (site) {
      this.$emit('updateSiteInfo', site)
    }
  },
  watch: {
  }
}
</script>
