<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="1050"
    :maskClosable="false"
    :destroyOnClose="true"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :height="500">
      <a-table
        ref="table"
        size="small"
        :loading="table.loading"
        :columns="table.columns"
        :dataSource="table.dataSource"
        :rowKey="table.rowKey"
        :pagination="false"
        @change="tableChange"
        :rowSelection="table.rowSelection"
      >
        <template slot="type" slot-scope="text">
          <a-tag v-if="text==1" color="cyan">采样</a-tag>
          <a-tag v-else-if="text==2" color="blue">检测</a-tag>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="status" slot-scope="text">
          <a-tag v-if="text==1" color="green">现行</a-tag>
          <a-tag v-else-if="text==2" color="red">废止</a-tag>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/pages/cloud-quote/item/modules/ItemForm'
import { standardItemApi, jcStandardApi } from '@/api/quote'

var vm = {}
export default {
  name: 'SelectStandard',
  components: {
    STable,
    CreateForm
  },
  props: {
    standardMap: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    vm = this
    return {
      title: '添加检测项',
      visible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        itemId: null,
        type: null
      },
      item: {},
      table: {
        loading: false,
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id || record.type
        },
        columns: [
          { title: '标准类型', dataIndex: 'type', width: '110px', customRender: (v, r, i) => (r.id ? '' : r.name) },
          { title: '标准编号', dataIndex: 'standardId' },
          { title: '标准名称', dataIndex: 'name', customRender: (v, r, i) => (r.id ? v : '') },
          { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } }
        ],
        pagination: {
          show: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          }
        },
        rowSelection: {
          // type: 'radio',
          getCheckboxProps: (record) => {
            return {
              props: {
                disabled: !record.id
              }
            }
          },
          onChange: (selectedRowKeys, selectedRows) => {
            this.table.selectedRowKeys = selectedRowKeys
            this.table.selectedRows = selectedRows
          }
        }
      }
    }
  },
  methods: {
    add (item) {
      this.visible = true
      // this.queryParam.itemId = item.itemId
      // this.queryParam.type = item.type
      this.item = item
      this.clearSelect()
      this.loadData()
      // this.getStandardTree()
    },
    clearSelect () {
      this.table.selectedRowKeys = []
      this.table.selectedRowKeys = []
    },
    tableChange (pagination, filters, sorter) {
      this.table.pagination.current = pagination.current
      this.table.pagination.pageSize = pagination.pageSize
      this.loadData()
    },
    getStandardTree () {
      jcStandardApi.getStandardTree().then(res => {
        if (res.code === 0) {
          // self.table.dataSource = res.data
        } else {
          this.$message.error('查询失败')
        }
      })
    },
    loadData () {
      var self = this
      self.table.loading = true
      jcStandardApi.getStandardTree().then(res => {
        if (res.code === 0) {
          self.table.loading = false
          self.table.dataSource = res.data
        } else {
          this.$message.error('查询失败')
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk (e) {
      e.preventDefault()
      const self = this
      if (self.table.selectedRowKeys.length < 1) {
        self.$message.error('请选择执行标准')
      } else {
        var standardList = []
        for (var idx in self.table.selectedRows) {
          const standard = self.table.selectedRows[idx]
          standardList.push({
            siteId: self.item.siteId,
            cateId: self.item.cateId,
            itemId: self.item.itemId,
            standardType: standard.type,
            standardId: standard.standardId,
            standardName: standard.name
          })
        }
        self.$emit('selectStandardOk', { item: self.item, standardList: standardList })
        self.handleCancel()
      }
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .search {
    margin-bottom: 54px;
  }

  .fold {
    width: calc(100% - 216px);
    display: inline-block
  }

  .operator {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
