<template>
  <div>
    <a-collapse>
      <a-collapse-panel v-for="(siteInfo,siteId) of siteData" :key="`site_${siteId}`">
        <template #header>
          <a-checkbox :style="{marginRight:'5px'}" @click.stop="()=>{return false}" @change="handleSampleCheckChange($event,siteInfo.details)" :checked="siteInfo.details.reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>
          {{ siteInfo.siteName }}
          <div :style="{float:'right',marginRight: '20px'}">
            合计： {{ siteCount[siteId] && siteCount[siteId].length || 0 }}
          </div>
        </template>

        <div class="period" v-for="(numPeriodInfo,numPeriod) of groupSampleInfoByNumPeriod(siteInfo.details)" :key="`numPeriod_${siteId}_${numPeriod}`">
          <div class="title">
            <span>
              <a-checkbox :style="{marginRight:'5px'}" @change="handleSampleCheckChange($event,numPeriodInfo.details)" :checked="numPeriodInfo.details.reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>
              第 【{{ numPeriod }}】 天
            </span>
            <span class="extra"><a-icon type="up"></a-icon></span>
          </div>
          <div class="content">
            <div class="frequency" v-for="(numFrequencyInfo,numFrequency) of groupSampleInfoByNumFrequency(numPeriodInfo.details)" :key="`numFrequency_${siteId}_${numPeriod}_${numFrequency}`">
              <div class="title">
                <a-checkbox :style="{marginRight:'5px'}" @change="handleSampleCheckChange($event,numFrequencyInfo.details)" :checked="numFrequencyInfo.details.reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>
                D{{ numPeriod }}-{{ numFrequency }}
              </div>
              <div class="detail">
                <div class="item" v-for="(cateInfo,cateId) of groupSampleInfoByCate(numFrequencyInfo.details,true)" :key="`cate_${siteId}_${numPeriod}_${numFrequency}_${cateId}`">
                  <span class="item-title">
                    <a-checkbox :style="{marginRight:'5px'}" @change="handleSampleCheckChange($event,cateInfo.details)" :checked="cateInfo.details.reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>
                    <a-tag color="green">{{ cateInfo.cateName || cateInfo.cateId }}</a-tag>
                  </span>
                  <span class="item-content">
                    <span class="item-content-title">总数</span>
                    <span class="item-content-content">{{ cateInfo.details.length }}</span>

                    <span class="item-content-title">待采</span>
                    <span class="item-content-content">
                      {{ cateInfo.statusGroup[sampleInfoTypeEnum.virtual] && cateInfo.statusGroup[sampleInfoTypeEnum.virtual].length || 0 }}
                    </span>

                    <span class="item-content-title">待交样</span>
                    <span class="item-content-content">
                      {{ cateInfo.statusGroup[sampleInfoStatusEnum.doneSample] && cateInfo.statusGroup[sampleInfoStatusEnum.doneSample].length || 0 }}
                    </span>

                    <span class="item-content-title">
                      <a-checkbox
                        :style="{marginRight:'5px'}"
                        :disabled="!cateInfo.statusGroup[sampleInfoStatusEnum.storaged]||!cateInfo.statusGroup[sampleInfoStatusEnum.storaged].length"
                        @change="handleSampleCheckChange($event,cateInfo.statusGroup[sampleInfoStatusEnum.storaged])"
                        :checked="cateInfo.statusGroup[sampleInfoStatusEnum.storaged] &&
                          cateInfo.statusGroup[sampleInfoStatusEnum.storaged].length&&
                          cateInfo.statusGroup[sampleInfoStatusEnum.storaged].reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>                         在库</span>
                    <span class="item-content-content">
                      {{ cateInfo.statusGroup[sampleInfoStatusEnum.storaged] && cateInfo.statusGroup[sampleInfoStatusEnum.storaged].length || 0 }}
                    </span>

                    <span class="item-content-title">
                      <a-checkbox
                        :style="{marginRight:'5px'}"
                        :disabled="!cateInfo.statusGroup[sampleInfoStatusEnum.storageApply]||!cateInfo.statusGroup[sampleInfoStatusEnum.storageApply].length"
                        @change="handleSampleCheckChange($event,cateInfo.statusGroup[sampleInfoStatusEnum.storageApply])"
                        :checked="cateInfo.statusGroup[sampleInfoStatusEnum.storageApply] &&
                          cateInfo.statusGroup[sampleInfoStatusEnum.storageApply].length&&
                          cateInfo.statusGroup[sampleInfoStatusEnum.storageApply].reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>                      待入库
                    </span>
                    <span class="item-content-content">
                      {{ cateInfo.statusGroup[sampleInfoStatusEnum.storageApply] && cateInfo.statusGroup[sampleInfoStatusEnum.storageApply].length || 0 }}
                    </span>

                    <span class="item-content-title">
                      <a-checkbox
                        :style="{marginRight:'5px'}"
                        :disabled="!cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply]||!cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply].length"
                        @change="handleSampleCheckChange($event,cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply])"
                        :checked="cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply] &&
                          cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply].length&&
                          cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply].reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>                      待出库</span>
                    <span class="item-content-content">
                      {{ cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply] && cateInfo.statusGroup[sampleInfoStatusEnum.pickupApply].length || 0 }}
                    </span>

                    <span class="item-content-title">
                      <a-checkbox
                        :style="{marginRight:'5px'}"
                        :disabled="!cateInfo.statusGroup[sampleInfoStatusEnum.outbound]||!cateInfo.statusGroup[sampleInfoStatusEnum.outbound].length"
                        @change="handleSampleCheckChange($event,cateInfo.statusGroup[sampleInfoStatusEnum.outbound])"
                        :checked="cateInfo.statusGroup[sampleInfoStatusEnum.outbound] &&
                          cateInfo.statusGroup[sampleInfoStatusEnum.outbound].length&&
                          cateInfo.statusGroup[sampleInfoStatusEnum.outbound].reduce((acc,item)=>acc&&checkSampleInfoIds.includes(item.id),true)"/>
                      已出库
                    </span>
                    <span class="item-content-content">
                      {{ cateInfo.statusGroup[sampleInfoStatusEnum.outbound] && cateInfo.statusGroup[sampleInfoStatusEnum.outbound].length || 0 }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </a-collapse-panel>
    </a-collapse>
    <a-card v-show="checkSampleInfoIds.length" style="margin-top: 32px;">
      <template #title>已选样品</template>
      <template #extra>
        合计：{{ checkSampleInfoIds.length }}
      </template>
      <div class="check-sample-wrapper">
        <div class="check-sample" v-for="(item,index) in checkSampleInfoList" :key="index">
          <a-tag color="blue">{{ item.id }}</a-tag>
          <a @click="checkSampleInfoList.splice(index,1)">
            <a-icon type="delete"></a-icon>
          </a>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { tsSampleinfoApi } from '@/api/sample'
import { sampleInfoStatusEnum, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { groupBy } from 'lodash'

export default {
  props: {
    projId: { type: Number, required: true }
  },
  data () {
    return {
      sampleInfoTypeEnum,
      sampleInfoStatusEnum,
      checkSampleInfoList: [],
      sampleInfoList: []
    }
  },

  computed: {

    siteCheckSampleItemList () {
      const blankSampleTypes = [sampleInfoTypeEnum.allBlank, sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank]
      return this.sampleInfoList.filter(item => !blankSampleTypes.includes(item.type))
    },

    blankCheckSampleItemList () {
      const blankSampleTypes = [sampleInfoTypeEnum.allBlank, sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank]
      return this.sampleInfoList.filter(item => blankSampleTypes.includes(item.type))
    },

    siteCount () {
      return groupBy(this.siteCheckSampleItemList, 'siteId')
    },
    siteData () {
      return this.groupSampleInfoBySite(this.siteCheckSampleItemList)
    },
    checkSampleInfoIds () {
      return this.checkSampleInfoList.map(item => item.id)
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (newValue) {
        newValue && this.initData(newValue)
      }
    }
  },

  methods: {

    // 获取选中的样品
    getCheckSampleList () {
      return this.checkSampleInfoList
    },

    // 处理样品选中事件
    handleSampleCheckChange (event, sampleInfoList) {
      const { target: { checked } } = event
      if (checked) {
        const notIncludes = sampleInfoList.filter(item => !this.checkSampleInfoIds.includes(item.id))
        notIncludes.length && this.checkSampleInfoList.push.apply(this.checkSampleInfoList, notIncludes)
      } else {
        const includes = sampleInfoList.filter(item => this.checkSampleInfoIds.includes(item.id))
        includes.length && includes.forEach(item => {
          const index = this.checkSampleInfoList.findIndex(sample => sample.id === item.id)
          this.checkSampleInfoList.splice(index, 1)
        })
      }
    },

    // 初始化必要的数据
    initData (projId) {
      this.queryProjSampleInfoDetail(projId).then(res => {
        if (res.code === 0) {
          this.sampleInfoList.splice(0, this.sampleInfoList.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询项目样品信息
    queryProjSampleInfoDetail (projId) {
      return tsSampleinfoApi.list({ page: false, neTypes: [sampleInfoTypeEnum.virtual], projId: projId }).then(res => res)
    },

    // 样品按照点位进行分组
    groupSampleInfoBySite (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.siteId] || (acc[sample.siteId] = { siteId: sample.siteId, siteName: sample.siteName, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 样品按照天数进行分组
    groupSampleInfoByNumPeriod (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.numPeriod] || (acc[sample.numPeriod] = { numPeriod: sample.numPeriod, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 样品按照频次进行分组
    groupSampleInfoByNumFrequency (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.numFrequency] || (acc[sample.numFrequency] = { numFrequency: sample.numFrequency, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 根据样品类型分组
    groupSampleInfoByType (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.type] || (acc[sample.type] = { type: sample.type, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 样品按照分类进行分组
    groupSampleInfoByCate (list, statusGroup = false) {
      const result = list.reduce((acc, sample) => {
        (acc[sample.cateId] || (acc[sample.cateId] = { cateId: sample.cateId, cateName: sample.cateNameStr, details: [] })).details.push(sample)
        return acc
      }, {})
      if (statusGroup) {
        Object.values(result).forEach(item => {
          item.statusGroup = groupBy(item.details.filter(item => !item.temp), 'status')
          item.statusGroup[sampleInfoTypeEnum.virtual] = item.details.filter(item => item.temp)
        })
      }
      return result
    }
  }
}
</script>

<style lang="less" scoped>
.period {
  font-size: 12px;
  border: 1px solid #409EEE;
  border-radius: 4px;

  &:hover {
    box-shadow: 3px 3px 5px #D7D7D7;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 5px 20px;
    background: #409EFF;

    .extra {
      float: right;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 30px;
    padding: 10px 20px;

    .frequency {
      .title {
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        background: #2EAB6BCC;
        padding: 5px 10px;

      }

      .detail {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 10px 0px;

        .item {
          border: 1px dashed #FFEEEE;
          padding: 3px 15px;

          &-title {
            color: #666666;
          }

          &-content {
            color: #999999;

            &-title {
              color: #666666;
              margin-left: 30px;

              &::after {
                content: "："
              }
            }

            &-content {
              color: #26CD20;
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }

  }
}

.check-sample-wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px 30px;
}
</style>
