<template>
  <div>

    <!--  查询区域 -->
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item label="名称查询" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="名称模糊查询" v-model.trim.lazy="queryParam.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col :style="{ textAlign: 'left',marginBottom:'15px' }" v-bind="colProps">
            <a-button-group>
              <a-button @click="search" icon="search" type="primary">查询</a-button>
              <a-button @click="reset" icon="reload">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <div class="fr">
        <a-button @click="formTypeModal.add()">配置</a-button>
      </div>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <check-standard-solution-type-table v-bind="tableBind" @selectedRows="handleRowSelected">
      </check-standard-solution-type-table>
    </a-card>

    <!-- 新增编辑类型表单 -->
    <a-modal v-bind="formTypeModal" v-if="formTypeModal.visible" v-on="formTypeModal">
      <check-standard-solution-type-form v-bind="formTypeModal.data" v-on="formTypeModal.data"></check-standard-solution-type-form>
      <template #footer>
        <a-button @click="formTypeModal.cancel()">关闭</a-button>
        <a-button @click="formTypeModal.onOk()" type="primary">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { search, action, table } from '@/pages/common/mixins/list'
import { fullModalData } from '@/pages/common/mixins/modal/full'

import { CheckStandardSolutionTypeForm, CheckStandardSolutionTypeTable } from '@/pages/cloud-quote/check-standard-solution-type/modules'
import { checkStandardSolutionTypeNoActionColumn } from '@/pages/cloud-quote/check-standard-solution-type/modules/columns'

export default {
  mixins: [search, action, table],
  components: { CheckStandardSolutionTypeTable, CheckStandardSolutionTypeForm },
  props: {
    selectType: { type: String, required: false, default: null, validator (value) { return [null, '', undefined, 'radio', 'checkbox'].includes(value) } },
    columns: { type: Array, required: false, default: () => (checkStandardSolutionTypeNoActionColumn) }
  },

  computed: {
    tableBind () {
      return Object.assign({ ref: 'table', columns: this.columns, selectType: this.selectType }, { ...this.$attrs }, { ...this.queryParam })
    }
  },
  data () {
    return {
      formTypeModal: Object.assign({}, fullModalData, {
        title: '标液配置',
        width: '90%',
        bodyStyle: { background: '#F0F2F5' },

        data: {
          ref: 'form',
          id: 0,
          editType: null,
          success: () => {
            this.formTypeModal.cancel()
            this.reload()
          }
        },

        add: () => {
          this.formTypeModal.data.editType = 'add'
          this.formTypeModal.data.id = 0
          this.formTypeModal.show()
        },

        edit: (record) => {
          this.formTypeModal.data.editType = 'edit'
          this.formTypeModal.data.id = record.id
          this.formTypeModal.show()
        },

        show: () => {
          this.formTypeModal.visible = true
        },

        onOk: () => {
          this.$refs[this.formTypeModal.data.ref].save()
        },

        cancel: () => {
          this.formTypeModal.visible = false
        }
      }),

      queryParam: {
        name: null
      },
      selectedRows: []

    }
  },

  methods: {

    // 获取选中的数据
    getSelectData () {
      return [...this.selectedRows]
    },

    // 处理行选中事件
    handleRowSelected (data) {
      this.selectedRows.splice(0, this.selectedRows.length, ...data)
      this.$emit('selectedRows', data)
    },

    search () {
      this.$refs.table.search()
    },

    reload () {
      this.$refs.table.reload()
    }
  }

}
</script>

<style lang="less" scoped>

</style>
