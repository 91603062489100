<template>
  <div class="sample-project-detail">
    <div class="header">
      <div class="left"><span>{{ projSampleInfo.projSampleSource === '1' ? '采样' : '送样' }}</span></div>

      <div class="right">
        <div class="title">{{ projSampleInfo.projName }} 【项目编号：{{ projSampleInfo.projNo }}】 【项目负责人：{{ projSampleInfo.projLeaderName }}】</div>
        <div class="content">
          <div class="item">客户单位：{{ projSampleInfo.csrName }}</div>
          <div class="item">项目负责人：{{ projSampleInfo.projLeaderName }}</div>
          <div class="item">采样负责人：{{ projSampleInfo.sampleLeaderName }}</div>
          <div class="item">
            <a-tooltip>
              <template #title>
                待采样：{{ projSampleInfo.tempSampleNum || 0 }}<br/>
                预计总样品：{{ projSampleInfo.sampleSum || 0 }}
              </template>
              样品总数：<span class="num-red">{{ projSampleInfo.tempSampleNum || 0 }}</span> / <span class="num-green">{{ projSampleInfo.sampleSum || 0 }}</span>
            </a-tooltip>
          </div>
          <div class="item"> 待入库：<a @click="$emit('toDetail',projSampleInfo,sampleInfoStatusEnum.storageApply,projSampleInfo.projId)"><span class="num-red">{{ projSampleInfo.storageApplyNum || 0 }}</span></a></div>
          <div class="item"> 待出库：<a @click="$emit('toDetail',projSampleInfo,sampleInfoStatusEnum.pickupApply,projSampleInfo.projId)"><span class="num-red">{{ projSampleInfo.pickupApplyNum || 0 }}</span></a></div>
        </div>
      </div>
    </div>

    <a-tabs>
      <a-tab-pane key="1" tab="按类别">

        <a-collapse :activeKey="Object.keys(cateGroup)">
          <a-collapse-panel v-for="(value,key) of cateGroup" :key="key">
            <template #header>
              {{ Object.values(value)[0][0].cateNameStr }}
              <span class="fr mar-r16">
                合计：
                <a @click.stop="$emit('toDetail',projSampleInfo,null,Object.values(value)[0][0].cateId,projSampleInfo.projId)">
                  <span class="num-green">{{ Object.values(value).reduce((acc, item) => acc + item.length, 0) }}</span>
                </a>
              </span>
            </template>

            <div class="details">
              <div :key="status" v-for="(statusItem,status) of sampleInfoStatusDataMap">
                <template v-if="status===sampleInfoStatusEnum.doneSample">
                  <span class="title">待采 / 已采</span>：
                  <span class="num-red"> {{ value[status] && value[status].filter(item => item.temp).length || 0 }} </span> /
                  <span class="num-green">  {{ value[status] && value[status].filter(item => !item.temp).length || 0 }}</span>
                </template>
                <template v-else>
                  <span class="title">  {{ statusItem.label }} </span>：
                  <a @click="$emit('toDetail',projSampleInfo,status,Object.values(value)[0][0].cateId,projSampleInfo.projId)">
                    <span :class="[value[status]?'num-green':'num-dark']">{{ value[status] && value[status].length || 0 }}</span>
                  </a>
                </template>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </a-tab-pane>

      <a-tab-pane key="2" tab="按状态">

        <a-collapse :activeKey="Object.keys(statusGroup)">
          <a-collapse-panel v-for="(value,key) of statusGroup" :key="key">
            <template #header>
              <template v-if="key===sampleInfoStatusEnum.doneSample">
                采样 （ 待采 / 已采 ）
              </template>
              <template v-else>
                {{ sampleInfoStatusDataMap[Object.values(value)[0][0].status].label }}
                <span class="fr mar-r16">
                  合计：
                  <a @click.stop="$emit('toDetail',projSampleInfo,Object.values(value)[0][0].status,null,projSampleInfo.projId)">
                    <span class="num-green">{{ Object.values(value).reduce((acc, item) => acc + item.length, 0) }}</span>
                  </a>
                </span>
              </template>
            </template>

            <div class="details" style="justify-content:flex-start;column-gap: 60px;">
              <template v-for="(subValue,subKey) of value">
                <div :key="subKey">
                  <span class="title">  {{ subValue[0].cateNameStr }} </span>：
                  <template v-if="key===sampleInfoStatusEnum.doneSample">
                    <span class="num-red"> {{ subValue && subValue.filter(item => item.temp).length || 0 }} </span> /
                    <span class="num-green">  {{ subValue && subValue.filter(item => !item.temp).length || 0 }}</span>
                  </template>
                  <template v-else>
                    <a @click="$emit('toDetail',projSampleInfo,Object.values(value)[0][0].status,subValue[0].cateId,projSampleInfo.projId)">
                      <span class="num-green">{{ subValue.length || 0 }}</span>
                    </a>
                  </template>
                </div>
              </template>
            </div>
          </a-collapse-panel>
        </a-collapse>

      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { tsSampleinfoApi } from '@/api/sample'
import { groupBy } from 'lodash'
import { sampleInfoStatusDataMap, sampleInfoStatusEnum, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

export default {
  name: 'Index',
  props: {
    projId: { type: Number, required: true }
  },

  data () {
    return {
      sampleInfoStatusDataMap,
      sampleInfoStatusEnum,

      projSampleInfo: {},
      cateGroup: {},
      statusGroup: {}
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (value) {
        this.querySampleInfos(value)
      }

    }
  },

  methods: {
    querySampleInfos (projId) {
      tsSampleinfoApi.projectList({ projId, neType: sampleInfoTypeEnum.virtual, page: false }).then(res => {
        if (res.code === 0) {
          this.projSampleInfo = res.data[0]
        } else {
          this.$message.error(res.msg)
        }
      })
      tsSampleinfoApi.list({ projId, neType: sampleInfoTypeEnum.virtual, page: false }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length) {
            // 分类 -》 状态
            const cateGroup = groupBy(res.data, 'cateId')
            Object.keys(cateGroup).forEach(key => {
              const value = cateGroup[key]
              cateGroup[key] = groupBy(value, 'status')
            })

            // 状态 -》 分类
            const statusGroup = groupBy(res.data, 'status')
            Object.keys(statusGroup).forEach(key => {
              const value = statusGroup[key]
              statusGroup[key] = groupBy(value, 'cateId')
            })

            this.cateGroup = cateGroup
            this.statusGroup = statusGroup
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";

.sample-project-detail {
  .margin();

  .color();

  .header {
    border-bottom: 1px solid #D7D7D7;
    padding: 0 0 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    column-gap: 30px;

    .left {
      width: 84px;
      height: 84px;
      background: #448AFF;
      border-radius: 4px;
      text-align: center;
      line-height: 84px;

      span {
        font-size: 30px;
        font-weight: 400;
        color: #FFFFFF;
      }

    }

    .right {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    .content {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 10px;

      div {
        flex: 0 0 33%;
      }
    }

    .item {
      color: #595959;
    }
  }

  .details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wap;

    .title {
      font-size: 14px;
      font-weight: 400;
      color: #448AFF;
    }

  }

  .num-red {
    color: #FF5722;
    font-size: 16px;
  }

  .num-green {
    color: #25CD5C;
    font-size: 16px;
  }

  .num-dark {
    color: #999999;
    font-size: 16px;
  }
}
</style>
