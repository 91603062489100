<template>
  <div class="CheckTaskResultStatus">
    <el-tooltip placement="top" v-if="data.status===checkTaskResultStatusEnum.void&&showVoid">
      <div slot="content">{{ data.voidReason }}</div>
      <a-tag :color="checkTaskResultStatusDataMap[checkTaskResultStatusEnum.void].color">
        {{ checkTaskResultStatusDataMap[checkTaskResultStatusEnum.void].label }}
      </a-tag>
    </el-tooltip>

    <template v-else>
      <el-tag :type="checkTaskResultDataStatusDataMap[data.dataStatus].type">
        {{ checkTaskResultDataStatusDataMap[data.dataStatus].label }}
      </el-tag>
    </template>

  </div>
</template>

<script>
import { checkTaskResultStatusEnum, checkTaskResultStatusDataMap, checkTaskResultDataStatusDataMap } from '@/api/check/checkTaskResultConstant'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    showVoid: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      checkTaskResultStatusEnum, checkTaskResultStatusDataMap, checkTaskResultDataStatusDataMap
    }
  }
}
</script>
