<script>
export default {
  name: 'SampleList',
  inheritAttrs: false,
  model: {
    prop: 'sampleInfoList',
    event: 'change'
  }
}
</script>

<script setup>
import { reactive, computed, getCurrentInstance, nextTick, ref, watch } from 'vue'
import { uniqBy } from 'lodash'
import { sampleInfoStatusEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { message } from 'ant-design-vue'
import { SampleListSelectSample, SampleListView, SampleListCateView, SampleListProjectView } from '@/pages/cloud-quote/check-task/modules/schedule/moudles/sample-list'
import OutboundSampleForm from '@/pages/cloud-quote/sample-info/modules/OutboundSampleForm.vue'

const { proxy } = getCurrentInstance()

const props = defineProps({
  sampleInfoList: { type: Array, required: true },
  taskList: { type: Array, required: false, default: () => [] },
  removeIds: { type: Array, required: false, default: () => [] },
  disabled: { type: Boolean, required: false, default: false },
  itemList: { type: Array, required: false, default: () => [] },
  compositeItem: { type: String, required: false, default: undefined },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue', 'update:taskList'])
const sampleInfoList = computed({
  get() {
    return props.sampleInfoList
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const taskList = computed({
  get() {
    return props.taskList
  },
  set(value) {
    emit('update:taskList', value)
  }
})

const removeIds = computed({
  get() {
    return props.removeIds
  },
  set(value) {
    emit('update:removeIds', value)
  }
})

const pickupApplySampleInfoList = computed(() => {
  return sampleInfoList.value.filter((item) => item.status === sampleInfoStatusEnum.pickupApply)
})

// 样品相关的操作
const componentShow = reactive({
  outboundForm: false
})
const outBoundFormRef = ref()
// 样品出库
const outboundSample = ({ data = null }) => {
  let result = null
  if (!pickupApplySampleInfoList.value?.length) {
    message.error('暂无需要出库的样品！')
    return false
  }
  result = pickupApplySampleInfoList.value.map((item) => {
    return { id: item.id }
  })
  componentShow.outboundForm = true
  nextTick(() => {
    outBoundFormRef.value.init(result)
  })
}

// 出库成功后 重置样品状态
const reloadSampleInfoStatus = () => {
  pickupApplySampleInfoList.value.forEach((item) => {
    item.status = sampleInfoStatusEnum.outbound
  })
}

// 选择样品
const selectSampleRef = ref()
const showSelectSample = () => {
  let queryParam = {}
  let itemIds = []
  let itemNames = ''
  // 判断如果props.compositeItem有值，也就是是在复合批次准备中打开以下页面，则使用复合因子作为查询条件，否则依然使用之前的逻辑
  if (props.compositeItem) {
    itemIds = props.compositeItem.split(',').map((item) => Number(item))
    let itemNameList = []
    for (let itemId of itemIds) {
      let items = props.itemList.filter((item) => item.id === itemId)
      if (items.length > 0) {
        itemNameList.push(items[0].name)
      }
    }
    itemNames = itemNameList.join(',')
  } else {
    const uniqTaskList = uniqBy(taskList.value, 'itemId')
    itemIds = uniqTaskList.map((item) => item.itemId)
    itemNames = uniqTaskList.map((item) => item.itemName).join(',')
  }
  queryParam = {
    itemIds,
    itemNames
  }

  selectSampleRef.value?.show(queryParam)
}

const handleSelectedTask = (selectedTask) => {
  const existTaskIds = taskList.value.map((item) => item.id)
  const noExistTasks = selectedTask.filter((item) => !existTaskIds.includes(item.id))
  // 添加push
  taskList.value.push(...noExistTasks)
}

// 显示样品
const showSampleInfo = ref(true)
// 显示方式
const SAMPLE_LIST_SHOW_TYPE = 'check_task_batch_sample_list_show_type'
const sampleListShowType = ref(proxy.$ls.get(SAMPLE_LIST_SHOW_TYPE, 'list'))
watch(
  () => sampleListShowType.value,
  (newValue) => {
    proxy.$ls.set(SAMPLE_LIST_SHOW_TYPE, newValue)
  }
)
</script>

<template>
  <div>
    <a-row :gutter="24" :style="{ marginTop: 0 }" class="title-row">
      <a-col :span="24">
        <span class="title">样品信息</span>
        <span class="mar-h12 fr">
          <template v-if="showSampleInfo">
            <a-tooltip>
              <template #title>点我隐藏样品信息</template>
              <a @click="() => (showSampleInfo = false)">
                <a-icon type="shrink" />
              </a>
            </a-tooltip>
          </template>
          <template v-else>
            <a-tooltip>
              <template #title>点我显示样品信息</template>
              <a @click="() => (showSampleInfo = true)">
                <a-icon type="arrows-alt" />
              </a>
            </a-tooltip>
          </template>
        </span>
        <span class="mar-h12 fr" v-if="props.firstFlag !== 1">
          <a-tooltip>
            <template #title>点我继续添加需要检测的样品</template>
            <a @click="showSelectSample">
              <a-icon type="plus-square"></a-icon>
            </a>
          </a-tooltip>
        </span>
        <span class="mar-h12 fr">
          聚合方式：
          <a-radio-group v-model="sampleListShowType" size="small">
            <a-radio value="list">列表</a-radio>
            <a-radio value="project">项目</a-radio>
            <a-radio value="cate">检测分类</a-radio>
          </a-radio-group>
        </span>

        <template v-if="pickupApplySampleInfoList.length">
          <span v-action:sample-info:outbound class="fr animate__animated animate__bounce animate__slower">
            <a @click="outboundSample">样品出库</a>
          </span>
        </template>
      </a-col>
    </a-row>
    <div class="content-row">
      <SampleListView v-show="showSampleInfo && sampleListShowType === 'list'" v-model="sampleInfoList" :removeIds.sync="removeIds" :firstFlag="props.firstFlag" />
      <SampleListProjectView v-show="showSampleInfo && sampleListShowType === 'project'" v-model="sampleInfoList" :removeIds.sync="removeIds" :firstFlag="props.firstFlag" />
      <SampleListCateView v-show="showSampleInfo && sampleListShowType === 'cate'" v-model="sampleInfoList" :removeIds.sync="removeIds" :firstFlag="props.firstFlag" />
    </div>

    <!-- 出库 -->
    <outbound-sample-form v-if="componentShow.outboundForm" ref="outBoundFormRef" @cancel="componentShow.outboundForm = false" @success="reloadSampleInfoStatus" />

    <!-- 选择样品-->
    <sample-list-select-sample ref="selectSampleRef" @selected="handleSelectedTask" :disabled="disabled" />
  </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';
@import '~@/assets/less/common/form.less';

.margin();
.ul-form();
</style>
