<template>
  <div class="check-group-batch-detail">
    <a-card v-if="batchGroupInfo" v-bind="baseCard" title="基本信息">
      <ul class="ul">
        <li class="li">
          <div>
            <span class="title">检测员：</span>
            <span class="content">{{ batchGroupInfo.createUserRealName }}</span>
          </div>

          <div>
            <span class="title">检测内容：</span>
            <span class="content feature">
              <a-tooltip>
                <template #title>
                  <template v-if="itemInfos.length">
                    <div v-for="(itemInfo) in itemInfos" :key="`${itemInfo.cateId}_${itemInfo.itemId}`">{{ itemInfo.cateName }}/{{ itemInfo.itemName }}</div>
                  </template>
                </template>
                <template v-if="itemInfos.length">
                  <span class="text-ellipsis" style="width: 300px;float: right">
                    {{ itemInfos.map(item => item.itemName).join(',') }}
                  </span>
                </template>
              </a-tooltip>

            </span>
          </div>

          <div>
            <span class="title">温度：</span>
            <span class="content">
              {{ batchGroupInfo.wd || '-' }}
            </span> ℃
          </div>

          <div>
            <span class="title">湿度：</span>
            <span class="content">
              {{ batchGroupInfo.sd || '-' }}
            </span> %
          </div>

          <div>
            <span class="title">检测时间：</span>
            <span class="content">
              {{ batchGroupInfo.makeBeginTime | minuteFilter }} - {{ batchGroupInfo.makeEndTime | minuteFilter }}
            </span>
          </div>
        </li>
      </ul>
    </a-card>

    <a-card v-bind="baseCard" title="检测因子">
      <div v-for="item in itemInfos" :key="`${item.cateId}_${item.itemId}`">
        <div class="title-row">
          <span class="title"> {{ item.cateName }} <span class="mar-h5">/</span>  {{ item.itemName }}</span>
        </div>
        <div class="content-row">
          <ul class="ul">
            <template v-if="batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].batchId">
              <li class="li">
                <div>
                  <span class="title">检测标准：</span>
                  <span class="content">{{ batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].standardName }} ( {{ batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].standardCode }})</span>
                </div>

                <div>
                  <span class="title">检测方法：</span>
                  <span class="content">{{ batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].methodName }} </span>
                </div>

                <div>
                  <span class="content">
                    <a-tooltip>
                      <template #title>查看准备详情</template>
                      <a @click="showBatchInfo(batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`])"><a-icon type="info-circle" /></a>
                    </a-tooltip>
                  </span>
                </div>
              </li>

              <li class="li">
                <template v-if="batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].fileTemplateId">
                  <div>
                    <span class="title">原始记录表：</span>
                    <span class="content">{{ batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].templateName }}</span>
                  </div>
                  <div>
                    <span class="title">复核状态：</span>
                    <span class="content">
                      <a-tag :color="checkOriginalRecordCheckStatusDataMap[ batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].recordReviewStatus].color">
                        {{ checkOriginalRecordCheckStatusDataMap[batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].recordReviewStatus].label }}
                      </a-tag>
                    </span>
                  </div>
                  <div>
                    <span class="title">审核状态：</span>
                    <span class="content">
                      <a-tag :color="checkOriginalRecordCheckStatusDataMap[ batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].recordCheckStatus].color">
                        {{ checkOriginalRecordCheckStatusDataMap[batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].recordCheckStatus].label }}
                      </a-tag>
                    </span>
                  </div>
                  <div v-if="loginUser.username===batchGroupInfo.createUser&&redo(batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`])">
                    <a @click="checkOriginalRecordModal.edit(batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].batchId,batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].recordId)">编辑</a>
                  </div>

                </template>

                <template v-else>
                  <div class="no-data red">
                    <template v-if="loginUser.username===batchGroupInfo.createUser">
                      <a-tooltip title="点我录入原始记录表">
                        <a @click="checkOriginalRecordModal.show(batchGroupInfo.itemInfo[`${item.cateId}_${item.itemId}`].batchId)">
                          暂未录入原始记录
                        </a>
                      </a-tooltip>
                    </template>
                    <template v-else> 暂未录入原始记录表</template>
                  </div>
                </template>

              </li>

            </template>
            <template v-else>
              <li class="li">
                <div class="no-data red">暂未开始检测</div>
              </li>
            </template>

          </ul>
        </div>
      </div>

    </a-card>

    <a-card v-if="batchGroupInfo" v-bind="baseCard" title="样品信息">
      <div v-for="(projInfo,projId) of projTaskMap" :key="projId">
        <div class="title-row">
          <span class="title" style="">项目编号：{{ projInfo.projNo }}</span>
        </div>
        <div class="content-row" style="padding: 5px;">
          <project-detail :task-list="projInfo.details"/>
        </div>
      </div>
    </a-card>

    <!-- 新增检测原始表 -->
    <a-modal v-if="checkOriginalRecordModal.visible" v-bind="checkOriginalRecordModal" v-on="checkOriginalRecordModal.action">
      <original-record v-bind="checkOriginalRecordModal.data" v-on="checkOriginalRecordModal.dataAction"></original-record>
    </a-modal>

    <!-- 检测准备界面 -->
    <a-modal v-if="checkTaskScheduleSingleModal.visible||checkTaskScheduleSingleModal.showTag" v-bind="checkTaskScheduleSingleModal" v-on="checkTaskScheduleSingleModal.action">
      <check-task-schedule-single v-bind="checkTaskScheduleSingleModal.data" v-on="checkTaskScheduleSingleModal.dataAction"></check-task-schedule-single>
      <template #footer>
        <a-button @click="checkTaskScheduleSingleModal.action.cancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { uniqBy } from 'lodash'
import { fullModalData } from '@/pages/common/mixins/modal/full'

import { checkTaskBatchGroupApi } from '@/api/check/checkTaskApi'
import { checkOriginalRecordCheckStatusDataMap, checkOriginalRecordCheckStatusEnum, checkOriginalRecordReviewStatusDataMap, checkOriginalRecordReviewStatusEnum } from '@/api/check/checkOriginalRecordConstant'

import ProjectDetail from '@/pages/cloud-quote/check-task/modules/schedule/ProjectDetail'
import OriginalRecord from '@/pages/cloud-quote/check-task/modules/originalRecord/index'
import { CheckTaskScheduleSingle } from '@/pages/cloud-quote/check-task/modules'

export default {
  props: {
    batchGroupId: { type: Number, required: true }
  },

  components: { ProjectDetail, OriginalRecord, CheckTaskScheduleSingle },

  data () {
    return {
      checkOriginalRecordReviewStatusDataMap,
      checkOriginalRecordCheckStatusDataMap,

      batchGroupInfo: null,

      checkOriginalRecordModal: Object.assign({}, fullModalData, {
        title: '检测原始记录表',
        data: {
          ref: 'checkOriginalRecord',
          batchId: null,
          recordId: null
        },

        dataAction: {
          success: () => {
            this.checkOriginalRecordModal.cancel()
            this.initData(this.batchGroupId)
          },

          cancel: () => {
            this.checkOriginalRecordModal.cancel()
          }

        },

        show: (batchId) => {
          this.checkOriginalRecordModal.data.batchId = batchId
          this.$nextTick(() => {
            this.checkOriginalRecordModal.visible = true
          })
        },

        edit: (batchId, recordId) => {
          this.checkOriginalRecordModal.data.batchId = batchId
          this.checkOriginalRecordModal.data.recordId = recordId
          this.$nextTick(() => {
            this.checkOriginalRecordModal.visible = true
          })
        },

        action: {
          ok: () => {
            this.$refs[this.checkOriginalRecordModal.data.ref].save()
          },

          cancel: () => {
            this.checkOriginalRecordModal.visible = false
          }
        }
      }),

      // 检测原始记录表 检测工作台
      // 检测准备
      checkTaskScheduleSingleModal: Object.assign({}, fullModalData, {
        visible: false,
        title: '检测准备',
        showTag: false,
        mask: false,
        destroyOnClose: false,

        edit: (id, groupId, taskList) => {
          this.checkTaskScheduleSingleModal.data.id = id
          this.checkTaskScheduleSingleModal.data.groupId = groupId
          this.checkTaskScheduleSingleModal.data.taskList = taskList
          this.checkTaskScheduleSingleModal.visible = true
          this.checkTaskScheduleSingleModal.showTag = false
        },

        action: {
          cancel: () => {
            this.checkTaskScheduleSingleModal.data.id = null
            this.checkTaskScheduleSingleModal.data.groupId = null
            this.checkTaskScheduleSingleModal.data.taskList = []
            this.checkTaskScheduleSingleModal.visible = false
          },

          ok: () => {
            this.$refs[this.checkTaskScheduleSingleModal.data.ref].submit()
          },

          min: () => {
            this.checkTaskScheduleSingleModal.visible = false
            this.checkTaskScheduleSingleModal.showTag = true
          },
          max: () => {
            this.checkTaskScheduleSingleModal.visible = true
            this.checkTaskScheduleSingleModal.showTag = false
          }
        },

        data: {
          id: null,
          groupId: null,
          taskList: [],
          ref: 'checkTaskScheduleSingle'
        },

        dataAction: {
          cancel: () => {
            this.checkTaskScheduleSingleModal.action.cancel()
          },

          success: () => {
            this.checkOriginalRecordModal.setBatchInfo(this.checkTaskScheduleSingleModal.data.id)
            this.checkTaskScheduleSingleModal.action.cancel()
          }
        }
      }),

      baseCard: {
        type: 'inner',
        size: 'mini',
        style: { marginBottom: '16px' }
      }
    }
  },

  computed: {
    taskList () { return this.batchGroupInfo && this.batchGroupInfo.taskList || [] },

    itemInfos () {
      return uniqBy(this.taskList.map(item => ({ cateId: item.cateId, cateName: item.cateName, itemId: item.itemId, itemName: item.itemName })), (item) => item.cateId + '-' + item.itemId) || []
    },

    projTaskMap () {
      return this.taskList.reduce((acc, item) => {
        (acc[item.projId] || (acc[item.projId] = { projId: item.projId, projName: item.projName, projNo: item.projNo, details: [] })).details.push(item)
        return acc
      }, {})
    },

    loginUser () {
      return { ...this.$store.getters.userInfo }
    }

  },

  watch: {
    batchGroupId: {
      immediate: true,
      handler (newValue, oldValue) {
        newValue && this.initData(newValue)
      }

    }
  },

  methods: {
    initData (batchGroupId) {
      checkTaskBatchGroupApi.getDetailInfoById(batchGroupId).then(res => {
        if (res.code === 0) {
          this.batchGroupInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    redo (item) {
      const reviewRedoStatus = [checkOriginalRecordReviewStatusEnum.pending, checkOriginalRecordReviewStatusEnum.rejected]
      const checkRedoStatus = [checkOriginalRecordCheckStatusEnum.rejected]
      return reviewRedoStatus.includes(item.recordReviewStatus) || checkRedoStatus.includes(item.recordCheckStatus)
    },

    // 显示准备详情
    showBatchInfo (batchInfo) {
      const taskList = this.taskList.filter(item => item.batchId === batchInfo.batchId)
      this.checkTaskScheduleSingleModal.edit(batchInfo.batchId, this.batchGroupInfo.id, taskList)
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";
@import "~@/assets/less/common/form";

.check-group-batch-detail {
  .ul-form();
  .data();
  .color();
}
</style>
