<template>
  <div :style="{width:this.width}" class="table">
    <s-table v-bind="contentTable" :scroll="scrollProp">
      <template #validStatus="text,record">
        <a-tooltip>
          <template #title>{{ record.validDate }}</template>
          <span :class="record.validDate | validTimeClassFilter">
            {{ record.validDate | validTimeFilter }}
          </span>
        </a-tooltip>
      </template>
      <template #validDate="text,record">
        <span :class="text | validDateClassFilter">{{text}}</span>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index">
        <slot v-bind="{text:text,row:record,index:index}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import mixinList from '@/pages/common/mixins/list'
import { checkStandardCurveVerifyApi } from '@/api/checkStandardCurve/checkStandardCurveApi'

import { CustomizeColumn } from '@/components/Columns'
import moment from 'moment'

const defaultColumn = [
  { title: '校准点位', dataIndex: 'siteNo', width: 80, sorter: true, scopedSlots: { customRender: 'siteNo' } },
  { title: '校准日期', dataIndex: 'makeDate', width: 120, sorter: true, scopedSlots: { customRender: 'makeDate' } },
  { title: '校准点位吸光度', dataIndex: 'standardCurveAbsorbance', width: 150, sorter: true, scopedSlots: { customRender: 'standardCurveAbsorbance' } },
  { title: '现测吸光度', dataIndex: 'siteAbsorbance', width: 150, sorter: true, scopedSlots: { customRender: 'siteAbsorbance' } },
  { title: '相对偏差(%)', dataIndex: 'relativeDeviation', sorter: true, width: 100, scopedSlots: { customRender: 'relativeDeviation' } },
  { title: '允许范围', dataIndex: 'allowRange', width: 120, scopedSlots: { customRender: 'allowRange' } },
  { title: '是否有效', dataIndex: 'validStatus', width: 100, scopedSlots: { customRender: 'validStatus' } },
  { title: '有效时间', dataIndex: 'validDate', width: 120, scopedSlots: { customRender: 'validDate' } },
  { title: '校准人', dataIndex: 'createUser', width: 100, scopedSlots: { customRender: 'createUser' } }
]

export default {
  name: 'index',

  components: { sTable, CustomizeColumn },
  mixins: [mixinList],

  props: {
    width: { type: [String, Number], required: false, default: '100%' },
    pagination: { type: Boolean, required: false, default: true },
    columns: { type: Array, required: false, default: () => (defaultColumn) },
    queryParam: { type: Object, required: false, default: () => { return {} } },
    rowKey: { type: [String, Function], default: 'id' }
  },

  data () {
    return {
      viewDetail: true,
      scrollProp: { x: '100%', y: document.documentElement.clientHeight - 445 },
      contentTable: {
        rowKey: this.rowKey,
        columns: this.columns,
        bordered: true,
        style: { width: '100%' },
        showPagination: this.pagination ? 'auto' : false
      }
    }
  },
  filters: {
    validTimeClassFilter (data) {
      return data && moment().isBefore(moment(data)) ? 'green' : 'red'
    },

    validTimeFilter (data) {
      return data && moment().isBefore(moment(data)) ? '是' : '否'
    },

    validDateClassFilter (data) {
      return data && moment().isBefore(moment(data)) ? '' : 'red'
    },
  },
  watch: {
    queryParam: {
      deep: true,
      immediate: false,
      handler (param) {
        this.loadData(param)
      }
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      return checkStandardCurveVerifyApi.list(Object.assign({}, param, this.queryParam)).then(res => res)
    }
  }

}
</script>

<style lang="less" scoped>

.red {
  color: red;
}
.green {
  color: green;
}

</style>
