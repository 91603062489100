<script>
export default {
  name: 'CheckBatchInfoSearchForm',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>
<script setup>
import SelectUserByDept from '@/pages/common/SelectUserByDept.vue'
// import SelectItem from '@/pages/common/SelectItem.vue'
import SelectCheckboxItem from '@/pages/common/SelectCheckboxItem.vue'
import { MoreParam } from '@/pages/common/components/modules/more-param'
import { ref, computed } from 'vue'

import { useSearchFormProps } from '@/pages/common/compositions/list/useSearch'
import { useDate } from '@/pages/common/compositions/date/useDate'
import { resStaffApi, Get } from '@/api/quote'

const props = defineProps({
  modelValue: { required: true, type: Object },
  reset: { required: true, type: Function },
  search: { required: true, type: Function }
})

const emit = defineEmits(['update:modelValue'])
const queryParam = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})

const { searchCard, searchForm, rowProps, colProps, formItemLayout, actionColProps, moreParam } = useSearchFormProps()

// 处理检测员改变事件
const handleCheckUserChange = (userList) => {
  const res = userList.map((item) => item.username)
  queryParam.value.checkUsers.splice(0, queryParam.value.checkUsers.length, ...res)
}

const modalWidth = ref(window.innerWidth * 0.9)
// 检测项改变事件
const handleItemChange = (itemList) => {
  const result = itemList?.map((item) => item.id) ?? []
  queryParam.value.itemIds.splice(0, queryParam.value.itemIds.length, ...result)
  props.search()
}

const { timeFormat, moment, dateRangeProps } = useDate()
const queryMakeTime = computed({
  set([begin, end]) {
    ;(begin && (queryParam.value.queryMakeBeginTime = timeFormat(begin))) || (queryParam.value.queryMakeBeginTime = null)
    ;(end && (queryParam.value.queryMakeEndTime = timeFormat(end))) || (queryParam.value.queryMakeEndTime = null)
  },
  get() {
    const { queryMakeBeginTime, queryMakeEndTime } = queryParam.value
    return queryMakeBeginTime && queryMakeEndTime ? [moment(queryMakeBeginTime), moment(queryMakeEndTime)] : []
  }
})

// 检测员
const userList = ref([])
// 获取检测员
const getUserList = () => {
  Get(resStaffApi.list, { page: false, queryStatus: ['0', '3'], status: '0,3', preparatStatus: '0', job: 27 }).then((res) => {
    userList.value = res.data
  })
}
getUserList()
const filterOption = (input, option) => {
  return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const selectCheckboxItem = ref()
// 传递检测项id，替换现有检测项条件数据
const searchList = (itemIds) => {
  selectCheckboxItem.value.searchList(itemIds)
}

// 重置，并重置检测项条件数据
const resetSearch = () => {
  searchList([])
  props.reset()
}

defineExpose({
  searchList: searchList
})
</script>

<template>
  <a-card v-bind="searchCard">
    <a-form v-bind="searchForm">
      <a-row v-bind="rowProps">
        <a-col v-bind="colProps">
          <a-form-item label="批次时间" v-bind="formItemLayout">
            <a-range-picker v-model="queryMakeTime" v-bind="dateRangeProps"></a-range-picker>
          </a-form-item>
        </a-col>

        <a-col v-bind="colProps">
          <a-form-item label="检测项" v-bind="formItemLayout">
            <!-- <select-item :trigger-change="true" type-title="检测项" :modal-width="modalWidth" @change="handleItemChange"></select-item> -->
            <select-checkbox-item ref="selectCheckboxItem" :trigger-change="true" type-title="检测项" :modal-width="modalWidth" @change="handleItemChange"></select-checkbox-item>
          </a-form-item>
        </a-col>

        <a-col v-bind="colProps">
          <a-form-item label="检测员" v-bind="formItemLayout">
            <!-- <select-user-by-dept :disabled="false" :triggerChange="true" @change="handleCheckUserChange" /> -->
            <a-select v-model="queryParam.checkUsers" mode="multiple" :filterOption="filterOption" placeholder="请选择检测员">
              <a-select-option v-for="usr in userList" :key="usr.name">{{ usr.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col v-bind="actionColProps">
          <a-button-group>
            <a-button icon="search" type="primary" @click="props.search">查询</a-button>
            <a-button icon="reload" @click="resetSearch">重置</a-button>
          </a-button-group>
          <MoreParam v-model="moreParam" />
        </a-col>
      </a-row>

      <a-row v-show="moreParam" v-bind="rowProps">
        <a-col v-bind="colProps">
          <a-form-item label="批次号" v-bind="formItemLayout">
            <a-input v-model.trim.lazy="queryParam.batchNo" placeholder="批次号模糊查询" @pressEnter="props.search"></a-input>
          </a-form-item>
        </a-col>

        <a-col v-bind="colProps">
          <a-form-item label="任务编号" v-bind="formItemLayout">
            <a-input v-model.trim.lazy="queryParam.projNoStr" placeholder="任务编号模糊查询" @pressEnter="props.search"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>

<style scoped></style>
