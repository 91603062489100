<template>
  <a-modal v-bind="siteModal" v-on="siteModal" class="EditStandardValue">
    <div class="card-blue">
      <table class="ahmux-table">
        <template v-if="emissionRate">
          <tr>
            <th class="tdLabel" style="width: 100px" rowspan="2">点位</th>
            <th class="tdLabel" style="width: 200px" rowspan="2">因子</th>
            <th class="tdLabel" style="width: 200px" rowspan="2">执行标准</th>
            <th class="tdLabel" style="width: 200px" colspan="2">排放浓度</th>
            <th class="tdLabel" style="width: 200px" colspan="2">排放速率</th>
            <th class="tdLabel" style="width: 80px" rowspan="2">操作</th>
          </tr>
          <tr>
            <th class="tdLabel" style="width: 100px">标准值</th>
            <th class="tdLabel" style="width: 100px">单位</th>
            <th class="tdLabel" style="width: 100px">标准值</th>
            <th class="tdLabel" style="width: 100px">单位</th>
          </tr>
        </template>

        <tr v-else>
          <th class="tdLabel" style="width: 100px">点位</th>
          <th class="tdLabel" style="width: 200px">因子</th>
          <th class="tdLabel" style="width: 200px">执行标准</th>
          <th class="tdLabel" style="width: 300px">标准值</th>
          <th class="tdLabel" style="width: 100px">单位</th>
          <th class="tdLabel" style="width: 80px">操作</th>
        </tr>

        <tr v-for="(item, index) in itemList" :key="index">
          <td v-if="index == 0" :rowspan="itemList.length + 2">
            {{ site.siteName }}
          </td>
          <td>
            <a-tag color="blue">{{ item.itemName }}</a-tag>
          </td>
          <td>
            {{ item.standardName }}
          </td>
          <td>
            <template v-if="item.standardType === 2">
              <a-input style="width: 45%" v-model="item.lowerLimitValEdit" @change="changeLimit(item)"></a-input> ~
              <a-input style="width: 45%" v-model="item.upperLimitValEdit" @change="changeLimit(item)"></a-input>
            </template>
            <template v-else-if="item.standardType === 3">
              <a-select style="width: 100%" placeholder="请选择" v-model="item.limitValEdit" @change="changeLimit(item)">
                <a-select-option :value="'1'" :key="'1'">有</a-select-option>
                <a-select-option :value="'0'" :key="'0'">无</a-select-option>
              </a-select>
            </template>
            <template v-else>
              <a-input v-model="item.limitValEdit" @change="changeLimit(item)"></a-input>
            </template>
          </td>
          <td>
            <a-input v-if="item.standardType !== 3" v-model="item.unitEdit" @change="changeLimit(item)"></a-input>
          </td>
          <template v-if="emissionRate">
            <td>
              <template v-if="item.standardType === 2">
                <a-input style="width: 45%" v-model="item.emissionRateLowerLimitValEdit" @change="changeLimit(item)"></a-input> ~
                <a-input style="width: 45%" v-model="item.emissionRateUpperLimitValEdit" @change="changeLimit(item)"></a-input>
              </template>
              <template v-else-if="item.standardType === 3">
                <a-select style="width: 100%" placeholder="请选择" v-model="item.emissionRateLimitValEdit" @change="changeLimit(item)">
                  <a-select-option :value="'1'" :key="'1'">有</a-select-option>
                  <a-select-option :value="'0'" :key="'0'">无</a-select-option>
                </a-select>
              </template>
              <template v-else>
                <a-input v-model="item.emissionRateLimitValEdit" @change="changeLimit(item)"></a-input>
              </template>
            </td>
            <td>
              <a-input v-if="item.standardType !== 3" v-model="item.emissionRateUnitEdit" @change="changeLimit(item)"></a-input>
            </td>
          </template>
          <td>
            <a-button type="primary" class="siteBtn" ghost @click="restore(item)">还原</a-button>
          </td>
        </tr>
      </table>
    </div>
  </a-modal>
</template>
<script>
import { limitValueEdit } from '@/api/projectStandard/projectStandardApi'

export default {
  name: 'EditStandardValue',
  data() {
    return {
      // type: 'add',
      title: '修改标准值和单位',
      siteModal: {
        title: '修改标准值和单位',
        visible: false,
        // maskClosable: false,
        destroyOnClose: true,
        centered: true,
        cancel: this.handleCancel,
        ok: this.handleOK,
        width: '1100px'
      },
      visible: false,

      site: {},
      itemList: [],
      emissionRate: 0
    }
  },
  methods: {
    getSite(site, emissionRate) {
      this.site = site
      this.emissionRate = emissionRate
      const itemList = site.itemList
      this.itemList = []
      itemList.forEach((item) => {
        if (item.projItemLimitValueId) {
          let obj = {
            id: item.id,
            itemId: item.itemId,
            itemName: item.itemName,
            standardName: item.standardName,
            projItemLimitValueId: item.projItemLimitValueId,
            limitValue: item.limitValue,
            standardType: item.standardType
          }
          if (!item.standardType || item.standardType === 1 || item.standardType === 2) {
            obj.unit = item.unit
            obj.unitEdit = item.limitValType && item.limitValType === '1' ? item.unitEdit : item.unit
          }
          if (emissionRate) {
            obj.emissionRateLimitValue = item.emissionRateLimitValue
            if (!item.standardType || item.standardType === 1 || item.standardType === 2) {
              obj.emissionRateUnit = item.emissionRateUnit
              obj.emissionRateUnitEdit = item.limitValType && item.limitValType === '1' ? item.emissionRateUnitEdit : item.emissionRateUnit
            }
          }
          if (item.standardType === 2) {
            let arr = []
            if (item.limitValType === '1') {
              arr = item.limitValEdit?.split('~') || []
            } else {
              arr = item.limitValue?.split('~') || []
            }
            obj.lowerLimitValEdit = arr.length > 0 ? Number(arr[0]) : null
            obj.upperLimitValEdit = arr.length > 1 ? Number(arr[1]) : null

            if (emissionRate) {
              let emissionRateArr = []
              if (item.limitValType === '1') {
                emissionRateArr = item.emissionRateLimitValEdit?.split('~') || []
              } else {
                emissionRateArr = item.emissionRateLimitValue?.split('~') || []
              }
              obj.emissionRateLowerLimitValEdit = emissionRateArr.length > 0 ? Number(emissionRateArr[0]) : null
              obj.emissionRateUpperLimitValEdit = emissionRateArr.length > 1 ? Number(emissionRateArr[1]) : null
            }
          } else {
            obj.limitValEdit = item.limitValType && item.limitValType === '1' ? item.limitValEdit : item.limitValue

            if (emissionRate) {
              obj.emissionRateLimitValEdit = item.limitValType && item.limitValType === '1' ? item.emissionRateLimitValEdit : item.emissionRateLimitValue
            }
          }
          this.itemList.push(obj)
        }
      })
      console.log(itemList)
      console.log(this.itemList)
      this.siteModal.visible = true
    },
    restore(item) {
      console.log(item)
      item.limitValType = 0
      item.limitValEdit = item.limitValue

      if (item.standardType === 2) {
        let arr = item.limitValue?.split('~') || []
        item.lowerLimitValEdit = arr.length > 0 ? Number(arr[0]) : null
        item.upperLimitValEdit = arr.length > 1 ? Number(arr[1]) : null
        item.unitEdit = item.unit
      } else if (item.standardType === 1 || !item.standardType) {
        item.unitEdit = item.unit
      }

      if (this.emissionRate) {
        console.log(item)
        item.emissionRateLimitValEdit = item.emissionRateLimitValue

        if (item.standardType === 2) {
          let emissionRateArr = item.emissionRateLimitValue?.split('~') || []
          item.emissionRateLowerLimitValEdit = emissionRateArr.length > 0 ? Number(emissionRateArr[0]) : null
          item.emissionRateUpperLimitValEdit = emissionRateArr.length > 1 ? Number(emissionRateArr[1]) : null
          item.emissionRateUnitEdit = item.emissionRateUnit
        } else if (item.standardType === 1 || !item.standardType) {
          item.emissionRateUnitEdit = item.emissionRateUnit
        }
      }
    },
    changeLimit(item) {
      console.log(item)
      item.limitValType = 1
    },
    handleOK(e) {
      // 提交表单
      const data = []
      const valIndex = this.itemList.findIndex((item) => {
        if (item.standardType === 2) {
          return !item.lowerLimitValEdit || !item.upperLimitValEdit
        } else {
          return !item.limitValEdit
        }
      })
      if (this.emissionRate) {
        if (valIndex !== -1) {
          this.$alert(`第${valIndex + 1}行未填写或选择排放浓度标准值，请输入内容`, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      } else {
        if (valIndex !== -1) {
          this.$alert(`第${valIndex + 1}行未填写或选择标准值，请输入内容`, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      }
      const unitIndex = this.itemList.findIndex((item) => {
        if (item.standardType === 3) {
          return false
        } else {
          return !item.unitEdit
        }
      })
      if (this.emissionRate) {
        if (unitIndex !== -1) {
          this.$alert(`第${unitIndex + 1}行未填写排放浓度单位，请输入内容`, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      } else {
        if (unitIndex !== -1) {
          this.$alert(`第${unitIndex + 1}行未填写单位，请输入内容`, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      }
      if (this.emissionRate) {
        const ervalIndex = this.itemList.findIndex((item) => {
          if (item.standardType === 2) {
            return !item.emissionRateLowerLimitValEdit || !item.emissionRateUpperLimitValEdit
          } else {
            return !item.emissionRateLimitValEdit
          }
        })
        if (ervalIndex !== -1) {
          this.$alert(`第${ervalIndex + 1}行未填写或选择排放速率标准值，请输入内容`, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        const erunitIndex = this.itemList.findIndex((item) => {
          if (item.standardType === 3) {
            return false
          } else {
            return !item.emissionRateUnitEdit
          }
        })
        if (erunitIndex !== -1) {
          this.$alert(`第${erunitIndex + 1}行未填写排放速率单位，请输入内容`, '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      }
      this.itemList.forEach((item) => {
        if (item.limitValType && item.limitValType === 1) {
          let obj = {
            projItemLimitValueId: item.projItemLimitValueId,
            limitValType: item.limitValType
          }
          if (item.standardType === 2) {
            obj.limitValEdit = item.lowerLimitValEdit + '~' + item.upperLimitValEdit
            obj.unitEdit = item.unitEdit
          } else if (item.standardType === 3) {
            obj.limitValEdit = item.limitValEdit
          } else {
            obj.limitValEdit = item.limitValEdit
            obj.unitEdit = item.unitEdit
          }
          if (this.emissionRate) {
            if (item.standardType === 2) {
              obj.emissionRateLimitValEdit = item.emissionRateLowerLimitValEdit + '~' + item.emissionRateUpperLimitValEdit
              obj.emissionRateUnitEdit = item.emissionRateUnitEdit
            } else if (item.standardType === 3) {
              obj.emissionRateLimitValEdit = item.emissionRateLimitValEdit
            } else {
              obj.emissionRateLimitValEdit = item.emissionRateLimitValEdit
              obj.emissionRateUnitEdit = item.emissionRateUnitEdit
            }
          }
          data.push(obj)
        }
        if (item.limitValType === 0) {
          data.push({
            projItemLimitValueId: item.projItemLimitValueId,
            limitValType: item.limitValType
          })
        }
      })
      console.log('data', data)
      if (data.length == 0) {
        this.handleCancel()
        return
      }
      this.$confirm({
        title: '确认提交更改吗？',
        onOk: () => {
          limitValueEdit.Post(data).then((res) => {
            if (res.code === 0) {
              this.$message.success(`修改成功`)
              this.isSuccess()
            } else {
              this.$message.error(`修改出错：${res.msg}`)
            }
          })
        },
        onCancel() {}
      })
    },
    handleCancel() {
      this.site = {}
      this.itemList = []
      this.siteModal.visible = false
    },
    isSuccess() {
      this.site = {}
      this.itemList = []
      this.siteModal.visible = false
      this.$emit('updateStandardValue')
    }
  },
  watch: {}
}
</script>

<style lang="less">
.ahmux-table {
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #caedff;
  }
}

.card-blue .card-bg-color {
  background-color: #e6f7ff;
}
.card-blue .card-border {
  border: 1px solid #91d5ff;
}

.itemLimitValue {
  display: inline-block;
  position: relative;
  margin: 7px 5px;
  .itemLimitName {
    height: 22px;
    padding: 0 7px;
    border: 1px solid #91d5ff;
    box-sizing: border-box;
    background: #e6e7ff;
    border-radius: 4px;
    font-size: 12px;
    color: #1890ff;
  }
  .itemLimitIcon {
    position: absolute;
    top: -8px;
    right: 0px;
    cursor: pointer;
    color: #ff0000;
  }
}

.siteBtn {
  margin: 4px 0;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  height: initial;
}

.down-tree {
  height: 300px;
  display: block;
  overflow-y: scroll;
}
</style>
