import { defineComponent, ref } from 'vue'

export const MoreParam = defineComponent({
  name: 'MoreParam',
  props: {
    modelValue: { type: Boolean, default: false }
  },
  model: { prop: 'modelValue', event: 'update:modelValue' },
  setup (props, { emit }) {
    /** 是否展示搜索内容 */
    const moreParam = ref(props.modelValue)
    const handleClickMore = () => {
      moreParam.value = !moreParam.value
      emit('update:modelValue', moreParam.value)
    }

    return () => (
      <a style={{ marginLeft: '8px' }} onClick={handleClickMore}>
        {moreParam.value ? '收起' : '展开'}
        <a-icon type={moreParam.value ? 'up' : 'down'}></a-icon>
      </a>
    )
  }
})
