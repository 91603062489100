<template>
  <div class="sample-storage-apply-form">
    <a-card title="基本信息" v-bind="baseFormCard">
      <detail-list :col="4">
        <detail-list-item :item-col="1">
          <span class="focus-num blue">{{ dataForm.data.title }}【{{ dataForm.data.batchNo }}】</span>
        </detail-list-item>
        <detail-list-item :item-col="1">
          <span class="gray">{{ dataForm.data.projName }}【{{ dataForm.data.projNo }}】</span>
        </detail-list-item>
        <detail-list-item term="申请人">{{ dataForm.data.applyUserName }}</detail-list-item>
        <detail-list-item term="申请时间">{{ dataForm.data.applyTime }}</detail-list-item>
        <detail-list-item term="申请样品">
          <span class="focus-num green mar-h5 point" @click="reset">{{ dataForm.data.sum }}</span> 个
        </detail-list-item>
        <detail-list-item v-for="(item,key) of cateStatMap" :key="key" :term="item.description">
          <span class="focus-num green mar-h5 point" @click="queryParam.cateId = parseInt(item.pk) ">{{ item.num }}</span>个
        </detail-list-item>
      </detail-list>
    </a-card>

    <a-card title="待入库样品信息" v-bind="baseFormCard">

      <template #extra>
        <div style="display: flex;gap: 12px;align-items: center">
          <template v-if="selectRow.length">
            <a-button @click="handleAllDelSample">未收样</a-button>
            <a-button type="primary" @click="handleAllLocationChange">存储位置</a-button>
          </template>
          <span>
            当前共计：<span class="focus-num green mar-h5">{{ sampleInfoData.length }}</span>个
          </span>

          入库时间：
          <a-date-picker
            v-model="storageTime"
            :style="{width:'180px'}"
            format="YYYY-MM-DD HH:mm"
            v-bind="moreCurrentDatePickerProps"></a-date-picker>
          <a-popover>
            <template #content>
              <a-row v-bind="rowProps">
                <a-col v-bind="colProps">
                  <a-form-item label="按点位" v-bind="formItemLayout">
                    <a-radio-group v-model="queryParam.siteId" button-style="solid">
                      <a-radio-button :value="null">全部</a-radio-button>
                      <a-radio-button v-for="item in siteList" :key="item.siteId" :value="+item.siteId">{{ item.siteName }}</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col v-bind="colProps">
                  <a-form-item label="按天数" v-bind="formItemLayout">
                    <a-radio-group v-model="queryParam.numPeriod" button-style="solid">
                      <a-radio-button :value="null">全部</a-radio-button>
                      <a-radio-button v-for="item in numPeriodList" :key="item.numPeriod" :value="+item.numPeriod">{{ item.value }}</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col v-bind="colProps">
                  <a-form-item label="按频次" v-bind="formItemLayout">
                    <a-radio-group v-model="queryParam.numFrequency" button-style="solid">
                      <a-radio-button :value="null">全部</a-radio-button>
                      <a-radio-button v-for="item in numFrequencyList" :key="item.numFrequency" :value="+item.numFrequency">{{ item.value }}</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </a-row>
            </template>
            <a-icon class="green point" type="search"></a-icon>
          </a-popover>
        </div>

      </template>
      <template v-if="!loading">
        <sample-info-table :extra-data="sampleInfoData" v-bind="sampleInfoTableProps" @selectedRows="handleSelectRow">

          <template #appearanceStatus="{row:record,index}">
            <v-contextmenu :key="`${record.id}_${index}_contextmenu`" :ref="`${record.id}_${index}_appearance_status_menu`">
              <v-contextmenu-item :data-index="index" @click="copyAppearanceStatusArr(record,index,'up')">
                <span :style="{margin:'0 10px'}"> <a-icon type="up"/>向上复制</span>
              </v-contextmenu-item>

              <v-contextmenu-item :data-index="index" @click="copyAppearanceStatusArr(record,index,'down')">
                <span :style="{margin:'0 10px '}"> <a-icon type="down"/>向下复制</span>
              </v-contextmenu-item>
            </v-contextmenu>
            <a-select
              v-model="record.appearanceStatusArr"
              v-contextmenu="`${record.id}_${index}_appearance_status_menu`"
              v-bind="appearanceStatusSelect"
              @change="val=>appearanceStatusSelect.change(val,record)">
              <a-select-option v-for="(data) in appearanceStatusSelect.dataSource" :key="data">{{ data }}</a-select-option>
            </a-select>
          </template>

          <template #locationName="{row:record,index}">
            <v-contextmenu :key="`${record.id}_${index}_contextmenu`" :ref="`${record.id}_${index}_location_menu`">
              <v-contextmenu-item :data-index="index" @click="copyLocation(record,index,'up')">
                <span :style="{margin:'0 10px'}"> <a-icon type="up"/> 向上复制</span>
              </v-contextmenu-item>

              <v-contextmenu-item :data-index="index" @click="copyLocation(record,index,'down')">
                <span :style="{margin:'0 10px '}"> <a-icon type="down"/> 向下复制</span>
              </v-contextmenu-item>
            </v-contextmenu>

            <a v-contextmenu="`${record.id}_${index}_location_menu`" @click="handleLocationChange(record)">
              {{ record.locationName || '-' }}
              <span style="margin-left:10px;">
                <a-icon type="edit"></a-icon>
              </span>
            </a>
          </template>

          <template #validity="{row:record,index}">
            <v-contextmenu :key="`${record.id}_${index}_contextmenu`" :ref="`${record.id}_${index}_validity_menu`">
              <v-contextmenu-item :data-index="index" @click="copyValidity(record,index,'up')">
                <span :style="{margin:'0 10px'}"> <a-icon type="up"/> 向上复制</span>
              </v-contextmenu-item>

              <v-contextmenu-item :data-index="index" @click="copyValidity(record,index,'down')">
                <span :style="{margin:'0 10px '}"> <a-icon type="down"/> 向下复制</span>
              </v-contextmenu-item>
            </v-contextmenu>
            <div v-contextmenu="`${record.id}_${index}_validity_menu`">
              <a-input-number
                v-model="record.validity"
                :min="1"
                class="mar-r8"
                style="width:70px;"/>
              <a-radio-group v-model="record.validityUnit" size="small">
                <a-radio value="天">天</a-radio>
                <a-radio value="小时">小时</a-radio>
              </a-radio-group>
            </div>
          </template>

          <template #action="{row:record,index}">
            <a @click="delSample(index)">未收样</a>
          </template>
        </sample-info-table>
      </template>
    </a-card>

    <a-card v-if="delSampleList.length" title="未收样样品列表" v-bind="baseFormCard">
      <template #extra>
        <span class="red">本次未收样的样品，需要重新提交申请单进行申请入库</span>
      </template>
      <detail-list :col="4">
        <detail-list-item v-for="(item,index) in delSampleList" :key="item.id">
          <a-tag color="red">{{ item.id }}</a-tag>
          <a-icon class="mar-h8 blue" type="delete" @click="unDelSample(index)"></a-icon>
        </detail-list-item>
      </detail-list>
    </a-card>

    <!--  放置位置修改表单 -->
    <location-form
      ref="locationForm"
      :locationList="locationList"
      :save="false"
      @ok="handleLocationSuccess"></location-form>
  </div>
</template>

<script>
import { groupBy, max, throttle } from 'lodash'
import mixinForm from '@/pages/common/mixins/form'
import { sampleInfoStorageApplyApi } from '@/api/sampleInfo/sampleInfoStorageApplyApi'
import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'
import LocationForm from '@/pages/cloud-quote/res/res-stock/modules/littleTable/locationForm'
import { sampleStorageApplyColumn as sampleColumn } from '@/pages/cloud-quote/sample-info/sampleTypeColumn'

import { DetailList } from '@/components'
import { dictApi } from '@/api/system'
import { customFunc } from '@/pages/cloud-quote/take-sample/modules/customForm/CustomFormFunc'
import { tsSampleinfoApi } from '@/api/sample'
import { Get, resLocationApi } from '@/api/quote'

const DetailListItem = DetailList.Item

export default {
  props: {
    id: { type: Number, required: true },
    projId: { type: Number, required: true }
  },
  mixins: [mixinForm],
  components: { DetailList, DetailListItem, SampleInfoTable, LocationForm },

  data () {
    return {
      loading: true,
      locationList: [],
      sampleInfoList: [],
      delSampleList: [],
      changeLocationList: [],
      selectRow: [],
      cateStatMap: {},

      // 快速查询

      queryParam: {
        siteId: null,
        cateId: null,
        numPeriod: null,
        numFrequency: null
      },

      currentColSize: 1,

      sampleInfoTableProps: {
        ref: 'sampleTable',
        local: true,
        columns: sampleColumn,
        pagination: false,
        cacheKeyPre: 'sample-storage-apply-form-table'
      },

      appearanceStatusSelect: {
        mode: 'tags',
        style: { width: '90%' },
        tokenSeparators: [',', '，'],
        dataSource: [],
        placeholder: '请选择样品状态',
        change: (val, record) => {
          const notIncludeArr = val && val.length && val.filter(item => !this.appearanceStatusSelect.dataSource.includes(item)) || []
          notIncludeArr.length && this.appearanceStatusSelect.dataSource.splice(this.appearanceStatusSelect.dataSource.length, 0, ...notIncludeArr)
          record.appearanceStatus = val && val.length && val.join(',') || null
        }
      },

      storageTime: this.moment(),
      dataForm: {
        data: {
          id: null
        }
      }
    }
  },

  computed: {
    maxStorageApplyTime () {
      return max(this.sampleInfoList, 'storageApplyTime')?.storageApplyTime
    },

    siteList () {
      const groupData = groupBy(this.sampleInfoList, 'siteId')
      return groupData && Object.keys(groupData)?.map(key => {
        return { siteId: key, siteName: groupData[key][0].siteName }
      })
    },

    numPeriodList () {
      const groupData = groupBy(this.sampleInfoList, 'numPeriod')
      return groupData && Object.keys(groupData)?.map(key => {
        return { numPeriod: key, value: `第${key}天` }
      })
    },

    numFrequencyList () {
      const groupData = groupBy(this.sampleInfoList, 'numFrequency')
      return groupData && Object.keys(groupData)?.map(key => {
        return { numFrequency: key, value: `第${key}次` }
      })
    },

    sampleInfoData () {
      const { siteId, numPeriod, numFrequency, cateId } = this.queryParam
      const filter = (item) => (!siteId || item.siteId === siteId) &&
        (!numPeriod || item.numPeriod === numPeriod) &&
        (!numFrequency || item.numFrequency === numFrequency) &&
        (!cateId || item.cateId === cateId)
      return this.sampleInfoList.filter(filter)
    }

  },

  watch: {
    id: {
      immediate: true,
      handler (value) {
        value && this.handleEditData(value)
        value && this.cateStat(value)
      }
    }

  },

  mounted () {
    this.checkSampleStatusDict()
    this.submit = throttle(this.submit, 6000, { leading: true, trailing: false })
    Get(resLocationApi.list, { page: false }).then(result => {
      this.locationList = result.data
    })
  },

  methods: {

    // 样品分类统计
    cateStat (id) {
      tsSampleinfoApi.cateStat({ storageApplyId: id }).then(res => {
        if (res.code === 0) {
          this.cateStatMap = res.data
        } else {
          this.$message.error(`获取样品分类统计信息出错 :${res.msg}`)
        }
      })
    },

    // 处理样品选中事件
    handleSelectRow (data) {
      this.selectRow.splice(0, this.selectRow.length, ...(data || []))
    },

    // 批量修改存放位置
    handleAllLocationChange () {
      const rowSelection = this.$refs[this.sampleInfoTableProps.ref].getRowSelection()
      if (rowSelection.selectedRows.length) {
        this.changeLocationList.splice(0, this.changeLocationList.length, ...rowSelection.selectedRows)
        const data = rowSelection.selectedRows[0]
        this.$refs.locationForm.add({ id: data.id, locationId: data.locationId + '' })
      } else {
        this.$message.error('请先选择样品！')
      }
    },

    // 存放位置改变事件
    handleLocationChange (record) {
      this.changeLocationList.splice(0, this.changeLocationList.length, record)
      this.$refs.locationForm.add({ id: record.id, locationId: record.locationId + '' })
    },

    // 复制存放位置
    copyLocation (record, index, type) {
      let isPrime = (ele, idx) => ele.locationId == null && idx < index
      if (type === 'down') {
        isPrime = (ele, idx) => ele.locationId == null && idx > index
      }
      // eslint-disable-next-line no-unused-expressions
      this.sampleInfoList.filter(isPrime)?.forEach(item => {
        item.locationId = record.locationId
        item.locationName = record.locationName
      })
    },

    // 复制有效期
    copyValidity (record, index, type) {
      let isPrime = (ele, idx) => ele.validity == null && idx < index
      if (type === 'down') {
        isPrime = (ele, idx) => ele.validity == null && idx > index
      }
      // eslint-disable-next-line no-unused-expressions
      this.sampleInfoList.filter(isPrime)?.forEach(item => {
        item.validity = record.validity
        item.validityUnit = record.validityUnit
      })
    },

    // 复制样品状态
    copyAppearanceStatusArr (record, index, type) {
      let isPrime = (ele, idx) => (ele.appearanceStatusArr == null || ele.appearanceStatusArr.length === 0) && idx < index
      if (type === 'down') {
        isPrime = (ele, idx) => (ele.appearanceStatusArr == null || ele.appearanceStatusArr.length === 0) && idx > index
      }
      // eslint-disable-next-line no-unused-expressions
      this.sampleInfoList.filter(isPrime)?.forEach(item => {
        item.appearanceStatusArr.splice(0, item.appearanceStatusArr.length, ...(record.appearanceStatusArr || []))
        this.appearanceStatusSelect.change(item.appearanceStatusArr, record)
      })
    },

    // 存放位置修改完成事件
    handleLocationSuccess (data) {
      this.changeLocationList.forEach(item => {
        item.locationId = data.locationId
        item.locationName = data.locationName
      })
    },

    // 入库时间限制,不能大于当前时间，不能小于最大的入库申请时间
    moreCurrentDate (current) {
      return current && (current < this.moment(this.maxStorageApplyTime) || current > this.now())
    },

    // 批量未收样
    handleAllDelSample () {
      const ids = this.selectRow.map(item => item.id)
      ids.forEach(item => {
        const index = this.sampleInfoList.findIndex(item => item.id === item)
        this.delSample(index)
      })
    },

    // 未收样
    delSample (index) {
      const data = this.sampleInfoList.splice(index, 1)
      this.delSampleList.push(...data)
    },

    unDelSample (index) {
      const data = this.delSampleList.splice(index, 1)
      this.sampleInfoList.push(...data)
    },

    // 准备采样状态字典
    checkSampleStatusDict () {
      // 字典是否存在，不存在保存一个
      dictApi.checkCode({ code: 'appearanceStatusSelect' }).then(res => {
        if (!res) {
          this.addSampleStatusDict()
        }
      })

      customFunc._self = this
      return customFunc.asyncGetDictCode((data) => {
        this.appearanceStatusSelect.dataSource = data && data.length && data.map(item => item.label) || []
      }, ['appearanceStatusSelect'])
    },

    // 添加字典
    addSampleStatusDict () {
      const dictData = {
        dictName: '采样样品状态',
        dictCode: 'appearanceStatusSelect',
        dictDesc: '采样样品状态字典'
      }

      dictApi.add(dictData).then(res => {
        if (res.code !== 0) {
          this.$message.error(`保存采样样品状态字典发生错误：${res.msg}`)
        }
      })
    },

    addSampleStatusDictDetail () {
      const sampleStatusArr = this.appearanceStatusSelect.dataSource.map((item, index) => {
        return {
          dictCode: 'appearanceStatusSelect',
          status: 1,
          itemText: item,
          itemValue: index + 1,
          sort: index + 1
        }
      })
      this.$store.commit('code/update', sampleStatusArr)
      this.$store.commit('code/addAll', sampleStatusArr)
    },

    // 处理编辑数据
    handleEditData (id) {
      this.dataForm.data.id = id
      this.loading = true
      sampleInfoStorageApplyApi.findById(id).then(res => {
        if (res.code === 0) {
          // eslint-disable-next-line no-unused-expressions
          res.data?.sampleInfoList?.forEach(item => {
            item.appearanceStatusArr = item.appearanceStatus?.split(',') ?? []
          })
          this.dataForm.data = res.data
          this.sampleInfoList.splice(0, this.sampleInfoList.length, ...(res.data.sampleInfoList || []))
        } else {
          this.$message.error('获取数据失败')
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 提交保存
    submit () {
      const storageSampleInfoList = this.sampleInfoList.map(item => ({ id: item.id, validity: item.validity, validityUnit: item.validityUnit, appearanceStatus: item.appearanceStatus, storageTime: this.storageTime, locationId: item.locationId }))
      const cancelStorageSampleInfoList = this.delSampleList.map(item => ({ id: item.id }))
      const result = { id: this.dataForm.data.id, storageSampleInfoList, cancelStorageSampleInfoList, projId: this.projId }
      const confirmStr = cancelStorageSampleInfoList.length ? `有${cancelStorageSampleInfoList.length}样品未收样，请仔细核查。` : '确定该批次样品已完全接收吗？'
      this.$confirm({
        title: '确认入库吗?',
        content: confirmStr,
        onOk: () => {
          sampleInfoStorageApplyApi.storage(result).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.addSampleStatusDictDetail()
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
              this.$emit('error')
            }
          })
        }
      })
    },

    // 重置
    reset () {
      const defaultData = this.$options.data.bind(this)()
      Object.assign(this.queryParam, defaultData.queryParam)
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
