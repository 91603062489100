<template>
  <div class="sample-storage-apply-list">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="批次号">
              <a-input v-model.trim.lazy="queryParam.batchNo" placeholder="批次号查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="项目编号">
              <a-input v-model.trim.lazy="queryParam.projNo" placeholder="项目编号查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="项目名称">
              <a-input v-model.trim.lazy="queryParam.projName" placeholder="项目名称查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="actionColProps">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
            <a style="margin-left: 8px" @click="toggleSearchStatus = !toggleSearchStatus">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
            </a>
          </a-col>
        </a-row>

        <template v-if="toggleSearchStatus">
          <a-row v-bind="rowProps">

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="标题">
                <a-input v-model.trim.lazy="queryParam.title" placeholder="申请标题查询" @pressEnter="search"></a-input>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="状态">
                <a-select v-model="queryParam.status">
                  <a-select-option :key="null">全部</a-select-option>
                  <a-select-option v-for="(item,key) of sampleInfoStorageApplyStatusDataMap" :key="key">{{ item.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </template>

      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <!--      <a-button v-bind="actionButton" @click="formModal.add()">新增</a-button>-->
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <storage-apply-table v-bind="tableProps">

        <template #action="{index,row}">
          <template v-if="row.status === sampleInfoStorageApplyStatusEnum.pending">
            <a v-action:sample-info:storage @click="formModal.edit(row)">入库</a>
            <a-divider type="vertical"/>
          </template>
          <template v-if="row.status !== sampleInfoStorageApplyStatusEnum.back">
            <a v-action:sample-info:storage @click="back(row)">退回</a>
            <a-divider type="vertical"/>
          </template>
          <a style="color:#1890FF" @click.stop="sampleHandoverModal.show(row)">样品交接单</a>
          <a-divider type="vertical"/>
          <a style="color:#1890FF" @click.stop="sampleFlowModal.show(row)">样品流转单</a>
        </template>

      </storage-apply-table>
    </a-card>

    <a-modal v-bind="formModal" v-on="formModal.action" v-if="formModal.visible">
      <storage-apply-form v-bind="formModal.data" v-on="formModal.formAction"></storage-apply-form>
    </a-modal>

    <!-- 样品流转下载预览 -->
    <a-modal v-bind="sampleFlowModal" v-on="sampleFlowModal">
      <SampleFlowDownloadPreview v-bind="sampleFlowModal.data" v-on="sampleFlowModal.data"/>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="sampleFlowModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 样品流转下载预览 -->
    <a-modal v-bind="sampleHandoverModal" v-on="sampleHandoverModal">
      <SampleHandoverDownloadPreview v-bind="sampleHandoverModal.data" v-on="sampleHandoverModal.data"/>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="sampleHandoverModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>

  </div>
</template>

<script>
import { withComponentList } from '@/pages/common/mixins/list'
import { fullModalData } from '@/pages/common/mixins/modal/full'

import storageApplyTable from './modules/table'
import storageApplyForm from './modules/form'
import SampleFlowDownloadPreview from '@/pages/cloud-quote/project/modules/download/SampleFlowDownloadPreview'
import SampleHandoverDownloadPreview from '@/pages/cloud-quote/project/modules/download/SampleHandoverDownloadPreview'
import { sampleInfoStorageApplyApi } from '@/api/sampleInfo/sampleInfoStorageApplyApi'

import { sampleInfoStorageApplyStatusDataMap, sampleInfoStorageApplyStatusEnum } from '@/api/sampleInfo/sampleInfoStorageApplyConstant'

export default {
  mixins: [withComponentList],
  components: { storageApplyTable, storageApplyForm, SampleFlowDownloadPreview, SampleHandoverDownloadPreview },
  props: {
    projNo: { type: String, required: false, default: undefined },
    projId: { type: Number, required: false, default: undefined },
    projName: { type: String, required: false, default: undefined },
    status: { type: String, required: false, default: undefined }
  },

  data () {
    return {
      sampleInfoStorageApplyStatusDataMap,
      sampleInfoStorageApplyStatusEnum,

      queryParam: {
        batchNo: null,
        projNo: this.projNo,
        projId: this.projId,
        projName: null,
        title: null,
        status: null
      },

      sampleFlowModal: Object.assign({}, fullModalData, {
        title: '样品流转单下载',
        data: {
          showAll: true,
          params: {
            projId: null,
            fileName: null
          }
        },
        show: (r) => {
          const params = { projId: r.projId, fileName: `【${r.projNo}】${r.projName}样品流转单-${r.batchNo}`, storageApplyId: r.id }
          // const params = { projId: r.projId, fileName: `${r.projNo}-${r.projName}样品流转单` }
          this.sampleFlowModal.data.params = params
          this.sampleFlowModal.visible = true
        },
        cancel: () => {
          this.sampleFlowModal.visible = false
        }
      }),

      sampleHandoverModal: Object.assign({}, fullModalData, {
        title: '样品交接单下载',
        data: {
          params: {
            projId: null,
            fileName: null
          }
        },
        show: (r) => {
          const params = { projId: r.projId, fileName: `【${r.projNo}】${r.projName}样品交接单-${r.batchNo}`, storageApplyId: r.id }
          // const params = { projId: r.projId, fileName: `${r.projNo}-${r.projName}样品交接单` }
          this.sampleHandoverModal.data.params = params
          this.sampleHandoverModal.visible = true
        },
        cancel: () => {
          this.sampleHandoverModal.visible = false
        }
      }),

      formModal: Object.assign({}, fullModalData, {
        title: '样品入库',

        // modal 事件
        action: {
          cancel: () => this.formModal.cancel(),
          ok: () => this.$refs[this.formModal.data.ref].submit()
        },

        // 绑定表单数据
        data: {
          ref: 'form'
        },

        // 绑定表单事件
        formAction: {
          success: () => {
            this.formModal.cancel()
            this.reload()
          }
        },

        // 进入新增界面
        add: () => {
          this.formModal.data.id = null
          this.formModal.show()
        },

        // 进入修改界面
        edit: ({ id, projId }) => {
          this.formModal.data.id = id
          this.formModal.data.projId = projId
          this.formModal.show()
        }

      }),

      contentCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: {},
        class: 'card-table'
      }
    }
  },
  computed: {
    tableProps () {
      const isSetData = false
      const showPagination = true
      const dataSource = []
      return Object.assign({ isSetData, showPagination, dataSource }, this.customTable)
    }
  },

  methods: {

    // 退回
    back (data) {
      this.$confirm({
        title: '确认要退回吗?',
        content: '退回后的样品需要重新申请入库，只有未入库和在库的样品才能被退回，请知晓。',
        onOk: () => {
          sampleInfoStorageApplyApi.back(data.id).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";

.sample-storage-apply-list {

}
</style>
