<template>
  <div id="mx-watermark" v-if="watermark" class="mx-watermark" style="">
    <div :key="ridx" v-for="ridx in rowNum">
      <div :key="cidx" v-for="cidx in colNum">
        <div
          :id="`${ridx}-${cidx}`"
          :key="`${ridx}-${cidx}`"
          :class="['mx-watermark-item', focusIdx === `${ridx}-${cidx}` ? 'hidden' : 'show']"
          :style="{top: (eq2top(ridx) + 10)+'px',left: (eq2left(cidx) + 0)+'%'}"
          @mouseover="markFocus(`${ridx}-${cidx}`)">
          <p>{{ loginUser.realname }}</p>
          <p>{{ dateTime }}</p>
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Watermark',
  props: {
    text: {
      type: String,
      default: '本次操作已记录，泄露相关信息将被依法追究法律责任'
    }
  },
  data () {
    return {
      parentNode: {},
      watermark: false,
      colNum: 3,
      rowNum: 2,
      // 行高
      rowHeight: 240,
      // 距离顶部最小高度
      mintop: 20,
      focusIdx: -1,
      dateTime: '',
      time: 3,
      focusTimer: null,
      dateFormat: 'YYYY-MM-DD HH:mm',
      imgSrc: ''
    }
  },
  methods: {
    moment,
    init () {
      var self = this
      if (self.configMap['WATERMARK'] === '1') {
        self.watermark = true
      }
      if (self.watermark) {
        this.dateTime = moment().format(this.dateFormat)
        setInterval(function () {
          self.dateTime = moment().format(self.dateFormat)
        }, 30 * 1000)
      }
    },
    markFocus (idx) {
      var self = this
      self.resetRowNum()
      this.focusIdx = idx
      if (this.focusTimer) {
        clearTimeout(this.focusTimer)
      }
      this.focusTimer = setTimeout(function () {
        self.focusIdx = -1
        self.focusTimer = null
      }, self.time * 1000)
    },
    eq2left (val) {
      var self = this
      if (val === 1) {
        return 0
      }
      if (val === 2) {
        return 33.33333
      }
      if (val === 3) {
        return 66.666666
      }
    },
    eq2top (val) {
      var self = this
      var top = self.rowHeight * (val - 1) + self.mintop * val
      if (top >= 0) {
        return top
      }
      return 0
    },
    resetRowNum () {
      var self = this
      var div = document.getElementById('mx-watermark')
      var pdivHeight = 0
      if (div) {
        var pdiv = div.parentNode
        pdivHeight = pdiv.clientHeight || pdiv.offsetHeight
      }
      if (pdivHeight > 0) {
        // 计算行数
        var rowNum = parseInt(pdivHeight / self.rowHeight)
        if (rowNum <= 0) {
          rowNum = 1
        }
        // 计算距离顶部最小高度
        var mintop = parseInt((pdivHeight % self.rowHeight) / (rowNum + 1))
        if (rowNum !== self.rowNum) {
          self.rowNum = rowNum
        }
        if (mintop && mintop !== self.mintop) {
          self.mintop = mintop
        }
      }
    }
  },
  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    },
    configMap () {
      return { ...this.$store.getters.sys_config }
    }
  },
  watch: {
    'documentElement.clientHeight' (nv, ov) {
      var self = this
      if (nv > 0) {
        // var rowNum = nv / self.rowHeight
        // if (rowNum > 0) {
        //   self.rowNum = rowNum
        // }
      }
    }
  },
  mounted () {
    var self = this
    self.init()
    // self.$nextTick(() => {
    //   self.resetRowNum()
    // })
  },
  created () {
  }
}
</script>
<style lang="less" scoped>
  @import "Watermark";
</style>

<style scoped>

</style>
