<template>
  <div>
    <div>
      <a-row>
        <a-col span="3">
          <div class="ahmux-sub-title" style="width: 100px;">
            申请信息
          </div>
        </a-col>
        <a-col span="7">
          <div class="ahmux-sub-title-detail" style="width: 100%;">
            申请人：{{ contract.createUserName }}
          </div>
        </a-col>
        <a-col span="7">
          <div class="ahmux-sub-title-detail" style="width: 100%;">
            申请时间：{{ contract.createTime }}
          </div>
        </a-col>
        <a-col span="7">
          <div class="ahmux-sub-title-detail" style="width: 100%;">
            <!--            部门：XX-->
          </div>
        </a-col>
      </a-row>
      <a-row>
        <table class="mxtable">
          <tr>
            <td class="tdLabel">任务名称</td>
            <td colspan="4">{{ contract.contractTitle }}</td>
          </tr>
          <tr>
            <!--            <td class="tdLabel">合同金额</td>-->
            <!--            <td colspan="2">{{ contract.contractAmount }}</td>-->
            <td class="tdLabel">合同编号</td>
            <td>{{ contract.contractNo }}</td>
            <td class="tdLabel">签订时间</td>
            <td>{{ contract.contractDate }}</td>
            <td></td>
          </tr>
          <!-- <tr>
            <td class="tdLabel" rowspan="6">评审内容</td>
            <td colspan="3">
              1、客户的要求是否明确；
            </td>
            <td>
              <a-radio-group v-model="auditContent.c1" :readOnly="readOnly">
                <a-radio value="是">是</a-radio>
                <a-radio value="否">否</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              2、技术能力和资源是否满足要求；
            </td>
            <td>
              <a-radio-group v-model="auditContent.c2" :readOnly="readOnly">
                <a-radio value="是">是</a-radio>
                <a-radio value="否">否</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              3、检测方法是否符合技术标准、规范；
            </td>
            <td>
              <a-radio-group v-model="auditContent.c3" :readOnly="readOnly">
                <a-radio value="是">是</a-radio>
                <a-radio value="否">否</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              4、是否需要分包，分包是否为客户接受；
            </td>
            <td>
              <a-radio-group v-model="auditContent.c4" :readOnly="readOnly">
                <a-radio value="是">是</a-radio>
                <a-radio value="否">否</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              5、是否在资质认定项目范围；
            </td>
            <td>
              <a-radio-group v-model="auditContent.c5" :readOnly="readOnly">
                <a-radio value="是">是</a-radio>
                <a-radio value="否">否</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              6、其他：{{ auditContent.c6 }}
            </td>
          </tr> -->
          <tr>
            <td class="tdLabel">关联项目</td>
            <td colspan="4">
              <a-list itemLayout="horizontal" :dataSource="projectList" size="small">
                <a-list-item slot="renderItem" slot-scope="project,index">
                  <a-list-item>{{ project.projNo }}-{{ project.projName }}</a-list-item>
                </a-list-item>
              </a-list>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">合同</td>
            <td colspan="4">
              <a-tag v-for="(file, fileIdx) in contract.fileList" :key="fileIdx" style="margin-top: 5px;margin-bottom: 5px">
                <a @click="downloadContract(file)">{{ file.fileName }}</a>
              </a-tag>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">备注</td>
            <td colspan="4">{{ contract.sbtRemark }}</td>
          </tr>
        </table>
      </a-row>
      <a-row>
        <a-col span="3">
          <div class="ahmux-sub-title" style="width: 100px;">
            技术意见
          </div>
        </a-col>
        <a-col span="7">
          <div class="ahmux-sub-title-detail" style="width: 100%;">
          </div>
        </a-col>
        <a-col span="7">
          <div class="ahmux-sub-title-detail" style="width: 100%;">
          </div>
        </a-col>
        <a-col span="7">
          <div class="ahmux-sub-title-detail" style="width: 100%;">
          </div>
        </a-col>
      </a-row>
      <a-row>
        <table class="mxtable">
          <tr>
            <th class="tdLabel">技术意见</th>
            <td colspan="5">
              <a-card v-if="contract.contractAuditor">
                <a-row>{{ contract.contractAuditOpinion }}</a-row>
                <a-row>审批人：{{ contract.contractAuditorName }}  审批时间：{{ contract.contractAuditTime }}</a-row>
              </a-card>
            </td>
          </tr>
        </table>
      </a-row>
    </div>
  </div>
</template>

<script>
import ARow from 'ant-design-vue/es/grid/Row'
import { sysCommonApi } from '@/api/common'
export default {
  name: 'SubTaskCheckForm',
  components: {
    ARow
  },
  props: {
    contract: {
      type: Object,
      default: () => ({})
    },
    auditContent: {
      type: Object,
      default: () => ({})
    },
    projectList: {
      type: Array,
      default: () => ([])
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      readOnly: true
    }
  },
  methods: {
    downloadContract (file) {
      window.location = sysCommonApi.downLoad + '?filePath=' + (file.url || file.fileUrl) + '&fileName=' + (file.fileName || file.filename || file.name) + '&token=' + this.$store.getters.token
    }
  },
  watch: {
  },
  computed: {
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style  lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
