const defaultColumn = [
  { title: '类目名称', width: 200, dataIndex: 'materialName', scopedSlots: { customRender: 'materialName' } },
  { title: '类型', width: 80, dataIndex: 'type', scopedSlots: { customRender: 'type' } },
  { title: '溶液类型', width: 80, dataIndex: 'solutionType', scopedSlots: { customRender: 'solutionType' } },
  { title: '浓度', width: 80, dataIndex: 'purity', scopedSlots: { customRender: 'purity' } },
  { title: '消耗', width: 80, dataIndex: 'num', scopedSlots: { customRender: 'num' } }
]

const editedColumn = [
  { title: '类目名称', width: 200, dataIndex: 'materialName', scopedSlots: { customRender: 'materialName' } },
  { title: '类型', width: 80, dataIndex: 'type', scopedSlots: { customRender: 'type' } },
  { title: '溶液类型', width: 80, dataIndex: 'solutionType', scopedSlots: { customRender: 'solutionType' } },
  { title: '浓度', width: 80, dataIndex: 'purity', scopedSlots: { customRender: 'purity' } },
  { title: '消耗', width: 80, dataIndex: 'num', scopedSlots: { customRender: 'num' } },
  { title: '操作', width: 100, dataIndex: 'action', scopedSlots: { customRender: 'action' } }
]

const configColumn = [
  { title: '原料', width: 150, dataIndex: 'materialName', scopedSlots: { customRender: 'materialName' } },
  { title: '溶液类型', width: 80, dataIndex: 'solutionType', scopedSlots: { customRender: 'solutionType' } },
  { title: '浓度', width: 80, dataIndex: 'purity', scopedSlots: { customRender: 'purity' } },
  { title: '类型', width: 80, dataIndex: 'type', scopedSlots: { customRender: 'type' } },
  { title: '预计消耗', width: 80, dataIndex: 'num', scopedSlots: { customRender: 'num' } },
  { title: '实际消耗', width: 250, dataIndex: 'actualNum', scopedSlots: { customRender: 'actualNum' } }
]

export {
  defaultColumn,
  editedColumn,
  configColumn
}
