// 质量控制状态字典枚举
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

export const qualityControlStatusEnum = {
  // 暂未制定
  pending: '0',
  // 已制定
  planed: '1'
}

//  质量控制状态状态选择项
export const qualityControlStatusOptions = [
  { label: '暂未制定', value: qualityControlStatusEnum.pending, color: 'purple' },
  { label: '已制定', value: qualityControlStatusEnum.planed, color: 'blue' }
]

export const qualityControlStatusMap = tranOptionsToMap(qualityControlStatusOptions)
export const qualityControlStatusDataMap = tranOptionsToDataMap(qualityControlStatusOptions)

// 质量控制环节状态字典枚举
export const qualityControlLinkStatusEnum = {
  // 无需配置
  noNeed: '-1',
  // 暂未计划
  notConfig: '0',
  // 已计划
  planed: '1',
  // 已配置
  configed: '2',
  // 已申请
  applyed: '3'
}

//  质量控制环节状态选择项
export const qualityControlLinkStatusOptions = [
  { label: '无需配置', value: qualityControlLinkStatusEnum.noNeed, color: 'red' },
  { label: '暂未计划', value: qualityControlLinkStatusEnum.notConfig, color: 'purple' },
  { label: '已计划', value: qualityControlLinkStatusEnum.planed, color: 'blue' },
  { label: '已配置', value: qualityControlLinkStatusEnum.configed, color: 'green' },
  { label: '已申请', value: qualityControlLinkStatusEnum.applyed, color: 'green' }
]

export const qualityControlLinkStatusMap = tranOptionsToMap(qualityControlLinkStatusOptions)
export const qualityControlLinkStatusDataMap = tranOptionsToDataMap(qualityControlLinkStatusOptions)

// 分类 质控类型 枚举
export const qualityControlCategoryTypeEnum = {
  // 必做
  need: '1',
  // 依据标准
  standard: '2',
  // 不做
  not: '3'
}

//  分类 质控类型选择项
export const qualityControlCategoryTypeOptions = [
  { label: '必做', value: qualityControlCategoryTypeEnum.need, color: 'green' },
  { label: '依据标准', value: qualityControlCategoryTypeEnum.standard, color: 'blue' },
  { label: '不做', value: qualityControlCategoryTypeEnum.not, color: 'gray' }
]

export const qualityControlCategoryTypeMap = tranOptionsToMap(qualityControlCategoryTypeOptions)
export const qualityControlCategoryTypeDataMap = tranOptionsToDataMap(qualityControlCategoryTypeOptions)

// 分类 质控数量类型 枚举
export const qualityControlCategoryNumTypeEnum = {
  // 固定数量
  value: '1',
  // 百分比
  percentage: '2'

}

//  分类 质控数量类型选择项
export const qualityControlCategoryNumTypeOptions = [
  { label: '固定数量', value: qualityControlCategoryNumTypeEnum.value, color: 'green' },
  { label: '百分比', value: qualityControlCategoryNumTypeEnum.percentage, color: 'blue' }
]

export const qualityControlCategoryNumTypeMap = tranOptionsToMap(qualityControlCategoryNumTypeOptions)
export const qualityControlCategoryNumTypeDataMap = tranOptionsToDataMap(qualityControlCategoryNumTypeOptions)

// 现场质控状态 枚举
export const qualityControlSampleQcEnum = {
  // 待质控
  pending: '0',
  // 已质控
  done: '1'
}

//  现场质控状态 选择项
export const qualityControlSampleQcOptions = [
  { label: '待质控', value: qualityControlSampleQcEnum.pending, color: 'red' },
  { label: '已质控', value: qualityControlSampleQcEnum.done, color: 'green' }
]

export const qualityControlSampleQcMap = tranOptionsToMap(qualityControlSampleQcOptions)
export const qualityControlSampleQcDataMap = tranOptionsToDataMap(qualityControlSampleQcOptions)

// 现场质控样品状态 枚举
export const qualityControlSampleQcSampleEnum = {
  // 无需质控样
  not: '0',
  // 待申请
  pending: '1',
  // 已申请
  applyed: '2',
  // 已制样
  done: '3',
  // 已出库
  outed: '4',
  // 已退回
  backed: '5'
}

//  现场质控样品状态 选择项
export const qualityControlSampleQcSampleOptions = [
  { label: '无需', value: qualityControlSampleQcSampleEnum.not, color: 'gray' },
  { label: '待申请', value: qualityControlSampleQcSampleEnum.pending, color: 'red' },
  { label: '已申请', value: qualityControlSampleQcSampleEnum.applyed, color: 'blue' },
  { label: '已制样', value: qualityControlSampleQcSampleEnum.done, color: 'orange' },
  { label: '已出库', value: qualityControlSampleQcSampleEnum.outed, color: 'green' },
  { label: '已退回', value: qualityControlSampleQcSampleEnum.backed, color: 'red' }
]

export const qualityControlSampleQcSampleMap = tranOptionsToMap(qualityControlSampleQcSampleOptions)
export const qualityControlSampleQcSampleDataMap = tranOptionsToDataMap(qualityControlSampleQcSampleOptions)
