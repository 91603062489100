<template></template>
<script>
export default {
  name: 'MinimizableModal',
  inheritAttrs: false,
  model: {
    prop: 'modelProps',
    event: 'update:modalProps'
  }
}
</script>
<script setup>
import { computed, ref } from 'vue'
import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'

const props = defineProps({
  modalProps: { required: false, type: Object, default: () => ({}) },
  modalAction: { required: false, type: Object, default: () => ({}) },
  tagStyle: { required: false, type: Object, default: () => ({}) },
  tagTitle: { required: false, type: String, default: undefined },
  isCheckEnter: { required: false, type: Boolean, default: false },
  isVerify: { required: false, type: Boolean, default: false },
  isStandardVerify: { required: false, type: Boolean, default: false },
  batchId: { required: false, type: Number, default: null },
  isBlankRecord: { required: false, type: Boolean, default: false },
  notReviewAudit: { required: false, type: Boolean, default: true },
  isNotEnterReadOnly: { required: false, type: Boolean, default: true }
})

const emit = defineEmits(['update:modalProps', 'showAssign', 'downloadBlankRecord', 'showVerify', 'reviewAuditRecords', 'showStandardVerify'])
const modalProps = computed({
  get() {
    return props.modalProps
  },
  set(value) {
    emit('update:modalProps', value)
  }
})

const showTag = ref(false)
const tagTitle = props.tagTitle ?? modalProps.value.title ?? ''
const max = () => {
  showTag.value = false
  modalProps.value.visible = true
}
const min = () => {
  showTag.value = true
  modalProps.value.visible = false
}

const cancel = () => {
  showTag.value = false
  props.modalAction?.cancel()
}
const ok = () => {
  props.modalAction?.ok()
}

const getBatchAutoReview = () => {
  checkBatchApi.getBatchAutoReview().then((res) => {
    if (res === 1) {
      verify()
    }
  })
}
const verify = () => {
  checkBatchApi.verify({ id: props.batchId, verifyPass: true }).then((res) => {
    if (res.code === 0) {
      getReviewedCheckUser()
      // emit('getReviewedCheckUser', value)
    } else {
      message.error(`保存检测批次状态错误 :${res.msg}`)
    }
  })
}

const getReviewedCheckUser = () => {
  checkBatchApi.getReviewedCheckUser({ batchId: props.batchId }).then((res) => {
    console.log(res)
    emit('showAssign', { batchId: props.batchId, assignUser: res })
  })
}

// 下载空白记录表
const downloadBlankRecord = () => {
  console.log('111111111111111111111111')
  emit('downloadBlankRecord')
}

const showVerify = () => {
  emit('showVerify')
}

const showStandardVerify = () => {
  emit('showStandardVerify')
}
const reviewAuditRecords = () => {
  emit('reviewAuditRecords')
}
const loading = ref(false)
const changeBtnStatus = (status) => {
  loading.value = status
}

defineExpose({ min, cancel, ok, changeBtnStatus, showTag })
</script>

<template>
  <div>
    <a-modal :destroyOnClose="false" v-bind="modalProps" v-if="showTag || modalProps.visible">
      <slot></slot>
      <template #footer>
        <slot name="footer">
          <a-spin :spinning="loading">
            <template v-if="props.isVerify">
              <a-button type="primary" style="float: left" @click="reviewAuditRecords">复核和审核记录</a-button>
            </template>
            <template v-if="props.isStandardVerify">
              <a-button type="primary" v-action:adminRole @click="showStandardVerify">标准校验</a-button>
            </template>
            <template v-if="props.isVerify && props.isNotEnterReadOnly">
              <a-button type="primary" @click="showVerify">校验</a-button>
            </template>
            <template v-if="props.isCheckEnter && props.isNotEnterReadOnly">
              <a-button @click="getBatchAutoReview" v-action:check-review:review>提交复核</a-button>
            </template>
            <template v-if="props.isBlankRecord">
              <a-button type="primary" @click="downloadBlankRecord">空白记录</a-button>
            </template>
            <template v-if="props.modalAction.cancel">
              <a-button @click="cancel">{{ modalProps.cancelText ?? '关闭' }}</a-button>
            </template>
            <a-button @click="min" v-if="props.notReviewAudit">最小化</a-button>
            <template v-if="props.modalAction.ok && props.isNotEnterReadOnly">
              <a-button :type="modalProps.okType ?? 'primary'" @click="ok">{{ modalProps.okText ?? '确定' }}</a-button>
            </template>
          </a-spin>
        </slot>
      </template>
    </a-modal>
    <div v-if="showTag" :style="tagStyle" class="tag animate__animated animate__shakeY animate__delay-1s" style="z-index: 10000">
      <span @click="max">{{ tagTitle }}</span>
    </div>
  </div>
</template>

<style scoped lang="less">
@import '~@/assets/less/common/snippet.less';
</style>
<style lang="less">
.modal-part {
  height: 100%;
  .ant-modal {
    height: 700px !important;
  }
}
</style>