/**
 * 文件模板相关API维护
 */
import { getAction, postAction, postDataAction } from './common'

const fileTemplatePre = '/ms-api/file-template'
const fileTemplateUreportPre = '/ms-api/file-template-ureport'
const fileTemplateReportPre = '/ms-api/file-template-report' // 报告文件模板

export const fileTemplateApi = {
  list: (p) => getAction(`${fileTemplatePre}/list`, p),
  edit: (p) => postAction(`${fileTemplatePre}/edit`, p),
  uploadFile: `${fileTemplatePre}/upload-file`
}

export const fileTemplateUreportApi = {
  list: (p) => getAction(`${fileTemplateUreportPre}/list`, p),
  save: (data) => postDataAction(`${fileTemplateUreportPre}/save`, data),
  backup: (data) => postAction(`${fileTemplateUreportPre}/backup`, data),
  copy: (data) => postAction(`${fileTemplateUreportPre}/copy`, data),
  delete: (id) => postAction(`${fileTemplateUreportPre}/delete/${id}`),
  findSampleFileTemplateMap: () => getAction(`${fileTemplateUreportPre}/sample-template-map`),
  edit: '/ms-api/ureport/designer',
  form: '/ms-api/ureport/form',
  preview: '/ms-api/ureport/preview',
  loadReportProviders: () => getAction('/ms-api/ureport/designer/loadReportProviders'),
  word: '/ms-api/ureport/word',
  excel: '/ms-api/ureport/excel',
  agreementList: (p) => getAction(`${fileTemplateUreportPre}/agreement-list`, p)
}

export const fileTemplateReportApi = {
  list: (p) => getAction(`${fileTemplateReportPre}/list`, p),
  save: (data) => postDataAction(`${fileTemplateReportPre}/save`, data),
  delete: (id) => postAction(`${fileTemplateReportPre}/delete/${id}`),
  deleteRel: (id) => postAction(`${fileTemplateReportPre}/delete-rel/${id}`),
  subList: (p) => getAction(`${fileTemplateReportPre}/sub-list`, p),
  backup: (data) => postAction(`${fileTemplateReportPre}/backup`, data),
  checkResultList: (p) => getAction(`${fileTemplateReportPre}/check-result-list`, p),
  active: (id) => postAction(`${fileTemplateReportPre}/active/${id}`)
}

export const ureportExportToServerApi = {
  // downloadWordByColor: (p) => getAction('/ms-api/ureport-export-to-server/downloadWordByColor', p)
  downloadWordByColor: '/ms-api/ureport-export-to-server/downloadWordByColor'
}
