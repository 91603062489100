<template>
  <a-modal id="check-form" v-bind="fullModal" v-on="fullModal">
    <a-form>
      <a-row v-bind="rowProps" style="padding: 0 10px">

        <a-col :span="12">
          <a-form-item label="点位号:" v-bind="formItemLayout1">
            <a-select
              style="width: 200px"
              v-model="curveVerifyInfo.siteNo"
              @change="handleSiteNoChange">
              <a-select-option v-for="m in curveDrawList" :key="m.drawIndex" :value="m.drawIndex">{{ m.drawIndex }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="标准值(X)" v-bind="formItemLayout1">
            <a-input-number v-model="curveVerifyInfo.standardCurveAbsorbance" @change="calcRD" style="width: 200px" disabled></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-bind="rowProps" style="padding: 0 10px">

        <a-col :span="12">
          <a-form-item label="现测值(Y)" v-bind="formItemLayout1">
            <a-input-number v-model="curveVerifyInfo.siteAbsorbance" @change="calcRD" style="width: 200px"></a-input-number>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="相对偏差" v-bind="formItemLayout1">
            <a-input-number
              v-model="curveVerifyInfo.relativeDeviation"
              style="width: 200px"
              :formatter="value => `${value}%`"
              :parser="value => value.replace('%', '')"
              >
              <!-- disabled -->
            </a-input-number>
          </a-form-item>
        </a-col>

      </a-row>

      <a-row v-bind="rowProps" style="padding: 0 10px">

        <a-col :span="12">
          <a-form-item label="允许范围" v-bind="formItemLayout1">
            <a-input v-model="curveVerifyInfo.allowRange" style="width: 200px" disabled />
<!--            <span style="color: red;margin: 0 10px;" v-if="inAllowRange">*相对偏差大于允许范围</span>-->
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="配置日期" style="margin: 0;" v-bind="formItemLayout1">
            <a-date-picker v-model="curveVerifyInfo.makeDate" :disabledDate="moreCurrentDate" format="YYYY-MM-DD HH:mm:ss" @change="setValidTime"></a-date-picker>
          </a-form-item>
        </a-col>

      </a-row>
<!--      <a-row v-bind="rowProps" style="padding: 0 10px">-->

<!--        <a-col v-bind="formItemLayout1">-->
<!--          <a-form-item label="是否" :labelCol="{ span: 4 }">-->
<!--            <a-input v-model="curveVerifyInfo.allowRange" style="width: 200px" disabled />-->
<!--          </a-form-item>-->
<!--        </a-col>-->

<!--      </a-row>-->

      <a-row v-bind="rowProps" style="padding: 0 10px">

        <a-col :span="24">
          <a-form-item label="备注" style="width: 715px" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
            <a-textarea v-model="curveVerifyInfo.note" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="备注信息"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
      <a-button @click="fullModal.submit" type="primary">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import {
  checkStandardCurveConfigApi,
  checkStandardCurveVerifyApi
} from '@/api/checkStandardCurve/checkStandardCurveApi'
import moment from 'moment'

export default {
  mixins: [fullModal],
  props: {
    curveRecord: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      formItemLayout1: {
        labelCol: {
          xxl: { span: 8 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        },
        wrapperCol: {
          xxl: { span: 16 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        }
      },
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },
      fullModal: {
        title: '曲线校验',
        width: 800,
        // height: 600,
        wrapClassName: '',
        bodyStyle: { padding: '40px 0' },
        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },
        submit: () => {
          if (Number(this.curveVerifyInfo.standardCurveAbsorbance)===0) {
            this.$message.error('所选点位的标准值为零，故相对偏差计算错误，请重新选择点位')
            return
          }
          if (!this.curveVerifyInfo.siteAbsorbance && this.curveVerifyInfo.siteAbsorbance!==0) {
            this.$message.error('请输入现测值')
            return
          }
          checkStandardCurveVerifyApi.save(this.curveVerifyInfo).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.fullModal.visible = false
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      curveDrawList: [],
      curveVerifyInfo: {
        curveId: this.curveRecord.id,
        siteNo: 1,
        standardCurveAbsorbance: null,
        siteAbsorbance: null,
        relativeDeviation: null,
        allowRange: null,
        makeDate: null,
        validDate: null,
        note: null
        // isValid: 0
      },
      inAllowRange: false
    }
  },

  computed: {
  },

  methods: {
    moment,
    // 时间限制 不能大于当前时间
    moreCurrentDate (current) {
      return current && current > moment()
    },
    setValidTime (current) {
      const validTime = this.getValidTime(current)
      this.curveVerifyInfo.validDate = validTime
    },
    getValidTime (current) {
      const { verifyValidDate, verifyValidDateUnit } = this.curveRecord
      return this.moment(current).add(verifyValidDate, verifyValidDateUnit)
    },
    calcRD () {
      const curveVerify = this.curveVerifyInfo
      if (curveVerify.standardCurveAbsorbance != null && !isNaN(curveVerify.standardCurveAbsorbance) && curveVerify.siteAbsorbance != null && !isNaN(curveVerify.siteAbsorbance)) {
        curveVerify.relativeDeviation = Math.floor((curveVerify.standardCurveAbsorbance - curveVerify.siteAbsorbance) / curveVerify.standardCurveAbsorbance * 10000) / 100
        // const minRange = this.curveRecord.verifyAllowMinRange
        // const maxRange = this.curveRecord.verifyAllowMaxRange
        // if ((minRange !== null && curveVerify.relativeDeviation < minRange) || (maxRange !== null && curveVerify.relativeDeviation > maxRange)) {
        //   curveVerify.isValid = 0
        // } else {
        //   curveVerify.isValid = 1
        // }
      }
    },
    handleSiteNoChange (value) {
      const draw = this.curveDrawList.filter(item => item.drawIndex === value)
      if (draw.length > 0) {
        this.curveVerifyInfo.standardCurveAbsorbance = draw[0].result
        this.calcRD()
      }
    },
    // 展示当前组件
    show () {
      this.getCurveSite()
      this.fullModal.show()
      const makeDate = this.moment(new Date())
      // this.curveVerifyInfo.allowRange = this.curveRecord.verifyAllowRange
      let allowRange = ''
      if (this.curveRecord.verifyAllowMinRange !== null) {
        allowRange = '≥' + this.curveRecord.verifyAllowMinRange + '%，'
      }
      if (this.curveRecord.verifyAllowMaxRange !== null) {
        allowRange += '≤' + this.curveRecord.verifyAllowMaxRange + '%'
      }
      this.curveVerifyInfo.allowRange = allowRange
      this.curveVerifyInfo.makeDate = makeDate
      this.setValidTime(makeDate)
    },
    getCurveSite () {
      const param = { id: this.curveRecord.id }
      checkStandardCurveConfigApi.curveSite(param).then(res => {
        if (res.code === 0 && res.data.length > 0) {
          this.curveDrawList = res.data
          // let drawIndexes = res.data.map(item => item.drawIndex)
          // console.log(drawIndexes);
          // drawIndexes = drawIndexes.filter(item => item >= 2)
          // console.log(drawIndexes);
          // let siteNo = drawIndexes[Math.floor(Math.random()*drawIndexes.length)]
          // console.log(siteNo);
          // let draw = res.data.filter(item => item.drawIndex === siteNo)[0]
          // this.curveVerifyInfo.siteNo = draw.drawIndex
          // this.curveVerifyInfo.standardCurveAbsorbance = draw.result
          // this.defaultSiteAbsorbance()
          this.curveVerifyInfo.siteNo = res.data[0].drawIndex
          this.curveVerifyInfo.standardCurveAbsorbance = res.data[0].result
        }
      })
    },
    defaultSiteAbsorbance(){
      let standardCurveAbsorbance = this.curveVerifyInfo.standardCurveAbsorbance * 1000
      let minRange = 0
      let maxRange = 0
      let minNum  = 0
      let maxNum  = 0
      let randomNum = 0
      if (this.curveRecord.verifyAllowMinRange !== null) {
        if (Number(this.curveRecord.verifyAllowMinRange) <= -1) {
          minRange = Number(this.curveRecord.verifyAllowMinRange) + 1
        } else {
          minRange = Number(this.curveRecord.verifyAllowMinRange)
        }
      }
      if (this.curveRecord.verifyAllowMaxRange !== null) {
        if (Number(this.curveRecord.verifyAllowMaxRange) >= 1) {
          maxRange = Number(this.curveRecord.verifyAllowMaxRange) - 1
        } else {
          maxRange = Number(this.curveRecord.verifyAllowMaxRange)
        }
      }
      if (minRange === 0 && maxRange === 0) {
        this.curveVerifyInfo.siteAbsorbance = standardCurveAbsorbance / 1000
      } else {
        minNum = standardCurveAbsorbance + standardCurveAbsorbance * minNum / 100
        maxNum = standardCurveAbsorbance + standardCurveAbsorbance * maxRange / 100
        randomNum = Math.floor(Math.random() * (maxNum - minNum + 1) + minNum)
        console.log(randomNum,minNum,maxNum);
        if (randomNum > maxNum) {
          randomNum = Math.floor(maxNum) < minNum ? maxNum : Math.floor(maxNum)
        }else if (randomNum < minNum) {
          randomNum = Math.ceil(minNum) > maxNum ? minNum : Math.ceil(minNum)
        }
        console.log(randomNum);
        this.curveVerifyInfo.siteAbsorbance = randomNum / 1000
      }
      this.calcRD()
    }
  }
}
</script>

<!--<style lang="less" scoped>
@import "~@/assets/less/table/index2";

.check-form {
  position: absolute;
  bottom: 100px;
  width: 100%;
  background: #FF0000;
  z-idnex: 1;
}

.mxtable td p {
  margin-bottom: .5em;
}

.check-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

</style>-->
