<script>
export default {
  name: 'SampleListSelectSample',
  inheritAttrs: false
}
</script>
<script setup>

import { reactive } from 'vue'
import { useFullModal } from '@/pages/common/mixins/modal/full'
import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import { CheckTaskTable } from '@/pages/cloud-quote/check-task/modules'
import SelectItem from '@/pages/common/SelectItem'
import { useOutSearch, useOutSearchProps } from '@/pages/common/compositions/list/useOutSearch'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import { useOutTable } from '@/pages/common/compositions/table/useTable'
import { useDateRangeCompute } from '@/pages/common/compositions/date/useDate'
import { MoreParam } from '@/pages/common/components/modules/more-param'
import { uniqBy } from 'lodash'

const props = defineProps({
  title: { type: String, required: false, default: '选择样品' },
  disabled: { type: Boolean, required: false, default: false }
})

const queryParam = reactive({
  checkUser: null,
  planed: false,
  itemIds: [],
  itemNames: '',
  projNo: null,
  sampleId: null,
  sampleBeginTime: null,
  sampleEndTime: null,
  taskBeginQueryBeginTime: null,
  taskBeginQueryEndTime: null,

  status: checkTaskStatusEnum.scheduled,
  showPagination: false,
  rowSelect: {
    type: 'checkbox',
    selectedRows: [],
    selectedRowKeys: []
  }
})

const { defaultDatePickerProps } = useFormProps()
const { rowProps, colProps, moreParam, searchCard, actionColProps } = useOutSearchProps()
const { tableRef, customTable } = useOutTable(queryParam)
const { search, reset } = useOutSearch(tableRef, queryParam)

const modalData = {
  title: props.title
}

const emit = defineEmits(['selected'])

const showSetup = (param) => {
  Object.assign(queryParam, param)
}

const okFun = () => {
  const selectedData = queryParam.rowSelect.selectedRows || []
  emit('selected', selectedData)
  modalAction.cancel()
}
const { modalProps, modalAction } = useFullModal(modalData, okFun, null, null, showSetup, null)

const sampleTime = useDateRangeCompute(queryParam.sampleBeginTime, queryParam.sampleEndTime)
const scheduleTime = useDateRangeCompute(queryParam.taskBeginQueryBeginTime, queryParam.taskBeginQueryEndTime)

// 检测项改变事件
const handleItemChange = (itemList) => {
  const uniqList = uniqBy(itemList || [], 'itemId')
  queryParam.itemIds.splice(0, queryParam.itemIds.length, ...uniqList.map(item => item.itemId))
  queryParam.itemNames = uniqList.map(item => item.itemName).join(',')
  search()
}
defineExpose({
  show: modalAction.show
})
</script>

<template>

  <a-modal v-bind="modalProps" v-on="modalAction">
    <a-card v-bind="searchCard">
      <a-row v-bind="rowProps">
        <a-col v-bind="colProps">
          <a-form-item label="样品编号">
            <a-input v-model.trim="queryParam.sampleId" @pressEnter="search"></a-input>
          </a-form-item>
        </a-col>
        <a-col v-bind="colProps">
          <a-form-item label="任务编号">
            <a-input v-model.trim="queryParam.projNo" @pressEnter="search"></a-input>
          </a-form-item>
        </a-col>
        <a-col v-bind="colProps">
          <a-form-item label="检测项">
            <select-item
              :disabled="disabled"
              :trigger-change="true"
              :value="queryParam.itemNames"
              type-title="检测项"
              @change="handleItemChange"></select-item>
          </a-form-item>
        </a-col>
        <a-col v-bind="actionColProps">
          <a-button-group>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
            <a-button icon="reload" @click="reset" :disabled="disabled">重置</a-button>
          </a-button-group>
          <MoreParam v-model="moreParam"/>
        </a-col>
      </a-row>

      <a-row v-bind="rowProps" v-show="moreParam">
        <a-col v-bind="colProps">
          <a-form-item label="采样时间">
            <a-range-picker v-model="sampleTime" v-bind="defaultDatePickerProps"></a-range-picker>
          </a-form-item>
        </a-col>

        <a-col v-bind="colProps">
          <a-form-item label="安排时间">
            <a-range-picker v-model="scheduleTime" v-bind="defaultDatePickerProps"></a-range-picker>
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <check-task-table v-bind="customTable" ref="tableRef"></check-task-table>
  </a-modal>
</template>
