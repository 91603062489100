import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

/** 填充数字中出现空 为 0 */
Vue.filter('numberFillNull', function (value) {
  return value || 0
})

Vue.filter('dayFilter', function (data, nullReplace = '-') {
  return data && moment(data).format('YYYY-MM-DD') || nullReplace
})

Vue.filter('minuteFilter', function (data, nullReplace = '-') {
  return data && moment(data).format('YYYY-MM-DD HH:mm') || nullReplace
})

Vue.filter('momentFilter', function (data, pattern = 'YYYY-MM-DD HH:mm:ss', nullReplace = '-') {
  return data && moment(data).format(pattern) || nullReplace
})

Vue.filter('splitFilter', function (data, splitFlag = ',') {
  return data && data.split(splitFlag)
})

Vue.filter('nullFilter', function (data, replace = '-') {
  return data || replace
})
