import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

// 质量控制人员环节措施枚举字典
export const personLinkStepEnum = {
  // 人员培训
  train: 1,
  // 人员监督
  inspect: 2,
  // 人员考核
  check: 4
}

//  质量控制人员环节措施选择项
export const personLinkStepOptions = [
  { label: '人员培训', value: personLinkStepEnum.train, color: 'blue' },
  { label: '人员监督', value: personLinkStepEnum.inspect, color: 'blue' },
  { label: '人员考核', value: personLinkStepEnum.check, color: 'blue' }
]

export const personLinkStepMap = tranOptionsToMap(personLinkStepOptions)
export const personLinkStepDataMap = tranOptionsToDataMap(personLinkStepOptions)

// 质量控制设备环节措施枚举字典
export const deviceLinkStepEnum = {
  // 设备溯源
  traceability: 1,
  // 设备使用记录
  usedRecord: 2,
  // 设备维护
  protect: 4,
  // 期间核查
  check: 8
}

//  质量控制设备环节措施选择项
export const deviceLinkStepOptions = [
  { label: '设备溯源', value: deviceLinkStepEnum.traceability, color: 'blue' },
  { label: '设备使用记录', value: deviceLinkStepEnum.usedRecord, color: 'blue' },
  { label: '设备维护', value: deviceLinkStepEnum.protect, color: 'blue' },
  { label: '期间核查', value: deviceLinkStepEnum.check, color: 'blue' }
]

export const deviceLinkStepMap = tranOptionsToMap(deviceLinkStepOptions)
export const deviceLinkStepDataMap = tranOptionsToDataMap(deviceLinkStepOptions)

// 质量控制现场环节措施枚举字典
export const sampleLinkStepEnum = {
  // 固定剂配置
  fixativeConfig: 1,
  // 现场空白
  fieldBlank: 2,
  // 运输空白
  transportBlank: 4,
  // 全程序空白
  allBlank: 8,
  // 现场平行
  fieldParallel: 16,
  // 仪器设备校准
  InstrumentCalibration: 32,
  // 样品运输过程
  transportationProcess: 64,
  // 人员比对
  PersonnelComparison: 128,
  // 人机比对
  manMachineComparison: 256
}

//  质量控制现场环节措施选择项
export const sampleLinkStepOptions = [
  { label: '固定剂配置', value: sampleLinkStepEnum.fixativeConfig, color: 'blue' },
  { label: '现场空白', value: sampleLinkStepEnum.fieldBlank, color: 'blue' },
  { label: '运输空白', value: sampleLinkStepEnum.transportBlank, color: 'blue' },
  { label: '全程序空白', value: sampleLinkStepEnum.allBlank, color: 'blue' },
  { label: '现场平行', value: sampleLinkStepEnum.fieldParallel, color: 'blue' },
  { label: '仪器设备校准', value: sampleLinkStepEnum.InstrumentCalibration, color: 'blue' },
  { label: '样品运输过程', value: sampleLinkStepEnum.transportationProcess, color: 'blue' },
  { label: '人员比对', value: sampleLinkStepEnum.PersonnelComparison, color: 'blue' },
  { label: '人机比对', value: sampleLinkStepEnum.manMachineComparison, color: 'blue' }
]

export const sampleLinkStepMap = tranOptionsToMap(sampleLinkStepOptions)
export const sampleLinkStepDataMap = tranOptionsToDataMap(sampleLinkStepOptions)

// 质量控制检测环节措施枚举字典
export const checkLinkStepEnum = {
  // 标液配置
  standardSolutionConfig: 1,
  // 基准溶液配置
  referenceSolutionConfig: 2,
  // 标液定值
  standardSettingValue: 4,
  // 标液稀释
  standardDilution: 8,
  // 曲线绘制
  curveDrawing: 16,
  // 空白样
  blank: 32,
  // 曲线控制
  curveControl: 64,
  // 平行样
  parallelSample: 128,
  // 加标回收率
  spikeRecovery: 256,
  // 密码样
  passwordSample: 512,
  // 人员比对
  PersonnelComparison: 1024,
  // 人机比对
  manMachineComparison: 2048,
  // 实验室间比对
  interLaboratoryComparison: 8192,
  // 标准样品
  standardSample: 16384,
  // 能力验证
  proficiencyTest: 32768
}

//  质量控制检测环节措施选择项
export const checkLinkStepOptions = [
  { label: '标液配置', value: checkLinkStepEnum.standardSolutionConfig, color: 'blue' },
  { label: '基准溶液配置', value: checkLinkStepEnum.referenceSolutionConfig, color: 'blue' },
  { label: '标液定值', value: checkLinkStepEnum.standardSettingValue, color: 'blue' },
  { label: '标液稀释', value: checkLinkStepEnum.standardDilution, color: 'blue' },
  { label: '曲线绘制', value: checkLinkStepEnum.curveDrawing, color: 'blue' },
  { label: '空白样', value: checkLinkStepEnum.blank, color: 'blue' },
  { label: '曲线控制', value: checkLinkStepEnum.curveControl, color: 'blue' },
  { label: '平行样', value: checkLinkStepEnum.parallelSample, color: 'blue' },
  { label: '加标回收率', value: checkLinkStepEnum.spikeRecovery, color: 'blue' },
  { label: '密码样', value: checkLinkStepEnum.passwordSample, color: 'blue' },
  { label: '人员比对', value: checkLinkStepEnum.PersonnelComparison, color: 'blue' },
  { label: '人机比对', value: checkLinkStepEnum.manMachineComparison, color: 'blue' },
  { label: '实验室间比对', value: checkLinkStepEnum.interLaboratoryComparison, color: 'blue' },
  { label: '标准样品', value: checkLinkStepEnum.standardSample, color: 'blue' },
  { label: '能力验证', value: checkLinkStepEnum.proficiencyTest, color: 'blue' }
]

export const checkLinkStepMap = tranOptionsToMap(checkLinkStepOptions)
export const checkLinkStepDataMap = tranOptionsToDataMap(checkLinkStepOptions)

// 质量控制环境环节措施枚举字典
export const envLinkStepEnum = {
  // 环境监督
  inspect: 1,
  // 环境控制
  control: 2,
  // 人员考核
  check: 4
}

//  质量控制环境环节措施选择项
export const envLinkStepOptions = [
  { label: '环境监督', value: envLinkStepEnum.inspect, color: 'blue' },
  { label: '环境控制', value: envLinkStepEnum.control, color: 'blue' }
]

export const envLinkStepMap = tranOptionsToMap(envLinkStepOptions)
export const envLinkStepDataMap = tranOptionsToDataMap(envLinkStepOptions)

// 质量控制样品状态 枚举类
export const qualityControlSampleStatusEnum = {
  // 已计划
  planed: '0',
  // 已申请
  applyed: '1'
}
//  质量控制样品状态 选择项
export const qualityControlSampleStatusOptions = [
  { label: '已计划', value: qualityControlSampleStatusEnum.planed, color: 'blue' },
  { label: '已申请', value: qualityControlSampleStatusEnum.applyed, color: 'green' }
]

export const qualityControlSampleStatusMap = tranOptionsToMap(qualityControlSampleStatusOptions)
export const qualityControlSampleStatusDataMap = tranOptionsToDataMap(qualityControlSampleStatusOptions)

// 质控环节对应的样品类型
export const qualityControlLinkSampleTypeMap = {
  sampleLink: [sampleInfoTypeEnum.fieldBlank, sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.allBlank, sampleInfoTypeEnum.fieldParallel]
}

// 质控环节相应的措施对应的样品类型
export const qualityControlLinkStepSampleTypeMap = {
  fieldBlank: sampleInfoTypeEnum.fieldBlank,
  transportBlank: sampleInfoTypeEnum.transportBlank,
  allBlank: sampleInfoTypeEnum.allBlank,
  fieldParallel: sampleInfoTypeEnum.fieldParallel
}
