<template>
  <detail-list :col="col" class="detail-layout">
    <detail-list-item term="项目名称">{{ orderData.projName }}</detail-list-item>
    <detail-list-item term="报告类型">{{ orderData.projReportType | reportTypeFilter }}</detail-list-item>
    <detail-list-item term="领取方式">
      <a-tag v-if="orderData.projReportReceive==='1'" color="blue">{{ reportReceiveDict[orderData.projReportReceive] }}</a-tag>
      <a-tag v-else-if="orderData.projReportReceive==='2'" color="green">{{ reportReceiveDict[orderData.projReportReceive] }}</a-tag>
      <a-tag v-else-if="orderData.projReportReceive==='9'" color="orange">{{ reportReceiveDict[orderData.projReportReceive] }}</a-tag>
    </detail-list-item>
    <detail-list-item term="委托单位">{{ orderData.csrName }}</detail-list-item>
    <detail-list-item term="委托日期">  {{ moment(orderData.projScheduledStart).format("YYYY-MM-DD") }}</detail-list-item>
    <detail-list-item term="报告负责人">{{ orderData.reportLeaderName }}</detail-list-item>
    <detail-list-item term="完成时间">{{ orderData.approvalTime }}</detail-list-item>
  </detail-list>
</template>

<script>
import DetailList from '@/components/tools/DetailList'
import moment from 'moment'
import { reportTypeApi } from '@/api/quote'
import { reduce } from 'lodash'
import { sendStatusDict, reportReceiveDict } from '../reportSendDict'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

var vm = {}
const DetailListItem = DetailList.Item
export default {
  name: 'ReportSendDetail',
  props: {
    orderData: {
      type: Object,
      required: true
    },
    col: {
      type: Number,
      default: 1
    }
  },
  components: { DetailList, DetailListItem },

  filters: {
    recptStatusFilter (status) {
      return { '0': '未收款', '1': '完成收款', '2': '部分收款' }[status]
    },

    reportTypeFilter (type) {
      return vm.reportType[type]
    }
  },

  data () {
    vm = this
    return {
      reportType: this.$store.state.code.cache.proj_report_type,
      // sendStatusDict: { '0': '待发', '1': '已邮寄', '2': '已取' },
      // reportReceiveDict: { '1': '邮寄', '2': '自取', '9': '其他' }
      sendStatusDict,
      reportReceiveDict
    }
  },
  methods: {
    moment
  }
}
</script>

<style scoped>

</style>
