<template>
  <div>
    <a-input-search
      :class="clazz"
      v-model="selectPayment"
      :placeholder="placeholder"
      disabled
      @search="onSearchPayment">
      <a-button slot="enterButton" :disabled="disabled">选择</a-button>
    </a-input-search>

    <search-payment-modal
      v-if="searchPaymentModal.visible"
      v-bind="searchPaymentModal"
      v-on="searchPaymentModal"
      :searchType="searchType"/>
  </div>
</template>

<script>
import SearchPaymentModal from '@/pages/flow/module/components/search-payment/SearchPaymentModal'

export default {
  name: 'SearchPaymentEdit',
  components: { SearchPaymentModal },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    clazz: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: '请先选择支付对象'
    },

    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    searchType: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      paymentList: [],

      selectPayment: '',

      searchPaymentModal: {
        visible: false,
        ref: 'searchPaymentModal',
        ok: (data) => { this.change(data) },
        show: () => { this.searchPaymentModal.visible = true },
        cancel: () => { this.searchPaymentModal.visible = false }
      }
    }
  },

  mounted () {
    this.selectPayment = this.value
  },

  watch: {
    paymentList: {
      immediate: true,
      handler (val) {
        this.selectPayment = val.map(item => { return item.name }).join(',')
      }
    }
  },
  methods: {

    change (data) {
      this.paymentList.splice(0, this.paymentList.length, ...data)
      this.$emit('change', data)
    },

    // 通过组织机构筛选选择用户
    onSearchPayment () {
      this.searchPaymentModal.show()
      this.$nextTick(() => {
        this.$refs[this.searchPaymentModal.ref].init(this.paymentList)
      })
    }
  }
}
</script>

<style scoped>

</style>
