<template>
  <div>
    <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
      签章申请
    </div>
    <report-base-info :orderData="orderData" v-if="orderData.projId"></report-base-info>
    <div style="width: 100%;height: 100%;">
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              申请信息
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              申请人：{{this.defaultData.userName}}
            </div>
          </a-col>
          <a-col span="14">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              申请时间：{{this.defaultData.applyTime}}
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel" style="padding: 0 5px;"><span style="color: red;margin-right: 5px;">*</span>盖章</td>
              <td colspan="3" style="padding: 0 5px;">
                <a-form-item label="" style="margin: 0;">
                  <template v-for="(obj, idx) in signatureTypeList">
                    <div :key="idx" v-if="defaultData.signatureType && defaultData.signatureType.includes(obj.name)" style="clear: both;">
                      <span style="color: #f5222d;margin-left: 10px;font-weight: bolder;float: left;height: 45px;line-height: 45px;"><a-icon type="check-square" style="margin: 0 10px;" />{{ obj.title }}</span>
                      <template v-if="obj.config">
                        <div style="float: left;line-height: 45px;">
                          <span style="color: #bfbfbf;margin-left: 10px;">【签章页码：</span>
                          <span style="color: #bfbfbf;" v-if="obj.page === 'first'">首页</span>
                          <span style="color: #bfbfbf;" v-else-if="obj.page === 'last'">尾页</span>
                          <span style="color: #bfbfbf;" v-else-if="obj.page === 'all'">每页</span>
                          <span style="color: #bfbfbf;" v-else-if="obj.page === 'custom'">自定义</span>
                        </div>
                        <div style="float: left;margin-left: 10px;line-height: 45px;">
                          <p v-for="(pageInfo,pageIndex) in obj.pageConfig" :key="pageIndex" style="margin: 0;">
                            <a-divider type="vertical"/>
                            <span v-if="obj.page==='custom'" style="color: #bfbfbf;">
                              第{{pageInfo.pageNum}}页：
                            </span>
                            <span style="color: #bfbfbf;">签章位置：</span>
                            <template v-for="pos in positionList">
                              <template v-if="pos.name === 'custom' && pageInfo.position === pos.name && pageInfo.hasOwnProperty('coordinate') && pageInfo.coordinate.split('|').length > 1">
                                <a-popover title="" :key="pos.name">
                                  <template slot="content">
                                    <canvas-position-modal :id="obj.name+`Area`+pageIndex" :imgUrl="obj.url" :position="pageInfo.coordinate" :readOnly="true"></canvas-position-modal>
                                  </template>
                                  <span style="color: #bfbfbf;">{{pos.title}}<a-icon type="info-circle" style="color: #f5222d; margin: 0 5px;" />】</span>
                                </a-popover>
                              </template>
                              <template v-else>
                                <span style="color: #bfbfbf;" :key="pos.name" v-if="pageInfo.position === pos.name">{{pos.title}}】</span>
                              </template>
                            </template>
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <span style="font-size: 14px;color: #bfbfbf;line-height: 45px;margin-left: 10px;" v-if="obj.desc">【{{obj.desc}}】</span>
                      </template>
                    </div>
                  </template>
                </a-form-item>
              </td>
            </tr>
            <tr>
              <td class="tdLabel">备注</td>
              <td colspan="3">{{ defaultData.remark }}</td>
            </tr>
            <tr>
              <td class="tdLabel">原始文件</td>
              <td colspan="3">
                <template v-for="(file, i) in defaultData.fileupload">
                  <div class="custom-list-file" :key="i" style="max-height: unset;">
                    <div :class="getCustomClass(file)" style="width: 120px;height: 80px;">
                      <a-dropdown :trigger="['contextmenu']">
                        <div class="right-click-menu"></div>
                        <a-menu slot="overlay">
                          <a-menu-item>
                            <a href="javascript:;" @click="downLoadFile(file)">下载</a>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                    <div>
                      <span class="file-name-position">
                        {{ file.name }}
                      </span>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
            <tr v-if="defaultData.signFile">
              <td class="tdLabel">签章文件</td>
              <td colspan="3">
                <template v-for="(file, i) in defaultData.signFile">
                  <div class="custom-list-file" :key="i" style="max-height: unset;" v-if="file.hasOwnProperty('name')">
                    <div :class="getCustomClass(file)" style="width: 120px;height: 80px;" @click="viewFile(file)">
                      <a-dropdown :trigger="['contextmenu']">
                        <div class="right-click-menu"></div>
                        <a-menu slot="overlay">
                          <a-menu-item>
                            <a href="javascript:;" @click="downLoadFile(file)">下载</a>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                    <div>
                      <span class="file-name-position">
                        {{ file.name }}
                      </span>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </table>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import { reportSendOrderApi, fileSignatureApi, reportDownloadLogApi } from '@/api/sample'
import ReportBaseInfo from '@/pages/cloud-quote/report/send/modules/ReportBaseInfo'
import { sysCommonApi, configApi } from '@/api/common'
import { Get } from '@/api/quote'
import CanvasPositionModal from '@/pages/cloud-quote/report/task/moudles/reportTemplate/CanvasPositionModal'

export default {
  name: 'FileSignatureMgr',
  components: { CanvasPositionModal, ReportBaseInfo },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      defaultData: {},

      orderData: {},

      downLoad: sysCommonApi.downLoad,

      signatureTypeList: [],
      positionList: [{ name: 'lt', title: '左上' },
        { name: 'lc', title: '左中' },
        { name: 'lb', title: '左下' },
        { name: 'ct', title: '中上' },
        { name: 'c', title: '中心' },
        { name: 'cb', title: '中下' },
        { name: 'rt', title: '右上' },
        { name: 'rc', title: '右中' },
        { name: 'rb', title: '右下' },
        { name: 'custom', title: '自定义' }
      ]
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null) {
          this.init()
        }
      }
    }
  },
  created () {
  },
  methods: {
    listConfig () {
      configApi.list().then(res => {
        if (res.code === 0) {
          const configList = res.data.filter(item => ['report', 'signet'].includes(item.groupName))
          const list = []
          configList.forEach((item, index) => {
            const info = {
              id: index + 1,
              name: item.name,
              title: item.title,
              config: false
            }
            list.push(info)
          })
          this.signatureTypeList.splice(0, this.signatureTypeList.length, ...list)
        }
      })
    },
    reload () {
      this.init()
    },
    init () {
      // this.listConfig()
      fileSignatureApi.findOne({ id: this.recordId }).then((res) => {
        if (res.code === 0) {
          this.defaultData = res.data
          if (this.defaultData.fileupload) this.defaultData.fileupload = JSON.parse(this.defaultData.fileupload)
          if (this.defaultData.signFile) this.defaultData.signFile = JSON.parse(this.defaultData.signFile)
          const signList = JSON.parse(this.defaultData.signTypeId)
          this.defaultData.signatureType = []
          signList.forEach(item => {
            this.defaultData.signatureType.push(item.name)
          })
          this.signatureTypeList.splice(0, this.signatureTypeList.length, ...signList)
          // this.signatureTypeList.forEach((item, index) => {
          //   if (this.defaultData.signatureType.includes(item.name)) {
          //     this.signatureTypeList[index] = signList.filter(i => i.name === item.name)[0]
          //   }
          // })
          if (this.defaultData.projId) {
            this.loadOrderData(this.defaultData.projId)
          }
        }
      })
    },

    loadOrderData (projId) {
      reportSendOrderApi.findReportOrder({ projId: projId }).then((res) => {
        if (res.code === 0) {
          this.orderData = res.data
        }
      })
    },
    getCustomClass (file) {
      let result = 'custom-icon-error'
      const filename = file.name
      if (filename.lastIndexOf('.') < 0) {
        return 'custom-icon-folder'
      }
      const type = filename.substr(filename.lastIndexOf('.') + 1, filename.length).toLocaleLowerCase()
      switch (type) {
        case 'doc':
          result = 'custom-icon-doc'
          break
        case 'docx':
          result = 'custom-icon-docx'
          break
        case 'jpg':
          result = 'custom-icon-jpg'
          break
        case 'png':
          result = 'custom-icon-jpg'
          break
        case 'jpeg':
          result = 'custom-icon-jpg'
          break
        case 'pdf':
          result = 'custom-icon-pdf'
          break
        case 'txt':
          result = 'custom-icon-txt'
          break
        case 'xls':
          result = 'custom-icon-xls'
          break
        case 'xlsm':
          result = 'custom-icon-xlsm'
          break
        case 'xlsx':
          result = 'custom-icon-xlsx'
          break
        case 'xlt':
          result = 'custom-icon-xlt'
          break
      }
      return result
    },
    downLoadFile (file) {
      window.location = this.downLoad + '?filePath=' + (file.url || file.fileUrl) + '&fileName=' + file.name + '&token=' + this.$store.getters.token
      if (this.reportTaskTemplate) {
        const log = {
          reportTemplateId: this.reportTaskTemplate.id,
          reportTaskId: this.reportTaskTemplate.reportTaskId,
          projId: this.projId,
          accessoryUid: file.uid,
          accessoryName: file.name,
          downloadType: '1'
        }
        reportDownloadLogApi.save(log).then(res => {
          if (res.code === 0) {
            this.countDownloadLog()
            this.downloadLogTableModal.reload()
          }
        })
      }
    },
    viewFile (file) {
      const filename = `${file.fileName || file.name}`
      const type = filename.substr(filename.lastIndexOf('.') + 1, filename.length).toLocaleLowerCase()
      if (type === 'jpg' || type === 'png' || type === 'jpeg') {
        this.previewVisible = true
        this.previewImage = `${sysCommonApi.view}/${file.fileUrl || file.url}`
      } else {
        window.open(`${sysCommonApi.viewFile}/${file.fileUrl || file.url}`)
        // const projectFullUrl = window.location.origin

        // const fileUrl = projectFullUrl + sysCommonApi.downLoadById + `?id=1&type=2&filename=${file.name}&filepath=${file.fileUrl || file.url}`
        // const url = projectFullUrl + sysCommonApi.previewJson + `/id/1/type/3?fileUrl=` + encodeURIComponent(fileUrl)
        // Get(sysCommonApi.callUrlAndSign, { url: url }).then(res => {
        //   window.open(res.data)
        // })
      }
    }
  }
}
</script>

<style scoped>

</style>
