<template>
  <a-modal id="review-form" v-bind="fullModal" v-on="fullModal">
    <iframe :src="formSrc" v-bind="form" v-if="formSrc"></iframe>
    <a-drawer v-bind="reviewDrawer" v-on="reviewDrawer">
      <div>
        <a-row align="middle">
          <a-col :span="4" class="mxtable-title">复核</a-col>
        </a-row>
        <table class="mxtable">
          <tr>
            <th rowspan="4">复核事项</th>
            <td class="tdLabel">复核时限</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td class="tdLabel">复核内容</td>
            <td colspan="3">
              <p>（1） 配置人员是否持证上岗；</p>
              <p>（2） 仪器设各是否检定/校准并在有效期内：</p>
              <p>（3） 环境条件、实验试剂质量是否符合分析方法要求；</p>
              <p>（4） 记录的填写是否真实、准确、完整、规范：</p>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">复核结果</td>
            <td colspan="3">
              <a-radio-group v-model="reviewData.reviewStatus">
                <a-radio :value="checkStandardCurveReviewStatusEnum.pass">通过</a-radio>
                <a-radio :value="checkStandardCurveReviewStatusEnum.rejected">驳回</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">复核意见</td>
            <td colspan="3">
              <a-textarea v-model="reviewData.reviewNote"></a-textarea>
            </td>
          </tr>
        </table>
        <div class="review-btn">
          <a-button @click="submitReview" type="primary">提交</a-button>
        </div>
      </div>
      <a-modal v-bind="reviewCheckModal">
        <a-input placeholder="请输入登录密码进行认证" type="password" v-model="checkPwd"/>
        <template #footer>
          <a-button @click="reviewCheckModal.ok" type="primary">提交</a-button>
        </template>
      </a-modal>
    </a-drawer>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
      <a-button @click="fullModal.showReview" type="primary">复核</a-button>
    </template>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import { checkStandardCurveApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { checkStandardCurveReviewStatusEnum } from '@/api/checkStandardCurve/checkStandardCurveConstant'

export default {
  mixins: [fullModal],

  props: {
    // ids 和 id 选择传递一个即可
    ids: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    id: {
      type: String,
      required: false,
      default: null
    },

    templateFile: {
      type: String,
      required: true
    },

    authMark: {
      type: Boolean,
      required: false,
      default: false
    },

    checkUser: {
      type: String || null,
      required: false,
      default: null
    }

  },

  data () {
    return {
      checkStandardCurveReviewStatusEnum,

      checkPwd: null,

      // 复核数据
      reviewData: {
        reviewStatus: null,
        reviewNote: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      reviewDrawer: {
        visible: false,
        width: 600,
        getContainer: false,
        closable: false,
        maskClosable: true,
        mask: true,
        wrapStyle: { position: 'absolute' },
        close: () => { this.reviewDrawer.visible = false }
      },

      reviewCheckModal: {
        title: '请先进行认证',
        centered: true,
        closed: false,
        width: 600,
        visible: false,
        show: () => { this.reviewCheckModal.visible = true },
        cancel: () => { this.reviewCheckModal.visible = false },
        ok: () => {
          if (this.checkPwd) {
            this.review()
          } else {
            this.$message.error('请输入认证密码！')
            return false
          }
        }
      },

      fullModal: {
        title: '标线配置记录复核',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },

        showReview: () => {
          this.reviewDrawer.visible = true
        }
      }
    }
  },

  computed: {
    formSrc () {
      if (this.id) {
        return `${fileTemplateUreportApi.preview}?_u=${this.templateFile}&id=${this.id}`
      } else if (this.ids.length) {
        return `${fileTemplateUreportApi.preview}?_u=${this.templateFile}&ids=${this.ids.join(',')}`
      }
      return null
    }
  },

  methods: {

    // 提交复审请求，如果需要认证则跳转到认证窗体
    submitReview () {
      if (this.checkReviewData()) {
        if (this.authMark) {
          this.reviewCheckModal.show()
        } else {
          this.review()
        }
      }
    },

    // 复核检测原始记录表
    review () {
      if (!this.ids.length && !this.id) {
        this.$message.error('无有效的数据！')
        return false
      }

      const reviewDataArr = this.ids.length && this.ids.map(item => Object.assign({ id: item }, this.reviewData)) || []
      this.id && reviewDataArr.push(Object.assign({ id: this.id }, this.reviewData))

      checkStandardCurveApi.review({ models: reviewDataArr, checkUser: this.checkUser, checkPwd: this.checkPwd }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
          this.fullModal.cancel()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 校验复核数据
    checkReviewData () {
      if (this.reviewData.reviewStatus) {
        return true
      } else {
        this.$message.error('请选择复核结果！')
        return false
      }
    },

    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/table/index2";

.review-form {
  position: absolute;
  bottom: 100px;
  width: 100%;
  background: #FF0000;
  z-idnex: 1;
}

.mxtable td p {
  margin-bottom: .5em;
}

.review-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

</style>
