<template>
  <a-card title="总费用" style="margin: 16px auto">
    <div>
      <table class="mxtable">
        <tr>
          <td :colspan="quoteInfo.subQuote ? 3 : 5">
            <a-checkbox :checked="quoteInfo.subQuote" @change="changeSubQuote">
              <b :class="quoteInfo.subQuote ? 'redLable' : ''">该方案产生的费用已包含在其他报价中</b>
            </a-checkbox>
          </td>
          <template v-if="quoteInfo.subQuote">
            <td class="tdLabel">关联报价</td>
            <td>
              <template v-if="quoteInfo.mainQuoteId">
                <a @click="planQuoteView(quoteInfo.mainQuoteId)" style="color: #722ed1; font-weight: 700">{{ quoteInfo.mainQuoteSn }} {{ quoteInfo.mainQuoteName }}</a>
              </template>
              <a @click="selectQuoteModal.show" v-if="editable"><a-icon type="edit" />关联</a>
            </td>
          </template>
        </tr>
        <tr>
          <th class="tdLabel" rowspan="3">检测费用</th>
          <td class="tdLabel">分包费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.subitemAmount }}</b> 元
            <a-button size="small" @click="itemAmountInfoModal.show" style="margin-left: 10px">详情</a-button>
          </td>
          <td class="tdLabel">检测费用</td>
          <td>
            <a-tooltip placement="top">
              <template slot="title">
                <span>套餐价格：{{ amount.groupAmount }}元</span><br />
                <span>非套餐价格：{{ amount.itemAmount }}元</span>
              </template>
              <b style="font-size: 20px; color: #ff0000">{{ amount.groupAmount }}</b> 元 (<b style="font-size: 12px; color: #ff0000">{{ amount.itemAmount }}</b> 元)
            </a-tooltip>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">上浮比例({{ parseInt(quoteInfo.quoteItemsRise * 100) }}%)</td>
          <td>
            <!-- 当折扣系数quoteItemsDiscount为100%时才可以修改上浮比例 -->
            <a-slider
              style="width: 180px"
              v-model="quoteInfo.quoteItemsRise"
              :disabled="!editable || parseInt(quoteInfo.quoteItemsDiscount * 100) !== 100"
              :step="0.01"
              :min="1.0"
              :max="10"
              :tipFormatter="(v) => `${parseInt(v * 100)}%${quoteInfo.quoteItemsDiscount < 1 ? '，要修改上浮比例，需要折扣系数为100%' : ''}`"
            />
          </td>
          <td class="tdLabel">上浮后费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.itemRiseAmount }}</b> 元
            <a-tooltip placement="top">
              <span slot="title">上浮后费用 = 检测费用 * 上浮比例</span>
              <a-icon style="margin-left: 8px" type="info-circle" />
            </a-tooltip>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">折扣系数({{ parseInt(quoteInfo.quoteItemsDiscount * 100) }}%)</td>
          <td>
            <!-- 当上浮比例quoteItemsRise为100%时才可以修改折扣系数 -->
            <a-slider
              style="width: 180px"
              v-model="quoteInfo.quoteItemsDiscount"
              :disabled="!editable || parseInt(quoteInfo.quoteItemsRise * 100) !== 100"
              :step="0.01"
              :min="lowestDiscountQuotation"
              :max="1"
              :tipFormatter="(v) => `${parseInt(v * 100)}%${quoteInfo.quoteItemsRise > 1 ? '，要修改折扣系数，需要上浮比例为100%' : ''}`"
            />
          </td>
          <td class="tdLabel">折后费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.itemDiscountAmount }}</b> 元
            <a-tooltip placement="top">
              <span slot="title">折后费用 = 参与折扣费用 * 上浮比例 * 折扣系数 + 不参与折扣费用 * 上浮比例</span>
              <a-icon style="margin-left: 8px" type="info-circle" />
            </a-tooltip>
          </td>
        </tr>
        <tr>
          <th class="tdLabel">其他费用</th>
          <td class="tdLabel">报告编制费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.compileTotalAmount }}</b> 元
          </td>
          <td class="tdLabel">其他费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.otherTotalAmount }}</b> 元
          </td>
        </tr>
        <tr>
          <th class="tdLabel" rowspan="4">总费用</th>
          <td class="tdLabel">税前费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.totalAmount }}</b> 元
          </td>
          <td class="tdLabel">税率</td>
          <td>
            <a-radio-group v-model="quoteInfo.taxes" buttonStyle="solid" :disabled="!editable" style="width: 240px">
              <a-radio-button v-for="(v, k) in taxesTypeMap" :key="k" :value="Number(k)">{{ v }}</a-radio-button>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">税后费用</td>
          <td>
            <b style="font-size: 20px; color: #ff0000">{{ amount.taxesTotalAmount }}</b> 元
            <a-tooltip placement="top">
              <span slot="title">税后费用 = 税前费用 * (1 + 税率)</span>
              <a-icon style="margin-left: 8px" type="info-circle" />
            </a-tooltip>
          </td>
          <td class="tdLabel">最终价格</td>
          <td>
            <template v-if="!quoteInfo.subQuote">
              <div :class="`required ${notNull(quoteInfo.quoteFinalAmount >= 0)}`">
                <a-input-number
                  size="large"
                  v-model="quoteInfo.quoteFinalAmount"
                  :precision="2"
                  :min="0"
                  :formatter="(value) => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                  style="width: 250px"
                  placeholder="请输入最终价格"
                  @change="handleFinalAmount"
                >
                </a-input-number>
                <a-tooltip placement="top" title="使用税后价格作为最终价格"><a-icon style="margin-left: 5px" type="dollar" theme="twoTone" @click="toFinalAmount" /></a-tooltip>
              </div>
            </template>
            <template v-else>
              <b style="font-size: 20px; color: #ff0000">{{ quoteInfo.quoteFinalAmount.toFixed(2) }}</b> 元
            </template>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">付款方式</td>
          <td>
            <template v-if="editable && !quoteInfo.subQuote">
              <div :class="`required ${notNull(quoteInfo.paymentMethod)}`">
                <a-select style="width: 250px" v-model="quoteInfo.paymentMethod" placeholder="请选择付款方式" :disabled="!editable">
                  <a-select-option v-for="(v, k) in this.paymentMethodMap" :key="k">{{ v }}</a-select-option>
                </a-select>
              </div>
            </template>
            <template v-else>
              {{ paymentMethodMap[quoteInfo.paymentMethod] }}
            </template>
          </td>
          <td class="tdLabel">付款备注</td>
          <td>
            <template v-if="editable && !quoteInfo.subQuote">
              <div :class="`required ${notNull(quoteInfo.paymentMethodRemark)}`">
                <a-input placeholder="付款备注" v-model="quoteInfo.paymentMethodRemark" :disabled="!editable" />
              </div>
            </template>
            <template v-else>
              {{ quoteInfo.paymentMethodRemark }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">业务咨询费</td>
          <td>
            <template v-if="editable && !quoteInfo.subQuote">
              <div :class="`required ${notNull(quoteInfo.middleAmount >= 0)}`">
                <a-input-number
                  size="large"
                  v-model="quoteInfo.middleAmount"
                  :precision="2"
                  :min="0"
                  :formatter="(value) => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                  style="width: 250px"
                  placeholder="请输入中间费用"
                  @change="handleMiddleAmount"
                >
                </a-input-number>
              </div>
            </template>
            <template v-else>
              <b style="font-size: 20px; color: #ff0000">{{ quoteInfo.middleAmount ? quoteInfo.middleAmount.toFixed(2) : 0 }}</b> 元
            </template>
          </td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="tdLabel">备注</td>
          <td colspan="5">
            <a-textarea placeholder="请输入备注" v-model="quoteInfo.remark" :disabled="!editable" :autosize="{ minRows: 4, maxRows: 8 }" />
          </td>
        </tr>
      </table>
    </div>
    <a-modal v-bind="selectQuoteModal" v-on="selectQuoteModal">
      <SelectQuoteList :ref="selectQuoteModal.refContent" :simple="true" checkType="radio" />
      <template #footer>
        <div style="text-align: center">
          <a-button @click="selectQuoteModal.cancel()">关闭</a-button>
          <a-button type="primary" @click="selectQuoteModal.ok()">确定</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 详情分包价格 -->
    <a-modal v-bind="itemAmountInfoModal" v-on="itemAmountInfoModal">
      <PlanContentCosts :quoteInfo="itemAmountInfoModal.data" />
      <template #footer>
        <div style="text-align: center">
          <a-button @click="itemAmountInfoModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import SelectQuoteList from './SelectQuoteList'
import PlanContentCosts from './PlanContentCosts'
import fullModal from '@/pages/common/mixins/modal/full'
import { debounce } from 'lodash'

const fullModalData = fullModal.data().fullModal
export default {
  name: 'QuoteTotalCosts',
  components: {
    SelectQuoteList,
    PlanContentCosts
  },
  props: {
    quoteInfo: {
      type: Object,
      default: () => ({})
    },
    amount: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    lowestDiscountQuotation: {
      type: Number,
      default: 0.01
    },
    costAmount: {
      type: String,
      default: '0.0'
    }
  },
  data() {
    return {
      selectQuoteModal: {
        ref: 'selectQuoteModal',
        refContent: 'selectQuoteModalContent',
        title: '关联报价选择',
        visible: false,
        width: 1080,
        centered: true,
        closable: false,
        maskClosable: false,
        destroyOnClose: true,
        show: (r) => {
          // this.selectQuoteModal.data.params = params
          this.selectQuoteModal.visible = true
        },
        ok: () => {
          this.$nextTick(() => {
            var res = this.$refs[this.selectQuoteModal.refContent].getSelected()
            if (res && res.rows && res.rows.length > 0) {
              var obj = res.rows[0]
              this.$set(this.quoteInfo, 'mainQuoteId', obj.id)
              this.$set(this.quoteInfo, 'mainQuoteSn', obj.quoteSn)
              this.$set(this.quoteInfo, 'mainQuoteName', obj.projectTitle)
              this.selectQuoteModal.cancel()
            } else {
              this.$message.error('请选择一条记录')
            }
          })
        },
        cancel: () => {
          this.selectQuoteModal.visible = false
        }
      },

      itemAmountInfoModal: Object.assign({}, fullModalData, {
        title: '分包检测项价格详情',
        data: {
          cateList: []
        },
        show: (r) => {
          var cateList = this.makeSubItemAmount()
          this.itemAmountInfoModal.data.cateList = cateList || []
          this.itemAmountInfoModal.visible = true
        },
        cancel: () => {
          this.itemAmountInfoModal.visible = false
        }
      })
    }
  },
  methods: {
    planQuoteView(id) {
      this.$router.push({ name: 'plan-quote-view-mgr', query: { quoteId: id } })
    },
    toFinalAmount() {
      this.$set(this.quoteInfo, 'quoteFinalAmount', this.amount.taxesTotalAmount)
    },
    handleFinalAmount(v) {
      this.quoteInfo.quoteFinalAmount = v
      if (!this.quoteInfo.subQuote) {
        this.validateTaxesTotalCostFinalAmount()
      }
    },
    validateTaxesTotalCostFinalAmount: debounce(
      function () {
        // 最低折扣价，也是成本价，也就是根据最低折扣系数（取自系统配置中的报价最低折扣）计算出的金额
        let costAmount = parseFloat(this.costAmount)
        // 税后费用
        let taxesTotalAmount = parseFloat(this.amount.taxesTotalAmount)
        // 最终价格
        let quoteFinalAmount = parseFloat(this.quoteInfo.quoteFinalAmount)
        let quotient = ((quoteFinalAmount - taxesTotalAmount) / taxesTotalAmount) * 100
        if (Math.abs(quotient) > 10) {
          this.$message.error(`最终价格与税后费用差距允许范围在10%，现有价格差距较大，请调节最终价格或税后费用`)
        } else if (costAmount > quoteFinalAmount) {
          this.$message.error(`本项目成本价为${costAmount}元，最终价格已低于成本价，请调节最终价格`)
        }
      },
      500,
      { leading: false, trailing: true }
    ),
    handleMiddleAmount(v) {
      this.quoteInfo.middleAmount = v
    },
    changeSubQuote() {
      if (this.editable) {
        this.quoteInfo.subQuote = !this.quoteInfo.subQuote
        this.quoteInfo.quoteFinalAmount = 0
        this.quoteInfo.middleAmount = 0
      }
    },
    makeSubItemAmount() {
      const cateList = JSON.parse(JSON.stringify(this.quoteInfo.cateList))
      for (let cidx = cateList.length - 1; cidx >= 0; cidx--) {
        const cate = cateList[cidx]
        const siteList = cate.siteList
        for (let sidx = siteList.length - 1; sidx >= 0; sidx--) {
          const site = siteList[sidx]
          const itemGroupList = site.quoteItemGroupList
          for (let gidx = itemGroupList.length - 1; gidx >= 0; gidx--) {
            const itemGroup = itemGroupList[gidx]
            if (itemGroup.quoteGroup == 1) {
              const itemList = itemGroup.itemList
              for (let idx = itemList.length - 1; idx >= 0; idx--) {
                const item = itemList[idx]
                if (item.itemType == 1) {
                  cate.itemType = 1
                  site.itemType = 1
                  itemGroup.itemType = 1
                }
              }
              if (itemGroup.itemType != 1) {
                // 去除不分包的组
                itemGroupList.splice(gidx, 1)
              }
            } else {
              if (itemGroup.itemType == 1) {
                cate.itemType = 1
                site.itemType = 1
              } else {
                // 去除不分包的项
                itemGroupList.splice(gidx, 1)
              }
            }
          }
          if (site.itemType != 1) {
            // 去除不分包的点位
            siteList.splice(sidx, 1)
          }
        }
        if (cate.itemType != 1) {
          // 去除不分包的分类
          cateList.splice(cidx, 1)
        }
      }
      return cateList
    },
    validateFinalAmount(rule, value, callback) {
      if (value !== '' && parseInt(value) < 0) {
        callback(new Error('最终价格应不小于零！'))
      } else {
        callback()
      }
    },
    notNull(v) {
      return v ? '' : 'has-error'
    }
  },
  watch: {},
  computed: {
    paymentMethodMap() {
      return { ...this.$store.state.code.cache.paymentMethod }
    },
    taxesTypeMap() {
      return { ...this.$store.state.code.cache.taxesType }
    }
  },
  mounted() {}
}
</script>
<style  lang="less">
.mxtable {
  width: 100%;
  border: 2px solid #1890ff;
  border-top: 5px solid #1890ff;
  border-collapse: unset;
  .required .ant-input,
  .required .ant-select-selection,
  .required .ant-cascader-picker {
    background-color: #fef0d0;
  }
  th {
    width: 75px;
    background: #f3f3f1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px dashed #909399;
  }
  td {
    padding: 5px;
    /*min-width: 12%;*/
    /*max-width: 22%;*/
    width: 125px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
  .tdLabel {
    width: 75px;
    background: #f3f3f1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
  .redLable {
    font-size: 16px;
    color: #ff0000;
  }
}
</style>
<style lang="less" scoped>
</style>
