<template>
  <div>
    <a-card title="相关流程">
      <a-table v-bind="table" v-on="table">
        <template slot="processImg" slot-scope="text, record">
          <a @click="showImg(record.processInstanceId)">流程图</a>
        </template>
        <template slot="processInstanceName" slot-scope="text, record">
          <a @click="flowModal.show(record.processInstanceId, record.processInstanceName)">{{ text }}</a>
        </template>
        <template slot="processStatus" slot-scope="text, record">
          <template v-if="record.endTime">
            <a-tag color="green">已完成</a-tag>
          </template>
          <template v-else-if="record.suspend">
            <a-tag color="red">已挂起</a-tag>
          </template>
          <template v-else-if="!record.suspend">
            <a-tag color="blue">进行中</a-tag>
          </template>
        </template>
        <template slot="startUserId" slot-scope="text, record">
          {{ record.startUserName || record.startUserId }}
        </template>
      </a-table>
    </a-card>
    <flow-img-modal ref="flowImgModal" />
    <a-modal v-bind="flowModal" v-on="flowModal">
      <flow-process-detail :processIdProp="flowModal.processId" :showOperator="false"></flow-process-detail>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="flowModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { projectApi } from '@/api/project'
import FlowImgModal from '@/pages/flow/module/FlowImgModal'
import fullModal from '@/pages/common/mixins/modal/full'
const fullModalData = fullModal.data().fullModal
export default {
  name: 'ProjectFlowList',
  components: {
    FlowImgModal
  },
  props: {
    projId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      projectInfo: {},
      table: {
        size: 'small',
        loading: false,
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.processInstanceId
        },
        columns: [
          { title: '流程图', dataIndex: 'processImg', width: '100px', scopedSlots: { customRender: 'processImg' } },
          { title: '标题', dataIndex: 'processInstanceName', width: '300px', scopedSlots: { customRender: 'processInstanceName' } },
          { title: '当前节点', dataIndex: 'taskName', width: '120px' },
          { title: '状态', dataIndex: 'processStatus', width: '90px', scopedSlots: { customRender: 'processStatus' } },
          { title: '提交人', dataIndex: 'startUserId', width: '120px', scopedSlots: { customRender: 'startUserId' } },
          { title: '提交日期', dataIndex: 'startTime', width: '150px' }
        ],
        pagination: false
      },

      flowModal: Object.assign({}, fullModalData,{
        ref: 'flowModal',
        visible: false,
        processId: null,
        title: '流程预览',
        width: '100%',
        centered: true,
        ok: () => { this.flowModal.visible = false },
        cancel: () => { this.flowModal.visible = false },
        show: (processId,title) => {
          this.flowModal.processId = processId
          this.flowModal.title = title
          this.flowModal.visible = true
        }
      })
    }
  },
  methods: {
    // 查询项目信息
    listProjectRelatedProcess (projId) {
      var self = this
      if (projId) {
        projectApi.listProjectRelatedProcess({ projId: projId }).then(result => {
          if (result.code === 0) {
            if (result.data) {
              self.$set(self.table, 'dataSource', result.data)
            }
          }
        })
      }
    },
    showImg (key) {
      this.$refs.flowImgModal.showImage(key)
    }
  },
  watch: {
    projId: {
      handler (nv) {
        this.listProjectRelatedProcess(nv)
      },
      immediate: true
    }
  },
  computed: {
  },
  created () {
  }
}
</script>

<style lang="less">
  .modal-container {
    height: 100%;
    background-color: #e4e7ed4d;

    .ant-modal {
      height: 100%;
      overflow: hidden;
      padding-bottom: 0px;

      .ant-modal-content {
        height: 100%;
        overflow: hidden;

        .ant-modal-body {
          overflow: auto;
          padding: 20px;
          height: ~"calc(100% - 108px)";
        }

        .ant-modal-body::-webkit-scrollbar {
          display: none;
        }

        .ant-modal-footer {
          text-align: center;
        }
      }
    }
  }
</style>
<style scoped>

</style>
