<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          申请信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          负责人：{{ subtask.sbtLeaderName }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ subtask.createTime }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          <!--            部门：XX-->
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="4">基础信息</th>
          <td class="tdLabel">任务名称</td>
          <td colspan="5">
            <div :class="`required ${notNull(subtask.sbtTitle)}`">
              <a-input placeholder="任务名称" v-model="subtask.sbtTitle"/>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">项目名称</td>
          <td colspan="3">{{ subtask.projName }}
            <a v-if="subtask.projId" @click="downloadTask" style="margin-left: 10px" title="查看下单表"><a-icon type="eye"/></a>
          </td>
          <td class="tdLabel">项目编号</td>
          <td>{{ subtask.projNo }}</td>
        </tr>
        <tr>
          <td class="tdLabel">分包价格</td>
          <td colspan="3">
            <div :class="`required ${notNull(subtask.sbtFinalAmount)}`">
              <a-input-number
                v-model="subtask.sbtFinalAmount"
                :precision="2"
                :min="1"
                :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')"
                style="width: 240px"
                placeholder="请输入最终价格"
                @change="handleFinalAmount">
              </a-input-number>
              <template v-if="sbtAmount">
                <a-tooltip placement="top">
                  <template slot="title">
                    使用推荐价格:<span style="color: red">{{ currency(sbtAmount) }}</span>
                  </template>
                  <a-icon style="margin-left: 5px" type="dollar" theme="twoTone" @click="useAmount"/>
                </a-tooltip>
              </template>
              <div style="width: 125px;float: right;">
                <UploadFile title="报价单上传" :fileList="subtask.fileQuoteList" @ok="updateQuoteFileOk"></UploadFile>
              </div>
            </div>
          </td>
          <td class="tdLabel">分包时间</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtDate)}`">
              <JDate style="width: 100%" v-model="subtask.sbtDate" placeholder="分包时间"></JDate>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">分包项目</td>
          <td colspan="5">
            <a-card>
              <span style="white-space: pre-line;">
                {{ subtask.sbtItemContent }}
              </span>
            </a-card>
            <a-button style="width: 100%; margin-top: 5px; margin-bottom: 8px" type="dashed" icon="plus" @click="addSubItem">选择分包项</a-button>
          </td>
        </tr>
        <tr>
          <th rowspan="5">分包机构</th>
          <td class="tdLabel">分包机构</td>
          <td>
            <div :class="`required ${notNull(subtask.subcontractor)}`">
              <a-input v-model="subtask.subcontractorName" :title="subtask.subcontractorName" @click="selectSubcontractor" readonly="readonly"/>
            </div>
          </td>
          <td class="tdLabel">联系人</td>
          <td>
            <div :class="`required ${notNull(subtask.subcontractorContacts)}`">
              <a-select
                v-model="subtask.subcontractorContacts"
                style="width: 100%"
                showSearch
                placeholder="分包机构联系人"
                optionFilterProp="children"
                @change="changeSubcontractorUser"
                :filterOption="filterOption">
                <a-icon title="添加联系人" type="plus-square" @click="showAddSubcontractorUser" slot="suffixIcon" />
                <a-select-option v-for="vo in this.subcontractorUserList" :key="vo.id">{{ vo.sctruName }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系电话</td>
          <td><a-input placeholder="分包机构联系电话" v-model="subtask.subcontractorContactsPhone"/></td>
        </tr>
        <tr>
          <td class="tdLabel">账号信息</td>
          <td colspan="5">
            <div :class="`required ${notNull(subtask.subcontractorAccount)}`">
              <a-input v-model="subtask.subcontractorAccount"/>
              <span style="color: #ff3a28;" v-if="subtask.subcontractor !== null && subtask.subcontractorAccount === null">*&nbsp;&nbsp;请完善该分包商账号信息</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">资质编号</td>
          <td>
            <div :class="`required ${notNull(subtask.subcontractorQualification)}`">
              <a-input placeholder="资质编号" v-model="subtask.subcontractorQualification"/>
            </div>
          </td>
          <td class="tdLabel">人员情况</td>
          <td>
            <a-radio-group v-model="subtask.sbtPerson">
              <a-radio value="满足">满足</a-radio>
              <a-radio value="不满足">不满足</a-radio>
            </a-radio-group>
          </td>
          <td class="tdLabel">设备情况</td>
          <td>
            <a-radio-group v-model="subtask.sbtDevice">
              <a-radio value="正常">正常</a-radio>
              <a-radio value="不正常">不正常</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">环境条件</td>
          <td>
            <a-radio-group v-model="subtask.sbtEnvironment">
              <a-radio value="满足">满足</a-radio>
              <a-radio value="不满足">不满足</a-radio>
            </a-radio-group>
          </td>
          <td class="tdLabel">体系运行状况</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtSystem)}`">
              <a-radio-group v-model="subtask.sbtSystem">
                <a-radio value="正常">正常</a-radio>
                <a-radio value="不正常">不正常</a-radio>
              </a-radio-group>
            </div>
          </td>
          <td class="tdLabel">检测能力</td>
          <td>
            <a-radio-group v-model="subtask.sbtTestAbility">
              <a-radio value="有">有</a-radio>
              <a-radio value="无">无</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">分包要求</td>
          <td colspan="5">
            <a-textarea placeholder="分包要求" v-model="subtask.sbtCondition" :autosize="{ minRows: 4, maxRows: 8 }"/>
          </td>
        </tr>
        <tr>
          <th rowspan="2">其他</th>
          <td class="tdLabel">附件</td>
          <td colspan="5">
            <UploadFile :fileList="subtask.fileList" @ok="uploadFileOK"></UploadFile>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">描述</td>
          <td colspan="5">
            <a-textarea placeholder="描述信息" v-model="subtask.sbtRemark" :autosize="{ minRows: 4, maxRows: 8 }"/>
          </td>
        </tr>
      </table>
    </a-row>
    <SubUserFormModal ref="subUserFormModal" @ok="addSubcontractorUserRes" :subcontractorList="subcontractorList"></SubUserFormModal>
    <AddSubItem ref="addSubItem" :cateIdItemIdList="subtask.cateIdItemIdList" @ok="addSubItemRes"></AddSubItem>
    <SubcontractorAmountList ref="subcontractorAmountList" @ok="updateSubcontractor"/>
    <DownloadTaskPreview ref="downloadTask"></DownloadTaskPreview>
    <ProjectTaskDownloadModal ref="projectTaskDownloadModal"/>
  </div>
</template>

<script>
import { subcontractorUserApi } from '@/api/project'
import SubUserFormModal from '@/pages/cloud-quote/subcontract/subcontractor-user/SubUserForm'
import UploadFile from '@/pages/common/UploadFile'
import SubcontractorAmountList from '@/pages/cloud-quote/subcontract/subcontractor/modules/SubcontractorAmountList'
import JDate from '@/components/j/JDate'
import AddSubItem from './AddSubItem'
import { currency } from '@/utils/util'
import DownloadTaskPreview from '@/pages/cloud-quote/proj-task/DownloadPreview'
import ProjectTaskDownloadModal from '@/pages/cloud-quote/project/modules/ProjectTaskDownloadModal'

export default {
  name: 'SubTaskBaseForm',
  components: {
    AddSubItem,
    UploadFile,
    JDate,
    SubUserFormModal,
    DownloadTaskPreview,
    SubcontractorAmountList,
    ProjectTaskDownloadModal
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    },
    userList: {
      type: Array,
      default: () => ([])
    },
    inFileList: {
      type: Array,
      default: () => ([])
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fetching: false,
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      subcontractorList: [],
      subcontractorUserList: [],
      // 推荐金额
      sbtAmount: null,
      // 分包项ID
      itemIds: [],
      PROJECT_SUBTASK_QUOTE_TYPE: 'FBBJ'
    }
  },
  methods: {
    currency,
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    handleFinalAmount (v) {
      this.subtask.sbtFinalAmount = v
    },

    addSubItem () {
      this.$refs.addSubItem.add(this.subtask.projId, this.subtask.id)
    },
    addSubItemRes (info) {
      this.$set(this.subtask, 'sbtItemContent', info.itemContent)
      var cateIdItemIdList = info.cateIdItemIdList
      this.$set(this.subtask, 'cateIdItemIdList', cateIdItemIdList)
      this.$set(this.subtask, 'cateItemIds', cateIdItemIdList.join(','))
    },
    selectSubcontractor () {
      var param = { projId: this.subtask.projId, itemIds: this.itemIds.join(',') }
      this.$refs.subcontractorAmountList.open(param)
    },
    updateSubcontractor (obj) {
      this.$set(this.subtask, 'subcontractor', obj.id)
      this.$set(this.subtask, 'subcontractorName', obj.sctrName)
      this.$set(this.subtask, 'subcontractorQualification', obj.sctrCma)
      this.$set(this.subtask, 'subcontractorAccount', obj.sctrAccount)
      this.$set(this, 'sbtAmount', obj.amount)
    },
    useAmount () {
      this.$set(this.subtask, 'sbtFinalAmount', this.sbtAmount)
      this.$set(this, 'sbtAmount', null)
    },
    listSubcontractorUser () {
      var self = this
      var ids = []
      if (self.subtask.subcontractor) {
        ids.push(self.subtask.subcontractor)
      }
      if (ids.length > 0) {
        var sctrIdsStr = ids.toString()
        subcontractorUserApi.list({ page: false, sctrIdsStr: sctrIdsStr }).then(result => {
          if (result.code === 0) {
            var subcontractorUserList = result.data || []
            this.subcontractorUserList = subcontractorUserList
            if (subcontractorUserList.length > 0) {
              var temp = subcontractorUserList.filter(obj => obj.id === self.subtask.subcontractorContacts)
              if (temp.length === 0) {
                // 当人员不在列表中 变更人员
                this.updateSubcontractorUser(subcontractorUserList[0])
              }
            } else {
              this.updateSubcontractorUser(null)
            }
          }
        })
      } else {
        this.$set(this, 'subcontractorUserList', [])
      }
    },
    updateSubcontractorUser (su) {
      if (su) {
        this.$set(this.subtask, 'subcontractorContacts', su.id)
        this.$set(this.subtask, 'subcontractorContactsName', su.sctruName)
        this.$set(this.subtask, 'subcontractorContactsPhone', su.sctruPhone)
      } else {
        this.$set(this.subtask, 'subcontractorContacts', '')
        this.$set(this.subtask, 'subcontractorContactsName', '')
        this.$set(this.subtask, 'subcontractorContactsPhone', '')
      }
    },
    changeSubcontractorUser (value, op) {
      var self = this
      const csrUser = self.subcontractorUserList.filter(item => value === item.id)[0]
      this.updateSubcontractorUser(csrUser)
    },
    showAddSubcontractorUser () {
      if (this.subtask.subcontractor) {
        this.$refs.subUserFormModal.addBySctrId(this.subtask.subcontractor, { addType: 2 })
      } else {
        this.$message.warning(`请先选择客户单位`)
      }
    },
    addSubcontractorUserRes (record) {
      this.subcontractorUserList.push(record)
      this.updateSubcontractorUser(record)
    },

    uploadFileOK (obj) {
      this.$set(this.subtask, 'newFileListStr', obj.newFileListStr)
      this.$set(this.subtask, 'delFileIdsStr', obj.delFileIdsStr)
    },

    updateQuoteFileOk (obj) {
      const newFileList = JSON.parse(obj.newFileListStr) // JSON.parse(obj.newFileListStr)
      if (newFileList) {
        // 增加文件类型
        newFileList.forEach(file => {
          file.relType = this.PROJECT_SUBTASK_QUOTE_TYPE
        })
      }
      this.$set(this.subtask, 'newQuoteFileListStr', JSON.stringify(newFileList))
      this.$set(this.subtask, 'delQuoteFileIdsStr', obj.delFileIdsStr)
    },

    downloadTask () {
      // this.$refs.downloadTask.show(this.subtask.projId)
      this.$refs.projectTaskDownloadModal.show(this.subtask.projId, this.subtask.projNo, this.subtask.projName)
    },
    initInfo () {
    }
  },
  watch: {
    'subtask.cateIdItemIdList': {
      handler (nv, ov) {
        if (nv) {
          var itemIds = []
          nv.forEach((it, idx) => {
            var arr = it.split('_')
            itemIds.push(arr[1])
          })
          this.itemIds = itemIds
        } else {
          this.itemIds = []
        }
      },
      immediate: true
    },
    'subtask.subcontractor': {
      handler (nv, ov) {
        this.listSubcontractorUser()
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted () {
    this.initInfo()
  },
  created () {
  }
}
</script>
<style lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
