import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import notification from 'ant-design-vue/es/notification'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Cookies from 'js-cookie'

// 创建 axios 实例 baseURL: '/api'
const service = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Credentials': true,
    // eslint-disable-next-line camelcase,no-undef
    'org': typeof (c_org) === 'undefined' ? '' : c_org
  }
})

const err = async (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      notification.error({
        message: '权限不足',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      const lastOperationTime = Vue.ls.get('lastOperation')
      let expireAt = 60 * 1000 * 30
      if (Vue.ls.get('expireAt')) {
        expireAt = Vue.ls.get('expireAt')
      }
      let time = 60 * 1000 * 30
      if (lastOperationTime != null) {
        time = new Date().getTime() - lastOperationTime
      }
      const flag = time < expireAt
      const rememberMe = Vue.ls.get('rememberMe')
      if ((rememberMe && rememberMe === '1') || flag) {
        return doRequest(error)
      } else {
        notification.error({
          message: '授权失效',
          description: '授权已失效'
        })
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    } else {
      notification.error({
        message: error.response.statusText,
        description: data.msg
      })
    }
  }
  return Promise.reject(error)
}

async function doRequest (error) {
  const data = await store.dispatch('FreshToken')
  const token = data.token
  const config = error.response.config
  config.headers[ACCESS_TOKEN] = token
  const res = await axios.request(config)
  return res.data
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers[ACCESS_TOKEN] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  if (response.headers.newtoken != null) {
    Vue.ls.set(ACCESS_TOKEN, response.headers.newtoken)
    Cookies.set(ACCESS_TOKEN, response.headers.newtoken)
  }
  Vue.ls.set('lastOperation', new Date().getTime())
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
