// 项目管理中常量
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 项目状态枚举类
export const projectStatusEnum = {
  // 已终止
  abandoned: '-1',
  // 进行中
  processing: '0',
  // 已完结
  done: '1',
  // 临时终止
  tempAbandoned: '2'
}

//  试剂等级状态选择项
export const projectStatusOptions = [
  { label: '已终止', value: projectStatusEnum.abandoned, color: 'red' },
  { label: '进行中', value: projectStatusEnum.processing, color: 'orange' },
  { label: '已完结', value: projectStatusEnum.done, color: 'green' },
  { label: '临时终止', value: projectStatusEnum.tempAbandoned, color: 'red' }
]

export const projectStatusMap = tranOptionsToMap(projectStatusOptions)
export const projectStatusDataMap = tranOptionsToDataMap(projectStatusOptions)
