<template>
  <div>
    <a-card :bordered="false" :bodyStyle="{ padding: '0' }">
      <a-row>
        <a-col span="3">
          <div class="ahmux-sub-title" style="width: 100px">付款信息</div>
        </a-col>
      </a-row>
      <a-row>
        <table class="mxtable">
          <tr>
            <td class="tdLabel">收款单位</td>
            <td>
              <span style="display: flex; align-items: center">
                <a-select v-model="purList.subcontractorId" @change="sctrChange">
                  <a-select-option v-for="vo in sctrList" :key="vo.id">{{ vo.sctrName }}</a-select-option>
                </a-select>
                <a-icon type="plus-square" style="margin-left: 5px; cursor: pointer; color: #1890ff" @click="showSubcontractor()" />
              </span>
            </td>
            <td class="tdLabel">开户行</td>
            <td>{{ purList.bankDeposit }}</td>
          </tr>
          <tr>
            <td class="tdLabel">银行账号</td>
            <td>{{ purList.bankAccount }}</td>
            <td class="tdLabel"></td>
            <td></td>
          </tr>
          <tr>
            <td class="tdLabel">合同</td>
            <td colspan="3">
              <UploadFile :fileList="purList.fileList ? purList.fileList : []" :multiple="true" @ok="uploadFileOK" />
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <a-spin :spinning="btnSpinning">
                <a-button style="width: 100%; margin-top: 3px; margin-bottom: 3px" type="primary" icon="save" @click="save">保存</a-button>
              </a-spin>
            </td>
          </tr>
        </table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { subcontractorApi } from '@/api/project'
import { resPurApi } from '@/api/quote'
import { postDataAction } from '@/api/common'
import UploadFile from '@/pages/common/upload/UploadFile'
export default {
  name: 'PayInfoForm',
  components: {
    UploadFile
  },
  props: {
    purList: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      sctrList: [],
      btnSpinning: false
    }
  },

  created() {},
  mounted() {
    // 获取供商数据
    this.listSubcontractor()
  },
  methods: {
    save() {
      const self = this
      const formData = Object.assign({}, self.purList)
      delete formData.fileList
      delete formData.sctrName
      delete formData.bankDeposit
      delete formData.bankAccount
      this.btnSpinning = true
      console.log(formData)
      postDataAction(resPurApi.savePayInfo, formData)
        .then((result) => {
          if (result.code === 0) {
            this.$message.success(`${formData.sbtTitle}操作成功`)
            this.$emit('ok', formData.id)
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((error) => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
        .finally(() => {
          this.btnSpinning = false
        })
    },
    uploadFileOK(obj) {
      this.$set(this.purList, 'newFileListStr', obj.newFileListStr)
      this.$set(this.purList, 'delFileIdsStr', obj.delFileIdsStr)
    },
    sctrChange(e) {
      let sctr = this.sctrList.find((item) => item.id === e)
      console.log(sctr)
      if (sctr) {
        this.$set(this.purList, 'sctrName', sctr.sctrName)
        this.$set(this.purList, 'bankDeposit', sctr.bankDeposit)
        this.$set(this.purList, 'bankAccount', sctr.sctrAccount || sctr.bankAccount)
      }
    },
    listSubcontractor() {
      subcontractorApi.list({ page: false }).then((res) => {
        if (res.code === 0) {
          this.sctrList = res.data
        }
      })
    },
    showSubcontractor() {
      this.$router.push({ name: 'subcontractor' })
    }
  }
}
</script>
