'use strict'

/**
 * 试剂管理 相关API
 */

import { delAction, getAction, postDataAction } from '@/api/common'

const checkReagentPre = '/ms-api/check-reagent'
const checkReagentCatePre = '/ms-api/reagent-cate'
const checkReagentCateMaterialPre = '/ms-api/reagent-cate-material'
const checkReagentRawMaterialPre = '/ms-api/check-reagent-raw-material'
const checkReagentRawMaterialInfoPre = '/ms-api/check-reagent-raw-material-info'
const checkReagentRawMaterialUsagePre = '/ms-api/check-reagent-raw-material-usage'
const checkReagentUsagePre = '/ms-api/check-reagent-usage'
const checkReagentWeighingPre = '/ms-api/check-reagent-material-weighing'
const checkReagentAmbientPre = '/ms-api/res-locationAmbientTemperatureRecord'

// 试剂类目 Api
export const checkReagentCateApi = {
  list: (param) => getAction(`${checkReagentCatePre}/list`, param),
  findById: (param) => getAction(`${checkReagentCatePre}/findById`, param),
  save: (data) => postDataAction(`${checkReagentCatePre}/save`, data),
  delete: (param) => delAction(`${checkReagentCatePre}/delete`, param)
}

// 试剂类目消耗原料 Api
export const checkReagentCateMaterialApi = {
  list: (param) => getAction(`${checkReagentCateMaterialPre}/list`, param)
}

// 试剂原料 Api
export const checkReagentRawMaterialApi = {
  list: (param) => getAction(`${checkReagentRawMaterialPre}/list`, param),
  materialList: (param) => getAction(`${checkReagentRawMaterialPre}/material-list`, param),
  findById: (param) => getAction(`${checkReagentRawMaterialPre}/findById`, param)
}

// 试剂原料明细 Api
export const checkReagentRawMaterialInfoApi = {
  list: (param) => getAction(`${checkReagentRawMaterialInfoPre}/list`, param),
  delete: (param) => getAction(`${checkReagentRawMaterialInfoPre}/delete`, param),
  selectUseNumByMaterialId: (param) => getAction(`${checkReagentRawMaterialInfoPre}/selectUseNumByMaterialId`, param)
}

// 试剂管理 Api
export const checkReagentApi = {
  list: (param) => getAction(`${checkReagentPre}/list`, param),
  findById: (id) => getAction(`${checkReagentPre}/findById/${id}`),
  save: (data) => postDataAction(`${checkReagentPre}/save`, data),
  delete: (param) => delAction(`${checkReagentPre}/delete`, param),
  recheck: (param) => postDataAction(`${checkReagentPre}/recheck`, param),
  getReagentInfo: (param) => getAction(`${checkReagentPre}/getReagentInfo`, param)
}

// 试剂原料使用记录Api
export const checkReagentRawMaterialUsageApi = {
  list: (param) => getAction(`${checkReagentRawMaterialUsagePre}/list`, param),
  findById: (id) => getAction(`${checkReagentRawMaterialUsagePre}/findById/${id}`),
  save: (data) => postDataAction(`${checkReagentRawMaterialUsagePre}/save`, data),
  delete: (param) => delAction(`${checkReagentRawMaterialUsagePre}/delete`, param)
}

// 试剂使用记录Api
export const checkReagentUsageApi = {
  list: (param) => getAction(`${checkReagentUsagePre}/list`, param),
  findById: (id) => getAction(`${checkReagentUsagePre}/findById/${id}`),
  save: (data) => postDataAction(`${checkReagentUsagePre}/save`, data),
  delete: (param) => delAction(`${checkReagentUsagePre}/delete`, param)
}

// 试剂称量记录Api
export const checkReagentWeighingApi = {
  list: (param) => getAction(`${checkReagentWeighingPre}/list`, param),
  weblist: (param) => getAction(`${checkReagentWeighingPre}/weblist`, param),
  save: (param) => postDataAction(`${checkReagentWeighingPre}/save`, param),
  delete: (param) => delAction(`${checkReagentWeighingPre}/delete`, param),
  devSelect: (param) => getAction(`${checkReagentWeighingPre}/devSelect`, param) // 称重仪器
}

// 环境管理Api
export const checkReagentAmbientListApi = {
  selectList: (param) => getAction(`${checkReagentAmbientPre}/selectList`, param),
  selectListById: (param) => getAction(`${checkReagentAmbientPre}/selectListById`, param),
  delete: (id) => delAction(`${checkReagentAmbientPre}/del/${id}`),
  selectById: (id) => getAction(`${checkReagentAmbientPre}/selectById/${id}`),
  edit: (param) => postDataAction(`${checkReagentAmbientPre}/edit`, param)
}
