import { delAction, getAction, postAction, postDataAction } from './common'

export const projectFlowKey = {
  projectFlow: 'jx_project',
  quoteCheckFlow: 'jx_quote_check',
  projectCheckFlow: 'jx_project_audit',
  projectSubcontractFlow: 'jx_fenbao'
}

export const projectApi = {
  list: (p) => getAction('/ms-api/project/list', p),
  statTableHeader: (p) => getAction('/ms-api/project/statTableHeader', p),
  get: (p) => getAction('/ms-api/project/get', p),
  getTimeLimitByProjId: (p) => getAction('/ms-api/project/getTimeLimitByProjId', p),
  add: (p) => postAction('/ms-api/project/add', p),
  edit: (p) => postAction('/ms-api/project/edit', p),
  resetNo: (p) => postAction('/ms-api/project/resetNo', p),
  finish: (p) => postAction('/ms-api/project/finish', p),
  del: (id) => delAction('/ms-api/project/del/' + id, {}),
  abandon: (p) => postAction('/ms-api/project/abandon', p),
  tempAbandon: (p) => postAction('/ms-api/project/temp-abandon', p),
  recover: (p) => postAction('/ms-api/project/recover', p),
  goBack: (p) => postAction('/ms-api/project/goBack', p),
  getByProjNo: (projNo) => getAction('/ms-api/project/getByProjNo/' + projNo, {}),
  listQuoteType: () => getAction('/ms-api/comm-dict/list', { dictCode: 'quote_type' }),
  listProcess: (p) => getAction('/ms-api/project/list-process', p),
  viewQuoteAgg: '/ms-api/project/viewQuoteAgg',
  listFiles: '/ms-api/project/list-files',
  listProjectFiles: (p) => getAction('/ms-api/project/list-project-files', p),
  cache: {
    progressList: null,
    progressMap: null
  },
  loadProgressList () {
    const self = this
    getAction('/ms-api/comm-dict/list', { dictCode: 'proj_progress' }).then(result => {
      if (result.code === 0) {
        const progressList = result.data
        if (progressList) {
          self.cache.progressList = progressList
          self.cache.progressMap = {}
          for (var idx in progressList) {
            self.cache.progressMap[progressList[idx].itemValue] = progressList[idx].itemText
          }
        }
      }
    })
  },
  getProgressName (code) {
    var self = this
    if (self.cache.progressMap) {
      return self.cache.progressMap[code]
    }
    return ''
  },
  listForFlow: (p) => getAction('/ms-api/project/list-for-flow', p),
  listFlowFileProject: (p) => getAction('/ms-api/project/list-flow-file-project', p),
  listProjectRelatedProcess: (p) => getAction('/ms-api/project/list-project-related-process', p),
  generateContractReview: '/ureport/preview?_u=DB:合同评审.ureport.xml',
  applyData: (p) => postDataAction('/ms-api/project/applyData', p),
  getOnlineRecordProjectData: (p) => getAction('/ms-api/project/getOnlineRecordProjectData', p), // 采样管理中在线记录中获取项目信息
  getOnlineRecordSiteData: (p) => getAction('/ms-api/project/getOnlineRecordSiteData', p), // 采样管理中在线记录中获取分类、点位、频次信息
  getOnlineRecordSampleData: (p) => getAction('/ms-api/project/getOnlineRecordSampleData', p), // 采样管理中在线记录中获取样品信息
  getOnlineRecordFrequencyData: (p) => getAction('/ms-api/project/getOnlineRecordFrequencyData', p), // 采样管理中在线记录中获取频次信息
  saveOnlineRecord: (p) => postDataAction('/ms-api/project/saveOnlineRecord', p), // 采样管理中在线记录中保存数据
  getSamplers: (p) => getAction('/ms-api/project/getSamplers', p), // 查询所有采样员和采样负责人以及当前项目下各个二级分类-点位下的采样人、复核人、审核人
  saveSamplerSign: (p) => postDataAction('/ms-api/project/saveSamplerSign', p) // 采样管理中在线记录中保存采样人、复核人、审核人数据
}

export const itemCateMethodApi = {
  add: (p) => postDataAction(`/ms-api/itemCateMethod/add`, p),
  selectMetchod: (p) => getAction(`/ms-api/itemCateMethod/selectMetchod`, p)
}

const anonProjectPre = '/ms-api/anon-project'
export const anonProjectApi = {
  getQuote: (p) => getAction(`${anonProjectPre}/get-quote`, p),
  getProject: (p) => getAction(`${anonProjectPre}/get-project`, p),
  listFileTemplate: (p) => getAction(`${anonProjectPre}/list-file-template`, p),
  listProjectMsgLog: (p) => getAction(`${anonProjectPre}/list-project-msg-log`, p)
}

export const projAuditApi = {
  get: (p) => getAction('/ms-api/proj-audit/get', p),
  getByProjId: (p) => getAction('/ms-api/proj-audit/getByProjId', p),
  addOrEdit: (p) => postAction('/ms-api/proj-audit/addOrEdit', p),
  queryActByProjecy: (p) => getAction('/ms-api/proj-audit/queryActByProjecy', p)
}

export const projDictApi = {
  projLevelList: [{ id: '1', name: '普通' }, { id: '2', name: '重要' }, { id: '3', name: '紧急' }],
  invoiceList: [{ id: '0', name: '不开' }, { id: '1', name: '专票' }, { id: '2', name: '普票' }],
  sampleSourceList: [{ id: '1', name: '采样' }, { id: '2', name: '送样' }]
}

export const projWorktableApi = {
  countProject: (p) => getAction('/ms-api/proj-worktable/countProject', p)
}

export const projTaskApi = {
  getByProjId: (id) => getAction('/ms-api/proj-task/getByProjId/' + id, {}),
  getTaskByProjId: (p) => getAction('/ms-api/proj-task/getTaskByProjId', p),
  addBefore: (p) => postAction('/ms-api/proj-task/addBefore', p),
  addOrGetTask: (p) => postAction('/ms-api/proj-task/addOrGetTask', p),
  addAfter: (p) => postAction('/ms-api/proj-task/addAfter', p),
  add: (p) => postAction('/ms-api/proj-task/add', p),
  edit: (p) => postAction('/ms-api/proj-task/edit', p),
  del: (id) => delAction('/ms-api/proj-task/del/' + id, {}),
  listProjectSiteDayFrequency: (p) => getAction('/ms-api/proj-task/listProjectSiteDayFrequency', p),
  download: '/ms-api/proj-task/download',
  addArchiveConfig: (p) => postAction('/ms-api/archiveConfig/add', p),
  getChildInfoById: (id) => getAction(`/ms-api/archiveConfig/childInfoById/${id}`),
  listInfo: (p) => getAction('/ms-api/archiveConfig/listInfo/',p),
  delete: (p) => postAction(`/ms-api/archiveConfig/delete/${p}`),
}

export const projTaskItemApi = {
  add: (p) => postAction('/ms-api/proj-task-item/add', p),
  list: (p) => getAction('/ms-api/proj-task-item/list', p),
  edit: (p) => postAction('/ms-api/proj-task-item/edit', p),
  editBatch: (p) => postDataAction('/ms-api/proj-task-item/editBatch', p),
  del: (p) => delAction('/ms-api/proj-task-item/del', p),
  addCate: (p) => postAction('/ms-api/proj-task-item/addCate', p),
  delItem: (p) => delAction('/ms-api/proj-task-item/delItem', p),
  listSubitemByProjId: (p) => getAction('/ms-api/proj-task-item/listSubitemByProjId', p),
  updateItemSub: (p) => postAction('/ms-api/proj-task-item/updateItemSub', p),
  updateStandard: (p) => postAction('/ms-api/proj-task-item/updateStandard', p),
  delStandard: (p) => delAction('/ms-api/proj-task-item/delStandard', p),
  detailList: (p) => getAction('/ms-api/proj-task-item/detail-list', p),
  addOrEditAndDelOld: (p) => postDataAction('/ms-api/proj-task-item/addOrEditAndDelOld', p),
  listCateItem: (p) => getAction('/ms-api/proj-task-item/list-cate-item', p),
  reomveSiteGroup: (p) => postDataAction('/ms-api/proj-task-item/reomveSiteGroup', p)
}

export const projTmplApi = {
  add: (p) => postAction('/ms-api/proj-template/add', p),
  list: (p) => getAction('/ms-api/proj-template/list', p),
  edit: (p) => postAction('/ms-api/proj-template/edit', p),
  del: (p) => delAction('/ms-api/proj-template/del', p),
  impQuote: (p) => postAction('/ms-api/proj-template/impQuote', p)
}

export const projCheckSiteApi = {
  add: (p) => postAction('/ms-api/check-site/add', p),
  del: (p) => delAction('/ms-api/check-site/del', p),
  edit: (p) => postAction('/ms-api/check-site/edit', p),
  listCheckSiteByProjId: (id) => getAction('/ms-api/check-site/listCheckSiteByProjId/' + id, {}),
  listCheckSiteByTaskId: (id) => getAction('/ms-api/check-site/listCheckSiteByTaskId/' + id, {}),
  listSiteByProjId: (id) => getAction('/ms-api/check-site/listSiteByProjId/' + id, {}),
  listProjSiteCateByProjId: (p) => getAction('/ms-api/check-site/list-proj-site-cate', p),
  getSiteLegendConfig: (p) => getAction('/ms-api/check-site/get-site-legend-config', p),
  saveSiteLegendConfig: (p) => postAction('/ms-api/check-site/save-site-legend-config', p)
}

export const projContractApi = {
  list: (p) => getAction('/ms-api/proj-contract/list', p),
  get: (p) => getAction('/ms-api/proj-contract/get', p),
  add: (p) => postAction('/ms-api/proj-contract/add', p),
  edit: (p) => postAction('/ms-api/proj-contract/edit', p),
  del: (p) => delAction('/ms-api/proj-contract/del/' + p.id + '/' + p.projId, {}),
  getContractProcessKey: () => getAction('/ms-api/proj-contract/get-contract-process-key', {}),
  downloadContract: '/ms-api/proj-download/downloadContract',
  getProjectCustomer: (p) => getAction('/ms-api/proj-contract/get-project-customer', p),
  getProjectContract: (p) => getAction('/ms-api/proj-contract/get-project-contract', p),
  copy: (p) => getAction('/ms-api/proj-contract/copy', p)
}

export const projPlanApi = {
  list: (p) => getAction('/ms-api/proj-plan/list', p),
  getDefaultPlan: (p) => getAction('/ms-api/proj-plan/getDefaultPlan', p),
  getPlanOrDefaultPlan: (p) => getAction('/ms-api/proj-plan/getPlanOrDefaultPlan', p),
  getByProjId: (p) => getAction('/ms-api/proj-plan/getByProjId', p),
  addOrEdit: (p) => postAction('/ms-api/proj-plan/addOrEdit', p),
  del: (id) => delAction('/ms-api/proj-plan/del/' + id, {})
}
export const projProgressUserApi = {
  list: (p) => getAction('/ms-api/proj-progress-user/list', p),
  addOrEdit: (p) => postAction('/ms-api/proj-progress-user/addOrEdit', p),
  getUserName: (p) => getAction('/ms-api/proj-progress-user/getUserName', p)
}

export const projReceiptApi = {
  list: (p) => getAction('/ms-api/proj-receipt/list', p),
  get: (id) => getAction('/ms-api/proj-receipt/get/' + id, {}),
  add: (p) => postAction('/ms-api/proj-receipt/add', p),
  edit: (p) => postAction('/ms-api/proj-receipt/edit', p),
  del: (id) => delAction('/ms-api/proj-receipt/del/' + id, {}),
  receiptConfirm: (p) => postAction('/ms-api/proj-receipt/receiptConfirm', p),
  invoiceConfirm: (p) => postAction('/ms-api/proj-receipt/invoiceConfirm', p),
  getReceiptProcessKey: () => getAction('/ms-api/proj-receipt/get-receipt-process-key')
}

export const projReceiptPlanApi = {
  listPlanByProjId: (p) => getAction('/ms-api/proj-receipt-plan/listPlanByProjId', p),
  add: (p) => postAction('/ms-api/proj-receipt-plan/add', p),
  edit: (p) => postAction('/ms-api/proj-receipt-plan/edit', p),
  del: (id) => delAction('/ms-api/proj-receipt-plan/del/' + id, {})
}

export const subcontractorApi = {
  list: (p) => getAction('/ms-api/proj-subcontractor/list', p),
  get: (p) => getAction('/ms-api/proj-subcontractor/get', p),
  add: (p) => postAction('/ms-api/proj-subcontractor/add', p),
  edit: (p) => postAction('/ms-api/proj-subcontractor/edit', p),
  del: (id) => delAction('/ms-api/proj-subcontractor/del/' + id, {}),
  getCheck: (p) => getAction('/ms-api/proj-subcontractor/getCheck', p),
  addOrEditCheck: (p) => postAction('/ms-api/proj-subcontractor/addOrEditCheck', p)
}

export const subcontractorUserApi = {
  list: (p) => getAction('/ms-api/proj-subcontractor-user/list', p),
  get: (p) => getAction('/ms-api/proj-subcontractor-user/get', p),
  addOrEdit: (p) => postAction('/ms-api/proj-subcontractor-user/addOrEdit', p),
  dels: (p) => delAction('/ms-api/proj-subcontractor-user/dels', p)
}

export const projSubcontractTaskApi = {
  list: (p) => getAction('/ms-api/proj-subcontract-task/list', p),
  get: (p) => getAction('/ms-api/proj-subcontract-task/get', p),
  getByProjId: (p) => getAction('/ms-api/proj-subcontract-task/getByProjId', p),
  getReportFileById: (p) => getAction('/ms-api/proj-subcontract-task/getReportFileById', p),
  add: (p) => postAction('/ms-api/proj-subcontract-task/add', p),
  edit: (p) => postAction('/ms-api/proj-subcontract-task/edit', p),
  listSample: (p) => getAction('/ms-api/proj-subcontract-task/listSample', p),
  saveSample: (p) => postDataAction('/ms-api/proj-subcontract-task/saveSample', p),
  del: (id) => delAction('/ms-api/proj-subcontract-task/del/' + id, {}),
  listCheck: (p) => getAction('/ms-api/proj-subcontract-task/listCheck', p),
  listSiteCateByProjId: (p) => getAction('/ms-api/proj-subcontract-task/listSiteCateByProjId', p),
  listCateSubItemByProjId: (p) => getAction('/ms-api/proj-subcontract-task/listCateSubItemByProjId', p),
  listInSubItemByTaskItemIds: (p) => getAction('/ms-api/proj-subcontract-task/listInSubItemByTaskItemIds', p),
  listSubitemListBySbtId: (p) => getAction('/ms-api/proj-subcontract-task/listSubitemListBySbtId', p),
  listNotSubItem: (p) => getAction('/ms-api/proj-subcontract-task/listNotSubItem', p),
  listSubcontractorAndPriceByItem: (p) => getAction('/ms-api/proj-subcontract-task/listSubcontractorAndPriceByItem', p)
}

export const projSubcontractTaskSampleApi = {
  list: (p) => getAction('/ms-api/proj-subcontract-task-sample/list', p),
  itemList: (p) => getAction('/ms-api/proj-subcontract-task-sample/itemList', p),
  itemListNew: (p) => getAction('/ms-api/proj-subcontract-task-sample/itemListNew', p),
  save: (data) => postDataAction('/ms-api/proj-subcontract-task-sample/save', data),
  saveItemResult: (data) => postDataAction('/ms-api/proj-subcontract-task-sample/saveItemResult', data)
}

export const projSubcontractItemMethodApi = {
  list: (p) => getAction('/ms-api/proj-subcontract-item-method/list', p),
  getItemInfoByTaskId: (taskId) => getAction('/ms-api/proj-subcontract-item-method/getItemInfoByTaskId/' + taskId),
  save: (data) => postDataAction('/ms-api/proj-subcontract-item-method/save', data)
}

export const projCountApi = {
  countMonthlyProjNumByYear: (p) => getAction('/ms-api/proj-count/countMonthlyProjNumByYear', p)
}
export const projDownloadApi = {
  downloadQuotePlan: '/ms-api/proj-download/downloadQuotePlan',
  downloadProjectRecord: '/ms-api/proj-download/downloadProjectRecord'
}

export const projDelayApi = {
  list: (param) => getAction(`/ms-api/proj-delay/list`, param),
  remindList: (param) => getAction(`/ms-api/proj-delay/remind-list`, param),
  findOne: (param) => getAction('/ms-api/proj-delay/findOne', param),
  saveByFlow: (data) => postDataAction(`/ms-api/proj-delay/save-by-flow`, JSON.stringify(data))
}

export const evnProjApi = {
  findOne: (param) => getAction('/ms-api/env_project/project-base-info', param),
  baseUpsert: (param) => postAction('/ms-api/env_project/base-upsert', param)
}

export const projPaymentRegistrationApi = {
  collectList: (param) => getAction('/ms-api/collect/collectList', param), // 进款列表显示
  sortList: (param) => getAction('/ms-api/collect/sortList', param), // 进款分类列表显示
  childrenList: (param) => getAction('/ms-api/collect/childrenList', param), // 公司下属项目显示
  collectRe: (param) => postDataAction('/ms-api/collect/collectRe', param), // 项目进款登记
  invalidCollect: (param) => getAction('/ms-api/collect/invalidCollect', param), // 作废进款记录
  findIncome: (param) => getAction('/ms-api/collect/findIncome', param), // 根据id查询一条进款记录
  modelDataDealForFlow: (param) => postDataAction('/ms-api/collect/modelDataDealForFlow', param), // 分配金额到项目，新增收付款项
  updateStatus: (param) => getAction('/ms-api/collect/updateStatus', param), // 修改状态
  getFlowStart: (param) => getAction('/ms-api/collect/getFlowStart', param) // 获取相同委托单位下有收款流程的记录
}
