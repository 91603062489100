import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'
// mock
// import './mock'

// 引入iconfont字体库
import '@/assets/iconfont/iconfont.css'

import bootstrap from './core/bootstrap'
import './core/use'
import './permission' // permission control
import './utils/filter' // global filter
import { optionalChaining } from './utils/util'
import VueQriously from 'vue-qriously'
import VueAMap from 'vue-amap'
import FlowProcessDetail from '@/pages/flow/FlowProcessDetail'
import BusinessListModal from '@/pages/cloud-quote/bj-business/BusinessListModal.vue'
import 'animate.css'
// import { LMap, LTileLayer, LMarker, LatLng } from 'vue2-leaflet'
// import { Icon } from 'leaflet'
// import 'leaflet/dist/leaflet.css'
// css common loader
import './assets/less/common/layout.less'
import { timeFormat } from '@/pages/common/mixins/date'
import moment from 'moment'

Vue.config.productionTip = false
Vue.component(FlowProcessDetail.name, FlowProcessDetail)
Vue.component(BusinessListModal.name, BusinessListModal)
Vue.prototype.$$ = optionalChaining

// mount axios Vue.$http and this.$http
Vue.use(VueAxios)

Vue.use(VueQriously)
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'b44f3b09dafaecd6f38d97f57b74f146',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.InfoWindow', 'AMap.ControlBar'],
  v: '1.4.15',
  uiVersion: '1.0.11' // 版本号
})
// AMap 秘钥
window._AMapSecurityConfig = {
  securityJsCode: '493d35af41190a6742aacfb5f0fb5ef9'
}

// Vue.component('l-map', LMap)
// Vue.component('l-tile-layer', LTileLayer)
// Vue.component('l-marker', LMarker)
//
// delete Icon.Default.prototype._getIconUrl
//
// Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// })

// fix moment format
moment.prototype.toISOString = function() {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format(timeFormat)
  }
}

new Vue({
  router,
  store,
  created () {
    bootstrap()
  },
  mounted () {
    store.dispatch('SysConfig')
  },
  render: h => h(App)
}).$mount('#app')
