<template>
  <div class="subcontract">
    <a-card :key="`${item.projId}_${index}`" v-bind="projCard" v-for="(item,index) in Object.values(projSampleGroup)">
      <template #title>{{ item.projName }}</template>
      <a-table :bordered="true" :columns="detailTableColumns" :dataSource="item.details" :pagination="false" rowKey="id">

        <template #taskId="data,record">
          <template v-if="projSubcontractTaskMap[item.projId]&&projSubcontractTaskMap[item.projId].length">
            <a-select
              v-bind="taskSelect"
              v-model="record.taskId">
              <a-select-option
                v-for="(taskItem,taskIndex) in projSubcontractTaskMap[item.projId]"
                :key="`${taskItem.id}_${taskIndex}`"
                :value="taskItem.id">
                {{ taskItem.sbtTitle }}【{{ taskItem.sbtDate }}】
              </a-select-option>
            </a-select>
            <a-tooltip>
              <template #title>应用到其他样品上</template>
              <a @click="applyAllTask(record.taskId,item.details)">
                <a-icon :style="{marginLeft:'12px'}" type="copy"></a-icon>
              </a>
            </a-tooltip>
          </template>
          <template v-else>
            <span class="no-data">暂未发起分包任务</span>
          </template>
        </template>

        <template #action="data,record,index">
          <a-popconfirm :title="`确定要移除这样品吗?`" @confirm="()=>{removeSample(record,index,item)}">
            <a>移除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { groupBy } from 'lodash'
import { projSubcontractTaskApi, projSubcontractTaskSampleApi } from '@/api/project'

export default {
  props: {
    sampleInfos: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  data () {
    return {
      projSampleGroup: {},

      sampleInfoList: JSON.parse(JSON.stringify(this.sampleInfos)),

      projSubcontractTaskMap: {},

      projCard: {
        type: 'inner',
        size: 'small',
        style: { marginBottom: '16px' }
      },

      taskSelect: {
        allowClear: true,
        placeholder: '请选择分包任务',
        style: { width: '400px' }
      },

      detailTableColumns: [
        {
          title: '样品编号',
          dataIndex: 'id',
          width: 150
        },
        {
          title: '检测项',
          dataIndex: 'itemNameStr',
          width: 380
        },
        {
          title: '分包任务',
          dataIndex: 'taskId',
          scopedSlots: { customRender: 'taskId' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 70
        }
      ]
    }
  },

  watch: {
    sampleInfos: {
      immediate: true,
      deep: true,
      handler (val) {
        val && val.length && this.handleSampleInfoList(this.sampleInfoList)
      }

    }
  },
  methods: {
    handleSampleInfoList (sampleInfoList = []) {
      const projGroupTemp = groupBy(sampleInfoList, 'projId')
      const projIds = Object.keys(projGroupTemp) || []
      projIds.length && this.listSaveData(projIds)
      projIds.length && this.listSubcontractTask(projIds)

      const projGroup = projIds.reduce((acc, key) => {
        Array.prototype.push.apply((acc[key] || (acc[key] = { projId: key, projName: projGroupTemp[key][0].projName, details: [] })).details, projGroupTemp[key])
        return acc
      }, {}) || {}

      this.projSampleGroup = Object.assign({}, projGroup)
    },

    listSaveData (projIds = []) {
      projSubcontractTaskSampleApi.list({ projIds, page: false }).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            const dataMap = res.data.reduce((acc, item) => {
              acc[item.sampleId] = item
              return acc
            }, {})
            this.sampleInfoList.forEach(item => {
              if (dataMap[item.id]) {
                this.$set(item, 'taskId', dataMap[item.id].taskId)
                this.$set(item, 'taskRefId', dataMap[item.id].id)
              }
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    listSubcontractTask (projIds = []) {
      projSubcontractTaskApi.list({ projIds: projIds, sbtStatus: '1', page: false }).then(res => {
        if (res.code === 0) {
          this.projSubcontractTaskMap = res.data.length && groupBy(res.data, 'projId') || {}
        } else {
          this.$message.error(`获取项目分包任务出错，错误信息：${res.msg}`)
        }
      })
    },

    applyAllTask (taskId, list) {
      list.forEach(item => {
        if (item.hasOwnProperty('taskId')) {
          item.taskId = taskId
        } else {
          this.$set(item, 'taskId', taskId)
        }
      })
    },

    removeSample (data, index, item) {
      item.details.splice(index, 1)
      const cIndex = this.sampleInfoList.findIndex(item => item.id === data.id)
      cIndex !== -1 && this.sampleInfoList.splice(cIndex, 1)
    },

    save () {
      const filterData = this.sampleInfoList.filter(item => item.taskId) || []
      if (filterData.length === 0) {
        this.$message.error('暂无需要提交的数据')
        return false
      }

      // 冒泡保存中事件
      this.$emit('saving')
      const saveData = filterData.map(item => { return { sampleId: item.id, taskId: item.taskId, id: item.taskRefId } })
      projSubcontractTaskSampleApi.save(saveData).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
  @import "_index.less";
</style>
