<template>
  <div>
    <a-card v-bind="contentCard">
      <SampleInfoTable v-bind="SampleInfoTable" :scroll="scrollProp" @selectedRows="handleSelectedRow"></SampleInfoTable>
      <a-card>
        <div style="height: 180px">
          <a-row :gutter="[8,8]">
            <template v-for="(item,idx) in selectedRows">
              <a-col span="3" :key="idx">
                <a-tag color="blue">{{ item.id }} {{ item.itemNameStr }}</a-tag>
                <a @click="selectedRows.splice(idx,1)">
                  <a-icon type="delete"></a-icon>
                </a>
              </a-col>
            </template>
          </a-row>
        </div>
      </a-card>
      <!--      <a-row :gutter="16">-->
      <!--        <a-col :span="4">-->
      <!--          <div :style="{maxHeight:getMaxHeight(),overflow:'auto'}">-->
      <!--            <a-input-search placeholder="..." style="margin-bottom: 8px" @change="handleTreeSearchChange"/>-->
      <!--            <a-tree v-bind="itemTreeProps" v-on="itemTreeProps.action">-->
      <!--              <template #title="{title}">-->
      <!--                <span v-if="searchValue&&title.indexOf(searchValue) > -1">-->
      <!--                  {{ title.substr(0, title.indexOf(searchValue)) }}-->
      <!--                  <span style="color: #FF5500">{{ searchValue }}</span>-->
      <!--                  {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}-->
      <!--                </span>-->
      <!--                <span v-else>{{ title }}</span>-->
      <!--              </template>-->
      <!--            </a-tree>-->
      <!--          </div>-->
      <!--        </a-col>-->

      <!--        <a-col :span="20">-->
      <!--          <SampleInfoTable v-bind="SampleInfoTable" :scroll="scrollProp" @selectedRows="handleSelectedRow"></SampleInfoTable>-->
      <!--        </a-col>-->
      <!--      </a-row>-->
    </a-card>
  </div>
</template>

<script>
import list from '@/pages/common/mixins/list'
import { projectStatusEnum } from '@/api/project/projectConstants'
import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'
import { sampleInfoStatusEnum } from '@/api/sampleInfo/sampleInfoConstant'

export default {
  name: 'SelectSampleList',
  components: {
    SampleInfoTable
  },
  mixins: [list],
  props: {
    subtaskId: {
      type: Number,
      default: null
    },
    itemIds: { type: Array, required: false, default: () => ([]) },
    projId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      fetching: false,
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      // 推荐金额
      sbtAmount: null,
      selectedRows: [],
      SampleInfoTable: {
        ref: 'sampleTable',
        columns: [
          { title: '样品编号', dataIndex: 'id', width: 180, scopedSlots: { customRender: 'id' } },
          { title: '检测项', dataIndex: 'itemNameStr', width: 200, scopedSlots: { customRender: 'itemNameStr' } },
          { title: '类型', dataIndex: 'type', width: 80, scopedSlots: { customRender: 'type' } },
          { title: '样品来源', dataIndex: 'source', width: 80, scopedSlots: { customRender: 'source' } },
          { title: '分包样品', dataIndex: 'itemType', width: 80, scopedSlots: { customRender: 'itemType' } },
          { title: '采样点位', dataIndex: 'siteName', width: 120, scopedSlots: { customRender: 'siteName' } },
          { title: '采/送样人', dataIndex: 'sampleUserName', width: 100, scopedSlots: { customRender: 'sampleUserName' } },
          { title: '采/制样时间', dataIndex: 'sampleTime', width: 200, scopedSlots: { customRender: 'sampleTime' } },
          { title: '份数', dataIndex: 'num', width: 80, scopedSlots: { customRender: 'num' } },
          { title: '有效期', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
          { title: '存储位置', dataIndex: 'locationName', width: 100, scopedSlots: { customRender: 'locationName' } },
          { title: '留样', dataIndex: 'reserved', width: 80, scopedSlots: { customRender: 'reserved' } },
          { title: '当前状态', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
        ],

        neStatus: '0',
        blurId: null,
        projNo: null,
        projId: this.projId,
        projName: null,
        status: sampleInfoStatusEnum.storaged,
        source: null,
        cateId: null,
        sampleUsers: [],
        siteName: null,
        reserved: null,
        pickupApplyUsers: [],
        itemIds: this.itemIds || [],
        itemType: null,
        expireSoonHour: null,
        projStatuses: [projectStatusEnum.processing, projectStatusEnum.done],
        joinOrQuery: {
          projIds: null,
          sampleIds: null
        }
      },
      searchValue: '',
      itemTreeProps: {
        treeData: [],
        checkable: true,
        expandedKeys: [],
        checkedKeys: [],
        autoExpandParent: true,

        action: {
          check: (checkedKeys, item) => {
            this.itemTreeProps.checkedKeys.splice(0, this.itemTreeProps.checkedKeys.length, ...checkedKeys)
            this.queryParam.itemIds.splice(0, this.queryParam.itemIds.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.itemIds.splice(0, this.queryParam.itemIds.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length, ...(expandedKeys || []))
            this.itemTreeProps.autoExpandParent = false
          },

          clear: () => {
            this.itemTreeProps.checkedKeys.splice(0, this.itemTreeProps.checkedKeys.length)
            this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length)
          }
        }
      }
    }
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    getSelected () {
      var res = []
      this.selectedRows.forEach(item => {
        res.push({ taskId: this.subtaskId, sampleId: item.id, itemName: item.itemNameStr })
      })
      return res
    },
    handleSelectedRow (list) {
      this.selectedRows.splice(0, this.selectedRows.length, ...(list || []))
    },
    // 树查询
    handleTreeSearchChange (e) {
      const value = e.target.value
      this.searchValue = value
      if (value) {
        const itemExpandedKeys = this.itemTreeDataList.map(item => {
          if (item.title.indexOf(value) > -1) {
            return this.getParentKey(item.key, this.itemTreeProps.treeData)
          }
          return null
        }).filter((item, i, self) => item && self.indexOf(item) === i)
        this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length, ...(itemExpandedKeys || []))
        this.itemTreeProps.autoExpandParent = true
      } else {
        this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length)
        this.itemTreeProps.autoExpandParent = false
      }
    },
    getMaxHeight () {
      return document.documentElement.clientHeight - 400 + 'px'
    },
    initInfo () {
      // this.listSubcontractorUser()
    }
  },
  watch: {},
  computed: {},
  mounted () {
    this.initInfo()
  },
  created () {
  }
}
</script>
<style lang="less">
.mxtable {
  width: 100%;
  border: 2px solid #1890FF;
  border-top: 5px solid #1890FF;
  border-collapse: unset;
  margin-top: -1px;

  .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
    background-color: #FEF0D0;
  }

  th {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px dashed #909399;
  }

  td {
    padding: 5px;
    /*min-width: 12%;*/
    /*max-width: 22%;*/
    width: 125px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }

  .tdLabel {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
}
</style>
<style lang="less" scoped></style>
