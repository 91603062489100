<template>
  <div class="utabs" style="height: 100%">
    <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
      <a-tab-pane :closable="false" :key="idx" :tab="obj.templateName" style="height: 100%" v-for="(obj,idx) of templateList">
        <template v-if="showAll">
          <template v-if="sampleDayList">
            <a-tabs
              v-model="sampleDayIdx"
              :key="idx+'days'"
              size="small"
              style="height: 100%;padding: 0;margin: 0;overflow: hidden;"
              tabPosition="left"
              type="line">
              <a-tab-pane :closable="false" :key="dayIdx" :tab="dayInfo.day" style="height: 100%" v-for="(dayInfo,dayIdx) in sampleDayList">
                <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
              </a-tab-pane>
            </a-tabs>
          </template>
        </template>
        <template v-else>
          <a-tabs v-model="curCateIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
            <a-tab-pane :closable="false" :key="cateIdx" :tab="cate.cateName" style="height: 100%" v-for="(cate,cateIdx) of project.hasCateList">
              <template v-if="Object.keys(sampleDayMap).length">
                <a-tabs
                  :key="idx+'days'"
                  v-model="sampleDayIdx"
                  size="small"
                  style="height: 100%;padding: 0;margin: 0;overflow: hidden;"
                  tabPosition="left"
                  type="line">
                  <a-tab-pane :closable="false" :key="dayIdx" :tab="dayInfo.day" style="height: 100%" v-for="(dayInfo,dayIdx) in sampleDayMap[cate.cateId]">
                    <a-row style="height:100%;">
                      <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
                      <Watermark/>
                    </a-row>
                  </a-tab-pane>
                </a-tabs>
              </template>
            </a-tab-pane>
          </a-tabs>
        </template>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { projectApi } from '@/api/project'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import Watermark from '@/pages/common/watermark/Watermark'
import { tsSampleinfoApi } from '@/api/sample'

import { projectStatusEnum } from '@/api/project/projectConstants'

export default {
  name: 'SampleFlowDownloadPreview',
  components: {
    Watermark
  },
  props: {
    params: { type: Object, default: () => ({}) },
    showAll: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      templateParam: {
        page: false,
        templateType: fileTemplateUreportTypeEnum.sampleFlow,
        sampleData: {}
      },
      sampleDayList: null,
      sampleDayMap: null,
      curIdx: 0,
      curCateIdx: 0,
      sampleDayIdx: 0,
      templateList: [],
      url: '',
      project: {}
    }
  },
  watch: {
    paramsProjId: {
      immediate: true,
      handler (nv, ov) {
        this.getProject(nv)
      }
    },

    queryParam: {
      immediate: true,
      handler (nv, ov) {
        this.getSampleDay(nv)
      }
    }
  },
  computed: {
    iframeSrc () {
      if (this.templateList && this.templateList.length > 0) {
        const template = this.templateList[this.curIdx]
        if (this.showAll) {
          const sampleDay = this.sampleDayList[this.sampleDayIdx]?.day
          const dateRange = this.getDateRange(sampleDay)
          return `${fileTemplateUreportApi.preview}?_t=1,2,3,4,5&_u=${template.templateFile}&sampleBeginTime=${dateRange[0]}&sampleEndTime=${dateRange[1]}&templateId=${template.id}&projId=${this.params.projId}&storageApplyId=${this.params.storageApplyId}&_n=${this.params.fileName}`
        } else {
          const cateId = this.project.hasCateList[this.curCateIdx].cateId
          const sampleDay = this.sampleDayMap[cateId]?.[this.sampleDayIdx]?.day
          const dateRange = this.getDateRange(sampleDay)
          return `${fileTemplateUreportApi.preview}?_t=1,2,3,4,5&_u=${template.templateFile}&sampleBeginTime=${dateRange[0]}&sampleEndTime=${dateRange[1]}&templateId=${template.id}&projId=${this.params.projId}&storageApplyId=${this.params.storageApplyId}&cateId=${cateId}&_n=${this.params.fileName}`
        }
      }
      return ''
    },

    queryParam () {
      return {
        projId: this.params.projId,
        storageApplyId: this.params.storageApplyId,
        projStatuses: [projectStatusEnum.tempAbandoned, projectStatusEnum.abandoned, projectStatusEnum.processing, projectStatusEnum.done],
        type: 1
      }
    },
    paramsProjId () {
      if (this.params) {
        return this.params.projId
      }
      return null
    }
  },

  methods: {
    selectTemplate (idx) {
      this.curIdx = idx
    },

    // 获取样品的采样日期
    getSampleDay (queryParam) {
      const api = this.showAll ? tsSampleinfoApi.getSampleDay : tsSampleinfoApi.getSampleDayByCategory
      api(queryParam).then(res => {
        if (res.code === 0) {
          this.showAll && (this.sampleDayList = res.data) || (this.sampleDayMap = res.data)
        }
      })
    },

    // 获取日期范围
    getDateRange (date) {
      if (date) {
        return [date + ' 00:00:00', date + ' 23:59:59']
      } else {
        return [null, null]
      }
    },

    // 获取项目信息
    getProject (projId) {
      if (projId) {
        projectApi.get({ id: projId }).then(result => {
          if (result.code === 0) {
            this.project = result.data
          }
        })
      } else {
        this.project = {}
      }
    },

    listFileTemplate () {
      fileTemplateUreportApi.list(this.templateParam).then(result => {
        if (result.code === 0) {
          this.templateList = result.data
        } else {
          this.$message.error(result.msg)
        }
      })
    }
  },
  created () {
    this.curIdx = 0
    this.listFileTemplate()
  }
}
</script>

<style scoped lang="less">
.utabs {
  .archive {
    margin: 0px 5px;
    border: 1px solid #91D5FF;
    background-color: #E6F7FF;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
    padding-left: 8px;
  }

  /deep/ .ant-tabs-bar {
    background: #FFFFFF;
  }
}
</style>
