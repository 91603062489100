<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          分包报告
        </div>
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="2">分包报告</th>
          <td class="tdLabel">报告文件</td>
          <td colspan="5">
            <UploadFile :fileList="subtask.fileReportList" :multiple="true" @ok="uploadFileOK"></UploadFile>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">分包结果</td>
          <td colspan="5">
            <a-button type="primary" @click="entryModal.action.show()">录入</a-button>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <a-spin :spinning="btnSpinning">
              <a-button style="width: 100%; margin-top: 3px; margin-bottom: 3px" type="primary" icon="save" @click="save">保存</a-button>
            </a-spin>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <div style="color: red">说明：上传分包检测报告，并到检测任务中录入分包项检测结果；
            </div>
          </td>
        </tr>
      </table>
    </a-row>
    <a-modal v-bind="entryModal" v-on="entryModal.action">
      <check-task-subcontract-entry v-bind="entryModal.data" v-on="entryModal.dataAction"></check-task-subcontract-entry>
    </a-modal>
  </div>
</template>

<script>
import { projSubcontractTaskApi } from '@/api/project'
import UploadFile from '@/pages/common/upload/UploadFile'

import { CheckTaskSubcontractEntry } from '@/pages/cloud-quote/check-task/modules'
import { fullModalData } from '@/pages/common/mixins/modal/full'
export default {
  name: 'SubTaskResultForm',
  components: {
    UploadFile, CheckTaskSubcontractEntry
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      btnSpinning: false,
      FILE_SUBTASK_REPORT_TYPE: 'FBBG',

      entryModal: Object.assign({}, fullModalData, {
        title: '分包录入',
        data: {
          ref: 'entryForm',
          taskId: null,
          projId: null,
          subcontractor: null
        },
        action: {
          show: () => {
            console.log(this.subtask);
            this.entryModal.data.taskId = this.subtask.id
            this.entryModal.data.projId = this.subtask.projId
            this.entryModal.data.subcontractor = this.subtask.subcontractor
            this.entryModal.visible = true
          },
          ok: () => {
            this.$refs[this.entryModal.data.ref].save()
          },

          cancel: () => { this.entryModal.visible = false }
        },

        dataAction: {
          success: () => {
            this.entryModal.action.cancel()
          }
        }

      })
    }
  },
  methods: {
    save () {
      const self = this
      const formData = Object.assign({}, self.subtask)
      if (self.subtask.cateIdItemIdList) {
        formData.cateItemIds = self.subtask.cateIdItemIdList.join(',')
      }
      delete formData.subItemList
      delete formData.cateIdItemIdList
      delete formData.fileList
      delete formData.fileReportList
      delete formData.fileQuoteList
      this.btnSpinning = true
      projSubcontractTaskApi.edit(formData).then(result => {
        if (result.code === 0) {
          this.$message.success(`${formData.sbtTitle}操作成功`)
          this.$emit('ok')
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      }).finally(() => {
        this.btnSpinning = false
      })
    },
    uploadFileOK (obj) {
      var newFileList = obj.newFileList
      if (newFileList) {
        // 增加文件类型
        newFileList.forEach(file => {
          file.relType = this.FILE_SUBTASK_REPORT_TYPE
        })
      }
      this.$set(this.subtask, 'newFileListStr', JSON.stringify(newFileList))
      this.$set(this.subtask, 'delFileIdsStr', obj.delFileIdsStr)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    initInfo () {
    }
  },
  watch: {
    'subtask.id': {
      handler (nv, ov) {
        if (nv) {
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted () {
    this.initInfo()
  },
  created () {
  }
}
</script>
<style lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
