'use strict'

/**
 * 自动配置 相关API
 */

import { getAction, postAction, postDataAction } from '@/api/common'

const sysConfigSetPre = '/ms-api/sysConfigSet'

export const autoConfigureApi = {
  get: (param) => getAction(`${sysConfigSetPre}/selectConfigSet`, param),
  edit: (data) => postDataAction(`${sysConfigSetPre}/edit`, data),
  selectConfigByType: (param) => getAction(`${sysConfigSetPre}/selectConfigByType`, param),
  selectConfigUserInfoByName: (param) => getAction(`/ms-api/sys-config/selectConfigUserInfoByName`, param),
  saveConfigUserInfoByName: (data) => postDataAction(`/ms-api/sys-config/saveConfigUserInfoByName  `, data)
}

export const sysReviewConfigUserControllerApi = {
  selectUserList: (data) => postAction(`/ms-api/sysReviewConfigUserController/selectUserList`, data) // type（ by 标液  qx 曲线  sj 试剂）
}
