<template>
  <a-modal v-bind="storageModal" v-on="storageModal">
    <storage v-bind="storageModal.data" @success="storageModal.success"></storage>
    <template #footer>
      <a-button @click="storageModal.cancel">关闭</a-button>
      <a-button @click="storageModal.ok" type="primary" :style="{marginLeft:'10px'}">申请</a-button>
    </template>
  </a-modal>
</template>

<script>
import Storage from '@/pages/cloud-quote/make-sample/modules/storage'

export default {
  components: { Storage },

  props: Object.assign({}, Storage.props, {
    title: {
      type: String,
      required: false,
      default: '质控样入库'
    },

    modalWidth: {
      type: [Number, String],
      required: false,
      default: '90%'
    },

    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  }),

  model: {
    prop: 'visible',
    event: 'change'
  },

  watch: {
    visible: {
      immediate: true,
      handler (show) {
        if (show) {
          this.storageModal.show()
        } else {
          this.storageModal.cancel()
        }
      }
    }
  },

  data () {
    return {
      storageModal: {
        title: this.title,
        width: this.modalWidth,
        visible: false,
        data: {
          ref: 'storageForm',
          projId: this.projId,
          types: this.types,
          statuses: this.statuses
        },
        show: () => { this.storageModal.visible = true },

        ok: () => {
          this.$refs[this.storageModal.data.ref].save()
        },

        success: () => {
          this.storageModal.cancel()
          this.$emit('success')
        },
        cancel: () => {
          this.storageModal.visible = false
          this.$emit('change', false)
        }
      }
    }
  }

}
</script>
