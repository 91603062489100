var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"centered":true,"closable":false,"destroyOnClose":true,"width":"90%","wrapClassName":"modal-container"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',[_c('page-view',{attrs:{"title":_vm.title}},[_c('a-card',{staticStyle:{"margin":"20px"},attrs:{"bordered":true,"title":"领用计划详情"}},[_c('detail-list',[_c('detail-list-item',{attrs:{"term":"领用批次号"}},[_vm._v(_vm._s(this.purList.purUseNbr))]),_c('detail-list-item',{attrs:{"term":"领用批次名称"}},[_vm._v(_vm._s(this.purList.purUseNbr))]),_c('detail-list-item',{attrs:{"term":"申请人姓名"}},[_vm._v(_vm._s(this.purList.userName))]),_c('detail-list-item',{attrs:{"term":"领用申请日期"}},[_vm._v(_vm._s(this.purList.dateOfPurUse))]),_c('detail-list-item',{attrs:{"term":"领用原因"}},[_vm._v(_vm._s(this.purList.purUseReason))]),_c('detail-list-item',{attrs:{"term":"备注"}},[_vm._v(_vm._s(this.purList.purUseDesc))]),_c('detail-list-item',{attrs:{"term":"关联项目"}},[_vm._v(_vm._s(this.purList.projName))])],1)],1),_c('a-card',{staticStyle:{"margin":"20px"},attrs:{"bordered":true,"title":"物资信息"}},[_c('a-table',{ref:"table",attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":false},scopedSlots:_vm._u([_vm._l((['cateId', 'materialName', 'speType','unit','materialNum','numOfUse']),function(col,i){return {key:col,fn:function(text, record){return [(col === 'cateId')?[_vm._v(" "+_vm._s(_vm.getName(text, record))+" ")]:(col==='unit')?[_vm._v(" "+_vm._s(_vm.materialUnitCode[text])+" ")]:[_vm._v(" "+_vm._s(text)+" ")]]}}}),{key:"operation",fn:function(text, record){return [_c('span',[_c('a-popconfirm',{attrs:{"title":"是否要作废此行?"},on:{"confirm":function($event){return _vm.remove(record)}}},[_c('a',[_vm._v("移除")])])],1)]}}],null,true)})],1)],1)],1),_c('template',{style:({
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#FFFFFF',
        textAlign: 'right',
      }),slot:"footer"},[_c('a-button',{style:({marginRight: '8px'}),on:{"click":_vm.handleCancel}},[_vm._v("关闭")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }