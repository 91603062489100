import { modelApi } from '@/api/flow'

const flow = {
  state: {
    flow_custom: {}
  },

  mutations: {
    FLOW_CUSTOM: (state, flowCustom) => {
      state.flow_custom = flowCustom
    }
  },

  actions: {
    // 获取个性化设置信息
    FlowCustom ({ commit }) {
      return new Promise((resolve, reject) => {
        modelApi.getCustomList().then(res => {
          if (res.code === 0) {
            const flowCustom = res.data && res.data.reduce((init, val) => {
              return (init[val.itemValue] = val.itemText) && init
            }, {}) || {}
            commit('FLOW_CUSTOM', flowCustom)
            resolve(res.data)
          } else {
            reject(new Error(''))
          }
        }).catch(error => {
          reject(error)
        })
      })
    }

  }
}

export default flow
