<template>
  <a-range-picker
    :value="momVal"
    :disabledTime="disabledTime"
    :format="dateFormat"
    :placeholder="placeholder"
    :showTime="showTime"
    :disabled="disabled"
    @change="handleDateRangeChange"
    :getCalendarContainer="getCalendarContainer"
  />
</template>

<script>
import moment from 'moment'

export default {
  name: 'JDateRangePicker',
  props: {
    disabledTime: {
      type: Function,
      required: false
    },
    ranges: {},
    placeholder: {
      type: Array,
      default: () => ['开始时间', '结束时间'],
      required: false
    },
    value: {
      type: Array,
      required: false
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
      required: false
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false
    },
    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    getCalendarContainer: {
      type: Function,
      default: () => document.body
    }
  },
  data () {
    const dateRangeStr = this.value
    return {
      decorator: '',
      momVal: this.dateStrRange2DateRange(dateRangeStr)
    }
  },
  watch: {
    value (val) {
      this.momVal = this.dateStrRange2DateRange(val)
    }
  },
  methods: {
    moment,
    dateStrRange2DateRange (dateStrRange) {
      var dateRang = []
      if (dateStrRange) {
        dateStrRange.forEach((dateStr, idx) => {
          dateRang.push(this.dateStr2Date(dateStr))
        })
      }
      return dateRang
    },
    dateStr2Date (dateStr) {
      if (this.isNotNull(dateStr)) {
        return moment(dateStr, this.dateFormat)
      }
      return null
    },
    isNull (str) {
      if (str == null || str === undefined || str === '') {
        return true
      } else {
        return false
      }
    },
    isNotNull (str) {
      return !this.isNull(str)
    },
    handleDateRangeChange (dates, dateStrs) {
      this.$emit('update', dateStrs)
      this.$emit('change', dates, dateStrs)
    }
  }
}
</script>

<style scoped>

</style>
