<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;">
          移交信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ transferInfo.tRealName }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ transferInfo.tDate }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <td class="tdLabel">接收人</td>
          <td>
            <select-user-by-dept ref="devUser" :disabled="disabled" :triggerChange="true" @change="handleUserChange" rowType="radio"/>
          </td>
          <td class="tdLabel">移交原因</td>
          <td>
            <a-input v-model="transferInfo.tReason" :disabled="disabled"></a-input>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">移交物资</td>
          <td colspan="3">
            <a-table
              ref="table"
              key="id"
              :columns="columns"
              :dataSource="materialList"
              :pagination="false"
            >
              <template #unit="text">
                {{ materialUnitCode.get(text) }}
              </template>
            </a-table>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { Get, Post, resPurUseApi } from '@/api/quote'
import { dictItemApi } from '@/api/system'

export default {
  name: 'MaterialTransferModal',
  components: {
    SelectUserByDept,
    Get,
    Post,
    resPurUseApi
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      disabled: false,
      materialList: [],
      transferInfo: {
        tUserName: undefined,
        tRealName: undefined,
        tPurUseId: undefined,
        tMaterialTypes: undefined,
        tMaterialIds: undefined,
        tReason: undefined,
        tDate: undefined,
        rUserName: undefined,
        rRealName: undefined
      },
      columns: [
        { title: '编号', dataIndex: 'id', key: 'id', width: 100 },
        { title: '内部编号', dataIndex: 'fixedAssetsId', key: 'fixedAssetsId', width: 150 },
        { title: '物品类别', dataIndex: 'cateName', key: 'cateName', width: 150 },
        { title: '物品名称', dataIndex: 'materialName', key: 'materialName', width: 200 },
        { title: '规格型号', dataIndex: 'speType', key: 'speType', width: 150 },
        { title: '单位', dataIndex: 'unit', key: 'unit', width: 100, scopedSlots: { customRender: 'unit' } }
      ],
      materialUnitCode: undefined
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.load()
        }
      }
    }
  },
  methods: {
    init (record, materialList, projId) {
      if (this.materialUnitCode) {
        this.initData(record, materialList, projId)
      } else {
        this.materialUnitCode = new Map()
        Get(dictItemApi.list, { dictCode: 'material_unit_code', pageSize: 100 }).then(res => {
          res.data.forEach(item => {
            this.materialUnitCode.set(item.itemValue, item.itemText)
          })
          this.initData(record, materialList, projId)
        })
      }
    },
    initData (record, materialList, projId) {
      this.disabled = false
      this.transferInfo.tUserName = record.userId
      this.transferInfo.tRealName = record.userName
      if (projId) {
        const purUseIds = []
        materialList.forEach(item => {
          if (purUseIds.indexOf(item.purUseId) === -1) purUseIds.push(item.purUseId)
        })
        this.transferInfo.tPurUseId = purUseIds.join(',')
      } else {
        this.transferInfo.tPurUseId = record.id
      }
      this.transferInfo.tDate = new Date().format('yyyy-MM-dd HH:mm:ss')
      materialList.sort((a, b) => a.materialId - b.materialId)
      this.materialList = materialList
    },
    load () {
      this.materialUnitCode = new Map()
      Get(dictItemApi.list, { dictCode: 'material_unit_code', pageSize: 100 }).then(res => {
        res.data.forEach(item => {
          this.materialUnitCode.set(item.itemValue, item.itemText)
        })
        this.loadData()
      })
    },
    loadData () {
      this.disabled = true
      Get(resPurUseApi.getTransferInfo, { id: this.recordId }).then(res => {
        if (res.code === 0 && res.data) {
          this.transferInfo = JSON.parse(res.data.transferInfo)
          this.$refs.devUser.setUserList(res.data.userList)
          this.transferInfo.tPurUseId.split(',').forEach(item => {
            Get(resPurUseApi.getReturnMaterial, { id: item }).then(res => {
              if (res.code === 0) {
                const ids = this.transferInfo.tMaterialIds.split(',')
                res.data.filter(item => ids.indexOf(item.id.toString()) !== -1).forEach(it => {
                  this.materialList.push(it)
                })
              }
            })
          })
        }
      })
    },
    handleUserChange (userList) {
      if (userList.length > 0) {
        if (userList[0].realname === this.transferInfo.tRealName) {
          this.$message.error('申请人与接收人不能相同！')
          this.$refs.devUser.setUserList([])
          return
        }
        this.transferInfo.rUserName = userList[0].username
        this.transferInfo.rRealName = userList[0].realname
      }
    },
    handleSubmit () {
      if (!this.transferInfo.rUserName) {
        this.$message.error('接收人不能为空！')
        return
      }
      if (this.materialList.length === 0) {
        this.$message.error('移交物资不能为空！')
        return
      }
      this.transferInfo.tMaterialTypes = this.materialList.map(item => item.materialId).join(',')
      this.transferInfo.tMaterialIds = this.materialList.map(item => item.id).join(',')
      Post(resPurUseApi.startTransfer, this.transferInfo).then(res => {
        if (res.code === 0) {
          this.$message.success('申请移交成功！')
          this.$emit('ok')
        } else {
          this.$message.success('申请移交失败！')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
