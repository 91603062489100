<template>
  <a-modal id="check-form" v-bind="fullModal" v-on="fullModal">
    <div class="utabs" style="height: 100%">
      <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
        <a-tab-pane :closable="false" :key="idx" :tab="obj.templateName" style="height: 100%" v-for="(obj,idx) of templateList">
          <a-row style="height:100%;">
            <iframe :src="formSrc" v-bind="form" v-if="formSrc" style="width: 100%;height:100%;margin: 0 auto"/>
            <Watermark/>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-drawer v-bind="checkDrawer" v-on="checkDrawer">
      <div>
        <a-row align="middle">
          <a-col :span="4" class="mxtable-title">审核</a-col>
        </a-row>
        <table class="mxtable">
          <tr>
            <th rowspan="4">审核事项</th>
            <td class="tdLabel">审核时限</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td class="tdLabel">审核内容</td>
            <td colspan="3">
              <p> （1）对复核进行审核： </p>
              <p> （2）数据的完整性； </p>
              <p> （3）程序与异常数据处置合规性； </p>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">审核结果</td>
            <td colspan="3">
              <a-radio-group v-model="checkData.checkStatus">
                <a-radio :value="checkOriginalRecordCheckStatusEnum.pass">通过</a-radio>
                <a-radio :value="checkOriginalRecordCheckStatusEnum.rejected">驳回</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">审核意见</td>
            <td colspan="3">
              <a-textarea v-model="checkData.checkNote"></a-textarea>
            </td>
          </tr>
        </table>
        <div class="check-btn">
          <a-button @click="submitCheck " type="primary">提交</a-button>
        </div>
      </div>
      <a-modal v-bind="checkCheckModal">
        <a-input placeholder="请输入登录密码进行认证" type="password" v-model="checkData.checkPwd"/>
        <template #footer>
          <a-button @click="checkCheckModal.ok" type="primary">提交</a-button>
        </template>
      </a-modal>
    </a-drawer>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
      <a-button @click="fullModal.showCheck" type="primary">审核</a-button>
    </template>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { checkOriginalRecordCheckStatusEnum } from '@/api/check/checkOriginalRecordConstant'
import { checkStandardSolutionApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import Watermark from '@/pages/common/watermark/Watermark'
import { throttle } from 'lodash'

export default {
  mixins: [fullModal],
  components: { Watermark },

  props: {
    // ids 和 id 选择传递一个即可
    ids: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    id: {
      type: Number,
      required: false,
      default: null
    },
    authMark: {
      type: Boolean,
      required: false,
      default: false
    },

    checkUser: {
      type: String || null,
      required: false,
      default: null
    }

  },

  data () {
    return {
      checkOriginalRecordCheckStatusEnum,

      drawerVisible: false,
      templateList: [],
      curIdx: 0,

      // 审核数据
      checkData: {
        checkUser: this.checkUser,
        checkPwd: null,
        checkStatus: null,
        checkNote: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      checkDrawer: {
        visible: false,
        width: 600,
        getContainer: false,
        closable: false,
        maskClosable: true,
        mask: true,
        wrapStyle: { position: 'absolute' },
        close: () => { this.checkDrawer.visible = false }
      },

      checkCheckModal: {
        title: '请先进行认证',
        centered: true,
        closed: false,
        width: 600,
        visible: false,
        show: () => { this.checkCheckModal.visible = true },
        cancel: () => { this.checkCheckModal.visible = false },
        ok: () => {
          if (this.checkData.checkPwd) {
            this.check()
          } else {
            this.$message.error('请输入认证密码！')
            return false
          }
        }
      },

      fullModal: {
        title: '标液配置记录审核',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },

        showCheck: () => {
          this.checkDrawer.visible = true
        }
      }
    }
  },

  computed: {
    formSrc () {
      if (this.templateList && this.templateList.length > 0) {
        const template = this.templateList[this.curIdx]
        if (this.id) {
          return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&id=${this.id}`
        } else if (this.ids.length) {
          return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&ids=${this.ids.join(',')}`
        }
      }
      return null
    }
  },

  created () {
    this.findTemplate()
  },

  mounted () {
    this.check = throttle(this.check, 6000, { leading: true, trailing: false })
  },

  methods: {

    findTemplate () {
      fileTemplateUreportApi.list({ templateType: fileTemplateUreportTypeEnum.solution, page: false }).then(res => {
        this.loadData = false
        if (res.code === 0) {
          this.templateList.splice(0, this.templateList.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 提交审核
    submitCheck () {
      if (this.checkCheckData()) {
        this.check()
      }
    },

    // 审核检测原始记录表
    check () {
      if (!this.ids.length && !this.id) {
        this.$message.error('无有效的数据！')
        return false
      }

      const checkDataArr = this.ids.length && this.ids.map(item => Object.assign({ id: item }, this.checkData)) || []
      this.id && checkDataArr.push(Object.assign({ id: this.id }, this.checkData))

      checkStandardSolutionApi.check(checkDataArr).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
          this.fullModal.cancel()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 校验审核数据
    checkCheckData () {
      if (this.checkData.checkStatus) {
        return true
      } else {
        this.$message.error('请选择审核结果！')
        return false
      }
    },

    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/table/index2";

.check-form {
  position: absolute;
  bottom: 100px;
  width: 100%;
  background: #FF0000;
  z-idnex: 1;
}

.mxtable td p {
  margin-bottom: .5em;
}

.check-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.utabs {
  .archive {
    margin: 0px 5px;
    border: 1px solid #91D5FF;
    background-color: #E6F7FF;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
  }
}

</style>
