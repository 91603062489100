<template>
  <div>
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="综合查询">
              <a-input v-model.trim.lazy="queryParam.compositionName" placeholder="名称模糊查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :style="{ textAlign: 'left',marginBottom:'15px' }">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <div class="fr">
        <a-button v-action:check-reagent-cate:add type="primary" @click="cateFormModal.add">
          <a-icon type="plus"/>
          类目
        </a-button>
      </div>
    </a-card>

    <a-card v-bind="contentCard">
      <a-row :gutter="8">
        <a-col :span="3">
          <!-- <a-tree :treeData="categorySource" @select="onCateSelect"/> -->
          <a-tree v-bind="reagentCateGradeTree" v-on="reagentCateGradeTree.action"/>
          <a-tree v-bind="checkCateSolutionTypeTree" v-on="checkCateSolutionTypeTree.action"/>
          <a-tree v-bind="reagentCateSolutionTypeTree" v-on="reagentCateSolutionTypeTree.action"/>
        </a-col>
        <a-col :span="21">
          <check-reagent-cate-table ref="table" v-bind="tableBind" @selectedRows="handleRowSelected">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
              <slot :name="slot" v-bind="scope"/>
            </template>
          </check-reagent-cate-table>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-if="cateFormModal.visible" v-bind="cateFormModal">
      <check-reagent-cate-form v-bind="cateFormModal.data" v-on="cateFormModal.data"></check-reagent-cate-form>
      <template #footer>
        <a-button @click="cateFormModal.cancel">取消</a-button>
        <a-button type="primary" @click="cateFormModal.submit">确定</a-button>
      </template>
    </a-modal>
  </div>

</template>

<script>
import { search, action, table } from '@/pages/common/mixins/list'
import { fullModalData } from '@/pages/common/mixins/modal/full'

import { Get, resdevCategoryApi } from '@/api/quote'

import { CheckReagentCateTable } from '@/pages/cloud-quote/check-reagent/cate/modules'
import { CheckReagentCateSelectColumn as selectColumn } from './colunm'
import { reagentCateGradeOptions, reagentCateSolutionTypeOptions } from '@/api/checkReagent/checkReagentConstant'
import { checkSolutionTypeOptions } from '@/api/checkStandardSolution/checkStandardSolutionConstant'

const commonTreeData = {
  treeData: [],
  checkable: true,
  expandedKeys: ['0'],
  checkKeys: [],
  autoExpandParent: true
}

export default {
  name: 'CheckReagentCateSelectAndQueryTable',
  props: Object.assign({}, CheckReagentCateTable.props),
  components: { CheckReagentCateTable },
  mixins: [search, action, table],

  data () {
    return {
      reagentCateGradeOptions,
      checkSolutionTypeOptions,
      reagentCateSolutionTypeOptions,
      cateFormModal: Object.assign({}, fullModalData, {
        visible: false,
        title: '类目维护',
        width: 800,
        destroyOnClose: true,
        bodyStyle: { background: '#F0F2F5' },

        data: {
          ref: 'cateForm',
          id: 0,
          type: 'add',
          success: () => {
            this.reload()
            this.cateFormModal.cancel()
          },
          cancel: () => {
            this.cateFormModal.cancel()
          }
        },

        add: () => {
          this.cateFormModal.data.id = 0
          this.cateFormModal.data.type = 'add'
          this.cateFormModal.show()
        },

        edit: (record) => {
          this.cateFormModal.data.id = record.id
          this.cateFormModal.data.type = 'edit'
          this.cateFormModal.show()
        },

        copy: (record) => {
          this.cateFormModal.data.id = record.id
          this.cateFormModal.data.type = 'copy'
          this.cateFormModal.show()
        },

        show: () => {
          this.cateFormModal.visible = true
        },

        submit: () => {
          this.$refs[this.cateFormModal.data.ref].submit()
        },

        cancel: () => {
          this.cateFormModal.visible = false
        }
      }),
      categorySource: [],
      queryParam: {
        name: this.name,
        compositionName: this.compositionName,
        materialCateId: this.materialCateId,
        grades: [],
        solutions: [],
        solutionTypes: []
      },
      selectedRows: [],
      reagentCateGradeTree: Object.assign({}, JSON.parse(JSON.stringify(commonTreeData)), {
        action: {
          check: (checkKeys, item) => {
            this.reagentCateGradeTree.checkKeys.splice(0, this.reagentCateGradeTree.checkKeys.length)
            this.queryParam.grades.splice(0, this.queryParam.grades.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.grades.splice(0, this.queryParam.grades.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.reagentCateGradeTree.expandedKeys.splice(0, this.reagentCateGradeTree.expandedKeys.length, ...(expandedKeys || []))
          }
        }
      }),

      checkCateSolutionTypeTree: Object.assign({}, JSON.parse(JSON.stringify(commonTreeData)), {
        action: {
          check: (checkKeys, item) => {
            this.checkCateSolutionTypeTree.checkKeys.splice(0, this.checkCateSolutionTypeTree.checkKeys.length)
            this.queryParam.solutionTypes.splice(0, this.queryParam.solutionTypes.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.solutionTypes.splice(0, this.queryParam.solutionTypes.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.checkCateSolutionTypeTree.expandedKeys.splice(0, this.checkCateSolutionTypeTree.expandedKeys.length, ...(expandedKeys || []))
          }
        }
      }),

      reagentCateSolutionTypeTree: Object.assign({}, JSON.parse(JSON.stringify(commonTreeData)), {
        action: {
          check: (checkKeys, item) => {
            this.reagentCateSolutionTypeTree.checkKeys.splice(0, this.reagentCateSolutionTypeTree.checkKeys.length)
            this.queryParam.solutions.splice(0, this.queryParam.solutions.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.solutions.splice(0, this.queryParam.solutions.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.reagentCateSolutionTypeTree.expandedKeys.splice(0, this.reagentCateSolutionTypeTree.expandedKeys.length, ...(expandedKeys || []))
          }
        }
      })
    }
  },

  computed: {
    tableBind () {
      return Object.assign({ columns: this.columns || selectColumn }, this.$attrs, this.$props, this.queryParam)
    }
  },

  mounted () {
    this.initData()
  },

  methods: {

    // 初始化数据
    initData () {
      const cateGradeData = [{ key: '-1', title: '试剂等级', children: this.reagentCateGradeOptions.map(item => ({ key: item.value, title: item.label, children: null })) }]
      const checkSolutionTypeData = [{ key: '-1', title: '溶液类型', children: this.checkSolutionTypeOptions.map(item => ({ key: item.value, title: item.label, children: null })) }]
      const cateSolutionTypeData = [{ key: '-1', title: '试剂形状', children: this.reagentCateSolutionTypeOptions.map(item => ({ key: item.value + 0, title: item.label, children: null })) }]
      
      this.reagentCateGradeTree.treeData.splice(0, this.reagentCateGradeTree.treeData.length, ...cateGradeData)
      this.checkCateSolutionTypeTree.treeData.splice(0, this.checkCateSolutionTypeTree.treeData.length, ...checkSolutionTypeData)
      this.reagentCateSolutionTypeTree.treeData.splice(0, this.reagentCateSolutionTypeTree.treeData.length, ...cateSolutionTypeData)


      const categoryFun = this.loadCategoryList()
      Promise.all([categoryFun]).then(res => {
        const cateTop = { key: '0', title: '物资类别', children: res[0]?.data || [] }
        this.categorySource.push(cateTop)
      })
    },

    // 获取选中的数据
    getSelectData () {
      return [...this.selectedRows]
    },

    // 处理行选中事件
    handleRowSelected (data) {
      this.selectedRows.splice(0, this.selectedRows.length, ...data)
      this.$emit('selectedRows', data)
    },

    search () {
      this.$refs.table.search()
    },

    reload () {
      this.$refs.table.reload()
    },

    onCateSelect (selectedKeys, info) {
      this.queryParam.cateId = parseInt(selectedKeys[0])
      this.search()
    },

    loadCategoryList () {
      return Get(resdevCategoryApi.list, { pageSize: 500 })
    }
  }
}
</script>

<style scoped>

</style>
