<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          样品信息
        </div>
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="4">样品信息</th>
          <td class="tdLabel">样品列表</td>
          <td colspan="5">
            <a-card>
              <a-row :gutter="[4,4]">
                <template v-for="(item,idx) in sampleList">
                  <a-col span="12" :key="idx" style="margin: 4px 0px">
                    <a-tag color="blue">{{ item.sampleId }} {{ item.itemName }}</a-tag>
                  </a-col>
                </template>
              </a-row>
            </a-card>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import { projSubcontractTaskApi } from '@/api/project'

export default {
  name: 'SubTaskApplySample',
  components: {
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      fetching: false,
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      // 推荐金额
      sbtAmount: null,
      // 分包项ID
      itemIds: [],
      sampleList: []

    }
  },
  methods: {
    listSample () {
      if (this.subtask.id) {
        projSubcontractTaskApi.listSample({ taskId: this.subtask.id }).then(res => {
          if (res.code === 0) {
            this.sampleList = res.data || []
          }
        })
      } else {
        this.sampleList = []
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    }
  },
  watch: {
    'subtask.id': {
      handler (nv, ov) {
        if (nv) {
          this.listSample()
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
