<template>
  <div>
    <a-input-search v-model="selectedItems" :placeholder="`请先选择${typeTitle}`" disabled @search="onSearchDepUser">
      <a-button slot="enterButton" :disabled="disabled">{{ typeTitle }}</a-button>
    </a-input-search>
    <select-item-modal ref="selectModal" :modal-width="modalWidth" :type-title="typeTitle" @cateTypeSelect="(v) => cateTypeSelect(v)" @ok="onSearchDepUserCallBack" />
  </div>
</template>

<script>
import SelectItemModal from './modal/SelectItemModal'
export default {
  name: 'SelectItem',
  components: { SelectItemModal },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: true
    },
    typeTitle: {
      type: String,
      default: '采样项'
    }
  },
  data() {
    return {
      itemList: [],
      selectedItems: ''
    }
  },
  mounted() {
    this.selectedItems = this.value
  },
  watch: {
    value(val) {
      this.selectedItems = val
    }
  },
  methods: {
    cateTypeSelect(v) {
      this.$emit('cateTypeSelect', v)
    },
    init() {
      this.selectedItems = ''
      this.itemList = []
    },
    onSearchDepUser() {
      this.$refs.selectModal.showModal(this.itemList, this.multiSelect)
    },
    setItemList(itemList) {
      this.onSearchDepUserCallBack(itemList)
    },
    onSearchDepUserCallBack(itemList) {
      this.itemList = itemList
      let itemNames = ''
      for (let i = 0; i < itemList.length; i++) {
        if (i === itemList.length - 1) {
          itemNames = itemNames + itemList[i].name
        } else {
          itemNames = itemNames + itemList[i].name + ','
        }
      }
      this.selectedItems = itemNames
      if (this.triggerChange) {
        this.$emit('change', itemList)
      } else {
        this.$emit('input', itemList)
      }
    }
  }
}
</script>

<style scoped>
</style>
