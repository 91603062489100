<template>
  <a-modal v-bind="searchProjectModal" v-on="searchProjectModal">
    <a-row v-bind="searchProjectRow">
      <a-col :md="24" :sm="24">

        <a-tabs v-model="tabModelCode" @change="callback"><!-- defaultActiveKey="1"-->
          <a-tab-pane tab="市场项目" key="市场项目">
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入项目编号/名称" v-model="queryParam.projName"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="loadData(1)" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>

              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="projectTable"
                  v-on="projectTable"
                  :pagination="ipagination"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                  :dataSource="dataSource"
                  @change="handleTableChange">
<!--                  <template slot="projUserName" slot-scope="text, record">-->
<!--                    <span v-if="searchType === 'projName'">{{record.projUserName}}</span>-->
<!--                    <span v-else>{{record.projContact}}</span>-->
<!--                  </template>-->
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane tab="通用项目" key="通用项目" forceRender :disabled="commonProjDisable">
            <a-card hoverable>
              <!-- table区域-begin -->
              <div>
                <a-table
                  v-if="searchType === 'relProjName'"
                  v-bind="projectTable"
                  v-on="projectTable"
                  :pagination="commonIpagination"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                  :dataSource="commonProjectList">
<!--                  <template slot="projUserName" slot-scope="text, record">-->
<!--                    <span v-if="searchType === 'projName'">{{record.projUserName}}</span>-->
<!--                    <span v-else>{{record.projContact}}</span>-->
<!--                  </template>-->
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane tab="商机" key="商机" forceRender :disabled="commonProjDisable">
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入商机名称" v-model="queryParam.title"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="loadBusinessData(1)" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>

              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="businessTable"
                  v-on="businessTable"
                  :pagination="ipaginationBusiness"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                  :dataSource="businessDataSource"
                  @change="handleBusinessTableChange">
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { ListMixin } from '@/mixins/ListMixin'
import { businessApi } from '@/api/quote'
import { projectApi } from '@/api/project'
import { commDictApi } from '@/api/common'

export default {
  name: 'SearchProjectModal',

  mixins: [ListMixin],

  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    title: {
      type: String,
      default: '请选择项目',
      required: false
    },
    searchType: {
      type: String,
      default: 'projName',
      required: false
    }
  },

  data () {
    return {
      tabModelCode: '市场项目',

      queryParam: {
        processInstanceName: null,
        key: null,
        startTime: null,
        endTime: null
      },

      loading: false,
      dataSource: [],

      projectTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        // rowKey: this.searchType === 'projName' ? 'projId' : 'id',
        rowKey: 'key',
        columns: [
          { title: '项目编号', align: 'center', dataIndex: 'projNo' },
          { title: '项目名称', align: 'center', dataIndex: 'projName' },
          // { title: '负责人', align: 'center', dataIndex: 'projUserName', scopedSlots: { customRender: 'projUserName' } }
          { title: '负责人', align: 'center', dataIndex: 'projContact' }
        ]
      },

      businessTable: {
        ref: 'businessTable',
        bordered: true,
        size: 'small',
        rowKey: 'key',
        columns: [
          { title: '商机名称', align: 'center', dataIndex: 'title' },
          { title: '客户单位', align: 'center', dataIndex: 'entrustCustomerName' },
          { title: '负责人', align: 'center', dataIndex: 'headName' }
        ]
      },
      businessDataSource: [],
      ipaginationBusiness: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: false,
        showSizeChanger: true,
        total: 0
      },
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: false,
        showSizeChanger: true,
        total: 0
      },

      searchProjectRow: {
        gutter: 10,
        style: { background: '#ECECEC', padding: '10px', margin: '-10px' }
      },

      searchProjectModal: {
        width: this.modalWidth,
        visible: false,
        title: this.title,
        ok: this.handleSubmit,
        cancel: this.close,
        cancelText: '关闭',
        style: { marginTop: '-70px' },
        wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      },

      roles: this.$store.getters.roles,
      commonProjectList: [],
      commonIpagination: false,
      commonProjDisable: this.searchType === 'projName'
    }
  },

  created () {
  },

  methods: {

    callback (v) {
      this.onClearSelected()
      if (v === '市场项目' && this.dataSource.length === 0) {
        this.loadData()
      } else if (v === '通用项目' && this.commonProjectList.length === 0) {
        this.listCommonProject()
      } else if (v === '商机' && this.businessDataSource.length === 0) {
        this.loadBusinessData()
      }
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams()// 查询条件
      this.loading = true

      if (this.searchType === 'projName') {
        // 拥有归档负责人权限的用户可以显示所有项目（报告审批结束的项目）
        const hasFileRight = this.roles.map(item => item.roleCode).includes('jx_fileLeader')
        params.authEnable = !hasFileRight
        projectApi.listFlowFileProject(params).then(res => {
          this.dataSource = res.data.data
          this.dataSource.forEach(item => {
            item.projId = item.id
            item.key = 'proj_' + item.id
            item.dataType = '市场项目'
          })
          this.ipagination.total = res.data.totalCount
          this.ipagination.current = res.data.pageNo
          this.ipagination.pageSize = res.data.pageSize
          this.loading = false
        })
        // if (!hasFileRight) {
        //   params.projUserName = this.$store.getters.userInfo.realname
        // }
        // reportTaskApi.listForFlow(params).then(res => {
        //   this.dataSource = res.data.data
        //   this.ipagination.total = res.data.totalCount
        //   this.ipagination.current = res.data.pageNo
        //   this.ipagination.pageSize = res.data.pageSize
        //   this.loading = false
        // })
      } else {
        const hasFileRight = this.roles.map(item => item.roleCode).includes('OA_SP') // OA_SP:小木自用oa系统需查询所有项目
        params.authEnable = !hasFileRight
        projectApi.listForFlow(params).then(res => {
          this.dataSource = res.data.data
          this.dataSource.forEach(item => {
            item.projId = item.id
            item.key = 'proj_' + item.id
            item.dataType = '市场项目'
          })
          this.ipagination.total = res.data.totalCount
          this.ipagination.current = res.data.pageNo
          this.ipagination.pageSize = res.data.pageSize
          this.loading = false
        })
      }
    },

    // 查询通用项目列表
    listCommonProject () {
      commDictApi.list({ dictCode: 'commonProjectCode' }).then(result => {
        if (result.code === 0) {
          const d = result.data
          this.commonProjectList = []
          for (let i = 0; i < d.length; i++) {
            this.commonProjectList.push({ id: d[i].itemValue, projId: d[i].itemValue, projNo: d[i].itemValue, projName: d[i].itemText, projContact: '-', key: 'common_' + d[i].itemValue, dataType: '通用项目' })
          }
        }
      })
    },

    loadBusinessData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipaginationBusiness.current = 1
      }
      const params = this.getBusinessQueryParams()// 查询条件
      const hasFileRight = this.roles.map(item => item.roleCode).includes('OA_SP') // OA_SP:小木自用oa系统需查询所有项目
      params.authEnable = !hasFileRight
      this.loading = true
      businessApi.listIncludeQuote(params).then(res => {
        if (res.code === 0) {
          this.businessDataSource = res.data.data
          this.businessDataSource.forEach(item => {
            item.key = 'business_' + item.id
            item.dataType = '商机'
          })
          this.ipaginationBusiness.total = res.data.totalCount
          this.ipaginationBusiness.current = res.data.pageNo
          this.ipaginationBusiness.pageSize = res.data.pageSize
          this.loading = false
        } else {
          this.$message.error('查询失败')
        }
      })
    },

    getBusinessQueryParams () {
      const param = JSON.parse(JSON.stringify(this.queryParam))
      for (const item in param) {
        if (typeof (param[item]) === 'string') {
          param[item] = param[item].trim()
        }
      }
      param.pageNo = this.ipaginationBusiness.current
      param.pageSize = this.ipaginationBusiness.pageSize
      return param
    },

    handleBusinessTableChange (pagination, filters, sorter) {
      this.ipaginationBusiness = pagination
      this.loadBusinessData()
    },

    onSelectChange (selectedRowKeys, selectionRows) {
      if (this.searchType === 'projName' && selectedRowKeys.length > 1) {
        this.selectedRowKeys = selectedRowKeys.slice(selectedRowKeys.length - 1)
        this.selectionRows = selectionRows.filter(item => this.selectedRowKeys.includes(item.projId))
      } else {
        this.selectedRowKeys = selectedRowKeys
        this.selectionRows = selectionRows
      }
    },

    // searchQuery () {
    //   this.loadData(1)
    // },

    searchReset () {
      this.queryParam = {}
      this.onClearSelected()
      if (this.tabModelCode === '市场项目') {
        this.loadData(1)
      } else if (this.tabModelCode === '商机') {
        this.loadBusinessData(1)
      }
    },

    init (projectList) {
      if (projectList && projectList.length) {
        this.tabModelCode = projectList[0].dataType
        this.callback(this.tabModelCode)
        this.selectionRows.splice(0, this.selectionRows.length, ...projectList)
        this.selectedRowKeys.splice(0, this.selectionRows.length, ...(projectList.map(item => { return item.key })))
      }
      this.$nextTick(() => {
        this.searchProjectModal.visible = true
      })
    },

    handleSubmit () {
      this.$emit('ok', this.selectionRows)
      this.close()
    },

    close () {
      this.searchProjectModal.visible = false
      this.$emit('cancel')
    }
  }
}
</script>
