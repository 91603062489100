const noActionCurveColumns = [
  { title: '曲线编号', dataIndex: 'curveNo', width: 150, scopedSlots: { customRender: 'curveNo' } },
  { title: '使用液', dataIndex: 'solutionName', width: 150, scopedSlots: { customRender: 'solutionName' } },
  { title: '配置人', dataIndex: 'createUserName', width: 100, align: 'center', scopedSlots: { customRender: 'createUserName' } },
  { title: '配置时间', dataIndex: 'makeDate', width: 150, align: 'center', scopedSlots: { customRender: 'makeDate' } },
  { title: '有效期', dataIndex: 'validTime', width: 100, align: 'center', scopedSlots: { customRender: 'validTime' } },
  { title: '复核人', dataIndex: 'reviewUserName', width: 120, align: 'center', scopedSlots: { customRender: 'reviewUserName' } },
  { title: '复核结果', dataIndex: 'reviewStatus', width: 100, align: 'center', scopedSlots: { customRender: 'reviewStatus' } },
  { title: '审核人', dataIndex: 'checkUserName', width: 120, align: 'center', scopedSlots: { customRender: 'checkUserName' } },
  { title: '审核结果', dataIndex: 'checkStatus', width: 100, align: 'center', scopedSlots: { customRender: 'checkStatus' } }
]

const defaultCurveColumns = [...noActionCurveColumns,
  { title: '操作', width: 200, scopedSlots: { customRender: 'action' } }
]

export default defaultCurveColumns
export { noActionCurveColumns }
