<template>
  <div>
    <template v-if="value">
      <div style="text-align: left;">
        <span v-for="(item,index) in value.split(',')" :key="index" >
          <a-tag color="green">{{ item.split("^")[1] }}<a style="margin-left: 3px;color: green" @click="openView(item)" v-action:project:list><a-icon type="eye" /></a></a-tag>
        </span>
      </div>
    </template>
    <a-modal v-bind="viewModal" v-on="viewModal" v-if="viewModal.visible">
      <proj-details-mgr :projId="viewModal.projId"></proj-details-mgr>
    </a-modal>
  </div>
</template>

<script>

import ProjDetailsMgr from '@/pages/cloud-quote/proj-worktable/ProjDetailsMgr'
export default {

  name: 'SearchProjectView',
  components: { ProjDetailsMgr },
  props: {
    value: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      viewModal: {
        ref: 'viewModal',
        visible: false,
        processId: null,
        title: null,
        width: '100%',
        centered: true,
        ok: () => { this.viewModal.visible = false },
        cancel: () => { this.viewModal.visible = false },
        show: () => { this.viewModal.visible = true },
        cancelText: '关闭',
        wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      }
    }
  },

  methods: {
    openView (item, args = item.split('^')) {
      // if (args[0].startsWith('TY')) {
      //   this.$message.info('通用项目无视图信息')
      // } else {
      const s = args[1].lastIndexOf('（') + 1
      if (args[1].substr(s).indexOf('商机') < 0) {
        this.viewModal.projId = args[0]
        this.viewModal.title = `预览： ${args[1]}`
        this.viewModal.show()
      }
    }
  }
}
</script>

<style scoped>

</style>
