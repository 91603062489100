import Vue from 'vue'
import form from '@/pages/cloud-quote/check-standard-solution/modules/form'
import assign from '@/pages/cloud-quote/check-standard-solution/modules/assign'
import review from '@/pages/cloud-quote/check-standard-solution/modules/review'
import check from '@/pages/cloud-quote/check-standard-solution/modules/check'
import detail from '@/pages/cloud-quote/check-standard-solution/modules/detail'
import table from '@/pages/cloud-quote/check-standard-solution/modules/table'
import tableModal from '@/pages/cloud-quote/check-standard-solution/modules/table/modal'
import selectTable from '@/pages/cloud-quote/check-standard-solution/modules/table/selectTable'

// 标液标定的时候存在循环引用，这里注册一下
Vue.component('CheckStandardSolutionTable', table)
Vue.component('CheckStandardSolutionTableModal', tableModal)

export {
  form as CheckStandardSolutionForm,
  assign as CheckStandardSolutionAssign,
  review as CheckStandardSolutionReview,
  check as CheckStandardSolutionCheck,
  detail as CheckStandardSolutionDetail,
  table as CheckStandardSolutionTable,
  tableModal as CheckStandardSolutionTableModal,
  selectTable as CheckStandardSolutionSelectTable
}
