<template>
  <a-modal
    :destroyOnClose="true"
    :maskClosable="false"
    :afterClose="clearInterval"
    :visible="visible"
    :width="800"
    :title="modalTitlte"
    @cancel="() => this.visible = false"
    @ok="handleOK">
    <a-row :gutter="20">
      <a-col :span="6">
        <qriously :value="qrcode" :size="150" />
      </a-col>
      <a-col :span="18">
        <b>*请打开app扫一扫上传图片或者手动上传</b>
        <div class="clearfix" style="margin-top: 5px;">
          <a-upload
            accept="image/*"
            :action="uploadUrl"
            :headers="tokenHeader"
            :multiple="true"
            :showUploadList="true"
            listType="picture-card"
            :fileList="fileList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
              <div class="ant-upload-text" v-if="this.fileLength">
                限 <span style="font-weight: 700px;color:#FF3B25">{{ this.fileLength }}</span> 张
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img :alt="previewImage" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { sysCommonApi, tempImageApi } from '@/api/common'

export default {
  name: 'PictureUpload',
  props: {
    fileLength: {
      type: Number,
      default: 0
    },
    modalTitlte: {
      type: String,
      default: '二维码'
    }
  },
  data () {
    return {
      visible: false,
      qrcode: '',
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      previewVisible: false,
      previewImage: '',
      fileList: []
    }
  },
  methods: {
    show (title, content) {
      this.title = title
      this.qrcode = content
      this.visible = true
      this.fileList = []
    },
    getPicture () {
      const self = this
      if (!!this.fileLength && self.fileList.length > this.fileLength) {
        this.$message.error(`最多上传${this.fileLength}张照片！`)
        return false
      }

      const index = this.qrcode.indexOf('-')
      const relId = this.qrcode.substring(index + 1)
      tempImageApi.list({ relId: relId }).then(res => {
        if (res.code === 0 && res.data && res.data.length > 0) {
          const ids = []
          for (let i = 0; i < res.data.length; i++) {
            ids.push(res.data[i].id)
            const picUrls = res.data[i].image
            const picUrlArr = picUrls.split(',')
            for (let j = 0; j < picUrlArr.length; j++) {
              // 增加data
              if (!self.fileList.find(item => item.uid === picUrlArr[j])) {
                self.fileList.push({
                  uid: picUrlArr[j],
                  name: picUrlArr[j],
                  status: 'done',
                  url: picUrlArr[j],
                  thumbUrl: self.viewUrl + '/' + picUrlArr[j]
                })
              }
            }
          }
          // 删除对应的数据
          if (ids.length > 0) tempImageApi.del({ ids: ids })
        }
      })
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.thumbUrl || sysCommonApi.view + '/' + file.url
      this.previewVisible = true
    },
    handleChange ({ file, fileList }) {
      this.fileList = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.view + '/' + file.response.data.fileUrl
          file.url = file.response.data.fileUrl
          fileList.forEach(item => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },

    clearInterval () {
      clearInterval(window.uploadInterval)
    },
    handleOK () {
      this.$emit('result', this.fileList)
      this.visible = false
    }
  },
  created () {
    window.pu = this
  },
  destroyed () {
    this.clearInterval()
  },
  watch: {
    visible (newValue) {
      if (newValue) {
        window.uploadInterval = setInterval(() => {
          setTimeout(this.getPicture, 0)
        }, 2500)
      }
    }
  }
}
</script>

<style scoped>

</style>

<style>
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
</style>
