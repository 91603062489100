<template>
  <div class="check-standard-solution-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-standard-solution-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table v-bind="contentTable" :rowSelection="selectType ? rowSelection : null" :scroll="scrollProp">
      <template #id="text, record">
        <a @click="detailModal.show(record)">{{ text }}</a>
      </template>

      <template #sumVolume="data, record">
        <span class="block">
          容量：
          <span :style="{ color: getVolumeColor((record.volume / data) * 100) }" class="focus-num">{{ record.volume }}</span> /
          <span class="focus-num">{{ data }}</span>
          <span style="margin-left: 5px">mL</span>
        </span>

        <span class="block"
          >剩余：
          <span :style="{ color: getVolumeColor((record.volume / data) * 100) }" class="focus-num">{{ ((record.volume / data) * 100).toFixed(2) }}% </span>
        </span>
      </template>

      <template #demarcateEnable="text, record">
        <a-tag v-if="record.demarcateEnable" color="blue">标定</a-tag>
        <a-tag v-else color="red">不标定</a-tag>
      </template>

      <template #usageReagent="data, record">
        <span class="block"> 编号：{{ record.usageReagentNos }} </span>
        <span class="block"> 名称：{{ record.usageReagentName }}</span>
      </template>

      <template #makeTime="data, record">
        <span class="block"> 配置时间：{{ data | minuteFilter }} </span>
        <span class="block"> 有效时间：{{ record.validTime | minuteFilter }}</span>
      </template>

      <template #makeStatus="data, record">
        <a-tag v-if="moment(record.validTime) < moment()" color="red">已过期</a-tag>
        <a-tag v-else color="blue">有效</a-tag>
      </template>

      <template #reviewStatus="text">
        <template v-if="checkStandardSolutionReviewStatusDataMap[text]">
          <a-tag :color="checkStandardSolutionReviewStatusDataMap[text].color">{{ checkStandardSolutionReviewStatusDataMap[text].label }}</a-tag>
        </template>
        <template v-else></template>
      </template>

      <template #checkStatus="text">
        <template v-if="checkStandardSolutionCheckStatusDataMap[text]">
          <a-tag :color="checkStandardSolutionCheckStatusDataMap[text].color">{{ checkStandardSolutionCheckStatusDataMap[text].label }}</a-tag>
        </template>
        <template v-else></template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" #[slot]="text, record, index, expanded">
        <slot v-bind="{ text: text, row: record, index: index, expanded }" :name="slot" />
      </template>
    </s-table>
  </div>
</template>

<script>
import sTable from '@/components/Table'
import { checkStandardSolutionDefaultColumn as defaultColumn } from '@/pages/cloud-quote/check-standard-solution/modules/columns'
import { componentTable } from '@/pages/common/mixins/list'
import { checkStandardSolutionApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'

import { CustomizeColumn } from '@/components/Columns'

import {
  checkStandardSolutionCheckStatusDataMap,
  checkStandardSolutionCheckStatusEnum,
  checkStandardSolutionReviewStatusDataMap,
  checkStandardSolutionReviewStatusEnum
} from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import moment from 'moment'

export default {
  mixins: [componentTable],
  components: { sTable, CustomizeColumn },

  props: {
    selectType: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return [null, '', undefined, 'radio', 'checkbox'].includes(value)
      }
    },
    columns: { type: Array, required: false, default: () => defaultColumn },
    compositionName: { type: String, required: false, default: undefined },
    compoundDateStr: { type: Array, required: false, default: () => [] },
    makeDate: { type: String, required: false, default: () => '' }
  },

  data() {
    return {
      checkStandardSolutionReviewStatusEnum,
      checkStandardSolutionReviewStatusDataMap,
      checkStandardSolutionCheckStatusEnum,
      checkStandardSolutionCheckStatusDataMap,

      detailModal: {
        ref: 'detail',
        id: null,
        visible: false,
        templateFile: '',

        show: (record) => {
          this.detailModal.id = record.id
          this.detailModal.templateFile = record.fileTemplateName
          this.detailModal.visible = true
          this.$nextTick(() => {
            this.$refs[this.detailModal.ref].show()
          })
        }
      },

      rowSelection: {
        type: this.selectType,
        getCheckboxProps: (record) => {
          return {
            props: {
              disabled: this.makeDate && !(moment(this.makeDate).isSameOrAfter(record.makeTime) && moment(this.makeDate).isSameOrBefore(record.validTime))
            }
          }
        }
      }
    }
  },

  computed: {
    queryParam() {
      return { ...this.$attrs }
    }
  },

  watch: {
    'rowSelection.selectedRows': {
      handler(param) {
        this.$emit('selectedRows', param)
      }
    },
    makeDate:{
      immediate:true,
      deep:true,
      handler(nv,ov){
        console.log(nv,ov);
        
      }
    }
  },

  methods: {
    moment,
    getVolumeColor(data) {
      return data > 50 ? 'green' : data > 30 ? 'orange' : 'red'
    },

    // 加载数据
    loadData(param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      if (this.compositionName) {
        this.queryParam.compositionName = this.compositionName
      } else {
        this.queryParam.compositionName = ''
      }
      if (this.compoundDateStr.length > 0) {
        if (this.compoundDateStr[0]) this.queryParam.makeDateBegin = this.compoundDateStr[0] + ' 00:00:00'
        if (this.compoundDateStr[1]) this.queryParam.makeDateEnd = this.compoundDateStr[1] + ' 23:59:59'
      } else {
        this.queryParam.makeDateBegin = ''
        this.queryParam.makeDateEnd = ''
      }
      if (!this.queryParam.queryType) {
        this.queryParam.queryType = 0
      }
      return checkStandardSolutionApi.list(Object.assign({}, param, this.queryParam)).then((res) => res)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet';

.check-standard-solution-table {
  position: relative;
  .color();
  .feature();

  .block {
    display: block;
  }

  .setting {
    position: absolute;
    z-index: 9;
    right: 12px;
    top: 11px;
  }
}
</style>
