<template>
  <div>
    <a-row>
      <a-col span="7">
        <div class="ahmux-sub-title" style="width: 150px;">
          培训申请信息
        </div>
      </a-col>
    </a-row>

    <a-row>
      <table class="mxtable">
        <tr>
          <th><span style="color: red">* </span>申请部门</th>
          <td>
            <a-cascader
              :allowClear="true"
              v-model="selectDept"
              :show-search="{ filter }"
              :fieldNames="fieldNames"
              :options="deptList"
              change-on-select
              expand-trigger="hover"
              placeholder="请选择所在部门"
              @change="handleChangeDeptId"
              style="width: 100%"
              :disabled="disabled">
            </a-cascader>
          </td>
          <th>培训课程</th>
          <td>
            <a-input v-model="trainingInfo.trainingCourse" placeholder="请输入培训课程" :disabled="true"></a-input>
          </td>
        </tr>
        <tr>
          <th>申请人</th>
          <td>
            <select-user-by-dept ref="user" :disabled="disabled" :triggerChange="true" @change="handleUserChange" rowType="radio"/>
          </td>
          <th>申请时间</th>
          <td>
            <a-input v-model="trainingApplyInfo.applyDate" :disabled="true"></a-input>
          </td>
        </tr>
        <tr>
          <th><span style="color: red">* </span>申请原因</th>
          <td colspan="3">
            <a-textarea
              v-model="trainingApplyInfo.applyReason"
              placeholder="请输入申请原因"
              :disabled="disabled"
              style="height: 30px"/>
          </td>
        </tr>
        <template v-if="trainingInfo.trainingMode === 0">
          <tr>
            <th colspan="4">内部培训</th>
          </tr>
          <tr>
            <th><span style="color: red">* </span>培训项目</th>
            <td colspan="3">
              <a-input v-model="trainingApplyInfo.trainingProject" placeholder="请输入培训项目" :disabled="disabled"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训内容</th>
            <td colspan="3">
              <a-input v-model="trainingInfo.trainingContent" :disabled="true"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训人数</th>
            <td colspan="3">
              <a-input v-model="trainingInfo.staffList.split(',').length" :disabled="true"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训日期</th>
            <td colspan="3">
              <a-input v-model="trainingInfo.trainingTime" :disabled="true"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训对象</th>
            <td colspan="3">
              <a-input v-model="trainingInfo.staffNameList" :disabled="true"></a-input>
            </td>
          </tr>
          <tr>
            <th>考核情况</th>
            <td colspan="3">
              <a-input v-model="trainingApplyInfo.evaluationCondition" placeholder="请输入考核情况" :disabled="disabled"></a-input>
            </td>
          </tr>
        </template>
        <template v-if="trainingInfo.trainingMode === 1">
          <tr>
            <th colspan="4">外部培训</th>
          </tr>
          <tr>
            <th><span style="color: red">* </span>培训机构</th>
            <td colspan="3">
              <a-input v-model="trainingApplyInfo.trainingAgency" placeholder="请输入培训机构" :disabled="disabled"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训教师</th>
            <td colspan="3">
              <a-input v-model="trainingInfo.lecturer" :disabled="true"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训地点</th>
            <td colspan="3">
              <a-input v-model="trainingInfo.trainingSite" :disabled="true"></a-input>
            </td>
          </tr>
          <tr>
            <th>培训费用</th>
            <td colspan="3">
              <a-input v-model="trainingApplyInfo.trainingCost" placeholder="请输入培训费用" :disabled="disabled"></a-input>
            </td>
          </tr>
          <tr>
            <th>考核方式</th>
            <td colspan="3">
              <a-tag v-if="trainingInfo.evaluationMode === 0" color="green">考察</a-tag>
              <a-tag v-if="trainingInfo.evaluationMode === 1" color="blue">考试</a-tag>
              <a-tag v-if="trainingInfo.evaluationMode === 2" color="orange">取证</a-tag>
              <a-tag v-if="trainingInfo.evaluationMode === 3" color="red">其他</a-tag>
            </td>
          </tr>
          <tr>
            <th>取证情况</th>
            <td colspan="3">
              <a-input v-model="trainingApplyInfo.evidenceStatus" placeholder="请输入取证情况" :disabled="disabled"></a-input>
            </td>
          </tr>
        </template>
        <tr>
          <th>其他培训事项</th>
          <td colspan="3">
            <a-textarea
              v-model="trainingApplyInfo.otherEvents"
              placeholder="请输入其他培训事项"
              :disabled="disabled"
              style="height: 30px"/>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import { userApi, deptApi } from '@/api/system'
import { Get, resStaffTrainingApi } from '@/api/quote'
import { postDataAction } from '@/api/common'
import SelectUserByDept from '@/pages/common/SelectUserByDept'

export default {
  name: 'StaffTrainingApplyModal',
  components: {
    SelectUserByDept
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },
  data () {
    return {
      disabled: false,
      deptList: [],
      selectDept: [],
      staffInfo: {},
      trainingInfo: {},
      trainingApplyInfo: {},
      fieldNames: { 'label': 'deptName', 'value': 'id', 'children': 'children' }
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.loadData()
        }
      }
    }
  },
  created () {
    this.getDeptList()
    this.getStaffList()
  },
  methods: {
    getDeptList () {
      Get(deptApi.list).then(res => {
        if (res.code === 0) {
          this.deptList = res.data
        }
      })
    },
    filter (inputValue, path) {
      return path.some(option => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    handleChangeDeptId (value) {
      if (value && value.length > 0) {
        this.trainingApplyInfo.applyDeptId = value[value.length - 1]
      }
    },
    init (record) {
      this.trainingInfo = record
      this.trainingApplyInfo = {}
      const userList = []
      const user = {
        userId: this.loginUser.id,
        name: this.loginUser.realname
      }
      userList.push(user)
      this.$refs.user.setUserList(userList)
      this.trainingApplyInfo.applyUser = this.loginUser.username
      this.trainingApplyInfo.applyDate = new Date().format('yyyy-MM-dd HH:mm:ss')
      this.trainingApplyInfo.applyDeptId = undefined
      this.trainingApplyInfo.trainingId = record.id
    },
    edit (record) {
      this.getDeptList()
      setTimeout(() => {
        this.getApplyInfo(record.id, false)
      }, 100)
    },
    handleUserChange (userList) {
      if (userList.length > 0) {
        this.trainingApplyInfo.applyUser = userList[0].username
      }
    },
    loadData () {
      this.getDeptList()
      setTimeout(() => {
        this.getApplyInfo(this.recordId, true)
      }, 100)
    },
    getApplyInfo (trainingId, disabled) {
      Get(resStaffTrainingApi.getApplyInfo, { id: trainingId }).then(res => {
        if (res.code === 0) {
          this.disabled = disabled
          this.trainingInfo = res.data.trainingInfo
          this.trainingApplyInfo = res.data.trainingApplyInfo
          Get(userApi.getUserList, { username: this.trainingApplyInfo.applyUser }).then(res => {
            if (res.data && res.data.length > 0) {
              const userList = res.data.map(({ id, realname }) => ({ userId: id, name: realname }))
              this.$refs.user.setUserList(userList)
            }
          })
          this.selectDept = this.findNode(this.deptList, this.trainingApplyInfo.applyDeptId)
        }
      })
    },
    findNode (tree, value) {
      const temp = []
      const fn = function (arr, key) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.key === key) {
            temp.push(item.key)
            fn(tree, item.parentId)
            break
          } else {
            if (item.children && item.children.length > 0) fn(item.children, key)
          }
        }
      }
      fn(tree, value)
      return temp.reverse()
    },
    handleSubmit () {
      if (this.isNullOrEmpty(this.trainingApplyInfo.applyDeptId)) {
        this.$message.error('请选择申请部门！')
        return
      }
      if (this.isNullOrEmpty(this.trainingApplyInfo.applyReason)) {
        this.$message.error('请输入申请原因！')
        return
      }
      if (this.trainingInfo.trainingMode === 0) {
        if (this.isNullOrEmpty(this.trainingApplyInfo.trainingProject)) {
          this.$message.error('请输入培训项目！')
          return
        }
      } else {
        if (this.isNullOrEmpty(this.trainingApplyInfo.trainingAgency)) {
          this.$message.error('请输入培训机构！')
          return
        }
      }
      postDataAction(resStaffTrainingApi.apply, JSON.stringify(this.trainingApplyInfo)).then(res => {
        if (res.code === 0) {
          this.$message.success('提交培训申请成功！')
          this.$emit('ok')
        } else {
          this.$message.error('提交培训申请失败！')
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    isNullOrEmpty (item) {
      return item === null || item === undefined || item === ''
    }
  }
}
</script>

<style scoped>

</style>
