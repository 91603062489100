import { render, staticRenderFns } from "./SolutionTypeSelectTable.vue?vue&type=template&id=df71a824&scoped=true&"
import script from "./SolutionTypeSelectTable.vue?vue&type=script&lang=js&"
export * from "./SolutionTypeSelectTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df71a824",
  null
  
)

export default component.exports