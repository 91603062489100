<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          分包报告
        </div>
      </a-col>
      <a-col span="7"></a-col>
      <a-col span="7"></a-col>
      <a-col span="7"></a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="2">分包报告</th>
          <td class="tdLabel">报告文件</td>
          <td colspan="5">
            <FileList :fileList="subtask.fileReportList"/>
             <!-- 合同上传 -->
          </td>
        </tr>

        <tr>
          <td class="tdLabel">分包结果</td>
          <td colspan="5">
            <a-button @click="entryModal.action.show()">查看</a-button>
          </td>
        </tr>
      </table>
    </a-row>
    <a-modal v-bind="entryModal" v-on="entryModal.action">
      <check-task-subcontract-entry v-bind="entryModal.data" v-on="entryModal.dataAction"></check-task-subcontract-entry>
    </a-modal>
  </div>
</template>

<script>
import FileList from '@/pages/common/file/FileList'
import { CheckTaskSubcontractEntry } from '@/pages/cloud-quote/check-task/modules'
import { fullModalData } from '@/pages/common/mixins/modal/full'

export default {
  name: 'SubTaskResult',
  components: {
    FileList, CheckTaskSubcontractEntry
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      btnSpinning: false,
      FILE_SUBTASK_REPORT_TYPE: 'FBBG',

      entryModal: Object.assign({}, fullModalData, {
        title: '分包录入结果查看',

        data: {
          ref: 'entryForm',
          taskId: null,
          projId: null,
          subcontractor: null,
          type: 'view'
        },
        action: {
          show: () => {
            this.entryModal.data.taskId = this.subtask.id
            this.entryModal.data.projId = this.subtask.projId
            this.entryModal.data.subcontractor = this.subtask.subcontractor
            this.entryModal.visible = true
          },
          ok: () => {
            this.$refs[this.entryModal.data.ref].save()
          },

          cancel: () => { this.entryModal.visible = false }
        },

        dataAction: {
          success: () => {
            this.entryModal.action.cancel()
          }
        }

      })
    }
  },
  methods: {},
  watch: {
    'subtask.id': {
      handler (nv, ov) {
        if (nv) {
        }
      },
      immediate: true
    }
  },
  computed: {},
  mounted () {
  },
  created () {
  }
}
</script>
<style lang="less">
.mxtable {
  width: 100%;
  border: 2px solid #1890FF;
  border-top: 5px solid #1890FF;
  border-collapse: unset;
  margin-top: -1px;

  .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
    background-color: #FEF0D0;
  }

  th {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px dashed #909399;
  }

  td {
    padding: 5px;
    /*min-width: 12%;*/
    /*max-width: 22%;*/
    width: 125px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }

  .tdLabel {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
}
</style>
<style lang="less" scoped></style>
