import Vue from 'vue'
import { authLogin, authInfo, authLogout, refreshToken } from '@/api/login'
import { sysCommonApi } from '@/api/common'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import { Get, dashboardApi } from '@/api/quote'

const user = {
  state: {
    token: '',
    refreshToken: '',
    name: '',
    realname: '',
    welcome: '',
    avatar: '',
    roles: [],
    perms: [],
    info: {},
    motto: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESHTOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken
    },
    SET_NAME: (state, { name, welcome, realname }) => {
      state.name = name
      state.welcome = welcome
      state.realname = realname
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_PERMS: (state, perms) => {
      state.perms = perms
    },
    SET_MOTTO: (state, motto) => {
      state.motto = motto
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        authLogin(userInfo).then(result => {
          if (result.code === 0) {
            Vue.ls.set(REFRESH_TOKEN, result.data.refreshToken, result.data.refreshTimeOut * 1000)
            Vue.ls.set(ACCESS_TOKEN, result.data.token, result.data.expireAt * 1000)
            Vue.ls.set('expireAt', result.data.expireAt * 1000)
            if (userInfo.rememberMe) {
              Vue.ls.set('rememberMe', '1', result.data.refreshTimeOut * 1000)
            }

            commit('SET_TOKEN', result.data.token)
            resolve(result)
          } else {
            reject(result.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    LoginUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        authInfo().then(response => {
          const result = response.data
          if (response.code === 0 && result.roles && result.roles.length > 0) {
            commit('SET_ROLES', result.roles)
            commit('SET_PERMS', result.perms)
            commit('SET_INFO', result)
            commit('SET_NAME', { name: result.username, welcome: welcome(), realname: result.realname })
            commit('SET_AVATAR', result.avatar)
            resolve(result)
          } else {
            reject(new Error('当前用户角色为空'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        Vue.ls.remove(ACCESS_TOKEN)
        Vue.ls.remove(REFRESH_TOKEN)
        Vue.ls.remove('rememberMe')
        authLogout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },

    // token刷新
    async FreshToken ({ commit, state }) {
      return new Promise((resolve) => {
        var refresh_token = Vue.ls.get(REFRESH_TOKEN)
        var refreshInfo = { refreshToken: refresh_token }
        commit('SET_TOKEN', '')
        Vue.ls.remove(ACCESS_TOKEN)
        Vue.ls.remove(REFRESH_TOKEN)
        refreshToken(refreshInfo).then(result => {
          if (result.code === 0) {
            Vue.ls.set(REFRESH_TOKEN, result.data.refreshToken, result.data.refreshTimeOut * 1000)
            Vue.ls.set(ACCESS_TOKEN, result.data.token, result.data.expireAt * 1000)
            commit('SET_TOKEN', result.data.token)
            resolve(result.data)
          } else {
            resolve(result.msg)
          }
        }).catch(error => {
          resolve(error)
        })
      })
    },

    // 获取个性化设置信息
    Personal ({ commit }) {
      return new Promise((resolve, reject) => {
        Get(dashboardApi.getPersonal).then(result => {
          if (result.code === 0) {
            commit('SET_AVATAR', sysCommonApi.view + result.data.imgUrl)
            commit('SET_MOTTO', result.data.motto)
            resolve(result.data)
          } else {
            // reject(new Error('当前用户没有进行个人设置'))
            reject(new Error(''))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登录
    QcLogin ({ commit }, result) {
      return new Promise((resolve, reject) => {
        if (result.code === 0) {
          if(result.data){
            Vue.ls.set(REFRESH_TOKEN, result.data.refreshToken, result.data.refreshTimeOut * 1000)
            Vue.ls.set(ACCESS_TOKEN, result.data.token, result.data.expireAt * 1000)
            Vue.ls.set('expireAt', result.data.expireAt * 1000)
            Vue.ls.set('rememberMe', '1', result.data.refreshTimeOut * 1000)
            commit('SET_TOKEN', result.data.token)
          }
          resolve(result)
        } else {
          reject(result.msg)
        }
      })
    }

  }
}

export default user
