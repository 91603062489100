<template>
  <a-modal v-bind="fullModal" v-on="fullModal">
    <div class="utabs" style="height: 100%">
      <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
        <a-tab-pane :closable="false" :key="idx" :tab="obj.templateName" style="height: 100%" v-for="(obj,idx) of templateList">
          <a-row style="height:100%;">
            <iframe :src="formSrc" v-bind="form" v-if="formSrc" style="width: 100%;height:100%;margin: 0 auto"/>
            <Watermark/>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import Watermark from '@/pages/common/watermark/Watermark'

export default {
  mixins: [fullModal],
  components: { Watermark },

  props: {

    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data () {
    return {
      templateList: [],
      curIdx: 0,

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      fullModal: {
        title: '标液配置详情',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },

        showReview: () => {
          this.reviewDrawer.visible = true
        }
      },
      templateCode: 'template_solution'
    }
  },

  computed: {
    formSrc () {
      if (this.templateList && this.templateList.length > 0) {
        const template = this.templateList[this.curIdx]
        if (this.id) {
          return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&id=${this.id}`
        } else if (this.ids.length) {
          return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&ids=${this.ids.join(',')}`
        }
      }
      return null
    }
  },

  created () {
    this.findTemplate()
  },

  methods: {

    findTemplate () {
      // const param = { templateType: fileTemplateUreportTypeEnum.solution, page: false }
      const param = { templateNameOrCode: this.templateCode, page: false }
      fileTemplateUreportApi.list(param).then(res => {
        this.loadData = false
        if (res.code === 0) {
          this.templateList.splice(0, this.templateList.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>

<style lang="less" scoped>

.utabs {
  .archive {
    margin: 0px 5px;
    border: 1px solid #91D5FF;
    background-color: #E6F7FF;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
  }
}

</style>
