<template>
  <a-modal :visible="visible" :title="title" @cancel="handleCancel" @ok="handleOK" :destroyOnClose="destroyOnClose">
    <div class="table-page-search-wrapper">
      <a-row justify="center">
        <a-col>
          <a-form :form="form">
            <a-form-item label="姓名" v-bind="formItemLayout">
              <a-input
                type="text"
                id="csrUserName"
                placeholder="姓名"
                v-decorator="['csrUserName', {rules: [
                  {required: true, message: '姓名不能为空！'},
                  {max: 10, message: '姓名长度小于10！'}
                ]}]"></a-input>
            </a-form-item>
            <a-form-item label="所属单位" v-bind="formItemLayout">
              <a-select
                id="csrId"
                showSearch
                placeholder="所属单位"
                :filterOption="filterOption"
                :disabled="csrIdDisabled"
                @change="changeCsr"
                v-decorator="['csrId', {initialValue: model.csrId, rules: [
                  {required: true, message: '所属单位不能为空！'}
                ]}]">
                <a-select-option v-for="d in customerList" :key="d.id" :data="d">{{ d.csrName }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="性别" v-bind="formItemLayout">
              <a-radio-group id="csrUserSex" name="csrUserSex" v-decorator="['csrUserSex',{initialValue:'0'}]">
                <a-radio value="0">
                  男
                </a-radio>
                <a-radio value="1">
                  女
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="联系电话" v-bind="formItemLayout">
              <a-input
                type="text"
                id="csrUserTelephone"
                placeholder="联系电话"
                v-decorator="['csrUserTelephone', {rules: [
                  {max: 20, message: '联系电话长度过大！'}
                ]}]"
              ></a-input>
            </a-form-item>
            <a-form-item label="邮箱" v-bind="formItemLayout">
              <a-input
                type="text"
                id="csrUserMail"
                placeholder="邮箱"
                v-decorator="['csrUserMail', {rules: [
                  {max: 32, message: '邮箱长度过大！'}
                ]}]"
              ></a-input>
            </a-form-item>
            <a-form-item label="地址" v-bind="formItemLayout">
              <a-input
                type="text"
                id="csrUserAddr"
                placeholder="地址"
                v-decorator="['csrUserAddr', {rules: [
                  {max: 128, message: '地址长度不得超过128！'}
                ]}]"
              ></a-input>
            </a-form-item>
            <a-form-item label="备注" v-bind="formItemLayout">
              <a-input
                type="text"
                id="csrUserRemark"
                placeholder="备注"
                v-decorator="['csrUserRemark', {rules: [
                  {max: 128, message: '备注长度不得超过128！'}
                ]}]"
              ></a-input>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>

  </a-modal>
</template>
<script>
import { Get, Post, customerApi, customerUserApi } from '@/api/quote'
export default {
  name: 'CustomerUserEdit',
  props: {
    customerList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      visible: false,
      destroyOnClose: true,
      title: '新增人员',
      form: this.$form.createForm(this),
      url: customerUserApi.add,
      model: {},
      id: null,
      addAttr: null,
      csrIdDisabled: false
    }
  },
  methods: {
    add () {
      this.title = '新增人员信息'
      this.visible = true
      this.model = {}
      this.csrIdDisabled = false
      this.url = customerUserApi.add
    },
    addByCsrId (csrId, addAttr) {
      this.title = '新增人员信息'
      this.visible = true
      this.model = { csrId: csrId }
      this.addAttr = addAttr
      this.csrIdDisabled = true
      this.url = customerUserApi.add
    },
    edit (record) {
      this.visible = true
      this.title = '编辑人员信息'
      this.model = record
      this.csrIdDisabled = false
      this.url = customerUserApi.edit
      this.form.$nextTick(() => {
        this.form.setFieldsValue(record)
      })
    },
    handleCancel () {
      this.visible = false
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      const self = this
      this.form.validateFields((error, values) => {
        const formData = Object.assign(values, {
          id: this.model.id
        })
        if (error == null) {
          Post(this.url, formData).then(result => {
            if (result.code === 0) {
              this.$message.success(`${self.title}成功`)
              this.handleCancel()
              this.$emit('ok', result.data, this.addAttr)
            } else {
              this.$message.error(result.msg)
            }
          }).catch(error => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
        }
      })
    },
    listCustomer () {
      customerApi.listCustomer({ page: false, authEnable: false }).then(result => {
        if (result.code === 0) {
          this.customerList = result.data
        }
      })
    },
    changeCsr (v, op) {
      var csrWholeAddr = op.data.attrs.data.csrWholeAddr
      if (!this.model.csrUserAddr) {
        this.form.setFieldsValue({
          csrUserAddr: csrWholeAddr
        })
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
