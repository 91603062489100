<template>
  <div>
    <a-spin :spinning="loading" tip="数据加载中，请稍后...">
      <a-card v-bind="siteQualityControlCard">
        <div slot="extra" style="display: flex;gap: 12px;align-items: center;">
          质控时间：
          <a-date-picker v-model="createTime" format="YYYY-MM-DD HH:mm" v-bind="moreCurrentDatePickerProps" :style="{width:'180px',marginRight:'10px'}"></a-date-picker>
          质控人：
          <select-user-by-dept :disabled="false" :triggerChange="true" row-type="radio" @change="(value)=>createUser=value[0].username"/>
        </div>
        <a-collapse :activeKey="Object.keys(sampleDetail)&&Object.keys(sampleDetail)[0]">
          <a-collapse-Panel v-for="(value,key) of sampleDetail" :key="key">
            <template #header>
              <span :style="{color:'red'}">  {{ value[0].cateName }}</span>
              <div :style="{float:'right',marginRight:'10px'}">
                <template v-if="cateConfigMap[key]&&cateConfigMap[key].length">
                  全程序空白：
                  <a-tag v-if="cateConfigMap[key].length" :color="qualityControlCategoryTypeDataMap[cateConfigMap[key][0].allBlankType].color">{{ qualityControlCategoryTypeDataMap[cateConfigMap[key][0].allBlankType].label }}</a-tag>
                  <a-tooltip v-if="cateConfigMap[key].length">
                    <template #title>介质</template>
                    <a-tag color="blue">{{ cateConfigMap[key][0].allBlankStorage || '待定' }}</a-tag>
                  </a-tooltip>
                  <a-tag v-if="cateConfigMap[key].length" color="blue">
                    {{ cateConfigMap[key][0].allBlankNum }}{{ cateConfigMap[key][0].allBlankNumType === qualityControlCategoryNumTypeEnum.value ? '个' : '%' }}
                  </a-tag>

                  现场空白：
                  <a-tag v-if="cateConfigMap[key].length" :color="qualityControlCategoryTypeDataMap[cateConfigMap[key][0].fieldBlankType].color">{{ qualityControlCategoryTypeDataMap[cateConfigMap[key][0].fieldBlankType].label }}</a-tag>
                  <a-tooltip v-if="cateConfigMap[key].length">
                    <template #title>介质</template>
                    <a-tag color="blue">{{ cateConfigMap[key][0].fieldBlankStorage || '待定' }}</a-tag>
                  </a-tooltip>
                  <a-tag v-if="cateConfigMap[key].length" color="blue">
                    {{ cateConfigMap[key][0].fieldBlankNum }}{{ cateConfigMap[key][0].fieldBlankNumType === qualityControlCategoryNumTypeEnum.value ? '个' : '%' }}
                  </a-tag>

                  运输空白：
                  <a-tag v-if="cateConfigMap[key].length" :color="qualityControlCategoryTypeDataMap[cateConfigMap[key][0].transportBlankType].color">{{ qualityControlCategoryTypeDataMap[cateConfigMap[key][0].transportBlankType].label }}</a-tag>
                  <a-tooltip v-if="cateConfigMap[key].length">
                    <template #title>介质</template>
                    <a-tag color="blue">{{ cateConfigMap[key][0].transportBlankStorage || '待定' }}</a-tag>
                  </a-tooltip>
                  <a-tag color="blue">
                    {{ cateConfigMap[key][0].transportBlankNum }}{{ cateConfigMap[key][0].transportBlankNumType === qualityControlCategoryNumTypeEnum.value ? '个' : '%' }}
                  </a-tag>

                  现场平行：
                  <a-tag v-if="cateConfigMap[key].length" :color="qualityControlCategoryTypeDataMap[cateConfigMap[key][0].fieldParallelType].color">{{ qualityControlCategoryTypeDataMap[cateConfigMap[key][0].fieldParallelType].label }}</a-tag>
                  <a-tag v-if="cateConfigMap[key].length" color="blue">
                    {{ cateConfigMap[key][0].fieldParallelNum }}{{ cateConfigMap[key][0].fieldParallelNumType === qualityControlCategoryNumTypeEnum.value ? '个' : '%' }}
                  </a-tag>
                </template>
                <template v-else>
                  <span :style="{color:'gray',fontSize:'12px'}">暂未配置，默认依据标准执行</span>
                </template>
              </div>
            </template>

            <a-table v-bind="cateTableProps" :dataSource="value">

              <template #groupName="data,record">
                <template v-if="record.groupId">
                  <a-tag color="blue">{{ record.groupName }}</a-tag>
                </template>
                <template v-else>
                  无
                </template>
              </template>

              <template #itemName="data,record">
                {{ data }}
                <a-tag v-if="parseInt(record.itemSample)!==1" :style="{marginLeft:'10px'}" color="red"> 直读项</a-tag>
              </template>

              <template #numFrequency="data,record">
                <a-tooltip>
                  <template #title>{{ record.itemName }}</template>
                  <span class="focus-num"> {{ record.numPeriod }}</span>天 <span class="focus-num">{{ record.numFrequency }}</span>次
                </a-tooltip>

              </template>

              <template #allBlankQc="data,record">
                <div v-if="data.sampled" :style="{position:'relative',minHeight:'22px'}">
                  <a-tooltip>
                    <template #title>
                      <p>点我添加质控样</p>
                    </template>
                    <a :style="{position:'absolute',right:'0px'}" @click="addBlankQc(data,record,sampleInfoTypeEnum.allBlank)">
                      <a-icon type="plus-circle"/>
                    </a>
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>
                      <template v-if="data.type==='suggest'">
                        <p> 预计样品数 ：{{ record.numPeriod * record.numFrequency }}</p>
                        <p> 配置条件 ：≥ {{ data.originalNum }} {{ data.numberType === '1' ? '个' : '%' }}</p>
                        <p> 建议空白样：{{ data.sampleNum }}个</p>
                      </template>
                      <p v-else>此处可选,根据实际情况可以添加</p>
                    </template>
                    <template v-for="(item,index) in data.sampleList">
                      <div :key="index">
                        <template v-if="item.editable">
                          <div :style="{margin:'10px 0 10px 0'}">
                            {{ index + 1 }}、
                            <span>介质：</span>
                            <a-input v-model="item.storage" :style="{width:'80px'}" size="small"></a-input>
                            <div>
                              批次： D
                              <a-input-number v-model="item.numPeriod" :max="record.numPeriod" :min="1" :style="{width:'50px'}" size="small"/>
                              -
                              <a-input-number v-model="item.numFrequency" :max="record.numFrequency" :min="1" :style="{width:'50px'}" size="small"/>
                              <a :style="{marginLeft:'5px'}" @click="handleFiledParallelItemOk(item)">
                                <a-icon type="check-circle"/>
                              </a>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <a-tag :key="`info_${index}`" :color="item | filterInfoColor(data)" :style="{margin:'0 10px 10px 0'}">
                            {{ index + 1 }}、
                            <span>介质：</span>{{ item.storage || '待定' }}
                            <span>批次：</span>D{{ item.numPeriod }}-{{ item.numFrequency }}
                          </a-tag>
                          <a :key="`del_action_${index}`" :style="{marginRight:'10px'}" @click="data.sampleList.splice(index,1)">
                            <a-icon type="close-circle"/>
                          </a>
                        </template>

                      </div>
                    </template>
                  </a-tooltip>
                </div>
                <template v-else>
                  <a-tag color="gray">不做</a-tag>
                </template>
              </template>

              <template #transportBlankQc="data,record">
                <div v-if="data.sampled" :style="{position:'relative',minHeight:'22px'}">
                  <a-tooltip>
                    <template #title>
                      <p>点我添加质控样</p>
                    </template>
                    <a :style="{position:'absolute',right:'0px'}" @click="addBlankQc(data,record,sampleInfoTypeEnum.transportBlank)">
                      <a-icon type="plus-circle"/>
                    </a>
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>
                      <template v-if="data.type==='suggest'">
                        <p> 预计样品数 ：{{ record.numPeriod * record.numFrequency }}</p>
                        <p> 配置条件 ：≥ {{ data.originalNum }} {{ data.numberType === '1' ? '个' : '%' }}</p>
                        <p> 建议空白样：{{ data.sampleNum }}个</p>
                      </template>
                      <p v-else>此处可选,根据实际情况可以添加</p>
                    </template>
                    <template v-for="(item,index) in data.sampleList">
                      <div :key="index">
                        <template v-if="item.editable">
                          <div :style="{margin:'10px 0 10px 0'}">
                            {{ index + 1 }}、
                            <span>介质：</span>
                            <a-input v-model="item.storage" :style="{width:'80px'}" size="small"></a-input>
                            <div>
                              批次： D
                              <a-input-number v-model="item.numPeriod" :max="record.numPeriod" :min="1" :style="{width:'50px'}" size="small"/>
                              -
                              <a-input-number v-model="item.numFrequency" :max="record.numFrequency" :min="1" :style="{width:'50px'}" size="small"/>
                              <a :style="{marginLeft:'5px'}" @click="handleFiledParallelItemOk(item)">
                                <a-icon type="check-circle"/>
                              </a>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <a-tag :key="`info_${index}`" :color="item | filterInfoColor(data)" :style="{margin:'0 10px 10px 0'}">
                            {{ index + 1 }}、
                            <span>介质：</span>{{ item.storage || '待定' }}
                            <span>批次：</span>D{{ item.numPeriod }}-{{ item.numFrequency }}
                          </a-tag>
                          <a :key="`del_action_${index}`" :style="{marginRight:'10px'}" @click="data.sampleList.splice(index,1)">
                            <a-icon type="close-circle"/>
                          </a>
                        </template>
                      </div>
                    </template>
                  </a-tooltip>
                </div>
                <template v-else>
                  <a-tag color="gray">不做</a-tag>
                </template>

              </template>

              <template #fieldBlankQc="data,record">

                <div v-if="data.sampled" :style="{position:'relative',minHeight:'22px'}">
                  <a-tooltip>
                    <template #title>
                      <p>点我添加质控样</p>
                    </template>
                    <a :style="{position:'absolute',right:'0px'}" @click="addBlankQc(data,record,sampleInfoTypeEnum.allBlank)">
                      <a-icon type="plus-circle"/>
                    </a>
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>
                      <template v-if="data.type==='suggest'">
                        <p> 预计样品数 ：{{ record.numPeriod * record.numFrequency }}</p>
                        <p> 配置条件 ：≥ {{ data.originalNum }} {{ data.numberType === '1' ? '个' : '%' }}</p>
                        <p> 建议空白样：{{ data.sampleNum }}个</p>
                      </template>
                      <p v-else>此处可选,根据实际情况可以添加</p>
                    </template>
                    <template v-for="(item,index) in data.sampleList">
                      <div :key="index">
                        <template v-if="item.editable">
                          <div :style="{margin:'10px 0 10px 0'}">
                            {{ index + 1 }}、
                            <span>介质：</span>
                            <a-input v-model="item.storage" :style="{width:'80px'}" size="small"></a-input>
                            <div>
                              批次： D
                              <a-input-number v-model="item.numPeriod" :max="record.numPeriod" :min="1" :style="{width:'50px'}" size="small"/>
                              -
                              <a-input-number v-model="item.numFrequency" :max="record.numFrequency" :min="1" :style="{width:'50px'}" size="small"/>
                              <a :style="{marginLeft:'5px'}" @click="handleFiledParallelItemOk(item)">
                                <a-icon type="check-circle"/>
                              </a>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <a-tag :key="`info_${index}`" :color="item | filterInfoColor(data)" :style="{margin:'0 10px 10px 0'}">
                            {{ index + 1 }}、
                            <span>介质：</span>{{ item.storage || '待定' }}
                            <span>批次：</span>D{{ item.numPeriod }}-{{ item.numFrequency }}
                          </a-tag>
                          <a :key="`del_action_${index}`" :style="{marginRight:'10px'}" @click="data.sampleList.splice(index,1)">
                            <a-icon type="close-circle"/>
                          </a>
                        </template>
                      </div>
                    </template>
                  </a-tooltip>
                </div>
                <template v-else>
                  <a-tag color="gray">不做</a-tag>
                </template>
              </template>

              <template #fieldParallelQc="data,record">
                <div v-if="data.sampled" :style="{position:'relative',minHeight:'22px'}">
                  <a-tooltip>
                    <template #title>
                      <p>点我添加质控样</p>
                    </template>
                    <a :style="{position:'absolute',right:'0px'}" @click="addFieldParallelQc(data,record)">
                      <a-icon type="plus-circle"/>
                    </a>
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>
                      <template v-if="data.type==='suggest'">
                        <p> 预计样品数 ：{{ record.numPeriod * record.numFrequency }}</p>
                        <p> 配置条件 ：≥ {{ data.originalNum }} {{ data.numberType === '1' ? '个' : '%' }}</p>
                        <p> 建议平行样：{{ data.sampleNum }}个</p>
                      </template>
                      <p v-else>此处可选,根据实际情况可以添加</p>
                    </template>
                    <template v-for="(item,index) in data.sampleList">
                      <template v-if="item.editable">
                        <div :key="`edit_${index}`">
                          D
                          <a-input-number v-model="item.numPeriod" :max="record.numPeriod" :min="1" :style="{width:'50px'}" size="small"/>
                          -
                          <a-input-number v-model="item.numFrequency" :max="record.numFrequency" :min="1" :style="{width:'50px'}" size="small"/>
                          <a :style="{marginLeft:'5px'}" @click="handleFiledParallelItemOk(item)">
                            <a-icon type="check-circle"/>
                          </a>
                        </div>
                      </template>
                      <template v-else>
                        <a-tag :key="`info_${index}`" :color="item | filterInfoColor(data)" :style="{margin:'0 10px 10px 0'}">{{ index + 1 }}、{{ item.day || '待定' }}</a-tag>
                        <a :key="`del_action_${index}`" :style="{marginRight:'10px'}" @click="data.sampleList.splice(index,1)">
                          <a-icon type="close-circle"/>
                        </a>
                      </template>
                    </template>
                  </a-tooltip>
                </div>
                <template v-else>
                  <a-tag color="gray">不做</a-tag>
                </template>
              </template>

            </a-table>
          </a-collapse-Panel>
        </a-collapse>

      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { groupBy, orderBy, throttle } from 'lodash'
import mixinForm from '@/pages/common/mixins/form'

import { qualityControlApi } from '@/api/quality/qualityControlApi'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { qualityControlCategoryNumTypeDataMap, qualityControlCategoryNumTypeEnum, qualityControlCategoryTypeDataMap, qualityControlCategoryTypeEnum } from '@/api/quality/qualityControlConstant'
import { qualityControlSampleApi } from '@/api/quality/qualityControlLinkApi'

import SelectUserByDept from '@/pages/common/SelectUserByDept'

export default {
  components: { SelectUserByDept },
  mixins: [mixinForm],
  props: {
    projId: {
      type: Number,
      required: true
    },

    id: {
      type: Number,
      required: false,
      default: 0
    },

    type: {
      type: String,
      required: false,
      default: 'add'
    }
  },

  data () {
    return {

      cateConfigMap: {},
      sampleDetail: {},
      sampleInfoTypeEnum,
      qualityControlCategoryTypeEnum,
      qualityControlCategoryTypeDataMap,
      qualityControlCategoryNumTypeEnum,
      qualityControlCategoryNumTypeDataMap,

      createTime: this.now(),
      createUser: this.$store.getters.userInfo.username,

      loading: false,
      cateTableProps: {
        bordered: true,
        pagination: false,
        rowKey: (record) => (`${record.siteId}_${record.cateId}_${record.itemId}`),
        columns: [
          {
            title: '点位',
            align: 'center',
            dataIndex: 'siteName',
            customCell: (row, index) => {
              const result = {
                attrs: {},
                style: {}
              }
              const allDataSource = this.sampleDetail[row.cateId]
              const dataSource = allDataSource.filter(item => item.siteId === row.siteId)
              // 多个相同项需要合并
              if (dataSource.length > 1) {
                const currentIdx = dataSource.findIndex(item => item.cateId === row.cateId && item.siteId === row.siteId && item.itemId === row.itemId)
                if (currentIdx === 0) {
                  result.attrs.rowSpan = dataSource.length
                } else {
                  result.attrs.rowSpan = 0
                  result.style.display = 'none'
                }
              } else {
                result.attrs.rowSpan = 1
              }
              return result
            }
          },
          {
            title: '分组情况',
            align: 'center',
            dataIndex: 'groupName',
            scopedSlots: { customRender: 'groupName' },
            customCell: (row, index) => {
              const result = {
                attrs: {},
                style: {}
              }
              const allDataSource = this.sampleDetail[row.cateId]
              const siteDataSource = allDataSource.filter(item => item.siteId === row.siteId)
              const dataSource = siteDataSource.filter(item => (!item.groupId && !row.groupId) || (item.groupId !== null && item.groupId === row.groupId))
              // 多个相同项需要合并
              if (dataSource.length > 1) {
                const currentIdx = dataSource.findIndex(item => item.cateId === row.cateId && item.siteId === row.siteId && item.itemId === row.itemId)
                if (currentIdx === 0) {
                  result.attrs.rowSpan = dataSource.length
                } else {
                  // rowSpan 属性 在此处不会重写到tdProps 故添加 style 进行隐藏
                  result.attrs.rowSpan = 0
                  result.style.display = 'none'
                }
              } else {
                result.attrs.rowSpan = 1
              }
              return result
            }
          },
          { title: '监测项目', align: 'center', dataIndex: 'itemName', scopedSlots: { customRender: 'itemName' } },
          {
            title: '监测频次',
            align: 'center',
            dataIndex: 'numFrequency',
            scopedSlots: { customRender: 'numFrequency' },
            customCell: (row, index) => {
              const result = {
                attrs: {},
                style: {}
              }
              const allDataSource = this.sampleDetail[row.cateId]
              const siteDataSource = allDataSource.filter(item => item.siteId === row.siteId)
              const dataSource = siteDataSource.filter(item => ((!item.groupId && !row.groupId) || (item.groupId !== null && item.groupId === row.groupId)) && item.numFrequency === row.numFrequency && item.numPeriod === row.numPeriod)
              // 多个相同项需要合并
              if (dataSource.length > 1) {
                const currentIdx = dataSource.findIndex(item => item.cateId === row.cateId && item.siteId === row.siteId && item.itemId === row.itemId)
                if (currentIdx === 0) {
                  result.attrs.rowSpan = dataSource.length
                } else {
                  // rowSpan 属性 在此处不会重写到tdProps 故添加 style 进行隐藏
                  result.attrs.rowSpan = 0
                  result.style.display = 'none'
                }
              } else {
                result.attrs.rowSpan = 1
              }
              return result
            }
          },
          {
            title: '质控措施',
            children: [
              { title: '全程序空白', align: 'center', width: 300, dataIndex: 'allBlankQc', scopedSlots: { customRender: 'allBlankQc' } },
              { title: '运输空白', align: 'center', width: 300, dataIndex: 'transportBlankQc', scopedSlots: { customRender: 'transportBlankQc' } },
              { title: '现场空白', align: 'center', width: 300, dataIndex: 'fieldBlankQc', scopedSlots: { customRender: 'fieldBlankQc' } },
              { title: '现场平行', align: 'center', width: 220, dataIndex: 'fieldParallelQc', scopedSlots: { customRender: 'fieldParallelQc' } }
            ]
          }
        ]
      },

      // 现场环节卡片
      siteQualityControlCard: {
        hoverable: true,
        title: '现场质控',
        size: 'small',
        type: 'inner'
      }
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (newValue) {
        newValue && this.initData(newValue)
      }
    }
  },

  filters: {
    filterInfoColor: function (item, data) {
      return item.add ? 'blue' : 'orange'
    }
  },

  mounted () {
    this.save = throttle(this.save, 6000, { leading: true, trailing: false })
  },

  methods: {

    initData (projId) {
      if (this.type === 'edit') {
        this.initEditData(projId)
      } else {
        this.initAddData(projId)
      }
    },

    // 初始化新增数据
    initAddData (projId) {
      this.loading = true
      this.searchDefaultData(projId).then(res => {
        if (res.code === 0) {
          this.cateConfigMap = res.data?.cateConfigMap
          this.sampleDetail = Object.keys(res.data?.sampleDetail).reduce((acc, key) => {
            acc[key] = orderBy(res.data?.sampleDetail[key], ['siteId', 'groupId', 'numPeriod', 'numFrequency'], ['asc', 'asc', 'desc', 'desc'])
            return acc
          }, {})
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 初始化修改数据
    initEditData (projId) {
      this.loading = true
      Promise.all([this.searchDefaultData(projId), this.searchSaveData(projId)]).then(res => {
        // eslint-disable-next-line standard/array-bracket-even-spacing
        const [defaultSettingRes, savedSettingRes] = res
        if (defaultSettingRes.code !== 0 || savedSettingRes.code !== 0) {
          this.$message.error('加载数据失败!')
          return false
        }
        const defaultSettingData = defaultSettingRes.data
        const savedSettingData = savedSettingRes.data
        this.cateConfigMap = defaultSettingData?.cateConfigMap
        const sampleDetail = defaultSettingData?.sampleDetail
        // 扁平 保存的数据
        savedSettingData.forEach(item => {
          const itemData = item.items[0]
          item.cateId = itemData.cateId
          item.itemId = itemData.itemId
          item.cateName = itemData.cateName
          item.itemName = itemData.itemName
        })
        const saveDataGroup = groupBy(savedSettingData, 'cateId') || {}

        // 合并保存的数据  TODO:代码需要优化,需要抽取公共部分
        Object.values(sampleDetail).flat(Infinity).forEach(sampleInfo => {
          const cateData = saveDataGroup[sampleInfo.cateId]
          const itemData = cateData?.filter(cate => cate.siteId === sampleInfo.siteId && cate.itemId === sampleInfo.itemId) || []

          if (sampleInfo.transportBlankQc.sampleList !== null) {
            const filterData = itemData.length && itemData.filter(item => item.type === sampleInfoTypeEnum.transportBlank)
            sampleInfo.transportBlankQc.sampleList = []
            filterData && filterData.length && filterData.forEach(innerData =>
              sampleInfo.transportBlankQc.sampleList.push({
                cateId: innerData.cateId,
                itemId: innerData.itemId,
                projId: innerData.projId,
                siteId: innerData.siteId,
                storage: innerData.storage,
                batchNo: innerData.batchNo || 1,
                numFrequency: innerData.numFrequency,
                numPeriod: innerData.numPeriod,
                day: `D${innerData.numPeriod}-${innerData.numFrequency}`,
                type: sampleInfoTypeEnum.transportBlank
              })
            )
          }

          if (sampleInfo.allBlankQc.sampleList !== null) {
            const filterData = itemData.length && itemData.filter(item => item.type === sampleInfoTypeEnum.allBlank)
            sampleInfo.allBlankQc.sampleList = []
            filterData && filterData.length && filterData.forEach(innerData =>
              sampleInfo.allBlankQc.sampleList.push({
                cateId: innerData.cateId,
                itemId: innerData.itemId,
                projId: innerData.projId,
                siteId: innerData.siteId,
                storage: innerData.storage,
                batchNo: innerData.batchNo || 1,
                numFrequency: innerData.numFrequency,
                numPeriod: innerData.numPeriod,
                day: `D${innerData.numPeriod}-${innerData.numFrequency}`,
                type: sampleInfoTypeEnum.allBlank
              })
            )
          }

          if (sampleInfo.fieldBlankQc.sampleList !== null) {
            const filterData = itemData.length && itemData.filter(item => item.type === sampleInfoTypeEnum.fieldBlank)
            sampleInfo.fieldBlankQc.sampleList = []
            filterData && filterData.length && filterData.forEach(innerData =>
              sampleInfo.fieldBlankQc.sampleList.push({
                cateId: innerData.cateId,
                itemId: innerData.itemId,
                projId: innerData.projId,
                siteId: innerData.siteId,
                storage: innerData.storage,
                batchNo: innerData.batchNo || 1,
                numFrequency: innerData.numFrequency,
                numPeriod: innerData.numPeriod,
                day: `D${innerData.numPeriod}-${innerData.numFrequency}`,
                type: sampleInfoTypeEnum.fieldBlank
              })
            )
          }

          if (sampleInfo.fieldParallelQc.sampleList !== null) {
            const filterData = itemData.length && itemData.filter(item => item.type === sampleInfoTypeEnum.fieldParallel)
            sampleInfo.fieldParallelQc.sampleList = []
            filterData && filterData.length && filterData.forEach(innerData =>
              sampleInfo.fieldParallelQc.sampleList.push({
                cateId: innerData.cateId,
                itemId: innerData.itemId,
                projId: innerData.projId,
                siteId: innerData.siteId,
                storage: innerData.storage,
                numFrequency: innerData.numFrequency,
                numPeriod: innerData.numPeriod,
                day: `D${innerData.numPeriod}-${innerData.numFrequency}`,
                type: sampleInfoTypeEnum.fieldParallel
              })
            )
          }
        })
        this.sampleDetail = Object.keys(sampleDetail).reduce((acc, key) => {
          acc[key] = orderBy(sampleDetail[key], ['siteId', 'groupId', 'numPeriod', 'numFrequency'], ['asc', 'asc', 'desc', 'desc'])
          return acc
        }, {})
      }).finally(() => {
        this.loading = false
      })
    },

    // 查询 默认配置数据
    searchDefaultData (projId) {
      return qualityControlApi.findSetting({ projId }).then(res => {
        return res
      })
    },

    // 查询已保存数据
    searchSaveData (projId) {
      return qualityControlSampleApi.queryDetails({ projId }).then(res => {
        return res
      })
    },

    // 添加 空白样
    addBlankQc (data, record, sampleType) {
      data.sampleList && data.sampleList.push({
        siteId: record.siteId,
        cateId: record.cateId,
        itemId: record.itemId,
        storage: data.qcStorage,
        type: sampleType,
        batchNo: 1,
        numPeriod: 1,
        numFrequency: 1,
        day: 'D1-1',
        editable: true,
        add: true
      })
    },

    // 空白样完成编辑
    handleBlankItemOk (item) {
      item.editable = false
    },

    // 添加 平行样
    addFieldParallelQc (data, record) {
      data.sampleList && data.sampleList.push({
        siteId: record.siteId,
        cateId: record.cateId,
        itemId: record.itemId,
        type: sampleInfoTypeEnum.fieldParallel,
        numPeriod: 1,
        numFrequency: 1,
        day: 'D1-1',
        editable: true,
        add: true
      })
    },

    // 平行样完成编辑
    handleFiledParallelItemOk (item) {
      item.editable = false
      item.day = `D${item.numPeriod}-${item.numFrequency}`
    },

    // 获取数据
    save () {
      if (this.loading) {
        this.$message.error('数据加载中，请稍后再试！')
        return false
      }
      const values = Object.values(this.sampleDetail).flat(Infinity)

      const fieldParallelSamples = values.reduce((acc, data) => {
        const sampleList = data.fieldParallelQc.sampleList
        sampleList && sampleList.length && (acc.push.apply(acc, sampleList.map(item => {
          return {
            numPeriod: item.numPeriod,
            numFrequency: item.numFrequency,
            storage: item.storage,
            siteId: data.siteId,
            items: [{
              cateId: item.cateId,
              itemId: item.itemId
            }]
          }
        })))
        return acc
      }, [])

      // 校验是否还有平行样还未保存
      const editedFieldParallel = fieldParallelSamples.find(item => item.editable)
      if (editedFieldParallel) {
        const itemInfo = values.find(item => { return item.fieldParallelQc && item.fieldParallelQc.sampleList && item.fieldParallelQc.sampleList.includes(editedFieldParallel) })
        const message = `未保存的平行样信息：【${itemInfo.cateName}】下，点位:【${itemInfo.siteName}】,监测项目:【${itemInfo.itemName}】`
        this.$message.error(message)
        return false
      }

      const allBlankSamples = values.reduce((acc, data) => {
        const sampleList = data.allBlankQc.sampleList
        sampleList && sampleList.length && (acc.push.apply(acc, sampleList.map(item => {
          return {
            storage: item.storage,
            siteId: data.siteId,
            batchNo: item.numPeriod,
            numPeriod: item.numPeriod,
            numFrequency: item.numFrequency,
            items: [{
              cateId: item.cateId,
              itemId: item.itemId
            }]
          }
        })))
        return acc
      }, [])

      const transportBlankSamples = values.reduce((acc, data) => {
        const sampleList = data.transportBlankQc.sampleList
        sampleList && sampleList.length && (acc.push.apply(acc, sampleList.map(item => {
          return {
            storage: item.storage,
            siteId: data.siteId,
            batchNo: item.numPeriod,
            numPeriod: item.numPeriod,
            numFrequency: item.numFrequency,
            items: [{
              cateId: item.cateId,
              itemId: item.itemId
            }]
          }
        })))
        return acc
      }, [])

      const fieldBlankSamples = values.reduce((acc, data) => {
        const sampleList = data.fieldBlankQc.sampleList
        sampleList && sampleList.length && (acc.push.apply(acc, sampleList.map(item => {
          return {
            storage: item.storage,
            siteId: data.siteId,
            batchNo: item.numPeriod,
            numPeriod: item.numPeriod,
            numFrequency: item.numFrequency,
            items: [{
              cateId: item.cateId,
              itemId: item.itemId
            }]
          }
        })))
        return acc
      }, [])

      const saveData = {
        projId: this.projId,
        createUser: this.createUser,
        createTime: this.createTime,
        sampleLink: {
          fieldBlank: fieldBlankSamples,
          transportBlank: transportBlankSamples,
          allBlank: allBlankSamples,
          fieldParallel: fieldParallelSamples
        }
      }
      qualityControlApi.saveSampleLink(saveData).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
@import "_index";
</style>
