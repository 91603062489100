import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 样品状态字典枚举
export const sampleInfoStatusEnum = {
  // 已采样
  doneSample: '0',
  // 待入库
  storageApply: '1',
  // 已入库 在库
  storaged: '2',
  // 待出库
  pickupApply: '3',
  // 已出库
  outbound: '4',
  // 已销毁
  destroyed: '5'
}

// 样品状态选择项
export const sampleInfoStatusOptions = [
  { label: '已采样', value: sampleInfoStatusEnum.doneSample, color: 'blue' },
  { label: '待入库', value: sampleInfoStatusEnum.storageApply, color: 'purple' },
  { label: '在库', value: sampleInfoStatusEnum.storaged, color: 'green' },
  { label: '待出库', value: sampleInfoStatusEnum.pickupApply, color: 'orange' },
  { label: '已出库', value: sampleInfoStatusEnum.outbound, color: '#108EE9' },
  { label: '已销毁', value: sampleInfoStatusEnum.destroyed, color: '#FF5500' }
]

export const sampleInfoStatusMap = tranOptionsToMap(sampleInfoStatusOptions)
export const sampleInfoStatusDataMap = tranOptionsToDataMap(sampleInfoStatusOptions)

// 样品来源字典枚举
export const sampleInfoSourceEnum = {
  // 采样
  sampling: '1',
  // 送样
  sampleDelivery: '2',
  // 制样
  makeSample: '3'
}

// 样品来源选择项
export const sampleInfoSourceOptions = [
  { label: '采样', value: sampleInfoSourceEnum.sampling, color: 'green' },
  { label: '送样', value: sampleInfoSourceEnum.sampleDelivery, color: 'blue' },
  { label: '制样', value: sampleInfoSourceEnum.makeSample, color: 'orange' }
]

export const sampleInfoSourceMap = tranOptionsToMap(sampleInfoSourceOptions)
export const sampleInfoSourceDataMap = tranOptionsToDataMap(sampleInfoSourceOptions)

// 样品分包字典枚举
export const sampleInfoSubcontractEnum = {
  // 采样
  no: 0,
  // 送样
  yes: 1
}

// 样品分包选择项
export const sampleInfoSubcontractOptions = [
  { label: '否', value: sampleInfoSubcontractEnum.no, color: 'blue' },
  { label: '分包', value: sampleInfoSubcontractEnum.yes, color: 'red' }
]

export const sampleInfoSubcontractMap = tranOptionsToMap(sampleInfoSubcontractOptions)
export const sampleInfoSubcontractDataMap = tranOptionsToDataMap(sampleInfoSubcontractOptions)

// 样品留样字典枚举
export const sampleInfoReservedEnum = {
  // 留样
  yes: true,
  // 未留样
  no: false
}

// 样品留样选择项
export const sampleInfoReservedOptions = [
  { label: '未留样', value: sampleInfoReservedEnum.no, color: 'red' },
  { label: '留样', value: sampleInfoReservedEnum.yes, color: 'blue' }
]

export const sampleInfoReservedMap = tranOptionsToMap(sampleInfoReservedOptions)
export const sampleInfoReservedDataMap = tranOptionsToDataMap(sampleInfoReservedOptions)

// 样品类型字典枚举
export const sampleInfoTypeEnum = {
  // 虚拟
  virtual: '-1',
  // 原样
  normal: '1',
  // 现场空白
  fieldBlank: '2',
  // 运输空白
  transportBlank: '3',
  // 全程序空白
  allBlank: '4',
  // 现场平行
  fieldParallel: '5',
  // 实验室平行
  labParallel: '6',
  // 实验室空白
  labBlank: '7',
  // 标准样品
  standardSample: '8',
  // 密码样
  password: '9',
  // 质控样
  qualityControl: '10',
  // 样品加标
  addStandardSample: '11',
  // 基质加标
  basisAddStandardSample: '12'
}

// 样品来源选择项
export const sampleInfoTypeOptions = [
  { label: '原样', value: sampleInfoTypeEnum.normal, color: 'green' },
  { label: '现场空白', value: sampleInfoTypeEnum.fieldBlank, color: 'blue' },
  { label: '运输空白', value: sampleInfoTypeEnum.transportBlank, color: 'blue' },
  { label: '全程序空白', value: sampleInfoTypeEnum.allBlank, color: 'blue' },
  { label: '现场平行', value: sampleInfoTypeEnum.fieldParallel, color: 'blue' },
  { label: '实验室平行', value: sampleInfoTypeEnum.labParallel, color: 'orange' },
  { label: '实验室空白', value: sampleInfoTypeEnum.labBlank, color: 'orange' },
  { label: '标准样品', value: sampleInfoTypeEnum.standardSample, color: 'orange' },
  { label: '密码样', value: sampleInfoTypeEnum.password, color: 'orange' },
  { label: '质控样', value: sampleInfoTypeEnum.qualityControl, color: 'orange' },
  { label: '样品加标', value: sampleInfoTypeEnum.addStandardSample, color: 'orange' },
  { label: '基质加标', value: sampleInfoTypeEnum.basisAddStandardSample, color: 'orange' }
]

export const sampleInfoTypeMap = tranOptionsToMap(sampleInfoTypeOptions)
export const sampleInfoTypeDataMap = tranOptionsToDataMap(sampleInfoTypeOptions)

// 检测环节的检测员自己添加的质控样
export const labQcTypeEnum = [
  sampleInfoTypeEnum.labBlank, sampleInfoTypeEnum.labParallel, sampleInfoTypeEnum.standardSample,
  sampleInfoTypeEnum.qualityControl, sampleInfoTypeEnum.addStandardSample, sampleInfoTypeEnum.basisAddStandardSample
]

export const checkOrderAbleStatusEnum = [
  sampleInfoStatusEnum.storaged,
  sampleInfoStatusEnum.pickupApply,
  sampleInfoStatusEnum.outbound
]

/** 需要实验室进行检测的样品类型 */
export const needLabTypeEnum = [
  sampleInfoTypeEnum.normal,
  sampleInfoTypeEnum.fieldBlank,
  sampleInfoTypeEnum.transportBlank,
  sampleInfoTypeEnum.allBlank,
  sampleInfoTypeEnum.fieldParallel
]
