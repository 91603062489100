<template>
  <div>
    <template v-if="dataSouce === 1">
      <a-card title="检测项点位/检测项">
        <div slot="extra">
          <!--            <a v-if="record.taskId" @click="fullModal.show(record.id)" v-action:proj-task:download>-->
          <!--              <a-icon type="alert"/>-->
          <!--              质控-->
          <!--            </a>-->
          <a-button size="small" @click="showStandardExecl" style="margin-left: 10px">评价标准</a-button>
          <a-button size="small" @click="cateSiteSort" v-if="isEdit">点位排序</a-button>
          <!-- 根据需求，隐藏选择标准 -->
          <!-- <a-button size="small" @click="fullModal.show(project.id)"><a-icon type="alert" />选择标准</a-button> -->
          <a-button size="small" @click="methodModal.show(project.id)" v-if="isEdit">分析方法</a-button>
        </div>
        <div class="card-blue">
          <table class="ahmux-table">
            <tr>
              <th class="tdLabel" style="width: 80px">分类</th>
              <th class="tdLabel" style="width: 200px">点位</th>
              <th class="tdLabel" style="width: 1020px">检测内容</th>
              <th class="tdLabel" style="width: 200px">点位关联标准</th>
            </tr>
            <template v-for="(cate, cateIdx) in project.cateList">
              <template v-if="!cate.siteList || cate.siteList.length == 0">
                <tr :id="`cate_${cateIdx}`" :key="cateIdx">
                  <td>{{ cate.name }}</td>
                  <td colspan="3"></td>
                </tr>
              </template>
              <template v-else>
                <tr :id="`cate_${cateIdx}_site_${siteIdx}`" v-for="(site, siteIdx) in cate.siteList" :key="`cate_${cateIdx}_site_${siteIdx}`">
                  <td v-if="siteIdx == 0" :rowspan="cate.siteList.length">
                    {{ cate.name }}
                  </td>
                  <td>
                    {{ site.siteName }}
                    <!-- <a-radio-group size="small" v-if="editable">
                        <a-radio-button @click="editSite(cateIdx, siteIdx, site)"><a-icon type="edit" title="编辑点位" /></a-radio-button>
                      </a-radio-group> -->

                    <div v-if="isEdit">
                      <a-tooltip placement="top" v-if="editable">
                        <template #title>
                          <span>编辑点位</span>
                        </template>
                        <a-button type="primary" class="siteBtn" ghost @click="editSite(cateIdx, siteIdx, site)" v-if="isEdit"><a-icon type="edit" />编辑</a-button>
                      </a-tooltip>
                      <a-tooltip placement="top">
                        <template #title>
                          <span>应用给当前分类下其他点位数据</span>
                        </template>
                        <a-button type="primary" class="siteBtn" ghost @click="usePointPositionBtn(site.projId, site.cateId, site.id, cate)"
                          ><a-icon type="snippets" />应用</a-button
                        >
                      </a-tooltip>
                      <!-- <a-tooltip placement="top">
                        <template #title>
                          <span>复制当前分类下其他点位的数据</span>
                        </template>
                        <a-button type="primary" class="siteBtn" ghost @click="copyPointPositionBtn(site.projId, site.cateId, site.id, cate)"><a-icon type="copy" />复制</a-button>
                      </a-tooltip> -->

                      <!-- 把这个按钮禁用，原因是会把点位一块删除 -->
                      <!-- <a-tooltip placement="top">
                          <template #title>
                            <span>删除点位下的内容</span>
                          </template>
                          <a-button type="primary" class="siteBtn" ghost @click="standardDelete(site.projId, site.cateId, site.id)"><a-icon type="delete" /></a-button>
                        </a-tooltip> -->
                    </div>
                  </td>

                  <td>
                    <a-table v-bind="table" v-on="table" :columns="cate.emissionRate ? emissionRateColumns : defaultColumns" bordered :dataSource="site.itemList">
                      <template slot="itemName" slot-scope="text, record">
                        <a-tag v-if="record.itemType == 0" color="blue">{{ record.itemName }}</a-tag>
                        <a-tag v-else-if="record.itemType == 1" color="red">*{{ record.itemName }}</a-tag>
                        <a-tag v-else>{{ record.itemName }}</a-tag>
                      </template>
                      <template slot="standard" slot-scope="text, record">
                        {{ record.modeType === 1 ? record.manualStandard : text }}
                      </template>
                      <template slot="limitValue" slot-scope="text, record">
                        <template v-if="record.standardType === 3">
                          {{
                            record.modeType === 1
                              ? record.manualLimitVal === '1'
                                ? '有'
                                : '无'
                              : record.limitValType && record.limitValType === '1'
                              ? record.limitValEdit === '1'
                                ? '有'
                                : '无'
                              : record.limitValue === '1'
                              ? '有'
                              : '无'
                          }}
                        </template>
                        <template v-else>
                          {{ record.modeType === 1 ? record.manualLimitVal : record.limitValType && record.limitValType === '1' ? record.limitValEdit : text }}
                        </template>
                      </template>
                      <template slot="unit" slot-scope="text, record">
                        <template v-if="!record.standardType || record.standardType !== 3">
                          {{ record.modeType === 1 ? record.manualUnit : record.limitValType && record.limitValType === '1' ? record.unitEdit : text }}
                        </template>
                      </template>
                      <template slot="emissionRateLimitValue" slot-scope="text, record">
                        <template v-if="record.standardType === 3">
                          {{
                            record.modeType === 1
                              ? record.manualEmissionRateLimitValue === '1'
                                ? '有'
                                : '无'
                              : record.limitValType && record.limitValType === '1'
                              ? record.emissionRateLimitValEdit === '1'
                                ? '有'
                                : '无'
                              : record.emissionRateLimitValue === '1'
                              ? '有'
                              : '无'
                          }}
                        </template>
                        <template v-else>
                          {{
                            record.modeType === 1
                              ? record.manualEmissionRateLimitValue
                              : record.limitValType && record.limitValType === '1'
                              ? record.emissionRateLimitValEdit
                              : text
                          }}
                        </template>
                      </template>
                      <template slot="emissionRateUnit" slot-scope="text, record">
                        <template v-if="!record.standardType || record.standardType !== 3">
                          {{ record.modeType === 1 ? record.manualEmissionRateUnit : record.limitValType && record.limitValType === '1' ? record.emissionRateUnitEdit : text }}
                        </template>
                      </template>
                      <template slot="methodId" slot-scope="text, record">{{ getMethodName(record) }}</template>
                      <!-- <template slot="standard" slot-scope="v, r">
                          <a-tag v-if="r.standardList">{{ r.standardList[0].standardCode }}-{{ r.standardList[0].standardName }}</a-tag>
                        </template> -->
                    </a-table>
                  </td>
                  <td>
                    <template v-if="site.modeType === 0">
                      <div v-for="(standard, standardIndex) in site.siteStandards" :key="`cate_${cateIdx}_site_${siteIdx}_standard_${standardIndex}`">
                        {{ standard.standardName }}<{{ standard.standardIds }}>
                      </div>
                      <div v-if="isEdit">
                        <a-tooltip placement="top">
                          <template #title>
                            <span>修改标准</span>
                          </template>
                          <a-button type="primary" class="siteBtn" ghost @click="fullModal.show(project.id)">标准</a-button>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template #title>
                            <span>编辑点位下因子信息</span>
                          </template>
                          <a-button type="primary" class="siteBtn" ghost @click="editPointPositionBtn(site.projId, site.cateId, site.id)">因子</a-button>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template #title>
                            <span>修改标准值、单位</span>
                          </template>
                          <a-button type="primary" class="siteBtn" ghost @click="editStandardValueBtn(site, cate.emissionRate)">标准值</a-button>
                        </a-tooltip>
                      </div>
                    </template>
                    <div v-if="isEdit">
                      <a-tooltip placement="top">
                        <template #title>
                          <span>手动录入标准名称、标准值、标准单位</span>
                        </template>
                        <a-button type="primary" class="siteBtn" ghost @click="manualEntryBtn(site.itemList, cate.emissionRate)">手动录入</a-button>
                      </a-tooltip>
                      <a-tooltip placement="top" v-if="site.modeType === 1">
                        <template #title>
                          <span>重置手动录入</span>
                        </template>
                        <a-button type="primary" class="siteBtn" ghost @click="resetManualEntryBtn(site.itemList)">重置</a-button>
                      </a-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </a-card>
    </template>
    <template v-else>
      <div>
        <a-row style="margin-top: 20px" align="middle">
          <a-col :span="2" style="line-height: 48px; text-align: center; background: #1890ff; border-radius: 0px 8px 0px 0px; font-size: 16px; font-weight: bold; color: #ffffff"
            >下单信息</a-col
          >
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <th rowspan="3">现场信息</th>
              <td class="tdLabel">下单时间</td>
              <td>
                <div :class="`required ${notNull(task.taskDate)}`">
                  <JDate style="width: 100%" v-model="task.taskDate" placeholder="下单时间"></JDate>
                </div>
              </td>
              <td class="tdLabel">现场联系人</td>
              <td>
                <div :class="`required ${notNull(task.taskOnsiteContactId)}`">
                  <a-select
                    style="width: 100%"
                    showSearch
                    placeholder="现场联系人"
                    v-model="task.taskOnsiteContactId"
                    optionFilterProp="children"
                    @change="taskOnsiteContactChange"
                    :filterOption="filterOption"
                  >
                    <a-icon type="plus-square" @click="showAddCustomerUser" slot="suffixIcon" />
                    <a-select-option v-for="vo in this.customerUserList" :key="vo.id">{{ vo.csrUserName }}</a-select-option>
                  </a-select>
                </div>
              </td>
              <td class="tdLabel">联系电话</td>
              <td>
                <div :class="`required ${notNull(task.taskOnsitePhone)}`">
                  <a-input placeholder="联系电话" v-model="task.taskOnsitePhone" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="tdLabel">地址</td>
              <td colspan="5">
                <div :class="`required has-${addr.status}`">
                  <AddressInput ref="addressInput" :addr.sync="addr"></AddressInput>
                </div>
              </td>
            </tr>
            <tr>
              <td class="tdLabel">备注</td>
              <td colspan="5">
                <a-textarea placeholder="备注" :autosize="{ minRows: 2, maxRows: 8 }" v-model="task.remark" />
              </td>
            </tr>
          </table>
        </a-row>
        <a-row>
          <a-card title="检测项点位/检测项">
            <div slot="extra">
              <!--            <a v-if="record.taskId" @click="fullModal.show(record.id)" v-action:proj-task:download>-->
              <!--              <a-icon type="alert"/>-->
              <!--              质控-->
              <!--            </a>-->
              <a-button size="small" @click="showStandardExecl" style="margin-left: 10px">评价标准</a-button>
              <a-button size="small" @click="cateSiteSort" v-if="isEdit">点位排序</a-button>
              <!-- 根据需求，隐藏选择标准 -->
              <!-- <a-button size="small" @click="fullModal.show(project.id)"><a-icon type="alert" />选择标准</a-button> -->
              <a-button size="small" @click="methodModal.show(project.id)" v-if="isEdit">分析方法</a-button>
            </div>
            <div class="card-blue">
              <table class="ahmux-table">
                <tr>
                  <th class="tdLabel" style="width: 80px">分类</th>
                  <th class="tdLabel" style="width: 200px">点位</th>
                  <th class="tdLabel" style="width: 1020px">检测内容</th>
                  <th class="tdLabel" style="width: 200px">点位关联标准</th>
                </tr>
                <template v-for="(cate, cateIdx) in project.cateList">
                  <template v-if="!cate.siteList || cate.siteList.length == 0">
                    <tr :id="`cate_${cateIdx}`" :key="cateIdx">
                      <td>{{ cate.name }}</td>
                      <td colspan="3"></td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr :id="`cate_${cateIdx}_site_${siteIdx}`" v-for="(site, siteIdx) in cate.siteList" :key="`cate_${cateIdx}_site_${siteIdx}`">
                      <td v-if="siteIdx == 0" :rowspan="cate.siteList.length">
                        {{ cate.name }}
                      </td>
                      <td>
                        {{ site.siteName }}
                        <!-- <a-radio-group size="small" v-if="editable">
                        <a-radio-button @click="editSite(cateIdx, siteIdx, site)"><a-icon type="edit" title="编辑点位" /></a-radio-button>
                      </a-radio-group> -->

                        <div v-if="isEdit">
                          <a-tooltip placement="top" v-if="editable">
                            <template #title>
                              <span>编辑点位</span>
                            </template>
                            <a-button type="primary" class="siteBtn" ghost @click="editSite(cateIdx, siteIdx, site)" v-if="isEdit"><a-icon type="edit" />编辑</a-button>
                          </a-tooltip>
                          <a-tooltip placement="top">
                            <template #title>
                              <span>应用给当前分类下其他点位数据</span>
                            </template>
                            <a-button type="primary" class="siteBtn" ghost @click="usePointPositionBtn(site.projId, site.cateId, site.id, cate)"
                              ><a-icon type="snippets" />应用</a-button
                            >
                          </a-tooltip>
                          <!-- <a-tooltip placement="top">
                            <template #title>
                              <span>复制当前分类下其他点位的数据</span>
                            </template>
                            <a-button type="primary" class="siteBtn" ghost @click="copyPointPositionBtn(site.projId, site.cateId, site.id, cate)"
                              ><a-icon type="copy" />复制</a-button
                            >
                          </a-tooltip> -->

                          <!-- 把这个按钮禁用，原因是会把点位一块删除 -->
                          <!-- <a-tooltip placement="top">
                          <template #title>
                            <span>删除点位下的内容</span>
                          </template>
                          <a-button type="primary" class="siteBtn" ghost @click="standardDelete(site.projId, site.cateId, site.id)"><a-icon type="delete" /></a-button>
                        </a-tooltip> -->
                        </div>
                      </td>

                      <td>
                        <a-table v-bind="table" v-on="table" :columns="cate.emissionRate ? emissionRateColumns : defaultColumns" bordered :dataSource="site.itemList">
                          <template slot="itemName" slot-scope="text, record">
                            <a-tag v-if="record.itemType == 0" color="blue">{{ record.itemName }}</a-tag>
                            <a-tag v-else-if="record.itemType == 1" color="red">*{{ record.itemName }}</a-tag>
                            <a-tag v-else>{{ record.itemName }}</a-tag>
                          </template>
                          <template slot="standard" slot-scope="text, record">
                            {{ record.modeType === 1 ? record.manualStandard : text }}
                          </template>
                          <template slot="limitValue" slot-scope="text, record">
                            <template v-if="record.standardType === 3">
                              {{
                                record.modeType === 1
                                  ? record.manualLimitVal === '1'
                                    ? '有'
                                    : '无'
                                  : record.limitValType && record.limitValType === '1'
                                  ? record.limitValEdit === '1'
                                    ? '有'
                                    : '无'
                                  : record.limitValue === '1'
                                  ? '有'
                                  : '无'
                              }}
                            </template>
                            <template v-else>
                              {{ record.modeType === 1 ? record.manualLimitVal : record.limitValType && record.limitValType === '1' ? record.limitValEdit : text }}
                            </template>
                          </template>
                          <template slot="unit" slot-scope="text, record">
                            <template v-if="!record.standardType || record.standardType !== 3">
                              {{ record.modeType === 1 ? record.manualUnit : record.limitValType && record.limitValType === '1' ? record.unitEdit : text }}
                            </template>
                          </template>
                          <template slot="emissionRateLimitValue" slot-scope="text, record">
                            <template v-if="record.standardType === 3">
                              {{
                                record.modeType === 1
                                  ? record.manualEmissionRateLimitValue === '1'
                                    ? '有'
                                    : '无'
                                  : record.limitValType && record.limitValType === '1'
                                  ? record.emissionRateLimitValEdit === '1'
                                    ? '有'
                                    : '无'
                                  : record.emissionRateLimitValue === '1'
                                  ? '有'
                                  : '无'
                              }}
                            </template>
                            <template v-else>
                              {{
                                record.modeType === 1
                                  ? record.manualEmissionRateLimitValue
                                  : record.limitValType && record.limitValType === '1'
                                  ? record.emissionRateLimitValEdit
                                  : text
                              }}
                            </template>
                          </template>
                          <template slot="emissionRateUnit" slot-scope="text, record">
                            <template v-if="!record.standardType || record.standardType !== 3">
                              {{ record.modeType === 1 ? record.manualEmissionRateUnit : record.limitValType && record.limitValType === '1' ? record.emissionRateUnitEdit : text }}
                            </template>
                          </template>
                          <template slot="methodId" slot-scope="text, record">{{ getMethodName(record) }}</template>
                          <!-- <template slot="standard" slot-scope="v, r">
                          <a-tag v-if="r.standardList">{{ r.standardList[0].standardCode }}-{{ r.standardList[0].standardName }}</a-tag>
                        </template> -->
                        </a-table>
                      </td>
                      <td>
                        <template v-if="site.modeType === 0">
                          <div v-for="(standard, standardIndex) in site.siteStandards" :key="`cate_${cateIdx}_site_${siteIdx}_standard_${standardIndex}`">
                            {{ standard.standardName }}<{{ standard.standardIds }}>
                          </div>
                          <div v-if="isEdit">
                            <a-tooltip placement="top">
                              <template #title>
                                <span>修改标准</span>
                              </template>
                              <a-button type="primary" class="siteBtn" ghost @click="fullModal.show(project.id)">标准</a-button>
                            </a-tooltip>
                            <a-tooltip placement="top">
                              <template #title>
                                <span>编辑点位下因子信息</span>
                              </template>
                              <a-button type="primary" class="siteBtn" ghost @click="editPointPositionBtn(site.projId, site.cateId, site.id)">因子</a-button>
                            </a-tooltip>
                            <a-tooltip placement="top">
                              <template #title>
                                <span>修改标准值、单位</span>
                              </template>
                              <a-button type="primary" class="siteBtn" ghost @click="editStandardValueBtn(site, cate.emissionRate)">标准值</a-button>
                            </a-tooltip>
                          </div>
                        </template>
                        <div v-if="isEdit">
                          <a-tooltip placement="top">
                            <template #title>
                              <span>手动录入标准名称、标准值、标准单位</span>
                            </template>
                            <a-button type="primary" class="siteBtn" ghost @click="manualEntryBtn(site.itemList, cate.emissionRate)">手动录入</a-button>
                          </a-tooltip>
                          <a-tooltip placement="top" v-if="site.modeType === 1">
                            <template #title>
                              <span>重置手动录入</span>
                            </template>
                            <a-button type="primary" class="siteBtn" ghost @click="resetManualEntryBtn(site.itemList)">重置</a-button>
                          </a-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </table>
            </div>
          </a-card>
        </a-row>
      </div>
    </template>

    <SiteForm ref="siteForm" @updateSiteInfo="updateSiteInfo"></SiteForm>
    <PlanSiteSort ref="planSiteSort" :quoteId="project.quoteId" :siteList.sync="project.siteList" :cateList.sync="project.cateList"></PlanSiteSort>
    <CustomerUserModal ref="customerUserModal" @ok="customerUserOk" :customerList="customerList"></CustomerUserModal>
    <a-modal v-bind="fullModal" v-on="fullModal" v-if="fullModal.visible">
      <standard-tree @success="fullModal.success" :proj-id="fullModal.projId" :ref="fullModal.standardRef" @updatePointPosition="updatePointPosition"></standard-tree>
      <template #footer>
        <div style="text-align: right">
          <a-button type="primary" @click="showStandardExecl">标准预览</a-button>
          <a-button @click="fullModal.cancel()">取消</a-button>
          <a-button type="primary" @click="fullModal.ok()">确定</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 分析方法 -->
    <a-modal v-bind="methodModal" v-on="methodModal" v-if="methodModal.visible">
      <test-method @success="methodModal.success" :proj-id="methodModal.projId" :ref="methodModal.ref"></test-method>
    </a-modal>
    <EditPointPosition ref="editPointPositionKey" @updatePointPosition="updatePointPosition"></EditPointPosition>
    <CopyPointPosition ref="copyPointPositionKey" @copyPointPosition="copyPointPosition"></CopyPointPosition>
    <UsePointPosition ref="usePointPositionKey" @usePointPosition="usePointPosition"></UsePointPosition>
    <EditStandardValue ref="editStandardValueKey" @updateStandardValue="updateStandardValue"></EditStandardValue>
    <ManualEntryModal ref="manualEntryKey" @refreshData="refreshData"></ManualEntryModal>

    <a-modal v-bind="evaluationCriterionModal" v-on="evaluationCriterionModal" width="100%">
      <iframe :src="iframeSrc" frameborder="0" style="width: 100%; height: 100%; margin: 0 auto" />
      <template #footer>
        <div style="text-align: right">
          <a-button @click="evaluationCriterionModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { customerUserApi, Get, itemCheckDetailApi } from '@/api/quote'
import { projectApi, projTaskApi, itemCateMethodApi } from '@/api/project'
import { standardDelete, projStandardTreeApi, manualEntry } from '@/api/projectStandard/projectStandardApi'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import JDate from '@/components/j/JDate'
import SiteForm from '@/pages/cloud-quote/bj-plan-quote/modules/SiteForm'
import PlanSiteSort from '@/pages/cloud-quote/bj-plan-quote/modules/PlanSiteSortNew'
import EditPointPosition from '@/pages/cloud-quote/bj-plan-quote/modules/EditPointPosition'
import CopyPointPosition from '@/pages/cloud-quote/bj-plan-quote/modules/CopyPointPosition'
import UsePointPosition from '@/pages/cloud-quote/bj-plan-quote/modules/UsePointPosition'
import EditStandardValue from '@/pages/cloud-quote/bj-plan-quote/modules/EditStandardValue'
import CustomerUserModal from '@/pages/cloud-quote/customer-user/Form'
import AddressInput from '@/pages/common/map/AddressInput'
import { QualityControlStandard as standard, QualityControlStandardTree as standardTree } from '@/pages/cloud-quote/quality-control/modules'
import TestMethod from '@/pages/cloud-quote/project/modules/TestMethod'
import ManualEntryModal from '@/pages/cloud-quote/project/modules/ManualEntryModal'

import fullModal from '@/pages/common/mixins/modal/full'
import moment from 'moment'
import { groupBy, orderBy, unionBy } from 'lodash'

const fullModalData = fullModal.data().fullModal
export default {
  name: 'ProjectTaskForm',
  components: {
    JDate,
    SiteForm,
    PlanSiteSort,
    EditPointPosition,
    CustomerUserModal,
    CopyPointPosition,
    UsePointPosition,
    EditStandardValue,
    standard,
    standardTree,
    AddressInput,
    TestMethod,
    ManualEntryModal
  },
  mixins: [fullModal],
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    projId: {
      type: Number,
      default: null
    },
    addr: {
      type: Object,
      default: () => ({})
    },
    dataSouce: {
      type: Number,
      default: null
    },
    isEdit: {
      // 判断是否显示修改的按钮
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 项目信息
      project: {},

      // 下单信息
      task: {},

      customerList: [],

      customerUserList: [],

      fullModal: {
        ref: 'taskScheduleModal',
        standardRef: 'standardModel',
        title: '选择标准',
        projId: null,
        show: (projId) => {
          this.fullModal.projId = projId
          this.fullModal.visible = true
        },
        ok: () => {
          this.$refs[this.fullModal.standardRef].save()
        },
        success: () => {
          this.fullModal.cancel()
          this.refreshData()
        }
      },
      methodModal: Object.assign({}, fullModalData, {
        ref: 'methodModal',
        title: '分析方法',
        projId: null,
        show: (projId) => {
          this.methodModal.projId = projId
          this.methodModal.visible = true
        },
        ok: () => {
          this.$refs[this.methodModal.ref].save()
        },
        cancel: () => {
          this.methodModal.projId = null
          this.methodModal.visible = false
        },
        success: () => {
          this.methodModal.cancel()
          this.refreshData()
        }
      }),

      // 评价标准地址
      iframeSrc: '',
      evaluationCriterionModal: {
        title: '评价标准',
        visible: false,
        // width: 800,
        centered: true,
        closable: false,
        maskClosable: false,
        destroyOnClose: true,
        wrapClassName: 'full-modal-wrapper',
        show: () => {
          this.evaluationCriterionModal.visible = true
        },
        ok: () => {},
        cancel: () => {
          this.evaluationCriterionModal.visible = false
        }
      },

      table: {
        ref: 'table',
        size: 'small',
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },

        pagination: false
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          customRender: (v, r, i) => i + 1,
          width: '50px',
          className: 'column-center'
        },
        {
          title: '检测项',
          dataIndex: 'itemName',
          width: '200px',
          className: 'column-center',
          scopedSlots: { customRender: 'itemName' }
        },
        { title: '频次', dataIndex: 'numFrequency', width: '50px', className: 'column-center' },
        { title: '周期', dataIndex: 'numPeriod', width: '50px', className: 'column-center' },
        { title: '分析方法', dataIndex: 'methodId', width: '200px', className: 'column-center', scopedSlots: { customRender: 'methodId' } },
        {
          title: '执行标准',
          dataIndex: 'standardName',
          width: '200px',
          className: 'column-center',
          scopedSlots: { customRender: 'standard' }
        }
      ],
      selectMetchods: [],
      checkGuideDetailCateMap: {},
      standardTree: [],
      isGetProject: false, // 判断是否完成了项目数据的查询
      isGetStandardTree: false // 判断是否完成了标准数据的查询
    }
  },
  methods: {
    moment,
    // 打开点位应用
    usePointPositionBtn(projId, cateId, siteId, cate) {
      const siteObj = {
        projId,
        cateId,
        siteId
      }
      const siteList = []
      cate.siteList.forEach((item) => {
        if (item.id != siteId) {
          const obj = {
            projId: item.projId,
            cateId: item.cateId,
            cateName: item.cateName,
            siteId: item.id,
            siteName: item.siteName
          }
          siteList.push(obj)
        }
      })
      this.$refs.usePointPositionKey.getCate(siteObj, siteList)
    },
    usePointPosition() {
      this.refreshData()
    },
    // 打开点位复制
    copyPointPositionBtn(projId, cateId, siteId, cate) {
      const siteObj = {
        projId,
        cateId,
        siteId
      }
      const siteList = []
      cate.siteList.forEach((item) => {
        if (item.id != siteId) {
          const obj = {
            projId: item.projId,
            cateId: item.cateId,
            cateName: item.cateName,
            siteId: item.id,
            siteName: item.siteName
          }
          siteList.push(obj)
        }
      })
      this.$refs.copyPointPositionKey.getCate(siteObj, siteList)
    },
    copyPointPosition() {
      this.refreshData()
    },
    // 打开点位编辑
    editPointPositionBtn(projId, cateId, siteId) {
      const siteObj = {
        projId,
        cateId,
        siteId
      }
      this.$refs.editPointPositionKey.getSite(siteObj)
    },
    updatePointPosition(site) {
      this.refreshData()
    },

    // 打开标准值和标准单位编辑
    editStandardValueBtn(site, emissionRate) {
      this.$refs.editStandardValueKey.getSite(site, emissionRate)
    },
    updateStandardValue(site) {
      this.refreshData()
    },
    // 刷新数据
    refreshData() {
      this.getProj()
      this.getStandardTree()
    },
    // 删除点位
    standardDelete(projId, cateId, siteId) {
      this.$confirm({
        title: '确认删除该点位下的信息吗？',
        onOk: () => {
          standardDelete.Delete({ projId, cateId, siteId }).then((res) => {
            if (res.code === 0) {
              this.$message.success(`删除点位成功`)
              this.refreshData()
            } else {
              this.$message.error(`删除点位出错：${res.msg}`)
            }
          })
        },
        onCancel() {}
      })
    },
    // 打开评价标准
    showStandardExecl() {
      this.iframeSrc = `${fileTemplateUreportApi.preview}?_u=DB:执行标准.ureport.xml&projId=${this.projId}`
      this.evaluationCriterionModal.show()
    },

    save() {
      var self = this
      self.task.quoteId = self.project.quoteId
      if (self.validate()) {
        let saveApi = projTaskApi.add
        if (self.task.id) {
          saveApi = projTaskApi.edit
        }
        self.task.projAdcode = self.addr.adCode
        self.task.projAddress = self.addr.address
        self.task.projWholeAddress = self.addr.wholeAddress
        self.task.projLng = self.addr.lng
        self.task.projLat = self.addr.lat
        saveApi(self.task)
          .then((result) => {
            if (result.code === 0) {
              self.$message.success(`操作成功`)
              self.$emit('ok', null)
            } else {
              self.$message.error(result.msg)
            }
          })
          .finally(() => {
            self.$emit('finally', null)
          })
      } else {
        self.$emit('finally', null)
      }
    },
    validate() {
      var self = this
      if (!self.task.taskOnsiteContactId) {
        self.$message.error(`现场联系人不能为空`)
        return false
      }
      if (!self.task.taskOnsitePhone || self.task.taskOnsitePhone.length > 50) {
        self.$message.error(`现场联系人电话不能为空,且长度不能超过50`)
        return false
      }
      return true
    },

    getProj(taskFlag) {
      this.isGetProject = false

      projectApi
        .get({ id: this.project.id, itemType: '1' })
        .then((result) => {
          if (result.code === 0) {
            if (result.data) {
              // 判断执行标准数据是否是手动录入的
              result.data.cateList?.forEach((item) => {
                item.siteList?.forEach((item2) => {
                  let modeTypeStatus = item2.itemList?.some((item3) => item3.modeType === 1)
                  if (modeTypeStatus) {
                    item2.modeType = 1
                  } else {
                    item2.modeType = 0
                  }
                })
              })
              this.project = result.data
              if (taskFlag) {
                this.getTaskByProjId()
              }
              this.queryCheckGuideDetail()
              this.selectMetchodList()
              if (this.isGetStandardTree) {
                this.project?.cateList?.forEach((cateItem) => {
                  let treeCate = this.standardTree?.find((it) => it.cateId === cateItem.id)
                  // console.log(cateItem, treeCate)
                  if (treeCate) {
                    cateItem.siteList?.forEach((siteItem) => {
                      if (treeCate.children && treeCate.children.length) {
                        let treeSite = treeCate.children.find((it) => it.siteId === siteItem.id)
                        // console.log(siteItem, treeSite)
                        if (treeSite) {
                          this.$set(siteItem, 'siteStandards', treeSite.affirmStandard || [])
                        }
                      }
                    })
                  }
                })
                // console.log(this.project)
              }
            }
          }
        })
        .finally(() => {
          this.isGetProject = true
        })
    },
    // 查询点位关联的标准数据
    getStandardTree() {
      this.isGetStandardTree = false
      projStandardTreeApi
        .listProjectStandard({ projId: this.project.id })
        .then((res) => {
          if (res.code === 0) {
            this.standardTree = res.data
            if (this.isGetProject) {
              this.project?.cateList?.forEach((cateItem) => {
                let treeCate = this.standardTree?.find((it) => it.cateId === cateItem.id)
                // console.log(cateItem, treeCate)
                if (treeCate) {
                  cateItem.siteList?.forEach((siteItem) => {
                    if (treeCate.children && treeCate.children.length) {
                      let treeSite = treeCate.children.find((it) => it.siteId === siteItem.id)
                      // console.log(siteItem, treeSite)
                      if (treeSite) {
                        this.$set(siteItem, 'siteStandards', treeSite.affirmStandard || [])
                      }
                    }
                  })
                }
              })
            }
            // console.log(this.project)
          }
        })
        .finally(() => {
          this.isGetStandardTree = true
        })
    },
    getTaskByProjId() {
      projTaskApi.getTaskByProjId({ projId: this.task.projId }).then((result) => {
        if (result.code === 0) {
          if (result.data) {
            this.task = result.data
          }
          this.initTask()
        }
      })
    },
    selectMetchodList() {
      itemCateMethodApi.selectMetchod({ projId: this.project.id }).then((res) => {
        if (res.code === 0) {
          this.selectMetchods = res.data
          if (this.selectMetchods && this.selectMetchods.length) {
            this.project.cateList.forEach((cateItem) => {
              cateItem.siteList?.forEach((siteItem) => {
                siteItem.itemList?.forEach((item) => {
                  let sms = this.selectMetchods.filter((it) => this.project.id === it.projId && item.cateId === it.cateId && item.itemId === it.itemId)
                  if (sms.length) {
                    this.$set(item, 'methodId', sms[0].methodId)
                  }
                })
              })
            })
          }
        }
      })
    },
    // 获取检测方法
    queryCheckGuideDetail() {
      let list = []
      this.project?.cateList?.forEach((cateItem) => {
        cateItem.siteList?.forEach((siteItem) => {
          siteItem.itemList?.forEach((item) => {
            let findIndex = list.findIndex((it) => it.cateId === item.cateId && it.itemId === item.itemId)
            if (findIndex === -1) {
              list.push(item)
            }
          })
        })
      })
      if (list.length) {
        let items = unionBy(list.map((item) => item.cateId + '-' + item.itemId))
        itemCheckDetailApi.listDetail({ page: false, items }).then((res) => {
          if (res.code === 0) {
            if (res.data) {
              const data = orderBy(res.data, 'defaultFlag', 'desc')
              this.checkGuideDetailCateMap = groupBy(data, (item) => item.cateId + '_' + item.itemId)
            }
          } else {
            message.error(res.msg)
          }
        })
      }
    },
    getMethodName(record) {
      let name = ''
      if (record.methodId) {
        const d = this.checkGuideDetailCateMap[record.cateId + '_' + record.itemId]?.filter((r) => r.methodId == record.methodId)
        if (d && d.length > 0) {
          name = d[0].methodName + '(' + d[0].standardCode + ')'
        }
      }
      return name
    },

    listCustomerUser(csrId) {
      Get(`${customerUserApi.list}`, { csrId: csrId, page: false, authEnable: false }).then((result) => {
        if (result.code === 0) {
          this.customerUserList = result.data
        }
      })
    },
    showAddCustomerUser() {
      this.$refs.customerUserModal.addByCsrId(this.project.customer)
    },
    customerUserOk(record) {
      this.customerUserList.push(record)
    },
    taskOnsiteContactChange(value) {
      const csrUser = this.customerUserList.filter((item) => value === item.id)[0]
      if (csrUser) {
        this.$set(this.task, 'taskOnsiteContact', csrUser.csrUserName)
        this.$set(this.task, 'taskOnsitePhone', csrUser.csrUserTelephone)
      } else {
        this.$set(this.task, 'taskOnsiteContact', '')
        this.$set(this.task, 'taskOnsitePhone', '')
      }
    },

    // 打开手动录入标准名称、标准值、标准单位的对话框
    manualEntryBtn(itemList, emissionRate) {
      this.$refs.manualEntryKey.init(itemList, emissionRate)
    },
    // 重置手动录入
    resetManualEntryBtn(itemList) {
      console.log(itemList)
      let params = itemList.map((item) => item.id)
      console.log(params)
      manualEntry.resetManual(params).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.$message.success('重置手动录入标准成功')
          this.refreshData()
        }
      })
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    editSite(cateIdx, siteIdx, site) {
      site.cateIdx = cateIdx
      site.siteIdx = siteIdx
      if (site.id) {
        this.$refs.siteForm.edit(site)
      } else {
        this.$refs.siteForm.editName(site)
      }
    },
    updateSiteInfo(site) {
      this.$set(this.project.cateList[site.cateIdx].siteList[site.siteIdx], 'siteName', site.siteName)
      this.$set(this.project.cateList[site.cateIdx].siteList[site.siteIdx], 'siteRemark', site.siteRemark)
    },
    cateSiteSort() {
      this.$refs.planSiteSort.sort()
    },
    initInfo() {
      if (this.projId) {
        this.$set(this.project, 'id', this.projId)
        this.$set(this.task, 'projId', this.projId)
        this.getProj(true)
        this.getStandardTree()
        this.getTaskByProjId()
      }
    },
    initTask() {
      if (this.project.id) {
        this.customerList = [
          { id: this.project.customer, csrName: this.project.customerName },
          { id: this.project.entrustCustomer, csrName: this.project.entrustCustomerName }
        ]
        this.listCustomerUser(this.project.customer)
      }
      if (!this.task.id) {
        this.$set(this.task, 'taskDate', this.project.projDate)
        this.$set(this.task, 'taskOnsiteContactId', this.project.customerContacts)
        this.$set(this.task, 'taskOnsiteContact', this.project.customerContactsName)
        this.$set(this.task, 'taskOnsitePhone', this.project.customerContactsPhone)
      }
    },
    subStr(text, len) {
      len = len && len > 0 ? len : 8
      if (text) {
        if (text.length > len) {
          return text.substring(0, len) + '...'
        } else {
          return text
        }
      } else {
        return ''
      }
    },
    notNull(v) {
      return v ? '' : 'has-error'
    }
  },
  watch: {
    projId: {
      handler(nv, ov) {
        // console.log('监听',nv,ov)
        this.initInfo()
      },
      immediate: true
    }
  },
  computed: {
    defaultColumns() {
      let columns = [
        {
          title: '标准值',
          dataIndex: 'limitValue',
          width: '65px',
          className: 'column-center',
          scopedSlots: { customRender: 'limitValue' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: '60px',
          className: 'column-center',
          scopedSlots: { customRender: 'unit' }
        }
      ]
      return [].concat(this.columns, columns)
    },
    emissionRateColumns() {
      let columns = [
        {
          title: '排放浓度',
          // align: 'center',
          children: [
            {
              title: '标准值',
              dataIndex: 'limitValue',
              width: '65px',
              className: 'column-center',
              scopedSlots: { customRender: 'limitValue' }
            },
            {
              title: '单位',
              dataIndex: 'unit',
              width: '60px',
              className: 'column-center',
              scopedSlots: { customRender: 'unit' }
            }
          ]
        },
        {
          title: '排放速率',
          // align: 'center',
          children: [
            {
              title: '标准值',
              dataIndex: 'emissionRateLimitValue',
              width: '65px',
              className: 'column-center',
              scopedSlots: { customRender: 'emissionRateLimitValue' }
            },
            {
              title: '单位',
              dataIndex: 'emissionRateUnit',
              width: '60px',
              className: 'column-center',
              scopedSlots: { customRender: 'emissionRateUnit' }
            }
          ]
        }
      ]
      return [].concat(this.columns, columns)
    }
  },
  mounted() {
    // this.intInfo()
  },
  created() {}
}
</script>

<style lang="less">
@import '~@/assets/less/table/index2';
.ahmux-table {
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #caedff;
  }
}

.card-blue .card-bg-color {
  background-color: #e6f7ff;
}
.card-blue .card-border {
  border: 1px solid #91d5ff;
}

.mxtable1 {
  width: 95%;
  border: 2px solid #1890ff;
  border-top: 5px solid #1890ff;
  border-collapse: unset;
}
.mxtable {
  width: 100%;
  border: 2px solid #1890ff;
  border-top: 5px solid #1890ff;
  border-collapse: unset;
  .required .ant-input,
  .required .ant-select-selection,
  .required .ant-cascader-picker {
    background-color: #fef0d0;
  }
  th {
    width: 75px;
    background: #f3f3f1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px dashed #909399;
  }
  td {
    padding: 5px;
    min-width: 12%;
    max-width: 22%;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
  .tdLabel {
    width: 75px;
    background: #f3f3f1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
}

.siteBtn {
  margin: 4px 0;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  height: initial;
}
</style>
<style scoped>
</style>
