<template>
  <a-table v-bind="tableContent">
    <template slot="num" slot-scope="data,record,index">
      <span :style="{fontWeight:'bolder'}">{{ index+1 }}</span>
    </template>
  </a-table>
</template>
<script>
import { makeSampleItemApi } from '@/api/makeSample/makeSamplelApi'

export default {
  name: 'SampleItemDetail',
  props: {
    sampleRefId: {
      type: Number,
      require: true
    },

    page: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      tableContent: {
        style: { padding: '16px', width: this.width, background: '#FFFFFF' },
        ref: 'sampleItemTable',
        size: 'small',
        rowKey: (data) => { return data.sampleId + '-' + data.cateId + '-' + data.itemId },
        dataSource: [],
        pagination: false,
        bordered: true,
        columns: [{
          title: '序号',
          align: 'center',
          dataIndex: 'id',
          width: 50,
          scopedSlots: { customRender: 'num' }
        }, {
          title: '检测分类',
          align: 'center',
          dataIndex: 'cateName',
          width: 100,
          scopedSlots: { customRender: 'cateName' }
        }, {
          title: '检测项',
          align: 'center',
          dataIndex: 'itemName',
          width: 100,
          scopedSlots: { customRender: 'itemName' }
        }]
      }
    }
  },
  computed: {
    queryParam () {
      return { sampleRefId: this.sampleRefId, page: this.page }
    }
  },
  watch: {
    queryParam: {
      immediate: true,
      handler (newValue, oldValue) {
        this.loadData()
      }
    }
  },
  methods: {
    loadData () {
      return makeSampleItemApi.list(this.queryParam).then(res => {
        if (res.code === 0) {
          this.tableContent.dataSource = res.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
