<template>
  <div>
    <a-card style="margin-top: 14px">
      <a-card :bordered="false" style="max-width: 1080px; margin: 16px auto;">
        <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
          项目评审-{{ projectInfo.projName }}
        </div>
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              申请信息
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              申请人：{{ projAudit.createUserName }}
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              申请时间：{{ projAudit.createTime }}
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              <!--            部门：XX-->
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel">项目名称</td>
              <td colspan="3">{{ projectInfo.projName }}</td>
              <td class="tdLabel">项目编号</td>
              <td>{{ projectInfo.projNo }}</td>
            </tr>
            <tr>
              <td class="tdLabel">合同编号</td>
              <td>{{ projectInfo.contractNo }}</td>
              <td class="tdLabel">签订时间</td>
              <td>{{ projectInfo.contractDate }}</td>
              <td class="tdLabel">合同金额</td>
              <td><b style="font-size: 20px; color: #ff0000;">{{ projectInfo.contractAmount }}</b> 元</td>
            </tr>
            <tr>
              <td class="tdLabel" rowspan="6">评审内容</td>
              <td colspan="4">
                1、客户的要求是否明确；
              </td>
              <td>
                <a-radio-group v-model="auditContent.c1">
                  <a-radio value="是">是</a-radio>
                  <a-radio value="否">否</a-radio>
                </a-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                2、技术能力和资源是否满足要求；
              </td>
              <td>
                <a-radio-group v-model="auditContent.c2">
                  <a-radio value="是">是</a-radio>
                  <a-radio value="否">否</a-radio>
                </a-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                3、检测方法是否符合技术标准、规范；
              </td>
              <td>
                <a-radio-group v-model="auditContent.c3">
                  <a-radio value="是">是</a-radio>
                  <a-radio value="否">否</a-radio>
                </a-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                4、若分包，分包是否为客户接受；
              </td>
              <td>
                <a-radio-group v-model="auditContent.c4">
                  <a-radio value="是">是</a-radio>
                  <a-radio value="否">否</a-radio>
                </a-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                5、是否在资质认定项目范围；
              </td>
              <td>
                <a-radio-group v-model="auditContent.c5">
                  <a-radio value="是">是</a-radio>
                  <a-radio value="否">否</a-radio>
                </a-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                6、其他：{{ auditContent.c6 }}
              </td>
            </tr>
            <tr>
              <td class="tdLabel">下单表</td>
              <td colspan="5">
                <a @click="downloadTask()"><a-icon type="eye"/>下单表</a>
              </td>
            </tr>
            <tr>
              <td class="tdLabel">项目文件</td>
              <td colspan="5">
                <FileList :fileList="projectInfo.fileList"/>
              </td>
            </tr>
            <tr>
              <td class="tdLabel">合同文件</td>
              <td colspan="5">
                <FileList :fileList="projectInfo.contractFileList"/>
              </td>
            </tr>
            <tr>
              <td class="tdLabel">备注</td>
              <td colspan="5">
                {{ projAudit.auditRemark }}
              </td>
            </tr>
          </table>
        </a-row>
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              技术意见
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <th class="tdLabel">技术意见</th>
              <td colspan="5">
                <a-card v-if="projAudit.auditAuditor">
                  <a-row>{{ projAudit.auditOpinion }}</a-row>
                  <a-row>审批人：{{ projAudit.auditAuditorName }}  审批时间：{{ projAudit.auditTime }}</a-row>
                </a-card>
              </td>
            </tr>
          </table>
        </a-row>
      </a-card>
    </a-card>
    <DownloadTaskPreview ref="downloadTask"></DownloadTaskPreview>
    <ProjectTaskDownloadModal ref="projectTaskDownloadModal"/>
  </div>
</template>

<script>
import { projectApi, projAuditApi } from '@/api/project'
import UploadFile from '@/pages/common/UploadFile'
import FileList from '@/pages/common/file/FileList'
import DownloadTaskPreview from '@/pages/cloud-quote/proj-task/DownloadPreview'
import ProjectTaskDownloadModal from '@/pages/cloud-quote/project/modules/ProjectTaskDownloadModal'
import JDate from '@/components/j/JDate'
import { flowApi } from '@/api/flow'

export default {
  name: 'ProjectAuditCheck',
  components: {
    FileList,
    UploadFile,
    JDate,
    DownloadTaskPreview,
    ProjectTaskDownloadModal
  },
  props: {
    auditId: {
      type: Number,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

      flowImage: `${flowApi.pdImgByKey}?key=jx_project_audit`, // 流程图

      // 项目信息
      projectInfo: {},

      // 项目审批
      projAudit: {},

      // 评审内容
      auditContent: {
        c1: '是',
        c2: '是',
        c3: '是',
        c4: '是',
        c5: '是',
        c6: ''
      }
    }
  },
  methods: {

    getProjAudit () {
      if (this.projAudit.id) {
        projAuditApi.get({ id: this.projAudit.id }).then(result => {
          if (result.code === 0) {
            const projAudit = result.data
            if (projAudit) {
              this.$set(this, 'projAudit', projAudit)
              this.$set(this.projectInfo, 'id', projAudit.projId)
            } else {
              this.$set(this, 'projAudit', {})
            }
            this.initProjAudit()
            this.getProject()
          }
        })
      }
    },
    getProject () {
      if (this.projectInfo.id) {
        projectApi.get({ id: this.projectInfo.id }).then(result => {
          if (result.code === 0) {
            const projectInfo = result.data
            this.projectInfo = projectInfo
          }
        })
      }
    },
    initInfo () {
      this.projAudit.id = this.auditId
      if (this.projAudit.id) {
        this.getProjAudit()
      }
    },
    initProjAudit () {
      if (this.projAudit.id) {
        var auditContent = JSON.parse(this.projAudit.auditContent)
        this.$set(this, 'auditContent', auditContent)
      } else {
        this.$set(this, 'auditContent', { c1: '是', c2: '是', c3: '是', c4: '是', c5: '是', c6: '' })
      }
    },

    downloadTask () {
      // this.$refs.downloadTask.show(this.projectInfo.id)
      this.$refs.projectTaskDownloadModal.show(this.projectInfo.id, this.projectInfo.projNo, this.projectInfo.projName)
    },

    notNull (v) {
      return v ? '' : 'has-error'
    }
  },
  watch: {
    auditId: {
      handler (nv) {
        this.initInfo()
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted () {
    this.initInfo()
  },
  created () {
  }
}
</script>
<style  lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
