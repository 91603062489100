<template>
  <puruse-item-form
    ref="materialForm"
    :userNameProp="userNameProp"
    :userIdProp="userIdProp"
    :purUseReason="purUseReason"
    :is-reagent="isReagent"
    @ok="cancel"
    @cancel="cancel"></puruse-item-form>
</template>

<script>
import PuruseItemForm from '@/pages/cloud-quote/res/res-puruse/modules/ItemForm'

export default {

  name: 'TsScheduleResources',
  components: { PuruseItemForm },
  data () {
    return {
      resourceModal: {
        title: '领用资源',
        visible: false,
        centered: true,
        closable: false,
        width: '100%',
        wrapClassName: 'modal-container',
        destroyOnClose: true
      },

      resource: [],
      userNameProp: '',
      userIdProp: '',
      purUseReason: '',
      isReagent: false
    }
  },

  computed: {},

  filters: {
    leaderFilter (value) {
      return value ? '负责人' : '组员'
    }
  },

  watch: {},

  mounted () {},

  methods: {

    init (resource, userNameProp, userIdProp, purUseReason, isReagent = false) {
      this.resource = resource
      this.userNameProp = userNameProp
      this.userIdProp = userIdProp
      this.purUseReason = purUseReason
      this.isReagent = isReagent
      this.show()
    },

    show () {
      this.resourceModal.visible = true
      if (this.isReagent) {
        this.$refs.materialForm.getData(Object.assign({}, this.resource, { searchType: 'material' }))
      } else {
        this.$refs.materialForm.getData(Object.assign({}, this.resource, { searchType: 'cate' }))
      }
    },

    cancel () {
      this.$emit('cancel')
      this.resourceModal.visible = false
    }
  }
}
</script>

<style lang="less" scoped>

  .modal-container {
    height: 100%;

    .table-person-task {

      /deep/ .ant-table-fixed-left {
        .ant-table-body-inner {
          padding: 0;
        }
      }

      /deep/ .ant-checkbox-wrapper {
        .ant-checkbox + span {
          padding: 0 3px 0 5px;
        }
      }

      /deep/ .ant-table-placeholder {
        color: red;
        font-weight: bolder;
      }

      /deep/ .ant-table-thead > tr > th.off-day {
        color: red !important;
      }

      /deep/ .has-check {
        color: red !important;

        /deep/ .ant-checkbox-inner {
          border: 1px solid red;
        }
      }
    }

    .ant-modal {
      height: 100%;
      overflow: hidden;
      padding-bottom: 0px;

      .ant-modal-content {
        height: 100%;
        overflow: hidden;

        .ant-modal-body {
          overflow: auto;
          padding: 5px;
          height: ~"calc(100% - 108px)";
        }

        .ant-modal-body::-webkit-scrollbar {
          display: none;
        }
      }
    }

  }

</style>
