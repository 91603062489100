'use strict'

/**
 * 检测日志相关API
 */

import { getAction } from '@/api/common'

const checkTaskLogPre = '/ms-api/check-log'

export const checkTaskLogApi = {
  list: (param) => getAction(`${checkTaskLogPre}/list`, param)
}
