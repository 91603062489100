'use strict'

/**
 * 检测任务相关API
 */

import { getAction, postAction, postDataAction, postRepeatAction } from '@/api/common'

const checkTask = '/ms-api/check-task'
const checkTaskBatchPre = '/ms-api/check-task-batch'
const checkTaskBatchGroupPre = '/ms-api/check-task-batch-group'
const checkSampleItemPre = '/ms-api/check-sample-item'
const checkTaskEnterPre = '/ms-api/check-task-enter'
const checkTaskEnterExpressionPre = '/ms-api/check-task-enter-expression'
const checkTaskVerifyPre = '/ms-api/check-task-verify'

export const checkTaskApi = {
  list: (param) => postRepeatAction(`${checkTask}/list`, param),
  sampleList: (param) => getAction(`${checkTask}/sample-list`, param),
  projectList: (param) => getAction(`${checkTask}/project-list`, param),
  projectStat: (param) => getAction(`${checkTask}/project-stat`, param),
  save: (data) => postDataAction(`${checkTask}/save`, JSON.stringify(data)),
  start: (data) => postDataAction(`${checkTask}/start`, JSON.stringify(data)),
  batchStart: (data) => postDataAction(`${checkTask}/batch-start`, JSON.stringify(data)),
  delete: (data) => postAction(`${checkTask}/del`, data),
  batchDelete: (data) => postDataAction(`${checkTask}/batch-del`, data),
  setCurveControl: (data) => postDataAction(`${checkTask}/curve-control`, data),
  done: (data) => postDataAction(`${checkTask}/done`, JSON.stringify(data)),
  AddLabParallelTask: (data) => postDataAction(`${checkTask}/lab-parallel-task/add`, data),
  addLabBlankTask: (data) => postDataAction(`${checkTask}/lab-blank-task/add`, data),
  addStandardSampleTask: (data) => postDataAction(`${checkTask}/lab-standard-task/add`, data),
  checkTaskItemTree: (param) => getAction(`${checkTask}/check-task-item-tree`, param),
  getShowDataByProjId: (param) => getAction(`${checkTask}/getShowDataByProjId`, param),
  projectCateSiteItemStat: (projId) => getAction(`${checkTask}/cateSIteItemStatByProjId/${projId}`),
  getProjIdByBatchId: (param) => getAction(`${checkTask}/getProjIdByBatchId`, param),
  seletCompositItem: (param) => getAction(`${checkTask}/seletCompositItem`, param), // 获取复合批次下的样品信息
  contentAudit: (data) => postDataAction('/ms-api/check-task-offline/contentAudit',data),
  againAudit: (data) => postDataAction('/ms-api//check-task-offline/againAudit',data),
  samplingAudit: (data) => postDataAction(`/ms-api/check-task-offline/samplingAudit/${data}`),
  againSamplingAudit: (data) => postDataAction(`/ms-api/check-task-offline/againSamplingAudit/${data}`)
}

export const checkTaskBatchApi = {
  list: (param) => getAction(`${checkTaskBatchPre}/list`, param),
  save: (data) => postDataAction(`${checkTaskBatchPre}/save`, JSON.stringify(data)),
  getDetailInfoById: (id) => getAction(`${checkTaskBatchPre}/findDetailById/${id}`),
  selectLocation: (param) => getAction(`${checkTaskBatchPre}/selectLocation`, param),
  selectSamInfo: (param) => getAction(`${checkTaskBatchPre}/selectSamInfo`, param),
  selectDeaultDevList : (param) => getAction(`${checkTaskBatchPre}/selectDeaultDevList`, param)
}

export const checkTaskBatchGroupApi = {
  list: (param) => getAction(`${checkTaskBatchGroupPre}/list`, param),
  save: (data) => postDataAction(`${checkTaskBatchGroupPre}/save`, JSON.stringify(data)),
  saveWithDetail: (data) => postDataAction(`${checkTaskBatchGroupPre}/save-with-detail`, JSON.stringify(data)),
  getDetailInfoById: (id) => getAction(`${checkTaskBatchGroupPre}/findDetailById/${id}`)
}

export const checkSampleItemApi = {
  list: (param) => getAction(`${checkSampleItemPre}/list`, param)
}

/**
 * 检测数据录入后台接口
 */
export const checkTaskEnterApi = {
  /**
   *  获取检测录入的数据
   * @param modelId 检测模型ID
   * @param batchId 批次ID
   * @param sampleTypes {Array.<string>} 批次ID
   * @return {Promise<any>} 录入的数据
   */
  getData: (modelId, batchId, sampleTypes) => getAction(`${checkTaskEnterPre}/getData`, { batchId, modelId, sampleTypes }),

  /**
   * 保存检测录入的数据
   * @param modelId {number} 检测模型ID
   * @param models {Array.<Object>} 录入的数据
   */
  save: (modelId, models) => postDataAction(`${checkTaskEnterPre}/save/${modelId}`, models),
  /**
   * 生成默认检测录入的数据
   * @param num 录入数据数量
   */
  randomByNumber: (num) => getAction(`${checkTaskEnterPre}/randomByNumber/${num}`),
  /**
   * 请求录入的数据，这是专用于粪大肠菌群、耐热大肠菌群、总大肠菌群的mpnOneHundredMl字段的请求接口
   */
  mpnSelectInfo: (param) => getAction(`/ms-api/mpnConfigController/selectInfo`,param),
  getVolumeInfo: (param) => getAction(`${checkTaskEnterPre}/getVolumeInfo`,param)
}

/**
 * 检测数据录入公式后台接口
 */
export const checkTaskEnterExpressionApi = {
  /**
   * 保存检测录入中的公式
   * @param models {Array.<Object>} 公式数据
   */
  save: (models) => postDataAction(`${checkTaskEnterExpressionPre}/save`, models)
}

/**
 * 检测任务验证相关API
 *
 */
export const checkTaskVerifyApi = {
  /**
   *  获取检测录入的数据
   * @param modelGroupId {number} 检测模型组ID
   * @param modelId {number}检测模型ID
   * @param batchId  {number}批次ID
   * @param sampleTypes {Array.<string>} 批次ID
   * @return {Promise<any>} 录入的数据
   */
  getData: (modelGroupId, modelId, batchId, sampleTypes) => getAction(`${checkTaskVerifyPre}/getData`, { modelGroupId, batchId, modelId, sampleTypes })
}
