<template>
  <div>
    <a-modal v-bind="modal">
      <hot-table
        ref="table"
        :data="mixSolutionTypeList"
        :settings="setting"
        :colHeaders="setting?.columns?.map((item) => item.title)"
        language="zh-CN"
        style="width: 100%"
      ></hot-table>
      <template #footer>
        <a-button @click="cancel">关闭</a-button>
        <a-button type="primary" @click="ok">确定</a-button>
      </template>
    </a-modal>
    <a-modal v-bind="solutionTypeSelectModal">
      <solution-type-select-table v-bind="solutionTypeSelectModal.data" v-on="solutionTypeSelectModal.tableAction"></solution-type-select-table>
      <template #footer>
        <a-button @click="solutionTypeSelectModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="solutionTypeSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import SolutionTypeSelectTable from '@/pages/cloud-quote/check-standard-solution-type/modules/table/SolutionTypeSelectTable'
import { groupBy, orderBy, uniqBy, cloneDeep, debounce, keys } from 'lodash'

import { HotTable } from '@handsontable/vue'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import { registerLanguageDictionary, zhCN } from 'handsontable/i18n'

registerAllModules()
registerLanguageDictionary(zhCN)
export default {
  name: 'ExistingMixSolutionTypeModal',
  components: { SolutionTypeSelectTable, HotTable },
  props: {
    // 常用容量单位
    capacityUnitMap: {
      type: Object,
      require: false,
      default: () => {}
    },
    // 常用质量单位
    massUnitMap: {
      type: Object,
      require: false,
      default: () => {}
    },
    // 常用浓度单位
    densityUnitMap: {
      type: Object,
      require: false,
      default: () => {}
    },
    // 常用峰面积单位
    peakAreaUnitMap: {
      type: Object,
      require: false,
      default: () => {}
    },
    // 有效时长单位
    reagentCateValidDateUnitMap: {
      type: Object,
      require: false,
      default: () => {}
    }
  },
  data() {
    return {
      modal: {
        visible: false,
        width: '100%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择混合标液类型',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' }
      },
      solutionTypeSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择标液类型',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' },

        show: (row) => {
          this.solutionTypeSelectModal.visible = true
          this.row = row
          this.rowData = this.mixSolutionTypeList[row]
        },

        action: {
          cancel: () => {
            this.solutionTypeSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.solutionTypeSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            console.log(selectData)

            const { id, name, purity } = selectData[0]

            // 标液类型初始浓度值
            let initialPurityNumber = this.getNum(purity)
            // 最终浓度单位
            let densityUnit = this.getUnit(purity)
            // this.$set(this.rowData, 'solutionTypeId', id)
            // this.$set(this.rowData, 'solutionName', name)
            // this.$set(this.rowData, 'initialPurity', purity)
            // this.$set(this.rowData, 'initialPurityNumber', initialPurityNumber)
            // this.$set(this.rowData, 'densityUnit', densityUnit)
            // this.$set(this.rowData, 'solutionPurityNumber', initialPurityNumber)
            // this.$set(this.rowData, 'solutionPurity', purity)
            this.mixSolutionTypeList.forEach((item) => {
              this.$set(item, 'solutionTypeId', id)
              this.$set(item, 'solutionName', name)
              this.$set(item, 'initialPurity', purity)
              this.$set(item, 'initialPurityNumber', initialPurityNumber)
              this.$set(item, 'densityUnit', densityUnit)
              this.$set(item, 'solutionPurityNumber', initialPurityNumber)
              this.$set(item, 'solutionPurity', purity)
            })
            this.$refs.table?.hotInstance.render()
            this.solutionTypeSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'solutionTypeSelectTable',
          selectType: 'radio'
        },

        tableAction: {}
      },

      setting: {
        width: '100%',
        height: 'auto',
        rowHeaders: true,
        colHeaders: true,
        autoColumnSize: true,
        filters: true,
        // dropdownMenu: true,
        manualColumnMove: true,
        multiColumnSorting: true,
        stretchH: 'all',
        className: 'htMiddle htCenter',
        currentHeaderClassName: 'ht__highlight',
        activeHeaderClassName: 'ht__active_highlight',
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 100,
        columns: [
          { title: '因子', data: 'itemName', readOnly: true },
          { title: '标液类型', data: 'solutionName', width: 200, readOnly: true, renderer: this.solutionTypeSelectRenderer },
          { title: '使用液浓度', data: 'solutionPurityNumber', type: 'numeric', readOnly: true },
          {
            title: '使用液浓度单位',
            data: 'densityUnit',
            readOnly: true
            // editor: 'select',
            // selectOptions: this.densityUnitMap,
            // renderer: (instance, td, row, col, prop, value, cellProperties) => this.dataCodeRender(instance, td, row, col, prop, value, cellProperties, this.densityUnitMap)
          },
          { title: '波长', data: 'wavelength' },
          { title: '回归方程', data: 'regressionEquation', defaultValue: 'Y = bX + a' },
          // { title: '有效时长', data: 'validDate' },
          // {
          //   title: '有效时长单位',
          //   data: 'validDateUnit',
          //   defaultValue: 'd',
          //   editor: 'select',
          //   selectOptions: this.reagentCateValidDateUnitMap,
          //   renderer: (instance, td, row, col, prop, value, cellProperties) =>
          //     this.dataCodeRender(instance, td, row, col, prop, value, cellProperties, this.reagentCateValidDateUnitMap)
          // },
          { title: '相关系数最小值', data: 'minCorrel', defaultValue: 0.9999 },
          {
            title: '标准使用液加入量单位',
            data: 'standardLiquid',
            defaultValue: 'mL',
            editor: 'select',
            selectOptions: this.capacityUnitMap,
            renderer: (instance, td, row, col, prop, value, cellProperties) => this.dataCodeRender(instance, td, row, col, prop, value, cellProperties, this.capacityUnitMap)
          },
          {
            title: '标准物质加入量单位',
            data: 'concentration',
            defaultValue: 'μg',
            editor: 'select',
            selectOptions: this.massUnitMap,
            renderer: (instance, td, row, col, prop, value, cellProperties) => this.dataCodeRender(instance, td, row, col, prop, value, cellProperties, this.massUnitMap)
          },
          {
            title: '测定浓度单位',
            data: 'purity',
            defaultValue: 'mg/L',
            editor: 'select',
            selectOptions: this.densityUnitMap,
            renderer: (instance, td, row, col, prop, value, cellProperties) => this.dataCodeRender(instance, td, row, col, prop, value, cellProperties, this.densityUnitMap)
          },
          { title: '仪器峰面积单位', data: 'result' },

          { title: '单点校准最小值', data: 'verifyAllowMinRange', type: 'numeric', defaultValue: -10 },
          { title: '单点校准最大值', data: 'verifyAllowMaxRange', type: 'numeric', defaultValue: 10 },
          { title: '单点校准有效时长', data: 'verifyValidDate', defaultValue: 1 },
          {
            title: '单点校准有效时长单位',
            data: 'verifyValidDateUnit',
            defaultValue: 'd',
            editor: 'select',
            selectOptions: this.reagentCateValidDateUnitMap,
            renderer: (instance, td, row, col, prop, value, cellProperties) =>
              this.dataCodeRender(instance, td, row, col, prop, value, cellProperties, this.reagentCateValidDateUnitMap)
          }
        ],
        afterChange: (changes) => {
          const self = this
          if (changes !== null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              console.log(row, prop, oldValue, newValue)
            })
          }
        }
      },

      row: -1,
      rowData: {},

      mixSolutionTypeList: []
    }
  },
  methods: {
    show(list) {
      this.modal.visible = true
      let mixSolutionTypeList = cloneDeep(list)

      setTimeout(() => {
        this.mixSolutionTypeList.splice(0, this.mixSolutionTypeList.length, ...mixSolutionTypeList)
        this.$refs.table?.hotInstance.render()
      }, 500)
    },
    cancel() {
      this.modal.visible = false
      this.mixSolutionTypeList.splice(0, this.mixSolutionTypeList.length)
    },
    ok() {
      let mixSolutionTypeList = cloneDeep(this.mixSolutionTypeList)
      this.$emit('ok', mixSolutionTypeList, '1')
      this.cancel()
    },
    solutionTypeSelectRenderer(instance, td, row, col, prop, value, cellProperties) {
      let self = this
      //   let rowData = instance.getSourceDataAtRow(row)
      //   console.log(rowData)

      td.innerText = value
      td.className = 'htMiddle htCenter htDimmed'
      // 渲染图标
      var span = document.createElement('span')
      span.innerHTML = `<a style="color: #1890ff; cursor: pointer;">请选择标准物质</a>`
      // 添加点击事件
      span.addEventListener('click', function () {
        // 这里可以添加你的点击事件逻辑

        self.solutionTypeSelectModal.show(row)
      })
      td.appendChild(span)
      // 返回渲染后的内容
      return td
    },
    // 字典渲染器
    dataCodeRender(instance, td, row, col, prop, value, cellProperties, options) {
      td.innerHTML = options?.[value] ?? ''
    },
    getNum(str) {
      if (str) {
        let matchs = str.match(/\d+(.\d+)?/g)
        if (matchs && matchs.length) {
          return matchs[0]
        }
      }
      return '0'
    },
    getUnit(val) {
      // console.log(val,val.replace(/[0-9]*/g, '').replace(/\./, ''));
      if (val) {
        return val.replace(/[0-9]*/g, '').replace(/\./, '')
      }
      return 'μg/ml'
    }
  }
}
</script>
<style lang="">
</style>