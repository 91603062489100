<template>
  <div>
    <a-form>
      <div class="ahmux-title" style="width: 100%; margin-bottom: 18px">
        {{ this.purList.purName }}
      </div>
      <div style="width: 100%; height: 100%">
        <a-card :bordered="false" :bodyStyle="{ padding: '0' }">
          <a-row>
            <a-col span="3">
              <div class="ahmux-sub-title" style="width: 100px">申请信息</div>
            </a-col>
            <a-col span="7">
              <div class="ahmux-sub-title-detail" style="width: 100%">申请人：{{ this.purList.userName }}</div>
            </a-col>
            <a-col span="7">
              <div class="ahmux-sub-title-detail" style="width: 100%">申请时间：{{ this.purList.dateOfApply }}</div>
            </a-col>
          </a-row>
          <a-row>
            <table class="mxtable">
              <tr>
                <td class="tdLabel">采购编号</td>
                <td>{{ this.purList.purNbr }}</td>
                <!--                <td class="tdLabel">期望采购日期</td>-->
                <!--                <td>{{this.purList.dateOfExpPur}}</td>-->
                <td class="tdLabel">采购原因</td>
                <td>{{ this.purList.purReason }}</td>
              </tr>
              <tr>
                <!--                <td class="tdLabel">采购原因</td>-->
                <!--                <td>{{this.purList.purReason}}</td>-->
                <td class="tdLabel">备注</td>
                <td colspan="3">{{ this.purList.purDesc }}</td>
              </tr>
            </table>
          </a-row>
        </a-card>
        <a-card :bordered="false" :bodyStyle="{ padding: '0', marginTop: '20px' }">
          <a-row>
            <a-col span="3">
              <div class="ahmux-sub-title" style="width: 100px">商品信息</div>
            </a-col>
            <div style="color: red; float: right; margin-right: 30px">
              总金额：<span title="总金额" style="font-size: 30px"> ￥{{ this.purList.purPrice }} </span>
            </div>
          </a-row>
          <a-row>
            <a-table ref="table" :rowKey="(r) => r.id" :columns="columns" :dataSource="goodsList" :pagination="false" class="mxtable" style="margin-bottom: 30px">
              <template
                v-for="(col, i) in ['materialId', 'speType', 'tecReq', /*'batchNum',*/ 'goodsNum', 'unit', 'goodsMoney', /*'termOfValid',*/ 'goodsDesc']"
                :slot="col"
                slot-scope="text, record"
              >
                <template v-if="col === 'materialId'">
                  {{ getMaterialName(record) }}
                </template>
                <!--<template v-else-if="col === 'unit'">
                  <template v-for="(value,key) of materialUnitCode">
                    <template v-if="key === text">
                      {{ value }}
                    </template>
                  </template>
                </template>-->
                <template v-else-if="col === 'goodsMoney'">
                  <span title="预计单价" style="color: red" :key="col + i"> ￥{{ text }} </span>
                </template>
                <template v-else-if="col === 'goodsDesc'">
                  <div style="height: 22px; overflow: auto" :key="col + i">{{ text }}</div>
                </template>
                <template v-else>
                  {{ text }}
                </template>
              </template>
            </a-table>
          </a-row>
        </a-card>
        <!-- 如果isPayApplication为true说明当前流程走到“付款申请”这一步了，可以编辑付款信息了 -->
        <PayInfoForm v-if="isPayApplication" :purList="purList" @ok="handleDetail"></PayInfoForm>
        <!-- 如果isPayApplication为false并且showPayInfo为true说明当前流程有“付款申请”了，但是不在当前这一步，可以显示付款信息了 -->
        <PayInfo v-else-if="!isPayApplication && showPayInfo" :purList="purList"></PayInfo>
      </div>
    </a-form>
  </div>
</template>

<script>
import { STable } from '@/components'
import { resPurApi, Get } from '@/api/quote'
import PayInfo from './PayInfo'
import PayInfoForm from './PayInfoForm'

export default {
  name: 'PurIndex',
  props: {
    recordId: {
      type: Number,
      default: 0
    },
    processData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    STable,
    PayInfo,
    PayInfoForm
  },
  data() {
    return {
      visible: false,
      purList: [],
      goodsList: [],
      columns: [
        {
          title: '物品类别',
          dataIndex: 'materialId',
          key: 'materialId',
          width: '200px',
          scopedSlots: { customRender: 'materialId' }
        },
        {
          title: '规格型号',
          dataIndex: 'speType',
          key: 'speType',
          width: '100px',
          scopedSlots: { customRender: 'speType' }
        },
        {
          title: '技术要求',
          dataIndex: 'tecReq',
          key: 'tecReq',
          width: '150px',
          scopedSlots: { customRender: 'tecReq' }
        },
        /* {
          title: '物资批次',
          dataIndex: 'batchNum',
          key: 'batchNum',
          width: '150px',
          scopedSlots: { customRender: 'batchNum' }
        }, */
        {
          title: '数量',
          width: '50px',
          dataIndex: 'goodsNum',
          key: 'goodsNum',
          scopedSlots: { customRender: 'goodsNum' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: '50px',
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '预计单价',
          dataIndex: 'goodsMoney',
          key: 'goodsMoney',
          width: '100px',
          scopedSlots: { customRender: 'goodsMoney' }
        },
        /* {
          title: '有效期',
          key: 'termOfValid',
          width: '180px',
          dataIndex: 'termOfValid',
          scopedSlots: { customRender: 'termOfValid' }
        }, */
        {
          title: '备注',
          key: 'goodsDesc',
          width: '120px',
          dataIndex: 'goodsDesc',
          scopedSlots: { customRender: 'goodsDesc' }
        }
      ],
      sctrList: [],
      isPayApplication: false,
      showPayInfo: false
    }
  },
  watch: {
    recordId: {
      handler(newValue, oldValue) {
        if (newValue && newValue > 0) {
          this.handleDetail(newValue)
        }
      },
      immediate: true
    },
    processData: {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        console.log(nv, ov)
        if (nv && nv.length && nv[nv.length - 1].name === '付款申请') {
          this.isPayApplication = true
        } else {
          this.isPayApplication = false
        }
        this.showPayInfo = nv?.some((item) => item.name === '付款申请') || false
        console.log(this.isPayApplication)
      }
    }
  },
  computed: {
    materialUnitCode() {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },
  methods: {
    initDictData() {
      this.$store.dispatch('code/select', 'material_unit_code')
    },
    getMaterialName(record) {
      let text = ''
      if (record.type === 1 || record.type === 3) {
        if (record.speType) {
          text = `${record.materialName}(${record.speType})`
        } else {
          text = `${record.materialName}`
        }
      } else {
        if (record.purity) {
          text = `${record.materialName}(${record.purity})`
        } else {
          text = `${record.materialName}`
        }
      }
      return text
    },
    handleCancel() {
      this.visible = false
    },
    handleDetail(id) {
      this.purList = []
      this.goodsList = []
      Get(`${resPurApi.getGoodsById}/${id}`, {}, {}).then((result) => {
        this.goodsList = result.data
        console.log(this.goodsList)
      })
      Get(`${resPurApi.list}`, { pageSize: 1, id: id, authEnable: false }, {}).then((result) => {
        this.purList = result.data[0]
      })
      this.visible = true
    }
  },
  created() {
    this.initDictData()
  }
}
</script>
