<template>
  <a-modal v-bind="fullModal" @ok="handleSubmit" v-on="fullModal">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="物资名称" v-bind="formItemLayout">
              <a-select
                v-model="queryParam.materialId"
                showSearch
                placeholder="请选择物资"
                style="width: 100%"
                :filterOption="false"
                @search="fetchUser"
                @change="e => handleChange(e)">
                <a-select-option v-for="d in selectData" :key="d.value">{{ d.text }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="出库数量" v-bind="formItemLayout">
              <a-input-number v-model="queryParam.num" :min="1" :max="100000000" @change="handleBlur"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <a-card v-bind="contentCard">
      <s-table
        ref="table"
        :scroll="scrollProp"
        :bordered="false"
        size="small"
        :columns="columns"
        :data="loadData"
        :pageSize="pageSize"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys,selectedRows:this.selectedRows,onChange: this.onSelectChange }">

        <template v-for="(col, index) in columns" v-if="col.scopedSlots" :slot="col.dataIndex" slot-scope="text, record">
          <div :key="index">
            <template v-if="record.editable"></template>
            <template v-else-if="col.dataIndex === 'cateId'">{{ getName(text, record) }}</template>
            <template v-else-if="col.dataIndex === 'unit'">{{ materialUnitCode[text] }}</template>
            <template v-else-if="col.dataIndex === 'locationId'">{{ getLocation(text, record) }}</template>
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template #purOutStatus="text">
          <a-tag v-if="text == '0' || text == null" color="blue">未领用</a-tag>
          <a-tag v-else color="green">可复用</a-tag>
        </template>
        <template #userName="text">
          <div style="color: #5ec75e">{{ text }}</div>
        </template>
        <template #operation="text, record">
          <a @click="handleRecord(record.id, 1)"><a-icon type="eye"></a-icon>使用记录</a>
        </template>
      </s-table>
    </a-card>
    <record-modal ref="record"></record-modal>
  </a-modal>
</template>

<script>
import mixinList from '@/pages/common/mixins/list'
import fullModal from '@/pages/common/mixins/modal/full'
import STree from '@/components/Tree/Tree'
import { STable } from '@/components'
import { Get, Post, resdevCategoryApi, resLocationApi, resMaterialApi, resMaterialOutApi, resPurUseApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import RecordModal from '@/pages/cloud-quote/res/res-stock/modules/recordModal'

import debounce from 'lodash/debounce'

export default {
  name: 'MaterialTable',
  components: {
    STable, STree, Post, resMaterialApi, RecordModal
  },
  mixins: [mixinList, fullModal],
  props: {
    categoryList: {
      type: Array,
      default: () => []
    },
    manufList: {
      type: Array,
      default: () => []
    },
    supplierList: {
      type: Array,
      default: () => []
    },
    locationList: {
      type: Array,
      default: () => []
    },

    cateType: {
      type: Number
    },

    clearStatus: {
      type: Boolean,
      default: false
    },

    useStatus: {
      type: Number,
      default: 1
    }
  },
  data () {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      selectData: [],
      tempData: [],
      tempDataRow: [],
      fetching: false,
      itemRecord: null,
      fullModal: {
        title: '物资列表'
      },
      locationShow: [],
      cateShow: [],
      viewUrl: sysCommonApi.view,
      selectedRowKeys: [],
      selectedRows: [],
      type: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        materialId: null,
        cateType: null,
        useStatus: 1,
        userName: null,
        projId: null
      },
      // 表头
      columns: [
        { title: '物资编号', dataIndex: 'id', scopedSlots: { customRender: 'id' } },
        { title: '内部编号', dataIndex: 'fixedAssetsId', scopedSlots: { customRender: 'fixedAssetsId' } },
        { title: '物资类目', dataIndex: 'cateName', scopedSlots: { customRender: 'cateName' } },
        { title: '物资名称', dataIndex: 'materialName', scopedSlots: { customRender: 'materialName' } },
        { title: '规格型号', dataIndex: 'speType', scopedSlots: { customRender: 'speType' } },
        { title: '批次', dataIndex: 'pc', scopedSlots: { customRender: 'pc' } },
        { title: '浓度', dataIndex: 'purity', scopedSlots: { customRender: 'purity' } },
        { title: '品牌', dataIndex: 'brand', scopedSlots: { customRender: 'brand' } },
        { title: '单位', dataIndex: 'unit', scopedSlots: { customRender: 'unit' } },
        { title: '有效期', dataIndex: 'termOfValid', scopedSlots: { customRender: 'termOfValid' } },
        { title: '放置位置', dataIndex: 'locationId', scopedSlots: { customRender: 'locationId' } },
        { title: '领用状态', dataIndex: 'purOutStatus', scopedSlots: { customRender: 'purOutStatus' } },
        { title: '领用人', dataIndex: 'userName', scopedSlots: { customRender: 'userName' } },
        { title: '操作', dataIndex: 'operation', width: '100px', scopedSlots: { customRender: 'operation' } }
      ],
      pageSize: this.$store.getters.pageSize,
      allData: [],
      devIds: [],
      materialIds: []
    }
  },

  computed: {
    materialUnitCode () {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },

  created () {
    Get(resLocationApi.selectList, { pageSize: 500 }).then(result => {
      this.locationShow = result.data
    })
    Get(resdevCategoryApi.selectList, { pageSize: 500 }).then(result => {
      this.cateShow = result.data
    })
    this.initDictData()
  },

  methods: {
    handleBlur (value) {
      this.selectedRowKeys = []
      this.selectedRows = []
      const data = this.tempData
      const dataRow = this.tempDataRow
      if (data.length < value) {
        const pageSize = this.$store.getters.pageSize
        if (value > pageSize) {
          this.$message.error(`当前页面只有` + pageSize + '条数据!')
        } else {
          this.$message.error(`当前库存不足!`)
        }
        return
      }
      for (let i = 0; i < value; i++) {
        this.selectedRowKeys.push(data[i])
        this.selectedRows.push(dataRow[i])
      }
    },
    loadData (parameter) {
      return Get(resMaterialOutApi.getMaterialList, Object.assign(parameter, this.queryParam)).then(res => {
        this.tempData = []
        this.tempData = res.data.map(item => item.id)
        this.tempDataRow = []
        res.data.forEach(item => {
          item.key = item.id
        })
        this.tempDataRow = res.data
        return res
      })
    },
    handleChange (value) {
      this.$refs.table.refresh(true)
      this.selectedRowKeys = []
      this.selectedRows = []
    },

    fetchUser (value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.fetching = true
      Get(resMaterialApi.list, { pageSize: 1000, materialName: value }).then(result => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        const data = result.data.map(material => ({
          text: `${material.materialName}(${material.speType})`,
          value: material.id

        }))
        this.selectData = data
        this.fetching = false
      })
    },

    handleSubmit () {
      this.selectedRowKeys = this.selectedRowKeys.filter(item => !isNaN(item))
      if (this.selectedRowKeys.length !== parseInt(this.queryParam.num)) {
        this.$message.error('所选数量(' + this.selectedRowKeys.length + ')与出库数量不符，请重新选择！')
        return
      }
      const cateType = this.itemRecord.cateType
      let type = null
      if (cateType === '3') {
        type = 'device'
      } else {
        type = 'container'
      }
      if (type) {
        let tableData = []
        // 如果需要数量大于分页数量，需要从所有数据中筛选出需要的数据
        if (this.queryParam.num > this.pageSize) {
          tableData = this.allData.filter(it => this.selectedRowKeys.indexOf(it.id) !== -1)
        } else {
          tableData = this.$refs.table.rowSelection.selectedRows
        }

        // 判断已选设备是否是对应的设备
        let materialId = ''
        if (tableData.length > 0) {
          materialId = tableData[0].materialId
          // 不是对应设备则需要判断是否已经选过此设备
          if (this.itemRecord.id !== materialId) {
            for (let i = 0; i < tableData.length; i++) {
              const item = tableData[i]
              if (item.cateType === 3) {
                if (this.devIds.indexOf(item.id) !== -1) {
                  this.$message.error('检测到出库物资中已存在' + item.id + '_' + item.fixedAssetsId + '_' + item.materialName + '_' + item.speType + '，请重新选择！')
                  return
                }
              } else {
                if (this.materialIds.indexOf(item.id) !== -1) {
                  this.$message.error('检测到出库物资中已存在' + item.id + '_' + item.materialName + '_' + item.speType + '，请重新选择！')
                  return
                }
              }
            }
          }
        }

        const d = tableData
        const tmp = []
        for (let i = 0; i < d.length; i++) {
          const a = d[i]
          a.type = type
          tmp.push(a)
        }
        const data = tmp
        this.$emit('updateData', data, this.itemRecord)

        this.selectedRows = []
        this.selectedRowKeys = []
        this.fullModal.visible = false
        this.$emit('cancel')
      }
    },

    initDictData () {
      // 根据字典Code, 初始化字典数组
      this.$store.dispatch('code/select', 'material_unit_code')
    },

    handleLocationClick (record) {
      this.$refs.locationForm.add(record)
    },

    handleButtonClick (e) {
      this.$refs.form.add()
    },

    handleMenuClick (e) {
      if (e.key === '1') {
        this.$refs.form.add()
      } else {
        this.$refs.purTable.add()
      }
    },

    handleButtonXHClick (e) {
      this.batchXH()
    },

    handleMenuXHClick (e) {
      this.batchXH()
    },

    handleButtonGHClick (e) {
      this.$refs.purUseTable.add()
    },

    handleMenuGHClick (e) {
      if (e.key === '1') {
        this.$refs.purUseTable.add()
      } else {
        this.$refs.purUseTable.add()
      }
    },

    batchXH () {
      Post(resPurUseApi.resMaterialXh, { ids: this.selectedRowKeys }).then(result => {
        if (result.code === 0) {
          this.$message.success(`操作成功`)
          this.$refs.table.refresh(true)
        } else {
          this.$message.error(result.msg)
        }
      })
    },

    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    handleClick (e) {
      this.queryParam = {
        key: e.key
      }
      this.$refs.table.refresh(true)
    },

    handleHref (record) {
      this.$router.push({ name: 'res-dev-cbt', params: {} })
    },

    getName (text, record) {
      const cate = this.cateShow.filter(item => item.id.toString() === record.cateId)[0]
      if (cate) {
        record.cateName = cate.name
      } else {
        record.cateName = '-'
      }
      return record.cateName
    },

    getLocation (text, record) {
      const locationInfo = this.locationShow?.filter(item => item.id.toString() === record.locationId)?.[0]
      return locationInfo?.name ?? '-'
    },

    getImg (text, record) {
      return this.viewUrl + record.materialImg
    },

    edit (row) {
      row.editable = true
    },
    // eslint-disable-next-line

    cancel () {
      // row.editable = false
      this.$refs.table.refresh()
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    resetSearchForm () {
      this.queryParam = {}
      this.$refs.table.refresh(true)
    },

    handleOk () {
      this.$refs.table.refresh()
    },

    handleAdd (record, keyArr, userName, projId, devIds, materialIds) {
      this.queryParam.userName = userName
      this.queryParam.cateType = record.cateType
      this.queryParam.materialId = record.id
      this.queryParam.num = record.numOfUse
      this.devIds = devIds
      this.materialIds = materialIds
      // 如果需要数量大于分页数量，切换页面时会丢失之前页面的数据，这里查出部分数据，提交时根据选择id进行筛选
      if (this.queryParam.num > this.pageSize) {
        Get(resMaterialOutApi.getMaterialList, Object.assign({ pageSize: 500 }, this.queryParam)).then(res => {
          res.data.forEach(item => {
            item.key = item.id
          })
          this.allData = res.data
        })
      }
      if (projId) {
        this.queryParam.projId = projId
      }
      this.selectData = []
      // 记录上级信息
      this.itemRecord = record
      this.type = record.type
      this.fullModal.title = '选择物资'
      this.fullModal.visible = true
      /* this.queryParam.speType = record.speType
      this.queryParam.purity = record.purity */
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      Get(resMaterialApi.list, { pageSize: 1000 }).then(result => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        const data = result.data.map(material => ({
          text: `${material.materialName}(${material.speType})`,
          value: material.id
        }))
        this.selectData = data
        this.fetching = false
        this.queryParam.materialId = record.id
        // this.$refs.table.refresh(true)
      })
      if (keyArr && keyArr.length) {
        this.selectedRowKeys = []
        for (let i = 0; i < keyArr.length; i++) {
          this.selectedRowKeys[i] = parseInt(keyArr[i])
        }
      }
    },

    handleEdit (_record, status) {
      this.$refs.form.edit(_record, status)
    },

    handleRecord (materialId, type) {
      this.$refs.record.init(materialId, type)
    }
  }
}
</script>
