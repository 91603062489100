<template>
  <div class="check-standard-curve-form-detail">
    <div class="action" style="position: absolute;top:70px;right: 40px;z-index: 1">
      <a-dropdown>
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="solutionTableModal.show">
            标液配置
          </a-menu-item>
        </a-menu>
        <a-button type="primary"> <a-icon type="setting" /> </a-button>
      </a-dropdown>
    </div>
    <div class="form-div">
      <iframe :src="formSrc" v-bind="form"></iframe>
    </div>
    <!-- 指派任务 -->
    <assign :ids="reviewIds" @cancel="()=>{this.assignShow=false;this.$emit('success')}" @success="(val)=>{this.review(val);this.assignShow=false;}" ref="assign" v-if="assignShow"/>

    <!-- 复核任务 -->
    <review
      :authMark="true"
      :checkUser="checkUser"
      :ids="reviewIds"
      :template-file="this.templateFile"
      @cancel="()=>{this.reviewShow=false}"
      @success="handleReviewSuccess"
      ref="review"
      v-if="reviewShow"/>

    <!-- 标液配置 -->
    <check-standard-solution-table-modal v-bind="solutionTableModal" v-on="solutionTableModal"/>

  </div>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { checkStandardCurveApi } from '@/api/checkStandardCurve/checkStandardCurveApi'

import Review from '@/pages/cloud-quote/check-standard-curve/modules/review'
import Assign from '@/pages/cloud-quote/check-standard-curve/modules/assign'
import { CheckStandardSolutionTableModal } from '@/pages/cloud-quote/check-standard-solution/modules'

export default {
  name: 'OriginalRecord',

  components: { Assign, Review, CheckStandardSolutionTableModal },

  props: {

    type: {
      type: String,
      required: false,
      default: 'view'
    },

    templateId: {
      type: Number,
      required: true
    },

    templateFile: {
      type: String,
      required: true
    },

    id: {
      type: String,
      required: false,
      default: null
    },

    solutionId: {
      type: String,
      required: false,
      default: undefined
    },
    standardMethodId: Number,
    standardInfo: String
  },
  data () {
    return {
      assignShow: false,
      reviewShow: false,
      reviewIds: [],
      checkUser: null,

      checkSolutionId: this.solutionId,

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      solutionTableModal: {
        ref: 'solutionTableModal',

        success: (records) => {
          this.checkSolutionId = records.length && records[0].id
        },

        show: () => {
          this.$refs[this.solutionTableModal.ref].show()
        }

      }
    }
  },

  computed: {
    formSrc () {
      if (this.id) {
        return `${fileTemplateUreportApi.form}?_u=${this.templateFile}&id=${this.id}&solutionId=${this.checkSolutionId}`
      }
      return `${fileTemplateUreportApi.form}?_u=${this.templateFile}&solutionId=${this.checkSolutionId}&standardInfo=${this.standardInfo}`
    }
  },

  methods: {

    // 获取原始记录表数据
    getData () {
      return this.$refs[this.form.ref].contentWindow.getData()
    },

    // 显示复核弹窗
    showReviewModal (ids) {
      this.$confirm({
        title: '提示',
        content: '是否立即开始复核？',
        onOk: () => {
          this.assign(ids)
        },
        onCancel: () => {
          this.$emit('success')
        }

      })
    },

    // 指派
    assign (ids) {
      this.assignId = ids
      this.assignShow = true
      this.$nextTick(() => {
        this.$refs.assign.show()
      })
    },

    // 复核
    review (checkUser) {
      this.checkUser = checkUser
      this.reviewShow = true
      this.$nextTick(() => {
        this.$refs.review.show()
      })
    },

    handleReviewSuccess () {
      this.reviewShow = false
      this.$emit('success')
    },

    // 保存原始记录表
    save () {
      const data = this.getData()
      if (data) {
        checkStandardCurveApi.save(
          {
            id: !this.id ? null : this.id,
            content: JSON.stringify(data),
            fileTemplateId: this.templateId,
            fileTemplateName: this.templateFile,
            standardMethodId: this.standardMethodId
          }
        ).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reviewIds.splice(0, this.reviewIds.length, ...res.data)
            this.showReviewModal(this.reviewIds)
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.error('暂无数据需要提交')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
