'use strict'

const defaultColumn = [
  { title: '项目编号', dataIndex: 'projNo', sorter: true, width: 200, align: 'left', scopedSlots: { customRender: 'projNo' } },
  { title: '项目名称', dataIndex: 'projName', sorter: true, align: 'left', scopedSlots: { customRender: 'projName' } },
  { title: '项目状态', dataIndex: 'projStatus', sorter: true, width: 100, align: 'left', scopedSlots: { customRender: 'projStatus' } },
  { title: '负责人', dataIndex: 'projLeaderName', sorter: true, width: 120, align: 'left', scopedSlots: { customRender: 'projLeader' } },
  { title: '样品来源', dataIndex: 'projSampleSource', width: 100, align: 'left', scopedSlots: { customRender: 'projSampleSource' } },
  { title: '进度', dataIndex: 'projSampleProgress', width: 100, align: 'left', scopedSlots: { customRender: 'projSampleProgress' } },
  { title: '交接情况', dataIndex: 'sampleInfoHandoverStat', width: 200, align: 'left', scopedSlots: { customRender: 'sampleInfoHandoverStat' } },
  { title: '样品情况', dataIndex: 'sampleInfoStat', width: 200, align: 'left', scopedSlots: { customRender: 'sampleInfoStat' } },
  { title: '操作', dataIndex: 'action', width: 150, align: 'left', scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as projectSampleInfoTableDefaultColumn
}
