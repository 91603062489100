import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
/**
 * 制样管理的API维护
 */
import { getAction, postDataAction } from '@/api/common'

const makeSamplePre = '/ms-api/make-sample'
const makeSampleItemPre = '/ms-api/make-sample-item'
const makeSampleApplyPre = '/ms-api/make-sample-apply'
const makeSampleApplySamplePre = '/ms-api/make-sample-apply/sample'

export const makeSampleApplyApi = {
  list: (param) => getAction(`${makeSampleApplyPre}/list`, param),
  detail: (param) => getAction(`${makeSampleApplyPre}/detail`, param),
  applyMake: (data) => postDataAction(`${makeSampleApplyPre}/apply`, data),
  dealApply: (data) => postDataAction(`${makeSampleApplyPre}/deal`, data)
}

export const makeSampleApplySampleApi = {
  list: (param) => getAction(`${makeSampleApplySamplePre}/list`, param)
}

export const makeSampleApi = {
  list: (param) => getAction(`${makeSamplePre}/list`, param),
  make: (data) => postDataAction(`${makeSamplePre}/make`, data),
  outBound: (data) => postDataAction(`${makeSamplePre}/outBound`, data),
  storageApply: (data) => postDataAction(`${makeSamplePre}/storage-apply`, data),
  storage: (data) => postDataAction(`${makeSamplePre}/storage`, data),
  invalid: (data) => postDataAction(`${makeSamplePre}/invalid`, data),
  listDetailsByProjNo: (param) => getAction(`${makeSamplePre}/listDetailsByProjNo`, param)
}

export const makeSampleItemApi = {
  list: (param) => getAction(`${makeSampleItemPre}/list`, param)
}

// 样品类型配置
export const sampleTypeSetting = {
  // 现场环节可出库样品类型
  sampleOutBound: [sampleInfoTypeEnum.fieldBlank, sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.allBlank]
}
