<template>
  <div>
    <a-card style="margin: 16px auto;">
      <div>
        <table class="ahmux-table">
          <tr>
            <th class="tdLabel" style="width: 80px">分类</th>
            <th class="tdLabel" style="width: 80px">点位</th>
            <th class="tdLabel" style="width: 460px">检测内容</th>
          </tr>
          <template v-for="(cate,cateIdx) in quoteInfo.cateList">
            <template v-if="!cate.siteList||cate.siteList.length==0">
              <tr :id="`cate_${cateIdx}`" :key="cateIdx">
                <td>{{ cate.name }}
                </td>
                <td colspan="2">
                </td>
              </tr>
            </template>
            <template v-else>
              <tr :id="`cate_${cateIdx}_site_${siteIdx}`" v-for="(site,siteIdx) in cate.siteList" :key="siteIdx">
                <td v-if="siteIdx==0" :rowspan="cate.siteList.length+1">
                  {{ cate.name }}
                </td>
                <td>{{ site.siteName }}
                </td>
                <td>
                  <PlanContentItemCosts :itemList.sync="site.quoteItemGroupList"></PlanContentItemCosts>
                </td>
              </tr>
              <tr :key="`cate_${cateIdx}_site_add`">
                <td colspan="2">
                </td>
              </tr>
            </template>
          </template>
          <tr>
            <td colspan="3">
            </td>
          </tr>
        </table>
      </div>
    </a-card>
  </div>
</template>

<script>
import PlanContentItemCosts from './PlanContentItemCosts'

export default {
  name: 'PlanContentCosts',
  components: {
    PlanContentItemCosts
  },
  props: {
    quoteInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      defaultSiteNum: 3
    }
  },
  methods: {
    subStr (text, len) {
      len = len && len > 0 ? len : 8
      if (text) {
        if (text.length > len) {
          return text.substring(0, len) + '...'
        } else {
          return text
        }
      } else {
        return ''
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="less">
@import "~@/assets/less/table/index2";
.ahmux-table{
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #caedff;
  }
}

th.column-center,
td.column-center {
  text-align: center !important;
  padding: 10px;
}

.card-blue .card-bg-color{
  background-color: #e6f7ff;
}
.card-blue .card-border {
  border: 1px solid #91d5ff;
}

.card-cyan .card-bg-color{
  background-color: #e6fffb;
}
.card-cyan .card-border {
  border: 1px solid #87e8de;
}

.card-green .card-bg-color{
  background-color: #f6ffed;
}
.card-green .card-border {
  border: 1px solid #b7eb8f;
}

.total-desc{
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 8px;
  border-radius: 4px;
}
.total-desc b{
  font-weight: 600;
  color: #40a9ff;
  margin-right: 5px;
}
.div-box{
  font-size: 16px;
  border: 1px dashed #d9d9d9;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: #fafafa;
  padding: 10px 0px;
}
</style>
<style lang="less" scoped>
.quote-card-grid {
  width: 100%;
  margin: 5px 5px;
}
/*.card{*/
/*  margin: 18px 0px;*/
/*  /deep/ .ant-card-body {*/
/*    padding: 0px;*/
/*  }*/
/*}*/

/*.card-not-padding{*/
/*  margin: 18px 10px;*/
/*  /deep/ .ant-card-body {*/
/*    padding: 0px;*/
/*  }*/
/*}*/

.sort-button .ant-radio-button-wrapper-checked {
  color: orange;border-color: orange;
}
.sort-button .ant-radio-button-wrapper-checked::before {
  background-color: orange !important;
  opacity: 0.1;
}
</style>
