<template>
  <div>
    <div class="descriptions-view" style="margin-bottom: 10px">
      <table style="width: 100%">
        <tbody>
          <tr class="descriptions-row">
            <th class="descriptions-item-label" colspan="2">方法名称</th>
            <th class="descriptions-item-label" colspan="3">方法依据</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content" colspan="2">{{ curveData.methodName }}</td>
            <td class="descriptions-item-content" colspan="3">{{ curveData.standardCode }}</td>
          </tr>

          <tr class="descriptions-row">
            <th class="descriptions-item-label" :colspan="'5'">设备型号</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content" :colspan="'5'">{{ curveData.devName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <a-tabs style="background: #fff" v-model="itemId" @change="clickTab">
      <!--  style="background:#fff;" -->
      <a-tab-pane v-for="(v, i) in mixSolutionList" :tab="v.itemName" :key="v.itemId"></a-tab-pane>
    </a-tabs>
    <div class="descriptions-view" style="margin-bottom: 10px">
      <table style="width: 100%">
        <tbody>
          <tr class="descriptions-row">
            <th class="descriptions-item-label" colspan="2">配置时间</th>
            <th class="descriptions-item-label" colspan="3">设备编号</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content" colspan="2">{{ curveRecord.makeDate ? curveRecord.makeDate : currentSolution.makeDate }}</td>
            <td class="descriptions-item-content" colspan="3">
              <template v-if="currentSolution.devId">
                <a-tag v-for="item in currentSolution.devId" :key="item" color="orange">{{ devMap[item]?.fixedAssetsId }}</a-tag>
              </template>
            </td>
          </tr>

          <tr>
            <th class="descriptions-item-label" colspan="2">标准使用液类型</th>
            <th class="descriptions-item-label">浓度</th>
            <th class="descriptions-item-label" colspan="2">标准使用液编号</th>
          </tr>
          <tr>
            <td class="descriptions-item-content" colspan="2">{{ currentSolution.solutionTypeName }}</td>
            <td class="descriptions-item-content">
              <a-tag color="pink">{{ currentSolution.solutionPurity }}</a-tag>
            </td>
            <td class="descriptions-item-content" colspan="2">
              <a-tag v-if="currentSolution.solutionNo" color="pink">{{ currentSolution.solutionNo }}</a-tag>
            </td>
          </tr>

          <tr v-if="curveData.configMode === checkStandardCurveModeEnum.manMade" class="descriptions-row">
            <th class="descriptions-item-label" colspan="2">参比溶液</th>
            <th class="descriptions-item-label" colspan="3">比色皿</th>
          </tr>
          <tr v-if="curveData.configMode === checkStandardCurveModeEnum.manMade" class="descriptions-row">
            <td class="descriptions-item-content" colspan="2">
              <a-tag color="burlywood">{{ currentSolution.solventName }}</a-tag>
            </td>
            <td class="descriptions-item-content" colspan="3">{{ curveData.thickness }}</td>
          </tr>

          <tr class="descriptions-row">
            <template v-if="curveData.configMode === checkStandardCurveModeEnum.manMade">
              <th class="descriptions-item-label">波长</th>
              <th class="descriptions-item-label">回归方程</th>
              <th class="descriptions-item-label">截距(a)</th>
              <th class="descriptions-item-label">斜率(b)</th>
              <th class="descriptions-item-label">相关系数(r)</th>
            </template>
            <template v-if="curveData.configMode === checkStandardCurveModeEnum.fileMade">
              <th class="descriptions-item-label" colspan="5">附件</th>
            </template>
          </tr>
          <tr class="descriptions-row">
            <template v-if="curveData.configMode === checkStandardCurveModeEnum.manMade">
              <td class="descriptions-item-content">{{ currentSolution.wavelength }}</td>
              <td class="descriptions-item-content">{{ currentSolution.regressionEquation }}</td>
              <td class="descriptions-item-content">
                <a-tag color="cyan">{{ currentSolution.intercept }}</a-tag>
              </td>
              <td class="descriptions-item-content">
                <a-tag color="cyan">{{ currentSolution.slope }}</a-tag>
              </td>
              <td class="descriptions-item-content">
                <a-tag color="cyan">{{ currentSolution.correlationCoefficient }}</a-tag>
              </td>
            </template>
            <template v-if="curveData.configMode === checkStandardCurveModeEnum.fileMade">
              <td class="descriptions-item-content" colspan="5">
                <template v-for="file in currentSolution.fileList">
                  <a style="padding: 1px; color: #3d93fd" @click="downloadFile(file)">
                    <a-icon style="margin: 0 6px; color: #909399" type="file-text" />
                    {{ file.fileName }}</a
                  >
                </template>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <a-card v-if="curveData.configMode === checkStandardCurveModeEnum.manMade" title="曲线信息">
      <a-table v-bind="curveDrawTable">
        <template v-for="(col, i) in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text, itemRecord">
          {{ text }}
        </template>
      </a-table>
    </a-card>
    <a-card
      v-if="curveRecord.reviewStatus === checkStandardCurveReviewStatusEnum.pass || curveRecord.reviewStatus === checkStandardCurveReviewStatusEnum.rejected"
      style="margin-top: 10px"
      title="复核信息"
    >
      <table style="width: 100%">
        <tbody>
          <tr class="descriptions-row">
            <th class="descriptions-item-label">复核人</th>
            <th class="descriptions-item-label">复核时间</th>
            <th class="descriptions-item-label">复核结果</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content">{{ curveRecord.reviewUserName }}</td>
            <td class="descriptions-item-content">{{ curveRecord.reviewTime }}</td>
            <td class="descriptions-item-content">
              <a-tag :color="checkStandardCurveReviewStatusDataMap[curveRecord.reviewStatus].color">{{
                checkStandardCurveReviewStatusDataMap[curveRecord.reviewStatus].label
              }}</a-tag>
            </td>
          </tr>
          <tr class="descriptions-row">
            <th class="descriptions-item-label" colspan="3">复核意见</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content" colspan="3">{{ curveRecord.reviewNote }}</td>
          </tr>
        </tbody>
      </table>
    </a-card>
    <a-card
      v-if="curveRecord.checkStatus === checkStandardCurveCheckStatusEnum.pass || curveRecord.checkStatus === checkStandardCurveCheckStatusEnum.rejected"
      style="margin-top: 10px"
      title="审核信息"
    >
      <table style="width: 100%">
        <tbody>
          <tr class="descriptions-row">
            <th class="descriptions-item-label">审核人</th>
            <th class="descriptions-item-label">审核时间</th>
            <th class="descriptions-item-label">审核结果</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content">{{ curveRecord.checkUserName }}</td>
            <td class="descriptions-item-content">{{ curveRecord.checkTime }}</td>
            <td class="descriptions-item-content">
              <a-tag :color="checkStandardCurveCheckStatusDataMap[curveRecord.checkStatus].color">{{ checkStandardCurveCheckStatusDataMap[curveRecord.checkStatus].label }}</a-tag>
            </td>
          </tr>
          <tr class="descriptions-row">
            <th class="descriptions-item-label" colspan="3">审核意见</th>
          </tr>
          <tr class="descriptions-row">
            <td class="descriptions-item-content" colspan="3">{{ curveRecord.checkNote }}</td>
          </tr>
        </tbody>
      </table>
    </a-card>
  </div>
</template>

<script>
import mixinForm from '@/pages/common/mixins/form'
import { sysCommonApi } from '@/api/common'
import { resDevApi } from '@/api/quote'
import { checkStandardCurveConfigApi, checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
// import { noActionCurveDrawColumns } from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import { baseCurveDrawColumns, curveDrawModels, remarkCurveDrawColumns } from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import {
  checkStandardCurveCheckStatusDataMap,
  checkStandardCurveCheckStatusEnum,
  checkStandardCurveModeEnum,
  checkStandardCurveModeOptions,
  checkStandardCurveReviewStatusDataMap,
  checkStandardCurveReviewStatusEnum
} from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { cloneDeep, groupBy, orderBy, sortBy, uniqBy } from 'lodash'

export default {
  name: 'PreviewForm',
  mixins: [mixinForm],
  props: {
    id: {
      type: [String, Number, Object],
      required: false,
      default: null
    },

    curveRecord: {
      type: Object,
      required: false,
      default: null
    }

    // columns: {
    //   type: Array,
    //   required: false,
    //   default: () => (noActionCurveDrawColumns)
    // }
  },
  data() {
    return {
      checkStandardCurveReviewStatusEnum,
      checkStandardCurveReviewStatusDataMap,
      checkStandardCurveCheckStatusEnum,
      checkStandardCurveCheckStatusDataMap,
      baseCurveDrawColumns,
      curveDrawModels,
      remarkCurveDrawColumns,
      checkStandardCurveModeEnum,
      checkStandardCurveModeOptions,
      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      // configData: {
      //   id: this.id,
      //   curveTypeId: this.curveRecord.curveTypeId,
      //   intercept: this.curveRecord.intercept,
      //   slope: this.curveRecord.slope,
      //   correlationCoefficient: this.curveRecord.correlationCoefficient,
      //   makeDate: this.curveRecord.makeDate,
      //   solutionId: this.curveRecord.solutionId,
      //   devId: this.curveRecord.devId,
      //   solutionNo: this.curveRecord.solutionNo,
      //   devNo: this.curveRecord.devNo
      // },
      curveData: {},
      solutionList: [],

      curveDrawTable: {
        rowKey: (item) => `${item.id}`,
        bordered: true,
        size: 'small',
        dataSource: [],
        // columns: this.columns,
        columns: [],
        scroll: { x: 890 },
        pagination: false
      },
      fileList: [],
      mixSolutionList: [],
      allDrawList: [],
      devMap: {},
      currentSolution: {},
      itemId: 0
    }
  },
  watch: {
    curveRecord: {
      immediate: true,
      handler(newRecord, oldRecord) {
        if (newRecord) {
          // newRecord.devNo = typeof newRecord.devNo === 'string' ? newRecord.devNo.split(',') : newRecord.devNo
          this.mixSolutionList = cloneDeep(newRecord.mixSolutionList)
          this.getCurveTypeDetail(newRecord.curveTypeId)
        }
        // this.getCurveSite(newRecord.id)
      }
    }
  },
  methods: {
    getCurveTypeDetail(id) {
      const param = { id: id }
      checkStandardCurveTypeApi.detailCurveType(param).then((res) => {
        if (res.code === 0 && res.data.length > 0) {
          const data = res.data[0]
          if (data.configMode === checkStandardCurveModeEnum.manMade) {
            this.initSiteUnit(data.siteModelId, data)
            this.getCurveSite(this.curveRecord.id)
            // } else if (data.configMode === checkStandardCurveModeEnum.fileMade) {
            //   this.fileList = JSON.parse(this.curveRecord.fileAppendix)
          }
          this.curveData = {
            id: data.id,
            name: data.name,
            methodName: data.methodName,
            standardCode: data.standardName + ' ' + data.standardCode,
            devTypeId: data.devTypeId,
            // solutionTypeId: data.solutionTypeId,
            // solutionName: data.solutionName,
            // solutionPurity: data.solutionPurity,
            referenceSolution: data.referenceSolution,
            referenceSolutionName: data.referenceSolutionName,
            thickness: data.thickness,
            // wavelength: data.wavelength,
            // regressionEquation: data.regressionEquation,
            devName: data.devName,
            configMode: data.configMode
          }
          this.getDevList(data.devTypeId)
          this.mixSolutionList.forEach((item) => {
            let devId = item.devId ? item.devId.split(',') : item.devId
            this.$set(item, 'devId', devId)
            if (item.fileAppendix) {
              this.$set(item, 'fileList', JSON.parse(item.fileAppendix))
            }

            data.mixSolutionTypeList?.forEach((item2) => {
              if (item.itemId === item2.itemId) {
                this.$set(item, 'solutionTypeId', item2.solutionTypeId)
                this.$set(item, 'solutionTypeName', item2.solutionName)
                this.$set(item, 'solutionPurity', item2.solutionPurity)
                this.$set(item, 'wavelength', item2.wavelength)
                this.$set(item, 'regressionEquation', item2.regressionEquation)
              }
            })
          })
        }
      })
    },
    getCurveSite(id) {
      const param = { id: id }
      checkStandardCurveConfigApi.curveSite(param).then((res) => {
        if (res.code === 0) {
          this.allDrawList = res.data || []
          // this.curveDrawTable.dataSource = res.data
          this.itemId = this.mixSolutionList[0].itemId
          this.clickTab()
        }
      })
    },
    initSiteUnit(siteId, data) {
      const columns = this.curveDrawModels.filter((item) => item.id === siteId)[0].columns
      const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns]))
      allColumns.forEach((item) => {
        if (data.hasOwnProperty(item.dataIndex) && data[item.dataIndex] !== null && data[item.dataIndex] !== '') item.title = item.title + '（' + data[item.dataIndex] + '）'
      })
      this.curveDrawTable.columns = allColumns
    },
    clickTab(key) {
      console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq')
      let currentSolution = this.mixSolutionList.find((item) => item.itemId === this.itemId) // 混合标液
      this.currentSolution = currentSolution || {} // 根据选择的因子过滤出的标液
      if (this.allDrawList.length) {
        let currentDrawList = this.allDrawList.filter((item) => item.itemId === this.itemId) // 点位数据

        this.curveDrawTable.dataSource = currentDrawList
      }
      this.$forceUpdate()
    },
    getDevList(typeId) {
      resDevApi.getDevsByTypes({ typeId: typeId }).then((res) => {
        this.devMap = res.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
    },
    downloadFile(file) {
      window.location = sysCommonApi.downLoad + '?filePath=' + (file.url || file.fileUrl) + '&fileName=' + file.fileName + '&token=' + this.$store.getters.token
    }
  }
}
</script>

<style scoped>
.descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
  background-color: #fafafa;
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  overflow-wrap: break-word;
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}

.descriptions-view {
  border: 1px solid #f0f0f0;
}
</style>
