<template>
  <div>
    <template v-if="disabled">
      <template v-if="receiveInfo.receiveCustomer!=='' && !isNaN(Number(receiveInfo.receiveCustomer))">
        <span v-for="vo in this.customerList" v-if="vo.id == receiveInfo.receiveCustomer" :key="vo.id">【{{ vo.csrName }}】</span>
        <span v-for="vo in this.customerUserList" v-if="vo.id == receiveInfo.receiveUser" :key="vo.id">{{ vo.csrUserName }}</span>
      </template>
      <template v-else>
        <span v-if="receiveInfo.receiveCustomer">【{{ receiveInfo.receiveCustomer }}】</span>
        <span v-if="receiveInfo.receiveUser">{{ receiveInfo.receiveUser }}</span>
      </template>
    </template>
    <template v-else>
      <a-select
        v-model="receiveInfo.receiveCustomer"
        showSearch
        :placeholder="placeholder"
        style="width: 40%;float: left;"
        optionFilterProp="children"
        @change="(value, option)=>changeCustomer(value, option)"
        :filterOption="filterOption">
        <a-icon type="plus-square" @click="showAddCustomer" slot="suffixIcon" />
        <a-select-option v-for="vo in this.customerList" :key="vo.id">{{ vo.csrName }}</a-select-option>
      </a-select>

      <a-select
        v-model="receiveInfo.receiveUser"
        showSearch
        placeholder="收件人"
        style="width: 40%;margin-left: 10px;float: left;"
        optionFilterProp="children"
        @change="(value, option)=>changeCustomerUser(value, option)"
        :filterOption="filterOption">
        <a-icon type="plus-square" @click="showAddCustomerUser" slot="suffixIcon" />
        <a-select-option v-for="vo in this.customerUserList" :key="vo.id">{{ vo.csrUserName }}</a-select-option>
      </a-select>
    </template>

    <customer-form ref="customerModalForm" @ok="addCustomerRes"></customer-form>
    <customer-user-edit ref="customerUserModal" @ok="addCustomerUseRes" :customerList="selectCustomerList"></customer-user-edit>
  </div>
</template>

<script>
import { customerApi, customerUserApi, Get } from '@/api/quote'
import CustomerForm from '@/pages/cloud-quote/customer/CustomerForm'
import CustomerUserEdit from '@/pages/cloud-quote/customer-user/Form'

export default {
  name: 'SearchCustomEdit',
  components: { CustomerUserEdit, CustomerForm },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    clazz: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: '请先选择邮寄单位'
    },

    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    searchCustom: {
      type: String,
      required: false,
      default: ''
    },
    searchCustomUser: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      customerList: [],
      customerUserList: [],
      receiveInfo: {},
      selectCustomerList: []
    }
  },

  created () {
    this.listCustomer()
  },

  watch: {
    searchCustom: {
      immediate: true,
      handler (val) {
        this.receiveInfo.receiveCustomer = val
        this.receiveInfo.receiveUser = this.searchCustomUser
        if (this.disabled) {
          this.listCustomerUser()
        }
      }
    }
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    listCustomer () {
      const self = this
      customerApi.listCustomer({ page: false, authEnable: false }).then(result => {
        if (result.code === 0) {
          self.customerList = result.data
        }
      })
    },
    listCustomerUser () {
      const self = this
      const csrIds = []
      if (self.receiveInfo.receiveCustomer && !isNaN(Number(self.receiveInfo.receiveCustomer))) {
        csrIds.push(self.receiveInfo.receiveCustomer)
      }
      if (csrIds.length > 0) {
        const csrIdsStr = csrIds.toString()
        Get(`${customerUserApi.list}`, { csrIds: csrIdsStr, page: false, authEnable: false }).then(result => {
          if (result.code === 0) {
            self.customerUserList = result.data
            if (self.customerUserList.length > 0) {
              const user = self.customerUserList[0]
              self.updateCustomerUser(user)
            }
          }
        })
      } else {
        self.customerUserList = []

        // this.$set(this.receiveInfo, 'receiveUser', null)
        this.$set(this.receiveInfo, 'receiveTel', null)
        this.$set(this.receiveInfo, 'receiveAddress', null)
      }
    },
    showAddCustomer () {
      this.$refs.customerModalForm.add({ addType: 2 })
    },
    addCustomerRes (obj) {
      const record = obj.result
      this.customerList.push(record)
      this.$set(this.receiveInfo, 'receiveCustomer', record.id)
    },
    addCustomerUseRes (record) {
      this.customerUserList.push(record)
      this.updateCustomerUser(record)
    },
    changeCustomer (value) {
      const csr = this.customerList.filter(item => value === item.id)[0]
      if (csr) {
        this.selectCustomerList.splice(0, this.selectCustomerList.length, csr)
        // this.receiveInfo.receiveCustomer = csr.id
        // this.$set(this.receiveInfo, 'receiveCustomer', csr.id)
        this.listCustomerUser()
      }
    },
    showAddCustomerUser () {
      this.$refs.customerUserModal.addByCsrId(this.receiveInfo.receiveCustomer)
    },
    changeCustomerUser (value, op) {
      const self = this
      const csrUser = self.customerUserList.filter(item => value === item.id)[0]
      self.updateCustomerUser(csrUser)
    },
    updateCustomerUser (csrUser) {
      const self = this
      if (csrUser) {
        self.$set(self.receiveInfo, 'receiveUser', csrUser.id)
        self.$set(self.receiveInfo, 'receiveTel', csrUser.csrUserTelephone)
        self.$set(self.receiveInfo, 'receiveAddress', csrUser.csrUserAddr)
        self.$emit('changeInfo', self.receiveInfo)
      }
    }
  }
}
</script>

<style scoped>

</style>
