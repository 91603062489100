<template>
  <a-modal v-bind="contentModal" v-on="contentModal">
    <a-tabs v-bind="detailTabs">
      <a-tab-pane
        v-for="sampleId in Object.keys(checkTaskGroup)"
        :key="sampleId"
        :closable="false">
        <div slot="tab">
          {{ sampleId }}
        </div>

        <template v-for="(item,index) in checkTaskGroup[sampleId]">
          <a-card v-bind="detailCard" :key="`detail_${index}`">
            <template #title>
              {{ item.cateName }}/{{ item.itemName }}
            </template>
            <check-task-feedback-detail
              ref="checkTaskFeedBack"
              :checkTask="item"
              :checkUnitDict.sync="checkUnitDict"/>
          </a-card>
        </template>

      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { groupBy } from 'lodash'

import { dictApi } from '@/api/system'
import { checkTaskResultApi } from '@/api/sample'
import { customFunc } from '@/pages/cloud-quote/take-sample/modules/customForm/CustomFormFunc'

import checkTaskFeedbackDetail from '@/pages/cloud-quote/check-task/modules/batchFeedback/Detail'

export default {
  components: { checkTaskFeedbackDetail },

  data () {
    return {
      checkTasks: [],

      checkTaskGroup: {},

      checkUnitDict: [],

      detailCard: {
        style: { marginBottom: '18px' },
        headStyle: {},
        bodyStyle: {},
        bordered: true,
        type: 'inner'
      },

      detailTabs: {
        type: 'line',
        tabBarStyle: { background: '#fff', padding: '0 0', border: '1px solid #e8e8e8' },
        tabPosition: 'top',
        size: 'large',
        tabBarGutter: 10
      },

      contentModal: {
        width: '80%',
        visible: false,
        closable: false,
        destroyOnClose: true,
        centered: false,
        wrapClassName: 'check-task-feedback-modal',
        title: '批量录入',
        ok: this.handleSubmit,
        cancel: this.close
      }
    }
  },

  created () {
    this.checkCheckUnitDict()
  },

  methods: {

    init (data) {
      this.contentModal.visible = true
      this.checkTaskGroup = groupBy(data, 'sampleId')
      this.checkTasks.splice(0, this.checkTasks.length, ...data)
    },

    // 准备计量单位字典
    checkCheckUnitDict () {
      // 字典是否存在，不存在保存一个
      dictApi.checkCode({ code: 'checkCustomizeUnit' }).then(res => {
        if (!res) {
          this.addCheckUnitDict()
        }
      })

      customFunc._self = this
      customFunc.getDictCode((data) => {
        this.checkUnitDict = data && data.length && data.map(item => item.label) || []
      }, ['checkCustomizeUnit'])
    },

    // 添加计量单位字典
    addCheckUnitDict () {
      const dictData = {
        dictName: '检测计量单位',
        dictCode: 'checkCustomizeUnit',
        dictDesc: '检测自定义计量单位'
      }

      dictApi.add(dictData).then(res => {
        if (res.code !== 0) {
          this.$message.error(`保存检测计量单位字典发生错误：${res.msg}`)
        }
      })
    },

    // 添加计量单位字典详情
    addSampleStatusDictDetail () {
      const checkUnitArr = this.checkUnitDict.map((item, index) => {
        return {
          dictCode: 'checkCustomizeUnit',
          status: 1,
          itemText: item,
          itemValue: index + 1,
          sort: index + 1
        }
      })
      this.$store.commit('code/update', checkUnitArr)
      this.$store.commit('code/addAll', checkUnitArr)
    },

    handlePreview (file) {
      this.previewImage = file.thumbUrl
      this.previewVisible = true
    },

    handleSubmit () {
      const checkResult = this.$refs.checkTaskFeedBack.reduce((initArr, item) => {
        initArr.push.apply(initArr, item.getData())
        return initArr
      }, [])

      if (!checkResult.length) {
        this.$message.error('请先添加检测数据')
        return false
      }

      const noSaveResults = checkResult.filter(item => item.editable || item.isNew)
      if (noSaveResults && noSaveResults.length) {
        this.$message.error('您还有检测数据未保存，请先保存后再试')
        return false
      }

      checkTaskResultApi.batchSave(checkResult).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.addSampleStatusDictDetail()
          this.$emit('success')
          this.close()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    close () {
      this.contentModal.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
