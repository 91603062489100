import { debounce } from 'lodash'

export default {
  data () {
    return {
      scrollProp: { x: 1000, y: document.documentElement.clientHeight - 445 },
      moreParam: false,
      otherHeight: this.defaultOtherHeight || 360 // 除去 搜索框 高度为 348,如果不一致，请覆盖修改 留白12px
    }
  },

  watch: {

    toggleSearchStatus (newValue) {
      this.$nextTick(() => {
        this.updateTableHeight()
      })
    },

    moreParam (newValue) {
      this.$nextTick(() => {
        this.updateTableHeight()
      })
    }
  },

  mounted () {
    this.debounceUpdateTableHeight = debounce(this.updateTableHeight, 100, { leading: false, trailing: true })
    /** 控制table的高度 */
    window.addEventListener('resize', this.debounceUpdateTableHeight)
    this.updateTableHeight()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.debounceUpdateTableHeight)
  },

  methods: {
    /**
     *计算table 高度， 请将
     */
    updateTableHeight () {
      // 除去 搜索框 高度为 348
      const offsetHei = document.documentElement.clientHeight
      const searchCardElement = document.getElementById('searchCard')
      const searchCardHeight = searchCardElement && searchCardElement.clientHeight || 97
      const tableHeight = offsetHei - this.otherHeight - searchCardHeight
      // table 最小高度设置为80px
      this.scrollProp.y = tableHeight < 80 ? 80 : tableHeight
    }
  }
}
