<template>
  <a-modal v-bind="modalProps" v-on="modalProps">
    <div class="table-page-search-wrapper">
      <a-row justify="center">
        <a-col>
          <a-form :form="form">
            <a-form-item label="姓名" v-bind="formItemLayout">
              <a-input
                type="text"
                placeholder="姓名"
                v-decorator="['sctruName', {initialValue: sctr.sctruName, rules: [
                  {required: true, message: '姓名不能为空！'},
                  {max: 10, message: '姓名长度小于10！'}
                ]}]"></a-input>
            </a-form-item>
            <a-form-item label="所属单位" v-bind="formItemLayout">
              <a-select
                showSearch
                placeholder="所属单位"
                :filterOption="filterOption"
                :disabled="csctrDisabled"
                v-decorator="['sctrId', {initialValue: sctr.sctrId, rules: [
                  {required: true, message: '所属单位不能为空！'}
                ]}]">
                <a-select-option v-for="d in sctrList" :key="d.id">{{ d.sctrName }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="岗位" v-bind="formItemLayout">
              <a-input
                type="text"
                placeholder="岗位"
                v-decorator="['sctruPosition', {initialValue: sctr.sctruPosition,
                rules: [
                  {max: 20, message: '岗位长度过大！'}
                ]}]"
              ></a-input>
            </a-form-item>
            <a-form-item label="性别" v-bind="formItemLayout">
              <a-radio-group v-decorator="['sctruSex',{initialValue: sctr.sctruSex}]">
                <a-radio value="男">男</a-radio>
                <a-radio value="女">女</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="联系电话" v-bind="formItemLayout">
              <a-input
                type="text"
                placeholder="联系电话"
                v-decorator="['sctruPhone', {initialValue: sctr.sctruPhone,
                rules: [
                  {max: 20, message: '联系电话长度过大！'}
                ]}]"
              ></a-input>
            </a-form-item>
            <a-form-item label="邮箱" v-bind="formItemLayout">
              <a-input
                type="text"
                id="sctruMail"
                placeholder="邮箱"
                v-decorator="['sctruMail', {initialValue: sctr.sctruMail,
                rules: [
                  {max: 32, message: '邮箱长度过大！'}
                ]}]"
              ></a-input>
            </a-form-item>
            <a-form-item label="备注" v-bind="formItemLayout">
              <a-input
                type="text"
                id="sctruRemark"
                placeholder="备注"
                v-decorator="['sctruRemark', {initialValue: sctr.sctruRemark,
                rules: [
                  {max: 128, message: '备注长度不得超过128！'}
                ]}]"
              ></a-input>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>

  </a-modal>
</template>
<script>
import { subcontractorUserApi, subcontractorApi } from '@/api/project'
export default {
  name: 'SubUserForm',
  props: {
      sctrId:{
      type:Number,
      default:null
    }
  },
  data () {
    return {
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      modalProps: {
        width: 500,
        visible: false,
        closable: false,
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
        // wrapClassName: 'done-modal',
        title: '编辑人员信息',
        ok: this.handleOK,
        cancel: this.cancel
      },

      form: this.$form.createForm(this),
      sctrList: [],
      sctr: {},
      addAttr: {
        addType: 1 // 1、分包单位列表打开，2、其他页面打开，需要返后新增数据
      },
      csctrDisabled: true
    }
  },
  methods: {
    addOrEdit (record) {
      this.modalProps.visible = true
      // this.csctrDisabled = false
      if (record) {
        this.csctrDisabled = true
        this.sctr = record
      } else {
        // this.sctr = { sctruSex: '男'}
      }
    },
    // addBySctrId (sctrId, addAttr){
    //   this.modalProps.visible = true
    //   this.sctr = { sctruSex: '男', sctrId: sctrId }
    //   this.addAttr = addAttr
    // },
    cancel () {
      this.modalProps.visible = false
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      this.form.validateFields((error, values) => {
        const formData = Object.assign(values, {
          id: this.sctr.id
        })
        if (error == null) {
          subcontractorUserApi.addOrEdit(formData).then(result => {
            if (result.code === 0) {
              this.$message.success(`${formData.sctruName}成功`)
              if (this.addAttr && this.addAttr.addType === 1) {
                this.$emit('ok', true)
              } else {
                this.$emit('ok', result.data)
              }
              this.cancel()
            } else {
              this.$message.error(result.msg)
            }
          }).catch(error => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
        }
      })
    },
    listSubcontractor () {
      subcontractorApi.list({ page: false }).then(res => {
        if (res.code === 0) {
          this.sctrList = res.data
        }
      })
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  mounted () {
    this.listSubcontractor()
  }
}
</script>
