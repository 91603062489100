<script>
export default {
  name: 'SampleListProjectView',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>
<script setup>
import { computed, defineProps, reactive, getCurrentInstance, defineEmits } from 'vue'
import UlTable from '@/pages/common/ul-table/Table'
import SampleInfoRowView from '@/pages/cloud-quote/check-task/modules/schedule/moudles/sample-list/Row.vue'
import { uniqBy } from 'lodash'

const { proxy } = getCurrentInstance()

const props = defineProps({
  modelValue: { type: Array, required: true, default: () => [] },
  removeIds: { type: Array, required: false, default: () => [] },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue'])

const sampleInfoList = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})

const removeIds = computed({
  get() {
    return props.removeIds
  },
  set(value) {
    emit('update:removeIds', value)
  }
})

const projectSampleMap = computed(() => {
  return sampleInfoList.value.reduce((acc, item) => {
    ;(acc[item.projId] || (acc[item.projId] = { projId: item.projId, projName: item.projName, projNo: item.projNo, details: [] })).details.push(item)
    return acc
  }, {})
})

const projectShowMap = reactive({})
const showProject = (projId) => {
  proxy.$set(projectShowMap, projId, true)
}
const hideProject = (projId) => {
  proxy.$set(projectShowMap, projId, false)
}

const removeSample = (sampleInfo) => {
  let removeInfoList = sampleInfoList.value.splice(
    sampleInfoList.value.findIndex((item) => item.id === sampleInfo.id),
    1
  )
  let ids = removeInfoList.map((item) => item.id)
  removeIds.value.push(...ids)
}
const removeProj = (projInfo) => {
  let ids = uniqBy(projInfo.details.map((item) => item.id))
  let data = sampleInfoList.value.filter((item) => !ids.includes(item.id))
  sampleInfoList.value.splice(0, sampleInfoList.value.length, ...data)
  removeIds.value.push(...ids)
}
</script>

<template>
  <transition-group enter-active-class="animate__animated animate__bounceInUp" leave-active-class="animate__animated animate__bounceOutDown">
    <div v-for="(projInfo, projId) of projectSampleMap" :key="`sampleInfo_project_title_${projId}`" :style="{ padding: '5px 10px' }">
      <ul-table
        :data-source="projInfo.details"
        :hidden-row="!projectShowMap[projId]"
        :row-props="{ leaveActiveClass: 'animate__animated animate__bounceOutRight' }"
        row-component="transition"
      >
        <template #title>
          <span>项目编号：</span>
          <span class="title">{{ projInfo.projNo }}</span>
          <a class="fr" @click="removeProj(projInfo)" style="color: red" v-if="props.firstFlag !== 1">移除</a>

          <span class="mar-h12 fr">
            <template v-if="projectShowMap[projId]">
              <a-tooltip>
                <template #title>点我隐藏样品信息</template>
                <a @click="hideProject(projId)">
                  <a-icon type="shrink" />
                </a>
              </a-tooltip>
            </template>
            <template v-else>
              <a-tooltip>
                <template #title>点我显示样品信息</template>
                <a @click="showProject(projId)">
                  <a-icon type="arrows-alt" />
                </a>
              </a-tooltip>
            </template>
          </span>
          <span class="fr">
            <span class="title">合计：</span>
            <span class="content">{{ projInfo.details.length }}</span>
          </span>
        </template>

        <template #default="{ data }">
          <sample-info-row-view :data="data" @remove="removeSample" :firstFlag="props.firstFlag" />
        </template>
      </ul-table>
    </div>
  </transition-group>
</template>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.color();
.margin();
.float();
</style>
