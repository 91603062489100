<template>
  <div>
    <a-spin :spinning="confirmLoading">
      <a-form v-bind="dataForm">
        <div class="content-row focus">
          <a-radio-group v-model="dataForm.data.configMode" style="margin: 10px 20px">
            <a-radio v-for="item in checkStandardCurveModeOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </div>
        <a-card v-bind="baseFormCard" title="基本信息">
          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="方法名称">
                <a-input v-model="dataForm.data.methodName" :disabled="true"></a-input>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="方法依据">
                <a-input v-model="dataForm.data.standardCode" :disabled="true"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item>
                <span slot="label" class="ant-form-item-required">标准物质</span>
                <span style="margin-right: 10px" v-if="dataForm.data.solutionName"
                  ><a-tag color="#00bea7" closable @close="changeSolutionType">{{ dataForm.data.solutionName }}</a-tag></span
                >
                <a @click="solutionTypeSelectModal.show()">请选择标准物质</a>
                <!--                <a-select v-decorator="decorators.solutionName" placeholder="请选择标液类型" style="width: 100%" @select="onSelectSolution">-->
                <!--                  <a-select-option v-for="v in solutionList" :key="v.id.toString()" :value="v.id.toString()">{{ v.name }}-{{ v.id }}</a-select-option>-->
                <!--                </a-select>-->
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="浓度">
                <a-input-group compact style="width: 100%">
                  <!-- <a-input v-model="dataForm.data.solutionPurity"></a-input> -->
                  <a-input v-model="solutionPurityNumber" style="width: 60%" @change="changeSolutionPurityNumber"></a-input>
                  <a-select v-model="densityUnit" placeholder="请选择浓度" style="width: 40%">
                    <a-select-option value="">无</a-select-option>
                    <a-select-option v-for="v in densityUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                  </a-select>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="设备类型">
                <a-select v-decorator="decorators.devType" :filterOption="filterOption" mode="multiple" placeholder="请选择设备类型" style="width: 100%" @change="onSelectDev">
                  <a-select-option v-for="v in devList" :key="v.id.toString()" :value="v.id.toString()">{{ v.materialName }}-{{ v.speType }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="规格型号">
                <a-input v-model="dataForm.data.speType"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
            <a-col v-bind="colProps">
              <a-form-item label="参比溶液">
                <!--<a-input v-decorator="decorators.referenceSolution"></a-input>-->
                <span style="margin-right: 10px" v-if="dataForm.data.referenceSolutionName"
                  ><a-tag color="burlywood" closable @close="changeReagentCate">{{ dataForm.data.referenceSolutionName }}</a-tag></span
                >
                <a @click="reagentCateSelectModal.show()">请选择参比溶液</a>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="比色皿">
                <a-input v-decorator="decorators.thickness"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
            <a-col v-bind="colProps">
              <a-form-item label="波长">
                <a-input v-decorator="decorators.wavelength"></a-input>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="回归方程">
                <a-input v-decorator="decorators.regressionEquation"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="有效时长">
                <a-row :gutter="10">
                  <a-col :span="8">
                    <a-input-number v-decorator="decorators.validDate" :min="0" :step="1" :style="{ width: '100%' }"></a-input-number>
                  </a-col>

                  <a-col :span="16">
                    <a-radio-group v-model="dataForm.data.validDateUnit">
                      <a-radio v-for="(value, key) of reagentCateValidDateUnitMap" :key="key" :value="key">{{ value }}</a-radio>
                    </a-radio-group>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
            <a-col v-bind="colProps" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
              <a-form-item label="相关系数最小值">
                <a-row :gutter="10">
                  <a-col :span="8">
                    <a-input-number v-decorator="decorators.minCorrel" :min="0" :style="{ width: '100%' }"></a-input-number>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="定容体积">
                <a-input-group compact style="width: 100%">
                  <!-- <a-input v-model="dataForm.data.solutionPurity"></a-input> -->
                  <a-input-number v-decorator="decorators.constantVolume" :min="0" style="width: 60%" @change="changeConstantVolume"></a-input-number>
                  <a-select v-model="dataForm.data.constantVolumeUnit" placeholder="请选择体积单位" style="width: 40%">
                    <a-select-option value="">无</a-select-option>
                    <a-select-option v-for="v in capacityUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                  </a-select>
                  <!-- <a-input v-model="dataForm.data.constantVolumeUnit" disabled style="width: 40%"></a-input> -->
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>

        <a-card v-bind="baseFormCard" title="曲线点位信息" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
          <a slot="extra" href="#" @click="siteModal.show()">点位模板</a>
          <div class="content-row focus">
            <span class="content-row-title">单位配置：</span>
            <div v-if="siteModal.data.modelColumns.length > 0">
              <template v-for="item in siteModal.data.modelColumns">
                <a-form-item
                  :key="item.dataIndex"
                  :label="siteModal.data.selectSiteModelId == 9 && item.dataIndex === 'benArea' ? '峰面积' : item.title"
                  v-if="
                    (siteModal.data.selectSiteModelId != 9 && item.dataIndex !== 'drawIndex') ||
                    (siteModal.data.selectSiteModelId == 9 &&
                      (item.dataIndex === 'standardLiquid' || item.dataIndex === 'concentration' || item.dataIndex === 'purity' || item.dataIndex === 'benArea'))
                  "
                  style="display: inline-table; width: 33%; padding: 0 10px"
                >
                  <a-row :gutter="10">
                    <a-col :span="8">
                      <template v-if="item.dataIndex === 'standardLiquid'">
                        <a-select v-model="item.unit" placeholder="请选择" style="width: 100%">
                          <a-select-option value="">无</a-select-option>
                          <a-select-option v-for="v in capacityUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                        </a-select>
                      </template>
                      <template v-else-if="item.dataIndex === 'concentration'">
                        <a-select v-model="item.unit" placeholder="请选择" style="width: 100%">
                          <a-select-option value="">无</a-select-option>
                          <a-select-option v-for="v in massUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                        </a-select>
                      </template>
                      <template v-else-if="item.title === '测定浓度'">
                        <a-select v-model="item.unit" placeholder="请选择" style="width: 100%">
                          <a-select-option value="">无</a-select-option>
                          <a-select-option v-for="v in densityUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                        </a-select>
                      </template>
                      <template v-else-if="item.dataIndex === 'benArea'">
                        <a-select v-model="item.unit" placeholder="请选择" style="width: 100%">
                          <a-select-option value="">无</a-select-option>
                          <a-select-option v-for="v in peakAreaUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                        </a-select>
                      </template>
                      <template v-else>
                        <a-input v-model="item.unit"></a-input>
                      </template>
                    </a-col>
                  </a-row>
                </a-form-item>
              </template>
            </div>
            <template v-else>
              <span>未配置模板，点击添加<a href="#" @click="siteModal.show()">点位模板</a></span>
            </template>
          </div>
          <div
            v-if="
              siteModal &&
              siteModal.data &&
              siteModal.data.selectSiteModelId &&
              (siteModal.data.selectSiteModelId == 3 ||
                siteModal.data.selectSiteModelId == 4 ||
                siteModal.data.selectSiteModelId == 8 ||
                siteModal.data.selectSiteModelId == 9 ||
                siteModal.data.selectSiteModelId == 10 ||
                siteModal.data.selectSiteModelId == 11)
            "
          >
            <div class="explanatory-note">注：1、标准物质加入量 = 标准使用液加入量 x 标准物质浓度值，请确保单位统一</div>
            <div class="explanatory-note text-indent">2、测定浓度 = 标准物质加入量 / 定容体积，请确保单位统一</div>
          </div>
          <a-table v-bind="curveDrawTable">
            <template v-for="(col, i) in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text, itemRecord">
              <!-- <j-input-number :key="col.dataIndex + itemRecord.drawIndex" v-model="itemRecord[col.dataIndex]" v-if="col.dataIndex !== 'drawIndex'" /> -->
              <j-input-number
                :key="col.dataIndex + itemRecord.drawIndex"
                v-model="itemRecord[col.dataIndex]"
                :disabled="
                  siteModal &&
                  siteModal.data &&
                  siteModal.data.selectSiteModelId &&
                  ((siteModal.data.selectSiteModelId == 3 && (col.dataIndex === 'concentration' || col.dataIndex === 'result')) ||
                    (siteModal.data.selectSiteModelId == 4 && (col.dataIndex === 'concentration' || col.dataIndex === 'purity')) ||
                    (siteModal.data.selectSiteModelId == 8 && (col.dataIndex === 'concentration' || col.dataIndex === 'purity')) ||
                    (siteModal.data.selectSiteModelId == 9 && (col.dataIndex === 'concentration' || col.dataIndex === 'purity')) ||
                    (siteModal.data.selectSiteModelId == 10 && (col.dataIndex === 'concentration' || col.dataIndex === 'purity')) ||
                    (siteModal.data.selectSiteModelId == 11 && (col.dataIndex === 'concentration' || col.dataIndex === 'purity')))
                "
                v-if="col.dataIndex !== 'drawIndex'"
                @change="(value) => changeCurveDraw(itemRecord, col.dataIndex, value)"
              />

              <!--              itemRecord.editable && -->
              <!--:placeholder="col.title"-->
              <template v-else>{{ text }}</template>
            </template>
            <template #action="data, record, index">
              <!--              <a v-if="record.editable" @click="record.editable = false">确定</a>-->
              <!--              <a v-else @click="record.editable = true">编辑</a>-->
              <!--              <a-divider type="vertical"/>-->
              <a @click="deleteCurveSite(index)">作废</a>
            </template>
          </a-table>
          <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="addCurveSite">新增记录</a-button>
        </a-card>

        <a-card v-bind="baseFormCard" title="单点校准">
          <div class="content-row">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="允许范围" style="margin: 0">
                  <!--                  <a-input v-decorator="decorators.verifyAllowRange" style="width: 160px;" />-->
                  <a-input-number v-model="dataForm.data.verifyAllowMinRange" :formatter="(value) => `≥${value}%`" :parser="(value) => value.replace('%', '').replace('≥', '')" />
                  -
                  <a-input-number v-model="dataForm.data.verifyAllowMaxRange" :formatter="(value) => `≤${value}%`" :parser="(value) => value.replace('%', '').replace('≤', '')" />
                </a-form-item>
              </a-col>

              <a-col :span="16">
                <a-form-item label="有效时长" v-bind="formItemLayout1" style="margin: 0">
                  <a-input-number v-decorator="decorators.verifyValidDate" :min="0" :step="1" style="width: 80px; margin-right: 10px"></a-input-number>
                  <a-radio-group v-model="dataForm.data.verifyValidDateUnit">
                    <a-radio v-for="(value, key) of reagentCateValidDateUnitMap" :key="key" :value="key">{{ value }}</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-form>

      <!-- 标液类型选择 -->
      <a-modal v-bind="solutionTypeSelectModal">
        <solution-type-select-table v-bind="solutionTypeSelectModal.data" v-on="solutionTypeSelectModal.tableAction"></solution-type-select-table>
        <template #footer>
          <a-button @click="solutionTypeSelectModal.action.cancel">关闭</a-button>
          <a-button type="primary" @click="solutionTypeSelectModal.action.ok">确定</a-button>
        </template>
      </a-modal>

      <!-- 参比溶液选择 -->
      <a-modal v-bind="reagentCateSelectModal">
        <cate-select-and-query-table v-bind="reagentCateSelectModal.data" v-on="reagentCateSelectModal.tableAction" />
        <!--        <solution-type-select-table v-bind="reagentCateSelectModal.data" v-on="reagentCateSelectModal.tableAction"></solution-type-select-table>-->
        <template #footer>
          <a-button @click="reagentCateSelectModal.action.cancel">关闭</a-button>
          <a-button type="primary" @click="reagentCateSelectModal.action.ok">确定</a-button>
        </template>
      </a-modal>

      <!-- 曲线点位模板表单-->
      <a-modal v-bind="siteModal" v-if="siteModal.visible" v-on="siteModal">
        <site-model v-bind="siteModal.data" v-on="siteModal.data"></site-model>
        <template #footer>
          <a-button @click="siteModal.action.cancel()">关闭</a-button>
          <a-button @click="siteModal.action.ok" type="primary">保存</a-button>
        </template>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import miniForm from '@/pages/common/mixins/form'
import { checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import {
  baseCurveDrawColumns,
  curveDrawModels,
  defaultBaseCurveDrawColumns,
  defaultBaseCurveDrawColumns3,
  defaultBaseCurveDrawColumns4
} from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import CateSelectAndQueryTable from '@/pages/cloud-quote/check-reagent/cate/modules/table/CateSelectAndQueryTable'
import { checkStandardCurveModeEnum, checkStandardCurveModeOptions } from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { CheckReagentCateSelectColumn as checkReagentCateColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { reagentCateValidDateUnitMap } from '@/api/checkReagent/checkReagentConstant'
import SiteModel from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable/SiteModel'
import SolutionTypeSelectTable from '@/pages/cloud-quote/check-standard-solution-type/modules/table/SolutionTypeSelectTable'
import { Get, resMaterialApi } from '@/api/quote'
import fullModal from '@/pages/common/mixins/modal/full'
import JInputNumber from '@/components/j/JInputNumber'
import { dictItemApi } from '@/api/system'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'Index',
  mixins: [miniForm],
  components: {
    CateSelectAndQueryTable,
    SiteModel,
    SolutionTypeSelectTable,
    JInputNumber
  },
  props: {
    editType: {
      type: String,
      required: false,
      default: 'add'
    },

    id: {
      type: [String, Object],
      required: false,
      default: null
    },

    standardMethodId: {
      type: [String, Number],
      required: false
    },
    standardId: {
      type: [String, Number],
      required: false
    },
    methodId: {
      type: [String, Number],
      required: false
    },
    methodName: {
      type: String,
      required: false
    },
    standardCode: {
      type: String,
      required: false
    },
    columns: {
      type: Array,
      required: false,
      default: () => defaultBaseCurveDrawColumns
    }
  },
  data() {
    return {
      reagentCateValidDateUnitMap,
      baseCurveDrawColumns,
      curveDrawModels,
      checkStandardCurveModeEnum,
      checkStandardCurveModeOptions,
      defaultBaseCurveDrawColumns3,
      defaultBaseCurveDrawColumns4,
      formItemLayout1: {
        labelCol: {
          xxl: { span: 8 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        },
        wrapperCol: {
          xxl: { span: 16 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        }
      },
      colProps1: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      formItemLayoutUnit: {
        labelCol: {
          xxl: { span: 14 },
          xl: { span: 14 },
          lg: { span: 14 },
          md: { span: 14 },
          sm: { span: 14 },
          xs: { span: 14 }
        },
        wrapperCol: {
          xxl: { span: 10 },
          xl: { span: 10 },
          lg: { span: 10 },
          md: { span: 10 },
          sm: { span: 10 },
          xs: { span: 10 }
        }
      },
      siteModal: Object.assign({}, fullModalData, {
        title: '曲线点位模板配置',
        visible: false,
        closable: false,
        maskClosable: true,
        width: '960px',
        data: {
          ref: 'siteModal',
          selectSiteModelId: '',
          modelColumns: [],
          success: () => {
            this.siteModal.action.cancel()
            this.reload()
          }
        },

        show: () => {
          this.siteModal.visible = true
        },

        action: {
          cancel: () => {
            this.siteModal.visible = false
          },

          ok: () => {
            const selectSiteModelId = this.$refs[this.siteModal.data.ref].getSelectData()
            if (!selectSiteModelId) {
              this.$message.error('您还未选择曲线模板！')
              return false
            }
            this.siteModal.data.selectSiteModelId = selectSiteModelId
            this.siteModal.data.modelColumns = this.initSiteUnit(selectSiteModelId)
            this.curveDrawTable.columns =
              this.siteModal.data.selectSiteModelId == 3
                ? this.defaultBaseCurveDrawColumns3
                : this.siteModal.data.selectSiteModelId == 4 ||
                  this.siteModal.data.selectSiteModelId == 8 ||
                  this.siteModal.data.selectSiteModelId == 9 ||
                  this.siteModal.data.selectSiteModelId == 10 ||
                  this.siteModal.data.selectSiteModelId == 11
                ? this.defaultBaseCurveDrawColumns4
                : this.columns
            this.autoCalcSiteNumber()
            this.siteModal.action.cancel()
          }
        }
      }),
      dataForm: {
        data: {
          id: null,
          name: this.methodName,
          standardMethodId: this.standardMethodId,
          standardId: this.standardId,
          methodId: this.methodId,
          methodName: this.methodName,
          standardCode: this.standardCode,
          devTypeId: null,
          speType: '',
          solutionTypeId: null,
          solutionPurity: '',
          referenceSolution: '',
          validDateUnit: 'd',
          configMode: checkStandardCurveModeEnum.manMade,
          verifyAllowMinRange: -10,
          verifyAllowMaxRange: 10,
          verifyValidDateUnit: 'd',
          constantVolumeUnit: 'mL'
        }
      },
      decorators: {
        name: ['name', { rules: [{ required: true, message: '请输入曲线类型名称！' }] }],
        // solutionName: ['solutionName', { rules: [{ required: true, message: '请选择标液类型！' }] }],
        thickness: ['thickness', { rules: [{ required: false }] }],
        wavelength: ['wavelength', { rules: [{ required: false }] }],
        regressionEquation: ['regressionEquation', { initialValue: 'Y = bX + a', rules: [{ required: false }] }],
        devType: ['devType', { rules: [{ required: true, message: '请选择设备类型！' }] }],
        validDate: ['validDate', { rules: [{ required: true, message: '请输入有效期！' }] }],
        minCorrel: ['minCorrel', { initialValue: 0.9999, rules: [{ required: true, message: '请输入相关系数最小值！' }] }],
        verifyValidDate: ['verifyValidDate', { initialValue: 1, rules: [{ required: true, message: '请输入有效期！' }] }],
        constantVolume: ['constantVolume', { rules: [{ required: true, message: '请输入定容体积！' }] }]
      },
      devList: [],
      devMap: {},
      // solutionList: [],

      confirmLoading: false,

      curveDrawTable: {
        rowKey: (item) => `${item.drawIndex}`,
        bordered: true,
        size: 'small',
        dataSource: [],
        columns: this.columns,
        pagination: false
      },
      reagentCateSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择参比溶液',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' },

        show: () => {
          this.reagentCateSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.reagentCateSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.reagentCateSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const { id, name } = selectData[0]
            this.dataForm.data.referenceSolution = id
            this.dataForm.data.referenceSolutionName = name

            this.reagentCateSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'reagentCateSelectTable',
          columns: checkReagentCateColumn,
          rowSelect: { type: 'radio' }
        },

        tableAction: {}
      },
      solutionTypeSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择标液类型',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' },

        show: () => {
          this.solutionTypeSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.solutionTypeSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.solutionTypeSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const { id, name, purity } = selectData[0]
            // this.dataForm.data.referenceSolution = id
            // this.dataForm.data.referenceSolutionName = name
            this.dataForm.data.solutionTypeId = id
            this.dataForm.data.solutionName = name
            this.dataForm.data.solutionPurity = purity

            this.solutionPurityNumber = this.getNum(purity)
            this.densityUnit = this.getUnit(purity)
            this.solutionTypeSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'solutionTypeSelectTable',
          selectType: 'radio'
        },

        tableAction: {}
      },
      // 浓度数据拆分，保存时再合并
      solutionPurityNumber: '0',
      densityUnit: 'μg/mL',
      // 常用容量单位
      capacityUnitList: [],
      // 常用质量单位
      massUnitList: [],
      // 常用浓度单位
      densityUnitList: [],
      // 常用峰面积单位
      peakAreaUnitList: []
    }
  },
  created() {
    // this.getSolutionList()
    this.getDevList()
    this.capacityUnitList = []
    this.massUnitList = []
    this.densityUnitList = []
    this.peakAreaUnitList = []
    // 常用容量单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'capacity_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.capacityUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
          }
        }
      }
    })
    // 常用质量单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'mass_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.massUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
          }
        }
      }
    })
    // 常用浓度单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'density_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.densityUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
          }
        }
      }
    })
    // 常用峰面积单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'peak_area_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.peakAreaUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
          }
        }
      }
    })
    console.log(this.capacityUnitList, this.massUnitList, this.densityUnitList, this.peakAreaUnitList)
  },
  watch: {
    editType: {
      immediate: true,
      handler(nv, ov) {
        console.log(nv)
        if (nv === 'edit') {
          const param = { id: this.id }
          checkStandardCurveTypeApi.detailCurveType(param).then((res) => {
            if (res.code === 0 && res.data.length > 0) {
              const data = res.data[0]
              this.solutionPurityNumber = this.getNum(data.solutionPurity)
              this.densityUnit = this.getUnit(data.solutionPurity)
              this.$nextTick(() => {
                this.dataForm.data = {
                  id: data.id,
                  name: data.name,
                  standardMethodId: data.standardMethodId,
                  standardId: data.standardId,
                  methodId: data.methodId,
                  methodName: data.methodName,
                  standardCode: data.standardCode,
                  devTypeId: data.devTypeId,
                  solutionTypeId: data.solutionTypeId,
                  solutionPurity: data.solutionPurity,
                  referenceSolution: data.referenceSolution,
                  referenceSolutionName: data.referenceSolutionName,
                  validDateUnit: data.validDateUnit,
                  solutionName: data.solutionName,
                  configMode: data.configMode || checkStandardCurveModeEnum.manMade,
                  verifyAllowMinRange: data.verifyAllowMinRange || -10,
                  verifyAllowMaxRange: data.verifyAllowMaxRange || 10,
                  verifyValidDateUnit: data.verifyValidDateUnit || 'd',
                  constantVolumeUnit: data.constantVolumeUnit || 'mL'
                }
                this.dataForm.form.setFieldsValue({
                  name: data.name,
                  thickness: data.thickness,
                  wavelength: data.wavelength,
                  regressionEquation: data.regressionEquation,
                  // devType: data.devName,
                  devType: data.devTypeId.split(','),
                  validDate: data.validDate,
                  minCorrel: data.minCorrel,
                  verifyValidDate: data.verifyValidDate || 1,
                  constantVolume: data.constantVolume
                })

                if (data.mixSolutionTypeList && data.mixSolutionTypeList.length) {
                  this.dataForm.form.mixSolutionTypeList = []
                }

                if (data.siteModelId) {
                  const columns = JSON.parse(JSON.stringify(this.initSiteUnit(data.siteModelId)))
                  columns.forEach((item) => {
                    item.unit = data[item.dataIndex]
                  })
                  this.siteModal.data.selectSiteModelId = data.siteModelId
                  this.siteModal.data.modelColumns = columns
                } else {
                  this.siteModal.data.selectSiteModelId = 1
                  this.siteModal.data.modelColumns = this.initSiteUnit(1)
                }

                this.curveDrawTable.columns =
                  this.siteModal.data.selectSiteModelId == 3
                    ? this.defaultBaseCurveDrawColumns3
                    : this.siteModal.data.selectSiteModelId == 4 ||
                      this.siteModal.data.selectSiteModelId == 8 ||
                      this.siteModal.data.selectSiteModelId == 9 ||
                      this.siteModal.data.selectSiteModelId == 10 ||
                      this.siteModal.data.selectSiteModelId == 11
                    ? this.defaultBaseCurveDrawColumns4
                    : this.columns
                console.log(`|${this.siteModal.data.selectSiteModelId}|`, this.curveDrawTable.columns)

                // this.onSelectSolution(data.solutionTypeId)
                this.onSelectDev(data.devTypeId.split(','))
                data.drawList = data.drawList.filter((item) => !item.itemId)
                data.drawList.forEach((item) => {
                  item.editable = false
                  // 模板3时，给测定浓度赋值
                  if (data.siteModelId && data.siteModelId == 3) {
                    if (item.standardLiquid) {
                      if (!item.concentration && this.solutionPurityNumber) {
                        item.concentration = (item.standardLiquid * this.solutionPurityNumber).toFixed(3)
                      }
                      if (item.measureConcentration) {
                        item.result = Number(item.measureConcentration).toFixed(3)
                      } else if (data.constantVolume || data.constantVolume === 0) {
                        item.result = (item.concentration / data.constantVolume).toFixed(3)
                      }
                    } else {
                      item.concentration = null
                      item.result = null
                    }
                  }
                  // 模板4或8时，给测定浓度赋值
                  if (data.siteModelId && (data.siteModelId == 4 || data.siteModelId == 8 || data.siteModelId == 9 || data.siteModelId == 10 || data.siteModelId == 11)) {
                    if (item.standardLiquid) {
                      if (!item.concentration && this.solutionPurityNumber) {
                        item.concentration = (item.standardLiquid * this.solutionPurityNumber).toFixed(3)
                      }
                      if (item.measureConcentration) {
                        item.purity = Number(item.measureConcentration).toFixed(3)
                      } else if (data.constantVolume || data.constantVolume === 0) {
                        item.purity = (item.concentration / data.constantVolume).toFixed(3)
                      }
                    } else {
                      item.concentration = null
                      item.purity = null
                    }
                  }
                })
                this.curveDrawTable.dataSource = data.drawList
                console.log('111111111111111525555555', this.curveDrawTable.dataSource)
              })
            }
          })
        } else {
          this.siteModal.data.selectSiteModelId = 1
          this.siteModal.data.modelColumns = this.initSiteUnit(1)

          this.curveDrawTable.columns =
            this.siteModal.data.selectSiteModelId == 3
              ? this.defaultBaseCurveDrawColumns3
              : this.siteModal.data.selectSiteModelId == 4 ||
                this.siteModal.data.selectSiteModelId == 8 ||
                this.siteModal.data.selectSiteModelId == 9 ||
                this.siteModal.data.selectSiteModelId == 10 ||
                this.siteModal.data.selectSiteModelId == 11
              ? this.defaultBaseCurveDrawColumns4
              : this.columns
          console.log(`|${this.siteModal.data.selectSiteModelId}|`, this.curveDrawTable.columns)
        }
      }
    },
    devList: {
      immediate: true,
      handler(nv, ov) {
        if (this.dataForm.data.devTypeId !== null) {
          this.onSelectDev(this.dataForm.data.devTypeId.split(','))
        }
      }
    }
    // 'solutionList': {
    //   immediate: true,
    //   handler (nv, ov) {
    //     if (this.dataForm.data.solutionTypeId !== null) {
    //       this.onSelectSolution(this.dataForm.data.solutionTypeId)
    //     }
    //   }
    // }
  },
  methods: {
    getNum(str) {
      if (str) {
        let matchs = str.match(/\d+(.\d+)?/g)
        if (matchs && matchs.length) {
          return matchs[0]
        }
      }
      return '0'
    },
    getUnit(val) {
      // console.log(val,val.replace(/[0-9]*/g, '').replace(/\./, ''));
      if (val) {
        return val.replace(/[0-9]*/g, '').replace(/\./, '')
      }
      return 'μg/ml'
    },
    changeSolutionType() {
      this.dataForm.data.solutionTypeId = ''
      this.dataForm.data.solutionName = ''
      this.dataForm.data.solutionPurity = ''
    },

    changeReagentCate() {
      this.dataForm.data.referenceSolution = ''
      this.dataForm.data.referenceSolutionName = ''
    },
    initSiteUnit(siteModelId) {
      const columns = this.curveDrawModels.filter((item) => item.id === siteModelId)[0].columns
      return [...baseCurveDrawColumns, ...columns]
    },
    // getSolutionList () {
    //   checkStandardSolutionTypeApi.list({ page: false }).then(result => {
    //     this.solutionList = result.data == null ? [] : result.data
    //   })
    // },
    getDevList() {
      Get(resMaterialApi.listDetail, { page: false, cateType: 3 }).then((result) => {
        this.devList = result.data
        this.devMap = this.devList.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
    },
    addCurveSite() {
      const data = this.curveDrawTable.dataSource
      const length = data.length
      data.push({
        drawIndex: length === 0 ? '1' : (length + 1).toString(),
        standardLiquid: '',
        concentration: '',
        absorbanceValue: '',
        absorbanceValue2: '',
        result: '',
        editable: true
      })
      this.curveDrawTable.dataSource = data
    },
    deleteCurveSite(index) {
      this.curveDrawTable.dataSource.splice(index, 1)
      this.curveDrawTable.dataSource.forEach((item, i) => {
        item.drawIndex = i + 1
      })
    },
    // onSelectSolution (value) {
    //   const data = this.solutionList.filter(it => it.id === value)
    //   if (data !== null && data.length > 0) {
    //     this.dataForm.data.solutionTypeId = data[0].id
    //     this.dataForm.data.solutionPurity = data[0].purity
    //   }
    // },
    onSelectDev(value) {
      const data = value.map((it) => (this.devMap[it] ? this.devMap[it].speType : ''))
      if (data !== null && data.length > 0) {
        this.dataForm.data.devTypeId = value.join(',')
        this.dataForm.data.speType = data.join(',')
      }
      // const data = this.devList.filter(it => it.id.toString() === value)
      // if (data !== null && data.length > 0) {
      //   this.dataForm.data.devTypeId = data[0].id
      //   this.dataForm.data.speType = data[0].speType
      // }
    },
    save() {
      if (!this.confirmLoading) {
        this.dataForm.form.validateFields((errors, values) => {
          if (!errors) {
            if (this.dataForm.data.solutionTypeId === '' || this.dataForm.data.solutionTypeId === null) {
              this.$message.error(`请选择标准物质！`)
              return
            }
            // if (this.dataForm.data.configMode === checkStandardCurveModeEnum.manMade && this.dataForm.data.referenceSolution === '') {
            //   this.$message.error(`请选择参比溶液！`)
            //   return
            // }
            if (this.dataForm.data.configMode === checkStandardCurveModeEnum.manMade && this.curveDrawTable.dataSource.length === 0) {
              this.$message.error(`请填写曲线点位信息!`)
              return
            }

            this.confirmLoading = true
            const data = Object.assign(values, this.dataForm.data)
            data.solutionPurity = this.getNum(this.solutionPurityNumber) + this.densityUnit
            const columnsUint = {}
            if (this.dataForm.data.configMode === checkStandardCurveModeEnum.manMade) {
              if (this.siteModal.data.selectSiteModelId == 3) {
                this.curveDrawTable.dataSource.forEach((item) => {
                  item.measureConcentration = item.result
                })
              }
              if (
                this.siteModal.data.selectSiteModelId == 4 ||
                this.siteModal.data.selectSiteModelId == 8 ||
                this.siteModal.data.selectSiteModelId == 9 ||
                this.siteModal.data.selectSiteModelId == 10 ||
                this.siteModal.data.selectSiteModelId == 11
              ) {
                this.curveDrawTable.dataSource.forEach((item) => {
                  item.measureConcentration = item.purity
                })
              }

              data.drawList = this.curveDrawTable.dataSource

              data.siteModelId = this.siteModal.data.selectSiteModelId
              this.siteModal.data.modelColumns.forEach((item) => {
                columnsUint[item.dataIndex] = item.unit
              })
            }
            checkStandardCurveTypeApi
              .save(Object.assign(data, columnsUint))
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.$emit('success')
                } else {
                  this.$message.error(res.msg)
                  this.$emit('error')
                }
              })
              .finally(() => {
                this.confirmLoading = false
                // this.close()
              })
          }
        })
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeCurveDraw(record, dataIndex, value) {
      if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 3) {
        if (dataIndex === 'standardLiquid') {
          if (value || value === 0) {
            if (this.solutionPurityNumber) {
              let concentration = value * this.solutionPurityNumber
              this.$set(record, 'concentration', concentration.toFixed(3))
              let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
              console.log(constantVolume)
              if (constantVolume || constantVolume === 0) {
                let result = concentration / constantVolume
                this.$set(record, 'result', result.toFixed(3))
              } else {
                this.$set(record, 'result', null)
              }
            } else {
              this.$set(record, 'concentration', null)
              this.$set(record, 'result', null)
            }
          } else {
            this.$set(record, 'concentration', null)
            this.$set(record, 'result', null)
          }
        }
      }
      if (
        this.siteModal &&
        this.siteModal.data &&
        this.siteModal.data.selectSiteModelId &&
        (this.siteModal.data.selectSiteModelId == 4 ||
          this.siteModal.data.selectSiteModelId == 8 ||
          this.siteModal.data.selectSiteModelId == 9 ||
          this.siteModal.data.selectSiteModelId == 10 ||
          this.siteModal.data.selectSiteModelId == 11)
      ) {
        if (dataIndex === 'standardLiquid') {
          if (value || value === 0) {
            if (this.solutionPurityNumber) {
              let concentration = value * this.solutionPurityNumber
              this.$set(record, 'concentration', concentration.toFixed(3))
              let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
              console.log(constantVolume)
              if (constantVolume || constantVolume === 0) {
                let purity = concentration / constantVolume
                this.$set(record, 'purity', purity.toFixed(3))
              } else {
                this.$set(record, 'purity', null)
              }
            } else {
              this.$set(record, 'concentration', null)
              this.$set(record, 'purity', null)
            }
          } else {
            this.$set(record, 'concentration', null)
            this.$set(record, 'purity', null)
          }
        }
      }
    },
    changeConstantVolume(value) {
      this.$nextTick(() => {
        this.autoCalcSiteNumber()
      })
      // console.log(value)
      // if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 3) {
      //   for (let i = 0; i < this.curveDrawTable.dataSource.length; i++) {
      //     let item = this.curveDrawTable.dataSource[i]
      //     if ((value || value === 0) && (item.concentration || item.concentration === 0)) {
      //       let result = item.concentration / value
      //       this.$set(this.curveDrawTable.dataSource[i], 'result', result.toFixed(3))
      //     } else {
      //       this.$set(this.curveDrawTable.dataSource[i], 'result', null)
      //     }
      //   }
      // }
      // if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 4) {
      //   for (let i = 0; i < this.curveDrawTable.dataSource.length; i++) {
      //     let item = this.curveDrawTable.dataSource[i]
      //     if ((value || value === 0) && (item.concentration || item.concentration === 0)) {
      //       let purity = item.concentration / value
      //       this.$set(this.curveDrawTable.dataSource[i], 'purity', purity.toFixed(3))
      //     } else {
      //       this.$set(this.curveDrawTable.dataSource[i], 'purity', null)
      //     }
      //   }
      // }
    },
    changeSolutionPurityNumber(e) {
      this.$nextTick(() => {
        this.autoCalcSiteNumber()
      })
      // console.log(e.target.value)
      // if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 3) {
      //   let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
      //   console.log(constantVolume)
      //   for (let i = 0; i < this.curveDrawTable.dataSource.length; i++) {
      //     let item = this.curveDrawTable.dataSource[i]
      //     if (e.target.value) {
      //       if (item.standardLiquid || item.standardLiquid === 0) {
      //         let concentration = item.standardLiquid * e.target.value
      //         this.$set(this.curveDrawTable.dataSource[i], 'concentration', concentration.toFixed(3))
      //         if (constantVolume || constantVolume === 0) {
      //           let result = concentration / constantVolume
      //           this.$set(this.curveDrawTable.dataSource[i], 'result', result.toFixed(3))
      //         } else {
      //           this.$set(this.curveDrawTable.dataSource[i], 'result', null)
      //         }
      //       } else {
      //         this.$set(this.curveDrawTable.dataSource[i], 'concentration', null)
      //         this.$set(this.curveDrawTable.dataSource[i], 'result', null)
      //       }
      //     } else {
      //       this.$set(this.curveDrawTable.dataSource[i], 'concentration', null)
      //       this.$set(this.curveDrawTable.dataSource[i], 'result', null)
      //     }
      //   }
      // }
      // if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 4) {
      //   let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
      //   console.log(constantVolume)
      //   for (let i = 0; i < this.curveDrawTable.dataSource.length; i++) {
      //     let item = this.curveDrawTable.dataSource[i]
      //     if (e.target.value) {
      //       if (item.standardLiquid || item.standardLiquid === 0) {
      //         let concentration = item.standardLiquid * e.target.value
      //         this.$set(this.curveDrawTable.dataSource[i], 'concentration', concentration.toFixed(3))
      //         if (constantVolume || constantVolume === 0) {
      //           let purity = concentration / constantVolume
      //           this.$set(this.curveDrawTable.dataSource[i], 'purity', purity.toFixed(3))
      //         } else {
      //           this.$set(this.curveDrawTable.dataSource[i], 'purity', null)
      //         }
      //       } else {
      //         this.$set(this.curveDrawTable.dataSource[i], 'concentration', null)
      //         this.$set(this.curveDrawTable.dataSource[i], 'purity', null)
      //       }
      //     } else {
      //       this.$set(this.curveDrawTable.dataSource[i], 'concentration', null)
      //       this.$set(this.curveDrawTable.dataSource[i], 'purity', null)
      //     }
      //   }
      // }
    },
    autoCalcSiteNumber() {
      let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
      console.log('constantVolume', constantVolume)
      console.log('solutionPurityNumber', this.solutionPurityNumber)
      if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 3) {
        for (let i = 0; i < this.curveDrawTable.dataSource.length; i++) {
          let item = this.curveDrawTable.dataSource[i]
          if (this.solutionPurityNumber && (item.standardLiquid || item.standardLiquid === 0)) {
            let concentration = item.standardLiquid * this.solutionPurityNumber
            this.$set(this.curveDrawTable.dataSource[i], 'concentration', concentration.toFixed(3))
            if (constantVolume || constantVolume === 0) {
              let result = concentration / constantVolume
              this.$set(this.curveDrawTable.dataSource[i], 'result', result.toFixed(3))
            } else {
              this.$set(this.curveDrawTable.dataSource[i], 'result', null)
            }
          } else {
            this.$set(this.curveDrawTable.dataSource[i], 'concentration', null)
            this.$set(this.curveDrawTable.dataSource[i], 'result', null)
          }
        }
      }
      if (
        this.siteModal &&
        this.siteModal.data &&
        this.siteModal.data.selectSiteModelId &&
        (this.siteModal.data.selectSiteModelId == 4 ||
          this.siteModal.data.selectSiteModelId == 8 ||
          this.siteModal.data.selectSiteModelId == 9 ||
          this.siteModal.data.selectSiteModelId == 10 ||
          this.siteModal.data.selectSiteModelId == 11)
      ) {
        for (let i = 0; i < this.curveDrawTable.dataSource.length; i++) {
          let item = this.curveDrawTable.dataSource[i]
          if (this.solutionPurityNumber && (item.standardLiquid || item.standardLiquid === 0)) {
            let concentration = item.standardLiquid * this.solutionPurityNumber
            this.$set(this.curveDrawTable.dataSource[i], 'concentration', concentration.toFixed(3))
            if (constantVolume || constantVolume === 0) {
              let purity = concentration / constantVolume
              this.$set(this.curveDrawTable.dataSource[i], 'purity', purity.toFixed(3))
            } else {
              this.$set(this.curveDrawTable.dataSource[i], 'purity', null)
            }
          } else {
            this.$set(this.curveDrawTable.dataSource[i], 'concentration', null)
            this.$set(this.curveDrawTable.dataSource[i], 'purity', null)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.content-row {
  border: 1px dashed #e8e8e8;
  margin-bottom: 10px;
}

.content-row-title {
  padding: 10px;
  line-height: 32px;
  color: #4daaff;
}
.explanatory-note {
  font-size: 12px;
  color: red;
}
.text-indent {
  text-indent: 2em;
}
</style>