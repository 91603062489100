<template>
  <div :style="{width:this.width}" class="check-reagent-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-reagent-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table v-bind="contentTable" :scroll="scrollProp" :rowSelection="customRowSelection">
      <template #sumVolume="data,record">
        <span :style="{color: getVolumeColor((record.volume/data *100 ))}" class="focus-num">{{ record.volume }}</span> /
        <span class="focus-num">{{ data }}</span>
        <span class="mar-l5">{{ record.unit }}</span><br/>
        剩余：
        <span :style="{color: getVolumeColor((record.volume/data *100 ))}" class="focus-num">{{ (record.volume / data * 100).toFixed(2) }}% </span>
      </template>

      <template #configTime="data">{{ data | minuteFilter }}</template>

      <template #originalValidTime="data">{{ data | minuteFilter }}</template>

      <template #validTime="data">
        <a-tooltip>
          <template #title>{{ data | minuteFilter }}</template>
          <span :class="data | validTimeClassFilter">
            {{ data | validTimeFilter }}
          </span>
        </a-tooltip>
      </template>

      <template #status="text">
        <template v-if="reagentStatusDataMap[text]">
          <a-tag :color="reagentStatusDataMap[text].color">{{ reagentStatusDataMap[text].label }}</a-tag>
        </template>
      </template>

      <template #recheckTime="data">{{ data | minuteFilter }}</template>

      <template #recheckUser="data">{{ data || '-' }}</template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { checkReagentDefaultColumn as defaultColumn } from './columns'
import { checkReagentApi } from '@/api/checkReagent/checkReagentApi'
import { reagentStatusDataMap } from '@/api/checkReagent/checkReagentConstant'

import { CustomizeColumn } from '@/components/Columns'

import moment from 'moment'

export default {
  components: { sTable, CustomizeColumn },
  mixins: [componentTable],

  props: {
    width: { type: [String, Number], required: false, default: '100%' },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    pagination: { type: Boolean, required: false, default: true },
    columns: { type: Array, required: false, default: () => (defaultColumn) },
    selectable: { type: Boolean, default: false }
  },

  data () {
    return {
      scrollProp: { x: '100%', y: document.documentElement.clientHeight - 445 },
      contentTable: {
        columns: this.columns,
        bordered: true,
        style: { width: '100%' }
      },
      reagentStatusDataMap
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },
    customRowSelection () {
      return this.selectable ? this.rowSelection : null
    }
  },

  filters: {
    volumeColorFilter (data) {
      return data > 50 ? 'green' : (data > 30 ? 'orange' : 'red')
    },

    validTimeClassFilter (data) {
      return moment().isBefore(moment(data)) ? 'green' : 'red'
    },

    validTimeFilter (data) {
      return moment(data).fromNow()
    }
  },

  methods: {
    getVolumeColor (data) {
      return data > 50 ? 'green' : (data > 30 ? 'orange' : 'red')
    },

    getSelectData () {
      return this.rowSelection.selectedRows
    },

    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.pagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        return checkReagentApi.list(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    },
    handleSearch (queryParam) {
      Object.assign(this.queryParam, queryParam)
      this.search()
    },
    handleReset () {
      this.queryParam.compositionName = null
      this.queryParam.configBeginTime = null
      this.queryParam.configEndTime = null
      this.queryParam.recheckBeginTime = null
      this.queryParam.recheckEndTime = null
      this.queryParam.validCheckTime = null
      this.search()
    },
    handleReload () {
      this.reload()
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
