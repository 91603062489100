<template>
  <div>
    <ProjectBaseView :projId="localId"/>
    <ProjectFlowList :projId="localId"/>
  </div>
</template>

<script>
import ProjectBaseView from './ProjectBaseView'
import ProjectFlowList from './ProjectFlowList'
export default {
  name: 'ProjectFlowView',
  components: {
    ProjectBaseView,
    ProjectFlowList
  },
  props: {
    processId: {
      type: Number,
      default: null
    },
    projId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      localId: null,
      projectInfo: {},
      processList: []
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
    processId: {
      handler (nv, ov) {
        if(nv){
          this.localId = nv
        }
      },
      immediate: true
    },
    projId: {
      handler (nv, ov) {
        if(nv){
          this.localId = nv
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  created () {
  }
}
</script>
<style lang="less">
</style>
<style scoped>
</style>
