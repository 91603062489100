<template>
  <a-spin size="large" :spinning="commonLoading">
    <div class="fpd" ref="fpd" style="position: relative; width: 100%; margin-bottom: 40px">
      <template v-if="processList !== null && processList.data.length > 0">
        <a-button
          v-if="!(currentProcessIndex === 0 && processList.pageNo === 1)"
          title="上一条"
          class="per-button"
          shape="circle"
          icon="left"
          @click="turnTo(currentProcessIndex - 1)"
        />
        <a-button
          v-if="!(currentProcessIndex === processList.data.length - 1 && processList.pageNo === processList.totalPage)"
          title="下一条"
          class="next-button"
          shape="circle"
          icon="right"
          @click="turnTo(currentProcessIndex + 1)"
        />
        <a-popover title="" placement="leftTop">
          <template slot="content">
            <a-list bordered :data-source="processList.data" style="max-height: 300px; overflow-y: auto">
              <a-list-item
                slot="renderItem"
                slot-scope="item, index"
                :style="{ background: item.processInstanceId === processId ? '#eee' : '#fff' }"
              >
                <a @click="turnTo(index)">{{ item.processInstanceName }}</a>
              </a-list-item>
            </a-list>
          </template>
          <a-button
            icon="unordered-list"
            style="position: absolute; right: 10px; top: 58px; z-index: 9; height: 32px"
          />
        </a-popover>
      </template>

      <page-view v-if="!isAdd" :style="tabTitle">
        <div style="float: left; width: 70%; padding: 10px">
          <detail-list
            size="small"
            :col="1"
            class="detail-layout"
            style="padding: 15px 0 0 15px">
            <detail-list-item term>
              <!--              <img-->
              <!--                :style="{'background': process.iconRelColor,'width': '26px','height': '26px','borderRadius': '5px'}"-->
              <!--                :src="process.icon.indexOf('/') === 0 ? viewUrl + process.icon : process.icon"-->
              <!--                onerror="this.src='~@/assets/flow.png'"-->
              <!--              />-->
              <img src="~@/assets/flow.png" width="20" />&nbsp;&nbsp;
              <b style="font-size: 20px">{{ process.processInstanceName }}</b>
            </detail-list-item>
          </detail-list>
          <detail-list
            size="small"
            :col="4"
            class="detail-layout"
            style="padding: 0 0 0 15px"
          ><!-- slot="headerContent"-->
            <detail-list-item
              :item-col="2"
              term=""
              v-if="process.configDesc !== null && process.configDesc !== ''"
            ><a-icon type="info-circle" style="color: #1890ff; margin-right: 4px" />{{
              process.configDesc
            }}</detail-list-item
            >
            <detail-list-item term=""
            ><a-icon type="user" style="color: #1890ff; margin-right: 4px" />{{ process.startUserId }}
              <span v-if="process.params && process.params.hasOwnProperty('userName')"
              >({{ process.params.userName }})</span
              ></detail-list-item
            >
            <detail-list-item term=""
            ><a-icon type="clock-circle" style="color: #1890ff; margin-right: 4px" />{{
              process.startTime
            }}</detail-list-item
            >
          </detail-list>
        </div>
        <div style="position: absolute; right: 5px; bottom: 15px">
          <a-button-group>
            <a-button type="link" title="打印" @click="handlePrint()"><a-icon type="printer" /></a-button>
            <a-button type="link" title="下载" @click="handleCut()"><a-icon type="download" /></a-button>
            <a-button type="link" title="归档"><a-icon type="barcode" /></a-button>
            <a-button type="link" title="添加抄送" @click="handleSure(1)"><a-icon type="share-alt" /></a-button>
            <a-button type="link" title="添加流转" @click="handleSure(2)"><a-icon type="retweet" /></a-button>
            <!--          <a-button type="link" title="添加评论">-->
            <!--            <a-popover title="添加评论" trigger="click" :visible="popover.commentPopoverVisible">-->
            <!--              <template slot="content">-->
            <!--                <a-textarea placeholder="评论内容" v-model="checkReply" :autosize="{ minRows: 4, maxRows: 8 }"/>-->
            <!--                <p></p>-->
            <!--                <a-button size="small" type="primary" @click="addComment()">确定</a-button>-->
            <!--                <a-button size="small" type="default" @click="closeComment()" style="margin-left: 10px;">取消</a-button>-->
            <!--              </template>-->
            <!--              <a-icon @click="popover.commentPopoverVisible = true" type="message" title="添加评论"/>-->
            <!--            </a-popover>-->
            <!--          </a-button>-->
          </a-button-group>
        </div>
      </page-view>

      <a-tabs defaultActiveKey="1" :style="tabFlowForm">
        <a-tab-pane key="1">
          <span slot="tab"> <a-icon type="profile" />表单内容 </span>
          <div ref="print" id="print" style="page-break-after: always; width: 1140px; margin: 0 auto">
            <!--            <a-card>&lt;!&ndash; style="border: 1px solid #D9D9D9;overflow-y: auto;overflow-x: hidden;position: absolute;left: 0;right: 0;bottom: 0;top: 43px;"&ndash;&gt;-->
            <div class="need-print">
              <img
                v-if="!process.endTime"
                src="/flow/processing.png"
                style="position: absolute; top: 95px; right: calc(50% - 500px); width: 160px; z-index: 9"
              />
              <img
                v-else
                src="/flow/done.png"
                style="position: absolute; top: 95px; right: calc(50% - 500px); width: 160px; z-index: 9"
              />
            </div>
            <template v-if="!reloading">
              <component
                :editCustomForm="cmtInfo.showEdit || isAdd"
                ref="flowComponent"
                :is="currentComponent"
                :quoteId="cmtInfo.id"
                :formType="cmtInfo.formType"
                :recordId="cmtInfo.id"
                :documentId="cmtInfo.id"
                :projNo="projNo"
                :processKey="cmtInfo.key"
                :processName="cmtInfo.name"
                :processId="cmtInfo.id"
                :processInstanceId="processId"
                :info.sync="process.params"
                :locationList="locationList"
                :userName="username"
                :activeTask="activityTask"
                :initFormModel="initFormModel"
                :suspend="process.suspend"
                :processData="processData"
                :userTasks="process.userTasks"
                :endTime="process.endTime||''"
                :showOperation="showOperationShouKuanQR"
                :isQuoteBack="isQuoteBack"
                @reload="reloadData(processId)"
                @toggleCommonLoading="toggleCommonLoading"
                v-if="!reloading"
              />
              <div class="need-print" style="padding: 0 24px; width: 1090px; margin: 0 auto">
                <a-table
                  v-bind="projectTable"
                  v-on="projectTable"
                  style="border: 2px solid #1890ff; border-top: 4px solid #1890ff"
                  :pagination="processIpagination"
                  :showHeader="processIpagination"
                  :dataSource="processDataTable"
                >
                  <template slot="processTableContent" slot-scope="text, record">
                    {{ record.assignee }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="record.signature_url"
                    >签名：<img
                      alt="签名"
                      style="width: 75px"
                      :src="viewUrl + '/' + record.signature_url"
                    /></span>
                    <span v-if="record.comment_message">{{ record.comment_message }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="record.name === '抄送'">{{ record.comment_type }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="record.endTime" style="float: right">{{ record.endTime }}</span>
                  </template>
                </a-table>
                <div style="width: 1090px; margin: 16px auto">
                  <span style="margin-right: 60px">打印时间：{{ currentTime }}</span
                  ><span>打印人员：{{ username }}</span>
                </div>
              </div>
            </template>
            <a-spin
              class="process-load"
              size="large"
              tip="数据加载中..."
              v-else></a-spin>
            <!--            </a-card>-->
          </div>
        </a-tab-pane>
        <a-tab-pane key="3"
        ><!--v-if="!isAdd"-->
          <span slot="tab"> <a-icon type="branches" />流程图 </span>
          <div style="min-height: 636px; overflow: auto"><img :src="previewImage" /></div>
        </a-tab-pane>
      </a-tabs>
      <a-tabs defaultActiveKey="1" :style="tabFlowHistory" v-if="!isAdd">
        <a-tab-pane key="2">
          <span slot="tab"> <a-icon type="schedule" />流程历史 </span>
          <a-row
            :gutter="12"
            style="overflow-y: auto; overflow-x: hidden; position: absolute; left: 0; right: 0; bottom: 0; top: 70px"
          >
            <a-col :span="2"></a-col>
            <a-col :span="20">
              <a-steps direction="vertical">
                <a-step
                  v-for="(v, index) in processData"
                  :key="v.endTime + index"
                  :title="v.name"
                  :status="v.endTime ? 'finish' : 'process'"
                  v-if="v.name !== '抄送' || v.comment_type"
                >
                  <template slot="icon">
                    <a-avatar v-if="v.header_url !== null && v.endTime" :src="viewUrl + v.header_url" />
                    <!--                    <a-avatar v-else-if="v.assignee !== 'system' && v.endTime" style="color: #f56a00; backgroundColor: #fde3cf">-->
                    <!--                      {{ v.assignee.substring(0, 1) }}-->
                    <!--                    </a-avatar>-->
                    <a-icon v-else-if="v.name === '抄送'" type="share-alt" style="width: 32px; height: 32px" />
                    <a-icon
                      v-else-if="v.name === '抄送回复' || v.name === '流转回复'"
                      type="solution"
                      style="width: 32px; height: 32px"
                    />
                    <a-icon v-else-if="v.name === '流转'" type="retweet" style="width: 32px; height: 32px" />
                  </template>
                  <div slot="description">
                    操作人：<template v-if="v.assignee === 'system'">系统</template
                    ><template v-else>{{ v.assignee }}</template>
                    <!--                    <span style="margin-left: 5px;color: #0DA2EC;"><a-icon type="interaction" style="font-size: 20px;" title="任务重新分配"/>重新分配</span><br/>-->
                    <a-button
                      v-if="v.endTime == null && (showOperation||(process.key==='jx-shoukuanqr'&&showOperationShouKuanQR)) && !process.suspend"
                      type="primary"
                      ghost
                      icon="redo"
                      style="margin-left: 10px"
                      @click="handleSure(3)"
                    >转办</a-button
                    ><!--v-if="v.endTime == null && currentUserName === 'admin'"-->
                    <span v-if="v.signature_url"
                    >【签名：<img alt="签名" style="width: 75px" :src="viewUrl + '/' + v.signature_url" />】</span
                    ><br />
                    <span v-if="v.endTime">处理时间：{{ v.endTime }}</span
                    ><br />
                    <span v-if="v.name === '抄送'"
                    >{{ v.comment_type }}
                      <!--                    <a-icon v-if="showRepeat(v.comment_userId)" type="edit" style="margin-left: 5px;color: #0DA2EC" @click="repeatCopy(v.comment_userId)" title="添加回复"/>-->

                      <a-popover title="抄送回复" trigger="click" :visible="popover.popoverVisible">
                        <template slot="content">
                          <a-textarea
                            placeholder="回复内容"
                            v-model="checkReply"
                            :autosize="{ minRows: 4, maxRows: 8 }"
                          />
                          <p></p>
                          <a-button size="small" type="primary" @click="replyCopy(v)">确定</a-button>
                          <a-button
                            style="margin-left: 10px"
                            size="small"
                            @click="popover.popoverVisible = false"
                          >关闭</a-button
                          >
                        </template>
                        <!--                      <a-tag color="cyan">回复</a-tag>-->
                        <a-icon
                          v-if="showReply(v.comment_userId)"
                          @click="popover.popoverVisible = true"
                          type="edit"
                          style="margin-left: 5px; color: #0da2ec; width: 21px; height: 21px"
                          title="添加回复"
                        />
                      </a-popover> </span
                    ><br v-if="v.name === '抄送'" />
                    <span v-if="v.common_type"
                    >操作结果：<a-tag :color="v.common_type_color">{{ v.common_type }}</a-tag></span
                    ><br v-if="v.common_type" />
                    <!--                  <span v-if="v.name === '抄送' && v.comment_message">{{ v.comment_message }}</span>-->
                    <span v-if="v.comment_message">意见说明：{{ v.comment_message.replace('>>', '➜') }}</span>
                    <br v-if="v.accessory" />
                    <span v-if="v.accessory"
                    >附件：
                      <a @click="downloadFile(v.accessory)" style="padding: 1px">{{ formatAccessory(v.accessory) }}</a>
                    </span>
                  </div>
                </a-step>
              </a-steps>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-icon
          type="double-right"
          slot="tabBarExtraContent"
          @click="changeStyle(1)"
          style="color: #1890ff; margin-right: 4px"
        />
      </a-tabs>
      <a-icon type="double-left" :style="historyIcon" @click="changeStyle(0)" />

      <!--    <div class="ant-pro-footer-toolbar" :style="tabOperation" v-if="showAction(activityTask)">-->
      <div class="ant-pro-footer-toolbar" :style="tabOperation" v-if="showOperation">
        <a-row>
          <a-col>
            <template v-if="process.suspend">
              <a-badge status="error" text="流程已挂起" />
            </template>
            <template v-else>
              <a-row style="border-bottom: 1px solid #e8e8e8; height: 48px; line-height: 48px; padding-left: 10px">
                <a-col>
                  当前节点：
                  <b style="color: #1890ff">{{ activityTask.name }}</b>
                  【<img
                    alt="签名"
                    style="width: 75px"
                    v-if="signatureUrl"
                    :src="viewUrl + '/' + signatureUrl" />
                  <a-popover
                    v-else
                    title="验证登陆密码"
                    trigger="click"
                    :visible="popover.signPopoverVisible">
                    <template slot="content">
                      <a-input
                        type="password"
                        autocomplete="false"
                        placeholder="请输入密码"
                        v-model="password">
                        <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
                      </a-input>
                      <p></p>
                      <a-button size="small" type="primary" @click="addSignature">确定</a-button>
                      <a-button
                        style="margin-left: 10px"
                        size="small"
                        @click="popover.signPopoverVisible = false"
                      >关闭</a-button
                      >
                    </template>
                    <a-button
                      type="link"
                      style="margin: 0; padding: 0"
                      @click="popover.signPopoverVisible = true"
                    >添加签名</a-button
                    > </a-popover
                  >】
                  <span
                    v-if="defaultSignature === '1' && signatureUrl === ''"
                    style="color: red"
                  >* 请先确认已激活电子签名，否则无法审批</span
                  >
                  <a-dropdown style="float: right; text-align: center; margin: 8px 8px 0 0">
                    <a-menu slot="overlay" @click="(e) => (agreeReason = e.key)">
                      <a-menu-item :key="vo.itemText" v-for="(vo, i) in processCommonWords">
                        {{ i + 1 }}. {{ vo.itemText }}
                      </a-menu-item>
                    </a-menu>
                    <a-button style="margin-left: 8px"> 审批常用语 </a-button>
                  </a-dropdown>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <a-textarea
                    placeholder="请输入意见"
                    rows="2"
                    style="margin: 5px 40px 10px; width: -webkit-fill-available; height: 80px"
                    v-model="agreeReason"
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col style="margin-left: 40px; line-height: 26px">
                  附件上传：
                  <a-upload v-bind="accessoryUpload" v-on="accessoryUpload" :fileList="fileList">
                    <a-button v-if="fileList.length < 1" type="link" style="margin: unset; padding: unset">
                      点击上传附件
                    </a-button>
                  </a-upload>
                </a-col>
              </a-row>
              <a-row
                style="height: 48px; line-height: 48px; padding-left: 40px"
                v-if="activityTask.comment_userId !== null && activityTask.comment_userId !== activityTask.assignee"
              >
                <a-col>
                  <template>
                    <a-button type="primary" @click="resolveTask()" style="margin-right: 10px">确认回复</a-button>
                  </template>
                </a-col>
              </a-row>
              <a-row style="height: 48px; line-height: 48px; padding-left: 40px" v-else>
                <a-col>
                  <template v-for="v in activityTaskOperation">
                    <template v-if="v.id === 'REJECT_AUDIT'">
                      <!--退回-->
                      <a-dropdown placement="topCenter" :key="v.id">
                        <a-menu slot="overlay" @click="(e) => backTopStep(activityTask.id, e.key)">
                          <a-menu-item :disabled="vo.disable" :key="vo.id" v-for="(vo, i) in process.userTasks">
                            <a-icon type="branches" />
                            {{ i + 1 }}. {{ vo.name }}
                          </a-menu-item>
                        </a-menu>
                        <a-button :type="getColor(v.id)" style="margin-left: 8px">
                          {{ v.name }}
                          <a-icon type="down" />
                        </a-button>
                      </a-dropdown>
                    </template>
                    <template v-else>
                      <!--正常操作-->
                      <a-button
                        :key="v.id"
                        :type="getColor(v.id)"
                        @click="changeStatus(activityTask.id, v.id, v.name)"
                        style="margin-right: 10px"
                      >{{ v.name }}</a-button
                      >
                    </template>
                  </template>
                </a-col>
              </a-row>
            </template>
          </a-col>
        </a-row>
      </div>
      <carbon-copy ref="carboncopy" @ok="refreshProcess"></carbon-copy
      ><!-- @ok="$refs.table.refresh(true)"--><!-- v-if="showCarboncopy"-->
    </div>
  </a-spin>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
import { MessageBox, Message } from 'element-ui'
import { omit, cloneDeep, forEach, groupBy, maxBy, sumBy } from 'lodash'
import print from '@/plugs/print'
import htmlToPdf from '@/plugs/htmlToPdf'
import QuoteCheckMgr from '@/pages/cloud-quote/bj-plan-quote/QuoteCheckMgr'
import SampleWeight from '@/pages/cloud-quote/bj-plan-quote/SampleWeight'
import SubtaskCheckMgr from '@/pages/cloud-quote/subcontract/subcontract-task/SubtaskCheckMgr'
import ProjectFlowView from '@/pages/cloud-quote/project/modules/ProjectFlowView'
import ProjectAuditMgr from '@/pages/cloud-quote/project/ProjectAuditMgr'
import ContractCheckMgr from '@/pages/cloud-quote/proj-contract/ContractCheckMgr'
import SctrCheckViewMgr from '@/pages/cloud-quote/subcontract/subcontractor/SctrCheckViewMgr'
import purPlanIndex from '@/pages/cloud-quote/res/res-purplan/modules/purPlanIndexForFlow'
import DocumentCheckForm from '@/pages/cloud-quote/file/modules/DocumentCheckForm'
import YiQiShouQuanForm from './module/YiQiShouQuanForm'
import PageView from '@/layouts/PageView'
import { flowApi } from '@/api/flow'
import { sysCommonApi, getAction, postDataAction, commDictApi } from '@/api/common'
import { userSignatureApi } from '@/api/system'
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import DetailList from '@/components/tools/DetailList'
import CustomPreviewForm from '@/pages/flow/module/CustomPreviewForm'
import purUseIndex from '@/pages/cloud-quote/res/res-puruse/modules/purUseIndexForFlow'
import purIndex from '@/pages/cloud-quote/res/res-pur/modules/purIndexForFlow'
import FlowFormModal from './module/FlowFormModal'
import CarbonCopy from './module/CarbonCopy'
import JxReport from '@/pages/flow/form/JxReport'
import ReportSendMgr from '@/pages/cloud-quote/report/send/modules/ReportSendMgr'
import ReportRecoveryMgr from '@/pages/cloud-quote/report/send/modules/ReportRecoveryMgr'
import { reportSendOrderApi, scheduleResourceApi, scheduleResourceTypeApi, tsTaskApi } from '@/api/sample'
import MaterialTransferModal from '@/pages/cloud-quote/res/res-puruse/modules/materialReturn/MaterialTransferModal.vue'
import ResMaterialApplyModal from '@/pages/cloud-quote/res/res-puruse/modules/ResLabMaterialApplyModal'
import ReagentRecheckModal from '@/pages/cloud-quote/check-reagent/modules/ReagentRecheckModal'
import EvalConfirmModal from '@/pages/cloud-quote/res/res-cbt/modules/EvalConfirmModal'
import CbtConfirmModal from '@/pages/cloud-quote/res/res-cbt/modules/CbtConfirmModal'
import { resCbtApi, resStaffApi, resStaffTrainingApi, resMaterialApi, resPurUseApi, resInterCheckApi } from '@/api/quote'
import StaffPostAbilityForm from '@/pages/cloud-quote/res/res-staff/modules/StaffPostAbilityForm'
import StaffAuthorizationForm from '@/pages/cloud-quote/res/res-staff/modules/StaffAuthorizationForm'
import CoordinationMgr from '../cloud-quote/report/task/moudles/coordination/CoordinationMgr'
import FileSignatureMgr from '../cloud-quote/signature/modules/FileSignatureMgr'
import StaffTrainingApplyModal from '@/pages/cloud-quote/res/res-staff-training/modules/StaffTrainingApplyModal'
import StaffTrainingEffectivenessModal from '@/pages/cloud-quote/res/res-staff-training/modules/StaffTrainingEffectivenessModal'
import { projProgressUserApi, projDelayApi, projectApi } from '@/api/project'
import { autoConfigureApi } from '@/api/autoConfigure/autoConfigureApi'
import { qualityControlApi } from '@/api/quality/qualityControlApi'
import { qualityControlSampleApi } from '@/api/quality/qualityControlLinkApi'
import { makeSampleApplySourceEnum } from '@/api/makeSample/makeSampleApplyConstant'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { makeSampleApplyApi } from '@/api/makeSample/makeSamplelApi'
import { resPurUseApplyStatusEnum } from '@/api/resMaterial/ResPurUseConstant'
// import ItemForm from '@/pages/cloud-quote/res/res-pur/modules/ItemFormForFlow'

const DetailListItem = DetailList.Item

Vue.use(print) // 注册
Vue.use(htmlToPdf)

export default {
  name: 'FlowProcessDetail',
  components: {
    FileSignatureMgr,
    CoordinationMgr,
    PageView,
    DetailList,
    DetailListItem,
    SelectUserByDept,
    // QuoteEdit,
    QuoteCheckMgr, // 报价审核
    SampleWeight,  //采样介质称量
    YiQiShouQuanForm,   //仪器称量流程表单
    SubtaskCheckMgr, // 分包审核
    SctrCheckViewMgr, // 分包商评审
    ContractCheckMgr, // 合同审批
    ProjectAuditMgr, // 合同审批
    purPlanIndex, // 采购计划预览表单
    DocumentCheckForm,
    CustomPreviewForm, // 自定义表单
    purUseIndex,
    purIndex, // 采购预览表单
    FlowFormModal, // 自定义流程表单
    CarbonCopy, // 抄送表单
    // ItemForm, // 采购申请表单
    JxReport,
    ReportSendMgr,
    ReportRecoveryMgr,
    print,
    htmlToPdf,
    ProjectFlowView, // 项目信息预览
    MaterialTransferModal, // 物资移交
    ResMaterialApplyModal, // 实验室物品申领
    ReagentRecheckModal, // 试剂配置复核
    EvalConfirmModal, // 仪器设备溯源结果评价及确认
    CbtConfirmModal, // 仪器设备校准/检定确认
    StaffPostAbilityForm, // 人员岗位能力确认
    StaffAuthorizationForm, // 人员授权
    StaffTrainingApplyModal, // 培训申请
    StaffTrainingEffectivenessModal // 培训有效性评价
  },
  inject: ['reload'],
  props: {
    processIdProp: {
      type: String,
      required: false,
      default: ''
    },

    showOperator: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currentComponent: Object,
      process: {},
      processData: [],
      processId: this.processIdProp,
      previewImage: '',
      activityTask: {},
      activityTaskOperation: [],
      agreeReason: '',
      cmtInfo: { quoteInfo: {} },
      showTask: true,
      reloading: true,
      // showCarboncopy: false,
      isAdd: true,
      model: null,
      initFormModel: {}, // 传入表单初始化数据
      processQueryParam: null,
      processList: null,
      currentProcessIndex: null,
      customIds: this.$store.getters.flow_custom,
      auditStatusMap: { AGREE_AUDIT: { name: '同意', color: 'blue' }, REJECT_AUDIT: { name: '退回', color: 'orange' } },
      tabFlowHistory: {
        backgroundColor: '#FFFFFF',
        width: '25%',
        position: 'absolute',
        bottom: '0',
        top: '0',
        right: '0'
      },
      tabFlowForm: {
        backgroundColor: '#FFFFFF',
        width: '73%',
        right: '0',
        position: 'relative',
        bottom: '0',
        top: '0',
        left: '0',
        overflow: 'auto'
      },
      tabOperation: {
        borderTop: 'none',
        position: 'relative',
        width: '73%',
        left: '0',
        padding: '0',
        height: '255px',
        bottom: '0',
        marginTop: '20px',
        zIndex: '0'
      },
      tabTitle: {
        position: 'relative',
        left: '0',
        width: '73%',
        margin: '0',
        top: '0',
        height: '110px',
        background: 'white',
        marginBottom: '15px'
      },
      historyIcon: {
        position: 'absolute',
        right: '0',
        border: '1px solid #1890ff',
        padding: '8px',
        borderRadius: '5px',
        background: '#1890ff',
        color: 'white',
        top: '0',
        display: 'none'
      },
      locationList: [], // 采购所需
      username: this.$store.getters.userInfo.username,
      checkReply: '',
      popover: {
        signPopoverVisible: false,
        popoverVisible: false,
        commentPopoverVisible: false
      },
      showOperation: false,
      showOperationShouKuanQR: false,
      projectTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        rowKey: (recode, index) => {
          return index
        },
        locale: {
          emptyText: '流程审批未开始'
        },
        columns: [
          {
            title: '历史',
            dataIndex: 'id',
            align: 'center',
            width: '110px',
            customRender: (text, row, index) => {
              const obj = {
                children: '流程审批历史',
                attrs: {}
              }
              if (index === 0) {
                obj.attrs.rowSpan = this.processDataTable.length
              } else {
                obj.attrs.rowSpan = 0
              }
              return obj
            }
          },
          { title: '流程节点', align: 'left', width: '190px', dataIndex: 'name' },
          { title: '内容', align: 'left', dataIndex: 'content', scopedSlots: { customRender: 'processTableContent' } }
        ]
      },
      processIpagination: false,
      processDataTable: [],
      password: '',
      signatureUrl: '',
      viewUrl: sysCommonApi.view,
      currentTime: moment().format('YYYY-MM-DD HH:mm'),
      commonLoading: false,
      defaultSignature: '0',
      currentUserName: this.$store.getters.nickname,
      processCommonWords: [],
      accessoryUpload: {
        accept:
          '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,' +
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        action: sysCommonApi.uploadFile,
        multiple: false,
        showUploadList: true,
        listType: 'text',
        headers: { 'Access-Token': this.$store.getters.token },
        preview: this.previewFile,
        change: this.handleFileChange,
        remove: this.delFile
      },
      fileList: [],
      // 项目id
      projId: '',
      // 项目编号
      projNo: null,
      // 物资出库申请人
      leader: {},
      // 项目对应的采样安排信息
      detailTabsData: [],
      // 物资出库资源
      resourceMap: {},
      // 自动物资出库申请原因
      purUseReason: '',
      isQuoteBack: false
    }
  },
  created () {
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$message = Message
    this.scrollTop = window.document.body.scrollTop
    window.document.body.scrollTop = 0
    this.getProcessCommonWords()
  },
  computed: {
    configMap () {
      return { ...this.$store.getters.sys_config }
    }
  },
  beforeRouteLeave (to, form, next) {
    Vue.prototype.$confirm = Modal.confirm
    Vue.prototype.$message = require('ant-design-vue/lib/message').default
    window.document.body.scrollTop = this.scrollTop
    next()
  },
  watch: {
    processIdProp: {
      handler (newValue, oldvalue) {
        if (newValue && typeof newValue !== 'undefined') {
          this.tabFlowForm.width = '73%'
          this.tabFlowForm.height = document.body.clientHeight - 300 + 'px'
          this.reloading = true
          this.reloadData(newValue)
          this.isAdd = false
        }
      },
      immediate: true
    }
  },

  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.handleRouteParams(to.params)
    })
  },

  methods: {
    moment,
    handleRouteParams (params) {
      // 处理路由参数
      const paramsKey = 'mx_FlowProcessDetailRouteParams'
      // var params = to.params
      // 取出上次的参数
      const lastParamsStr = window.localStorage.getItem(paramsKey)
      if (Object.keys(params).length === 0) {
        // 如果本次参数是空使用上次的参数，即刷新页面时，取上次参数
        params = JSON.parse(lastParamsStr)
      }
      if (params) {
        // 更新参数
        window.localStorage.setItem(paramsKey, JSON.stringify(params))
        this.processId = (params && params.processId) || ''
        this.projId = (params && params.projId) || ''
        this.model = (params && params.model) || {}
        this.initFormModel = (params && params.initFormModel) || {}
        this.processQueryParam = params && params.queryParam ? JSON.parse(params.queryParam) : null
        this.commonLoading = false
        if(params.enterType&&params.enterType==='add_jx_caiyangjiezhichengliang'){
          this.projNo =  params.projNo

        }
        this.initData()
      }
    },
    addSignature () {
      const self = this
      if (!this.password || this.password === '') {
        this.$message.error('请填写登录密码')
        return
      }
      const { username, password } = this
      userSignatureApi.getActiveSignature({ username, password }).then((res) => {
        if (res.code === 0) {
          this.popover.signPopoverVisible = false
          if (typeof res.data === 'string') {
            self.$message.error(res.data)
          } else {
            if (res.data.signatureUrl !== null && res.data.signatureUrl !== '') {
              this.signatureUrl = res.data.signatureUrl
            } else {
              self.$message.error('请先确认已激活电子签名，否则无法审批')
            }
          }
        } else {
          self.$message.error(res.msg)
        }
      })
    },
    getDefaultSignature () {
      const self = this
      if (this.signatureUrl === '' && self.configMap.hasOwnProperty('PROCESS_SIGNATURE')) {
        self.defaultSignature = self.configMap.PROCESS_SIGNATURE
        if (self.defaultSignature === '1') {
          userSignatureApi.defaultActiveSignature().then((res) => {
            if (res.code === 0) {
              self.signatureUrl = res.data.signatureUrl
              // } else {
              //   self.$message.error(res.msg)
            }
          })
        }
      }
    },
    changeStyle (value) {
      if (value === 1) {
        this.tabFlowHistory.display = 'none'
        this.tabFlowForm.width = '96%'
        this.tabFlowForm.height = document.body.clientHeight - 300 + 'px'
        this.tabOperation.width = '96%'
        this.tabTitle.width = '96%'
        this.historyIcon.display = 'block'
      } else {
        this.tabFlowHistory.display = 'block'
        this.tabFlowForm.width = '73%'
        this.tabFlowForm.height = document.body.clientHeight - 300 + 'px'
        this.tabOperation.width = '73%'
        this.tabTitle.width = '73%'
        this.historyIcon.display = 'none'
      }
    },
    initData () {
      this.getDefaultSignature()
      if (this.defaultSignature !== '1') this.signatureUrl = '' // 签名置空
      this.agreeReason = ''
      this.fileList = []
      if (this.processId !== '') {
        this.tabFlowForm.width = '73%'
        this.tabFlowForm.height = document.body.clientHeight - 300 + 'px'
        this.reloading = true
        this.reloadData(this.processId)
        this.getProcessList()
        this.isAdd = false
      } else if (Object.keys(this.model).length > 0) {
        this.currentComponent = null
        this.reloading = true
        this.isAdd = true
        this.cmtInfo.key = ''
        this.tabFlowForm.width = '100%'
        this.tabFlowForm.height = document.body.clientHeight - 200 + 'px'
        this.showForm(this.model)
        this.previewImage = `${flowApi.pdImg}?key=${this.model.id}` // 流程图
      }
    },
    getProcessList () {
      if (this.processQueryParam === null) {
        this.processList = null
      } else {
        const param = omit(this.processQueryParam, ['url'])
        getAction(this.processQueryParam.url, param)
          .then((res) => {
            this.processList = res
            if (this.currentProcessIndex === -1) {
              this.currentProcessIndex = this.processList.data.length - 1
              this.processId = this.processList.data[this.currentProcessIndex].processInstanceId
              this.reloadData(this.processId)
            } else if (this.currentProcessIndex === this.processList.pageSize) {
              this.currentProcessIndex = 0
              this.processId = this.processList.data[this.currentProcessIndex].processInstanceId
              this.reloadData(this.processId)
            } else {
              this.currentProcessIndex = this.processList.data.findIndex(
                (item) => item.processInstanceId === this.processId
              )
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    turnTo (index) {
      this.currentProcessIndex = index
      if (index === -1) {
        this.processQueryParam.pageNo -= 1
        this.getProcessList()
      } else if (index === this.processList.data.length) {
        this.currentProcessIndex = this.processList.pageSize
        this.processQueryParam.pageNo += 1
        this.getProcessList()
      } else {
        const process = this.processList.data[index]
        this.processId = process.processInstanceId
        this.reloadData(this.processId)
      }
    },
    showReply (data) {
      const userName = this.currentUserName
      let flag = false
      if (data != null) {
        const userInfo = data.toString().split(',')
        userInfo.forEach((item) => {
          const itemArray = item.split('-')
          if (itemArray[1] === userName && itemArray[2] === '0') {
            flag = true
          }
        })
      }
      return flag
    },
    addComment () {
      // if (!(this.checkReply) || this.checkReply === '') {
      //   this.$message.error('请填写评论内容')
      //   return
      // }
      // getAction(flowApi.replyCopy, { reply: this.checkReply, processInstanceId: this.process.processInstanceId, creatUser: data.assignee }).then(res => {
      //   if (res.code === 0) {
      //     this.$message.success('评论成功')
      //     this.checkReply = ''
      //     this.processData = res.data.processTasks // 任务信息
      //     this.commentPopoverVisible = false
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },
    closeComment () {
      this.popover.commentPopoverVisible = false
      this.checkReply = ''
      },
    replyCopy (data) {
      if (!this.checkReply || this.checkReply === '') {
        this.$message.error('请填写回复内容')
        return
      }
      const userName = this.currentUserName
      const userInfo = data.comment_userId.toString().split(',')
      let id = ''
      userInfo.forEach((item) => {
        const itemArray = item.split('-')
        if (itemArray[1] === userName) {
          id = itemArray[0]
        }
      })
      getAction(flowApi.replyCopy, {
        id,
        reply: this.checkReply,
        processInstanceId: this.process.processInstanceId,
        creatUser: data.assignee
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('回复成功')
          this.checkReply = ''
          this.processData = res.data.processTasks // 任务信息
          this.popover.popoverVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showForm (record) {
      this.currentComponent = {}
      this.reloading = false
      this.showOperation = false // 流程操作栏不显示
      this.showOperationShouKuanQR = false
      if (this.customIds.hasOwnProperty(record.key)) {
        console.log('qqqqqqqqqqqq59+6526+459+60',record.key)
        this.cmtInfo.key = record.key
        this.cmtInfo.id = 0
        this.cmtInfo.name = record.name
        this.cmtInfo.showEdit = false
        this.isAdd = true
        this.currentComponent = CustomPreviewForm
      } else {
        console.log('444444444444444444444444444444444',record.key)
        switch (record.key) {
          case 'jx_res_pur': // 采购
            // getAction(resLocationApi.list, { pageSize: 500 }).then(result => {
            //   this.locationList = result.data
            // })
            this.cmtInfo.id = null
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.userName = this.$store.getters.realname
            // this.currentComponent = ItemForm
            this.currentComponent = () => import(`@/pages/cloud-quote/res/res-pur/modules/ItemFormForFlow`)
            break
          case 'jx_res_purplan': // 采购计划
            // getAction(resLocationApi.list, { pageSize: 500 }).then(result => {
            //   this.locationList = result.data
            // })
            this.cmtInfo.id = null
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.userName = this.$store.getters.realname
            // this.currentComponent = ItemForm
            this.currentComponent = () => import(`@/pages/cloud-quote/res/res-purplan/modules/ItemFormForFlow`)
            break
          case 'file_signature_report': // 报告签章申请
          case 'file_signature': // 签章申请
            this.cmtInfo.id = null
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.userName = this.$store.getters.realname
            this.currentComponent = () => import(`@/pages/cloud-quote/signature/modules/SignatureApplyFormForFlow`)
            break
          case 'jx_fapiao': // 发票申请
            this.cmtInfo.id = null
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.userName = this.$store.getters.realname
            this.currentComponent = () => import(`@/pages/flow/form/JxFapiaoForm`)
            break
          case 'jx_caiyangjiezhichengliang': // 采样介质称量流程称重人
            this.cmtInfo.id = null
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.userName = this.$store.getters.realname
            this.currentComponent = SampleWeight
            break
            case 'jx_yiqishouquan': // 仪器授权流程
            this.cmtInfo.id = null
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.userName = this.$store.getters.realname
            this.currentComponent = YiQiShouQuanForm
            break
          default:
            this.cmtInfo.key = record.key
            this.cmtInfo.name = record.name
            this.cmtInfo.showEdit = false
            this.isAdd = true
            this.currentComponent = () => import(`@/pages/flow/form/jx_normal`)
        }
      }
    },
    // 抄送 & 流转
    handleSure (value) {
      // this.showCarboncopy = true
      if (value === 1 || (value === 2 && (this.showOperation || (this.process.key === 'jx-shoukuanqr' && this.showOperationShouKuanQR))) || value === 3) {
        this.$refs.carboncopy.edit(this.process, this.activityTask, value)
      } else if (value === 2 && this.process.endTime !== null) {
        this.$message.info('流程已完结，无法进行流转')
      } else if (!this.showOperation || (this.process.key === 'jx-shoukuanqr' && !this.showOperationShouKuanQR)) {
        this.$message.info('当前节点您无流转权限')
      }
    },
    // 流转回复
    resolveTask () {
      if (!this.agreeReason || this.agreeReason === '') {
        this.$message.error('请填写回复意见')
        return
      }
      getAction(flowApi.resolveTask, {
        taskId: this.activityTask.id,
        reply: this.agreeReason,
        processInstanceId: this.process.processInstanceId,
        createUser: this.activityTask.comment_userId
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('回复成功')
          this.checkReply = ''
          this.refreshProcess(res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 表单打印
    handlePrint () {
      this.currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
      this.$print(this.$refs.print)
    },
    handleCut () {
      MessageBox.confirm('确认下载pdf文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getPdf(this.$refs.print, this.process.processInstanceName)
      })
    },
    refreshProcess (data) {
      this.processData = data.processTasks // 任务信息
      const activityTasks = this.processData.filter((v) => v.endTime == null)
      if (activityTasks !== null && activityTasks.length > 0) {
        this.activityTask = activityTasks[0]
      } else {
        this.activityTask = this.processData[this.processData.length - 1]
      }
      this.showAction(this.activityTask)
    },
    reloadData (id) {
      this.process = {}
      this.activityTask = {}
      this.activityTaskOperation = []
      this.fileList = []
      const self = this

      getAction(flowApi.process, { id }).then((res) => {
        if (res.code === 0) {
          this.reloading = false
          this.isQuoteBack = false
          const key = res.data.key // 流程实例key
          const reg = new RegExp(key + '_', 'g') // g,表示全部替换。
          const businessId = res.data.businessKey.replace(reg, '') // 业务ID
          const timestamp = new Date().getTime()
          this.previewImage = `${flowApi.img}?key=${res.data.processInstanceId}&timestamp=${timestamp}` // 流程图
          this.process = res.data
          // 对流程信息里数组进行处理 start
          if (typeof this.process.params !== 'undefined') {
            const pa = {}
            const param = []
            let paramLength = 0
            for (const i in this.process.params) {
              if (i.startsWith('accountInfos')) {
                const arr = i.split('[')[1].split(']')[0]
                pa[arr] = this.process.params[i]
                if (paramLength === 0 && typeof this.process.params[i] === 'object') {
                  paramLength = this.process.params[i].length
                }
              }
            }
            if (paramLength > 1) {
              for (let i = 0; i < paramLength; i++) {
                const item = {}
                for (const k in pa) {
                  item[k] = pa[k][i]
                }
                param.push(item)
              }
              this.process.params.accountInfos = param
            } else if (Object.keys(pa).length > 0) {
              param.push(pa)
              this.process.params.accountInfos = param
            }
          }
          // 对流程信息里数组进行处理 end

          this.cmtInfo.showEdit = false
          this.cmtInfo.name = res.data.processInstanceName.split('-')[0]
          this.processData = res.data.processTasks // 任务信息
          if (this.processData) {
            for (const i in this.processData) {
              if (this.processData[i].comment_message) {
                if (this.processData[i].comment_type) {
                  const commentType = this.processData[i].comment_type
                  const obj = this.auditStatusMap[commentType]
                  if (obj) {
                    this.processData[i].common_type = obj.name
                    this.processData[i].common_type_color = obj.color
                  }
                } else {
                  const commentType = this.processData[i].comment_message.split('>>')
                  if (commentType.length > 1) {
                    const type = commentType[0]
                    this.processData[i].common_type = type
                    if (type.indexOf('不') >= 0) {
                      this.processData[i].common_type_color = 'red'
                    } else if (type.indexOf('退') >= 0) {
                      this.processData[i].common_type_color = 'orange'
                    } else {
                      this.processData[i].common_type_color = 'blue'
                    }
                  }
                }
              }
            }
            const username = this.currentUserName
            // this.activityTask = this.processData[this.processData.length - 1]
            const activityTasks = this.processData.filter((v) => v.endTime == null)
            if (activityTasks !== null && activityTasks.length > 0) {
              // this.activityTask = activityTasks[0]
              const task = activityTasks.filter((v) => v.assignee === username)
              this.activityTask = task !== null && task.length > 0 ? task[0] : activityTasks[0]
            } else {
              this.activityTask = this.processData[this.processData.length - 1]
            }
            if (!this.process.endTime) {
              // 流程未结束
              if (
                this.activityTask.processOperations &&
                this.activityTask.processOperations.length > 0 &&
                !this.customIds.hasOwnProperty(key) &&
                !['jx_fapiao'].includes(key)
              ) {
                this.activityTaskOperation = this.activityTask.processOperations
              } else if (this.processData[0].name === this.activityTask.name) {
                // 当前流程为退回状态时，不显示下方操作框
                // 当前用户为该退回流程的发起者时，表单状态为可编辑
                if (username === this.processData[0].assignee && this.processData[0].name === this.activityTask.name) {
                  this.cmtInfo.showEdit = true
                }
              } else {
                this.activityTaskOperation = [
                  { id: 'AGREE_AUDIT', name: '确认通过' },
                  { id: 'REJECT_AUDIT', name: '退回到节点' }
                ]
              }
            }
          }
          let processDataTable = JSON.parse(JSON.stringify(res.data.processTasks)) // 流程历史表格信息
          if (processDataTable) {
            // processDataTable = processDataTable.splice(1, processDataTable.length)
            for (const i in processDataTable) {
              if (processDataTable[i].comment_message != null) {
                const commonType = processDataTable[i].comment_message.split('>>')
                if (commonType.length > 1) {
                  const type = commonType[0]
                  processDataTable[i].common_type = type
                }
                processDataTable[i].comment_message = processDataTable[i].comment_message.replace('>>', '：')
              }
            }
            // processDataTable = processDataTable.filter(v => v['endTime'] != null  && v['comment_message'] != null  && (v['assignee'] !== 'system' || ['抄送', '抄送回复', '流转'].includes(v['name'])))
            processDataTable = processDataTable.filter((v) => v.name !== '抄送' || v.comment_type)
          }
          this.processDataTable = processDataTable

          this.showAction(this.activityTask, key) // 判端节点操作区是否显示

          const userTasks = this.process.userTasks // 流程节点信息
          let flag = false
          for (let i = 0; i < userTasks.length; i++) {
            if (flag) {
              userTasks[i].disable = true
              continue
            }
            if (userTasks[i].name === this.activityTask.name) {
              userTasks[i].disable = true
              flag = true
            }
          }

          this.currentComponent = null
          if (this.customIds.hasOwnProperty(key)) {
            self.cmtInfo.key = key
            self.cmtInfo.id = parseInt(businessId)
            this.currentComponent = CustomPreviewForm
          } else {
            switch (key) {
              case 'jx_quote_check': // 报价
                self.cmtInfo.id = parseInt(businessId)
                // if (this.showAction(this.activityTask)) {
                if (this.showOperation) {
                  self.cmtInfo.formType = 2
                }
                this.currentComponent = QuoteCheckMgr
                break
              case 'jx_fenbao': // 分包
                self.cmtInfo.id = parseInt(businessId)
                // if (this.showAction(this.activityTask)) {
                if (this.showOperation) {
                  self.cmtInfo.formType = 2
                }
                this.currentComponent = SubtaskCheckMgr
                break
              case 'jx_fbs_check': // 分包商评审
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = SctrCheckViewMgr
                break
              case 'jx_contract_audit': // 合同审批
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ContractCheckMgr
                break
              case 'jx_project': // 项目流程
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ProjectFlowView
                break
              case 'jx_project_audit': // 项目审批
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ProjectAuditMgr
                break
              case 'jx_res_purplan': // 采购计划
                self.cmtInfo.id = parseInt(businessId)
                if (this.cmtInfo.showEdit === true) {
                  this.currentComponent = () => import(`@/pages/cloud-quote/res/res-purplan/modules/ItemFormForFlow`)
                } else {
                  this.currentComponent = purPlanIndex
                }
                break
              case 'jx_res_pur': // 采购
                self.cmtInfo.id = parseInt(businessId)
                if (this.cmtInfo.showEdit === true) {
                  this.currentComponent = () => import(`@/pages/cloud-quote/res/res-pur/modules/ItemFormForFlow`)
                } else {
                  this.currentComponent = purIndex
                }
                break
              case 'jx_universal_doc': // 文档
                self.cmtInfo.id = parseInt(businessId)
                if (businessId !== '') {
                  this.currentComponent = DocumentCheckForm
                }
                break
              case 'jx_res_puruse': // 领用
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = purUseIndex
                break
              case 'jx_report': // 报告审批
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = JxReport
                break
              case 'report_send': // 报告寄发
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ReportSendMgr
                break
              case 'report_recovery': // 报告回收
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ReportRecoveryMgr
                break
              case 'res_transfer': // 物资移交
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = MaterialTransferModal
                break
              case 'res_lab_material_apply': // 实验室物品申领
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ResMaterialApplyModal
                break
              case 'res_reagent_recheck_apply': // 试剂配置复核
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = ReagentRecheckModal
                break
              case 'res_cbt_eval_confirm_apply': // 仪器设备溯源结果评价及确认
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = EvalConfirmModal
                break
              case 'res_cbt_confirm_apply': // 仪器设备校准/检定确认流程
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = CbtConfirmModal
                break
              case 'res_staff_post_ability_apply': // 人员岗位能力确认
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = StaffPostAbilityForm
                break
              case 'res_staff_authorization_apply': // 人员授权
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = StaffAuthorizationForm
                break
              case 'report_coordination': // 报告外协审核
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = CoordinationMgr
                break
              case 'file_signature_report': // 报告签章审核
              case 'file_signature': // 签章审核
                self.cmtInfo.id = parseInt(businessId)
                if (this.cmtInfo.showEdit === true) {
                  this.currentComponent = () =>
                    import(`@/pages/cloud-quote/signature/modules/SignatureApplyFormForFlow`)
                } else {
                  this.currentComponent = FileSignatureMgr
                }
                break
              case 'res_staff_training_apply': // 培训申请
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = StaffTrainingApplyModal
                break
              case 'res_staff_training_effectiveness_apply': // 培训有效性评价
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = StaffTrainingEffectivenessModal
                break
              case 'proj_delay_remind': // 项目延期提醒流程
                self.cmtInfo.id = parseInt(businessId)
                if (!this.process.endTime) {
                  this.activityTaskOperation = [{ id: 'AGREE_AUDIT', name: '确认反馈' }]
                  self.cmtInfo.showEdit = true
                }
                this.currentComponent = () => import(`../cloud-quote/proj_delay/moudles/ProjDelayRemindMgr`)
                break
              case 'report_task_user_change': // 报告编制人变更流程
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = () => import(`@/pages/flow/form/ReportTaskUserChangeForm`)
                break
              case 'jx_fapiao': // 发票申请
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = () => import(`@/pages/flow/form/JxFapiaoForm`)
                break
              case 'jx-shoukuanqr': // 收款确认
                self.cmtInfo.key = key
                self.cmtInfo.id = parseInt(businessId)
                this.showActionQR(this.activityTask, key) // 收款登记流程判断节点操作区是否显示
                this.currentComponent = () => import(`@/pages/cloud-quote/proj-payment-registration/PaymentConfirm`)
                break
              case 'jx_quote_back': // 报价退回
                self.cmtInfo.id = parseInt(businessId)
                // if (this.showAction(this.activityTask)) {
                if (this.showOperation) {
                  self.cmtInfo.formType = 2
                }
                this.isQuoteBack = true
                this.currentComponent = QuoteCheckMgr
                break
                case 'jx_caiyangjiezhichengliang': // 采样介质称量流程称重人
                self.cmtInfo.id = parseInt(businessId)
                // if (this.showAction(this.activityTask)) {
                  this.currentComponent = SampleWeight
                break
              default:
                self.cmtInfo.id = parseInt(businessId)
                this.currentComponent = () => import(`@/pages/flow/form/jx_normal`)
              // this.currentComponent = () => import(`@/pages/flow/form/${res.data.key}`)
            }
          }
          this.$nextTick(() => {
            // 查找表单内的reload 并执行
            if (typeof this.$refs.flowComponent !== 'undefined') {
              const reload = this.$refs.flowComponent.reload
              if (reload && typeof reload === 'function') {
                reload()
              }
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    showAction (record, key) {
      const username = this.currentUserName
      const operateUsers = record.assignee != null ? record.assignee.split(',') : []
      if (
        (operateUsers.includes(username) ||
          username === record.assignee ||
          username === 'system' ||
          username === 'admin') &&
        !record.endTime &&
        !this.isAdd &&
        !this.cmtInfo.showEdit &&
        key != 'jx_project' &&
        key != 'jx-shoukuanqr'
      ) {
        this.tabFlowForm.height = document.body.clientHeight - 580 + 'px'
        this.showOperation = true
      } else {
        this.tabFlowForm.height = document.body.clientHeight - 300 + 'px'
        this.showOperation = false
      }
    },

    showActionQR (record, key) {
      const username = this.currentUserName
      const operateUsers = record.assignee != null ? record.assignee.split(',') : []
      if (
        (operateUsers.includes(username) ||
          username === record.assignee ||
          username === 'system' ||
          username === 'admin') &&
        !record.endTime &&
        !this.isAdd &&
        !this.cmtInfo.showEdit
      ) {
        // this.tabFlowForm['height'] = document.body.clientHeight - 580 + 'px'
        this.showOperationShouKuanQR = true
      } else {
        // this.tabFlowForm['height'] = document.body.clientHeight - 300 + 'px'
        this.showOperationShouKuanQR = false
      }
    },

    getColor (type) {
      switch (type) {
        case 'success':
        case 'AGREE_AUDIT':
          return 'primary'
        case 'stop':
        case 'back':
        case 'backToStep':
        case 'REJECT_AUDIT':
          return 'danger'
        default:
          return 'default'
      }
    },

    // 退回
    backTopStep (taskId, distFlowElementId) {
      const self = this
      if (self.defaultSignature === '1' && self.signatureUrl === '') {
        self.$message.error('请先确认已激活电子签名，否则无法审批')
        return
      }
      if (this.agreeReason === '' || this.agreeReason.length < 1) {
        this.$message.error('请填写审批意见')
        return
      }
      const param = {
        taskId,
        distFlowElementId,
        reason: this.agreeReason
      }
      const vars = {}
      if (this.signatureUrl !== '') vars.signatureUrl = this.signatureUrl
      if (this.fileList.length > 0) {
        let accessory = cloneDeep(this.fileList)
        accessory = accessory.filter((it) => !it.error)
        for (let i = 0; i < accessory.length; i++) {
          accessory[i] = omit(accessory[i], [
            'lastModified',
            'lastModifiedDate',
            'originFileObj',
            'percent',
            'response',
            'size',
            'status',
            'type',
            'thumbUrl'
          ])
        }
        vars.accessory = accessory
      }
      if (JSON.stringify(vars) !== '{}') param.vars = JSON.stringify(vars)
      this.commonLoading = !this.commonLoading
      getAction(flowApi.backToStep, param).then((res) => {
        if (res.code === 0) {
          if (['jx_contract_audit'].includes(self.process.key)) {
            self.listProject()
          } else {
            self.reloadData(self.processId)
            self.$message.success('操作成功')
          }
        } else {
          self.$message.error(res.msg)
        }
        self.commonLoading = !self.commonLoading
      })
    },

    listProject () {
      const self = this
      projectApi.list({ contractId: self.cmtInfo.id, page: false }).then((res) => {
        if (res.code === 0) {
          const contractProjects = res.data
          if (contractProjects && contractProjects.length > 1) {
            const formData = []
            let InitialProjId = null
            contractProjects.forEach((item) => {
              if (item.projContractType && item.projContractType == 1) {
                formData.push(item.id)
              } else {
                InitialProjId = item.id
              }
            })
            if (formData.length > 0) {
              self.contractProjApply(formData, InitialProjId)
              return
            }
          }
          self.reloadData(self.processId)
          self.$message.success('操作成功')
        } else {
          self.$message.error(res.msg)
        }
      })
    },
    contractProjApply (formData, projId) {
      const self = this
      const data = {
        contractId: null,
        contractAmount: null,
        type: 1,
        projIds: formData,
        projId
      }
      projectApi.applyData(data).then((res) => {
        if (res.code === 0) {
          self.reloadData(self.processId)
          self.$message.success('操作成功')
        } else {
          self.$message.error(res.msg)
        }
      })
    },

    // 审批通过
    changeStatus (taskId, type, typeName) {
      const self = this
      if (self.defaultSignature === '1' && self.signatureUrl === '') {
        self.$message.error('请先确认已激活电子签名，否则无法审批')
        return
      }
      if (this.agreeReason === '' || this.agreeReason.length < 1) {
        self.$message.error('请填写审批意见')
        return
      }
      this.commonLoading = !this.commonLoading
      const params = {
        taskId,
        type,
        reason: typeName + '>>' + self.agreeReason,
        opinion: self.agreeReason
      }
      params.vars = {
        type,
        typeName,
        reason: typeName + '>>' + self.agreeReason,
        opinion: self.agreeReason
      }
      if (this.signatureUrl !== '') params.vars.signatureUrl = this.signatureUrl
      if (this.fileList.length > 0) {
        let accessory = cloneDeep(this.fileList)
        accessory = accessory.filter((it) => !it.error)
        for (let i = 0; i < accessory.length; i++) {
          accessory[i] = omit(accessory[i], [
            'lastModified',
            'lastModifiedDate',
            'originFileObj',
            'percent',
            'response',
            'size',
            'status',
            'type',
            'thumbUrl'
          ])
        }
        params.vars.accessory = JSON.stringify(accessory)
      }

      // 自定义表单审批
      if (self.customIds.hasOwnProperty(this.process.key)) {
        const isEditForm = self.$refs.flowComponent.$children[0].$attrs.isEditForm
        if (isEditForm) {
          const dataList = self.$refs.flowComponent.$children[0].data.list.filter((item) => item.viewMode === 'edit')
          const requireList = []
          this.updateModelRecursive(dataList, requireList)
          const data = self.$refs.flowComponent.$children[0].models
          let flag = false
          requireList.forEach((i) => {
            if (!data.hasOwnProperty(i) || data[i].toString() === '') flag = true
          })

          if (flag) {
            self.$message.error('请先完善表单内容')
            self.commonLoading = !self.commonLoading
          } else {
            const tempData = {}
            const fileAppendixList = []
            for (const item in data) {
              if (typeof data[item] === 'object') {
                const options = []
                data[item].forEach((it) => {
                  if (it.hasOwnProperty('url') && it.hasOwnProperty('name')) {
                    const name = it.name
                    const url = it.url
                    options.push({ name, url })
                    if (self.process.key === 'jx_guidang' && it.hasOwnProperty('response')) {
                      const projId = data.projName.split('^')[0]
                      fileAppendixList.push(Object.assign({ projId }, self.fileAppendix(item, it.response)))
                    }
                  } else {
                    options.push(it)
                  }
                })
                tempData[item] = options
              } else {
                tempData[item] = data[item]
              }
            }
            tempData.id = self.cmtInfo.id
            const saveData = { tableName: self.process.key, data: tempData, params, fileAppendixList }
            flowApi.saveByModel({ modelJson: JSON.stringify(saveData) }).then((res) => {
              if (res.code === 0) {
                self.signatureUrl = '' // 签名置空
                self.reloadData(self.processId)
                self.$message.success('操作成功')
              } else {
                self.$message.error(res.msg)
              }
              self.commonLoading = !self.commonLoading
            })
          }
          return
        }
      } else if (['report_send'].includes(this.process.key)) {
        // 报告邮寄审批方法
        const isEditForm = self.$refs.flowComponent.isEditForm
        if (isEditForm) {
          this.saveReportSendByFlow(params)
          return
        }
      } else if (['res_cbt_confirm_apply'].includes(this.process.key)) {
        // 当前审批节点
        const currentApply = this.process.processTasks[this.process.processTasks.length - 1]
        // 如果是确认人审批则需要验证表单数据
        if (currentApply.name === '确认人审批') {
          this.saveCbtConfirmByFlow(params)
          return
        }
      } else if (['res_staff_post_ability_apply'].includes(this.process.key)) {
        // 当前审批节点
        const currentApply = this.process.processTasks[this.process.processTasks.length - 1]
        // 如果是行政人事审批则需要验证表单数据
        if (currentApply.name === '行政人事审批') {
          this.saveStaffPostAbilityByFlow(params)
          return
        }
      } else if (['res_staff_authorization_apply'].includes(this.process.key)) {
        // 当前审批节点
        const currentApply = this.process.processTasks[this.process.processTasks.length - 1]
        // 如果是行政人事审批则需要验证表单数据
        if (currentApply.name === '行政人事审批') {
          this.saveStaffAuthorizationByFlow(params)
          return
        }
      } else if (['res_staff_training_effectiveness_apply'].includes(this.process.key)) {
        // 当前审批节点
        const currentApply = this.process.processTasks[this.process.processTasks.length - 1]
        // 如果是技术负责人/质量负责人审批节点需要验证数据
        if (currentApply.name === '技术负责人/质量负责人审批') {
          this.saveStaffTrainingEffectivenessByFlow(params)
          return
        }
      } else if (['proj_delay_remind'].includes(this.process.key)) {
        // 项目延期提醒流程保存方法
        // const isEditForm = self.$refs.flowComponent.isEditForm
        // if (isEditForm) {
        this.saveProjDelayByFlow(params)
        return
        // }
      }
      // 非自定义表单审批
      this.handleSubmit(params)
    },
    saveReportSendByFlow (params) {
      const self = this
      self.$refs.flowComponent.dataForm.form.validateFields((errors, values) => {
        if (errors != null) {
          self.commonLoading = !self.commonLoading
          return false
        }
        const defaultData = self.$refs.flowComponent.defaultData
        // const sendData = self.$refs.flowComponent.sendData
        // if (sendData.sendMode === '1' && (defaultData.accessory === '' || defaultData.accessory === null)) {
        //   self.commonLoading = !self.commonLoading
        //   self.$message.error('请上传快递附件')
        //   return
        // }
        const saveData = { model: JSON.stringify(Object.assign(defaultData, values)), params: JSON.stringify(params) }
        reportSendOrderApi.saveByFlow(saveData).then((res) => {
          if (res.code === 0) {
            self.signatureUrl = '' // 签名置空
            self.reloadData(self.processId)
            self.$message.success('操作成功')
          } else {
            self.$message.error(res.msg)
          }
          self.commonLoading = !self.commonLoading
        })
      })
    },

    saveProjDelayByFlow (params) {
      const self = this
      const defaultData = self.$refs.flowComponent.defaultData
      const projList = defaultData.projList.filter((item) => item.doneTime == null)
      if (projList !== null && projList.length > 0) {
        self.commonLoading = !self.commonLoading
        self.$message.error('请填写完成时间')
        return
      }
      const fileAppendix = self.$refs.flowComponent.fileAppendix
      if (fileAppendix.length > 0) {
        defaultData.fileupload = JSON.stringify(fileAppendix)
      }
      const saveData = { model: JSON.stringify(defaultData), params: JSON.stringify(params) }
      projDelayApi.saveByFlow(saveData).then((res) => {
        if (res.code === 0) {
          self.signatureUrl = '' // 签名置空
          self.reloadData(self.processId)
          self.$message.success('操作成功')
        } else {
          self.$message.error(res.msg)
        }
        self.commonLoading = !self.commonLoading
      })
    },

    saveCbtConfirmByFlow (params) {
      const issueList = this.$refs.flowComponent.issueList
      if (
        this.isNullOrEmpty(issueList.issueOne) ||
        this.isNullOrEmpty(issueList.issueTwo) ||
        this.isNullOrEmpty(issueList.issueThree) ||
        this.isNullOrEmpty(issueList.issueFour) ||
        this.isNullOrEmpty(issueList.issueFive) ||
        this.isNullOrEmpty(issueList.issueSix) ||
        this.isNullOrEmpty(issueList.issueSeven)
      ) {
        this.$message.error('请输入确认结果！')
        this.commonLoading = !this.commonLoading
        return
      }
      if (this.isNullOrEmpty(issueList.conclusion)) {
        this.$message.error('请输入结论！')
        this.commonLoading = !this.commonLoading
        return
      }
      const cbtInfo = this.$refs.flowComponent.cbtInfo
      cbtInfo.confirmIssue = JSON.stringify(issueList)
      const data = { model: JSON.stringify(cbtInfo), params: JSON.stringify(params) }
      resCbtApi.saveByFlow(data).then((res) => {
        debugger
        if (res.code === 0) {
          this.signatureUrl = '' // 签名置空
          this.reloadData(this.processId)
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg)
        }
        this.commonLoading = !this.commonLoading
      })
    },

    saveStaffPostAbilityByFlow (params) {
      const postAbilityApplyInfo = this.$refs.flowComponent.postAbilityApplyInfo
      const fileList = this.$refs.flowComponent.fileAppendix
      const data = {
        model: JSON.stringify(postAbilityApplyInfo),
        fileList: JSON.stringify(fileList),
        params: JSON.stringify(params)
      }
      resStaffApi.saveByFlow(data).then((res) => {
        if (res.code === 0) {
          this.signatureUrl = '' // 签名置空
          this.$refs.flowComponent.loadData()
          this.reloadData(this.processId)
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg)
        }
        this.commonLoading = !this.commonLoading
      })
    },

    saveStaffAuthorizationByFlow (params) {
      const authorizationInfo = this.$refs.flowComponent.authorizationInfo
      const methodIds = this.$refs.flowComponent.methodIds
      if (methodIds.length === 0 && authorizationInfo.authMethods === null) {
        this.$message.error('请选择授权检测方法！')
        this.commonLoading = !this.commonLoading
        return
      }
      const data = {
        model: JSON.stringify(authorizationInfo),
        methodIds: JSON.stringify(methodIds),
        params: JSON.stringify(params)
      }
      resStaffApi.saveAuthorizationByFlow(data).then((res) => {
        if (res.code === 0) {
          this.signatureUrl = '' // 签名置空
          this.$refs.flowComponent.loadData()
          this.reloadData(this.processId)
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg)
        }
        this.commonLoading = !this.commonLoading
      })
    },

    saveStaffTrainingEffectivenessByFlow (params) {
      const effectivenessInfo = this.$refs.flowComponent.effectivenessInfo
      if (this.isNullOrEmpty(effectivenessInfo.trainingEffectiveness)) {
        this.$message.error('请输入培训评价！')
        this.commonLoading = !this.commonLoading
        return
      }
      const data = { model: JSON.stringify(effectivenessInfo), params: JSON.stringify(params) }
      resStaffTrainingApi.saveByFlow(data).then((res) => {
        if (res.code === 0) {
          this.signatureUrl = '' // 签名置空
          this.$refs.flowComponent.loadData()
          this.reloadData(this.processId)
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg)
        }
        this.commonLoading = !this.commonLoading
      })
    },

    isNullOrEmpty (item) {
      return item === undefined || item === null || item === ''
    },

    fileAppendix (item, fileData) {
      // 归档附件处理（传到后台保存至 file_appendix 表中）
      let projProgress = ''
      let result = {}
      if (fileData.code === 0) {
        const file = fileData.data
        if (item === 'prjoTaskFile') {
          // 下单表
          projProgress = 'task'
        } else if (item === 'taskSampleFile') {
          // 采样记录
          projProgress = 'sampling'
        } else if (item === 'checkFile') {
          // 分析检测
          projProgress = 'check'
        } else if (item === 'reportFile') {
          // 报告
          projProgress = 'report'
        } else if (item === 'subReportFile') {
          // 分包报告
          projProgress = 'subReport'
        } else if (item === 'subContractFile') {
          // 分包合同
          projProgress = 'subContract'
        } else if (item === 'projContractFile') {
          // 项目合同
          projProgress = 'projContract'
        }
        result = {
          projProgress,
          fileUrl: file.fileUrl,
          fileName: file.fileName,
          fileExt: file.fileExt,
          fileType: file.fileType
        }
      }
      return result
    },

    updateModelRecursive (jsonList, requireList) {
      const excludeType = ['text', 'arrayDisplay', 'divider', 'image', 'blank', 'button']
      const recursiveType = ['card', 'grid', 'popModal', 'subForm', 'dataBind']
      jsonList.forEach((item) => {
        if (!excludeType.includes(item.type)) {
          if (recursiveType.includes(item.type)) {
            if (item.type === 'grid') {
              for (let i = 0; i < item.columns.length; i++) {
                item.columns[i].list = this.updateModelRecursive(item.columns[i].list, requireList)
              }
            } else {
              item.data.list = this.updateModelRecursive(item.data.list, requireList)
            }
          } else {
            if (item.options.hasOwnProperty('required') && item.options.required === true) requireList.push(item.model)
          }
        }
      })
      return jsonList
    },
    handleSubmit (params) {
      const self = this
      const processKey = this.process.key
      switch (this.process.key) {
        case 'jx_quote_check':
          // params.vars = this.$refs.flowComponent.getCheckData()
          break
        case 'jx_fenbao':
          params.vars = { active: self.activityTask.name, agreeReason: self.agreeReason }
          break
        case 'jx_res_purplan':
          break
        case 'jx_universal_doc':
          break
      }
      if (this.signatureUrl !== '') params.vars.signatureUrl = this.signatureUrl
      params.vars = JSON.stringify(params.vars)

      let flag = true
      // 查找表单内的验证器validator 并执行
      const validator = self.$refs.flowComponent.validator
      if (validator && typeof validator === 'function') {
        flag = validator()
      }
      if (flag) {
        getAction(flowApi.agree, params)
          .then((res) => {
            if (res.code === 0) {
              self.signatureUrl = '' // 签名置空
              self.reloadData(self.processId)
              self.$message.success('操作成功')
              // if (processKey == 'jx_project_audit') {
              //   self.getQualityApplicationSet()
              // }
            } else {
              self.$message.error(res.msg)
            }
          })
          .finally(() => {
            self.commonLoading = !self.commonLoading
          })
      } else {
        self.commonLoading = !self.commonLoading
      }
    },
    // 判断是否为自动质控申请
    getQualityApplicationSet () {
      autoConfigureApi.selectConfigByType({ type: 'quality_application_set' }).then((res) => {
        // 如果是1，自动质控申请
        if (res == 1) {
          this.autoQualityApplicationSave()
        }
      })
    },
    // 自动进行质控申请
    autoQualityApplicationSave () {
      qualityControlApi.findSetting({ projId: this.projId }).then((res) => {
        if (res.code === 0) {
          const values = Object.values(res.data?.sampleDetail).flat(Infinity)
          const fieldParallelSamples = values.reduce((acc, data) => {
            const sampleList = data.fieldParallelQc.sampleList
            sampleList &&
              sampleList.length &&
              acc.push.apply(
                acc,
                sampleList.map((item) => {
                  return {
                    numPeriod: item.numPeriod,
                    numFrequency: item.numFrequency,
                    storage: item.storage,
                    siteId: data.siteId,
                    items: [
                      {
                        cateId: item.cateId,
                        itemId: item.itemId
                      }
                    ]
                  }
                })
              )
            return acc
          }, [])

          // 校验是否还有平行样还未保存
          const editedFieldParallel = fieldParallelSamples.find((item) => item.editable)
          if (editedFieldParallel) {
            const itemInfo = values.find((item) => {
              return (
                item.fieldParallelQc &&
                item.fieldParallelQc.sampleList &&
                item.fieldParallelQc.sampleList.includes(editedFieldParallel)
              )
            })
            const message = `未保存的平行样信息：【${itemInfo.cateName}】下，点位:【${itemInfo.siteName}】,监测项目:【${itemInfo.itemName}】`
            this.$message.error(message)
            return false
          }

          const allBlankSamples = values.reduce((acc, data) => {
            const sampleList = data.allBlankQc.sampleList
            sampleList &&
              sampleList.length &&
              acc.push.apply(
                acc,
                sampleList.map((item) => {
                  return {
                    storage: item.storage,
                    siteId: data.siteId,
                    batchNo: item.numPeriod,
                    numPeriod: item.numPeriod,
                    numFrequency: item.numFrequency,
                    items: [
                      {
                        cateId: item.cateId,
                        itemId: item.itemId
                      }
                    ]
                  }
                })
              )
            return acc
          }, [])

          const transportBlankSamples = values.reduce((acc, data) => {
            const sampleList = data.transportBlankQc.sampleList
            sampleList &&
              sampleList.length &&
              acc.push.apply(
                acc,
                sampleList.map((item) => {
                  return {
                    storage: item.storage,
                    siteId: data.siteId,
                    batchNo: item.numPeriod,
                    numPeriod: item.numPeriod,
                    numFrequency: item.numFrequency,
                    items: [
                      {
                        cateId: item.cateId,
                        itemId: item.itemId
                      }
                    ]
                  }
                })
              )
            return acc
          }, [])

          const fieldBlankSamples = values.reduce((acc, data) => {
            const sampleList = data.fieldBlankQc.sampleList
            sampleList &&
              sampleList.length &&
              acc.push.apply(
                acc,
                sampleList.map((item) => {
                  return {
                    storage: item.storage,
                    siteId: data.siteId,
                    batchNo: item.numPeriod,
                    numPeriod: item.numPeriod,
                    numFrequency: item.numFrequency,
                    items: [
                      {
                        cateId: item.cateId,
                        itemId: item.itemId
                      }
                    ]
                  }
                })
              )
            return acc
          }, [])

          const saveData = {
            projId: this.projId,
            createUser: this.$store.getters.userInfo.username,
            createTime: moment(),
            sampleLink: {
              fieldBlank: fieldBlankSamples,
              transportBlank: transportBlankSamples,
              allBlank: allBlankSamples,
              fieldParallel: fieldParallelSamples
            }
          }
          qualityControlApi.saveSampleLink(saveData).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getSampleApplicationSet()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 判断是否为自动制样申请
    getSampleApplicationSet () {
      autoConfigureApi.selectConfigByType({ type: 'sample_application_set' }).then((res) => {
        // 如果是1，自动制样申请
        if (res == 1) {
          this.autoSampleApplicationSave()
        }
      })
    },
    // 自动进行制样申请
    autoSampleApplicationSave () {
      qualityControlSampleApi.qeuryQualityByProId({ projId: this.projId }).then((result) => {
        qualityControlSampleApi.queryDetails({ qualityId: result.qualityId }).then((res) => {
          if (res.code === 0) {
            const sampleDetails = res.data
            const details =
              (sampleDetails &&
                sampleDetails.length &&
                sampleDetails.filter((item) => {
                  return item.type !== sampleInfoTypeEnum.fieldParallel
                })) ||
              []
            if (details.length > 0) {
              details.forEach((item) => {
                item.qcSampleId = item.id
              })
              const data = {
                projId: this.projId,
                refId: result.qualityId,
                applyLevel: '1',
                applyNote: undefined,
                applyReason: `因需对《${result.projNo} ${result.projName}》项目进行质控，特此申请制样。`,
                applyTime: moment(),
                applyUser: null,
                expectTime: moment(),
                source: makeSampleApplySourceEnum.qualityControlSample,
                details
              }
              makeSampleApplyApi.applyMake(data).then((res) => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.getMaterialApplicationSet()
                } else {
                  this.$message.error(res.msg)
                }
              })
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    // 判断是否为自动物资出库申请
    getMaterialApplicationSet () {
      autoConfigureApi.selectConfigByType({ type: 'material_application_set' }).then((res) => {
        // 如果是1，自动物资出库申请
        if (res == 1) {
          this.getUserName()
          this.querySampleTask(this.projId)
          this.queryResources([this.projId])
        }
      })
    },

    // 查询物资出库申请人
    getUserName () {
      projProgressUserApi.getUserName().then((res) => {
        this.leader = res
        this.nextStep()
      })
    },
    // 查询项目对应的采样安排信息
    querySampleTask (projId) {
      this.detailTabsData = []
      tsTaskApi.findOne({ projId }).then((res) => {
        this.detailTabsData.push(res.data)
        this.nextStep()
      })
    },
    // 查询物资出库资源
    queryResources (projId) {
      scheduleResourceTypeApi.initResourceType(projId)
      const needResourceSearch = tsTaskApi.queryResource(projId).then((res) => {
        return res.data
      })
      const existResourceSearch = scheduleResourceApi.list({ page: false, projIds: projId }).then((res) => {
        return res.data
      })
      Promise.all([needResourceSearch, existResourceSearch]).then((res) => {
        const needData = res[0]
        const existData = res[1]
        const existProjectGroup = groupBy(existData, 'projId')
        forEach(needData, (value, key) => {
          const existProjectData = existProjectGroup[key] ?? []
          const existProjectDataGroup = groupBy(existProjectData, 'cateId')
          value.forEach((subValue) => {
            subValue.details.forEach((detail) => {
              const existDetailData = existProjectDataGroup[detail.id] ?? []
              detail.existNum = existDetailData.length
              detail.existData = [].concat(existProjectDataGroup[existDetailData])
            })
          })
        })
        this.resourceMap = needData
        this.nextStep()
      })
    },
    // 判断是否获取到需要的数据
    nextStep () {
      if (
        this.leader &&
        Object.keys(this.leader).length > 0 &&
        this.detailTabsData &&
        this.detailTabsData.length > 0 &&
        this.resourceMap &&
        Object.keys(this.resourceMap).length > 0
      ) {
        this.getResourceDetail()
      }
    },
    // 加载物资出库资源
    getResourceDetail () {
      const detailTabsData = this.detailTabsData
      const resourceMap = this.resourceMap
      const needProject = detailTabsData.filter((item) => item.purStatus === resPurUseApplyStatusEnum.pending)
      const needProjIds = (needProject && needProject.length && needProject.map((item) => item.projId)) || []
      // 容器/工具合并，设备取最大值
      const material = []
      const resourceKeyTemp = Object.keys(resourceMap)
      const resourceVal =
        (resourceKeyTemp &&
          resourceKeyTemp &&
          resourceKeyTemp.filter((item) => needProjIds.includes(parseInt(item)))) ||
        []

      const detailList =
        (resourceVal &&
          resourceVal.length &&
          resourceVal.reduce((init, res) => {
            init.push.apply(init, resourceMap[res])
            return init
          }, [])) ||
        []

      // 设备 device
      const deviceList = detailList.filter((item) => item.type === 'device')

      const deviceDetailList =
        (deviceList &&
          deviceList.length &&
          deviceList.reduce((init, res) => {
            init.push.apply(init, res.details)
            return init
          }, [])) ||
        []

      const deviceGroup = groupBy(deviceDetailList, 'id')
      const deviceGroupVal = Object.values(deviceGroup)

      const deviceRes =
        (deviceGroupVal &&
          deviceGroupVal.length &&
          deviceGroupVal.reduce((init, res) => {
            init.push(
              maxBy(res, (o) => {
                return o.number - o.existNum
              })
            )
            return init
          }, [])) ||
        []
      const filterDeviceRes = deviceRes.filter((item) => item.number > item.existNum)

      material.push.apply(
        material,
        filterDeviceRes.map((item) => {
          return { id: item.id, type: 'device', num: item.number - item.existNum }
        })
      )

      // 工具 tool
      const toolList = detailList.filter((item) => item.type === 'tool')

      const toolDetailList =
        (toolList &&
          toolList.length &&
          toolList.reduce((init, res) => {
            init.push.apply(init, res.details)
            return init
          }, [])) ||
        []

      const toolGroup = groupBy(toolDetailList, 'id')
      const toolGroupVal = Object.values(toolGroup)

      const toolRes =
        (toolGroupVal &&
          toolGroupVal.length &&
          toolGroupVal.reduce((init, res) => {
            init.push(
              maxBy(res, (o) => {
                return o.number - o.existNum
              })
            )
            return init
          }, [])) ||
        []

      const filterToolRes = toolRes.filter((item) => item.number > item.existNum)
      material.push.apply(
        material,
        filterToolRes.map((item) => {
          return { id: item.id, type: 'tool', num: item.number - item.existNum }
        })
      )

      // 容器 container
      const containerList = detailList.filter((item) => item.type === 'container')

      const containerDetailList =
        (containerList &&
          containerList.length &&
          containerList.reduce((init, res) => {
            init.push.apply(init, res.details)
            return init
          }, [])) ||
        []

      const containerGroup = groupBy(containerDetailList, 'id')
      const containerGroupVal = Object.values(containerGroup)

      const containerRes =
        (containerGroupVal &&
          containerGroupVal.length &&
          containerGroupVal.reduce((init, res) => {
            init.push(
              Object.assign(res[0], {
                number: sumBy(res, (o) => {
                  return o.number - o.existNum
                })
              })
            )
            return init
          }, [])) ||
        []
      const filterContainerRes = containerRes.filter((item) => item.number > 0)

      material.push.apply(
        material,
        filterContainerRes.map((item) => {
          return { id: item.id, type: 'container', num: item.number }
        })
      )

      // 标准品 standardSample
      const standardSampleList = detailList.filter((item) => item.type === 'standardSample')

      const standardSampleDetailList =
        (standardSampleList &&
          standardSampleList.length &&
          standardSampleList.reduce((init, res) => {
            init.push.apply(init, res.details)
            return init
          }, [])) ||
        []

      const standardSampleGroup = groupBy(standardSampleDetailList, 'id')
      const standardSampleGroupVal = Object.values(standardSampleGroup)

      const standardSampleRes =
        (standardSampleGroupVal &&
          standardSampleGroupVal.length &&
          standardSampleGroupVal.reduce((init, res) => {
            init.push(
              Object.assign(res[0], {
                number: sumBy(res, (o) => {
                  return o.number - o.existNum
                })
              })
            )
            return init
          }, [])) ||
        []

      const filterStandardSampleGroup = standardSampleRes.filter((item) => item.number > 0)

      material.push.apply(
        material,
        filterStandardSampleGroup.map((item) => {
          return { id: item.id, type: 'standardSample', num: item.number }
        })
      )

      //  固定剂 fixactive
      const fixactiveList = detailList.filter((item) => item.type === 'fixactive')

      const fixactiveDetailList =
        (fixactiveList &&
          fixactiveList.length &&
          fixactiveList.reduce((init, res) => {
            init.push.apply(init, res.details)
            return init
          }, [])) ||
        []

      const fixactiveGroup = groupBy(fixactiveDetailList, 'id')
      const fixactiveGroupVal = Object.values(fixactiveGroup)

      const fixactiveRes =
        (fixactiveGroupVal &&
          fixactiveGroupVal.length &&
          fixactiveGroupVal.reduce((init, res) => {
            init.push(
              Object.assign(res[0], {
                number: sumBy(res, (o) => {
                  return o.number - o.existNum
                })
              })
            )
            return init
          }, [])) ||
        []

      const filterFixactiveRes = fixactiveRes.filter((item) => item.number > 0)

      material.push.apply(
        material,
        filterFixactiveRes.map((item) => {
          return { id: item.id, type: 'fixactive', num: item.number }
        })
      )

      if (material.length > 0) {
        const resource = { material, projId: needProjIds.join(','), purId: null, purFlag: '1' }
        const projNames = needProject.map((item) => `《${item.projName}-${item.projNo}》`).join('、\r\n')
        this.purUseReason = `因 ${projNames} 项目采样需要，进行申请`
        this.getData(Object.assign({}, resource, { searchType: 'cate' }))
      }
    },

    getData (dataJson) {
      // 根据id查询所有物资
      postDataAction(resMaterialApi.getDataByIds, { dataJson })
        .then((result) => {
          if (result.code === 0) {
            const d = result.data
            if (d.length === 0) {
              this.$message.error(`请选择领用物资!`)
              return
            }
            for (let i = 0; i < d.length; i++) {
              if (d[i].numOfUse === null || d[i].numOfUse === undefined) {
                this.$message.error(`请完善` + d[i].materialName + `物资使用数量!`)
                return
              }
            }

            const goodsArray = []
            for (let i = 0; i < d.length; i++) {
              const a = d[i]
              a.key = d[i].cateId + '_' + d[i].materialName + '_' + d[i].speType
              a.ids = ''
              goodsArray.push(a)
            }

            const dataMap = result.other
            const projId = dataMap.projId // 项目Id
            const purId = dataMap.purId // 领用ID
            const purFlag = dataMap.purFlag // 领用标识

            const nowDate = new Date()
            const date = {
              year: nowDate.getFullYear(),
              month: nowDate.getMonth() + 1,
              date: nowDate.getDate(),
              hour: nowDate.getHours(),
              min: nowDate.getMinutes(),
              second: nowDate.getSeconds()
            }
            const datetime =
              date.year + '' + date.month + '' + date.date + '' + date.hour + '' + date.min + '' + date.second
            const pNbr = `MX${datetime}LYSQ`

            const v = {
              purUseNbr: pNbr,
              purUseName: this.leader.realname + datetime + '领用申请流程',
              dateOfPurUse: moment().format('YYYY-MM-DD HH:mm:ss'),
              purUseReason: this.purUseReason,
              purUseDesc: '',
              projId,
              userName: this.leader.realname,
              userId: this.leader.username,
              id: null,
              goodsArray,
              purFlag,
              purUseStatus: 1,
              reagentFlag: false,
              purOutStatus: undefined,
              purReturnStatus: undefined,
              purInsertStatus: undefined,
              queryType: 1
            }
            this.autoMaterialApplicationSave(v)
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((error) => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
      // 保存数据的时候讲项目编号也存起来
    },

    // 自动进行物资出库申请
    autoMaterialApplicationSave (record) {
      postDataAction(resPurUseApi.add, record)
        .then((result) => {
          if (result.code === 0) {
            this.$message.success(`新建领用申请成功`)
            this.leader = {}
            this.detailTabsData = []
            this.resourceMap = {}
            this.purUseReason = ''
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((error) => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
    },

    getProcessCommonWords () {
      commDictApi.list({ dictCode: 'process_common_words' }).then((result) => {
        if (result.code === 0) {
          this.processCommonWords = result.data
        }
      })
    },
    previewFile (file) {
      window.open(sysCommonApi.preview + '?filePath=' + file.url, file.name, null, false)
    },
    handleFileChange ({ file, fileList }) {
      this.fileList = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.preview + '?filePath=' + file.response.data.fileUrl
          file.url = file.response.data.fileUrl
          fileList.forEach((item) => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },
    // 移除附件时，把上传到数据库中的附件删除
    delFile(file) {
      resInterCheckApi.deleteFile({ fileUrl: file.url })
    },
    formatAccessory (accessory) {
      const accessoryList = JSON.parse(accessory)
      if (accessoryList.length > 0) {
        return accessoryList[0].name
      }
      return ''
    },
    downloadFile (accessory) {
      const accessoryList = JSON.parse(accessory)
      if (accessoryList.length > 0) {
        window.location =
          sysCommonApi.downLoad +
          '?filePath=' +
          (accessoryList[0].url || accessoryList[0].fileUrl) +
          '&fileName=' +
          accessoryList[0].name +
          '&token=' +
          this.$store.getters.token
      }
    },
    toggleCommonLoading () {
      const self = this
      self.commonLoading = !self.commonLoading
    }
  }
}
</script>

<style lang="less" scoped>
.fpd /deep/ .ant-breadcrumb {
  display: none;
}

.fpd /deep/ .ant-pro-footer-toolbar {
  height: 115px;
  border-top: #1890ff 2px solid;
}

.process-load {
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload-list-item {
  margin-top: 0;
}
.next-button {
  position: absolute;
  right: 10px;
  top: 46%;
  z-index: 9;
  width: 46px;
  height: 46px;
  background: rgb(220, 220, 220, 0.6);
  color: #fff;
  border: none;
}
.per-button {
  position: absolute;
  left: 10px;
  top: 46%;
  z-index: 9;
  width: 46px;
  height: 46px;
  background: rgb(220, 220, 220, 0.6);
  color: #fff;
  border: none;
}
.next-button:hover,
.per-button:hover {
  background: #3d93fd;
  color: #fff;
}
</style>
<style>
.need-print {
  display: none;
}
</style>
