import { printApi } from '@/api/print'

let websocket = null

const printHeader = 'AHMUX_PRINTER:'

// 初始化webSocket
function print (sendData, config) {
  debugger
  if (websocket && websocket.readyState === WebSocket.OPEN) {
    websocket.send(sendData)
  } else {
    websocket = new WebSocket("ws://localhost:3000/v1/ws")
    websocket.onmessage = onMessage
    websocket.onopen = onOpen(sendData)
    websocket.onerror = onError
    websocket.onclose = onClose
  }
}

export const printHelper = async function (type, dataArr) {
  const typeMap = await printApi.sample()
  if (!typeMap[type]) {
    throw new Error(`对应的模板不存在，${type}`)
  }
  let template = printHeader + typeMap[type]
  const templateMatch = template.match(/%s/g)
  for (let i = 0; i < templateMatch.length; i++) {
    template = template.replace(templateMatch[i], dataArr[i])
  }
  print(template, typeMap)
}

export const printDevHelper = async function (type, dataArr) {
  const typeMap = await printApi.dev()
  if (!typeMap[type]) {
    throw new Error(`对应的模板不存在，${type}`)
  }
  let template = printHeader + typeMap[type]
  const templateMatch = template.match(/%s/g)
  for (let i = 0; i < templateMatch.length; i++) {
    template = template.replace(templateMatch[i], dataArr[i])
  }
  print(template, typeMap)
}

function onMessage (msg) {
  console.log(`webSocket接收的数据${msg}!`)
}

function onOpen (sendData) {
  console.log('webSocket连接建立成功!')
  setTimeout(() => {
    if (websocket.readyState === WebSocket.OPEN) {
      websocket.send(sendData)
    }
  }, 200)
}

function onError (e) {
  console.log(`webSocket异常!`, e)
  throw e
}

function onClose (e) {
  this.websocket = null
  console.log('webSocket关闭!')
}
