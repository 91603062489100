<template>
  <div class="check-standard-solution-type-form">
    <a-form v-bind="dataForm">
      <a-card v-bind="baseFormCard" title="基本信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="名称">
              <a-input v-decorator="decorators.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="配制浓度">
              <a-input v-decorator="decorators.purity"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="有效期">
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-input-number v-decorator="decorators.validDate" :min="0" :step="1" :style="{ width: '100%' }"></a-input-number>
                </a-col>

                <a-col :span="16">
                  <a-radio-group v-model="dataForm.data.validDateUnit">
                    <a-radio v-for="(value, key) of commonValidDateUnitDataMap" :key="key" :value="key">{{ value.label }}</a-radio>
                  </a-radio-group>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card v-bind="baseFormCard" title="配置信息">
        <a-row :gutter="24" class="title-row" :style="{ marginTop: 0 }">
          <a-col :span="10">
            <span class="title">使用液</span>
          </a-col>
        </a-row>
        <div class="content-row">
          <ul class="ul">
            <li class="li average">
              <div>
                <span class="title"> 上级液： </span>
                <span class="content">
                  <template v-if="dataForm.data.parentReagentCateId">
                    {{ dataForm.data.parentReagentCateName }}
                    <template v-if="checkSolutionTypeDataMap[dataForm.data.parentReagentCateSolutionType]">
                      <a-tag :color="checkSolutionTypeDataMap[dataForm.data.parentReagentCateSolutionType].color">
                        {{ checkSolutionTypeDataMap[dataForm.data.parentReagentCateSolutionType].label }}
                      </a-tag>
                    </template>
                    <span>
                      <a @click="checkReagentCateSelectModal.show()">
                        <a-icon type="form"></a-icon>
                      </a>
                    </span>
                  </template>
                  <template v-else>
                    <a @click="checkReagentCateSelectModal.show(dataForm.data)">请选择上级液</a>
                  </template>
                </span>
              </div>

              <div>
                <span class="title"> 浓度： </span>
                <span class="content">
                  {{ dataForm.data.parentReagentCatePurity }}
                </span>
              </div>

              <div>
                <span class="title"> 取样量： </span>
                <span class="content">
                  <a-tooltip>
                    <template #title>
                      此处取样量表示：
                      <span class="red">配置过程中使用多少上级液</span>
                    </template>
                    <a-input-number v-model="dataForm.data.volume" :min="0"></a-input-number>
                    {{ dataForm.data.parentReagentCateUnit || 'mL' }}
                  </a-tooltip>
                </span>
              </div>

              <div>
                <span class="title"> 定容体积： </span>
                <span class="content">
                  <a-input-number v-model="dataForm.data.constantVolume" :min="0"></a-input-number>
                  mL
                </span>
              </div>

              <div>
                <span class="title"> 稀释液： </span>
                <span class="content">
                  <template v-if="dataForm.data.solventId">
                    {{ dataForm.data.solventName }}
                    <span>
                      <a @click="checkReagentCateSelectForSolventModal.show(dataForm.data)">
                        <a-icon type="form"></a-icon>
                      </a>
                    </span>
                    <a-tooltip>
                      <template #title> 移除稀释液 </template>
                      <a style="margin-left: 10px; color: red" @click="deleteSolventInfo(dataForm.data)">
                        <a-icon type="delete"></a-icon>
                      </a>
                    </a-tooltip>
                  </template>
                  <template v-else>
                    <a @click="checkReagentCateSelectForSolventModal.show(dataForm.data)">请选择稀释液</a>
                  </template>
                </span>
              </div>

              <div>
                <span class="title"> 存储条件： </span>
                <span class="content">
                  <a-input v-model="dataForm.data.storageCondition" :style="{ width: '150px' }"></a-input>
                </span>
              </div>
            </li>
          </ul>
        </div>

        <template v-for="(item, index) in dataForm.data.detailList">
          <a-row :gutter="24" class="title-row" :style="{ marginTop: '32px' }" :key="`detail_title_${index}`">
            <a-col :span="24">
              <span class="title">
                {{ item.reagentCateName }}
                <span class="fr">
                  <a-tag :color="checkSolutionTypeDataMap[item.solutionType].color">
                    {{ checkSolutionTypeDataMap[item.solutionType].label }}
                  </a-tag>
                </span>
              </span>
            </a-col>
          </a-row>

          <div class="content-row" :key="`detail_content_${index}`">
            <ul class="ul">
              <li class="li average">
                <div v-if="item.parentReagentCateId">
                  <span class="title">
                    <template v-if="item.parentType !== reagentCateMaterialTypeEnum.cate"> 原料： </template>
                    <template v-else> 上级液： </template>
                  </span>
                  <span class="content">
                    {{ item.parentReagentCateName }}
                    <template v-if="checkSolutionTypeDataMap[item.parentReagentCateSolutionType]">
                      <a-tag :color="checkSolutionTypeDataMap[item.parentReagentCateSolutionType].color">
                        {{ checkSolutionTypeDataMap[item.parentReagentCateSolutionType].label }}
                      </a-tag>
                    </template>
                  </span>
                </div>

                <div>
                  <span class="title"> 浓度： </span>
                  <span class="content">
                    {{ item.parentReagentCatePurity }}
                  </span>
                </div>

                <div>
                  <span class="title"> 取样量： </span>
                  <span class="content">
                    <a-tooltip>
                      <template #title>
                        <span class="red">此处取样量是根据使用剂配置过程中计算得出</span>
                      </template>
                      <a-input-number v-model="item.volume" :min="0"></a-input-number>
                      {{ item.parentReagentCateUnit || 'mL' }}
                    </a-tooltip>
                  </span>
                </div>

                <div>
                  <span class="title"> 定容体积： </span>
                  <span class="content">
                    <a-tooltip>
                      <template #title>
                        <span class="red">此处定容体积是根据使用剂配置过程中计算得出</span>
                      </template>
                      <a-input-number v-model="item.constantVolume" :min="0"></a-input-number>
                      {{ item.parentReagentCateUnit || 'mL' }}
                    </a-tooltip>
                  </span>
                </div>

                <div>
                  <span class="title"> 稀释液： </span>
                  <span class="content">
                    <template v-if="item.solventId">
                      {{ item.solventName }}
                    </template>
                    <template v-else>
                      <a @click="checkReagentCateSelectForSolventModal.show(item)">请选择稀释液</a>
                    </template>
                  </span>
                </div>

                <div>
                  <span class="title"> 存储条件： </span>
                  <span class="content">
                    <template v-if="item.storageCondition">
                      {{ item.storageCondition }}
                    </template>
                    <template v-else>
                      <a-input v-model="item.storageCondition"></a-input>
                    </template>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </a-card>

      <a-card v-bind="baseFormCard" title="标定配置">
        <template #extra>
          <a-checkbox :checked="dataForm.demarcatesConfig.enable" @change="handleEnableChange">是否标定</a-checkbox>
        </template>
        <template v-if="dataForm.demarcatesConfig.enable">
          <a-row v-bind="rowProps">
            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="标定过程">
                <a-radio-group v-model="dataForm.demarcatesConfig.demarcated">
                  <a-radio :value="true">必做</a-radio>
                  <a-radio :value="false">非必做</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="基准溶液类型">
                <!--              <a-input v-model="dataForm.demarcatesConfig.referSolutionId"></a-input>-->
                <!--              请选择基准溶液-->
                <!--              <span slot="label" class="ant-form-item-required">请选择基准溶液类型</span>-->
                <span style="margin-right: 10px" v-if="dataForm.demarcatesConfig.referSolutionName">
                  <a-tag color="#00bea7" closable @close="changeSolutionType">{{ dataForm.demarcatesConfig.referSolutionName }}</a-tag>
                </span>
                <a-button type="primary" icon="plus" @click="solutionTypeSelectModal.show">基准溶液</a-button>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="标定类型">
                <a-radio-group v-model="dataForm.demarcatesConfig.type">
                  <a-radio value="0">平行标定</a-radio>
                  <a-radio value="1">K值标定</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <template v-if="dataForm.demarcatesConfig.type === '0'">
              <a-col v-bind="colProps">
                <a-form-item v-bind="formItemLayout" label="四平行极差比范围">
                  <a-input-number v-model="dataForm.demarcatesConfig.fourMinRangeRadio" :precision="2" :min="0"></a-input-number>
                  % ~
                  <a-input-number v-model="dataForm.demarcatesConfig.fourRangeRadio" :precision="2" :min="0"></a-input-number>
                  %
                </a-form-item>
              </a-col>

              <a-col v-bind="colProps">
                <a-form-item v-bind="formItemLayout" label="八平行极差比范围">
                  <a-input-number v-model="dataForm.demarcatesConfig.eightMinRangeRadio" :precision="2" :min="0"></a-input-number>
                  % ~
                  <a-input-number v-model="dataForm.demarcatesConfig.eightRangeRadio" :precision="2" :min="0"></a-input-number>
                  %
                </a-form-item>
              </a-col>
            </template>

            <a-col v-bind="colProps" v-if="dataForm.demarcatesConfig.type === '1'">
              <a-form-item v-bind="formItemLayout" label="K值范围">
                <a-input-number v-model="dataForm.demarcatesConfig.fourMinRangeRadio" :precision="2" :min="0"></a-input-number>
                ~
                <a-input-number v-model="dataForm.demarcatesConfig.fourRangeRadio" :precision="2" :min="0"></a-input-number>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps" v-if="dataForm.demarcatesConfig.type === '0'">
              <a-form-item v-bind="formItemLayout" label="是否空白">
                <a-radio-group v-model="dataForm.demarcatesConfig.blankStatus">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="被标定液体积">
                <a-input-number v-model="dataForm.demarcatesConfig.demarcateVolume" :min="0"></a-input-number>
                mL
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="方法依据">
                <a-select showSearch :filterOption="filterOption" v-bind="fullWidthStyle" v-model="standardMethodIds" placeholder="请选择标准方法">
                  <a-select-option
                    v-for="item in standardMethodList"
                    :title="getStandardMethodName(item)"
                    :value="item.standardId + '_' + item.methodId"
                    :key="item.standardId + '_' + item.methodId"
                    >{{ item.methodName + '(' + item.standardCode + ')' }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item v-bind="formItemLayout" label="计算公式">
                <a-input v-model="dataForm.demarcatesConfig.formula"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col>
              <a-form-item v-bind="formItemLayout" :labelCol="{ span: 0 }" :wrapperCol="{ span: 24 }">
                <a-textarea :autosize="{ minRows: 4, maxRows: 20 }" v-model="dataForm.demarcatesConfig.operation" placeholder="请简要描述操作过程"></a-textarea>
              </a-form-item>
            </a-col>
          </a-row>
        </template>
      </a-card>
    </a-form>

    <!-- 试剂类目选择 -->
    <a-modal v-bind="checkReagentCateSelectModal">
      <check-reagent-cate-select-and-query-table v-bind="checkReagentCateSelectModal.data" v-on="checkReagentCateSelectModal.tableAction" />
      <template #footer>
        <a-button @click="checkReagentCateSelectModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="checkReagentCateSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 稀释液选择 -->
    <a-modal v-bind="checkReagentCateSelectForSolventModal">
      <check-reagent-cate-select-and-query-table v-bind="checkReagentCateSelectForSolventModal.data" v-on="checkReagentCateSelectForSolventModal.tableAction" />
      <template #footer>
        <a-button @click="checkReagentCateSelectForSolventModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="checkReagentCateSelectForSolventModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 基准液选类型选择 -->
    <a-modal v-bind="solutionTypeSelectModal">
      <check-reagent-cate-select-and-query-table v-bind="solutionTypeSelectModal.data" v-on="solutionTypeSelectModal.tableAction" />
      <template #footer>
        <a-button @click="solutionTypeSelectModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="solutionTypeSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import mixinForm from '@/pages/common/mixins/form'
import { commonValidDateUnitDataMap } from '@/api/commonConstant'
import { checkSolutionTypeDataMap, checkSolutionTypeEnum } from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { checkStandardSolutionTypeApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'
import { CheckReagentCateSelectColumn as checkReagentCateColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { CheckReagentCateMaterialTable } from '@/pages/cloud-quote/check-reagent/cate/modules'
import { checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'
import { checkStandardSolutionDemarcatesConfigApi } from '@/api/checkStandardSolution/checkStandardSolutionDemarcatesApi'
import { throttle } from 'lodash'
import { Get, jcMethodApi } from '@/api/quote'

export default {
  mixins: [mixinForm],
  props: ['id'],
  components: { CheckReagentCateMaterialTable },

  data() {
    return {
      commonValidDateUnitDataMap,
      checkSolutionTypeEnum,
      reagentCateMaterialTypeEnum,
      checkSolutionTypeDataMap,

      loadDetail: false,

      standardMethodList: [],

      dataForm: {
        data: {
          id: null,
          validDateUnit: 'd',
          reagentCateId: null,
          reagentCateName: null,
          parentReagentCateId: null,
          parentReagentCateName: null,
          parentReagentCateUnit: null,
          solutionType: checkSolutionTypeEnum.use,
          parentReagentCateSolutionType: null,
          solventId: null,
          solventName: null,
          volume: null,
          constantVolume: null,
          storageCondition: null,
          detailList: []
        },

        demarcatesConfig: {
          id: null,
          standardId: null,
          methodId: null,
          formula: null,
          operation: null,
          demarcated: null,
          fourRangeRadio: 0.15,
          fourMinRangeRadio: null,
          eightRangeRadio: 0.18,
          eightMinRangeRadio: null,
          referSolutionId: null,
          referSolutionName: null,
          blankStatus: '1',
          demarcateVolume: null,
          enable: false,
          type: '0'
        }
      },

      decorators: {
        name: ['name', { rules: [{ required: true, message: '请输入标准使用液名称！' }] }],
        validDate: ['validDate', { rules: [{ required: true, message: '请输入有效期！' }] }],
        purity: ['purity', { rules: [{ required: true, message: '请输入浓度！' }] }]
      },

      // 贮备液
      stockSolutionList: [],

      // 中间液
      middleSolutionList: [],

      checkReagentCateSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择试剂种类',
        bodyStyle: { background: '#F0F2F5' },

        show: () => {
          this.checkReagentCateSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentCateSelectModal.visible = false
          },

          ok: () => {
            const selectDataList = this.$refs[this.checkReagentCateSelectModal.data.ref].getSelectData()
            if (!selectDataList || !selectDataList.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const data = this.dataForm.data
            const selectData = selectDataList[0]

            data.parentReagentCateId = selectData.id
            data.parentReagentCateName = selectData.name
            data.parentReagentCatePurity = selectData.purity

            this.checkReagentCateSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentCateSelectTable',
          solutionTypes: [checkSolutionTypeEnum.standard, checkSolutionTypeEnum.middle, checkSolutionTypeEnum.reserve],
          columns: checkReagentCateColumn,
          rowSelect: { type: 'radio' }
        },

        tableAction: {}
      },

      checkReagentCateSelectForSolventModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择稀释液',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' },

        show: (solution) => {
          this.checkReagentCateSelectForSolventModal.currentSolution = solution
          this.checkReagentCateSelectForSolventModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentCateSelectForSolventModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.checkReagentCateSelectForSolventModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const { id, name } = selectData[0]
            this.checkReagentCateSelectForSolventModal.currentSolution.solventId = id
            this.checkReagentCateSelectForSolventModal.currentSolution.solventName = name

            this.checkReagentCateSelectForSolventModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentCateSelectForSolventTable',
          solutionType: checkSolutionTypeEnum.solvent,
          columns: checkReagentCateColumn,
          rowSelect: { type: 'radio' }
        },

        tableAction: {}
      },

      solutionTypeSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '选择基准溶液类型',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' },
        data: {
          ref: 'solutionTypeSelectTable',
          solutionTypes: [
            checkSolutionTypeEnum.normal,
            checkSolutionTypeEnum.standard,
            checkSolutionTypeEnum.reserve,
            checkSolutionTypeEnum.middle,
            checkSolutionTypeEnum.solvent,
            checkSolutionTypeEnum.use
          ],
          columns: checkReagentCateColumn,
          // selectType: 'radio'
          rowSelect: { type: 'radio' }
        },
        show: () => {
          this.solutionTypeSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.solutionTypeSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.solutionTypeSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            // const { id, name, purity } = selectData[0]
            const { id, name } = selectData[0]
            this.dataForm.demarcatesConfig.referSolutionId = id
            this.dataForm.demarcatesConfig.referSolutionName = name
            // this.dataForm.data.solutionPurity = purity

            this.solutionTypeSelectModal.action.cancel()
          }
        },
        tableAction: {}
      }
    }
  },

  computed: {
    standardMethodIds: {
      get() {
        const { standardId, methodId } = this.dataForm.demarcatesConfig
        return standardId && methodId ? `${standardId}_${methodId}` : null
      },
      set(value) {
        const dataArr = value?.split('_') || []
        this.dataForm.demarcatesConfig.standardId = dataArr?.[0]
        this.dataForm.demarcatesConfig.methodId = dataArr?.[1]
      }
    }
  },

  watch: {
    id: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadDetail = true
          this.findDetailById(value)
          this.findDemarcatesConfigById(value)
        }
      }
    },
    'dataForm.data.parentReagentCateId': {
      immediate: true,
      handler(value) {
        value && !this.loadDetail && this.queryParentReagentCateInfo(value)
      }
    }
  },

  mounted() {
    this.loadStandMethodList()
    this.save = throttle(this.save, 6000, { leading: true, trailing: false })
  },

  methods: {
    changeSolutionType() {
      this.dataForm.demarcatesConfig.referSolutionId = null
      this.dataForm.demarcatesConfig.referSolutionName = null
    },
    // 加载标准方法
    loadStandMethodList() {
      Get(jcMethodApi.listByModel, {}).then((res) => {
        if (res.code === 0) {
          this.standardMethodList.splice(0, this.standardMethodList.length, ...(res.data || []))
        }
      })
    },

    // 显示方法名称
    getStandardMethodName(record) {
      return record.methodName + '(' + record.standardCode + ')'
    },

    // 获取对应标液类型配置明细
    findDetailById(id) {
      checkStandardSolutionTypeApi.findDetailById(id).then((res) => {
        if (res.code === 0) {
          Object.assign(this.dataForm.data, res.data)
          this.dataForm.form.$nextTick(() => {
            const decoratorKeys = Object.keys(this.decorators)
            const newData = Object.keys(res.data).reduce((acc, key) => {
              decoratorKeys.includes(key) && (acc[key] = res.data[key])
              return acc
            }, {})
            this.dataForm.form.setFieldsValue(newData)
            this.loadDetail = false
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 根据溶液类型ID 查询数据
    findDemarcatesConfigById(solutionTypeId) {
      checkStandardSolutionDemarcatesConfigApi.getDetailBySolutionTypeId(solutionTypeId).then((res) => {
        if (res.code === 0) {
          res.data && Object.assign(this.dataForm.demarcatesConfig, res.data)
        } else {
          this.$message.error(`:${res.msg}`)
        }
      })
    },

    // 获取使用液对应的上级液配置信息
    async queryParentReagentCateInfo(id) {
      const resData = await this.queryReagentCateInfo(id)
      const config = this.getConfigByReagentCateInfo(resData, this.dataForm.data)
      Object.assign(this.dataForm.data, config)
      this.dataForm.data.detailList.splice(0, this.dataForm.data.detailList.length)
      if (!this.dataForm.data.constantVolume) {
        const isSolutionTypes = [checkSolutionTypeEnum.middle, checkSolutionTypeEnum.reserve, checkSolutionTypeEnum.solvent]
        const parentRegentList = resData?.materialList?.filter((item) => isSolutionTypes.includes(item.solutionType))
        this.dataForm.data.constantVolume = parentRegentList?.reduce((acc, item) => acc + item.num, 0)
      }

      if (!this.dataForm.data.solventId) {
        const solventData = resData?.materialList?.find((item) => item.solutionType === checkSolutionTypeEnum.solvent)
        this.dataForm.data.solventId = solventData?.materialId
        this.dataForm.data.solventName = solventData?.materialName
      }

      if (!this.dataForm.data.name && !this.dataForm.form.getFieldValue('name')) {
        const isSolutionTypes = [checkSolutionTypeEnum.middle, checkSolutionTypeEnum.reserve]
        const reagentInfo = resData?.materialList?.find((item) => isSolutionTypes.includes(item.solutionType)) || resData
        const parentName = reagentInfo?.materialName || reagentInfo?.name
        if (parentName) {
          const name = parentName.replace('标准贮备液', '').replace('标准中间液', '').replace('贮备液', '').replace('中间液', '') + '标准使用液'
          this.dataForm.form.setFieldsValue({ name })
        }
      }
      this.getChildrenConfig(resData)
    },

    // 根据试剂获取对应的原料的配置信息
    getChildrenConfig(cateInfo) {
      const checkHasChildSolutionTypes = [checkSolutionTypeEnum.middle, checkSolutionTypeEnum.reserve]
      const hasChildrenDataArr = cateInfo.materialList?.filter((item) => checkHasChildSolutionTypes.includes(item.solutionType))
      hasChildrenDataArr.forEach(async (item) => {
        const reagentCateInfo = await this.queryReagentCateInfo(item.materialId)
        const childrenData = this.getConfigByReagentCateInfo(reagentCateInfo, cateInfo)
        this.dataForm.data.detailList.push(childrenData)
        this.getChildrenConfig(reagentCateInfo)
      })
      const materialInfo = cateInfo.materialList?.find((item) => item.type === 'material')
      if (materialInfo) {
        const childrenData = this.getConfigByMaterialInfo(materialInfo, cateInfo)
        this.dataForm.data.detailList.push(childrenData)
      }
    },

    // 根据试剂获取配置信息
    getConfigByReagentCateInfo(cateInfo, parent) {
      const isSolutionTypes = [checkSolutionTypeEnum.middle, checkSolutionTypeEnum.reserve, checkSolutionTypeEnum.solvent]
      const solventData = parent?.materialList?.find((item) => item.solutionType === checkSolutionTypeEnum.solvent)
      const cateMaterialInfo = parent?.materialList?.find((item) => item.materialId === cateInfo.id)
      const parentRegentList = parent?.materialList?.filter((item) => isSolutionTypes.includes(item.solutionType))
      return {
        parentType: 'cate',
        solventId: solventData?.materialId,
        solventName: solventData?.materialName,
        solutionType: parent?.solutionType,
        reagentCateId: parent?.id,
        reagentCateName: parent?.name,
        parentReagentCateId: cateInfo.id,
        parentReagentCateName: cateInfo.name,
        parentReagentCatePurity: cateInfo.purity,
        parentReagentCateUnit: cateInfo.unit,
        parentReagentCateSolutionType: cateInfo.solutionType,
        storageCondition: parent?.storageCondition,
        volume: cateMaterialInfo?.num,
        constantVolume: parentRegentList?.reduce((acc, item) => acc + item.num, 0)
      }
    },

    // 根据原料获取对应的配置
    getConfigByMaterialInfo(cateInfo, parent) {
      const isSolutionTypes = [checkSolutionTypeEnum.middle, checkSolutionTypeEnum.reserve, checkSolutionTypeEnum.solvent]
      const solventData = parent?.materialList?.find((item) => item.solutionType === checkSolutionTypeEnum.solvent)
      const cateMaterialInfo = parent?.materialList?.find((item) => item.materialId === cateInfo.materialId)
      const parentRegentList = parent?.materialList?.filter((item) => isSolutionTypes.includes(item.solutionType))
      return {
        parentType: 'material',
        solventId: solventData?.materialId,
        solventName: solventData?.materialName,
        solutionType: parent?.solutionType,
        reagentCateId: parent?.id,
        reagentCateName: parent?.name,
        parentReagentCateId: cateInfo.materialId,
        parentReagentCateName: cateInfo.materialName,
        parentReagentCatePurity: cateInfo.purity,
        parentReagentCateUnit: cateInfo.unit,
        parentReagentCateSolutionType: cateInfo.solutionType,
        storageCondition: parent?.storageCondition,
        volume: cateMaterialInfo?.num,
        constantVolume: parentRegentList?.reduce((acc, item) => acc + item.num, 0)
      }
    },

    queryReagentCateInfo(id) {
      return checkReagentCateApi.findById({ id }).then((res) => {
        return res.data
      })
    },
    handleEnableChange(e) {
      this.dataForm.demarcatesConfig.enable = e.target.checked
    },

    // 移除稀释液
    deleteSolventInfo(solution) {
      solution.solventId = null
      solution.solventName = null
    },

    save() {
      this.dataForm.form.validateFields((errors, values) => {
        if (!errors) {
          if (!this.dataForm.data.parentReagentCateId) {
            this.$message.error('请选择上级溶液！')
            return false
          }

          if (!this.dataForm.data.volume) {
            this.$message.error('请输入使用量！')
            return false
          }

          if (!this.dataForm.data.constantVolume) {
            this.$message.error('请选择定容量！')
            return false
          }
          const solutionTypeData = Object.assign({}, this.dataForm.data, values)
          if (this.dataForm.demarcatesConfig.enable) {
            if (!this.dataForm.demarcatesConfig.standardId) {
              this.$message.error('请选择标定时，使用的标准！')
              return false
            }

            if (!this.dataForm.demarcatesConfig.fourRangeRadio) {
              this.$message.error('请输入四平行极差比')
              return false
            }

            if (this.dataForm.demarcatesConfig.enable && !this.dataForm.demarcatesConfig.eightRangeRadio) {
              this.$message.error('请输入八平行极差比')
              return false
            }
          }
          delete solutionTypeData.parentReagentCatePurity
          solutionTypeData.demarcatesConfig = this.dataForm.demarcatesConfig
          checkStandardSolutionTypeApi.save(solutionTypeData).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
              this.$emit('error')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import './_index';
</style>
