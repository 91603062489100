<template>
  <div class="categoryTree">
    <a-card>
      <a-tree :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent" :tree-data="cateTreeList" @expand="onExpand" @select="selectCate"/>
    </a-card>
  </div>
</template>

<script>
import { itemCategoryApi } from '@/api/quote'

export default {
  name: 'CategoryTree',
  components: {},
  props: {
    projIdTemp: {
      type: Number
    }
  },

  computed: {},
  data () {
    return {
      autoExpandParent: true,
      queryParam: [],
      expandedKeys: [],
      cateTreeList: []
    }
  },
  mounted () {
  },
  created () {
    var self = this
    itemCategoryApi.treeList().then(result => {
      if (result.data.length > 0) {
        var cateTreeList = result.data
        self.buildTree(cateTreeList)
        self.cateTreeList = cateTreeList
      }
    })
  },
  methods: {
    onExpand (expandedKeys) {
      console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    selectCate (selectedKeys, e) {
      var self = this
      self.queryParam.cateType = null
      self.queryParam.cateId = null
      if (e.selected) {
        var selectedKey = selectedKeys[0]
        if (typeof (selectedKey) === 'string') {
          self.queryParam.cateType = selectedKey
        } else {
          self.queryParam.cateId = selectedKey
        }
      }
      this.$emit('categoryClick', self.queryParam)
    },
    buildTree (categoryList) {
      var self = this
      if (categoryList) {
        for (var idx in categoryList) {
          var cate = categoryList[idx]
          cate.title = cate.name
          var key = cate.id ? cate.id : cate.cateType
          cate.key = key
          if (!cate.id) {
            self.expandedKeys.push(key)
          }
          if (cate.children) {
            self.buildTree(cate.children)
          }
        }
      }
    }

  }

}
</script>
<style lang="less">

</style>
