<template>
  <a-modal v-bind="modal" v-on="modal">
    <!-- 查询区域 -->
    <a-card v-bind="searchCard" v-if="showSearch">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="编号" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="编号模糊查询" v-model.trim.lazy="queryParam.blurId"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="名称" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="名称模糊查询" v-model.trim.lazy="queryParam.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="配置日期" v-bind="formItemLayout">
              <a-range-picker :showTime="false" :style="{ width: '100%' }" @change="handleMakeDateChange" @ok="search" format="YYYY-MM-DD"></a-range-picker>
            </a-form-item>
          </a-col>

          <a-col :style="{ textAlign: 'left', marginBottom: '15px' }" v-bind="colProps">
            <a-button-group>
              <a-button @click="search" icon="search" type="primary">查询</a-button>
              <a-button @click="reset" icon="reload">重置</a-button>
            </a-button-group>
            <a @click="toggleSearchStatus = !toggleSearchStatus" style="margin-left: 8px">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
            </a>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps" v-show="toggleSearchStatus">
          <a-col v-bind="colProps">
            <a-form-item label="上级液编号" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="上级液编号模糊查询" v-model.trim.lazy="queryParam.blurFatherId"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="上级液名称" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="上级液名称模糊查询" v-model.trim.lazy="queryParam.fatherName"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="复核状态" v-bind="formItemLayout">
              <a-radio-group @change="search" v-model="queryParam.reviewStatus">
                <a-radio :key="key" :value="key" v-for="(value, key) of checkStandardCurveReviewStatusDataMap">{{ value.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps" v-show="toggleSearchStatus">
          <a-col v-bind="colProps">
            <a-form-item label="审核状态" v-bind="formItemLayout">
              <a-radio-group @change="search" v-model="queryParam.checkStatus">
                <a-radio :key="key" :value="key" v-for="(value, key) of checkStandardCurveCheckStatusDataMap">{{ value.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <div class="fr">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in curveTypeList" :key="item.standardMethodId" @click="makeFormModal.add(item)">
              {{ item.name }} {{ item.methodName }} （{{ item.standardCode }}）
            </a-menu-item>
          </a-menu>
          <a-button type="primary"> 曲线配置 <a-icon type="down" /> </a-button>
        </a-dropdown>
      </div>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <curve-table v-bind="curveTable" v-on="curveTable"></curve-table>
    </a-card>

    <!-- 曲线配置表单-->
    <a-modal v-bind="makeFormModal" v-if="makeFormModal.visible" v-on="makeFormModal">
      <check-standard-curve-make-form-mix
        v-if="methodItems && methodItems.length"
        v-bind="makeFormModal.data"
        v-on="makeFormModal.data"
        :methodItems="methodItems"
      ></check-standard-curve-make-form-mix>
      <check-standard-curve-make-form v-else v-bind="makeFormModal.data" v-on="makeFormModal.data"></check-standard-curve-make-form>
      <template #footer>
        <a-button @click="makeFormModal.cancel()">关闭</a-button>
        <a-button @click="makeFormModal.onOk()" type="primary">保存</a-button>
      </template>
    </a-modal>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import mixinList from '@/pages/common/mixins/list'

import curveTable from '@/pages/cloud-quote/check-standard-curve/modules/table'
import { checkStandardCurveConfigApi, checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import { CheckStandardCurveMakeForm, CheckStandardCurveMakeFormMix } from '@/pages/cloud-quote/check-standard-curve-type/modules'
import { itemCheckDetailCompositeApi } from '@/api/quote'

export default {
  mixins: [fullModal, mixinList],

  components: { curveTable, CheckStandardCurveMakeForm, CheckStandardCurveMakeFormMix },

  props: {
    selectType: {
      type: String,
      required: false,
      default: 'radio',
      validator(value) {
        return [null, '', undefined, 'radio', 'checkbox'].includes(value)
      }
    },

    showSearch: {
      type: Boolean,
      required: false,
      default: false
    },

    queryParam: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },

    modalType: {
      type: String,
      required: false,
      default: 'fullModal'
    },

    modalWidth: {
      type: [Number, String],
      required: false,
      default: 800
    },

    modalTitle: {
      type: String,
      required: false,
      default: '标线配置'
    },
    itemList: { type: Array, required: false, default: () => [] }
  },

  data() {
    return {
      selectedRows: [],
      curveTypeList: [],

      customTable: {
        ref: 'table',
        selectType: this.selectType,
        selectedRows: (data) => {
          const ids = data.map((item) => item.id).join(',')
          checkStandardCurveConfigApi.curveSite({ id: ids }).then((res) => {
            if (res.code === 0 && res.data.length > 0) {
              data.forEach((item) => {
                item.drawList = res.data
              })
            }
          })
          this.selectedRows.splice(0, this.selectedRows.length, ...(data || []))
        }
      },

      customModal: {
        visible: false,
        title: this.modalTitle,
        bodyStyle: { background: '#f0f2f5' },
        destroyOnClose: true,

        show: () => {
          this.customModal.visible = true
        },

        ok: () => {
          this.$emit('success', this.selectedRows)
          this.customModal.cancel()
        },

        cancel: () => {
          this.customModal.visible = false
          this.$emit('cancel')
        }
      },

      fullModal: {
        title: this.modalTitle,
        bodyStyle: { background: '#f0f2f5' },

        show: () => {
          this.fullModal.visible = true
        },

        ok: () => {
          this.$emit('success', this.selectedRows)
          this.fullModal.cancel()
        },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        }
      },
      makeFormModal: Object.assign(
        {},
        {
          title: '曲线配置',
          width: '60%',
          visible: false,
          data: {
            ref: 'makeForm',
            id: '',
            editType: null,
            // curveTypeId: null,
            curveRecord: null,
            typeRecord: null,
            success: () => {
              this.makeFormModal.cancel()
              this.configListReload()
            }
          },

          add: async (record) => {
            if (!record.id) {
              this.$message.error('未配置曲线类型')
              return
            }
            this.makeFormModal.data.editType = 'add'
            this.makeFormModal.data.id = ''
            this.makeFormModal.data.curveRecord = {}
            this.makeFormModal.data.typeRecord = record
            this.methodItems = await this.getMethodItems(record.methodId)
            this.makeFormModal.show()
          },

          show: () => {
            this.makeFormModal.visible = true
          },

          onOk: () => {
            this.$refs[this.makeFormModal.data.ref].save()
          },

          cancel: () => {
            this.makeFormModal.visible = false
          }
        }
      ),
      methodItems: []
    }
  },

  computed: {
    modal() {
      return this.modalType === 'fullModal' ? this.fullModal : this.customModal
    },

    curveTable: {
      get() {
        return { ...this.customTable, queryParam: this.queryParam }
      }
    }
  },

  methods: {
    searchCurveTypeList() {
      checkStandardCurveTypeApi.list(Object.assign({}, { page: false }, this.queryParam)).then((res) => {
        this.curveTypeList = res.data
      })
    },
    // 查询
    search() {
      this.$refs[this.customTable.ref].search()
    },

    // 重置
    reset() {
      this.$refs[this.customTable.ref].reset()
    },

    // 重载
    reload() {
      this.$refs[this.customTable.ref].reload()
    },

    // 显示组件
    show() {
      this.modal.show()
    },

    getMethodItems(methodId) {
      return itemCheckDetailCompositeApi.selectListByMetchId({ methodId }).then((res) => {
        if (res.code === 0 && res.data && res.data.length > 0) {
          let itemsIds = res.data.map((item) => item.itemId)
          let methodItems = this.itemList.filter((item2) => itemsIds.includes(item2.id))
          return methodItems
        } else {
          return []
        }
      })
    }
  },
  watch: {
    queryParam: {
      deep: true,
      immediate: false,
      handler(param) {
        if (
          this.queryParam.hasOwnProperty('standardIds') &&
          this.queryParam.standardIds.length > 0 &&
          this.queryParam.hasOwnProperty('methodIds') &&
          this.queryParam.methodIds.length > 0
        ) {
          this.searchCurveTypeList()
        }
      }
    }
  }
}
</script>
