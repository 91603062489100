import { Get, resdevCategoryApi, resPurUseApi } from '@/api/quote'

//cache in use
const projMap = {}
export const getProjectName = async projIds => {
  const projIdArr = projIds.split(',')
  const existProjIds = Object.keys(projMap)
  const notExistProjIds = projIdArr.filter(item => !existProjIds.includes(item))
  // 不存在的项目id 去后台查找对应的信息
  if (notExistProjIds?.length) {
    await Get(resPurUseApi.getProjSelect, { projIds: notExistProjIds.join(',') }).then(result => {
      result.data.forEach(({ id, projName, projNo }) => {
        // 保存到map中 以便下次使用 只存储必要的字段
        projMap[id] = { projNo, id, projName }
      })
    })
  }

  const projInfos = projIdArr.map(item => projMap[item])
  const projJoinName = projInfos.reduce((acc, item) => {
    acc += item.projNo + '_' + item.projName + '、'
    return acc
  }, '')
  return (projJoinName.endsWith('、') && projJoinName.substring(0, projJoinName.length - 1)) || projJoinName
}

//cache in use
const cateMap = {}
export const getCateName = async cateIds => {
  const cateIdArr = cateIds.split(',')
  const existCateIds = Object.keys(cateMap)
  const notExistCateIds = cateIdArr.filter(item => !existCateIds.includes(item))
  // 不存在的项目id 去后台查找对应的信息
  if (notExistCateIds?.length) {
    await Get(resdevCategoryApi.selectList, { pageSize: 500 }).then(result => {
      result.data.forEach(({ id, name }) => {
        // 保存到map中 以便下次使用 只存储必要的字段
        cateMap[id] = name
      })
    })
  }

  return cateIdArr.map(item => cateMap[item]).join('-')
}
