'use strict'

const defaultColumn = [
  { title: '物资图片', dataIndex: 'materialImg', width: '80px', scopedSlots: { customRender: 'materialImg' } },
  { title: '物资名称', dataIndex: 'materialName', width: '260px', scopedSlots: { customRender: 'materialName' } },
  { title: '物资类别', dataIndex: 'cateId', width: '260px', scopedSlots: { customRender: 'cateId' } },
  { title: '类目名称', dataIndex: 'cateName', width: '240px', scopedSlots: { customRender: 'cateName' } },
  { title: '类目类型', dataIndex: 'cateType', width: '160px', scopedSlots: { customRender: 'cateType' } },
  { title: '品牌', dataIndex: 'brand', width: '160px', scopedSlots: { customRender: 'brand' } },
  { title: '规格型号', dataIndex: 'speType', width: '160px', scopedSlots: { customRender: 'speType' } },
  { title: '单位', dataIndex: 'unit', width: '160px', scopedSlots: { customRender: 'unit' } },
  // { title: '放置位置', dataIndex: 'locationId', width: '160px', scopedSlots: { customRender: 'locationId' } },
  { title: '存储条件', dataIndex: 'storageCondition', width: '160px', scopedSlots: { customRender: 'storageCondition' } },
  { title: '操作', dataIndex: 'action', width: '160px',scopedSlots: { customRender: 'action' } }
]

const defaultSelectColumns = [
  { title: '物资图片', dataIndex: 'materialImg', width: '80px', scopedSlots: { customRender: 'materialImg' } },
  { title: '物资名称', dataIndex: 'materialName', width: '260px', scopedSlots: { customRender: 'materialName' } },
  { title: '类目名称', dataIndex: 'cateName', width: '240px', scopedSlots: { customRender: 'cateName' } },
  { title: '类目类型', dataIndex: 'cateType', width: '160px', scopedSlots: { customRender: 'cateType' } },
  { title: '品牌', dataIndex: 'brand', width: '160px', scopedSlots: { customRender: 'brand' } },
  { title: '规格型号', dataIndex: 'speType', width: '160px', scopedSlots: { customRender: 'speType' } },
  { title: '浓度', dataIndex: 'purity', width: '120px', scopedSlots: { customRender: 'purity' } },
  { title: '单位', dataIndex: 'unit', width: '120px', scopedSlots: { customRender: 'unit' } },
  // { title: '放置位置', dataIndex: 'locationId', width: '160px', scopedSlots: { customRender: 'locationId' } },
  { title: '存储条件', dataIndex: 'storageCondition', width: '120px', scopedSlots: { customRender: 'storageCondition' } },
  { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } }
]

const selectColumns = [
  { title: '物资图片', dataIndex: 'materialImg', width: '80px', scopedSlots: { customRender: 'materialImg' } },
  { title: '物资名称', dataIndex: 'materialName', width: '260px', scopedSlots: { customRender: 'materialName' } },
  { title: '类目名称', dataIndex: 'cateName', width: '240px', scopedSlots: { customRender: 'cateName' } },
  { title: '类目类型', dataIndex: 'cateType', width: '160px', scopedSlots: { customRender: 'cateType' } },
  { title: '品牌', dataIndex: 'brand', width: '160px', scopedSlots: { customRender: 'brand' } },
  { title: '规格型号', dataIndex: 'speType', width: '160px', scopedSlots: { customRender: 'speType' } },
  { title: '浓度', dataIndex: 'purity', width: '120px', scopedSlots: { customRender: 'purity' } },
  { title: '单位', dataIndex: 'unit', width: '120px', scopedSlots: { customRender: 'unit' } },
  // { title: '放置位置', dataIndex: 'locationId', width: '160px', scopedSlots: { customRender: 'locationId' } },
  { title: '存储条件', dataIndex: 'storageCondition', width: '120px', scopedSlots: { customRender: 'storageCondition' } }
]

export default defaultColumn
export {
  defaultSelectColumns as materialSingleSelectColumn,
  defaultColumn as materialSingleDefaultColumn,
  selectColumns
}
