<template>
  <div :style="{width:this.width}" class="check-reagent-cate-material-table">

    <customize-column v-model="contentTable.columns" cache-key-pre="check-reagent-cate-material-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>

    <s-table v-bind="contentTable" :scroll="scrollProp">
      <template #type="data">
        <a-tag :color="reagentCateMaterialTypeDataMap[data].color">{{ reagentCateMaterialTypeDataMap[data].label }}</a-tag>
      </template>

      <template #solutionType="data">
        <template v-if="checkSolutionTypeDataMap[data]">
          <a-tag :color="checkSolutionTypeDataMap[data].color">{{ checkSolutionTypeDataMap[data].label }}</a-tag>
        </template>
      </template>

      <template #num="data,record">
        <span style="color:green;font-weight: 600;">{{ data }}</span>
        <span :style="{marginLeft:'5px'}">{{ record.unit }}</span>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index">
        <slot :name="slot" v-bind="{text:text,row:record,index:index}"/>
      </template>

    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { reagentCateMaterialTypeDataMap } from '@/api/checkReagent/checkReagentConstant'
import { checkReagentCateMaterialApi } from '@/api/checkReagent/checkReagentApi'
import { defaultColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/material/columns'
import { checkSolutionTypeDataMap } from '@/api/checkStandardSolution/checkStandardSolutionConstant'

import { CustomizeColumn } from '@/components/Columns'

export default {
  components: { sTable, CustomizeColumn },
  mixins: [componentTable],

  props: {
    width: { type: [String, Number], required: false, default: '100%' },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    pagination: { type: Boolean, required: false, default: false },
    columns: { type: Array, required: false, default: () => (defaultColumn) }
  },

  data () {
    return {

      reagentCateMaterialTypeDataMap,
      checkSolutionTypeDataMap,

      currentDataSource: [],
      scrollProp: { x: '100%', y: document.documentElement.clientHeight - 445 },
      contentTable: {
        columns: this.columns,
        bordered: true,
        style: { width: '100%' }
      }
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      return checkReagentCateMaterialApi.list(Object.assign({}, param, this.queryParam)).then(res => {
        this.currentDataSource.splice(0, this.currentDataSource.length, ...(res.data || []))
        return res
      })
    },

    getData () {
      return this.currentDataSource
    }
  }

}
</script>

<style lang="less" scoped>
.check-reagent-cate-material-table{
  position: relative;

  .setting {
    position: absolute;
    z-index: 9;
    right: 12px;
    top: 9px;
  }

}
</style>
