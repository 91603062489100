import Vue from 'vue'
import VueStorage from 'vue-ls'
import config from '@/config/defaultSettings'

import 'element-ui/lib/theme-chalk/index.css'
import 'ahmux-form-designing/dist/AhmuxFormDesigning.css'
import 'v-contextmenu/dist/index.css'

import AhmuxFormDesigning from 'ahmux-form-designing'
import ContentMenu from 'v-contextmenu'
import Element from 'element-ui'

import Viser from 'viser-vue'
import VueCropper from 'vue-cropper'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import VueClipboard from 'vue-clipboard2'
import PermissionHelper from '@/utils/helper/permission'
import './directives/action'

Vue.use(Element, { size: 'mini' })
Vue.use(AhmuxFormDesigning)
Vue.use(Antd)
Vue.use(ContentMenu)

Vue.use(Viser)

VueClipboard.config.autoSetContainer = true

Vue.use(VueStorage, config.storageOptions)
Vue.use(VueClipboard)
Vue.use(PermissionHelper)
Vue.use(VueCropper)
