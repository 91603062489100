import Vue from 'vue'
import router from './router'
import store from './store'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import notification from 'ant-design-vue/es/notification'
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['login', 'register', 'registerResult', 'plan-quote-view', 'wechat', 'quote-download-preview', 'take-sample-record', 'proj-progress-details', 'system-documents', 'report-file-preview'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  const token = to.query.AccessToken
  if (token) {
    Vue.ls.set(ACCESS_TOKEN, token)
  }
  NProgress.start() // start progress bar
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))

  if (!Vue.ls.get(ACCESS_TOKEN)) {
    const lastOperationTime = Vue.ls.get('lastOperation')
    let expireAt = 60 * 1000 * 30
    if (Vue.ls.get('expireAt')) {
      expireAt = Vue.ls.get('expireAt')
    }
    let time = 60 * 1000 * 30
    if (lastOperationTime != null) {
      time = new Date().getTime() - lastOperationTime
    }
    const flag = time < expireAt
    const rememberMe = Vue.ls.get('rememberMe')
    if ((rememberMe && rememberMe === '1') || flag) {
      await store.dispatch('FreshToken')
    }
  }
  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    if (to.path === '/user/login') {
      next({ path: '/dashboard/workplace' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        store.dispatch('Personal')
        store.dispatch('FlowCustom')
        store.dispatch('LoginUserInfo').then(res => {
          store.dispatch('GenerateRoutes', { res }).then(() => {
            // 根据roles权限生成可访问的路由表
            // 动态添加可访问路由表
            router.addRoutes(store.getters.addRouters)
            const redirect = decodeURIComponent(from.query.redirect || to.path)
            if (to.path === redirect) {
              // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true })
            } else {
              // 跳转到目的路由
              next({ path: redirect })
            }
          })
        }).catch((res) => {
          notification.error({
            message: '错误',
            description: res.message
          })
          store.dispatch('Logout').then(() => {
            NProgress.done()
            next({ path: '/user/login', query: { redirect: to.fullPath } })
          })
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/user/login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
