'use strict'

/**
 * 样品作废管理API
 */

import { getAction, postDataAction, downloadGetAction } from '@/api/common'

const sampleInfoDeletePre = '/ms-api/ts-sampleinfo-delete'

export const sampleInfoDeleteApi = {
  list: (param) => getAction(`${sampleInfoDeletePre}/list`, param),
  download: (param) => downloadGetAction(`${sampleInfoDeletePre}/download`, param),
  apply: (data) => postDataAction(`${sampleInfoDeletePre}/apply`, data),
  findDetail: (param) => getAction(`${sampleInfoDeletePre}/find-detail`, param)
}
