<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          样品出库
        </div>
      </a-col>
      <a-col span="7"></a-col>
      <a-col span="7"></a-col>
      <a-col span="7"></a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="4">出库信息</th>
          <td class="tdLabel">出库人</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtFinalAmount)}`">
              {{ userInfo.realname }}
            </div>
          </td>
          <td class="tdLabel">出库日期</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtTransferDate)}`">
              <JDate style="width: 100%" v-model="subtask.sbtTransferDate" placeholder="出库日期"></JDate>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="tdLabel">接收人</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtReceiver)}`">
              <!--              <a-input v-model="subtask.sbtReceiver" :title="subtask.sbtReceiver" placeholder="接收人"/>-->
              <a-select
                v-model="subtask.sbtReceiver"
                style="width: 100%"
                showSearch
                placeholder="接收人"
                optionFilterProp="children"
                @change="receiverChange"
                :filterOption="filterOption">
                <a-icon type="plus-square" @click="showAddReceiver" slot="suffixIcon"/>
                <a-select-option v-for="vo in this.sbtReceiverList" :key="vo.sctruName" :data="vo">{{ vo.sctruName }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系电话</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtReceiverPhone)}`">
              <a-input v-model="subtask.sbtReceiverPhone" :title="subtask.sbtReceiverPhone" placeholder="联系电话"/>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="tdLabel">移交方式</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtTransferMode)}`">
              <a-select v-model="subtask.sbtTransferMode" style="width: 100%">
                <a-select-option v-for="(v, k) in sbtTransferModeMap" :key="k" :value="k">{{ v }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">快递公司</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtExpressCompany)}`">
              <a-select
                v-model="subtask.sbtExpressCompany"
                style="width: 100%"
                showSearch
                placeholder="快递公司"
                :allowClear="true"
                :filterOption="filterOption">
                <a-select-option v-for="(v, k) in expressCompanyMap" :key="k" :value="v">{{ v }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">快递单号</td>
          <td>
            <div :class="`required ${notNull(subtask.sbtExpressNumber)}`">
              <a-input v-model="subtask.sbtExpressNumber" placeholder="快递单号"/>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">附件信息</td>
          <td colspan="5">
            <UploadFile
              :fileList="subtask.sbtExpressImg ? JSON.parse(subtask.sbtExpressImg) : []"
              @ok="uploadFileOK"
              list-type="picture-card"
              accept="image/*"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3" v-if="!successOutBound">
            <a-button style="width: 100%; margin-top: 3px; margin-bottom: 3px" icon="save" @click="outBound">样品出库</a-button>
          </td>
          <td :colspan="successOutBound?7:4">
            <a-spin :spinning="btnSpinning">
              <a-button style="width: 100%; margin-top: 3px; margin-bottom: 3px" type="primary" icon="save" @click="save">保存</a-button>
            </a-spin>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <div style="color: red">说明：1、录入并确认样品移交信息；2、点击保存按钮进行保存移交信息；3、然后进行流程确认操作；
            </div>
          </td>
        </tr>
      </table>
    </a-row>
    <subUserForm ref="subUserForm" @ok="addReceiverRes"></subUserForm>
    <!-- 出库 -->
    <outbound-sample-form v-if="outboundFlag" ref="outboundForm" @cancel="outboundFlag=false" @success="successOutBound=true"/>
  </div>
</template>

<script>
import { projSubcontractTaskApi, subcontractorUserApi } from '@/api/project'
// import UploadFile from '@/pages/common/UploadFile'
import UploadFile from '@/pages/common/upload/UploadFile'
import JDate from '@/components/j/JDate'
import subUserForm from '@/pages/cloud-quote/subcontract/subcontractor-user/SubUserForm'
import OutboundSampleForm from '@/pages/cloud-quote/sample-info/modules/OutboundSampleForm'

export default {
  name: 'SubTaskApplySampleForm',
  components: {
    JDate,
    UploadFile,
    subUserForm,
    OutboundSampleForm
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      btnSpinning: false,
      userInfo: {},
      sbtReceiverList: [],
      outboundFlag: false,
      sampleList: [],
      successOutBound: false
    }
  },
  methods: {
    save () {
      const self = this
      const formData = Object.assign({}, self.subtask)
      if (self.subtask.cateIdItemIdList) {
        formData.cateItemIds = self.subtask.cateIdItemIdList.join(',')
      }
      formData.sbtTransferor = this.userInfo.realname
      delete formData.subItemList
      delete formData.cateIdItemIdList
      delete formData.fileList
      delete formData.fileReportList
      delete formData.fileQuoteList
      this.btnSpinning = true
      projSubcontractTaskApi.edit(formData).then(result => {
        if (result.code === 0) {
          this.$message.success(`${formData.sbtTitle}操作成功`)
          this.$emit('ok')
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      }).finally(() => {
        this.btnSpinning = false
      })
    },
    listReceiver () {
      subcontractorUserApi.list({ sctrId: this.subtask.subcontractor, page: false }).then(res => {
        if (res.code === 0) {
          this.sbtReceiverList = res.data
        }
      })
    },
    showAddReceiver () {
      if (this.subtask.subcontractor) {
        this.$refs.subUserForm.addBySctrId(this.subtask.subcontractor)
      } else {
        this.$message.warning(`请先选择分包机构`)
      }
    },
    addReceiverRes (record) {
      this.sbtReceiverList.push(record)
      if (record) {
        this.$set(this.subtask, 'sbtReceiver', record.sctruName)
        this.$set(this.subtask, 'sbtReceiverPhone', record.sctruPhone)
      }
    },
    receiverChange (value, op) {
      const self = this
      const obj = op.data.attrs.data
      if (obj) {
        self.$set(self.subtask, 'sbtReceiver', obj.sctruName)
        self.$set(self.subtask, 'sbtReceiverPhone', obj.sctruPhone)
      }
    },
    uploadFileOK (obj) {
      this.$set(this.subtask, 'sbtExpressImg', obj.newFileListStr)
      // this.$set(this.subtask, 'delFileIdsStr', obj.delFileIdsStr)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },

    searchSampleList (taskId) {
      projSubcontractTaskApi.listSample({ taskId }).then(res => {
        if (res.code === 0) {
          this.sampleList = res.data || []
        }
      })
    },
    outBound () {
      if (!this.sampleList.length) {
        this.$message.error('暂无需要出库的样品！')
        return
      }
      const sampleList = this.sampleList.map(item => ({ id: item.sampleId }))
      this.outboundFlag = true
      this.$nextTick(() => {
        this.$refs.outboundForm.init(sampleList)
      })
    }
  },
  watch: {
    'subtask.id': {
      handler (nv, ov) {
        if (nv) {
          this.listReceiver()
          this.searchSampleList(nv)
        }
      },
      immediate: true
    }
  },
  computed: {
    expressCompanyMap () {
      return { ...this.$store.state.code.cache.expressCompany }
    },
    sbtTransferModeMap () {
      return { ...this.$store.state.code.cache.sbtTransferMode }
    }
  },
  mounted () {
    const userInfo = this.$store.getters.userInfo
    this.userInfo = { username: userInfo.username, realname: userInfo.realname }
  },
  created () {
    this.$store.dispatch('code/select', 'expressCompany')
    this.$store.dispatch('code/select', 'sbtTransferMode')
  }
}
</script>
<style lang="less">
.mxtable {
  width: 100%;
  border: 2px solid #1890FF;
  border-top: 5px solid #1890FF;
  border-collapse: unset;
  margin-top: -1px;

  .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
    background-color: #FEF0D0;
  }

  th {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px dashed #909399;
  }

  td {
    padding: 5px;
    /*min-width: 12%;*/
    /*max-width: 22%;*/
    width: 125px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }

  .tdLabel {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
}
</style>
<style lang="less" scoped></style>
