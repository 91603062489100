'use strict'

const noActionColumn = [
  { title: '批次号', dataIndex: 'batchNo', sorter: true, width: 160, align: 'left', scopedSlots: { customRender: 'batchNo' } },
  { title: '标题', dataIndex: 'title', sorter: true, width: 250, align: 'left', scopedSlots: { customRender: 'title' } },
  { title: '所属项目', dataIndex: 'projName', sorter: true, width: 250, align: 'left', scopedSlots: { customRender: 'projName' } },
  { title: '申请人', dataIndex: 'applyUserName', sorter: true, width: 100, align: 'left', scopedSlots: { customRender: 'applyUserName' } },
  { title: '申请时间', dataIndex: 'applyTime', sorter: true, width: 160, align: 'left', scopedSlots: { customRender: 'applyTime' } },
  { title: '样品情况', dataIndex: 'sampleStatus', width: 200, align: 'left', scopedSlots: { customRender: 'sampleStatus' } },
  { title: '处理人', dataIndex: 'dealUserName', sorter: true, width: 100, align: 'left', scopedSlots: { customRender: 'dealUserName' } },
  { title: '处理时间', dataIndex: 'dealTime', sorter: true, width: 160, align: 'left', scopedSlots: { customRender: 'dealTime' } },
  { title: '状态', dataIndex: 'status', sorter: true, width: 100, align: 'left', scopedSlots: { customRender: 'status' } }
]

const noActionCheckColumn = [
  { title: '批次号', dataIndex: 'batchNo', sorter: true, width: 160, align: 'left', scopedSlots: { customRender: 'batchNo' } },
  { title: '标题', dataIndex: 'title', sorter: true, width: 250, align: 'left', scopedSlots: { customRender: 'title' } },
  { title: '所属任务', dataIndex: 'projName', sorter: true, width: 200, align: 'left', scopedSlots: { customRender: 'projName' } },
  { title: '申请人', dataIndex: 'applyUserName', sorter: true, width: 100, align: 'left', scopedSlots: { customRender: 'applyUserName' } },
  { title: '申请时间', dataIndex: 'applyTime', sorter: true, width: 160, align: 'left', scopedSlots: { customRender: 'applyTime' } },
  { title: '样品情况', dataIndex: 'sampleStatus', width: 150, align: 'left', scopedSlots: { customRender: 'sampleStatus' } },
  { title: '收样时间', dataIndex: 'storageTime', width: 150, align: 'left', scopedSlots: { customRender: 'storageTime' } },
  { title: '申请状态', dataIndex: 'status', sorter: true, width: 100, align: 'left', scopedSlots: { customRender: 'status' } },
  { title: '安排状态', dataIndex: 'scheduleStatus', width: 100, align: 'left', scopedSlots: { customRender: 'scheduleStatus' } }
]

const defaultColumn = [...noActionColumn,
  { title: '操作', dataIndex: 'action', width: 280, align: 'left', scopedSlots: { customRender: 'action' } }
]

const defaultCheckColumn = [...noActionCheckColumn,
  { title: '操作', dataIndex: 'action', width: 250, align: 'left', scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as sampleStorageApplyDefaultColumn,
  noActionColumn as sampleStorageApplyNoActionColumn,
  defaultCheckColumn as sampleStorageApplyCheckDefaultColumn
}

export default defaultColumn
