<template>
  <a-card title="方案信息">
    <div>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="4">基础信息</th>
          <td class="tdLabel">方案名称</td>
          <td colspan="3">
            {{ quoteInfo.projectTitle }}
          </td>
          <td class="tdLabel">单号</td>
          <td>
            {{ quoteInfo.quoteSn }}
          </td>
        </tr>
        <tr>
          <td class="tdLabel">商机名称</td>
          <td colspan="3">
            {{ quoteInfo.busTitle }}
          </td>
          <td class="tdLabel">商机来源</td>
          <td>
            {{ businessSourceMap[quoteInfo.busSource] }}
          </td>
        </tr>
        <tr>
          <td class="tdLabel">监测类型</td>
          <td>{{ quoteTypeMap[quoteInfo.quoteType] }}</td>
          <td class="tdLabel">样品来源</td>
          <td>{{ sampleSourceMap[quoteInfo.sampleSource] }}</td>
          <td class="tdLabel">报告类型</td>
          <td> {{ reportTypeMap[quoteInfo.reportType] }}</td>
        </tr>
        <tr>
          <td class="tdLabel">负责人</td>
          <td>{{ quoteInfo.quoteContactsName }}</td>
          <td class="tdLabel">联系电话</td>
          <td>{{ quoteInfo.quoteContactsPhone }}</td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <th rowspan="3">客户信息</th>
          <td class="tdLabel">委托单位</td>
          <td>{{ quoteInfo.entrustCustomerName }}</td>
          <td class="tdLabel">联系人</td>
          <td>{{ quoteInfo.entrustContactsName }}</td>
          <td class="tdLabel">联系电话</td>
          <td>{{ quoteInfo.entrustContactsPhone }}</td>
        </tr>
        <tr>
          <td class="tdLabel">受检单位</td>
          <td>{{ quoteInfo.customerName }}</td>
          <td class="tdLabel">联系人</td>
          <td>{{ quoteInfo.customerContactsName }}</td>
          <td class="tdLabel">联系电话</td>
          <td>{{ quoteInfo.customerContactsPhone }}
          </td></tr>
        <tr>
          <td class="tdLabel">地址</td>
          <td colspan="3"> {{ quoteInfo.projWholeAddress }} </td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <th rowspan="2">其他</th>
          <td class="tdLabel">附件</td>
          <td colspan="5">
            <FileList :fileList="quoteInfo.fileList"/>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">描述</td>
          <td colspan="5"> {{ quoteInfo.projectDesc }} </td>
        </tr>
        <tr v-if="isQuoteBack">
          <th>退回原因</th>
          <td colspan="6">{{ quoteInfo.goBackMsg }}</td>
        </tr>
      </table>
    </div>
  </a-card>
</template>

<script>
import PlanContentPreview from './PlanContentPreview'
import FileList from '@/pages/common/file/FileList'
import ARow from 'ant-design-vue/es/grid/Row'
import ACol from 'ant-design-vue/es/grid/Col'

export default {
  name: 'PlanPreview',
  components: {
    ACol,
    ARow,
    FileList,
    PlanContentPreview
  },
  props: {
    quoteInfo: {
      type: Object,
      default: () => ({})
    },
    userList: {
      type: Array,
      default: () => ([])
    },
    customerList: {
      type: Array,
      default: () => ([])
    },
    inFileList: {
      type: Array,
      default: () => ([])
    },
    editable: {
      type: Boolean,
      default: false
    },
    addr: {
      type: Object,
      default: () => ({})
    },
    isQuoteBack: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

      // 客户单位信息
      customerUserList: [],

      fetching: false,
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      }
    }
  },
  methods: {
    notNull (v) {
      return v ? '' : 'has-error'
    }
  },
  watch: {
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    },
    businessSourceMap () {
      return { ...this.$store.state.code.cache.business_source }
    }
  },
  mounted () {
  },
  created () {
    this.$store.dispatch('code/select', 'business_source')
  }
}
</script>
<style  lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
