
<template>
  <div style="background-color: red">

    <a-modal
      @ok="handleSubmit"
      @cancel.prevent="cancel"
      :centered="true"
      :closable="false"
      :title="title"
      v-model="visible"
      width="60%"
      wrapClassName="modal-container"
      :destroyOnClose="true"
    >  <a-form :form="form" >

         <a-row class="form-row" :gutter="16">
           <div style="width: 95%;">
             <a-card :bordered="true" title="基本信息">
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   label="物资类目"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-tree-select
                     :treeData="categoryList"
                     placeholder="类目分类"
                     :treeExpandedKeys="treeExpandedKeys"
                     v-decorator="[
                       'cateId',
                       {rules: [{ required: true, message: '请选择类目分类'}]}
                     ]"
                   />
                 </a-form-item>
               </a-col>
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   label="类目类型"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-select
                     :disabled="cateTypeDis"
                     style="width: 100%;"
                     v-decorator="['cateType', {rules: [{required: true,message:'请选择类目类型'}]}]"
                   >
                     <a-select-option :key="1">普通物资</a-select-option>
                     <a-select-option :key="2">危化物资</a-select-option>
                     <a-select-option :key="3">设备</a-select-option>
                   </a-select>
                 </a-form-item>
               </a-col>
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   label="类目名称"
                   :labelCol="labelCol"

                   :wrapperCol="wrapperCol"
                 >
                   <a-input v-decorator="['materialName', {rules: [{required: true, min: 1, message: '请输入物资名称！'}]}]" />
                 </a-form-item>
               </a-col>
               <a-col :lg="12" :md="16" :sm="24">

                 <a-form-item
                   label="规格型号"

                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-input v-decorator="['speType', {rules: [{required: true,message:'请输入规格型号'}]}]" />
                 </a-form-item>
               </a-col>
               <!-- <a-col :lg="12" :md="16" :sm="24">

          <a-form-item
            label="浓度"

            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input    v-decorator="['purity', {rules: [{required: false,message:'请输入浓度'}]}]" />
          </a-form-item>
        </a-col>-->
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   label="存放地点"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-tree-select
                     :treeData="locationList"
                     placeholder="选择地点"
                     v-decorator="[
                       'locationId',
                       {rules: [{ required: true, message: '请选择地点'}]}
                     ]"
                   />
                 </a-form-item>
               </a-col>
               <!--  <a-col :lg="12" :md="8" :sm="24">
          <a-form-item
            label="品牌"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input   v-decorator="['brand', {rules: [{required: false}]}]" />
          </a-form-item>
        </a-col>-->
               <!--   <a-col :lg="12" :md="16" :sm="24">
          <a-form-item
            label="保存条件"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input   v-decorator="['storageCondition', {rules: [{required: false}]}]" />
          </a-form-item>
        </a-col>-->
               <a-col :lg="12" :md="8" :sm="24">
                 <a-form-item
                   label="单位"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-select
                     style="width: 100%;"
                     v-decorator="['unit', {rules: [{required: false}]}]"
                   >
                     <a-select-option v-for="vo in dictOptions" :key="vo.itemValue">{{ vo.itemText }}</a-select-option>
                   </a-select>
                 </a-form-item>
               </a-col>

               <a-col :lg="8" :md="16" :sm="24">
                 <a-form-item
                   style="left:18%"
                   label="消耗品"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-radio-group v-decorator="['cmsOrNot']">
                     <a-radio value="1">
                       是
                     </a-radio>
                     <a-radio value="0">
                       否
                     </a-radio>
                   </a-radio-group>
                 </a-form-item>
               </a-col>

               <a-col :lg="8" :md="16" :sm="24">
                 <a-form-item
                   label="前置天数"
                   style="left:2%"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-input-number :min="0" v-decorator="['preDays', {rules: [{required: false}]}]" />
                 </a-form-item>
               </a-col>
               <a-col :lg="8" :md="16" :sm="24">
                 <a-form-item
                   label="安全库存"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-input-number :min="0" v-decorator="['safetyStock', {rules: [{required: false}]}]" />
                 </a-form-item>
               </a-col>
               <a-col :lg="24" :md="16" :sm="24">
                 <a-form-item
                   style="left: -17%"
                   label="物资图片"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >

                   <a-upload
                     :fileList="fileList"
                     :headers="tokenHeader"
                     listType="picture-card"
                     class="avatar-uploader"
                     @preview="handlePreview"
                     :action="uploadApi"
                     :beforeUpload="beforeUpload"
                     @change="handleImgChange"
                   >
                     <div v-if="fileList.length < 3">
                       <a-icon type="plus" />
                       <div class="ant-upload-text">点击添加物资图片</div>
                     </div>
                   </a-upload>
                   <a-modal :visible="previewVisible" :footer="null" @cancel="imgCancel">
                     <img alt="example" style="width: 100%" :src="previewImage" />

                   </a-modal>

                 </a-form-item>
               </a-col>
               <a-col :lg="24" :md="16" :sm="24">
                 <a-form-item
                   label="描述"
                   style="left: -17%"
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                 >
                   <a-textarea :autosize="true" placeholder="请在这里输入描述" v-decorator="['materialDesc', {rules: [{required: false}]}]" />
                 </a-form-item>
               </a-col>
             </a-card>
             <a-card :bordered="true" :style="isShow" title="质控信息">
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                   label="定值日期">
                   <a-date-picker format="YYYY-MM-DD" v-decorator="['fixedValueDate', {rules: [{required: false}]}]" />
                 </a-form-item>
               </a-col>
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                   label="标准值">
                   <a-input v-decorator="['standardValue', {rules: [{required: false,message:'请输入标准值'}]}]" />
                 </a-form-item>
               </a-col>
               <a-col :lg="12" :md="16" :sm="24">
                 <a-form-item
                   :labelCol="labelCol"
                   :wrapperCol="wrapperCol"
                   label="不确定浓度">
                   <a-input v-decorator="['uncertainty', {rules: [{required: false,message:'请输入不确定浓度'}]}]" />
                 </a-form-item>
               </a-col>
               <div v-show="showDiv">
                 <a-col :lg="12" :md="16" :sm="24">
                   <a-form-item
                     :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     label="稀释倍数">
                     <a-input v-decorator="['dilutionMultiple', {rules: [{required: false,message:'请输入稀释倍数'}]}]" />
                   </a-form-item>
                 </a-col>
                 <a-col :lg="12" :md="16" :sm="24" >
                   <a-form-item
                     :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     label="稀释液">
                     <a-input v-decorator="['dilution_solution', {rules: [{required: false,message:'请输入稀释液'}]}]" />
                   </a-form-item>
                 </a-col>
               </div>
             </a-card>

           </div>

         </a-row>

       </a-form>
      <template
        slot="footer"
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#FFFFFF',
          textAlign: 'right',
        }">
        <a-button :style="{marginRight: '8px'}" @click="handleCancel">关闭</a-button>
        <a-button :style="{marginRight: '8px'}" @click="handleSubmit" type="primary">提交</a-button>
        <a-button :style="{marginRight: '8px'}" @click="handleSubmitCnt" type="primary">继续添加</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { resMaterialApi, resPurUseApi, resdevCategoryApi, Post, Get } from '@/api/quote'
import ATextarea from 'ant-design-vue/es/input/TextArea'
import Moment from 'moment'
import ACol from 'ant-design-vue/es/grid/Col'
import { sysCommonApi } from '@/api/common'
import AInputGroup from 'ant-design-vue/es/input/Group'
import AFormItem from 'ant-design-vue/es/form/FormItem'
export default {
  components: { AFormItem, AInputGroup, ACol, ATextarea },
  props: {
    dictOptions: {
      type: Array,
      default: () => []
    },
    userName: null,
    locationList: {
      type: Array,
      default: () => []
    },

    locationList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      cateTypeDis: true,
      treeExpandedKeys: null,
      categoryList: null,
      isShow: 'display:none',
      showDiv: false,
      batchNbrList: [],
      previewVisible: false,
      previewImage: '',
      fUrl: '',
      saveFileUrl: '',
      saveFileList: [],
      uploadApi: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      fileList: [],
      speTypeList: [],
      materialList: [],
      fileAppendix: [],
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 16
      },
      visible: false,
      height: '100%',
      width: '100%',
      id: null,
      title: '新增类目',
      form: this.$form.createForm(this),
      type: 0,
      defaultChecked: false
    }
  },
  methods: {

    listCateTree () {
      var self = this
      Get(resdevCategoryApi.list).then(result => {
        self.treeExpandedKeys = []
        if (result.data.length > 0) {
          var cateTreeList = result.data
          self.buildTree(cateTreeList, true)
          self.categoryList = cateTreeList
        }
      })
    },
    buildTree (cateTreeList, expanded) {
      debugger
      var self = this
      if (cateTreeList) {
        for (var idx in cateTreeList) {
          var cate = cateTreeList[idx]
          cate.title = cate.name
          var key = cate.id ? cate.id : 'T' + cate.cateType
          cate.key = key
          cate.value = key
          cate.selectable = true
          if (!cate.id) {
            if (expanded) {
              self.treeExpandedKeys.push(key)
            }
            cate.selectable = false
          }
          if (cate.cateAdd == '1') {
            cate.selectable = false
          }
          if (cate.children) {
            self.buildTree(cate.children)
          }
        }
      }
    },
    handleSpeTypeChange (value) {
      const v = {
        speType: value
      }
      this.form.setFieldsValue(v)
    },
    handleMaterialChange (value) {
      const v = {
        materialName: value
      }
      this.form.setFieldsValue(v)
      Post(`${resPurUseApi.getSpeTypeByMaterialName}/${value}`, {}).then(result => {
        this.speTypeList = result
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    handleWzChange (value) {
      /*     if(column==='materialName'){
        this.materialTemp=value
        Post(`${resPurUseApi.getSpeTypeByMaterialName}/${value}`, {}).then(result => {
          this.speTypeList=result
        }).catch(error => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
      } */
      Post(`${resPurUseApi.getMatersByCateId}/${value}`, {}).then(result => {
        this.materialList = result
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })

      if (value === '23') {
        console.log('in 23')
        this.isShow = 'display=display'
        this.showDiv = false
      } else if (value === '24') {
        console.log('in 24')
        this.isShow = 'display=display'
        this.showDiv = true
      } else {
        console.log('in else')
        this.isShow = 'display:none'
        this.showDiv = false
      }
    },
    handleChange (value) {
      const batchArr = value.split('-')
      const v = {
        'batchNbr': batchArr[0],
        'batchName': batchArr[1]
      }
      this.form.setFieldsValue(v)
    },
    imgCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = this.fUrl
      this.previewVisible = true
    },
    beforeUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      if (!isJPG) {
        this.$message.error('请上传jpeg格式的图片!')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('图片大小限定为2M!')
        return
      }
      return isJPG && isLt2M
    },
    handleImgChange (info) {
      this.fileList = info.fileList
      /* if(this.fileList.length>0){
          for(let i=0;i<this.fileList.length;i++){
           let img= this.fileList[i].response.data

            this.saveFileList.push(img)
          }
      }else{
        this.saveFileList=[]
      } */
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件图片成功`)
          const fileUrl = info.file.response.data.fileUrl
          const fileName = info.file.response.data.fileName
          info.file.thumbUrl = this.viewUrl + fileUrl
          this.fUrl = info.file.thumbUrl
          info.file.url = this.viewUrl + fileName
          this.saveFileUrl = fileUrl
          this.saveFileList.push(fileUrl)

          this.fileAppendix.push({ uid: info.file.uid, fileUrl: fileUrl, fileName: fileName })
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'removed') {
        const tmpArr = []
        const fileUrl = info.file.response.data.fileUrl
        for (let i = 0; i < this.saveFileList.length; i++) {
          if (this.saveFileList[i] !== fileUrl) {
            tmpArr.push(this.saveFileList[i])
          }
        }
        this.saveFileList = tmpArr
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    add () {
      this.cateTypeDis = false
      this.isShow = 'display:none'
      this.showDiv = false
      this.fUrl = ''
      this.fileList = []
      this.saveFileUrl = ''
      this.saveFileList = []
      this.title = '新增类目'
      this.visible = true
      this.id = null
      this.url = resMaterialApi.add
      const nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hour: nowDate.getHours(),
        min: nowDate.getMinutes(),
        second: nowDate.getSeconds()
      }
      this.form.$nextTick(() => {
        const v = {
          'materialName': '',
          'cateId': '',
          'cateType': 1,
          'materialDesc': '',
          'dateOfPur': null,
          'locationId': '',
          'termOfValid': null,
          'useStatus': '1',
          'materialNum': 1,
          'depositary': '',
          'cmsOrNot': '0',
          // 'purpose':null,
          'brand': '',
          'speType': '',
          'unit': '4',
          'storageCondition': '',
          'materialDesc': '',
          'preDays': '',
          'safetyStock': ''
        }
        this.form.setFieldsValue(v)
      })
    },
    edit (record) {
      this.cateTypeDis = true
      console.log(this.cateTypeDis)
      this.fileList = []
      if (record.materialImg != null) {
        console.log(this.viewUrl + record.materialImg)
        const v = {
          'uid': '-1',
          'name': 'car.jpg',
          'status': 'done',
          'url': this.viewUrl + record.materialImg
        }
        this.fileList.push(v)
      }
      this.visible = true
      this.title = '编辑物资信息'
      this.id = record.id
      this.url = resMaterialApi.edit
      this.saveFileUrl = record.materialImg
      this.fUrl = this.viewUrl + record.materialImg
      this.form.$nextTick(() => {
        console.log(record)
        const v = {
          'materialName': record['materialName'],
          'cateId': record['cateId'],
          'cateType': record['cateType'],
          'batchNbr': record['batchNbr'],
          'batchName': record['batchName'],
          'materialDesc': record['materialDesc'],
          'dateOfPur': record['dateOfPur'] == null ? null : Moment(record['dateOfPur'], 'YYYY-MM-DD'),
          'locationId': record['locationId'],
          'termOfValid': record['termOfValid'] == null ? null : Moment(record['termOfValid'], 'YYYY-MM-DD'),
          'useStatus': record['useStatus'],
          // 'numOfUse'     :record['numOfUse'],
          'depositary': record['depositary'],
          'cmsOrNot': record['cmsOrNot'],
          // 'purpose'     :record['purpose'],
          'brand': record['brand'],
          'speType': record['speType'],
          'purity': record['purity'],
          'unit': record['unit'],
          'storageCondition': record['storageCondition'],
          'materialDesc': record['materialDesc'],
          'materialNum': record['materialNum']
        }
        this.form.setFieldsValue(v)
      })
    },
    handleCancel () {
      this.visible = false
    },
    handleSubmitCnt (e) {
      this.submit(e, 1)
    },
    handleSubmit (e) {
      this.submit(e, 0)
    },
    submit (e, t) {
      const { form: { validateFields } } = this
      e.preventDefault()
      validateFields((errors, record) => {
        const v = {
          'materialName': record['materialName'],
          'cateId': record['cateId'],
          'cateType': record['cateType'],
          'materialDesc': record['materialDesc'],
          'dateOfPur': record['dateOfPur'] == null ? null : record['dateOfPur'].format('YYYY-MM-DD'),
          'locationId': record['locationId'],
          'termOfValid': record['termOfValid'] == null ? null : record['termOfValid'].format('YYYY-MM-DD'),
          'useStatus': '1',
          'materialNum': record['materialNum'],
          'depositary': record['depositary'],
          'depositary': record['depositary'],
          'cmsOrNot': record['cmsOrNot'],
          //  'purpose'     :record['purpose'],
          'brand': record['brand'],
          'speType': record['speType'],
          'purity': record['purity'],
          'unit': record['unit'],
          'storageCondition': record['storageCondition'],
          'materialDesc': record['materialDesc'],
          'batchNbr': record['batchNbr'],
          'batchName': record['batchName'],
          'mImg': this.saveFileList,
          'materialImg': this.saveFileList[0],
          'id': this.id,
          'preDays': record['preDays'],
          'safetyStock': record['safetyStock']
        }
        if (!errors) {
          Post(this.url, v).then(result => {
            if (result.code === 0) {
              this.$message.success(`${this.title}成功`)
              if (t === 1) {
                const s = {
                  'materialName': '',
                  'cateId': '',
                  'cateType': 1,
                  'materialDesc': '',
                  'dateOfPur': null,
                  'locationId': '',
                  'termOfValid': null,
                  'useStatus': '1',
                  'materialNum': 1,
                  'depositary': '',
                  'cmsOrNot': '0',
                  // 'purpose':null,
                  'brand': '',
                  'speType': '',
                  'purity': '',
                  'unit': '',
                  'storageCondition': '',
                  'materialDesc': '',
                  'safetyStock': '',
                  'preDays': ''
                }
                this.form.setFieldsValue(s)
                this.fileList = []
                //     this.initBacthNbrList()
              } else {
                this.handleCancel()
                this.$emit('ok', '')
              }
            } else if (result.code === -1) {
              this.$message.error('当前物资已存在,请修改信息后添加!')
            } else {
              this.$message.error(result.msg)
            }
          }).catch(error => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.$emit('ok', '')
    }
  }
}
</script>
<style  lang="less" >
  .modal-container {
    height: 100%;

    .ant-modal {
      height: 100%;
      overflow: hidden;
      padding-bottom: 0px;

      .ant-modal-content {
        height: 100%;
        overflow: hidden;

        .ant-modal-body {
          overflow: auto;
          padding: 5px;
          height: ~"calc(100% - 108px)";
        }

        .ant-modal-body::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
</style>
