'use strict'

/**
 * 标准溶液标定相关API
 */

import { getAction, postDataAction } from '@/api/common'

const checkStandardSolutionDemarcatesPre = '/ms-api/check-standard-solution-demarcates'
const checkStandardSolutionDemarcatesConfigPre = '/ms-api/check-standard-solution-demarcates-config'

export const checkStandardSolutionDemarcatesApi = {
  list: (param) => getAction(`${checkStandardSolutionDemarcatesPre}/list`, param),
  getDetail: (param) => getAction(`${checkStandardSolutionDemarcatesPre}/get-detail`, param),
  demarcate: (data) => postDataAction(`${checkStandardSolutionDemarcatesPre}/demarcate`, data),
  verify: (data) => postDataAction(`${checkStandardSolutionDemarcatesPre}/verify`, data),
  check: (data) => postDataAction(`${checkStandardSolutionDemarcatesPre}/check`, data),
  getFourAveragePurity: (data) => getAction(`${checkStandardSolutionDemarcatesPre}/get-fourAveragePurity`, data)
}

export const checkStandardSolutionDemarcatesConfigApi = {
  list: (param) => getAction(`${checkStandardSolutionDemarcatesConfigPre}/list`, param),
  getDetailBySolutionTypeId: (solutionTypeId) => getAction(`${checkStandardSolutionDemarcatesConfigPre}/detail/solution-type-id/${solutionTypeId}`)
}
