<template>
  <a-card>
    <div style="margin: 16px auto;">
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="3">基础信息</th>
          <td class="tdLabel">方案名称</td>
          <td colspan="3">
            <div :class="`required ${notNull(quoteInfo.projectTitle)}`">
              <a-input placeholder="方案名称" v-model="quoteInfo.projectTitle"/>
            </div>
          </td>
          <td class="tdLabel">单号</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.quoteSn)}`">
              <a-input placeholder="等待生成" v-model="quoteInfo.quoteSn"/>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">监测类型</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.quoteType)}`">
              <a-select style="width: 100%" v-model="quoteInfo.quoteType" placeholder="监测类型">
                <a-select-option v-for="(v,k) in this.quoteTypeMap" :key="k">{{ v }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">样品来源</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.sampleSource)}`">
              <a-select style="width: 100%" v-model="quoteInfo.sampleSource" placeholder="样品来源">
                <a-select-option v-for="(v,k) in this.sampleSourceMap" :key="k">{{ v }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">报告类型</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.reportType)}`">
              <a-select style="width: 100%" v-model="quoteInfo.reportType" placeholder="报告类型">
                <a-select-option v-for="(v,k) in this.reportTypeMap" :key="k">{{ v }}</a-select-option>
              </a-select>
              <!--              <a-select style="width: 100%"  v-model="quoteInfo.reportType" placeholder="报告类型">-->
              <!--                <a-select-option v-for="(v,k) in this.reportTypeMap" :key="k">{{ v }}</a-select-option>-->
              <!--              </a-select>-->
            </div>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">负责人</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.quoteContacts)}`">
              <a-select
                v-model="quoteInfo.quoteContacts"
                style="width: 100%"
                showSearch
                placeholder="负责人"
                @change="changeQuoteContacts"
                optionFilterProp="children"
                :filterOption="filterOption">
                <a-select-option v-for="vo in this.userList" :key="vo.username" :obj="vo">
                  <template v-if="vo.username==vo.realname">
                    {{ vo.realname }}
                  </template>
                  <template v-else>
                    {{ vo.realname }}({{ vo.username }})
                  </template>
                </a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系电话</td>
          <td>
            <div>
              <a-input placeholder="联系电话" v-model="quoteInfo.quoteContactsPhone"/>
            </div>
          </td>
          <td colspan="2"></td>
        </tr>
<!--        <tr>-->
<!--          <td class="tdLabel">付款方式</td>-->
<!--          <td>-->
<!--            <div :class="`required ${notNull(quoteInfo.entrustCsrName)}`">-->
<!--              <a-input placeholder="方案类型" v-model="quoteInfo.entrustCsrName"/>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td class="tdLabel">付款备注</td>-->
<!--          <td colspan="4">-->
<!--            <div :class="`required ${notNull(quoteInfo.entrustContacts)}`">-->
<!--              <a-input placeholder="付款备注" v-model="quoteInfo.entrustContacts"/>-->
<!--            </div>-->
<!--          </td>-->
<!--        </tr>-->

        <tr>
          <th rowspan="3">客户信息</th>
          <td class="tdLabel">委托单位</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.entrustCustomer)}`">
              <a-select
                v-model="quoteInfo.entrustCustomer"
                style="width: 100%"
                showSearch
                placeholder="请选择委托单位"
                @change="changeEntrust"
                optionFilterProp="children"
                :filterOption="filterOption">
                <a-icon type="plus-square" @click="showAddCustomer(1)" slot="suffixIcon" />
                <a-select-option v-for="vo in this.customerList" :key="vo.id">{{ vo.csrName }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系人</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.entrustContacts)}`">
              <a-select
                v-model="quoteInfo.entrustContacts"
                style="width: 100%"
                showSearch
                placeholder="委托单位联系人"
                optionFilterProp="children"
                @change="(value, option)=>changeCustomerUser(value, option, 2)"
                :filterOption="filterOption">
                <a-icon type="plus-square" @click="showAddCustomerUser" slot="suffixIcon" />
                <a-select-option v-for="vo in this.customerUserList" :key="vo.id">{{ vo.csrUserName }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系电话</td>
          <td><a-input placeholder="委托单位联系电话" v-model="quoteInfo.entrustContactsPhone"/></td>
        </tr>
        <tr>
          <td class="tdLabel">受检单位</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.customer)}`">
              <a-select
                v-model="quoteInfo.customer"
                style="width: 100%"
                showSearch
                placeholder="请选择受检单位"
                @change="changeCustomer"
                optionFilterProp="children"
                :filterOption="filterOption">
                <a-icon type="plus-square" @click="showAddCustomer(2)" slot="suffixIcon" />
                <a-select-option v-for="vo in this.customerList" :key="vo.id" :obj="vo">{{ vo.csrName }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系人</td>
          <td>
            <div :class="`required ${notNull(quoteInfo.customerContacts)}`">
              <a-select
                v-model="quoteInfo.customerContacts"
                style="width: 100%"
                showSearch
                placeholder="受检单位联系人"
                optionFilterProp="children"
                @change="(value, option)=>changeCustomerUser(value, option, 1)"
                :filterOption="filterOption">
                <a-icon type="plus-square" @click="showAddCustomerUser" slot="suffixIcon" />
                <a-select-option v-for="vo in this.customerUserList" :key="vo.id">{{ vo.csrUserName }}</a-select-option>
              </a-select>
            </div>
          </td>
          <td class="tdLabel">联系电话</td>
          <td><a-input placeholder="受检单位联系电话" v-model="quoteInfo.customerContactsPhone"/></td>
        </tr>
        <tr>
          <td class="tdLabel">地址</td>
          <td colspan="3">
            <div :class="`required ${addr.status=='success'?'':'has-error'}`"><AddressInput ref="addressInput" :addr.sync="addr"></AddressInput></div>
          </td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <th rowspan="2">其他</th>
          <td class="tdLabel">附件</td>
          <td colspan="5">
            <UploadFile :fileList="quoteInfo.fileList" @ok="uploadFileOK"></UploadFile>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">描述</td>
          <td colspan="5">
            <a-textarea placeholder="描述信息" v-model="quoteInfo.projectDesc" :autosize="{ minRows: 4, maxRows: 8 }"/>
          </td>
        </tr>
      </table>
    </div>
    <CustomerModal ref="customerModalForm" @ok="addCustomerRes"></CustomerModal>
  </a-card>
</template>

<script>
import { customerApi, customerUserApi, Get } from '@/api/quote'
import CustomerModal from '@/pages/cloud-quote/customer/CustomerForm'
import AddressInput from '@/pages/common/map/AddressInput'
import UploadFile from '@/pages/common/UploadFile'
import ARow from 'ant-design-vue/es/grid/Row'
import ACol from 'ant-design-vue/es/grid/Col'

export default {
  name: 'QuoteInfo',
  components: {
    ACol,
    ARow,
    AddressInput,
    UploadFile,
    CustomerModal
  },
  props: {
    quoteInfo: {
      type: Object,
      default: () => ({})
    },
    userList: {
      type: Array,
      default: () => ([])
    },
    customerList: {
      type: Array,
      default: () => ([])
    },
    customerUserList: {
      type: Array,
      default: () => ([])
    },
    inFileList: {
      type: Array,
      default: () => ([])
    },
    editable: {
      type: Boolean,
      default: false
    },
    addr: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {

      // 客户单位信息
      // customerUserList: [],

      fetching: false,
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      }
    }
  },
  methods: {
    changeQuoteContacts (v, o) {
      var obj = o.data.attrs.obj
      this.$set(this.quoteInfo, 'quoteContacts', obj.username)
      this.$set(this.quoteInfo, 'quoteContactsName', obj.realname)
      this.$set(this.quoteInfo, 'quoteContactsPhone', obj.phone)
    },

    // 根据 委托单位  受检单位 查询委托人 并默认选取第一个
    listCustomerUser (csrType) {
      // csrType  1-受检， 2-委托
      var self = this
      var csrIds = []
      if (self.quoteInfo.entrustCustomer) {
        csrIds.push(self.quoteInfo.entrustCustomer)
      }
      if (self.quoteInfo.customer) {
        csrIds.push(self.quoteInfo.customer)
      }
      if (csrIds.length > 0) {
        var csrIdsStr = csrIds.toString()
        Get(`${customerUserApi.list}`, { csrIds: csrIdsStr, page: false, authEnable: false }).then(result => {
          if (result.code === 0) {
            var customerUserList = result.data
            this.$emit('customerUserList', customerUserList)
            if (customerUserList && customerUserList.length > 0) {
              var temp = customerUserList[0]
              self.updateCustomerUser(csrType, temp)
            }
          }
        })
      } else {
        this.$emit('customerUserList', [])

        this.$set(this.quoteInfo, 'entrustContacts', null)
        this.$set(this.quoteInfo, 'entrustContactsName', null)
        this.$set(this.quoteInfo, 'entrustContactsPhone', null)

        this.$set(this.quoteInfo, 'customerContacts', null)
        this.$set(this.quoteInfo, 'customerContactsName', null)
        this.$set(this.quoteInfo, 'customerContactsPhone', null)
      }
    },
    showAddCustomer (csrType) {
      this.$refs.customerModalForm.add({ addType: 2, csrType: csrType })
    },
    addCustomerRes (obj) {
      var record = obj.result
      this.customerList.push(record)
      if (obj.csrType === 1) {
        this.$set(this.quoteInfo, 'entrustContacts', record.id)
      }
      if (obj.csrType === 2) {
        this.$set(this.quoteInfo, 'customer', record.id)
      }
    },
    changeCustomer (value) {
      const csr = this.customerList.filter(item => value === item.id)[0]
      if (csr) {
        this.quoteInfo.customer = csr.id
        this.$set(this.quoteInfo, 'customer', csr.id)
        this.listCustomerUser(1)
        // this.projectInfo.projAddress = csr.csrAddr
        // this.addr.adCode = csr.csrAdCode
        // this.addr.address = csr.csrAddr
        // this.addr.lng = csr.csrLng
        // this.addr.lat = csr.csrLat
        // this.$refs.addressInput.initAdCode(csr.csrAdCode)
      }
    },
    showAddCustomerUser (e) {
      e.stopPropagation()
      // this.$refs.customerUserModal.addByCsrId(this.quoteInfo.entrustCsrId)
    },
    changeCustomerUser (value, op, csrType) {
      var self = this
      const csrUser = self.customerUserList.filter(item => value === item.id)[0]
      self.updateCustomerUser(csrType, csrUser)
    },
    updateCustomerUser (csrType, csrUser) {
      var self = this
      // 更新联系人 csrType  1-受检， 2-委托
      if (csrUser) {
        if (csrType === 1) {
          this.$set(this.quoteInfo, 'customerContacts', csrUser.id)
          this.$set(this.quoteInfo, 'customerContactsName', csrUser.csrUserName)
          this.$set(this.quoteInfo, 'customerContactsPhone', csrUser.csrUserTelephone)
        }
        if (csrType === 2) {
          this.$set(this.quoteInfo, 'entrustContacts', csrUser.id)
          this.$set(this.quoteInfo, 'entrustContactsName', csrUser.csrUserName)
          this.$set(this.quoteInfo, 'entrustContactsPhone', csrUser.csrUserTelephone)
        }
      }
    },
    changeEntrust (value) {
      const csr = this.customerList.filter(item => value === item.id)[0]
      this.$set(this.quoteInfo, 'entrustCustomer', csr.id)
      this.listCustomerUser(2)
    },
    uploadFileOK (obj) {
      this.$set(this.quoteInfo, 'newFileListStr', obj.newFileListStr)
      this.$set(this.quoteInfo, 'delFileIdsStr', obj.delFileIdsStr)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    initInfo () {
      this.listCustomerUser()
    }
  },
  watch: {
    addr: {
      handler (nv, ov) {
        if (!nv.adCode || !nv.address) {
          this.addr.status = 'error'
          this.addr.tip = '请添加项目地址'
        } else if (!nv.lng || !nv.lat) {
          this.addr.status = 'warning'
          this.addr.tip = '未选择经纬度'
        } else {
          this.addr.status = 'success'
          this.addr.tip = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    }
  },
  mounted () {
    this.listCustomerUser()
  },
  created () {
  }
}
</script>
<style  lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
