<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
    wrapClassName="ant-modal-cust-warp"
    style="top: 0; height: 70%"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form" v-if="isRouterAlive">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准编号">
          <a-input placeholder="请输入标准编号" v-decorator="['standardId', validatorRules.standardId]" />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准名称">
          <a-input placeholder="请输入标准名称" v-decorator="['name', validatorRules.name]" />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="启动时间">
          <a-date-picker @change="onChange" :defaultValue="moment(defaultDate, dateFormat)" :format="dateFormat" :allowClear="false" />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准类型">
          <template>
            <a-select v-decorator="['type', validatorRules.type]" style="width: 100%" placeholder="请选择标准类型">
              <a-select-option v-for="item in standardTypeList" :key="item.key">{{ item.title }}</a-select-option>
            </a-select>
          </template>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准状态">
          <template>
            <a-radio-group name="type" v-decorator="['status', {}]">
              <a-radio :value="'1'">现行</a-radio>
              <a-radio :value="'2'">废止</a-radio>
            </a-radio-group>
          </template>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准等级">
          <template>
            <a-select v-decorator="['grade', {}]" style="width: 100%" placeholder="请选择标准等级">
              <a-select-option :key="'1'">国标</a-select-option>
              <a-select-option :key="'2'">部标</a-select-option>
              <a-select-option :key="'3'">行业标准</a-select-option>
              <a-select-option :key="'4'">地方标准</a-select-option>
            </a-select>
          </template>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="替代标准">
          <a-input placeholder="请输入替代标准" v-decorator="['replStandards', {}]" />
        </a-form-item>

        <a-form-item label="附件" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-upload
            accept="image/*,.pdf,.doc,.docx"
            :action="uploadUrl"
            listType="text"
            :fileList="fileList"
            :headers="tokenHeader"
            class="upload-list-inline"
            :remove="handleRemove"
            @change="handleChange"
          >
            <a-button v-if="fileList.length < 3"> <a-icon type="upload" /> 点击上传 </a-button>
          </a-upload>
        </a-form-item>

        <a-form-item v-if="showMethod" :labelCol="labelCol" :wrapperCol="wrapperCol" label="方法">
          <template>
            <a-select mode="multiple" showSearch :filterOption="filterOption" :defaultValue="selectMethodValue" style="width: 100%" @change="handleMethodChange">
              <!--  <div slot="dropdownRender" slot-scope="menu">
                <v-nodes :vnodes="menu"/>
                <a-divider style="margin: 4px 0;" />
                <div style="padding: 8px; cursor: pointer;" @click="addMethod">
                  <a-icon type="plus" /> 新增方法
                </div>
              </div>-->
              <a-select-option v-for="m in methodList" :value="m.id" :key="m.id">{{ m.name }}</a-select-option>
            </a-select>
          </template>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="一级分类">
          <template>
            <!-- initialValue: model.sysDictItemId  -->
            <a-select mode="multiple" style="width: 100%" placeholder="请选择一级分类" v-decorator="['sysDictItemId', {}]">
              <a-select-option v-for="item in cateTypeList" :value="item.key" :key="item.key">{{ item.title }}</a-select-option>
            </a-select>
          </template>
        </a-form-item>
      </a-form>
      <a-modal title="添加方法" :width="800" :visible="methodVisible" :zIndex="zIndex" @ok="methodSubmit" @cancel="methodCancel">
        <method-add ref="methodModal" @ok="handleSuccess"></method-add>
      </a-modal>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import moment from 'moment'
import { sysCommonApi, postAction } from '@/api/common'
import { jcStandardApi, jcMethodApi, Post, Get } from '@/api/quote'
import AFormItem from 'ant-design-vue/es/form/FormItem'
import MethodAdd from '@/pages/cloud-quote/standard/modules/MethodAdd'

export default {
  name: 'StandardModal',
  components: {
    AFormItem,
    MethodAdd,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    cateTypeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      methodVisible: false,
      zIndex: 10000000,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      fileList: [],
      fileAppendix: [],
      isRouterAlive: true,
      title: '操作',
      dateFormat: 'YYYY-MM-DD',
      visible: false,
      standardList: [],
      standardTypeList: [],
      defaultDate: '',
      defaultType: 'collect',
      defaultStatus: '1',
      defaultGrade: '1',
      isDefaultStandard: '2',
      standardIdDisabled: false,
      model: {},
      methodList: [],
      standardMethodList: [],
      selectMethodValue: [],
      showMethod: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        name: { rules: [{ required: true, message: '请输入标准名称!' }] },
        id: { rules: [{ required: true, message: '请输入标准编号!' }] },
        type: { rules: [{ required: true, message: '请选择标准类型!' }] },
        standardId: {
          rules: [{ required: true, message: '请输入标准编号!' }, { validator: this.validateStandardId }]
        }
      }
    }
  },
  created() {
    Get(jcMethodApi.listAll, {}).then((result) => {
      if (result.code === 0) {
        this.methodList = result.data
      }
    })
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    addMethod() {
      this.methodVisible = true
    },
    handleSuccess(method) {
      this.methodVisible = false
      this.methodList.push(method)
    },
    methodSubmit() {
      this.$refs.methodModal.handleSubmit()
    },
    methodCancel() {
      this.methodVisible = false
    },
    handleRemove(info) {
      this.fileAppendix = this.fileAppendix.filter((file) => file.uid !== info.uid)
      this.fileList = this.fileList.filter((r) => r.uid !== info.uid)
    },
    handleMethodChange(option) {
      this.standardMethodList = []
      option.forEach((value, index, array) => {
        this.standardMethodList.push(this.methodList.filter((item) => item.id === value)[0])
      })
    },
    handleChange(info) {
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          const fileUrl = info.file.response.data.fileUrl
          const fileName = info.file.response.data.fileName
          info.file.thumbUrl = this.viewUrl + fileUrl
          info.file.url = this.viewUrl + fileName
          this.fileAppendix.push({ uid: info.file.uid, fileUrl: fileUrl, fileName: fileName })
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
        setTimeout(() => {
          this.form.setFieldsValue(pick(this.model, 'standardId', 'name', 'operation', 'type', 'status', 'grade', 'replStandards', 'sysDictItemId'))
        }, 0)
      })
    },
    moment,
    add(type, standardTypeList, standardList, showMethod) {
      if (showMethod != null) {
        this.showMethod = showMethod
      }
      this.edit({ type: type, status: '1', grade: '1' }, standardTypeList, standardList)
    },
    edit(record, standardTypeList, standardList) {
      this.selectMethodValue = []
      this.defaultDate = '2000-01-01'
      this.standardList = standardList
      this.standardTypeList = standardTypeList
      this.model = Object.assign({}, record)

      let sysDictItemId = []
      if (this.model.sysDictItemId) {
        sysDictItemId = this.model.sysDictItemId.split(',')
      }
      this.$set(this.model, 'sysDictItemId', sysDictItemId)

      this.fileList = []
      this.fileAppendix = []
      this.standardIdDisabled = false
      if (record.id) {
        this.standardMethodList = record.methods ?? []
        this.standardMethodList.forEach((value, index, array) => {
          this.selectMethodValue.push(value.methodId)
        })

        Post(jcStandardApi.getAppendixListById, { relId: record.id, relType: '1' }).then((result) => {
          if (result.code === 0) {
            this.model.files = result.data
            const files = this.model.files
            if (typeof files !== 'undefined' && files != null && files.length > 0) {
              const d = files
              for (let i = 0; i < d.length; i++) {
                this.fileList.push({ uid: i.toString(), name: d[i].fileName, status: 'done', url: this.viewUrl + d[i].fileUrl })
                this.fileAppendix.push({ uid: i.toString(), fileName: d[i].fileName, fileUrl: d[i].fileUrl })
              }
            }
          }
        })
        // 编辑页面禁止修改标准编号
        this.standardIdDisabled = true
        if (this.model.issueTime) {
          this.defaultDate = this.model.issueTime
        }
        this.defaultType = this.model.type
        this.defaultStatus = this.model.status
        this.defaultGrade = this.model.grade
      }
      this.visible = true

      this.reload()
      /* this.$nextTick(() => {
           this.form.setFieldsValue(pick(this.model,'id', 'name', 'type','operation', 'issueTime'))
         }) */
    },
    close() {
      // this.$emit('close')
      this.visible = false
    },
    handleOk() {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          formData.issueTime = this.defaultDate
          formData.files = JSON.stringify(this.fileAppendix)
          formData.methods = JSON.stringify(this.standardMethodList)
          if (formData.sysDictItemId && formData.sysDictItemId.length) {
            formData.sysDictItemId = formData.sysDictItemId.join(',')
          } else {
            formData.sysDictItemId = ''
          }
          postAction(this.standardIdDisabled ? jcStandardApi.edit : jcStandardApi.add, formData).then((res) => {
            if (res.code === 0) {
              that.confirmLoading = false
              this.$message.success(res.msg)
              /*    res.data.files = this.fileAppendix
                res.data.methods = this.standardMethodList */
              this.close()
              this.$emit('ok', res.data, this.standardIdDisabled)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    handleCancel() {
      this.close()
    },
    typeChange(e) {
      this.defaultType = e.target.value
    },
    itemDefaultChange(e) {
      this.isDefaultStandard = e.target.value
    },
    onChange(moment, datetring) {
      this.defaultDate = datetring
    },
    validateStandardId(rule, value, callback) {
      Get(jcStandardApi.list, { standardId: value.toString().trim() }).then((res) => {
        const d = res.data
        if (this.model.id) {
          if (d.length > 0 && d[0].id != this.model.id) {
            callback('该标准编号已存在')
          } else {
            callback()
          }
        } else if (d.length > 0) {
          callback('该标准编号已存在')
        } else {
          callback()
        }
      })
    }
  },
  watch: {
    visible(value) {
      if (value) {
        /*  Get(jcStandardApi.list, Object.assign( {})).then(res => {
              if (res.code === 0) {
                const d = res.data
                for(let i=0;i<d.length;i++){
                  this.standardList.push({title:d[i].standardId,key:d[i].standardId})
                }

              }
            }).catch(err => {
              console.log(err)
            }) */
      }
    }
  }
}
</script>

<style scoped>
</style>
