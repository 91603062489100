<template>
  <a-modal v-bind="storageApplyModal" v-on="storageApplyModal">
    <template #title>
      <span>{{ title }}</span>
      <div class="fr" style="display: flex;gap: 12px;align-items: center;">
        申请时间：
        <a-date-picker v-model="pickupApplyTime" v-bind="moreCurrentDatePickerProps" format="YYYY-MM-DD HH:mm" :style="{width:'180px'}"></a-date-picker>
        申请人：
        <select-user-by-dept :disabled="false" :triggerChange="true" row-type="radio" @change="(value)=>pickupApplyUser=value[0].username"/>
      </div>
    </template>
    <storageApply v-bind="storageApplyModal.data" @success="storageApplyModal.success"></storageApply>
    <template #footer>
      <a-button @click="storageApplyModal.cancel">关闭</a-button>
      <a-button @click="storageApplyModal.ok" type="primary" :style="{marginLeft:'10px'}">申请</a-button>
    </template>
  </a-modal>
</template>

<script>
import { maxBy } from 'lodash'

import storageApply from '@/pages/cloud-quote/make-sample/modules/storageApply'
import mixinForm from '@/pages/common/mixins/form'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import SelectUserByDept from '@/pages/common/SelectUserByDept'

export default {
  components: { storageApply, SelectUserByDept },
  mixins: [mixinForm],

  props: Object.assign({}, storageApply.props, {
    title: { type: String, required: false, default: '质控样领样申请' },
    modalWidth: { type: [Number, String], required: false, default: '90%' },
    visible: { type: Boolean, required: true, default: false }
  }),

  model: {
    prop: 'visible',
    event: 'change'
  },

  data () {
    return {
      pickupApplyTime: this.now(),
      pickupApplyUser: null,
      storageApplyModal: Object.assign({}, fullModalData, {
        width: this.modalWidth,
        visible: false,
        closable: false,
        data: {
          ref: 'storageApplyForm',
          projId: this.projId,
          types: this.types,
          statuses: this.statuses,
          rowSelect: {
            selectedRows: [],
            selectedRowKeys: []
          }
        },
        show: () => { this.storageApplyModal.visible = true },

        ok: () => {
          const { pickupApplyUser, pickupApplyTime } = this
          this.$refs[this.storageApplyModal.data.ref].save({ pickupApplyTime, pickupApplyUser })
        },

        success: () => {
          this.storageApplyModal.cancel()
          this.$emit('success')
        },
        cancel: () => {
          this.storageApplyModal.visible = false
          this.$emit('change', false)
        }
      })
    }
  },

  computed: {
    maxMakeTime () {
      return maxBy(this.storageApplyModal.data.rowSelect.selectedRows, 'makeBeginTime')?.makeBeginTime ?? this.timeFormat(this.now())
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler (show) {
        if (show) {
          this.storageApplyModal.show()
        } else {
          this.storageApplyModal.cancel()
        }
      }
    }
  },

  methods: {

    moreCurrentDate (current) {
      return current && (current < this.moment(this.maxMakeTime) || current > this.now())
    }

  }

}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common/snippet";
.margin();
</style>
