<template>
  <div class="check-task-enter">
    <div class="check-task-enter-title">
      <ul-table title="量信息">
        <template #content>
          <ul-table-row>
            <ul-table-column title=" 曲线截距（a）">
              <a-input-number v-model="constantData.curveA"/>
            </ul-table-column>

            <ul-table-column title="曲线斜率（b）">
              <a-input-number v-model="constantData.curveB"/>
            </ul-table-column>
            <ul-table-column title="标准限值">
              <a-input-number v-model="constantData.limitValue"/>
            </ul-table-column>
          </ul-table-row>
        </template>
      </ul-table>
    </div>

    <div class="check-task-enter-content">
      <div class="steps">
        <a-steps :current="currentStep">
          <a-step
            v-for="item in stepForm"
            :key="item.key"
            :title="item.title"
            @click="handleStepChange(item.key,item.index)"></a-step>
        </a-steps>
      </div>

      <div class="content">
        <template v-for="item in stepForm">
          <div class="table" :key="item.key" v-show="enterTableKey === item.key">
            <enter-hot-table
              :ref="item.key"
              :hidden-columns="hiddenColumns"
              :expressions.sync="expressions"
              :tableKey="item.key"
              :col-headers="colHeaders"
              :columns="columns"
              :table-data="item.data"/>
          </div>
        </template>
      </div>
    </div>

    <a-card title="当前常量值">
      <ul-table :data-source="expressions" :hidden-title="true">
        <template #default="{data}">
          <ul-table-column :data="data" :title="data.key" data-index="value"/>
        </template>
      </ul-table>
    </a-card>

  </div>
</template>

<script>
import { sysModelFieldApi } from '@/api/quote'
import EnterHotTable from '@/pages/cloud-quote/check-task/modules/enter/EnterHotTable.vue'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkFieldApi } from '@/api/system'
import { groupBy, orderBy } from 'lodash'
import mixinDate from '@/pages/common/mixins/date'
import UlTable, { UlTableColumn, UlTableRow } from '@/pages/common/ul-table/Table.jsx'
import { getColName } from '@/api/common'

import '@/assets/less/common/form.less'

export default {
  name: 'CheckEnterPreviewTable',
  mixins: [mixinDate],
  components: { UlTable, EnterHotTable, UlTableRow, UlTableColumn },

  props: {
    modelId: { type: Number }
  },

  data () {
    return {
      getColName,
      /** 原生的任务数据 */
      originalData: [],
      /** 表格数据  */
      tableData: [],
      result: [],
      /** 模型信息 */
      modelInfoList: [],
      // 字段对应关系，原始字段映射成驼峰形式,不需要响应式
      camelCaseFieldMap: Object.freeze({ mapping: {} }),
      /** 当前的步骤值 */
      currentStep: 0,
      /** 当前录入表格的值 */
      currentTableKey: null,
      /** 采样数据配置 */
      sampleDataConfig: [
        { key: 'labBlank', title: '实验室空白', sampleTypes: [sampleInfoTypeEnum.labBlank] },
        { key: 'qc', title: '质控', sampleTypes: [sampleInfoTypeEnum.qualityControl] },
        { key: 'addSample', title: '加标', sampleTypes: [sampleInfoTypeEnum.addStandardSample, sampleInfoTypeEnum.basisAddStandardSample] },
        { key: 'fieldParallel', title: '平行', sampleTypes: [sampleInfoTypeEnum.labParallel, sampleInfoTypeEnum.fieldParallel] },
        { key: 'sampleBlank', title: '采样空白', sampleTypes: [sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank, sampleInfoTypeEnum.allBlank] },
        { key: 'normal', title: '普通样品', sampleTypes: [sampleInfoTypeEnum.normal] }
      ],
      /** 表达式 */
      expressions: [],
      constantData: {
        curveA: 0.0086,
        curveB: 0.0076,
        limitValue: 0.025
      }
    }
  },

  computed: {

    /** hot table 表头信息 */
    colHeaders () {
      return ['样品编号'].concat(this.modelInfoList.map(item => item.fieldName),['因子名称'])
    },

    /** hot table 列信息 */
    columns () {
      const defaultColumns = [
        { title: '样品编号', data: 'sampleId', index: 0, readOnly: true, hidden: false, labBlankAverageFlag: false, labBlankAverageFiled: null, type: 'text' }
      ]
      const type = ['INT', 'BIGINT', 'TINYINT', 'SMALLINT', 'DECIMAL']
      const modelColumns = this.modelInfoList.reduce((acc, item, index) => {
        acc.push({
          title: item.fieldName,
          index: index + 1,
          data: this.getCamelCaseField(item.field),
          type: type.includes(item.type) ? 'numeric' : 'text',
          readOnly: !item.editAble,
          hidden: !item.isShow,
          formula: item.formula,
          labBlankAverageFlag: item.blankAverageFlag,
          labBlankAverageField: item.blankAverageField
        })
        return acc
      }, [])
      return defaultColumns.concat(modelColumns,[
        {
          title: '因子ID',
          data: 'itemId',
          index: this.modelInfoList.length + 1,
          readOnly: true,
          hidden: true,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        },
        {
          title: '因子名称',
          data: 'itemName',
          index: this.modelInfoList.length + 2,
          readOnly: true,
          hidden: false,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        }
      ])
    },

    hiddenColumns () {
      return [...this.modelInfoList.map((item, index) => !item.isShow ? index + 1 : null).filter(item => !!item), this.modelInfoList.length + 1]
    },

    /** 处理表格数据的对象 */
    dealTableData () {
      const { modelInfoList, originalData } = this
      return { modelInfoList, originalData }
    },

    /** 实验室需要进行空白的字段 */
    labBlankAverageColumns () {
      return this.columns.filter(item => item.labBlankAverageFlag)
    },

    /** 步骤配置信息 */
    stepForm () {
      let idx = 0
      return this.sampleDataConfig.reduce((acc, item) => {
        const tableData = this.tableData.filter(data => item.sampleTypes.includes(data.sampleType))
        const defaultData = []
        if (item.key === 'labBlank') {
          const labBlankAverageColumns = this.labBlankAverageColumns
          if (labBlankAverageColumns.length) {
            const filterIdx = labBlankAverageColumns.map(item => item.index)
            const averageData = this.columns.reduce((acc, item) => {
              if (item.index === 0) {
                return { ...acc, [item.data]: '平均值:' }
              } else if (filterIdx.includes(item.index)) {
                // const colName = String.fromCharCode(65 + item.index)
                const colName = this.getColName(1 + item.index)
                return { ...acc, [item.data]: `=AVERAGE(${colName}1:${colName}${tableData.length})` }
              } else {
                return { ...acc, [item.data]: '' }
              }
            }, {})
            defaultData.push(averageData)
          }
        }
        if (tableData.length) {
          return [...acc, { key: item.key, title: item.title, data: tableData.concat(defaultData), index: idx++ }]
        }
        return acc
      }, [])
    },

    /** 当前录入的表格 KEY */
    enterTableKey () {
      return this.currentTableKey ?? this.stepForm[0]?.key
    }
  },

  watch: {
    modelId: {
      immediate: true,
      handler (value) {
        value && this.searchModelInfo(value)
      }
    },

    dealTableData: {
      deep: true,
      handler (value) {
        const { modelInfoList, originalData } = value
        modelInfoList.length && originalData.length && this.fillEnterData(originalData)
      }
    },
    constantData: {
      deep: true,
      immediate: true,
      handler (value) {
        Object.keys(value).forEach(key => {
          this.changeExpression(key, value[key])
        })
      }
    }
  },

  mounted () {
    this.mockEnterData()
  },

  methods: {

    /**
     * 查询模型信息
     * @param modelId 模型ID
     * @return {Promise} 查询结果
     */
    searchModelInfo (modelId) {
      const modelInfoApis = [sysModelFieldApi.getByModelId(modelId), checkFieldApi.list({ modelId, page: false })]
      return Promise.all(modelInfoApis).then(([res, checkRes]) => {
        if (res.code === 0) {
          const resData = orderBy(res.data || [], ['sort'])
          const checkData = checkRes.data
          const checkGroupData = groupBy(checkData, 'fieldId')
          resData.forEach(item => {
            checkGroupData[item.fieldId] && Object.assign(item, ...checkGroupData[item.fieldId])
          })
          this.modelInfoList.splice(0, this.modelInfoList.length, ...resData)
        } else {
          this.$message.error(`获取模型信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 模拟检测录入的数据
     */
    mockEnterData () {
      const mockData = [
        { sampleId: '实验室空白-1', sampleType: sampleInfoTypeEnum.labBlank },
        { sampleId: '实验室空白-2', sampleType: sampleInfoTypeEnum.labBlank },
        { sampleId: '质控-1', sampleType: sampleInfoTypeEnum.qualityControl },
        { sampleId: '质控-2', sampleType: sampleInfoTypeEnum.qualityControl },
        { sampleId: '加标-1', sampleType: sampleInfoTypeEnum.addStandardSample },
        { sampleId: '加标-2', sampleType: sampleInfoTypeEnum.addStandardSample },
        { sampleId: '现场空白-1', sampleType: sampleInfoTypeEnum.fieldBlank },
        { sampleId: '现场空白-2', sampleType: sampleInfoTypeEnum.fieldBlank },
        { sampleId: '普通样品-1', sampleType: sampleInfoTypeEnum.normal },
        { sampleId: '普通样品-2', sampleType: sampleInfoTypeEnum.normal },
        { sampleId: '普通样品-3', sampleType: sampleInfoTypeEnum.normal },
        { sampleId: '普通样品-4', sampleType: sampleInfoTypeEnum.normal },
        { sampleId: '普通样品-5', sampleType: sampleInfoTypeEnum.normal }
      ]
      this.originalData.splice(0, this.originalData.length, ...mockData)
    },

    /**
     * 填充录入的数据
     * @param dataList 录入的原始数据
     */
    fillEnterData (dataList) {
      const result = dataList.reduce((acc, item, index) => {
        const fillData = this.modelInfoList.reduce((subAcc, subItem) => {
          const { dvalue: defValue, field } = subItem
          const camelCaseField = this.getCamelCaseField(field)
          // 设置默认值
          if (defValue && !item[camelCaseField]) {
            subAcc[camelCaseField] = defValue
          }
          // 补充字段
          if (!subAcc[camelCaseField]) {
            subAcc[camelCaseField] = null
          }
          return subAcc
        }, item)
        acc.push(fillData)
        return acc
      }, [])
      this.tableData.splice(0, this.tableData.length, ...result)
    },

    /**
     * 根据字段获取对应的驼峰形式
     * @param field 原始字段形式
     * @return {String} 驼峰
     */
    getCamelCaseField (field) {
      const map = this.camelCaseFieldMap.mapping
      return map[field] || (map[field] = field.replace(/_(\w)/g, (all, letter) => letter.toUpperCase()))
    },

    /**
     * 处理步骤跳转
     * @param key 步骤配置的KEY
     * @param index 步骤配置的索引
     */
    handleStepChange (key, index) {
      this.currentTableKey = key
      this.currentStep = index
    },

    /**
     * 修改表达式的值
     * @param key 表达式的KEY
     * @param value 表达式的值
     */
    changeExpression (key, value) {
      if (key) {
        const existIdx = this.expressions.findIndex(item => item.key === key)
        if (existIdx > -1) {
          this.expressions[existIdx].value = value
        } else {
          this.expressions.push({ key, value })
        }
      }
    }

  }

}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet.less";

.check-task-enter {

  .margin();
  .data();

  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-title {
    background: #FFFFFF;
    padding: 0 30px 30px;
  }

  &-content {
    padding: 30px 30px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .content {
    padding: 0 15%;
    position: relative;
  }
}
</style>
