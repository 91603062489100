<template>
  <div class="check-standard-curve-form">
    <template v-if="!formProps.visible">
      <a-spin :spinning="loadData">
        <div class="template-container">
          <template v-if="!templateList.length">
            <div class="data">
              <div class="no-data">暂未获取到标线配置的模板，请先去文件模板中配置</div>
            </div>
          </template>
          <template v-else>
            <div :key="index" @click="toForm(item)" class="template" v-for="(item,index) in templateList">
              {{ item.templateName }}
            </div>
          </template>
        </div>
      </a-spin>
    </template>
    <template v-else-if="formProps.visible">
      <From v-bind="formProps" v-on="formProps"></From>
    </template>
  </div>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'

import From from '@/pages/cloud-quote/check-standard-curve/modules/form/form'

export default {
  props: {
    editType: {
      type: String,
      required: false,
      default: 'add'
    },

    id: {
      type: [String, Object],
      required: false,
      default: null
    },

    solutionId: {
      type: String,
      required: false,
      default: ''
    },
    standardMethodId: Number,
    standardInfo: String
  },

  components: { From },

  data () {
    return {
      fileTemplateUreportTypeEnum,

      templateList: [],

      loadData: true,

      tableSize: 50,

      formProps: {
        ref: 'form',
        visible: false,
        templateId: null,
        templateFile: null,

        show: () => { this.formProps.visible = true },

        cancel: () => { this.formProps.visible = false },

        success: () => {
          this.formProps.cancel()
          this.$emit('success')
        }
      }
    }
  },

  created () {
    this.findTemplate()
  },

  methods: {

    findTemplate () {
      fileTemplateUreportApi.list({ templateType: fileTemplateUreportTypeEnum.standardCurveLine, page: false }).then(res => {
        this.loadData = false
        if (res.code === 0) {
          this.templateList.splice(0, this.templateList.length, ...res.data)
          if (this.templateList.length === 1) {
            this.toForm(this.templateList[0])
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    toForm (item) {
      const { id: templateId, templateFile } = item
      let data
      if (this.editType === 'edit') {
        data = { id: this.id, templateId, templateFile, solutionId: this.solutionId }
      } else {
        data = { templateId, templateFile, solutionId: this.solutionId, standardMethodId: this.standardMethodId, standardInfo: this.standardInfo }
      }
      this.formProps = Object.assign(this.formProps, data)
      this.formProps.show()
    },

    save () {
      this.$refs[this.formProps.ref].save()
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
