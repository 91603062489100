import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 检测原始记录复核状态 字典枚举
export const checkOriginalRecordReviewStatusEnum = {
  // 待提交
  pending: '0',
  // 待复核
  toReview: '1',
  // 通过
  pass: '2',
  // 驳回
  rejected: '3'
}

//  检测原始记录复核状态 选择项
export const checkOriginalRecordReviewStatusOptions = [
  { label: '待提交', value: checkOriginalRecordReviewStatusEnum.pending, color: 'gray' },
  { label: '待复核', value: checkOriginalRecordReviewStatusEnum.toReview, color: 'gray' },
  { label: '通过', value: checkOriginalRecordReviewStatusEnum.pass, color: 'green' },
  { label: '驳回', value: checkOriginalRecordReviewStatusEnum.rejected, color: 'red' }
]

export const checkOriginalRecordReviewStatusMap = tranOptionsToMap(checkOriginalRecordReviewStatusOptions)
export const checkOriginalRecordReviewStatusDataMap = tranOptionsToDataMap(checkOriginalRecordReviewStatusOptions)

// 检测原始记录审核状态 字典枚举
export const checkOriginalRecordCheckStatusEnum = {
  // 待提交
  pending: '0',
  // 待审核
  toCheck: '1',
  // 通过
  pass: '2',
  // 驳回
  rejected: '3'
}

//  检测原始记录审核状态 选择项
export const checkOriginalRecordCheckStatusOptions = [
  { label: '待提交', value: checkOriginalRecordCheckStatusEnum.pending, color: 'gray' },
  { label: '待审核', value: checkOriginalRecordCheckStatusEnum.toCheck, color: 'gray' },
  { label: '通过', value: checkOriginalRecordCheckStatusEnum.pass, color: 'green' },
  { label: '驳回', value: checkOriginalRecordCheckStatusEnum.rejected, color: 'red' }
]

export const checkOriginalRecordCheckStatusMap = tranOptionsToMap(checkOriginalRecordCheckStatusOptions)
export const checkOriginalRecordCheckStatusDataMap = tranOptionsToDataMap(checkOriginalRecordCheckStatusOptions)
