<template>
  <div class="make-sample-form">
    <template v-for="(value,key ) of sampleInfoGroup">

      <a-card :key="key" v-bind="sampleInfoCard">
        <template #title>
          <span> {{ Object.values(value)[0][0].projName }} 【{{ Object.values(value)[0][0].projNo }}】</span>
        </template>

        <template v-for="(subValue,subKey ) of value">

          <a-card :key="`${key}_${subKey}`" v-bind="subSampleInfoCard">
            <template #title>
              <a-tag :color="sampleInfoTypeDataMap[subKey].color">{{ sampleInfoTypeDataMap[subKey].label }}</a-tag>
            </template>

            <make-sample
              :item-alias-map="itemAliasMap"
              :proj-id="parseInt(key)"
              :proj-no="subValue[0].projNo"
              :sample-status-dict-list="SampleStatusDictDetail"
              :sampleInfos="subValue"
              ref="makeSample"/>
          </a-card>

        </template>

      </a-card>

    </template>

  </div>
</template>

<script>

import moment from 'moment'
import { groupBy, throttle } from 'lodash'
import { customFunc } from '@/pages/cloud-quote/take-sample/modules/customForm/CustomFormFunc'
import { sampleInfoTypeDataMap, sampleInfoTypeMap } from '@/api/sampleInfo/sampleInfoConstant'

import { dictApi } from '@/api/system'

import MakeSample from '@/pages/cloud-quote/make-sample/modules/form/make'
import { makeSampleApi } from '@/api/makeSample/makeSamplelApi'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

export default {

  components: { MakeSample },

  props: {
    sampleInfos: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      sampleInfoTypeDataMap,
      sampleInfoTypeMap,

      sampleInfoGroup: {},

      itemAliasMap: {},

      SampleStatusDictDetail: [],

      sampleInfoCard: {
        type: 'inner',
        style: { marginBottom: '16px' },
        bodyStyle: { background: '#F6F6F6' }
      },

      subSampleInfoCard: {
        type: 'inner',
        style: { marginBottom: '16px' }
      }

    }
  },

  watch: {
    sampleInfos: {
      immediate: true,
      handler (val) {
        val && val.length && this.initData(val)
      }
    }
  },

  mounted () {
    this.save = throttle(this.save, 6000, { leading: true, trailing: false })
  },

  methods: {

    // 初始化数据
    async initData (sampleInfo) {
      await this.checkSampleStatusDict()
      this.handleSampleInfo(sampleInfo)
    },

    // 将样品 按照 项目、类型分组
    handleSampleInfo (sampleInfo = []) {
      const projectGroup = groupBy(sampleInfo, 'projId')
      for (const key in projectGroup) {
        projectGroup[key] = groupBy(projectGroup[key], 'type')
      }
      this.sampleInfoGroup = projectGroup
    },

    // 保存制样信息
    save () {
      const samples = this.$refs.makeSample.reduce((initArr, item) => {
        initArr.push.apply(initArr, item.getResult())
        return initArr
      }, [])

      if (!samples.length) {
        this.$message.error('暂无需要制样的样品')
        return false
      }

      makeSampleApi.make(samples).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 准备采样状态字典
    checkSampleStatusDict () {
      // 字典是否存在，不存在保存一个
      dictApi.checkCode({ code: 'appearanceStatusSelect' }).then(res => {
        if (!res) {
          this.addSampleStatusDict()
        }
      })

      customFunc._self = this
      return customFunc.asyncGetDictCode((data) => {
        this.SampleStatusDictDetail = data && data.length && data.map(item => item.label) || []
      }, ['appearanceStatusSelect'])
    },

    // 添加字典
    addSampleStatusDict () {
      const dictData = {
        dictName: '采样样品状态',
        dictCode: 'appearanceStatusSelect',
        dictDesc: '采样样品状态字典'
      }

      dictApi.add(dictData).then(res => {
        if (res.code !== 0) {
          this.$message.error(`保存采样样品状态字典发生错误：${res.msg}`)
        }
      })
    },

    addSampleStatusDictDetail () {
      const sampleStatusArr = this.SampleStatusDictDetail.map((item, index) => {
        return {
          dictCode: 'appearanceStatusSelect',
          status: 1,
          itemText: item,
          itemValue: index + 1,
          sort: index + 1
        }
      })
      this.$store.commit('code/update', sampleStatusArr)
      this.$store.commit('code/addAll', sampleStatusArr)
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
