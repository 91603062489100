<template>
  <div>
    <a-modal :centered="true" :title="title" v-model="visible" width="30%" :destroyOnClose="true">
      <a-form :form="form">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="存放地点" v-bind="formItemLayout">
              <a-tree-select
                show-search
                :filterTreeNode="treeFilterOption"
                :treeData="locationList"
                :dropdownStyle="{maxHeight:'400px'}"
                @change="handleTreeSelectChange"
                placeholder="选择地点"
                v-decorator="[ 'locationId', {rules: [{ required: true, message: '请选择地点'}]} ]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <a-button :style="{marginRight: '8px'}" @click="handleCancel">关闭</a-button>
        <a-button :style="{marginRight: '8px'}" @click="handleSubmit" type="primary">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Post, resMaterialApi } from '@/api/quote'
import mixinForm from '@/pages/common/mixins/form'

export default {
  props: {
    locationList: { type: Array, default: () => [] },
    save: { type: Boolean, required: false, default: true }
  },
  mixins: [mixinForm],

  data () {
    return {
      visible: false,
      id: null,
      title: '修改位置',
      form: this.$form.createForm(this),
      selectNode: null
    }
  },

  methods: {
    add (record) {
      this.title = '修改位置'
      this.visible = true
      this.id = record.id
      this.form.$nextTick(() => {
        this.form.setFieldsValue({ locationId: record.locationId })
      })
    },

    handleTreeSelectChange (value, label) {
      this.selectNode = { locationId: value, locationName: label[0] }
    },

    handleCancel () {
      this.visible = false
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, record) => {
        if (this.save) {
          const v = { 'id': this.id, 'locationId': record.locationId }
          if (!errors) {
            Post(resMaterialApi.edit, v).then(result => {
              if (result.code === 0) {
                this.$message.success(`修改放置位置成功`)
                this.$emit('ok', result.data)
                this.handleCancel()
              } else {
                this.$message.error(result.msg)
              }
            }).catch(error => {
              this.$message.error(`服务器发生错误！错误消息为${error}`)
            })
          }
        } else {
          this.$emit('ok', this.selectNode)
          this.handleCancel()
        }
      })
    }

  }
}
</script>
