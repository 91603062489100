<template>
  <a-modal v-bind="modalProps" v-on="modalProps" :visible="visible">
    <a-form v-bind="formProps" :form="dataForm">
      <a-card v-bind="sampleInfoCard" hoverable>
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="管理人员" v-bind="formItemLayout">
              <a-select showSearch v-bind="userProps" v-decorator="decorators.reservedUser">
                <a-select-option v-for="item in userList" :key="item.username">{{ item.realname }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="留样地点" v-bind="formItemLayout">
              <a-tree-select v-bind="addressTreeSelect" :treeData="locationList" v-decorator="decorators.reservedAddress"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="留样时长" v-bind="formItemLayout">
              <a-input-number :min="1" :style="{width:'40%'}" v-decorator="decorators.reservedTime"/>
              <a-radio-group v-on="radioGroupProps" v-bind="radioGroupProps" :value="data.reservedTimeUnit">
                <a-radio value="天">天</a-radio>
                <a-radio value="月">月</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form>
    <template v-bind="footerPorps" slot="footer">
      <a-button :style="{marginRight: '8px'}" @click="cancel">关闭</a-button>
      <a-button :style="{marginRight: '8px'}" @click="handleSubmit" type="primary">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import { userApi } from '@/api/system'
import { getAction } from '@/api/common'
import { tsSampleinfoApi } from '@/api/sample'
import { resLocationApi } from '@/api/quote'

export default {
  name: 'ReservedSampleForm',
  data () {
    return {
      visible: false,
      dataForm: this.$form.createForm(this),
      userList: [],
      data: {
        reservedTimeUnit: '天',
        reserved: true
      },
      dataList: [],

      locationList: [],

      decorators: {
        reservedUser: ['reservedUser', { rules: [{ required: true, message: '请选择管理人员' }] }],
        reservedAddress: ['reservedAddress', { rules: [{ required: true, message: '请输入留样地址' }] }],
        reservedTime: ['reservedTime', { rules: [{ required: true, message: '请输入留样时长' }] }]
      },

      formProps: {
        class: 'ant-advanced-search-form',
        form: this.dataForm
      },
      radioGroupProps: {
        style: { width: '60%' },
        change: this.reservedTimeUnitChange
      },

      sampleInfoCard: {
        title: '',
        style: {},
        headStyle: {},
        bodyStyle: {},
        bordered: true,
        type: ''
      },
      userProps: {
        placeholder: '请选择管理人员',
        optionFilterProp: 'children',
        filterOption: this.filterOption
      },

      addressTreeSelect: {
        placeholder: '选择地点'
      },

      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },

      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 12,
        style: { textAlign: 'right' }
      },

      modalProps: {
        width: 800,
        closable: false,
        destroyOnClose: true,
        centered: false,
        wrapClassName: 'receive-sample-form',
        bodyStyle: {},
        title: '样品留样',
        ok: this.handleSubmit,
        cancel: this.cancel
      },

      footerPorps: {
        style: {
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#FFFFFF',
          textAlign: 'right'
        }
      },

      fullWitdh: {
        style: { width: '100%' }
      }
    }
  },
  mounted () {
    this.loadUsers()
    this.loadLoactionList()
  },
  methods: {
    loadUsers () {
      // TODO： 调整后台接口
      getAction(userApi.list, { pageSize: 5000 }).then(res => {
        this.userList = res.data
      })
    },

    loadLoactionList () {
      getAction(resLocationApi.list, {}).then(result => {
        this.locationList = result.data
      })
    },

    reservedTimeUnitChange (e) {
      this.data.reservedTimeUnit = e.target.value
    },

    handleSubmit () {
      this.dataForm.validateFields((errors, values) => {
        if (errors != null) { return false }
        if (!this.dataList && !this.dataList.length) {
          this.$message.error('没有找到需要留样的样品信息')
          return false
        }
        const result = Object.assign(this.data, values)
        const resultList = this.dataList.map(item => { return Object.assign(item, result) })
        tsSampleinfoApi.reserved(resultList).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$emit('success')
            this.cancel()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    init (data) {
      this.visible = true
      this.dataList = data
    },

    cancel () {
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less">
  .receive-sample-form {
    .ant-cascader-picker-label {
      width: auto;
    }
  }
</style>
