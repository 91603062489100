<template>
  <div class="parallel-sample">
    <div :style="{marginBottom:'10px'}" class="fr"> 共计：<span class="focus-num green">{{ sampleCount }}</span>个样品</div>
    <a-table v-bind="contentTable">

      <template #itemName="item,record">
        {{ record.cateName }}/{{ record.itemName }}
      </template>

      <div slot="action" slot-scope="data,record,index">
        <a @click.prevent="removeData(index)" class="row-button"> 移除</a>
      </div>
    </a-table>
  </div>
</template>

<script>
import { countBy, orderBy } from 'lodash'
import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'
import { STable } from '@/components'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkTaskApi } from '@/api/check/checkTaskApi'

export default {

  components: { SampleInfoTable, STable },
  props: {
    dataSource: {
      type: Array,
      required: true
    },

    sampleType: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      sampleInfoTypeEnum,
      contentTable: {
        dataSource: [...orderBy(this.dataSource, 'sampleId')],
        pagination: false,
        bordered: true,
        columns: [
          { title: '样品编号', dataIndex: 'sampleId', width: 170, scopedSlots: { customRender: 'sampleId' } },
          { title: '任务编号', dataIndex: 'projNo', width: 120, scopedSlots: { customRender: 'projNo' } },
          { title: '检测因子', dataIndex: 'itemName', scopedSlots: { customRender: 'itemName' } },
          { title: '指派给', dataIndex: 'checkUserName', width: 80, scopedSlots: { customRender: 'checkUserName' } },
          { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } }
        ]
      }
    }
  },

  computed: {
    sampleCount () {
      return Object.keys(countBy(this.contentTable.dataSource, 'sampleId')).length
    }
  },

  methods: {
    removeData (index) {
      this.contentTable.dataSource.splice(index, 1)
    },

    save () {
      const dataSource = this.contentTable.dataSource
      if (!dataSource.length) {
        this.$message.error('暂无需要新增的平行样品！')
        return false
      }

      let api = null
      switch (this.sampleType) {
        case sampleInfoTypeEnum.labParallel :
          api = checkTaskApi.AddLabParallelTask(dataSource)
          break
        case sampleInfoTypeEnum.labBlank :
          api = checkTaskApi.addLabBlankTask(dataSource)
          break

        case sampleInfoTypeEnum.standardSample :
          api = checkTaskApi.addStandardSampleTask(dataSource)
          break
        default:
          this.$message.error('暂不支持该类型的任务')
          throw new Error('暂不支持该类型的任务')
      }

      api.then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "_index";
</style>
