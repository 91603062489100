<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="modalWidth"
    cancelText="关闭"
    style="margin-top: -70px"
    wrapClassName="ant-modal-cust-warp"
    @cancel="close"
    @ok="handleSubmit">
    <a-row :gutter="10" style="background-color: #ECECEC; padding: 10px; margin: -10px">
      <a-col :md="6" :sm="24">
        <a-card :bordered="false">
          <!--检测项一级分类-->
          <a-directory-tree
            v-if="cateTypeTree.length>0"
            :checkStrictly="true"
            :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
            :selectedKeys="selectedKeys"
            :treeData="cateTypeTree"
            selectable
            @select="this.onSelect"/>
        </a-card>
      </a-col>
      <a-col :md="18" :sm="24">
        <a-card v-bind="actionCard" hoverable>
          {{ typeTitle }}名称:
          <a-input-search v-model="queryParam.itemName" :placeholder="`请输入${typeTitle}名称`" :style="{width:'200px',marginBottom:'15px'}" enterButton @search="onSearch"></a-input-search>
          <a-button icon="redo" style="margin-left: 20px" @click="searchReset(1)">重置</a-button>
          <a-checkbox v-if="selectedKeys.length>0" :checked="checked" style="margin-left: 20px" @change="queryItems"> 全选</a-checkbox>
          <!--用户列表-->
          <a-table
            ref="table"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
            :scroll="scrollTrigger"
            rowKey="id"
            size="middle"
            @change="handleTableChange">
            <template slot="itemType" slot-scope="text">{{ text == 1 ? '是' : '否' }}</template>
            <template slot="itemSample" slot-scope="text">{{ text == 1 ? '是' : '否' }}</template>
          </a-table>
        </a-card>

        <a-card v-bind="contentCard" hoverable>
          <a-button slot="extra" @click="clearAll">清空</a-button>
          <div style="min-height: 20px;height: auto">
            <template v-for="cateType in cateTypeTree">
              <div v-if="selectedItemList.filter(r=>r.cateType==cateType.key).length>0" :key="cateType.key">
                <span>{{ cateType.title }}：</span>
                <template v-for="v in selectedItemList">
                  <a-tag v-if="v.cateType==cateType.key" :key="v.id" :afterClose="() => delSelectedItem(v.id)" :closable="closable">{{ v.name }}</a-tag>
                </template>
              </div>
            </template>

          </div>
        </a-card>

      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { filterObj } from '@/utils/util'
import { Get, itemApi } from '@/api/quote'
import { dictApi } from '@/api/system'

export default {
  name: 'SelectItemModal',
  components: {},
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },
    typeTitle: {
      type: String,
      default: '采样项'
    }
  },
  data () {
    return {
      closable: true,
      lastRowKeyLength: 0,
      selectedItemList: [],
      actionCard: {
        title: '选择' + this.typeTitle,
        style: {},
        headStyle: {},
        bodyStyle: { background: '#D7DDE312' },
        bordered: true,
        type: ''
      },

      contentCard: {
        title: '已选项',
        style: { marginTop: '24px' },
        headStyle: {},
        bodyStyle: {},
        bordered: false,
        type: 'inner'
      },
      queryParam: {
        username: ''
      },
      columns: [
        { title: '编号', dataIndex: 'id' },
        { title: '名称', dataIndex: 'itemName' },
        { title: '别名', dataIndex: 'itemAlias' },
        { title: '分包', dataIndex: 'itemType', scopedSlots: { customRender: 'itemType' } },
        { title: '采样', dataIndex: 'itemSample', scopedSlots: { customRender: 'itemSample' } }
      ],
      containerList: [],
      scrollTrigger: {},
      dataSource: [],
      selectedKeys: [],
      currentCateType: '',
      userNameArr: [],
      departName: '',
      userRolesMap: {},
      title: '选择项',
      ipagination: {
        current: 1,
        pageSize: 7,
        pageSizeOptions: ['7', '10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      selectedRowKeys: [],
      selectedRows: [],
      cateTypeTree: [],
      visible: false,
      multiSelect: true,
      checked: false,
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 该方法触发屏幕自适应
    this.resetScreenSize()
  },
  methods: {
    delSelectedItem (id) {
      this.selectedItemList = this.selectedItemList.filter(r => r.id !== id)
      this.selectedRowKeys = this.selectedRowKeys.filter(r => r !== id)
      this.lastRowKeyLength = this.selectedRowKeys.length
    },
    loadData (arg) {
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams()// 查询条件
      Get(itemApi.listByCateType, params).then((res) => {
        this.dataSource = res.data
        this.ipagination.total = res.totalCount
      })
    },

    // 触发屏幕自适应
    resetScreenSize () {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.scrollTrigger = { x: 800 }
      } else {
        this.scrollTrigger = {}
      }
    },
    showModal (selectedItemList, multiSelect) {
      this.multiSelect = multiSelect
      this.checked = false
      this.visible = true
      this.selectedRowKeys = []
      this.lastRowKeyLength = selectedItemList.length
      this.selectedItemList = selectedItemList
      this.initRowkeyBySelectedItemList(selectedItemList)
      this.queryCateTypeTree()
      this.form.resetFields()
    },
    initRowkeyBySelectedItemList (selectedItemList) {
      for (let i = 0; i < this.selectedItemList.length; i++) {
        this.selectedRowKeys.push(selectedItemList[i].id)
      }
    },
    getQueryParams () {
      const param = Object.assign({}, this.queryParam, this.isorter)
      param.field = this.getQueryField()
      if (this.selectedKeys.length > 0) {
        param.cateType = this.selectedKeys[0].toString()
      }
      param.userName = this.queryParam.userName
      param.pageNo = this.ipagination.current
      param.pageSize = this.ipagination.pageSize

      return filterObj(param)
    },
    getQueryField () {
      let str = 'id,'
      for (let a = 0; a < this.columns.length; a++) {
        str += ',' + this.columns[a].dataIndex
      }
      return str
    },
    queryItems (e) {
      this.checked = e.target.checked
      if (e.target.checked) {
        Get(itemApi.listByCateType, { cateType: this.selectedKeys[0].toString(), page: false }).then((res) => {
          const d = res.data
          for (let i = 0; i < d.length; i++) {
            const list = this.selectedItemList.filter(r => r.id === d[i].id)
            if (list.length === 0) {
              this.selectedItemList.push({ id: d[i].id, name: d[i].itemName, cateType: this.currentCateType })
              this.selectedRowKeys.push(d[i].id)
            }
          }
          this.lastRowKeyLength = this.selectedRowKeys.length
        })
      } else {
        Get(itemApi.listByCateType, { cateType: this.selectedKeys[0].toString(), page: false }).then((res) => {
          const d = res.data
          for (let i = 0; i < d.length; i++) {
            this.selectedItemList = this.selectedItemList.filter(r => r.id !== d[i].id)
            this.selectedRowKeys = this.selectedRowKeys.filter(r => r !== d[i].id)
          }
          this.lastRowKeyLength = this.selectedRowKeys.length
        })
      }
    },
    clearAll () {
      this.selectedItemList = []
      this.selectedRowKeys = []
      this.lastRowKeyLength = 0
    },
    searchReset (num) {
      const that = this
      if (num !== 0) {
        that.queryParam = {}
        that.loadData(1)
      }
    },
    close () {
      this.selectedRowKeys = []
      this.selectedKeys = []
      this.visible = false
    },
    handleTableChange (pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    handleSubmit () {
      this.$emit('ok', this.selectedItemList)
      this.close()
    },
    getCateType (rowId) {
      const d = this.dataSource.filter(r => r.id === rowId)
      if (d.length > 0) {
        return d.cateType
      } else {

      }
    },
    getItemName (rowId) {
      let name = '未知'
      const dataSource = this.dataSource
      for (let i = 0, len = dataSource.length; i < len; i++) {
        if (rowId === dataSource[i].id) {
          name = dataSource[i].itemName
          break
        }
      }
      return name
    },
    // 点击树节点,筛选出对应的用户
    onSelect (selectedKeys) {
      if (selectedKeys.length > 0) {
        this.selectedKeys = selectedKeys
        this.$emit('cateTypeSelect', selectedKeys[0].toString())
        this.currentCateType = selectedKeys[0].toString()
        this.checked = false
        this.loadData()
      }
    },
    validGroupAddItem (selectedRowKeys) {
      if (selectedRowKeys.length >= 2 && !this.multiSelect) {
        const first = this.selectedItemList.filter(r => r.id === selectedRowKeys[0])[0]
        if (first === undefined || first.cateType !== this.currentCateType) {
          return { flag: false, message: '同一个分组不能添加不同分类的项' }
        }
        return { flag: true, message: '' }
      } else {
        return { flag: true, message: '' }
      }
    },
    onSelectChange (selectedRowKeys, selectionRows) {
      const a = selectedRowKeys
      if (this.lastRowKeyLength < a.length) {
        const valid = this.validGroupAddItem(selectedRowKeys)
        if (!valid.flag) {
          this.$message.info(valid.message)
          return
        }
        // 勾选
        for (let i = 0; i < a.length; i++) {
          if (i > this.lastRowKeyLength - 1) {
            this.selectedItemList.push({ id: a[i], name: this.getItemName(a[i]), cateType: this.currentCateType })
          }
        }
      } else {
        // 去除
        const result = []
        const b = this.selectedItemList
        for (let i = 0; i < b.length; i++) {
          let flag = false
          for (let j = 0; j < a.length; j++) {
            if (a[j] === b[i].id) {
              flag = true
            }
          }
          if (flag) {
            result.push(b[i])
          }
        }
        this.selectedItemList = result
      }

      this.lastRowKeyLength = selectedRowKeys.length
      this.selectedRowKeys = selectedRowKeys
    },
    onSearch () {
      if (this.selectedKeys.length === 0) {
        this.$message.info('请选择一个分类')
        return
      }
      this.loadData(1)
    },

    // 传入用户id,找到匹配的角色名称
    queryUserRole (userId) {
      const map = this.userRolesMap
      const roleName = []
      for (var key in map) {
        if (userId === key) {
          roleName.push(map[key])
        }
      }
      return roleName.join(',')
    },
    queryCateTypeTree () {
      this.cateTypeTree = []
      dictApi.getItems({ dictCode: 'cate_type' }).then((res) => {
        if (res.code === 0) {
          const d = res.data
          const temp = []
          for (let i = 0; i < d.length; i++) {
            temp.push({ title: d[i].itemText, key: d[i].itemValue })
          }
          this.cateTypeTree = temp
        }
      })
    },

    modalFormOk () {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.ant-table-tbody .ant-table-row td {
  padding-top    : 10px;
  padding-bottom : 10px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size   : 18px;
  line-height : 64px;
  padding     : 0 24px;
  cursor      : pointer;
  transition  : color .3s;
}

.ant-select-selection__choice {
  color              : rgba(0, 0, 0, 0.65);
  background-color   : #FAFAFA;
  border             : 1px solid #E8E8E8;
  border-radius      : 2px;
  cursor             : default;
  float              : left;
  margin-right       : 4px;
  margin-top         : 4px;
  max-width          : 99%;
  position           : relative;
  overflow           : hidden;
  -webkit-transition : padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition         : padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding            : 0 20px 0 10px;
}

.ant-select-selection__choice__content {
  display            : inline-block;
  white-space        : nowrap;
  overflow           : hidden;
  text-overflow      : ellipsis;
  max-width          : 100%;
  -webkit-transition : margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition         : margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.anticon {
  display                 : inline-block;
  font-style              : normal;
  vertical-align          : -0.125em;
  text-align              : center;
  text-transform          : none;
  line-height             : 0;
  text-rendering          : optimizeLegibility;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
}
</style>
