<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import { ref, getCurrentInstance, computed, reactive } from 'vue'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import CheckReagentCateList from '@/pages/cloud-quote/check-reagent/cate/List.vue'
import { useFullModal } from '@/pages/common/mixins/modal/full'
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { CheckReagentCateSelectColumn as checkReagentCateColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import CheckReagentList from '@/pages/cloud-quote/check-reagent/SelectList.vue'
import { checkReagentSelectColumn } from '@/pages/cloud-quote/check-reagent/modules/table/columns'
import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { useDate } from '@/pages/common/compositions/date/useDate'
import { message } from 'ant-design-vue'

const { proxy } = getCurrentInstance()

const props = defineProps({
  modelValue: { type: Array, required: true },
  selectCheckGuide: { type: Object, required: false, default: undefined },
  defaultValidTime: { type: String, required: false, default: undefined }
})

const emit = defineEmits(['update:modelValue'])
const checkGuideReagentList = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emit('update:modelValue', value)
  }
})
// 试剂类型选择
const selectReagentTypeData = reactive({
  showPagination: true,
  rowSelect: { type: 'checkbox', selectedRows: [] },
  columns: checkReagentCateColumn
})
const selectReagentTypeOkFun = () => {
  const selectData = selectReagentTypeData.rowSelect.selectedRows
  if (!selectData || !selectData.length) {
    this.$message.error('您还未选择！')
    return false
  }
  const guideData = checkGuideReagentList.value?.[0]
  const hasExistIds = checkGuideReagentList.value.map((item) => item.reagentCateId)
  const noExistData = selectData.filter((item) => !hasExistIds.includes(item.id))
  noExistData.length &&
    checkGuideReagentList.value.push.apply(
      checkGuideReagentList.value,
      noExistData.map((item) => ({
        id: guideData?.id,
        methodId: guideData?.detailId,
        standardId: guideData?.standardId,
        reagentCateId: item.id,
        reagentCateName: item.name,
        num: 0,
        unit: item.unit
      }))
    )
  selectReagentTypeModalAction.cancel()
}
const { modalProps: selectReagentTypeModalProps, modalAction: selectReagentTypeModalAction } = useFullModal({ title: '请选择试剂类型', width: '80%' }, selectReagentTypeOkFun)

// 试剂选择
const selectReagentData = reactive({
  currentMaterialData: null,
  cateId: null,
  selectable: true,
  pagination: true,
  validCheckTime: null,
  columns: checkReagentSelectColumn
})
const { now, timeFormat } = useDate()
const validCheckTime = props.defaultValidTime ?? timeFormat(now())
const selectReagentShowSetup = (materialId, row) => {
  selectReagentData.cateId = materialId
  selectReagentData.validCheckTime = validCheckTime
  selectReagentData.currentMaterialData = row
}

const selectReagentRef = ref()
const selectReagentOkFun = () => {
  const selectData = selectReagentRef.value?.getSelectData()
  if (!selectData || !selectData.length) {
    message.error('您还未选择！')
    return false
  }
  const materialData = selectReagentData.currentMaterialData
  if (!Object.prototype.hasOwnProperty.call(materialData, 'cateUsageInfos')) {
    proxy.$set(materialData, 'cateUsageInfos', [])
  }
  materialData.cateUsageInfos.splice(
    0,
    materialData.cateUsageInfos.length,
    ...selectData.map((item) => ({
      id: item.id,
      name: item.name,
      type: reagentCateMaterialTypeEnum.cate,
      sumVolume: item.sumVolume,
      volume: item.volume,
      unit: item.unit,
      useVolume: item.volume > materialData.num ? materialData.num : item.volume
    }))
  )
  selectReagentModalAction.cancel()
}

const { modalProps: selectReagentModalProps, modalAction: selectReagentModalAction } = useFullModal(
  { width: '80%', title: '请选择使用的试剂' },
  selectReagentOkFun,
  null,
  null,
  selectReagentShowSetup
)
const { baseFormCard } = useFormProps()
</script>

<template>
  <a-card title="试剂" v-bind="baseFormCard">
    <template #extra>
      <span :style="{ margin: '0 0 0 20px' }" class="fr red">
        <a @click="selectReagentTypeModalAction.show">添加</a>
      </span>
      <template v-if="!selectCheckGuide">
        <span class="red">请先选择检测方法</span>
      </template>
      <template v-else-if="!checkGuideReagentList || !checkGuideReagentList.length">
        <span class="red"> 暂未配置使用试剂 </span>
      </template>
    </template>
    <ul-table
      :data-source="checkGuideReagentList"
      :hidden-title="true"
      :hiddenWrap="true"
      :row-attrs="{ class: 'average' }">
      <template #default="{ data: reagent, index }">
        <div class="del-action">
          <a-tooltip>
            <template #title> 如果未使用到此种试剂，点我删除</template>
            <a @click="() => checkGuideReagentList.splice(index, 1)">
              <a-icon type="close-circle" />
            </a>
          </a-tooltip>
        </div>

        <ul-table-column :data="reagent" data-index="=reagentCateName" title="配置试剂" />

        <ul-table-column title="预计用量">
          <template v-if="reagent.num">
            <span class="green">{{ reagent.num }} </span> {{ reagent.unit }}
          </template>
          <template v-else>
            <span class="red">待定</span>
          </template>
        </ul-table-column>

        <ul-table-column v-if="!reagent.cateUsageInfos || !reagent.cateUsageInfos.length" title="使用试剂">
          <a @click="selectReagentModalAction.show(reagent.reagentCateId, reagent)">请选择使用的试剂</a>
        </ul-table-column>

        <div v-else style="flex: 2">
          <div v-for="(item, _index) in reagent.cateUsageInfos" :key="`actualNum_${_index}`" class="flex item">
            <div :style="{ flex: 2 }">
              <a-tag color="blue">
                <a-icon :style="{ marginRight: '10px' }" type="check-circle" />
                {{ item.name }}
              </a-tag>
            </div>
            <div :style="{ flex: 1 }">
              可用：<span :style="{ color: 'green', margin: '0 10px 0 5px' }">{{ item.volume }}</span
              >{{ item.unit }}
            </div>
            <div :style="{ flex: 2 }">
              <span :style="{ margin: '0 10px' }">本次使用：</span>
              <a-input-number
                v-model="item.useVolume"
                :max="item.volume"
                :min="0"
                :style="{ width: '65px' }"
                size="small"></a-input-number>
              {{ item.unit }}
            </div>
            <div :style="{ flex: 1 }">
              <a @click="reagent.cateUsageInfos.splice(_index, 1)" style="color: red">移除</a>
            </div>
          </div>
        </div>
      </template>
    </ul-table>

    <!-- 试剂类型选择 -->
    <a-modal v-bind="selectReagentTypeModalProps" v-on="selectReagentTypeModalAction">
      <check-reagent-cate-list v-bind="selectReagentTypeData" />
    </a-modal>

    <!-- 试剂使用选择-->
    <a-modal v-bind="selectReagentModalProps" v-on="selectReagentModalAction">
      <check-reagent-list ref="selectReagentRef" v-bind="selectReagentData"></check-reagent-list>
    </a-modal>
  </a-card>
</template>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet.less";
.color();
</style>
