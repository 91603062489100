<template>
  <div style="height: 100%" class="project-check-original-record">
    <a-spin style="height: 100%;width:100%" size="large" :spinning="commonLoading"/>
    <a-tabs
      size="small"
      style="height: 100%;padding: 0;margin: 0;overflow: hidden;background: #FFFFFF"
      tabPosition="left"
      type="line" v-model="cateShowMapKey" @change="changeCateShowMapKey">
      <a-tab-pane
        :closable="false"
        :key="key"
        style="height: 100%"
        v-for="(value,key) of cateShowMap">
        <template slot="tab">
          {{ value.cateName }}<br>
          <template v-if="cateSiteItemStatMap[key]">
            <template v-if="cateSiteItemStatMap[key].sum === cateSiteItemStatMap[key].doneNum">
              <span class="green">{{ cateSiteItemStatMap[key].doneNum }}  /  {{ cateSiteItemStatMap[key].sum }}</span>
            </template>
            <template v-else>
              <span class="red">{{ cateSiteItemStatMap[key].doneNum }}  /  {{ cateSiteItemStatMap[key].sum }}</span>
            </template>
          </template>
        </template>
        <a-tabs
          size="small"
          style="height: 100%;padding: 0;margin: 0;overflow: hidden;"
          tabPosition="left"
          type="line" v-model="siteDetailIndex" @change="changeSiteDetailIndex">
          <a-tab-pane
            :closable="false"
            :key="`siteDetail${index}`"
            style="height: 100%"
            v-for="(siteDetail,index) in value.details">
            <div slot="tab" style="max-width: 200px;word-break: break-word;white-space: normal">
              {{ siteDetail.siteName }}<br/>
              <template v-if="cateSiteItemStatMap[key]&&cateSiteItemStatMap[key].children&&cateSiteItemStatMap[key].children[siteDetail.siteId]">
                <template v-if="cateSiteItemStatMap[key].children[siteDetail.siteId].sum === cateSiteItemStatMap[key].children[siteDetail.siteId].doneNum">
                  <span class="green">{{ cateSiteItemStatMap[key].children[siteDetail.siteId].doneNum }}  /  {{ cateSiteItemStatMap[key].children[siteDetail.siteId].sum }}</span>
                </template>
                <template v-else>
                  <span class="red">{{ cateSiteItemStatMap[key].children[siteDetail.siteId].doneNum }}  /  {{ cateSiteItemStatMap[key].children[siteDetail.siteId].sum }}</span>
                </template>
              </template>
            </div>
            <a-tabs
              size="small"
              style="height: 100%;padding: 0;margin: 0;overflow: hidden;"
              tabPosition="left"
              type="line" v-model="itemDetailIndex" @change="changeItemDetailIndex">
              <a-tab-pane
                :closable="false"
                :key="`itemDetail${itemIndex}`"
                style="height: 100%"
                v-for="(itemDetail,itemIndex) in siteDetail.details">
                <template slot="tab">
                  <a-badge status="success" v-if="itemDetail.details.length>0" style="vertical-align: baseline;"/>
                  {{ itemDetail.itemName }}<br/>
                  <template v-if="cateSiteItemStatMap[key]&&cateSiteItemStatMap[key].children&&cateSiteItemStatMap[key]&&cateSiteItemStatMap[key].children[siteDetail.siteId]&&cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId]">
                    <template v-if="cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId].sum === cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId].doneNum">
                      <span class="green">{{ cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId].doneNum }}  /  {{ cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId].sum }}</span>
                    </template>
                    <template v-else>
                      <span class="red">{{ cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId].doneNum }}  /  {{ cateSiteItemStatMap[key].children[siteDetail.siteId].children[itemDetail.itemId].sum }}</span>
                    </template>
                  </template>
                </template>
                <template v-if="itemDetail.details.length > 0">
                  <a-tabs
                    size="small"
                    style="height: 100%;padding: 0;margin: 0;overflow: hidden;"
                    tabPosition="top"
                    type="line" v-model="batchDetailIndex">
                    <a-tab-pane
                      :closable="false"
                      :key="`batchDetail${batchIndex}`"
                      :tab="batchDetail.batchGroupNo"
                      style="height: 100%"
                      v-for="(batchDetail,batchIndex) in itemDetail.details">
                      <template v-if="batchDetail.fileTemplateId">
                        <a style="position:absolute;right: 50px; top: 66px;" title="跳转至批次页" @click="toBatch(batchDetail)">
                          <a-icon type="desktop"></a-icon>
                        </a>
                        <iframe
                          :src="getViewUrl(projId,value.cateId,itemDetail.itemId,batchDetail)"
                          frameborder="0"
                          style="width: 100%;height:100%;margin: 0 auto;padding-bottom: 40px;"/>
                        <Watermark/>
                      </template>
                      <template v-else>
                        <span :key="`batchDetail_ERR_${batchIndex}`" style="display: inline-block;width: 100%;text-align: center;color:#AAAAAA;">检测模板暂未配置，请前往检测明细中配置！</span>
                      </template>
                    </a-tab-pane>
                  </a-tabs>
                </template>
                <template v-else>
                  <span :key="`itemDetail_ERR_${itemIndex}`" style="display: inline-block;width: 100%;text-align: center;color:#AAAAAA;">暂无检测结果批次数据！</span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { fileTemplateUreportApi, ureportExportToServerApi } from '@/api/fileTemplate'
import Watermark from '@/pages/common/watermark/Watermark'
import { checkTaskApi } from '@/api/check/checkTaskApi'
import { groupBy } from 'lodash'

export default {
  components: { Watermark },

  props: {
    projId: {
      type: Number,
      required: false
    },
    templateType: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      cateShowMap: {},
      cateSiteItemStatList: [],
      projIdParam: null,
      commonLoading: false,

      cateShowMapKey: null,
      siteDetailIndex: 'siteDetail0',
      itemDetailIndex: 'itemDetail0',
      batchDetailIndex: 'batchDetail0'
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null) {
          this.getShowDataByProjId(val)
        }
      }
    }
  },

  computed: {
    cateSiteItemStatMap () {
      const statData = this.cateSiteItemStatList
      const cateGroup = groupBy(statData, 'cateId')
      return Object.keys(cateGroup).reduce((acc, key) => {
        const details = cateGroup[key]
        const cateSum = this.getSumByStat(details)

        //
        const siteGroup = groupBy(details, 'siteId')
        const children = Object.keys(siteGroup).reduce((siteAcc, siteKey) => {
          const siteDetails = siteGroup[siteKey]
          const siteSum = this.getSumByStat(siteDetails)
          const siteChildren = siteDetails.reduce((itemAcc, itemChild) => (itemAcc[itemChild.itemId] = itemChild) && itemAcc, {})
          siteAcc[siteKey] = { siteId: siteKey, pendingNum: siteSum[0], scheduleNum: siteSum[1], progressNum: siteSum[2], doneNum: siteSum[3], sum: siteSum[4], children: siteChildren }
          return siteAcc
        }, {})

        acc[key] = { cateId: key, pendingNum: cateSum[0], scheduleNum: cateSum[1], progressNum: cateSum[2], doneNum: cateSum[3], sum: cateSum[4], children }
        return acc
      }, {})
    }
  },

  created () {
  },

  methods: {

    getShowDataByProjId (projId) {
      this.commonLoading = !this.commonLoading
      this.projIdParam = projId
      Promise.all([checkTaskApi.projectCateSiteItemStat(projId), checkTaskApi.getShowDataByProjId({ projId })]).then(([statRes, res]) => {
        if (res.code === 0) {
          this.cateShowMap = res.data
          this.cateShowMapKey = Object.keys(res.data)[0]
        } else {
          this.$message.error(res.msg)
        }
        this.commonLoading = !this.commonLoading

        // 统计信息
        if (statRes.code === 0) {
          this.cateSiteItemStatList.splice(0, this.cateSiteItemStatList.length, ...statRes.data)
        }
      })
    },

    getSumByStat (list) {
      let pending = 0
      let schedule = 0
      let progress = 0
      let done = 0
      let total = 0
      for (const { pendingNum, scheduleNum, progressNum, doneNum, sum } of list) {
        pending += pendingNum
        schedule += scheduleNum
        progress += progressNum
        done += doneNum
        total += sum
      }
      return [pending, schedule, progress, done, total]
    },

    toBatch (batchInfo) {
      this.$router.open({ name: 'check-task', query: { batchGroupNo: batchInfo.batchGroupNo } })
    },

    getViewUrl (projId, cateId, itemId, batchDetail) {
      if (batchDetail.fileTemplateId !== null) {
        const templateFile = batchDetail.fileTemplateName
        let url = `${fileTemplateUreportApi.preview}?_u=${templateFile}&templateId=${batchDetail.fileTemplateId}&projId=${projId}&cateId=${cateId}&itemId=${itemId}&batchId=${batchDetail.batchId}&_t=1,2,4,5,9`
        if (batchDetail.recordId !== null) {
          url += `&id=${batchDetail.recordId}`
        }
        return url
      } else {
        return null
      }
    },
    // 下载空白记录
    downloadBlankRecord(){
      if (this.cateShowMapKey) {
        let value = this.cateShowMap[this.cateShowMapKey]
        if (value && value.cateId && value.details&&value.details.length>0) {
          let siteDetailIndex = this.siteDetailIndex.replace('siteDetail','')
          let siteDetail=value.details[siteDetailIndex]
          if (siteDetail && siteDetail.details&&siteDetail.details.length>0) {
            let itemDetailIndex = this.itemDetailIndex.replace('itemDetail','')
            let itemDetail = siteDetail.details[itemDetailIndex]
            if (itemDetail && itemDetail.itemId) {
              if (itemDetail.details && itemDetail.details.length > 0) {
                let batchDetailIndex= this.batchDetailIndex.replace('batchDetail','')
                let batchDetail = itemDetail.details[batchDetailIndex]
                if (batchDetail && batchDetail.batchId) {
                  if (batchDetail.fileTemplateId) {
                    let templateFile = batchDetail.fileTemplateName
                    let url = `${ureportExportToServerApi.downloadWordByColor}?_u=${templateFile}&templateId=${batchDetail.fileTemplateId}&projId=${this.projId}&cateId=${value.cateId}&itemId=${itemDetail.itemId}&batchId=${batchDetail.batchId}&_t=1,2,4,5,9`
                    if (batchDetail.recordId !== null) {
                      url += `&id=${batchDetail.recordId}`
                    }
                    // window.location = url
                    window.open(url, '_blank')
                  }else{
                    this.$message.error('检测模板暂未配置，请前往检测明细中配置！')
                  }
                }
              } else {
                this.$message.error('暂无检测结果批次数据！')
              }
            }
          }
        }
      }
    },
    changeCateShowMapKey(){
      this.siteDetailIndex = 'siteDetail0'
      this.itemDetailIndex = 'itemDetail0'
      this.batchDetailIndex = 'batchDetail0'
    },
    changeSiteDetailIndex(){
      this.itemDetailIndex = 'itemDetail0'
      this.batchDetailIndex = 'batchDetail0'
    },
    changeItemDetailIndex(){
      this.batchDetailIndex = 'batchDetail0'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const params = vm.$route.query
      if (params && params.projId) {
        vm.getShowDataByProjId(params.projId)
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet.less";
.project-check-original-record {
  /deep/ .ant-tabs-content {
    height: 100%;
  }
  .color();
  .margin();
}
</style>
