<template>
  <div class="check-task-schedule animate__animated animate__fadeIn">
    <a-card title="基本信息" v-bind="baseCard">
      <ul class="ul">
        <ul-table-row>
          <ul-table-column title="检测员" :data="loginUser.realname"/>

          <ul-table-column title="检测内容">
            <a-tooltip>
              <template #title>
                <template v-if="itemInfos.length">
                  <div v-for="(itemInfo) in itemInfos" :key="`${itemInfo.cateId}_${itemInfo.itemId}`">{{ itemInfo.cateName }}/{{ itemInfo.itemName }}</div>
                </template>
              </template>
              <template v-if="itemInfos.length">
                {{ itemInfos.map(item => item.itemName).join(',') }}
              </template>
            </a-tooltip>
          </ul-table-column>

          <ul-table-column title="温度" :span="6">
            <a-input-number v-model="baseForm.wd"></a-input-number> ℃
          </ul-table-column>

          <ul-table-column title="湿度" :span="6">
            <a-input-number v-model="baseForm.sd" :max="100" :min="0"></a-input-number> %
          </ul-table-column>
        </ul-table-row>
      </ul>

      <sample-list v-model="sampleInfoList" :taskList="taskList" @success="sampleInfoListHandler"/>
    </a-card>

    <template v-for="(itemInfo,_index) in itemInfos">
      <a-card :key="`${itemInfo.cateId}_${itemInfo.itemId}_${_index}`" :title="`${itemInfo.cateName}/${itemInfo.itemName}`" v-bind="baseCard">
        <template #extra>
          <template v-if="checkGuideDetailCateMap[itemInfo.cateId+'_'+itemInfo.itemId]">
            <template v-if="saveItemMap[itemInfo.cateId+'_'+itemInfo.itemId]">
              <a-popconfirm title="确定调整准备内容？" @confirm="checkTaskScheduleModal.show(itemInfo.cateId,itemInfo.itemId)">
                <a class="green">准备完成</a>
              </a-popconfirm>
            </template>
            <template v-else>
              <a @click="checkTaskScheduleModal.show(itemInfo.cateId,itemInfo.itemId)">方法准备</a>
            </template>
          </template>
        </template>
        <template v-if="checkGuideDetailCateMap[itemInfo.cateId+'_'+itemInfo.itemId]">
          <ul class="ul">
            <li v-for="(checkGuide,index) in checkGuideDetailCateMap[itemInfo.cateId+'_'+itemInfo.itemId]" :key="`checkGuide_${index}`" :class="[{'checked':selectCheckGuideMap[itemInfo.cateId+'_'+itemInfo.itemId]===checkGuide.id}]">
              <div>
                <span class="content">
                  <template v-if="checkGuide.defaultFlag===1">
                    <a-tag color="green">常用</a-tag>
                  </template>
                  <template v-else>
                    <a-tag color="red">不常用</a-tag>
                  </template>
                </span>
              </div>
              <div>
                <span class="title">检测标准:</span>
                <span class="content">{{ checkGuide.standardName }}   （{{ checkGuide.standardCode }}）</span>
              </div>

              <div>
                <span class="title">检测方法:</span>
                <span class="content">{{ checkGuide.methodName }}</span>
              </div>

              <div>
                <span class="title">检测模板：</span>
                <template v-if="checkGuide.templateName">
                  <span class="content">{{ checkGuide.templateName }}</span>
                </template>
                <template v-else>
                  <a-tag color="red">暂未配置检测原始记录模板</a-tag>
                </template>
              </div>

              <div>
                <span class="title">平行样：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needParallel].color">{{ qualityOptionDataMap[checkGuide.needParallel].label }}</a-tag>
                </span>
              </div>

              <div>
                <span class="title">空白样：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needBlank].color">{{ qualityOptionDataMap[checkGuide.needBlank].label }}</a-tag>
                </span>
              </div>

              <div>
                <span class="title">加标回收：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needMarkup].color">{{ qualityOptionDataMap[checkGuide.needMarkup].label }}</a-tag>
                </span>
              </div>

              <div>
                <span class="title">标准曲线：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needCurve].color">{{ qualityOptionDataMap[checkGuide.needCurve].label }}</a-tag>
                </span>
              </div>

              <div class="checkbox">
                <input
                  :checked="selectCheckGuideMap[itemInfo.cateId+'_'+itemInfo.itemId]===checkGuide.id"
                  :disabled="checkGuideDetailCateMap[itemInfo.cateId+'_'+itemInfo.itemId].length===1"
                  :name="`checkGuide_${itemInfo.cateId}_${itemInfo.itemId}`"
                  type="radio"
                  @change="handleSelectGuide($event,checkGuide)"/>
              </div>

            </li>
          </ul>
        </template>
        <template v-else>
          <div class="data prompt">
            <div class="red no-data">
              配置检测检测方法，请先配置检测方法后再试
            </div>
          </div>
        </template>
      </a-card>
    </template>

    <!-- 单个因子的检测准备界面 -->
    <a-modal v-if="checkTaskScheduleModal.visible||checkTaskScheduleModal.showTag" v-bind="checkTaskScheduleModal" v-on="checkTaskScheduleModal.action">
      <single-schedule v-bind="checkTaskScheduleModal.data" v-on="checkTaskScheduleModal.dataAction"></single-schedule>
      <template #footer>
        <a-button @click="checkTaskScheduleModal.action.cancel">关闭</a-button>
        <a-button @click="checkTaskScheduleModal.action.min">最小化</a-button>
        <a-button type="primary" @click="checkTaskScheduleModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <div v-if="checkTaskScheduleModal.showTag" class="tag animate__animated animate__shakeY animate__delay-1s">
      <span @click="checkTaskScheduleModal.action.max">方法准备</span>
    </div>

  </div>
</template>
<script setup>
import { UlTableColumn, UlTableRow } from '@/pages/common/ul-table/Table.jsx'
</script>

<script>
import { groupBy, orderBy, uniqBy } from 'lodash'
import mixinDate from '@/pages/common/mixins/date'
import { checkTaskBatchGroupApi } from '@/api/check/checkTaskApi'
import { itemCheckDetailApi } from '@/api/quote'

import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { qualityOptionDataMap, qualityOptionEnum } from '@/api/itemCheckDetail/itemCheckDetailConstant'
import { labQcTypeEnum, sampleInfoStatusDataMap, sampleInfoStatusEnum, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

import JUpload from '@/components/j/JUpload'
import SingleSchedule from '@/pages/cloud-quote/check-task/modules/schedule/single'
import SampleList from '@/pages/cloud-quote/check-task/modules/schedule/SampleList'
import { tsSampleinfoApi } from '@/api/sample'
import { fullModalData } from '@/pages/common/mixins/modal/full'

export default {
  name: 'Index',
  components: { JUpload, SampleList, SingleSchedule },
  mixins: [mixinDate],

  props: {
    batchGroupId: { required: true, type: Number }
  },

  data () {
    return {
      qualityOptionEnum,
      qualityOptionDataMap,
      sampleInfoTypeEnum,
      sampleInfoTypeDataMap,
      reagentCateMaterialTypeEnum,
      sampleInfoStatusDataMap,

      // 检测任务
      taskList: [],

      // 样品信息
      sampleInfoList: [],
      showError: false,

      // 检测明细ID
      checkGuideDetailCateMap: {},
      selectCheckGuideMap: {},
      saveItemMap: {},

      checkTaskDateRange: {
        showTime: { format: 'HH:mm' },
        format: 'YYYY-MM-DD HH:mm',
        placeholder: ['开始时间', '截止时间']
      },

      baseForm: {
        id: this.batchGroupId,
        makeBeginTime: this.now().subtract(1, 'h'),
        checkDetailId: null,
        makeEndTime: this.now(),
        wd: null,
        sd: null,
        atlas: null
      },

      checkTaskScheduleModal: Object.assign({}, fullModalData, {
        visible: false,
        title: '检测',
        width: '100%',
        destroyOnClose: false,
        currentCateId: null,
        currentItemId: null,
        showTag: false,
        ref: 'checkTaskScheduleModal',

        show: (cateId, itemId) => {
          // 设置数据
          this.checkTaskScheduleModal.currentCateId = cateId
          this.checkTaskScheduleModal.currentItemId = itemId
          this.checkTaskScheduleModal.data.id = this.saveItemMap[cateId + '_' + itemId]
          const taskList = this.actualTaskList.filter(item => item.cateId === cateId && item.itemId === itemId)
          this.checkTaskScheduleModal.data.taskList.splice(0, this.checkTaskScheduleModal.data.taskList.length, ...taskList)

          // 校验样品是否已经出库
          // const notOutBoundSample = taskList.filter(item => item.sampleStatus !== sampleInfoStatusEnum.outbound)
          // if (notOutBoundSample.length) {
          //  const notOutBoundSampleIds = notOutBoundSample.map(item => item.checkSampleId || item.sampleId).join()
          //  this.$message.error(`样品编号为 ${notOutBoundSampleIds} 还未出库，请先出库样品！`)
          //  return false
          // }

          const { wd, sd } = this.baseForm
          const checkDetailId = this.selectCheckGuideMap[cateId + '_' + itemId]
          const [makeBeginTime, makeEndTime] = this.makeTime
          const hasBatchIds = taskList.filter(item => item.batchId)
          hasBatchIds && hasBatchIds.length && (this.checkTaskScheduleModal.data.id = hasBatchIds[0].batchId)
          Object.assign(this.checkTaskScheduleModal.data, { makeBeginTime, checkDetailId, makeEndTime, wd, sd })
          if (!this.baseForm.id) {
            this.saveBatchInfo().then(res => {
              if (res.code === 0) {
                this.baseForm.id = res.data
                this.checkTaskScheduleModal.data.groupId = this.baseForm.id
                this.checkTaskScheduleModal.showTag = false
                this.checkTaskScheduleModal.visible = true
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.checkTaskScheduleModal.data.groupId = this.baseForm.id
            this.checkTaskScheduleModal.showTag = false
            this.checkTaskScheduleModal.visible = true
          }
        },

        action: {
          cancel: () => {
            this.checkTaskScheduleModal.visible = false
          },

          ok: () => {
            this.$refs[this.checkTaskScheduleModal.data.ref].submit()
          },

          max: () => {
            this.checkTaskScheduleModal.showTag = false
            this.checkTaskScheduleModal.visible = true
          },

          min: () => {
            this.checkTaskScheduleModal.showTag = true
            this.checkTaskScheduleModal.action.cancel()
          }
        },

        data: {
          id: null,
          taskList: [],
          groupId: null,
          checkDetailId: null,
          makeBeginTime: null,
          makeEndTime: null,
          wd: null,
          sd: null,
          ref: 'checkTaskSchedule'
        },

        dataAction: {
          cancel: (data) => {
            this.checkTaskScheduleModal.action.cancel()
            this.saveItemMap[this.checkTaskScheduleModal.currentCateId + '_' + this.checkTaskScheduleModal.currentItemId] = data
          },

          success: (data) => {
            this.checkTaskScheduleModal.dataAction.cancel(data)
          }
        }
      }),

      baseCard: {
        type: 'inner',
        size: 'mini',
        style: { marginBottom: '16px' }
      }
    }
  },

  computed: {
    sampleIds () {
      return this.taskList.filter(item => !labQcTypeEnum.includes(item.sampleType)).map(item => item.sampleId)
    },

    itemInfos () {
      return uniqBy(this.actualTaskList.map(item => ({ cateId: item.cateId, cateName: item.cateName, itemId: item.itemId, itemName: item.itemName })), (item) => item.cateId + '-' + item.itemId) || []
    },

    itemIds () {
      return this.itemInfos.map(item => item.itemId)
    },

    actualTaskList () {
      // 获取实际提交的任务，如果样品被移除 认为样品不在此批次内，故需要计算实际提交的任务
      const sampleIds = this.sampleInfoList.map(item => item.id)
      return this.taskList.filter(item => sampleIds.includes(item.sampleId))
    },

    loginUser () {
      return { ...this.$store.getters.userInfo }
    },

    curveVerifyMap () {
      return groupBy(this.curveVerifyList, 'curveId')
    },

    makeTime: {
      set (val) {
        if (val != null) {
          this.baseForm.makeBeginTime = val[0]
          this.baseForm.makeEndTime = val[1]
        }
      },
      get () {
        const { baseForm: { makeBeginTime, makeEndTime } } = this
        return [this.moment(makeBeginTime), this.moment(makeEndTime)]
      }
    }

  },

  watch: {
    batchGroupId: {
      immediate: true,
      handler (value) {
        value && this.queryBatchGroupDetail(value)
      }
    },

    sampleIds: {
      immediate: true,
      handler (sampleIds) {
        sampleIds && sampleIds.length && this.querySampleInfos(sampleIds)
      }
    },

    itemInfos: {
      immediate: true,
      handler (value) {
        value && value.length && this.queryCheckGuideDetail(value)
      }
    },

    sampleInfoList (list) {
      // list && list.length && this.validatorSample(list)
    },

    makeTime (makeTime) {
      makeTime && makeTime.length && this.computeValidityDate(this.sampleInfoList)
    }

  },

  methods: {

    // 处理样品出库事件
    sampleInfoListHandler (successIds) {
      const taskList = this.taskList.filter(item => successIds.includes(item.sampleIds))
      taskList.forEach(item => { item.sampleStatus = sampleInfoStatusEnum.outbound })
    },

    // 根据批次ID 获取样品信息
    queryBatchGroupDetail (batchGroupId) {
      checkTaskBatchGroupApi.getDetailInfoById(batchGroupId).then(res => {
        if (res.code === 0) {
          const itemInfo = res.data?.itemInfo
          Object.keys(itemInfo).forEach(key => {
            const value = itemInfo[key]
            this.$set(this.selectCheckGuideMap, key, value.checkDetailId ?? 0)
            this.saveItemMap[key] = value.batchId ?? 0
          })
          Object.keys(this.baseForm).forEach(item => { this.baseForm[item] = res.data[item] })
          const resData = res.data?.taskList || []
          const existTaskIds = this.taskList.map(item => item.id)
          const noExistTask = resData.filter(item => !existTaskIds.includes(item.id))
          this.taskList.push.apply(this.taskList, noExistTask)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询检测明细信息
    queryCheckGuideDetail (itemInfos) {
      itemCheckDetailApi.listDetail({ page: false, items: itemInfos.map(item => item.cateId + '-' + item.itemId) }).then(res => {
        if (res.code === 0) {
          const data = orderBy(res.data, 'defaultFlag', 'desc')
          this.checkGuideDetailCateMap = groupBy(data, (item) => item.cateId + '_' + item.itemId)
          Object.values(this.checkGuideDetailCateMap).reduce((acc, items) => {
            const itemId = items[0].itemId
            const cateId = items[0].cateId
            const key = `${cateId}_${itemId}`
            acc[key] || (acc[key] = items[0].id)
            return acc
          }, this.selectCheckGuideMap)

          if (Object.keys(this.saveItemMap).length === 0) {
            this.saveItemMap = Object.values(this.checkGuideDetailCateMap).reduce((acc, items) => {
              const itemId = items[0].itemId
              const cateId = items[0].cateId
              acc[cateId + '_' + itemId] = 0
              return acc
            }, {})
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 处理检测明细修改
    handleSelectGuide (event, checkGuide) {
      this.selectCheckGuideMap[checkGuide.cateId + '_' + checkGuide.itemId] = checkGuide.id
    },

    // 查询样品信息
    querySampleInfos (sampleIds) {
      tsSampleinfoApi.list({ ids: sampleIds, page: false }).then(res => {
        if (res.code === 0) {
          const sampleInfoList = res.data || []
          this.computeValidityDate(sampleInfoList)
          this.sampleInfoList.splice(0, this.sampleInfoList.length, ...(orderBy(sampleInfoList, 'type')))
        } else {
          this.$message.error(`获取样品信息错误信息:${res.msg}`)
        }
      })
    },

    // 校验检测明细
    validatorSample (sampleInfoList) {
      const noConfigList = sampleInfoList.filter(item => !item.validityDate)
      const expiredList = sampleInfoList.filter(item => item.expired)
      noConfigList.length && !this.showError && (this.showError = true) && this.$message.error(`${noConfigList.map(item => item.checkSampleId).join(',')}样品暂未获取到有效期,你注意样品是否过期!`)
      expiredList.length && !this.showError && (this.showError = true) && this.$message.error(`${expiredList.map(item => item.checkSampleId).join(',')}样品已经有效期,你注意样品是否过期!`)
    },

    // 计算样品有效期
    computeValidityDate (sampleInfoList) {
      const transUnit = (unit) => {
        switch (unit) {
          case '天':
            return 'd'
          case '小时':
            return 'h'
          case '分钟':
            return 'm'
          default:
            return unit
        }
      }
      sampleInfoList.forEach(item => {
        const { sampleBeginTime, validity, validityUnit: validityUnitData } = item
        const now = this.baseForm.makeBeginTime
        if (validity && validityUnitData) {
          const validityUnit = transUnit(validityUnitData)
          const beginTime = this.moment(sampleBeginTime)
          const validityDate = beginTime.add(validity, validityUnit)
          item.validityDate = validityDate
          item.expired = validityDate.isBefore(now)
        } else {
          item.validityDate = null
        }
      })
    },

    submit () {
      const hasCheckBool = Object.values(this.saveItemMap).reduce((acc, item) => acc || item, false)
      if (!this.baseForm.id && !hasCheckBool) {
        this.$message.error('您还未进行任何检测!')
        return false
      }
      this.saveBatchInfo().then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    saveBatchInfo () {
      const taskList = this.actualTaskList.map(item => ({ id: item.id }))
      if (!taskList.length) {
        this.$message.error('暂无需要检测的任务!')
        return false
      }
      return checkTaskBatchGroupApi.save(Object.assign({ taskList }, this.baseForm)).then(res => res)
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
