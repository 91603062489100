<template>
  <div>
    <el-carousel :interval="4000" type="card" height="200px">
      <el-carousel-item v-for="(item,index) in photos" :key="index">
        <el-image :src="getImgUrl(index)"></el-image>
      </el-carousel-item>
    </el-carousel>

    <a-modal :visible="previewPictureVisible" :width="600" :footer="null" @cancel="() => this.previewPictureVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>
<script>

import { sysCommonApi } from '@/api/common'

export default {
  name: 'PictureShow',
  props: {
    photos: {
      type: Array,
      required: true,
      default: () => []
    },

    height: {
      type: String,
      required: false,
      default: '200px'
    }
  },
  data () {
    return {
      viewUrl: sysCommonApi.view,
      previewPictureVisible: false,
      previewImage: null
    }
  },
  watch: {
    photos: {
      handler (newVal) {
        console.log('newVal=>', newVal)
      },
      immediate: true
    }
  },
  methods: {
    getImgUrl (i) {
      return this.photos[i].thumbUrl || (this.viewUrl + this.photos[i].url)
    },

    viewPicture (file) {
      this.previewImage = file.thumbUrl || (this.viewUrl + file.url)
      this.previewPictureVisible = true
    }

  }
}
</script>
<style lang="less">
  @arrowColor: #402AFF;
  .ant-carousel {
    /deep/ .slick-dots {
      height: auto;
      position: static;
      padding: 10px;
    }

    /deep/ .slick-slide{
      overflow: hidden;
      img {
        border: 5px solid #FFFFFF;
        overflow: hidden;
        display: block;
        margin: auto;
        max-width: 80%;
      }
    }

    /deep/ .slick-thumb {
      bottom: -45px;
    }

    /deep/ .slick-thumb li {
      width: 60px;
      height: 45px;
    }

    /deep/ .slick-thumb li img {
      width: 100%;
      height: 100%;
      filter: grayscale(100%);
    }

    /deep/ .slick-thumb li.slick-active img {
      filter: grayscale(0%);
    }

    /deep/ .custom-slick-arrow {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: @arrowColor;
      background-color: rgba(31, 45, 61, .11);
      opacity: 0.3;
    }

    /deep/ .slick-next{
      top:calc(50% - 33px);
      &:hover {
        color: @arrowColor;
      }
    }

    /deep/ .slick-prev{
      top:calc(50% - 33px);
      &:hover {
        color: @arrowColor;
      }
    }

    /deep/ .slick-slide {
      text-align: center;
      background: #FFFFFF;
      overflow: hidden;
    }

    /deep/ .custom-slick-arrow:before {
      display: none;
    }

    /deep/ .custom-slick-arrow:hover {
      opacity: 0.9;
    }

    /deep/ .slick-slide h3 {
      color: #FFFFFF;
    }
  }

</style>
