<template>
  <a-modal :visible="previewVisible" :width="width" :footer="null" @cancel="handleCancel">
    <img id="pImg" :src="previewImage" @load="imgLoad" />
  </a-modal>
</template>

<script>
import { flowApi } from '@/api/flow'

export default {
  name: 'FlowImgModal',
  data () {
    return {
      width: 1000,
      previewVisible: false,
      previewImage: '',
      visible: false
    }
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    showImage (id) {
      const timestamp = new Date().getTime()
      this.previewImage = `${flowApi.img}?key=${id}&timestamp=${timestamp}`
      this.previewVisible = true
    },
    imgLoad (e) {
      const w = e.currentTarget.naturalWidth
      const h = e.currentTarget.naturalHeight
      if (w > this.width) {
        document.getElementById('pImg').style.width = (this.width - 100) + 'px'
      } else {
        document.getElementById('pImg').style.width = w + 'px'
        document.getElementById('pImg').style.height = h + 'px'
      }
    }
  }
}
</script>
