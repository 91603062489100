const getters = {
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.name,
  username: state => state.user.name,
  realname: state => state.user.realname,
  welcome: state => state.user.welcome,
  roles: state => state.user.roles,
  perms: state => state.user.perms,
  userInfo: state => state.user.info,
  addRouters: state => state.permission.addRouters,
  pageSize: state => state.app.pageSize,
  multiTab: state => state.app.multiTab,
  autoRefreshRoute: state => state.app.autoRefreshRoute,
  fixedHeader: state => state.app.fixedHeader,
  motto: state => state.user.motto,
  flow_custom: state => state.flow.flow_custom,
  sys_config: state => state.app.sys_config
}

export default getters
