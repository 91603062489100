<script>
export default {
  name: 'CheckTaskVerifyHotTable',
  inheritAttrs: false
}
</script>

<script setup>
import { HotTable } from '@handsontable/vue'
import HyperFormula from 'hyperformula'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import { registerLanguageDictionary, zhCN } from 'handsontable/i18n'
import { defineProps, ref, computed, watch, onMounted, nextTick, defineExpose } from 'vue'

registerAllModules()
registerLanguageDictionary(zhCN)

const props = defineProps({
  columns: { type: Array, required: true },
  hiddenColumns: { type: Array, default: () => [] },
  colHeaders: { type: Array, required: true },
  tableData: { type: Array, default: () => [] },
  expressions: { type: Array, default: () => [] },
  tableKey: { type: String, default: '' }
})

const table = ref(null)
const hotInstance = ref(null)
const hyperFormula = ref(null)

/** hot table 的配置信息 */
const hotSettings = computed(() => {
  return {
    height: 'auto',
    stretchH: 'all',
    licenseKey: 'non-commercial-and-evaluation',
    columns: props.columns,
    allowRemoveCol: false,
    allowInsertCol: false,
    allowInsertRow: false,
    allowRemoveRow: false,
    colHeaders: props.colHeaders,
    formulas: {
      engine: HyperFormula,
      sheetId: 1,
      sheetName: 'sheet'
    },
    hiddenColumns: {
      copyPasteEnabled: true,
      indicators: false,
      columns: props.hiddenColumns
    }
  }
})

/** 实验室需要进行空白的字段 */
const labBlankAverageColumns = computed(() => {
  return props.tableKey === 'labBlank' ? props.columns.filter(item => item.labBlankAverageFlag) : []
})

/** 实验室需要进行空白的字段对应关系 */
const labBlankAverageColumnMap = computed(() => {
  return labBlankAverageColumns.value.reduce((acc, item) => ({ ...acc, [item.data]: item }), {})
})

/** 实验室需要进行空白的字段 */
const labBlankAverageColumnKeys = computed(() => {
  return labBlankAverageColumns.value.map(item => item.data)
})

/** 公式计算的字段 */
const formulaColumns = computed(() => {
  return props.columns.filter(item => !!item.formula)
})

/** 公式计算的字段对应的KEY */
const formulaColumnKeys = computed(() => {
  return formulaColumns.value.map(item => item.data)
})

/** 公式计算的字段对应关系 */
const formulaColumnMap = computed(() => {
  return formulaColumns.value.reduce((acc, item) => ({ ...acc, [item.data]: item }), {})
})

/**
 * 加载表格数据
 * @param data {*[]} 需要加载的数据
 */
const loadData = (data) => {
  if (hotInstance.value) {
    hotInstance.value.loadData(data)
    hotInstance.value.render()
    // 存在公式计算的字段，则给对应的第一个数据设置默认的公式
    const fillData = formulaColumns.value.length
      ? data.map((item, index) => {
        if (index === 0) {
          return Object.keys(item).reduce((data, key) => formulaColumnKeys.value?.includes(key) ? { ...data, [key]: formulaColumnMap.value?.[key]?.formula } : data, item)
        }
        return item
      })
      : data
    nextTick(() => {
      hotInstance.value.loadData(fillData)
      // 存在需要进行公式计算的字段，进行列填充
      formulaColumns.value.forEach(item => {
        const length = labBlankAverageColumnKeys.value.length ? props.tableData.length - 1 : props.tableData.length
        const fillRangeData = hyperFormula.value.engine.getFillRangeData(
          { start: { sheet: 0, row: 0, col: item.index }, end: { sheet: 0, row: 0, col: item.index } },
          { start: { sheet: 0, row: 1, col: item.index }, end: { sheet: 0, row: length - 1, col: item.index } }
        )
        fillRangeData.length && hyperFormula.value.engine.setCellContents({ sheet: 0, col: item.index, row: 1 }, fillRangeData)
      })
      hyperFormula.value.engine.doesSheetExist('sheet')
      hotInstance.value.render()
    })
  }
}

/**
 * 加载命名表达式
 * @param expressions 需要加载的命名表达式
 */
const loadNamedExpression = (expressions) => {
  if (hyperFormula.value) {
    const hfInstance = hyperFormula.value.engine
    const existExpressions = hfInstance.listNamedExpressions()
    existExpressions.forEach(item => hfInstance.removeNamedExpression(item))
    expressions.forEach(item => hfInstance.addNamedExpression(item.key, item.value))
    hotInstance.value.render()
  }
}

/** 获取表格中的数据  */
const getData = () => {
  const data = hotInstance.value.getData()
  const actualData = data.reduce((acc, item) => [...acc, props.columns.reduce((subAcc, subItem, subIdx) => ({ ...subAcc, [subItem.data]: item[subIdx] }), {})], [])
  // 如果需要进行平均计算的，则最后一条数据是平均数，不是需要的数据
  labBlankAverageColumnKeys.value.length && actualData.splice(actualData.length - 1, 1)
  return actualData
}

/** 初始化空白平均数据 */
const initLabBlankAverage = () => {
  labBlankAverageColumns.value.forEach(item => {
    const propDataArr = hotInstance.value.getDataAtProp(item.data)
    const key = item.labBlankAverageField
    const value = hyperFormula.value.engine.getCellValue({ sheet: 0, col: item.index, row: propDataArr.length - 1 })
    if (!Object.getPrototypeOf(value).constructor.name.includes('Error')) {
      changeExpression(key, value)
    } else {
      changeExpression(key, null)
    }
  })
}

/**
 * 处理表格数据改变事件
 * @param changes {Array<[]>} 改变的数据
 */
const handleAfterChange = (changes) => {
  if (changes?.length) {
    changes.forEach(change => {
      const [, prop] = change
      if (labBlankAverageColumnKeys.value.includes(prop)) {
        const column = labBlankAverageColumnMap.value[prop]
        const dataAtProp = hotInstance.value.getDataAtProp(prop)
        const fieldName = column.labBlankAverageField
        const value = dataAtProp[dataAtProp.length - 1]
        changeExpression(fieldName, value)
      }
    })
    hotInstance.value.render()
  }
}

/**
 * 设置表达式
 * @param key {String} 对应的常量名称
 * @param value {String} 对应的值
 */
const changeExpression = (key, value) => {
  if (key) {
    const existIdx = props.expressions.findIndex(item => item.key === key)
    if (existIdx > -1) {
      // eslint-disable-next-line vue/no-mutating-props
      props.expressions[existIdx].value = value
    } else {
      // eslint-disable-next-line vue/no-mutating-props
      props.expressions.push({ key, value })
    }
  }
}

onMounted(() => {
  hotInstance.value = table?.value?.hotInstance
  hyperFormula.value = hotInstance.value?.getPlugin('formulas')
  labBlankAverageColumnKeys?.value?.length && hotInstance.value?.addHook('afterChange', handleAfterChange)
  props.expressions.length && loadNamedExpression(props.expressions)
  props.tableData.length && loadData(props.tableData)
  initLabBlankAverage()
})

watch(() => props.expressions, (value) => {
  loadNamedExpression(value)
}, { deep: true, immediate: true })

defineExpose({ getData })

</script>

<template>
  <div class="check-task-verify-hot-table">
    <hot-table
      ref="table"
      :colHeaders="true"
      :rowHeaders="true"
      :settings="hotSettings"
      language="zh-CN"></hot-table>
  </div>
</template>

<style scoped>
.check-task-verify-hot-table {
}
</style>
