import { delAction, getAction, postAction, postDataAction } from './common'
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

export const userApi = {
  rolesAndDepts: '/ms-api/user/roles-and-depts',
  getPostTypeByUserId: '/ms-api/user/get-user-post-type',
  menu: (p) => getAction('/ms-api/user/menus', p),
  list: '/ms-api/user/list',
  listUser: (p) => getAction('/ms-api/user/list', p),
  add: (p) => postAction('/ms-api/user/add', p),
  edit: (p) => postAction('/ms-api/user/edit', p),
  del: '/ms-api/user/del',
  checkUserName: (p) => getAction('/ms-api/user/check-user-name', p),
  changePassword: (p) => postAction('/ms-api/user/change-password', p),
  changeLimitTime: (p) => postAction('/ms-api/user/update-limit-time', p),
  changeCurrentPassword: (p) => postAction('/ms-api/user/change-current-pwd', p),
  findUserByDept: (p) => getAction('/ms-api/user/find-user-dept', p),
  changeUserStatus: (p) => getAction('/ms-api/user/change-user-status', p),
  getUserList: '/ms-api/user/getUserList',
  getUserNoPage:'/ms-api/user/getUserListNoPage'
}

export const sysUserRefAgreementApi = {
  add: (p) => postDataAction('/ms-api/sysUserRefAgreement/add', p), // 新增或修改
  delete: (p) => postDataAction('/ms-api/sysUserRefAgreement/delete', p), // 作废
  selectInfoByStaffId: (p) => getAction(`/ms-api/sysUserRefAgreement/selectInfoByStaffId/${p}`) // 查询某个人的所有人事合同
}

export const userSignatureApi = {
  getPersonalSignature: (p) => getAction('/ms-api/user-signature/list', p),
  delSignature: (p) => postAction('/ms-api/user-signature/del', p),
  activeSignature: (p) => postAction('/ms-api/user-signature/active', p),
  getActiveSignature: (p) => getAction('/ms-api/user-signature/get-active', p),
  defaultActiveSignature: (p) => getAction('/ms-api/user-signature/default-active-sign', p),
  addSignature: (p) => postAction('/ms-api/user-signature/add', p)
}

export const deptApi = {
  list: '/ms-api/dept/list',
  add: (p) => postAction('/ms-api/dept/add', p),
  edit: (p) => postAction('/ms-api/dept/edit', p),
  del: '/ms-api/dept/del'
}

export const roleApi = {
  list: '/ms-api/role/list',
  listRole: (p) => getAction('/ms-api/role/list', p),
  add: (p) => postAction('/ms-api/role/add', p),
  edit: (p) => postAction('/ms-api/role/edit', p),
  del: '/ms-api/role/del',
  checkRoleCode: (p) => getAction('/ms-api/role/check-role-code', p),
  getMenusById: (p) => getAction('/ms-api/role/menus', p),
  saveMenusById: (p) => postAction('/ms-api/role/update-menus', p),
  addRoles: (p) => postAction('/ms-api/res-staff-station/addRoles', p),
  staffStationId: (p) => getAction('/ms-api/selectRoleByStaffStationId/staffStationId', p),
}

export const menuApi = {
  list: '/ms-api/menu/list',
  add: (p) => postAction('/ms-api/menu/add', p),
  edit: (p) => postAction('/ms-api/menu/edit', p),
  del: '/ms-api/menu/del'
}

export const menuDataRuleApi = {
  list: '/ms-api/sys-menu-data-rule/list',
  add: '/ms-api/sys-menu-data-rule/add',
  edit: '/ms-api/sys-menu-data-rule/edit',
  del: '/ms-api/sys-menu-data-rule/del'
}

export const dictApi = {
  list: '/ms-api/sys-dict/list',
  add: (p) => postAction('/ms-api/sys-dict/add', p),
  edit: (p) => postAction('/ms-api/sys-dict/edit', p),
  del: '/ms-api/sys-dict/del',
  getItems: (p) => getAction('/ms-api/sys-dict/items', p),
  checkCode: (p) => getAction('/ms-api/sys-dict/check-code', p),
  dataList: function (p) {
    return getAction(this.list, p)
  }
}

export const dictItemApi = {
  list: '/ms-api/sys-dict-item/list',
  add: (p) => postAction('/ms-api/sys-dict-item/add', p),
  edit: (p) => postAction('/ms-api/sys-dict-item/edit', p),
  batchUpdate: (p) => postDataAction('/ms-api/sys-dict-item/batch-update', p),
  del: '/ms-api/sys-dict-item/del',
  getText: (p) => getAction('/ms-api/sys-dict-item/get-text', p),
  deleteByCode: (p) => delAction('/ms-api/sys-dict-item/delByCode', p),
  delete: function (p) {
    return delAction(this.del, p)
  }
}
export const queryControlledCodeApi = {
 list: (p) => getAction('/ms-api/qualifi_controlled_code/queryControlledCodeList', p),
 save: (p) => postDataAction('/ms-api/qualifi_controlled_code/saveControlledCodeList', p),
 delete: (p) => getAction('/ms-api/qualifi_controlled_code/deleteControlledCodeList',p),
}
export const systemPlanApi = {
  list: (p) => postDataAction('/ms-api/file-system-plan/list', p),
  saveOrUpdate: (p) => postDataAction('/ms-api/file-system-plan/saveOrUpdate', p),
  delete: (p) => postDataAction(`/ms-api/file-system-plan/delete/${p}`),
}

export const fieldApi = {
  list: (p) => getAction('/ms-api/sys-field/list', p),
  listGroup: (p) => getAction('/ms-api/sys-field/list-group', p),
  insertOrUpdate: (p) => postAction('/ms-api/sys-field/add-update', p),
  del: '/ms-api/sys-field/del',
  relationList: (p) => getAction('/ms-api/sys-field/relation-list', p),
  updateRelationList: (p) => postAction('/ms-api/sys-field/update-relation-list', p),
  listModelGroupField: (p) => getAction('/ms-api/sys-field/list-model-group-field', p),
  delModelField: (p) => getAction('/ms-api/sys-field/del-model-field', p),
  updateModelField: (p) => postAction('/ms-api/sys-field/model-field-update', p),
  modelGroupInsertOrUpdate: (p) => postAction('/ms-api/sys-field/model-group-update', p),
  queryDataCodeList: (p) => getAction('/ms-api/sys-field/query-dataCode-list', p)
}

const checkFieldPrefix = '/ms-api/sys-model-field-check'
/** 检测模型字段配置接口 */
export const checkFieldApi = {
  /**
   * 列表查询
   * @param param
   * @return {Promise} 查询结果
   */
  list: (param) => getAction(`${checkFieldPrefix}/list`, param),

  /**
   * 保存检测模型字段配置
   * @param data 保存对象
   * @return {Promise} 保存结果
   */
  save: (data) => postDataAction(`${checkFieldPrefix}/save`, data)
}

/** 模型类型枚举 */
export const sysModelTypeEnum = {
  // 采样
  sample: '1',
  // 检测
  check: '2',
  // 标准
  standard: '3',
  // 流程
  flow: '4',
  // 现场环境
  siteEnv: '5',
  // 每日环境
  dailyEnv: '6'
}

export const sysModelTypeOptions = [
  { label: '采样', value: sysModelTypeEnum.sample },
  { label: '检测', value: sysModelTypeEnum.check },
  { label: '标准', value: sysModelTypeEnum.standard },
  { label: '流程', value: sysModelTypeEnum.flow },
  { label: '现场环境', value: sysModelTypeEnum.siteEnv },
  { label: '每日环境', value: sysModelTypeEnum.dailyEnv }
]

export const sysModelTypeMap = tranOptionsToMap(sysModelTypeOptions)
export const sysModelTypeDataMap = tranOptionsToDataMap(sysModelTypeOptions)
