// eslint-disable-next-line
import { UserLayout, BlankLayout } from '@/layouts'
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/pages/Login')
      }
      // ,
      // {
      //   path: 'register',
      //   name: 'register',
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      // },
      // {
      //   path: 'register-result',
      //   name: 'registerResult',
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      // }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/404')
  },
  {
    //  permission.js 中配置白名单
    path: '/cloud-quote/plan-quote-view',
    name: 'plan-quote-view',
    component: () => import('@/pages/cloud-quote/bj-plan-quote/PlanQuoteViewMgr'),
    meta: { title: '方案报价信息', keepAlive: true }
  },
  {
    path: '/wechat',
    name: 'wechat',
    component: () => import(/* webpackChunkName: "wechat" */ '@/pages/wechat/centerList'),
    meta: { title: '账号绑定', keepAlive: true }
  },
  {
    path: '/cloud-quote/quote-download-preview',
    name: 'quote-download-preview',
    component: () => import('@/pages/cloud-quote/bj-plan-quote/modules/QuoteDownloadPreview'),
    meta: { title: '方案报价下载', keepAlive: true }
  },
  {
    path: '/cloud-quote/take-sample-record',
    name: 'take-sample-record',
    component: () => import('@/pages/cloud-quote/take-sample/modules/sampleRecord'),
    meta: { title: '原始记录表', keepAlive: true }
  },
  {
    path: '/common/proj-progress-details',
    name: 'proj-progress-details',
    component: () => import('@/pages/cloud-quote/project/modules/ProjectMsgLog'),
    meta: { title: '项目进度详情', keepAlive: true }
  },
  {
    path: '/cloud-quote/system-documents',
    name: 'system-documents',
    component: () => import('@/pages/file-system-manual/FileListForExternal'),
    meta: { title: '体系文件', keepAlive: true }
  },
  {
    path: '/cloud-quote/report-file-preview',
    name: 'report-file-preview',
    component: () => import('@/pages/cloud-quote/report/task/moudles/reportTemplate/FilePreviewExternal'),
    meta: { title: '检测报告', keepAlive: true }
  }
]
