<template>
  <a-card hoverable style="min-width: 300px">
    <a-card-meta>
      <canvas ref="headCanvas" slot="avatar" style="width: 50px; height: 50px;"></canvas>
      <template #title>
        {{ userInfo.realname }}
        <a-tag color="red" v-if="userInfo.limitTime!=null && moment().isAfter(userInfo.limitTime)"> 过期 </a-tag>
        <a-tag color="blue" v-else-if="userInfo.status === '1'">正常</a-tag>
        <a-tag color="red" v-else-if="userInfo.status === '0'">禁用</a-tag>
      </template>
      <template #description>
        <p v-if="userInfo.realname" style="line-height: 32px;">姓名：{{ userInfo.realname }}
          <a-icon v-if="userInfo.sex === '1'" type="woman" style="color: #eb2f96" title="女" />
          <a-icon v-else-if="userInfo.sex === '0'" type="man" style="color: #3aa0ff" title="男" />
        </p>
        <p v-if="userInfo.phone" style="line-height: 32px;">手机号码：{{ userInfo.phone }}</p>
        <p v-if="userInfo.email" style="line-height: 32px;">邮箱：{{ userInfo.email }}</p>
        <p v-if="userInfo.depts" style="line-height: 32px;">部门：
          <a-tag v-for="(obj,idx) in userInfo.depts" :key="idx" style="max-width: 95%">
            <span class="text-ellipsis">
              <a-icon v-if="obj.leader" type="user" style="color: #1890FF"/> {{ obj.deptName }}
            </span>
          </a-tag>
        </p>
        <p v-if="userInfo.job" style="line-height: 32px;">岗位：
          <a-tag v-if="staffInfo.job!=null" v-for="(obj,idx) in staffInfo.job" :key="idx">
            <span class="text-ellipsis">{{ stationMap[obj].stationName }}</span>
          </a-tag>
        </p>
        <!--        <p>角色：-->
        <!--          <a-tag v-for="(obj,idx) in userInfo.roles" :key="idx">{{ obj.roleName }}</a-tag>-->
        <!--        </p>-->
      </template>
    </a-card-meta>
  </a-card>
</template>

<script>
import { getAction } from '@/api/common'
import { Get, resStaffApi, resStaffStationApi } from '@/api/quote'
import { userApi } from '@/api/system'
import moment from 'moment'

export default {
  name: 'UserBaseInfo',
  props: {
    username: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      userInfo: {},
      staffInfo: {},
      stationMap: {}
    }
  },
  watch: {
    userInfo: {
      immediate: true,
      handler (val) {
        if (val != null && val.hasOwnProperty('id')) {
          this.getStaffDataByUserId()
        }
      }
    },
    username: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null) {
          this.getUserInfoByUserName(val)
        }
      }
    }
  },
  created () {
    this.initStationList()
  },
  methods: {
    moment,
    initStationList () {
      Get(resStaffStationApi.listAll, { }).then((res) => {
        if (res.code === 0) {
          this.stationMap = {}
          res.data.forEach(item => {
            this.stationMap[item.id] = item
          })
        }
      })
    },
    getStaffDataByUserId () {
      resStaffApi.getOne({ id: this.userInfo.id }).then(res => {
        if (res.code === 0) {
          this.staffInfo = res.data
          if (this.staffInfo.job !== null) {
            this.staffInfo.job = this.staffInfo.job.split(',')
          }
        }
      })
    },
    getUserInfoByUserName (name) {
      getAction(`${userApi.list}`, { username: name, page: false }).then(res => {
        if (res.code === 0 && res.data.length > 0) {
          this.userInfo = res.data[0]
          this.genHead()
        }
      })
    },
    genHead () {
      const firstName = this.userInfo.realname.charAt(0)
      const fontSize = 25
      const fontWeight = 'bold'
      const canvas = this.$refs.headCanvas
      canvas.width = 50
      canvas.height = 50
      const context = canvas.getContext('2d')
      context.fillStyle = '#f7f7f9'
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.fillStyle = '#605ca8'
      context.font = fontWeight + ' ' + fontSize + 'px sans-serif'
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.fillText(firstName, fontSize, fontSize)
    }
  }
}
</script>

<style scoped>

</style>
