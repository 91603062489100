import '@/assets/less/modal/full-modal.less'
import { isReactive, reactive } from 'vue'

export const footerProps = {
  style: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e9e9e9',
    padding: '10px 16px',
    background: '#FFFFFF',
    textAlign: 'right'
  }
}

const modalBaseData = {
  visible: false,
  centered: true,
  closable: false,
  destroyOnClose: true,
  maskClosable: false
}
const fullModalBaseData = {
  ...modalBaseData,
  width: '100%',
  wrapClassName: 'full-modal-wrapper',
  bodyStyle: { background: '#F0F2F5' }
}

const fullModal = {
  data () {
    return {
      fullModal: Object.assign({}, fullModalBaseData, {
        cancel: () => (this.fullModal.visible = false),
        show: () => (this.fullModal.visible = true)
      }),
      footerProps
    }
  }
}

export const fullModalData = Object.assign({}, fullModalBaseData, {
  show () {
    this.visible = true
  },
  cancel () {
    this.visible = false
  }
})

/**
 *
 * @type {{wrapClassName: string, visible: boolean, centered: boolean, closable: boolean, width: string, bodyStyle: {background: string}, destroyOnClose: boolean, maskClosable: boolean}}
 */
export const fullModalProps = Object.assign({}, fullModalBaseData)

/**
 * 生成弹窗的action
 * @param modalProps 表单的对象
 * @param okFun {Function} 确定函数
 * @param cancelSetup {Function} 取消的前置函数
 * @param cancelTearDown {Function} 取消的后置函数
 * @param showSetUp {Function}显示的前置函数
 * @param showTeardown {Function} 显示的后置函数
 * @return {{cancel: Function, show: Function,ok:Function}}
 */
export const createModalAction = (modalProps, okFun = null, cancelSetup = null, cancelTearDown = null, showSetUp = null, showTeardown = null) => {
  return {
    cancel: () => {
      cancelSetup?.()
      modalProps.visible = false
      cancelTearDown?.()
    },
    show: (...args) => {
      showSetUp?.(...args)
      modalProps.visible = true
      showTeardown?.(...args)
    },
    ok: () => okFun?.()
  }
}

/**
 * 全屏弹窗钩子
 * @param modalData {Object} 弹窗属性对象
 * @param okFun {Function} 确定函数
 * @param cancelSetup {Function} 取消的前置函数
 * @param cancelTearDown {Function} 取消的后置函数
 * @param showSetup {Function}显示的前置函数
 * @param showTeardown {Function} 显示的后置函数
 * @return {{modalProps: *, modalAction: {cancel: Function, show: Function,ok:Function}}} 返回弹窗属性对象和弹窗方法对象
 */
export const useFullModal = (modalData = {}, okFun = null, cancelSetup = null, cancelTearDown = null, showSetup = null, showTeardown = null) => {
  const modalProps = reactive(Object.assign({}, fullModalBaseData, modalData))
  return useModal(modalProps, okFun, cancelSetup, cancelTearDown, showSetup, showTeardown)
}

/**
 * 弹窗钩子
 * @param modalData {Object} 弹窗属性对象
 * @param okFun {Function} 确定函数
 * @param cancelSetup {Function} 取消的前置函数
 * @param cancelTearDown {Function} 取消的后置函数
 * @param showSetup {Function}显示的前置函数
 * @param showTeardown {Function} 显示的后置函数
 * @return {{modalProps: *, modalAction: {cancel: Function, show: Function,ok:Function}}} 返回弹窗属性对象和弹窗方法对象
 */
export const useModal = (modalData = {}, okFun = null, cancelSetup = null, cancelTearDown = null, showSetup = null, showTeardown = null) => {
  const modalProps = reactive(Object.assign({}, modalBaseData, modalData))
  const modalAction = createModalAction(modalProps, okFun, cancelSetup, cancelTearDown, showSetup, showTeardown)
  return { modalProps, modalAction }
}

export default fullModal
