const defaultColumn = [
  { title: '类目名称', dataIndex: 'name', sorter: true, scopedSlots: { customRender: 'name' } },
  { title: '试剂等级', dataIndex: 'grade', sorter: true, width: 150, scopedSlots: { customRender: 'grade' } },
  { title: '处理方式', dataIndex: 'needMake', width: 120, scopedSlots: { customRender: 'needMake' } },
  { title: '形状', dataIndex: 'solution', width: 100, scopedSlots: { customRender: 'solution' } },
  { title: '溶液类型', dataIndex: 'solutionType', sorter: true, width: 121, scopedSlots: { customRender: 'solutionType' } },
  { title: '浓度', dataIndex: 'purity', sorter: true, width: 121, scopedSlots: { customRender: 'purity' } },
  { title: '规格', dataIndex: 'speType', width: 120, scopedSlots: { customRender: 'speType' } },
  { title: '有效期', dataIndex: 'validDate', width: 100, scopedSlots: { customRender: 'validDate' } },
  { title: '维护人', dataIndex: 'updateUserName', width: 100, scopedSlots: { customRender: 'updateUserName' } },
  { title: '维护时间', dataIndex: 'updateTime', width: 180, scopedSlots: { customRender: 'updateTime' } },
  { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } }
]

const selectColumn = [
  { title: '类目名称', dataIndex: 'name', sorter: true, scopedSlots: { customRender: 'name' } },
  { title: '试剂等级', dataIndex: 'grade', sorter: true, scopedSlots: { customRender: 'grade' } },
  { title: '处理方式', dataIndex: 'needMake', scopedSlots: { customRender: 'needMake' } },
  { title: '形状', dataIndex: 'solution', scopedSlots: { customRender: 'solution' } },
  { title: '溶液类型', dataIndex: 'solutionType', sorter: true, scopedSlots: { customRender: 'solutionType' } },
  { title: '浓度', dataIndex: 'purity', sorter: true, scopedSlots: { customRender: 'purity' } },
  { title: '规格', dataIndex: 'speType', scopedSlots: { customRender: 'speType' } },
  { title: '有效期', dataIndex: 'validDate', scopedSlots: { customRender: 'validDate' } }
]

const selectActionColumn = [...selectColumn,
  { title: '操作', dataIndex: 'action', width: 100, align: 'left', scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as CheckReagentCateDefaultColumn,
  selectColumn as CheckReagentCateSelectColumn,
  selectActionColumn as CheckReagentCateSelectActionColumn
}
