<template>
  <a-date-picker
    :disabled="readOnly"
    :placeholder="placeholder"
    @change="handleDateChange"
    :value="momVal"
    :showTime="showTime"
    :format="dateFormat"
    :getCalendarContainer="getCalendarContainer"
    :disabledDate="disabledDate"
  />
</template>
<script>
import moment from 'moment'
import { cloneDeep } from 'lodash'
export default {
  name: 'JDate',
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: String,
      required: false
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
      required: false
    },
    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false
    },
    getCalendarContainer: {
      type: Function,
      default: () => document.body
    },
    disabledDateObj: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    const dateStr = this.value
    return {
      decorator: '',
      momVal: !dateStr ? null : moment(dateStr, this.dateFormat)
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.momVal = null
      } else {
        this.momVal = moment(val, this.dateFormat)
      }
    }
  },
  methods: {
    moment,
    handleDateChange(mom, dateStr) {
      if (this.triggerChange) {
        this.$emit('change', dateStr)
      } else {
        this.$emit('input', dateStr)
      }
    },
    disabledDate(current) {
      if (this.disabledDateObj && this.disabledDateObj.isDisabledDate) {
        let date = moment()
        if (this.disabledDateObj.days || this.disabledDateObj.days === 0) {
          date = moment().add(this.disabledDateObj.days, 'days')
        }
        return current && moment(current).isBefore(date, 'day')
      } else {
        return false
      }
    }
  }
}
// note: do not set the prop value one default property
</script>
