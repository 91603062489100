<template>
  <div>
    <ProjectAuditCheck :auditId="processId"/>
  </div>
</template>

<script>
import ProjectAuditCheck from './modules/ProjectAuditCheck'

export default {
  name: 'ProjectFormMgr',
  components: {
    ProjectAuditCheck
  },
  props: {
    processId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less">
  .form-steps {
    max-width: 900px;
    margin: 16px auto;
  }
</style>
