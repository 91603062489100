<template>
  <div class="destroy-form">
    <preview-form :data="generateFormProps.jsonData" :remote="remote" ref="generateForm" v-if="generateFormProps.visible" v-model="models">
      <template slot="dealUser">
        <span>{{ models.dealUserName }}</span>
      </template>

      <template #sampleDestroyItemName="{model}">
        <a-tooltip>
          <template #title>{{ model.itemNameStr }}</template>
          <div class="ahmux-text-ellipsis" :style="{width:'100%',float:'left'}">{{ model.itemNameStr }}</div>
        </a-tooltip>
      </template>

      <template #sampleDestroyProjName="{model}">
        <a-tooltip>
          <template #title>{{ model.projName }}</template>

          <div class="ahmux-text-ellipsis" :style="{width:'100%',float:'left'}">
            <template v-if="model.projStatus==='-1'">
              <a-tag color="red" :style="{marginBottom:'0px'}">已终止</a-tag>
            </template>
            <template v-else-if="model.projStatus==='2'">
              <a-tag color="red" :style="{marginBottom:'0px'}">临时终止</a-tag>
            </template>
            {{ model.itemNameStr }}
          </div>
        </a-tooltip>
      </template>

      <template #sampleDestroySampleType="{model}">
        <a-tag :color="sampleInfoTypeDataMap[model.type]&&sampleInfoTypeDataMap[model.type].color||'red'">
          {{ model.type === sampleInfoTypeEnum.password ? sampleInfoTypeDataMap[sampleInfoTypeEnum.normal].label : sampleInfoTypeDataMap[model.type] && sampleInfoTypeDataMap[model.type].label || '未知' }}
        </a-tag>
      </template>
    </preview-form>
    <template v-else>
      <div class="load">
        <a-spin class="content" size="large" tip="页面渲染中..."></a-spin>
      </div>
    </template>
  </div>
</template>

<script>
import date from '@/pages/common/mixins/date'
import { customFormFunc } from '@/pages/flow/module/CustomFormFunc'
import { sampleInfoDestroyApi } from '@/api/sampleInfo/sampleInfoDestroyApi'

import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { getAction } from '@/api/common'
import { modelApi } from '@/api/flow'
import { sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

export default {

  components: { SelectUserByDept },

  mixins: [date],

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      sampleInfoTypeDataMap,
      sampleInfoTypeEnum,

      // 自定义表单数据
      customData: {},

      scrollTop: 0,

      flowKey: 'ts_sample_info_destroy',

      models: {},

      remoteFuncs: {},

      // 表单生成组件类型参数
      generateFormProps: {
        url: null,
        visible: false,
        jsonData: null
      }
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    },

    // 自定义表单远程方法 ，外部组件传递的远程方法优先级高于内部自定义
    remote () {
      customFormFunc._self = this
      return Object.assign({}, customFormFunc, this.remoteFuncs)
    }
  },

  watch: {

    // 自定义表单数据 解析工作
    customData: {
      handler (data) {
        if (data) {
          const jsonData = JSON.parse(data.json)
          this.generateFormProps.jsonData = jsonData
          this.generateFormProps.visible = true
        }
      }
    }
  },

  async created () {
    await this.findDetail()
    this.findCustomForm()
  },

  mounted () {
    // 修复浏览器滚动条位置可能导致elementUI弹出层定位偏移问题
    this.scrollTop = window.document.body.scrollTop
    window.document.body.scrollTop = 0
  },

  destroyed () {
    // 重置浏览器滚动条的位置
    window.document.body.scrollTop = this.scrollTop
  },

  methods: {

    // 查询分组信息
    findCustomForm () {
      getAction(modelApi.getCustomForm, { processId: this.flowKey }).then(res => {
        if (res.code === 0) {
          this.customData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    findDetail () {
      return sampleInfoDestroyApi.findDetail({ id: this.id }).then(res => {
        if (res.code === 0) {
          console.log('res.data=>', res.data)
          this.models = res.data[0]
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "index.less";
</style>
