import { useDate } from '@/pages/common/compositions/date/useDate'

const { moment, weekChinese, timeMomentProps, dateRangeProps, now, dateFormat: dateFormatFun, timeFormat: timeFormatFun, getMomentRangeByKey } = useDate()
export const timeFormat = 'YYYY-MM-DD HH:mm:ss'
export const shortTimeFormat = 'YYYY-MM-DD HH:mm'
export const minuteFormat = 'HH:mm'
export const dateFormat = 'YYYY-MM-DD'

const date = {
  data() {
    return {
      dateFormatStr: dateFormat,
      timeFormatStr: timeFormat,
      shortTimeFormatStr: shortTimeFormat,
      minuteFormatStr: minuteFormat,
      weekChinese,
      timeMomentProps,
      dateRangeProps
    }
  },
  methods: {
    moment,
    now,
    dateFormat: dateFormatFun,
    timeFormat: timeFormatFun,
    getMomentRangeByKey
  }
}

const mixinDate = {
  mixins: [date]
}

export default mixinDate
