<template>
  <a-modal :title="title" :visible="visible" :width="modalWidth" @cancel="close" @ok="handleSubmit" cancelText="关闭" style="margin-top: -70px" wrapClassName="ant-modal-cust-warp">
    <a-row :gutter="10" style="background-color: #ececec; padding: 10px; margin: -10px">
      <a-col :md="6" :sm="24">
        <a-card :bordered="false">
          <!--组织机构-->
          <a-directory-tree
            :checkStrictly="true"
            :defaultExpandedKeys="defaultExpandedKeys"
            :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
            :selectedKeys="selectedKeys"
            :treeData="departTree"
            @select="this.onSelect"
            selectable
            v-if="departTree.length > 0"
          />
        </a-card>
      </a-col>
      <a-col :md="18" :sm="24">
        <a-card hoverable v-bind="actionCard">
          用户账号:
          <a-input-search
            :style="{ width: '200px', marginBottom: '15px' }"
            @search="onSearch"
            enterButton
            placeholder="请输入用户账号"
            v-model="queryParam.username"
          ></a-input-search>
          <a-button @click="searchReset(1)" icon="redo" style="margin-left: 20px">重置</a-button>
          <!--用户列表-->
          <a-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: this.rowType }"
            :scroll="scrollTrigger"
            @change="handleTableChange"
            ref="table"
            rowKey="id"
            size="middle"
          ></a-table>
        </a-card>

        <a-card hoverable v-bind="contentCard">
          <div style="min-height: 20px; height: auto">
            <template v-for="(obj, idx) in selectList">
              <a-tag :key="idx" closable :visible="true" @close="del(obj.id)" style="margin: 4px; font-size: 14px">{{ obj.name }}</a-tag>
            </template>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { getAction } from '@/api/common'
import { filterObj } from '@/utils/util'
import { uniqBy } from 'lodash'
import { deptApi, userApi } from '@/api/system'

export default {
  name: 'SelectUserByDeptModal',
  components: {},
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    // 列表选择框类型 checkbox or radio
    rowType: {
      type: String,
      required: false,
      default: 'checkbox'
    }
  },
  data() {
    return {
      defaultExpandedKeys: [],
      lastRowKeyLength: 0,
      selectList: [],
      actionCard: {
        title: '选择用户',
        style: {},
        headStyle: {},
        bodyStyle: { background: '#D7DDE312' },
        bordered: true,
        type: ''
      },

      contentCard: {
        title: '已选用户',
        style: { marginTop: '24px' },
        headStyle: {},
        bodyStyle: {},
        bordered: false,
        type: 'inner'
      },
      queryParam: {
        username: ''
      },
      columns: [
        { title: '用户账号', align: 'center', dataIndex: 'username' },
        { title: '真实姓名', align: 'center', dataIndex: 'realname' },
        { title: '角色名称', align: 'center', dataIndex: 'roleName' },
        {
          title: '性别',
          align: 'center',
          dataIndex: 'sex',
          customRender: function (text) {
            if (text === '0') {
              return '男'
            } else if (text === '1') {
              return '女'
            } else {
              return text
            }
          }
        },
        { title: '手机号码', align: 'center', dataIndex: 'phone' },
        { title: '邮箱', align: 'center', dataIndex: 'email' }
      ],
      scrollTrigger: {},
      dataSource: [],
      selectedKeys: [],
      userNameArr: [],
      departName: '',
      userRolesMap: {},
      title: '根据部门选择用户',
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      selectedRowKeys: [],
      selectedRows: [],
      cache: {},
      departTree: [],
      visible: false,
      form: this.$form.createForm(this)
    }
  },
  created() {
    // 该方法触发屏幕自适应
    this.resetScreenSize()
    // this.queryUserRoleMap()
    this.loadData(1)
  },
  methods: {
    del(id) {
      const index = this.selectedRowKeys.indexOf(id)
      this.selectedRowKeys.splice(index, 1)
    },
    loadData(arg) {
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams() // 查询条件
      if (this.selectedKeys.length > 0) {
        params.deptId = this.selectedKeys[0]
      }
      getAction(userApi.getUserList, params).then((res) => {
        this.dataSource = res.data
        this.updateCache(this.dataSource)
        this.assignRoleName(this.dataSource)
        this.ipagination.total = res.totalCount
      })
    },
    updateCache(list) {
      if (list) {
        list.forEach((obj) => {
          obj.id = obj.id || obj.userId
          obj.name = obj.realname || obj.name
          obj.userId = obj.id
          this.cache[obj.id] = obj
        })
      }
    },
    queryUserRoleMap() {
      // queryUserRoleMap().then((res) => {
      //   if (res.success) {
      //     this.userRolesMap = res.result
      //     this.loadData()
      //   }
      // })
    },
    // 触发屏幕自适应
    resetScreenSize() {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.scrollTrigger = { x: 800 }
      } else {
        this.scrollTrigger = {}
      }
    },
    showModal(selectList) {
      this.visible = true
      this.selectedRowKeys = []
      this.selectList = selectList
      this.initRowkeyByselectList(selectList)
      this.assignRoleName(this.dataSource)
      this.queryDepartTree()
      this.form.resetFields()
    },
    initRowkeyByselectList(selectList) {
      this.updateCache(selectList)
      for (let i = 0; i < this.selectList.length; i++) {
        this.selectedRowKeys.push(selectList[i].userId)
      }
    },
    getQueryParams() {
      const param = Object.assign({}, this.queryParam, this.isorter)
      param.field = this.getQueryField()
      param.userName = this.queryParam.userName
      param.pageNo = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    getQueryField() {
      let str = 'id,'
      for (let a = 0; a < this.columns.length; a++) {
        str += ',' + this.columns[a].dataIndex
      }
      return str
    },
    searchReset(num) {
      const that = this
      that.selectedRowKeys = []
      that.userNameArr = []
      that.selectedKeys = []
      if (num !== 0) {
        that.queryParam = {}
        that.loadData(1)
      }
    },
    close() {
      this.searchReset(0)
      this.visible = false
    },
    handleTableChange(pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    handleSubmit() {
      this.$emit('ok', this.selectList)
      this.close()
    },
    // 遍历匹配,获取用户真实姓名
    getUserNames(rowId) {
      const dataSource = this.dataSource
      for (let i = 0, len = dataSource.length; i < len; i++) {
        if (rowId === dataSource[i].id) {
          this.userNameArr.push(dataSource[i].realname)
        }
      }
    },

    getUserName(rowId) {
      let name = '未知'
      const dataSource = this.dataSource
      for (let i = 0, len = dataSource.length; i < len; i++) {
        if (rowId === dataSource[i].id) {
          name = dataSource[i].realname
        }
      }
      return name
    },

    // 根据ID 获取用户信息
    getUser(rowId) {
      return this.dataSource.find((data) => data.id === rowId) || null
    },

    // 点击树节点,筛选出对应的用户
    onSelect(selectedKeys) {
      /* if (selectedKeys[0] != null) {
        this.queryUserByDeptId(selectedKeys) // 调用方法根据选选择的id查询用户信息
        if (this.selectedKeys[0] !== selectedKeys[0]) {
          this.selectedKeys = [selectedKeys[0]]
        }
      } */
      this.selectedKeys = selectedKeys
      this.loadData(1)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
    },
    onSearch() {
      this.loadData(1)
    },
    // 根据选择的id来查询用户信息
    queryUserByDeptId(selectedKeys) {
      userApi.findUserByDept({ deptId: selectedKeys.toString() }).then((res) => {
        if (res.code === 0) {
          const resData = (res.data && res.data.length && uniqBy(res.data, 'id')) || []
          this.dataSource = resData
          this.updateCache(this.dataSource)
          this.ipagination.total = resData.length
          this.assignRoleName(this.dataSource)
        }
      })
    },
    // 传入用户id,找到匹配的角色名称
    queryUserRole(userId) {
      const map = this.userRolesMap
      const roleName = []
      for (const key in map) {
        if (userId === key) {
          roleName.push(map[key])
        }
      }
      return roleName.join(',')
    },
    queryDepartTree() {
      getAction(deptApi.list).then((res) => {
        if (res.code === 0) {
          this.defaultExpandedKeys.push(res.data[0].key)
          this.departTree = res.data
        }
      })
    },
    // 为角色名称赋值
    assignRoleName(data) {
      let userId = ''
      let role = ''
      for (let i = 0, length = data.length; i < length; i++) {
        userId = this.dataSource[i].id
        role = this.queryUserRole(userId)
        this.dataSource[i].roleName = role
      }
    },
    modalFormOk() {
      this.loadData()
    },
    selected2List(nv) {
      const keys = this.selectedRowKeys
      var tempList = []
      for (let i = 0; i < keys.length; i++) {
        const obj = this.cache[keys[i]]
        tempList.push(Object.assign({}, obj))
      }
      this.selectList = tempList
    },
    searchList(userName) {
      this.$set(this.queryParam, 'username', userName)
      this.loadData(1)
      const params = this.getQueryParams() // 查询条件
      Object.assign(params, { pageSize: 1000 })
      getAction(userApi.getUserList, params).then((res) => {
        if (res.data && res.data.length > 0) {
          let obj = {
            ...res.data[0],
            id: res.data[0].id || res.data[0].userId,
            name: res.data[0].realname || res.data[0].name,
            userId: res.data[0].id
          }
          this.selectList = [obj]
          this.selectedRowKeys.splice(0, this.selectedRowKeys.length, res.data[0].id)
          this.$emit('ok', this.selectList)
        }
      })
    }
  },
  watch: {
    selectedRowKeys(nv, ov) {
      this.selected2List(nv)
    }
  }
}
</script>

<style lang="less">
.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
