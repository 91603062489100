<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="methodVisible"
    @ok="methodSubmit"
    @cancel="close">
    <div>
      <a-form :form="form" v-if="isRouterAlive">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="方法名">
          <a-select mode="tags"  style="width: 100%" :token-separators="[',']" v-decorator="['id', {rules:[{ required: true, message: '请选择方法' }]}]" @change="handleChange" >
            <a-select-option v-for="m in methodList" :key="m.id.toString()">
              {{ m.name}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="请选择标准">
          <select-standard ref="selectStandardModal" v-decorator="['standardIdList', {rules:[{ required: true, message: '请选择接标准' }]}]" :disabled="false" :triggerChange="true" @change="standardChange"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="方法原理">
          <a-textarea :rows="5" placeholder="..." v-decorator="['principle', {initialValue: model.principle, rules: [{}]}]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="干扰及消除">
          <a-textarea :rows="5" placeholder="..." v-decorator="['interference', {initialValue: model.interference, rules: [{}]}]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="描述信息">
          <a-textarea :rows="5" placeholder="..." v-decorator="['description', {initialValue: model.description, rules: [{}]}]" />
        </a-form-item>

      </a-form>

    </div>
  </a-modal>
</template>

<script>
import { Get, jcMethodApi, Post, resMaterialApi } from '@/api/quote'
import SelectStandard from '@/pages/common/SelectStandard'
import { postDataAction } from '@/api/common'

export default {
  name: 'MethodForm',
  components: {
    SelectStandard
  },
  props: {
    title: {
      type: String,
      default: '添加方法'
    }
  },
  data () {
    return {
      methodList: [],
      isRouterAlive: true,
      methodVisible: false,
      standardMethodList: [],
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 20
      },
      model: {},
      isEdit: true,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    handleChange(value){
      var self = this
      if(value!=null&&value.length>0){
        var d = self.methodList.filter(item=>item.id == value[value.length-1]);
        if(d.length>0){
          this.form.$nextTick(() => {
            this.form.setFieldsValue({ id: [d[0].id.toString()]})
          })
          return
        }
        jcMethodApi.checkAdd({ name: value[value.length-1] }).then((res) => {
          const list = res.data
          this.model = list[0]
          if (list.length > 0) {
            self.methodList.push(list[0])
            this.form.$nextTick(() => {
              this.form.setFieldsValue({ id: [list[0].id.toString()]})
            })
          }
        })

      }else{
        this.form.$nextTick(() => {
          this.form.setFieldsValue({ name: []})
        })
      }

    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
        setTimeout(() => {
          if (this.model.standardList && this.model.standardList.length > 0) {
            this.model.standardList.forEach(r =>
              this.standardMethodList.push({ 'name': r.standardCode, 'id': r.standardId })
            )

            this.$refs.selectStandardModal.setStandardList(this.standardMethodList)
          }
        }, 0)
      })
    },
    add () {
      this.edit({})
    },
    edit (record) {
      this.methodVisible = true
      this.isEdit = true
      this.standardMethodList = []
      this.model = {}
      if (record.id) {
        this.isEdit = true
        this.model = record
      }
      this.reload()
    },
    standardChange (selectedStandardList) {
      this.selectedStandardList = selectedStandardList
      this.form.$nextTick(() => {
        this.form.setFieldsValue({ standardIdList: this.getStandardNameStr() })
      })
    },
    validateName (rule, value, callback) {
      if (value) {
        jcMethodApi.checkName({ name: value }).then((res) => {
          const list = res.data
          if (list.length > 1) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('方法名已存在')
          } else if (list.length === 1) {
            if (this.model.methodId === undefined || list[0].id !== this.model.methodId) {
              // eslint-disable-next-line standard/no-callback-literal
              callback('方法名已存在')
            } else {
              callback()
            }
          } else {
            callback()
          }
        })
      } else {
        callback()
      }
    },
    methodSubmit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          const formData = Object.assign(this.model, values)
          formData.standardIds = this.getStandardIdStr()
          if(formData.id.length>0){
            formData.id = formData.id[0]
          }
          formData.updateFlag = false
          postDataAction(jcMethodApi.edit, formData).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.close()
              this.$emit('ok', res.data)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    getStandardIdStr () {
      let standardIds = ''
      const selectedStandardList = this.selectedStandardList
      if (!selectedStandardList) {
        return standardIds
      }
      for (let i = 0; i < selectedStandardList.length; i++) {
        if (i === selectedStandardList.length - 1) {
          standardIds = standardIds + selectedStandardList[i].id
        } else {
          standardIds = standardIds + selectedStandardList[i].id + ','
        }
      }
      return standardIds
    },

    getStandardNameStr () {
      const selectedStandardList = this.selectedStandardList
      if (!selectedStandardList) {
        return ''
      }
      return selectedStandardList.map(v => v.name).join(',')
    },
    close () {
      this.methodVisible = false
      this.selectedStandardList = []
    }

  },
  created () {
    var self = this;
    Get(jcMethodApi.listAll, {}).then(result => {
      self.methodList = result.data;
    })
  }

}
</script>

<style scoped>

</style>
