<template>
  <div><!-- style="min-height: 600px;"-->
    <div :style="{width:'1090px',margin:'0 auto',padding:'48px 24px 24px 24px'}">
      <!--      <a-card style="padding: 24px;margin-top: 20px;">-->
      <preview-form
        :data="previewFormProps.jsonData"
        :remote="remoteFuncs"
        :viewMode="viewMode"
        :isEditForm="isEditForm"
        v-if="previewFormProps.visible && !editCustomForm"
        v-model="models">
        <template #relationProcessId="{model,compStyle:style,compClass:clazz}">
          <SearchFlowView
            :value="model.relationProcessId"></SearchFlowView>
        </template>
        <template #projName="{model,compStyle:style,compClass:clazz}">
          <SearchProjectView
            :value="model.projName"></SearchProjectView>
        </template>
        <template #relProjName="{model,compStyle:style,compClass:clazz}">
          <SearchProjectView
            :value="model.relProjName"></SearchProjectView>
        </template>

        <template slot="dealUser">
          <span >{{ models.dealUserName }}</span>
        </template>
        <template #receiptProjName="{model,compStyle:style,compClass:clazz}">
          <search-project-receipt
            :style="style"
            :clazz="clazz"
            :isEdit="false"
            :projId="model.projId"
            :projName="model.projName"
            searchType="relProjName"
            @change="searchProjectReceipt2SaveData"/>
        </template>
        <template #projectReceiptPlan="{model,compStyle:style,compClass:clazz}">
          <template v-if="model.projType=='1'&&model.projId">
            <ProjectReceiptPlan :projId="model.projId"/>
          </template>
        </template>
        <template #projectReceiptInfo="{model,compStyle:style,compClass:clazz}">
          <template v-if="model.projType=='1'&&model.projId">
            <ProjectReceiptInfo :projId="model.projId"/>
          </template>
        </template>
        <template #staffName="{model,compStyle:style,compClass:clazz}">
          <!--          <search-staff-edit-->
          <!--            :style="style"-->
          <!--            :clazz="clazz"-->
          <!--            :staffId="models.staffId"-->
          <!--            :staffName="models.staffName"-->
          <!--            searchType="staffName"-->
          <!--            @change="(data)=>{if(data){$set(model, 'staffId',data.id);$set(model, 'staffName',data.name);}else{$set(model, 'staffId',null);$set(model, 'staffName','');}}"/>-->
          <template v-if="model.staffId">
            <SearchStaffView :value="model.staffId"></SearchStaffView>
          </template>
        </template>
        <template #enterDept="{model,compStyle:style,compClass:clazz}">
          <!--          <search-dept-edit-->
          <!--            :style="style"-->
          <!--            :clazz="clazz"-->
          <!--            :enterDeptId="models.enterDeptId"-->
          <!--            :enterDept="models.enterDept"-->
          <!--            searchType="enterDept"-->
          <!--            @change="(data)=>{if(data){$set(model, 'enterDeptId',data.id);$set(model, 'enterDept',data.deptName);}else{$set(model, 'enterDeptId',null);$set(model, 'enterDept','');}}"/>-->
          <template v-if="model.enterDept">
            {{ models.enterDept }}
          </template>
        </template>
        <template #uploadFiles="{file}">
          <a @click="downloadFile(file)" style="padding: 1px;color: #606266"><a-icon style="margin: 0 8px;color: #909399" type="file-text" />{{ file.name }}<a-icon style="margin-left: 16px;color: #67c23a;" type="check-circle" /></a>
        </template>

        <template #sampleDestroyItemName="{model}">
          <a-tooltip>
            <template #title>{{ model.itemNameStr }}</template>
            <div class="ahmux-text-ellipsis" :style="{width:'100%',float:'left'}">{{ model.itemNameStr }}</div>
          </a-tooltip>
        </template>

        <template #sampleDestroyProjName="{model}">
          <a-tooltip>
            <template #title>{{ model.projName }}</template>

            <div class="ahmux-text-ellipsis" :style="{width:'100%',float:'left'}">
              <template v-if="model.projStatus==='-1'">
                <a-tag color="red" :style="{marginBottom:'0px'}">已终止</a-tag>
              </template>
              <template v-else-if="model.projStatus==='2'">
                <a-tag color="red" :style="{marginBottom:'0px'}">临时终止</a-tag>
              </template>
              {{ model.itemNameStr }}
            </div>
          </a-tooltip>
        </template>

        <template #sampleDestroySampleType="{model}">
          <a-tag :color="sampleInfoTypeDataMap[model.type]&&sampleInfoTypeDataMap[model.type].color||'red'">
            {{ model.type === sampleInfoTypeEnum.password ? sampleInfoTypeDataMap[sampleInfoTypeEnum.normal].label : sampleInfoTypeDataMap[model.type] && sampleInfoTypeDataMap[model.type].label || '未知' }}
          </a-tag>
        </template>
        <template #payUser="{model,compStyle:style,compClass:clazz}">
          <template v-if="model.payUser">
            {{model.payUser}}
          </template>
        </template>
        <template #reportBaseInfo="{model,compStyle:style,compClass:clazz}">
          <ReportTaskDetailFlow v-bind="model" v-if="model.projId"></ReportTaskDetailFlow>
        </template>

        <template #receiveInfo="{model,compStyle:style,compClass:clazz}">
          <search-custom-edit
            :style="style"
            :clazz="clazz"
            :searchCustom="model.receiveCustomer"
            :searchCustomUser="model.receiveUser"
            :disabled="true"
          ></search-custom-edit>
        </template>

      </preview-form>
      <!--      </a-card>-->
    </div>
    <div>
      <flow-form-modal ref="flowFormModal" v-if="editCustomForm" :editCustomForm="editCustomForm" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Modal } from 'ant-design-vue'
import { MessageBox, Message } from 'element-ui'
import { modelApi, flowApi } from '@/api/flow'
import { userApi } from '@/api/system'
import { getAction, sysCommonApi } from '@/api/common'
import { customFormFunc } from '@/pages/flow/module/CustomFormFunc'
import FlowFormModal from '@/pages/flow/module/FlowFormModal'
import SearchFlowView from '@/pages/flow/module/components/search-flow/SearchFlowView'
import SearchProjectView from '@/pages/flow/module/components/search-project/SearchProjectView'

import SearchProjectReceipt from '@/pages/flow/module/components/search-project-receipt/SearchProjectReceipt'
import ProjectReceiptPlan from '@/pages/flow/module/components/search-project-receipt/ProjectReceiptPlan'
import ProjectReceiptInfo from '@/pages/flow/module/components/search-project-receipt/ProjectReceiptInfo'

import SearchStaffEdit from '@/pages/flow/module/components/search-staff/SearchStaffEdit'
import SearchStaffView from '@/pages/flow/module/components/search-staff/SearchStaffView'

import SearchDeptEdit from '@/pages/flow/module/components/search-dept/SearchDeptEdit'
import { sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import ReportTaskDetailFlow from './components/search-project/ReportTaskDetailFlow'
import SearchCustomEdit from '@/pages/flow/module/components/search-custom/SearchCustomEdit'

export default {
  name: 'CustomPreviewForm',
  components: {
    SearchCustomEdit,
    ReportTaskDetailFlow,
    FlowFormModal,
    SearchFlowView,
    SearchProjectView,
    SearchProjectReceipt,
    ProjectReceiptPlan,
    ProjectReceiptInfo,
    SearchStaffEdit,
    SearchStaffView,
    SearchDeptEdit
  },
  props: {

    // 流程类型KEY
    processKey: {
      type: String,
      required: true,
      default: ''
    },

    // 流程名称
    processName: {
      type: String,
      required: true,
      default: ''
    },

    // 流程ID
    processId: {
      type: Number,
      required: true,
      default: null
    },
    editCustomForm: {
      type: Boolean,
      required: true,
      default: false
    },

    // 流程操作节点信息
    activeTask: {
      type: Object,
      required: false,
      default: null
    },

    initFormModel: {
      type: Object,
      default: () => ({})
    }

  },

  data () {
    return {
      // 数据承载对象
      models: {},
      sampleInfoTypeDataMap,
      sampleInfoTypeEnum,

      // 预览模式 orgin-原始， text-默认，文本模式
      viewMode: 'text',

      // 数据加载判断标识
      loading: true,

      // 自定义表单查询对象
      queryParam: {
        id: null,
        type: null,
        status: '1'
      },

      // 预览
      previewFormProps: {
        visible: false,
        jsonData: null,
        url: null
      },
      isAssignee: false,
      isEditForm: false
    }
  },

  computed: {
    // 数据查询对象
    queryModelDataParam () {
      return { tableName: this.processKey, flowId: this.processId, processName: this.processName, activeTaskId: this.activeTask.id }
    },

    // 远程方法
    remoteFuncs () {
      customFormFunc._self = this
      return customFormFunc
    }

  },

  created () {
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$message = Message
    this.scrollTop = window.document.body.scrollTop
    window.document.body.scrollTop = 0
  },

  beforeDestroy () {
    Vue.prototype.$confirm = Modal.confirm
    Vue.prototype.$message = require('ant-design-vue/lib/message').default
    window.document.body.scrollTop = this.scrollTop
  },

  watch: {
    queryModelDataParam: {
      async handler (newVal, oldVal) {
        if (newVal.tableName) { // && newVal.flowId
          Promise.all(
            [
              this.findSysModelData(newVal),
              this.findCustomForm(newVal.tableName)
            ]
          ).then(res => {
            if (res.reduce((a, b) => a && b)) {
              this.$nextTick(() => {
                this.previewFormProps.visible = true
                if (this.editCustomForm) {
                  this.$refs.flowFormModal.showByModel(this.previewFormProps.jsonData, this.models, newVal)
                }
              })
            }
          })
        }
      },
      immediate: true
    },
    initFormModel: {
      handler (newVal, oldVal) {
        Object.assign(this.models, newVal)
        this.saveData = this.models
        if (this.previewFormProps.jsonData && this.previewFormProps.jsonData.list) {
          this.previewFormProps.jsonData.list[0].key = Date.now()
        }
      }
    }
  },

  methods: {
    downloadFile (file) {
      window.location = sysCommonApi.downLoad + '?filePath=' + (file.url || file.fileUrl) + '&fileName=' + file.name + '&token=' + this.$store.getters.token
    },
    // 根据流程类型获取自定义表单声明
    findCustomForm (key) {
      return getAction(modelApi.getCustomForm, { processId: key }).then(res => {
        if (res.code === 0) {
          // 对获取的表单进行处理，在当前节点需要显示和操作人填写的
          const username = this.$store.getters.nickname
          const operateUsers = (this.activeTask.hasOwnProperty('assignee') && this.activeTask.assignee != null) ? this.activeTask.assignee.split(',') : []
          this.isAssignee = (operateUsers.includes(username) || username === this.activeTask.assignee && !this.activeTask.endTime && !this.editCustomForm) || false
          const jsonData = JSON.parse(res.data.json)
          if (!this.editCustomForm) {
            const filterModelJSON = jsonData.list
            // let flag = true
            // filterModelJSON.forEach(item => {
            //   if (['card'].includes(item.type)) {
            //     item.isShow = true
            //     if (flag) {
            //       item.isShow = true
            //       if (this.activeTask.name === item.name) {
            //         flag = false
            //         this.isEditForm = true
            //         if (this.isAssignee) item.viewMode = 'edit' // 节点操作人时，该card修改为可编辑模式
            //       }
            //     } else {
            //       item.isShow = false
            //     }
            //   }
            // })
            filterModelJSON.forEach(item => {
              if (['card', 'grid', 'popModal', 'subForm', 'dataBind'].includes(item.type)) {
                item.isShow = true
                if (this.activeTask.name === item.name) {
                  this.isEditForm = true
                  if (this.isAssignee) item.viewMode = 'edit' // 节点操作人时，该card修改为可编辑模式
                }
              }
            })
            jsonData.list = filterModelJSON
          }

          this.previewFormProps.jsonData = jsonData
        } else {
          this.$message.error('自定义表单获取失败，暂无法预览！')
          return Promise.resolve(false)
        }
        return Promise.resolve(true)
      })
    },

    findSysModelData (queryParam) {
      if (queryParam.flowId !== 0) {
        return flowApi.queryByModel(queryParam).then(res => {
          if (res.code === 0) {
            for (const item in res.data) {
              if (item === 'otherStaff') {
                res.data[item] = res.data[item].split(',')
              } else if (item === 'accountInfos') {
                res.data[item].forEach(it => {
                  if (it.hasOwnProperty('vehType')) {
                    it.vehType = it.vehType.split(',')
                  }
                })
              }
            }
            this.models = res.data
          } else {
            this.$message.error('暂无法获取到数据，请联系管理员！')
          }
          return Promise.resolve(true)
        })
      }
      this.user = this.$store.getters.userInfo
      this.models = { applyTime: new Date().format('yyyy-MM-dd HH:mm:ss'), userName: this.user.realname, userId: this.user.id }
      // 追加初始化数据
      Object.assign(this.models, this.initFormModel)
      getAction(userApi.rolesAndDepts, { id: this.user.id }).then((res) => {
        if (res.code === 0) {
          let dept = ''
          const depts = res.data.depts
          for (let i = 0; i < depts.length; i += 1) {
            dept = dept === '' ? depts[i].deptName : dept + ',' + depts[i].deptName
          }
          this.models.dept = dept
        }
      })
      return Promise.resolve(true)
    },
    searchProjectReceipt2SaveData (data) {
      if (data) {
        this.models = Object.assign({}, this.models, {
          projType: data.projType,
          projId: data.projId,
          projName: data.projName,
          processTitle: data.projName
        })
      } else {
        this.models = Object.assign({}, this.models, {
          projType: null,
          projId: null,
          projName: null,
          processTitle: '-'
        })
      }
    }
  }
}
</script>

<style lang="less">
  @import '../../../assets/less/custom/index.less';
</style>

<style lang="css" src="element-ui/lib/theme-chalk/index.css"/>

<style lang="less" scoped>
  .no-feedback {
    display: flex;
    text-align: center;
    align-items: center;
    height: 80%;
    min-height: 500px;

    .content {
      flex: auto;

      h2 {
        margin-bottom: 24px;
        color: #434E59;
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;
      }

      .desc {
        margin-bottom: 16px;
        color: fade(#000000, 45%);
        font-size: 20px;
        line-height: 28px;
      }

      .actions {

        button:not(:last-child) {
          margin-right: 8px;
        }

      }
    }
  }
</style>

<style lang="less">
.el-upload__input {
  display: none !important;
}

  .bm-view {
    height: 420px;
  }

  .feedback-form {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  .custom-form-modal {
    height: 100%;

    .ant-modal {
      height: 100%;
      overflow: hidden;
      padding-bottom: 0px;

      .ant-modal-content {
        height: 100%;
        overflow: hidden;

        .ant-modal-body {
          overflow: auto;
          padding: 5px;
          height: ~"calc(100% - 108px)";
        }

        .ant-modal-body::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
</style>
