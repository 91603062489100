<template>
  <div>
    <template v-if="isEdit">
      <a-input-search
        :class="clazz"
        v-model="project.projName"
        :placeholder="placeholder"
        disabled
        @search="onSearchProject">
        <a-button slot="enterButton" :disabled="disabled">选择</a-button>
      </a-input-search>
    </template>
    <template v-else>
      {{ project.projName }}
    </template>

    <a-modal v-bind="searchProjectModal" v-on="searchProjectModal">
      <search-project-modal :ref="searchProjectModal.bodyRef" :searchType="searchType"/>
    </a-modal>
  </div>
</template>

<script>

import SearchProjectModal from '@/pages/flow/module/components/search-project/SearchSingleProjectModal'
import ProjectReceiptPlan from '@/pages/flow/module/components/search-project-receipt/ProjectReceiptPlan'

export default {
  name: 'SearchProjectReceipt',
  components: { SearchProjectModal, ProjectReceiptPlan },
  props: {

    projId: {
      type: Number,
      default: null
    },

    projName: {
      type: String,
      default: ''
    },

    isEdit: {
      type: Boolean,
      default: false
    },

    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    clazz: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: '请先选择项目'
    },

    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    searchType: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      projectList: [],

      project: {},

      searchProjectModal: {
        width: 1250,
        title: '选择项目',
        visible: false,
        ref: 'searchProjectModal',
        bodyRef: 'searchProjectModalBody',
        style: { marginTop: '-70px' },
        wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000,
        ok: () => {
          var data = this.$refs[this.searchProjectModal.bodyRef].getSelected()
          this.change(data)
          this.searchProjectModal.cancel()
        },
        show: () => { this.searchProjectModal.visible = true },
        cancel: () => { this.searchProjectModal.visible = false },
        cancelText: '关闭'
      }
    }
  },

  mounted () {
    this.$set(this.project, 'projId', this.projId)
    this.$set(this.project, 'projName', this.projName)
  },

  watch: {
    projId: {
      immediate: true,
      handler (nv, ov) {
        this.$set(this.project, 'projId', this.projId)
        this.$set(this.project, 'projName', this.projName)
      }
    }
  },
  methods: {

    change (data) {
      if (data) {
        this.project = Object.assign({}, data)
        this.project.projName = this.project.projNo + '-' + this.project.projName
      } else {
        this.project = {}
      }
      this.$emit('change', this.project)
    },

    // 通过组织机构筛选选择用户
    onSearchProject () {
      this.searchProjectModal.show()
    }
  }
}
</script>
