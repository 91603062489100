<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { message, Select } from 'ant-design-vue'
import { computed, watch, watchEffect, ref } from 'vue'
import { qualityOptionDataMap } from '@/api/itemCheckDetail/itemCheckDetailConstant'

const ASelectOption = Select.Option

const props = defineProps({
  selectCheckGuide: { required: false, type: Object, default: undefined },
  modelValue: { required: true, type: Array },
  taskInfo: { required: true, type: Object },
  sampleInfoList: { required: true, type: Array },
  qualityControlStatus: { type: Boolean, required: false, default: false },
  itemList: { required: false, type: Array, default: () => [] },
  compositeItem: { type: String, required: false, default: undefined },
  sampleInfoStatus: { type: Boolean, required: false, default: false },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue'])
const parallelList = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

// 添加平行样
const addParallel = () => {
  const [sampleInfo] = props.sampleInfoList
  if (!sampleInfo) {
    message.error('暂无需要检测的样品!')
    return false
  }

  const { itemId, cateId, itemName, cateName } = props.taskInfo
  console.log(sampleInfo)
  const { id, checkSampleId } = sampleInfo
  // compositeItem
  if (props.selectCheckGuide.compositeItemParallel === '2') {
    let compositeItems = props.compositeItem.split(',')
    for (let pi = 0; pi < compositeItems.length; pi++) {
      let csi = compositeItems[pi]
      parallelList.value.push({ id, checkSampleId, itemId, cateId, itemName, cateName, compositeItem: csi, editable: true })
    }
  } else {
    parallelList.value.push({ id, checkSampleId, itemId, cateId, itemName, cateName, compositeItem: props.compositeItem, editable: true })
  }
}

const firstHandle = ref(false)

const handleWatchEle = () => {
  if (
    props.firstFlag !== 1 &&
    props.qualityControlStatus &&
    props.sampleInfoStatus &&
    props.selectCheckGuide &&
    (props.selectCheckGuide.needParallel === '1' || props.selectCheckGuide.needParallel === '2')
  ) {
    firstHandle.value = true

    parallelList.value = parallelList.value.filter((item) => !item.editable)
    if (props.sampleInfoList && props.sampleInfoList.length > 0) {
      const silLength = Math.ceil(props.sampleInfoList.length / 10)
      const residueLength = silLength - parallelList.value.length
      if (residueLength > 0) {
        for (let i = 0; i < residueLength; i++) {
          addParallel()
        }
      }
    }
  }
}

const map = { 1: '标准', 2: '企业' }
const qcRefSource = computed(() => (type) => map[type] || '')

// watchEffect(() => {
//   props.firstFlag !== 1 && props.qualityControlStatus && props.sampleInfoStatus && props.selectCheckGuide && handleWatchEle()
// })
watch(
  () => props.firstFlag,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.qualityControlStatus,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.sampleInfoStatus,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.selectCheckGuide,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)

const getItemName = (itemId) => {
  // console.log(itemId, props.itemList)
  let items = props.itemList.filter((item) => item.id === Number(itemId))
  console.log(items)
  if (items.length > 0) {
    return items[0].name
  }
  return ''
}
</script>

<template>
  <ul-table :data-source="parallelList" title="平行样">
    <template #extra v-if="props.firstFlag !== 1">
      <template v-if="selectCheckGuide">
        <a-tooltip :getPopupContainer="() => $el">
          <template #title>
            数量： ≥ <span class="green">{{ selectCheckGuide.needParallelNum }}</span>
            <template v-if="selectCheckGuide.needParallelNumType !== 'fix'"> %</template>
            <br />
            参考：
            <a-tag>{{ qcRefSource(selectCheckGuide.needParallelRefSource) }}</a-tag>
          </template>
          <a-tag :color="qualityOptionDataMap[selectCheckGuide.needParallel].color">{{ qualityOptionDataMap[selectCheckGuide.needParallel].label }}</a-tag>
        </a-tooltip>
      </template>
      <a @click="addParallel">
        <a-icon type="plus"></a-icon>
      </a>
    </template>

    <template #default="{ data: sampleInfo, index }">
      <template v-if="parallelList.length">
        <ul-table-column :title="sampleInfo.editable ? '平行' : '编号'">
          <template v-if="sampleInfo.editable">
            <a-select v-model="sampleInfo.id">
              <a-select-option v-for="item in sampleInfoList" :key="item.id">{{ item.checkSampleId || item.id }}</a-select-option>
            </a-select>
          </template>
          <template v-else>{{ sampleInfo.checkSampleId || sampleInfo.id }}</template>
        </ul-table-column>
        <!-- 如果当前批次是复合批次，那这里显示的就是复合因子 -->
        <ul-table-column data-index="compositeItem" :data="sampleInfo" title="检测项" class="compositeItem">
          <div v-for="item in sampleInfo.compositeItem.split(',')" :key="item">{{ getItemName(item) }}</div>
        </ul-table-column>

        <ul-table-column title="" v-if="sampleInfo.editable">
          <template #default>
            <a @click="parallelList.splice(index, 1)" style="color: red">移除</a>
          </template>
        </ul-table-column>
      </template>

      <template v-else>
        <div class="data prompt">
          <div class="no-data">暂无数据</div>
        </div>
      </template>
    </template>
  </ul-table>
</template>

<style lang="less">
.compositeItem {
  display: flex;
  .title-row {
    margin: 0;
  }
}
</style>