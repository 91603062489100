<template>
  <div class="form">
    <a-form v-bind="dataForm">
      <a-card v-bind="baseCard" title="基本信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="试剂名称">
              <a-input v-decorator="decorators.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="试剂等级">
              <a-select v-decorator="decorators.grade">
                <a-select-option v-for="(value,key) of reagentCateGradeMap" :key="key" :value="key">{{ value }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="处理方式">
              <a-radio-group v-decorator="decorators.needMake">
                <a-radio v-for="(item,index) in reagentCateMakeTypeOptions" :key="index" :value="item.value"> {{ item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

        </a-row>
      </a-card>

      <a-card v-bind="baseCard" title="原料">
        <template #extra>
          <a @click="materialTableModal.show">添加</a>
        </template>

        <a-table v-bind="materialTable">
          <template #type="data">
            <a-tag :color="reagentCateMaterialTypeDataMap[data].color">{{ reagentCateMaterialTypeDataMap[data].label }}</a-tag>
          </template>

          <template #num="data,record">
            <template v-if="record.editable">
              <a-input-number v-model="record.num" :min="0"></a-input-number>
              <span :style="{marginLeft:'5px'}">{{ record.unit }}</span>
            </template>
            <template v-else>
              {{ data }}
              <span :style="{marginLeft:'5px'}">{{ record.unit }}</span>
            </template>
          </template>

          <template #action="data,record,index">
            <a v-if="record.editable" @click="confirmData(record)">确定</a>
            <a-divider type="vertical"/>
            <a @click="materialTable.dataSource.splice(index,1)">移除</a>
          </template>
        </a-table>
      </a-card>

      <a-card v-bind="baseCard" title="性状信息">
        <a-row :gutter="64">
          <a-col :span="12">
            <a-form-item label="形状">
              <a-radio-group v-decorator="decorators.solution" @change="handleSolutionChange">
                <a-radio v-for="(item,index) in reagentCateSolutionTypeOptions" :key="index" :value="item.value"> {{ item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="溶液类型">
              <a-select v-decorator="decorators.solutionType" :disabled="showSolutionType">
                <template v-for="(value,key) of checkSolutionTypeDataMap">
                  <a-select-option :key="key" v-if="value.reagentShow">{{ value.label }}</a-select-option>
                </template>
              </a-select>
            </a-form-item>
          </a-col>

        </a-row>

        <a-row :gutter="64">
          <a-col v-bind="colProps">
            <a-form-item label="浓度">
              <a-input v-decorator="decorators.purity"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="规格">
              <a-row :gutter="10">
                <a-col :span="12">
                  <a-input-number v-decorator="decorators.speType" :min="0" :step="1" :style="{width:'100%'}"></a-input-number>
                </a-col>

                <a-col :span="12">
                  <a-input v-decorator="decorators.unit"></a-input>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>

        </a-row>

      </a-card>

      <a-card v-bind="baseCard" title="保存方式">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="有效时长">
              <a-row :gutter="10">
                <a-col :span="8">
                  <a-input-number v-decorator="decorators.validDate" :min="0" :step="1" :style="{width:'100%'}"></a-input-number>
                </a-col>

                <a-col :span="16">
                  <a-radio-group v-model="dataForm.data.validDateUnit">
                    <a-radio v-for="(value,key) of reagentCateValidDateUnitMap" :key="key" :value="key">{{ value }}</a-radio>
                  </a-radio-group>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="保存介质">
              <a-input v-decorator="decorators.storageMedium"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item label="保存条件">
          <a-textarea v-decorator="decorators.storageCondition" :autosize="{ minRows: 3, maxRows: 5 }"></a-textarea>
        </a-form-item>
      </a-card>

      <a-card v-bind="baseCard" title="配制方法">
        <a-form-item>
          <a-textarea v-decorator="decorators.preparationMethod" :autosize="{ minRows: 4, maxRows: 8 }"></a-textarea>
        </a-form-item>
      </a-card>
    </a-form>

    <a-modal v-bind="materialTableModal">
      <check-reagent-cate-material-select v-bind="materialTableModal.data"></check-reagent-cate-material-select>
      <template #footer>
        <a-button @click="materialTableModal.cancel">取消</a-button>
        <a-button type="primary" @click="materialTableModal.ok">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import fullModal from '@/pages/common/mixins/modal/full'
import mixinForm from '@/pages/common/mixins/form'

import { checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'
import { reagentCateGradeMap, reagentCateMakeTypeOptions, reagentCateSolutionTypeOptions, reagentCateValidDateUnitMap, reagentCateMaterialTypeDataMap } from '@/api/checkReagent/checkReagentConstant'
import { checkSolutionTypeDataMap } from '@/api/checkStandardSolution/checkStandardSolutionConstant'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'CheckReagentCateForm',
  mixins: [mixinForm],
  props: {
    id: {
      type: Number,
      required: false,
      default: 0
    },

    type: {
      type: String,
      required: false,
      default: 'add'
    }
  },

  components: {},

  data () {
    return {
      reagentCateGradeMap,
      reagentCateValidDateUnitMap,
      reagentCateMakeTypeOptions,
      reagentCateSolutionTypeOptions,
      reagentCateMaterialTypeDataMap,
      checkSolutionTypeDataMap,

      currentColSize: 2,

      dataForm: {
        data: { materialId: null, validDateUnit: 'd', id: null }
      },

      baseCard: {
        type: 'inner',
        hoverable: true,
        size: 'small',
        style: { marginBottom: '16px' }
      },

      materialTableModal: Object.assign({}, fullModalData, {
        ref: 'materialTableModal',
        title: '请选择原料',
        visible: false,
        width: '80%',
        data: {
          ref: 'materialTable',
          cateTypes: [2, 5, 6, 7],
          rowSelectionType: 'checkbox'
        },
        show: () => { this.materialTableModal.visible = true },
        ok: () => {
          const data = this.$refs[this.materialTableModal.data.ref].getSelectData()
          const { cate: cateData = [], material: materialData = [] } = data
          const cateTranData = cateData.map(item => {
            return {
              materialId: item.id,
              type: 'cate',
              num: null,
              materialCateId: item.materialCateId,
              unit: item.unit,
              purity: item.purity,
              materialName: item.name,
              editable: true
            }
          })

          const materialTranData = materialData.map(item => {
            return {
              materialId: item.id,
              type: 'material',
              num: null,
              materialCateId: item.cateId,
              unit: item.speType?.replace(/[0-9,\\.]/ig, '')?.trim(),
              purity: item.purity,
              materialName: `${item.materialName}(${item.speType})`,
              editable: true
            }
          })
          if (materialData.length) { this.fillDataByMaterial(materialData[0]) }
          const existCateIds = this.materialTable.dataSource?.filter(item => item.type === 'cate')?.map(item => item.materialId)
          const existMaterialIds = this.materialTable.dataSource?.filter(item => item.type === 'material')?.map(item => item.materialId)
          const notExistCateTranData = cateTranData?.filter(item => !existCateIds.includes(item.materialId)) || []
          const notExistMaterialTranData = materialTranData?.filter(item => !existMaterialIds.includes(item.materialId)) || []
          notExistCateTranData.push.apply(notExistCateTranData, notExistMaterialTranData)

          this.materialTable.dataSource.push.apply(this.materialTable.dataSource, notExistCateTranData)
          this.materialTableModal.cancel()
        },
        cancel: () => { this.materialTableModal.visible = false }
      }),

      materialTable: {
        rowKey: item => (`${item.materialId}_${item.type}`),
        bordered: true,
        size: 'small',
        dataSource: [],
        columns: [
          { title: '类目名称', width: 200, dataIndex: 'materialName', scopedSlots: { customRender: 'materialName' } },
          { title: '类型', width: 80, dataIndex: 'type', scopedSlots: { customRender: 'type' } },
          { title: '浓度', width: 100, dataIndex: 'purity', scopedSlots: { customRender: 'purity' } },
          { title: '消耗', width: 150, dataIndex: 'num', scopedSlots: { customRender: 'num' } },
          { title: '操作', width: 80, dataIndex: 'action', scopedSlots: { customRender: 'action' } }
        ],
        pagination: false
      },

      showSolutionType: false,

      decorators: {
        name: ['name', { rules: [{ required: true, message: '请输入试剂名称！' }] }],
        needMake: ['needMake', { initialValue: true, rules: [{ required: true, message: '请选择处理方式！' }] }],
        solution: ['solution', { initialValue: true, rules: [{ required: true, message: '请选择形状！' }] }],
        validDate: ['validDate', { rules: [{ required: true, message: '请输入有效期！' }] }],
        storageCondition: ['storageCondition', { rules: [{ required: false }] }],
        preparationMethod: ['preparationMethod', { rules: [{ required: false }] }],
        grade: ['grade', { initialValue: '1', rules: [{ required: true, message: '请选择等级！' }] }],
        storageMedium: ['storageMedium', { rules: [{ required: false }] }],
        purity: ['purity', { rules: [{ required: false, message: '请输入浓度！' }] }],
        speType: ['speType', { rules: [{ required: false, message: '请输入规格，例如100ml,填写100！' }] }],
        unit: ['unit', { rules: [{ required: false, message: '请输入规格单位,例如100ml,此处填写“ml”！' }] }],
        solutionType: ['solutionType', { rules: [{ required: false, message: '请输入规格单位！' }] }]
      }
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (value) {
        value && this.initEditData(value)
      }
    }
  },

  mounted () {
    this.submit = throttle(this.submit, 6000, { leading: true, trailing: false })
  },

  methods: {
    initEditData (id) {
      checkReagentCateApi.findById({ id }).then(res => {
        if (res.code === 0) {
          const data = res.data
          const materialList = data.materialList?.map(item => { return Object.assign({ editable: false }, item) }) || []
          this.materialTable.dataSource.splice(0, this.materialTable.dataSource.length, ...materialList)
          const { materialId, validDateUnit, id } = data
          if (this.type === 'edit') {
            Object.assign(this.dataForm.data, { materialId, validDateUnit, id })
          } else {
            Object.assign(this.dataForm.data, { materialId, validDateUnit, id: null })
          }
          this.dataForm.form.$nextTick(() => {
            const decoratorKeys = Object.keys(this.decorators)
            const newData = Object.keys(data).reduce((acc, key) => {
              decoratorKeys.includes(key) && (acc[key] = data[key])
              return acc
            }, {})
            this.dataForm.form.setFieldsValue(newData)
          })
        } else {
          this.$message.error(res.msg)
          this.$emit('cancel')
        }
      })
    },

    fillDataByMaterial (materialData) {
      const { id, storageCondition, speType, purity, materialName } = materialData
      this.dataForm.data.materialId = id
      const { storageCondition: storageConditionFormData, unit: unitFormData, purity: purityFormData, name: nameFormData } = this.dataForm.form.getFieldsValue()
      const unit = speType?.replace(/[0-9,\\.]/ig, '').trim()
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue({ name: nameFormData || `${materialName}（${purityFormData || purity}）`, storageCondition: storageConditionFormData || storageCondition, unit: unitFormData || unit, purity: purityFormData || purity })
      })
    },
    // 处理试剂类型改变事件
    handleSolutionChange (val) {
      this.$nextTick(() => {
        const solution = this.dataForm.form.getFieldValue('solution')
        this.showSolutionType = !solution
      })
    },

    submit () {
      this.dataForm.form.validateFields((errors, values) => {
        if (!errors) {
          const materialDataSource = this.materialTable.dataSource
          if (!materialDataSource.length) {
            this.$message.error('请选择原料！')
            return false
          }
          const once = materialDataSource.find(item => !item.num)
          if (once) {
            this.$message.error('请填写消耗量！')
            return false
          }
          const data = Object.assign({}, this.dataForm.data, values)
          data.materialList = materialDataSource
          data.materialCateId = materialDataSource[0].materialCateId
          data.solutionType = data.solution ? data.solutionType : null

          checkReagentCateApi.save(data).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
              this.$emit('error')
            }
          })
        }
      })
    },

    confirmData (data) {
      data.editable = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
