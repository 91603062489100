<template>
  <div class="standardTree">
    <a-card v-bind="itemDetailCard">
      <template>
        <a-row :gutter="16">
          <a-col :span="10">
            <a-card title="点位表">
              <div v-if="treeData.length > 0" class="tree-wrap">
                <!-- :props="treeDataProps" -->
                <el-tree :data="treeData" highlight-current @node-click="handleNodeClick" default-expand-all>
                  <span slot-scope="{ data }" style="flex: 1">
                    <span v-if="data.childFlag === false" style="flex: 1">
                      <div style="display: flex">
                        <div style="width: 40%">
                          <!-- {{ data.siteName }} -->
                          <ellipsis :length="20">{{ data.siteName }}</ellipsis>
                        </div>
                        <div style="width: 60%">
                          <div v-for="item in data.affirmStandard" :key="item.standardId">
                            <!-- {{ item.standardName }}<{{ item.standardIds }}> -->
                            <ellipsis :length="30">{{ item.standardName }}<{{ item.standardIds }}></ellipsis>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span v-else>{{ data.cateName }}</span>
                  </span>
                </el-tree>
              </div>
              <div v-else style="height: 60px; text-align: center; font-size: 14px; color: #909399">正在加载中...</div>
            </a-card>
          </a-col>
          <a-col :span="7">
            <a-card class="notCheckedCard" title="未选择标准">
              <template #extra>
                <a-input style="width: 220px" v-model.trim.lazy="searchStandardName" placeholder="标准名称、标准编号模糊搜索"></a-input>

                <a-button icon="search" type="primary" @click="searchStandard">查询</a-button>
              </template>
              <div class="tree-wrap">
                <el-tree :data="notCheckedStandard" :props="subTreeDataProps" highlight-current @node-click="isCheckedStandardNodeClick" default-expand-all></el-tree>
              </div>
            </a-card>
          </a-col>
          <a-col :span="7">
            <a-card title="已选择标准">
              <div class="tree-wrap">
                <el-tree :data="isCheckedStandard" :props="subTreeDataProps" highlight-current @node-click="notCheckedStandardNodeClick" default-expand-all></el-tree>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </template>
    </a-card>
  </div>
</template>

<script>
import { projStandardTreeApi, projStandardSaveLimitApi } from '@/api/projectStandard/projectStandardApi'
import Ellipsis from '@/components/Ellipsis'
import Vue from 'vue'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    Ellipsis
  },
  props: {
    projId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      tableContent: {
        border: true,
        size: 'mini',
        maxHeight: 400,
        stripe: true,
        rowKey: (data) => {
          return data.itemId + (data.id || data.attrValueId || '')
        },
        bordered: true
      },

      itemDetailCard: {
        title: '',
        type: 'inner',
        style: { marginBottom: '16px' }
      },
      cateId: '',
      siteId: '',
      // 搜索框value
      searchStandardName: '',

      // 所有的数据
      allData: [],
      // 左侧树状图
      treeData: [],

      // 已选择标准
      isCheckedStandard: [],
      // 未选择标准
      notCheckedStandard: [],
      standardData: [],

      treeDataProps: {
        children: 'children',
        label: function (data, node) {
          if (data.cateName && data.cateName != null) {
            return data.cateName
          } else if (data.siteName && data.siteName != null) {
            return data.siteName
            // } else if (data.standardName && data.standardName != null) {
            // return data.standardName + `<${data.standardIds}>`
          }
        }
      },
      subTreeDataProps: {
        children: 'children',
        label: function (data, node) {
          if (data.standardName && data.standardName != null) {
            return data.standardName + `<${data.standardIds}>`
          }
        }
      }
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler(val) {
        this.searchProjectTaskItem()
      }
    }
  },
  created() {
    Vue.prototype.$confirm = Modal.confirm
  },

  methods: {
    // 查询左侧树状图
    searchProjectTaskItem() {
      let params = {
        projId: this.projId
      }
      if (this.standardName && this.standardName != undefined && this.standardName != '') {
        params.standardName = this.standardName
      }
      projStandardTreeApi.listProjectStandard(params).then((res) => {
        if (res.code === 0) {
          let data = res.data

          this.allData = res.data
          this.treeData = []
          data.forEach((item) => {
            let itemdata = []
            if (item.children && item.children.length) {
              item.children.forEach((item2) => {
                itemdata.push({
                  projId: item2.projId,
                  cateId: item2.cateId,
                  siteId: item2.siteId,
                  siteName: item2.siteName,
                  childFlag: item2.childFlag,
                  affirmStandard: item2.affirmStandard
                })
                let staIds = []
                let deleteStaIds = []
                if (item2.affirmStandard && item2.affirmStandard.length > 0) {
                  item2.affirmStandard.forEach((item3) => {
                    staIds.push(item3.standardId)
                  })
                }
                if (item2.children && item2.children.length > 0) {
                  if (staIds.length) {
                    item2.children = item2.children.filter((item3) => staIds.indexOf(item3.standardId) === -1)
                  }
                  item2.children.forEach((item3) => {
                    deleteStaIds.push(item3.standardId)
                  })
                } else {
                  item2.children = []
                }

                this.standardData.push({
                  projId: item2.projId,
                  cateId: item2.cateId,
                  siteId: item2.siteId,
                  staIds: staIds,
                  deleteStaIds: deleteStaIds,
                  type: '1'
                })
              })
            } else {
              item.children = []
            }

            this.treeData.push({
              projId: item.projId,
              cateId: item.cateId,
              cateName: item.cateName,
              childFlag: item.childFlag,
              children: itemdata
            })
          })
        } else {
          this.$message.error(`查询标准表信息出错：${res.msg}`)
        }
      })
    },
    // 点击左侧树状图
    handleNodeClick(data, e) {
      this.isCheckedStandard = []
      this.notCheckedStandard = []
      if (data.childFlag == false) {
        this.cateId = data.cateId
        this.siteId = data.siteId
        let cate = this.allData.find((item) => item.cateId == data.cateId)
        if (cate != undefined) {
          let site = cate.children.find((item) => item.siteId == data.siteId)
          if (site != undefined) {
            this.isCheckedStandard = site.affirmStandard
            if (this.searchStandardName && (this.searchStandardName != '' || this.searchStandardName != undefined)) {
              this.notCheckedStandard = site.children.filter((item) => {
                return item.standardName.indexOf(this.searchStandardName) != -1 || item.standardIds.indexOf(this.searchStandardName) != -1
              })
            } else {
              site.children.forEach((item) => {
                this.notCheckedStandard.push(item)
              })
            }
          }
        }
      }
    },
    // 查询标准
    searchStandard() {
      if (this.searchStandardName && (this.searchStandardName != '' || this.searchStandardName != undefined)) {
        this.notCheckedStandard = this.notCheckedStandard.filter((item) => {
          return item.standardName.indexOf(this.searchStandardName) != -1 || item.standardIds.indexOf(this.searchStandardName) != -1
        })
      } else {
        this.notCheckedStandard = []
        let cate = this.allData.find((item) => item.cateId == this.cateId)
        if (cate != undefined) {
          let site = cate.children.find((item) => item.siteId == this.siteId)
          if (site != undefined) {
            if (site.children && site.children.length > 0) {
              site.children.forEach((item) => {
                this.notCheckedStandard.push(item)
              })
            }
          }
        }
      }
    },

    // 点击未选择标准项->已选择标准
    isCheckedStandardNodeClick(data, e) {
      console.log(data, e)
      let findIndex = this.notCheckedStandard.findIndex((item) => item.standardId == data.standardId)
      if (findIndex != -1) {
        this.isCheckedStandard.push(data)
        this.notCheckedStandard.splice(findIndex, 1)
        this.allData.forEach((item) => {
          if (item.cateId == this.cateId) {
            item.children.forEach((item2) => {
              if (item2.siteId == this.siteId) {
                let fIndex = item2.children.findIndex((v) => v.standardId == data.standardId)
                if (fIndex != -1) {
                  item2.children.splice(fIndex, 1)
                }
              }
            })
          }
        })
        this.standardData.forEach((item) => {
          console.log(item)
          if (item.projId == data.projId && item.cateId == this.cateId && item.siteId == this.siteId) {
            item.staIds.push(data.standardId)
            let index = item.deleteStaIds.indexOf(data.standardId)
            if (index != -1) {
              item.deleteStaIds.splice(index, 1)
            }
          }
        })
        // this.allData.forEach((item) => {
        //   if (item.cateId == data.cateId) {
        //     item.children.forEach((item2) => {
        //       if (item2.siteId == data.siteId) {
        //         let fIndex = item2.children.findIndex((v) => v.standardId == data.standardId)
        //         if (fIndex != -1) {
        //           item2.children.splice(fIndex, 1)
        //         }
        //       }
        //     })
        //   }
        // })
        // this.standardData.forEach((item) => {
        //   console.log(item);
        //   if (item.projId == data.projId && item.cateId == data.cateId && item.siteId == data.siteId) {
        //     item.staIds.push(data.standardId)
        //     let index = item.deleteStaIds.indexOf(data.standardId)
        //     if (index != -1) {
        //       item.deleteStaIds.splice(index, 1)
        //     }
        //   }
        // })

        let treeCate = this.treeData.find((item) => item.cateId == this.cateId)
        if (treeCate != undefined) {
          let treeSite = treeCate.children.find((item) => item.siteId == this.siteId)
          if (treeSite != undefined) {
            treeSite.affirmStandard = this.isCheckedStandard
          }
        }
      }
    },
    // 点击已选择标准项->未选择标准
    notCheckedStandardNodeClick(data, e) {
      let findIndex = this.isCheckedStandard.findIndex((item) => item.standardId == data.standardId)
      if (findIndex != -1) {
        this.notCheckedStandard.push(data)
        this.isCheckedStandard.splice(findIndex, 1)
        this.allData.forEach((item) => {
          if (item.cateId == this.cateId) {
            item.children.forEach((item2) => {
              if (item2.siteId == this.siteId) {
                item2.children.push(data)
              }
            })
          }
        })
        this.standardData.forEach((item) => {
          if (item.projId == data.projId && item.cateId == this.cateId && item.siteId == this.siteId) {
            item.deleteStaIds.push(data.standardId)
            let index = item.staIds.indexOf(data.standardId)
            if (index != -1) {
              item.staIds.splice(index, 1)
            }
          }
        })
        // this.allData.forEach((item) => {
        //   if (item.cateId == data.cateId) {
        //     item.children.forEach((item2) => {
        //       if (item2.siteId == data.siteId) {
        //         item2.children.push(data)
        //       }
        //     })
        //   }
        // })
        // this.standardData.forEach((item) => {
        //   if (item.projId == data.projId && item.cateId == data.cateId && item.siteId == data.siteId) {
        //     item.deleteStaIds.push(data.standardId)
        //     let index = item.staIds.indexOf(data.standardId)
        //     if (index != -1) {
        //       item.staIds.splice(index, 1)
        //     }
        //   }
        // })

        let treeCate = this.treeData.find((item) => item.cateId == this.cateId)
        if (treeCate != undefined) {
          let treeSite = treeCate.children.find((item) => item.siteId == this.siteId)
          if (treeSite != undefined) {
            treeSite.affirmStandard = this.isCheckedStandard
          }
        }
      }
    },
    // 保存入库
    save() {
      this.$confirm({
        title: '确认提交吗？',
        onOk: () => {
          projStandardSaveLimitApi.listProjectStandard(this.standardData).then((res) => {
            if (res.code === 0) {
              this.standardData = []
              this.$message.success(res.msg)
              this.$emit('success')
            } else {
              this.$message.error(`${res.msg}`)
            }
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './_standardTree.less';
.tree-wrap {
  max-height: 800px;
  display: block;
  overflow-y: scroll;
}
</style>
<style lang="less">
.notCheckedCard {
  .ant-card-extra {
    display: flex;
    padding: 0;
  }
}
.standardTree .el-tree-node__content {
  height: 100% !important;
}
</style>