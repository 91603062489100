<template>
  <a-modal
    :width="screenWidth"
    :visible="visible"
    :title="title"
    :destroyOnClose="true"
    :maskClosable="false"
    style="top: 20px"
    @cancel="close"
    @ok="handleSubmit"
    wrapClassName="ant-modal-cust-warp"
  >
    <a-tabs :tabPosition="tabPosition" v-if="standardList.length > 0" v-model="activeStandardKey" @change="activeKeyChange">
      <template v-for="standard in standardList">
        <a-tab-pane :tab="getTabName(standard)" :key="standard.id">
          <!--          <standard-item-factor :model="obj" @limitData="v=>limitData(v)"></standard-item-factor>-->
          <div v-if="factorList.length == 0" style="text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.45)">暂无数据</div>
          <div v-else>
            <a-card v-for="(factor, idx) in factorListMap[activeStandardKey]" :title="getTitle(factor)" :key="idx">
              <a-radio-group name="radioGroup" slot="extra" v-model="factor.type">
                <a-radio :value="'radio'">单选</a-radio>
                <a-radio :value="'checkbox'">多选</a-radio>
              </a-radio-group>
              <template v-if="factor.valueSelected" slot="extra">
                <span style="font-weight: bold">限值关系：</span>
                <a-radio-group name="radioGroup" v-model="factor.relation">
                  <a-radio :value="1">或</a-radio>
                  <a-radio :value="0">并且</a-radio>
                </a-radio-group>
              </template>
              <attribute-combine-limit-value
                :key="idx"
                :ref="`combineValue${idx}`"
                :attributeList="attributeList"
                :model="factor"
                @valueSelect="(keys) => standardSelected(standard, factor, keys)"
              ></attribute-combine-limit-value>
            </a-card>
          </div>
        </a-tab-pane>
      </template>
    </a-tabs>
    <div v-else style="text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.45)">暂无数据</div>
  </a-modal>
</template>
<script>
import AttributeCombineLimitValue from '@/pages/common/modal/AttributeCombineLimitValue'
import { standardItemFactorApi, standardItemApi, attributeApi, Get, itemCategoryApi } from '@/api/quote'
import { projTaskItemApi } from '@/api/project'

export default {
  name: 'SelectStandardLimitValue',
  components: {
    AttributeCombineLimitValue
  },

  data() {
    return {
      screenWidth: 1000,
      tabPosition: 'top',
      visible: false,
      title: '执行标准限值',
      saveData: [],
      param: {},
      activeStandardKey: '',
      standardList: [],
      factorListMap: {},
      factorList: [],
      attributeList: [],
      activeKeyMap: {},
      selectKeysOld: []
    }
  },

  methods: {
    view(param, itemName) {
      this.visible = true
      this.title = '执行标准限值'
      if (itemName != undefined) {
        this.title = itemName + this.title
      }
      this.param = param
      this.standardSelectedOld(param.standardList)
      this.loadStandardList(param)
      this.loadAttributeList()
    },
    handleSubmit() {
      const self = this
      var selected = self.activeKeyMap[self.activeStandardKey]
      var confirmTitle = '该操作仅保存当前选中标准的限值'
      var confirmContent = '是否保存当前页选中数据?'
      if (!selected) {
        confirmTitle = '当前页未选择任何限值'
        confirmContent = '确定会作废该因子已保存限值?'
      }
      self.$confirm({
        title: confirmTitle,
        content: confirmContent,
        onOk: function () {
          // 需要保存限值
          const standardListStr = JSON.stringify(selected)
          var params = selected[0]
          params.standardListStr = standardListStr
          projTaskItemApi
            .updateStandard(params)
            .then((result) => {
              if (result.code === 0) {
                self.param.standardList = result.data
                self.$emit('ok', self.param)
                self.$message.success(`执行标准设置成功`)
                self.close()
              } else {
                self.$message.error(result.msg)
              }
            })
            .catch((error) => {
              self.$message.error(`服务器发生错误！错误消息为${error}`)
            })
        }
      })
    },
    activeKeyChange(activeStandardKey) {
      this.activeStandardKey = activeStandardKey
      this.loadStandardItemFactor(activeStandardKey)
    },
    getTabName(s) {
      return s.standardCode + '-' + s.standardName
    },
    getTitle(factor) {
      const title = factor.name
      let unit = factor.unit
      if (unit) {
        unit = '无'
      }
      return title + '(单位：' + unit + ')'
    },
    loadStandardList(param) {
      var self = this
      param.page = false
      if (param.cateId) {
        itemCategoryApi.list({ id: param.cateId }).then((result) => {
          if (result.code === 0) {
            if (result.data && result.data.length) {
              let queryParam=Object.assign({},param)
              queryParam.cateType = result.data[0].cateType
              delete queryParam.cateId
              Get(standardItemApi.list, queryParam).then((res) => {
                if (res.code === 0) {
                  self.standardList = res.data
                  if (res.data.length > 0) {
                    self.$nextTick(() => {
                      if (self.param.standardList && self.param.standardList.length > 0) {
                        //  已选择的tab
                        self.activeStandardKey = self.param.standardList[0].standardItemId
                      } else {
                        self.activeStandardKey = self.standardList[0].id
                      }
                      self.loadStandardItemFactor(self.activeStandardKey)
                    })
                  } else {
                    self.loadStandardItemFactor()
                  }
                }
              })
            }
          }
        })
      }
    },
    loadStandardItemFactor(standItemId) {
      var self = this
      if (standItemId) {
        var factorList = self.factorListMap[standItemId]
        if (!factorList) {
          var param = { standItemId: standItemId }
          standardItemFactorApi.list(param).then((res) => {
            if (res.code === 0) {
              var factorList = res.data
              factorList.forEach((it, idx) => {
                it.valueSelected = false
                it.type = 'radio'
                it.relation = 1
                it.selectKeys = self.selectKeysOld
              })
              self.factorList = factorList
              self.factorListMap[standItemId] = factorList
            }
          })
        }
      } else {
        self.factorListMap = {}
        self.factorList = []
      }
    },
    loadAttributeList() {
      Get(attributeApi.listWithValue, { type: this.param.type, cateId: this.param.cateId }).then((res) => {
        if (res.code === 0) {
          this.attributeList = res.data
        } else {
          this.attributeList = []
        }
      })
    },
    standardSelectedOld(standardList) {
      var selectKeysOld = []
      if (standardList) {
        standardList.forEach((it, idx) => {
          selectKeysOld.push(it.attrListId)
        })
      }
      this.selectKeysOld = selectKeysOld
    },
    standardSelected(standard, factor, keys) {
      //  已选择的标准
      factor.valueSelectedList = keys
      if (keys.length > 1) {
        factor.valueSelected = true
      } else {
        factor.valueSelected = false
      }
      var tempList = []
      keys.forEach((it) => {
        var obj = {
          taskItemId: this.param.taskItemId,
          standardId: standard.standardId,
          standardCode: standard.standardCode,
          standardName: standard.standardName,
          standardItemId: standard.id,
          factorId: factor.id,
          attrListId: it,
          relation: factor.relation
        }
        tempList.push(obj)
      })
      this.activeKeyMap[this.activeStandardKey] = tempList
    },
    close() {
      this.visible = false
    }
  },
  watch: {}
}
</script>
<style lang="less"></style>
