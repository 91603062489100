'use strict'

const defaultColumn = [
  { title: '任务编号', dataIndex: 'projNo', width: 100, align: 'left', scopedSlots: { customRender: 'projNo' } },
  { title: '任务名称', dataIndex: 'projName', width: 200, align: 'left', scopedSlots: { customRender: 'projName' } },
  { title: '业主名称', dataIndex: 'csrName', width: 150, align: 'left', scopedSlots: { customRender: 'csrName' } },
  { title: '检测负责人', dataIndex: 'detectionLeaderName', width: 100, align: 'left', scopedSlots: { customRender: 'detectionLeaderName' } },
  { title: '安排时间', dataIndex: 'scheduleTime', width: 140, align: 'left', scopedSlots: { customRender: 'scheduleTime' } },
  { title: '检测时间', dataIndex: 'checkTime', width: 140, align: 'left', scopedSlots: { customRender: 'checkTime' } },
  { title: '检测员', dataIndex: 'checkUserNames', width: 160, align: 'left', scopedSlots: { customRender: 'checkUserNames' } },
  { title: '安排进度', dataIndex: 'scheduleProgress', width: 160, align: 'left', scopedSlots: { customRender: 'scheduleProgress' } },
  { title: '检测进度', dataIndex: 'doneProgress', width: 160, align: 'left', scopedSlots: { customRender: 'doneProgress' } },
  { title: '复核进度', dataIndex: 'reviewProgress', width: 160, align: 'left', scopedSlots: { customRender: 'reviewProgress' } },
  { title: '审核进度', dataIndex: 'checkProgress', width: 160, align: 'left', scopedSlots: { customRender: 'checkProgress' } },
  { title: '线下完成', dataIndex: 'offline', width: 80, align: 'left', scopedSlots: { customRender: 'offline' } },
  { title: '操作', dataIndex: 'action', width: 200, align: 'left', scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as checkTaskProjectTableDefaultColumn
}
