import search from '@/pages/common/mixins/list/search'
import action from '@/pages/common/mixins/list/action'
import table from '@/pages/common/mixins/list/table'
import scrollTable from '@/pages/common/mixins/list/scrollTable'
import outSearch from '@/pages/common/mixins/list/outSearch'

export {
  search, action, table, scrollTable, outSearch
}

// 通过table组件形式引用的list
export const withComponentList = {
  mixins: [outSearch, action]
}

// table 组件需要的混入
export const componentTable = {
  mixins: [table, scrollTable, search]
}

const mixinList = {
  mixins: [search, action, table, scrollTable]
}

export default mixinList
