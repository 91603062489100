import '@/assets/less/common/form.less'
import { isReactive, isRef, ref, toRaw } from 'vue'
import { createBaseFormLayout } from '@/pages/common/compositions/form/useForm'

/**
 * 获取原生对象,如何本身是原生对象，则会进行拷贝
 * @param data {Object} 数据对象
 * @return {Object} 原生对象
 */
const getOriginalObject = (data) => {
  let originalData = data
  if (isRef(data)) {
    originalData = data.value
  } else if (isReactive(data)) {
    originalData = toRaw(data)
  }
  return JSON.parse(JSON.stringify(originalData))
}

/**
 * 列表查询表单属性
 * @return {{currentColSize: *, colProps: *, rowProps: {gutter: {xl: number, md: number, sm: number, xs: number, lg: number, xxl: number}, justify: string, type: string, align: string}, moreParam: *, searchCard: {bodyStyle: {padding: string}, hoverable: boolean, style: {marginBottom: string}, id: string, bordered: boolean, title: null, type: string, headStyle: {}, class: string}, formItemLayout: {wrapperCol: {xl: {span: number}, md: {span: number}, sm: {span: number}, lg: {span: number}, xs: {span: number}, xxl: {span: number}}, labelCol: {xl: {span: number}, md: {span: number}, sm: {span: number}, lg: {span: number}, xs: {span: number}, xxl: {span: number}}}, searchForm: {class: string}, actionColProps: {pull: number, offset: number, xl: {span: number}, md: {span: number}, style: {textAlign: string, marginBottom: string}, sm: {span: number}, xs: {span: number}, xxl: {span: number}, push: number}}}
 */
export const useSearchFormProps = () => {
  /** 是否展示搜索内容 */
  const moreParam = ref(false)

  /** 搜索表单配置 */
  const searchCard = {
    id: 'searchCard',
    title: null,
    bordered: false,
    hoverable: true,
    type: 'inner',
    style: { marginBottom: '24px' },
    headStyle: {},
    bodyStyle: { padding: '16px' },
    class: 'card-search'
  }
  const searchForm = { class: '' }

  /** 行配置 */
  /** 操作列配置  */
  const actionColProps = {
    offset: 0,
    pull: 0,
    push: 0,
    style: { textAlign: 'left', marginBottom: '16px' },
    xxl: { span: 6 },
    xl: { span: 8 },
    md: { span: 12 },
    sm: { span: 24 },
    xs: { span: 24 }
  }

  const { rowProps, colProps, formItemLayout, currentColSize } = createBaseFormLayout()
  // 默认设置成四列布局
  currentColSize.value = 4

  return {
    moreParam,
    currentColSize,
    searchCard,
    searchForm,
    rowProps,
    colProps,
    actionColProps,
    formItemLayout
  }
}

/**
 * 列表查询方法
 * @param tableRef
 * @param queryOriginalParam
 * @return {{search: function, reload: function, loadKey: *, reset: function, resetQueryParam: function}}
 */
export const useSearch = (tableRef = null, queryOriginalParam = {}) => {
  /** 加载引用的KEY，每次加载过程中会自动切换，从而达到刷新组件的目的 */
  const loadKey = ref(false)

  /**
   * 列表查询方法
   */
  const search = () => {
    loadKey.value = !loadKey.value
    tableRef?.value?.refresh(true)
  }

  /**
   * 重新加载
   */
  const reload = () => {
    loadKey.value = !loadKey.value
    tableRef?.value?.refresh(false)
  }

  const defaultParam = getOriginalObject(queryOriginalParam)

  /**
   * 重置查询条件
   */
  const resetQueryParam = () => {
    queryOriginalParam && Object.assign(queryOriginalParam, defaultParam)
  }

  /**
   * 重置查询
   */
  const reset = () => {
    resetQueryParam()
    search()
  }

  return { loadKey, resetQueryParam, search, reload, reset }
}
