<template>
  <div>
    <div v-if="device == 'tablet' || device == 'desktop'" id="userLayout" :class="['user-layout-wrapper', device]">
      <div class="pc-container container">

        <div class="container-main" style="transform: scale(0.9)">
          <div class="background">
            <img src="~@/assets/images/desk_background_img.png"/>
          </div>

          <div class="title">
            <div>
              <div v-show="showLogo">
                <div class="Imgbox Imgboxleft" v-if="configMap['login_logo']">
                  <img  :src="this.viewUrl + configMap['login_logo']" >
                </div>
                <!-- <span v-if="configMap.COMPANY_LOGIN_LOGO && configMap.SHOW_MUXING_LOGO === 'true' " class="img-split"></span>
                <img v-if="configMap.SHOW_MUXING_LOGO === 'true' " src="~@/assets/images/desk_logo.png"> -->
                <span v-if="configMap['login_logo']" class="img-split"></span>
                <div class="Imgbox">
                  <img  v-if="configMap['desk_logo']" :src="this.viewUrl + configMap['desk_logo']" >
                  <img  v-else src="~@/assets/images/desk_logo.png" >
                </div>
              </div>
              <div v-show="!showLogo">
                <img src="~@/assets/images/login_title.png"/>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="top">
              <div>
                <user-image-carousel/>
              </div>
              <div class="tree-left"/>
              <div class="login">
                <route-view/>
              </div>
              <div class="tree-right"/>
            </div>

            <div class="bottom"></div>
            <div class="footer">
              <div class="copyright">
                <span style="margin-right: 24px;" @click="appPreview">APP 下载</span>
                Copyright &copy; 2020-2021 <a :href="configMap['COMPANY_URL'] || '#'" :target="configMap['COMPANY_URL'] ? '_blank' : ''">{{ configMap['COMPANY_NAME'] }}</a><br/>技术支持：{{ configMap['TECH_SUPPORT'] }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-if="device == 'mobile'" id="userLayout" :class="['user-layout-wrapper', device]">
      <div class="container">
        <div class="top">
          <div class="header">
            <a href="/">
              <span class="title">{{ configMap['SYSTEM_NAME'] }}</span>
            </a>
          </div>
          <div class="desc"></div>
        </div>

        <route-view></route-view>

        <div class="footer">
          <div class="copyright">
            Copyright &copy; 2020-2021 <a :href="configMap['COMPANY_URL'] || '#'" :target="configMap['COMPANY_URL'] ? '_blank' : ''">{{ configMap['COMPANY_NAME'] }}</a><br/>技术支持：{{ configMap['TECH_SUPPORT'] }}
          </div>
        </div>
      </div>
    </div>

    <a-modal :footer="null" :visible="previewVisible" :width="600" title="APP下载" @cancel="() => this.previewVisible = false">
      <p>
        版本号：{{ previewVersion }}<br/>
        - {{ previewContent }} </p>
      <div style="height: 350px;">
        <div style="float: left">
          <qriously :size="250" :value="previewQrcode"/>
          <center>Android版扫码下载</center>
        </div>
        <div style="float: left; margin-left: 20px;">
          <qriously :size="250" value="https://apps.apple.com/cn/app/%E5%B0%8F%E6%9C%A8%E6%A3%80%E6%B5%8B/id1504791972"/>
          <center>iPhone或App Store搜索“小木检测”</center>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import RouteView from './RouteView'
import { mixinDevice } from '@/utils/mixin'
import UserImageCarousel from '@/layouts/UserImageCarousel'
import { axios } from '@/utils/request'
import { sysCommonApi } from '@/api/common'

export default {
  name: 'UserLayout',
  components: { RouteView, UserImageCarousel },
  mixins: [mixinDevice],
  data () {
    return {
      previewVisible: false,
      previewQrcode: '',
      previewContent: '',
      previewVersion: '',
      showLogo: true,
      showLogoInterval: null,
      viewUrl: sysCommonApi.view,
    }
  },
  computed: {
    configMap () {
      return { ...this.$store.getters.sys_config }
    }
  },
  mounted () {
    document.body.classList.add('userLayout')
    // const showEimsLogo = this.configMap.SHOW_EIMS_LOGO === 'true' ?? true
    // if (showEimsLogo) {
    //   this.showLogoInterval = window.setInterval(() => {
    //     this.showLogo = !this.showLogo
    //   }, 5000)
    // }
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
    if (this.showLogoInterval) window.clearInterval(this.showLogoInterval)
  },

  methods: {

    appPreview () {
      axios({
        url: '/update_version.json',
        method: 'get'
      }).then(res => {
        this.previewContent = res.data.content
        this.previewVersion = res.data.android_version
        this.previewQrcode = window.location.host + '/' + res.data.android_url + '_' + res.data.android_version + '.apk'
        this.previewVisible = true
      })
    }

  }

}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  padding: 0;
  margin: 0;
  height: 100%;

  &.mobile {
    .container {
      width: 100%;
      min-height: 100%;
      background: #F0F2F5 url(~@/assets/background.svg) no-repeat 50%;
      background-size: 100%;
      padding: 270px 0;
      position: relative;

      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  &.tablet {
    .pc-container {
      .pc-diy-right {
        left: 50%;
        margin-left: -205px;
      }
    }
  }

  &.desktop {
    .pc-container {
      .pc-diy-right {
        right: 15%;
      }
    }
  }

  &.desktop, &.tablet {
    .pc-container {
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #2572FD 0%, #2586FD 72%, #26ACFF 100%);
      position: absolute;
      display: flex;

      .container-main {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .background {
          position: absolute;

        }

        .title {
          height: 80px;
          display: flex;
          img {
            height: 100%;
          }

          .img-split {
            border-left: solid 2px #FFFFFF;
            height: 50px;
            vertical-align: middle;
            display: inline-block;
            margin: 5px 20px;
          }

        }

        .content {
          width: 1040px;
          height: 640px;
          margin-top: 30px;
          position: relative;

          .top {
            width: 100%;
            height: 498px;
            border-radius: 4px;
            background: #FFFFFF;
            padding: 86px 80px 0 38px;
            position: relative;

            .tree-left {
              position: absolute;
              top: 380px;
              left: 495px;
              width: 64px;
              height: 138px;
              background: #FFFFFF00 url("~@/assets/images/desk_tree_left.png") no-repeat;
              background-size: 100%;
              z-index: 1;
            }

            .login {
              position: absolute;
              top: 80px;
              left: 548px;
              z-index: 2;
            }

            .tree-right {
              position: absolute;
              top: 406px;
              left: 938px;
              width: 102px;
              height: 122px;
              background: #FFFFFF00 url("~@/assets/images/desk_tree_right.png") no-repeat;
              background-size: 100%;
              z-index: 1;
            }
          }

          .bottom {
            position: absolute;
            height: 148px;
            opacity: 1;
            border-radius: 0px 0px 4px 4px;
            bottom: 0;
            width: 100%;
            background: #608EF9 url(~@/assets/images/desk_main_content_bottom.png) no-repeat 100%;
          }
        }
      }

      .pc-diy-pic {
        width: 48%;
        height: 66%;
        position: absolute;
        left: 7%;
        top: 21%;
        float: left;

        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      }

      .pc-diy-right {
        width: 410px;
        height: 440px;
        position: absolute;
        top: 50%;
        margin-top: -200px;
      }

      .footer {
        .links a {
          color: rgba(255, 255, 255, 0.9);
        }

        .copyright {
          color: rgba(255, 255, 255, 0.9);

          a {
            color: #B1DCEC;
            cursor: pointer;
          }
        }
      }
    }
  }

  .container {

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }

        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, .85);
          font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-weight: 600;
          position: relative;
          top: 0px;
        }
      }

      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .main {
      min-width: 412px;
      margin: 0 auto;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        margin-bottom: 8px;
        font-size: 14px;

        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;

          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }

      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  }
  .Imgbox{
    width: 270px;
    height: 70px;
    display: inline-block;
    img{
      // width: 100%;
      height: 100%;
    }
  }
  .Imgboxleft{
    text-align: right;
  }
}
</style>
