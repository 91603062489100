<template>
  <div class="check-task-enter">
    <a-spin :spinning="fullPageLoading">
      <template v-if="modelId">
        <div class="check-task-enter-title">
          <ul-table title="批次信息">
            <template #content>
              <ul-table-row>
                <ul-table-column title="检测时间">
                  <a-range-picker v-model="checkTime" format="YYYY-MM-DD HH:mm" show-time @ok="onRangeOk" />
                </ul-table-column>
                <ul-table-column title="房间">
                  <a-tree-select
                    v-model="locationId"
                    show-search
                    tree-node-filter-prop="title"
                    :replaceFields="replaceFields"
                    style="width: 150px"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="请选择房间"
                    allow-clear
                    tree-default-expand-all
                    :tree-data="treeData"
                    @change="changeLocation"
                  >
                  </a-tree-select>
                </ul-table-column>
                <ul-table-column title="温度">
                  <a-input-number v-model="batchInfo.wd" :step="0.1" />
                </ul-table-column>

                <ul-table-column title="湿度">
                  <a-input-number v-model="batchInfo.sd" :step="0.1" />
                  <a @click="expressModalProps.show" style="margin-left: 4px">
                    <a-icon type="unordered-list" />
                  </a>
                </ul-table-column>
              </ul-table-row>
            </template>
          </ul-table>
        </div>

        <div class="check-task-enter-content">
          <div style="display: flex; justify-content: space-between">
            <a-radio-group v-model="radioType" button-style="solid" @change="changeViewType">
              <a-radio-button value="list">列表</a-radio-button>
              <a-radio-button value="step">步骤</a-radio-button>
            </a-radio-group>
            <div v-if="itemName && (itemName === '粪大肠菌群' || itemName === '耐热大肠菌群' || itemName === '总大肠菌群' || itemName === '大肠菌群数')">
              <a-button type="primary" :loading="isClickedMpn" @click="getMpn">查表</a-button>
            </div>
          </div>

          <template v-if="viewType === 'step'">
            <div class="steps">
              <a-steps :current="currentStep">
                <a-step v-for="item in stepForm" :key="item.key" :title="item.title" @click="handleStepChange(item.key, item.index)"></a-step>
              </a-steps>
            </div>

            <div class="content">
              <template v-for="item in stepForm">
                <div v-show="enterTableKey === item.key" :key="item.key" class="table">
                  <enter-hot-table
                    :ref="item.key"
                    :col-headers="colHeaders"
                    :columns="columns"
                    :expressions.sync="expressions"
                    :hidden-columns="hiddenColumns"
                    :table-data="item.data"
                    :tableKey="item.key"
                  />
                </div>
              </template>
            </div>
          </template>
          <template v-if="viewType === 'list'">
            <a-timeline style="margin-top: 10px">
              <a-timeline-item v-for="item in stepForm" :key="item.key">
                <template #dot
                  ><span style="display: inline-block; width: 32px; height: 32px; line-height: 32px; border-radius: 50%; background: #1890ff; font-size: 16px; color: #fff">{{
                    item.index + 1
                  }}</span></template
                >
                <div style="margin-left: 8px">
                  <div
                    style="
                      font-weight: 500;
                      font-size: 16px;
                      color: rgba(0, 0, 0, 0.85);
                      display: inline-block;
                      padding-right: 16px;
                      position: relative;
                      margin-top: -8px;
                      line-height: 32px;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div class="table">
                    <enter-hot-table
                      :ref="item.key + 'list'"
                      :col-headers="colHeaders"
                      :columns="columns"
                      :expressions.sync="expressions"
                      :hidden-columns="hiddenColumns"
                      :table-data="item.data"
                      :tableKey="item.key"
                    />
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>
          </template>
        </div>
      </template>
      <template v-if="!modelId">
        <div class="no-data-striking">
          暂未配置对应的检测录入模型，请先配置后再试！
          <template v-if="checkDetailId">
            <a-button icon="setting" style="vertical-align: text-bottom" type="primary" @click="toItemDetail">立刻前往</a-button>
          </template>
        </div>
      </template>
    </a-spin>
    <a-modal v-bind="expressModalProps">
      <p :key="item.key" v-for="item in expressions">{{ item.key }} : {{ item.value }}</p>
      <template #footer>
        <a-button @click="expressModalProps.cancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { itemCheckDetailApi, sysModelFieldApi, resLocationApi, Get } from '@/api/quote'
import { checkTaskEnterApi, checkTaskEnterExpressionApi, checkTaskBatchApi } from '@/api/check/checkTaskApi'
import EnterHotTable from '@/pages/cloud-quote/check-task/modules/enter/EnterHotTable.vue'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkFieldApi } from '@/api/system'
import { groupBy, orderBy, cloneDeep } from 'lodash'
import { checkBatchApi, checkBatchCurveApi } from '@/api/checkBatch/checkBatchApi'
import { checkStandardSolutionDemarcatesApi } from '@/api/checkStandardSolution/checkStandardSolutionDemarcatesApi'
import mixinDate from '@/pages/common/mixins/date'
import UlTable, { UlTableColumn, UlTableRow } from '@/pages/common/ul-table/Table.jsx'
import { getColName } from '@/api/common'

import '@/assets/less/common/form.less'

export default {
  name: 'CheckTaskEnter',
  mixins: [mixinDate],
  components: { UlTable, EnterHotTable, UlTableRow, UlTableColumn },

  props: {
    checkDetailId: { required: true, type: Number },
    modelId: { type: Number },
    batchId: { required: true, type: Number },
    batchGroupId: { required: true, type: Number },
    itemName: { required: false, type: String },
    cateId: { required: false, type: Number },
    cateCode: { required: false, type: String }
  },

  data() {
    return {
      getColName,
      /** 原生的任务数据 */
      originalData: [],
      /** 表格数据  */
      tableData: [],
      /** 模型信息 */
      modelInfoList: [],
      // 字段对应关系，原始字段映射成驼峰形式,不需要响应式
      camelCaseFieldMap: Object.freeze({ mapping: {} }),
      expressModalProps: {
        visible: false,
        title: '表达式值',
        show: () => (this.expressModalProps.visible = true),
        cancel: () => (this.expressModalProps.visible = false)
      },
      /** 当前的步骤值 */
      currentStep: 0,
      /** 当前录入表格的值 */
      currentTableKey: null,
      /** 采样数据配置 */
      sampleDataConfig: [
        { key: 'labBlank', title: '实验室空白', sampleTypes: [sampleInfoTypeEnum.labBlank] },
        { key: 'qc', title: '质控', sampleTypes: [sampleInfoTypeEnum.qualityControl] },
        { key: 'addSample', title: '加标', sampleTypes: [sampleInfoTypeEnum.addStandardSample, sampleInfoTypeEnum.basisAddStandardSample] },
        { key: 'fieldParallel', title: '平行', sampleTypes: [sampleInfoTypeEnum.labParallel, sampleInfoTypeEnum.fieldParallel] },
        { key: 'sampleBlank', title: '现场空白', sampleTypes: [sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank, sampleInfoTypeEnum.allBlank] },
        { key: 'normal', title: '普通样品', sampleTypes: [sampleInfoTypeEnum.normal] }
      ],
      /** 表达式 */
      expressions: [],
      /** 批次信息 */
      batchInfo: {
        id: null,
        wd: null,
        sd: null,
        makeBeginTime: null,
        makeEndTime: null
      },
      taskList: [],
      treeData: [],
      locationId: null,
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      // 页面切换前的type
      radioType: 'list',
      // 页面切换的type
      viewType: 'list',
      fullPageLoading: false,
      isClickedMpn: false // 粪大肠菌群、耐热大肠菌群、总大肠菌群、大肠菌群数专用查表按钮是否已点击过
    }
  },

  computed: {
    /** hot table 表头信息 */
    colHeaders() {
      return ['样品编号'].concat(
        this.modelInfoList.map((item) => item.fieldName),
        ['因子名称']
      )
    },

    /** hot table 列信息 */
    columns() {
      const defaultColumns = [
        { title: '样品编号', data: 'sampleId', index: 0, readOnly: true, hidden: false, labBlankAverageFlag: false, labBlankAverageFiled: null, type: 'text' }
      ]
      const type = ['INT', 'BIGINT', 'TINYINT', 'SMALLINT', 'DECIMAL']
      const modelColumns = this.modelInfoList.reduce((acc, item, index) => {
        let title = item.aliasName || item.fieldName
        if (item.notNull && item.editAble) {
          title = `<span class="red">${item.aliasName || item.fieldName}</span>`
        } else if (!item.editAble) {
          title = `<span class="gray">${item.aliasName || item.fieldName}</span>`
        }
        acc.push({
          title,
          index: index + 1,
          data: this.getCamelCaseField(item.field),
          type: type.includes(item.type) ? 'numeric' : 'text',
          readOnly: !item.editAble,
          hidden: !item.isShow,
          formula: item.formula,
          labBlankAverageFlag: item.blankAverageFlag,
          labBlankAverageField: item.blankAverageField
        })
        return acc
      }, [])
      return defaultColumns.concat(modelColumns, [
        {
          title: '因子ID',
          data: 'itemId',
          index: this.modelInfoList.length + 1,
          readOnly: true,
          hidden: true,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        },
        {
          title: '因子名称',
          data: 'itemName',
          index: this.modelInfoList.length + 2,
          readOnly: true,
          hidden: false,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        }
      ])
    },

    hiddenColumns() {
      return [...this.modelInfoList.map((item, index) => (!item.isShow ? index + 1 : null)).filter((item) => !!item), this.modelInfoList.length + 1]
    },

    /** 检测录入的数据的查询对象 */
    queryEnterDataParam() {
      const { modelId, batchId } = this
      return modelId && batchId ? { modelId, batchId } : null
    },

    /** 处理表格数据的对象 */
    dealTableData() {
      const { modelInfoList, originalData } = this
      return { modelInfoList, originalData }
    },

    /** 实验室需要进行空白的字段 */
    labBlankAverageColumns() {
      return this.columns.filter((item) => item.labBlankAverageFlag)
    },

    /** 步骤配置信息 */
    stepForm() {
      let idx = 0
      return this.sampleDataConfig.reduce((acc, item) => {
        const tableData = this.tableData.filter((data) => item.sampleTypes.includes(data.sampleType))
        const defaultData = []
        if (item.key === 'labBlank') {
          const labBlankAverageColumns = this.labBlankAverageColumns
          if (labBlankAverageColumns.length) {
            const filterIdx = labBlankAverageColumns.map((item) => item.index)
            const averageData = this.columns.reduce((acc, item) => {
              if (item.index === 0) {
                return { ...acc, [item.data]: '平均值:' }
              } else if (filterIdx.includes(item.index)) {
                const colName = this.getColName(1 + item.index)
                // const colName = String.fromCharCode(65 + item.index)
                // console.log(this.getColName(1 + item.index),String.fromCharCode(65 + item.index));
                return { ...acc, [item.data]: `=AVERAGE(${colName}1:${colName}${tableData.length})` }
              } else {
                return { ...acc, [item.data]: '' }
              }
            }, {})
            defaultData.push(averageData)
          }
        }
        if (tableData.length) {
          return [...acc, { key: item.key, title: item.title, data: tableData.concat(defaultData), index: idx++ }]
        }
        return acc
      }, [])
    },

    /** 当前录入的表格 KEY */
    enterTableKey() {
      return this.currentTableKey ?? this.stepForm[0]?.key
    },

    checkTime: {
      set(value) {
        const [start, done] = value
        this.batchInfo.makeBeginTime = this.timeFormat(start)
        this.batchInfo.makeEndTime = this.timeFormat(done)
      },
      get() {
        const { makeBeginTime, makeEndTime } = this.batchInfo
        return makeBeginTime && makeEndTime ? [this.moment(makeBeginTime), this.moment(makeEndTime)] : []
      }
    }
  },

  watch: {
    modelId: {
      immediate: true,
      handler(value) {
        value && this.searchModelInfo(value)
      }
    },

    queryEnterDataParam: {
      immediate: true,
      handler(value) {
        value && this.searchEnterData(value)
      }
    },

    dealTableData: {
      deep: true,
      handler(value) {
        const { modelInfoList, originalData } = value
        // console.log(' modelInfoList, originalData', value)
        modelInfoList.length && originalData.length && this.fillEnterData(originalData)
      }
    },

    checkDetailId: {
      immediate: true,
      handler(value) {
        value && this.loadLimitValueExpression(value)
      }
    },

    batchId: {
      immediate: true,
      handler(value) {
        value && this.searchBatchInfo(value)
        value && this.loadCurveExpression(value)
        // value && this.handleDevAndLocation(value, '2023-04-18 15:00:12')
        value && this.selectLocationByDevId(value)
        if (value) {
          this.getStandardCalcResult(value)
        } else {
          this.$nextTick(() => {
            this.changeExpression('standardCalcResult', 0)
          })
        }
      }
    }
  },
  created() {
    this.getLocation()
  },

  methods: {
    selectLocationByDevId(devId) {
      checkBatchApi.selectLocationByDevId({ devId }).then((res) => {
        if (res.code === 0) {
          if (res.data && res.data.locationId) {
            this.locationId = parseInt(res.data.locationId)
            const timer = setInterval(() => {
              if (this.checkTime.length > 0) {
                clearInterval(timer)
                this.changeLocation()
              }
            }, 500)
          }
        }
      })
    },
    getLocation() {
      Get(resLocationApi.list).then((res) => {
        // console.log(res)
        if (res.code === 0) {
          this.treeData = res.data
        }
      })
    },
    changeLocation(value, label, extra) {
      this.handleDevAndLocation(this.checkTime[0].format('YYYY-MM-DD HH:mm:ss'))
    },
    /**
     * 获取批次信息
     * @param id {number} 批次 ID
     */
    searchBatchInfo(id) {
      this.taskList = []
      checkBatchApi.findBydId(id).then((res) => {
        if (res.code === 0) {
          const { wd, sd, makeBeginTime, makeEndTime, taskList } = res.data
          // wd, sd,
          Object.assign(this.batchInfo, { id, makeBeginTime, makeEndTime })
          this.taskList = taskList || []
          this.handleDevAndLocation(makeBeginTime)
        } else {
          this.$message.error(`获取批次信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 查询模型信息
     * @param modelId 模型ID
     * @return {Promise} 查询结果
     */
    searchModelInfo(modelId) {
      const modelInfoApis = [sysModelFieldApi.getByModelId(modelId), checkFieldApi.list({ modelId, page: false })]
      return Promise.all(modelInfoApis).then(([res, checkRes]) => {
        if (res.code === 0) {
          const resData = orderBy(res.data || [], ['sort'])
          const checkData = checkRes.data
          const checkGroupData = groupBy(checkData, 'fieldId')
          resData.forEach((item) => {
            checkGroupData[item.fieldId] && Object.assign(item, ...checkGroupData[item.fieldId])
          })
          this.modelInfoList.splice(0, this.modelInfoList.length, ...resData)
          // console.log('modelInfoList', this.modelInfoList)
        } else {
          this.$message.error(`获取模型信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 查询检测录入的数据
     * @param batchId 批次ID
     * @param modelId 模型ID
     */
    searchEnterData({ modelId, batchId }) {
      this.fullPageLoading = true
      return checkTaskEnterApi.getData(modelId, batchId).then((res) => {
        if (res.code === 0) {
          this.originalData.splice(0, this.originalData.length, ...(res.data || []))
        } else {
          this.$message.error(`获取检测数据错误 :${res.msg}`)
        }
      })
    },

    /**
     * 填充录入的数据
     * @param dataList 录入的原始数据
     */
    async fillEnterData(data) {
      // console.log(data);
      let dataList = cloneDeep(data)
      const result = dataList.reduce((acc, item, index) => {
        const fillData = this.modelInfoList.reduce((subAcc, subItem) => {
          const { dvalue: defValue, field } = subItem
          const camelCaseField = this.getCamelCaseField(field)
          // 设置默认值
          if (defValue && !item[camelCaseField]) {
            subAcc[camelCaseField] = defValue
          }
          return subAcc
        }, item)
        // console.log('fillData', fillData)
        acc.push(fillData)
        return acc
      }, [])
      for (let i = 0; i < result.length; i++) {
        let item = result[i]
        if (item.cateType === 'G') {
          let bool = this.modelInfoList.some((it) => it.fieldName.includes('采样体积') || it.fieldName.includes('标况体积') || it.fieldName.includes('参比体积'))
          if (bool) {
            let defaultData = await this.getVolumeInfo(item.projId, item.sampleId)
              .then((res) => {
                if (res.code === 0) {
                  return res.data
                }
              })
              .catch((error) => {
                return null
              })
            if (defaultData) {
              for (const key in defaultData) {
                if (!item[key] && defaultData[key]) {
                  item[key] = defaultData[key]
                }
              }
            }
            // if (defaultData && (defaultData.actualVolume || defaultData.actualVolume === 0)) {
            //   item.actualVolume = defaultData.actualVolume // 采样体积
            // }
            // if (defaultData && (defaultData.standardVolume || defaultData.standardVolume === 0)) {
            //   item.standardVolume = defaultData.standardVolume // 标况体积
            // }
            // if (defaultData && (defaultData.referenceVolume || defaultData.referenceVolume === 0)) {
            //   item.referenceVolume = defaultData.referenceVolume // 参比体积
            // }
          }
        }
      }
      this.tableData.splice(0, this.tableData.length, ...result)
      // console.log('fillEnterData', this.tableData)
      this.fullPageLoading = false
    },

    /**
     * 获取采样体积、标况体积、参比体积的默认数据
     * @param modelTableName 模型code
     * @param sampleId 样品Id
     */
    getVolumeInfo(proId, sampleId) {
      return checkTaskEnterApi.getVolumeInfo({ proId, cateId: this.cateId, cateCode: this.cateCode, sampleId })
    },

    /**
     * 请求录入的数据，这是专用于粪大肠菌群、耐热大肠菌群、总大肠菌群、大肠菌群数的mpnOneHundredMl字段的请求接口
     * @param dataList 录入的原始数据
     */
    async getMpn() {
      if (this.itemName && (this.itemName === '粪大肠菌群' || this.itemName === '耐热大肠菌群' || this.itemName === '总大肠菌群' || this.itemName === '大肠菌群数')) {
        if (this.isClickedMpn) {
          this.$message.warning('正在查询中，请稍后再点击！')
          return
        }
        this.isClickedMpn = true
        let itemType = 1
        if (this.itemName === '粪大肠菌群') {
          itemType = 1
        } else if (this.itemName === '耐热大肠菌群') {
          itemType = 2
        } else if (this.itemName === '总大肠菌群' || this.itemName === '大肠菌群数') {
          itemType = 3
        }
        let keyName = ''
        if (this.viewType === 'list') {
          keyName = 'list'
        }
        for (let i = 0; i < this.stepForm.length; i++) {
          let item = this.stepForm[i]
          let data = this.$refs[item.key + keyName][0].getData()
          for (let j = 0; j < data.length; j++) {
            let item2 = data[j]
            let params = {
              itemType,
              ten: item2.totalYangTubeTen,
              one: item2.totalYangTubeOne,
              zero: item2.totalYangTubeOneTenth
            }
            await checkTaskEnterApi
              .mpnSelectInfo(params)
              .then((res) => {
                // console.log(res)
                // console.log('111111111111');
                if (res.code === 0) {
                  item2.mpnOneHundredMl = res.data
                } else {
                  this.$message.error(res.msg)
                  this.isClickedMpn = false
                }
              })
              .catch((error) => {
                this.$message.error(error)
                this.isClickedMpn = false
              })
          }
          // console.log('222222222222222222222', data)
          this.$refs[item.key + keyName][0].loadData(data)
        }
        this.isClickedMpn = false
      }
    },

    /** 跳转到检测明细 */
    toItemDetail() {
      this.$router.open({ path: '/cloud-quote/item-check-detail', query: { id: this.checkDetailId } })
    },

    /**
     * 根据字段获取对应的驼峰形式
     * @param field 原始字段形式
     * @return {String} 驼峰
     */
    getCamelCaseField(field) {
      const map = this.camelCaseFieldMap.mapping
      return map[field] || (map[field] = field.replace(/_(\w)/g, (all, letter) => letter.toUpperCase()))
    },

    /**
     * 处理步骤跳转
     * @param key 步骤配置的KEY
     * @param index 步骤配置的索引
     */
    handleStepChange(key, index) {
      this.currentTableKey = key
      this.currentStep = index
    },

    /**
     * 保存数据
     */
    async submit() {
      this.fullPageLoading = true
      let keyName = ''
      if (this.viewType === 'list') {
        keyName = 'list'
      }
      const resultData = this.stepForm.reduce((acc, item) => {
        const data = this.$refs[item.key + keyName][0].getData()
        return [...acc, ...data]
      }, [])

      await checkBatchApi.update(Object.assign({}, this.batchInfo, { id: this.batchId }))
      if (resultData.length) {
        const expressions = this.expressions.map((item) => {
          return {
            key: item.key,
            value: item.value,
            batchId: this.batchId
          }
        })
        let normalSampleMeetingStandardsData = this.getMeetingStandardsData()
        if (normalSampleMeetingStandardsData.length) {
          resultData.forEach((item) => {
            let items = normalSampleMeetingStandardsData.filter((item2) => item2.sampleId === item.sampleId)
            if (items.length) {
              if (items[0].meetingStandards || items[0].meetingStandards === 0) {
                item.meetingStandards = items[0].meetingStandards
              }
              if (items[0].meetingValues || items[0].meetingValues === 0) {
                item.meetingValues = items[0].meetingValues
              }
            }
          })
        }
        // console.log(resultData, normalSampleMeetingStandardsData)
        // console.log(this.stepForm, resultData)
        // console.log(expressions)
        const saveApi = [checkTaskEnterApi.save(this.modelId, resultData), checkTaskEnterExpressionApi.save(expressions)]

        Promise.all(saveApi).then(([res]) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.fullPageLoading = false
            this.$emit('success')
          } else {
            this.$message.error(`检测数据录入失败 :${res.msg}`)
            this.fullPageLoading = false
          }
        })
      }
    },

    /**
     * 加载限值表达式
     * @param checkDetailId {number} 检测明细ID
     */
    loadLimitValueExpression(checkDetailId) {
      itemCheckDetailApi.list({ id: checkDetailId, page: false }).then((res) => {
        if (res.code === 0) {
          const result = res.data[0] ?? { limitValue: 0 }
          this.changeExpression('limitValue', result.limitValue)
          if (result.limitValueOne) {
            this.changeExpression('limitValueOne', result.limitValueOne)
          }
          if (result.limitValueTwo) {
            this.changeExpression('limitValueTwo', result.limitValueTwo)
          }
        } else {
          this.$message.error(`获取检测明细信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 加载曲线表达式
     * @param batchId {number} 批次ID
     */
    loadCurveExpression(batchId) {
      checkBatchCurveApi.getCurveByBatchId(batchId).then((res) => {
        if (res.code === 0) {
          const { intercept: curveA = 0, slope: curveB = 0 } = res.data ?? {}
          this.changeExpression('curveA', curveA)
          this.changeExpression('curveB', curveB)
        } else {
          this.$message.error(`获取曲线信息出错 :${res.msg}`)
        }
      })
    },

    getStandardCalcResult(batchId) {
      checkStandardSolutionDemarcatesApi.getFourAveragePurity({ batchId }).then((res) => {
        if (res.code === 0) {
          this.changeExpression('standardCalcResult', res.data)
        }
      })
    },

    handleDevAndLocation(startTime) {
      if (this.locationId && startTime) {
        const time = startTime.substr(0, 13) + ':00'
        checkTaskBatchApi.selectLocation({ locationId: this.locationId, time, batchId: this.batchId }).then((res) => {
          // console.log('接口',res)
          if (res.code === 0 && res.data) {
            const wd = res.data.ambient
            const sd = res.data.temperature
            Object.assign(this.batchInfo, { wd, sd })
          }
        })
      }
    },

    onRangeOk(range) {
      const time = this.moment(range[0]).format('YYYY-MM-DD HH:mm:ss')
      this.handleDevAndLocation(time)
    },

    /**
     * 修改表达式的值
     * @param key 表达式的KEY
     * @param value 表达式的值
     */
    changeExpression(key, value) {
      if (key) {
        const existIdx = this.expressions.findIndex((item) => item.key === key)
        if (existIdx > -1) {
          this.expressions[existIdx].value = value
        } else {
          this.expressions.push({ key, value })
        }
      }
    },

    /**
     * 切换显示方式
     */
    changeViewType(e) {
      // console.log(e.target.value)
      let keyName = ''
      if (e.target.value === 'step') {
        keyName = 'list'
      }
      for (let i = 0; i < this.stepForm.length; i++) {
        let item = this.stepForm[i]
        let data = this.$refs[item.key + keyName][0].getData()
        // console.log(item, data)
        for (let j = 0; j < item.data.length; j++) {
          let itemDataItem = item.data[j]
          let dataItem = data.filter((it) => it.sampleId === itemDataItem.sampleId)[0]
          if (dataItem) {
            for (const k in dataItem) {
              this.$set(this.stepForm[i].data[j], k, dataItem[k])
            }
          }
        }
      }
      this.viewType = e.target.value
    },

    /**
     * 查询普通样品是否达标的数据
     */
    getMeetingStandardsData() {
      let keyName = 'normal'
      if (this.viewType === 'list') {
        keyName += 'list'
      }
      let data = []
      let normalData = this.$refs[keyName][0].getData()
      if (normalData && normalData.length && this.taskList && this.taskList.length) {
        normalData.forEach((item) => {
          let obj = {
            sampleId: item.sampleId,
            result: item.result
          }
          let list = this.taskList.filter((item2) => item2.sampleId === item.sampleId)
          if (list.length) {
            obj.standardValue = list[0].standardValue
          }
          let olist = this.originalData.filter((item2) => item2.sampleId === item.sampleId)
          if (olist.length) {
            obj.itemName = olist[0].itemName
          }
          data.push(obj)
        })
      } else if (normalData && normalData.length) {
        normalData.forEach((item) => {
          let obj = {
            sampleId: item.sampleId,
            result: item.result,
            standardValue: null
          }
          let olist = this.originalData.filter((item2) => item2.sampleId === item.sampleId)
          if (olist.length) {
            obj.itemName = olist[0].itemName
          }
          data.push(obj)
        })
      }
      if (data.length) {
        data.forEach((item) => {
          if ((item.result || item.result === 0) && (item.standardValue || item.standardValue === 0)) {
            if (item.itemName.toUpperCase() === 'PH' || item.itemName.toUpperCase() === 'PH值') {
              let values = item.standardValue.split('~')
              if (values.length == 2) {
                let lowerValue = values[0] // 下限值
                let upperValue = values[1] // 上限值
                if (item.result > 7.0) {
                  // 标准指数=(最终结果-7.0)/(上限值-7.0)
                  item.meetingValues = (item.result - 7.0) / (upperValue - 7.0)
                } else {
                  // 标准指数=(7.0-最终结果)/(7.0-下限值)
                  item.meetingValues = (7.0 - item.result) / (7.0 - lowerValue)
                }
                // 标准指数>1不达标，否则达标
                if (item.meetingValues > 1) {
                  item.meetingStandards = 0
                } else {
                  item.meetingStandards = 1
                }
              } else {
                this.$message.error(`${item.itemName}的标准值需要是范围限值，请联系管理员重新配置！`)
              }
              // item.meetingStandards = 1
              // item.meetingValues = 0
            } else if (item.itemName === '溶解氧') {
              let T = 20 // 水温
              let DOf = 468 / (31.6 + T) // 饱和溶解氧浓度
              if (item.result > DOf) {
                // 标准指数=(最终结果-饱和溶解氧浓度)/(饱和溶解氧浓度-标准限值)
                item.meetingValues = (item.result - DOf) / (DOf - item.standardValue)
              } else {
                // 标准指数=标准限值/最终结果
                item.meetingValues = item.standardValue / item.result
              }
              // 标准指数>1不达标，否则达标
              if (item.meetingValues > 1) {
                item.meetingStandards = 0
              } else {
                item.meetingStandards = 1
              }
              // item.meetingStandards = 1
              // item.meetingValues = 0
            } else {
              // 标准指数=最终结果/标准限值
              item.meetingValues = item.result / item.standardValue
              // 标准指数>1不达标，否则达标
              if (item.meetingValues > 1) {
                item.meetingStandards = 0
              } else {
                item.meetingStandards = 1
              }
            }
            item.meetingValues = item.meetingValues.toFixed(2)
          } else if (item.result || item.result === 0) {
            // if (item.itemName.toUpperCase() === 'PH' || item.itemName.toUpperCase() === 'PH值') {
            //   item.meetingStandards = 1
            //   item.meetingValues = '0'
            // } else if (item.itemName === '溶解氧') {
            //   item.meetingStandards = 1
            //   item.meetingValues = '0'
            // } else {
            //   item.meetingStandards = -1
            //   item.meetingValues = '无'
            // }
            item.meetingStandards = -1
            item.meetingValues = '无'
          }
        })
      }

      console.log(data)
      return data
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.check-task-enter {
  .margin();
  .data();

  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-title {
    background: #ffffff;
    padding: 0 30px 30px;
  }

  &-content {
    padding: 30px 30px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .content {
    padding: 0 15%;
    position: relative;
  }
}
</style>
