<template>
  <div>
    <a-form :form="form">
      <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;background-color: #1890ff;color:#ffff;">采样称量申请</div>
      <div style="width: 100%; height: 100%">
        <a-card class="card-search">
          <a-row :gutter="16">
            <a-col :lg="12" :md="16" :sm="24">
              <a-form-item label="申请人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input :disabled="true" v-decorator="['userName', {}]" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="16" :sm="24">
              <a-form-item label="申请称重日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-date-picker
                  :disabled="dateOfApplyAble"
                  format="YYYY-MM-DD HH:mm:ss"
                  v-decorator="['dateOfApply', { rules: [{ required: true, message: '请选择申请日期!  ' }] }]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :lg="12" :md="16" :sm="24">
              <a-form-item label="项目编号及名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <!-- <a-select default-value="a1" @change="handleChange" v-decorator="['projName', {}]" placeholder="">
                  <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
                    {{ (i + 9).toString(36) + i }}
                  </a-select-option>
                </a-select> -->
                <a-input v-decorator="['projNo', {}]" @change="onChangeProjNo" :disabled="projNoAble" />
              </a-form-item>
            </a-col>
            <a-button type="primary" @click="testProj()">校验</a-button>
          </a-row>
        </a-card>
        <!-- v-if="isEdit" -->
        <a-card style="padding-bottom: 50px" v-if="isEdit">
          <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;background-color: #1890ff;color:#ffff;">采样称量表</div>
          <div style="width: 100%; height: 100%">
            <hot-table ref="table" :settings="setting" language="zh-CN" style="width: 100%"></hot-table>
          </div>
          <a-button type="primary" style="width: 100%; margin-top: 18px" @click="saveData()" :disabled="saveAble">保存</a-button>
        </a-card>
      </div>
      <div class="ant-pro-footer-toolbar" style="width: 100%; text-align: center; height: 100px; padding-top: 15px" v-if="createSubmit">
        <template>
          <a-button :style="{ marginRight: '8px' }" @click="handleSubmit" type="primary">提交</a-button>
        </template>
      </div>
    </a-form>
  </div>
</template>
<script>
import { HotTable } from '@handsontable/vue'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import { registerLanguageDictionary, zhCN } from 'handsontable/i18n'
import { tsTaskApi, sampleMediumApplyApi } from '@/api/sample'
import { postDataAction } from '@/api/common'
import Moment from 'moment'
registerAllModules()
registerLanguageDictionary(zhCN)
export default {
  name: 'SampleWeight',
  props: {
    processData: {
      type: Array,
      default: () => []
    },
    userName: {
      type: String,
      default: () => ''
    },
    recordId: {
      type: Number,
      default: null
    },
    projNo: {
      type: String,
      default: () => ''
    }
  },
  components: {
    HotTable
  },
  data() {
    return {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 16
      },
      form: this.$form.createForm(this),
      projId: '',
      status: 1,
      createSubmit: true,
      dateOfApplyAble: false,
      projNoAble: false,
      saveAble: false,
      setting: {
        width: '100%',
        height: 'auto',
        rowHeaders: true,
        colHeaders: true,
        autoColumnSize: true,
        filters: true,
        manualColumnMove: true,
        multiColumnSorting: true,
        stretchH: 'all',
        licenseKey: 'non-commercial-and-evaluation',
        columns: [
          { title: '样品编号', data: 'sampleId', type: 'text' ,readOnly: true,},
          { title: '介质类型', data: 'mediumType', type: 'text',readOnly: true, },
          { title: '介质编号', data: 'mediumId', type: 'text',readOnly: true, },
          { title: '第一次', data: 'weightOne', type: 'text' },
          { title: '第二次', data: 'weightTwo', type: 'text' },
          { title: '第三次', data: 'weightThree', type: 'text' },
          { title: '称量日期', data: 'weighingDate', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss',  correctFormat: true },// 日期格式，与你的数据格式相匹配
         // 确保用户输入的数据符合指定的格式 },
          { title: '称量人', data: 'weighingUser', type: 'text', readOnly: true, renderer: this.resultRenderer }
          // 其他列定义...
        ],

        data: [],
        nestedHeaders: [
          [
            { label: '样品编号', colspan: 1, rowspan: 2 },
            { label: '介质类型', colspan: 1, rowspan: 1 },
            { label: '介质编号', colspan: 1 },
            { label: '重量', colspan: 3 },
            { label: '称量日期', colspan: 1 },
            '称量人'
          ],
          ['', '', '', '第一次', '第二次', '第三次', '', '']
        ],
        afterInit: () => {
          console.log('init')
        },

        afterGetColHeader: function (col, th) {
          //合并表头的前两列
          setTimeout(() => {
            if (col === -1) {
              const theads = th.parentNode.parentNode // 获取当前表头的thead对象
              const trs = theads.getElementsByTagName('tr') // 获取所有行
              const trCols1 = trs[0].getElementsByTagName('th') // 获取第一行所有列
              const trCols2 = trs[1].getElementsByTagName('th') // 获取第二行所有列
              if (trCols1.length === trCols2.length) {
                // 行号表头将第一行的底部边框去除掉，符合合并单元格样式
                // 此处不能执行rowSpan属性，否则出现第二行合表头数据错位
                trCols1[0].style.borderBottom = 'none'
                for (let i = 1; i < trCols1.length; i++) {
                  // 如果单元格不包含colSpan属性且不是隐藏的单元格，则表明需要合并行，否则，则表明不需要合并行
                  if (!trCols1[i].getAttribute('colSpan') && trCols1[i].className !== 'hiddenHeader') {
                    trCols1[i].rowSpan = 2
                    trCols1[i].style.verticalAlign = 'middle'
                    // 将第二行表格隐藏，并将第一行的底部边框去除
                    trCols2[i].className = 'hiddenHeader'
                    trCols1[i].style.borderBottom = 'none'
                  }
                }
              }
            }
          }, 100)
        },
        afterChange: (changes) => {
          const self = this

          if (changes !== null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              // console.log('999999', row, prop, oldValue, newValue)
              // this.$set(this.setting.data[row], prop, newValue)
              // let item = this.setting.data[row]
              // let findIndex = this.paramsData.findIndex((it) => it.sampleId === item.sampleId)
              // if (findIndex === -1) {
              //   this.paramsData.push(item)
              // }
            })
          }
        },
        /** 公式计算的字段 */
      
      },
      paramsData: [],
      isEdit: false
    }
  },

  watch: {
    processData: {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        console.log('', nv, ov)
        if (nv && nv.length && nv[nv.length - 1].name === '流程发起人') {
          // this.isEdit = false
        } else if (nv && nv.length && nv[nv.length - 1].name === '称重人') {
          // this.isEdit = true
        } else {
          this.saveAble = true
          // this.isEdit = true
        }
      }
    },
    projNo: {
      immediate: true,
      handler(nv, ov) {
        console.log(nv, ov)
      }
    },
    recordId: {
      immediate: true,
      handler(nv, ov) {
        console.log('recordId', nv, ov)
      }
    }
  },
  computed: {
    // loginUser() {
    //   return { ...this.$store.getters.userInfo }
    // }
  },
  created() {
    if (this.recordId === null) {
      this.add()
    } else {
      this.edit(this.recordId, 1)
    }
  },
  activated() {
    if (this.recordId === null) {
      this.add()
    } else {
      this.edit(this.recordId, 1)
    }
  },
  methods: {
    moment: Moment,
    // resultRenderer(instance, td, row, col, prop, value, cellProperties) {
    //   if (value) {
    //     td.innerText = value
    //   } else {
    //     td.innerText = this.userName
    //   }
    //   td.className = 'htMiddle htCenter htDimmed'
    // },
    ss() {
      this.setting.data.splice(0, this.setting.data.length, ...list)
    },
    // getTaskList() {
    //   tsTaskApi.taskListAllNoPage({ statuses: ['0', '1', '2', '3'] }).then((res) => {
    //     console.log('采样未完成数据', res)
    //   })
    // },
    testProj() {
      let projNo = this.form.getFieldValue('projNo')
      tsTaskApi.taskListAllNoPage({ statuses: ['0', '1', '2', '3'], projNo, flag: '1' }).then((res) => {
        console.log('查询采样项目校验', res)
        if (res.code === 0) {
          if (res.data.length < 1) {
            this.$message.info('该项目编号不存在或者已经称量')
          } else {
            this.$message.success('校验成功，可以提交')
            let data = res.data[0]
            this.projId = data.projId
          }
        }
      })
    },
    handleSubmit(e) {
      if (this.projId) {
        this.submit(e, this.status)
      } else {
        this.$message.info('请先校验项目编号是否合格')
      }
    },
    submit(e, status) {
      const {
        form: { validateFields }
      } = this
      e.preventDefault()
      // const tableData = this.$refs.table.dataSource
      validateFields((errors, record) => {
        const v = {
          projId: this.projId,
          userName: record['userName'],
          // dateOfApply: record['dateOfApply'] == null ? null : record['dateOfApply'].format('YYYY-MM-DD'),
          dateOfApply: record['dateOfApply'] == null ? null : record['dateOfApply'].format('YYYY-MM-DD HH:mm:ss'),
          status: status
        }
        if (!errors) {
          postDataAction(this.url, v)
            .then((result) => {
              if (result.code === 0) {
                this.$message.success(`操作成功`)
                this.createSubmit = false
                this.$router.push({ name: 'flow-task' })
              } else {
                this.$message.error(result.msg)
              }
            })
            .catch((error) => {
              this.$message.error(`服务器发生错误！错误消息为${error}`)
            })
        }
      })
    },
    saveData(){
      console.log('保存参数', this.setting.data)
      // // this.paramsData
      sampleMediumApplyApi.save(this.setting.data).then((res) => {
        console.log('保存接口', res)
      })
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    onChangeProjNo(value) {
      console.log('4444', value.target.value)
    },
    add() {
      this.status = 0
      this.createSubmit = true
      this.isEdit = false
      this.dateOfApplyAble = false
      this.projNoAble = false
      this.url = sampleMediumApplyApi.add
      let v = {}
      if (this.projNo) {
        v = {
          projNo: this.projNo,
          userName: this.userName,
          dateOfApply: this.moment()
        }
      } else {
        v = {
          projNo: '',
          userName: this.userName,
          dateOfApply: this.moment()
        }
      }
      console.log('gggggggggggggggggggggggggggggggggggggg')
      this.form.$nextTick(() => {
        this.form.setFieldsValue(v)
      })
    },
    edit(id, status) {
      this.visible = true
      this.createSubmit = false
      this.isEdit = true
      this.dateOfApplyAble = true
      this.projNoAble = true
      this.id = id
      this.status = status
      sampleMediumApplyApi.getApplyAllInfo(id).then((result) => {
        const record = result.data
        record.mediumArray.forEach((item) => {
          item.weighingUser = this.userName
        })
        this.setting.data.splice(0, this.setting.data.length, ...record.mediumArray)
        console.log('666666', this.setting.data)
        this.form.$nextTick(() => {
          const v = {
            projNo: record['projNo'],
            userName: record['userName'],
            dateOfApply: record['dateOfApply'] == null ? null : Moment(record['dateOfApply'], 'YYYY-MM-DD')
          }
          this.form.setFieldsValue(v)
        })
      })
    }
  }
}
</script>
<style>
</style>