<template>
  <div class="check-task-schedule-step animate__animated animate__fadeIn">
    <a-card v-bind="baseCard">
      <a-steps :current="currentStep" size="small">
        <a-step @click="currentStep = 0" title="选择任务" />
        <a-step @click="showItemInfos" title="检测方法" />
        <a-step @click="submit" title="生成批次" />
      </a-steps>

      <div style="margin: 16px 0 0 0; min-height: 600px" v-if="currentStep === 0">
        <template v-if="actualTaskList.length">
          <sample-list v-model="sampleInfoList" :show-item-info="true" :taskList.sync="taskList" :disabled="true" />
        </template>
        <div class="data" style="height: 600px" v-else>
          <div class="no-data">
            请先添加任务
            <a-button icon="plus" type="primary" @click="checkTaskListModal.show()">任务</a-button>
          </div>
        </div>
      </div>

      <template v-else-if="currentStep === 1">
        <div style="margin: 16px 0 0 0">
          <item-info ref="itemInfo" :itemInfos="itemInfos" :actualTaskList="actualTaskList"></item-info>
        </div>
      </template>

      <template v-else-if="currentStep === 2">
        <div style="margin: 16px 0 0 0; text-align: center; min-height: 600px; display: flex; align-items: center; justify-content: center">
          <a-spin tip="批次生成中，请稍后。。。" />
        </div>
      </template>
    </a-card>

    <!-- 选择检测任务 -->
    <a-modal v-bind="checkTaskListModal" v-on="checkTaskListModal">
      <check-task-list v-bind="checkTaskListModal.data" :mustHaveCateAndItem="true"></check-task-list>
    </a-modal>
  </div>
</template>

<script>
import { groupBy, orderBy, throttle, uniqBy, cloneDeep } from 'lodash'
import { fullModalData } from '@/pages/common/mixins/modal/full'

import SampleList from '@/pages/cloud-quote/check-task/modules/schedule/SampleList'
import ItemInfo from '@/pages/cloud-quote/check-task/modules/schedule/ItemInfo'
import { CheckTaskList } from '@/pages/cloud-quote/check-task/modules'

import { labQcTypeEnum, sampleInfoStatusEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { tsSampleinfoApi } from '@/api/sample'
import { checkTaskBatchGroupApi } from '@/api/check/checkTaskApi'

export default {
  name: 'CheckTaskBatchStepSchedule',
  components: { SampleList, ItemInfo, CheckTaskList },
  data() {
    return {
      currentStep: 0,
      sampleInfoList: [],
      taskList: [],

      baseCard: {
        type: 'inner',
        size: 'mini',
        style: { marginBottom: '16px' }
      },

      checkTaskListModal: Object.assign({}, fullModalData, {
        title: '选择任务',
        data: {
          ref: 'checkTaskList',
          planed: false,
          queryType: '1',
          rowSelect: {
            selectedRows: [],
            selectedRowKeys: [],
            getCheckboxProps: (record) => {
              return {
                props: { disabled: record.batchNo != null }
              }
            }
          }
        },

        show: () => {
          this.checkTaskListModal.visible = true
        },
        cancel: () => {
          this.checkTaskListModal.visible = false
        },

        ok: () => {
          const data = this.checkTaskListModal.data.rowSelect.selectedRows
          this.taskList.splice(0, this.taskList.length, ...data)
          this.checkTaskListModal.cancel()
        }
      })
    }
  },

  computed: {
    sampleIds() {
      return this.taskList.filter((item) => !labQcTypeEnum.includes(item.sampleType)).map((item) => item.sampleId)
    },

    actualTaskList() {
      // 获取实际提交的任务，如果样品被移除 认为样品不在此批次内，故需要计算实际提交的任务
      const sampleIds = this.sampleInfoList.map((item) => item.id)
      return this.taskList.filter((item) => sampleIds.includes(item.sampleId))
    },

    itemInfos() {
      return (
        uniqBy(
          this.actualTaskList.map((item) => ({
            cateId: item.cateId,
            cateName: item.cateName,
            itemId: item.itemId,
            itemName: item.itemName,
            methodsId: item.methodsId || null,
            methodsName: item.methodsName || null
          })),
          (item) => item.cateId + '-' + item.itemId
        ) || []
      )
    }
  },

  watch: {
    sampleIds: {
      immediate: true,
      handler(sampleIds) {
        sampleIds && sampleIds.length && this.querySampleInfos(sampleIds)
      }
    }
  },

  mounted() {
    this.submit = throttle(this.submit, 6000, { leading: true, trailing: false })
  },

  methods: {
    // 处理样品出库事件
    sampleInfoListHandler(successIds) {
      const taskList = this.taskList.filter((item) => successIds.includes(item.sampleIds))
      taskList.forEach((item) => {
        item.sampleStatus = sampleInfoStatusEnum.outbound
      })
    },

    // 查询样品信息
    querySampleInfos(sampleIds) {
      tsSampleinfoApi.list({ ids: sampleIds, page: false }).then((res) => {
        if (res.code === 0) {
          const sampleInfoList = res.data || []
          this.sampleInfoList.splice(0, this.sampleInfoList.length, ...orderBy(sampleInfoList, 'type'))
        } else {
          this.$message.error(`获取样品信息错误信息:${res.msg}`)
        }
      })
    },

    // 显示检测方法
    showItemInfos() {
      if (!this.itemInfos?.length) {
        this.$message.error('请先选择需要检测的任务！')
        return
      }
      this.currentStep = 1
    },

    handleSubmit() {
      if (this.currentStep === 0) {
        this.showItemInfos()
      } else if (this.currentStep === 1) {
        this.submit()
      }
    },

    async submit() {
      if (!this.itemInfos?.length) {
        this.$message.error('请先选择需要检测的任务！')
        return
      }
      if (!this.$refs.itemInfo) {
        this.$message.error('请先选择检测方法！')
        return
      }
      if (!this.$refs.itemInfo.defaultCompositeItemsStatus) {
        this.$message.error('数据还未完全显示，请稍等一下！')
        return
      }

      this.currentStep = 2
      const selectCheckGuideMap = this.$refs.itemInfo.getSelectCheckGuideMap()
      const checkGuideDetailCateMap = this.$refs.itemInfo.getCheckGuideDetailCateMap()
      const taskGroup = groupBy(this.actualTaskList, (item) => item.cateId + '_' + item.itemId)

      for (let i = 0; i < Object.values(taskGroup).length; i++) {
        let item = Object.values(taskGroup)[i]
        let sampleIds = item.map((it) => it.sampleId)
        let firstItem = item[0]
        let checkDetailId = selectCheckGuideMap[firstItem.cateId + '_' + firstItem.itemId]
        // 检测明细详细信息
        let checkDetail = checkGuideDetailCateMap[firstItem.cateId + '_' + firstItem.itemId]
        if (checkDetailId) {
          let chechedCheckDetail = checkDetail.filter((it) => it.id === checkDetailId)
          let itemList = await this.$refs.itemInfo.getSampleItemList(sampleIds, chechedCheckDetail[0].methodId)
          if (itemList && itemList.length && (!firstItem.associatedItems || firstItem.associatedItems.length === 0)) {
            this.$message.error(`${firstItem.cateName}/${firstItem.itemName} 在当前选择的检测方法下是复合因子，请在检测方法环节最少选择一个复合因子！`)
            return
          }
        }
      }

      const saveData = Object.values(taskGroup).reduce((acc, item) => {
        const firstItem = item[0]
        const checkDetailId = selectCheckGuideMap[firstItem.cateId + '_' + firstItem.itemId]
        // 检测明细详细信息
        const checkDetail = checkGuideDetailCateMap[firstItem.cateId + '_' + firstItem.itemId]
        if (checkDetailId) {
          const chechedCheckDetail = checkDetail.filter((it) => it.id === checkDetailId)
          const taskList = []
          item.forEach((it) => {
            let newIt = cloneDeep(it)
            newIt.compositeItem = null
            if (newIt.associatedItems && newIt.associatedItems.length > 1) {
              newIt.compositeItem = newIt.associatedItems.map((it2) => it2.itemId).join(',')
            }
            delete newIt.allAssociationItems
            delete newIt.associatedItems
            taskList.push({ ...newIt, methodId: chechedCheckDetail[0].methodId, methodName: chechedCheckDetail[0].methodName })
          })
          acc.push({ taskList: taskList, checkDetailId, cateId: firstItem.cateId, itemId: firstItem.itemId })
        }
        return acc
      }, [])
      if (!saveData.length) {
        this.$message.error('暂无需要保存的数据，请先确定检测方法！')
        // this.showItemInfos()
        return false
      }
      console.log('saveData:', saveData)
      return checkTaskBatchGroupApi.saveWithDetail({ detailList: saveData }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(`批次生成失败 :${res.msg}`)
        }
      })
    },

    saveBatchInfo() {}
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
