<template>
  <div>
    <a-table v-bind="sampleTable">

      <template slot="items" slot-scope="item, record">
        <span>{{ record.itemNameStr }}</span>
      </template>

      <template slot="sampleTime" slot-scope="sampleTime, record">
        <a-range-picker :showTime="{ format: 'HH:mm' }" :format="showTimeFormat" :placeholder="['开始时间', '结束时间']" :style="{width:'100%'}" :defaultValue="[record.makeBeginTime,record.makeEndTime]" @change="val => handleMakeTimeChange(val, record)"/>
      </template>

      <template slot="num" slot-scope="num, record">
        <a-input-number :min="1" v-model="record.num" :style="{width:'100%'}"/>
      </template>

      <template slot="storage" slot-scope="storage, record">
        <a-input v-model="record.storage"/>
      </template>

      <template slot="volume" slot-scope="volume, record">
        <a-input-number :min="0" v-model="record.volume" :style="{width:'40%'}"/>
        <j-dict-select-tag v-bind="volumeDictProp" :value="record.volumeUnit" @change="v => handleVolumeUnitChange(v, record)"/>
      </template>

      <template slot="validity" slot-scope="validity, record">
        <a-input-number :style="tableColStyles.validityNumber" :min="1" v-model="record.validity"/>
        <a-radio-group v-model="record.validityUnit" :style="{width:'63%'}">
          <a-radio value="天">天</a-radio>
          <a-radio value="小时">小时</a-radio>
        </a-radio-group>
      </template>

      <template #appearanceStatus="appearanceStatus, record">
        <a-select v-bind="appearanceStatusSelect" @change="val=>appearanceStatusSelect.change(val,record)" v-model="record.appearanceStatusArr">
          <a-select-option v-for="(data) in appearanceStatusSelect.dataSource" :key="data">{{ data }}</a-select-option>
        </a-select>
      </template>

      <template slot="photos" slot-scope="photos, record">
        <a-tag @click="showQrcode(record.idTemp)" color="orange">二维码传图</a-tag>
        <template v-if="photos.length">
          <div :key="photoIndex" class="photos" v-for="(photo,photoIndex) in photos">
            <span :style="{float:'left',width:'80%'}">{{ photo.name }}</span>
            <span :style="{float:'left',marginRight: '5px'}"><a @click="handlePreview(photo)"><a-icon type="eye"></a-icon></a></span>
            <span :style="{float:'left'}">
              <a-popconfirm @confirm="photos.splice(photoIndex,1)" title="是否要删除此图片吗？">
                <a><a-icon type="delete"></a-icon></a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </template>

      <template slot="operation" slot-scope="operation, record,index">
        <a-popconfirm title="是否要移除此行？" @confirm="removeSample(index)">
          <a>移除</a>
        </a-popconfirm>
      </template>
    </a-table>

    <picture-upload ref="pictureUploadModal" :file-length="3" modalTitlte="上传样品照片" @result="savePicture"/>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>

import moment from 'moment'
import { cloneDeep, omit } from 'lodash'
import { randomString } from '@/utils/util'

import { resMaterialApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import JDictSelectTag from '@/pages/common/dict/JDictSelectTag'
import PictureUpload from '@/pages/common/img-qrcode/PictureUpload'
import { printHelper } from '@/utils/printUtil'

moment.prototype.toISOString = function() {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

const showTimeFormat = 'YYYY-MM-DD HH:mm'

export default {
  components: { JDictSelectTag, PictureUpload },
  props: {
    sampleInfos: { type: Array, required: true },
    projId: { type: Number, required: true },
    projNo: { type: String, required: true },
    itemAliasMap: { type: Object, required: true },
    sampleStatusDictList: { type: Array, required: true }
  },

  data () {
    return {
      nowDate: this.now(),

      addPictureId: null,

      isGroup: this.needGroup,

      isIdInProjNo: true,

      saveSampleList: [],

      appearanceStatusSelect: {
        mode: 'tags',
        style: { width: '90%' },
        tokenSeparators: [',', '，'],
        dataSource: this.sampleStatusDictList,
        placeholder: '请选择样品状态',
        change: (val, record) => {
          const notIncludeArr = val && val.length && val.filter(item => !this.appearanceStatusSelect.dataSource.includes(item)) || []
          notIncludeArr.length && this.appearanceStatusSelect.dataSource.splice(this.appearanceStatusSelect.dataSource.length, 0, ...notIncludeArr)
          record.appearanceStatus = val && val.length && val.join(',') || null
        }
      },

      taskInfo: {
        projId: this.projId,
        projNo: this.projNo,
        projTaskId: this.projTaskId,
        siteId: this.siteId
      },

      siteCount: 1,

      showTimeFormat: showTimeFormat,

      timeFormat: 'YYYY-MM-DD HH:mm:ss',

      sampleTable: {
        pagination: false,
        size: 'small',
        rowKey: 'idTemp',
        bordered: true,
        dataSource: [],
        columns: [
          { title: '检测项', key: 'items', width: 150, scopedSlots: { customRender: 'items' } },
          { title: '采样时间', dataIndex: 'sampleTime', key: 'sampleTime', width: 250, scopedSlots: { customRender: 'sampleTime' } },
          { title: '份数', dataIndex: 'num', key: 'num', width: 80, scopedSlots: { customRender: 'num' } },
          { title: '存储介质', dataIndex: 'storage', key: 'storage', width: 100, scopedSlots: { customRender: 'storage' } },
          { title: '体积/重量', dataIndex: 'volume', key: 'volume', width: 150, scopedSlots: { customRender: 'volume' } },
          { title: '有效期', dataIndex: 'validity', key: 'validity', width: 180, scopedSlots: { customRender: 'validity' } },
          { title: '样品状态', dataIndex: 'appearanceStatus', key: 'appearanceStatus', width: 150, scopedSlots: { customRender: 'appearanceStatus' } },
          { title: '样品照片', dataIndex: 'photos', key: 'photos', width: 150, scopedSlots: { customRender: 'photos' } },
          { title: '操作', key: 'action', width: 80, scopedSlots: { customRender: 'operation' } }
        ]
      },

      previewVisible: false,
      previewImage: null,

      pictureUpload: {
        accept: 'image/*',
        action: sysCommonApi.uploadFile,
        multiple: true,
        showUploadList: true,
        listType: 'text',
        headers: { 'Access-Token': this.$store.getters.token },
        preview: this.handlePreview
      },

      volumeDictProp: {
        style: { width: '50%', marginLeft: '10px' },
        placeholder: '请选择单位',
        triggerChange: true,
        dictCode: 'dev_measure'
      },

      tableColStyles: {
        itemDeleteButton: { marginLeft: '5px' },
        validityNumber: { width: '30%', marginRight: '5px' },
        addSampleButton: { width: '100%', margin: '16px 0 8px' }
      }
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },

  watch: {

    sampleInfos: {
      immediate: true,
      handler (val) {
        val && this.initSampleInfo(val)
      }
    },

    'appearanceStatusSelect.dataSource': {
      deep: true,
      handler (val) {
        this.$emit('update:sampleStatusDictList', val)
      }
    }
  },

  methods: {

    moment,

    print (record) {
      let itemName = record.groupName || record.items.reduce((initName, item) => {
        return initName + ',' + this.itemInfo.children.find(child => child.id === item[1]).name
      }, '')
      itemName.startsWith(',') && (itemName = itemName.substring(1))
      try {
        printHelper('sample', [record.id, record.id, itemName, record.sampleBeginTime.format('YYYY-MM-DD HH:mm:ss'), this.loginUser.realname])
      } catch (e) {
        this.$message.error('打印失败，请检查打印助手和设备是否正常连接')
        console.log('打印失败，请检查打印助手和设备是否正常连接', e)
      }
    },

    savePicture (data) {
      const find = this.sampleTable.dataSource.find(item => item.idTemp === this.addPictureId)
      find && find.photos.splice(0, find.photos.length, ...data)
    },

    showQrcode (id) {
      this.addPictureId = id
      this.$refs.pictureUploadModal.show('二维码', `sampleinfo-${id}-${randomString(6)}`)
    },

    handleMakeTimeChange (val, record) {
      record.makeBeginTime = val[0]
      record.makeEndTime = val[1]
    },

    itemChange (value, record) {
      if ((record.items.length && record.items[0][0] !== value[0]) || record.items.length) {
        this.initId(record)
      }
    },

    initSampleInfo (sampleInfos = []) {
      sampleInfos.forEach(item => {
        item.makeBeginTime = this.now()
        item.makeEndTime = this.now().add('hour', 1)
        item.appearanceStatusArr = []
        item.num = 1
        item.photos = []
        item.validity = 30
        item.validityUnit = '天'
      })
      console.log('sampleInfos.map(item => { return {item.batchNo} })=>', sampleInfos.map(item => { return item.batchNo }))
      this.sampleTable.dataSource.splice(0, this.sampleTable.dataSource.length, ...sampleInfos)
    },

    storageCascaderChange (value, record) {
      record.storage = value.map(item => item[item.length - 1]).join(',')
    },

    handlePreview (file) {
      this.previewImage = file.thumbUrl
      this.previewVisible = true
    },

    handleCancel () {
      this.previewVisible = false
    },

    now () {
      return this.moment(new Date(), this.timeFormat)
    },

    findStorageCascader () {
      resMaterialApi.getMaterialAndDevCascade({}).then(res => {
        if (res.code === 0) {
          this.storageCascader.options = res.data
          this.storageCascader2.options = res.data
        }
      })
    },

    handleFileChange ({ file, fileList }, record) {
      record.photos = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.view + file.response.data.fileUrl
          file.url = file.response.data.fileUrl
          fileList.forEach(item => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },

    handleVolumeUnitChange (e, record) {
      record.volumeUnit = e
    },

    removeSample (index) {
      this.sampleTable.dataSource.splice(index, 1)
    },

    storageFilter (inputValue, path) {
      return (path.some(option => (option.name).indexOf(inputValue) > -1))
    },

    getResult () {
      const result = cloneDeep(this.sampleTable.dataSource)
      result.forEach(item => {
        if (item.photos && item.photos.length) {
          const photos = item.photos.filter(it => !it.error)
          for (let i = 0; i < photos.length; i++) {
            photos[i] = omit(photos[i], ['lastModified', 'lastModifiedDate', 'originFileObj', 'percent', 'response', 'thumbUrl'])
          }
          item.photos = JSON.stringify(photos)
        } else {
          item.photos = null
        }
        delete item['createTime']
        delete item['updateTime']
        delete item['createUser']
        delete item['updateUser']
      })
      return result
    }
  }
}
</script>

<style scoped>
.photos {
  color     : gray;
  font-size : 12px;
  padding   : 5px;
}
</style>
