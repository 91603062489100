<template>
  <div>
    <a-upload
      :action="uploadUrl"
      listType="text"
      :fileList="localFileList"
      :headers="tokenHeader"
      :accept="accept"
      :remove="handleFileRemove"
      @change="handleFileChange"
      multiple>
      <a-button> <a-icon type="upload" /> {{ title }} </a-button>
    </a-upload>
  </div>
</template>

<script>
import { sysCommonApi } from '@/api/common'

/**
 * 请使用新的组件 @/pages/common/upload/UploadFil
 */
export default {
  name: 'UploadFile',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '点击上传文件'
    },
    fileList: {
      type: Array,
      default: () => ([])
    },
    accept: {
      // 可以上传的文件格式
      type: String,
      default: '*'
    }
  },
  data () {
    return {
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      downLoad: sysCommonApi.downLoad,
      downLoadById: sysCommonApi.downLoadById,
      localFileList: [],
      oldFileIdList: [],
      delFileIdList: []
    }
  },
  methods: {
    handleFileRemove (file) {
      var self = this
      const index = self.localFileList.indexOf(file)
      const localFileList = self.localFileList.slice()
      localFileList.splice(index, 1)
      self.localFileList = localFileList
      self.makeNewFileList()
    },
    handleFileChange (info) {
      var self = this
      self.localFileList = info.fileList
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          var fileListTemp = info.fileList
          if (fileListTemp) {
            fileListTemp.forEach((file, idx) => {
              if (!fileListTemp[idx].data) {
                var filePath = fileListTemp[idx].response.data.fileUrl
                var fileName = fileListTemp[idx].response.data.fileName
                fileListTemp[idx].url = `${this.downLoad}?filePath=${filePath}&fileName=${fileName}`
                fileListTemp[idx].data = fileListTemp[idx].response.data
              }
              delete fileListTemp[idx].response
              delete fileListTemp[idx].originFileObj
              delete fileListTemp[idx].lastModifiedDate
            })
          }
          self.makeNewFileList()
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    makeNewFileList () {
      var newFileList = []
      var delFileIds = [] // 需要删除文件
      var tempFileIdList = [] // 剩余的老文件
      this.localFileList.forEach((file, idx) => {
        if (file.data && !file.data.id) {
          newFileList.push(file.data)
        } else {
          tempFileIdList.push(file.data.id)
        }
      })
      // 查询需要删除的文件ID
      this.oldFileIdList.forEach(fid => {
        if (tempFileIdList.indexOf(fid) === -1) {
          delFileIds.push(fid)
        }
      })
      var map = { newFileListStr: JSON.stringify(newFileList), delFileIdsStr: delFileIds.toString() }
      this.$emit('ok', map)
    },
    makeFileList () {
      const self = this
      var fileList = self.fileList
      var updateFileList = []
      var oldFileIdList = []
      if (fileList) {
        fileList.forEach((file,idx)=>{
          updateFileList.push({
            uid: idx,
            name: file.fileName,
            status: 'done',
            url: self.downLoadById + '?id=' + file.id,
            fileOld: true, // 上次的文件
            data: file
          })
          oldFileIdList.push(file.id)
        })
      }
      self.localFileList = updateFileList
      self.oldFileIdList = oldFileIdList
    }
  },
  computed: {
  },
  watch: {
    fileList (nv, ov) {
      this.makeFileList()
    }
  },
  filters: {
  },
  mounted () {
    this.makeFileList()
  },
  created () {
  }
}
</script>

<style scoped>

</style>
