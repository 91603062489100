<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { message } from 'ant-design-vue'
import { computed, watch, watchEffect, ref } from 'vue'
import { qualityOptionDataMap } from '@/api/itemCheckDetail/itemCheckDetailConstant'

const props = defineProps({
  selectCheckGuide: { required: false, type: Object, default: undefined },
  modelValue: { required: true, type: Array },
  taskInfo: { required: true, type: Object },
  sampleInfoList: { required: true, type: Array },
  baseQualityControl: { required: false, type: Object, default: undefined },
  qualityControlStatus: { type: Boolean, required: false, default: false },
  itemList: { required: false, type: Array, default: () => [] },
  compositeItem: { type: String, required: false, default: undefined },
  sampleInfoStatus: { type: Boolean, required: false, default: false },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue'])
const blankList = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

// 添加平行样
const addBlank = () => {
  const [sampleInfo] = props.sampleInfoList
  if (!sampleInfo) {
    message.error('暂无需要检测的样品!')
    return false
  }

  const { itemId, cateId, itemName, cateName } = props.taskInfo
  // console.log('blank',sampleInfo)
  const { id, checkSampleId } = sampleInfo
  const blankNo = `${props.baseQualityControl.batchNo}-${props.baseQualityControl.sampleCode}-KB`

  // const blankIndex = blankList.value.length + 1 >= 10 ? blankList.value.length + 1 : '0' + (blankList.value.length + 1)
  if (props.selectCheckGuide.compositeItemBlank === '2') {
    let compositeItems = props.compositeItem.split(',')
    for (let bi = 0; bi < compositeItems.length; bi++) {
      let csi = compositeItems[bi]
      let blankIndex = '01'
      const list = blankList.value.filter((item) => item.checkSampleId.indexOf(blankNo) > -1)
      list.forEach((item) => {
        const orderNumber = item.checkSampleId.split(blankNo)[1]
        if (parseInt(orderNumber) > parseInt(blankIndex)) {
          blankIndex = orderNumber
        }
      })
      if (list.length > 0) {
        const num = parseInt(blankIndex) + 1
        blankIndex = num >= 10 ? num : '0' + num
      }
      const blankNumber = blankNo + blankIndex
      blankList.value.push({ id, checkSampleId: blankNumber, itemId, cateId, itemName, cateName, compositeItem: csi, editable: true })
    }
  } else {
    let blankIndex = '01'
    const list = blankList.value.filter((item) => item.checkSampleId.indexOf(blankNo) > -1)
    list.forEach((item) => {
      const orderNumber = item.checkSampleId.split(blankNo)[1]
      if (parseInt(orderNumber) > parseInt(blankIndex)) {
        blankIndex = orderNumber
      }
    })
    if (list.length > 0) {
      const num = parseInt(blankIndex) + 1
      blankIndex = num >= 10 ? num : '0' + num
    }
    const blankNumber = blankNo + blankIndex
    blankList.value.push({ id, checkSampleId: blankNumber, itemId, cateId, itemName, cateName, compositeItem: props.compositeItem, editable: true })
  }
  console.log(blankList.value)
}

const firstHandle = ref(false)

const handleWatchEle = () => {
  if (
    props.firstFlag !== 1 &&
    props.qualityControlStatus &&
    props.sampleInfoStatus &&
    props.selectCheckGuide &&
    (props.selectCheckGuide.needBlank === '1' || props.selectCheckGuide.needBlank === '2')
  ) {
    firstHandle.value = true

    blankList.value = blankList.value.filter((item) => !item.editable)
    const residueLength = 2 - blankList.value.length
    if (residueLength > 0) {
      for (let i = 0; i < residueLength; i++) {
        addBlank()
      }
    }
  }
}

const map = { 1: '标准', 2: '企业' }
const qcRefSource = computed(() => (type) => map[type] || '')

// watchEffect(() => {
//   props.firstFlag !== 1 && props.qualityControlStatus && props.sampleInfoStatus && props.selectCheckGuide && handleWatchEle()
// })
watch(
  () => props.firstFlag,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.qualityControlStatus,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.sampleInfoStatus,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.selectCheckGuide,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)

const getItemName = (itemId) => {
  // console.log(itemId, props.itemList)
  let items = props.itemList.filter((item) => item.id === Number(itemId))
  console.log(items)
  if (items.length > 0) {
    return items[0].name
  }
  return ''
}
</script>

<template>
  <ul-table :data-source="blankList" title="空白样">
    <template #extra v-if="props.firstFlag !== 1">
      <template v-if="selectCheckGuide">
        <a-tooltip :getPopupContainer="() => $el">
          <template #title>
            数量： ≥ <span class="green">{{ selectCheckGuide.needBlankNum }}</span>
            <template v-if="selectCheckGuide.needBlankNumType !== 'fix'"> %</template>
            <br />
            参考：
            <a-tag>{{ qcRefSource(selectCheckGuide.needBlankRefSource) }}</a-tag>
          </template>
          <a-tag :color="qualityOptionDataMap[selectCheckGuide.needBlank].color">{{ qualityOptionDataMap[selectCheckGuide.needBlank].label }}</a-tag>
        </a-tooltip>
      </template>
      <a @click="addBlank">
        <a-icon type="plus"></a-icon>
      </a>
    </template>

    <template #default="{ data: sampleInfo, index }">
      <template v-if="blankList.length">
        <ul-table-column>
          <template #title>
            <!-- <template v-if="sampleInfo.editable"> 空白样{{ index + 1 }} </template>
            <template v-else>
              {{ sampleInfo.checkSampleId }}
            </template> -->
            {{ sampleInfo.checkSampleId }}
          </template>
        </ul-table-column>

        <!-- 如果当前批次是复合批次，那这里显示的就是复合因子 -->
        <ul-table-column data-index="compositeItem" :data="sampleInfo" title="检测项" class="compositeItem">
          <div v-for="item in sampleInfo.compositeItem.split(',')" :key="item">{{ getItemName(item) }}</div>
        </ul-table-column>
        <ul-table-column v-if="sampleInfo.editable" title="">
          <template #default>
            <a @click="blankList.splice(index, 1)" style="color: red">移除</a>
          </template>
        </ul-table-column>
      </template>

      <template v-else>
        <div class="data prompt">
          <div class="no-data">暂无数据</div>
        </div>
      </template>
    </template>
  </ul-table>
</template>

<style lang="less">
.compositeItem {
  display: flex;
  .title-row {
    margin: 0;
  }
}
</style>