<template>
  <div>
    <a-card style="margin-top: 14px; min-width: 650px">
      <a-card :bordered="false" style="max-width: 1080px; margin: 16px auto">
        <div class="ahmux-title" style="width: 100%; margin-bottom: 18px">{{ quoteInfo.projectTitle }}报价</div>
        <PlanPreview :quoteInfo.sync="quoteInfo" v-bind="planContent" :isQuoteBack="isQuoteBack" />

        <a-card style="max-width: 1080px; margin: 16px auto" title="检测费用">
          <template slot="extra">
            <a-button
              class="standardMethodEditBtn"
              :type="isStandardMethodEdit ? 'primary' : ''"
              :class="!isStandardMethodEdit ? 'standardMethodEditGray' : ''"
              @click="standards(quoteInfo)"
              >标准方法录入</a-button
            >
            <a-tooltip placement="top">
              <template slot="title">
                <span>套餐价格：{{ amount.groupAmount }}元</span><br />
                <span>非套餐价格：{{ amount.itemAmount }}元</span>
              </template>
              <b style="font-size: 18px; color: #ff0000">小计：{{ amount.groupAmount }}</b> 元 (<b style="font-size: 12px; color: #ff0000">{{ amount.itemAmount }}</b> 元)
            </a-tooltip>
            <a-button size="small" @click="itemAmountInfoModal.show" style="margin-left: 10px">详情</a-button>
          </template>

          <PlanContentPreview ref="planContentPreview" :params="{ quoteId: quoteInfo.id, projId: quoteInfo.projId }"></PlanContentPreview>
        </a-card>

        <QuoteCompileCosts ref="quoteCompileCosts" :quoteInfo.sync="quoteInfo" :amount.sync="amount" :editable="editable"></QuoteCompileCosts>

        <QuoteOtherCosts ref="quoteOtherCosts" :quoteInfo.sync="quoteInfo" :amount.sync="amount" :editable="editable"></QuoteOtherCosts>

        <QuoteTotalCosts :quoteInfo.sync="quoteInfo" :amount.sync="amount" :editable="editable"></QuoteTotalCosts>
      </a-card>
    </a-card>

    <!-- 报价下载预览 -->
    <a-modal v-bind="itemAmountInfoModal" v-on="itemAmountInfoModal">
      <PlanContentCosts :quoteInfo="quoteInfo" />
      <template #footer>
        <div style="text-align: center">
          <a-button @click="itemAmountInfoModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 标准方法录入 -->
    <a-modal v-bind="standardsModal" v-on="standardsModal">
      <ProjectTaskForm :dataSouce="1" :isEdit="isStandardMethodEdit" :projId="standardsModal.projId" />
      <template #footer>
        <div style="text-align: center">
          <a-button @click="standardsModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { STable } from '@/components'
import { businessApi, quoteApi, Get } from '@/api/quote'
import PlanPreview from './modules/PlanPreview'
import QuoteInfo from './modules/QuoteInfo'
import PlanContentCosts from './modules/PlanContentCosts'
import PlanContentPreview from './modules/PlanContentPreview'
import QuoteCompileCosts from './modules/QuoteCompileCosts'
import QuoteOtherCosts from './modules/QuoteOtherCosts'
import QuoteTotalCosts from './modules/QuoteTotalCosts'
import moment from 'moment'
import ARow from 'ant-design-vue/es/grid/Row'
import DetailList from '@/components/tools/DetailList'
import ACol from 'ant-design-vue/es/grid/Col'
import fullModal from '@/pages/common/mixins/modal/full'
import ProjectTaskForm from '@/pages/cloud-quote/project/modules/ProjectTaskForm'

const fullModalData = fullModal.data().fullModal
const DetailListItem = DetailList.Item

export default {
  name: 'QuoteCheckMgr',
  components: {
    ACol,
    ARow,
    DetailList,
    DetailListItem,
    STable,
    PlanPreview,
    QuoteInfo,
    PlanContentCosts,
    PlanContentPreview,
    QuoteCompileCosts,
    QuoteOtherCosts,
    QuoteTotalCosts,
    ProjectTaskForm
  },
  props: {
    quoteId: {
      type: Number,
      default: null
    },
    isQuoteBack: {
      type: Boolean,
      default: false
    },
    processData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editable: false,
      // 是否可以修改标准方法
      isStandardMethodEdit: false,
      // 商业信息
      businessInfo: {},

      // 方案报价信息
      quoteInfo: {},

      // 计算价格时使用
      amount: {},

      // 本地用户信息
      userList: [],

      // 客户单位信息
      customerList: [],

      // 客户人员信息
      customerUserList: [],

      // 步骤条
      formSteps: {
        current: 0,
        class: 'form-steps'
      },

      // 方案基本信息
      planBase: {
        ref: 'planBase',
        showStep: 0,
        customerUserListOK: this.customerUserListOk
      },

      // 方案基本信息
      planContent: {
        ref: 'planContent',
        showStep: 1
      },

      //  方案完成
      planPreview: {
        ref: 'planPreview',
        showStep: 2
      },

      // 定位信息
      addr: {
        status: '',
        tip: '',
        adCode: '',
        address: '',
        adFullName: '',
        wholeAddress: '',
        lng: '',
        lat: ''
      },
      itemAmountInfoModal: Object.assign({}, fullModalData, {
        title: '检测项价格详情',
        data: {
          params: {
            projId: null,
            fileName: null
          }
        },
        show: (r) => {
          this.itemAmountInfoModal.visible = true
        },
        cancel: () => {
          this.itemAmountInfoModal.visible = false
        }
      }),

      standardsModal: Object.assign({}, fullModalData, {
        title: '标准方法录入',
        projId: '',
        show: (r) => {
          this.standardsModal.projId = r.projId
          this.standardsModal.visible = true
        },
        cancel: () => {
          this.standardsModal.visible = false
          this.standardsModal.projId = ''
        }
      })
    }
  },
  methods: {
    moment,
    standards(record) {
      console.log('标准方法录入', record)
      this.standardsModal.show(record)
    },
    planFinish() {
      this.$message.success(`保存成功`)
    },

    getBusiness() {
      if (this.businessInfo.id) {
        businessApi.list({ id: this.businessInfo.id, authEnable: false, page: false }).then((res) => {
          if (res.code === 0) {
            if (res.data && res.data.length > 0) {
              // this.businessInfo = res.data[0]
              this.$set(this, 'businessInfo', res.data[0])
            }
          }
        })
      }
    },
    getSiteCateItemList() {
      const self = this
      if (self.quoteInfo.id) {
        Get(quoteApi.get, { id: self.quoteInfo.id }).then((result) => {
          if (result.code === 0) {
            const data = result.data
            data.subQuote = !!data.mainQuoteId
            self.quoteInfo = data
            self.$set(self.addr, 'adCode', self.quoteInfo.projAdcode)
            self.$set(self.addr, 'address', self.quoteInfo.projAddress)
            self.$set(self.addr, 'lng', self.quoteInfo.projLng)
            self.$set(self.addr, 'lat', self.quoteInfo.projLat)
            self.$set(self.amount, 'itemAmount', self.quoteInfo.quoteItemsAmount)
            self.$set(self.amount, 'groupAmount', self.quoteInfo.quoteGroupAmount)
            self.$set(self.amount, 'subitemAmount', self.quoteInfo.quoteSubitemsAmount)

            const groupAmount = parseFloat(self.quoteInfo.quoteGroupAmount || 0.0)
            const quoteItemsRise = parseFloat(self.quoteInfo.quoteItemsRise || 1.0)
            const itemRiseAmount = groupAmount * quoteItemsRise
            self.$set(self.amount, 'itemRiseAmount', itemRiseAmount)

            self.$set(self.amount, 'itemDiscountAmount', self.quoteInfo.quoteItemsFinalAmount)

            self.$set(self.amount, 'totalAmount', self.quoteInfo.quoteAmount)
            self.$set(self.amount, 'taxesTotalAmount', self.quoteInfo.quoteTaxesAmount)

            self.$set(self.amount, 'compileTotalAmount', self.quoteInfo.compileCostsAmount)
            self.$set(self.amount, 'otherTotalAmount', self.quoteInfo.otherCostsAmount)
          }
        })
      }
    },
    customerUserListOk(list) {
      this.$set(this.addr, 'customerUserList', list)
    },
    getCheckData() {
      return {
        id: this.quoteInfo.id,
        // quoteItemsAmount: this.quoteInfo.quoteItemsAmount,
        // quoteItemsDiscount: this.form.getFieldValue('quoteItemsDiscount'),
        // quoteItemsFinalAmount: this.quoteInfo.quoteItemsFinalAmount,
        // amount: this.quoteInfo.amount,
        // taxesAmount: this.quoteInfo.taxesAmount,
        quoteFinalAmount: this.quoteInfo.quoteFinalAmount
      }
    },
    initInfo() {
      this.$store.dispatch('code/select', 'proj_report_type')
      this.$store.dispatch('code/select', 'sample_source')
      this.$store.dispatch('code/select', 'quote_type')
      this.$store.dispatch('code/select', 'paymentMethod')
      this.$store.dispatch('code/select', 'taxesType')
      // this.getBusiness()
      this.getSiteCateItemList()
    }
  },
  watch: {
    quoteId(nv, ov) {
      this.quoteInfo.id = nv
      this.initInfo()
    },
    processData: {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        console.log(nv, ov)
        if (nv && nv.length && nv[nv.length - 1].name === '标准方法录入') {
          this.isStandardMethodEdit = true
        } else {
          this.isStandardMethodEdit = false
        }
        console.log(this.isStandardMethodEdit)
      }
    }
  },
  computed: {
    reportTypeMap() {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap() {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap() {
      return { ...this.$store.state.code.cache.quote_type }
    }
  },
  filters: {},
  mounted() {
    this.$set(this.quoteInfo, 'id', this.quoteId)
    this.initInfo()
  }
}
</script>

<style>
</style>

<style lang="less">
.form-steps {
  max-width: 750px;
  margin: 16px auto;
}
.standardMethodEditBtn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.standardMethodEditGray {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  &:hover,
  &:focus,
  &:visited {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
    border-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
