import { swapObject, tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

export const resMaterialSingleCateTypeEnum = {
  // 普通物资
  ordinary: '1',
  // 危化物资
  hazardous: '2',
  // 设备
  device: '3',
  // 容器
  container: '4',
  // 标准品
  standardSample: '5',
  // 固定剂
  fixative: '6',
  // 试剂
  reagent: '7',
  // 工具
  tool: '8'
}

//  检测任务状态选择项
export const resMaterialSingleCateTypeOptions = [
  { label: '普通物资', value: resMaterialSingleCateTypeEnum.ordinary, color: 'gray' },
  { label: '危化物资', value: resMaterialSingleCateTypeEnum.hazardous, color: 'orange' },
  { label: '设备', value: resMaterialSingleCateTypeEnum.device, color: 'blue' },
  { label: '容器', value: resMaterialSingleCateTypeEnum.container, color: '#87D068' },
  { label: '标准品', value: resMaterialSingleCateTypeEnum.standardSample, color: '#87D068' },
  { label: '固定剂', value: resMaterialSingleCateTypeEnum.fixative, color: '#87D068' },
  { label: '试剂', value: resMaterialSingleCateTypeEnum.reagent, color: '#87D068' },
  { label: '工具', value: resMaterialSingleCateTypeEnum.tool, color: '#87D068' }
]

export const resMaterialSingleCateTypeMap = tranOptionsToMap(resMaterialSingleCateTypeOptions)
export const resMaterialSingleCateTypeDataMap = tranOptionsToDataMap(resMaterialSingleCateTypeOptions)
export const resMaterialSingleCateTypeSwapMap = swapObject(resMaterialSingleCateTypeEnum)
