<template>
  <table class="sample-info-table">
    <tr>
      <th>样品编号</th>
      <td>{{ sampleInfo.id }}</td>
      <th>项目名称</th>
      <td>{{ sampleInfo.projName }}</td>
      <th>来源</th>
      <td>
        <span v-if="sampleInfo.source==='1'"><a-tag color="green">采样</a-tag></span>
        <span v-if="sampleInfo.source==='2'"><a-tag color="blue">送样</a-tag></span>
      </td>
    </tr>

    <tr>
      <th>采集点位</th>
      <td>{{ sampleInfo.siteName }}</td>
      <th>采集人</th>
      <td>{{ sampleInfo.sampleUserName }}</td>
      <th>采集时间</th>
      <td>
        <template v-if="sampleInfo.sampleEndTime">
          <span style="color: green">起：</span>{{ sampleInfo.sampleBeginTime | momentFilter }}<br/>
          <span style="color: red">止：</span>{{ sampleInfo.sampleEndTime | momentFilter }}
        </template>
        <template v-else>
          {{ sampleInfo.sampleBeginTime }}
        </template>
      </td>
    </tr>

    <tr>
      <th>存储介质</th>
      <td>{{ sampleInfo.storage }}</td>
      <th>重量</th>
      <td>
        <span v-if="sampleInfo.volume">
          <span style="font-weight: bolder;font-size: 16px">{{ sampleInfo.volume }}</span>
          {{ sampleInfo.volumeUnit }}
        </span>
        <span v-else>-</span>
      </td>
      <th>有效期</th>
      <td>
        <span v-if="sampleInfo.validity">
          <span style="font-weight: bolder;font-size: 16px">{{ sampleInfo.validity }}</span>
          {{ sampleInfo.validityUnit }}
        </span>
        <span v-else>-</span>
      </td>
    </tr>

  </table>
</template>

<script>
import { tsSampleinfoApi } from '@/api/sample'

export default {
  name: 'SampleInfoDetail',
  props: {
    sampleId: {
      type: String,
      default: null
    },

    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sampleInfo: {}
    }
  },
  computed: {
    queryParam () {
      return { id: this.sampleId }
    }
  },

  watch: {
    queryParam: {
      handler (newValue, oldValue) {
        this.$nextTick(() => {
          this.loadData(newValue)
        })
      }
    }
  },

  methods: {
    loadData (param) {
      tsSampleinfoApi.findOne(param).then(res => {
        if (res.code === 0) {
          this.sampleInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "~@/assets/less/table/index";
 .sample-info-table{
   width: 100%;
   tr{
     th{
       width: 10%;
       text-align: right;
     }
     td{
       width: 23.333333333%;
       text-align: left;
     }
   }

 }
</style>
