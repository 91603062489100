/**
 * 项目管理-项目立项
 */
import { getAction, postAction,postDataAction } from '@/api/common'

const projItemStandardTreePre = '/ms-api/proj-standard'
const projAttributeVoPre = '/ms-api/attribute-vo'

export const projStandardTreeApi = {
  listProjectStandard: (param) => getAction(`${projItemStandardTreePre}/getCate`, param)
}
export const projStandardAttributeApi = {
  listProjectStandard: (param) => getAction(`${projItemStandardTreePre}/getAttribute`, param)
}
export const projStandardItemLimitApi = {
  listProjectStandard: (param) => getAction(`${projItemStandardTreePre}/get-item-limit`, param)
}
export const projStandardSaveLimitApi = {
  listProjectStandard: (param) => postDataAction(`${projItemStandardTreePre}/saveLimitValue`, param)
}
// 获取点位信息
export const projSiteEdit = {
  listProjectStandard: (param) => getAction(`${projItemStandardTreePre}/site-edit`, param)
}
// 查询分类下所有标准
export const getAttributeApi = {
  listProjectStandard: (param) => getAction(`${projItemStandardTreePre}/attribute`, param)
}
// 删除选中的因子
export const siteItemLimitDelete = {
  listProjectStandard: (param) => postDataAction(`${projItemStandardTreePre}/site-delete`, param)
}
// 删除点位
export const standardDelete = {
  Delete: (param) => getAction(`${projItemStandardTreePre}/site-delete`, param)
}
// 复制点位
export const standardCopy = {
  Copy: (param) => getAction(`${projItemStandardTreePre}/site-copy`, param)
}
// 应用点位
export const standardUse = {
  Post: (param) => postDataAction(`${projItemStandardTreePre}/site-apply`, param)
}
// 判断标准下选中的因子是否有标准值
export const affirmLimitValue = {
  listProjectStandard: (param) => postDataAction(`${projItemStandardTreePre}/affirm-limit-value`, param)
}
// 修改标准值及单位
export const limitValueEdit = {
  Post: (param) => postDataAction(`${projItemStandardTreePre}/limit-value-edit`, param)
}

// 分类显示标准下的标准明细
export const getStandardAttributeVo = {
  get: (param) => getAction(`${projAttributeVoPre}/get`, param)
}
// 分类显示标准下的标准明细
export const refreshProjTime = {
  get: (param) => getAction(`/ms-api/proj-time/update`, param)
}

export const manualEntry = {
  saveManual: (param) => postDataAction(`${projItemStandardTreePre}/save-manual`, param), // 保存手动录入
  resetManual: (param) => postDataAction(`${projItemStandardTreePre}/reset-manual`, param) // 重置手动录入
}
