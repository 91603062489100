<template>
  <div>
    <a-modal v-bind="modal" v-on="modal">
      <a-card :bordered="false" class="card-table" @keyup.enter="search">
        <a-form>
          <a-row :gutter="rowProps.gutter">
            <a-col span="18">
              <a-form-item label="名称" v-bind="formItemLayout">
                <a-input v-model="queryParam.sctrName" placeholder=""/>
              </a-form-item>
            </a-col>
            <a-col span="6">
              <span class="table-page-search-submitButtons">
                <a-button-group>
                  <a-button type="primary" icon="search" @click="search">查询</a-button>
                </a-button-group>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <s-table v-bind="table" v-on="table">
          <template slot="sctrName" slot-scope="v,r">
            <template v-if="r.checkStatus=='1'">
              <a-tag v-if="r.score=='优'" color="green">{{ r.score }}</a-tag>
              <a-tag v-if="r.score=='良'" color="blue">{{ r.score }}</a-tag>
              <a-tag v-if="r.score=='中'" color="orange">{{ r.score }}</a-tag>
              <a-tag v-if="r.score=='差'" color="red">{{ r.score }}</a-tag>
              {{ v }}
            </template>
            <template v-else>
              <span style="color: red" title="未通过评审">{{ v }}</span>
            </template>
          </template>
          <template slot="hasItemNum" slot-scope="v,r">
            <a-tooltip placement="top">
              <template slot="title">
                包含数/总数：{{ v }}/{{ r.itemNum }}
              </template>
              <template>
                <a-tag v-if="v==r.itemNum" color="blue">{{ v }}/{{ r.itemNum }}</a-tag>
                <a-tag v-else-if="0<v&&v<r.itemNum" color="orange">{{ v }}/{{ r.itemNum }}</a-tag>
                <a-tag v-else color="red">{{ v }}/{{ r.itemNum }}</a-tag>
              </template>
            </a-tooltip>
          </template>
          <template slot="amount" slot-scope="v,r">
            <span style="color: red">{{ currency(v) }}</span>
          </template>
          <span slot="opRender" slot-scope="v,r">
            <div class="row-button">
              <a @click="edit(r)"><a-icon type="edit"/>编辑</a>
              <a @click="addSubUser(r)"><a-icon type="plus"/>人员</a>
              <a @click="del(r)"><a-icon type="delete"/>作废</a>
            </div>
          </span>
          <template slot="expandedRowRender" slot-scope="record">
            <a-card>
              <a-table v-bind="table2" v-on="table2" :dataSource="record.subItemList">
                <template slot="numPointTitle">
                  频次
                  <a-tooltip placement="top">
                    <template slot="title">
                      点位 * 天数 * 频次
                    </template>
                    <a-icon type="info-circle"/>
                  </a-tooltip>
                </template>
                <template slot="itemName" slot-scope="v,r">
                  <a-icon v-if="r.subitemPrice" type="star" theme="twoTone" two-tone-color="#eb2f96"/>{{ v }}
                </template>
                <template slot="numPoint" slot-scope="v,r">
                  {{ r.numPoint }} * {{ r.numPeriod }} * {{ r.numFrequency }}
                  <!--                  <span style="color: red">{{ currency(v) }}</span>-->
                  <!--                  <span style="color: red">{{ currency(r.subitemPrice) }}</span>-->
                </template>
                <template slot="itemPrice" slot-scope="v,r">
                  <a-row>销售价：<span style="color: red">{{ currency(v) }}</span></a-row>
                  <a-row>分包价：<span style="color: red">{{ currency(r.subitemPrice) }}</span></a-row>
                </template>
              </a-table>
            </a-card>
          </template>
        </s-table>
      </a-card>
    </a-modal>
  </div>
</template>

<script>

import { projSubcontractTaskApi } from '@/api/project'
import { STable } from '@/components'
import { currency } from '@/utils/util'
import ARow from 'ant-design-vue/es/grid/Row'

export default {
  name: 'SubcontractorAmountList',
  components: {
    ARow,
    STable
  },
  data () {
    return {
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },
      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      modal: {
        title: '分包商选择',
        visible: false,
        centered: true,
        closable: false,
        width: '850px',
        destroyOnClose: true,
        maskClosable: false,
        cancel: this.close,
        ok: this.ok,
        okText: '确定'
      },

      moreParam: false,
      queryParam: {
      },
      table: {
        ref: 'table',
        size: 'small',
        loading: false,
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        showPagination: false,
        scroll: {
          y: '500px'
        },
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '名称', dataIndex: 'sctrName', width: '230px', scopedSlots: { customRender: 'sctrName' } },
          // { title: '联系人', dataIndex: 'sctrContacts', width: '80px' },
          // { title: '联系电话', dataIndex: 'sctrContactsPhone', width: '100px' },
          { title: '分包项', dataIndex: 'hasItemNum', width: '100px', scopedSlots: { customRender: 'hasItemNum' } },
          { title: '金额(元)', dataIndex: 'amount', width: '100px', scopedSlots: { customRender: 'amount' } }
          // ,{ title: '操作', dataIndex: 'actions', scopedSlots: { customRender: 'opRender' } }
        ],
        data: parameter => {
          // var params = Object.assign(parameter, this.queryParam)
          var params = this.queryParam
          return projSubcontractTaskApi.listSubcontractorAndPriceByItem(params).then(res => {
            if (res.code === 0) {
              return res
            } else {
              this.$message.error('查询失败')
              return null
            }
          }).catch(err => {
            console.log(err)
          })
        },
        rowSelection: {
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) => {
            this.table.selectedRowKeys = selectedRowKeys
            this.table.selectedRows = selectedRows
          }
        }
      },
      table2: {
        ref: 'table2',
        size: 'small',
        loading: false,
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        showPagination: false,
        scroll: {
          y: '500px'
        },
        rowKey: (record) => {
          return `${record.cateId}-${record.itemId}`
        },
        columns: [
          { title: '分类', dataIndex: 'cateName', width: '60px' },
          { title: '检测项', dataIndex: 'itemName', width: '80px', scopedSlots: { customRender: 'itemName' } },
          { /* title: '频次',*/ dataIndex: 'numPoint', width: '60px', slots: { title: 'numPointTitle' }, scopedSlots: { customRender: 'numPoint' } },
          { title: '单价(元)', dataIndex: 'itemPrice', width: '80px', scopedSlots: { customRender: 'itemPrice' } }
          // ,{ title: '操作', dataIndex: 'actions', scopedSlots: { customRender: 'opRender' } }
        ],
        pagination: false
      }
    }
  },
  methods: {
    currency,
    open (param) {
      this.modal.visible = true
      this.$set(this, 'queryParam', { projId: param.projId, itemIds: param.itemIds })
    },
    search () {
      this.$refs.table.refresh(true)
    },
    resetSearch () {
      this.queryParam = { }
      this.search()
    },
    addSubUser (record) {
      this.$refs.subUserForm.addBySctrId(record.id)
    },
    ok () {
      if (this.table.selectedRows.length > 0) {
        var obj = this.table.selectedRows[0]
        this.$emit('ok', obj)
        this.close()
      } else {
        this.$message.error('还没选择分包商!')
      }
    },
    close () {
      this.modal.visible = false
    }
  }
}
</script>

<style scoped>

</style>
