<template>
  <a-modal v-bind="checkTaskFeedbackModal" v-on="checkTaskFeedbackModal">
    <a-card v-bind="baseInfoCard">
      <a-tabs v-bind="baseInfoTabs">
        <a-tab-pane tab="样品信息" key="1">
          <sample-info-detail v-bind="sampleInfoProps" v-if="checkTaskFeedbackModal.visible"></sample-info-detail>
        </a-tab-pane>
        <a-tab-pane tab="检测指南" key="2">
          <ExecuteStandardList :param="executeStandardProp"/>
        </a-tab-pane>
      </a-tabs>
    </a-card>

    <a-card v-bind="formCard">

      <a-form v-bind="formProps" :form="dataForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :span="24">
            <a-form-item label="检测设备" v-bind="formItemLayoutOne">
              <div :key="index" class="device" v-for="(item,index) in deviceInfoProp.dataSource">
                <div class="device-info">
                  <el-image :src="item.materialImg" style="width: 100%; height: 100%;" v-if="item.materialImg"></el-image>
                  <el-image :preview-src-list="item.materialImgs||[]" :src="item.materialImgs&&item.materialImgs[0]" style="width: 100%; height: 100%" v-else></el-image>
                  <p class="device-title" :title="item.materialName ">{{ item.fixedAssetsId }} {{ item.materialName }}</p>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="记录值" v-bind="formItemLayout">
              {{ data.value }}
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="检测时间" v-bind="formItemLayout">
              {{ data.checkTime }}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="检测用量" v-bind="formItemLayout">
              {{ data.volume }}
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="计量单位" v-bind="formItemLayout">
              {{ data.volumeUnit }}
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :span="24" :style="{textAlign: 'left'}">
            <a-form-item label="备注" v-bind="formItemLayoutOne">
              {{ data.note }}
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :span="24" :style="{textAlign: 'left'}">
            <a-form-item label="图片" v-bind="formItemLayoutOne">
              <template v-if="fileList.length">
                <div v-for="(photo,photoIndex) in fileList" :key="photoIndex" class="photos">
                  <span :style="{float:'left',width:'80%'}">{{ photo.name }}</span>
                  <span :style="{float:'left',marginRight: '5px'}"><a @click="handlePreview(photo)"><a-icon type="eye"></a-icon></a></span>
                </div>
              </template>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <!-- 外部组件 -->
    <picture-upload ref="pictureUploadModal" :file-length="3" modalTitlte="上传样品照片" @result="savePicture"/>

    <material-table v-bind="deviceInfoProp" v-on="deviceInfoProp" v-if="deviceInfoProp.visible"></material-table>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>

    <template #footer>
      <a-button @click="checkTaskFeedbackModal.cancel">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { FmUpload } from 'ahmux-form-designing'
import { cloneDeep, omit } from 'lodash'
import { randomString } from '@/utils/util'

import { dictApi } from '@/api/system'
import { sysCommonApi } from '@/api/common'
import { customFunc } from '@/pages/cloud-quote/take-sample/modules/customForm/CustomFormFunc'
import { checkTaskResultApi } from '@/api/sample'
import { Get, jcListApi, resMaterialImgApi } from '@/api/quote'

import ExecuteStandardList from '@/pages/common/modal/ExecuteStandardList'
import SampleInfoDetail from '@/pages/cloud-quote/sample-info/modules/SampleInfoDetail'
import PictureUpload from '@/pages/common/img-qrcode/PictureUpload'
import MaterialTable from '@/pages/cloud-quote/res/res-materialout/modules/materialTable'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

export default {

  components: { SampleInfoDetail, ExecuteStandardList, PictureUpload, FmUpload, MaterialTable },

  props: {
    id: {
      type: Number
    }
  },

  data () {
    return {
      // 检测标准参数
      executeStandardProp: {
        itemId: null,
        cateId: null
      },
      viewUrl: sysCommonApi.view,

      checkDeviceList: [],
      resourceImagesCache: {},

      fileList: [],

      previewVisible: false,
      previewImage: null,

      accessoryUpload: {
        accept: '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,' +
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        action: sysCommonApi.uploadFile,
        multiple: true,
        showUploadList: true,
        listType: 'text',
        headers: { 'Access-Token': this.$store.getters.token },
        preview: this.previewFile,
        change: this.handleFileChange
      },

      numberStyle: {
        style: { width: '50%' }
      },

      checkUnitSelect: {
        defaultValue: [],
        mode: 'tags',
        style: { width: '100%' },
        tokenSeparators: [',', '，'],
        dataSource: [],
        placeholder: '请选择计量单位',
        change: (val) => {
          const notIncludeArr = val && val.length && val.filter(item => !this.checkUnitSelect.dataSource.includes(item)) || []
          notIncludeArr.length && this.checkUnitSelect.dataSource.splice(this.checkUnitSelect.dataSource.length, 0, ...notIncludeArr)
          this.data.volumeUnit = val && val.length && val.join(',') || null
        }
      },

      data: {},

      checkTaskFeedbackModal: {
        width: '60%',
        visible: false,
        closable: false,
        destroyOnClose: true,
        centered: true,
        wrapClassName: 'check-task-feedback-modal',
        bodyStyle: { background: '#EFF2F3' },
        title: '结果录入',
        ok: this.handleSubmit,
        cancel: this.close
      },

      baseInfoCard: {
        title: '',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: { padding: '10px 24px 16px' },
        bordered: false,
        type: 'inner',
        hoverable: true
      },

      formCard: {
        title: '记录结果',
        style: { marginTop: '30px' },
        headStyle: {},
        bodyStyle: {},
        bordered: false,
        type: 'inner',
        hoverable: true
      },

      baseInfoTabs: {
        defaultActiveTabKey: '1',
        size: 'default',
        tabBarStyle: { marginBottom: '24px', paddingLeft: '5px' }
      },

      CheckGuideProps: {
        projId: null,
        showAction: false,
        itemType: '2'
      },

      sampleInfoProps: {
        sampleId: null,
        visible: false
      },

      deviceInfoProp: {
        ref: 'deviceInfo',
        visible: false,
        cateType: 3,
        clearStatus: true,
        dataSource: [],
        show: () => { this.deviceInfoProp.visible = true },
        cancel: () => { this.deviceInfoProp.visible = false },
        updateData: this.handleDeviceInfo
      },

      dataForm: this.$form.createForm(this),

      formProps: {
        class: 'ant-advanced-search-form',
        layout: 'horizontal'
      },

      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 8 },
          sm: { span: 8 },
          xs: { span: 8 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
          sm: { span: 16 },
          xs: { span: 16 }
        }
      },

      formItemLayoutOne: {
        labelCol: {
          xxl: { span: 3 },
          xl: { span: 4 },
          lg: { span: 4 },
          md: { span: 4 },
          sm: { span: 4 },
          xs: { span: 4 }
        },
        wrapperCol: {
          xxl: { span: 21 },
          xl: { span: 20 },
          lg: { span: 20 },
          md: { span: 20 },
          sm: { span: 20 },
          xs: { span: 20 }
        }
      },

      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 }
      },

      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 12,
        style: { textAlign: 'left' }
      },

      decorators: {
        up: ['up', { rules: [{ required: true, message: '请输入上限值' }] }],
        down: ['down', { rules: [{ required: true, message: '请输入下限值' }] }],
        value: ['value', { rules: [{ required: true, message: '请输入检出值' }] }],
        checkTime: ['checkTime', { initialValue: this.moment(), rules: [{ required: false, message: '请选择检测时间' }] }],
        volume: ['volume', {}],
        note: ['note', {}]
      }
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (val) {
        if (val) {
          this.data.id = val
          this.queryById(val)
          this.$nextTick(() => {
            this.show()
          })
        }
      }
    },

    'checkTaskFeedbackModal.visible' (val) {
      if (val) {
        this.checkCheckUnitDict()
      }
    }
  },

  methods: {
    moment,

    // 根据ID 查询检测数据
    queryById (id) {
      checkTaskResultApi.getById(id).then(res => {
        if (res.code === 0) {
          // 加载设备数据
          if (res.data.deviceIds) {
            this.queryDeviceInfo(res.data.deviceIds.split(','))
          }
          Object.assign(this.data, res.data)
          this.sampleInfoProps.sampleId = res.data.sampleId
          this.fileList = res.data.accessory && JSON.parse(res.data.accessory) || []
        } else {
          this.$message.error(`获取检测数据详情失败！${res.msg}`)
        }
      })
    },

    queryDeviceInfo (ids) {
      Get('/ms-api/res-dev/getByIds', { ids }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length) {
            res.data.forEach(item => {
              item.materialName = item.devName
              item.materialId = parseInt(item.type)
            })
            this.handleDeviceInfo(res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询默认的检测设备类目
    queryCheckDevice () {
      jcListApi.queryProjItemGuide({ projId: this.data.projId, cateId: this.data.cateId }).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            this.checkDeviceList.splice(0, this.checkDeviceList.length, ...res.data.filter(item => item.itemId === this.data.itemId))
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 选择检测设备
    showSearchDevice () {
      this.deviceInfoProp.show()
      const selectKey = this.deviceInfoProp.dataSource && this.deviceInfoProp.dataSource.map(item => item.id) || []
      this.$nextTick(() => {
        this.$refs[this.deviceInfoProp.ref].handleAdd({
          type: 'device',
          materialName: this.checkDeviceList.length &&
              this.checkDeviceList[0].checkDeviceNames && this.checkDeviceList[0].checkDeviceNames.split(',')[0] || ''
        }, selectKey)
      })
    },

    // 处理选择设备事件，如果图片不存在，去找类目图片
    handleDeviceInfo (info) {
      this.deviceInfoProp.dataSource.splice(0, this.deviceInfoProp.dataSource.length, ...info)
      this.$nextTick(() => {
        info.map(item => {
          if (!item.materialImg) {
            this.getMaterialImg(item.materialId).then(data => {
              if (item.hasOwnProperty('materialImgs')) {
                item.materialImgs.split(0, item.materialImgs.length, ...data)
              } else {
                this.$set(item, 'materialImgs', data)
              }
            })
          }
        })
      })
    },

    // 获取类目图片
    async getMaterialImg (materialId) {
      await this.queryMaterialImg(materialId)
      return this.resourceImagesCache[materialId]
    },

    queryMaterialImg (materialId) {
      return resMaterialImgApi.list({ materialId: materialId, page: false }).then(res => {
        if (res.code === 0) {
          this.resourceImagesCache[materialId] = res.data.map(item => this.viewUrl + item.materialImg) || []
        } else {
          this.$message.error(`获取物资类目图片出错，错误信息：${res.msg}`)
        }
      })
    },

    // 二维码传图
    showQrcode (id) {
      this.addPictureId = id
      this.$refs.pictureUploadModal.show('二维码', `checkTaskResult-${id}-${randomString(6)}`)
    },

    // 处理保存图片
    savePicture (data) {
      this.fileList.splice(0, this.fileList.length, ...data)
    },

    handlePreview (file) {
      this.previewImage = file.thumbUrl || sysCommonApi.view + '/' + file.url
      this.previewVisible = true
    },

    handleFileChange ({ file, fileList }) {
      this.fileList = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.preview + '?filePath=' + file.response.data.fileUrl
          file.url = file.response.data.fileUrl
          fileList.forEach(item => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },

    handleSubmit () {
      this.dataForm.validateFields((errors, values) => {
        if (!errors) {
          if (!this.deviceInfoProp.dataSource.length) {
            this.$message.error('请选择检测设备！')
            return false
          }

          if (this.fileList && this.fileList.length) {
            let accessory = cloneDeep(this.fileList)
            accessory = accessory.filter(it => !it.error)
            for (let i = 0; i < accessory.length; i++) {
              accessory[i] = omit(accessory[i], ['lastModified', 'lastModifiedDate', 'originFileObj', 'originFileObj', 'thumbUrl', 'percent', 'response'])
            }
            this.data.accessory = JSON.stringify(accessory)
          } else {
            this.data.accessory = null
          }

          const deviceIds = this.deviceInfoProp.dataSource.map(item => item.id).join(',')
          const deviceNames = this.deviceInfoProp.dataSource.map(item => item.materialName).join(',')
          checkTaskResultApi.save(Object.assign(this.data, values, { deviceIds, deviceNames })).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.addSampleStatusDictDetail()
              this.$emit('success')
              this.close()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    previewFile (file) {
      window.open(sysCommonApi.preview + '?filePath=' + file.url, file.name, null, false)
    },

    // 准备计量单位字典
    checkCheckUnitDict () {
      // 字典是否存在，不存在保存一个
      dictApi.checkCode({ code: 'checkCustomizeUnit' }).then(res => {
        if (!res) {
          this.addCheckUnitDict()
        }
      })

      customFunc._self = this
      customFunc.getDictCode((data) => {
        this.checkUnitSelect.dataSource = data && data.length && data.map(item => item.label) || []
      }, ['checkCustomizeUnit'])
    },

    // 添加计量单位字典
    addCheckUnitDict () {
      const dictData = {
        dictName: '检测计量单位',
        dictCode: 'checkCustomizeUnit',
        dictDesc: '检测自定义计量单位'
      }

      dictApi.add(dictData).then(res => {
        if (res.code !== 0) {
          this.$message.error(`保存检测计量单位字典发生错误：${res.msg}`)
        }
      })
    },

    // 添加计量单位字典详情
    addSampleStatusDictDetail () {
      const checkUnitArr = this.checkUnitSelect.dataSource.map((item, index) => {
        return {
          dictCode: 'checkCustomizeUnit',
          status: 1,
          itemText: item,
          itemValue: index + 1,
          sort: index + 1
        }
      })
      this.$store.commit('code/update', checkUnitArr)
      this.$store.commit('code/addAll', checkUnitArr)
    },

    init (data) {
      this.CheckGuideProps.projId = data.projId
      this.sampleInfoProps.sampleId = data.sampleId
      this.data.projId = data.projId
      this.data.sampleId = data.sampleId
      this.data.checkTaskId = data.id
      this.data.cateId = data.cateId
      this.data.itemId = data.itemId
      this.executeStandardProp.cateId = data.cateId
      this.executeStandardProp.itemId = data.itemId
      this.$nextTick(() => {
        this.show()
        this.queryCheckDevice()
      })
    },

    show () {
      this.checkTaskFeedbackModal.visible = true
    },

    close () {
      this.checkTaskFeedbackModal.visible = false
      this.$emit('cancel')
    },

    handleVolumeUnitChange (val) {
      this.data.volumeUnit = val
    },

    disabledDate (current) {
      return current && current >= this.moment()
    }
  }

}
</script>

<style lang="less" scoped>
  .check-task-feedback-modal {

  }

  .device {
    margin: 0 auto;
    width: 100px;
    height: 128px;

    &-info {
      width: 100px;
      height: 100px;
      border: 1px solid #AAAAAA;
      background: #C9C9C9;
    }

    &-info:hover {
      .device-operation {
        display: block;
        position: relative;
      }
    }

    &-operation {
      height: 100px;
      width: 100px;
      background: #BBBBBB;
      position: absolute;
      display: none;
      top: 0;
      z-index: 1;

      &-delete {
        font-size: 24px;
        color: #FFFFFF;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      &-add {
        font-size: 54px;
        text-align: center;
        line-height: 86px;
        color: #FFFFFF;
      }
    }

    &-title {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

</style>
