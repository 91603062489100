import { render, staticRenderFns } from "./ReceiveSampleForm.vue?vue&type=template&id=794755a8&scoped=true&"
import script from "./ReceiveSampleForm.vue?vue&type=script&lang=js&"
export * from "./ReceiveSampleForm.vue?vue&type=script&lang=js&"
import style0 from "./ReceiveSampleForm.vue?vue&type=style&index=0&id=794755a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794755a8",
  null
  
)

export default component.exports