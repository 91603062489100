<template>
  <div class="receive-sample-project-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="receive-sample-project-table">
      <template #button>
        <a-icon class="setting" type="setting"></a-icon>
      </template>
    </customize-column>
    <s-table :rowSelection="customRowSelection" :scroll="scrollProp" v-bind="contentTable" v-on="contentTable.action">

      <template #scheduledTime="text,record">
        开始：<span class="green">{{ record.projScheduledStart|dayFilter }}</span><br/>
        结束：<span class="red">{{ record.projScheduledEnd|dayFilter }}</span>
      </template>

      <template #sampleNum="num">
        <template v-if="num">
          <a-tag color="green">{{ num }}</a-tag>
        </template>
        <template v-else>
          <a-tag type="error">未接</a-tag>
        </template>

      </template>

      <template #sampleUserNames="text">
        <template v-if="text">
          <a-tag color="blue" v-for="item in text.split(',')" :key="item">{{ item }}</a-tag>
        </template>
      </template>

      <template #sampleGiverNames="text">
        <template v-if="text">
          <a-tag color="blue" v-for="item in text.split(',')" :key="item">{{ item }}</a-tag>
        </template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot :name="slot" v-bind="{text:text,row:record,index:index,expanded}"/>
      </template>

    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { CustomizeColumn } from '@/components/Columns'

import mixinDate from '@/pages/common/mixins/date'
import { componentTable } from '@/pages/common/mixins/list'
import { receiveSampleProjectColumn as defaultColumn } from './columns'

import { tsSampleinfoApi } from '@/api/sample'

export default {
  components: { sTable, CustomizeColumn },
  mixins: [componentTable, mixinDate],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      contentTable: {
        columns: this.columns,
        bordered: this.bordered,
        rowKey: 'projId',
        showPagination: this.showPagination ? 'auto' : false
      }
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        return tsSampleinfoApi.listReceiveSampleProject(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '_index';
</style>
