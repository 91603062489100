<template>
  <a-modal :width="modalWidth" :visible="visible" :title="title" @ok="handleSubmit" @cancel="close" cancelText="关闭" style="margin-top: -70px" wrapClassName="ant-modal-cust-warp">
    <a-row :gutter="10" style="background-color: #ececec; padding: 10px; margin: -10px">
      <a-col :md="6" :sm="24">
        <a-card :bordered="false">
          <!--组织机构-->
          <a-directory-tree
            selectable
            v-if="departTree.length > 0"
            :selectedKeys="selectedKeys"
            :checkStrictly="true"
            :defaultExpandedKeys="defaultExpandedKeys"
            @select="this.onSelect"
            :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
            :treeData="departTree"
          />
        </a-card>
      </a-col>
      <a-col :md="18" :sm="24">
        <a-card hoverable v-bind="actionCard">
          标准编号:
          <a-input-search
            :style="{ width: '200px', marginBottom: '15px' }"
            placeholder="请输入标准编号"
            v-model="queryParam.standardId"
            @search="onSearch"
            enterButton
          ></a-input-search>
          <a-button type="primary" @click="handleAdd()" style="margin-left: 20px" icon="plus">标准</a-button>
          <a-button @click="searchReset(1)" style="margin-left: 20px" icon="redo">重置</a-button>
          <!--用户列表-->
          <a-table
            ref="table"
            :scroll="scrollTrigger"
            size="middle"
            rowKey="id"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :rowSelection="{ type: this.checkType, selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            @change="handleTableChange"
          >
          </a-table>
        </a-card>

        <a-card hoverable v-bind="contentCard">
          <div style="min-height: 20px; height: auto">
            <ul>
              <li v-for="v in selectedStandardList" unselectable="on" role="presentation" class="ant-select-selection__choice">
                <div class="ant-select-selection__choice__content">
                  {{ v.name }}
                  <i class="ant-select-remove-icon anticon anticon-close"> <a-icon type="close" @click="delStandard(v.id)" /></i>
                </div>
              </li>
            </ul>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <standard-modal ref="modalForm" @ok="modalFormOk"></standard-modal>
  </a-modal>
</template>

<script>
import { getAction } from '@/api/common'
import { filterObj } from '@/utils/util'
import { jcStandardApi, Get, Post } from '@/api/quote'
import { dictApi } from '@/api/system'
import { deptApi, userApi } from '@/api/system'
import StandardModal from '@/pages/cloud-quote/standard/modules/StandardModal'
export default {
  name: 'SelectStandardModal',
  components: {
    StandardModal
  },
  props: {
    modalWidth: {
      type: Number,
      required: false,
      default: 1250
    },
    checkType: {
      type: String,
      require: false,
      default: 'checkbox'
    }
  },
  data() {
    return {
      standardTypeList: [],
      defaultExpandedKeys: [],
      lastRowKeyLength: 0,
      selectedStandardList: [],
      actionCard: {
        title: '选择标准',
        style: {},
        headStyle: {},
        bodyStyle: { background: '#d7dde312' },
        bordered: true,
        type: ''
      },

      contentCard: {
        title: '已选标准',
        style: { marginTop: '24px' },
        headStyle: {},
        bodyStyle: {},
        bordered: false,
        type: 'inner'
      },
      queryParam: {
        username: ''
      },
      columns: [
        { title: '标准编号', align: 'center', dataIndex: 'standardId' },
        { title: '标准名称', align: 'center', dataIndex: 'name' },
        { title: '标准等级', align: 'center', dataIndex: 'grade' },
        { title: '代替标准', align: 'center', dataIndex: 'replStandard' }
      ],
      scrollTrigger: {},
      dataSource: [],
      selectedKeys: [],
      userNameArr: [],
      departName: '',
      userRolesMap: {},
      title: '标准选择',
      ipagination: {
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      selectedRowKeys: [],
      selectedRows: [],
      departTree: [],
      visible: false,
      form: this.$form.createForm(this)
    }
  },
  created() {
    // 该方法触发屏幕自适应
    this.resetScreenSize()
    this.queryUserRoleMap()
  },
  methods: {
    handleAdd() {
      var key
      if (this.selectedKeys.length > 0) {
        key = this.selectedKeys[0]
      }
      this.$refs.modalForm.add(key, this.standardTypeList, [], false)
    },
    delStandard(id) {
      this.selectedStandardList = this.selectedStandardList.filter((r) => r.id != id)
      this.selectedRowKeys = this.selectedRowKeys.filter((r) => r != id)
      this.lastRowKeyLength = this.selectedRowKeys.length
    },
    loadData(arg) {
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams() // 查询条件
      getAction(jcStandardApi.pageList, params).then((res) => {
        this.dataSource = res.data
        this.ipagination.total = res.totalCount
      })
    },
    queryUserRoleMap() {
      // queryUserRoleMap().then((res) => {
      //   if (res.success) {
      //     this.userRolesMap = res.result
      //     this.loadData()
      //   }
      // })
    },
    // 触发屏幕自适应
    resetScreenSize() {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.scrollTrigger = { x: 800 }
      } else {
        this.scrollTrigger = {}
      }
    },
    showModal(selectedStandardList) {
      this.visible = true
      this.selectedRowKeys = []
      this.lastRowKeyLength = selectedStandardList.length
      this.selectedStandardList = selectedStandardList
      this.initRowkeyByselectedStandardList(selectedStandardList)
      this.queryDepartTree()
      this.form.resetFields()
    },
    initRowkeyByselectedStandardList(selectedStandardList) {
      for (let i = 0; i < this.selectedStandardList.length; i++) {
        this.selectedRowKeys.push(selectedStandardList[i].id)
      }
    },
    getQueryParams() {
      const param = Object.assign({}, this.queryParam, this.isorter)
      param.field = this.getQueryField()
      if (this.selectedKeys.length > 0 && this.selectedKeys[0] != 'all') {
        param.type = this.selectedKeys[0].toString()
      }
      param.userName = this.queryParam.userName
      param.pageNo = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    getQueryField() {
      let str = 'id,'
      for (let a = 0; a < this.columns.length; a++) {
        str += ',' + this.columns[a].dataIndex
      }
      return str
    },
    searchReset(num) {
      const that = this
      if (num !== 0) {
        that.queryParam = {}
        that.loadData(1)
      }
      /*   that.selectedRowKeys = []
      that.userNameArr = []
      that.selectedKeys = []*/
    },
    close() {
      //this.searchReset(0)
      //this.$destroy()
      this.selectedRowKeys = []
      this.selectedKeys = []
      this.visible = false
    },
    handleTableChange(pagination, filters, sorter) {
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    handleSubmit() {
      this.$emit('ok', this.selectedStandardList)
      this.close()
    },
    // 遍历匹配,获取用户真实姓名
    getUserNames(rowId) {
      const dataSource = this.dataSource
      for (let i = 0, len = dataSource.length; i < len; i++) {
        if (rowId === dataSource[i].id) {
          this.userNameArr.push(dataSource[i].realname)
        }
      }
    },

    getStandardId(rowId) {
      let name = '未知'
      const dataSource = this.dataSource
      for (let i = 0, len = dataSource.length; i < len; i++) {
        if (rowId === dataSource[i].id) {
          name = dataSource[i].standardId
        }
      }
      return name
    },
    // 点击树节点,筛选出对应的用户
    onSelect(selectedKeys) {
      if (selectedKeys.length > 0) {
        this.selectedKeys = selectedKeys
        this.loadData()
      }
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      const a = selectedRowKeys
      if (this.checkType === 'checkbox') {
        if (this.lastRowKeyLength < a.length) {
          //勾选
          for (let i = 0; i < a.length; i++) {
            if (i > this.lastRowKeyLength - 1) {
              this.selectedStandardList.push({ id: a[i], name: this.getStandardId(a[i]) })
            }
          }
        } else {
          //去除
          const result = []
          const b = this.selectedStandardList
          for (let i = 0; i < b.length; i++) {
            let flag = false
            for (let j = 0; j < a.length; j++) {
              if (a[j] == b[i].id) {
                flag = true
              }
            }
            if (flag) {
              result.push(b[i])
            }
          }
          this.selectedStandardList = result
        }
      } else {
        this.selectedStandardList.splice(0, this.selectedStandardList.length, { id: selectedRowKeys[0], name: this.getStandardId(selectedRowKeys[0]) })
      }

      this.lastRowKeyLength = selectedRowKeys.length
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
    },
    onSearch() {
      this.loadData(1)
    },

    // 传入用户id,找到匹配的角色名称
    queryUserRole(userId) {
      const map = this.userRolesMap
      const roleName = []
      for (var key in map) {
        if (userId === key) {
          roleName.push(map[key])
        }
      }
      return roleName.join(',')
    },
    queryDepartTree() {
      this.defaultExpandedKeys = []
      this.departTree = []
      dictApi.getItems({ dictCode: 'standard_type' }).then((res) => {
        if (res.code === 0) {
          const d = res.data

          const temp = []
          for (let i = 0; i < d.length; i++) {
            temp.push({ title: d[i].itemText, key: d[i].itemValue })
          }
          this.standardTypeList = temp
          this.departTree.push({ title: '全部', key: 'all', children: temp })
          this.defaultExpandedKeys.push('all')
        }
      })
    },

    modalFormOk() {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  float: left;
  margin-right: 4px;
  margin-top: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 20px 0 10px;
}

.ant-select-selection__choice__content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
