<template>
  <div>
    <a-spin :spinning="confirmLoading">
      <a-form v-bind="dataForm">
        <div class="content-row focus">
          <a-radio-group v-model="dataForm.data.configMode" style="margin: 10px 20px">
            <a-radio v-for="item in checkStandardCurveModeOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </div>
        <a-card v-bind="baseFormCard" title="基本信息">
          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="方法名称">
                <a-input v-model="dataForm.data.methodName" :disabled="true"></a-input>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="方法依据">
                <a-input v-model="dataForm.data.standardCode" :disabled="true"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="设备类型">
                <a-select v-decorator="decorators.devType" :filterOption="filterOption" mode="multiple" placeholder="请选择设备类型" style="width: 100%" @change="onSelectDev">
                  <a-select-option v-for="v in devList" :key="v.id.toString()" :value="v.id.toString()">{{ v.materialName }}-{{ v.speType }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="colProps">
              <a-form-item label="规格型号">
                <a-input v-model="dataForm.data.speType"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-bind="rowProps" style="padding: 0 10px">
            <a-col v-bind="colProps">
              <a-form-item label="有效时长">
                <a-row :gutter="10">
                  <a-col :span="8">
                    <a-input-number v-decorator="decorators.validDate" :min="0" :step="1" :style="{ width: '100%' }"></a-input-number>
                  </a-col>

                  <a-col :span="16">
                    <a-radio-group v-model="dataForm.data.validDateUnit">
                      <a-radio v-for="(value, key) of reagentCateValidDateUnitMap" :key="key" :value="key">{{ value }}</a-radio>
                    </a-radio-group>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>

        <a-card v-bind="baseFormCard" title="标物及曲线参数配置">
          <a-card v-bind="baseFormCard" title="标物成分">
            <template slot="extra">
              <a href="#" @click="showExistingMixSolutionType()">已有混合标液</a>
              <a-divider type="vertical" />
              <a href="#" @click="showCurrentConfigMixSolutionType()">现配混合标液</a>
            </template>

            <a-row v-bind="rowProps" style="padding: 0 10px" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
              <a-col v-bind="colProps">
                <a-form-item label="参比溶液">
                  <!--<a-input v-decorator="decorators.referenceSolution"></a-input>-->
                  <span style="margin-right: 10px" v-if="dataForm.data.referenceSolutionName"
                    ><a-tag color="burlywood" closable @close="changeReagentCate">{{ dataForm.data.referenceSolutionName }}</a-tag></span
                  >
                  <a @click="reagentCateSelectModal.show()">请选择参比溶液</a>
                </a-form-item>
              </a-col>

              <a-col v-bind="colProps">
                <a-form-item label="比色皿">
                  <a-input v-decorator="decorators.thickness"></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-bind="rowProps" style="padding: 0 10px">
              <a-col v-bind="colProps">
                <a-form-item label="定容体积">
                  <a-input-group compact style="width: 100%">
                    <!-- <a-input v-model="dataForm.data.solutionPurity"></a-input> -->
                    <a-input-number v-decorator="decorators.constantVolume" :min="0" style="width: 60%" @change="changeConstantVolume"></a-input-number>
                    <a-select v-model="dataForm.data.constantVolumeUnit" placeholder="请选择体积单位" style="width: 40%">
                      <a-select-option value="">无</a-select-option>
                      <a-select-option v-for="v in capacityUnitList" :key="v.id" :value="v.id">{{ v.name }}</a-select-option>
                    </a-select>
                    <!-- <a-input v-model="dataForm.data.constantVolumeUnit" disabled style="width: 40%"></a-input> -->
                  </a-input-group>
                </a-form-item>
              </a-col>
            </a-row>

            <div style="margin: 0">
              <a-table v-bind="mixSolutionTypeTable"></a-table>
            </div>
          </a-card>

          <a-card v-bind="baseFormCard" title="曲线点位信息" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
            <a slot="extra" href="#" @click="siteModal.show()">点位模板</a>

            <a-row v-bind="rowProps" style="padding: 0 10px" v-if="dataForm.data.configMode === checkStandardCurveModeEnum.manMade">
              <a-col v-bind="colProps">
                <a-form-item label="选择点位数">
                  <a-select placeholder="选择点位数" v-model="siteQuantity" @change="changeSiteQuantity">
                    <a-select-option :value="1">1</a-select-option>
                    <a-select-option :value="2">2</a-select-option>
                    <a-select-option :value="3">3</a-select-option>
                    <a-select-option :value="4">4</a-select-option>
                    <a-select-option :value="5">5</a-select-option>
                    <a-select-option :value="6">6</a-select-option>
                    <a-select-option :value="7">7</a-select-option>
                    <a-select-option :value="8">8</a-select-option>
                    <a-select-option :value="9">9</a-select-option>
                    <a-select-option :value="10">10</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div>
              <div class="explanatory-note">注：1、标准物质加入量 = 标准使用液加入量 x 标准物质浓度值，请确保单位统一</div>
              <div class="explanatory-note text-indent">2、测定浓度 = 标准物质加入量 / 定容体积，请确保单位统一</div>
            </div>

            <hot-table
              ref="table"
              :data="curveDrawTable.dataSource"
              :settings="setting"
              :colHeaders="setting?.columns?.map((item) => item.title)"
              language="zh-CN"
              style="width: 100%"
            ></hot-table>
          </a-card>
        </a-card>
      </a-form>

      <!-- 参比溶液选择 -->
      <a-modal v-bind="reagentCateSelectModal">
        <cate-select-and-query-table v-bind="reagentCateSelectModal.data" v-on="reagentCateSelectModal.tableAction" />
        <template #footer>
          <a-button @click="reagentCateSelectModal.action.cancel">关闭</a-button>
          <a-button type="primary" @click="reagentCateSelectModal.action.ok">确定</a-button>
        </template>
      </a-modal>

      <!-- 曲线点位模板表单-->
      <a-modal v-bind="siteModal" v-if="siteModal.visible" v-on="siteModal">
        <site-model v-bind="siteModal.data" v-on="siteModal.data"></site-model>
        <template #footer>
          <a-button @click="siteModal.action.cancel()">关闭</a-button>
          <a-button @click="siteModal.action.ok" type="primary">保存</a-button>
        </template>
      </a-modal>

      <CurrentConfigMixSolutionTypeModal
        ref="currentConfigMixSolutionTypeRef"
        :capacityUnitMap="capacityUnitMap"
        :massUnitMap="massUnitMap"
        :densityUnitMap="densityUnitMap"
        :peakAreaUnitMap="peakAreaUnitMap"
        :reagentCateValidDateUnitMap="reagentCateValidDateUnitMap"
        @ok="successMixSolutionType"
      ></CurrentConfigMixSolutionTypeModal>
      <ExistingMixSolutionTypeModal
        ref="existingMixSolutionTypeRef"
        :capacityUnitMap="capacityUnitMap"
        :massUnitMap="massUnitMap"
        :densityUnitMap="densityUnitMap"
        :peakAreaUnitMap="peakAreaUnitMap"
        :reagentCateValidDateUnitMap="reagentCateValidDateUnitMap"
        @ok="successMixSolutionType"
      ></ExistingMixSolutionTypeModal>
    </a-spin>
  </div>
</template>

<script>
import miniForm from '@/pages/common/mixins/form'
import { checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import {
  baseCurveDrawColumns,
  curveDrawModels,
  defaultBaseCurveDrawColumns,
  defaultBaseCurveDrawColumns3,
  defaultBaseCurveDrawColumns4
} from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import CateSelectAndQueryTable from '@/pages/cloud-quote/check-reagent/cate/modules/table/CateSelectAndQueryTable'
import { checkStandardCurveModeEnum, checkStandardCurveModeOptions } from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { CheckReagentCateSelectColumn as checkReagentCateColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { reagentCateValidDateUnitMap } from '@/api/checkReagent/checkReagentConstant'
import SiteModel from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable/SiteModel'
import CurrentConfigMixSolutionTypeModal from '@/pages/cloud-quote/check-standard-curve-type/modules/form/currentConfigMixSolutionTypeModal'
import ExistingMixSolutionTypeModal from '@/pages/cloud-quote/check-standard-curve-type/modules/form/existingMixSolutionTypeModal'
import { Get, resMaterialApi, itemCheckDetailCompositeApi, itemApi } from '@/api/quote'
import fullModal from '@/pages/common/mixins/modal/full'
import JInputNumber from '@/components/j/JInputNumber'
import { dictItemApi } from '@/api/system'
import { checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'
import { cloneDeep, groupBy, orderBy, sortBy, uniqBy } from 'lodash'

import { HotTable } from '@handsontable/vue'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import { registerLanguageDictionary, zhCN } from 'handsontable/i18n'
registerAllModules()
registerLanguageDictionary(zhCN)

const fullModalData = fullModal.data().fullModal

export default {
  name: 'Index',
  mixins: [miniForm],
  components: {
    CateSelectAndQueryTable,
    SiteModel,
    JInputNumber,
    CurrentConfigMixSolutionTypeModal,
    ExistingMixSolutionTypeModal,
    HotTable
  },
  props: {
    editType: {
      type: String,
      required: false,
      default: 'add'
    },

    id: {
      type: [String, Object],
      required: false,
      default: null
    },

    standardMethodId: {
      type: [String, Number],
      required: false
    },
    standardId: {
      type: [String, Number],
      required: false
    },
    methodId: {
      type: [String, Number],
      required: false
    },
    methodName: {
      type: String,
      required: false
    },
    standardCode: {
      type: String,
      required: false
    },
    columns: {
      type: Array,
      required: false,
      default: () => defaultBaseCurveDrawColumns
    },
    methodItems: {
      type: Array,
      required: false,
      default: []
    }
  },
  data() {
    return {
      reagentCateValidDateUnitMap,
      baseCurveDrawColumns,
      curveDrawModels,
      checkStandardCurveModeEnum,
      checkStandardCurveModeOptions,
      defaultBaseCurveDrawColumns3,
      defaultBaseCurveDrawColumns4,
      formItemLayout1: {
        labelCol: {
          xxl: { span: 8 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        },
        wrapperCol: {
          xxl: { span: 16 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        }
      },
      colProps1: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      formItemLayoutUnit: {
        labelCol: {
          xxl: { span: 14 },
          xl: { span: 14 },
          lg: { span: 14 },
          md: { span: 14 },
          sm: { span: 14 },
          xs: { span: 14 }
        },
        wrapperCol: {
          xxl: { span: 10 },
          xl: { span: 10 },
          lg: { span: 10 },
          md: { span: 10 },
          sm: { span: 10 },
          xs: { span: 10 }
        }
      },
      siteModal: Object.assign({}, fullModalData, {
        title: '曲线点位模板配置',
        visible: false,
        closable: false,
        maskClosable: true,
        width: '960px',
        data: {
          ref: 'siteModal',
          selectSiteModelId: '',
          success: () => {
            this.siteModal.action.cancel()
            this.reload()
          }
        },

        show: () => {
          this.siteModal.visible = true
        },

        action: {
          cancel: () => {
            this.siteModal.visible = false
          },

          ok: () => {
            const selectSiteModelId = this.$refs[this.siteModal.data.ref].getSelectData()
            if (!selectSiteModelId) {
              this.$message.error('您还未选择曲线模板！')
              return false
            }
            this.siteModal.data.selectSiteModelId = selectSiteModelId
            this.curveDrawTable.columns =
              this.siteModal.data.selectSiteModelId == 3
                ? this.defaultBaseCurveDrawColumns3
                : this.siteModal.data.selectSiteModelId == 4 ||
                  this.siteModal.data.selectSiteModelId == 8 ||
                  this.siteModal.data.selectSiteModelId == 9 ||
                  this.siteModal.data.selectSiteModelId == 10 ||
                  this.siteModal.data.selectSiteModelId == 11
                ? this.defaultBaseCurveDrawColumns4
                : this.columns
            let columns = [{ title: '因子', data: 'itemName', readOnly: true }]
            this.curveDrawTable.columns.forEach((it, ix) => {
              if (ix !== this.curveDrawTable.columns.length - 1) {
                let tt = {
                  title: it.title,
                  data: it.dataIndex
                }
                if (it.dataIndex !== 'itemName' && it.dataIndex !== 'drawIndex') {
                  tt.type = 'numeric'
                }
                if (it.dataIndex === 'drawIndex') {
                  tt.width = 50
                }
                if (
                  this.siteModal.data.selectSiteModelId == 3 ||
                  this.siteModal.data.selectSiteModelId == 4 ||
                  this.siteModal.data.selectSiteModelId == 8 ||
                  this.siteModal.data.selectSiteModelId == 9 ||
                  this.siteModal.data.selectSiteModelId == 10 ||
                  this.siteModal.data.selectSiteModelId == 11
                ) {
                  if (it.dataIndex !== 'standardLiquid') {
                    tt.readOnly = true
                  }
                }
                columns.push(tt)
              }
            })

            this.setting.columns = columns
            this.autoCalcSiteNumberMix()
            this.siteModal.action.cancel()
          }
        }
      }),
      dataForm: {
        data: {
          id: null,
          name: this.methodName,
          standardMethodId: this.standardMethodId,
          standardId: this.standardId,
          methodId: this.methodId,
          methodName: this.methodName,
          standardCode: this.standardCode,
          devTypeId: null,
          speType: '',
          referenceSolution: '',
          validDateUnit: 'd',
          configMode: checkStandardCurveModeEnum.manMade,
          constantVolumeUnit: 'mL'
        }
      },
      decorators: {
        name: ['name', { rules: [{ required: true, message: '请输入曲线类型名称！' }] }],
        thickness: ['thickness', { rules: [{ required: false }] }],
        devType: ['devType', { rules: [{ required: true, message: '请选择设备类型！' }] }],
        validDate: ['validDate', { rules: [{ required: true, message: '请输入有效期！' }] }],
        constantVolume: ['constantVolume', { rules: [{ required: true, message: '请输入定容体积！' }] }]
      },
      devList: [],
      devMap: {},

      confirmLoading: false,

      curveDrawTable: {
        rowKey: (item) => `${item.itemId}-${item.drawIndex}`,
        bordered: true,
        size: 'small',
        dataSource: [],
        columns: this.columns,
        pagination: false
      },
      reagentCateSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择参比溶液',
        currentSolution: null,
        bodyStyle: { background: '#F0F2F5' },

        show: () => {
          this.reagentCateSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.reagentCateSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.reagentCateSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const { id, name } = selectData[0]
            this.dataForm.data.referenceSolution = id
            this.dataForm.data.referenceSolutionName = name

            this.reagentCateSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'reagentCateSelectTable',
          columns: checkReagentCateColumn,
          rowSelect: { type: 'radio' }
        },

        tableAction: {}
      },

      // 常用容量单位
      capacityUnitList: [],
      capacityUnitMap: {},
      // 常用质量单位
      massUnitList: [],
      massUnitMap: {},
      // 常用浓度单位
      densityUnitList: [],
      densityUnitMap: {},
      // 常用峰面积单位
      peakAreaUnitList: [],
      peakAreaUnitMap: {},

      mixSolutionTypeList: [],
      mixSolutionTypeTable: {
        rowKey: 'itemId',
        bordered: true,
        size: 'small',
        width: '100%',
        dataSource: [],
        columns: [
          { title: '因子', dataIndex: 'itemName', scopedSlots: { customRender: 'itemName' } },
          { title: '标液类型', dataIndex: 'solutionName', scopedSlots: { customRender: 'solutionName' } },
          { title: '浓度', dataIndex: 'solutionPurity', scopedSlots: { customRender: 'solutionPurity' } }
        ],
        pagination: false
      },
      siteQuantity: 6,
      setting: {
        width: '100%',
        height: '500px',
        rowHeaders: true,
        colHeaders: true,
        autoColumnSize: true,
        filters: true,
        // dropdownMenu: true,
        manualColumnMove: true,
        multiColumnSorting: true,
        stretchH: 'all',
        className: 'htMiddle htCenter',
        currentHeaderClassName: 'ht__highlight',
        activeHeaderClassName: 'ht__active_highlight',
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 100,
        // columns: [],
        afterChange: (changes) => {
          const self = this
          if (changes !== null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              // console.log(row, prop, oldValue, newValue)
              // 只要有一个定容体积或定容体积单位改变，其它几行数据也需要同步改变
              if (prop === 'standardLiquid') {
                let record = self.curveDrawTable.dataSource[row]
                self.changeCurveDrawMix(record, prop, newValue)
              }
            })
          }
        }
      },
      mixSolutionTypeStatus: '1' // 判断混合标液类型数据是从已有混合标液还是现配混合标液中取的
    }
  },
  created() {
    this.getDevList()
    this.capacityUnitList = []
    this.capacityUnitMap = {}
    this.massUnitList = []
    this.massUnitMap = {}
    this.densityUnitList = []
    this.densityUnitMap = {}
    this.peakAreaUnitList = []
    this.peakAreaUnitMap = {}

    // 常用容量单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'capacity_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.capacityUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
            this.capacityUnitMap[d[i].itemValue] = d[i].itemText
          }
        }
      }
    })
    // 常用质量单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'mass_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.massUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
            this.massUnitMap[d[i].itemValue] = d[i].itemText
          }
        }
      }
    })
    // 常用浓度单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'density_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.densityUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
            this.densityUnitMap[d[i].itemValue] = d[i].itemText
          }
        }
      }
    })
    // 常用峰面积单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'peak_area_unit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.peakAreaUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
            this.peakAreaUnitMap[d[i].itemValue] = d[i].itemText
          }
        }
      }
    })
  },
  watch: {
    editType: {
      immediate: true,
      handler(nv, ov) {
        console.log(nv)
        if (nv === 'edit') {
          const param = { id: this.id }
          checkStandardCurveTypeApi.detailCurveType(param).then((res) => {
            if (res.code === 0 && res.data.length > 0) {
              const data = res.data[0]
              this.mixSolutionTypeStatus = data.mixSolutionTypeStatus || '1'
              this.$nextTick(() => {
                this.dataForm.data = {
                  id: data.id,
                  name: data.name,
                  standardMethodId: data.standardMethodId,
                  standardId: data.standardId,
                  methodId: data.methodId,
                  methodName: data.methodName,
                  standardCode: data.standardCode,
                  devTypeId: data.devTypeId,
                  referenceSolution: data.referenceSolution,
                  referenceSolutionName: data.referenceSolutionName,
                  validDateUnit: data.validDateUnit,
                  configMode: data.configMode || checkStandardCurveModeEnum.manMade,
                  constantVolumeUnit: data.constantVolumeUnit || 'mL'
                }
                this.dataForm.form.setFieldsValue({
                  name: data.name,
                  thickness: data.thickness,
                  devType: data.devTypeId.split(','),
                  validDate: data.validDate,
                  constantVolume: data.constantVolume
                })

                this.onSelectDev(data.devTypeId.split(','))

                if (data.mixSolutionTypeList && data.mixSolutionTypeList.length) {
                  this.mixSolutionTypeList = data.mixSolutionTypeList
                }

                if (data.siteModelId) {
                  this.siteModal.data.selectSiteModelId = data.siteModelId
                } else {
                  this.siteModal.data.selectSiteModelId = 1
                }
                this.curveDrawTable.columns =
                  this.siteModal.data.selectSiteModelId == 3
                    ? this.defaultBaseCurveDrawColumns3
                    : this.siteModal.data.selectSiteModelId == 4 ||
                      this.siteModal.data.selectSiteModelId == 8 ||
                      this.siteModal.data.selectSiteModelId == 9 ||
                      this.siteModal.data.selectSiteModelId == 10 ||
                      this.siteModal.data.selectSiteModelId == 11
                    ? this.defaultBaseCurveDrawColumns4
                    : this.columns
                console.log(`|${this.siteModal.data.selectSiteModelId}|`, this.curveDrawTable.columns)
                data.drawList.forEach((item) => {
                  // 模板3时，给测定浓度赋值
                  if (data.siteModelId && data.siteModelId == 3) {
                    if (item.standardLiquid) {
                      if (item.measureConcentration) {
                        item.result = Number(item.measureConcentration).toFixed(3)
                      }
                    } else {
                      item.concentration = null
                      item.result = null
                    }
                  }
                  // 模板4或8时，给测定浓度赋值
                  if (data.siteModelId && (data.siteModelId == 4 || data.siteModelId == 8 || data.siteModelId == 9 || data.siteModelId == 10 || data.siteModelId == 11)) {
                    if (item.standardLiquid) {
                      if (item.measureConcentration) {
                        item.purity = Number(item.measureConcentration).toFixed(3)
                      }
                    } else {
                      item.concentration = null
                      item.purity = null
                    }
                  }
                })
                console.log(data.drawList, data.drawList.length)

                this.curveDrawTable.dataSource.splice(0, this.curveDrawTable.dataSource.length, ...data.drawList)

                this.getMethodItems()
              })
            }
          })
        } else {
          this.mixSolutionTypeStatus = '1'
          this.siteModal.data.selectSiteModelId = 1
          this.curveDrawTable.columns =
            this.siteModal.data.selectSiteModelId == 3
              ? this.defaultBaseCurveDrawColumns3
              : this.siteModal.data.selectSiteModelId == 4 ||
                this.siteModal.data.selectSiteModelId == 8 ||
                this.siteModal.data.selectSiteModelId == 9 ||
                this.siteModal.data.selectSiteModelId == 10 ||
                this.siteModal.data.selectSiteModelId == 11
              ? this.defaultBaseCurveDrawColumns4
              : this.columns
          console.log(`|${this.siteModal.data.selectSiteModelId}|`, this.curveDrawTable.columns)
          this.getMethodItems()

          checkReagentCateApi.findById({ id: -1 }).then((res) => {
            if (res.code === 0 && res.data) {
              this.$set(this.dataForm.data, 'referenceSolution', res.data.id)
              this.$set(this.dataForm.data, 'referenceSolutionName', res.data.name)
            }
          })
        }
      }
    },
    devList: {
      immediate: true,
      handler(nv, ov) {
        if (this.dataForm.data.devTypeId !== null) {
          this.onSelectDev(this.dataForm.data.devTypeId.split(','))
        }
      }
    }
  },
  methods: {
    getMethodItems() {
      let methodItemIds = this.methodItems.map((item) => item.id)
      let mergeCells = []

      for (let i = this.mixSolutionTypeList.length - 1; i >= 0; i--) {
        let item = this.mixSolutionTypeList[i]
        let findIndex = this.methodItems.findIndex((item2) => item2.id === item.itemId)
        if (findIndex === -1) {
          this.mixSolutionTypeList.splice(i, 1)
        }
      }
      if (this.curveDrawTable.dataSource && this.curveDrawTable.dataSource.length) {
        this.siteQuantity = uniqBy(this.curveDrawTable.dataSource, 'drawIndex').length
      }
      let curveDrawList = []
      this.methodItems.forEach((item, index) => {
        let findIndex = this.mixSolutionTypeList.findIndex((item2) => item2.itemId === item.id)
        if (findIndex === -1) {
          let mixSolutionTypeItem = {
            methodId: this.dataForm.data.methodId,
            itemId: item.id,
            itemName: item.name,
            initialConstantVolumeUnit: 'mL',
            initialUsageVolumeUnit: 'mL',
            regressionEquation: 'Y = bX + a',
            // validDateUnit: 'd',
            minCorrel: 0.9999,

            standardLiquid: 'mL',
            concentration: 'μg',
            purity: 'mg/L',

            verifyAllowMinRange: -10,
            verifyAllowMaxRange: 10,
            verifyValidDate: 1,
            verifyValidDateUnit: 'd'
          }
          if (this.dataForm.data.id) {
            mixSolutionTypeItem.rid = this.dataForm.data.id
          }
          this.mixSolutionTypeList.push(mixSolutionTypeItem)
        }

        let list = this.curveDrawTable.dataSource.filter((item2) => item2.itemId === item.id)
        if (list.length < this.siteQuantity) {
          let num = this.siteQuantity - list.length
          let totalNum = list.length
          for (let i = 1; i <= num; i++) {
            list.push({
              itemId: item.id,
              itemName: item.name,
              drawIndex: (totalNum + i).toString(),
              standardLiquid: '',
              concentration: '',
              absorbanceValue: '',
              absorbanceValue2: '',
              result: '',
              editable: true
            })
          }
        }
        console.log(list)

        curveDrawList.push(...list)
        mergeCells.push({ row: this.siteQuantity * index, col: 0, rowspan: this.siteQuantity, colspan: 1 })
      })
      this.mixSolutionTypeList.forEach((item) => {
        if (item.initialPurity) {
          item.initialPurityNumber = this.getNum(item.initialPurity)
        }
        if (item.solutionPurity) {
          item.solutionPurityNumber = this.getNum(item.solutionPurity)
          item.densityUnit = this.getUnit(item.solutionPurity)
        }
      })

      this.mixSolutionTypeTable.dataSource.splice(0, this.mixSolutionTypeTable.dataSource.length, ...this.mixSolutionTypeList)

      // this.siteModal.data.selectSiteModelId = 4
      let columns = [{ title: '因子', data: 'itemName', readOnly: true }]
      this.curveDrawTable.columns.forEach((it, ix) => {
        if (ix !== this.curveDrawTable.columns.length - 1) {
          let tt = {
            title: it.title,
            data: it.dataIndex
          }
          if (it.dataIndex !== 'itemName' && it.dataIndex !== 'drawIndex') {
            tt.type = 'numeric'
          }
          if (it.dataIndex === 'drawIndex') {
            tt.width = 50
          }
          if (
            this.siteModal.data.selectSiteModelId == 3 ||
            this.siteModal.data.selectSiteModelId == 4 ||
            this.siteModal.data.selectSiteModelId == 8 ||
            this.siteModal.data.selectSiteModelId == 9 ||
            this.siteModal.data.selectSiteModelId == 10 ||
            this.siteModal.data.selectSiteModelId == 11
          ) {
            if (it.dataIndex !== 'standardLiquid') {
              tt.readOnly = true
            }
          }

          columns.push(tt)
        }
      })

      this.setting.columns = columns
      this.setting.mergeCells = mergeCells

      if (curveDrawList.length) {
        curveDrawList = orderBy(curveDrawList, 'drawIndex', 'asc')
        curveDrawList = sortBy(curveDrawList, (item) => methodItemIds.findIndex((mItem) => mItem === item.itemId))
      }
      this.curveDrawTable.dataSource.splice(0, this.curveDrawTable.dataSource.length, ...curveDrawList)
      this.$refs.table?.hotInstance.updateSettings({ columns: this.setting.columns, mergeCells: this.setting.mergeCells })
      console.log(this.mixSolutionTypeTable.dataSource, this.curveDrawTable.dataSource)
    },
    showExistingMixSolutionType() {
      let list = cloneDeep(this.mixSolutionTypeList)
      if (this.mixSolutionTypeStatus !== '1') {
        list.forEach((item) => {
          // item.solutionTypeId = null
          // item.solutionName = null
          // item.initialPurity = null
          // item.initialPurityNumber = null
          // item.initialConstantVolume = null
          // item.initialConstantVolumeUnit = null
          // item.initialUsageVolume = null
          // item.initialUsageVolumeUnit = null
          // item.densityUnit = null
          // item.solutionPurityNumber = null
          // item.solutionPurity = null
          delete item.solutionTypeId
          delete item.solutionName
          delete item.initialPurity
          delete item.initialPurityNumber
          delete item.initialConstantVolume
          delete item.initialConstantVolumeUnit
          delete item.initialUsageVolume
          delete item.initialUsageVolumeUnit
          delete item.densityUnit
          delete item.solutionPurityNumber
          delete item.solutionPurity
        })
      }
      this.$refs.existingMixSolutionTypeRef.show(list)
    },
    showCurrentConfigMixSolutionType() {
      let list = cloneDeep(this.mixSolutionTypeList)
      if (this.mixSolutionTypeStatus !== '2') {
        list.forEach((item) => {
          // item.solutionTypeId = null
          // item.solutionName = null
          // item.initialPurity = null
          // item.initialPurityNumber = null
          // item.initialConstantVolume = null
          // item.initialConstantVolumeUnit = null
          // item.initialUsageVolume = null
          // item.initialUsageVolumeUnit = null
          // item.densityUnit = null
          // item.solutionPurityNumber = null
          // item.solutionPurity = null
          delete item.solutionTypeId
          delete item.solutionName
          delete item.initialPurity
          delete item.initialPurityNumber
          delete item.initialConstantVolume
          // delete item.initialConstantVolumeUnit
          item.initialConstantVolumeUnit = 'mL'
          delete item.initialUsageVolume
          // delete item.initialUsageVolumeUnit
          item.initialUsageVolumeUnit = 'mL'
          delete item.densityUnit
          delete item.solutionPurityNumber
          delete item.solutionPurity
        })
      }
      this.$refs.currentConfigMixSolutionTypeRef.show(list)
    },
    successMixSolutionType(mixSolutionTypeList, mixSolutionTypeStatus) {
      console.log(mixSolutionTypeList, mixSolutionTypeStatus)

      this.mixSolutionTypeList.splice(0, this.mixSolutionTypeList.length, ...mixSolutionTypeList)
      this.mixSolutionTypeTable.dataSource.splice(0, this.mixSolutionTypeTable.dataSource.length, ...this.mixSolutionTypeList)
      this.mixSolutionTypeStatus = mixSolutionTypeStatus
      this.autoCalcSiteNumberMix()
    },

    changeSiteQuantity(e) {
      let curveDrawGroup = groupBy(this.curveDrawTable.dataSource, 'itemId')
      let totalList = []
      let mergeCells = []
      this.methodItems.forEach((item, index) => {
        let list = curveDrawGroup[item.id]
        if (list && list.length) {
          if (list.length > e) {
            list = list.slice(0, e)
          } else if (list.length < e) {
            let num = e - list.length
            let totalNum = list.length
            for (let i = 1; i <= num; i++) {
              list.push({
                itemId: item.id,
                itemName: item.name,
                drawIndex: (totalNum + i).toString(),
                standardLiquid: '',
                concentration: '',
                absorbanceValue: '',
                absorbanceValue2: '',
                result: '',
                editable: true
              })
            }
          }
        } else {
          list = []
          for (let i = 1; i <= e; i++) {
            list.push({
              itemId: item.id,
              itemName: item.name,
              drawIndex: i.toString(),
              standardLiquid: '',
              concentration: '',
              absorbanceValue: '',
              absorbanceValue2: '',
              result: '',
              editable: true
            })
          }
        }
        totalList.push(...list)
        mergeCells.push({ row: e * index, col: 0, rowspan: e, colspan: 1 })
      })

      this.setting.mergeCells = mergeCells

      this.curveDrawTable.dataSource.splice(0, this.curveDrawTable.dataSource.length, ...totalList)
    },
    getNum(str) {
      if (str) {
        let matchs = str.match(/\d+(.\d+)?/g)
        if (matchs && matchs.length) {
          return matchs[0]
        }
      }
      return '0'
    },
    getUnit(val) {
      // console.log(val,val.replace(/[0-9]*/g, '').replace(/\./, ''));
      if (val) {
        return val.replace(/[0-9]*/g, '').replace(/\./, '')
      }
      return 'μg/ml'
    },

    changeReagentCate() {
      this.dataForm.data.referenceSolution = ''
      this.dataForm.data.referenceSolutionName = ''
    },
    getDevList() {
      Get(resMaterialApi.listDetail, { page: false, cateType: 3 }).then((result) => {
        this.devList = result.data
        this.devMap = this.devList.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
    },
    onSelectDev(value) {
      const data = value.map((it) => (this.devMap[it] ? this.devMap[it].speType : ''))
      if (data !== null && data.length > 0) {
        this.dataForm.data.devTypeId = value.join(',')
        this.dataForm.data.speType = data.join(',')
      }
    },
    save() {
      if (!this.confirmLoading) {
        this.dataForm.form.validateFields((errors, values) => {
          if (!errors) {
            if (this.dataForm.data.configMode === checkStandardCurveModeEnum.manMade && this.curveDrawTable.dataSource.length === 0) {
              this.$message.error(`请填写曲线点位信息!`)
              return
            }

            this.confirmLoading = true
            const data = Object.assign(values, this.dataForm.data)
            const columnsUint = {}
            if (this.dataForm.data.configMode === checkStandardCurveModeEnum.manMade) {
              if (this.siteModal.data.selectSiteModelId == 3) {
                this.curveDrawTable.dataSource.forEach((item) => {
                  item.measureConcentration = item.result
                })
              }
              if (
                this.siteModal.data.selectSiteModelId == 4 ||
                this.siteModal.data.selectSiteModelId == 8 ||
                this.siteModal.data.selectSiteModelId == 9 ||
                this.siteModal.data.selectSiteModelId == 10 ||
                this.siteModal.data.selectSiteModelId == 11
              ) {
                this.curveDrawTable.dataSource.forEach((item) => {
                  item.measureConcentration = item.purity
                })
              }
              // this.curveDrawTable.dataSource.forEach((item) => {
              //   item.measureConcentration = item.purity
              // })
              data.drawList = this.curveDrawTable.dataSource
              data.siteModelId = this.siteModal.data.selectSiteModelId
            }

            data.solutionTypeId = null

            checkStandardCurveTypeApi
              .save(Object.assign(data, columnsUint, { mixSolutionTypeList: this.mixSolutionTypeList, mixSolutionTypeStatus: this.mixSolutionTypeStatus }))
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.$emit('success')
                } else {
                  this.$message.error(res.msg)
                  this.$emit('error')
                }
              })
              .finally(() => {
                this.confirmLoading = false
                // this.close()
              })
          }
        })
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    changeConstantVolume(value) {
      this.$nextTick(() => {
        this.autoCalcSiteNumberMix()
      })
    },

    // 混合标液分类下的点位修改
    changeCurveDrawMix(record, dataIndex, value) {
      if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 3) {
        if (dataIndex === 'standardLiquid') {
          if (value || value === 0) {
            let mItem = this.mixSolutionTypeList.find((item) => item.itemId === record.itemId)
            if (mItem) {
              if (mItem.solutionPurityNumber) {
                let concentration = value * mItem.solutionPurityNumber
                this.$set(record, 'concentration', concentration.toFixed(3))
                let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
                if (constantVolume || constantVolume === 0) {
                  let result = concentration / constantVolume
                  this.$set(record, 'result', result.toFixed(3))
                } else {
                  this.$set(record, 'result', null)
                }
              } else {
                this.$set(record, 'concentration', null)
                this.$set(record, 'result', null)
              }
            } else {
              this.$set(record, 'concentration', null)
              this.$set(record, 'result', null)
            }
          } else {
            this.$set(record, 'concentration', null)
            this.$set(record, 'result', null)
          }
        }
      }
      if (
        this.siteModal &&
        this.siteModal.data &&
        this.siteModal.data.selectSiteModelId &&
        (this.siteModal.data.selectSiteModelId == 4 ||
          this.siteModal.data.selectSiteModelId == 8 ||
          this.siteModal.data.selectSiteModelId == 9 ||
          this.siteModal.data.selectSiteModelId == 10 ||
          this.siteModal.data.selectSiteModelId == 11)
      ) {
        if (dataIndex === 'standardLiquid') {
          if (value || value === 0) {
            let mItem = this.mixSolutionTypeList.find((item) => item.itemId === record.itemId)
            if (mItem) {
              if (mItem.solutionPurityNumber) {
                let concentration = value * mItem.solutionPurityNumber
                this.$set(record, 'concentration', concentration.toFixed(3))
                let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
                if (constantVolume || constantVolume === 0) {
                  let purity = concentration / constantVolume
                  this.$set(record, 'purity', purity.toFixed(3))
                } else {
                  this.$set(record, 'purity', null)
                }
              } else {
                this.$set(record, 'concentration', null)
                this.$set(record, 'purity', null)
              }
            } else {
              this.$set(record, 'concentration', null)
              this.$set(record, 'purity', null)
            }
          } else {
            this.$set(record, 'concentration', null)
            this.$set(record, 'purity', null)
          }
        }
      }
      this.$refs.table?.hotInstance.render()
    },
    // 混合标液分类点击确定时自动计算
    autoCalcSiteNumberMix() {
      let constantVolume = this.dataForm.form.getFieldValue('constantVolume')
      let curveDrawGroup = groupBy(this.curveDrawTable.dataSource, 'itemId')
      let totalList = []
      for (let mi = 0; mi < this.mixSolutionTypeList.length; mi++) {
        let mItem = this.mixSolutionTypeList[mi]
        let curveDrawGroupItem = curveDrawGroup[mItem.itemId]
        for (let i = 0; i < curveDrawGroupItem.length; i++) {
          let item = curveDrawGroupItem[i]
          if (this.siteModal && this.siteModal.data && this.siteModal.data.selectSiteModelId && this.siteModal.data.selectSiteModelId == 3) {
            if (mItem.solutionPurityNumber && (item.standardLiquid || item.standardLiquid === 0)) {
              let concentration = item.standardLiquid * mItem.solutionPurityNumber
              this.$set(curveDrawGroupItem[i], 'concentration', concentration.toFixed(3))
              if (constantVolume || constantVolume === 0) {
                let result = concentration / constantVolume
                this.$set(curveDrawGroupItem[i], 'result', result.toFixed(3))
              } else {
                this.$set(curveDrawGroupItem[i], 'result', null)
              }
            } else {
              this.$set(curveDrawGroupItem[i], 'concentration', null)
              this.$set(curveDrawGroupItem[i], 'result', null)
            }
          }
          if (
            this.siteModal &&
            this.siteModal.data &&
            this.siteModal.data.selectSiteModelId &&
            (this.siteModal.data.selectSiteModelId == 4 ||
              this.siteModal.data.selectSiteModelId == 8 ||
              this.siteModal.data.selectSiteModelId == 9 ||
              this.siteModal.data.selectSiteModelId == 10 ||
              this.siteModal.data.selectSiteModelId == 11)
          ) {
            if (mItem.solutionPurityNumber && (item.standardLiquid || item.standardLiquid === 0)) {
              let concentration = item.standardLiquid * mItem.solutionPurityNumber
              this.$set(curveDrawGroupItem[i], 'concentration', concentration.toFixed(3))
              if (constantVolume || constantVolume === 0) {
                let purity = concentration / constantVolume
                this.$set(curveDrawGroupItem[i], 'purity', purity.toFixed(3))
              } else {
                this.$set(curveDrawGroupItem[i], 'purity', null)
              }
            } else {
              this.$set(curveDrawGroupItem[i], 'concentration', null)
              this.$set(curveDrawGroupItem[i], 'purity', null)
            }
          }
          totalList.push(item)
        }
      }
      console.log(totalList)

      this.curveDrawTable.dataSource.splice(0, this.curveDrawTable.dataSource.length, ...totalList)
    }
  }
}
</script>

<style scoped lang="less">
.content-row {
  border: 1px dashed #e8e8e8;
  margin-bottom: 10px;
}

.content-row-title {
  padding: 10px;
  line-height: 32px;
  color: #4daaff;
}
.explanatory-note {
  font-size: 12px;
  color: red;
}
.text-indent {
  text-indent: 2em;
}
</style>
