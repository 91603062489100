<template>
  <div style="margin-bottom: 50px;">
    <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
      报告审核
    </div>
    <div style="width: 100%;height: 100%;">
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              基本信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel">项目名称</td>
              <td colspan="3">{{checkTask.projName}}</td>
            </tr>
            <tr>
              <td class="tdLabel">委托单位</td>
              <td colspan="3">{{ checkTask.csrName }}</td>
            </tr>
            <tr>
              <td class="tdLabel">委托日期</td>
              <td>{{ moment(checkTask.projScheduledStart).format('YYYY-MM-DD') }}</td>
              <td class="tdLabel">检测点位</td>
              <td><span style="font-weight: 700;margin: 0 5px 0 3px">{{ checkTask.siteSum }}</span>个</td>
            </tr>
            <tr>
              <td class="tdLabel">项目负责人</td>
              <td>{{ checkTask.projUserName }}</td>
              <td class="tdLabel">检测负责人</td>
              <td>{{ checkTask.detectionLeaderName }}</td>
            </tr>
            <tr>
              <td class="tdLabel">报告人</td>
              <td>{{ checkTask.reportUserName }}</td>
              <td class="tdLabel">开始时间</td>
              <td>{{ checkTask.startTime }}</td>
            </tr>
            <tr v-if="checkTask.accessory || taskTemplateList.length>0">
              <td class="tdLabel">报告文件</td>
              <td colspan="3">
                <template v-if="taskTemplateList.length>0">
                  <template v-for="(template,tIndex) in taskTemplateList">
                    <div :key="`accessory_`+tIndex+index" v-for="(item,index) in JSON.parse(template.accessory)">
                      <a v-if="!(parseInt(template.checkStatus) && parseInt(template.approvalStatus))" @dblclick="previewFile(item)" title="双击查看报告文件">{{ item.name }}</a>
                      <template v-else>{{ item.name }}</template>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div :key="`accessory_`+index" v-for="(item,index) in JSON.parse(checkTask.accessory)">
                    <a v-if="!(parseInt(checkTask.checkStatus) && parseInt(checkTask.approvalStatus))" @dblclick="previewFile(item)" title="双击查看报告文件">{{ item.name }}</a>
                    <template v-else>{{ item.name }}</template>
                  </div>
                </template>
              </td>
            </tr>
          </table>
        </a-row>
      </a-card>
    </div>
<!--    <div style="width: 100%;height: 100%;" v-if="parseInt(checkTask.checkStatus)">-->
<!--      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">-->
<!--        <a-row>-->
<!--          <a-col span="3">-->
<!--            <div class="ahmux-sub-title" style="width: 100px;">-->
<!--              审核信息-->
<!--            </div>-->
<!--          </a-col>-->
<!--        </a-row>-->
<!--        <a-row>-->
<!--          <table class="mxtable">-->
<!--            <tr>-->
<!--              <td class="tdLabel">审核人</td>-->
<!--              <td>{{ checkTask.checkUser }}</td>-->
<!--              <td class="tdLabel">审核结果</td>-->
<!--              <td>-->
<!--                <span style="margin-right: 10px">与 {{ checkTask.checkTime }}</span>-->
<!--                <a-tag color="green" v-if="checkTask.checkStatus==='1'">通过</a-tag>-->
<!--                <a-tag color="red" v-if="checkTask.checkStatus==='2'">不通过</a-tag>-->
<!--              </td>-->
<!--            </tr>-->

<!--            <tr v-if="parseInt(checkTask.checkStatus)">-->
<!--              <td class="tdLabel">审核意见</td>-->
<!--              <td colspan="3">{{ checkTask.checkIdea }}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </a-row>-->
<!--      </a-card>-->
<!--    </div>-->
<!--    <div style="width: 100%;height: 100%;" v-if="parseInt(checkTask.approvalStatus)">-->
<!--      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">-->
<!--        <a-row>-->
<!--          <a-col span="3">-->
<!--            <div class="ahmux-sub-title" style="width: 100px;">-->
<!--              审批信息-->
<!--            </div>-->
<!--          </a-col>-->
<!--        </a-row>-->
<!--        <a-row>-->
<!--          <table class="mxtable">-->
<!--            <tr>-->
<!--              <td class="tdLabel">审批人</td>-->
<!--              <td>{{ checkTask.approvalUser }}</td>-->
<!--              <td class="tdLabel">审批结果</td>-->
<!--              <td>-->
<!--                <span style="margin-right: 10px">与 {{ checkTask.approvalTime }}</span>-->
<!--                <a-tag color="green" v-if="checkTask.approvalStatus==='1'">通过</a-tag>-->
<!--                <a-tag color="red" v-if="checkTask.approvalStatus==='2'">不通过</a-tag>-->
<!--              </td>-->
<!--            </tr>-->

<!--            <tr v-if="parseInt(checkTask.approvalStatus)">-->
<!--              <td class="tdLabel">审批意见</td>-->
<!--              <td colspan="3">{{ checkTask.approvalIdea }}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </a-row>-->
<!--      </a-card>-->
<!--    </div>-->
  </div>
</template>

<script>
import ReportTaskDetail from '@/pages/cloud-quote/report/task/moudles/ReportTaskDetail'
import moment from 'moment'
import { reportTaskApi } from '@/api/sample'
import { Get } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import { reportTaskTemplateApi } from '../../../api/sample'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}
export default {
  name: 'JxReport',
  components: { ReportTaskDetail },
  props: {

    // 流程类型KEY
    processKey: {
      type: String,
      required: true,
      default: ''
    },

    // 流程名称
    processName: {
      type: String,
      required: true,
      default: ''
    },

    // 流程ID
    recordId: {
      type: Number,
      required: true,
      default: null
    },
    processInstanceId: {
      type: String,
      default: null
    }

  },
  filters: {
    reportTypeFilter (type) {
      return this.reportType[type]
    }
  },
  data () {
    return {
      defaultData: {
        id: null
      },
      modalProps: {
        visible: false
      },
      reportType: this.$store.state.code.cache.proj_report_type,
      checkTask: {},
      taskTemplateList: {}
    }
  },
  // computed: {
  //   queryParam () {
  //     return { id: this.recordId }
  //   }
  // },
  // watch: {
  //   queryParam: {
  //     immediate: true,
  //     handler (newValue, oldValue) {
  //       this.$nextTick(() => {
  //         this.loadData(newValue)
  //       })
  //     }
  //   }
  // },
  watch: {
    recordId: {
      handler (newValue, oldValue) {
        if (newValue && newValue > 0) {
          this.loadData({ id: this.recordId })
        }
      },
      immediate: true
    },
    processInstanceId: {
      handler (newValue, oldValue) {
        if (newValue != null && typeof newValue !== 'undefined') {
          this.loadReportTemplateData({ processId: newValue, page: false })
        }
      },
      immediate: true
    }
  },
  methods: {
    moment,
    loadData (param) {
      reportTaskApi.getOne(param).then(res => {
        if (res.code === 0) {
          this.checkTask = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    loadReportTemplateData (param) {
      reportTaskTemplateApi.list(param).then(res => {
        if (res.code === 0) {
          this.taskTemplateList = res.data
          // if (this.taskTemplateList != null && this.taskTemplateList.length > 0) {
          //
          // }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    previewFile (file) {
      if (!file) {
        this.$message.error('暂无报告文件')
        return false
      }
      const isProduction = process.env.NODE_ENV === 'production'
      if (!isProduction) {
        const filename = file.name
        const type = filename.substr(filename.lastIndexOf('.') + 1, filename.length).toLocaleLowerCase()
        if (type === 'pdf') {
          this.previewPdfVisible = true
          this.previewUrl = file.fileUrl || file.url
        } else if (type === 'jpg' || type === 'png' || type === 'jpeg') {
          this.previewVisible = true
          this.previewImage = file.fileUrl
        } else if (type === 'doc' || type === 'docx' || type === 'xls' || type === 'xlsx') {
          const projectFullUrl = window.location.origin
          const fileUrl = projectFullUrl + `/ms-api/common/download-by-id?id=1&type=2&filename=${file.name}&filepath=${file.fileUrl || file.url}`
          const url = projectFullUrl + sysCommonApi.previewJson + '/id/1/type/4?fileUrl=' + encodeURIComponent(fileUrl)
          Get(sysCommonApi.callUrlAndSign, { url: url }).then(res => {
            window.open(res.data)
          })
        } else {
          this.$message.info('不支持此类文件预览')
        }
      } else {
        window.location = sysCommonApi.downLoad + '?filePath=' + (file.url || file.fileUrl) + '&fileName=' + file.name + '&token=' + this.$store.getters.token
      }
    }
  }
}
</script>

<style lang="less" scoped>
 /* @import "~@/assets/less/table/index";

  .sample-info-table {
    width: 100%;

    tr {
      th {
        width: 15%;
        text-align: right;
      }

      td {
        width: 35%;
        text-align: left;
      }
    }

  }*/
</style>
