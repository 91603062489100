<template>
  <div>
    <a-input-search
      :class="clazz"
      v-model="selectFlow"
      :placeholder="placeholder"
      disabled
      @search="onSearchFlow">
      <a-button slot="enterButton" :disabled="disabled">选择</a-button>
    </a-input-search>

    <search-flow-modal
      v-if="searchFlowModal.visible"
      v-bind="searchFlowModal"
      v-on="searchFlowModal"/>
  </div>
</template>

<script>
import SearchFlowModal from '@/pages/flow/module/components/search-flow/SearchFlowModal'

export default {
  name: 'SearchFlowEdit',
  components: { SearchFlowModal },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    clazz: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: '请先选择流程'
    },

    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      flowList: [],

      selectFlow: '',

      searchFlowModal: {
        visible: false,
        ref: 'SearchFlowModal',
        ok: (data) => { this.change(data) },
        show: () => { this.searchFlowModal.visible = true },
        cancel: () => { this.searchFlowModal.visible = false }
      }
    }
  },

  mounted () {
    this.selectFlow = this.value
  },

  watch: {
    flowList: {
      immediate: true,
      handler (val) {
        this.selectFlow = val.map(item => { return item.processInstanceName }).join(',')
      }
    }
  },
  methods: {

    change (data) {
      this.flowList.splice(0, this.flowList.length, ...data)
      this.$emit('change', data)
    },

    // 通过组织机构筛选选择用户
    onSearchFlow () {
      this.searchFlowModal.show()
      this.$nextTick(() => {
        this.$refs[this.searchFlowModal.ref].init(this.flowList)
      })
    }
  }
}
</script>

<style scoped>

</style>
