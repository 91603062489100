<template>
  <a-modal v-bind="modalProps" @ok="handleSubmit" @cancel.prevent="cancel">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="物资名称" v-bind="formItemLayout">
              <a-select
                v-model="queryParam.materialId"
                showSearch
                placeholder="请选择物资"
                :filterOption="false"
                @search="fetchMaterial"
                @change="e => handleChange(e)">
                <a-select-option v-for="d in selectData" :key="d.value">{{ d.text }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="出库数量" v-bind="formItemLayout">
              <a-input-number v-model="queryParam.num" :min="1" :max="100000000" @change="handleBlur"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <a-card v-bind="contentCard">
      <s-table
        ref="table"
        :scroll="scrollProp"
        :bordered="false"
        size="small"
        :columns="columns"
        :data="loadData"
        :pageSize="this.$store.getters.pageSize"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys,selectedRows:this.selectedRows,onChange: this.onSelectChange }">

        <template v-for="(col, index) in columns" v-if="col.scopedSlots" :slot="col.dataIndex" slot-scope="text, record">
          <div :key="index">
            <template v-if="record.editable"></template>
            <template v-if="col.dataIndex === 'unit'">{{ materialUnitCode[record.unit] }}</template>
            <template v-else-if="col.dataIndex === 'locationId'">{{ getLocation(text, record) }}</template>
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template #purOutStatus="text">
          <a-tag v-if="text == '0' || text == null" color="blue">未领用</a-tag>
          <a-tag v-else color="green">可复用</a-tag>
        </template>
        <template #userName="text">
          <div v-if="text == queryParam.userName" style="color: #5ec75e">{{ text }}</div>
          <div v-else style="color: red">{{ text }}</div>
        </template>
        <template #operation="text, record">
          <a @click="handleRecord(record.id, 1)"><a-icon type="eye"></a-icon>使用记录</a>
        </template>
      </s-table>
    </a-card>
    <record-modal ref="record"></record-modal>
  </a-modal>
</template>

<script>
import { scrollTable, withComponentList } from '@/pages/common/mixins/list'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import STree from '@/components/Tree/Tree'
import { STable } from '@/components'
import { Get, Post, resdevCategoryApi, resLocationApi, resMaterialApi, resMaterialOutApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import { dictApi } from '@/api/system'
import debounce from 'lodash/debounce'
import RecordModal from '@/pages/cloud-quote/res/res-stock/modules/recordModal'

export default {
  name: 'MaterialTable',
  components: {
    STable, STree, Post, resMaterialApi, RecordModal
  },

  mixins: [withComponentList, scrollTable],

  props: {
    categoryList: { type: Array, default: () => [] },
    manufList: { type: Array, default: () => [] },
    supplierList: { type: Array, default: () => [] },
    locationList: { type: Array, default: () => [] },
    cateType: { type: Number },
    clearStatus: { type: Boolean, default: false },
    useStatus: { type: Number, default: 1 }
  },

  data () {
    this.fetchMaterial = debounce(this.fetchMaterial, 800)
    return {
      selectData: [],
      tempData: [],
      tempDataRow: [],
      fetching: false,
      itemRecord: null,
      locationShow: [],
      cateShow: [],
      viewUrl: sysCommonApi.view,
      selectedRowKeys: [],
      selectedRows: [],
      // 查询参数
      queryParam: {
        materialId: null,
        cateType: null,
        useStatus: 1,
        userName: null,
        projId: null
      },
      // 表头
      columns: [
        { title: '物资编号', dataIndex: 'id', width: '80px', scopedSlots: { customRender: 'id' } },
        { title: '内部编号', dataIndex: 'fixedAssetsId', width: '100px', scopedSlots: { customRender: 'fixedAssetsId' } },
        { title: '物资类目', dataIndex: 'cateName', width: '120px', scopedSlots: { customRender: 'cateName' } },
        { title: '物资名称', dataIndex: 'materialName', width: '120px', scopedSlots: { customRender: 'materialName' } },
        { title: '规格型号', dataIndex: 'speType', width: '80px', scopedSlots: { customRender: 'speType' } },
        { title: '浓度', dataIndex: 'purity', width: '80px', scopedSlots: { customRender: 'purity' } },
        { title: '品牌', dataIndex: 'brand', width: '80px', scopedSlots: { customRender: 'brand' } },
        { title: '单位', dataIndex: 'unit', width: '80px', scopedSlots: { customRender: 'unit' } },
        { title: '有效期', dataIndex: 'termOfValid', width: '150px', scopedSlots: { customRender: 'termOfValid' } },
        { title: '放置位置', dataIndex: 'locationId', width: '100px', scopedSlots: { customRender: 'locationId' } },
        { title: '领用状态', dataIndex: 'purOutStatus', width: '100px', scopedSlots: { customRender: 'purOutStatus' } },
        { title: '领用人', dataIndex: 'userName', width: '100px', scopedSlots: { customRender: 'userName' } },
        { title: '操作', dataIndex: 'operation', width: '100px', scopedSlots: { customRender: 'operation' } }
      ],
      modalProps: Object.assign({}, fullModalData, {
        width: '80%',
        title: '物资列表'
      })

    }
  },
  computed: {
    materialUnitCode () {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },

  created () {
    Get(resLocationApi.selectList, { pageSize: 500 }).then(result => {
      this.locationShow = result.data
    })
    Get(resdevCategoryApi.selectList, { pageSize: 500 }).then(result => {
      this.cateShow = result.data
    })
    this.initDictData()
  },

  methods: {
    handleBlur (value) {
      this.selectedRowKeys = []
      this.selectedRows = []
      const data = this.tempData
      const dataRow = this.tempDataRow
      if (data.length < value) {
        const pageSize = this.$store.getters.pageSize
        if (value > pageSize) {
          this.$message.error(`当前页面只有` + pageSize + '条数据!')
        } else {
          this.$message.error(`当前库存不足!`)
        }
        return
      }
      for (let i = 0; i < value; i++) {
        this.selectedRowKeys.push(data[i])
        this.selectedRows.push(dataRow[i])
      }
    },
    handleChange (value) {
      this.$refs.table.refresh(true)
      this.queryParam.num = null
      this.selectedRowKeys = []
      this.selectedRows = []
    },

    fetchMaterial (value) {
      this.fetching = true
      Get(resMaterialApi.list, { pageSize: 1000, materialName: value }).then(result => {
        const data = result.data.map(material => ({
          text: `${material.materialName}(${material.speType})`,
          value: material.id
        }))
        this.selectData.splice(0, this.selectData.length, ...data)
        this.fetching = false
      })
    },

    handleSubmit () {
      this.$emit('updateDataDev', this.selectedRows, this.itemRecord)
      this.selectedRows = []
      this.selectedRowKeys = []
      this.cancel()
    },

    initDictData () {
      // 根据字典Code, 初始化字典数组
      this.$store.dispatch('code/select', 'material_unit_code')
    },

    getLocation (text, record) {
      const locationInfo = this.locationShow?.filter(item => item.id.toString() === record.locationId)?.[0]
      return locationInfo?.name ?? '-'
    },

    cancel () {
      this.modalProps.visible = false
      this.$emit('cancel')
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    handleAdd (record, keyArr, cateId, userName, projId) {
      if (userName) this.queryParam.userName = userName
      this.queryParam.cateType = record.cateType
      this.queryParam.num = record.numOfUse
      if (projId) {
        this.queryParam.projId = projId.join(',')
      }
      this.selectData = []
      // 记录上级信息
      this.itemRecord = record
      this.queryParam.materialId = record.id
      this.modalProps.title = '选择物资'
      this.modalProps.visible = true
      Get(resMaterialApi.getMaterialByCateId, { cateId: cateId }).then(result => {
        const data = result.data.data.map(material => ({
          text: `${material.materialName}(${material.speType})`,
          value: material.id
        }))
        this.selectData = data
        this.fetching = false
      })
      if (keyArr && keyArr.length) {
        this.selectedRowKeys = []
        for (let i = 0; i < keyArr.length; i++) {
          this.selectedRowKeys[i] = parseInt(keyArr[i])
        }
      }
    },
    handleRecord (materialId, type) {
      this.$refs.record.init(materialId, type)
    },
    // 加载数据
    loadData (parameter) {
      return Get(resMaterialOutApi.getMaterialList, Object.assign(parameter, this.queryParam)).then(res => {
        res.data.forEach(item => {
          item.key = item.id
        })
        this.tempData = res.data.map(item => item.id)
        this.tempDataRow.splice(0, this.tempDataRow.length, ...(res.data || []))
        return res
      })
    }
  }
}
</script>
