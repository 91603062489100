<template>
  <div>
    <a-card style="margin-top: 14px">
      <a-card :bordered="false" style="max-width: 1080px; margin: 16px auto;">
        <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
          合同评审-{{ contract.contractTitle }}
        </div>
        <ContractCheck :contract.sync="contract" :auditContent.sync="auditContent" :projectList="projectList"/>
      </a-card>
    </a-card>
  </div>
</template>
<script>
import { projectApi, projContractApi } from '@/api/project'
import ContractCheck from './modules/ContractCheck'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item

export default {
  name: 'ContractCheckMgr',
  components: {
    ContractCheck,
    DetailList,
    DetailListItem
  },
  props: {
    processId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {

      editEnable: false,

      flowImage: '', // 流程图

      // 项目信息
      projectInfo: {},

      // 合同信息
      contract: {
      },
      projectList: [],

      // 评审内容
      auditContent: {
        c1: '是',
        c2: '是',
        c3: '是',
        c4: '是',
        c5: '是',
        c6: ''
      },

      btnSpinning: false

    }
  },
  methods: {
    getContract () {
      if (this.contract.id) {
        projContractApi.get({ id: this.contract.id }).then(result => {
          if (result.code === 0) {
            const contract = result.data
            this.$set(this, 'contract', contract || {})
            this.initContract()
          }
        })
      }
    },
    listProject () {
      if (this.contract.id) {
        projectApi.list({ contractId: this.contract.id, page: false }).then(res => {
          if (res.code === 0) {
            this.projectList = res.data
          }
        })
      } else {
        this.projectList = []
      }
    },
    initInfo () {
      this.btnSpinning = false
      if (this.contract.id) {
        this.getContract()
        this.listProject()
      }
    },
    initContract () {
      if (this.contract.id) {
        if (this.contract.contractAuditContent) {
          var auditContent = JSON.parse(this.contract.contractAuditContent)
          this.$set(this, 'auditContent', auditContent)
        }
      }
    }
  },
  watch: {
    processId: {
      handler (nv) {
        this.contract.id = this.processId
        this.initInfo()
      },
      immediate: true
    }
  },
  computed: {
  },
  filters: {
  },
  mounted () {
    this.contract.id = this.processId
    this.initInfo()
  }
}
</script>

<style>
</style>

<style lang="less">
  .form-steps {
    max-width: 750px;
    margin: 16px auto;
  }
</style>
