<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import UlTable, { UlTableColumn, UlTableRow } from '@/pages/common/ul-table/Table'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import { getCurrentInstance, computed, watchEffect, ref } from 'vue'
import { itemCheckDetailApi, myQualificationsApi } from '@/api/quote'
import { orderBy } from 'lodash'
import { message } from 'ant-design-vue'
import { qualityOptionDataMap } from '@/api/itemCheckDetail/itemCheckDetailConstant'

const { baseFormCard } = useFormProps()
const { proxy } = getCurrentInstance()

const props = defineProps({
  modelValue: { type: Number, required: true },
  checkGuide: { type: Object, required: false, default: undefined },
  cateId: { type: Number, required: true },
  itemId: { type: Number, required: true },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue', 'update:checkGuide'])
const checkDetailId = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const selectCheckGuide = computed({
  get() {
    return props.checkGuide
  },
  set(value) {
    emit('update:checkGuide', value)
  }
})

// 查询检测明细信息
const checkGuideDetailList = ref([])
const source = ref('')
const queryCheckGuideDetail = (cateId, itemId) => {
  const itemInfoStr = cateId + '-' + itemId
  myQualificationsApi.getCheckMethod({ cateId, itemId }).then((res) => {
    // itemCheckDetailApi.listDetail({ page: false, items: [itemInfoStr] }).then((res) => {
    if (res.code === 0) {
      source.value = res.data.source
      const data = orderBy(res.data.dataList, 'defaultFlag', 'desc')
      checkGuideDetailList.value.splice(0, checkGuideDetailList.value.length, ...(data || []))
      // 设置默认为第一个
      const defaultData = checkGuideDetailList.value.find((item) => item.id === checkDetailId.value) || checkGuideDetailList.value?.[0]
      handleSelectGuide(defaultData)
    } else {
      message.error(res.msg)
    }
  })
}

watchEffect(() => {
  queryCheckGuideDetail(props.cateId, props.itemId)
})

// 处理检测明细修改
const handleSelectGuide = (guide) => {
  checkDetailId.value = guide.id
  selectCheckGuide.value = guide
}

const handleToSelectGuide = () => {
  if (selectCheckGuide.value) {
    proxy.$router?.open({ path: '/cloud-quote/item-check-detail', query: { id: selectCheckGuide.value.id } })
  } else {
    proxy.$router?.open({ path: '/cloud-quote/item-check-detail' })
  }
}

defineExpose({
  selectCheckGuideList: () => checkGuideDetailList
})
</script>

<template>
  <a-card title="检测方法" v-bind="baseFormCard">
    <template #extra>
      <a @click="handleToSelectGuide">检测明细</a>
    </template>
    <ul-table :data-source="checkGuideDetailList" :hidden-title="true" class="CheckGuideTable">
      <template #content>
        <ul-table-row v-for="(guide, index) in checkGuideDetailList" :key="index" :class="[{ checked: checkDetailId === guide.id }]">
          <ul-table-column title="来源">
            {{ source }}
          </ul-table-column>
          <ul-table-column title="分类">
            {{ guide.cateName }}
          </ul-table-column>
          <ul-table-column title="">
            <template v-if="guide.defaultFlag === 1">
              <a-tag color="green">常用</a-tag>
            </template>
            <template v-else>
              <a-tag color="red">不常用</a-tag>
            </template>
          </ul-table-column>

          <ul-table-column title="检测标准"> {{ guide.standardName }} （{{ guide.standardCode }}）</ul-table-column>

          <ul-table-column data-index="methodName" title="检测方法">{{ guide.methodName }}</ul-table-column>

          <ul-table-column title="检测模板">
            <template v-if="guide.templateName">
              <span class="content">{{ guide.templateName }}</span>
            </template>
            <template v-else>
              <a-tag color="red">暂未配置检测原始记录模板</a-tag>
            </template>
          </ul-table-column>

          <ul-table-column title="平行样">
            <a-tag :color="qualityOptionDataMap[guide.needParallel].color">{{ qualityOptionDataMap[guide.needParallel].label }}</a-tag>
          </ul-table-column>

          <ul-table-column title="空白样">
            <a-tag :color="qualityOptionDataMap[guide.needBlank].color">{{ qualityOptionDataMap[guide.needBlank].label }}</a-tag>
          </ul-table-column>

          <ul-table-column title="加标回收">
            <a-tag :color="qualityOptionDataMap[guide.needMarkup].color">{{ qualityOptionDataMap[guide.needMarkup].label }}</a-tag>
          </ul-table-column>

          <ul-table-column title="标准曲线">
            <a-tag :color="qualityOptionDataMap[guide.needCurve].color">{{ qualityOptionDataMap[guide.needCurve].label }}</a-tag>
          </ul-table-column>

          <ul-table-column title="">
            <input
              :checked="checkDetailId === guide.id"
              :disabled="props.firstFlag === 1 || checkGuideDetailList.length === 1"
              name="checkGuide"
              type="radio"
              @change="handleSelectGuide(guide)"
            />
          </ul-table-column>
        </ul-table-row>
      </template>
    </ul-table>
  </a-card>
</template>
<style lang="less">
.CheckGuideTable {
  .title-row {
    padding: 0 0 5px 10px;
    .title {
      margin: 0;
    }
  }
}
</style>