<template>
  <a-modal :visible="visible" :title="title" @cancel="handleCancel" @ok="handleOK" :destroyOnClose="destroyOnClose">
    <a-row :span="24">
      选择行政区划：
    </a-row>
    <a-row :span="24" style="margin-top: 10px">
      <a-cascader
        style="width: 100%"
        placeholder
        allowClear
        :options="adCodeList"
        @change="adCodeOnChange"
        :loadData="adCodeLoadData"
        :fieldNames="{label: 'adName', value: 'adCode', children: 'children'}"
        changeOnSelect
      />
    </a-row>
  </a-modal>
</template>

<script>

import { adCodeApi } from '@/api/common'
import ARow from 'ant-design-vue/es/grid/Row'
export default {
  name: 'AdCodeSelect',
  components: { ARow },
  props: {
  },
  data () {
    return {
      visible: false,
      destroyOnClose: true,
      title: '行政区划',
      adCodeList: [],
      adCode: {}
    }
  },
  methods: {
    open () {
      this.title = '行政区划'
      this.listAdCode('0')
      this.visible = true
    },
    listAdCode (adPcode, adCodeObj) {
      adCodeApi.list({ adPcode: adPcode }).then(result => {
        if (result.code === 0) {
          var tempAdCodeList = result.data
          if (tempAdCodeList) {
            for (var i = 0; i < tempAdCodeList.length; i++) {
              if (tempAdCodeList[i].adType < '3') {
                tempAdCodeList[i].isLeaf = false
              }
            }
          }
          if (adCodeObj) {
            adCodeObj.children = tempAdCodeList || []
          } else {
            this.adCodeList = tempAdCodeList || []
          }
        }
        if (adCodeObj) {
          adCodeObj.loading = false
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    adCodeLoadData (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      this.listAdCode(targetOption['adCode'], targetOption)
    },
    adCodeOnChange (value, selectedOptions) {
      if (value.length > 0) {
        this.adCode = selectedOptions[selectedOptions.length - 1]
      } else {
        this.adCode = {}
      }
    },
    handleOK () { // 提交表单
      const self = this
      self.$emit('ok', self.adCode)
      self.handleCancel()
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style scoped>
</style>
