<template>
  <a-row :gutter="8">
    <a-col :span="3">
      <a-tree v-bind="reagentCateGradeTree" v-on="reagentCateGradeTree.action"/>
      <a-tree v-bind="checkCateSolutionTypeTree" v-on="checkCateSolutionTypeTree.action"/>
      <a-tree v-bind="reagentCateSolutionTypeTree" v-on="reagentCateSolutionTypeTree.action"/>
    </a-col>
    <a-col :span="21">
      <check-reagent-cate-table v-bind="tableProps">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
      </check-reagent-cate-table>
    </a-col>
  </a-row>
</template>

<script>
import CheckReagentCateTable from '@/pages/cloud-quote/check-reagent/cate/modules/table'
import { withComponentList } from '@/pages/common/mixins/list'

import { reagentCateGradeOptions, reagentCateSolutionTypeOptions } from '@/api/checkReagent/checkReagentConstant'
import { checkSolutionTypeOptions } from '@/api/checkStandardSolution/checkStandardSolutionConstant'

const commonTreeData = {
  treeData: [],
  checkable: true,
  expandedKeys: ['0'],
  checkKeys: [],
  autoExpandParent: true
}

export default {
  name: 'CateSelectTable',
  props: Object.assign({}, CheckReagentCateTable.props),
  mixins: [withComponentList],
  components: { CheckReagentCateTable },

  data () {
    return {
      categorySource: [],
      reagentCateGradeOptions,
      reagentCateSolutionTypeOptions,
      checkSolutionTypeOptions,

      queryParam: {
        grades: [],
        solutions: [],
        solutionTypes: []
      },

      reagentCateGradeTree: Object.assign({}, JSON.parse(JSON.stringify(commonTreeData)), {
        action: {
          check: (checkKeys, item) => {
            this.reagentCateGradeTree.checkKeys.splice(0, this.reagentCateGradeTree.checkKeys.length)
            this.queryParam.grades.splice(0, this.queryParam.grades.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.grades.splice(0, this.queryParam.grades.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.reagentCateGradeTree.expandedKeys.splice(0, this.reagentCateGradeTree.expandedKeys.length, ...(expandedKeys || []))
          }
        }
      }),

      reagentCateSolutionTypeTree: Object.assign({}, JSON.parse(JSON.stringify(commonTreeData)), {
        action: {
          check: (checkKeys, item) => {
            this.reagentCateSolutionTypeTree.checkKeys.splice(0, this.reagentCateSolutionTypeTree.checkKeys.length)
            this.queryParam.solutions.splice(0, this.queryParam.solutions.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.solutions.splice(0, this.queryParam.solutions.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.reagentCateSolutionTypeTree.expandedKeys.splice(0, this.reagentCateSolutionTypeTree.expandedKeys.length, ...(expandedKeys || []))
          }
        }
      }),

      checkCateSolutionTypeTree: Object.assign({}, JSON.parse(JSON.stringify(commonTreeData)), {
        action: {
          check: (checkKeys, item) => {
            this.checkCateSolutionTypeTree.checkKeys.splice(0, this.checkCateSolutionTypeTree.checkKeys.length)
            this.queryParam.solutionTypes.splice(0, this.queryParam.solutionTypes.length)
            const { checkedNodes } = item
            if (checkedNodes.length) {
              const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
              this.queryParam.solutionTypes.splice(0, this.queryParam.solutionTypes.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.checkCateSolutionTypeTree.expandedKeys.splice(0, this.checkCateSolutionTypeTree.expandedKeys.length, ...(expandedKeys || []))
          }
        }
      })

    }
  },

  mounted () {
    this.initData()
  },

  computed: {
    tableProps () {
      return Object.assign(this.customTable, this.$props)
    }
  },

  methods: {

    // 初始化数据
    initData () {
      const cateGradeData = [{ key: '-1', title: '试剂等级', children: this.reagentCateGradeOptions.map(item => ({ key: item.value, title: item.label, children: null })) }]
      const cateSolutionTypeData = [{ key: '-1', title: '试剂形状', children: this.reagentCateSolutionTypeOptions.map(item => ({ key: item.value + 0, title: item.label, children: null })) }]
      const checkSolutionTypeData = [{ key: '-1', title: '溶液类型', children: this.checkSolutionTypeOptions.map(item => ({ key: item.value, title: item.label, children: null })) }]
      this.reagentCateGradeTree.treeData.splice(0, this.reagentCateGradeTree.treeData.length, ...cateGradeData)
      this.reagentCateSolutionTypeTree.treeData.splice(0, this.reagentCateSolutionTypeTree.treeData.length, ...cateSolutionTypeData)
      this.checkCateSolutionTypeTree.treeData.splice(0, this.checkCateSolutionTypeTree.treeData.length, ...checkSolutionTypeData)
    }
  }
}
</script>

<style scoped>

</style>
