<template>
  <div class="container">
    <a-carousel autoplay effect="fade">
      <div><img src="~@/assets/images/desk_signal_1.png"/></div>
      <div><img src="~@/assets/images/desk_signal_2.png"/></div>
      <div><img src="~@/assets/images/desk_signal_3.png"/></div>
    </a-carousel>
  </div>

</template>
<script>
export default {
  name: 'UserImageCarousel'
}
</script>

<style scoped>
.container{
  width : 422px;
  height : 358px;
}
.image{
  width : 100%;
  height : 100%;
}
.image img{
  height: 100%;
}
</style>
