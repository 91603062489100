import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 质控措施选项字典枚举
export const qualityOptionEnum = {

  // 必做
  must: '1',
  // 选做
  optional: '2',
  // 不做
  not: '3'
}

//  质控措施选项选择项
export const qualityOptionOptions = [
  { label: '必做', value: qualityOptionEnum.must, color: 'green' },
  { label: '选做', value: qualityOptionEnum.optional, color: 'blue' },
  { label: '不做', value: qualityOptionEnum.not, color: 'gray' }
]

export const qualityOptionMap = tranOptionsToMap(qualityOptionOptions)
export const qualityOptionDataMap = tranOptionsToDataMap(qualityOptionOptions)
