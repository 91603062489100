import Vue from 'vue'
import {
  SIDEBAR_TYPE,
  DEFAULT_THEME,
  DEFAULT_LAYOUT_MODE,
  DEFAULT_COLOR,
  DEFAULT_PAGE_SIZE,
  DEFAULT_COLOR_WEAK,
  DEFAULT_FIXED_HEADER,
  DEFAULT_FIXED_SIDEMENU,
  DEFAULT_FIXED_HEADER_HIDDEN,
  DEFAULT_CONTENT_WIDTH_TYPE,
  DEFAULT_MULTI_TAB,
  SYS_CONFIG,
  SYS_VERSION,
  DEFAULT_AUTO_REFRESH_ROUTE
} from '@/store/mutation-types'
import { configApi } from '@/api/common'
import { cemenuApi } from '@/api/appmenu/cemenu'

const app = {
  state: {
    sidebar: true,
    device: 'desktop',
    theme: '',
    layout: '',
    contentWidth: '',
    fixedHeader: false,
    fixSiderbar: false,
    autoHideHeader: false,
    pageSize: 20,
    color: null,
    weak: false,
    multiTab: true,
    autoRefreshRoute: false,
    sys_config: {},
    sys_version: ''
  },
  mutations: {
    SYS_VERSION: (state, v) => {
      state.sys_version = v
      Vue.ls.set(SYS_VERSION, v)
    },
    SYS_CONFIG: (state, configs) => {
      state.sys_config = configs
      Vue.ls.set(SYS_CONFIG, configs)
    },
    SET_SIDEBAR_TYPE: (state, type) => {
      state.sidebar = type
      Vue.ls.set(SIDEBAR_TYPE, type)
    },
    CLOSE_SIDEBAR: state => {
      Vue.ls.set(SIDEBAR_TYPE, true)
      state.sidebar = false
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    TOGGLE_THEME: (state, theme) => {
      Vue.ls.set(DEFAULT_THEME, theme)
      state.theme = theme
    },
    TOGGLE_LAYOUT_MODE: (state, layout) => {
      Vue.ls.set(DEFAULT_LAYOUT_MODE, layout)
      state.layout = layout
    },
    TOGGLE_FIXED_HEADER: (state, fixed) => {
      Vue.ls.set(DEFAULT_FIXED_HEADER, fixed)
      state.fixedHeader = fixed
    },
    TOGGLE_FIXED_SIDERBAR: (state, fixed) => {
      Vue.ls.set(DEFAULT_FIXED_SIDEMENU, fixed)
      state.fixSiderbar = fixed
    },
    TOGGLE_FIXED_HEADER_HIDDEN: (state, show) => {
      Vue.ls.set(DEFAULT_FIXED_HEADER_HIDDEN, show)
      state.autoHideHeader = show
    },
    TOGGLE_CONTENT_WIDTH: (state, type) => {
      Vue.ls.set(DEFAULT_CONTENT_WIDTH_TYPE, type)
      state.contentWidth = type
    },
    TOGGLE_PAGE_SIZE: (state, pageSize) => {
      Vue.ls.set(DEFAULT_PAGE_SIZE, pageSize)
      state.pageSize = pageSize
    },
    TOGGLE_COLOR: (state, color) => {
      Vue.ls.set(DEFAULT_COLOR, color)
      state.color = color
    },
    TOGGLE_WEAK: (state, flag) => {
      Vue.ls.set(DEFAULT_COLOR_WEAK, flag)
      state.weak = flag
    },
    TOGGLE_MULTI_TAB: (state, bool) => {
      Vue.ls.set(DEFAULT_MULTI_TAB, bool)
      state.multiTab = bool
    },
    TOGGLE_AUTO_REFRESH_ROUTE: (state, bool) => {
      Vue.ls.set(DEFAULT_AUTO_REFRESH_ROUTE, bool)
      state.autoRefreshRoute = bool
    }
  },
  actions: {
    // 获取系统设置信息
    SysConfig({ commit }) {
      return new Promise((resolve, reject) => {
        configApi.get().then(res => {
            if (res.code === 0) {
              resolve(res.data)
            } else {
              reject(new Error(''))
            }
          }).catch(error => {
            reject(error)
          })
        const keys = {
          queryType: 4,
          pageNo: 1,
          pageSize: 1000
        }
        cemenuApi.queryListConfig(keys).then(res => {
          let obj = {}
          res.records.forEach(item => {
            obj[item.name] = item.content
          })
          commit(SYS_CONFIG, obj)
        })
      })
    },
    SysVersion({ commit }, v) {
      commit(SYS_VERSION, v)
    },
    setSidebar({ commit }, type) {
      commit('SET_SIDEBAR_TYPE', type)
    },
    CloseSidebar({ commit }) {
      commit('CLOSE_SIDEBAR')
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    ToggleTheme({ commit }, theme) {
      commit('TOGGLE_THEME', theme)
    },
    ToggleLayoutMode({ commit }, mode) {
      commit('TOGGLE_LAYOUT_MODE', mode)
    },
    ToggleFixedHeader({ commit }, fixedHeader) {
      if (!fixedHeader) {
        commit('TOGGLE_FIXED_HEADER_HIDDEN', false)
      }
      commit('TOGGLE_FIXED_HEADER', fixedHeader)
    },
    ToggleFixSiderbar({ commit }, fixSiderbar) {
      commit('TOGGLE_FIXED_SIDERBAR', fixSiderbar)
    },
    ToggleFixedHeaderHidden({ commit }, show) {
      commit('TOGGLE_FIXED_HEADER_HIDDEN', show)
    },
    ToggleContentWidth({ commit }, type) {
      commit('TOGGLE_CONTENT_WIDTH', type)
    },
    TogglePageSize({ commit }, type) {
      commit('TOGGLE_PAGE_SIZE', type)
    },
    ToggleColor({ commit }, color) {
      commit('TOGGLE_COLOR', color)
    },
    ToggleWeak({ commit }, weakFlag) {
      commit('TOGGLE_WEAK', weakFlag)
    },
    ToggleMultiTab({ commit }, bool) {
      commit('TOGGLE_MULTI_TAB', bool)
    },
    toggleAutoRefreshRoute({ commit }, bool) {
      commit('TOGGLE_AUTO_REFRESH_ROUTE', bool)
    }
  }
}

export default app
