<template>
  <a-modal
    :confirm-loading="loading"
    :destroyOnClose="true"
    :bodyStyle="{ color: '#F0F2F5' }"
    :visible="visible"
    :width="1200"
    title="应用检测明细"
    @cancel="handleCancel"
    @ok="handleSubmit"
  >
    <a-form :form="form">
      <a-card v-bind="baseCard" title="基本信息">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准方法" v-show="!view">
          <a-input disabled :value="getStandardMethodName(this.standardMethod)"></a-input>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="一级分类">
          <a-select
            v-decorator="[
              'cateType',
              { initialValue: model.cateType, rules: [{ required: true, message: '请选择一级分类!' }] },
            ]"
            :filterOption="filterOption"
            placeholder="请选择一级分类"
            showSearch
            style="width: 100%"
            @change="handleCateTypeChange"
          >
            <a-select-option v-for="m in cateTypeList" :key="m.key" :value="m.key">{{ m.title }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="二级分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-checkbox-group v-model="cateIds" style="width:100%;">
            <a-row :gutter="24">
              <a-col :span="6" v-for="m in categoryList" :key="m.id" style="margin-bottom: 5px">
                <a-checkbox :value="m.id" @change="isCheckedCate"></a-checkbox>
                <a-button size="small" style="margin-left:5px;" @click="loadItemList(m.id)">{{ m.name }}</a-button>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="因子">
          <el-transfer
            class="checkTransfer"
            v-model="itemTransfer.targetKeys"
            :data="itemTransfer.dataSource"
            @change="targetTransferChange"
            filterable
            :filter-method="filterMethod"
            filter-placeholder="请输入因子">
            <template slot-scope="{ option }">
              <el-tooltip placement="top" v-if="option.count > 0">
                <div slot="content">
                  <div style="font-weight:500;">
                    已配置标准方法：
                  </div>
                  <div>
                    <span v-for="it in option.children" :key="it" style="display:block;margin-top:2px;">{{ it.methodName + '(' + it.standardCode + ')' }}</span>
                  </div>
                </div>
                <el-badge :value="option.count" :max="99" class="item">
                  <a-button size="small">{{ option.itemName }} </a-button>
                </el-badge>
              </el-tooltip>
              <a-button size="small" v-else>{{ option.itemName }} </a-button>
            </template>
            <!-- <span slot="notFoundContent"> 暂无匹配的因子 </span> -->
          </el-transfer>
        </a-form-item>

      </a-card>
    </a-form>
  </a-modal>
</template>

<script>
import {
  Get,
  itemApi,
  itemCategoryApi,
  itemCheckDetailApi,
  jcMethodApi,
  Post
} from '@/api/quote'
import ATextarea from 'ant-design-vue/es/input/TextArea'
import SelectItem from '@/pages/common/SelectItem'
import ARow from 'ant-design-vue/es/grid/Row'
import ACol from 'ant-design-vue/es/grid/Col'
import MethodForm from '@/pages/common/method/MethodForm'
import { dictApi } from '@/api/system'

export default {
  components: { ACol, ARow, ATextarea, SelectItem, MethodForm },
  data () {
    return {
      baseCard: {
        type: 'inner',
        size: 'small'
      },

      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 16
      },
      model: {},
      visible: false,
      loading: false,
      isEdit: false,
      form: this.$form.createForm(this),
      type: 0,
      defaultChecked: false,
      cateTypeList: [],

      itemList: [],
      standardMethodList: [],
      standardMethod: {},
      allCategoryList: [],
      copyCategoryList: [],
      categoryList: [],
      itemCodeList: [],
      itemTransfer: {
        dataSource: [],
        showSearch: true,
        lazy: true,
        listStyle: { width: '350px', height: '400px' },
        targetKeys: [],
        checkedKeys: [],
        change: this.targetTransferChange,
        selectChange: this.handleSelectChange
      },
      selectedKeys: [],
      cateIds: [],
      cateId: ''
    }
  },
  props: {
    view: { type: Boolean, default: false }
  },
  created () {
    this.loadCateType()
    this.loadAllCategory()
  },
  methods: {
    isCheckedCate (e) {
      const id = e.target.value
      const checked = e.target.checked
      const cateType = this.form.getFieldValue('cateType')
      const cateTypeIndex = this.itemCodeList.findIndex(item => item.cateType == cateType)
      if (cateTypeIndex != -1) {
        const cateList = this.itemCodeList[cateTypeIndex].cateList
        if (cateList.length > 0) {
          const cateIndex = this.itemCodeList[cateTypeIndex].cateList.findIndex(item => item.cateId == id)
          if (cateIndex != -1) {
            this.itemCodeList[cateTypeIndex].cateList[cateIndex].isChecked = checked
          } else {
            this.itemCodeList[cateTypeIndex].cateList.push({
              cateId: id,
              isChecked: checked,
              itemIds: []
            })
          }
        } else {
          this.itemCodeList[cateTypeIndex].cateList.push({
            cateId: id,
            isChecked: checked,
            itemIds: []
          })
        }
      }
    },
    targetTransferChange (targetKeys, direction, moveKeys) {
      this.itemTransfer.targetKeys = targetKeys
      this.itemCodeList.forEach(item1 => {
        if (item1.cateType == this.form.getFieldValue('cateType')) {
          item1.cateList.forEach(item2 => {
            if (item2.cateId == this.cateId) {
              item2.itemIds = targetKeys
            }
          })
        }
      })
    },
    handleSelectChange (sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys]
    },

    loadAllCategory () {
      Post(itemCategoryApi.getAll).then((result) => {
        this.allCategoryList = result.data
      })
    },
    loadCategory (v) {
      this.categoryList = this.allCategoryList.filter((r) => r.cateType === v)
      console.log(this.categoryList, v)
    },
    loadCateType () {
      dictApi.getItems({ dictCode: 'cate_type' }).then((res) => {
        if (res.code === 0) {
          const d = res.data
          const temp = []
          for (let i = 0; i < d.length; i++) {
            temp.push({ title: d[i].itemText, key: d[i].itemValue })
          }
          this.cateTypeList = temp
        }
      })
    },
    loadStandMethodList () {
      Get(jcMethodApi.listByModel, {}).then((result) => {
        if (result.code === 0) {
          this.standardMethodList = result.data
          this.standardMethod = result.data.find(
            (item) => item.standardId == this.model.standardId && item.methodId == this.model.methodId
          )
        }
      })
    },
    validateType (rule, value, callback) {
      const form = this.form
      const cateType = form.getFieldValue('cateType')
      const itemId = form.getFieldValue('itemId')
      if (cateType && itemId) {
        form.validateFields(['itemId'], { force: true })
      }
      callback()
    },
    validateExist (rule, value, callback) {
      const form = this.form
      const standardMethodId = form.getFieldValue('standardMethodId')
      const cateId = form.getFieldValue('cateId')
      const itemId = form.getFieldValue('itemId')
      if (cateId && itemId && (standardMethodId || this.view)) {
        this.validateItemId(callback)
      } else {
        callback()
      }
    },

    validateNumber (rule, value, callback) {
      const reg = /^\d+(?=\.{0,1}\d+$|$)/
      if (!value || reg.test(value)) {
        callback()
      } else {
        // eslint-disable-next-line standard/no-callback-literal
        callback('检出限必须为数字')
      }
    },

    validateItemId (callback) {
      const form = this.form
      const cateId = form.getFieldValue('cateId')
      const itemId = form.getFieldValue('itemId')
      const standardMethodId = form.getFieldValue('standardMethodId')
      const standardId = standardMethodId.split('_')[0]
      const methodId = standardMethodId.split('_')[1]
      itemCheckDetailApi
        .list({ cateId: cateId, itemId: itemId, standardId: standardId, methodId: methodId })
        .then((res) => {
          const d = res.data
          if (this.model.id) {
            if (d.filter((r) => r.id !== this.model.id).length > 0) {
              // 编辑的属性名跟其他属性重复
              // eslint-disable-next-line standard/no-callback-literal
              callback('已维护该采样明细')
            } else {
              callback()
            }
          } else if (d.length > 0) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('已维护该采样明细')
          } else {
            callback()
          }
        })
    },
    getStandardMethodName (record) {
      if (record.standardName.trim().indexOf(record.standardCode.trim()) !== -1) {
        return record.standardName + '-' + record.methodName
      } else {
        return record.standardCode + '(' + record.standardName + ')-' + record.methodName
      }
    },
    loadItemList (value) {
      this.cateId = value
      return itemApi.list({ cateIds: value, pageSize: 1000 }).then((res) => {
        this.itemList = res.data
        const itemIds = []
        res.data.forEach((item) => {
          itemIds.push(item.id)
        })
        this.itemShow(value, itemIds)
        return res.data
      })
    },
    itemShow (cateId, itemIds) {
      this.itemTransfer.dataSource = []
      this.itemTransfer.targetKeys = []
      const cateType = this.form.getFieldValue('cateType')
      itemCheckDetailApi.itemShow({ cateType, cateId, itemIds }).then((res) => {
        res.data.forEach((item) => {
          item.key = item.itemId
          item.label = item.itemName
        })
        // const result = (res.data || []).map(item => ({ key: item.id, title: item.name }))
        this.itemTransfer.dataSource = res.data
        // const cateType = this.form.getFieldValue('cateType')
        const cateTypeIndex = this.itemCodeList.findIndex((item) => item.cateType == cateType)
        const itemIndex = res.data.findIndex((item) => item.itemId == this.model.itemId)
        if (cateTypeIndex != -1) {
          const cateList = this.itemCodeList[cateTypeIndex].cateList
          if (cateList.length == 0 || cateList.findIndex((item) => item.cateId == cateId) == -1) {
            const cateItem = {
              cateId: cateId,
              isChecked: false,
              itemIds: []
            }

            if (itemIndex != -1) {
              this.itemTransfer.targetKeys = [this.model.itemId]
              cateItem.itemIds = [this.model.itemId]
            }
            this.itemCodeList[cateTypeIndex].cateList.push(cateItem)
          } else {
            const cateIndex = cateList.findIndex((item) => item.cateId == cateId)
            if (this.itemCodeList[cateTypeIndex].cateList[cateIndex].itemIds.length > 0) {
              this.itemTransfer.targetKeys = this.itemCodeList[cateTypeIndex].cateList[cateIndex].itemIds
            } else {
              this.itemTransfer.targetKeys = [this.model.itemId]
              this.itemCodeList[cateTypeIndex].cateList[cateIndex].itemIds = [this.model.itemId]
            }
          }
        } else {
          if (itemIndex != -1) {
            this.itemTransfer.targetKeys = [this.model.itemId]
          }
        }
      })
    },
    handleCategoryChange (v) {
      this.itemList = []
      this.form.setFieldsValue({ itemId: null })
      this.loadItemList(v)
    },
    loadCopyCategory (cateIds) {
      this.copyCategoryList = this.allCategoryList.filter((r) => cateIds.includes(r.id))
    },
    async handleItemChange (v) {
      const self = this
      let itemList = self.itemList
      if (self.itemList.length === 0 && self.model.cateId) {
        itemList = await self.loadItemList(self.model.cateId)
      }
      this.copyCategoryList = []
      const cateId = self.form.getFieldValue('cateId')
      const d = itemList.filter((r) => r.id === v)
      if (d.length > 0) {
        const cateIds = d[0].cateIds.replace(cateId, '')
        if (cateIds.length !== 0) {
          this.loadCopyCategory(cateIds)
        }
      }
    },
    handleCateTypeChange (v) {
      this.itemList = []
      this.categoryList = []
      this.form.setFieldsValue({ itemId: null, cateId: null })
      const cateTypeIndex = this.itemCodeList.findIndex(item => item.cateType == v)
      if (cateTypeIndex == -1) {
        this.itemCodeList.push({
          cateType: v,
          cateList: []
        })
        this.cateIds = []
        this.itemTransfer.dataSource = []
        this.itemTransfer.targetKeys = []
      } else {
        this.itemCodeList[cateTypeIndex].cateList.forEach(item => {
          this.cateIds.push(item.cateId)
        })
      }
      this.loadCategory(v)
    },
    handleStandardMethodChange (v) {
      // const array = v.toString().split(',')
    },

    editData (record) {
      record.edited = true
      this.$forceUpdate()
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    filterMethod (query, item) {
      return item.itemName.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    add () {
      this.edit({})
    },
    edit (row) {
      this.isEdit = false
      this.visible = true
      this.itemList = []
      this.model = {}
      this.model = Object.assign({}, row)
      this.itemCodeList = []
      this.itemCodeList.push({
        cateType: this.model.cateType,
        cateList: [
          {
            cateId: this.model.cateId,
            isChecked: true,
            itemIds: [this.model.itemId]
          }
        ]
      })
      this.cateIds = [this.model.cateId]
      this.loadStandMethodList()
      if (row.id !== undefined) {
        this.isEdit = true
      }
    },
    saveOrUpdate (formData) {
      this.loading = true
      itemCheckDetailApi
        .copy(formData)
        .then((res) => {
          this.model = {}
          this.visible = false
          this.$emit('ok', 'ref2_' + formData.standardId + '_' + formData.methodId)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          const self = this
          const formData = Object.assign(values)
          const data = []
          this.itemCodeList.forEach(item1 => {
            item1.cateList.forEach(item2 => {
              if (item2.cateId && item2.isChecked && item2.itemIds) {
                item2.itemIds.forEach(item3 => {
                  const obj = {
                    ...this.model,
                    standardId: this.model.standardId,
                    methodId: this.model.methodId,
                    cateType: item1.cateType,
                    cateId: item2.cateId,
                    itemId: item3
                  }
                  delete obj.id
                  delete obj.key
                  data.push(obj)
                })
              }
            })
          })
          if (data.length == 0) {
            this.$message.error('未选择因子，请先选择因子')
            return
          }
          // console.log(this.itemCodeList, data)
          self.saveOrUpdate(data)
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.model = {}
    }
  },
  watch: {
    'model.cateType': {
      handler (newValue, oldValue) {
        if (newValue && newValue !== '') {
          this.handleCateTypeChange(newValue)
        }
      },
      immediate: true
    },
    'model.cateId': {
      handler (newValue, oldValue) {
        if (newValue) {
          this.handleCategoryChange(newValue)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang ="less" scope>
.control-item {
  display: inline-flex;
  justify-content: space-between;
  justify-items: center;
  gap: 35px;
}
</style>
<style lang="less">
.checkTransfer {
  .el-transfer-panel {
    width: 340px;
  }

  .el-transfer-panel__body {
    width: 340px;
    height: 400px;
  }

  .el-transfer-panel__list {
    width: 340px;
    height: 400px;
  }
  .el-transfer__buttons {
    padding: 0 10px;
    .el-button {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 28px;
      height: 24px;
      padding: 0;
    }
  }
  .item {
    .el-badge__content.is-fixed {
      font-size: 20px;
      height: 22px;
      line-height: 22px;
      top: -4px;
      right: -15px;
      transform: scale(0.6);
    }
  }
}
</style>
