<template>
  <div class="check-task-query-search">
    <div class="header">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="项目编号">
              <a-input v-model="queryParam.projNo" placeholder="项目编号查询" @pressEnter="queryProjectSample"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="actionColProps">
            <a-button-group>
              <a-button icon="search" type="primary" @click="queryProjectSample">查询</a-button>
              <a-button icon="table" @click="showDetail">详细</a-button>
              <a-button icon="reload" @click="()=>{queryParam.projNo=null;queryProjectSample();}">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>

      </a-form>
      <div class="check-wrapper">
        <div v-for="(projInfo,key) of checkedProjectMap" :key="key" class="checked">
          <a-tag color="blue">{{ projInfo.projNo }}</a-tag>
          ：{{ projInfo.length }}
          <a @click="()=>{$delete(checkedProjectMap,key)}">
            <a-icon type="delete"></a-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="wrapper">
        <div v-for="(project,index) in projectList" :key="`${project.projId}_${index}`" :class="['card',{'checked':checkedProjectIds.includes(project.projId)}]">
          <div class="content">
            <div class="left" :class="project.sampleSource==='1'?'':'green'"><span>{{ project.sampleSource === '1' ? '采样' : '送样' }}</span></div>
            <div class="right">
              <div>
                <div class="title"> {{ project.projNo }}</div>
              </div>
              <div class="detail">
                <div class="item">
                  <span class="item-title">项目负责人</span>
                  <span class="item-content"> {{ project.projLeaderName }}</span>
                </div>
                <div class="item">
                  <span class="item-title">检测负责人</span>
                  <span class="item-content"> {{ project.detectionLeaderName }}</span>
                </div>
                <div class="item">
                  <span class="item-title">样品总数</span>
                  <span class="item-content"> {{ project.sampleSum || 0 }}</span>
                </div>

                <div class="item">
                  <span class="item-title">任务总数</span>
                  <span class="item-content"> {{ project.taskSum || 0 }}</span>
                </div>

                <div class="item">
                  <span class="item-title">任务进度</span>
                  <span class="item-content">
                    <a-tooltip>
                      <template #title>
                        <span>待开始：{{ project.pendingNum }}</span> <br/>
                        <span>进行中：{{ project.processNum }}</span> <br/>
                        <span>已完成：{{ project.doneNum }}</span>
                      </template>
                      <span class="red">{{ project.pendingNum || 0 }}</span>
                      <span class="mar-h5">/</span>
                      <span class="orange">{{ project.processNum || 0 }}</span>
                      <span class="mar-h5">/</span>
                      <span class="green"> {{ project.doneNum || 0 }}</span>
                    </a-tooltip>
                  </span>
                </div>

                <div class="item">
                  <span class="item-title">数据审查</span>
                  <span class="item-content">
                    <a-tooltip>
                      <template #title>
                        <span>数据存疑：{{ project.doubtNum }}</span> <br/>
                        <span>审核通过：{{ project.passNum }}</span>
                      </template>
                      <span class="red">{{ project.doubtNum || 0 }}</span>
                      <span class="mar-h5">/</span>
                      <span class="green"> {{ project.passNum || 0 }}</span>
                    </a-tooltip>
                  </span>
                </div>
              </div>

            </div>
          </div>
          <div class="check-wrapper">
            <a-checkbox :checked="checkedProjectIds.includes(project.projId)" :value="project.projId" @change="handleProjectChecked($event,project)"></a-checkbox>
          </div>
          <div class="operation">
            <div class="prompt">
              <template v-if="checkedProjectIds.includes(project.projId)">
                已选： {{ checkedProjectMap[project.projId].length }}
              </template>
            </div>
            <div class="action">
              <a @click="detailModal.action.show(project)">
                <a-icon type="setting"></a-icon>
              </a>
            </div>
          </div>

        </div>
      </div>
      <div class="page">
        <a-pagination v-bind="projectPagination" v-on="projectPagination"/>
      </div>
    </div>

    <!-- 项目详细信息-->
    <a-modal v-if="detailModal.visible" v-bind="detailModal" v-on="detailModal.action">
      <detail v-bind="detailModal.data" v-on="detailModal.dataAction"></detail>
    </a-modal>
  </div>

</template>

<script>
import { fullModalData } from '@/pages/common/mixins/modal/full'
import { search } from '@/pages/common/mixins/list'

import { checkTaskApi } from '@/api/check/checkTaskApi'
import { needLabTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

import Detail from '@/pages/cloud-quote/check-task/modules/quickSearch/Detail'

export default {
  mixins: [search],

  components: { Detail },

  data () {
    return {
      projectList: [],
      checkedProjectMap: {},
      searchProjectList: [],
      queryParam: {
        projNo: null,
        sampleTypes: needLabTypeEnum
      },

      currentColSize: 3,
      actionColProps: { xxl: 12 },

      detailModal: Object.assign({}, fullModalData, {
        title: null,
        ref: 'detailModal',

        data: {
          ref: 'detail',
          projId: null,
          projNo: null,
          projName: null
        },

        action: {
          show: (projInfo) => {
            const { projId, projNo, projName } = projInfo
            if (!projId) {
              this.$message.error(`无效的项目ID,请确认${projId}`)
              return false
            }
            this.detailModal.title = `${projName}【${projNo}】详情`
            Object.assign(this.detailModal.data, { projId, projNo, projName })
            this.detailModal.visible = true
          },
          cancel: () => { this.detailModal.visible = false },
          ok: () => {
            const { projId, projNo, projName } = this.detailModal.data
            const checkSampleList = this.$refs[this.detailModal.data.ref].getCheckSampleList()
            const projCheck = { projId, projNo, projName, type: 'detail', list: checkSampleList, length: checkSampleList.length }
            this.$set(this.checkedProjectMap, projId, projCheck)
            this.detailModal.action.cancel()
          }
        },

        dataAction: {
          success: (res) => {
          }
        }
      }),

      // 项目视图分页
      projectPagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        total: 0,
        pageSize: 10,
        size: 'small',
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showSizeChange: (current, pageSize) => {
          this.projectPagination.pageSize = pageSize
          this.$nextTick(() => {
            this.queryProjectSample()
          })
        },
        change: (pageNo) => {
          this.projectPagination.current = pageNo
          this.$nextTick(() => {
            this.queryProjectSample()
          })
        }
      }
    }
  },
  computed: {
    checkedProjectIds () {
      return Object.values(this.checkedProjectMap)?.map(item => item.projId)
    }
  },

  mounted () {
    this.initData()
  },

  methods: {

    // 显示详情
    showDetail () {
      const checkedProjectList = Object.values(this.checkedProjectMap)

      const detailCheckProjectList = checkedProjectList.filter(item => item.type === 'detail')
      const allCheckProjectList = checkedProjectList.filter(item => item.type === 'all')
      if (detailCheckProjectList.length || allCheckProjectList.length) {
        const sampleIds = detailCheckProjectList.reduce((acc, item) => {
          acc.push.apply(acc, item.list)
          return acc
        }, [])?.map(item => item.id)
        const projIds = allCheckProjectList.map(item => item.projId)
        this.$emit('toDetail', { sampleIds, projIds })
      } else {
        this.$emit('toDetail', null)
      }
    },

    // 处理项目选中事件
    handleProjectChecked (event, projInfo) {
      const { target: { checked } } = event
      const { projId, projNo, projName } = projInfo

      if (checked) {
        const projCheck = { projId, projNo, projName, type: 'all', length: projInfo.sampleSum }
        this.$set(this.checkedProjectMap, projId, projCheck)
      } else {
        this.$delete(this.checkedProjectMap, projId)
      }
    },

    initData () {
      this.queryProjectSample()
    },

    // 查询项目样品信息
    queryProjectSample () {
      checkTaskApi.projectList(Object.assign({
        pageNo: this.projectPagination.current,
        pageSize: this.projectPagination.pageSize
      }, this.queryParam)).then(res => {
        Object.assign(this.projectPagination, {
          current: res.pageNo,
          total: res.totalCount,
          pageSize: res.pageSize
        })
        this.projectList.splice(0, this.projectList.length, ...res.data)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "index";
</style>
