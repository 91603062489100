<template>
    <a-spin :spinning="confirmLoading">
      <a-form :form="form" v-if="isRouterAlive">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="文档标题">
          <a-input placeholder="文档标题" :disabled="checkable" v-decorator="[ 'title', validatorRules.title]" />
        </a-form-item>
        
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          v-decorator="[ 'type', validatorRules.type]"
          label="文档类型">
          <template>
            <a-select   style="width: 100%"
                       placeholder="请选择文档类型" :disabled="checkable"  v-decorator="[ 'type', validatorRules.type]">
              <a-select-option  v-for="v in typeList" :key="v.id">{{ v.name }}</a-select-option>
            </a-select>
          </template>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="接收人员">
          <select-user-by-dept ref="deptUserModal" :disabled="false" :triggerChange="true" @change="userChange"/>
        </a-form-item>
        <a-form-item
          label="附件"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <template v-if="checkable">
            <FileDisplayView :fileArray="model.files"></FileDisplayView>
          </template>
          <template v-else>
            <a-upload
              accept="image/*,.pdf,.doc,.docx"
              :action="uploadUrl"
              listType="text"
              :fileList="fileList"
              :headers="tokenHeader"
              class="upload-list-inline"
              :remove="handleRemove"
              @change="handleChange"
            >
              <a-button v-if="fileList.length < 3">
                <a-icon type="upload" /> 点击上传
              </a-button>
            </a-upload>
          </template>

        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="描述信息">
          <a-textarea :rows="5" placeholder="..." v-decorator="[ 'remark', {} ]" :disabled="checkable"/>
        </a-form-item>

      </a-form>
    </a-spin>
</template>

<script>
  import pick from 'lodash.pick'
  import moment from 'moment';
  import { sysCommonApi } from '@/api/common'
  import {documentApi,Get, Post, Del } from '@/api/quote'
  import AFormItem from "ant-design-vue/es/form/FormItem";
  import { dictItemApi } from '@/api/system'
  import SelectUserByDept from '@/pages/common/SelectUserByDept'
  import FileDisplayView from '@/pages/cloud-quote/file/modules/FileDisplayView'

  export default {
    name: 'DocumentCheckForm',
    props:{
      checkable: {//是否审核表单操作
        type: Boolean,
        default: true
      },
      documentId: {
        type:Number,
        default: null
      }
    },
    components: {
      AFormItem,
      SelectUserByDept,
      FileDisplayView},
    data () {
      return {
        userList:[],
        tokenHeader: { 'Access-Token': this.$store.getters.token },
        uploadUrl: sysCommonApi.uploadFile,
        viewUrl: sysCommonApi.view,
        fileList: [],
        fileAppendix:[],
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        },
        isRouterAlive: true,
        typeList:[],
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        },
        confirmLoading: false,
        form: this.$form.createForm(this),
        validatorRules: {
          title: { rules: [{ required: true, message: '请输入文档标题' }] },
          type: { rules: [{ required: true, message: '请选择文档类型!' }] }
        }
      }
    },
    created () {
      Get(dictItemApi.list, {pageSize:500,dictCode:'document_type'}).then(res => {
        const d = res.data
        for (let i = 0; i < d.length; i++) {
          this.typeList.push({id:d[i].itemValue,name:d[i].itemText})
        }
      })
    },
    methods: {
      loadDocument(id){
        if(id!=null){
          Post(documentApi.documentInfos, {documentId:id}).then(res => {
            const d = res.data
            this.model = Object.assign({}, d)
            this.reload()
            this.userList = d.userList

          })
        }
      },
      userChange (userList){
        this.userList = userList
      },
      handleRemove (info) {
        this.fileAppendix = this.fileAppendix.filter(file=>file.uid!=info.uid)
        this.fileList = this.fileList.filter(r=>r.uid!=info.uid)
      },
      handleChange (info) {
        this.fileList = info.fileList
        if (info.file.status === 'done') {
          if (info.file.response.code === 0) {
            this.$message.success(`${info.file.name} 文件上传成功`)
            const fileUrl = info.file.response.data.fileUrl
            const fileName = info.file.response.data.fileName
            info.file.thumbUrl = this.viewUrl + fileUrl
            info.file.url = this.viewUrl + fileName
            this.fileAppendix.push({uid:info.file.uid,fileUrl:fileUrl,fileName:fileName})
          } else {
            this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
          }
        } else if (info.file.status === 'error') {
          this.$message.error(`文件上传失败: ${info.file.msg} `)
        }
      },
      reload () {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
          setTimeout(()=>{
            this.form.setFieldsValue(pick(this.model,'title', 'type','remark'))
            if(this.userList.length>0){
              this.$refs.deptUserModal.setUserList(this.userList)
            }

          },0)
        })
      },
      moment,
      getUserStr(){
        let userIds = ''
        const userList = this.userList
        if(userList.length==0){
          return
        }
        for(let i=0;i<userList.length;i++){
          if(i==userList.length-1){
            userIds = userIds+ userList[i].userId
          }else{
            userIds = userIds+ userList[i].userId+","
          }
        }
        return userIds
      },
      handleSubmit(){
        this.form.validateFields((err, values) => {
          if (!err) {
              this.confirmLoading = true
              const formData = Object.assign(this.model, values)
              if(formData.id==undefined){
                //添加表单操作
                formData.process = '1'
              }
              formData.fileListStr = JSON.stringify(this.fileAppendix)
              formData.userIds = this.getUserStr()
              Post(formData.id!=undefined?documentApi.edit:documentApi.add, formData).then(res => {
                if (res.code === 0) {
                  that.confirmLoading = false
                  this.$message.success(res.msg)
                } else {
                  this.$message.error(res.msg)
                }
              })
            }


        })
      }
    },
    watch:{
      documentId:{
        handler(newVal, oldVal) {
          this.loadDocument(newVal)
        },
        immediate: true
      },
    }
  }
</script>

<style scoped>

</style>
