<template>
  <div>
    <a-input-search
      :class="clazz"
      v-model="selectDeparts"
      :placeholder="placeholder"
      disabled
      @search="onSearchDept">
      <a-button slot="enterButton" :disabled="disabled">选择</a-button>
    </a-input-search>

    <depart-window v-if="SearchStaffModal.visible" v-bind="SearchStaffModal" v-on="SearchStaffModal"></depart-window><!-- ref="departWindow" @ok="change"-->
  </div>
</template>

<script>
import departWindow from '@/pages/cloud-system/modules/DepartWindow'

export default {
  name: 'SearchDeptEdit',
  components: { departWindow },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    clazz: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: '点击右侧按钮选择部门'
    },

    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    enterDeptId: {
      type: String,
      default: ''
    },

    enterDept: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      checkedDeparts: [],
      selectedDepartKeys: [],
      selectedDepartKeysLeader: [],

      selectDeparts: '',

      SearchStaffModal: {
        visible: false,
        ref: 'departWindow',
        ok: (data) => { this.change(data) },
        show: () => { this.SearchStaffModal.visible = true },
        cancel: () => { this.SearchStaffModal.visible = false }
      }
    }
  },

  mounted () {
    // this.selectDeparts = this.value
    this.selectDeparts = this.enterDept
  },

  methods: {

    change (data) {
      var selectedDepartKeys = []
      var selectedDepartKeysLeader = []
      var checkedDepartNames = []
      for (let i = 0; i < data.checkedDeparts.length; i++) {
        selectedDepartKeys.push(data.checkedDeparts[i].id)
        selectedDepartKeysLeader.push(data.checkedDeparts[i].leader)
        checkedDepartNames.push(data.checkedDeparts[i].title)
      }
      this.selectedDepartKeys = selectedDepartKeys
      this.selectedDepartKeysLeader = selectedDepartKeysLeader
      this.selectDeparts = checkedDepartNames.join(',')

      console.log('data===', data.checkedDeparts)
      this.$emit('change', data.checkedDeparts)
    },

    // 通过组织机构筛选选择用户
    onSearchDept () {
      this.SearchStaffModal.show()
      this.$nextTick(() => {
        this.$refs[this.SearchStaffModal.ref].show(this.checkedDeparts)
      })
    }
  }
}
</script>

<style scoped>

</style>
