import { render, staticRenderFns } from "./BusinessForm.vue?vue&type=template&id=2591e810&scoped=true&"
import script from "./BusinessForm.vue?vue&type=script&lang=js&"
export * from "./BusinessForm.vue?vue&type=script&lang=js&"
import style0 from "./BusinessForm.vue?vue&type=style&index=0&id=2591e810&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2591e810",
  null
  
)

export default component.exports