<template>
  <div>
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="方法名">
        <a-input placeholder="请输入方法名" v-decorator="['name', {rules: [{ required: true, message: '请输入方法名!' },{ validator: validateName }]}]" />
      </a-form-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="描述信息">
        <a-textarea :rows="5" placeholder="..." v-decorator="['description', {rules: [{}]}]" />
      </a-form-item>

    </a-form>
  </div>
</template>

<script>
import {resdevCategoryApi,jcMethodApi, standardItemDevApi, Get, Post, Del } from '@/api/quote'
import { dictApi } from '@/api/system'
export default {
  name: 'MethodAdd',
  components: {
  },
  props: {
    standardSelectFlag: {
      type: Boolean,//后面根据此标记来确定是否显示标准选择组件
      default: false
    },
  },
  data () {
    return {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 20
      },
      model:{},
      form: this.$form.createForm(this),
    }
  },
  methods: {
    validateName (rule, value, callback) {
      jcMethodApi.checkName({ name: value }).then((res) => {
        if (res === false) {
          callback()
        } else if (res === true) {
          // eslint-disable-next-line standard/no-callback-literal
          callback('方法名已存在')
        } else {
          callback(res.msg)
        }
      })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const formData = Object.assign(values)
          Post(jcMethodApi.add, formData).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.$emit("ok",res.data)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })

    },

  },

  created() {
  }
}
</script>

<style scoped>

</style>
