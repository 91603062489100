<template>
  <div class="plan">
    <a-card v-bind="projectInfoCard">
      <template v-if="projectInfo.baseInfo">
        <detail-list :col="3" size="small">
          <detail-list-item term="项目名称">【{{ projectInfo.baseInfo.projNo }}】{{ projectInfo.baseInfo.projName }}</detail-list-item>
          <detail-list-item term="负责人">{{ projectInfo.baseInfo.projContact | nullFilter }}</detail-list-item>
          <detail-list-item term="联系电话">{{ projectInfo.baseInfo.projContactPhone | nullFilter }}</detail-list-item>
          <detail-list-item term="预计时间">{{ projectInfo.baseInfo.projScheduledStart }}-{{ projectInfo.baseInfo.projScheduledEnd }}</detail-list-item>
          <detail-list-item term="当前进度">
            <a-tag color="blue">{{ projProgress[projectInfo.baseInfo.projProgress] }}</a-tag>
          </detail-list-item>
          <detail-list-item term="检测内容">{{ projectInfo.baseInfo.cateList.map(item => item.cateName).join('，') }}</detail-list-item>
          <detail-list-item term="项目描述">
            共计 <span class="focus-num green">{{ sampleStat.siteCount }}</span>个点位，
            <span class="focus-num green">{{ sampleStat.cateCount }} </span>个分类，
            预计需要采集 <span class="focus-num green">{{ sampleStat.count }}</span> 个样品。
            <div :style="{marginLeft:'-30px'}">
              <span :key="index" :style="{padding:'0 30px',borderRight:'1px dashed #999'}" v-for="(item,index) in sampleStat.options">
                {{ item.name }}：
                <span class="focus-num green">
                  {{ sampleStat.cateDetailCount && sampleStat.cateDetailCount[item.id] && sampleStat.cateDetailCount[item.id].count || '-' }}
                </span> 个
              </span>
            </div>
          </detail-list-item>

        </detail-list>
      </template>
    </a-card>

    <a-card v-bind="personLinkCard">
      <template #extra>
        <a-checkbox @change="checkedValue=>handleLinkDataChange(checkedValue,linkData.person,personLinkStepDataMap)">全选</a-checkbox>
      </template>
      <a-checkbox-group v-model="linkData.person">
        <a-checkbox :key="item.value" :style="{color:item.color}" :value="item.value" v-for="item in Object.values(personLinkStepDataMap)">{{ item.label }}</a-checkbox>
      </a-checkbox-group>
    </a-card>

    <a-card v-bind="deviceLinkCard">
      <template #extra>
        <a-checkbox @change="checkedValue=>handleLinkDataChange(checkedValue,linkData.device,deviceLinkStepDataMap)">全选</a-checkbox>
      </template>
      <a-checkbox-group v-model="linkData.device">
        <a-checkbox :key="item.value" :style="{color:item.color}" :value="item.value" v-for="item in Object.values(deviceLinkStepDataMap)">{{ item.label }}</a-checkbox>
      </a-checkbox-group>
    </a-card>

    <a-card v-bind="sampleLinkCard">
      <template #extra>
        <a-checkbox :disabled="planInfo&&planInfo.sampleStatus===qualityControlLinkStatusEnum.applyed" @change="checkedValue=>handleLinkDataChange(checkedValue,linkData.sample,sampleLinkStepDataMap)">全选</a-checkbox>
      </template>
      <a-checkbox-group v-model="linkData.sample">
        <a-checkbox :disabled="planInfo&&planInfo.sampleStatus===qualityControlLinkStatusEnum.applyed" :key="item.value" :style="{color:item.color}" :value="item.value" v-for="item in Object.values(sampleLinkStepDataMap)">{{ item.label }}</a-checkbox>
      </a-checkbox-group>
    </a-card>

    <a-card v-bind="checkLinkCard">
      <template #extra>
        <a-checkbox :disabled="planInfo&&planInfo.checkStatus===qualityControlLinkStatusEnum.applyed" @change="checkedValue=>handleLinkDataChange(checkedValue,linkData.check,checkLinkStepDataMap)">全选</a-checkbox>
      </template>
      <a-checkbox-group v-model="linkData.check">
        <a-checkbox :disabled="planInfo&&planInfo.checkStatus===qualityControlLinkStatusEnum.applyed" :key="item.value" :style="{color:item.color}" :value="item.value" v-for="item in Object.values(checkLinkStepDataMap)">{{ item.label }}</a-checkbox>
      </a-checkbox-group>
    </a-card>

    <a-card v-bind="envLinkCard">
      <template #extra>
        <a-checkbox @change="checkedValue=>handleLinkDataChange(checkedValue,linkData.env,envLinkStepDataMap)">全选</a-checkbox>
      </template>
      <a-checkbox-group v-model="linkData.env">
        <a-checkbox :key="item.value" :style="{color:item.color}" :value="item.value" v-for="item in Object.values(envLinkStepDataMap)">{{ item.label }}</a-checkbox>
      </a-checkbox-group>
    </a-card>
  </div>
</template>

<script>
import { checkLinkStepDataMap, deviceLinkStepDataMap, envLinkStepDataMap, personLinkStepDataMap, sampleLinkStepDataMap } from '@/api/quality/qualityControlLinkConstant'
import { qualityControlLinkStatusEnum } from '@/api/quality/qualityControlConstant'

import { qualityControlApi } from '@/api/quality/qualityControlApi'
import { projectApi, projTaskItemApi } from '@/api/project'
import DetailList from '@/components/tools/DetailList'
import { jcListApi } from '@/api/quote'
import { countBy, maxBy, sumBy } from 'lodash'

const DetailListItem = DetailList.Item

const baseCard = {
  type: 'inner',
  size: 'small',
  style: { marginBottom: '24px' }
}

export default {

  components: { DetailList, DetailListItem },

  props: {
    projId: {
      type: Number,
      required: true
    },

    id: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data () {
    return {

      personLinkStepDataMap,
      deviceLinkStepDataMap,
      sampleLinkStepDataMap,
      checkLinkStepDataMap,
      envLinkStepDataMap,
      qualityControlLinkStatusEnum,

      projectInfo: {
        baseInfo: null
      },

      sampleStat: {
        count: null,
        siteCount: null,
        cateCount: null,
        cateDetailCount: null,
        options: []
      },
      planInfo: null,
      linkData: {
        person: [],
        device: [],
        sample: [],
        check: [],
        env: []
      },

      projectInfoCard: Object.assign({}, baseCard, {
        title: '项目基本信息'
      }),

      personLinkCard: Object.assign({}, baseCard, {
        title: '人员环节'
      }),

      deviceLinkCard: Object.assign({}, baseCard, {
        title: '设备环节'
      }),

      sampleLinkCard: Object.assign({}, baseCard, {
        title: '现场环节'
      }),

      checkLinkCard: Object.assign({}, baseCard, {
        title: '检测环节'
      }),

      envLinkCard: Object.assign({}, baseCard, {
        title: '环境环节'
      })
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (val) {
        val && this.findProjectInfo(val)
        val && this.findProjectItemDetail(val)
        val && this.projItemGroupList(val)
      }
    },
    id: {
      immediate: true,
      handler (val) {
        val && this.findPlanInfo(val)
      }
    }
  },

  computed: {
    projProgress () {
      return { ...this.$store.state.code.cache.proj_progress }
    }
  },

  created () {
    this.$store.dispatch('code/select', 'proj_progress')
  },

  methods: {

    // 查询项目信息
    findProjectInfo (projId) {
      projectApi.get({ id: projId }).then(res => {
        if (res.code === 0) {
          this.projectInfo.baseInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询计划信息
    findPlanInfo (id) {
      qualityControlApi.list({ id, page: false }).then(res => {
        if (res.code === 0) {
          const data = res.data.length && res.data[0] || null
          if (data) {
            this.linkData.person.splice(0, this.linkData.person.length, ...this.transCode(data.personCode, personLinkStepDataMap))
            this.linkData.device.splice(0, this.linkData.device.length, ...this.transCode(data.deviceCode, deviceLinkStepDataMap))
            this.linkData.sample.splice(0, this.linkData.sample.length, ...this.transCode(data.sampleCode, sampleLinkStepDataMap))
            this.linkData.check.splice(0, this.linkData.check.length, ...this.transCode(data.checkCode, checkLinkStepDataMap))
            this.linkData.env.splice(0, this.linkData.env.length, ...this.transCode(data.envCode, envLinkStepDataMap))
            this.planInfo = data
          }
        } else {
          this.$message.error(`获取计划信息出错，错误信息：${res.msg}`)
        }
      })
    },
    // 获取项目对应的检测项明细信息
    findProjectItemDetail (projId) {
      projTaskItemApi.detailList({ projId: projId, page: false }).then(res => {
        if (res.data && res.data.length) {
          var siteCount = countBy(res.data, 'siteId')
          var cateCount = countBy(res.data, 'cateId')
          this.sampleStat.siteCount = Object.keys(siteCount).length
          this.sampleStat.cateCount = Object.keys(cateCount).length

          // 组装 分类 因子 数据结构
          const itemCascaderData = res.data.reduce((result, data) => {
            (result[data.cateId] || (result[data.cateId] = { id: data.cateId, name: data.cateName, children: [] })).children.push({
              id: data.itemId,
              name: data.itemName
            })
            return result
          }, {})
          const itemOptions = Object.values(itemCascaderData)
          this.sampleStat.options.splice(0, this.sampleStat.options.length, ...itemOptions)
        }
      })
    },

    projItemGroupList (projId) {
      jcListApi.projItemGroupList({ projId }).then(res => {
        if (res.code === 0) {
          const result = Object.keys(res.data).reduce((result, key) => {
            (result[key] || (result[key] = { cateId: parseInt(key), count: 0 })).count = sumBy(Object.values(res.data[key]), (cItem) => {
              return sumBy(cItem.infoList, (dItem) => {
                const numFrequency = maxBy(dItem.itemList, 'numFrequency').numFrequency
                const numPeriod = maxBy(dItem.itemList, 'numPeriod').numFrequency
                return numFrequency * numPeriod
              })
            })
            return result
          }, {})
          this.sampleStat.cateDetailCount = result
          this.sampleStat.count = sumBy(Object.values(result), 'count')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    transCode (code, codeMap) {
      return Object.values(codeMap).reduce((val, item) => {
        code & item.value && (val.push(item.value))
        return val
      }, [])
    },

    // 处理环节数据变化
    handleLinkDataChange (checkedValue, data, dataSourceMap) {
      const { target: { checked } } = checkedValue
      if (checked) {
        this.checkAll(data, dataSourceMap)
      } else {
        this.clearCheckAll(data)
      }
    },

    // 全选
    checkAll (data, dataSourceMap) {
      var dataArr = Object.values(dataSourceMap).map(item => item.value)
      if (!data) {
        data = []
      }
      data.splice(0, data.length, ...dataArr)
    },

    // 全不选
    clearCheckAll (data) {
      data.splice(0, data.length)
    },

    // 保存
    save () {
      const personCode = this.generateCode(this.linkData.person)
      const deviceCode = this.generateCode(this.linkData.device)
      const sampleCode = this.generateCode(this.linkData.sample)
      const checkCode = this.generateCode(this.linkData.check)
      const envCode = this.generateCode(this.linkData.env)
      const result = { id: this.id, projId: this.projId, personCode, deviceCode, sampleCode, checkCode, envCode }
      qualityControlApi.makePlan(result).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 生成计划环节 CODE
    generateCode (data) {
      return data.reduce((val, current) => val + current, 0)
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index.less";
</style>
