<template>
  <div class="check-reagent-form">
    <a-form v-bind="dataForm">
      <a-card v-bind="baseFormCard" title="基本信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="名称">
              <a-input v-decorator="decorators.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="配置量">
              <j-input-number v-decorator="decorators.sumVolume"></j-input-number>
              <span style="margin-left: 10px">{{ reagentCateInfo.unit }}</span>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="配置时间">
              <a-date-picker v-bind="moreCurrentDatePickerProps" v-decorator="decorators.configTime" format="YYYY-MM-DD HH:mm" @change="setValidTime"></a-date-picker>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="有效期止">
              <a-date-picker v-bind="defaultDatePickerProps" v-decorator="decorators.validTime" :disabled="true" format="YYYY-MM-DD HH:mm"></a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card v-bind="baseFormCard" title="配置信息">
        <template #extra>
          <template v-if="dataForm.data.cateId">
            <a @click="checkReagentCateSelectModal.show">更改试剂种类</a>
          </template>
        </template>

        <template v-if="dataForm.data.cateId">
          <check-reagent-cate-material-table v-bind="cateMaterialTableProps">
            <template #num="{ row }">
              {{ row.num * makeNum }}
            </template>
            <template #actualNum="{ row }">
              <a-row>
                <a-col :span="22">
                  <template v-if="row.usageInfos">
                    <div v-for="(item, index) in row.usageInfos" :key="`actualNum_${index}`" :style="{ border: '0.5px dashed #390', padding: '10px' }">
                      <a-tag color="blue">
                        <a-icon :style="{ marginRight: '10px' }" type="check-circle" />
                        {{ item.name }}
                      </a-tag>
                      <template v-if="item.source"> 称量量：{{ item.useVolume }} {{ item.unit }} </template>
                      <template v-else>
                        可用：<span :style="{ color: 'green', margin: '0 10px 0 5px' }">{{ item.volume }}</span
                        >{{ item.unit }}
                        <div :style="{ margin: '10px 0 0 0 ' }">
                          <span :style="{ margin: '0 10px' }">本次使用：</span>
                          <j-input-number v-model="item.useVolume" :max="item.volume" :min="0" :style="{ width: '65px' }" size="small"></j-input-number>
                          {{ item.unit }}
                        </div>
                      </template>
                    </div>
                  </template>
                </a-col>
                <a-col :span="2">
                  <a :style="{ marginLeft: '10px' }" @click="checkReagentWeighingModal.show(row)">
                    <a-icon type="bank" />
                  </a>
                  <a :style="{ margin: '0 0 0 10px' }" @click="addUsageInfo(row)">
                    <a-icon type="plus"></a-icon>
                  </a>
                </a-col>
              </a-row>
            </template>
          </check-reagent-cate-material-table>
        </template>
        <template v-else>
          <div class="no-data">
            <a @click="checkReagentCateSelectModal.show">请先选择需要配置的试剂</a>
          </div>
        </template>
      </a-card>

      <a-card v-bind="baseFormCard" title="配置指南">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :span="24">
            <a-form-item v-bind="formItemLayout" :labelCol="{ span: 4 }" label="保存介质">
              {{ reagentCateInfo.storageMedium }}
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :span="24">
            <a-form-item v-bind="formItemLayout" :labelCol="{ span: 4 }" label="储备条件">
              {{ reagentCateInfo.storageCondition }}
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :span="24">
            <a-form-item v-bind="formItemLayout" :labelCol="{ span: 4 }" label="配置方法">
              {{ reagentCateInfo.preparationMethod }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form>

    <!-- 试剂类目选择 -->
    <a-modal v-bind="checkReagentCateSelectModal">
      <check-reagent-cate-list v-bind="checkReagentCateSelectModal.data" v-on="checkReagentCateSelectModal.tableAction"></check-reagent-cate-list>
      <template #footer>
        <a-button @click="checkReagentCateSelectModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="checkReagentCateSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!--试剂原料选择-->
    <a-modal v-bind="rawMaterialDetailSelectModal">
      <raw-material-detail-table v-bind="rawMaterialDetailSelectModal.data" v-on="rawMaterialDetailSelectModal.tableAction"></raw-material-detail-table>
      <template #footer>
        <a-button @click="rawMaterialDetailSelectModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="rawMaterialDetailSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 试剂使用选择-->
    <a-modal v-bind="checkReagentSelectModal">
      <check-reagent-list v-bind="checkReagentSelectModal.data" v-on="checkReagentSelectModal.tableAction"></check-reagent-list>
      <template #footer>
        <a-button @click="checkReagentSelectModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="checkReagentSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 试剂称量记录  -->
    <a-modal v-bind="checkReagentWeighingModal">
      <check-reagent-weighing-list v-bind="checkReagentWeighingModal.data" v-on="checkReagentWeighingModal.tableAction"></check-reagent-weighing-list>
      <template #footer>
        <a-button @click="checkReagentWeighingModal.action.cancel">关闭</a-button>
        <a-button type="primary" @click="checkReagentWeighingModal.action.ok">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import mixinForm from '@/pages/common/mixins/form'

import { checkReagentApi, checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'
import { CheckReagentCateMaterialTable, CheckReagentCateSelectTable } from '@/pages/cloud-quote/check-reagent/cate/modules'
import CheckReagentCateList from '@/pages/cloud-quote/check-reagent/cate/List'
import { checkReagentSelectColumn } from '@/pages/cloud-quote/check-reagent/modules/table/columns'
import { configColumn as checkReagentCateMaterialConfigColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/material/columns'

import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { rawMaterialDetailTable } from '@/pages/cloud-quote/check-reagent/rawMaterial/modules'
import { CheckReagentCateSelectColumn as checkReagentCateColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { checkReagentWeighingSelectColumn } from '@/pages/cloud-quote/check-reagent/weighing/modules/table/columns'
import CheckReagentList from '@/pages/cloud-quote/check-reagent/SelectList'
import CheckReagentWeighingList from '@/pages/cloud-quote/check-reagent/weighing'
import JInputNumber from '@/components/j/JInputNumber'
import { autoConfigureApi, sysReviewConfigUserControllerApi } from '@/api/autoConfigure/autoConfigureApi'
import { flowApi } from '@/api/flow'
import { getAction } from '@/api/common'

export default {
  props: {
    id: Number,
    cateId: Number
  },
  mixins: [mixinForm],

  components: {
    CheckReagentCateSelectTable,
    CheckReagentCateMaterialTable,
    rawMaterialDetailTable,
    CheckReagentList,
    CheckReagentCateList,
    CheckReagentWeighingList,
    JInputNumber
  },

  data() {
    return {
      reagentCateMaterialTypeEnum,

      decorators: {
        name: ['name', { rules: [{ required: true, message: '请输入ID！' }] }],
        validTime: ['validTime', { rules: [{ required: true }] }],
        configTime: ['configTime', { rules: [{ required: true, message: '请选择配置时间！' }] }],
        sumVolume: ['sumVolume', { rules: [{ required: true, message: '请输入配置量！' }] }]
      },
      tesNumber: 0,
      currentColSize: 2,
      makeNum: 1,
      speType: null,

      // 试剂类目信息
      reagentCateInfo: {},

      dataForm: {
        data: { id: null, cateId: null }
      },

      checkReagentCateSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        title: '请选择试剂种类',
        bodyStyle: { background: '#F0F2F5' },

        show: () => {
          this.checkReagentCateSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentCateSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.checkReagentCateSelectModal.data.rowSelect.selectedRows
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const cateData = selectData[0]

            this.reagentCateInfo = cateData
            this.dataForm.data.cateId = cateData.id
            this.fillByReagentCateData(cateData)
            this.checkReagentCateSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'selectTable',
          rowSelect: { type: 'radio', selectedRows: [], selectedRowKeys: [] },
          columns: checkReagentCateColumn
        },

        tableAction: {}
      },

      rawMaterialDetailSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        title: '请选择使用的原料',
        currentMaterialData: null,
        destroyOnClose: true,
        bodyStyle: { background: '#F0F2F5' },

        show: (materialId, row) => {
          this.rawMaterialDetailSelectModal.data.materialId = materialId
          this.rawMaterialDetailSelectModal.data.validCheckTime = this.timeFormat(this.getFormDataByKey('configTime'))
          this.rawMaterialDetailSelectModal.currentMaterialData = row
          this.rawMaterialDetailSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.rawMaterialDetailSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.rawMaterialDetailSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const materialData = this.rawMaterialDetailSelectModal.currentMaterialData
            if (!materialData.hasOwnProperty('usageInfos')) {
              this.$set(materialData, 'usageInfos', [])
            }
            delete materialData.checkReagentMaterialWeighingId
            const needNum = materialData.num * this.makeNum
            materialData.usageInfos.splice(
              0,
              materialData.usageInfos.length,
              ...selectData.map((item) => ({
                id: item.id,
                name: item.materialName,
                type: this.reagentCateMaterialTypeEnum.material,
                sumVolume: item.sumVolume,
                volume: item.volume,
                unit: item.unit,
                useVolume: item.volume > needNum ? needNum : item.volume
              }))
            )
            this.rawMaterialDetailSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'rawMaterialSelectTable',
          validCheckTime: null,
          materialId: null,
          selectable: true
        },

        tableAction: {}
      },

      checkReagentSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        title: '请选择使用的试剂',
        currentMaterialData: null,
        destroyOnClose: true,
        bodyStyle: { background: '#F0F2F5' },

        show: (materialId, row) => {
          this.checkReagentSelectModal.data.cateId = materialId
          const configTimeStr = this.timeFormat(this.getFormDataByKey('configTime'))
          this.checkReagentSelectModal.data.validCheckTime = configTimeStr
          this.checkReagentSelectModal.currentMaterialData = row
          this.checkReagentSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.checkReagentSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const materialData = this.checkReagentSelectModal.currentMaterialData
            if (!materialData.hasOwnProperty('usageInfos')) {
              this.$set(materialData, 'usageInfos', [])
            }
            delete materialData.checkReagentMaterialWeighingId
            const needNum = materialData.num * this.makeNum
            materialData.usageInfos.splice(
              0,
              materialData.usageInfos.length,
              ...selectData.map((item) => ({
                id: item.id,
                name: item.name,
                type: this.reagentCateMaterialTypeEnum.cate,
                sumVolume: item.sumVolume,
                volume: item.volume,
                unit: item.unit,
                useVolume: item.volume > needNum ? needNum : item.volume
              }))
            )
            this.checkReagentSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentSelectTable',
          cateId: null,
          selectable: true,
          pagination: true,
          validCheckTime: null,
          columns: checkReagentSelectColumn
        },

        tableAction: {}
      },

      checkReagentWeighingModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        title: '请选择使用的称量记录',
        currentMaterialData: null,
        destroyOnClose: true,
        bodyStyle: { background: '#F0F2F5' },

        show: (row) => {
          this.checkReagentWeighingModal.currentMaterialData = row
          this.checkReagentWeighingModal.data.materialCateId = row.materialId
          this.checkReagentWeighingModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentWeighingModal.visible = false
          },

          ok: () => {
            const materialData = this.checkReagentWeighingModal.currentMaterialData
            const { type } = materialData
            const selectData = this.checkReagentWeighingModal.data.rowSelect.selectedRows?.filter((item) => item.type === type)
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            if (!materialData.hasOwnProperty('usageInfos')) {
              this.$set(materialData, 'usageInfos', [])
            }
            materialData.checkReagentMaterialWeighingId = selectData[0].id
            if (type === this.reagentCateMaterialTypeEnum.cate) {
              materialData.usageInfos.splice(
                0,
                materialData.usageInfos.length,
                ...selectData.map((item) => ({
                  id: item.materialId,
                  name: item.materialName,
                  type: this.reagentCateMaterialTypeEnum.cate,
                  unit: item.unit,
                  useVolume: item.weighNum,
                  source: 'weighing'
                }))
              )
            } else if (type === this.reagentCateMaterialTypeEnum.material) {
              materialData.usageInfos.splice(
                0,
                materialData.usageInfos.length,
                ...selectData.map((item) => ({
                  id: item.materialId,
                  name: item.materialName,
                  type: this.reagentCateMaterialTypeEnum.material,
                  unit: item.unit,
                  useVolume: item.weighNum,
                  source: 'weighing'
                }))
              )
            }

            this.checkReagentWeighingModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentWeighingModal',
          columns: checkReagentWeighingSelectColumn,
          materialCateId: null,
          rowSelect: {
            type: 'radio',
            selectedRowKeys: [],
            selectedRows: []
          }
        },

        tableAction: {}
      },
      // 新增的试剂Id
      reagentInfo: {},
      // 自动试剂复核人员
      assignUser: {},
      // 复核流程数据
      processDetail: {}
    }
  },

  computed: {
    cateMaterialTableProps() {
      const cateId = this.dataForm.data.cateId
      return { ref: 'cateMaterialTable', cateId, columns: checkReagentCateMaterialConfigColumn }
    }
  },

  watch: {
    id: {
      immediate: true,
      handler(value) {
        value && this.handleEditData(value)
      }
    }
  },

  mounted() {
    this.submit = throttle(this.submit, 6000, { leading: true, trailing: false })

    if (!this.cateId) {
      this.checkReagentCateSelectModal.show()
    } else {
      this.dataForm.data.cateId = this.cateId
      this.queryReagentCateInfo(this.cateId)
    }
  },

  methods: {
    // 计算制备的分数
    getMakeNum() {
      const sumVolume = this.getFormDataByKey('sumVolume')
      const num = this.speType ? sumVolume / this.speType : 1
      this.makeNum = num
    },

    // 添加试剂使用记录
    addUsageInfo(row) {
      const { materialId, type } = row
      if (type === this.reagentCateMaterialTypeEnum.material) {
        this.rawMaterialDetailSelectModal.show(materialId, row)
      } else if (type === this.reagentCateMaterialTypeEnum.cate) {
        this.checkReagentSelectModal.show(materialId, row)
      } else {
        this.$message.error('不识别的试剂类别')
      }
    },

    // 通过试剂类目填充数据
    fillByReagentCateData(cateData) {
      const { name, speType, id } = cateData
      const { configTimeFormData } = this.dataForm.form.getFieldsValue()
      const configTime = configTimeFormData || this.moment()
      const validTime = this.getValidTime(configTime)
      this.speType = speType
      this.dataForm.data.cateId = id
      this.$nextTick(() => {
        this.$refs.cateMaterialTable.search()
      })
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue({ name, configTime, validTime })
      })
    },

    // 查询试剂类目信息
    queryReagentCateInfo(cateId) {
      checkReagentCateApi.findById({ id: cateId }).then((res) => {
        if (res.code === 0) {
          this.reagentCateInfo = res.data
          this.dataForm.data.cateId = res.data.id
          this.fillByReagentCateData(res.data)
        } else {
          this.$message.error(`获取试剂类目信息出错：${res.msg}`)
        }
      })
    },

    // 根据试剂类目配置获取有效期止
    getValidTime(current) {
      const { validDateUnit, validDate } = this.reagentCateInfo
      return this.moment(current).add(validDate, validDateUnit)
    },

    // 根据试剂类目配置设置有效期止
    setValidTime(current) {
      const validTime = this.getValidTime(current)
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue({ validTime })
      })
    },

    // 处理编辑数据
    handleEditData(id) {
      this.dataForm.data.id = id
      checkReagentApi.findById(id).then((res) => {
        if (res.code === 0) {
          this.dataForm.form.$nextTick(() => {
            const decoratorKeys = Object.keys(this.decorators)
            const newData = Object.keys(res.data || {}).reduce((acc, key) => {
              let data = res.data[key]
              if (key.endsWith('Time') || key.endsWith('Date')) {
                data = this.moment(data)
              }
              decoratorKeys.includes(key) && (acc[key] = data)
              return acc
            }, {})
            this.dataForm.form.setFieldsValue(newData)
          })
        } else {
          this.$message.error('获取数据失败')
        }
      })
    },

    // 提交保存
    submit() {
      this.dataForm.form.validateFields((errors, values) => {
        if (!errors) {
          const usageInfoDataList = this.$refs[this.cateMaterialTableProps.ref].getData()
          const notConfigData = usageInfoDataList.find((item) => {
            return !item.usageInfos || !item.usageInfos.length
          })
          if (notConfigData) {
            this.$message.error(`${notConfigData.materialName}还未配置使用记录!`)
            return false
          }
          this.$emit('updateReagentLoading',true)
          const allUsageInfos = usageInfoDataList.reduce((acc, item) => {
            acc.push.apply(acc, item.usageInfos)
            return acc
          }, [])
          const cateUsageInfos = allUsageInfos
            .filter((item) => item.type === this.reagentCateMaterialTypeEnum.cate)
            .map((item) => {
              item.useVolumeStr = item.useVolume
              return item
            })
          const materialInfos = allUsageInfos
            .filter((item) => item.type === this.reagentCateMaterialTypeEnum.material)
            .map((item) => {
              item.useVolumeStr = item.useVolume
              return item
            })
          const checkReagentMaterialWeighingId = []
          usageInfoDataList.forEach((item) => {
            if (item.checkReagentMaterialWeighingId) {
              checkReagentMaterialWeighingId.push(item.checkReagentMaterialWeighingId)
            }
          })
          const data = Object.assign({ cateUsageInfos, materialInfos, checkReagentMaterialWeighingId }, values, this.dataForm.data)
          // console.log(data)
          checkReagentApi.save(data).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reagentInfo = res.data
              this.getStandardSolutionReviewConfig()
              // this.$emit('success')
            } else {
              this.$message.error(res.msg)
              this.$emit('error')
              this.$emit('updateReagentLoading',false)
            }
          }).catch((err) => {
            this.$emit('updateReagentLoading',false)
          })
        }else{
          this.$emit('updateReagentLoading',false)
        }
      })
    },
    // 判断是否为自动试剂复核
    getStandardSolutionReviewConfig() {
      autoConfigureApi.selectConfigByType({ type: 'reagent_review_config' }).then((res) => {
        // 如果是1，自动试剂复核
        if (res == 1) {
          this.$message.warning('正在自动复核，请勿进行其它操作！')
          this.selectUserList()
        } else {
          this.$emit('success')
        }
      }).catch((err) => {
        this.$emit('success')
      })
    },
    // 获取自动试剂复核复核人员
    selectUserList() {
      console.log(this.reagentInfo.id)
      sysReviewConfigUserControllerApi.selectUserList({ type: 'sj', id: this.reagentInfo.id }).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.assignUser = res.data
          this.autoAssignReviewedCheckUser()
        }else{
          this.$emit('success')
        }
      }).catch((err) => {
        this.$emit('success')
      })
    },
    // 自动指派复核人
    autoAssignReviewedCheckUser() {
      this.reagentInfo.recheckApplyTime = new Date().format('yyyy-MM-dd HH:mm:ss')
      this.reagentInfo.recheckUser = this.assignUser.reviewedCheckUser
      checkReagentApi.recheck(this.reagentInfo).then((res) => {
        if (res.code === 0) {
          if (this.reagentInfo.status === '0') {
            this.$message.success('试剂配置复核流程申请成功！')
          } else {
            this.$message.success('试剂配置复核流程重新提交成功！')
          }
          this.selectPageProcess()
        } else {
          if (this.reagentInfo.status === '0') {
            this.$message.error('试剂配置复核流程申请失败！')
          } else {
            this.$message.error('试剂配置复核流程重新提交失败！')
          }
          this.$emit('success')
        }
      }).catch((err) => {
        this.$emit('success')
      })
    },
    // 获取流程数据
    selectPageProcess() {
      getAction(flowApi.pageProcess, { pageNo: 1, pageSize: 20, page: true, businessKey: 'res_reagent_recheck_apply_' + this.reagentInfo.id }).then((res) => {
        this.processDetail = res.data[0]
        this.autoReview()
      }).catch((err) => {
        this.$emit('success')
      })
    },
    // 自动复核
    autoReview() {
      const params = {
        taskId: this.processDetail.taskId,
        type: 'AGREE_AUDIT',
        reason: '确认通过>>同意',
        opinion: '同意',
        vars: { type: 'AGREE_AUDIT', typeName: '确认通过', reason: '确认通过>>同意', opinion: '同意' }
      }

      getAction(flowApi.agree, params).then((res) => {
        if (res.code === 0) {
          this.$message.success('试剂配置自动复核成功')
          this.reagentInfo = {}
          this.assignUser = {}
          this.processDetail = {}
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
          this.$emit('success')
        }
      }).catch((err) => {
        this.$emit('success')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
