/**
 * 检测明细ID API
 */
import { getAction, postDataAction } from '@/api/common'

const itemCheckDetailReagentPre = '/ms-api/item-check-detail-reagent'
const itemCheckDetailSolutionTypePre = '/ms-api/item-check-detail-solution-type'

export const itemCheckDetailReagentApi = {
  list: (param) => getAction(`${itemCheckDetailReagentPre}/list`, param),
  save: (data) => postDataAction(`${itemCheckDetailReagentPre}/save`, data)
}

export const itemCheckDetailSolutionApi = {
  list: (param) => getAction(`${itemCheckDetailSolutionTypePre}/list`, param),
  saveOrUpdate: (param) => postDataAction(`${itemCheckDetailSolutionTypePre}/saveOrUpdate`, param),
  delete: (param) => getAction(`${itemCheckDetailSolutionTypePre}/delete`, param)
}
