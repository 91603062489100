import { tsSampleinfoApi } from '@/api/sample'
import { checkTaskApi } from '@/api/check/checkTaskApi'
import { ref } from 'vue'
import { orderBy } from 'lodash'
import { message } from 'ant-design-vue'
import { useDate } from '@/pages/common/compositions/date/useDate'

const { moment } = useDate()

export const useSampleInfo = () => {
  const sampleInfoList = ref([])
  const sampleInfoStatus = ref(false)
  const unitMap = new Map([
    ['天', 'd'],
    ['小时', 'h'],
    ['分钟', 'm']
  ])

  // 查询样品信息
  const querySampleInfos = (sampleIds, batchId, compositeItem) => {
    // console.log(batchId, compositeItem);
    let res1 = null
    let res2 = null
    if (compositeItem) {
      res1 = checkTaskApi.seletCompositItem({ batchId })
    }
    res2 = tsSampleinfoApi.list({ ids: sampleIds, page: false })
    Promise.all([res1, res2]).then((res) => {
      console.log(res);
      let res1 = res[0]
      let res2 = res[1]
      let compositeItemList = []
      if (res1 && res1.code === 0) {
        compositeItemList = res1.data
      }
      if (res2 && res2.code === 0) {
        let resData = res2.data || []
        if (compositeItem) {
          resData.forEach(item => {
            let compositeItems = compositeItemList.filter(item2 => item2.sampleId === item.id)
            if (compositeItems.length > 0) {
              item.compositeItem = compositeItems[0].compositeItem
              item.compositeItemName = compositeItems[0].compositeItemName
            }
          })
        }
        sampleInfoList.value.splice(0, sampleInfoList.value.length, ...orderBy(resData, 'type'))
        console.log(sampleInfoList.value);
        sampleInfoStatus.value = true
      } else {
        message.error(`获取样品信息错误信息:${res2.msg}`)
      }
    })
  }

  const transUnit = (unit) => {
    return unitMap.get(unit) ?? unit
  }

  const computeValidityDate = (sampleInfoList, now) => {
    sampleInfoList.forEach((item) => {
      const { sampleBeginTime, validity, validityUnit: validityUnitData } = item
      if (validity && validityUnitData) {
        const validityUnit = transUnit(validityUnitData)
        const beginTime = moment(sampleBeginTime)
        const validityDate = beginTime.add(validity, validityUnit)
        item.validityDate = validityDate
        item.expired = validityDate.isBefore(now)
      } else {
        item.validityDate = null
      }
    })
  }

  return {
    sampleInfoList,
    sampleInfoStatus,
    querySampleInfos,
    computeValidityDate
  }
}
