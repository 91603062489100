<template>
  <a-modal
    :title="title"
    :footer="null"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
    class="UsingVideoModal"
  >
    <img src="@/assets/semacode.png" style="width: 200px; height: 200px" />
    <!-- style="width: 450px;height: 450px;" -->
  </a-modal>
</template>
  
  <script>
import { userApi } from '@/api/system'

export default {
  name: 'UsingVideo',
  data() {
    return {
      title: '抖音扫一扫',
      modalWidth: 220,
      // modalHeight:240,
      visible: false,
      confirmLoading: false,
      // validatorRules: {
      //   oldPassword: {
      //     rules: [{
      //       required: true, message: '请输入旧密码!'
      //     }]
      //   },
      //   password: {
      //     rules: [{
      //       required: true, message: '请输入新密码!'
      //     }, {
      //       validator: this.validateToNextPassword
      //     }]
      //   },
      //   confirmpassword: {
      //     rules: [{
      //       required: true, message: '请确认新密码!'
      //     }, {
      //       validator: this.compareToFirstPassword
      //     }]
      //   }
      // },
      confirmDirty: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      form: this.$form.createForm(this)
      // username: ''
    }
  },
  methods: {
    show(uname) {
      if (!uname) {
        this.$message.warning('当前系统无登陆用户!')
      } else {
        //   this.username = uname
        //   this.form.resetFields()
        this.visible = true
      }
    },
    handleCancel() {
      this.close()
    },
    close() {
      // this.$emit('close')
      this.visible = false
      // this.disableSubmit = false
      // this.selectedRole = []
    },
    handleOk() {
      that.close()
    }
    //   validateToNextPassword  (rule, value, callback) {
    //     const form = this.form
    //     if (value && this.confirmDirty) {
    //       form.validateFields(['confirm'], { force: true })
    //     }
    //     callback()
    //   },
    //   compareToFirstPassword  (rule, value, callback) {
    //     const form = this.form
    //     if (value && value !== form.getFieldValue('password')) {
    //       callback(new Error('两次输入的密码不一样！'))
    //     } else {
    //       callback()
    //     }
    //   },
    //   handleConfirmBlur  (e) {
    //     const value = e.target.value
    //     this.confirmDirty = this.confirmDirty || !!value
    //   }
  }
}
</script>
  
<style >
/* .ant-modal-content {
  padding: 10px !important;  
} */
.UsingVideoModal .ant-modal-body {
  padding: 10px !important; /* 修改为你想要的内边距 */
}
</style>
