<template>
  <div>
    <a-table
      v-if="model.type!=undefined"
      ref="table"
      size="small"
      :rowKey="record => record.id"
      :columns="columns"
      :dataSource="dataSource"
      :scroll="{y:350}"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type:model.type}"
      :pagination="false"
    >
      <template v-for="(m, index) in attributeList" :slot="m.id" slot-scope="text, record">
        {{ getAttributeValName(text) }}
      </template>
    </a-table>
    <a-table
      v-else
      ref="table"
      size="small"
      :rowKey="record => record.id"
      :columns="columns"
      :dataSource="dataSource"
      :scroll="{y:350}"
      :pagination="false"
    >
      <template v-for="(m, index) in attributeList" :slot="m.id" slot-scope="text, record">
        {{ getAttributeValName(text) }}
      </template>
    </a-table>
  </div>
</template>

<script>
import { attributeApi, standardItemFactorApi, Get, Post, Del } from '@/api/quote'

export default {
  name: 'AttributeCombineLimitValue',
  components: { },
  props: {
    attributeList: {
      type: Array,
      default: []
    },
    model: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: 'radio'
    }
  },
  data () {
    return {
      columns: [],
      dataSource: [],
      factorList: [],
      attributeDetailList: [],
      savedDataSource: [],
      selectedRowKeys: []
    }
  },
  computed: {
  },

  methods: {
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    getAttributeValName (value) {
      if (value) {
        const valueList = this.attributeList.map(item => item.valueList)
        let text = value.toString()
        valueList.forEach(valueArray => {
          const d = valueArray.filter(item => item.id == value)
          if (d.length > 0) {
            text = d[0].valName
          }
        })
        return text
      } else {
        return '-'
      }
    },
    loadAttributeList () {
      return new Promise((resolve, reject) => {
        Get(attributeApi.listWithValue, { type: this.model.type, cateId: this.model.cateId }).then(res => {
          if (res.code === 0) {
            resolve(res.data)
          } else {
            resolve([])
          }
        })
      })
    },
    getAttributeName (text) {
      if (text) {
        const temp = []
        text.split(',').forEach(item => {
          const d = this.attributeList.filter(r => r.id == item)
          if (d.length != 0) {
            temp.push(d[0].attributeName)
          }
        })
        return temp.join(',')
      } else {
        return ''
      }
    },
    loadAttributeDetail () {
      return new Promise((resolve, reject) => {
        standardItemFactorApi.listAttributeDetail({ factorId: this.model.id }).then(res => {
          if (res.code === 0) {
            resolve(res.data)
          } else {
            resolve([])
          }
        })
      })
    },
    loadData: async function () {
      this.attributeDetailList = await this.loadAttributeDetail()
      Get(standardItemFactorApi.listCombine, { factorId: this.model.id }).then(res => {
        if (res.code === 0) {
          const d = res.data
          this.savedDataSource = JSON.parse(JSON.stringify(d.dataList))
          this.dataSource = d.dataList.reduce((dataSource, item) => {
            const obj = { id: item.id }
            switch (item.type) {
              case '1':
                obj.limitValue = item.limitValue
                break
              case '2':
                obj.lowerLimitValue = item.lowerLimitValue
                obj.upperLimitValue = item.upperLimitValue
                break
              case '3':
                obj.describeValue = item.describeValue
                break
            }
            const detailList = this.attributeDetailList.filter(r => r.attrListId == item.id)
            detailList.forEach(detail => {
              obj[detail.attributeId] = detail.valId
            })
            dataSource.push(obj)
            return dataSource
          }, [])
          /* if(d.dataList&&d.dataList.length>2){
            this.model.type = 'checkbox'
          } */
        }
      })
    }

  },
  watch: {
    selectedRowKeys: {
      immediate: true,
      handler (newVal, oldVal) {
        this.$emit('valueSelect', newVal)
      }
    },
    'model.id': {
      immediate: true,
      handler (newVal, oldVal) {
        var model = this.model
        if (model.attributeIds) {
          this.columns = model.attributeIds.split(',').reduce((columns, item) => {
            columns.push({ title: this.getAttributeName(item), align: 'center', width: 200, dataIndex: item, scopedSlots: { customRender: item } })
            return columns
          }, [])
          if (model.selectKeys) {
            this.selectedRowKeys = model.selectKeys
          }
          this.loadData()
        }
      }
    },
    'model.type': {
      handler (newVal, oldVal) {
        this.selectedRowKeys = []
      }
    },
    savedDataSource: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal != undefined && newVal.length > 0) {
          switch (newVal[0].type) {
            case '1':
              this.columns.push({ title: '限值', align: 'center', dataIndex: 'limitValue', scopedSlots: { customRender: 'limitValue' } })
              break
            case '2':
              this.columns.push({ title: '下限值', align: 'center', dataIndex: 'lowerLimitValue', scopedSlots: { customRender: 'lowerLimitValue' } })
              this.columns.push({ title: '上限值', align: 'center', dataIndex: 'upperLimitValue', scopedSlots: { customRender: 'upperLimitValue' } })
              break
            case '3':
              this.columns.push({ title: '描述限值', align: 'center', dataIndex: 'describeValue', scopedSlots: { customRender: 'describeValue' } })
              break
          }
        }
      }
    }

  }
}
</script>
