import { delAction, getAction, postAction, postDataAction, putDataAction } from './common'

const msgConfigPre = '/ms-api/msg-config'
const msgPushLogPre = '/ms-api/msgPushLog'
const msgGenerateLogPre = '/ms-api/msgGenerateLog'

export const msgConfigApi = {
  list: (p) => getAction(`${msgConfigPre}/list`, p),
  get: (p) => getAction(`${msgConfigPre}/get`, p),
  add: (p) => postDataAction(`${msgConfigPre}/add`, p),
  edit: (p) => postDataAction(`${msgConfigPre}/edit`, p),
  addOrEditNode: (p) => postDataAction(`${msgConfigPre}/addOrEditNode`, p),
  dels: (p) => delAction(`${msgConfigPre}/dels`, p),
  validateName: (p) => getAction(`${msgConfigPre}/validate-name`, p),
  testPush: (p) => postAction(`${msgConfigPre}/testPush`, p)
}

export const msgPushLogApi = {
  list: (p) => getAction(`${msgPushLogPre}/list`, p),
  get: (p) => getAction(`${msgPushLogPre}/get`, p),
  read: (p) => putDataAction(`${msgPushLogPre}/read`, p)
}

export const msgGenerateLogApi = {
  list: (p) => getAction(`${msgGenerateLogPre}/list`, p),
  get: (p) => getAction(`${msgGenerateLogPre}/get`, p)
}
