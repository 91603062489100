export default {
  data () {
    return {
      actionCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        headStyle: {},
        bodyStyle: { padding: '16px' },
        class: 'card-action'
      },

      actionButton: {
        style: { margin: '0 0 0 12px' },
        class: 'fr'
      },

      contentCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: { padding: '16px' },
        class: 'card-table'
      }
    }
  }
}
