'use strict'

// 样品销毁申请状态字典枚举
export const sampleDestroyApplyStatusEnum = {
  // 待申请
  pending: '0',
  // 已申请
  applyed: '1'
}

// 样品销毁申请状态选择项
export const sampleDestroyApplyStatusOptions = [
  { label: '待申请', value: sampleDestroyApplyStatusEnum.pending, color: 'gray' },
  { label: '已申请', value: sampleDestroyApplyStatusEnum.applyed, color: 'blue' }
]

// 样品销毁状态字典枚举
export const sampleDestroyStatusEnum = {
  // 申请
  apply: '1',
  // 审核通过
  pass: '2'
}

// 样品销毁审核状态选择项
export const sampleDestroyStatusOptions = [
  { label: '已申请', value: sampleDestroyStatusEnum.apply, color: 'blue' },
  { label: '审核通过', value: sampleDestroyStatusEnum.pass, color: 'green' }
]

// 样品销毁状态字典枚举
export const sampleDestroyApprovalStatusEnum = {
  // 通过
  pass: '1',
  // 不通过
  fail: '0'
}

// 样品销毁审核状态选择项
export const sampleDestroyApprovalStatusOptions = [
  { label: '通过', value: sampleDestroyApprovalStatusEnum.pass, color: 'green' },
  { label: '不通过', value: sampleDestroyApprovalStatusEnum.fail, color: 'red' }
]
