import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 标线配置 复核状态 字典枚举
export const checkStandardCurveReviewStatusEnum = {
  // 待复核
  pending: '0',
  // 通过
  pass: '1',
  // 驳回
  rejected: '2'
}

//  标线配置 复核状态 选择项
export const checkStandardCurveReviewStatusOptions = [
  { label: '待复核', value: checkStandardCurveReviewStatusEnum.pending, color: 'gray' },
  { label: '通过', value: checkStandardCurveReviewStatusEnum.pass, color: 'green' },
  { label: '驳回', value: checkStandardCurveReviewStatusEnum.rejected, color: 'red' }
]

export const checkStandardCurveReviewStatusMap = tranOptionsToMap(checkStandardCurveReviewStatusOptions)
export const checkStandardCurveReviewStatusDataMap = tranOptionsToDataMap(checkStandardCurveReviewStatusOptions)

// 标线配置 审核状态 字典枚举
export const checkStandardCurveCheckStatusEnum = {
  // 待审核
  pending: '0',
  // 通过
  pass: '2',
  // 驳回
  rejected: '3'
}

//  标线配置 审核状态 选择项
export const checkStandardCurveCheckStatusOptions = [
  { label: '待审核', value: checkStandardCurveCheckStatusEnum.pending, color: 'gray' },
  { label: '通过', value: checkStandardCurveCheckStatusEnum.pass, color: 'green' },
  { label: '驳回', value: checkStandardCurveCheckStatusEnum.rejected, color: 'red' }
]

// 曲线配置模式
export const checkStandardCurveModeEnum = {
  // 人工录入
  manMade: 1,
  // 附件上传
  fileMade: 2
}

//  曲线配置模式 选择项
export const checkStandardCurveModeOptions = [
  { label: '人工录入', value: checkStandardCurveModeEnum.manMade, color: 'blue' },
  { label: '附件上传', value: checkStandardCurveModeEnum.fileMade, color: 'green' }
]

export const checkStandardCurveCheckStatusMap = tranOptionsToMap(checkStandardCurveCheckStatusOptions)
export const checkStandardCurveCheckStatusDataMap = tranOptionsToDataMap(checkStandardCurveCheckStatusOptions)
