// import {axios} from '@/utils/request.js'

// // 数据列表接口
// export const getPortalAPI=(param)=>{
//   return axios({
//         url:'ms-api/sysTitleUrlConfig/queryList',    //完全可以
//         param
//     })
// }

// // 添加数据接口
// export const addPortalAPI=(data)=>{
//     return axios({
//         url:`ms-api/sysTitleUrlConfig/addOrEdit/`,
//         method:'post',
//         data
//     })
// }
// // 删除数据接口
// export const delPortalAPI=(id)=>{
//     return axios({
//         url:`ms-api/sysTitleUrlConfig/delete/${id}`,
//         method:'delete'
//     })
// }

// // 查询接口
// export const checkPortalAPI=(id)=>{
//     return axios({
//         url:`ms-api/sysTitleUrlConfig/queryList/${id}`
//     })
// }

import { getAction, delAction, postAction, postDataAction, putAction } from '@/api/common'

export const portalManagementAPI = {
  getPortalAPI: params => getAction(`/ms-api/sysTitleUrlConfig/queryList`, params), // 数据列表接口
  delPortalAPI: params => delAction(`/ms-api/sysTitleUrlConfig/delete`, params), // 删除数据列表接口
  addPortalAPI: params => postDataAction(`/ms-api/sysTitleUrlConfig/addOrEdit`, params), // 新增或编辑数据列表接口
  resPortalAPI: params => getAction(`/ms-api/sysTitleUrlConfig/queryById`, params), // 获取详情数据列表接口
  formDel: params => delAction(`/ms-api/sysTitleUrlConfig/deleteAccount`, params) // 删除密码表格接口
}
