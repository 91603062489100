import { computed, onBeforeUnmount, onMounted, reactive, ref, useAttrs, watch } from 'vue'
import { debounce } from 'lodash'

export const useTable = (loadData, columns = [], showPagination = true, bordered = null, handleLoading = null) => {
  const loading = ref(false)
  watch(
    () => loading.value,
    (value) => {
      handleLoading?.(value)
    }
  )

  const rowSelection = reactive({
    selectedRowKeys: [],
    selectedRows: [],
    onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows)
  })

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    rowSelection.selectedRowKeys.splice(0, rowSelection.selectedRowKeys.length, ...selectedRowKeys)
    rowSelection.selectedRows.splice(0, rowSelection.selectedRows.length, ...selectedRows)
  }

  const tableRef = ref(null)

  const contentTable = reactive({
    rowKey: 'id',
    size: 'small',
    bordered,
    data: loadData,
    expandedRowKeys: [],
    columns: columns ?? [{ title: 'ID', dataIndex: 'id', scopedSlots: { customRender: 'id' } }],
    style: { background: '#FFFFFF' },
    bodyStyle: { background: '#D7DDE312' },
    showPagination: !showPagination ? false : 'auto',

    action: {
      loading: (value) => {
        loading.value = value
      }
    }
  })

  const clearSelect = () => {
    rowSelection.selectedRowKeys.splice(0, rowSelection.selectedRowKeys.length)
    rowSelection.selectedRows.splice(0, rowSelection.selectedRows.length)
  }

  return {
    loading,
    rowSelection,
    clearSelect,
    tableRef,
    contentTable
  }
}

export const useOutTable = (queryOriginalParam) => {
  const tableRef = ref()
  const attrs = useAttrs()
  const customTable = computed(() => {
    return Object.assign({}, attrs, queryOriginalParam?.value ?? queryOriginalParam ?? {})
  })
  return { tableRef, customTable }
}

export const useScrollTable = (defaultOtherHeight) => {
  const otherHeight = computed(() => defaultOtherHeight?.value ?? defaultOtherHeight ?? 360)
  const scrollProp = reactive({ x: 1000, y: document.documentElement.clientHeight - 445 })

  watch(
    () => otherHeight.value,
    () => updateTableHeight()
  )

  onMounted(() => {
    updateTableHeight()
    window.addEventListener('resize', updateTableHeight)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateTableHeight)
  })

  const updateTableHeight = debounce(
    () => {
      const offsetHei = document.documentElement.clientHeight
      const searchCardElement = document.getElementById('searchCard')
      const searchCardHeight = (searchCardElement && searchCardElement.clientHeight) || 97
      const tableHeight = offsetHei - otherHeight.value - searchCardHeight
      // table 最小高度设置为80px
      scrollProp.y = tableHeight < 80 ? 80 : tableHeight
    },
    100,
    { leading: false, trailing: true }
  )

  return {
    scrollProp,
    updateTableHeight
  }
}
