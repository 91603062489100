<script>
export default {
  name: 'Action',
  inheritAttrs: false
}
</script>
<script setup>
import { ref, reactive } from 'vue'
import { useActionProps } from '@/pages/common/compositions/list/useAction'
import { useFullModal } from '@/pages/common/mixins/modal/full'
import { CheckTaskDetailUreport, CheckTaskList, CheckTaskScheduleStep } from '@/pages/cloud-quote/check-task/modules'
import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import MinimizableModal from '@/pages/common/compositions/modal/MinimizableModal.vue'
import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'
import { useDate } from '@/pages/common/compositions/date/useDate'
import { checkTaskApi, checkTaskBatchGroupApi } from '@/api/check/checkTaskApi'
import { tsSampleinfoApi } from '@/api/sample'
import { labQcTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { itemCheckDetailApi } from '@/api/quote'
// sampleInfoStatusEnum
import { groupBy, orderBy, uniqBy } from 'lodash'

import { Menu, Modal, message } from 'ant-design-vue'

const MenuItem = Menu.Item

const emit = defineEmits(['success', 'batchDelSuccess'])
const props = defineProps({
  rowSelect: { type: Object, required: false, default: () => ({}) }
})
// 检测任务详情
const { actionCard, actionButton } = useActionProps()
const detailListData = reactive({ statuses: [], batchId: null })
const showDetailListModal = (status, batchId) => {
  detailListData.statuses.splice(0, detailListData.statuses.length)
  detailListData.statuses.push(status)
  detailListData.batchId = batchId
}
const { modalAction: detailListModalAction, modalProps: detailListModalProps } = useFullModal({ title: '任务明细' }, null, null, null, showDetailListModal)

// 添加批次
const scheduleRef = ref()
const scheduleOkFun = () => {
  scheduleRef.value?.handleSubmit()
}
const { modalProps: addBatchModalProps, modalAction: addBatchModalAction } = useFullModal({ title: '新建批次' }, scheduleOkFun)
const addBatchData = reactive({
  tasks: [],
  batchGroupId: null
})
const addBatchAction = {
  cancel: () => {
    addBatchModalAction.cancel()
  },

  success: () => {
    addBatchModalAction.cancel()
    emit('success')
  }
}

// 自动生成批次
const autoBatchVisible = ref(false)
const confirmLoading = ref(false)
const { timeFormat, moment } = useDate()
const autoBatchQueryParam = reactive({
  taskBeginQueryBeginTime: '',
  taskBeginQueryEndTime: ''
})

// 预设常用日期范围
const ranges = reactive({
  本日: [moment(), moment()],
  本周: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  本月: [moment().startOf('month'), moment().endOf('month')],
  本季: [moment().startOf('quarter'), moment().endOf('quarter')],
  本年: [moment().startOf('year'), moment().endOf('year')]
})

const autoBatchRanges = ref([])
const taskList = ref([])
const sampleInfoList = ref([])
const actualTaskList = ref([])
const itemInfos = ref([])
// // 自动批次成功后返回的id集合
// const batchIds=ref([])
const handleCheckOrderTimeChange = (value) => {
  if (value.length > 0) {
    autoBatchQueryParam.taskBeginQueryBeginTime = timeFormat(value[0], 'YYYY-MM-DD') + ' 00:00:00'
    autoBatchQueryParam.taskBeginQueryEndTime = timeFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59'
  } else {
    autoBatchQueryParam.taskBeginQueryBeginTime = ''
    autoBatchQueryParam.taskBeginQueryEndTime = ''
  }
}
const autoBatchShow = () => {
  autoBatchQueryParam.taskBeginQueryBeginTime = timeFormat(moment().startOf('isoWeek'))
  autoBatchQueryParam.taskBeginQueryEndTime = timeFormat(moment().endOf('isoWeek'))
  autoBatchRanges.value = [moment().startOf('isoWeek'), moment().endOf('isoWeek')]
  taskList.value = []
  sampleInfoList.value = []
  actualTaskList.value = []
  itemInfos.value = []
  autoBatchVisible.value = true
}
const autoBatchOkFun = () => {
  console.log('哒哒哒大')
  confirmLoading.value = true
  let param = {
    page: false,
    planed: false,
    queryType: '1'
  }
  if (autoBatchQueryParam.taskBeginQueryBeginTime && autoBatchQueryParam.taskBeginQueryEndTime) {
    param.taskBeginQueryBeginTime = autoBatchQueryParam.taskBeginQueryBeginTime
    param.taskBeginQueryEndTime = autoBatchQueryParam.taskBeginQueryEndTime
  }
  checkTaskApi.list(param).then((res) => {
    console.log(res)
    taskList.value = res.data
    if (res.data.length > 0) {
      let sampleIds = res.data.filter((item) => !labQcTypeEnum.includes(item.sampleType)).map((item) => item.sampleId)
      console.log(sampleIds);
      if (sampleIds.length > 0) {
        querySampleInfos(sampleIds)
      }else{
        message.warning('暂无需要检测的任务，请先安排任务')
        confirmLoading.value = false
      }
    } else {
      message.warning('暂无任务，请先安排任务')
      confirmLoading.value = false
    }
  })
}
const querySampleInfos = (sampleIds) => {
  tsSampleinfoApi.postList({ ids: sampleIds, page: false }).then((res) => {
    if (res.code === 0) {
      const data = res.data || []
      sampleInfoList.value = orderBy(data, 'type')
      // 获取实际提交的任务，如果样品被移除 认为样品不在此批次内，故需要计算实际提交的任务
      let sampleIds = sampleInfoList.value.map((item) => item.id)
      actualTaskList.value = taskList.value.filter((item) => sampleIds.includes(item.sampleId))
      itemInfos.value =
        uniqBy(
          actualTaskList.value.map((item) => ({ cateId: item.cateId, cateName: item.cateName, itemId: item.itemId, itemName: item.itemName })),
          (item) => item.cateId + '-' + item.itemId
        ) || []
      if (itemInfos.value.length > 0) {
        queryCheckGuideDetail(itemInfos.value)
      } else {
        message.warning('暂无需要检测的任务，请先安排任务')
        confirmLoading.value = false
      }
    } else {
      message.error(`获取样品信息错误信息:${res.msg}`)
      confirmLoading.value = false
    }
  })
}
const queryCheckGuideDetail = (itemInfos) => {
  itemCheckDetailApi.listDetail({ page: false, items: itemInfos.map((item) => item.cateId + '-' + item.itemId) }).then((res) => {
    if (res.code === 0) {
      if (res.data) {
        const data = orderBy(res.data, 'defaultFlag', 'desc')
        let checkGuideDetailCateMap = groupBy(data, (item) => item.cateId + '_' + item.itemId)
        let selectCheckGuideMap = Object.values(checkGuideDetailCateMap).reduce((acc, items) => {
          const itemId = items[0].itemId
          const cateId = items[0].cateId
          const key = `${cateId}_${itemId}`
          acc[key] || (acc[key] = items[0].id)
          return acc
        }, {})
        autoBatchSubmit(checkGuideDetailCateMap, selectCheckGuideMap)
      } else {
        message.warning('任务无对应的检测方法，请先配置检测方法！')
        confirmLoading.value = false
      }
    } else {
      message.error(res.msg)
      confirmLoading.value = false
    }
  })
}

const autoBatchSubmit = (checkGuideDetailCateMap, selectCheckGuideMap) => {
  const taskGroup = groupBy(actualTaskList.value, (item) => item.cateId + '_' + item.itemId + '_' + item.methodsId)
  const saveData = Object.values(taskGroup).reduce((acc, item) => {
    const firstItem = item[0]
    let checkDetailId = null
    // 检测明细详细信息
    const checkDetail = checkGuideDetailCateMap[firstItem.cateId + '_' + firstItem.itemId]
    if (firstItem.methodsId) {
      let cd = checkDetail.filter((it) => it.methodId === firstItem.methodsId)
      if (cd.length > 0) {
        checkDetailId = cd[0].id
      } else {
        checkDetailId = selectCheckGuideMap[firstItem.cateId + '_' + firstItem.itemId]
      }
    } else {
      checkDetailId = selectCheckGuideMap[firstItem.cateId + '_' + firstItem.itemId]
    }

    if (checkDetailId) {
      const chechedCheckDetail = checkDetail.filter((it) => it.id === checkDetailId)
      const taskList = []
      item.forEach((it) => {
        taskList.push({ ...it, methodId: chechedCheckDetail[0].methodId, methodName: chechedCheckDetail[0].methodName })
      })
      acc.push({ taskList: taskList, checkDetailId, cateId: firstItem.cateId, itemId: firstItem.itemId })
    }
    return acc
  }, [])
  if (!saveData.length) {
    message.error('暂无需要保存的数据，请先确定检测方法！')
    confirmLoading.value = false
    return false
  }
  console.log('saveData', saveData)
  checkTaskBatchGroupApi
    .saveWithDetail({ detailList: saveData, type: 'vuejob', createType: 'auto' })
    .then((res) => {
      if (res.code === 0) {
        message.success(res.msg)
        autoBatchCancelFun()
        emit('success')
        // batchIds.value=res.data
        // batchReview()
      } else {
        message.error(`批次生成失败 :${res.msg}`)
      }
    })
    .finally(() => {
      confirmLoading.value = false
    })
}

// 自动批量复核
const batchReview = () => {
  let reviewData = []
  batchIds.value.forEach((item) => {
    reviewData.push({
      id: item,
      checkUser: null,
      checkPwd: null,
      reviewStatus: '2',
      reviewNote: null
    })
  })
  checkBatchApi.batchReview(reviewData).then((res) => {
    if (res.code === 0) {
      batchCheck()
    } else {
      message.error(res.msg)
    }
  })
}

// 自动批量审核
const batchCheck = () => {
  let checkData = []
  batchIds.value.forEach((item) => {
    reviewData.push({
      id: item,
      checkUser: null,
      checkPwd: null,
      checkStatus: '2',
      checkNote: null
    })
  })
  checkBatchApi.batchCheck(checkData).then((res) => {
    if (res.code === 0) {
      // message.success(res.msg)
      autoBatchCancelFun()
      emit('success')
    } else {
      message.error(res.msg)
    }
  })
}

const autoBatchCancelFun = () => {
  autoBatchVisible.value = false
  // confirmLoading.value = false
  autoBatchQueryParam.taskBeginQueryBeginTime = ''
  autoBatchQueryParam.taskBeginQueryEndTime = ''
  autoBatchRanges.value = []
  taskList.value = []
  sampleInfoList.value = []
  actualTaskList.value = []
  itemInfos.value = []
}

// 安排明细
const { modalAction: taskDetailModalAction, modalProps: taskDetailModalProps } = useFullModal({ title: '项目实验室人员安排明细' })

// 批量作废
const batchDel = () => {
  Modal.confirm({
    title: '确认作废?',
    content: '您确认要删除选中的数据吗？',
    onOk: () => {
      checkBatchApi.deleteCheckTaskBatch({ ids: props.rowSelect.selectedRowKeys }).then((res) => {
        if (res.code === 0) {
          if (typeof res.data === 'string') {
            message.success(res.data)
          } else {
            message.success(res.msg)
          }
          emit('batchDelSuccess')
        } else {
          message.error(res.msg)
        }
      })
    }
  })
}

// 批次合并
const batchMerge = () => {
  Modal.confirm({
    title: '确认合并?',
    content: '您确认要合并选中的数据吗？',
    onOk: () => {
      let param = []
      props.rowSelect.selectedRows.forEach((item) => {
        let obj = {
          id: item.id,
          metchId: item.methodId
        }
        param.push(obj)
      })
      checkBatchApi.mergeaBatch(JSON.stringify(param)).then((res) => {
        if (res.code === 0) {
          if (typeof res.data === 'string') {
            message.success(res.data)
          } else {
            message.success(res.msg)
          }
          emit('batchDelSuccess')
        } else {
          message.error(res.msg)
          emit('batchDelSuccess')
        }
      })
    }
  })
}

defineExpose({
  detailListShow: detailListModalAction.show
})
</script>

<template>
  <div>
    <a-card v-bind="actionCard">
      <a-dropdown-button v-bind="actionButton" @click="detailListModalAction.show(null, null)">
        任务列表
        <template #overlay>
          <a-menu>
            <menu-item key="1" @click="detailListModalAction.show(checkTaskStatusEnum.scheduled, null)">待开始</menu-item>
            <menu-item key="2" @click="detailListModalAction.show(checkTaskStatusEnum.processing, null)">进行中</menu-item>
            <menu-item key="3" @click="detailListModalAction.show(checkTaskStatusEnum.completed, null)">已完成</menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <!-- <div v-if="props.rowSelect && props.rowSelect.selectedRowKeys.length">
        <a-button v-action:check-task:batchDel v-bind="actionButton" @click="batchDel"><a-icon type="delete" />批量作废</a-button>
        <a-button v-bind="actionButton" @click="batchMerge"><a-icon type="delete" />批次合并</a-button>
      </div> -->
      <a-dropdown v-bind="actionButton" v-if="props.rowSelect && props.rowSelect.selectedRowKeys.length">
        <a-menu slot="overlay">
          <a-menu-item key="1" v-action:check-task:batchDel @click="batchDel">
            <a-icon type="delete" />
            批量作废
          </a-menu-item>
          <a-menu-item key="2" @click="batchMerge">
            <a-icon type="copy" />
            批次合并
          </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">
          批量操作
          <a-icon type="down" />
        </a-button>
      </a-dropdown>
      <a-tooltip title="点我添加批次信息">
        <a-button icon="plus" type="primary" v-bind="actionButton" @click="addBatchModalAction.show">批次</a-button>
      </a-tooltip>

      <a-button v-action:check-order:schedule v-bind="actionButton" @click="taskDetailModalAction.show">任务明细</a-button>
      <a-button type="primary" v-bind="actionButton" @click="autoBatchShow">自动批次</a-button>
    </a-card>

    <!-- 检测任务明细 -->
    <a-modal v-bind="detailListModalProps">
      <check-task-list v-bind="detailListData" />
      <template #footer>
        <a-button @click="detailListModalAction.cancel()">关闭</a-button>
      </template>
    </a-modal>

    <!-- 检测准备按步骤 -->
    <minimizable-modal :modal-action="addBatchModalAction" :modal-props="addBatchModalProps" :tag-style="{ bottom: '54px' }">
      <check-task-schedule-step v-bind="addBatchData" ref="scheduleRef" v-on="addBatchAction" />
    </minimizable-modal>

    <!-- 项目实验室人员安排明细  -->
    <a-modal v-bind="taskDetailModalProps">
      <check-task-detail-ureport />
      <template #footer>
        <a-button @click="taskDetailModalAction.cancel()">关闭</a-button>
      </template>
    </a-modal>

    <!-- 自动生成批次 -->
    <a-modal title="自动批次" :visible="autoBatchVisible" @ok="autoBatchOkFun" @cancel="autoBatchCancelFun" :confirmLoading="confirmLoading">
      <a-form>
        <a-form-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" label="安排时间">
          <a-range-picker format="YYYY-MM-DD" allow-clear v-model="autoBatchRanges" @change="handleCheckOrderTimeChange" :ranges="ranges" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="autoBatchCancelFun">取消</a-button>
        <a-popconfirm title="确定自动生成批次吗?" @confirm="() => autoBatchOkFun()">
          <a-button type="primary" :loading="confirmLoading">确定</a-button>
        </a-popconfirm>
      </template>
    </a-modal>
  </div>
</template>

<style scoped></style>
