<template>
  <a-modal id="check-form" v-bind="fullModal" v-on="fullModal">
    <div class="utabs" style="height: 100%">
      <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
        <template v-for="obj of templateList">
          <a-tab-pane :closable="false" :key="obj.templateCode" :tab="obj.templateName" style="height: 100%" v-if="curIdx === obj.templateCode">
            <a-row style="height:100%;">
              <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
            </a-row>
          </a-tab-pane>
        </template>
      </a-tabs>
    </div>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { anonProjectApi } from '@/api/project'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'RecordForm',
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null
    },
    solutionId: {
      type: [String, Number],
      required: false,
      default: null
    },
    siteModelId: {
      type: String,
      required: false,
      default: ''
    }

  },
  data () {
    return {
      fullModal: Object.assign({}, fullModalData, {
        title: '原始记录表',
        closable: true,
        // data: {
        //   params: {
        //     quoteId: null,
        //     projId: null,
        //     fileName: null
        //   }
        // },
        show: () => {
          this.fullModal.visible = true
        },
        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        }
      }),
      templateList: [],
      curIdx: null,
      templateParam: {
        page: false,
        templateType: fileTemplateUreportTypeEnum.standardCurveLine
      }
    }
  },
  computed: {
    iframeSrc: function () {
      if (this.templateList && this.templateList.length > 0) {
        // const template = this.templateList[this.curIdx]
        const templist = this.templateList.filter(item => item.templateCode === this.curIdx)
        const template = templist.length > 0 ? templist[0] : null
        const fileName = '标准曲线原始记录表'
        // if (this.localParams.fileName) {
        //   fileName = this.localParams.fileName
        // }
        if (template !== null) {
          if (this.solutionId) {
            return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&_n=${fileName}&id=${this.id}&solutionId=${this.solutionId}` // _t=1,2,3,4,5&
          }
          return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&_n=${fileName}&id=${this.id}`
        }
        // return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&_n=${fileName}`
      }
      return ''
    }
  },
  created () {
    // this.curIdx = 0
    this.listFileTemplate()
  },
  watch: {
    siteModelId: {
      handler (newValue, oldValue) {
        if (newValue && typeof newValue !== 'undefined') {
          this.curIdx = 'template_curve_c' + newValue
        }
      },
      immediate: true
    }
  },
  methods: {
    listFileTemplate () {
      anonProjectApi.listFileTemplate(this.templateParam).then(result => {
        if (result.code === 0) {
          this.templateList = result.data
          if (this.curIdx === null) {
            this.curIdx = this.templateList[0].templateCode
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>

<style scoped lang="less">
  .utabs {
    .archive {
      margin: 0px 5px;
      border: 1px solid #91d5ff;
      background-color: #e6f7ff;
      padding: 8px;
      border-radius: 4px;
    }

    /deep/ .ant-tabs-content {
      height : 100%;
    }
  }
</style>
