// 音频播放
<script>
import { Howl } from 'howler'
export default {
  name: 'AppAudiojs',
  props: {
    src: {
      type: Array,
      default () {
        return []
      }
    },
    loop: {
      type: Boolean,
      default: false
    },
    preload: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // ins: null
    }
  },
  methods: {
    play () {
      if (this.ins) {
        this.ins.play()
      }
    },
    __init () {
      if (!this.ins) {
        this.ins = new Howl({
          src: this.src,
          loop: this.loop,
          preload: this.preload,
          autoplay: this.autoplay
        })
      }
    },
    __destroy () {
      if (this.ins) {
        this.ins.unload()
        this.ins = null
      }
    }
  },
  render () {
    return {}
  },
  mounted () {
    this.__init()
  },
  beforeDestroy () {
    this.__destroy()
  }
}
</script>
