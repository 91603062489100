<template>
  <div>
    <template v-for="v in factorList">
      <a-card :title="getTitle(v)">
        <template v-if="model.projId!=undefined">
          <a-radio-group name="radioGroup" slot="extra" v-model="v.type">
            <a-radio :value="'radio'">单选</a-radio>
            <a-radio :value="'checkbox'">多选</a-radio>
          </a-radio-group>
          <template  v-if="v.valueSelected" slot="extra">
            <span style="font-weight:bold; ">限值关系：</span>
            <a-radio-group name="radioGroup"  v-model="v.relation">
              <a-radio :value="1">或</a-radio>
              <a-radio :value="0">并且</a-radio>
            </a-radio-group>
          </template>
        </template>
        <attribute-combine-limit-value ref="combineValue" :attributeList="attributeList"
                                       :model="v" @valueSelect="val=>valueSelect(v,val)" ></attribute-combine-limit-value>
      </a-card>
      <br />
    </template>
    <div v-if="factorList.length==0" style="text-align: center;font-size: 14px; color: rgba(0, 0, 0, 0.45);">
      暂无数据
    </div>

  </div>
</template>

<script>
import { attributeApi,standardItemFactorApi,Get,Post,Del} from '@/api/quote'
import AttributeCombineLimitValue from '@/pages/common/modal/AttributeCombineLimitValue'

export default {
  name: 'StandardItemFactor',
  components: { AttributeCombineLimitValue },
  props: {
    model: {
      type: Object,
      default: ()=>{return {}}
    }
  },
  data () {
    return {
      title:"属性关系",
      screenWidth:600,
      visible:false,
      factorList:[],
      attributeList:[],
      saveList:[],
    }
  },
  computed: {
    queryParam () {
      return {
        standItemId:this.model.id
      }
    },
  },

  methods: {
    valueSelect(v,val){
      if(this.model.projId){
        v.valueSelectedList = val
        if(val.length>1){
          v.valueSelected = true
        }else{
          v.valueSelected = false
        }
        const projItemLimitData = {standardItemId:this.model.id,factorId:v.id,valueSelectdList:val,relation:v.relation}
        this.$emit("limitData",projItemLimitData)
      }

    },
    getTitle(factor){
      let title = factor.name
      let unit = factor.unit
      if(unit == undefined || unit==''){
        unit= '无'
      }
      return title+"(单位："+unit+")"
    },
    loadAttributeList (){
      return new Promise((resolve, reject) =>{
        Get(attributeApi.listWithValue,{type:this.model.type,cateId:this.model.cateId}).then(res=>{
          if(res.code === 0){
            resolve(res.data)
          }else{
            resolve([])
          }
        })
      })
    },

    loadData:async function(){
      this.attributeList = await this.loadAttributeList()

      standardItemFactorApi.list(this.queryParam).then(res=>{
        if(res.code === 0){
          this.factorList = res.data.reduce((temp,item)=>{
            if(this.model.projId){
              item.valueSelected = false
              item.type = 'radio'
              item.relation = 1
            }
            if(this.model.savedData!=undefined){
              const savedData = this.model.savedData.filter(r=>r.factorId==item.id)
              if(savedData.length>1){
                item.valueSelected = true
                item.type = 'checkbox'
                item.relation = savedData[0].relation
              }
              item.selectKeys = savedData.map(item=>item.attrListId)
            }
            temp.push(item)
            return temp
          },[])
        }
      })
    },


  },
  watch: {
    queryParam: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal.standItemId) {
         this.loadData()
        }
      }
    },

  }
}
</script>

