<template>
  <a-card :bordered="false" :title="`项目编号:${info.projNo}`">
    <div style="display: flex;width: 100%;">
      <div style="flex: auto;color: rgba(0, 0, 0, 0.45);line-height: 22px;">
        <detail-list size="small" :col="itemCol" class="detail-layout">
          <detail-list-item term="项目名称">
            <a @click="gotoFlowDetail(info)"><a-icon type="share-alt" style="margin-right: 4px"/>{{ info.projNo }}
            <template v-if="info.projNumber">/ {{ info.projNumber }}</template></a>
          </detail-list-item>
          <detail-list-item term="项目名称">{{ info.projName }}</detail-list-item>
          <detail-list-item term="报告类别">{{ reportTypeMap[info.projReportType] }}</detail-list-item>
          <detail-list-item term="负责人">{{ info.projContact | nullReplace }}</detail-list-item>
          <detail-list-item term="联系电话">{{ info.projContactPhone | nullReplace }}</detail-list-item>
<!--          <detail-list-item term="预计时间">{{ info.projScheduledStart }}-{{ info.projScheduledEnd }}</detail-list-item>-->
<!--          <detail-list-item term="实际时间">{{ info.projActualStart }}-{{ info.projActualEnd }}</detail-list-item>-->
          <detail-list-item term="委托单位">{{ info.entrustCustomerName | nullReplace }}</detail-list-item>
          <detail-list-item term="项目描述">{{ info.projDescription | nullReplace }}</detail-list-item>
        </detail-list>
      </div>
      <div style="flex: 0 1 auto;margin-left: 88px;min-width: 242px;text-align: right;">
        <a-row class="status-list">
          <a-col :xs="12" :sm="12">
            <div class="text">状态</div>
            <div class="heading" v-if="info.projStatus === '0'">进行中</div>
            <div class="heading" v-else-if="info.projStatus === '1'">完结</div>
            <div class="heading" v-else-if="info.projStatus === '2'">临时终止</div>
          </a-col>
          <a-col :xs="12" :sm="12">
            <div class="text">订单金额</div>
            <div class="heading"><span style="color: red">{{ currency(info.quoteFinalAmount) }}</span></div>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- actions -->
    <!--    <template slot="extra">-->
    <!--      <a-button-group style="margin-right: 4px;">-->
    <!--        <a-button>操作</a-button>-->
    <!--        <a-button>操作</a-button>-->
    <!--        <a-button><a-icon type="ellipsis"/></a-button>-->
    <!--      </a-button-group>-->
    <!--      <a-button type="primary" >主操作</a-button>-->
    <!--    </template>-->
  </a-card>
</template>

<script>

import { projectApi, projectFlowKey } from '@/api/project'
import { currency } from '@/utils/util'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item

export default {
  name: 'ProjView',
  components: {
    DetailList,
    DetailListItem
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      itemCol: 2,
      info: {}
    }
  },
  methods: {
    currency,
    get () {
      if (this.id) {
        projectApi.get({ id: this.id }).then(result => {
          if (result.code === 0) {
            this.info = result.data
          }
        })
      }
    },
    gotoFlowDetail (r) {
      projectApi.listProcess({ key: projectFlowKey.projectFlow, mxId: r.id }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            var obj = res.data[0]
            this.$router.push({ name: 'flow-process-detail', params: { processId: obj.processInstanceId } })
          } else {
            this.$message.warn(`未找到相关流程`)
          }
        }
      })
    },
    gotoProjectCheckFlowDetail (r) {
      projectApi.listProcess({ key: projectFlowKey.projectCheckFlow, mxId: r.auditId }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            var obj = res.data[0]
            this.$router.push({ name: 'flow-process-detail', params: { processId: obj.processInstanceId } })
          } else {
            this.$message.warn(`未找到相关流程`)
          }
        }
      })
    }
  },
  filters: {
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    }
  },
  watch: {
    id: {
      handler (newVal, oldVal) {
        this.get()
      },
      immediate: true
    },
    data: {
      handler (newVal, oldVal) {
        this.info = newVal
      },
      immediate: true
    }
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
  }
}
</script>

<style lang="less" scoped>
  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .text {
    }
    .status-list {
      text-align: left;
    }
  }
</style>
