<template>
  <div class="check-task-project-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-task-project-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table v-bind="contentTable" v-on="contentTable.action" :rowSelection="customRowSelection" :scroll="scrollProp">

      <template #scheduleTime="item,{beginTime,endTime}">
        开始：<span class="green">{{ beginTime|dayFilter }}</span><br/>
        结束：<span class="red">{{ endTime|dayFilter }}</span>
      </template>

      <template #checkTime="item,{startTime,doneTime}">
        开始：<span class="green">{{ startTime|dayFilter }}</span><br/>
        结束：<span class="red">{{ doneTime|dayFilter }}</span>
      </template>

      <template #detectionLeaderName="item, {detectionLeader}">
        <a-tooltip :getPopupContainer="getCurrentDocument" :overlayStyle="{minWidth:'max-content'}">
          <template #title>
            <user-base-info :username="detectionLeader"></user-base-info>
          </template>
          {{ item }}
        </a-tooltip>
      </template>

      <template #checkUserNames="item, record">
        <template v-if="item">
          <a-tag v-for="(checkUser,index) in item.split(',')" :key="checkUser" :style="{margin:'2px'}" color="blue">
            <a-tooltip :getPopupContainer="getCurrentDocument" :overlayStyle="{minWidth:'max-content'}">
              <template #title>
                <user-base-info :username="record.checkUsers.split(',')[index]"></user-base-info>
              </template>
              {{ checkUser }}
            </a-tooltip>
          </a-tag>
        </template>
      </template>

      <template #scheduleProgress="item,{projId,offlineId,pendingScheduleNum,scheduleSum}">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            待安排：<span class="red focus-num mar-h5">{{ pendingScheduleNum }}</span><br/>
            已安排：<span class="red focus-num mar-h5">{{ scheduleSum - pendingScheduleNum }}</span><br/>
            总计：<span class="red focus-num mar-h5">{{ scheduleSum }}</span><br/>
          </template>
          <a-progress :percent="getProgressValue(offlineId,scheduleSum-pendingScheduleNum,scheduleSum)" :style="{width:'90%'}" @click="toSchedule(projId)"></a-progress>
        </a-tooltip>
      </template>

      <template #doneProgress="item,{projId,offlineId,pendingNum,processNum,doneNum,taskSum}">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            待开始：<span class="red focus-num mar-h5">{{ pendingNum }}</span><br/>
            进行中：<span class="red focus-num mar-h5">{{ processNum }}</span><br/>
            已完成：<span class="green focus-num mar-h5">{{ doneNum }}</span><br/>
          </template>
          <a-progress @click="toCheckTask(projId)" :percent="getProgressValue(offlineId,doneNum,taskSum)" :style="{width:'90%'}"></a-progress>
        </a-tooltip>
      </template>

      <template #reviewProgress="item,{offlineId,recordReviewPendingNum,recordReviewToAuditNum,recordReviewPassNum,recordReviewRejectNum,taskSum,}">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            待提交：<span class="red focus-num mar-h5">{{ recordReviewPendingNum }}</span><br/>
            待复核：<span class="red focus-num mar-h5">{{ recordReviewToAuditNum }}</span><br/>
            复核通过：<span class="green focus-num mar-h5">{{ recordReviewPassNum }}</span><br/>
            复核驳回：<span class="green focus-num mar-h5">{{ recordReviewRejectNum }}</span><br/>
          </template>
          <a-progress :percent="getProgressValue(offlineId,recordReviewPassNum+recordReviewRejectNum,taskSum)" :style="{width:'90%'}"></a-progress>
        </a-tooltip>
      </template>

      <template #checkProgress="item,{offlineId,recordCheckPendingNum,recordCheckToAuditNum,recordCheckPassNum,recordCheckRejectNum,taskSum}">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            待提交：<span class="red focus-num mar-h5">{{ recordCheckPendingNum }}</span><br/>
            待审核：<span class="red focus-num mar-h5">{{ recordCheckToAuditNum }}</span><br/>
            审核通过：<span class="green focus-num mar-h5">{{ recordCheckPassNum }}</span><br/>
            审核驳回：<span class="green focus-num mar-h5">{{ recordCheckRejectNum }}</span><br/>
          </template>
          <a-progress :percent="getProgressValue(offlineId,recordCheckPassNum+recordCheckRejectNum,taskSum)" :style="{width:'90%'}"></a-progress>
        </a-tooltip>
      </template>

      <template #offline="item,{offlineId,offlineCheckUser,offlineCheckNote,offlineStatus}">
        <template v-if="offlineId">
          <template v-if="offlineCheckUser">
            <a-tooltip :getPopupContainer="getCurrentDocument">
              <template #title>
                <p>审核人：{{ offlineCheckUser }}</p>
                <p>审核意见：{{ offlineCheckNote }}</p>
              </template>
              <a-tag :color="checkTaskOfflineStatusDataMap[offlineStatus].color">{{ checkTaskOfflineStatusDataMap[offlineStatus].label }}</a-tag>
            </a-tooltip>
          </template>
          <template v-else>
            <a-tag :color="checkTaskOfflineStatusDataMap[offlineStatus].color">{{ checkTaskOfflineStatusDataMap[offlineStatus].label }}</a-tag>
          </template>
        </template>
        <template v-else>
          <a-tag color="blue">线上</a-tag>
        </template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { checkTaskProjectTableDefaultColumn as defaultColumn } from '@/pages/cloud-quote/check-task/modules/projectTable/colunms'

import { checkTaskApi } from '@/api/check/checkTaskApi'
import { checkTaskOfflineStatusDataMap } from '@/api/check/checkTaskOfflineConstant'

import { CustomizeColumn } from '@/components/Columns'
import UserBaseInfo from '@/pages/cloud-quote/person/UserBaseInfo'

export default {
  components: { UserBaseInfo, sTable, CustomizeColumn },
  mixins: [componentTable],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    defaultOtherHeight: { type: Number, required: false, default: 0 },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      checkTaskOfflineStatusDataMap,
      contentTable: {
        rowKey: 'projId'
      }
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {

    /**
     * 跳转到检测安排界面
     * @param projId
     */
    toSchedule (projId) {
      this.$router.push({ name: 'check-order', params: { projId }, props: (route) => ({ projId }) })
    },

    // 跳转到检测任务
    toCheckTask (projId) {
      this.$router.push({ name: 'check-task', params: { projId }, props: (route) => ({ projId }) })
    },

    /**
     * 获取进度值,保留两位小数
     * @param offline 线下完成信息
     * @param curVal 当前值
     * @param sum 总数
     * @returns {number} 进度值
     */
    getProgressValue (offline, curVal, sum) {
      return (offline && 100) || (Number((curVal / sum * 100).toFixed(2)))
    },

    /**
     * 加载数据
     * @param param 查询参数
     * @returns {*|Promise<unknown>}
     */
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        return checkTaskApi.projectList(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '_index';
</style>
