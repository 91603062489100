<template>
  <div>
    <s-table v-bind="contentTable" v-on="contentTable.action">
      <template slot="num" slot-scope="data,record,index">
        <span :style="{fontWeight:'bolder'}">{{ index + 1 }}</span>
      </template>

      <template slot="checkType" slot-scope="checkType">
        <a-tag v-if="checkType==='1'" color="blue">现场检测</a-tag>
        <a-tag v-else color="green">实验室监测</a-tag>
      </template>

      <template #sampleId="item,record">
        {{ record.checkSampleId ||record.sampleId }}
      </template>

      <template #sampleType="data">
        <a-tag :color="sampleInfoTypeDataMap[data].color">
          {{ data === sampleInfoTypeEnum.password ? sampleInfoTypeDataMap[sampleInfoTypeEnum.normal].label : sampleInfoTypeDataMap[data].label }}
        </a-tag>
      </template>

      <template slot="dataStatus" slot-scope="dataStatus,record">
        <check-task-result-status :data="record"/>
      </template>

      <template slot="items" slot-scope="items,data">
        <el-tooltip placement="top">
          <div slot="content">
            <template v-if="data.testStandard">
              <dl>
                <dt>检测标准</dt>
                <dd> 【{{ data.testStandardCode }}】{{ data.testStandardName }}</dd>
              </dl>
              <dl>
                <dt>受控</dt>
                <dd>
                  {{ data.testFactorName }}:
                  <template v-if="data.testType==='1'">{{ data.testLimitValue }}{{ data.testUnit || '' }}</template>
                  <template v-else-if="data.testType==='2'">{{ data.testLowerLimitValue }}{{ data.testUnit || '' }}-{{ data.testUpperLimitValue }}{{ data.testUnit || '' }}</template>
                  <template v-else>{{ data.testLimitValue }}</template>
                </dd>
              </dl>
            </template>
            <template v-else>
              暂未配置标准
            </template>
          </div>
          <span class="detail"> {{ data.cateName }}/{{ data.itemName }}</span>
        </el-tooltip>
      </template>

      <template #deviceNames="deviceNames">
        <template v-if="deviceNames">
          <span v-for="(item,index) in deviceNames.split(',')" :key="index" :title="item">
            <a-tag class="device-title" color="blue"> {{ item }}</a-tag>
          </span>
        </template>
        <template v-else>
          暂无
        </template>

      </template>

      <template slot="limit" slot-scope="items,record">
        <div v-if="record.editable">
          <a-input-number v-model="record.down" v-bind="{precision:2}" placeholder="下限"></a-input-number>
          <span :style="{margin:'0 5px'}">~</span>
          <a-input-number v-model="record.up" v-bind="{precision:2}" placeholder="上限"></a-input-number>
        </div>
        <div v-else>
          <span class="highlight">{{ record.down }}</span>~<span class="highlight">{{ record.up }}</span>
        </div>
      </template>

      <template slot="originalValue" slot-scope="value,record">
        <div v-if="record.editable">
          <a-input-number v-model="record.originalValue"></a-input-number>
        </div>
        <span v-else class="highlight">
          {{ value }}
        </span>
      </template>

      <template slot="value" slot-scope="value,record">
        <div v-if="record.editable">
          <a-select v-model="record.resultType" :style="{width:'100px',margin:'0 0 10px 0'}">
            <a-select-option v-for="(item) in Object.keys(resultDataType)" :key="item">
              {{ resultDataType[item] }}
            </a-select-option>
          </a-select>
          <a-input-number v-if="record.resultType!=='3'" v-model="record.value"></a-input-number>
        </div>
        <span v-else class="highlight">
          <template v-if="record.resultType==='1'">{{ record.value }}</template>
          <template v-else-if="record.resultType==='2'">{{ record.value }}L</template>
          <template v-else-if="record.resultType==='3'">{{ resultDataType[record.resultType] }}</template>
          <template v-else> 暂无</template>
        </span>
      </template>

      <template slot="accessory" slot-scope="accessory">
        <div v-for="(item,index) in JSON.parse(accessory)" :key="index+`_accessory`" :style="{padding:'5px'}">
          <a title="双击查看照片" @dblclick="handlePreview(item)">{{ item.name }}</a>
        </div>
      </template>

      <template #checkTime="time">
        <span>{{ time | minuteFilter }}</span>
      </template>

      <template #createTime="time">
        <span>{{ time | minuteFilter }}</span>
      </template>

      <template v-if="showAction" slot="action" slot-scope="status,record">
        <a-button v-if="!record.editable" :style="{marginRight: '8px'}" size="small" @click="showDetailView(record)">详情</a-button>

        <template v-if="record.createUser===loginUser.username &&!record.editable &&record.status==='1'">

          <a-button v-if="record.dataStatus===checkTaskResultDataStatusEnum.perfected&&record.sampleType===sampleInfoTypeEnum.normal" :style="{marginRight: '8px'}" size="small" @click="submitApproval(record)">提交审核
          </a-button>

          <a-button v-if="checkTaskStatus===checkTaskStatusEnum.processing&&(record.dataStatus===checkTaskResultDataStatusEnum.broken||record.dataStatus===checkTaskResultDataStatusEnum.perfected)" :style="{marginRight: '8px'}" size="small" @click="showModifyView(record)">修改</a-button>

          <a-button
            v-if="checkTaskStatus===checkTaskStatusEnum.processing&&(record.dataStatus===checkTaskResultDataStatusEnum.broken||record.dataStatus===checkTaskResultDataStatusEnum.perfected)"
            v-action:check-task:feedback
            :style="{marginRight: '8px'}"
            size="small"
            type="danger"
            @click="showVoidView(record)">作废</a-button>

          <template v-if="record.dataStatus===checkTaskResultDataStatusEnum.doubt">
            <a-button v-if="!record.done" v-action:check-task:feedback :style="{marginRight: '8px'}" size="small" @click="confirm(record)">无误</a-button>

            <a-button v-if="!record.done" v-action:check-task:feedback :style="{marginRight: '8px'}" size="small" @click="adjust(record)">调整</a-button>
          </template>
        </template>

        <template v-else-if="record.editable&&!record.done">
          <a-popconfirm title="您确定此条调整完成吗？" @confirm="saveAdjust(record)">
            <a>保存</a>
          </a-popconfirm>
        </template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>

    </s-table>

    <div v-if="adjustList.length||confirmList.length" style="float:right;margin:10px 16px 10px 0">
      <a-button v-action:check-task:feedback type="primary" @click="submitChange">提交重审</a-button>
    </div>

    <!-- 图片预览 -->
    <a-modal :footer="null" :visible="previewVisible" @cancel="previewVisible=false">
      <img :src="previewImage" alt="example" style="width: 100%"/>
    </a-modal>

    <!-- 作废 -->
    <a-modal v-if="voidModalProps.visible" v-bind="voidModalProps" v-on="voidModalProps">
      <a-textarea v-model="voidModalProps.record.voidReason" :rows="4" placeholder="请输入作废原因"/>
    </a-modal>

    <!-- 反馈数据组件 -->
    <check-task-feedback v-if="checkTaskFeedbackProps.visible" v-bind="checkTaskFeedbackProps" v-on="checkTaskFeedbackProps"></check-task-feedback>

    <!-- 反馈数据详情组件 -->
    <check-task-feedback-view v-if="checkTaskFeedbackViewProps.visible" v-bind="checkTaskFeedbackViewProps" v-on="checkTaskFeedbackViewProps"></check-task-feedback-view>

  </div>
</template>

<script>
import sTable from '@/components/Table'
import { sysCommonApi } from '@/api/common'
import { checkTaskResultApi } from '@/api/sample'

import { componentTable } from '@/pages/common/mixins/list'

import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import { sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkTaskResultDataStatusEnum, checkTaskResultStatusEnum } from '@/api/check/checkTaskResultConstant'

import CheckTaskFeedback from '@/pages/cloud-quote/check-task/modules/feedBack'
import CheckTaskFeedbackView from '@/pages/cloud-quote/check-task/modules/feedBack/view'
import CheckTaskResultStatus from '@/pages/cloud-quote/check-task/modules/checkTaskResultStatus'
import { checkTaskResultDefaultColumn as defaultColumn } from '@/pages/cloud-quote/check-task/modules/checkResultTable/columns'

export default {
  name: 'CheckResultTable',
  components: { sTable, CheckTaskResultStatus, CheckTaskFeedback, CheckTaskFeedbackView },

  props: {
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) },

    showAction: { type: Boolean, required: false, default: true },
    checkTaskStatus: { type: String, require: true }
  },

  mixins: [componentTable],

  data () {
    return {
      checkTaskStatusEnum,
      checkTaskResultDataStatusEnum,
      checkTaskResultStatusEnum,
      sampleInfoTypeEnum,
      sampleInfoTypeDataMap,

      adjustList: [],

      confirmList: [],

      previewVisible: false,

      previewImage: null,

      checkTaskFeedbackProps: {
        ref: 'checkTaskFeedback',
        visible: false,
        id: null,
        show: () => { this.checkTaskFeedbackProps.visible = true },
        success: this.loadData,
        cancel: () => { this.checkTaskFeedbackProps.visible = false }
      },

      voidModalProps: {
        ref: 'voidModal',
        width: '50%',
        title: '作废',
        visible: false,
        record: null,
        show: () => { this.voidModalProps.visible = true },
        ok: this.handleVoid,
        cancel: () => { this.voidModalProps.visible = false }
      },

      checkTaskFeedbackViewProps: {
        ref: 'checkTaskFeedbackView',
        visible: false,
        id: null,
        show: () => { this.checkTaskFeedbackViewProps.visible = true },
        cancel: () => { this.checkTaskFeedbackViewProps.visible = false }
      },

      contentTable: {
        style: { padding: '16px', width: this.width, background: '#FFFFFF' },
        ref: 'CheckResultTable'
      }
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    loginUser () {
      return { ...this.$store.getters.userInfo }
    },

    resultDataType () {
      return { ...this.$store.state.code.cache.check_result_data_type }
    }
  },

  created () {
    this.$store.dispatch('code/select', 'check_result_data_type')
  },

  methods: {

    // 提交调整内容
    submitChange () {
      this.$confirm({
        title: '确定提交调整的内容了吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          checkTaskResultApi.requestApproval({ adjustList: this.adjustList, confirmList: this.confirmList }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 确认无误
    confirm (record) {
      this.$confirm({
        title: '您确定此条数据准确无误吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.confirmList.push(record)
          this.$set(record, 'done', true)
        }
      })
    },

    // 保存调整内容
    saveAdjust (record) {
      this.adjustList.push(record)
      this.$set(record, 'editable', false)
      this.$set(record, 'done', true)
    },

    // 调整数据
    adjust (record) {
      this.$set(record, 'editable', true)
    },

    // 加载数据
    loadData (param = {}) {
      return checkTaskResultApi.list(Object.assign(param, this.queryParam)).then(res => res)
    },

    // 图片预览
    handlePreview (file) {
      this.previewImage = file.thumbUrl || sysCommonApi.view + '/' + file.url
      this.previewVisible = true
    },

    // 显示修改界面
    submitApproval (record) {
      this.checkTaskFeedbackProps.id = record.id
      checkTaskResultApi.submitApproval(record).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 显示修改界面
    showModifyView (record) {
      this.checkTaskFeedbackProps.id = record.id
      this.checkTaskFeedbackProps.show()
    },

    // 显示详情界面
    showVoidView (record) {
      this.voidModalProps.record = record
      this.voidModalProps.show()
    },

    // 处理作废
    handleVoid () {
      if (!this.voidModalProps.record.voidReason) {
        this.$message.error('请填写作废原因')
        return false
      }
      const { id, voidReason } = this.voidModalProps.record
      checkTaskResultApi.void({ id, voidReason }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.voidModalProps.cancel()
          this.loadData()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 显示详情界面
    showDetailView (record) {
      this.checkTaskFeedbackViewProps.id = record.id
      this.checkTaskFeedbackViewProps.show()
    }
  }
}
</script>

<style scoped>
.highlight {
  font-weight : 700;
  padding     : 5px 10px;
  display     : inline-flex;
  text-align  : center;
}

.device-title {
  display       : inline-block;
  max-width     : 90%;
  white-space   : nowrap;
  overflow      : hidden;
  text-overflow : ellipsis;
}

.standard-info dl {
  padding : 3px 4px;
}

.standard-info dt {
  font-wight    : 500;
  margin-bottom : 6px;
}

.standard-info dt::after {
  content : '：';
}

.standard-info dd {
  color : #DDDDDD;
}
</style>
