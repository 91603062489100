<template>
  <div class="table">
    <template v-if="table.dataSource.length">
      <div v-for="item in table.dataSource" :key="item.projId" class="card">
        <div class="content">
          <div class="symbol">
            <div :class="projectStatusDataMap[item.projStatus].color">{{ projectStatusDataMap[item.projStatus].label }}</div>
          </div>

          <div class="left"><span>{{ item.projSampleSource === '1' ? '采样' : '送样' }}</span></div>
          <div class="right">
            <div class="title"> {{ item.projName }} 【项目编号：{{ item.projNo }}】 【项目负责人：{{ item.projLeaderName }}】</div>
            <div class="detail">
              <div class="item">客户单位：{{ item.csrName }}</div>
              <div class="item">采样负责人：{{ item.sampleLeaderName }}</div>
              <div class="item">采样人员：{{ item.sampleUserNames }}</div>
              <div class="item">
                <a-tooltip>
                  <template #title>
                    待采样：{{ item.tempSampleNum || 0 }}<br/>
                    待交样：{{ item.doneSampleNum || 0 }}<br/>
                    预计总样品：{{ item.sampleSum || 0 }}
                  </template>
                  样品总数：<span class="num-red mar-h5">{{ item.tempSampleNum || 0 }}</span>/<span class="num-yellow mar-h5">{{ item.doneSampleNum || 0 }}</span> / <span class="num-green mar-h5">{{ item.sampleSum || 0 }}</span>
                </a-tooltip>
              </div>
              <div class="item"> 待入库：<a @click="$emit('toDetail',item,sampleInfoStatusEnum.storageApply)"><span class="num-red">{{ item.storageApplyNum || 0 }}</span></a></div>
              <div class="item"> 待出库：<a @click="$emit('toDetail',item,sampleInfoStatusEnum.pickupApply)"><span class="num-red">{{ item.pickupApplyNum || 0 }}</span></a></div>
            </div>
          </div>
        </div>
        <div class="action">
          <slot v-bind="item" name="action"></slot>
        </div>
      </div>

      <div v-if="showPagination" :style="{margin: '16px 0',float:'right'}">
        <a-pagination v-bind="table.pagination" v-on="table.pagination.action"/>
      </div>
    </template>
    <template v-else>
      <div class="no-data">
        <a-icon type="frown-o"/>
        暂无数据
      </div>
    </template>

  </div>

</template>

<script>
import sTable from '@/components/Table'
import mixinList from '@/pages/common/mixins/list'

import { tsSampleinfoApi } from '@/api/sample'

import { sampleInfoStatusEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { projectStatusDataMap } from '@/api/project/projectConstants'

export default {
  components: { sTable },
  mixins: [mixinList],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    rowSelect: { type: Object, required: false, default: () => ({}) },
    pageNo: { type: Number, required: false, default: 1 },
    pageSize: { type: Number, required: false, default: 10 },
    page: { type: Boolean, required: false, default: true }
  },

  data () {
    return {

      sampleInfoStatusEnum,
      projectStatusDataMap,

      table: {
        dataSource: [],
        pagination: {
          showQuickJumper: true,
          showSizeChanger: true,
          current: this.pageNo,
          pageSize: this.pageSize,
          total: 0,
          size: 'small',
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          },
          showSizeChange: (current, pageSize) => {
            this.table.pagination.pageSize = pageSize
          },
          action: {
            change: (pageNo) => {
              this.table.pagination.current = pageNo
            }
          }
        }
      }
    }
  },

  computed: {
    queryParam () {
      const { table: { pagination: { current: pageNo, pageSize } }, page } = this
      return { ...this.$attrs, pageNo, pageSize, page }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  created () {
    this.search()
  },
  methods: {

    search () {
      this.loadData(this.queryParam).then(res => {
        this.table.dataSource.splice(0, this.table.dataSource.length, ...(res.data || []))
        this.table.pagination.total = res.totalCount
      })
    },

    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        return tsSampleinfoApi.projectList(param).then(res => res)
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import "card-table";
</style>
