<template>
  <a-modal v-bind="searchPaymentModal" v-on="searchPaymentModal">
    <a-row v-bind="searchPaymentRow">
      <a-col :md="24" :sm="24">

        <a-tabs v-model="tabModelCode" @change="callback"><!-- defaultActiveKey="1"-->
          <a-button slot="tabBarExtraContent" @click="addPayment">
            添加账号信息
          </a-button>
          <a-tab-pane tab="分包商" key="1">
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入分包商名称" v-model="queryParam.name"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="loadPaymentData(1)" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>

              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="paymentTable"
                  v-on="paymentTable"
                  :pagination="ipagination"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
                  :dataSource="subcontractList"
                  @change="handlePaymentTableChange">
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane tab="商家" key="2" forceRender>
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入商家名称" v-model="queryParam.name"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="loadPaymentData(1)" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>
              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="paymentTable"
                  v-on="paymentTable"
                  :pagination="ipaginationBusiness"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
                  :dataSource="businessList"
                  @change="handlePaymentTableChange">
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane tab="内部人员" key="3" forceRender>
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入人员名称" v-model="queryParam.name"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="loadPaymentData(1)" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>

              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="personTable"
                  v-on="personTable"
                  :pagination="ipaginationStaff"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
                  :dataSource="staffList"
                  @change="handlePaymentTableChange">
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>

          <a-tab-pane tab="外部人员" key="4" forceRender>
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入人员名称" v-model="queryParam.name"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="loadPaymentData(1)" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>

              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="personTable"
                  v-on="personTable"
                  :pagination="ipaginationExternal"
                  :loading="loading"
                  :rowSelection="{selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
                  :dataSource="externalPersonList"
                  @change="handlePaymentTableChange">
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
    <payment-form ref="paymentForm" @ok="loadPaymentData(1)"></payment-form>
  </a-modal>
</template>

<script>
import { ListMixin } from '@/mixins/ListMixin'
import { businessApi } from '@/api/quote'
import { paymentApi } from '@/api/payment'
import { commDictApi } from '@/api/common'
import PaymentForm from '@/pages/flow/module/components/search-payment/PaymentForm'

export default {
  name: 'SearchPaymentModal',
  components: { PaymentForm },
  mixins: [ListMixin],

  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    title: {
      type: String,
      default: '请选择支付对象',
      required: false
    },
    searchType: {
      type: String,
      default: 'projName',
      required: false
    }
  },

  data () {
    return {
      tabModelCode: '1',
      queryParam: {
        name: null
      },
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
      paymentTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '名称', align: 'center', dataIndex: 'name' },
          { title: '开户行', align: 'center', dataIndex: 'bank' },
          { title: '账号', align: 'center', dataIndex: 'bankNbr' }
        ],
        data: parameter => {
          this.loadPaymentData()
        }
        // rowSelection: {
        //   type: 'radio',
        //   onChange: (selectedRowKeys, selectedRows) => {
        //     this.selectedRowKeys = selectedRowKeys
        //     this.selectedRows = selectedRows
        //   }
        // }
      },

      personTable: {
        ref: 'personTable',
        bordered: true,
        size: 'small',
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '姓名', align: 'center', dataIndex: 'name' },
          { title: '开户行', align: 'center', dataIndex: 'bank' },
          { title: '账号', align: 'center', dataIndex: 'bankNbr' }
        ],
        data: parameter => {
          this.loadPaymentData()
        }
        // rowSelection: {
        //   type: 'radio',
        //   onChange: (selectedRowKeys, selectedRows) => {
        //     this.selectedRowKeys = selectedRowKeys
        //     this.selectedRows = selectedRows
        //   }
        // }
      },
      ipaginationBusiness: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: false,
        showSizeChanger: true,
        total: 0
      },
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: false,
        showSizeChanger: true,
        total: 0
      },
      ipaginationStaff: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: false,
        showSizeChanger: true,
        total: 0
      },
      ipaginationExternal: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: false,
        showSizeChanger: true,
        total: 0
      },

      searchPaymentRow: {
        gutter: 10,
        style: { background: '#ECECEC', padding: '10px', margin: '-10px' }
      },

      searchPaymentModal: {
        width: this.modalWidth,
        visible: false,
        title: this.title,
        ok: this.handleSubmit,
        cancel: this.close,
        cancelText: '关闭',
        style: { marginTop: '-70px' },
        wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      },
      url: {
        list: ''
      },
      subcontractList: [], // 分包商列表
      businessList: [], // 商家列表
      staffList: [], // 内部人员列表
      externalPersonList: [] // 外部人员列表
    }
  },

  created () {
    this.loadPaymentData()
  },

  methods: {

    addPayment () {
      this.$refs.paymentForm.add(this.tabModelCode)
    },
    callback (v) {
      this.onClearSelected()
      this.loadPaymentData()
      // if (v === '1' && this.subcontractList.length === 0) {
      //   this.loadSubcontractData()
      // } else if (v === '2' && this.businessList.length === 0) {
      //   this.loadBusinessData()
      // } else if (v === '3' && this.staffList.length === 0) {
      //   this.loadStaffData()
      // } else if (v === '4' && this.externalPersonList.length === 0) {
      //   this.loadExternalPersonData()
      // }
    },
    loadPaymentData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams()// 查询条件
      params.type = this.tabModelCode
      this.loading = true

      paymentApi.listPaymentForFlow(params).then(res => {
        if (this.tabModelCode === '1') {
          this.subcontractList = res.data.data
          this.ipagination.total = res.data.totalCount
          this.ipagination.current = res.data.pageNo
          this.ipagination.pageSize = res.data.pageSize
        } else if (this.tabModelCode === '2') {
          this.businessList = res.data.data
          this.ipaginationBusiness.total = res.data.totalCount
          this.ipaginationBusiness.current = res.data.pageNo
          this.ipaginationBusiness.pageSize = res.data.pageSize
        } else if (this.tabModelCode === '3') {
          this.staffList = res.data.data
          this.ipaginationStaff.total = res.data.totalCount
          this.ipaginationStaff.current = res.data.pageNo
          this.ipaginationStaff.pageSize = res.data.pageSize
        } else if (this.tabModelCode === '4') {
          this.externalPersonList = res.data.data
          this.ipaginationExternal.total = res.data.totalCount
          this.ipaginationExternal.current = res.data.pageNo
          this.ipaginationExternal.pageSize = res.data.pageSize
        }
        this.loading = false
      })
    },

    handlePaymentTableChange (pagination, filters, sorter) {
      if (this.tabModelCode === '1') {
        this.ipagination = pagination
      } else if (this.tabModelCode === '2') {
        this.ipaginationBusiness = pagination
      } else if (this.tabModelCode === '3') {
        this.ipaginationStaff = pagination
      } else if (this.tabModelCode === '4') {
        this.ipaginationExternal = pagination
      }
      this.loadPaymentData()
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      // if (this.searchType === 'projName' && selectedRowKeys.length > 1) {
      // this.selectedRowKeys = selectedRowKeys.slice(selectedRowKeys.length - 1)
      // this.selectionRows = selectionRows.filter(item => this.selectedRowKeys.includes(item.projId))
      // } else {
      //   this.selectedRowKeys = selectedRowKeys
      //   this.selectionRows = selectionRows
      // }
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    // searchQuery () {
    //   this.loadData(1)
    // },

    searchReset () {
      this.queryParam = {}
      this.onClearSelected()
      this.callback(this.tabModelCode)
      // if (this.tabModelCode === '市场项目') {
      //   this.loadData(1)
      // } else if (this.tabModelCode === '商机') {
      //   this.loadBusinessData(1)
      // }
    },

    init (paymentList) {
      if (paymentList && paymentList.length) {
        this.tabModelCode = paymentList[0].type
        this.callback(this.tabModelCode)
        this.selectionRows.splice(0, this.selectionRows.length, ...paymentList)
        this.selectedRowKeys.splice(0, this.selectionRows.length, ...(paymentList.map(item => { return item.key })))
      }
      this.$nextTick(() => {
        this.searchPaymentModal.visible = true
      })
    },

    handleSubmit () {
      this.$emit('ok', this.selectedRows)
      this.close()
    },

    close () {
      this.searchPaymentModal.visible = false
      this.$emit('cancel')
    }
  }
}
</script>
