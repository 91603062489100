import '@/assets/less/common/form.less'
import { defaultData } from './search'
import { throttle } from 'lodash'

export default {
  mixins: [defaultData],

  data () {
    return {

      // 加载引用的KEY，每次加载过程中会自动切换，从而达到刷新组件的目的
      loading: false,

      // 查询条件
      queryParam: {},

      customTableAction: {
        loading: (value) => {
          this.loading = value
        }
      },

      rowSelection: {
        selectedRows: [],
        selectedRowKeys: []
      }
    }
  },

  computed: {
    customTable () {
      return Object.assign({ ref: 'table' }, { ...this.$attrs }, { ...this.queryParam })
    },

    // 列配置
    colProps () {
      const defaultProps = { offset: 0, pull: 0, push: 0, style: { textAlign: 'left' } }
      return Object.assign({}, defaultProps, this.colSpanMap[this.currentColSize])
    }
  },

  watch: {
    loading (value) {
      this.$emit('loading', value)
    },

    toggleSearchStatus () {
      this.$nextTick(() => {
        this.defaultUpdateTableHeight()
      })
    }
  },

  mounted () {
    this.search = throttle(this.search, 50, { leading: false, trailing: true })
  },

  methods: {

    /// 查询
    search () {
      this.loadKey = !this.loadKey
      const table = this.$refs.table
      this.$nextTick(() => {
        table && table instanceof Object && table.search && table.search()
        table && table instanceof Array && table.forEach(item => item.search && item.search())
      })
    },

    /// 重置
    reset () {
      this.resetQueryParam()
      this.$nextTick(() => {
        this.search()
      })
    },

    // 重置查询条件
    resetQueryParam () {
      const defaultParam = this.$options.data.bind(this)().queryParam
      Object.assign(this.queryParam, defaultParam)
    },

    /// 重新加载
    reload () {
      this.loadKey = !this.loadKey
      const table = this.$refs.table
      table && table instanceof Object && table.reload && table.reload()
      table && table instanceof Array && table.forEach(item => item.reload && item.reload())
    },

    /// 重新加载
    clearSelect () {
      const table = this.$refs.table
      table && table instanceof Object && table.clearSelect && table.clearSelect()
      table && table instanceof Array && table.forEach(item => item.clearSelect && item.clearSelect())
    },

    // 默认的更新table 高度方法， 如果有需要 混入后重写
    defaultUpdateTableHeight () {
      const table = this.$refs.table
      // 此处只区分数组和对象 Object.prototype.toString.call()
      table && table instanceof Object && table.updateTableHeight && table.updateTableHeight()
      table && table instanceof Array && table.forEach(item => item.updateTableHeight && item.updateTableHeight())
    }
  }
}
