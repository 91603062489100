<template>
  <div style="margin-bottom: 50px;">
    <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
      报告回收申请
    </div>
    <report-base-info :orderData="orderData" v-if="orderData.projId"></report-base-info>
    <div style="width: 100%;height: 100%;">
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              回收信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel">回收原因</td>
              <td colspan="3">{{ recoveryData.recoveryReason }}</td>
            </tr>
            <tr>
              <td class="tdLabel">处理措施</td>
              <td colspan="3">{{ recoveryData.recoveryMeasure }}</td>
            </tr>
          </table>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import ReportBaseInfo from './ReportBaseInfo'
import { reportSendOrderApi } from '@/api/sample'
export default {
  name: 'ReportRecoveryMgr',
  components: { ReportBaseInfo },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      reportType: this.$store.state.code.cache.proj_report_type,
      defaultData: {
        id: this.recordId,
        accessory: null
      },

      orderData: {},
      recoveryData: {}

    }
  },
  methods: {
    init () {
      // this.show()
      reportSendOrderApi.findRecoveryOne({ id: this.recordId }).then((res) => {
        if (res.code === 0) {
          this.recoveryData = res.data
        }
      })
    },

    loadOrderData () {
      // reportSendOrderApi.list({ projId: this.recoveryData.projId, page: false }).then((res) => {
      //   if (res.code === 0) {
      //     this.orderData = res.data[0]
      //   }
      // })
      reportSendOrderApi.findReportOrder({ projId: this.recoveryData.projId }).then((res) => {
        if (res.code === 0) {
          this.orderData = res.data
        }
      })
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null) {
          this.init()
        }
      }
    },
    recoveryData: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null && val.hasOwnProperty('projId')) {
          this.loadOrderData()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
