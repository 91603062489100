import { axios } from '@/utils/request'

export const authApi = {
  login: '/ms-api/auth/login',
  logout: '/ms-api/auth/logout',
  refresh: '/ms-api/auth/refresh-token',
  info: '/ms-api/user/current-roles',
  qcLogin: '/ms-api/auth/barcode-login'

}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: ''
 * }
 * @param parameter
 * @returns {*}
 */
export function authLogin (parameter) {
  return axios({
    url: authApi.login,
    method: 'post',
    params: parameter
  })
}

export function authInfo () {
  return axios({
    url: authApi.info,
    method: 'get'
  })
}

export function authLogout (parameter) {
  return axios({
    url: authApi.logout,
    method: 'get',
    params: parameter
  })
}

export function refreshToken (parameter) {
  return axios({
    url: authApi.refresh,
    method: 'post',
    params: parameter
  })
}

export function qcLogin (parameter) {
  return axios({
    url: authApi.qcLogin,
    method: 'post',
    params: parameter
  })
}
