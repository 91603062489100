<template>
  <div>
    <a-table v-bind="checkResultTable">
      <template #deviceInfo="record">
        <ul style="margin: 0">
          <li :key="index" :style="{listStyle:'none'}" v-for="(item,index) in record.deviceInfo">
            <a-popconfirm v-if="record.editable" title="是否要作废吗？" @confirm="record.deviceInfo.splice(index,1)">
              <div>
                <a-tag color="blue" class="device-title">{{ item.fixedAssetsId }} {{ item.materialName }}</a-tag>
                <a-icon type="delete" class="device-title-operation"/>
              </div>
            </a-popconfirm>

            <a-tag v-else color="blue" class="device-title">{{ item.fixedAssetsId }} {{ item.materialName }}</a-tag>
          </li>
        </ul>
      </template>

      <template #checkTime="record">
        <a-date-picker
          :showTime="true"
          v-if="record.editable"
          v-model="record.checkTime"
          :disabledDate="disabledDate"
          format="YYYY-MM-DD HH:mm:ss"/>
        <span v-else>{{ record.checkTime|momentFilter }}</span>
      </template>

      <template #resultType="record">

        <div v-if="record.editable">
          <a-select :style="{width:'80%'}" v-model="record.resultType">
            <a-select-option v-for="(item) in Object.keys(resultDataType)" :key="item">
              {{ resultDataType[item] }}
            </a-select-option>
          </a-select>
        </div>
        <span v-else>
          {{ resultDataType[record.resultType] }}
        </span>
      </template>

      <template #value="record">
        <a-input-number v-if="record.editable" v-bind="{precision:2}" v-model="record.value"></a-input-number>
        <span v-else>{{ record.value }}</span>
      </template>

      <template #volume="record">
        <a-input-number v-if="record.editable" :min="1" v-model="record.volume"></a-input-number>
        <span v-else>{{ record.volume }}</span>
      </template>

      <template #volumeUnit="record">
        <a-select
          v-if="record.editable"
          v-bind="checkUnitSelect"
          v-model="record.volumeUnitSelect"
          @change="val=>checkUnitSelect.change(val,record)">
          <a-select-option v-for="(data) in checkUnitSelect.dataSource" :key="data">{{ data }}</a-select-option>
        </a-select>
        <span v-else>{{ record.volumeUnit }}</span>
      </template>

      <template #fileList="record">
        <a-tag color="orange" @click="showQrcode(record)">二维码传图</a-tag>
        <template v-if="record.fileList.length">
          <div v-for="(photo,photoIndex) in record.fileList" :key="photoIndex" class="photos">
            <span :style="{float:'left',width:'80%'}">{{ photo.name }}</span>
            <span :style="{float:'left',marginRight: '5px'}"><a @click="handlePreview(photo)">
              <a-icon type="eye"></a-icon></a>
            </span>
            <span :style="{float:'left'}" v-if="record.editable">
              <a-popconfirm title="是否要作废此图片吗？" @confirm="record.fileList.splice(photoIndex,1)">
                <a><a-icon type="delete"></a-icon></a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </template>

      <template #operation="operation, record">
        <template v-if="record.editable">
          <span v-if="record.isNew">
            <a @click="saveResult(record)">添加</a>
            <a-divider type="vertical"/>
            <a @click="showSearchDevice(record)" class="device-info">检测设备</a>
            <a-divider type="vertical"/>
            <a-popconfirm title="是否要作废此行？" @confirm="deleteResult(record.key)">
              <a>作废</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a @click="saveResult(record)">保存</a>
            <a-divider type="vertical"/>
            <a @click="cancelOperation(record.key)">取消</a>
          </span>
        </template>

        <span v-else>
          <a @click="editResult( record.key)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm title="是否要作废此行？" @confirm="deleteResult(record.key)">
            <a>作废</a>
          </a-popconfirm>
        </span>
      </template>

    </a-table>
    <a-button :style="tableColStyles.addResultButton" type="dashed" icon="plus" @click="addResult">新增记录</a-button>

    <!-- 外部组件 -->
    <picture-upload ref="pictureUploadModal" :file-length="3" modalTitlte="上传样品照片" @result="savePicture"/>

    <!--检测设备-->
    <material-table v-bind="deviceInfoProp" v-on="deviceInfoProp" v-if="deviceInfoProp.visible"></material-table>

    <!--预览照片-->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { cloneDeep, omit } from 'lodash'
import { randomString } from '@/utils/util'

import { jcListApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'

import MaterialTable from '@/pages/cloud-quote/res/res-materialout/modules/materialTable'
import PictureUpload from '@/pages/common/img-qrcode/PictureUpload'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

export default {
  components: { MaterialTable, PictureUpload },

  props: {
    checkTask: {
      type: Object,
      required: true,
      validator (value) {
        return value.hasOwnProperty('id') && value.hasOwnProperty('projId') &&
            value.hasOwnProperty('cateId') && value.hasOwnProperty('itemId') &&
            value.hasOwnProperty('sampleId')
      }
    },

    checkUnitDict: {
      type: Array,
      required: true
    }
  },

  data () {
    return {

      // 检测设备类型
      checkDeviceList: [],

      previewVisible: false,
      previewImage: null,

      // 正在添加检测设备的数据
      editRecord: null,

      checkResultTable: {
        pagination: false,
        size: 'small',
        bordered: true,
        dataSource: [],
        columns: [{
          title: '检测设备',
          key: 'deviceInfo',
          width: 200,
          scopedSlots: { customRender: 'deviceInfo' }
        }, {
          title: '检测时间',
          key: 'checkTime',
          width: 190,
          scopedSlots: { customRender: 'checkTime' }
        }, {
          title: '数据类型',
          key: 'resultType',
          width: 120,
          scopedSlots: { customRender: 'resultType' }
        }, {
          title: '记录值',
          key: 'value',
          width: 120,
          scopedSlots: { customRender: 'value' }
        }, {
          title: '检测用量',
          key: 'volume',
          width: 120,
          scopedSlots: { customRender: 'volume' }
        }, {
          title: '计量单位',
          key: 'volumeUnit',
          width: 150,
          scopedSlots: { customRender: 'volumeUnit' }
        }, {
          title: '照片',
          key: 'fileList',
          width: 200,
          scopedSlots: { customRender: 'fileList' }
        }, {
          title: '操作',
          key: 'action',
          width: 180,
          scopedSlots: { customRender: 'operation' }
        }]
      },

      checkUnitSelect: {
        mode: 'tags',
        style: { width: '100%' },
        tokenSeparators: [',', '，'],
        dataSource: [],
        placeholder: '请选择计量单位',
        change: (val, record) => {
          const notIncludeArr = val && val.length && val.filter(item => !this.checkUnitSelect.dataSource.includes(item)) || []
          notIncludeArr.length && this.checkUnitSelect.dataSource.splice(this.checkUnitSelect.dataSource.length, 0, ...notIncludeArr)
          record.volumeUnit = val && val.length && val.join(',') || null
        }
      },

      deviceInfoProp: {
        ref: 'deviceInfo',
        cateType: 3,
        clearStatus: true,
        visible: false,
        dataSource: [],
        show: () => {
          this.deviceInfoProp.visible = true
        },
        cancel: () => {
          this.deviceInfoProp.visible = false
        },
        updateData: this.handleDeviceInfo
      },

      tableColStyles: {
        addResultButton: { width: '100%', margin: '16px 0 8px' }
      }

    }
  },

  computed: {
    resultDataType () {
      return { ...this.$store.state.code.cache.check_result_data_type }
    }
  },

  watch: {

    // 计量单位赋值
    checkUnitDict: {
      immediate: true,
      deep: true,
      handler (val) {
        this.checkUnitSelect.dataSource = val
      }
    },

    // 更新计量单位
    'checkUnitSelect.dataSource': {
      deep: true,
      handler (val) {
        if (val && val.length) {
          this.$emit('update:checkUnitDict', val)
        }
      }
    }
  },

  created () {
    this.queryCheckDevice()
    this.$store.dispatch('code/select', 'check_result_data_type')
  },

  methods: {

    moment,

    // 编辑数据
    editResult (key) {
      const target = this.getDataByKey(key)
      target.editable = !target.editable
    },

    // 取消操作
    cancelOperation (key) {
      const target = this.getDataByKey(key)
      target.editable = false
    },

    // 新增数据
    addResult () {
      const length = this.checkResultTable.dataSource.length
      const lastData = length && this.checkResultTable.dataSource[length - 1]
      const key = length && (parseInt(lastData.key) + 1).toString() || '1'
      const checkResultData = {
        projId: this.checkTask.projId,
        sampleId: this.checkTask.sampleId,
        checkTaskId: this.checkTask.id,
        checkType: '0',
        resultType: '1',
        cateId: this.checkTask.cateId,
        itemId: this.checkTask.itemId,
        value: null,
        volume: lastData && lastData.volume,
        volumeUnit: lastData && lastData.volumeUnit,
        deviceIds: lastData && lastData.deviceIds || [],
        deviceNames: lastData && lastData.deviceNames || [],
        accessory: null,
        checkTime: lastData && lastData.checkTime || this.moment(),

        key,
        fileList: [],
        volumeUnitSelect: lastData && cloneDeep(lastData.volumeUnitSelect) || [],
        deviceInfo: lastData && cloneDeep(lastData.deviceInfo) || [],
        editable: true,
        isNew: true
      }
      this.checkResultTable.dataSource.push(checkResultData)
    },

    // 保存数据
    saveResult (record) {
      if (!record.deviceInfo.length) {
        this.$message.error('请选择检测设备！')
        return false
      }

      if (!record.value) {
        this.$message.error('请输入记录值！')
        return false
      }

      // 处理图片信息
      if (record.fileList && record.fileList.length) {
        let accessory = cloneDeep(record.fileList)
        accessory = accessory.filter(it => !it.error)
        for (let i = 0; i < accessory.length; i++) {
          accessory[i] = omit(accessory[i], ['lastModified', 'lastModifiedDate', 'originFileObj', 'originFileObj', 'thumbUrl', 'percent', 'response'])
        }
        record.accessory = JSON.stringify(accessory)
      } else {
        record.accessory = null
      }

      record.deviceIds = record.deviceInfo.map(item => item.id).join(',')
      record.deviceNames = record.deviceInfo.map(item => item.materialName).join(',')
      record.editable = false
      record.isNew = false
    },

    // 作废数据
    deleteResult (key) {
      const index = this.checkResultTable.dataSource.findIndex(item => item.key === key)
      this.checkResultTable.dataSource.splice(index, 1)
    },

    // 根据KEY 获取数据
    getDataByKey (key) {
      const list = this.checkResultTable.dataSource.filter(item => item.key === key)
      return list.length && list[0]
    },

    // 查询检测指南配置的设备类目
    queryCheckDevice () {
      jcListApi.queryProjCheckGuide({ projId: this.checkTask.projId, cateId: this.checkTask.cateId, page: false }).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            this.checkDeviceList.splice(0, this.checkDeviceList.length, ...res.data.filter(item => item.itemId === this.checkTask.itemId))
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询检测设备
    showSearchDevice (record) {
      this.deviceInfoProp.show()
      this.editRecord = record
      const selectKey = record.deviceInfo && record.deviceInfo.map(item => item.id) || []
      this.$nextTick(() => {
        this.$refs[this.deviceInfoProp.ref].handleAdd({
          type: 'device',
          cateType: 3,
          materialName: this.checkDeviceList.length &&
              this.checkDeviceList[0].checkDeviceNames && this.checkDeviceList[0].checkDeviceNames.split(',')[0] || ''
        }, selectKey)
      })
    },

    // 处理选择设备事件
    handleDeviceInfo (info) {
      this.editRecord.deviceInfo.splice(0, this.editRecord.deviceInfo.length, ...info)
    },

    // 显示二维码传图
    showQrcode (record) {
      const id = record.checkTaskId + '-' + record.key
      this.editRecord = record
      this.$refs.pictureUploadModal.show('二维码', `checkTaskResult-${id}-${randomString(6)}`)
    },

    // 保存照片
    savePicture (data) {
      this.editRecord.fileList.splice(0, this.editRecord.fileList.length, ...data)
    },

    // 预览照片
    handlePreview (file) {
      this.previewImage = file.thumbUrl || sysCommonApi.view + '/' + file.url
      this.previewVisible = true
    },

    // 获取列表数据
    getData () {
      const result = cloneDeep(this.checkResultTable.dataSource)
      result.forEach(item => {
        delete item.fileList
        delete item.volumeUnitSelect
        delete item.deviceInfo
      })
      return result
    },

    disabledDate (current) {
      return current && current >= this.moment()
    }
  }
}
</script>

<style scoped>
  .device-title {
    margin-bottom: 5px;
    float: left;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .device-title-operation {
    display: block;
    float: left;
    line-height: 27px;
  }
</style>
