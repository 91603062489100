<!-- @format -->

<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a class="action" style="padding: 0">
        <el-popover placement="bottom-end" width="500" height="500" trigger="click">
          <a style="display: block; padding: 0 12px" slot="reference" @click="showPortal">
            <i class="el-icon-s-grid"></i>
          </a>
          <a-tabs tab-position="left" style="height: 500px" v-model="type">
            <a-tab-pane :tab="item.label" :value="item.value" v-for="item in types" :key="item.value">
              <div class="box">
                <div class="item" v-for="obj in portalList" :key="obj.id">
                  <el-card class="big" @click.native="getUrl(obj)">
                    <img class="imgBox" fit="cover" :src="viewUrl + '/' + obj.icon" style="width: 50px; height: 50px" />
                    <div class="small">{{ obj.shortName }}</div>
                  </el-card>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </el-popover>
      </a>
      <a @click="refreshRoute">
        <a-tooltip placement="bottom">
          <template #title>刷新页面</template>
          <span class="action">
            <a-icon type="reload"></a-icon>
          </span>
        </a-tooltip>
      </a>
      <notice-icon ref="notice" class="action" />
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar :src="avatar()" class="avatar" size="small" />
          <span>{{ realname() }}</span>
        </span>
        <a-menu class="user-dropdown-menu-wrapper" slot="overlay">
          <a-menu-item key="0">
            <router-link :to="{ name: 'center' }">
              <a-icon type="user" />
              <span>个人中心</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="1">
            <router-link :to="{ name: 'settings' }">
              <a-icon type="setting" />
              <span>账户设置</span>
            </router-link>
          </a-menu-item>
          <a-menu-item @click="updatePassword" key="2">
            <a-icon type="setting" />
            <span>修改密码</span>
          </a-menu-item>
          <a-menu-item @click="appPreview" key="3">
            <a-icon type="android" />
            <span>app下载</span>
          </a-menu-item>
          <a-menu-item key="4" @click="toUsingVideo">
            <a-icon type="play-circle" />
            <span>操作视频</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="5">
            <a @click="handleLogout" href="javascript:;">
              <a-icon type="logout" />
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <user-password ref="userPassword"></user-password>
    <using-Video ref="usingVideo"> </using-Video>

    <a-modal :footer="null" :visible="previewVisible" :width="600" @cancel="() => (this.previewVisible = false)" title="APP下载">
      <p>
        版本号：{{ previewVersion }}
        <br />
        - {{ previewContent }}
      </p>
      <div style="height: 350px">
        <div style="float: left">
          <qriously :size="250" :value="previewQrcode" />
          <center>Android版扫码下载</center>
        </div>
        <div style="float: left; margin-left: 20px">
          <qriously :size="250" value="https://apps.apple.com/cn/app/%E5%B0%8F%E6%9C%A8%E6%A3%80%E6%B5%8B/id1504791972" />
          <center>iPhone或App Store搜索“小木检测”</center>
        </div>
      </div>
    </a-modal>
    <pc-version-update-modal />
  </div>
</template>

<script>
import { sysCommonApi } from '@/api/common'
import { dictApi } from '@/api/system'
import { portalManagementAPI } from '@/api/portal-management/portal-management.js'
import { PcVersionDetailModal as PcVersionUpdateModal } from '@/pages/common/version/modules'
import { axios } from '@/utils/request'
import { Modal } from 'ant-design-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import NoticeIcon from './UserNoticeIcon'
import UserPassword from './UserPassword'
import UsingVideo from './UsingVideo'

export default {
  name: 'UserMenu',
  inject: ['refreshRoute'],
  components: {
    NoticeIcon,
    UserPassword,
    UsingVideo,
    PcVersionUpdateModal
  },
  data() {
    return {
      previewVisible: false,
      previewQrcode: '',
      previewContent: '',
      previewVersion: '',
      websocket: {
        ws: null,
        reconnectTimes: 0,
        maxReconnectTimes: 3,
        intervalTime: 10 * 1000
      },
      visible: false,
      portalList: [],
      viewUrl: sysCommonApi.view,
      types: [
        // {
        //   label: '全部',
        //   value: 0
        // },
        // {
        //   label: '国家',
        //   value: 1
        // },
        // {
        //   label: '省级',
        //   value: 2
        // },
        // {
        //   label: '市级',
        //   value: 3
        // },
        // {
        //   label: '县级',
        //   value: 4
        // },
        // {
        //   label: '其他',
        //   value: 5
        // }
      ],
      type: 0
    }
  },
  created() {
    // @TODO 消息提示socket还未完成
    this.initWebSocket()
    dictApi.getItems({ dictCode: 'mhfl' }).then((res) => {
      if (res.code === 0) {
        const d = res.data
        const temp = []
        for (let i = 0; i < d.length; i++) {
          temp.push({ label: d[i].itemText, value: Number(d[i].itemValue) })
        }
        this.types = [
          {
            label: '全部',
            value: 0
          },
          ...temp
        ]
      }
    })
  },
  destroyed() {
    // 销毁监听
    this.socket.onclose = this.close
  },
  computed: {
    userInfo() {
      return { ...this.$store.getters.userInfo }
    },

    ...mapState({
      configMap: (state) => state.app.sys_config
    }),

    companyUrl() {
      return this.configMap.COMPANY_URL || '#'
    }
  },
  watch: {
    type() {
      this.getPortal()
    }
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['nickname', 'avatar', 'username', 'realname']),
    handleLogout() {
      const that = this

      Modal.confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk() {
          return that
            .Logout({})
            .then(() => {
              window.location.reload()
            })
            .catch((err) => {
              that.$message.error({
                title: '错误',
                description: err.message
              })
            })
        },
        onCancel() {}
      })
    },
    updatePassword() {
      const username = this.realname
      this.$refs.userPassword.show(username)
    },
    toUsingVideo() {
      const username = this.realname
      this.$refs.usingVideo.show(username)
    },
    appPreview() {
      axios({
        url: '/update_version.json',
        method: 'get'
      }).then((res) => {
        this.previewContent = res.data.content
        this.previewVersion = res.data.android_version
        this.previewQrcode = window.location.host + '/' + res.data.android_url + '_' + res.data.android_version + '.apk'
        this.previewVisible = true
      })
    },
    // 调取获取数据接口
    async getPortal() {
      const { records } = await portalManagementAPI.getPortalAPI({
        type: this.type,
        pageNo: 1,
        pageSize: 1000,
        shows: 0
      })
      this.portalList = records
    },
    showPortal() {
      this.getPortal()
      this.visible = !this.visible
    },
    getUrl(obj) {
      window.open(obj.url)
    },
    initWebSocket() {
      var host = window.location.host
      const wsuri = `ws://${host}/ws/message/${this.userInfo.username}/${window.c_org}`
      if ('WebSocket' in window) {
        this.websocket.ws = new WebSocket(wsuri)
        this.websocket.ws.onmessage = this.wsOnmessage
        this.websocket.ws.onopen = this.wsOnopen
        this.websocket.ws.onerror = this.wsOnerror
        this.websocket.ws.onclose = this.wsOnclose
      } else {
        console.log('浏览器不支持websocket')
      }
    },
    wsOnopen() {
      // 连接建立之后执行send方法发送数据
      const actions = { username: this.userInfo.username }
      // this.wsSend(JSON.stringify(actions))
    },
    wsOnerror(e) {
      // 连接建立失败重连
      var self = this
      console.error(e)
      setTimeout(function () {
        self.initWebSocket()
      }, this.websocket.intervalTime)
    },
    wsOnmessage(e) {
      // 数据接收
      const msg = JSON.parse(e.data)
      this.$refs.notice.fetchLastNotice(msg)
    },
    wsSend(Data) {
      // 数据发送
      this.websocket.ws.send(Data)
    },
    wsOnclose(e) {
      // 关闭
      console.log('断开连接', e)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 500px;
  // display: grid;
  // justify-content: space-between;
  // grid-template-columns: repeat(auto-fill, 90px);
  // grid-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 10px;
  overflow-y: auto;
  .item {
    // width: 90px;
    width: 25%;
    height: 90px;
    padding: 0 2px;
  }
  ::v-deep .el-card__body {
    padding: 10px;
  }
}
</style>
