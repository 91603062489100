import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 检测数据 数据状态字典枚举

export const checkTaskResultDataStatusEnum = {
  // 待录入
  pending: '0',
  // 待完善
  broken: '1',
  // 已完善
  perfected: '2',
  // 待审查
  done: '3',
  // 审查通过
  pass: '4',
  // 存疑
  doubt: '5',
  // 请求重审
  reSubmit: '6',
  // 确认无误
  noError: '7',
  // 待复核
  pendReview: '8',
  // 复核驳回
  ReviewReject: '9',
  // 复核重审
  reviewResubmit: '10',
  // 审核通过
  checkPass: '11'
}

//  检测数据 数据状态选择项
export const checkTaskResultDataStatusOptions = [
  { label: '待录入', value: checkTaskResultDataStatusEnum.pending, type: 'info' },
  { label: '待完善', value: checkTaskResultDataStatusEnum.broken, type: 'danger' },
  { label: '已完善', value: checkTaskResultDataStatusEnum.perfected, type: 'warning' },
  { label: '待审查', value: checkTaskResultDataStatusEnum.done, type: 'success' },
  { label: '审查通过', value: checkTaskResultDataStatusEnum.pass, type: 'success' },
  { label: '存疑', value: checkTaskResultDataStatusEnum.doubt, type: 'danger' },
  { label: '请求重审', value: checkTaskResultDataStatusEnum.reSubmit, type: 'warning' },
  { label: '确认无误', value: checkTaskResultDataStatusEnum.noError, type: 'warning' },
  { label: '待复核', value: checkTaskResultDataStatusEnum.pendReview, type: 'info' },
  { label: '复核驳回', value: checkTaskResultDataStatusEnum.ReviewReject, type: 'danger' },
  { label: '复核重审', value: checkTaskResultDataStatusEnum.reviewResubmit, type: 'warning' },
  { label: '审核通过', value: checkTaskResultDataStatusEnum.checkPass, type: 'success' }
]

export const checkTaskResultDataStatusMap = tranOptionsToMap(checkTaskResultDataStatusOptions)
export const checkTaskResultDataStatusDataMap = tranOptionsToDataMap(checkTaskResultDataStatusOptions)

export const checkTaskResultRedoDataStatus = [checkTaskResultDataStatusEnum.broken,
  checkTaskResultDataStatusEnum.perfected, checkTaskResultDataStatusEnum.ReviewReject]

// 检测数据 状态字典枚举
export const checkTaskResultStatusEnum = {
  // 作废
  void: '0',
  // 正常
  normal: '1'

}

//  检测数据 状态选择项
export const checkTaskResultStatusOptions = [
  { label: '作废', value: checkTaskResultStatusEnum.void, color: 'red' },
  { label: '正常', value: checkTaskResultStatusEnum.normal, color: 'blue' }
]

export const checkTaskResultStatusMap = tranOptionsToMap(checkTaskResultStatusOptions)
export const checkTaskResultStatusDataMap = tranOptionsToDataMap(checkTaskResultStatusOptions)
