<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane tab="物资" key="1" :style=" { background: '#F0F2F5' }">
        <material-single-list :columns="materialSingleSelectColumn" :row-selection-type="rowSelectionType" :cate-type="cateType" :cate-types="cateTypes" @selectedRows="handleMaterialRowSelected"/>
      </a-tab-pane>

      <a-tab-pane tab="试剂" key="2" :style=" { background: '#F0F2F5' }">
        <check-reagent-cate-list v-bind="cateListProps"/>
      </a-tab-pane>
    </a-tabs>

    <a-card v-bind="checkCard">
      <a-row>
        <a-col :span="2">物资:</a-col>
        <a-col :span="22">
          <a-row>
            <template v-if="materialSelectedRows.length">
              <a-col :span="6" v-for="(item,index) in materialSelectedRows" :key="`${index}_materialSelected`">
                <a-tag color="blue" :style="{margin:'5px'}"> {{ item.materialName }}({{ item.speType }})</a-tag>
                <a-tooltip>
                  <template #title>点我移除</template>
                  <a @click="()=>materialSelectedRows.splice(index,1)">
                    <a-icon type="close-circle" style="margin-left: 12px"></a-icon>
                  </a>
                </a-tooltip>
              </a-col>
            </template>
            <template v-else></template>
          </a-row>

        </a-col>
      </a-row>

      <a-row style="margin: 32px 0 0 0 ;">
        <a-col :span="2">试剂:</a-col>
        <a-col :span="22">
          <a-row>
            <template v-if="cateSelectedRows.length">
              <a-col :span="6" v-for="(item,index) in cateSelectedRows" :key="`${index}_cateSelected`">
                <a-tag color="green" :style="{margin:'5px'}"> {{ item.name }}</a-tag>
                <a-tooltip>
                  <template #title>点我移除</template>
                  <a @click="()=>cateSelectedRows.splice(index,1)">
                    <a-icon type="close-circle" style="margin-left: 12px"></a-icon>
                  </a>
                </a-tooltip>
              </a-col>
            </template>
            <template v-else></template>
          </a-row>
        </a-col>
      </a-row>

    </a-card>
  </div>
</template>

<script>
import MaterialSingleList from '@/pages/cloud-quote/res/res-materialsingle/materialSingleList'
import { CheckReagentCateSelectColumn as selectColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { materialSingleSelectColumn } from '@/pages/cloud-quote/res/res-materialsingle/modules/columns'
import CheckReagentCateList from '@/pages/cloud-quote/check-reagent/cate/List'

export default {
  name: 'CheckReagentCateMaterialSelect',
  components: { MaterialSingleList, CheckReagentCateList },

  props: {
    rowSelectionType: String,
    cateType: Number,
    cateTypes: Array
  },
  data () {
    return {
      selectColumn,
      materialSingleSelectColumn,

      materialSelectedRows: [],
      cateSelectedRows: [],
      checkCard: {
        title: '已选项',
        type: 'inner',
        size: 'small',
        style: { marginTop: '10px' }
      }
    }
  },

  computed: {
    cateListProps () {
      return { columns: selectColumn, rowSelect: { type: this.rowSelectionType, selectedRows: this.cateSelectedRows } }
    }
  },

  methods: {
    handleMaterialRowSelected (data) {
      this.materialSelectedRows.splice(0, this.materialSelectedRows.length, ...data)
    },

    handleCateRowSelected (data) {
      this.cateSelectedRows.splice(0, this.cateSelectedRows.length, ...data)
    },

    getSelectData () {
      return {
        cate: this.cateSelectedRows,
        material: this.materialSelectedRows
      }
    }
  }
}
</script>

<style scoped>

</style>
