<template>
  <div class="check-task-enter">
    <a-spin :spinning="fullPageLoading">
      <!-- <template v-if="models && models.length > 0"> -->
      <div class="check-task-enter-title" style="padding-bottom: 0">
        <ul-table title="批次信息">
          <template #content>
            <ul-table-row>
              <ul-table-column title="检测时间">
                <a-range-picker v-model="checkTime" format="YYYY-MM-DD HH:mm" show-time @ok="onRangeOk" />
              </ul-table-column>
              <ul-table-column title="房间">
                <a-tree-select
                  v-model="locationId"
                  show-search
                  tree-node-filter-prop="title"
                  :replaceFields="replaceFields"
                  style="width: 150px"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择房间"
                  allow-clear
                  tree-default-expand-all
                  :tree-data="treeData"
                  @change="changeLocation"
                >
                </a-tree-select>
              </ul-table-column>
              <ul-table-column title="温度">
                <a-input-number v-model="batchInfo.wd" :step="0.1" />
              </ul-table-column>

              <ul-table-column title="湿度">
                <a-input-number v-model="batchInfo.sd" :step="0.1" />
                <a @click="expressModalProps.show" style="margin-left: 4px">
                  <a-icon type="unordered-list" />
                </a>
              </ul-table-column>
            </ul-table-row>
          </template>
        </ul-table>
      </div>

      <div class="check-task-enter-content">
        <div>
          <a-radio-group v-model="radioType" button-style="solid" @change="changeViewType">
            <a-radio-button value="list">列表</a-radio-button>
            <a-radio-button value="step">步骤</a-radio-button>
          </a-radio-group>
          <div style="padding-top: 10px; padding-left: 10px; color: red">
            <div>注：1、自信度代码：‘1’代表正确，‘0’代表不明确，‘-1’代表错误；2、以下表格中只能输入‘1’、‘0’、‘-1’，不能输入其它的值；</div>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、编辑一种稀释倍数的数据后，点击右上角保存，暂存数据并显示下一个稀释倍数的可编辑数据；4、如果某个稀释倍数下的数据没有显示，当点击确定时，未显示的数据不管之前是否编辑过，默认清除之前输入的数据！
            </div>
          </div>
        </div>

        <div v-for="(modelItem, modelIndex) in models" :key="modelItem.id">
          <div style="padding: 10px 0; position: relative; display: flex; justify-content: center; align-items: center">
            <div style="font-size: 20px; font-weight: 600; color: #000">{{ modelItem.stepName }}</div>
            <div style="position: absolute; right: 0">
              <a-button type="primary" style="margin-right: 10px" v-if="modelItem.stepName === '10倍稀释' && defaultGenerateData" @click="defaultData">默认数据</a-button>
              <a-button type="primary" v-if="modelItem.stepName != '1000倍稀释'" @click="stagingData(modelIndex)">保存</a-button>
            </div>
          </div>
          <template v-if="viewType === 'step'">
            <div class="steps">
              <a-steps :current="currentSteps[modelIndex]">
                <a-step v-for="item in modelItem.stepForm" :key="item.key" :title="item.title" @click="handleStepChange(item.key, item.index, modelIndex)"></a-step>
              </a-steps>
            </div>

            <div class="content">
              <template v-for="item in modelItem.stepForm">
                <div v-show="currentTableKeys[modelIndex] === item.key" :key="item.key" class="table">
                  <enter-hot-table
                    :ref="modelItem.id + item.key"
                    :col-headers="modelItem.colHeaders"
                    :columns="modelItem.columns"
                    :expressions.sync="expressions"
                    :hidden-columns="modelItem.hiddenColumns"
                    :table-data="item.data"
                    :tableKey="item.key"
                  />
                </div>
              </template>
            </div>
          </template>
          <template v-if="viewType === 'list'">
            <a-timeline style="margin-top: 10px">
              <a-timeline-item v-for="item in modelItem.stepForm" :key="item.key">
                <template #dot
                  ><span style="display: inline-block; width: 32px; height: 32px; line-height: 32px; border-radius: 50%; background: #1890ff; font-size: 16px; color: #fff">{{
                    item.index + 1
                  }}</span></template
                >
                <div style="margin-left: 8px">
                  <div
                    style="
                      font-weight: 500;
                      font-size: 16px;
                      color: rgba(0, 0, 0, 0.85);
                      display: inline-block;
                      padding-right: 16px;
                      position: relative;
                      margin-top: -8px;
                      line-height: 32px;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div class="table">
                    <enter-hot-table
                      :ref="modelItem.id + item.key + 'list'"
                      :col-headers="modelItem.colHeaders"
                      :columns="modelItem.columns"
                      :expressions.sync="expressions"
                      :hidden-columns="modelItem.hiddenColumns"
                      :table-data="item.data"
                      :tableKey="item.key"
                    />
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>
          </template>
        </div>
      </div>
      <!-- </template>
      <template v-else>
        <div class="no-data-striking">暂未配置“三点比较式臭袋法无组织”模型，请先配置后再试！</div>
      </template> -->
    </a-spin>
    <a-modal v-bind="expressModalProps">
      <p :key="item.key" v-for="item in expressions">{{ item.key }} : {{ item.value }}</p>
      <template #footer>
        <a-button @click="expressModalProps.cancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { itemCheckDetailApi, sysModelApi, sysModelFieldApi, resLocationApi, Get } from '@/api/quote'
import { checkTaskEnterApi, checkTaskEnterExpressionApi, checkTaskBatchApi } from '@/api/check/checkTaskApi'
import EnterHotTable from '@/pages/cloud-quote/check-task/modules/enter/EnterHotTable.vue'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkFieldApi } from '@/api/system'
import { groupBy, orderBy, debounce } from 'lodash'
import { checkBatchApi, checkBatchCurveApi } from '@/api/checkBatch/checkBatchApi'
import { checkStandardSolutionDemarcatesApi } from '@/api/checkStandardSolution/checkStandardSolutionDemarcatesApi'
import mixinDate from '@/pages/common/mixins/date'
import UlTable, { UlTableColumn, UlTableRow } from '@/pages/common/ul-table/Table.jsx'
import { getColName } from '@/api/common'

import '@/assets/less/common/form.less'

export default {
  name: 'CheckTaskEnter',
  mixins: [mixinDate],
  components: { UlTable, EnterHotTable, UlTableRow, UlTableColumn },

  props: {
    checkDetailId: { required: true, type: Number },
    modelId: { type: Number },
    batchId: { required: true, type: Number },
    batchGroupId: { required: true, type: Number }
  },

  data() {
    return {
      getColName,
      /** 原生的任务数据 */
      originalData: [],
      /** 表格数据  */
      tableData: [],
      /** 模型信息 */
      modelInfoList: [],
      // 字段对应关系，原始字段映射成驼峰形式,不需要响应式
      camelCaseFieldMap: Object.freeze({ mapping: {} }),
      expressModalProps: {
        visible: false,
        title: '表达式值',
        show: () => (this.expressModalProps.visible = true),
        cancel: () => (this.expressModalProps.visible = false)
      },
      /** 当前的步骤值 */
      currentSteps: {},
      /** 当前录入表格的值 */
      currentTableKeys: {},
      /** 采样数据配置 */
      sampleDataConfig: [
        { key: 'labBlank', title: '实验室空白', sampleTypes: [sampleInfoTypeEnum.labBlank] },
        { key: 'qc', title: '质控', sampleTypes: [sampleInfoTypeEnum.qualityControl] },
        { key: 'addSample', title: '加标', sampleTypes: [sampleInfoTypeEnum.addStandardSample, sampleInfoTypeEnum.basisAddStandardSample] },
        { key: 'fieldParallel', title: '平行', sampleTypes: [sampleInfoTypeEnum.labParallel, sampleInfoTypeEnum.fieldParallel] },
        { key: 'sampleBlank', title: '现场空白', sampleTypes: [sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank, sampleInfoTypeEnum.allBlank] },
        { key: 'normal', title: '普通样品', sampleTypes: [sampleInfoTypeEnum.normal] }
      ],
      /** 表达式 */
      expressions: [],
      /** 批次信息 */
      batchInfo: {
        id: null,
        wd: null,
        sd: null,
        makeBeginTime: null,
        makeEndTime: null
      },
      treeData: [],
      locationId: null,
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      // 页面切换前的type
      radioType: 'list',
      // 页面切换的type
      viewType: 'list',
      fullPageLoading: false,
      // 臭气浓度需要的模型
      models: [],
      // 判断是否显示默认生成数据按钮
      defaultGenerateData: false,
      // 判断是否点击过默认生成数据按钮
      isDefaultGenerateData: false
    }
  },

  computed: {
    checkTime: {
      set(value) {
        const [start, done] = value
        this.batchInfo.makeBeginTime = this.timeFormat(start)
        this.batchInfo.makeEndTime = this.timeFormat(done)
      },
      get() {
        const { makeBeginTime, makeEndTime } = this.batchInfo
        return makeBeginTime && makeEndTime ? [this.moment(makeBeginTime), this.moment(makeEndTime)] : []
      }
    }
  },

  watch: {
    checkDetailId: {
      immediate: true,
      handler(value) {
        value && this.loadLimitValueExpression(value)
      }
    },

    batchId: {
      immediate: true,
      handler(value) {
        value && this.getModels() // 在这里调用是为了保证在下面使用batchId时不会出现null
        value && this.searchBatchInfo(value)
        value && this.loadCurveExpression(value)
        // value && this.handleDevAndLocation(value, '2023-04-18 15:00:12')
        value && this.selectLocationByDevId(value)
        if (value) {
          this.getStandardCalcResult(value)
        } else {
          this.$nextTick(() => {
            this.changeExpression('standardCalcResult', 0)
          })
        }
      }
    }
  },
  created() {
    this.getLocation()
  },

  methods: {
    // 默认生成数据
    defaultData() {
      this.isDefaultGenerateData = true
      let keyName = ''
      if (this.viewType === 'list') {
        keyName = 'list'
      }
      let model = this.models[0]

      checkTaskEnterApi.randomByNumber(model.tableData.length).then((res) => {
        console.log(res)
        model.tableData.forEach((item, index) => {
          item.aOne = String(res[index][0])
          item.aTwo = String(res[index][1])
          item.aThree = String(res[index][2])

          item.bOne = String(res[index][3])
          item.bTwo = String(res[index][4])
          item.bThree = String(res[index][5])

          item.cOne = String(res[index][6])
          item.cTwo = String(res[index][7])
          item.cThree = String(res[index][8])

          item.dOne = String(res[index][9])
          item.dTwo = String(res[index][10])
          item.dThree = String(res[index][11])

          item.eOne = String(res[index][12])
          item.eTwo = String(res[index][13])
          item.eThree = String(res[index][14])

          item.fOne = String(res[index][15])
          item.fTwo = String(res[index][16])
          item.fThree = String(res[index][17])
        })
        let stepForm = this.stepForm(model.tableData, model.columns, model.labBlankAverageColumns)
        model.stepForm.splice(0, model.stepForm.length, ...stepForm)

        this.$nextTick(() => {
          model.stepForm.forEach((item) => {
            this.$refs[model.id + item.key + keyName][0].loadData(item.data)
          })
        })
      })
    },
    // 暂存数据，用于处理数据，以使当前稀释倍数下的其它稀释倍数的数据选择显示
    stagingData(modelIndex) {
      console.log(modelIndex, this.models[modelIndex])
      let keyName = ''
      if (this.viewType === 'list') {
        keyName = 'list'
      }
      let model = this.models[modelIndex]

      let num = 0
      // showSampleData暂存没通过判定的数据，这些数据中的值需要作为下一级稀释倍数的参数
      let showSampleData = []
      let showSampleIds = []
      for (let i = 0; i < model.stepForm.length; i++) {
        let item = model.stepForm[i]
        num += 1
        let data = this.$refs[model.id + item.key + keyName][0].getData()
        for (let j = 0; j < data.length; j++) {
          let dataItem = data[j]
          if (
            (dataItem.aOne && dataItem.aOne !== '1' && dataItem.aOne !== '0' && dataItem.aOne !== '-1') ||
            (dataItem.aTwo && dataItem.aTwo !== '1' && dataItem.aTwo !== '0' && dataItem.aTwo !== '-1') ||
            (dataItem.aThree && dataItem.aThree !== '1' && dataItem.aThree !== '0' && dataItem.aThree !== '-1') ||
            (dataItem.bOne && dataItem.bOne !== '1' && dataItem.bOne !== '0' && dataItem.bOne !== '-1') ||
            (dataItem.bTwo && dataItem.bTwo !== '1' && dataItem.bTwo !== '0' && dataItem.bTwo !== '-1') ||
            (dataItem.bThree && dataItem.bThree !== '1' && dataItem.bThree !== '0' && dataItem.bThree !== '-1') ||
            (dataItem.cOne && dataItem.cOne !== '1' && dataItem.cOne !== '0' && dataItem.cOne !== '-1') ||
            (dataItem.cTwo && dataItem.cTwo !== '1' && dataItem.cTwo !== '0' && dataItem.cTwo !== '-1') ||
            (dataItem.cThree && dataItem.cThree !== '1' && dataItem.cThree !== '0' && dataItem.cThree !== '-1') ||
            (dataItem.dOne && dataItem.dOne !== '1' && dataItem.dOne !== '0' && dataItem.dOne !== '-1') ||
            (dataItem.dTwo && dataItem.dTwo !== '1' && dataItem.dTwo !== '0' && dataItem.dTwo !== '-1') ||
            (dataItem.dThree && dataItem.dThree !== '1' && dataItem.dThree !== '0' && dataItem.dThree !== '-1') ||
            (dataItem.eOne && dataItem.eOne !== '1' && dataItem.eOne !== '0' && dataItem.eOne !== '-1') ||
            (dataItem.eTwo && dataItem.eTwo !== '1' && dataItem.eTwo !== '0' && dataItem.eTwo !== '-1') ||
            (dataItem.eThree && dataItem.eThree !== '1' && dataItem.eThree !== '0' && dataItem.eThree !== '-1') ||
            (dataItem.fOne && dataItem.fOne !== '1' && dataItem.fOne !== '0' && dataItem.fOne !== '-1') ||
            (dataItem.fTwo && dataItem.fTwo !== '1' && dataItem.fTwo !== '0' && dataItem.fTwo !== '-1') ||
            (dataItem.fThree && dataItem.fThree !== '1' && dataItem.fThree !== '0' && dataItem.fThree !== '-1')
          ) {
            this.$message.error('只能输入‘1’、‘0’、‘-1’，不能输入其它的值！')
            return
          }
        }
        // console.log(data)
        let failedData = []
        if (modelIndex === 0) {
          failedData = data.filter((item) => item.mTen > 0.58)
        } else if (modelIndex === 1) {
          failedData = data.filter((item) => item.mHundred > 0.58)
        }

        let failedSampleIds = failedData.map((item) => item.sampleId)
        showSampleData.push(...failedData)
        showSampleIds.push(...failedSampleIds)
      }

      if (num > 0 || modelIndex === 1) {
        let nextModel = this.models[modelIndex + 1]
        let existingData = []
        nextModel.stepForm.forEach((item) => {
          if (this.$refs[nextModel.id + item.key + keyName][0] && this.$refs[nextModel.id + item.key + keyName][0].getData()) {
            let getData = this.$refs[nextModel.id + item.key + keyName][0].getData()
            existingData.push(...getData)
          }
        })
        if (existingData.length > 0) {
          nextModel.tableData.forEach((item) => {
            let ei = existingData.filter((it) => it.sampleId === item.sampleId)
            if (ei.length) {
              for (const key in item) {
                if (ei[0][key] || ei[0][key] === 0) {
                  item[key] = ei[0][key]
                }
              }
            }
          })
        }
        nextModel.tableData.forEach((item) => {
          // 如果稀释倍数数据更新了，所以下一个稀释倍数数据，也就是nextModel的tabelData的依赖的m相关值需要更新
          let ssd = showSampleData.filter((it) => it.sampleId === item.sampleId)
          if (ssd.length) {
            if (modelIndex === 0) {
              item.mTen = ssd[0].mTen
            } else if (modelIndex === 1) {
              item.mHundred = ssd[0].mHundred
            }
          }
        })
        let nextShowTableData = nextModel.tableData.filter((item) => showSampleIds.includes(item.sampleId))
        let nextStepForm = this.stepForm(nextShowTableData, nextModel.columns, nextModel.labBlankAverageColumns)
        nextModel.stepForm.splice(0, nextModel.stepForm.length, ...nextStepForm)
        this.$set(this.currentSteps, modelIndex + 1, 0)
        this.$set(this.currentTableKeys, modelIndex + 1, nextModel.stepForm[0]?.key)

        console.log(nextModel)
        this.$nextTick(() => {
          nextModel.stepForm.forEach((item) => {
            // console.log(this.$refs[nextModel.id + item.key + keyName][0])
            this.$refs[nextModel.id + item.key + keyName][0].loadData(item.data)
            // this.$refs[nextModel.id + item.key + keyName][0].hotInstance.render()
          })
        })
      }
      if (modelIndex === 0) {
        this.$nextTick(() => {
          this.stagingData(1)
        })
      }
    },
    // 查询臭气浓度的10倍、100倍、1000倍模型
    getModels() {
      Get(sysModelApi.list, { name: '三点比较式臭袋法无组织' }).then(async (res) => {
        let data = res.data
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          if (item.tableName === 'template_record_ten') {
            item.index = 1
            item.stepName = '10倍稀释'
          } else if (item.tableName === 'template_record_hundred') {
            item.index = 2
            item.stepName = '100倍稀释'
          } else if (item.tableName === 'template_record_thousand') {
            item.index = 3
            item.stepName = '1000倍稀释'
          }
        }
        data = orderBy(data, 'index', 'asc')
        // console.log(data)

        for (let i = 0; i < data.length; i++) {
          let item = data[i]

          let modelInfoList = await this.searchModelInfo(item.id)
          item.modelInfoList = modelInfoList
          let originalData = await this.searchEnterData({ modelId: item.id, batchId: this.batchId })
          item.originalData = originalData
          let tableData = await this.fillEnterData(originalData, modelInfoList)
          // 判断是否显示默认生成数据按钮
          if (i === 0) {
            this.defaultGenerateData = tableData.every(
              (it) =>
                !it.aOne &&
                !it.aTwo &&
                !it.aThree &&
                !it.bOne &&
                !it.bTwo &&
                !it.bThree &&
                !it.cOne &&
                !it.cTwo &&
                !it.cThree &&
                !it.dOne &&
                !it.dTwo &&
                !it.dThree &&
                !it.eOne &&
                !it.eTwo &&
                !it.eThree &&
                !it.fOne &&
                !it.fTwo &&
                !it.fThree &&
                (!it.mTen || it.mTen === 0 || it.mTen === '0')
            )
          }

          let colHeaders = this.colHeaders(modelInfoList)
          let columns = this.columns(modelInfoList)
          let hiddenColumns = this.hiddenColumns(modelInfoList)
          let labBlankAverageColumns = this.labBlankAverageColumns(columns)
          let stepForm = []
          if (item.tableName === 'template_record_ten') {
            stepForm = this.stepForm(tableData, columns, labBlankAverageColumns)
            // console.log(stepForm)
          } else if (item.tableName === 'template_record_hundred' || item.tableName === 'template_record_thousand') {
            let prevItem = data[i - 1]
            let prevTableData = prevItem.tableData
            let failedSampleIds = []
            if (item.tableName === 'template_record_hundred') {
              // 当前稀释倍数是100倍时，查看10倍稀释倍数的mTen
              failedSampleIds = prevTableData.filter((it) => it.mTen > 0.58).map((it) => it.sampleId)
            } else if (item.tableName === 'template_record_thousand') {
              // 当前稀释倍数是1000倍时，查看100倍稀释倍数的mHundred
              failedSampleIds = prevTableData.filter((it) => it.mHundred > 0.58).map((it) => it.sampleId)
            }
            let showTableData = tableData.filter((it) => failedSampleIds.includes(it.sampleId))
            stepForm = this.stepForm(showTableData, columns, labBlankAverageColumns)
          }

          item.tableData = tableData
          item.colHeaders = colHeaders
          item.columns = columns
          item.hiddenColumns = hiddenColumns
          item.labBlankAverageColumns = labBlankAverageColumns
          item.stepForm = stepForm

          this.$set(this.currentSteps, i, 0)
          this.$set(this.currentTableKeys, i, stepForm[0]?.key)
          this.fullPageLoading = false
          this.$emit('changeBtnStatus', false)
        }
        this.models = data
        // this.models = orderBy(data, 'index', 'asc')
        // console.log(this.models)
      })
    },
    /** hot table 表头信息 */
    colHeaders(modelInfoList) {
      return ['样品编号'].concat(modelInfoList.map((item) => item.fieldName),['因子名称'])
    },

    /** hot table 列信息 */
    columns(modelInfoList) {
      const defaultColumns = [
        { title: '样品编号', data: 'sampleId', index: 0, readOnly: true, hidden: false, labBlankAverageFlag: false, labBlankAverageFiled: null, type: 'text' }
      ]
      const type = ['INT', 'BIGINT', 'TINYINT', 'SMALLINT', 'DECIMAL']
      const modelColumns = modelInfoList.reduce((acc, item, index) => {
        let title = item.aliasName || item.fieldName
        if (item.notNull && item.editAble) {
          title = `<span class="red">${item.aliasName || item.fieldName}</span>`
        } else if (!item.editAble) {
          title = `<span class="gray">${item.aliasName || item.fieldName}</span>`
        }
        acc.push({
          title,
          index: index + 1,
          data: this.getCamelCaseField(item.field),
          type: type.includes(item.type) ? 'numeric' : 'text',
          readOnly: !item.editAble,
          hidden: !item.isShow,
          formula: item.formula,
          labBlankAverageFlag: item.blankAverageFlag,
          labBlankAverageField: item.blankAverageField
        })
        return acc
      }, [])
      return defaultColumns.concat(modelColumns,[
        {
          title: '因子ID',
          data: 'itemId',
          index: modelInfoList.length + 1,
          readOnly: true,
          hidden: true,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        },
        {
          title: '因子名称',
          data: 'itemName',
          index: modelInfoList.length + 2,
          readOnly: true,
          hidden: false,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        }
      ])
    },

    hiddenColumns(modelInfoList) {
      return [...modelInfoList.map((item, index) => (!item.isShow ? index + 1 : null)).filter((item) => !!item), modelInfoList.length + 1]
    },

    /** 实验室需要进行空白的字段 */
    labBlankAverageColumns(columns) {
      return columns.filter((item) => item.labBlankAverageFlag)
    },

    /** 步骤配置信息 */
    stepForm(allTableData, columns, labBlankAverageColumns) {
      let idx = 0
      return this.sampleDataConfig.reduce((acc, item) => {
        const tableData = allTableData.filter((data) => item.sampleTypes.includes(data.sampleType))
        const defaultData = []
        if (item.key === 'labBlank') {
          // const labBlankAverageColumns = this.labBlankAverageColumns
          if (labBlankAverageColumns.length) {
            const filterIdx = labBlankAverageColumns.map((item) => item.index)
            const averageData = columns.reduce((acc, item) => {
              if (item.index === 0) {
                return { ...acc, [item.data]: '平均值:' }
              } else if (filterIdx.includes(item.index)) {
                // const colName = String.fromCharCode(65 + item.index)
                const colName = this.getColName(1 + item.index)
                return { ...acc, [item.data]: `=AVERAGE(${colName}1:${colName}${tableData.length})` }
              } else {
                return { ...acc, [item.data]: '' }
              }
            }, {})
            defaultData.push(averageData)
          }
        }
        if (tableData.length) {
          return [...acc, { key: item.key, title: item.title, data: tableData.concat(defaultData), index: idx++ }]
        }
        return acc
      }, [])
    },
    selectLocationByDevId(devId) {
      checkBatchApi.selectLocationByDevId({ devId }).then((res) => {
        if (res.code === 0) {
          if (res.data && res.data.locationId) {
            this.locationId = parseInt(res.data.locationId)
            const timer = setInterval(() => {
              if (this.checkTime.length > 0) {
                clearInterval(timer)
                this.changeLocation()
              }
            }, 500)
          }
        }
      })
    },
    getLocation() {
      Get(resLocationApi.list).then((res) => {
        // console.log(res)
        if (res.code === 0) {
          this.treeData = res.data
        }
      })
    },
    changeLocation(value, label, extra) {
      // console.log(value, label, extra)
      // console.log(this.checkTime)
      this.handleDevAndLocation(this.checkTime[0].format('YYYY-MM-DD HH:mm:ss'))
    },
    /**
     * 获取批次信息
     * @param id {number} 批次 ID
     */
    searchBatchInfo(id) {
      checkBatchApi.findBydId(id).then((res) => {
        if (res.code === 0) {
          const { wd, sd, makeBeginTime, makeEndTime } = res.data
          // wd, sd,
          Object.assign(this.batchInfo, { id, makeBeginTime, makeEndTime })
          this.handleDevAndLocation(makeBeginTime)
        } else {
          this.$message.error(`获取批次信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 查询模型信息
     * @param modelId 模型ID
     * @return {Promise} 查询结果
     */
    searchModelInfo(modelId) {
      const modelInfoApis = [sysModelFieldApi.getByModelId(modelId), checkFieldApi.list({ modelId, page: false })]
      return Promise.all(modelInfoApis).then(([res, checkRes]) => {
        if (res.code === 0) {
          const resData = orderBy(res.data || [], ['sort'])
          const checkData = checkRes.data
          const checkGroupData = groupBy(checkData, 'fieldId')
          resData.forEach((item) => {
            checkGroupData[item.fieldId] && Object.assign(item, ...checkGroupData[item.fieldId])
          })
          // this.modelInfoList.splice(0, this.modelInfoList.length, ...resData)
          // console.log('modelInfoList', this.modelInfoList)
          return resData
        } else {
          this.$message.error(`获取模型信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 查询检测录入的数据
     * @param batchId 批次ID
     * @param modelId 模型ID
     */
    searchEnterData({ modelId, batchId }) {
      return checkTaskEnterApi.getData(modelId, batchId).then((res) => {
        if (res.code === 0) {
          // this.originalData.splice(0, this.originalData.length, ...(res.data || []))
          return res.data
        } else {
          this.$message.error(`获取检测数据错误 :${res.msg}`)
        }
      })
    },

    /**
     * 填充录入的数据
     * @param dataList 录入的原始数据
     */
    fillEnterData(dataList, modelInfoList) {
      const result = dataList.reduce((acc, item, index) => {
        const fillData = modelInfoList.reduce((subAcc, subItem) => {
          const { dvalue: defValue, field } = subItem
          const camelCaseField = this.getCamelCaseField(field)
          // 设置默认值
          if (defValue && !item[camelCaseField]) {
            subAcc[camelCaseField] = defValue
          }
          return subAcc
        }, item)
        // console.log('fillData', fillData)
        acc.push(fillData)
        return acc
      }, [])
      // this.tableData.splice(0, this.tableData.length, ...result)
      // console.log('fillEnterData', this.tableData)
      return result
    },

    /** 跳转到检测明细 */
    toItemDetail() {
      this.$router.open({ path: '/cloud-quote/item-check-detail', query: { id: this.checkDetailId } })
    },

    /**
     * 根据字段获取对应的驼峰形式
     * @param field 原始字段形式
     * @return {String} 驼峰
     */
    getCamelCaseField(field) {
      const map = this.camelCaseFieldMap.mapping
      return map[field] || (map[field] = field.replace(/_(\w)/g, (all, letter) => letter.toUpperCase()))
    },

    /**
     * 处理步骤跳转
     * @param key 步骤配置的KEY
     * @param index 步骤配置的索引
     */
    handleStepChange(key, index, modelIndex) {
      this.$set(this.currentSteps, modelIndex, index)
      this.$set(this.currentTableKeys, modelIndex, key)
    },

    /**
     * 保存数据
     */
    submit: debounce(
      function () {
        const self = this
        if (this.fullPageLoading === true) {
          this.$message.error('正在保存数据，请勿操作！')
        } else {
          this.fullPageLoading = true
          this.$emit('changeBtnStatus', true)

          this.stagingData(0)

          let timer = setTimeout(() => {
            clearTimeout(timer)
            self.saveData()
          })
        }
      },
      500,
      { leading: false, trailing: true }
    ),
    async saveData() {
      let keyName = ''
      if (this.viewType === 'list') {
        keyName = 'list'
      }
      let saveData = []
      this.models.forEach((modelItem) => {
        const resultData = modelItem.stepForm.reduce((acc, item) => {
          const data = this.$refs[modelItem.id + item.key + keyName][0].getData()
          return [...acc, ...data]
        }, [])
        let basicData = []
        if (resultData.length > 0) {
          resultData.forEach((item) => {
            let tds = modelItem.tableData.filter((it) => it.sampleId === item.sampleId)
            // console.log(modelItem,tds);
            if (tds.length && tds[0].id) {
              item.id = tds[0].id
            }
          })
          let sampleIds = resultData.map((item) => item.sampleId)
          basicData = modelItem.tableData.filter((item) => !sampleIds.includes(item.sampleId))
        } else {
          basicData = modelItem.tableData
        }
        basicData.forEach((item) => {
          let obj = {
            aOne: null,
            aThree: null,
            aTwo: null,
            bOne: null,
            bThree: null,
            bTwo: null,
            cOne: null,
            cThree: null,
            cTwo: null,
            dOne: null,
            dThree: null,
            dTwo: null,
            eOne: null,
            eThree: null,
            eTwo: null,
            fOne: null,
            fThree: null,
            fTwo: null,
            // m: null,
            result: null,
            sampleId: item.sampleId,
            taskId: item.taskId
          }
          if (item.id) {
            obj.id = item.id
          }
          // 区分10倍稀释、100倍稀释、1000倍稀释
          if (item.dilutionRatio10) {
            obj.dilutionRatio10 = '10'
            obj.mTen = null
          } else if (item.dilutionRatio100) {
            obj.dilutionRatio100 = '100'
            obj.mTen = null
            obj.mHundred = null
          } else if (item.dilutionRatio1000) {
            obj.dilutionRatio1000 = '1000'
            obj.mHundred = null
            obj.mThousand = null
          }
          resultData.push(obj)
        })
        saveData.push({ modelId: modelItem.id, stepName: modelItem.stepName, resultData })
      })
      console.log(saveData)
      await checkBatchApi.update(Object.assign({}, this.batchInfo, { id: this.batchId }))
      if (saveData.length) {
        for (let i = 0; i < saveData.length; i++) {
          let item = saveData[i]
          for (let j = 0; j < item.resultData.length; j++) {
            let dataItem = item.resultData[j]
            if (
              (dataItem.aOne && dataItem.aOne !== '1' && dataItem.aOne !== '0' && dataItem.aOne !== '-1') ||
              (dataItem.aTwo && dataItem.aTwo !== '1' && dataItem.aTwo !== '0' && dataItem.aTwo !== '-1') ||
              (dataItem.aThree && dataItem.aThree !== '1' && dataItem.aThree !== '0' && dataItem.aThree !== '-1') ||
              (dataItem.bOne && dataItem.bOne !== '1' && dataItem.bOne !== '0' && dataItem.bOne !== '-1') ||
              (dataItem.bTwo && dataItem.bTwo !== '1' && dataItem.bTwo !== '0' && dataItem.bTwo !== '-1') ||
              (dataItem.bThree && dataItem.bThree !== '1' && dataItem.bThree !== '0' && dataItem.bThree !== '-1') ||
              (dataItem.cOne && dataItem.cOne !== '1' && dataItem.cOne !== '0' && dataItem.cOne !== '-1') ||
              (dataItem.cTwo && dataItem.cTwo !== '1' && dataItem.cTwo !== '0' && dataItem.cTwo !== '-1') ||
              (dataItem.cThree && dataItem.cThree !== '1' && dataItem.cThree !== '0' && dataItem.cThree !== '-1') ||
              (dataItem.dOne && dataItem.dOne !== '1' && dataItem.dOne !== '0' && dataItem.dOne !== '-1') ||
              (dataItem.dTwo && dataItem.dTwo !== '1' && dataItem.dTwo !== '0' && dataItem.dTwo !== '-1') ||
              (dataItem.dThree && dataItem.dThree !== '1' && dataItem.dThree !== '0' && dataItem.dThree !== '-1') ||
              (dataItem.eOne && dataItem.eOne !== '1' && dataItem.eOne !== '0' && dataItem.eOne !== '-1') ||
              (dataItem.eTwo && dataItem.eTwo !== '1' && dataItem.eTwo !== '0' && dataItem.eTwo !== '-1') ||
              (dataItem.eThree && dataItem.eThree !== '1' && dataItem.eThree !== '0' && dataItem.eThree !== '-1') ||
              (dataItem.fOne && dataItem.fOne !== '1' && dataItem.fOne !== '0' && dataItem.fOne !== '-1') ||
              (dataItem.fTwo && dataItem.fTwo !== '1' && dataItem.fTwo !== '0' && dataItem.fTwo !== '-1') ||
              (dataItem.fThree && dataItem.fThree !== '1' && dataItem.fThree !== '0' && dataItem.fThree !== '-1')
            ) {
              this.$message.error(`${item.stepName}下数据只能输入‘1’、‘0’、‘-1’，不能输入其它的值！`)
              this.fullPageLoading = false
              this.$emit('changeBtnStatus', false)
              return
            }
            if (
              !(
                (dataItem.aOne &&
                  dataItem.aTwo &&
                  dataItem.aThree &&
                  dataItem.bOne &&
                  dataItem.bTwo &&
                  dataItem.bThree &&
                  dataItem.cOne &&
                  dataItem.cTwo &&
                  dataItem.cThree &&
                  dataItem.dOne &&
                  dataItem.dTwo &&
                  dataItem.dThree &&
                  dataItem.eOne &&
                  dataItem.eTwo &&
                  dataItem.eThree &&
                  dataItem.fOne &&
                  dataItem.fTwo &&
                  dataItem.fThree) ||
                (!dataItem.aOne &&
                  !dataItem.aTwo &&
                  !dataItem.aThree &&
                  !dataItem.bOne &&
                  !dataItem.bTwo &&
                  !dataItem.bThree &&
                  !dataItem.cOne &&
                  !dataItem.cTwo &&
                  !dataItem.cThree &&
                  !dataItem.dOne &&
                  !dataItem.dTwo &&
                  !dataItem.dThree &&
                  !dataItem.eOne &&
                  !dataItem.eTwo &&
                  !dataItem.eThree &&
                  !dataItem.fOne &&
                  !dataItem.fTwo &&
                  !dataItem.fThree)
              )
            ) {
              this.$message.error(`A1~F3的数据请全部输入，或全部为空！`)
              this.fullPageLoading = false
              this.$emit('changeBtnStatus', false)
              return
            }
          }
        }

        const expressions = this.expressions.map((item) => {
          return {
            key: item.key,
            value: item.value,
            batchId: this.batchId
          }
        })
        // console.log(expressions)
        const saveApi = []
        for (let i = 0; i < saveData.length; i++) {
          let item = saveData[i]
          saveApi.push(checkTaskEnterApi.save(item.modelId, item.resultData))
        }
        saveApi.push(checkTaskEnterExpressionApi.save(expressions))
        Promise.all(saveApi)
          .then((ress) => {
            console.log(ress)
            ress.pop()
            let errorData = []
            for (let i = 0; i < ress.length; i++) {
              let item = ress[i]
              if (item.code !== 0) {
                errorData.push({
                  code: item.code,
                  msg: item.msg,
                  stepName: saveData[i].stepName
                })
              }
            }
            if (errorData.length) {
              errorData.forEach((item) => {
                this.$message.error(`【${item.stepName}】的检测数据录入失败 :${item.msg}`)
              })
              // this.fullPageLoading = false
            } else {
              this.$message.success(ress[0].msg)
              // this.fullPageLoading = false
              this.$emit('success')
              this.defaultGenerateData = false
            }
          })
          .catch((error) => {
            console.log(error)
            this.$message.error(error)
            // this.fullPageLoading = false
          })
          .finally(() => {
            this.getModels()
          })
      }
    },

    /**
     * 加载限值表达式
     * @param checkDetailId {number} 检测明细ID
     */
    loadLimitValueExpression(checkDetailId) {
      itemCheckDetailApi.list({ id: checkDetailId, page: false }).then((res) => {
        if (res.code === 0) {
          const result = res.data[0] ?? { limitValue: 0 }
          this.changeExpression('limitValue', result.limitValue)
        } else {
          this.$message.error(`获取检测明细信息出错 :${res.msg}`)
        }
      })
    },

    /**
     * 加载曲线表达式
     * @param batchId {number} 批次ID
     */
    loadCurveExpression(batchId) {
      checkBatchCurveApi.getCurveByBatchId(batchId).then((res) => {
        if (res.code === 0) {
          const { intercept: curveA = 0, slope: curveB = 0 } = res.data ?? {}
          this.changeExpression('curveA', curveA)
          this.changeExpression('curveB', curveB)
        } else {
          this.$message.error(`获取曲线信息出错 :${res.msg}`)
        }
      })
    },

    getStandardCalcResult(batchId) {
      checkStandardSolutionDemarcatesApi.getFourAveragePurity({ batchId }).then((res) => {
        if (res.code === 0) {
          this.changeExpression('standardCalcResult', res.data)
        }
      })
    },

    handleDevAndLocation(startTime) {
      if (this.locationId && startTime) {
        const time = startTime.substr(0, 13) + ':00'
        checkTaskBatchApi.selectLocation({ locationId: this.locationId, time, batchId: this.batchId }).then((res) => {
          if (res.code === 0 && res.data) {
            const wd = res.data.ambient
            const sd = res.data.temperature
            Object.assign(this.batchInfo, { wd, sd })
          }
        })
      }
    },

    onRangeOk(range) {
      const time = this.moment(range[0]).format('YYYY-MM-DD HH:mm:ss')
      this.handleDevAndLocation(time)
    },

    /**
     * 修改表达式的值
     * @param key 表达式的KEY
     * @param value 表达式的值
     */
    changeExpression(key, value) {
      if (key) {
        const existIdx = this.expressions.findIndex((item) => item.key === key)
        if (existIdx > -1) {
          this.expressions[existIdx].value = value
        } else {
          this.expressions.push({ key, value })
        }
      }
    },

    /**
     * 切换显示方式
     */
    changeViewType(e) {
      // console.log(e.target.value)
      let keyName = ''
      if (e.target.value === 'step') {
        keyName = 'list'
      }
      for (let m = 0; m < this.models.length; m++) {
        let model = this.models[m]

        for (let i = 0; i < model.stepForm.length; i++) {
          let item = model.stepForm[i]
          let data = this.$refs[model.id + item.key + keyName][0].getData()
          // console.log(item, data)
          for (let j = 0; j < item.data.length; j++) {
            let itemDataItem = item.data[j]
            let dataItem = data.filter((it) => it.sampleId === itemDataItem.sampleId)[0]
            if (dataItem) {
              for (const k in dataItem) {
                this.$set(this.models[m].stepForm[i].data[j], k, dataItem[k])
              }
            }
          }
        }
      }

      this.viewType = e.target.value
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.check-task-enter {
  .margin();
  .data();

  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-title {
    background: #ffffff;
    padding: 0 30px 30px;
  }

  &-content {
    padding: 30px 30px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .content {
    padding: 0 15%;
    position: relative;
  }
}
</style>
