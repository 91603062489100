<template>
  <div>
    <a-card v-bind="contentCard" v-for="item in curveDrawModels" :key="item.id">
      <a-radio slot="extra" @change="onSelectModel(item.id)" :checked="item.id === selectedModel">模板{{item.id}}</a-radio>
      <a-table
        :columns="connect(item.columns)"
        :dataSource="[]">
      </a-table>
    </a-card>

  </div>
</template>

<script>
import mixinList from '@/pages/common/mixins/list'
import { baseCurveDrawColumns, curveDrawModels, remarkCurveDrawColumns } from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'

export default {
  name: 'SiteModel',
  mixins: [mixinList],

  props: {
    selectSiteModelId: {
      type: [String, Number],
      required: false
    }
  },

  data () {
    return {

      baseCurveDrawColumns,
      remarkCurveDrawColumns,
      curveDrawModels,

      selectedModel: this.selectSiteModelId
    }
  },

  methods: {

    // 获取选中的数据
    getSelectData () {
      return this.selectedModel
    },

    connect (columns) {
      return [...this.baseCurveDrawColumns, ...columns, ...this.remarkCurveDrawColumns]
    },

    onSelectModel (index) {
      this.selectedModel = index
    }
  }
}
</script>
