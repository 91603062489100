<template>
  <a-modal
    :destroyOnClose="true"
    :width="modalWidth"
    :visible="visible"
    title="部门搜索"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    cancelText="关闭"
    :zIndex="zIndex"
    wrapClassName="ant-modal-cust-warp"
  >
    <!--部门树-->
    <template>
      <a-form :form="form">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="所属部门">
          <a-tree
            multiple
            treeCheckable="tree"
            checkable
            :defaultExpandAll="true"
            :checkedKeys="checkedKeys"
            allowClear="true"
            :checkStrictly="true"
            @check="onCheck"
            :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
            :treeData="departTree"
            placeholder="请选择上级部门"
          >
          </a-tree>
        </a-form-item>
        <div v-if="checkedDeparts.length > 0">
          已选部门：
          <div v-for="v in checkedDeparts" :key="v.key">
            <div>{{ v.title }} - 负责人：<a-switch checkedChildren="是" unCheckedChildren="否" @change="b => v.leader = b" :defaultChecked="v.leader" /></div>
          </div>
        </div>
      </a-form>
    </template>
  </a-modal>
</template>

<script>
import { getAction } from '@/api/common'
import { deptApi } from '@/api/system'

export default {
  name: 'DepartWindow',
  data () {
    return {
      checkedKeys: [], // 存储选中的部门id
      checkedDeparts: [],
      model: {}, // 存储SysUserDepartsVO表
      modalWidth: 400,
      zIndex: 3000,
      departTree: [],
      title: '操作',
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    show (checkedDeparts) {
      for (let i = 0; i < checkedDeparts.length; i++) {
        this.checkedKeys.push(checkedDeparts[i].key)
      }
      this.checkedDeparts = checkedDeparts
      this.queryDepartTree()
      this.visible = true
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.checkedDeparts = []
      this.checkedKeys = []
    },
    handleCancel () {
      this.close()
    },
    handleSubmit () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err) => {
        if (!err) {
          const formData = { checkedDeparts: this.checkedDeparts }
          that.$emit('ok', formData)
          that.confirmLoading = false
          that.close()
        }
      })
    },
    // 选择部门时作用的API
    onCheck (checkedKeys, info) {
      const temp = this.checkedDeparts
      this.checkedDeparts = []
      this.checkedKeys = checkedKeys.checked
      const checkedNodes = info.checkedNodes
      for (let i = 0; i < checkedNodes.length; i++) {
        const de = checkedNodes[i].data.props
        const t = temp.find(item => item.id == de.id)
        if (t && t.leader) {
          de.leader = true
        } else {
          de.leader = false
        }
        this.checkedDeparts.push(de)
      }
    },
    queryDepartTree () {
      getAction(deptApi.list).then((res) => {
        if (res.code === 0) {
          this.departTree = res.data
        }
      })
    },
    modalFormOk () {
    }
  }
}
</script>

<style scoped>
  .ant-table-tbody .ant-table-row td{
    padding-top:10px;
    padding-bottom:10px;
  }
</style>
