<template>
  <a-modal :visible="visible" v-bind="modalProps" v-on="modalProps">
    <a-card hoverable v-bind="sampleInfoCard">
      <a-tree :defaultExpandAll="true" :treeData="locationList" showLine @select="treeSelect"/>
    </a-card>
    <template slot="footer" v-bind="footerProps">
      <a-button :style="{marginRight: '8px'}" @click="cancel">关闭</a-button>
      <a-button :style="{marginRight: '8px'}" type="primary" @click="handleSubmit">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import { getAction } from '@/api/common'
import { tsSampleinfoApi } from '@/api/sample'
import { resLocationApi } from '@/api/quote'

export default {
  name: 'ChangeLocationForm',
  data () {
    return {
      visible: false,
      locationList: [],
      locationId: null,

      sampleInfoCard: {
        title: '',
        style: {},
        headStyle: {},
        bodyStyle: {},
        bordered: true,
        type: ''
      },

      modalProps: {
        width: 600,
        closable: false,
        destroyOnClose: true,
        centered: false,
        wrapClassName: 'receive-sample-form',
        bodyStyle: {},
        title: '请选择存放地址',
        ok: this.handleSubmit,
        cancel: this.cancel
      },

      footerProps: {
        style: {
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#FFFFFF',
          textAlign: 'right'
        }
      }
    }
  },
  mounted () {
    this.loadLocationList()
  },
  methods: {
    treeSelect (val, option) {
      if (option.selected) {
        this.locationId = option.selectedNodes[0].data.props.id
      }
    },
    loadLocationList () {
      return getAction(resLocationApi.list, {}).then(res => {
        this.locationList = res.data
      })
    },

    handleSubmit () {
      if (!this.dataList && !this.dataList.length) {
        this.$message.error('没有找到需要修改的样品信息')
        return false
      }
      if (!this.locationId) {
        this.$message.error('请先选择存放的位置')
        return false
      }
      const resultList = this.dataList.map(item => ({ id: item.id, locationId: this.locationId }))
      tsSampleinfoApi.changeLocation(resultList).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
          this.cancel()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    init (data) {
      this.visible = true
      this.dataList = data
    },

    cancel () {
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less">
.receive-sample-form {
  .ant-cascader-picker-label {
    width: auto;
  }
}
</style>
