<template>
  <!--  <a-modal-->
  <!--    :title="title"-->
  <!--    :destroyOnClose="true"-->
  <!--    :maskClosable="false"-->
  <!--    :visible="visible"-->
  <!--    :confirmLoading="confirmLoading"-->
  <!--    @ok="handleOk"-->
  <!--    @cancel="handleCancel"-->
  <!--    width="60%">-->
  <a-form :form="form" :style="{width: '1090px', margin: '0px auto 100px auto'}" :visible="visible">
    <!--      <component :is="component" :info.sync="info" />-->
    <enhanced-generate-form
      :data="jsonData"
      :remote="remoteFuncs"
      v-model="saveData"
      ref="generateForm">
      <template #relationProcessId="{model,compStyle:style,compClass:clazz}">
        <search-flow-edit
          :style="style"
          :clazz="clazz"
          @change="(data)=>{saveData.relationProcessId=data&&data.length&&data.map(item=>{return item.processInstanceId+'^'+item.processInstanceName}).join(',')}"/>
        <SearchFlowView
          v-if="saveData.relationProcessId"
          :value="saveData.relationProcessId"></SearchFlowView>
      </template>
      <template #projName="{model,compStyle:style,compClass:clazz}">
        <search-project-edit
          :style="style"
          :clazz="clazz"
          searchType="projName"
          @change="(data)=>{saveData.projName=data&&data.length&&data.map(item=>{return item.dataType === '商机' ? (item.id+'^'+item.title+'（'+item.dataType+'）') : (item.projId+'^'+item.projNo+'：'+item.projName+'（'+item.dataType+'）')}).join(',')}"/>
        <SearchProjectView
          v-if="saveData.projName"
          :value="saveData.projName"></SearchProjectView>
      </template>
      <template #relProjName="{model,compStyle:style,compClass:clazz}">
        <search-project-edit
          :style="style"
          :clazz="clazz"
          searchType="relProjName"
          @change="(data)=>{getProjectInfo(data)}"/>
        <!--          @change="(data)=>{saveData.relProjName=data&&data.length&&data.map(item=>{return item.dataType === '商机' ? (item.id+'^'+item.title+'（'+item.dataType+'）') : (item.projId+'^'+item.projNo+'：'+item.projName+'（'+item.dataType+'）')}).join(',')}"/>-->
        <SearchProjectView
          v-if="saveData.relProjName"
          :value="saveData.relProjName"></SearchProjectView>
      </template>
      <template #receiptProjName="{model,compStyle:style,compClass:clazz}">
        <search-project-receipt
          :style="style"
          :clazz="clazz"
          :isEdit="true"
          :projId="saveData.projId"
          :projName="saveData.projName"
          searchType="relProjName"
          @change="searchProjectReceipt2SaveData"/>
      </template>
      <template #projectReceiptPlan="{model,compStyle:style,compClass:clazz}">
        <template v-if="saveData.projType=='1'&&saveData.projId">
          <ProjectReceiptPlan :projId="saveData.projId"/>
        </template>
      </template>
      <template #projectReceiptInfo="{model,compStyle:style,compClass:clazz}">
        <template v-if="saveData.projType=='1'&&saveData.projId">
          <ProjectReceiptInfo :projId="saveData.projId"/>
        </template>
      </template>
      <template #staffName="{model,compStyle:style,compClass:clazz}">
        <search-staff-edit
          :style="style"
          :clazz="clazz"
          :staffId="saveData.staffId"
          :staffName="saveData.staffName"
          searchType="staffName"
          @change="(data)=>{if(data){$set(saveData, 'staffId',data.id);$set(saveData, 'staffName',data.name);}else{$set(saveData, 'staffId',null);$set(saveData, 'staffName','');}}"/>
        <template v-if="saveData.staffId">
          <SearchStaffView :value="saveData.staffId"></SearchStaffView>
        </template>
      </template>
      <template #enterDept="{model,compStyle:style,compClass:clazz}">
        <search-dept-edit
          :style="style"
          :clazz="clazz"
          :enterDeptId="saveData.enterDeptId"
          :enterDept="saveData.enterDept"
          searchType="enterDept"
          @change="(data)=>{saveData.enterDeptId=data&&data.length&&data.map(item=>{return item.id}).join(',');saveData.enterDept=data&&data.length&&data.map(item=>{return item.deptName}).join(',');}"/>
      </template>
      <template #payUser="{model,compStyle:style,compClass:clazz}">
        <search-payment-edit
          :style="style"
          :clazz="clazz"
          searchType="payUser"
          @change="(data)=>{getPaymentInfo(data)}"/>
      </template>
      <template #reportBaseInfo="{model,compStyle:style,compClass:clazz}">
        <ReportTaskDetailFlow v-bind="projData" v-if="projData.projId"></ReportTaskDetailFlow>
      </template>
      <template #receiveInfo="{model,compStyle:style,compClass:clazz}">
        <search-custom-edit
          :style="style"
          :clazz="clazz"
          :searchCustom="model.receiveCustomer"
          :searchCustomUser="model.receiveUser"
          @changeInfo="(data)=>{getReceiveInfo(data)}"
        ></search-custom-edit>
      </template>
    </enhanced-generate-form>
    <div class="ant-pro-footer-toolbar" style="width:100%;text-align: center;height: 100px;padding-top: 15px;" v-if="showCustomForm">
      <template >
        <a-button :style="{marginRight: '8px'}" @click="handleOk" type="primary" v-if="showCustomForm" :disabled="buttonDisabled">提交</a-button>
        <!--        <a-button :style="{marginRight: '8px'}" @click="handleSubmit" type="primary"v-if="cmtInfo.showEdit">重新提交</a-button>-->
      </template>
    </div>
  </a-form>
<!--  </a-modal>-->
</template>

<script>

import Vue from 'vue'
import { Modal } from 'ant-design-vue'
import SearchFlowEdit from '@/pages/flow/module/components/search-flow/SearchFlowEdit'
import SearchFlowView from '@/pages/flow/module/components/search-flow/SearchFlowView'
import { MessageBox, Message } from 'element-ui'

import { customFormFunc } from '@/pages/flow/module/CustomFormFunc'
import { GenerateForm, hoc } from 'ahmux-form-designing'

import { getAction } from '@/api/common'
import { modelApi, flowApi } from '@/api/flow'
import { userApi } from '@/api/system'
import SearchProjectEdit from '@/pages/flow/module/components/search-project/SearchProjectEdit'
import SearchProjectView from '@/pages/flow/module/components/search-project/SearchProjectView'

import SearchProjectReceipt from '@/pages/flow/module/components/search-project-receipt/SearchProjectReceipt'
import ProjectReceiptPlan from '@/pages/flow/module/components/search-project-receipt/ProjectReceiptPlan'
import ProjectReceiptInfo from '@/pages/flow/module/components/search-project-receipt/ProjectReceiptInfo'

import SearchStaffEdit from '@/pages/flow/module/components/search-staff/SearchStaffEdit'
import SearchStaffView from '@/pages/flow/module/components/search-staff/SearchStaffView'

import SearchDeptEdit from '@/pages/flow/module/components/search-dept/SearchDeptEdit'
import SearchPaymentEdit from '@/pages/flow/module/components/search-payment/SearchPaymentEdit'
import ReportTaskDetailFlow from './components/search-project/ReportTaskDetailFlow'
import SearchCustomEdit from '@/pages/flow/module/components/search-custom/SearchCustomEdit'

const EnhancedGenerateForm = hoc(GenerateForm, {
  watch: {
    'models': {
      deep: true,
      handler (newVal, oldVal) {
        if (newVal.startTime && newVal.endTime && newVal.startTime !== '' && newVal.endTime !== '') {
          const dateBegin = new Date(newVal.startTime)
          const dateEnd = new Date(newVal.endTime)
          const dateDiff = dateEnd.getTime() - dateBegin.getTime()// 时间差的毫秒数
          // if (newVal.startTime.length === 10) {
          // const dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) + 1// 计算出相差天数
          // newVal.days = dayDiff
          // } else {
          // const hourDiff = Math.floor(dateDiff / (3600 * 1000) * 10) / 10 // 计算出相差小时数
          const hourDiff = Math.floor(dateDiff / (3600 * 1000) * 100) / 100 // 计算出相差小时数
          newVal.hours = hourDiff
          // }
          const dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) + 1// 计算出相差天数
          if (dayDiff > 1) {
            if (hourDiff % 24 === 0) {
              newVal.days = parseInt(hourDiff / 24)
            } else {
              newVal.days = (hourDiff % 24 > 5 || hourDiff % 24 === 0) ? parseInt(hourDiff / 24) + 1 : parseInt(hourDiff / 24) + 0.5 // 5小时内计0.5天，超过5小时计为1天
            }
          } else {
            if (hourDiff === 0) {
              newVal.days = 0
            } else {
              newVal.days = hourDiff > 5 ? 1 : 0.5 // 5小时内计0.5天，超过5小时计为1天
            }
          }
          // newVal.days = hourDiff % 10 > 5 ? parseInt(hourDiff / 10) + 1 : parseInt(hourDiff / 10) + 0.5 // 5小时内计0.5天，超过5小时计为1天
        }
        if (newVal.accountInfos && newVal.accountInfos.length > 0) {
          let total = 0
          for (const i in newVal.accountInfos) {
            if (newVal.accountInfos[i].startTime && newVal.accountInfos[i].endTime && newVal.accountInfos[i].startTime !== '' && newVal.accountInfos[i].endTime !== '') {
              const dateBegin = new Date(newVal.accountInfos[i].startTime)
              const dateEnd = new Date(newVal.accountInfos[i].endTime)
              const dateDiff = dateEnd.getTime() - dateBegin.getTime()// 时间差的毫秒数
              const dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) + 1// 计算出相差天数
              newVal.accountInfos[i].days = dayDiff
            }
            if (newVal.accountInfos[i].travelAllowance || newVal.accountInfos[i].trafficExp || newVal.accountInfos[i].stayExp || newVal.accountInfos[i].otherExp) {
              const subtotal = parseFloat(newVal.accountInfos[i].travelAllowance) + parseFloat(newVal.accountInfos[i].trafficExp) +
                parseFloat(newVal.accountInfos[i].stayExp) + parseFloat(newVal.accountInfos[i].otherExp)
              newVal.accountInfos[i].subtotal = subtotal
            }
            if (newVal.accountInfos[i].subtotal) {
              total += newVal.accountInfos[i].subtotal
            }
          }
          newVal.total = total
        }
      }
    }
  }
})

export default {
  name: 'FlowFormModal',
  components: { SearchCustomEdit, ReportTaskDetailFlow, SearchPaymentEdit, SearchProjectEdit, EnhancedGenerateForm, SearchFlowEdit, SearchFlowView, SearchProjectView, SearchProjectReceipt, ProjectReceiptPlan, ProjectReceiptInfo, SearchStaffEdit, SearchStaffView, SearchDeptEdit },
  data () {
    return {
      saveData: {},
      component: Object,
      showCustomForm: false,
      confirmLoading: false,
      visible: false,
      key: '',
      info: {},
      form: this.$form.createForm(this),
      jsonData: {},
      values: {},
      title: {},
      scrollTop: 0,
      processName: null,
      flowId: null,
      buttonDisabled: false,
      projData: {}
    }
  },
  computed: {
    remoteFuncs () {
      customFormFunc._self = this
      return customFormFunc
    }
  },

  created () {
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$message = Message
    this.scrollTop = window.document.body.scrollTop
    window.document.body.scrollTop = 0
  },

  beforeDestroy () {
    Vue.prototype.$confirm = Modal.confirm
    Vue.prototype.$message = require('ant-design-vue/lib/message').default
    window.document.body.scrollTop = this.scrollTop
  },

  methods: {
    getPaymentInfo (data) {
      const payment = data && data.length > 0 ? data[0] : null
      if (payment !== null) {
        this.saveData.bankName = payment.bank
        this.saveData.cardNum = payment.bankNbr
        this.saveData.payUser = payment.name
        this.saveData.paymentRefId = payment.refId
        this.saveData.invoiceCsr = payment.name
        this.saveData.invoiceCsrId = payment.refId
      }
    },
    getCompanyInfo (data) {
      const payment = data && data.length > 0 ? data[0] : null
      if (payment !== null) {
        this.saveData.invoiceCompany = payment.name
      }
    },
    getReceiveInfo (data) {
      if (data !== null) {
        Object.assign(this.saveData, data)
      }
    },
    getProjectInfo (data) {
      const costomer = data && data.length > 0 ? data[0] : null
      if (costomer !== null) {
        this.saveData = Object.assign({}, this.saveData, {
          customer: costomer.customerContactsName,
          company: costomer.customerName,
          amount: costomer.projContractAmount,
          telephone: costomer.customerContactsPhone,
          projId: costomer.projId
        })
        if (['jx_fukuan', 'jx_fukuanwaibao'].includes(this.key)) this.saveData.processTitle = costomer.projNo + '-' + costomer.projName
        if (['report_coordination'].includes(this.key)) { // 报告外协审核
          if (data && data.length > 0) {
            if (data.length > 1) {
              this.$message.warn('项目数过多，请选择一个项目!')
            } else {
              this.projData.projId = costomer.projId
            }
          }
        }
      } else {
        this.saveData = Object.assign({}, this.saveData, {
          customer: null,
          company: null,
          amount: null,
          telephone: null,
          processTitle: null
        })
      }
      this.saveData.relProjName = data && data.length && data.map(item => {
        return item.dataType === '商机' ? (item.id + '^' + item.title + '（' + item.dataType + '）') : (item.projId + '^' + item.projNo + '：' + item.projName + '（' + item.dataType + '）')
      }).join(',')
    },
    show (record) {
      this.key = record.key
      this.title = '发起【' + record.name + '】'
      this.processName = record.name
      this.queryProcessCustom()
      this.user = this.$store.getters.userInfo
      this.saveData = { applyTime: new Date().format('yyyy-MM-dd HH:mm:ss'), userName: this.user.realname, userId: this.user.id }
      this.projData = {}
      getAction(userApi.rolesAndDepts, { id: this.user.id }).then((res) => {
        if (res.code === 0) {
          let dept = ''
          for (const i in res.data.depts) {
            dept = dept === '' ? res.data.depts[i].deptName : dept + ',' + res.data.depts[i].deptName
          }
          this.saveData.dept = dept
        }
        this.$nextTick(() => {
          this.visible = true
          this.showCustomForm = true
        })
      })
    },

    showByModel (json, model, other) {
      this.jsonData = json
      this.key = other.tableName
      this.processName = other.processName
      this.flowId = other.flowId
      this.saveData = model
      this.projData = {}
      // this.$set(this, 'saveData', model)
      this.$nextTick(() => {
        this.visible = true
        this.showCustomForm = true
        this.buttonDisabled = false
      })
    },

    handleOk () {
      // 调用此方法验证表单数据和获取表单数据
      this.$refs.generateForm.$children[0].getData().then(data => {
        if (data.hasOwnProperty('accountInfos') && (typeof data.accountInfos === 'undefined' || data.accountInfos.length === 0)) {
          this.$message.warn('请填写明细')
        } else if (data.hasOwnProperty('staffName') && data.staffName === '') {
          this.$message.warn('请选择入职人员信息')
        } else if (data.hasOwnProperty('enterDept') && data.enterDept === '') {
          this.$message.warn('请选择入职部门')
        } else {
          const tempData = {}
          for (const item in data) {
            if (typeof data[item] === 'object' && data[item] !== null) {
              if (item === 'otherStaff') {
                tempData[item] = data[item].join(',')
              } else if (item === 'accountInfos') {
                data[item].forEach(it => {
                  if (it.hasOwnProperty('vehType')) {
                    it.vehType = it.vehType.join(',')
                  }
                })
                tempData[item] = data[item]
              } else {
                const options = []
                data[item].forEach(it => {
                  if (it.hasOwnProperty('url') && it.hasOwnProperty('name')) {
                    options.push({ name: it.name, url: it.url })
                  } else {
                    options.push(it)
                  }
                })
                tempData[item] = options
              }
            } else {
              tempData[item] = data[item]
            }
          }
          // 数据校验成功
          this.buttonDisabled = true // 控制提交按钮
          // data 为获取的表单数据
          this.confirmLoading = true
          const { key: tableName, processName } = this
          if (this.flowId !== 0) {
            tempData.id = this.flowId
          }
          const saveData = { tableName, processName, data: tempData }
          if (tempData.processTitle) {
            // processTitle 不为空时变更流程名称
            saveData.processName = saveData.processName + '-' + tempData.processTitle
          }
          flowApi.saveByModel({ modelJson: JSON.stringify(saveData) }).then(res => {
            if (res.code === 0) {
              this.$message.success('操作成功')
              // this.showCustomForm = false
              this.$router.push({ name: 'flow-task' })
            } else {
              this.buttonDisabled = false
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.visible = false
            this.buttonDisabled = false
          })
        }
      }, () => {
        this.$refs.dialog.end()
      })
    },
    handleCancel () {
      this.visible = false
    },
    queryProcessCustom () {
      getAction(modelApi.getCustomForm, { processId: this.key }).then(res => {
        if (res.code === 0) {
          this.jsonData = JSON.parse(res.data.json)
          this.$nextTick(() => {
            this.showCustomForm = true
          })
        } else {
        }
      })
    },
    searchProjectReceipt2SaveData (data) {
      if (data) {
        this.saveData = Object.assign({}, this.saveData, {
          projType: data.projType,
          projId: data.projId,
          projName: data.projName,
          processTitle: data.projName
        })
      } else {
        this.saveData = Object.assign({}, this.saveData, {
          projType: null,
          projId: null,
          projName: null,
          processTitle: '-'
        })
      }
    }
  }
}
</script>

<style lang="less">
@import '../../../assets/less/custom/index.less';

.fpd /deep/ .ant-pro-footer-toolbar {
  height: 115px;
  border-top: #1890FF 2px solid;
}
</style>
