<template>
  <div class="make-sample-storage-apply">
    <a-table v-bind="contentTable" :rowSelection="customRowSelection" :scroll="scrollProp" v-on="contentTable.action">>

      <template #projName="data,record">
        <span>【{{ record.projNo }}】{{ record.projName }}</span>
      </template>

      <template #type="type">
        <a-tag :color="sampleInfoTypeDataMap[type].color">{{ sampleInfoTypeDataMap[type].label }}</a-tag>
      </template>

      <template #projName="data,record">
        【{{ record.projNo }}】{{ data }}
      </template>

      <template #makeTime="data,record">
        <template v-if="record.makeBeginTime">
          <span style="color: green">起：</span>{{ record.makeBeginTime | minuteFilter }}<br/>
          <span style="color: red">止：</span>{{ record.makeEndTime | minuteFilter }}
        </template>
      </template>

      <template #itemNameStr="data,record">
        {{ record.cateNameStr }}/{{ data }}
      </template>

      <template #volume="data,record">
        <span v-if="data">
          <span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.volumeUnit }}
        </span>
        <span v-else>-</span>
      </template>

      <template #validity="data,record">
        <span v-if="data"><span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.validityUnit }}</span>
        <span v-else>-</span>
      </template>

      <template #status="status">
        <a-tag :color="makeSampleInfoStatusDataMap[status].color">{{ makeSampleInfoStatusDataMap[status].label }}</a-tag>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>

    </a-table>
  </div>
</template>

<script>
import { makeSampleApi, sampleTypeSetting, makeSampleApplyApi } from '@/api/makeSample/makeSamplelApi'

import { makeSampleInfoStatusEnum, makeSampleInfoStatusDataMap } from '@/api/makeSample/makeSampleConstant'
import { makeSampleApplyStatusEnum } from '@/api/makeSample/makeSampleApplyConstant'
import { sampleInfoTypeDataMap } from '@/api/sampleInfo/sampleInfoConstant'

import STable from '@/components/Table'
import list from '@/pages/common/mixins/list'

export default {
  components: { STable },

  mixins: [list],

  props: {
    projId: { type: Number, required: false, default: 0 },
    statuses: { type: Array, required: false, default: () => { return [makeSampleInfoStatusEnum.done] } },
    types: { type: Array, required: false, default: () => { return sampleTypeSetting.sampleOutBound } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      sampleTypeSetting,
      makeSampleInfoStatusEnum,
      sampleInfoTypeDataMap,
      makeSampleInfoStatusDataMap,

      processedApplyIds: [],
      otherHeight: 90,
      contentTable: {
        rowKey: 'idTemp',
        bordered: true,
        pagination: false,

        dataSource: [],

        columns: [
          { title: '样品编号', align: 'center', dataIndex: 'id', width: 160 },
          { title: '样品类型', align: 'center', dataIndex: 'type', width: 100, scopedSlots: { customRender: 'type' } },
          { title: '项目名称', align: 'center', dataIndex: 'projName', scopedSlots: { customRender: 'projName' } },
          { title: '检测项', align: 'center', dataIndex: 'itemNameStr', width: 200, scopedSlots: { customRender: 'itemNameStr' } },
          { title: '制样人', align: 'center', dataIndex: 'makeUserName', width: 100 },
          { title: '制样时间', align: 'center', dataIndex: 'makeTime', width: 180, scopedSlots: { customRender: 'makeTime' } },
          { title: '存储介质', align: 'center', dataIndex: 'storage', width: 120 },
          { title: '份数', align: 'center', dataIndex: 'num', width: 80 },
          { title: '体积/重量', align: 'center', dataIndex: 'volume', width: 90, scopedSlots: { customRender: 'volume' } },
          { title: '有效期', align: 'center', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
          { title: '当前状态', align: 'center', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
        ]
      }
    }
  },

  computed: {
    queryParam () {
      const { projId, statuses, types } = this
      return { projId, statuses, types }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  watch: {
    processedApplyIds: {
      immediate: true,
      handler (val) {
       val?.length && this.loadData(val)
      }
    },

    'queryParam.projId': {
      immediate: true,
      handler (val) {
        val && this.getProcessedApply(val)
      }
    }
  },

  methods: {
    // 获取已经处理的申请单信息
    getProcessedApply (projId) {
      makeSampleApplyApi.list({ projId, page: false, status: makeSampleApplyStatusEnum.processed }).then(res => {
        if (res.code === 0) {
          const applyIds = res.data?.map(item => item.id)
          this.processedApplyIds.splice(0, this.processedApplyIds.length, ...applyIds)
        } else {
          this.$message.error(`无法获取已经处理的质控申请单：${res.msg}`)
        }
      })
    },

    loadData (param) {
      return makeSampleApi.list(Object.assign({ page: false, applyIds: this.processedApplyIds }, param)).then(res => {
        if (res.code === 0) {
          this.contentTable.dataSource.splice(0, this.contentTable.dataSource.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    save ({ pickupApplyUser, pickupApplyTime }) {
      const saveData = this.rowSelection.selectedRows.length ? this.rowSelection.selectedRows : this.contentTable.dataSource
      if (!saveData.length) {
        this.$message.error('请先选择需要申请的样品！')
        return false
      }
      saveData.forEach(item => {
        item.pickupApplyUser = pickupApplyUser
        item.pickupApplyTime = pickupApplyTime
      })
      makeSampleApi.storageApply(saveData).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "_idnex";
</style>
