<template>
  <a-modal v-bind="modal" v-on="modal">
    <div style="height: 750px" class="ahmux">
      <a-row>
        <a-col>
          <a-card style="width:100%;height: 750px;overflow: auto;">
            <template slot="title">
              <a-row style="margin-bottom: 10px">
                <a-radio-group v-model="itemType">
                  <a-radio :value="1">分包</a-radio>
                  <a-radio :value="0">正常</a-radio>
                  <a-radio :value="-1">全部</a-radio>
                </a-radio-group>
                <span style="font-size: 12px"><a-icon type="star" theme="twoTone" two-tone-color="#fa8c16"/>表示已在其他任务中进行分包</span>
              </a-row>
            </template>
            <a-row>
              <a-checkbox-group v-model="cateIdItemIdList" style="width: 100%">
                <template v-for="(cate, cateIdx) in cateItemList">
                  <a-card :key="cateIdx">
                    <template slot="title">
<!--                      <a-checkbox :value="cate.id" @change="(e)=>cateChange(e,cate.id)">{{ cate.name }}</a-checkbox>-->
                      {{ cate.name }}
                    </template>
                    <a-row :span="24">
                      <template v-for="(citem, cidx) in cate.itemList">
                        <a-col v-if="itemType==-1||itemType==citem.itemType" :key="cidx" :span="8" style="line-height: 35px;width: 170px;">
                          <a-checkbox :value="`${citem.cateId}_${citem.itemId}`">
                            <a-tag v-if="citem.itemType==0" color="green" :title="citem.itemName">
                              {{ subStr(citem.itemName) }}
                            </a-tag>
                            <a-tag v-if="citem.itemType==1" color="red" :title="citem.itemName">
                              {{ subStr(citem.itemName) }}
                            </a-tag>
                            <a-icon v-if="citem.subFlag=='1'" type="star" theme="twoTone" two-tone-color="#fa8c16" style="position: absolute;top: 10px;"/>
                          </a-checkbox>
                        </a-col>
                      </template>
                    </a-row>
                  </a-card>
                </template>
              </a-checkbox-group>
            </a-row>
          </a-card>
        </a-col>
        <!--        <a-col span="12">-->
        <!--          <a-card style="height: 750px">-->
        <!--            -->
        <!--          </a-card>-->
        <!--        </a-col>-->
      </a-row>
    </div>
  </a-modal>
</template>

<script>

import { projSubcontractTaskApi, projTaskItemApi } from '@/api/project'
import ARow from 'ant-design-vue/es/grid/Row'
import ACol from 'ant-design-vue/es/grid/Col'

export default {
  name: 'SubTaskCheckForm',
  components: { ACol, ARow },
  props: {
    siteCateList: {
      type: Array,
      default: () => []
    },
    cateIdItemIdList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      modal: {
        title: '分包项选择',
        width: 800,
        visible: false,
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
        cancel: this.cancel,
        ok: this.handleOK
      },
      projId: null,
      subtaskId: null,
      itemType: 1,
      cateItemList: [],
      cateMap: {},
      citemMap: {},
      siteCateMap: {},
      curSiteCate: {},
      targetKeys: []
    }
  },
  methods: {
    add (projId, subtaskId) {
      this.projId = projId
      this.subtaskId = subtaskId
      this.itemType = 1
      this.listCateSubItemByProjId()
      this.modal.visible = true
    },
    listCateSubItemByProjId () {
      var self = this
      if (self.projId) {
        projSubcontractTaskApi.listCateSubItemByProjId({ projId: self.projId }).then(res => {
          if (res.code === 0) {
            var cateItemList = res.data
            self.cateItemList = cateItemList
            this.itemToMap()
          } else {
            self.$message.error('查询失败')
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    itemToMap () {
      var cateMap = {}
      var citemMap = {}
      if (this.cateItemList) {
        this.cateItemList.forEach((cate, cateIdx) => {
          if (cate) {
            cateMap[cate.id] = cate
            cate.itemList.forEach((citem, cIdx) => {
              var key = `${citem.cateId}_${citem.itemId}`
              citemMap[key] = citem
            })
          }
        })
      }
      this.cateMap = cateMap
      this.citemMap = citemMap
    },
    getCitem () {
      var cateIdItemIdList = this.cateIdItemIdList
      var mapItem = {}
      var mapString = {}
      if (cateIdItemIdList) {
        cateIdItemIdList.forEach((id, idx) => {
          var citem = this.citemMap[id]
          // itemList.push(citem)
          if (mapItem[citem.cateName]) {
            mapItem[citem.cateName].push(citem)
            mapString[citem.cateName].push(citem.itemName)
          } else {
            mapItem[citem.cateName] = []
            mapItem[citem.cateName].push(citem)
            mapString[citem.cateName] = []
            mapString[citem.cateName].push(citem.itemName)
          }
        })
      }
      var itemContent = ''
      for (var k in mapString) {
        if (itemContent) {
          itemContent += '\n' + k + '：' + mapString[k].join('，')
        } else {
          itemContent += k + '：' + mapString[k].join('，')
        }
      }
      return itemContent
    },
    handleOK () { // 提交表单
      const self = this
      const itemContent = self.getCitem()
      self.$emit('ok', { itemContent: itemContent, cateIdItemIdList: this.cateIdItemIdList })
      self.cancel()
    },
    cancel () {
      this.modal.visible = false
    },
    includes (arr1, arr2) {
      // 数组arr1 包含数组arr2
      return arr2.every(val => arr1.includes(val))
    },
    subStr (text, len) {
      len = len && len > 0 ? len : 8
      if (text) {
        if (text.length > len) {
          return text.substring(0, len) + '...'
        } else {
          return text
        }
      } else {
        return ''
      }
    }
  },
  mounted () {
  }
}
</script>
<style type="less">
  .ant-transfer-list {
    height: 300px;
  }
  .ahmux {
    .ant-card-head-title{
      padding: 12px 0;
    }
    .ant-card-body{
      padding: 12px 0;
    }
  }
</style>

<style type="less" scoped>
  .ahmux {
    .ant-card-head-title{
      padding: 12px 0;
    }
    .ant-card-body{
      padding: 12px 0;
    }
  }
</style>
