<template>
  <div class="check-group-batch-project-detail">
    <a-collapse>
      <a-collapse-panel v-for="(site,siteId) of groupCheckSampleItemBySite(taskList)" :key="`site_${siteId}`">
        <template #header>{{ site.siteName }}</template>
        <div class="wrapper">
          <div v-for="(period,numPeriod) of groupCheckSampleItemByNumPeriod(site.details)" :key="`site_period_${numPeriod}`" class="period">
            <div class="period-title">第【{{ numPeriod }}】天</div>
            <div class="period-content">
              <div v-for="(frequency,numFrequency) of groupCheckSampleItemByNumFrequency(period.details)" :key="`site_period_frequency_${numFrequency}`" class="frequency">
                <div class="frequency-title">D{{ numPeriod }}-{{ numFrequency }}</div>
                <div class="frequency-detail">
                  <div v-for="(sample,sampleId) of groupCheckSampleItemBySampleId(frequency.details,true,true)" :key="`site_period_frequency_sample_${sampleId}`" class="frequency-detail-item">
                    <div class="frequency-detail-item-left">
                      <span>
                        {{ sampleId }}
                      </span>
                      <a-tag :color="sampleInfoTypeDataMap[sample.sampleType].color">{{ sampleInfoTypeDataMap[sample.sampleType].label }}</a-tag>
                    </div>
                    <div class="frequency-detail-item-right">

                      <div v-for="(taskInfo,taskId) of sample.details" :key="`site_period_frequency_sample_task_${taskId}`" class="row">
                        <div class="item">
                          <el-tag :type="checkTaskResultDataStatusDataMap[taskInfo.dataStatus].color">{{ checkTaskResultDataStatusDataMap[taskInfo.dataStatus].label }}</el-tag>
                        </div>

                        <div class="item">
                          <span class="item-title">检测员</span>
                          <span class="item-content">{{ taskInfo.checkUserName }}</span>
                        </div>

                        <div class="item">
                          <span class="item-title">检测项</span>
                          <a-tooltip>
                            <template #title>{{ taskInfo.cateName }}/{{ taskInfo.itemName }}</template>
                            <span class="item-content feature" >
                              <span class="ahmux-text-ellipsis" style="width:200px;float:right;">
                                {{ taskInfo.cateName }}/{{ taskInfo.itemName }}
                              </span>
                            </span>
                          </a-tooltip>
                        </div>

                        <div class="item">
                          <span class="item-title">检测结果</span>
                          <span class="item-content">
                            <template v-if="[checkTaskResultDataStatusEnum.pending,checkTaskResultDataStatusEnum.broken].includes(taskInfo.dataStatus)">
                              --
                            </template>
                            <template v-else>
                              <a-tag v-if="taskInfo.resultType==='3'" color="blue">{{ resultDataType[taskInfo.resultType] }}</a-tag>
                              <a-tag v-else-if="taskInfo.resultType==='2'" color="blue">{{ taskInfo.value }}L</a-tag>
                              <a-tag v-else color="blue">{{ taskInfo.value }}</a-tag>
                            </template>
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { checkSampleItemApi } from '@/api/check/checkTaskApi'
import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import { checkTaskResultDataStatusDataMap, checkTaskResultDataStatusEnum } from '@/api/check/checkTaskResultConstant'
import { needLabTypeEnum, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

import { groupBy } from 'lodash'

export default {
  props: { taskList: { type: Array, required: true } },

  data () {
    return {
      checkTaskStatusEnum,
      checkTaskResultDataStatusEnum,
      checkTaskResultDataStatusDataMap,
      sampleInfoTypeDataMap,

      checkSampleItemList: [],

      sampleCard: {
        title: '按检测因子'
      },

      itemCard: {
        title: '按因子'
      }
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (newValue, oldValue) {
        newValue && this.initData(newValue)
      }

    }
  },

  computed: {
    resultDataType () {
      return { ...this.$store.state.code.cache.check_result_data_type }
    }
  },

  methods: {
    // 初始化数据
    initData (projId) {
      this.querySampleItemById(projId).then(res => {
        if (res.code === 0) {
          this.checkSampleItemList.splice(0, this.checkSampleItemList.length, ...(res.data || []))
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询项目对应的检测结果信息
    querySampleItemById (projId) {
      return checkSampleItemApi.list({ projId, sampleTypes: needLabTypeEnum, sampleTemp: false, page: false }).then(res => res)
    },

    // 检测因子按照点位进行分组
    groupCheckSampleItemBySite (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.siteId] || (acc[sample.siteId] = { siteId: sample.siteId, siteName: sample.siteName, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 检测因子按照天数进行分组
    groupCheckSampleItemByNumPeriod (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.numPeriod] || (acc[sample.numPeriod] = { numPeriod: sample.numPeriod, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 检测因子按照频次进行分组
    groupCheckSampleItemByNumFrequency (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.numFrequency] || (acc[sample.numFrequency] = { numFrequency: sample.numFrequency, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 检测因子按照样品ID进行分组
    groupCheckSampleItemBySampleId (list, taskStatusGroup = false, dataStatusGroup = false) {
      const result = list.reduce((acc, sample) => {
        (acc[sample.sampleId] || (acc[sample.sampleId] = { sampleId: sample.sampleId, sampleType: sample.sampleType, details: [] })).details.push(sample)
        return acc
      }, {})
      if (taskStatusGroup) {
        Object.values(result).forEach(item => {
          item.taskStatusGroup = groupBy(item.details, 'orderStatus')
        })
      }
      if (dataStatusGroup) {
        Object.values(result).forEach(item => {
          item.dataStatusGroup = groupBy(item.details, 'dataStatus')
        })
      }
      return result
    },

    // 检测因子按照分类进行分组
    groupCheckSampleItemByCate (list, statusGroup = false) {
      const result = list.reduce((acc, sample) => {
        (acc[sample.cateId] || (acc[sample.cateId] = { cateId: sample.cateId, cateName: sample.cateNameStr, details: [] })).details.push(sample)
        return acc
      }, {})
      if (statusGroup) {
        Object.values(result).forEach(item => {
          item.statusGroup = groupBy(item.details.filter(item => !item.temp), 'status')
          item.statusGroup[sampleInfoTypeEnum.virtual] = item.details.filter(item => item.temp)
        })
      }
      return result
    }
  }
}
</script>

<style lang="less" scoped>
@import "project-detail";
</style>
