<template>
  <div>
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="综合查询" v-bind="formItemLayout">
              <a-input v-model.trim.lazy="queryParam.compositionName" placeholder="类目名称/类别、规格型号、存储条件模糊查询" @pressEnter="search" />
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps" :style="{ textAlign: 'left', marginBottom: '15px' }">
            <span class="table-page-search-submitButtons">
              <a-button-group>
                <a-button icon="search" type="primary" @click="search">查询</a-button>
                <a-button icon="reload" @click="reset">重置</a-button>
              </a-button-group>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <a-card v-bind="actionCard">
      <div class="fr">
        <a-button type="primary" icon="plus" @click="handleAdd">添加类目</a-button>
      </div>
    </a-card>
    <a-card v-bind="contentCard">
      <a-row :gutter="8">
        <a-col :span="3" style="width: 15%">
          <div :style="{ maxHeight: scrollProp.y + 50 + 'px', overflow: 'auto', border: '1px solid #E8E8E8' }">
            <a-tree
              :treeData="datasource"
              :selectedKeys="selectedKeys"
              :checkedKeys="checkedKeys"
              @select="onCateSelect"
              @check="onCateCheck"
              :expanded-keys="expandKeys || ['0']"
              @expand="handleExpandTree"
            />
            <!-- <a-tree :treeData="locationSource" :selectedKeys="locationSelectedKeys" :checkedKeys="locationCheckedKeys" @select="onLocalSelect" @check="onLocalCheck"/> -->
          </div>
        </a-col>

        <a-col :span="21" style="width: 85%">
          <s-table :rowSelection="rowSelectionType ? rowSelection : null" :scroll="scrollProp" v-bind="contentTable">
            <template #cateId="text">{{ findNode(categoryList, '2-' + text) }}</template>
            <template #cateName="text">{{ text }}</template>

            <template #cateType="data">
              <template v-if="resMaterialSingleCateTypeDataMap[data]">
                <a-tag :color="resMaterialSingleCateTypeDataMap[data].color">{{ resMaterialSingleCateTypeDataMap[data].label }}</a-tag>
              </template>
            </template>

            <template #unit="text">{{ materialUnitCode[text] }}</template>

            <!-- <template #locationId="text,record">
              <a @click="handleLocationClick(record)">{{ record.locationName || '-' }}</a>
            </template> -->

            <template #materialImg="text, record">
              <el-popover placement="top" title="" trigger="hover">
                <el-image slot="reference" :src="getImg(text, record)" style="max-height: 50px; max-width: 60px">
                  <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
                </el-image>
                <el-image :src="getImg(text, record)" style="max-height: 600px; max-width: 400px"></el-image>
              </el-popover>
            </template>

            <template v-if="showOperation == true" slot="action" slot-scope="text, record">
              <div class="editable-row-operations">
                <a class="edit" @click="handleEdit(record)">修改</a>
                <template v-if="loginUser.username == 'admin'">
                  <a-divider type="vertical" />
                  <a class="delete" @click="handleDel(record)">作废</a>
                </template>
              </div>
            </template>
          </s-table>
        </a-col>
      </a-row>
    </a-card>

    <item-form ref="form" :userName="userName" :categoryList="categoryList" :locationList="locationList" @ok="$refs.table.refresh(true)"></item-form>

    <location-form ref="locationForm" :locationList="locationList" @ok="$refs.table.refresh(true)"></location-form>
  </div>
</template>

<script>
import STree from '@/components/Tree/Tree'
import mixinList from '@/pages/common/mixins/list'
import { STable } from '@/components'
import { getAction, sysCommonApi } from '@/api/common'
import { resMaterialApi, resLocationApi, resdevCategoryApi, resPurUseApi, Get, Del, Post } from '@/api/quote'

import { dictApi } from '@/api/system'
import MaterialSingleForm from './modules/materialSingleForm'
import { PageView } from '@/layouts'
import ItemForm from '@/pages/cloud-quote/res/res-stock/modules/ItemForm'
import locationForm from '@/pages/cloud-quote/res/res-stock/modules/littleTable/locationForm'
import { materialSingleDefaultColumn } from '@/pages/cloud-quote/res/res-materialsingle/modules/columns'
import { resMaterialSingleCateTypeDataMap } from '@/api/resMaterialSingle/resMaterialSingleConstant'

const treeData = [
  {
    title: 'parent 1',
    key: 1,

    children: [
      { title: 'leaf', key: 2 },
      { title: 'leaf', key: 3 }
    ]
  }
]

export default {
  name: 'MaterialSingleList',
  mixins: [mixinList],
  components: {
    STable,
    STree,
    Post,
    resMaterialApi,
    MaterialSingleForm,
    PageView,
    ItemForm,
    locationForm
  },
  props: {
    userName: null,
    columns: { type: Array, required: false, default: () => materialSingleDefaultColumn },
    manufList: { type: Array, default: () => [] },
    supplierList: { type: Array, default: () => [] },
    cateId: { type: String, required: false, default: null },
    caeteName: { type: String, required: false, default: null },
    cateType: { type: Number, required: false, default: null },
    cateTypes: { type: Array, required: false, default: null },
    // locationId: { type: String, required: false, default: null },
    rowSelectionType: { type: String, required: false, default: null },
    pagination: { type: Boolean, required: false, default: true },
    showOperation: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      resMaterialSingleCateTypeDataMap,

      notFound: '/notFound.jpg',
      dataSource: [],
      value: 'stock',
      treeData,
      expandedKeys: [],
      autoExpandParent: true,
      ds: [],
      cateDs: {
        key: 1,
        title: '资源类型',
        children: null
      },
      locationDs: {
        key: 2,
        title: '放置地点',
        children: null
      },
      ss: {
        key: null,
        title: null,
        children: null
      },
      statusDs: {
        key: 3,
        title: '物资状态',
        children: []
      },
      datasource: [],
      checkedKeys: [],
      selectedKeys: [],
      locationSource: [],
      locationCheckedKeys: [],
      locationSelectedKeys: [],
      openKeys: [1],
      locationShow: [],
      locationList: [],
      categoryList: [],
      cateShow: [],
      viewUrl: sysCommonApi.view,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        compositionName: null,
        cateId: this.cateId,
        type: null
        // locationId: this.locationId
      },
      contentTable: {
        columns: this.columns
      },
      expandKeys: undefined
    }
  },
  computed: {
    materialUnitCode() {
      return { ...this.$store.state.code.cache.material_unit_code }
    },

    customQueryParam() {
      const { cateType, cateTypes } = this
      return Object.assign({ cateType, cateTypes }, this.queryParam)
    },

    loginUser() {
      return { ...this.$store.getters.userInfo }
    }
  },

  watch: {
    'rowSelection.selectedRows'(newValue) {
      this.$emit('selectedRows', newValue)
    }
  },

  created() {
    Get(resLocationApi.selectList, { pageSize: 500 }).then((result) => {
      this.locationShow = result.data
    })
    Get(resdevCategoryApi.selectList, { pageSize: 500 }).then((result) => {
      this.cateShow = result.data
    })
    this.initData()
  },

  methods: {
    findNode(tree, value) {
      const temp = []
      const fn = function (arr, key) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.key === key) {
            temp.push(item.title)
            fn(tree, item.parentId)
            break
          } else {
            if (item.children && item.children.length > 0) fn(item.children, key)
          }
        }
      }
      fn(tree, value)
      console.log(temp, value)

      return temp.reverse().join(' / ')
    },
    reset() {
      this.selectedKeys.splice(0, this.selectedKeys.length)
      this.checkedKeys.splice(0, this.checkedKeys.length)
      this.locationCheckedKeys.splice(0, this.locationCheckedKeys.length)
      this.locationSelectedKeys.splice(0, this.locationSelectedKeys.length)
      const defaultParam = this.$options.data.bind(this)().queryParam
      Object.assign(this.queryParam, defaultParam)
      this.search()
    },

    // 初始化数据
    initData() {
      const categoryFun = this.loadCategoryList()
      const locationFun = this.loadLocationList()
      Promise.all([categoryFun, locationFun]).then((res) => {
        const cateTop = { key: '0', title: '物资类别', children: res[0]?.data || [] }
        this.datasource.push(cateTop)
        this.categoryList = res[0]?.data
        const locationTop = { key: '0', title: '地点类别', children: res[1]?.data || [] }
        this.locationList = res[1]?.data
        this.locationSource.push(locationTop)
      })
    },

    loadCategoryList() {
      // return Get(resdevCategoryApi.list, { pageSize: 500 })
      if (this.showOperation === true) {
        return Get(resMaterialApi.getMaterialCategory, { hasMaterial: false })
      } else {
        return Get(resdevCategoryApi.getMaterialCategory, { cateId: '9,11,27' })
      }
    },

    loadLocationList() {
      return Get(resLocationApi.list, { pageSize: 500 })
    },

    handleExpand(expanded, record) {
      if (expanded) {
        this.innerData = []
        Get(resMaterialApi.getStockByMaterialId, { pageSize: 500, materialId: record.id }).then((result) => {
          record.innerData = result.data
        })
      }
    },

    // 查询列表数据
    loadData(parameter) {
      return getAction(resMaterialApi.listDetail, Object.assign(parameter || {}, this.customQueryParam)).then((res) => res)
    },

    handleLocationClick(record) {
      this.$refs.locationForm.add(record)
    },

    handleButtonClick(e) {
      this.$router.push({ name: 'res-material-in', params: {} })
    },

    handleMenuClick(e) {
      if (e.key === '1') {
        this.$refs.form.add()
      } else {
        this.$refs.purTable.add()
      }
    },

    handleButtonXHClick(e) {
      this.batchXH()
    },

    handleMenuXHClick(e) {
      this.batchXH()
    },

    handleButtonGHClick(e) {
      this.$router.push({ name: 'res-material-out', params: {} })
    },

    handleButtonUseClick(e) {
      this.$router.push({ name: 'res-pur-use', params: {} })
    },

    handleButtonReturnClick(e) {
      this.$router.push({ name: 'res-pur-return', params: {} })
    },

    handleMenuGHClick(e) {
      if (e.key === '1') {
        this.$refs.purUseTable.add()
      } else {
        this.$refs.purUseTable.add()
      }
    },

    batchXH() {
      Post(resPurUseApi.resMaterialXh, { ids: this.rowSelection.selectedRowKeys }).then((result) => {
        if (result.code === 0) {
          this.$message.success(`操作成功`)
          this.$refs.table.refresh(true)
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleTitleClick(e) {
      console.log(e)
    },

    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    onCateCheck(checkedKeys, info) {
      this.checkedKeys.splice(0, this.checkedKeys.length, checkedKeys[0])
    },

    onCateSelect(selectedKeys, info) {
      if (selectedKeys && selectedKeys.length > 0) {
        this.queryParam.cateId = selectedKeys[0].indexOf('-') === -1 ? selectedKeys[0] : selectedKeys[0].split('-')[1]
        this.queryParam.type = selectedKeys.length > 0 ? info.node.dataRef.type : null
        this.selectedKeys.splice(0, this.selectedKeys.length, selectedKeys[0])
      } else {
        this.queryParam.cateId = null
        this.queryParam.type = null
        this.selectedKeys = []
      }
      this.search()
    },

    onLocalCheck(checkedKeys, info) {
      this.locationCheckedKeys.splice(0, this.locationCheckedKeys.length, checkedKeys[0])
    },

    // onLocalSelect (selectedKeys, info) {
    //   this.queryParam.locationId = selectedKeys[0]
    //   this.locationSelectedKeys.splice(0, this.locationSelectedKeys.length, selectedKeys[0])
    //   this.search()
    // },

    handleHref(record) {
      this.$router.push({ name: 'res-dev-cbt', params: {} })
    },

    getImg(text, record) {
      if (record.materialImg === null || record.materialImg === '') {
        return this.notFound
      } else {
        return this.viewUrl + record.materialImg
      }
    },
    handleChange(value, key, column, record) {
      record[column.dataIndex] = value
    },

    handleAdd() {
      this.$refs.form.add(null)
    },

    handleEdit(_record) {
      // console.log('修改',_record.technicalInfos)
      this.$refs.form.edit(_record)
    },

    handleBack(_record) {
      this.$confirm({
        title: '确认撤回吗?',
        content: `撤回会将编号为【${_record.purNbr}】的采购信息改变为未提交。`,
        onOk() {},
        onCancel() {}
      })
    },

    handleDel(_record) {
      const $self = this
      this.$confirm({
        title: '确认作废?',
        content: `请确认是否作废名称为【${_record.materialName}】的物资信息,作废后将不可恢复`,
        onOk() {
          Del(`${resMaterialApi.del}/${_record.id}`, {}).then((result) => {
            if (result.code === 0) {
              $self.$message.success(`作废物资${_record.materialName}成功`)
              $self.$refs.table.refresh(true)
            } else {
              $self.$message.error(`作废物资${_record.materialName}失败`)
            }
          })
        },
        onCancel() {}
      })
    },

    handleExpandTree(expandKeys) {
      this.expandKeys = expandKeys
    }
  }
}
</script>
