<template>
  <a-modal v-bind="modalProps">
    <a-form :form="form">
      <a-card v-bind="baseFormCard" title="基本信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="物资类型" v-bind="formItemLayout">
              <!--<a-tree-select
                show-search
                :filterTreeNode="treeFilterOption"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="categoryList"
                placeholder="选择物资类型"
                v-decorator="[ 'cateId', {rules: [{ required: true, message: '请选择物资类目'}]} ]"/>-->
              <a-cascader
                :show-search="true"
                :options="categoryList"
                :fieldNames="fieldNames"
                expand-trigger="hover"
                placeholder="请选择物资类目"
                notFoundContent="暂无数据"
                @change="handleChange"
                v-decorator="['cateId', { rules: [{ required: true, message: '请选择物资类目' }] }]"
              />
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="物资名称" v-bind="formItemLayout">
              <a-input v-decorator="['materialName', { rules: [{ required: true, min: 1, message: '请输入物资名称！' }] }]" />
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="规格型号" v-bind="formItemLayout">
              <a-input v-decorator="['speType', { rules: [{ required: true, message: '请输入规格型号' }] }]" />
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="品牌" v-bind="formItemLayout">
              <a-input v-decorator="['brand', { rules: [{ required: false }] }]" />
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="厂商" v-bind="formItemLayout">
              <div style="display: flex">
                <a-select v-decorator="['subcontractorId', { rules: [{ required: false, message: '请选择形状类型！' }] }]" showSearch :filterOption="filterOption">
                  <a-select-option :key="i.id" v-for="i in SubcontractorList">{{ i.sctrName }}</a-select-option>
                </a-select>
                <a-button type="primary" @click="addFirm">新增</a-button>
              </div>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="单位" v-bind="formItemLayout">
              <a-select v-decorator="['unit', { rules: [{ required: false }] }]">
                <a-select-option v-for="(item, key) of materialUnitCode" :key="key">{{ item }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="形状类型" v-bind="formItemLayout">
              <a-select v-decorator="['shapeType', { rules: [{ required: true, message: '请选择形状类型！' }] }]">
                <a-select-option :key="1">固体</a-select-option>
                <a-select-option :key="2">液体</a-select-option>
                <a-select-option :key="3">气体</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="浓度/特征" v-bind="formItemLayout">
              <a-input v-decorator="['purity', { rules: [{ required: false, message: '请输入浓度' }] }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card v-bind="baseFormCard" title="技术信息">
        <a-row v-bind="rowProps">
          <a-col v-for="item in technicalInfos" :key="item.id"  v-bind="colProps">
            <a-form-item :label="item.technicalName" v-bind="formItemLayout">
            <a-input v-model="item.technicalValue" />
          </a-form-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card v-bind="baseFormCard" title="储存信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="消耗品" v-bind="formItemLayout">
              <a-radio-group v-decorator="['cmsOrNot', { rules: [{ required: true, message: '请选择是否是消耗品！' }] }]">
                <a-radio value="1"> 是</a-radio>
                <a-radio value="0"> 否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <!-- 隐藏存放地点字段，不做非空判断 -->
          <!-- <a-col v-bind="colProps">
            <a-form-item label="存放地点" v-bind="formItemLayout">
              <a-tree-select :treeData="locationList" placeholder="选择地点" v-decorator="['locationId', { rules: [{ required: true, message: '请选择存放地点' }] }]" />
            </a-form-item>
          </a-col> -->

          <a-col v-bind="colProps">
            <a-form-item label="保存条件" v-bind="formItemLayout">
              <a-input v-decorator="['storageCondition', { rules: [{ required: false }] }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card v-bind="baseFormCard" title="库存预警">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="前置天数" v-bind="formItemLayout">
              <a-input-number :min="0" v-decorator="['preDays', { rules: [{ required: false }] }]" @change="onChangepreDays" />
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="安全库存" v-bind="formItemLayout">
              <a-input-number :min="0" v-decorator="['safetyStock', { rules: [{ required: false }] }]" @change="onChangeSafetyStock" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card v-bind="baseFormCard" title="核查和维护保养">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="核查模型选择" v-bind="formItemLayout" :labelCol="labelCol2" :wrapperCol="wrapperCol2">
              <a-select v-decorator="['verifyModelId', { rules: [{ required: false, message: '请选择核查模型！' }] }]">
                <a-select-option :key="1">测试模型1</a-select-option>
                <a-select-option :key="2">测试模型2</a-select-option>
                <a-select-option :key="3">测试模型3</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="保养维护内容" v-bind="formItemLayout" :labelCol="labelCol2" :wrapperCol="wrapperCol2">
              <a-select v-decorator="['maintenanceId', { rules: [{ required: false, message: '请选择保养维护内容' }] }]" mode="multiple" showSearch :filterOption="filterOption">
                <a-select-option :key="i.id" v-for="i in dictItemList" :value="i.id">{{ i.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card v-bind="baseFormCard" title="档案信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :xxl="24" :xl="24">
            <a-table ref="fileTable" :columns="fileColumns" :dataSource="archivesTypeList" :bordered="true" :pagination="false">
              <template slot="uploadFile" slot-scope="text, record">
                <template v-for="(item, index) in archivesFileList.filter((item) => item.fileType.toString() === record.itemValue)">
                  <a :key="`${record.itemValue}${index}a`" :href="item.fileUrl" target="view_window">
                    {{ item.fileName }}
                  </a>
                  <a-icon title="点击删除" style="color: #1890ff; margin: 0 5px" :key="`${record.itemValue}${index}i`" @click="handleFileRemove(item)" type="delete" />
                  <a-divider :key="`${record.itemValue}${index}d`" type="vertical" />
                </template>
                <a-upload
                  accept="image/*,.pdf,.doc,.docx,.xlsx"
                  :action="uploadUrl"
                  :headers="tokenHeader"
                  class="upload-list-inline"
                  :showUploadList="false"
                  :multiple="true"
                  @change="
                    (info) => {
                      handleFileChange(info, record.itemValue)
                    }
                  "
                >
                  <a title="点击上传">
                    <a-icon type="upload" />
                  </a>
                </a-upload>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-card>

      <a-card v-bind="baseFormCard" title="其他信息">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :xxl="24" :xl="24">
            <a-form-item label="物资图片">
              <j-upload listType="picture-card" class="avatar-uploader" v-model="fileList" file-type="image">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">添加物资图片</div>
                </div>
              </j-upload>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :xxl="24" :xl="24">
            <a-form-item label="操作手册">
              <j-upload listType="picture-card" class="avatar-uploader" v-model="operFileList" file-type="file">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">添加操作手册</div>
                </div>
              </j-upload>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps" :xxl="24" :xl="24">
            <a-form-item label="操作视频">
              <j-upload listType="picture-card" class="avatar-uploader" v-model="videoFileList" file-type="video">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">添加操作视频</div>
                </div>
              </j-upload>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps" :xxl="24" :xl="24">
            <a-form-item>
              <a-textarea :autosize="{ minRows: 3, maxRows: 6 }" placeholder="请在这里输入物资的描述信息" v-decorator="['materialDesc', { rules: [{ required: false }] }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form>
    <template #footer>
      <a-button :style="{ marginRight: '8px' }" @click="handleCancel">关闭</a-button>
      <a-button :style="{ marginRight: '8px' }" @click="handleSubmit" type="primary">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { Get, Post, resMaterialApi, resPurUseApi } from '@/api/quote'
import Moment from 'moment'
import { resMaterialSingleCateTypeOptions } from '@/api/resMaterialSingle/resMaterialSingleConstant'
import mixinForm from '@/pages/common/mixins/form'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import JUpload from '@/components/j/JUpload'
import { commDictApi, postDataAction, sysCommonApi } from '@/api/common'
import { subcontractorApi } from '@/api/project'
import { dictItemApi } from '@/api/system'

export default {
  components: { JUpload },

  props: {
    locationList: { type: Array, default: () => [] }
  },
  mixins: [mixinForm],

  data() {
    return {
      preDays:null,
      safetyStock:null,
      resMaterialSingleCateTypeOptions,
      categoryList: null,
      showDiv: false,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      batchNbrList: [],
      archivesFileList: [], // 档案附件
      fileList: [],
      // 操作手册
      operFileList: [],
      videoFileList: [],
      speTypeList: [],
      materialList: [],
      fileAppendix: [],
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 16
      },
      labelCol2: {
        span: 8
      },
      wrapperCol2: {
        span: 16
      },
      visible: false,
      height: '100%',
      width: '100%',
      id: null,
      form: this.$form.createForm(this),
      type: 0,
      defaultChecked: false,

      modalProps: Object.assign({}, fullModalData, {
        width: '60%',
        title: ''
      }),
      fieldNames: { label: 'title', value: 'key', children: 'children' },
      cateType: undefined,
      fileColumns: [
        { title: '序号', dataIndex: 'itemValue', width: '10%' },
        { title: '附件类型', dataIndex: 'itemText', width: '30%' },
        { title: '已上传附件', dataIndex: 'uploadFile', width: '60%', scopedSlots: { customRender: 'uploadFile' } }
      ],
      fileTypeDictCode: 'material_file_type',
      archivesTypeList: [],
      SubcontractorList: [],
      dictItemList: [],
      technicalInfos: []
    }
  },

  computed: {
    materialUnitCode() {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },

  created() {
    // this.listCateTree()
    Get(resMaterialApi.getMaterialCategory, { hasMaterial: false }).then((res) => {
      if (res.code === 0) {
        this.categoryList = res.data
      }
    })
    this.$store.dispatch('code/select', 'material_unit_code')
    this.initFileTypeList()
  },

  methods: {
    /* listCateTree () {
       var self = this
      Get(resdevCategoryApi.list).then(result => {
        self.treeExpandedKeys = []
        if (result.data.length > 0) {
          var cateTreeList = result.data
          self.buildTree(cateTreeList, true)
          self.categoryList = cateTreeList
        }
      })
    }, */

    /* buildTree (cateTreeList, expanded) {
      var self = this
      if (cateTreeList) {
        for (var idx in cateTreeList) {
          var cate = cateTreeList[idx]
          cate.title = cate.name
          var key = cate.cateAdd ? cate.id + '_Cate' : cate.id
          cate.key = key
          cate.value = key + ''
          cate.selectable = true
          if (!cate.id) {
            if (expanded) {
              self.treeExpandedKeys.push(key)
            }
            cate.selectable = false
          }
          if (cate.cateAdd === 1) {
            cate.selectable = false
          }
          if (cate.children) {
            self.buildTree(cate.children)
          }
        }
      }
    }, */
    onChangepreDays(value){
      console.log('onChangepreDays', value);
    let val=value?value:null
      this.preDays=val
    },
    onChangeSafetyStock(value){
      let val=value?value:null
      this.safetyStock=val
      console.log('onChangeSafetyStock', value);
    },
    getListSubcontractor() {
      subcontractorApi.list({ page: false, sctrStatus: 1 }).then((res) => {
        // console.log('厂商',res)
        if (res.code == 0) {
          this.SubcontractorList = res.data
        }
      })
    },
    getdictItemListByid() {
      let dictItemList = []
      Get(dictItemApi.list, { pageSize: 500, dictCode: 'sbwhnr' }).then((res) => {
        // console.log('设备维护内容', res)
        const d = res.data
        for (let i = 0; i < d.length; i++) {
          dictItemList.push({ id: d[i].itemValue, name: d[i].itemText })
        }
        this.dictItemList = dictItemList
      })
    },
    addFirm() {
      this.modalProps.visible = false
      this.$router.push({ name: 'subcontractor' })
    },
    initFileTypeList() {
      commDictApi.list({ dictCode: this.fileTypeDictCode }).then((res) => {
        res.data.forEach((item) => {
          item.fileList = []
        })
        this.archivesTypeList = res.data
      })
    },

    handleChange(value, option) {
      this.technicalInfos=[]
      const cateId=value[value.length - 1].slice(2)
      // console.log('类目',cateId)
         resMaterialApi.technicalInfoByCateId({cateId:cateId}).then((res)=>{
         console.log('请求技术信息',res)
         this.technicalInfos=res
      })
      if (option) {
        const item = option[option.length - 1]
        if (item.type !== '2') {
          this.handleCateIdChange([])
          this.$message.error('当前类型下没有相关物资类目，请到配置中心-人资配置中添加！')
        } else {
          this.cateType = item.cateType
          this.handleCateIdChange(value)
        }
      } else {
        this.cateType = undefined
        this.handleCateIdChange([])
      }
    },

    handleCateIdChange(value) {
      this.$nextTick(() => {
        const v = {
          cateId: value
        }
        this.form.setFieldsValue(v)
      })
    },

    findNode(tree, value) {
      const temp = []
      const fn = function (arr, key) {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.key === key) {
            temp.push(item.key)
            fn(tree, item.parentId)
            break
          } else {
            if (item.children && item.children.length > 0) fn(item.children, key)
          }
        }
      }
      fn(tree, value)
      return temp.reverse()
    },

    initMaterialImg(mId) {
      this.fileList = []
      Get(`${resMaterialApi.getMaterialImg}/${mId}`, {}).then((result) => {
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.fileList.push(result[i].materialImg)
          }
        }
      })
    },

    initMaterialOper(mId) {
      this.operFileList = []
      Get(`${resMaterialApi.getMaterialOper}/${mId}`, {}).then((result) => {
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.operFileList.push(result[i].operId)
          }
        }
      })
    },

    initMaterialVideo(mId) {
      this.videoFileList = []
      Get(`${resMaterialApi.getMaterialVideo}/${mId}`, {}).then((result) => {
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.videoFileList.push(result[i].videoId)
          }
        }
      })
    },

    initMaterialAppendix(mId) {
      Get(`${resMaterialApi.getMaterialAppendix}/${mId}`, {}).then((result) => {
        if (result.length > 0) {
          this.archivesFileList.splice(0, this.archivesFileList.length, ...result)
        }
      })
    },

    handleSpeTypeChange(value) {
      const v = {
        speType: value
      }
      this.form.setFieldsValue(v)
    },

    handleMaterialChange(value) {
      const v = {
        materialName: value
      }
      this.form.setFieldsValue(v)
      Post(`${resPurUseApi.getSpeTypeByMaterialName}/${value}`, {})
        .then((result) => {
          this.speTypeList = result
        })
        .catch((error) => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
    },
    add() {
      this.getListSubcontractor()
      this.getdictItemListByid()
      this.isShow = 'display:none'
      this.showDiv = false
      this.archivesFileList = []
      this.fileList = []
      this.operFileList = []
      this.videoFileList = []
      this.modalProps.title = '新增物资类目'
      this.modalProps.visible = true
      this.id = null
      this.url = resMaterialApi.addMaterial
      this.$nextTick(() => {
        this.form.$nextTick(() => {
          const v = {
            materialName: '',
            // 'cateType': 1,
            cateId: [],
            materialDesc: '',
            dateOfPur: null,
            // locationId: '',
            termOfValid: null,
            useStatus: '1',
            materialNum: 1,
            depositary: '',
            cmsOrNot: '0',
            brand: '',
            subcontractorId: null,
            // verifyModelId: null,
            maintenanceId: '',
            speType: '',
            purity: '',
            unit: '',
            storageCondition: '',
            preDays: null,
            safetyStock: null,
            shapeType: null
          }
          this.form.setFieldsValue(v)
        })
      })

      this.technicalInfos.splice(0, this.technicalInfos.length)
    },

    edit(record) {
      this.getListSubcontractor()
      this.getdictItemListByid()
      this.archivesFileList = []
      this.fileList = []
      this.operFileList = []
      this.videoFileList = []
      this.initMaterialImg(record.id)
      this.initMaterialOper(record.id)
      this.initMaterialVideo(record.id)
      this.initMaterialAppendix(record.id)
      this.modalProps.visible = true
      this.modalProps.title = '编辑物资型号信息'
      this.id = record.id
      this.url = resMaterialApi.editMaterial
      const cateId = this.findNode(this.categoryList, '2-' + record.cateId)
      this.cateType = record.cateType
      this.form.$nextTick(() => {
        const v = {
          materialName: record.materialName,
          cateId,
          batchNbr: record.batchNbr,
          batchName: record.batchName,
          materialDesc: record.materialDesc,
          dateOfPur: record.dateOfPur == null ? null : Moment(record.dateOfPur, 'YYYY-MM-DD'),
          // locationId: record.locationId,
          termOfValid: record.termOfValid == null ? null : Moment(record.termOfValid, 'YYYY-MM-DD'),
          useStatus: record.useStatus,
          depositary: record.depositary,
          cmsOrNot: record.cmsOrNot,
          brand: record.brand,
          maintenanceId: record.maintenanceId?.split(',') || '',
          preDays:record.preDays,
          safetyStock:record.safetyStock,
          // verifyModelId: record.verifyModelId?.split(',') || null,
          subcontractorId: record.subcontractorId,
          speType: record.speType,
          purity: record.purity,
          unit: record.unit,
          storageCondition: record.storageCondition,
          materialNum: record.materialNum,
          shapeType: record.shapeType ? record.shapeType : null
        }
        this.form.setFieldsValue(v)
      })
      this.technicalInfos.splice(0, this.technicalInfos.length, ...record.technicalInfos)
    },

    handleCancel() {
      this.modalProps.visible = false
    },

    handleSubmit(e) {
      this.submit(e, 0)
    },

    submit(e, t){
      const {
        form: { validateFields }
      } = this
      e.preventDefault()
      validateFields((errors, record) => {
        let cateId = record.cateId[record.cateId.length - 1]
        cateId = cateId.indexOf('-') === -1 ? cateId : cateId.split('-')[1]
        const fileList = this.fileList.filter((it) => it !== null)
        const operFileList = this.operFileList.filter((it) => it !== null)
        const videoFileList = this.videoFileList.filter((it) => it !== null)
        const v = {
          cateType: this.cateType,
          materialName: record.materialName,
          cateId,
          materialDesc: record.materialDesc,
          dateOfPur: record.dateOfPur == null ? null : record.dateOfPur.format('YYYY-MM-DD'),
          // locationId: record.locationId,
          termOfValid: record.termOfValid == null ? null : record.termOfValid.format('YYYY-MM-DD'),
          useStatus: '1',
          materialNum: record.materialNum,
          depositary: record.depositary,
          cmsOrNot: record.cmsOrNot,
          brand: record.brand,
          // verifyModelId: record.verifyModelId,
          subcontractorId: record.subcontractorId,
          maintenanceId: record.maintenanceId.join(','),
          speType: record.speType,
          purity: record.purity,
          unit: record.unit,
          storageCondition: record.storageCondition,
          batchNbr: record.batchNbr,
          batchName: record.batchName,
          mImg: fileList.length > 0 ? fileList.map((it) => it.url) : [],
          oper: operFileList.length > 0 ? operFileList.map((it) => it.url) : [],
          videos: videoFileList.length > 0 ? videoFileList.map((it) => it.url) : [],
          materialImg: fileList.length > 0 ? fileList[0].url : null,
          id: this.id,
          preDays: this.preDays,
          safetyStock: this.safetyStock,
          archivesFileList: this.archivesFileList,
          shapeType: record.shapeType
        }
        if (this.id) {
          this.technicalInfos.forEach((it) => {
            it.materialId = this.id
           
          })
        }
        v.technicalInfos = this.technicalInfos
        v.preDays= this.preDays,
        v.safetyStock= this.safetyStock
        if (!errors) {
          console.log('666666',v)
          postDataAction(this.url, v)
            .then((result) => {
              if (result.code === 0) {
                this.$message.success(`${this.modalProps.title}成功`)
                if (t === 1) {
                  const s = {
                    cateType: 1,
                    materialName: '',
                    cateId: '',
                    materialDesc: '',
                    dateOfPur: null,
                    locationId: '',
                    termOfValid: null,
                    useStatus: '1',
                    materialNum: 1,
                    depositary: '',
                    cmsOrNot: '0',
                    // 'purpose':null,
                    brand: '',
                    // verifyModelId: null,
                    subcontractorId: null,
                    maintenanceId: '',
                    speType: '',
                    purity: '',
                    unit: '',
                    storageCondition: '',
                    safetyStock: '',
                    preDays: ''
                  }
                  this.form.setFieldsValue(s)
                  this.fileList = []
                  this.operFileList = []
                  this.videoFileList = []
                  this.archivesFileList = []
                } else {
                  this.handleCancel()
                  this.$emit('ok', '')
                }
              } else if (result.code === -1) {
                this.$message.error('当前物资已存在,请修改信息后添加!')
              } else {
                this.$message.error(result.msg)
              }
            })
            .catch((error) => {
              this.$message.error(`服务器发生错误！错误消息为${error}`)
            })
        }
      })
    },
    handleFileRemove(file) {
      const index = this.archivesFileList.indexOf(file)
      const newFileList = this.archivesFileList.slice()
      newFileList.splice(index, 1)
      this.archivesFileList = newFileList
    },
    handleFileChange(info, type) {
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          const file = {
            fileType: type,
            fileUrl: info.file.response.data.fileUrl,
            fileName: info.file.name,
            fileExt: info.file.response.data.fileExt
          }
          this.archivesFileList.splice(this.archivesFileList.length, 0, file)
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
<style lang="less">
.ant-cascader-menu {
  height: 350px;
}
</style>
