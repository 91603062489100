<template>
  <div>
    <a-spin :spinning="confirmLoading">
      <div class="descriptions-view" style="margin-bottom: 10px">
        <table style="width: 100%">
          <tbody>
            <tr class="descriptions-row">
              <th class="descriptions-item-label" colspan="2">方法名称</th>
              <th class="descriptions-item-label" colspan="2">方法依据</th>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item-content" colspan="2">{{ typeRecord.methodName }}</td>
              <td class="descriptions-item-content" colspan="2">{{ typeRecord.standardName }} {{ typeRecord.standardCode }}</td>
            </tr>
            <tr class="descriptions-row">
              <th class="descriptions-item-label" colspan="2">设备类型</th>
              <template v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
                <th class="descriptions-item-label">参比溶液</th>
                <th class="descriptions-item-label">比色皿</th>
              </template>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item-content" colspan="2">{{ typeRecord.devName }}</td>
              <template v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
                <td class="descriptions-item-content">
                  <template v-if="typeRecord.referenceSolutionName">
                    <a-tag color="burlywood" style="line-height: 28px; height: 28px">{{ typeRecord.referenceSolutionName }}</a-tag>
                  </template>
                </td>
                <td class="descriptions-item-content">{{ typeRecord.thickness }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <a-form-item style="background: #fff; margin: 0; padding: 10px 0" :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" label="选择因子">
        <a-select v-model="selectedItemIds" :filterOption="filterOption" placeholder="请选择因子" mode="multiple" @change="selectedItemChange">
          <span slot="removeIcon">
            <a-icon type="close" v-if="selectedItemIds.length > 1"></a-icon>
          </span>
          <a-select-option v-for="v in methodItems" :key="v.id">{{ v.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-tabs v-model="itemId" @change="clickTab">
        <!--  style="background:#fff;" -->
        <a-tab-pane v-for="(v, i) in selectedMethodItems" :tab="v.name" :key="v.id"></a-tab-pane>
      </a-tabs>
      <div style="margin: 0 0 10px 20px; color: red">以下数据需给每个因子都配置</div>
      <div class="descriptions-view" style="margin-bottom: 10px">
        <table style="width: 100%">
          <tbody>
            <tr class="descriptions-row">
              <th class="descriptions-item-label">标液类型</th>
              <th class="descriptions-item-label">浓度</th>
              <template v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
                <th class="descriptions-item-label">波长</th>
                <th class="descriptions-item-label">回归方程</th>
              </template>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item-content">
                <a-tag color="#98a4f9" style="line-height: 28px; height: 28px">{{ currentSolutionType.solutionName }}</a-tag>
              </td>
              <td class="descriptions-item-content">{{ currentSolutionType.solutionPurity }}</td>
              <template v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
                <td class="descriptions-item-content">{{ currentSolutionType.wavelength }}</td>
                <td class="descriptions-item-content">{{ currentSolutionType.regressionEquation }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

      <a-card v-bind="baseFormCard" title="曲线点位信息" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
        <template>
          <!--          :data="hotData"-->
          <hot-table
            ref="hotTableComponent"
            :settings="hotSetting"
            :colHeaders="curveDrawTable.columns.map((item) => item.title)"
            :columns="
              curveDrawTable.columns.map((item) => {
                return { data: item.dataIndex, readOnly: item.readOnly }
              })
            "
            style="width: 100%"
          >
          </hot-table>
        </template>
        <!--        <a-table v-bind="curveDrawTable">-->
        <!--          <template v-for="(col, i) in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text, itemRecord">-->
        <!--            <a-input-->
        <!--              :key="col.dataIndex + itemRecord.drawIndex"-->
        <!--              v-model="itemRecord[col.dataIndex]"-->
        <!--              v-if="itemRecord.editable && col.dataIndex !== 'drawIndex' && col.dataIndex === 'remark'"/>-->
        <!--            <a-input-number-->
        <!--              :key="col.dataIndex + itemRecord.drawIndex"-->
        <!--              v-model="itemRecord[col.dataIndex]"-->
        <!--              v-else-if="itemRecord.editable && col.dataIndex !== 'drawIndex' && col.dataIndex !== 'remark'"-->
        <!--              style="width: 90%"-->
        <!--              :min="0"/>-->
        <!--            <template v-else>{{ text }}</template>-->
        <!--          </template>-->
        <!--          <template #action="data,record,index">-->
        <!--            <a v-if="record.editable" @click="record.editable = false">确定</a>-->
        <!--            <a v-else @click="record.editable = true">编辑</a>-->
        <!--            <a-divider type="vertical"/>-->
        <!--            <a @click="deleteCurveSite(index)">作废</a>-->
        <!--          </template>-->
        <!--        </a-table>-->
        <!--        <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="addCurveSite">新增记录</a-button>-->
      </a-card>
      <a-form v-bind="dataForm">
        <a-card v-bind="baseFormCard" title="配置信息">
          <template slot="extra" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
            <a href="#" @click="formulaCalc">公式计算</a>
            <a-divider type="vertical" />
            <a href="#" @click="curvePreviewModal.show">曲线预览</a>
          </template>

          <div class="content-row">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="配置日期" style="margin: 0">
                  <a-date-picker
                    v-model="currentSolution.makeDate"
                    :showTime="true"
                    :disabledDate="moreCurrentDate"
                    format="YYYY-MM-DD HH:mm:ss"
                    @change="setValidTime"
                  ></a-date-picker>
                  <!-- v-decorator="decorators.makeDate" -->
                </a-form-item>
              </a-col>

              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="设备" style="margin: 0">
                  <a-select v-model="currentSolution.devId" placeholder="请选择设备" mode="multiple" @change="handleDevChange">
                    <a-select-option v-for="v in devList" :key="v.id.toString()" :value="v.id.toString()"
                      >{{ v.fixedAssetsId }} {{ v.materialName }} {{ v.speType }}</a-select-option
                    >
                  </a-select>
                  <!-- v-decorator="decorators.devId" -->
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div class="content-row">
            <span class="content-row-title ant-form-item-required">标准使用液：</span>
            <template v-if="currentSolution.solutionId">
              <span style="margin-right: 10px"
                ><a-tag color="pink" style="line-height: 32px; height: 32px; border-style: unset">{{ currentSolution.solutionName }}</a-tag></span
              >
              <!-- v-if="currentSolution.solutionVolume" -->
              <span style="margin-right: 10px">可用量： {{ currentSolution.solutionVolume }} mL</span>
              <!-- v-if="currentSolution.solutionVolume" -->
              <span style="margin-right: 10px">使用量： <a-input-number v-model="currentSolution.solutionUseVolume" :min="0" style="width: 70px"></a-input-number> mL</span>
            </template>
            <span v-if="currentSolution.isEmptySolution" class="empty-data">暂无可用的标准使用液，请先配置</span>
            <a v-if="mixSolutionTypeStatus === '1'" @click="solutionSelectModal.show(currentSolutionType.solutionTypeId)">请选择标准使用液</a>
            <a v-else @click="currentConfigSolutionSelectModal.show(currentSolutionType.solutionTypeId)">请选择标准使用液</a>
            <div v-if="currentSolution.isLessThanSolutionVolume" class="empty-data" style="margin-top: 5px">
              标准使用液可用量少于使用量，请重新选择标准使用液或配置更多标准使用液
            </div>
          </div>
          <div class="content-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
            <span class="content-row-title ant-form-item-required" style="width: 10%">参比溶液：</span>
            <template v-if="currentSolution.solventName">
              <span style="margin-right: 10px"
                ><a-tag color="pink" style="line-height: 32px; height: 32px; border-style: unset">{{ currentSolution.solventName }}</a-tag></span
              >
              <span style="margin-right: 10px" v-if="currentSolution.solventVolume">可用量： {{ currentSolution.solventVolume }} {{ currentSolution.solventUnit }}</span>
              <span style="margin-right: 10px" v-if="currentSolution.solventVolume"
                >使用量： <a-input-number v-model="currentSolution.solventUseVolume" :min="0" style="width: 70px" @change="solventUseVolumeChange"></a-input-number
                >{{ currentSolution.solventUnit }}</span
              >
            </template>
            <span v-if="currentSolution.isEmptyReagent" class="empty-data">暂无可用的参比溶液，请先配置</span>
            <a @click="checkReagentSelectModal.show(typeRecord.referenceSolution, 'solvent')">请选择参比溶液</a>
            <!-- <div v-if="currentSolution.isLessThanReagentVolume" class="empty-data" style="margin-top: 5px">参比溶液可用量少于使用量，请重新选择参比溶液或配置更多参比溶液</div> -->
          </div>

          <div class="content-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.fileMade">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" style="margin: 0">
                  <span slot="label" class="ant-form-item-required">附件上传</span>
                  <a-upload
                    accept="image/*,.pdf,.doc,.docx,.xlsx"
                    :action="uploadUrl"
                    :fileList="fileList"
                    :headers="tokenHeader"
                    class="upload-list-inline"
                    :remove="handleRemove"
                    :multiple="true"
                    @change="handleChange"
                  >
                    <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div class="content-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="截距(a)" style="margin: 0">
                  <a-input-number v-model="currentSolution.intercept" style="width: 160px" disabled />
                  <!-- v-decorator="decorators.intercept" -->
                </a-form-item>
              </a-col>

              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="斜率(b)" style="margin: 0">
                  <a-input-number v-model="currentSolution.slope" style="width: 160px" disabled />
                  <!-- v-decorator="decorators.slope" -->
                </a-form-item>
              </a-col>
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="相关系数(r)" style="margin: 0"
                  ><!--R²-->
                  <a-input-number v-model="currentSolution.correlationCoefficient" style="width: 160px" @change="onCorrelChange" disabled />
                  <!-- v-decorator="decorators.correlationCoefficient" -->
                  <span style="margin-left: 5px; color: #a8a8a8">≥{{ currentSolutionType.minCorrel }}</span>
                  <span style="color: #06cb06" v-if="currentSolution.correlStatus === 'satisfy'"><a-icon type="check-circle" style="margin: 0 5px" />满足要求</span>
                  <span style="color: red" v-if="currentSolution.correlStatus === 'unsatisfy'"><a-icon type="exclamation-circle" style="margin: 0 5px" />不满足要求</span>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-card>

        <a-card v-bind="baseFormCard" title="单点校准">
          <template slot="extra" v-if="currentSolutionType.notVerifyData">
            <span style="color: red">单点校准数据不全，请先在曲线类型中添加保存</span>
          </template>
          <div class="content-row">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="允许范围" style="margin: 0">
                  <a-input-number
                    disabled
                    v-model="currentSolutionType.verifyAllowMinRange"
                    :formatter="(value) => `≥${value}%`"
                    :parser="(value) => value.replace('%', '').replace('≥', '')"
                  />
                  -
                  <a-input-number
                    disabled
                    v-model="currentSolutionType.verifyAllowMaxRange"
                    :formatter="(value) => `≤${value}%`"
                    :parser="(value) => value.replace('%', '').replace('≤', '')"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="16">
                <a-form-item label="有效时长" v-bind="formItemLayout1" style="margin: 0">
                  <a-input-number disabled v-model="currentSolutionType.verifyValidDate" :min="0" :step="1" style="width: 80px; margin-right: 10px"></a-input-number>
                  <!-- v-decorator="decorators.verifyValidDate" -->
                  <a-radio-group disabled v-model="currentSolutionType.verifyValidDateUnit">
                    <a-radio v-for="(value, key) of reagentCateValidDateUnitMap" :key="key" :value="key">{{ value }}</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-form>
    </a-spin>

    <!-- 参比溶液选择-->
    <a-modal v-bind="checkReagentSelectModal" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
      <check-reagent-list v-bind="checkReagentSelectModal.data" v-on="checkReagentSelectModal.tableAction"></check-reagent-list>
      <template #footer>
        <a-button @click="checkReagentSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="checkReagentSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 标准使用液液选择-->
    <a-modal v-bind="solutionSelectModal" v-if="mixSolutionTypeStatus === '1'">
      <standard-solution-list v-bind="solutionSelectModal.data" v-on="solutionSelectModal.tableAction"></standard-solution-list>
      <template #footer>
        <a-button @click="solutionSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="solutionSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <a-modal v-bind="currentConfigSolutionSelectModal" v-if="mixSolutionTypeStatus === '2'">
      <check-reagent-list v-bind="currentConfigSolutionSelectModal.data" v-on="currentConfigSolutionSelectModal.tableAction"></check-reagent-list>
      <template #footer>
        <a-button @click="currentConfigSolutionSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="currentConfigSolutionSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <a-modal v-bind="curvePreviewModal" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
      <composite-curve-preview v-if="curvePreviewModal.isComposite" v-bind="curvePreviewModal.compositeData"></composite-curve-preview>
      <curve-preview v-else v-bind="curvePreviewModal.data"></curve-preview>
      <template #footer>
        <a-button @click="curvePreviewModal.cancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import miniForm from '@/pages/common/mixins/form'
import { checkStandardCurveConfigApi, checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import { sysCommonApi } from '@/api/common'
import { resDevApi } from '@/api/quote'
import { actionColumns, baseCurveDrawColumns, curveDrawModels, remarkCurveDrawColumns } from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import CheckReagentList from '@/pages/cloud-quote/check-reagent/SelectList'
import { checkReagentSelectColumn } from '@/pages/cloud-quote/check-reagent/modules/table/columns'
import Moment from 'moment'
import StandardSolutionList from '@/pages/cloud-quote/check-standard-curve-type/modules/table/StandardSolutionList'
import { checkStandardSolutionCheckStatusEnum } from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import CurvePreview from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable/CurvePreview'
import CompositeCurvePreview from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable/CompositeCurvePreview'
import { checkStandardCurveModeEnum, checkStandardCurveModeOptions } from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { HotColumn, HotTable } from '@handsontable/vue'
import { registerAllModules } from 'handsontable/registry'
import { reagentCateValidDateUnitMap } from '@/api/checkReagent/checkReagentConstant'
import 'handsontable/dist/handsontable.full.css'
import { autoConfigureApi, sysReviewConfigUserControllerApi } from '@/api/autoConfigure/autoConfigureApi'
import { checkStandardSolutionApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'
import { checkReagentApi } from '@/api/checkReagent/checkReagentApi'
import { cloneDeep, uniqBy, uniq } from 'lodash'

registerAllModules()

export default {
  name: 'MakeFormMix',
  mixins: [miniForm],
  components: { StandardSolutionList, CheckReagentList, CurvePreview, CompositeCurvePreview, HotTable, HotColumn },
  props: {
    editType: {
      type: String,
      required: false,
      default: 'add'
    },

    id: {
      type: [String, Number, Object],
      required: false,
      default: null
    },

    typeRecord: {
      type: Object,
      required: false,
      default: null
    },

    curveRecord: {
      type: Object,
      required: false,
      default: null
    },

    methodItems: {
      type: Array,
      required: false,
      default: []
    }

    // columns: {
    //   type: Array,
    //   required: false,
    //   default: () => (defaultCurveDrawColumns)
    // }
  },
  data() {
    return {
      checkStandardSolutionCheckStatusEnum,
      baseCurveDrawColumns,
      curveDrawModels,
      remarkCurveDrawColumns,
      actionColumns,
      checkStandardCurveModeEnum,
      checkStandardCurveModeOptions,
      reagentCateValidDateUnitMap,
      formItemLayout1: {
        labelCol: {
          xxl: { span: 8 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        },
        wrapperCol: {
          xxl: { span: 16 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        }
      },
      colProps1: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      dataForm: {
        data: {
          id: null,
          curveTypeId: this.typeRecord.id,
          verifyAllowMinRange: this.typeRecord?.verifyAllowMinRange,
          verifyAllowMaxRange: this.typeRecord?.verifyAllowMaxRange,
          verifyValidDateUnit: this.typeRecord?.verifyValidDateUnit
        }
      },
      decorators: {
        intercept: ['intercept', { rules: [{ required: true, message: '请输入a的值！' }] }],
        slope: ['slope', { rules: [{ required: true, message: '请输入b的值！' }] }],
        correlationCoefficient: ['correlationCoefficient', { rules: [{ required: true, message: '请输入R²的值！' }] }],
        makeDate: ['makeDate', { rules: [{ required: true, message: '请选择配置时间！' }] }],
        devId: ['devId', { rules: [{ required: true, message: '请选择设备！' }] }],
        // solutionId: ['solutionId', { rules: [{ required: true, message: '请选择标液！' }] }]
        verifyAllowRange: ['verifyAllowRange', { initialValue: '≤10%', rules: [{ required: true, message: '请输入校准允许范围！' }] }],
        verifyValidDate: ['verifyValidDate', { initialValue: this.typeRecord?.verifyValidDate, rules: [{ required: true, message: '请输入有效期！' }] }]
      },

      devList: [], // 根据设备类型查询的设备列表
      devMap: {},
      selectedDevList: [], // 选中的设备列表
      confirmLoading: false,

      curveDrawTable: {
        rowKey: (item) => `${item.drawIndex}`,
        bordered: true,
        size: 'small',
        dataSource: [],
        // columns: this.columns,
        columns: [],
        pagination: false
      },
      checkReagentSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '请选择使用的参比溶液',
        bodyStyle: { background: '#F0F2F5' },
        selectType: 'solution',

        show: (materialId, selectType) => {
          console.log(this.currentSolutionType)

          console.log(materialId, selectType)

          this.checkReagentSelectModal.selectType = selectType
          this.checkReagentSelectModal.data.cateId = materialId
          this.checkReagentSelectModal.data.validCheckTime = this.timeFormat(this.currentSolution.makeDate)
          this.checkReagentSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.checkReagentSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            let reagent = selectData[0]
            // let isLessThanReagentVolume = false
            let solventUseVolume = this.typeRecord && (this.typeRecord.constantVolume || this.typeRecord.constantVolume === 0) ? this.typeRecord.constantVolume : null
            this.mixSolutionList.forEach((item) => {
              item.isEmptyReagent = false
              item.solventId = reagent.id
              item.solventName = reagent.name
              item.solventUnit = reagent.unit
              item.solventVolume = reagent.volume
              item.solventUseVolume = solventUseVolume
            })
            // // let msl = this.mixSolutionList.filter((item) => Number(item.itemId) !== Number(this.currentSolution.itemId) && Number(item.solventId) === Number(reagent.id))
            // // if (msl.length) {
            // //   let totalSolventUseVolume = 0
            // //   msl.forEach((item3) => {
            // //     if (item3.solventUseVolume) {
            // //       totalSolventUseVolume += item3.solventUseVolume
            // //     }
            // //   })
            // //   if (solventUseVolume || solventUseVolume === 0) {
            // //     if (reagent.volume - totalSolventUseVolume < solventUseVolume) {
            // //       isLessThanReagentVolume = true
            // //     }
            // //   } else {
            // //     if (reagent.volume - totalSolventUseVolume === 0) {
            // //       isLessThanReagentVolume = true
            // //     }
            // //   }
            // // } else {
            // //   if (solventUseVolume || solventUseVolume === 0) {
            // //     if (reagent.volume < solventUseVolume) {
            // //       isLessThanReagentVolume = true
            // //     }
            // //   } else {
            // //     if (reagent.volume === 0) {
            // //       isLessThanReagentVolume = true
            // //     }
            // //   }
            // // }
            // this.currentSolution.isEmptyReagent = false
            // this.currentSolution.solventId = reagent.id
            // this.currentSolution.solventName = reagent.name
            // this.currentSolution.solventUnit = reagent.unit
            // this.currentSolution.solventVolume = reagent.volume
            // this.currentSolution.solventUseVolume = solventUseVolume
            // // this.currentSolution.isLessThanReagentVolume = isLessThanReagentVolume
            // console.log(this.currentSolution)

            this.checkReagentSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentSelectTable',
          cateId: null,
          selectable: true,
          pagination: true,
          validCheckTime: null,
          columns: checkReagentSelectColumn
        },

        tableAction: {}
      },
      currentConfigSolutionSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '请选择使用的参比溶液',
        bodyStyle: { background: '#F0F2F5' },
        selectType: 'solution',

        show: (materialId) => {
          this.currentConfigSolutionSelectModal.data.cateId = materialId
          this.currentConfigSolutionSelectModal.data.validCheckTime = this.timeFormat(this.currentSolution.makeDate)
          this.currentConfigSolutionSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.currentConfigSolutionSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.currentConfigSolutionSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            let solution = selectData[0]
            let isLessThanSolutionVolume = false
            const useData = this.curveDrawTable.dataSource
            let useVolume = 0.0
            useData.forEach((item) => {
              useVolume += parseFloat(item.standardLiquid)
            })
            useVolume = useVolume.toFixed(3)

            let msl = this.mixSolutionList
              .filter((item3) => this.selectedItemIds.includes(item3.itemId))
              .filter((item) => Number(item.itemId) !== Number(this.currentSolution.itemId) && Number(item.solutionId) === Number(solution.id))
            if (msl.length) {
              let totalSolutionUseVolume = 0
              msl.forEach((item3) => {
                if (item3.solutionUseVolume) {
                  totalSolutionUseVolume += item3.solutionUseVolume
                }
              })
              if (solution.volume - totalSolutionUseVolume < useVolume) {
                isLessThanSolutionVolume = true
              }
            } else {
              if (solution.volume < useVolume) {
                isLessThanSolutionVolume = true
              }
            }

            this.currentSolution.isEmptySolution = false
            this.currentSolution.solutionId = solution.id
            this.currentSolution.solutionName = solution.reagentNo + ' ' + solution.name
            this.currentSolution.solutionVolume = solution.volume
            this.currentSolution.solutionUseVolume = useVolume
            this.currentSolution.isLessThanSolutionVolume = isLessThanSolutionVolume

            this.currentConfigSolutionSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentSelectTable',
          cateId: null,
          selectable: true,
          pagination: true,
          validCheckTime: null,
          columns: checkReagentSelectColumn
        },

        tableAction: {}
      },
      solutionSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '请选择使用的标准使用液',
        bodyStyle: { background: '#F0F2F5' },

        show: (solutionTypeId) => {
          this.solutionSelectModal.data.solutionTypeId = parseInt(solutionTypeId)
          this.solutionSelectModal.data.validTime = this.timeFormat(this.currentSolution.makeDate)
          this.solutionSelectModal.data.makeDate = this.timeFormat(this.currentSolution.makeDate)
          this.solutionSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.solutionSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.solutionSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            let solution = selectData[0]
            let isLessThanSolutionVolume = false
            const useData = this.curveDrawTable.dataSource
            let useVolume = 0.0
            useData.forEach((item) => {
              useVolume += parseFloat(item.standardLiquid)
            })
            useVolume = useVolume.toFixed(3)

            let msl = this.mixSolutionList
              .filter((item3) => this.selectedItemIds.includes(item3.itemId))
              .filter((item) => Number(item.itemId) !== Number(this.currentSolution.itemId) && Number(item.solutionId) === Number(solution.id))
            if (msl.length) {
              let totalSolutionUseVolume = 0
              msl.forEach((item3) => {
                if (item3.solutionUseVolume) {
                  totalSolutionUseVolume += item3.solutionUseVolume
                }
              })
              if (solution.volume - totalSolutionUseVolume < useVolume) {
                isLessThanSolutionVolume = true
              }
            } else {
              if (solution.volume < useVolume) {
                isLessThanSolutionVolume = true
              }
            }

            this.currentSolution.isEmptySolution = false
            this.currentSolution.solutionId = solution.id
            this.currentSolution.solutionName = solution.solutionNo + ' ' + solution.name
            this.currentSolution.solutionVolume = solution.volume
            this.currentSolution.solutionUseVolume = useVolume
            this.currentSolution.isLessThanSolutionVolume = isLessThanSolutionVolume
            // if (useVolume > solution.volume) {
            //   this.currentSolution.isLessThanSolutionVolume = true
            // }
            this.solutionSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'solutionSelectTable',
          solutionTypeId: null,
          checkStatus: checkStandardSolutionCheckStatusEnum.pass,
          validTime: null,
          makeDate: null,
          selectType: 'radio'
        },

        tableAction: {}
      },
      curvePreviewModal: {
        visible: false,
        width: 960,
        height: 600,
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '曲线预览',

        show: async () => {
          const data = this.curveDrawTable.dataSource

          this.curvePreviewModal.isComposite = false

          const values = ['concentration', 'result']
          const curveSiteData = []

          // 模板2
          const spColumns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('F含量') >= 0)
          // 模板3
          const siteModel3Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('result') >= 0)
          // 模板4或8或10或11
          const siteModel4h8Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('purity') >= 0)
          const calcData = []
          if (spColumns.length > 0) {
            data.forEach((item) => {
              const concentration = item.absorbanceValue ? Math.round(Math.log10(item.absorbanceValue) * 1000) / 1000 : ''
              calcData.push({ concentration, result: item.result })
            })
          } else if (siteModel3Columns.length > 0) {
            // 模板3公式计算
            data.forEach((item) => {
              calcData.push({ concentration: item.result, result: item.absorbanceValue })
            })
          } else if (siteModel4h8Columns.length > 0) {
            // 模板4或8或10或11公式计算
            data.forEach((item) => {
              calcData.push({ concentration: item.purity, result: item.result })
            })
          } else {
            data.forEach((item) => {
              calcData.push({ concentration: item.concentration, result: item.result })
            })
          }

          try {
            calcData.forEach((item) => {
              // if (item.concentration === '' || item.result === '') {
              //   this.$message.warn('请先完善曲线点位信息')
              //   throw new Error('breakForEach')
              // }
              const a = {}
              for (const i in item) {
                // if (values.includes(i)) {
                if (isNaN(parseFloat(item[i]))) {
                  this.$message.warn('请先完善曲线点位信息')
                  throw new Error('breakForEach')
                } else {
                  a[i] = parseFloat(item[i])
                }
                // }
              }
              curveSiteData.push(a)
            })
            const columns = this.curveDrawTable.columns
            const axisData = {}
            columns.forEach((item) => {
              if (values.includes(item.dataIndex)) axisData[item.dataIndex] = item.title
            })
            if (spColumns.length > 0) {
              // 模板2
              axisData.concentration = spColumns[0].title
            } else if (siteModel3Columns.length > 0) {
              // 模板3
              axisData.concentration = siteModel3Columns[0].title
              let resultColumn = columns.find((item) => item.dataIndex === 'absorbanceValue')
              if (resultColumn) {
                axisData.result = resultColumn.title
              }
            } else if (siteModel4h8Columns.length > 0) {
              // 模板4或8或10或11
              axisData.concentration = siteModel4h8Columns[0].title
            }
            this.curvePreviewModal.data.curveSiteData = curveSiteData
            this.curvePreviewModal.data.axisData = axisData
            if (this.curvePreviewModal.data.equationData === null) {
              this.autoFormulaCalc()
            }
            this.curvePreviewModal.visible = true
          } catch (e) {
            if (e.message !== 'breakForEach') throw e
          }
        },

        cancel: () => {
          this.curvePreviewModal.visible = false
        },

        data: {
          ref: 'curvePreview',
          curveSiteData: null,
          axisData: null,
          equationData: null
        },
        // 复合点位曲线数据
        compositeData: {
          ref: 'compositeCurvePreview',
          list: []
        },
        isComposite: false // 是否复合
      },
      correlStatus: '',
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      fileList: [],
      fileAppendix: [],
      // hotData: [
      //   { drawIndex: 1, standardLiquid: 0.1, concentration: 0.2 },
      //   { drawIndex: 2, standardLiquid: '0', concentration: '0' }
      // ],
      hotSetting: {
        height: 'auto',
        rowHeights: 40,
        manualRowResize: true,
        stretchH: 'all',
        className: 'htMiddle htCenter',
        currentHeaderClassName: 'ht__highlight',
        activeHeaderClassName: 'ht__active_highlight',
        licenseKey: 'non-commercial-and-evaluation',
        contextMenu: {
          // 自定义右键菜单，可汉化，默认布尔值
          items: {
            row_above: {
              name: '上方插入一行'
            },
            hsep1: '---------', // 提供分隔线
            row_below: {
              name: '下方插入一行'
            },
            hsep2: '---------', // 提供分隔线
            remove_row: {
              name: '删除行'
            }
          }
        },
        afterInit: () => {
          console.log('init')
        },
        afterChange: (changes) => {
          const self = this
          if (changes !== null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              if (self.typeRecord.siteModelId === 1 || self.typeRecord.siteModelId === 8) {
                if (prop === 'absorbanceValue') {
                  const d = self.curveDrawTable.dataSource[row]
                  if (parseFloat(newValue).toString() !== 'NaN' && parseFloat(d.absorbanceValue2).toString() !== 'NaN') {
                    // *100000为了避免计算误差，小数点后位数三位以上加减法计算结果精度存在误差（如计算0.105 - 0.005的结果是0.9999999999999999）
                    // 小数点后位数三位为139的数乘于100000有精度误差，改为1000
                    let result = ((parseFloat(newValue) * 1000 - parseFloat(d.absorbanceValue2) * 1000) / 1000).toString()
                    if (newValue.indexOf('.') >= 0) {
                      const vLength = newValue.split('.')[1].length
                      const rLength = result.indexOf('.') >= 0 ? result.split('.')[1].length : 0
                      for (let i = 0; i < vLength - rLength; i++) {
                        if (rLength === 0 && i === 0) {
                          result += '.0'
                        } else {
                          result += '0'
                        }
                      }
                    }
                    d.result = result
                    self.curveDrawTable.dataSource[row] = d
                  }
                } else if (prop === 'absorbanceValue2') {
                  const d = self.curveDrawTable.dataSource[row]
                  if (parseFloat(newValue).toString() !== 'NaN' && parseFloat(d.absorbanceValue).toString() !== 'NaN') {
                    let result = ((parseFloat(d.absorbanceValue) * 1000 - parseFloat(newValue) * 1000) / 1000).toString()
                    if (d.absorbanceValue.indexOf('.') >= 0) {
                      const vLength = d.absorbanceValue.split('.')[1].length
                      const rLength = result.indexOf('.') >= 0 ? result.split('.')[1].length : 0
                      for (let i = 0; i < vLength - rLength; i++) {
                        if (rLength === 0 && i === 0) {
                          result += '.0'
                        } else {
                          result += '0'
                        }
                      }
                    }
                    d.result = result
                    self.curveDrawTable.dataSource[row] = d
                  }
                }
              }

              if (['concentration', 'result'].includes(prop)) {
                self.autoFormulaCalc()
              }
              if (self.typeRecord.siteModelId === 3 && prop === 'absorbanceValue') {
                self.autoFormulaCalc()
              }
            })
            self.$refs.hotTableComponent.hotInstance.loadData(self.curveDrawTable.dataSource)
          }
        }
      },

      // 新增的曲线Id
      newId: null,
      // 自动曲线复审核审核人员、复核人员
      assignUser: {},
      // 判断曲线分类中是否有单点校准数据
      notVerifyData: false,
      isEmptySolution: false,
      isLessThanSolutionVolume: false,
      isEmptyReagent: false,
      isLessThanReagentVolume: false,
      makeDate: null,
      curveCalculateResultList: [],

      selectedItemIds: [], // 当前曲线选择的因子id，这些选择的因子就是需要配置曲线数据的因子
      selectedMethodItems: [], // 当前曲线选择的因子
      notMethodItems: [], // 用以判断当前曲线类型关联的方法的关联因子是否没有对应的混合标液类型
      mixSolutionTypeList: [], // 混合标液类型
      mixSolutionList: [], // 混合标液
      methodItemsSiteColumns: [], // 不同关联因子的点位模板（模板的title的单位不一样）
      allDrawList: [], // 点位数据
      itemId: null, // 默认的第一个因子
      curIdx: 0, // 默认的第一个下标，和itemId配合使用
      currentSolutionType: {}, // 根据选择的因子过滤出的标液类型
      currentSolution: {}, // 根据选择的因子过滤出的标液
      currentSiteColumns: {}, // 根据选择的因子过滤出的点位列表数据
      currentDrawList: [], // 根据选择的因子过滤出的点位数据
      publicTimeData: {}, // 公用时间数据
      mixSolutionStatus: '1', // 判断混合标液类型数据是从已有混合标液还是现配混合标液中取的，这里是暂时性数据，为了表示是从曲线中获得的参数
      mixSolutionTypeStatus: '1' // 判断混合标液类型数据是从已有混合标液还是现配混合标液中取的
    }
  },
  async created() {
    this.confirmLoading = true
    let makeDate = Moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
    this.makeDate = makeDate
    if (this.curveRecord !== null && Object.keys(this.curveRecord).length > 0) {
      this.mixSolutionStatus = this.curveRecord.mixSolutionTypeStatus || '1'
      this.publicTimeData.makeDate = Moment(this.curveRecord.makeDate == null ? new Date() : this.curveRecord.makeDate, 'YYYY-MM-DD HH:mm:ss')
      this.publicTimeData.validTime =
        this.curveRecord.validTime === null ? this.getValidTime(Moment(new Date(), 'YYYY-MM-DD HH:mm:ss')) : Moment(this.curveRecord.validTime, 'YYYY-MM-DD HH:mm:ss')
      this.mixSolutionList = this.curveRecord.mixSolutionList || []
      this.selectedItemIds = this.curveRecord.selectedItemIds?.split(',').map((item) => Number(item)) || []
    } else {
      this.publicTimeData.makeDate = Moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
      this.publicTimeData.validTime = this.getValidTime(Moment(new Date(), 'YYYY-MM-DD HH:mm:ss'))
    }
    if (this.typeRecord !== null) {
      this.mixSolutionTypeStatus = this.typeRecord.mixSolutionTypeStatus || '1'
      this.mixSolutionTypeList = this.typeRecord.mixSolutionTypeList || []
      this.mixSolutionTypeList.forEach((item) => {
        if (!item.verifyAllowMinRange || !item.verifyAllowMaxRange || !item.verifyValidDate || !item.verifyValidDateUnit) {
          item.notVerifyData = true
        }
      })
      this.getDevList(this.typeRecord.devTypeId)
      if (!this.typeRecord.configMode) this.typeRecord.configMode = checkStandardCurveModeEnum.manMade
      if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
        if (this.editType === 'edit') {
          await this.getCurveSite(this.curveRecord.id)
        }
        await this.getCurveTypeSite(this.typeRecord.id)
      }
    }
    console.log(this.mixSolutionList, this.mixSolutionTypeList, this.methodItems)

    // 判断曲线类型是否给全部的管理因子配置了标液类型数据，如果notMethodItems不是空数组，则说明有因子没有配置标液类型数据，需要提醒用户配置
    this.notMethodItems = this.methodItems.filter((item) => !this.mixSolutionTypeList.some((item2) => item2.itemId === item.id))
    if (this.notMethodItems.length) {
      let msg = ''
      this.notMethodItems.forEach((item, index) => {
        msg += item.name
        if (index !== this.notMethodItems.length - 1) {
          msg += '、'
        }
      })
      this.$message.error('因子' + msg + '的混合标液类型未配置，请先修改曲线类型，配置混合标液类型！')
    }
    for (let i = this.mixSolutionList.length - 1; i >= 0; i--) {
      let item = this.mixSolutionList[i]
      let findIndex = this.methodItems.findIndex((item2) => item2.id === item.itemId)
      if (findIndex === -1) {
        this.mixSolutionList.splice(i, 1)
      }
    }
    let allReagentList = []
    if (this.typeRecord && this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
      allReagentList = await this.defaultReagentList(this.typeRecord.referenceSolution)
    }
    // 当selectedItemIds时，默认选中所有的因子
    if (!this.selectedItemIds || this.selectedItemIds.length === 0) {
      this.selectedItemIds = this.methodItems.map((item) => item.id)
    }
    this.selectedMethodItems = this.methodItems.filter((item) => this.selectedItemIds.includes(item.id))
    if (this.mixSolutionTypeStatus !== this.mixSolutionStatus) {
      this.mixSolutionList.forEach((item) => {
        delete item.solutionId
        delete item.solutionName
        delete item.solutionVolume
        delete item.solutionUseVolume
        delete item.isLessThanSolutionVolume
        delete item.isEmptySolution
      })
    }

    for (let i2 = 0; i2 < this.methodItems.length; i2++) {
      let item = this.methodItems[i2]
      let findIndex = this.mixSolutionList.findIndex((item2) => item2.itemId === item.id)
      console.log(findIndex)
      let mixSolutionTypeItem = this.mixSolutionTypeList.find((item2) => item2.itemId === item.id)

      if (!mixSolutionTypeItem || !mixSolutionTypeItem.solutionTypeId) {
        this.$message.error(`因子 ${item.name} 未配置标液类型，请先在曲线类型中配置标液类型！`)
      }

      if (findIndex === -1) {
        console.log('1111111111111111111111111111wwwwwwwwwwwwwwwwwwwwwwwwwwwwww')
        let mixSolutionItem = {
          itemId: item.id,
          itemName: item.name,
          makeDate: this.publicTimeData.makeDate,
          validTime: this.publicTimeData.validTime,
          devId: this.mixSolutionList[0]?.devId || [],
          verifyAllowMinRange: mixSolutionTypeItem?.verifyAllowMinRange,
          verifyAllowMaxRange: mixSolutionTypeItem?.verifyAllowMaxRange,
          verifyValidDate: mixSolutionTypeItem?.verifyValidDate,
          verifyValidDateUnit: mixSolutionTypeItem?.verifyValidDateUnit
        }

        if (this.mixSolutionTypeStatus === '1') {
          let solutionList = await this.defaultSolutionList(mixSolutionTypeItem.solutionTypeId)
          solutionList = solutionList.filter(
            (item2) => Moment(mixSolutionItem.makeDate).isSameOrAfter(item2.makeTime) && Moment(mixSolutionItem.makeDate).isSameOrBefore(item2.validTime)
          )
          console.log(solutionList, solutionList.length)
          if (solutionList.length > 0) {
            /**
             * 如果返回的标液数据在之前的因子中被选中过，那么就需要排除掉之前因子的选择对标液可用量的影响，
             * 即现在的标液可用量=标液数据中的标液可用量-之前因子的标液使用量，
             * 现在的标液可用量再与当前因子的标液使用量进行判断；
             * 如果返回的标液数据没被选过，那直接返回true
             *
             * 如果判断过后，没有符合要求的数据了，那给当前因子默认赋值 返回的标液数据的第一条
             */
            let solution = {}
            const useData = this.allDrawList.filter((item2) => item2.itemId === item.id)
            let useVolume = 0.0
            useData.forEach((item2) => {
              useVolume += parseFloat(item2.standardLiquid)
            })
            useVolume = useVolume.toFixed(3)

            let solutionFilter = solutionList.filter((item2) => {
              let msl = this.mixSolutionList.filter((item3) => this.selectedItemIds.includes(item3.itemId)).filter((item3) => Number(item3.solutionId) === Number(item2.id))
              let totalSolutionUseVolume = 0
              if (msl.length) {
                msl.forEach((item3) => {
                  if (item3.solutionUseVolume) {
                    totalSolutionUseVolume += item3.solutionUseVolume
                  }
                })
              }
              if (item2.volume - totalSolutionUseVolume >= useVolume) {
                return true
              } else {
                return false
              }
            })
            let isLessThanSolutionVolume = false
            if (solutionFilter.length) {
              solution = solutionFilter[0]
            } else {
              solution = solutionList[0]
              isLessThanSolutionVolume = true
            }
            mixSolutionItem.solutionId = solution.id
            mixSolutionItem.solutionName = solution.solutionNo + ' ' + solution.name
            mixSolutionItem.solutionVolume = solution.volume

            mixSolutionItem.solutionUseVolume = useVolume
            if (isLessThanSolutionVolume) {
              mixSolutionItem.isLessThanSolutionVolume = true
            }
          } else {
            mixSolutionItem.isEmptySolution = true
          }
        } else {
          let solutionList = await this.defaultReagentList(mixSolutionTypeItem.solutionTypeId)
          solutionList = solutionList.filter(
            (item2) => Moment(mixSolutionItem.makeDate).isSameOrAfter(item2.configTime) && Moment(mixSolutionItem.makeDate).isSameOrBefore(item2.validTime)
          )
          if (solutionList.length > 0) {
            /**
             * 如果返回的标液数据在之前的因子中被选中过，那么就需要排除掉之前因子的选择对标液可用量的影响，
             * 即现在的标液可用量=标液数据中的标液可用量-之前因子的标液使用量，
             * 现在的标液可用量再与当前因子的标液使用量进行判断；
             * 如果返回的标液数据没被选过，那直接返回true
             *
             * 如果判断过后，没有符合要求的数据了，那给当前因子默认赋值 返回的标液数据的第一条
             */
            let solution = {}
            const useData = this.allDrawList.filter((item2) => item2.itemId === item.id)
            let useVolume = 0.0
            useData.forEach((item2) => {
              useVolume += parseFloat(item2.standardLiquid)
            })
            useVolume = useVolume.toFixed(3)
            let solutionFilter = solutionList.filter((item2) => {
              let msl = this.mixSolutionList.filter((item3) => this.selectedItemIds.includes(item3.itemId)).filter((item3) => Number(item3.solutionId) === Number(item2.id))
              let totalSolutionUseVolume = 0
              if (msl.length) {
                msl.forEach((item3) => {
                  if (item3.solutionUseVolume) {
                    totalSolutionUseVolume += item3.solutionUseVolume
                  }
                })
              }
              if (item2.volume - totalSolutionUseVolume >= useVolume) {
                return true
              } else {
                return false
              }
            })
            let isLessThanSolutionVolume = false
            if (solutionFilter.length) {
              solution = solutionFilter[0]
            } else {
              solution = solutionList[0]
              isLessThanSolutionVolume = true
            }
            mixSolutionItem.solutionId = solution.id
            mixSolutionItem.solutionName = solution.reagentNo + ' ' + solution.name
            mixSolutionItem.solutionVolume = solution.volume
            mixSolutionItem.solutionUseVolume = useVolume
            if (isLessThanSolutionVolume) {
              mixSolutionItem.isLessThanSolutionVolume = true
            }
          } else {
            mixSolutionItem.isEmptySolution = true
          }
        }

        if (this.typeRecord && this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
          mixSolutionItem.intercept = null // 截距(a)
          mixSolutionItem.slope = null // 斜率(b)
          mixSolutionItem.correlationCoefficient = null // 相关系数(r)
          if (this.mixSolutionList[0] && this.mixSolutionList[0].solventId) {
            mixSolutionItem.solventId = this.mixSolutionList[0].solventId
            mixSolutionItem.solventName = this.mixSolutionList[0].solventName
            mixSolutionItem.solventUnit = this.mixSolutionList[0].solventUnit
            mixSolutionItem.solventVolume = this.mixSolutionList[0].solventVolume
            mixSolutionItem.solventUseVolume = this.mixSolutionList[0].solventUseVolume
          } else {
            let reagentList = allReagentList.filter(
              (item2) => Moment(mixSolutionItem.makeDate).isSameOrAfter(item2.configTime) && Moment(mixSolutionItem.makeDate).isSameOrBefore(item2.validTime)
            )
            if (reagentList.length > 0) {
              let solventUseVolume = this.typeRecord && (this.typeRecord.constantVolume || this.typeRecord.constantVolume === 0) ? this.typeRecord.constantVolume : null
              let reagent = reagentList[0]
              mixSolutionItem.solventId = reagent.id
              mixSolutionItem.solventName = reagent.name
              mixSolutionItem.solventUnit = reagent.unit
              mixSolutionItem.solventVolume = reagent.volume
              mixSolutionItem.solventUseVolume = solventUseVolume
            } else {
              mixSolutionItem.isEmptyReagent = true
            }
          }
        }

        if (this.typeRecord && this.typeRecord.configMode === checkStandardCurveModeEnum.fileMade) {
          mixSolutionItem.fileAppendix = []
          mixSolutionItem.fileList = []
        }

        if (this.curveRecord && this.curveRecord.id) {
          mixSolutionItem.rid = this.curveRecord.id
        }

        console.log(mixSolutionItem)
        this.mixSolutionList.push(mixSolutionItem)
      } else {
        let mixSolutionItem = this.mixSolutionList[findIndex]
        console.log(mixSolutionItem)
        mixSolutionItem.makeDate = Moment(mixSolutionItem.makeDate)
        mixSolutionItem.validTime = Moment(mixSolutionItem.validTime)
        mixSolutionItem.devId = mixSolutionItem.devId ? mixSolutionItem.devId.split(',') : []
        mixSolutionItem.verifyAllowMinRange = mixSolutionTypeItem?.verifyAllowMinRange
        mixSolutionItem.verifyAllowMaxRange = mixSolutionTypeItem?.verifyAllowMaxRange
        mixSolutionItem.verifyValidDate = mixSolutionTypeItem?.verifyValidDate
        mixSolutionItem.verifyValidDateUnit = mixSolutionTypeItem?.verifyValidDateUnit

        const useData = this.allDrawList.filter((item2) => item2.itemId === item.id)
        let useVolume = 0.0
        useData.forEach((item2) => {
          useVolume += parseFloat(item2.standardLiquid)
        })
        useVolume = useVolume.toFixed(3)

        if (this.mixSolutionTypeStatus === '1') {
          if (mixSolutionItem.solutionId) {
            mixSolutionItem.solutionId = Number(mixSolutionItem.solutionId)
            mixSolutionItem.solutionUseVolume = mixSolutionItem.solutionUseVolume || mixSolutionItem.solutionUseVolume === 0 ? mixSolutionItem.solutionUseVolume : useVolume
            let solutions = await this.getSolutionOne(mixSolutionItem.solutionId)
            if (solutions.length) {
              mixSolutionItem.solutionName = solutions[0].solutionNo + ' ' + solutions[0].name
              mixSolutionItem.solutionVolume = solutions[0].volume
            }
          } else {
            let solutionList = await this.defaultSolutionList(mixSolutionTypeItem.solutionTypeId)
            solutionList = solutionList.filter(
              (item2) => Moment(mixSolutionItem.makeDate).isSameOrAfter(item2.makeTime) && Moment(mixSolutionItem.makeDate).isSameOrBefore(item2.validTime)
            )
            console.log(solutionList, solutionList.length)
            if (solutionList.length > 0) {
              /**
               * 如果返回的标液数据在之前的因子中被选中过，那么就需要排除掉之前因子的选择对标液可用量的影响，
               * 即现在的标液可用量=标液数据中的标液可用量-之前因子的标液使用量，
               * 现在的标液可用量再与当前因子的标液使用量进行判断；
               * 如果返回的标液数据没被选过，那直接返回true
               * 如果判断过后，没有符合要求的数据了，那给当前因子默认赋值 返回的标液数据的第一条
               */
              let solution = {}

              if (!mixSolutionItem.solutionUseVolume && mixSolutionItem.solutionUseVolume !== 0) {
                mixSolutionItem.solutionUseVolume = useVolume
              }

              let solutionFilter = solutionList.filter((item2) => {
                let msl = this.mixSolutionList.filter((item3) => this.selectedItemIds.includes(item3.itemId)).filter((item3) => Number(item3.solutionId) === Number(item2.id))
                let totalSolutionUseVolume = 0
                if (msl.length) {
                  msl.forEach((item3) => {
                    if (item3.solutionUseVolume) {
                      totalSolutionUseVolume += item3.solutionUseVolume
                    }
                  })
                }
                if (item2.volume - totalSolutionUseVolume >= mixSolutionItem.solutionUseVolume) {
                  return true
                } else {
                  return false
                }
              })
              let isLessThanSolutionVolume = false
              if (solutionFilter.length) {
                solution = solutionFilter[0]
              } else {
                solution = solutionList[0]
                isLessThanSolutionVolume = true
              }
              mixSolutionItem.solutionId = solution.id
              mixSolutionItem.solutionName = solution.solutionNo + ' ' + solution.name
              mixSolutionItem.solutionVolume = solution.volume

              if (isLessThanSolutionVolume) {
                mixSolutionItem.isLessThanSolutionVolume = true
              }
            } else {
              mixSolutionItem.isEmptySolution = true
            }
          }
        } else {
          if (mixSolutionItem.solutionId) {
            mixSolutionItem.solutionId = Number(mixSolutionItem.solutionId)
            mixSolutionItem.solutionUseVolume = mixSolutionItem.solutionUseVolume || mixSolutionItem.solutionUseVolume === 0 ? mixSolutionItem.solutionUseVolume : useVolume
            let solutionItem = await this.getSolventOne(mixSolutionItem.solutionId)
            if (solutionItem) {
              mixSolutionItem.solutionName = solutionItem.reagentNo + ' ' + solutionItem.name
              mixSolutionItem.solutionVolume = solutionItem.volume
            }
          } else {
            let solutionList = await this.defaultReagentList(mixSolutionTypeItem.solutionTypeId)
            solutionList = solutionList.filter(
              (item2) => Moment(mixSolutionItem.makeDate).isSameOrAfter(item2.configTime) && Moment(mixSolutionItem.makeDate).isSameOrBefore(item2.validTime)
            )
            console.log(solutionList, solutionList.length)
            if (solutionList.length > 0) {
              /**
               * 如果返回的标液数据在之前的因子中被选中过，那么就需要排除掉之前因子的选择对标液可用量的影响，
               * 即现在的标液可用量=标液数据中的标液可用量-之前因子的标液使用量，
               * 现在的标液可用量再与当前因子的标液使用量进行判断；
               * 如果返回的标液数据没被选过，那直接返回true
               * 如果判断过后，没有符合要求的数据了，那给当前因子默认赋值 返回的标液数据的第一条
               */
              let solution = {}

              if (!mixSolutionItem.solutionUseVolume && mixSolutionItem.solutionUseVolume !== 0) {
                mixSolutionItem.solutionUseVolume = useVolume
              }

              let solutionFilter = solutionList.filter((item2) => {
                let msl = this.mixSolutionList.filter((item3) => this.selectedItemIds.includes(item3.itemId)).filter((item3) => Number(item3.solutionId) === Number(item2.id))
                let totalSolutionUseVolume = 0
                if (msl.length) {
                  msl.forEach((item3) => {
                    if (item3.solutionUseVolume) {
                      totalSolutionUseVolume += item3.solutionUseVolume
                    }
                  })
                }
                if (item2.volume - totalSolutionUseVolume >= mixSolutionItem.solutionUseVolume) {
                  return true
                } else {
                  return false
                }
              })
              let isLessThanSolutionVolume = false
              if (solutionFilter.length) {
                solution = solutionFilter[0]
              } else {
                solution = solutionList[0]
                isLessThanSolutionVolume = true
              }
              mixSolutionItem.solutionId = solution.id
              mixSolutionItem.solutionName = solution.reagentNo + ' ' + solution.name
              mixSolutionItem.solutionVolume = solution.volume

              if (isLessThanSolutionVolume) {
                mixSolutionItem.isLessThanSolutionVolume = true
              }
            } else {
              mixSolutionItem.isEmptySolution = true
            }
          }
        }
        if (this.typeRecord && this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
          let solventUseVolume = this.typeRecord && (this.typeRecord.constantVolume || this.typeRecord.constantVolume === 0) ? this.typeRecord.constantVolume : null

          if (mixSolutionItem.solventId) {
            mixSolutionItem.solventId = Number(mixSolutionItem.solventId)
            mixSolutionItem.solventUseVolume = mixSolutionItem.solventUseVolume || mixSolutionItem.solventUseVolume === 0 ? mixSolutionItem.solventUseVolume : solventUseVolume
            let solvent = await this.getSolventOne(mixSolutionItem.solventId)
            if (solvent) {
              mixSolutionItem.solventName = solvent.name
              mixSolutionItem.solventUnit = solvent.unit
              mixSolutionItem.solventVolume = solvent.volume
            }
          } else {
            let reagentList = allReagentList.filter(
              (item2) => Moment(mixSolutionItem.makeDate).isSameOrAfter(item2.configTime) && Moment(mixSolutionItem.makeDate).isSameOrBefore(item2.validTime)
            )
            if (reagentList.length > 0) {
              let reagent = reagentList[0]
              mixSolutionItem.solventId = reagent.id
              mixSolutionItem.solventName = reagent.name
              mixSolutionItem.solventUnit = reagent.unit
              mixSolutionItem.solventVolume = reagent.volume
              mixSolutionItem.solventUseVolume = solventUseVolume
            } else {
              mixSolutionItem.isEmptyReagent = true
            }
          }
        }
        if (this.typeRecord && this.typeRecord.configMode === checkStandardCurveModeEnum.fileMade) {
          mixSolutionItem.fileAppendix = mixSolutionItem.fileAppendix ? JSON.parse(mixSolutionItem.fileAppendix) : []
          let fileList = []
          mixSolutionItem.fileAppendix.forEach((it) => fileList.push({ uid: it.uid, name: it.fileName, status: 'done', url: this.viewUrl + it.fileUrl }))
          mixSolutionItem.fileList = fileList
        }
        this.onCorrelChangeComposite(mixSolutionItem.correlationCoefficient, mixSolutionTypeItem, mixSolutionItem)
      }
    }
    this.mixSolutionTypeList.forEach((item1) => {
      const columns = this.curveDrawModels.filter((item) => item.id === this.typeRecord.siteModelId)[0].columns
      const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns])) // 使用hotTable后不需要添加操作列，右键菜单添加删除列
      allColumns.forEach((item) => {
        if (item.hasOwnProperty(item.dataIndex) && item[item.dataIndex] !== null && item[item.dataIndex] !== '') {
          item.title = item.title + '（' + item[item.dataIndex] + '）'
        }

        if (item.dataIndex === 'drawIndex' || item.dataIndex === 'standardLiquid' || item.dataIndex === 'concentration') {
          item.readOnly = true
        }
        if (this.typeRecord.siteModelId == 3) {
          if (item.dataIndex === 'result') {
            item.readOnly = true
          }
        }
        if (
          this.typeRecord.siteModelId == 4 ||
          this.typeRecord.siteModelId == 8 ||
          this.typeRecord.siteModelId == 9 ||
          this.typeRecord.siteModelId == 10 ||
          this.typeRecord.siteModelId == 11
        ) {
          if (item.dataIndex === 'purity') {
            item.readOnly = true
          }
        }
      })
      let methodItemsSiteColumnsItem = {
        itemId: item1.itemId,
        itemName: item1.itemName,
        columns: allColumns
      }
      this.methodItemsSiteColumns.push(methodItemsSiteColumnsItem)
    })
    this.itemId = this.selectedItemIds[0]
    console.log(this.mixSolutionTypeList.length && this.mixSolutionList.length && this.methodItemsSiteColumns.length && this.allDrawList.length)
    console.log(this.mixSolutionTypeList.length, this.mixSolutionList.length, this.methodItemsSiteColumns.length, this.allDrawList.length)

    if (this.mixSolutionTypeList.length && this.mixSolutionList.length && this.methodItemsSiteColumns.length && this.allDrawList.length) {
      this.clickTab()
      this.confirmLoading = false
    }
  },
  methods: {
    clickTab(key) {
      console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq', this.itemId)
      let currentSolutionType = this.mixSolutionTypeList.find((item) => item.itemId === this.itemId) // 混合标液类型
      let currentSolution = this.mixSolutionList.find((item) => item.itemId === this.itemId) // 混合标液
      let currentSiteColumns = this.methodItemsSiteColumns.find((item) => item.itemId === this.itemId) // 不同关联因子的点位模板（模板的title的单位不一样）

      this.currentSolutionType = currentSolutionType || {} // 根据选择的因子过滤出的标液类型
      this.currentSolution = currentSolution || {} // 根据选择的因子过滤出的标液
      this.currentSiteColumns = currentSiteColumns || {} // 根据选择的因子过滤出的点位列表数据
      if (this.allDrawList.length) {
        let currentDrawList = this.allDrawList.filter((item) => item.itemId === this.itemId) // 点位数据

        this.currentDrawList.splice(0, this.currentDrawList.length, ...currentDrawList)
        this.curveDrawTable.dataSource = this.currentDrawList
        this.curveDrawTable.columns = this.currentSiteColumns.columns
        this.$refs.hotTableComponent.hotInstance.loadData(this.currentDrawList)
      }
      this.$forceUpdate()
      console.log(this.mixSolutionTypeList, '|', this.mixSolutionList, '|', this.methodItemsSiteColumns, '|', this.allDrawList)
      console.log(this.mixSolutionTypeList.length, '|', this.mixSolutionList.length, '|', this.methodItemsSiteColumns.length, '|', this.allDrawList.length)
      console.log(this.currentSolutionType, '|', this.currentSolution, '|', this.currentSiteColumns, '|', this.currentDrawList)
    },
    selectedItemChange() {
      this.selectedMethodItems = this.methodItems.filter((item) => this.selectedItemIds.includes(item.id))
      if (!this.selectedItemIds.includes(this.itemId)) {
        this.itemId = this.selectedItemIds[0]
        this.clickTab(this.itemId)
      }
    },
    // 默认显示的标准使用液
    defaultSolutionList(solutionTypeId) {
      let queryParam = {
        pageNo: 1,
        pageSize: 10000,
        page: true,
        checkStatus: checkStandardSolutionCheckStatusEnum.pass,
        validTime: this.timeFormat(this.makeDate),
        solutionTypeId: parseInt(solutionTypeId),
        compositionName: '',
        makeDateBegin: '',
        makeDateEnd: '',
        queryType: 0
      }
      return checkStandardSolutionApi.list(queryParam).then((res) => {
        console.log(res)
        let data = res.data || []
        return data
      })
    },
    // 默认显示的参比溶液
    defaultReagentList(materialId) {
      let queryParam = {
        cateId: materialId,
        validCheckTime: this.timeFormat(this.makeDate),
        page: false
      }
      return checkReagentApi.list(queryParam).then((res) => {
        console.log(res)
        if (res.code === 0) {
          let data = res.data || []
          return data
          // if (res.data.length > 0) {
          //   let selectData = res.data
          //   this.dataForm.data.solventId = selectData[0].id
          //   this.dataForm.data.solventName = selectData[0].name
          //   this.dataForm.data.solventUnit = selectData[0].unit
          //   this.dataForm.data.solventVolume = selectData[0].volume
          //   this.dataForm.data.solventUseVolume =
          //     this.typeRecord && (this.typeRecord.constantVolume || this.typeRecord.constantVolume === 0) ? this.typeRecord.constantVolume : null
          // } else {
          //   this.isEmptyReagent = true
          // }
        }
      })
    },
    initSiteUnit(siteId) {
      const columns = this.curveDrawModels.filter((item) => item.id === siteId)[0].columns
      // const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns, ...actionColumns]))
      const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns])) // 使用hotTable后不需要添加操作列，右键菜单添加删除列
      allColumns.forEach((item) => {
        if (
          this.typeRecord.hasOwnProperty(item.dataIndex) &&
          this.typeRecord[item.dataIndex] !== null &&
          this.typeRecord[item.dataIndex] !== '' &&
          this.typeRecord.siteModelId !== 9
        ) {
          item.title = item.title + '（' + this.typeRecord[item.dataIndex] + '）'
        }
        if (
          this.typeRecord.siteModelId === 9 &&
          this.typeRecord.benArea &&
          ['benArea', 'jiabenArea', 'yibenArea', 'duierjiabenArea', 'jianerjiabenArea', 'linerjiabenArea', 'yibingbenArea', 'benyixiArea'].includes(item.dataIndex)
        ) {
          item.title = item.title + '（' + this.typeRecord.benArea + '）'
        }

        if (siteId == 3) {
          if (item.dataIndex === 'standardLiquid' || item.dataIndex === 'concentration' || item.dataIndex === 'result') {
            item.readOnly = true
          }
        }
        if (siteId == 4 || siteId == 8 || siteId == 9 || siteId == 10 || siteId == 11) {
          if (item.dataIndex === 'standardLiquid' || item.dataIndex === 'concentration' || item.dataIndex === 'purity') {
            item.readOnly = true
          }
        }
      })
      // console.log(this.typeRecord)
      console.log(allColumns)
      this.curveDrawTable.columns = allColumns
    },
    getCurveTypeSite(id) {
      const param = { id: id }
      return checkStandardCurveTypeApi.curveTypeSite(param).then((res) => {
        if (res.code === 0 && res.data.length > 0) {
          const drawList = []
          res.data.forEach((item) => {
            let obj = {
              itemId: item.itemId,
              itemName: item.itemName,
              drawIndex: item.drawIndex,
              standardLiquid: item.standardLiquid,
              concentration: item.concentration
            }
            if (this.typeRecord.siteModelId == 3) {
              obj.result = item.measureConcentration
            }
            if (
              this.typeRecord.siteModelId == 4 ||
              this.typeRecord.siteModelId == 8 ||
              this.typeRecord.siteModelId == 9 ||
              this.typeRecord.siteModelId == 10 ||
              this.typeRecord.siteModelId == 11
            ) {
              obj.purity = item.measureConcentration
            }
            drawList.push(obj)
          })
          let drawItemIds = uniq(this.allDrawList.map((item) => item.itemId))
          let restDrawList = drawList.filter((item) => !drawItemIds.includes(item.itemId))
          this.allDrawList.push(...restDrawList)

          // if (this.itemId) {
          //   let currentDrawList = this.allDrawList.filter((item) => item.itemId === this.itemId)
          //   this.currentDrawList.splice(0, this.currentDrawList.length, ...currentDrawList)
          //   this.curveDrawTable.dataSource = this.currentDrawList
          //   this.$refs.hotTableComponent.hotInstance.loadData(this.currentDrawList)
          // }
        }
      })
    },
    getCurveSite(id) {
      const param = { id: id }
      return checkStandardCurveConfigApi.curveSite(param).then((res) => {
        if (res.code === 0 && res.data.length > 0) {
          this.allDrawList = res.data
          // if (this.itemId) {
          //   let currentDrawList = this.allDrawList.filter((item) => item.itemId === this.itemId)
          //   this.currentDrawList.splice(0, this.currentDrawList.length, ...currentDrawList)
          //   this.curveDrawTable.dataSource = this.currentDrawList
          //   this.$refs.hotTableComponent.hotInstance.loadData(this.currentDrawList)
          // }
          // this.curveDrawTable.dataSource = res.data
          // this.$refs.hotTableComponent.hotInstance.loadData(res.data)
        }
      })
    },
    save() {
      const self = this
      if (!self.confirmLoading) {
        if (this.notMethodItems.length) {
          let msg = ''
          this.notMethodItems.forEach((item, index) => {
            msg += item.name
            if (index !== this.notMethodItems.length - 1) {
              msg += '、'
            }
          })
          this.$message.error('因子' + msg + '的混合标液类型未配置，请先修改曲线类型，配置混合标液类型！')
        } else {
          let unsatisfyList = this.mixSolutionList.filter((item) => this.selectedItemIds.includes(item.itemId)).filter((item) => item.correlStatus === 'unsatisfy')
          if (unsatisfyList.length > 0 && this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
            // self.$message.error(`相关系数小于设置的最小值，无法提交`)
            let unsatisfyMsg = ''
            unsatisfyList.forEach((item, index) => {
              unsatisfyMsg += item.name
              if (index !== unsatisfyList.length - 1) {
                unsatisfyMsg += '、'
              }
            })
            this.$message.error('因子' + unsatisfyMsg + '的相关系数小于设置的最小值，无法提交')
          } else {
            self.saveData()
          }
        }
      }
    },
    async saveData() {
      const self = this
      // for (let i = 0; i < this.mixSolutionList.length; i++) {
      //   const element = array[i];

      // }
      let mixSolutionList = cloneDeep(this.mixSolutionList.filter((item) => this.selectedItemIds.includes(item.itemId)))
      self.$emit('updateCurveLoading', true)
      self.confirmLoading = true
      if (!mixSolutionList[0].solventId) {
        this.$message.error('请选择参比溶液！')
        self.$emit('updateCurveLoading', false)
        self.confirmLoading = false
        return
      }
      if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
        let solvent = await this.getSolventOne(mixSolutionList[0].solventId)
        let correctTime = mixSolutionList[0].makeDate.isSameOrAfter(solvent.configTime) && mixSolutionList[0].makeDate.isSameOrBefore(solvent.validTime)
        if (correctTime === false) {
          this.$message.error('配置时间不在范围之内，请对照‘参比溶液’的配置时间和有效期时间')
          self.$emit('updateCurveLoading', false)
          self.confirmLoading = false
          return
        }
      }
      for (let i = 0; i < this.selectedMethodItems.length; i++) {
        let item = this.selectedMethodItems[i]
        let mixSolutionItem = mixSolutionList.find((item2) => item2.itemId === item.id)
        let mixSolutionTypeItem = this.mixSolutionTypeList.find((item2) => item2.itemId === item.id)
        console.log(mixSolutionItem, mixSolutionTypeItem)
        if (!mixSolutionItem.makeDate) {
          this.$message.error(`请选择配制日期！`)
          self.$emit('updateCurveLoading', false)
          self.confirmLoading = false
          return
        }
        if (typeof mixSolutionItem.makeDate === 'string') {
          mixSolutionItem.makeDate = Moment(mixSolutionItem.makeDate)
          mixSolutionItem.validTime = Moment(mixSolutionItem.validTime)
        }

        if (!mixSolutionItem.devId || mixSolutionItem.devId.length === 0) {
          this.$message.error(`请选择设备！`)
          self.$emit('updateCurveLoading', false)
          self.confirmLoading = false
          return
        }
        if (!mixSolutionItem.solutionId || typeof mixSolutionItem.solutionId === 'undefined' || mixSolutionItem.solutionId === '') {
          this.$message.error(`因子【${item.name}】请选择标液！`)
          self.$emit('updateCurveLoading', false)
          self.confirmLoading = false
          return
        }

        if (this.mixSolutionTypeStatus === '1') {
          let solutions = await this.getSolutionOne(mixSolutionItem.solutionId)
          let correctTime = mixSolutionItem.makeDate.isSameOrAfter(solutions[0].makeTime) && mixSolutionItem.makeDate.isSameOrBefore(solutions[0].validTime)
          if (correctTime === false) {
            this.$message.error(`因子【${item.name}】的配置时间不在范围之内，请对照‘标准使用液’的配置时间和有效期时间`)
            self.$emit('updateCurveLoading', false)
            self.confirmLoading = false
            return
          }
        } else {
          let solutionItem = await this.getSolventOne(mixSolutionItem.solutionId)
          let correctTime = mixSolutionItem.makeDate.isSameOrAfter(solutionItem.configTime) && mixSolutionItem.makeDate.isSameOrBefore(solutionItem.validTime)
          if (correctTime === false) {
            this.$message.error(`因子【${item.name}】的配置时间不在范围之内，请对照‘标准使用液’的配置时间和有效期时间`)
            self.$emit('updateCurveLoading', false)
            self.confirmLoading = false
            return
          }
        }

        if (!mixSolutionItem.verifyAllowMinRange || !mixSolutionItem.verifyAllowMaxRange || !mixSolutionItem.verifyValidDate || !mixSolutionItem.verifyValidDateUnit) {
          this.$message.error(`因子【${item.name}】单点校准数据不全，请先在曲线类型中添加保存！`)
          self.$emit('updateCurveLoading', false)
          self.confirmLoading = false
          return
        }
        if (self.typeRecord.configMode === checkStandardCurveModeEnum.fileMade) {
          if (mixSolutionItem.fileAppendix.length === 0) {
            this.$message.error(`因子【${item.name}】请上传附件！`)
            self.$emit('updateCurveLoading', false)
            self.confirmLoading = false
            return
          }
          mixSolutionItem.fileAppendix = JSON.stringify(mixSolutionItem.fileAppendix)
        }
        if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
          if (
            (!mixSolutionItem.intercept && mixSolutionItem.intercept !== 0) ||
            (!mixSolutionItem.slope && mixSolutionItem.slope !== 0) ||
            (!mixSolutionItem.correlationCoefficient && mixSolutionItem.correlationCoefficient !== 0)
          ) {
            this.$message.error(`因子【${item.name}】请先完善曲线点位信息！`)
            self.$emit('updateCurveLoading', false)
            self.confirmLoading = false
            return
          }

          mixSolutionItem.makeDate = mixSolutionItem.makeDate ? self.timeFormat(mixSolutionItem.makeDate) : null
          mixSolutionItem.validTime = mixSolutionItem.validTime ? self.timeFormat(mixSolutionItem.validTime) : null
        }
        if (typeof mixSolutionItem.devId !== 'string') {
          mixSolutionItem.devId = mixSolutionItem.devId.join(',')
        }
      }
      const data = Object.assign(this.curveRecord, this.publicTimeData, {
        methodId: this.typeRecord.methodId,
        curveTypeId: this.typeRecord.id,
        mixSolutionList: mixSolutionList,
        selectedItemIds: this.selectedItemIds?.join(',') || null,
        mixSolutionTypeStatus: this.mixSolutionTypeStatus
      })
      if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
        data.drawList = self.allDrawList.filter((item) => this.selectedItemIds.includes(item.itemId))
      }
      console.log(data)
      checkStandardCurveConfigApi
        .save(data)
        .then((res) => {
          if (res.code === 0) {
            self.$message.success(res.msg)
            // self.$emit('success')
            self.newId = res.data.modelId
            self.getStandardSolutionReviewConfig()
          } else {
            self.$message.error(res.msg)
            self.$emit('error')
            self.$emit('updateCurveLoading', false)
          }
        })
        .catch((err) => {
          self.$emit('updateCurveLoading', false)
        })
        .finally(() => {
          self.confirmLoading = false
        })
      //   } else {
      //     this.$emit('updateCurveLoading', false)
      //   }
      // })
    },

    autoFormulaCalc() {
      const data = this.curveDrawTable.dataSource
      let flag = data.length > 0
      // console.log(this.curveDrawTable.columns)
      // 模板2
      const columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('F含量') >= 0)
      // 模板3
      const siteModel3Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('result') >= 0)
      // 模板4或8或10或11
      const siteModel4h8Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('purity') >= 0)
      // console.log(columns)
      const calcData = []
      if (columns.length > 0) {
        data.forEach((item) => {
          if (!item.hasOwnProperty('absorbanceValue') || item.absorbanceValue === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            const concentration = Math.round(Math.log10(item.absorbanceValue) * 1000) / 1000
            calcData.push({ concentration, result: item.result })
          }
        })
      } else if (siteModel3Columns.length > 0) {
        // 模板3公式计算
        data.forEach((item) => {
          if (!item.hasOwnProperty('absorbanceValue') || item.absorbanceValue === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            calcData.push({ concentration: item.result, result: item.absorbanceValue })
          }
        })
      } else if (siteModel4h8Columns.length > 0) {
        // 模板4或8或10或11公式计算
        data.forEach((item) => {
          if (!item.hasOwnProperty('purity') || item.purity === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            calcData.push({ concentration: item.purity, result: item.result })
          }
        })
      } else {
        data.forEach((item) => {
          if (!item.hasOwnProperty('concentration') || item.concentration === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            calcData.push({ concentration: item.concentration, result: item.result })
          }
        })
      }
      if (flag) {
        checkStandardCurveConfigApi.formulaCalc({ drawList: calcData, minCorrel: this.typeRecord.minCorrel }).then((res) => {
          if (res.code === 0) {
            const { intercept, slope, correlationCoefficient } = res.data
            this.curvePreviewModal.data.equationData = { intercept, slope, correlationCoefficient }
            this.$set(this.currentSolution, 'intercept', intercept)
            this.$set(this.currentSolution, 'slope', slope)
            this.$set(this.currentSolution, 'correlationCoefficient', correlationCoefficient)

            this.onCorrelChange(correlationCoefficient)
          }
        })
      }
      return flag
    },

    formulaCalc() {
      let flag = false

      flag = this.autoFormulaCalc()

      if (!flag) {
        this.$message.warn('请先完善曲线点位信息')
      }
    },
    onCorrelChange(value) {
      if (typeof value === 'undefined') {
        this.currentSolution.correlStatus = ''
      } else if (value < this.currentSolutionType.minCorrel) {
        this.currentSolution.correlStatus = 'unsatisfy'
      } else {
        this.currentSolution.correlStatus = 'satisfy'
      }
    },
    // 复合因子的判断
    onCorrelChangeComposite(value, mixSolutionTypeItem, it) {
      if (typeof value === 'undefined') {
        // it.correlStatus = ''
        this.$set(it, 'correlStatus', '')
      } else if (value < mixSolutionTypeItem.minCorrel) {
        // it.correlStatus = 'unsatisfy'
        this.$set(it, 'correlStatus', 'unsatisfy')
      } else {
        // it.correlStatus = 'satisfy'
        this.$set(it, 'correlStatus', 'satisfy')
      }
    },
    handleRemove(info) {
      this.fileAppendix = this.fileAppendix.filter((file) => file.uid !== info.uid)
      this.fileList = this.fileList.filter((r) => r.uid !== info.uid)
    },
    handleChange(info) {
      this.currentSolution.fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          const fileUrl = info.file.response.data.fileUrl
          const fileName = info.file.response.data.fileName
          info.file.thumbUrl = this.viewUrl + fileUrl
          info.file.url = this.viewUrl + fileName
          this.currentSolution.fileAppendix.push({ uid: info.file.uid, fileUrl: fileUrl, fileName: fileName })
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    getDevList(typeId) {
      resDevApi.getDevsByTypes({ typeId: typeId }).then((res) => {
        this.devList = res
        this.devMap = this.devList.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
    },
    handleDevChange(value) {
      const data = value.map((it) => this.devMap[it])
      // this.currentSolution.devList = data
      this.mixSolutionList.forEach((item) => {
        item.devId = value
        item.devList = data
      })
    },
    solventUseVolumeChange(value) {
      console.log(value)
      for (let i = 0; i < this.mixSolutionList.length; i++) {
        let item = this.mixSolutionList[i]
        item.solventUseVolume = value
      }
    },
    // 根据试剂类目配置获取有效期止
    getValidTime(current) {
      const { validDateUnit, validDate } = this.typeRecord
      return this.moment(current).add(validDate, validDateUnit)
    },
    // 根据试剂类目配置设置有效期止
    async setValidTime(current) {
      const validTime = this.getValidTime(current)
      this.publicTimeData.makeDate = current
      this.publicTimeData.validTime = validTime
      for (let i = 0; i < this.mixSolutionList.length; i++) {
        let item = this.mixSolutionList[i]
        item.makeDate = current
        item.validTime = validTime
      }

      let mixSolutionList = this.mixSolutionList.filter((item) => this.selectedItemIds.includes(item.itemId))
      for (let i = 0; i < mixSolutionList.length; i++) {
        let item = mixSolutionList[i]
        if (item.solutionId) {
          if (this.mixSolutionTypeStatus === '1') {
            let solutions = await this.getSolutionOne(item.solutionId)
            console.log('66666666', solutions[0])
            let correctTime = current.isSameOrAfter(solutions[0].makeTime) && current.isSameOrBefore(solutions[0].validTime)
            if (correctTime === false) {
              this.$message.error(`因子【${item.itemName}】的配置时间不在范围之内，请对照‘标准使用液’的配置时间和有效期时间`)
            }
          } else {
            let solutionItem = await this.getSolventOne(item.solutionId)
            console.log('88888888', solutionItem)
            let correctTime = current.isSameOrAfter(solutionItem.configTime) && current.isSameOrBefore(solutionItem.validTime)
            if (correctTime === false) {
              this.$message.error(`因子【${item.itemName}】的配置时间不在范围之内，请对照‘标准使用液’的配置时间和有效期时间`)
            }
          }
        }
      }

      if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade && mixSolutionList[0].solventId) {
        let solvent = await this.getSolventOne(mixSolutionList[0].solventId)
        console.log('88888888', solvent)
        let correctTime = current.isSameOrAfter(solvent.configTime) && current.isSameOrBefore(solvent.validTime)
        if (correctTime === false) {
          this.$message.error('配置时间不在范围之内，请对照‘参比溶液’的配置时间和有效期时间')
        }
      }
    },
    getSolutionOne(id) {
      return checkStandardSolutionApi.list({ id, page: false }).then((res) => {
        return res.data
      })
    },
    getSolventOne(id) {
      return checkReagentApi.findById(id).then((res) => {
        console.log(res)
        return res.data
      })
    },

    // 判断是否为自动曲线复审核
    getStandardSolutionReviewConfig() {
      autoConfigureApi
        .selectConfigByType({ type: 'curve_review_config' })
        .then((res) => {
          // 如果是1，自动曲线复审核
          if (res == 1) {
            this.$message.warning('正在自动复审核，请勿进行其它操作！')
            this.selectUserList()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 获取自动曲线复审核 复核人员
    selectUserList() {
      sysReviewConfigUserControllerApi
        .selectUserList({ type: 'qx', id: this.newId })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.assignUser = res.data
            this.autoAssignReviewedCheckUser()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动指派复核人
    autoAssignReviewedCheckUser() {
      let assignDataArr = [{ id: this.newId, reviewUser: this.assignUser.reviewedCheckUser }]
      checkStandardCurveConfigApi
        .assign(assignDataArr)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('曲线配置自动指派复核人成功')
            this.autoReview()
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动复核
    autoReview() {
      const reviewDataArr = [{ id: this.newId, reviewNote: null, reviewStatus: '1', reviewUser: this.assignUser.reviewedCheckUser }]

      checkStandardCurveConfigApi
        .review({ models: reviewDataArr, checkUser: null, checkPwd: null })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('曲线配置自动复核成功')
            this.selectUserListReviewedUser()
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 获取自动曲线复审核 审核人员
    selectUserListReviewedUser() {
      sysReviewConfigUserControllerApi
        .selectUserList({ type: 'qx', id: this.newId })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.assignUser = res.data
            this.autoCheck()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动审核
    autoCheck() {
      const checkDataArr = [{ id: this.newId, checkNote: null, checkPwd: null, checkStatus: '2', checkUser: this.assignUser.reviewedUser }]

      checkStandardCurveConfigApi
        .autoCheck(checkDataArr)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('曲线配置自动审核成功')
            this.newId = null
            this.assignUser = {}
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>

<style scoped>
.descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
  background-color: #fafafa;
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  overflow-wrap: break-word;
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.descriptions-view {
  border: 1px solid #f0f0f0;
}

.content-row {
  border: 1px dashed #e8e8e8;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.content-row-title {
  /*padding: 10px 10px 10px 20px;*/
  line-height: 32px;
  /*color: #4daaff;*/
  color: rgba(0, 0, 0, 0.85);
}
.empty-data {
  color: red;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1rem;
}
</style>
<style lang="less">
.curveCompositeItemSite .ant-form-item-label:before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}
</style>