<template>
  <div>
    <a-upload v-bind="uploadProps" @change="handleChange" @preview="handlePreview">
      <slot>
        <template v-if="fileType==='file'">
          <a-button>
            <a-icon type="upload"/>
            点击上传
          </a-button>
        </template>
        <template v-else>
          <div>
            <a-icon type="plus"/>
          </div>
        </template>
      </slot>
    </a-upload>
    <a-modal :footer="null" :visible="previewVisible" @cancel="handlePreviewCancel">
      <img :src="previewImage" alt="example" style="width: 100%"/>
    </a-modal>
    <a-modal :footer="null" :visible="previewVideoVisible" @cancel="handlePreviewCancel">
      <video id="video" autoplay="loop" cotrols muted width="100%">
        <source :src="videoSrc" type="video/mp4">
        <source :src="videoSrc" type="video/ogg">
        <source :src="videoSrc" type="video/webm">
        <object :data="videoSrc" width="100%">
          <embed :src="videoSrc" width="100%">
        </object>
        您的浏览器不支持video标签
      </video>
    </a-modal>
  </div>
</template>

<script>

import { sysCommonApi } from '@/api/common'
import { getFileName, uidGenerator } from '@/utils/util'

const FILE_TYPE_IMG = 'image'
const FILE_TYPE_TXT = 'file'
const FILE_TYPE_VIDEO = 'video'

export default {
  name: 'JUpload',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { required: false, type: [String, Array] },
    fileType: {
      type: String,
      required: false,
      default: 'file'
    }
  },

  data () {
    return {
      videoSrc: null,
      accessToken: this.$store.getters.token,
      previewVisible: false,
      previewVideoVisible: false,
      previewImage: null,
      uploadAction: sysCommonApi.upload,
      urlDownload: sysCommonApi.downLoad,
      headers: { 'Access-Token': this.$store.getters.token },
      fileList: []
    }
  },

  computed: {
    uploadProps () {
      const { header, uploadAction: action, fileList, beforeUpload } = this
      return Object.assign({}, this.$attrs, { header, action, fileList, beforeUpload })
    }
  },

  created () {
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.initFileList(newValue)
      }
    }
  },

  methods: {
    initFileList (paths) {
      // 适配传入属性为空
      if (!paths) {
        this.fileList.splice(0, this.fileList.length)
        return false
      }
      let fileList
      switch (paths.constructor) {
        case String:
          fileList = paths?.split(',').map(item => {
            let url = ''
            if (this.fileType === FILE_TYPE_IMG) {
              url = item.startsWith(sysCommonApi.view) ? item : sysCommonApi.view + '/' + item
            } else if (this.fileType === FILE_TYPE_VIDEO) {
              url = item.startsWith(sysCommonApi.viewVideo) ? item : sysCommonApi.viewVideo + '/' + item
            } else {
              url = item
            }

            return { uid: uidGenerator(), name: getFileName(item), status: 'done', url: url, response: { status: 'history', data: item } }
          })
          break
        case Array:
          fileList = paths.map(item => {
            let url = ''

            // 修复部分数据中url 依旧为一个对象
            const subUrl = this.getUrlByFile(item)
            if (this.fileType === FILE_TYPE_IMG) {
              url = subUrl.startsWith(sysCommonApi.view) ? subUrl : sysCommonApi.view + '/' + subUrl
            } else if (this.fileType === FILE_TYPE_VIDEO) {
              url = subUrl.startsWith(sysCommonApi.viewVideo) ? subUrl : sysCommonApi.viewVideo + '/' + subUrl
            } else {
              url = subUrl
            }
            const data1 = { uid: uidGenerator(), name: item.name || getFileName(item), status: 'done', url: url, response: { status: 'history' } }
            return data1
          })
          break
      }

      fileList && this.fileList.splice(0, this.fileList.length, ...fileList)
    },

    getUrlByFile (file) {
      if (typeof (file) === 'string') {
        return file
      } else if (typeof (file) === 'object' && (file.url || file.fileUrl)) {
        return this.getUrlByFile(file.url || file.fileUrl)
      } else {
        return ''
      }
    },

    handlePathChange () {
      const result = this.fileList?.reduce((acc, item) => {
        item?.response?.data && acc.push(item.response.data)
        return acc
      }, []) || []
      // 适配传入属性为空
      if (!this.value) {
        this.$emit('change', result.join(','))
        return false
      }
      switch (this.value.constructor) {
        case String:
          this.$emit('change', result.join(','))
          break
        case Array:

          this.$emit('change', [...this.fileList.map(item => ({ uid: item.uid, url: item.url, name: item.name, status: item.status }))])
          break
      }
    },
    // 文件类型
    checkType (file, typeList) {
      const that = this
      return new Promise(function (resolve, reject) {
        if (!typeList.includes(file.type)) {
          that.$message.warning('视频文件类型错误，请重新上传MP4格式视频')
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        } else {
          console.log('类型正确')
          resolve()
        }
      })
    },
    beforeUpload (file) {
      const fileType = file.type
      if (fileType === FILE_TYPE_IMG) {
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片')
          return false
        }
      } else if (fileType === FILE_TYPE_TXT) {
        if (fileType.indexOf('image') >= 0) {
          this.$message.warning('请上传文件')
          return false
        }
      } else if (fileType === FILE_TYPE_VIDEO) {
        // 视频验证
        return Promise.all([this.checkType(file, ['video/mp4', 'video/ogg'])])
      }
      // TODO 扩展功能验证文件大小
      return true
    },

    handlePreview (file) {
      if (this.fileType === FILE_TYPE_IMG) {
        this.previewImage = file.url || file.preview
        this.previewVisible = true
      } else if (this.fileType === FILE_TYPE_VIDEO) {
        this.videoSrc = file.url || file.preview
        this.previewVideoVisible = true
      } else {
        window.location = sysCommonApi.downLoad + '?filePath=' + file.url + '&fileName=' + file.name + '&token=' + this.$store.getters.token
      }
    },

    handlePreviewCancel () {
      if (this.previewVideoVisible === true) {
        // 关闭弹窗之前关闭视频
        const myVideo = document.getElementById('video')
        myVideo.pause()
      }

      this.previewVisible = false
      this.previewVideoVisible = false
      this.previewImage = null
      this.videoSrc = null
    },

    handleChange (info) {
      let fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          fileList = fileList.map((file) => {
            if (file.response) {
              file.url = file.response.data
            }
            return file
          })
        }
        this.$message.success(`${info.file.name} 上传成功!`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败.`)
      } else if (info.file.status === 'removed') {
        this.handleDelete(info.file)
      }
      this.fileList = fileList
      if (info.file.status === 'done' || info.file.status === 'removed') {
        this.handlePathChange()
      }
    },

    handleDelete (file) {
      // 如有需要新增 删除逻辑
      console.log(file)
    }
  }
}
</script>

<style scoped>

</style>
