'use strict'

/**
 * 检测模型: 相关API
 */

import { delAction, getAction, postDataAction } from '@/api/common'

const sysCheckModelPre = '/ms-api/sys-check-model'

export const sysCheckModelApi = {
  list: (param) => getAction(`${sysCheckModelPre}/list`, param),
  findById: (id) => getAction(`${sysCheckModelPre}/findById/${id}`),
  save: (data) => postDataAction(`${sysCheckModelPre}/save`, JSON.stringify(data)),
  deleteById: (id) => delAction(`${sysCheckModelPre}/deleteById/${id}`)
}
