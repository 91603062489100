<template>
  <a-modal
    title="修改分类时间"
    :width="640"
    :visible="visible"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="分类名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select placeholder="请选择分类名称" v-model="cateId" :disabled="changeDisabled" @change="changeCate">
          <a-select-option v-for="item in cateList" :key="item.id">{{ item.cateName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="周期开始间隔" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number
          style="width: 100px"
          v-model="model.periodIntervalTimeLeft"
          :min="1"
          :precision="0"
          placeholder="最小值"
          @blur="changeValue(model.periodIntervalTimeLeft, model.periodIntervalTimeRight, '周期开始间隔')"
        />
        ~
        <a-input-number
          style="width: 100px"
          v-model="model.periodIntervalTimeRight"
          :min="1"
          :precision="0"
          placeholder="最大值"
          @blur="changeValue(model.periodIntervalTimeLeft, model.periodIntervalTimeRight, '周期开始间隔')"
        />分钟
      </a-form-item>
      <a-form-item label="点位距离" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number
          style="width: 100px"
          v-model="model.siteTimeLeft"
          :min="1"
          :precision="0"
          placeholder="最小值"
          @blur="changeValue(model.siteTimeLeft, model.siteTimeRight, '点位距离')"
        />
        ~
        <a-input-number
          style="width: 100px"
          v-model="model.siteTimeRight"
          :min="1"
          :precision="0"
          placeholder="最大值"
          @blur="changeValue(model.siteTimeLeft, model.siteTimeRight, '点位距离')"
        />分钟
      </a-form-item>
      <a-form-item label="频次间隔" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number
          style="width: 100px"
          v-model="model.frequencyIntervalTimeLeft"
          :min="1"
          :precision="0"
          placeholder="最小值"
          @blur="changeValue(model.frequencyIntervalTimeLeft, model.frequencyIntervalTimeRight, '频次间隔')"
        />
        ~
        <a-input-number
          style="width: 100px"
          v-model="model.frequencyIntervalTimeRight"
          :min="1"
          :precision="0"
          placeholder="最大值"
          @blur="changeValue(model.frequencyIntervalTimeLeft, model.frequencyIntervalTimeRight, '频次间隔')"
        />分钟
      </a-form-item>
      <a-form-item label="采样耗时" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number
          style="width: 100px"
          v-model="model.samplingTimeLeft"
          :min="1"
          :precision="0"
          placeholder="最小值"
          @blur="changeValue(model.samplingTimeLeft, model.samplingTimeRight, '采样耗时')"
        />
        ~
        <a-input-number
          style="width: 100px"
          v-model="model.samplingTimeRight"
          :min="1"
          :precision="0"
          placeholder="最大值"
          @blur="changeValue(model.samplingTimeLeft, model.samplingTimeRight, '采样耗时')"
        />分钟
      </a-form-item>
      <a-form-item label="粒度" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number
          style="width: 100px"
          v-model="model.particle"
          :min="1"
          :precision="0"
          placeholder="粒度"
          @change="changeParticle"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { itemCategoryApi } from '@/api/quote'
import moment from 'moment'

export default {
  inheritAttrs: false,
  props: {
    cateList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      visible: false,
      form: this.$form.createForm(this),
      model: {},
      changeDisabled: false,
      cateId: null,
      oldCateId: null,
      cateIndex: 0
    }
  },

  methods: {
    moment,
    changeCate (value, key) {
      console.log(value, key)
      const judge = this.validateTime(this.model)
      if (judge === 'true') {
        this.changeDisabled = false
        this.oldCateId = this.cateId
        const index = this.cateList.findIndex((item) => item.id === value)
        this.model = this.cateList[index]
        this.cateIndex = index
        console.log(this.oldCateId, this.cateId, this.cateIndex)
      } else {
        this.changeDisabled = true
        this.cateId = this.oldCateId
        console.log(this.oldCateId, this.cateId, this.cateIndex)
      }
    },
    changeValue (min, max, value) {
      if (!min || !max) {
        this.$message.error(`${value}的最小值与最大值不能为空`)
        this.changeDisabled = true
        return
      }
      if (min && max && min > max) {
        this.$message.error(`${value}最小值应小于等于最大值`)
        this.changeDisabled = true
        return
      }
      const judge = this.validateTime(this.model)
      if (judge === 'true') {
        this.changeDisabled = false
      } else {
        this.changeDisabled = true
      }
    },
    changeParticle (value) {
      console.log(value)
      if (!value) {
        this.$message.error(`粒度不能为空`)
        this.changeDisabled = true
        return
      }
      const judge = this.validateTime(this.model)
      if (judge === 'true') {
        this.changeDisabled = false
      } else {
        this.changeDisabled = true
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    show () {
      this.visible = true
      this.changeDisabled = false
      this.model = {}
      this.cateIndex = 0
      this.cateId = null
      this.oldCateId = null
      if (this.cateList.length > 0) {
        this.model = this.cateList[0]
        this.cateIndex = 0
        this.cateId = this.cateList[0].id
        this.oldCateId = this.cateList[0].id
      }
    },
    handleSubmit () {
      const self = this

      const findIndex = this.cateList.findIndex((item) => self.validateTime(item) === 'false')
      console.log(findIndex)
      if (findIndex !== -1) {
        self.$message.error(`第${findIndex + 1}个，分类名称为：${this.cateList[findIndex].cateName} 需完善数据`)
        return
      }
      itemCategoryApi.copyAddOrEdit(this.cateList).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.$emit('ok')
          this.handleCancel()
        }
      })
    },

    handleCancel () {
      this.visible = false
      this.changeDisabled = false
      this.model = {}
      this.cateIndex = 0
      this.cateId = null
      this.oldCateId = null
    },
    validateTime (model) {
      const self = this
      var {
        periodIntervalTimeLeft,
        periodIntervalTimeRight,
        siteTimeLeft,
        siteTimeRight,
        frequencyIntervalTimeLeft,
        frequencyIntervalTimeRight,
        samplingTimeLeft,
        samplingTimeRight,
        particle
      } = model

      if (
        [
          periodIntervalTimeLeft,
          periodIntervalTimeRight,
          siteTimeLeft,
          siteTimeRight,
          frequencyIntervalTimeLeft,
          frequencyIntervalTimeRight,
          samplingTimeLeft,
          samplingTimeRight,
          particle
        ].some((item, index, array) => {
          return item == '' || item == undefined
        })
      ) {
        self.$message.error(`周期开始间隔、点位距离、频次间隔、采样耗时、粒度均不能为空`)
        return 'false'
      }
      if (periodIntervalTimeLeft > periodIntervalTimeRight) {
        self.$message.error(`周期开始间隔最小值应小于等于最大值`)
        return 'false'
      }

      if (siteTimeLeft > siteTimeRight) {
        self.$message.error(`点位距离最小值应小于等于最大值`)
        return 'false'
      }

      if (frequencyIntervalTimeLeft > frequencyIntervalTimeRight) {
        self.$message.error(`频次间隔最小值应小于等于最大值`)
        return 'false'
      }

      if (samplingTimeLeft > samplingTimeRight) {
        self.$message.error(`采样耗时最小值应小于等于最大值`)
        return 'false'
      }

      if (particle && periodIntervalTimeRight < particle) {
        self.$message.error(`粒度不能大于周期开始间隔最大值`)
        return 'false'
      }
      if (particle && siteTimeRight < particle) {
        self.$message.error(`粒度不能大于点位距离最大值`)
        return 'false'
      }
      if (particle && frequencyIntervalTimeRight < particle) {
        self.$message.error(`粒度不能大于频次间隔最大值`)
        return 'false'
      }
      if (particle && samplingTimeRight < particle) {
        self.$message.error(`粒度不能大于采样耗时最大值`)
        return 'false'
      }
      if (particle && periodIntervalTimeLeft && periodIntervalTimeRight) {
        const arr = []
        for (let k = periodIntervalTimeLeft; k <= periodIntervalTimeRight; k++) {
          arr.push(k)
        }
        const someJudge = arr.some((item) => item % particle === 0)
        if (!someJudge) {
          self.$message.error(`粒度数值有错，请重新输入`)
          return 'false'
        }
      }
      if (particle && siteTimeLeft && siteTimeRight) {
        const arr = []
        for (let k = siteTimeLeft; k <= siteTimeRight; k++) {
          arr.push(k)
        }
        const someJudge = arr.some((item) => item % particle === 0)
        if (!someJudge) {
          self.$message.error(`粒度数值有错，请重新输入`)
          return 'false'
        }
      }
      if (particle && frequencyIntervalTimeLeft && frequencyIntervalTimeRight) {
        const arr = []
        for (let k = frequencyIntervalTimeLeft; k <= frequencyIntervalTimeRight; k++) {
          arr.push(k)
        }
        const someJudge = arr.some((item) => item % particle === 0)
        if (!someJudge) {
          self.$message.error(`粒度数值有错，请重新输入`)
          return 'false'
        }
      }
      if (particle && samplingTimeLeft && samplingTimeRight) {
        const arr = []
        for (let k = samplingTimeLeft; k <= samplingTimeRight; k++) {
          arr.push(k)
        }
        const someJudge = arr.some((item) => item % particle === 0)
        if (!someJudge) {
          self.$message.error(`粒度数值有错，请重新输入`)
          return 'false'
        }
      }
      return 'true'
    }
  }
}
</script>
