<script>
export default {
  name: 'CheckBatchScheduleBaseInfo',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import { computed } from 'vue'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { useDate } from '@/pages/common/compositions/date/useDate'

const props = defineProps({
  modelValue: { required: true, type: Object },
  batchInfo: { required: true, type: Object },
  itemList: { required: false, type: Array, default: () => [] }
})

const emit = defineEmits(['update:modelValue'])
const modelValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
const { moment } = useDate()

const changeReadyTime = (date, dateStr) => {
  console.log(date, dateStr)
  modelValue.value.readyTime = dateStr
}
const checkTime = computed({
  set(value) {
    const [start, done] = value
    modelValue.value.makeBeginTime = moment(start).format('YYYY-MM-DD HH:mm:ss')
    modelValue.value.makeEndTime = moment(done).format('YYYY-MM-DD HH:mm:ss')
  },
  get() {
    const { makeBeginTime, makeEndTime } = modelValue.value
    return makeBeginTime && makeEndTime ? [moment(makeBeginTime), moment(makeEndTime)] : []
  }
})

const getItemNames = (compositeItem) => {
  let itemNames = []
  for (let itemId of compositeItem.split(',')) {
    let items = props.itemList.filter((item) => item.id === Number(itemId))
    // console.log(items)
    if (items.length > 0) {
      itemNames.push(items[0].name)
    }
  }
  return itemNames.join('，')
}

const { baseFormCard } = useFormProps()
</script>
<template>
  <a-card title="基本信息" v-bind="baseFormCard">
    <ul-table :data-source="batchInfo" :hidden-title="true" :hidden-wrap="true">
      <ul-table-column title="检测员">{{ batchInfo?.checkUserName }}</ul-table-column>
      <!-- 如果有compositeItem，说明本批次是复合批次，需要显示所有复合因子 -->
      <template v-if="batchInfo.compositeItem">
        <ul-table-column title="检测内容"> {{ getItemNames(batchInfo.compositeItem) }} </ul-table-column>
      </template>
      <template v-else>
        <ul-table-column title="检测内容"> {{ batchInfo?.cateName }}/{{ batchInfo?.itemName }} </ul-table-column>
      </template>

      <!-- <ul-table-column title="温度">
        <a-input-number disabled v-model="modelValue.wd"></a-input-number>
        <span> ℃</span>
      </ul-table-column>

      <ul-table-column title="湿度">
        <a-input-number
          disabled
          v-model="modelValue.sd"
          :max="100"
          :min="0"></a-input-number>
        <span> %</span>
      </ul-table-column> -->
      <ul-table-column title="准备时间">
        <a-date-picker v-model="modelValue.readyTimeMoment" format="YYYY-MM-DD HH:mm:ss" show-time :allowClear="false" @change="changeReadyTime" />
        <!-- <a-range-picker v-model="checkTime" format="YYYY-MM-DD HH:mm" show-time /> -->
      </ul-table-column>
    </ul-table>
    <slot name="extras"></slot>
  </a-card>
</template>
