'use strict'

// 样品日志类型字典枚举
export const sampleInfoLogTypeEnum = {
  receive: '接样',
  batchSave: '批量新增',
  save: '新增样品',
  storageApply: '申请入库',
  storage: '样品入库',
  applyOutbound: '领样申请',
  outbound: '样品出库',
  reserved: '样品留样',
  destroyApply: '申请销毁',
  destroy: '样品销毁',
  cancelCheck: '取消检测'
}

// 样品日志类型选择项
export const sampleInfoLogTypeOptions = [
  { label: 'receive', value: sampleInfoLogTypeEnum.receive, color: '#1364E6' },
  { label: 'batchSave', value: sampleInfoLogTypeEnum.batchSave, color: '#1364E6' },
  { label: 'save', value: sampleInfoLogTypeEnum.save, color: '#1364E6' },
  { label: 'storageApply', value: sampleInfoLogTypeEnum.storageApply, color: '#e2a442' },
  { label: 'storage', value: sampleInfoLogTypeEnum.storage, color: '#169926' },
  { label: 'applyOutbound', value: sampleInfoLogTypeEnum.applyOutbound, color: '#e2a442' },
  { label: 'outbound', value: sampleInfoLogTypeEnum.outbound, color: '#990001' },
  { label: 'reserved', value: sampleInfoLogTypeEnum.reserved, color: '#169926' },
  { label: 'destroyApply', value: sampleInfoLogTypeEnum.destroyApply, color: '#e2a442' },
  { label: 'destroy', value: sampleInfoLogTypeEnum.destroy, color: '#990001' }
]
