<template>
  <div>
    <a-form :form="form">
      <a-form-item label="所属大类" v-bind="formItemLayout">
        <a-select placeholder="请选择所属大类" v-decorator="['cateType', { rules: rules.cateType }]" @change="onChangeCateType">
          <a-select-option v-for="vo in cateTypeList" :key="vo.itemValue">{{ vo.itemValue }}-{{ vo.itemText }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属分类" v-bind="formItemLayout">
        <a-select allowClear mode="multiple" placeholder="请选择所属分类" v-decorator="['cateIdsArr', { rules: rules.cateIdsArr }]">
          <a-select-option v-for="obj in selectCateList" :key="obj.id + ''">{{ obj.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <!--      <a-form-item label="检测项代码" v-bind="formItemLayout">-->
      <!--        <a-input v-decorator="['itemCode', {rules: rules.itemCode}]"/>-->
      <!--      </a-form-item>-->
      <a-form-item label="检测项名称" v-bind="formItemLayout">
        <a-input v-decorator="['name', { rules: rules.itemName }]" @change="formatter" />
      </a-form-item>
      <a-form-item label="检测项别名" v-bind="formItemLayout">
        <a-input v-decorator="['alias', { rules: rules.itemAlias }]" @change="formatter" />
      </a-form-item>
      <a-form-item label="指标类别" v-bind="formItemLayout">
        <a-select showSearch placeholder="请选择指标类别" :filterOption="filterOption" v-decorator="['itemAttributeType', { rules: rules.itemAttributeType }]">
          <a-select-option v-for="(obj, idx) in itemAttributeTypeList" :key="obj.itemValue">{{ obj.itemText }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="化学式" v-bind="formItemLayout">
        <FormulaInput :value.sync="model.formula" />
      </a-form-item>
      <a-form-item label="定量离子" v-bind="formItemLayout">
        <a-row span="24">
          <a-col span="7">
            <a-radio-group v-model="rationLonicValue" @change="changeRationLonic">
              <a-radio :value="0"> 不涉及 </a-radio>
              <a-radio :value="1"> 涉及 </a-radio>
            </a-radio-group>
          </a-col>
          <a-col span="8" v-if="rationLonicShow">
            <a-input v-decorator="['rationLonic', { rules: rules.rationLonic }]" />
          </a-col>
          <a-col span="9" v-if="rationLonicShow"> &nbsp;&nbsp;&nbsp;<span style="color: red; line-height: 0">多条值的话使用“97/23”隔开</span> </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="辅助离子" v-bind="formItemLayout">
        <a-row span="24">
          <a-col span="7">
            <a-radio-group v-model="assistLonicValue" @change="changeAssistLonic">
              <a-radio :value="0"> 不涉及 </a-radio>
              <a-radio :value="1"> 涉及 </a-radio>
            </a-radio-group>
          </a-col>
          <a-col span="8" v-if="assistLonicShow">
            <a-input v-decorator="['assistLonic', { rules: rules.assistLonic }]" />
            <!-- <input onkeyup="if(isNaN(value))execCommand('undo')" onafterpaste="if(isNaN(value))execCommand('undo')"> -->
          </a-col>
          <a-col span="9" v-if="assistLonicShow"> &nbsp;&nbsp;&nbsp;<span style="color: red; line-height: 0">多条值的话使用“97/23”隔开</span> </a-col>
        </a-row>
      </a-form-item>
      <!--      <a-form-item label="数值类型" v-bind="formItemLayout">-->
      <!--        <a-radio-group v-model="model.itemValueType">-->
      <!--          <a-radio v-for="(vo,idx) in valueTypeList" :key="idx" :value="vo.key">{{ vo.name }}</a-radio>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-item>-->

      <!--      <a-form-item label="单价" v-bind="formItemLayout">-->
      <!--        <a-input-number-->
      <!--          style="width: 160px"-->
      <!--          :min="0"-->
      <!--          :max="99999999"-->
      <!--          :precision="2"-->
      <!--          :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"-->
      <!--          :parser="value => value.replace(/\￥\s?|(,*)/g, '')"-->
      <!--          v-decorator="['price', {rules: rules.itemPrice}]" /> 元-->
      <!--      </a-form-item>-->

      <!--      <a-form-item label="成本价" v-bind="formItemLayout">-->
      <!--        <a-input-number-->
      <!--          style="width: 160px"-->
      <!--          :min="0"-->
      <!--          :max="99999999"-->
      <!--          :precision="2"-->
      <!--          :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"-->
      <!--          :parser="value => value.replace(/\￥\s?|(,*)/g, '')"-->
      <!--          v-decorator="['costPrice', {rules: rules.itemCostPrice}]" /> 元-->
      <!--      </a-form-item>-->
      <a-form-item label="样品代码" v-bind="formItemLayout">
        <a-input v-decorator="['itemSampleCode', { rules: rules.itemSampleCode }]">
          <template #suffix>
            <a-tooltip title="点击生成编号">
              <a-icon type="plus-circle" @click="getItemSampleCode" />
            </a-tooltip>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="排序" v-bind="formItemLayout">
        <a-input-number v-model="model.itemSort" :min="0" :max="9999" />
        <span style="color: red; margin-left: 10px; font-size: 12px">排序按照降序排列，数字越大越靠前</span>
      </a-form-item>
      <a-row>
        <!--        <a-col :span="8">-->
        <!--          <a-form-item label="是分包项" v-bind="checkboxItemLayout">-->
        <!--            <a-checkbox :checked="model.type=='1'" @change="(e)=>{ checkboxOnChange('type', e) }"/>-->
        <!--          </a-form-item>-->
        <!--        </a-col>-->
        <!--        <a-col :span="8">-->
        <!--          <a-form-item label="参与折扣" v-bind="checkboxItemLayout">-->
        <!--            <a-checkbox :checked="model.itemDiscount=='1'" @change="(e)=>{ checkboxOnChange('itemDiscount', e) }"/>-->
        <!--          </a-form-item>-->
        <!--        </a-col>-->
        <a-col :span="8">
          <a-form-item label="需要采样" v-bind="checkboxItemLayout">
            <a-checkbox
              :checked="model.sample == '1'"
              @change="
                (e) => {
                  checkboxOnChange('sample', e)
                }
              "
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="现场检测" v-bind="checkboxItemLayout">
            <a-checkbox
              :checked="model.siteCheck == '1'"
              @change="
                (e) => {
                  checkboxOnChange('siteCheck', e)
                }
              "
            />
          </a-form-item>
        </a-col>
        <a-col :span="8"></a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { itemApi, itemCategoryApi } from '@/api/quote'
import FormulaInput from '@/pages/common/formula/FormulaInput'
import Vue from 'vue'

export default {
  name: 'ItemForm',
  components: {
    FormulaInput
  },
  props: {
    itemList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rationLonicShow: false,
      assistLonicShow: false,
      rationLonicValue: 0,
      assistLonicValue: 0,
      formItemLayout: {
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 16
        }
      },
      checkboxItemLayout: {
        labelCol: {
          span: 12
        },
        wrapperCol: {
          span: 12
        }
      },
      cateType: '',
      model: {},
      defaultModel: { itemValueType: 'common', price: 0.0, costPrice: 0.0 },
      form: this.$form.createForm(this),
      rules: {
        cateType: [{ required: true, message: '请选择所属大类' }],
        cateIdsArr: [{ required: true, message: '请选择所属分类' }],
        itemName: [{ required: true, message: '请输入检测项名称！' }, { min: 1, max: 100, message: '请检测项名称长度在1-100个字符之间！' }, { validator: this.validateItemName }],
        itemAlias: [{ max: 50, message: '检测项别名过长！' }],
        itemAttributeType: [{ required: true, message: '请选择指标类别' }],
        itemPrice: [{ required: true, message: '请输入单价！' }],
        itemCostPrice: [{ required: true, message: '请输入成本价！' }],
        itemCode: [{ max: 18, message: '检测项代码过长！' }],
        itemSampleCode: [{ max: 18, message: '检测项样品代码过长！' }, { validator: this.validateSampleCode }],
        rationLonic: [{ message: '请输入定量离子' }, { validator: this.inputNum }],
        assistLonic: [{ message: '请输入辅助离子' }, { validator: this.inputNum }]
      },
      valueTypeList: [
        { key: 'common', name: '普通值' },
        { key: 'hour', name: '小时值' },
        { key: 'day', name: '日均值' }
      ],
      cateTypeList: [],
      cateList: [],
      selectCateList: [],
      cateGroupCateType: []
    }
  },
  methods: {
    init(param) {
      if (param.id) {
        this.getItem(param.id)
      } else {
        this.model = Object.assign({}, this.defaultModel)
        this.setFormData()
      }
    },
    getItem(id) {
      if (id) {
        itemApi.get({ id: id }).then((res) => {
          if (res.code === 0) {
            var obj = res.data
            console.log('查询', obj)
            if (obj.cateIds) {
              obj.cateIdsArr = obj.cateIds.split(',')
            }
            if (obj.rationLonic) {
              this.rationLonicValue = 1
              this.rationLonicShow = true
            }
            if (obj.assistLonic) {
              this.assistLonicValue = 1
              this.assistLonicShow = true
            }
            this.model = obj
            if (this.model.cateType) {
              this.initCateType(this.model.cateType)
            }
            this.setFormData()
          } else {
            this.$message.error('数据已不存在，请刷新页面后确认！')
          }
        })
      }
    },
    setFormData() {
      this.form.$nextTick(() => {
        this.form.setFieldsValue(this.model)
      })
    },
    save() {
      var self = this
      self.form.validateFields((err, values) => {
        if (!err) {
          var formData = Object.assign({}, self.model, values)

          if (this.rationLonicValue == 0) {
            this.$set(formData, 'rationLonic', '')
          } else if (!formData.rationLonic) {
            this.$message.error('涉及定量离子时，后面输入框不能为空！')
            return
          }
          if (this.assistLonicValue == 0) {
            this.$set(formData, 'assistLonic', '')
          } else if (!formData.assistLonic) {
            this.$message.error('涉及辅助离子时，后面输入框不能为空！')
            return
          }
          // console.log('保存参数', formData)
          formData.cateIds = formData.cateIdsArr.toString()
          delete formData.cateIdsArr
          delete formData.itemClauseList
          var saveApi = itemApi.add
          if (self.model.id) {
            saveApi = itemApi.edit
          }
          saveApi(formData)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(`检测项信息保存成功`)
                this.$emit('msg', 'ok')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((error) => {
              this.$message.error(`服务器发生错误！错误消息为${error}`)
            })
            .finally(() => {
              this.$emit('msg', 'fail')
            })
        } else {
          this.$emit('msg', 'fail')
        }
      })
    },
    checkboxOnChange(name, e) {
      if (name) {
        if (e.target.checked) {
          this.$set(this.model, name, 1)
        } else {
          this.$set(this.model, name, 0)
        }
      }
    },
    getItemSampleCode() {
      const ts = this
      const cateType = ts.model.cateType || ts.form.getFieldValue('cateType')
      if (!cateType) {
        ts.$message.warn('请先选择所属大类！')
        return
      }
      itemApi.getCode({ id: ts.model.id, cateType: cateType }).then((res) => {
        if (res.code === 0) {
          ts.$set(ts.model, 'itemSampleCode', res.data)
          ts.form.setFieldsValue({ itemSampleCode: res.data })
        } else {
          ts.$message.error('获取编号失败，请联系管理员！')
        }
      })
    },
    validateItemName(rule, value, msgCallback) {
      var self = this
      var itemName = (value || '').toString().trim()
      if (itemName) {
        self.form.validateFields(['cateType', 'cateIdsArr'], (errors, values) => {
          if (errors) {
          } else {
            var cateType = values['cateType']
            var cateIds = values['cateIdsArr'].toString()
            itemApi.listItem({ cateType: cateType, cateIds: cateIds, itemName: itemName, page: false }).then((res) => {
              if (res.code === 0) {
                var list = res.data
                if (list && list.length > 0) {
                  if (list.length === 1 && self.model.id == list[0].id) {
                    // 只有一条数据且于当前数据id相同，则是该数据本身
                    msgCallback()
                  } else {
                    var old = list[0]
                    var cateName = self.getCateName(cateIds)
                    msgCallback(`[${old.cateTypeName}->${cateName}]分类中，[${itemName}]已经存在！`)
                  }
                } else {
                  msgCallback()
                }
              } else {
                this.$message.error('数据已不存在，请刷新页面后确认！')
              }
            })
          }
        })
      }
    },
    validateSampleCode(rule, value, msgCallback) {
      var ts = this
      var itemSampleCode = (value || '').toString().trim()
      if (itemSampleCode) {
        itemApi.checkCode({ id: ts.model.id, itemSampleCode: itemSampleCode }).then((res) => {
          if (res.code === 0) {
            var flag = res.data
            if (flag) {
              msgCallback()
            } else {
              msgCallback(`[${itemSampleCode}]样品代码已经存在！`)
            }
          } else {
            this.$message.error('数据已不存在，请刷新页面后确认！')
          }
        })
      } else {
        msgCallback()
      }
    },
    inputNum(rule, value, msgCallback) {
      var ts = this
      var inputNumValue = (value || '').toString().trim()
      if (inputNumValue) {
        let bool = /^[\d\/]+$/.test(inputNumValue)
        console.log(bool)
        if (bool) {
          msgCallback()
        } else {
          this.$message.error('该数值为数字类型！')
        }
      } else {
        this.$message.error('请输入数字！')
      }
    },
    listCateType() {
      var self = this
      itemCategoryApi.listCateType().then((res) => {
        if (res.code === 0) {
          self.cateTypeList = res.data
        }
      })
    },
    onChangeCateType(cateType, option) {
      this.selectCateList = this.cateGroupCateType[cateType] || []
      var allCateIdsArr = []
      this.selectCateList.forEach((cate) => {
        if (cate.id) {
          allCateIdsArr.push(cate.id + '')
        }
      })
      this.form.setFieldsValue({ cateIdsArr: allCateIdsArr })
    },
    initCateType(cateType) {
      this.selectCateList = this.cateGroupCateType[cateType] || []
    },
    listCate() {
      itemCategoryApi.list({}).then((result) => {
        if (result.code === 0) {
          var cateList = result.data
          this.cateList = cateList
          var cateGroupCateType = {}
          if (cateList) {
            cateList.forEach((cate) => {
              if (!cateGroupCateType[cate.cateType]) {
                cateGroupCateType[cate.cateType] = []
              }
              cateGroupCateType[cate.cateType].push(cate)
            })
          }
          this.cateGroupCateType = cateGroupCateType
          if (this.model.cateType) {
            this.initCateType(this.model.cateType)
          }
        }
      })
    },
    getCateName(cateIds) {
      const cateName = []
      const cateIdsArr = cateIds.split(',')
      for (let i = 0; i < cateIdsArr.length; i++) {
        const cate = this.selectCateList.filter((item) => item.id == cateIdsArr[i])[0]
        if (cate) {
          cateName.push(cate.name)
        }
      }
      return cateName.join(' | ')
    },
    formatter(e) {
      var value = e.target.value
      value = value
        .replace(/^\s+|\s+$/g, '')
        .replace(/（/g, '(')
        .replace(/）/g, ')')
      e.target.value = value
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeRationLonic(e) {
      if (this.rationLonicValue == 1) {
        this.rationLonicShow = true
        // console.log("定量涉及",this.model)
      } else {
        this.rationLonicShow = false
        // console.log("定量不涉及",this.model)
      }
    },
    changeAssistLonic(e) {
      if (this.assistLonicValue == 1) {
        this.assistLonicShow = true
        // let form=this.$form.createForm(this)
        // console.log("辅助涉及",)
      } else {
        this.assistLonicShow = false
        let form = this.$form.createForm(this)
        console.log('辅助不涉及', this.model)
      }
    }
  },
  computed: {
    itemAttributeTypeList() {
      return [...Vue.ls.get('item_attribute_type')]
    }
  },
  watch: {},
  mounted() {},
  created() {
    this.$store.dispatch('code/select', 'item_attribute_type')
    this.listCateType()
    this.listCate()
  }
}
</script>
