<template>
  <div style="max-height: 600px; overflow-y:scroll">
    <a-timeline>
      <a-timeline-item :key="index" v-for="(item, index) in list">
        <h3>{{ item.createTime.substring(0, 10) }} 更新日志</h3>
        <div v-html="item.content"></div>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import { versionApi } from '@/api/common'

export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    versionApi.list({ page: true }).then(res => {
      const d = res.data
      for (let i = 0; i < d.length; i++) {
        d[i]['content'] = d[i]['content'].replace(/\\n/g, '')
      }
      this.list = d
    })
  }
}
</script>

<style scoped>
  ::-webkit-scrollbar
  {
    width: 5px;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0,0,0,0.1);
  }
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0,0,0,0.1);
  }
</style>
