<template>
  <a-modal v-bind="modal" v-on="modal">
    <!-- 查询区域 -->
    <a-card v-bind="searchCard" v-if="showSearch">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item label="编号" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="编号模糊查询" v-model.trim.lazy="queryParam.blurId"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="名称" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="名称模糊查询" v-model.trim.lazy="queryParam.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="配置日期" v-bind="formItemLayout">
              <a-range-picker :showTime="false" :style="{width:'100%'}" @change="handleMakeDateChange" @ok="search" format="YYYY-MM-DD"></a-range-picker>
            </a-form-item>
          </a-col>

          <a-col :style="{ textAlign: 'left',marginBottom:'15px' }" v-bind="colProps">
            <a-button-group>
              <a-button @click="search" icon="search" type="primary">查询</a-button>
              <a-button @click="reset" icon="reload">重置</a-button>
            </a-button-group>
            <a @click="toggleSearchStatus = !toggleSearchStatus" style="margin-left: 8px">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
            </a>
          </a-col>
        </a-row>

        <a-row v-bind="rowProps" v-show="toggleSearchStatus">
          <a-col v-bind="colProps">
            <a-form-item label="上级液编号" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="上级液编号模糊查询" v-model.trim.lazy="queryParam.blurFatherId"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="上级液名称" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="上级液名称模糊查询" v-model.trim.lazy="queryParam.fatherName"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="复核状态" v-bind="formItemLayout">
              <a-radio-group @change="search" v-model="queryParam.reviewStatus">
                <a-radio :key="key" :value="key" v-for="(value,key) of checkStandardSolutionReviewStatusDataMap">{{ value.label }}</a-radio>
              </a-radio-group>

            </a-form-item>
          </a-col>

        </a-row>

        <a-row v-bind="rowProps" v-show="toggleSearchStatus">
          <a-col v-bind="colProps">
            <a-form-item label="审核状态" v-bind="formItemLayout">
              <a-radio-group @change="search" v-model="queryParam.checkStatus">
                <a-radio :key="key" :value="key" v-for="(value,key) of checkStandardSolutionCheckStatusDataMap">{{ value.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

        </a-row>

      </a-form>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <check-standard-solution-table v-bind="customTable" :select-type="selectType" @selectedRows="handleSelectedRows"></check-standard-solution-table>
    </a-card>

  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import { withComponentList } from '@/pages/common/mixins/list'

import { checkStandardSolutionCheckStatusDataMap, checkStandardSolutionReviewStatusDataMap } from '@/api/checkStandardSolution/checkStandardSolutionConstant'

export default {
  mixins: [fullModal, withComponentList],
  name: 'CheckStandardSolutionModal',

  props: {

    selectType: {
      type: String,
      required: false,
      default: 'radio',
      validator (value) {
        return [null, '', undefined, 'radio', 'checkbox'].includes(value)
      }
    },

    showSearch: { type: Boolean, required: false, default: false },
    modalType: { type: String, required: false, default: 'fullModal' },
    modalWidth: { type: [Number, String], required: false, default: 800 },
    modalTitle: { type: String, required: false, default: '标液配置' },
    checkStatus: { type: String, required: false, default: null }

  },

  data () {
    return {
      checkStandardSolutionReviewStatusDataMap,
      checkStandardSolutionCheckStatusDataMap,

      selectedRows: [],

      queryParam: {
        name: null,
        blurFatherId: null,
        fatherName: null,
        reviewStatus: null,
        checkStatus: this.checkStatus,
        makeDateBegin: null,
        makeDateEnd: null
      },

      customModal: {
        visible: false,
        title: this.modalTitle,
        bodyStyle: { background: '#F0F2F5' },
        destroyOnClose: true,

        show: () => {
          this.customModal.visible = true
        },

        ok: () => {
          this.$emit('success', this.selectedRows)
          this.customModal.cancel()
        },

        cancel: () => {
          this.customModal.visible = false
          this.$emit('cancel')
        }
      },

      fullModal: {
        title: this.modalTitle,
        bodyStyle: { background: '#F0F2F5' },

        show: () => {
          this.fullModal.visible = true
        },

        ok: () => {
          this.$emit('success', this.selectedRows)
          this.fullModal.cancel()
        },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        }
      }
    }
  },

  computed: {
    modal () {
      return this.modalType === 'fullModal' ? this.fullModal : this.customModal
    }
  },

  methods: {

    // 配置日期改变事件
    handleMakeDateChange (value) {
      this.queryParam.makeDateBegin = value[0] ? this.dateFormat(value[0]) : null
      this.queryParam.makeDateEnd = value[1] ? this.dateFormat(value[1]) : null
    },

    // 查询
    search () {
      this.$nextTick(() => {
        this.$refs[this.customTable.ref].search()
      })
    },

    // 重置
    reset () {
      this.$nextTick(() => {
        this.$refs[this.customTable.ref].reset()
      })
    },

    // 重载
    reload () {
      this.$nextTick(() => {
        this.$refs[this.customTable.ref].reload()
      })
    },

    // 显示组件
    show () {
      this.modal.show()
    },

    handleSelectedRows (data) {
      this.selectedRows.splice(0, this.selectedRows.length, ...(data || []))
    }
  }
}
</script>
