<template>
  <a-modal id="check-form" v-bind="fullModal" v-on="fullModal">
    <div class="utabs" style="height: 100%">
      <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
        <template v-for="obj of templateList">
          <a-tab-pane :closable="false" :key="obj.templateCode" :tab="obj.templateName" style="height: 100%" v-if="curIdx === obj.templateCode">
            <a-row style="height:100%;">
              <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
            </a-row>
          </a-tab-pane>
        </template>
      </a-tabs>
    </div>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { anonProjectApi } from '@/api/project'
import fullModal from '@/pages/common/mixins/modal/full'
import { debounce } from 'lodash'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'Record',
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null
    },
    solutionId: {
      type: [String, Number],
      required: false,
      default: null
    },
    itemName: {
      type: String,
      required: false,
      default: ''
    },
    curveRecord: {
      type: Object,
      required: false,
      default: null
    },
    curveTypeRecord: {
      type: Object,
      required: false,
      default: null
    },
    templateType: {
      type: String,
      required: false,
      default: ''
    }

  },
  data () {
    return {
      fullModal: Object.assign({}, fullModalData, {
        title: '原始记录表',
        closable: true,
        show: () => {
          this.fullModal.visible = true
        },
        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        }
      }),
      templateList: [],
      curIdx: null,
      templateParam: {
        page: false,
        templateCode: `standard_curve_detailed${this.templateType}`
      }
    }
  },
  computed: {
    iframeSrc: function () {
      if (this.templateList && this.templateList.length > 0) {
        const templist = this.templateList.filter(item => item.templateCode === this.curIdx)
        const template = templist.length > 0 ? templist[0] : null
        if (template !== null) {
          if (this.curveRecord !== null) {
            const fileName = '标准曲线校准记录表'
            return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&_n=${fileName}&curveId=${this.curveRecord.id}`
          } else if (this.curveTypeRecord !== null) {
            const fileName = '标准曲线配置记录表'
            return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&_n=${fileName}&curveTypeId=${this.curveTypeRecord.id}`
          } else {
            const fileName = '标准曲线配置统计表'
            return `${fileTemplateUreportApi.preview}?_u=${template.templateFile}&_n=${fileName}`
          }
        }
      }
      return ''
    }
  },
  created () {
    this.listFileTemplate()
  },
  mounted () {
    this.$nextTick(() => { // 页面渲染完成后的回调I
      this.iframeStyle = { height: document.body.clientHeight - 360 + 'px' }
      // 定义iframe 所需要的事件
      this.defineIframeFun()
    })
  },
  beforeDestroy () {
    // 销毁 对象 防止 self 出现内存泄漏
    this.destroyDefineIframeFun()
    // 销毁事件监听
    window.removeEventListener('resize', this.updateIframeHeight)
  },
  methods: {
    updateIframeHeight: debounce(function () {
      const height = document.body.clientHeight - 360 + 'px'
      this.iframeStyle.height = height
    }, 500, { leading: false, trailing: true }),
    // 定义Iframe 方法
    defineIframeFun () {
      window.toCurveView = this.toCurveView
      window.toCurveVerifyView = this.toCurveVerifyView
    },

    toCurveView (projId) {
      // this.$router.push({ name: 'bo-proj-details', params: { type: 'project', id: projId } })
    },

    toCurveVerifyView (projId) {
      // this.$router.push({ name: 'bo-proj-details', params: { type: 'project', id: projId } })
    },

    // 销毁定义的方法
    destroyDefineIframeFun () {
      window.toCurveView = null
      window.toCurveVerifyView = null
    },
    listFileTemplate () {
      anonProjectApi.listFileTemplate(this.templateParam).then(result => {
        if (result.code === 0) {
          this.templateList = result.data
          if (this.curIdx === null) {
            this.curIdx = this.templateList[0].templateCode
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>

<style scoped lang="less">
  .utabs {
    .archive {
      margin: 0px 5px;
      border: 1px solid #91d5ff;
      background-color: #e6f7ff;
      padding: 8px;
      border-radius: 4px;
    }

    /deep/ .ant-tabs-content {
      height : 100%;
    }
  }
</style>
