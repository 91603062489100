// 试剂类目管理中常量
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 试剂等级枚举
export const reagentCateGradeEnum = {
  // 一级品
  one: '1',
  // 二级品
  two: '2',
  // 三级品
  three: '3',
  // 四级品
  four: '4'
}

//  试剂等级状态选择项
export const reagentCateGradeOptions = [
  { label: '一级品 【GR】', value: reagentCateGradeEnum.one, color: 'green' },
  { label: '二级品 【AR】', value: reagentCateGradeEnum.two, color: 'red' },
  { label: '三级品 【CP】', value: reagentCateGradeEnum.three, color: 'blue' },
  { label: '四级品 【LR】', value: reagentCateGradeEnum.four, color: 'orange' }
]

export const reagentCateGradeMap = tranOptionsToMap(reagentCateGradeOptions)
export const reagentCateGradeDataMap = tranOptionsToDataMap(reagentCateGradeOptions)

// 试剂处理方式枚举
export const reagentCateMakeTypeEnum = {
  // 二次处理
  need: true,
  // 直接使用
  notNeed: false
}

//  试剂处理方式状态选择项
export const reagentCateMakeTypeOptions = [
  { label: '二次处理', value: reagentCateMakeTypeEnum.need, color: 'red' },
  { label: '直接使用', value: reagentCateMakeTypeEnum.notNeed, color: 'green' }
]

export const reagentCateMakeTypeMap = tranOptionsToMap(reagentCateMakeTypeOptions)
export const reagentCateMakeTypeDataMap = tranOptionsToDataMap(reagentCateMakeTypeOptions)

// 试剂形状枚举
export const reagentCateSolutionTypeEnum = {
  // 液体
  liquid: true,
  // 固体
  solid: false
}

//  试剂形状状态选择项
export const reagentCateSolutionTypeOptions = [
  { label: '液体', value: reagentCateSolutionTypeEnum.liquid, color: 'blue' },
  { label: '固体', value: reagentCateSolutionTypeEnum.solid, color: 'green' }
]

export const reagentCateSolutionTypeMap = tranOptionsToMap(reagentCateSolutionTypeOptions)
export const reagentCateSolutionTypeDataMap = tranOptionsToDataMap(reagentCateSolutionTypeOptions)

// 试剂有效期单位枚举
export const reagentCateValidDateUnitEnum = {
  // 天
  day: 'd',
  // 小时
  hour: 'h',
  // 分钟
  minute: 'm'

}

//  试剂有效期单位选择项
export const reagentCateValidDateUnitOptions = [
  { label: '天', value: reagentCateValidDateUnitEnum.day, color: 'blue' },
  { label: '小时', value: reagentCateValidDateUnitEnum.hour, color: 'blue' },
  { label: '分钟', value: reagentCateValidDateUnitEnum.minute, color: 'blue' }
]

export const reagentCateValidDateUnitMap = tranOptionsToMap(reagentCateValidDateUnitOptions)
export const reagentCateValidDateUnitDataMap = tranOptionsToDataMap(reagentCateValidDateUnitOptions)

// 试剂有效期单位枚举
export const reagentCateMaterialTypeEnum = {
  // 试剂
  cate: 'cate',
  // 原料
  material: 'material'

}

//  试剂有效期单位选择项
export const reagentCateMaterialTypeOptions = [
  { label: '试剂', value: reagentCateMaterialTypeEnum.cate, color: 'green' },
  { label: '原料', value: reagentCateMaterialTypeEnum.material, color: 'blue' }
]

export const reagentCateMaterialTypeMap = tranOptionsToMap(reagentCateMaterialTypeOptions)
export const reagentCateMaterialTypeDataMap = tranOptionsToDataMap(reagentCateMaterialTypeOptions)

export const reagentStatusEnum = {
  // 未复核
  unReview: '0',
  // 复核中
  reviewing: '1',
  // 已复核
  reviewed: '2',
  // 退回
  back: '3'
}

//  试剂等级状态选择项
export const reagentStatusOptions = [
  { label: '未复核', value: reagentStatusEnum.unReview, color: 'red' },
  { label: '复核中', value: reagentStatusEnum.reviewing, color: 'blue' },
  { label: '已复核', value: reagentStatusEnum.reviewed, color: 'green' },
  { label: '退回', value: reagentStatusEnum.back, color: 'orange' }
]

export const reagentStatusMap = tranOptionsToMap(reagentStatusOptions)
export const reagentStatusDataMap = tranOptionsToDataMap(reagentStatusOptions)
