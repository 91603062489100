const noActionColumn = [
  { title: '使用液类型名称', dataIndex: 'name', sorter: true, scopedSlots: { customRender: 'name' } },
  { title: '浓度', dataIndex: 'purity', sorter: true, scopedSlots: { customRender: 'purity' } },
  { title: '有效期', dataIndex: 'validDate', sorter: true, scopedSlots: { customRender: 'validDate' } },
  { title: '上级液', dataIndex: 'parentReagentCateName', scopedSlots: { customRender: 'parentReagentCateName' } },
  { title: '数量', dataIndex: 'num', scopedSlots: { customRender: 'num' } },
  { title: '截至时间', dataIndex: 'validTime', scopedSlots: { customRender: 'validTime' } },
  { title: '有效库存量', dataIndex: 'validVolume', scopedSlots: { customRender: 'validVolume' } },
  // { title: '涉及方法数量', dataIndex: 'num', scopedSlots: { customRender: 'num' } },
  { title: '配置人', dataIndex: 'updateUserName', align: 'center', scopedSlots: { customRender: 'updateUserName' } }
]

const defaultColumn = [...noActionColumn,
  { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } }
]

export {
  noActionColumn as checkStandardSolutionTypeNoActionColumn,
  defaultColumn as checkStandardSolutionTypeDefaultColumn
}

export default defaultColumn
