<template>
    <a-modal
      :title="title"
      width="1200px"
      :visible="visible"
      :centered="true"
      :bodyStyle="bodyStyle"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button type="primary" @click="handleCancel">返回</a-button>
      </template>
      <template v-if="type == 1">
        <span style="font-size: 16px">使用年份：</span>
        <a-select
          v-model="year"
          style="width: 150px"
          placeholder="请选择使用年份"
          @change="handleChange"
        >
          <a-select-option v-for="item in years" :key="item">{{ item }}</a-select-option>
        </a-select>
        <a-divider/>
      </template>
      <iframe ref="iframe" :src="ureportUrl" class="iframe" :style="iframeStyle"/>
    </a-modal>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'

export default {
  name: 'recordModal',
  components: {
    fileTemplateUreportApi,
    fileTemplateUreportTypeEnum
  },
  data () {
    return {
      title: '',
      visible: false,
      type: '',
      paramOne: '',
      paramTwo: '',
      year: undefined,
      years: [],
      bodyStyle: {
        height: '700px'
      },
      templateCode: ['template_device_receive_and_return_record', 'template_device_use_record'],
      templateMap: new Map(),
      ureportUrl: '',
      iframeStyle: {
        width: '100%',
        height: '100%',
        border: 'none'
      }
    }
  },
  created () {
    this.templateMap.clear()
    const queryParam = { templateType: fileTemplateUreportTypeEnum.other, page: false }
    fileTemplateUreportApi.list(queryParam).then(res => {
      if (res.code === 0) {
        res.data.forEach(item => {
          this.templateMap.set(item.templateCode, item.id + ',' + item.templateFile)
        })
      } else {
        this.$message.error(res.msg)
      }
    })

    const year = new Date().getFullYear()
    for (let i = 0; i < 5; i++) {
      this.years.push(year - i)
    }
  },
  methods: {
    init (materialIds, type) {
      this.title = type === 0 ? '领用归还记录' : '使用记录'
      this.visible = true
      this.year = new Date().getFullYear()
      this.type = type
      this.paramOne = '&materialIds=' + materialIds
      this.paramTwo = type === 0 ? '' : '&year=' + this.year
      this.loadData(type)
    },
    handleChange () {
      this.paramTwo = '&year=' + this.year
      this.loadData(1)
    },
    loadData (type) {
      const templateId = this.templateMap.get(this.templateCode[type]).split(',')[0]
      const templateName = this.templateMap.get(this.templateCode[type]).split(',')[1]
      this.ureportUrl = fileTemplateUreportApi.preview + '?_u=' + templateName + '&templateId=' + templateId + this.paramOne + this.paramTwo
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>
