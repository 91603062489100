import '@/assets/less/common/form.less'
import {colSpanMap} from '@/pages/common/mixins/form/form'

export const defaultData = {
  data () {
    return {
      /// 是否显示更多搜索
      toggleSearchStatus: false,

      // 当前列数
      currentColSize: 4,

      // 加载引用的KEY，每次加载过程中会自动切换，从而达到刷新组件的目的
      loadKey: false,

      /// 搜索表单配置
      searchCard: {
        id: 'searchCard',
        title: null,
        bordered: false,
        hoverable: true,
        type: 'inner',
        style: { marginBottom: '24px' },
        headStyle: {},
        bodyStyle: { padding: '16px' },
        class: 'card-search'
      },

      /// 搜索表单配置
      searchForm: {
        class: ''
      },

      moreParam: false,
      /// 行配置
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },

      colSpanMap,

      actionColProps: {
        offset: 0,
        pull: 0,
        push: 0,
        style: { textAlign: 'left', marginBottom: '16px' },
        xxl: { span: 6 },
        xl: { span: 8 },
        md: { span: 12 },
        sm: { span: 24 },
        xs: { span: 24 }
      },

      /// 表单项配置
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 4 },
          xs: { span: 4 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 20 },
          xs: { span: 20 }
        }
      }
    }
  }
}
export default {
  mixins: [defaultData],

  computed: {
    // 列配置
    colProps () {
      const defaultProps = { offset: 0, pull: 0, push: 0, style: { textAlign: 'left' } }
      return Object.assign({}, defaultProps, this.colSpanMap[this.currentColSize])
    }
  },

  methods: {

    /// 查询
    search () {
      this.loadKey = !this.loadKey
      this.$refs.table.refresh(true)
    },

    /// 重置
    reset () {
      const defaultParam = this.$options.data.bind(this)().queryParam
      Object.assign(this.queryParam, defaultParam)
      this.search()
    },

    /// 重新加载
    reload () {
      this.loadKey = !this.loadKey
      this.$refs.table.refresh(false)
    },

    // 当前组件承载的节点
    getCurrentDocument () {
      return this.$el
    }
  }
}
