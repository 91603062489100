import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 制样申请状态 字典枚举
export const makeSampleInfoStatusEnum = {
  // 待制样
  pending: '0',
  // 已制样
  done: '1',
  // 待出库
  pickupApply: '2',
  // 已出库
  outbound: '3',
  // 已入库 在库
  storage: '4'

}

//  制样申请状态 选择项
export const makeSampleInfoStatusOptions = [
  { label: '待制样', value: makeSampleInfoStatusEnum.pending, color: 'red' },
  { label: '已制样', value: makeSampleInfoStatusEnum.done, color: 'green' },
  { label: '待出库', value: makeSampleInfoStatusEnum.pickupApply, color: 'orange' },
  { label: '已出库', value: makeSampleInfoStatusEnum.outbound, color: 'blue' },
  { label: '已入库', value: makeSampleInfoStatusEnum.storage, color: 'green' }
]

export const makeSampleInfoStatusMap = tranOptionsToMap(makeSampleInfoStatusOptions)
export const makeSampleInfoStatusDataMap = tranOptionsToDataMap(makeSampleInfoStatusOptions)
