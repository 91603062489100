<template>
<a-modal
    title="预览报价单"
    width="870px"
    :destroyOnClose="true"
    :visible="visible"
    @cancel="handleCancel"
    :footer="null">
    <div style="width: 750px;height: 700px; margin: 0 auto">
      <iframe :src="url" frameborder="0" style="width: 750px; height: 600px;" />
      <form id="myForm" method="get" v-if="showBtn">
        <input type="hidden" id="quoteId" name="id" />
        <input type="hidden" id="downType" name="type" />
        <input type="hidden" id="qrcode" name="qrcode" />
        <a-button
          v-action:download:quote
          style="width: 45%; margin-top: 10px;"
          type="primary"
          class="login-button"
          @click="downFile('word')">下载报价单(word)
        </a-button>
        <a-button
          v-action:download:quote-pdf
          style="width: 45%; margin-top: 10px; float: right"
          type="primary"
          class="login-button"
          @click="downFile('pdf')">下载报价单(pdf)
        </a-button>
      </form>
    </div>
  </a-modal>
</template>

<script>
import { commonApi } from '@/api/quote'

export default {
  name: 'QuoteDownload',
  data () {
    return {
      showBtn: false,
      visible: false,
      quoteId: 0,
      url: ''
    }
  },
  watch: {
    quoteId: {
      handler (newValue) {
        this.url = commonApi.downloadQuote + '?type=html&id=' + newValue
      },
      immediate: true
    }
  },
  methods: {
    show (quoteId, status) {
      this.quoteId = quoteId
      this.showBtn = true
      // if (status == 2 || status == 3) {
      //   this.showBtn = true
      // } else {
      //   this.showBtn = false
      // }
      this.visible = true
    },
    downFile (type) {
      var href = window.location.href
      href = href.substr(0, href.indexOf('#'))
      var qrcode = `${href}#/cloud-quote/plan-quote-view?quoteId=${this.quoteId}`
      document.getElementById('myForm').action = commonApi.downloadQuote
      document.getElementById('quoteId').value = this.quoteId
      document.getElementById('qrcode').value = qrcode
      document.getElementById('downType').value = type
      document.getElementById('myForm').submit()
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>
