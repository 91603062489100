<template>
  <div>
    <a-row>
      <a-col span="3" style="margin-right: 120px">
        <div class="ahmux-sub-title" style="width: 120px;">
          实验室物品申领
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ record.userName }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ record.dateOfPurUse }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <td class="tdLabel">使用地点</td>
          <td>
            <a-input v-model="record.useLocation" :disabled="disabled"></a-input>
          </td>
          <td class="tdLabel"><span style="color: red; padding-top: 5px">* </span>申领原因</td>
          <td>
            <a-input v-model="record.purUseReason" :disabled="disabled"></a-input>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">备注</td>
          <td>
            <a-input v-model="record.purUseDesc" :disabled="disabled" style="width: 266%"></a-input>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">申领物资</td>
          <td colspan="3">
            <a-table
              ref="table"
              key="id"
              :columns="columns"
              :dataSource="materialList"
              :pagination="false"
            >
            </a-table>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import { Get, Post, resPurUseApi } from '@/api/quote'

export default {
  name: 'ResLabMaterialApplyModal',
  components: {
    Get,
    Post,
    resPurUseApi
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      disabled: false,
      record: {},
      materialList: [],
      columns: [
        { title: '物品类别', dataIndex: 'cateName', key: 'cateName', width: '200px' },
        { title: '物品名称', dataIndex: 'materialName', key: 'materialName', width: '250px' },
        { title: '规格型号', dataIndex: 'speType', key: 'speType', width: '100px' },
        { title: '浓度', dataIndex: 'purity', key: 'purity', width: '100px' },
        { title: '使用数量', dataIndex: 'numOfUse', key: 'numOfUse', width: '100px' },
        { title: '单位', dataIndex: 'unitName', key: 'unitName', width: '100px' }
      ]
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.loadData()
        }
      }
    }
  },
  methods: {
    init (record) {
      record.useLocation = '实验室内部'
      this.record = record
      this.materialList = record.goodsArray
    },
    loadData () {
      this.disabled = true
      Get(resPurUseApi.getLabMaterialApplyInfo, { id: this.recordId }).then(res => {
        if (res.code === 0) {
          const labMaterialApplyInfo = res.data.labMaterialApplyInfo
          const materialList = res.data.materialList
          this.record.userName = labMaterialApplyInfo.purUseRealname
          this.record.dateOfPurUse = labMaterialApplyInfo.purUseDate
          this.record.useLocation = labMaterialApplyInfo.useLocation
          this.record.purUseReason = labMaterialApplyInfo.purUseReason
          this.record.purUseDesc = labMaterialApplyInfo.purUseRemark
          this.materialList = materialList
        }
      })
    },
    handleSubmit () {
      if (!this.record.purUseReason) {
        this.$message.error('申领原因不能为空！')
        this.$emit('changeStatus',false)
        return
      }
      this.record.apply = true
      this.$emit('ok', this.record)
    }
  }
}
</script>