
const sendStatusDict = { '0': '待发', '1': '已邮寄', '2': '已取', '3': '审批中', '4': '审批驳回' }

const reportReceiveDict = { '1': '邮寄', '2': '自取', '9': '扫描件' }

const reportReceiveList = [{ id: '1', name: '邮寄' }, { id: '2', name: '自取' }, { id: '9', name: '扫描件' }] //, { id: '9', name: '其他' }

export {
  sendStatusDict,
  reportReceiveDict,
  reportReceiveList
}
