<template>
  <div>
    <a-row>
      <a-col span="7">
        <div class="ahmux-sub-title" style="width: 150px;">
          岗位能力确认信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ postAbilityInfo.name }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ postAbilityApplyInfo.createTime }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th colspan="2">岗位名称</th>
          <td colspan="1">{{ postAbilityInfo.post }}</td>
          <th colspan="2">岗位性质</th>
          <td colspan="2">
            <template v-if="postAbilityInfo.postType === '1'">
              管理岗位
            </template>
            <template v-if="postAbilityInfo.postType === '2'">
              技术岗位
            </template>
            <template v-if="postAbilityInfo.postType === '3'">
              工勤岗位
            </template>
          </td>
        </tr>
        <tr>
          <th colspan="2">拟入职人员</th>
          <td colspan="1">{{ postAbilityInfo.name }}</td>
          <th colspan="2">年龄</th>
          <td colspan="2">{{ postAbilityInfo.age }}</td>
        </tr>
        <tr>
          <th rowspan="6" colspan="1">任职条件</th>
          <td rowspan="1" colspan="1">学历条件</td>
          <td rowspan="1" colspan="1">
            <a-textarea
            :value="postAbilityInfo.education"
            :disabled="true">
            </a-textarea>
          </td>
          <th rowspan="6" colspan="1">能力确认</th>
          <td rowspan="1" colspan="1">
            <a-radio-group v-model="postAbilityApplyInfo.eduLevelConfirm" :disabled="disabled">
              <a-radio :style="radioStyle" :value="0">
                达到
              </a-radio>
              <a-radio :style="radioStyle" :value="1">
                未达到
              </a-radio>
            </a-radio-group>
          </td>
          <th rowspan="6" colspan="1">能力证明描述</th>
          <td rowspan="1" colspan="1">
            <a-textarea
              v-model="postAbilityApplyInfo.eduLevelDescription"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <td rowspan="1" colspan="1">工作经验</td>
          <td rowspan="1" colspan="1">
            <a-textarea
              :value="postAbilityInfo.workExperience"
              :disabled="true">
            </a-textarea>
          </td>
          <td rowspan="1" colspan="1">
            <a-radio-group v-model="postAbilityApplyInfo.workExpConfirm" :disabled="disabled">
              <a-radio :style="radioStyle" :value="0">
                达到
              </a-radio>
              <a-radio :style="radioStyle" :value="1">
                未达到
              </a-radio>
            </a-radio-group>
          </td>
          <td rowspan="1" colspan="1">
            <a-textarea
              v-model="postAbilityApplyInfo.workExpDescription"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <td rowspan="1" colspan="1">专业知识</td>
          <td rowspan="1" colspan="1">
            <a-textarea
              :value="postAbilityInfo.majorKnowledge"
              :disabled="true">
            </a-textarea>
          </td>
          <td rowspan="1" colspan="1">
            <a-radio-group v-model="postAbilityApplyInfo.proKnowledgeConfirm" :disabled="disabled">
              <a-radio :style="radioStyle" :value="0">
                达到
              </a-radio>
              <a-radio :style="radioStyle" :value="1">
                未达到
              </a-radio>
            </a-radio-group>
          </td>
          <td rowspan="1" colspan="1">
            <a-textarea
              v-model="postAbilityApplyInfo.proKnowledgeDescription"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <td rowspan="1" colspan="1">职称要求</td>
          <td rowspan="1" colspan="1">
            <a-textarea
              :value="postAbilityInfo.professionalTitle"
              :disabled="true">
            </a-textarea>
          </td>
          <td rowspan="1" colspan="1">
            <a-radio-group v-model="postAbilityApplyInfo.titleReqConfirm" :disabled="disabled">
              <a-radio :style="radioStyle" :value="0">
                达到
              </a-radio>
              <a-radio :style="radioStyle" :value="1">
                未达到
              </a-radio>
            </a-radio-group>
          </td>
          <td rowspan="1" colspan="1">
            <a-textarea
              v-model="postAbilityApplyInfo.titleReqDescription"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <td rowspan="1" colspan="1">岗位资质要求</td>
          <td rowspan="1" colspan="1">
            <a-textarea
              :value="postAbilityInfo.postQualification"
              :disabled="true">
            </a-textarea>
          </td>
          <td rowspan="1" colspan="1">
            <a-radio-group v-model="postAbilityApplyInfo.qualificationConfirm" :disabled="disabled">
              <a-radio :style="radioStyle" :value="0">
                达到
              </a-radio>
              <a-radio :style="radioStyle" :value="1">
                未达到
              </a-radio>
            </a-radio-group>
          </td>
          <td rowspan="1" colspan="1">
            <a-textarea
              v-model="postAbilityApplyInfo.qualificationDescription"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <td rowspan="1" colspan="1">其它培训要求</td>
          <td rowspan="1" colspan="1">
            <a-textarea
              :value="postAbilityInfo.otherTraining"
              :disabled="true">
            </a-textarea>
          </td>
          <td rowspan="1" colspan="1">
            <a-radio-group v-model="postAbilityApplyInfo.otherReqConfirm" :disabled="disabled">
              <a-radio :style="radioStyle" :value="0">
                达到
              </a-radio>
              <a-radio :style="radioStyle" :value="1">
                未达到
              </a-radio>
            </a-radio-group>
          </td>
          <td rowspan="1" colspan="1">
            <a-textarea
              v-model="postAbilityApplyInfo.otherReqDescription"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <th colspan="1">职责权限</th>
          <td colspan="6">
            <a-textarea
              :value="postAbilityInfo.dutyAndAuthority"
              :disabled="true">
            </a-textarea>
          </td>
        </tr>
        <tr>
          <th colspan="1">相关证明材料</th>
          <td colspan="6">
            <div style="width: 20%">
              <a-upload
                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                :action="uploadUrl"
                :fileList="fileList"
                :headers="tokenHeader"
                class="upload-list-inline"
                :remove="handleRemove"
                :multiple="true"
                @change="handleChange"
                :disabled="disabled"
              >
                <a-button><a-icon type="upload"/> 点击上传</a-button>
              </a-upload>
            </div>
          </td>
        </tr>
        <tr>
          <th colspan="1">评价结论</th>
          <td colspan="6">
            <a-textarea
              v-model="postAbilityApplyInfo.conclusion"
              :disabled="disabled">
            </a-textarea>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import { Get, resStaffApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'

export default {
  name: 'StaffPostAbilityForm',
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      disabled: false,
      postAbilityInfo: {},
      postAbilityApplyInfo: {},
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.viewFile,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      fileList: [],
      fileAppendix: []
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.loadData()
        }
      }
    }
  },
  methods: {
    loadData (id) {
      const param = {
        id: this.recordId
      }
      if (id) {
        param.id = id
      }
      Get(resStaffApi.getPostAbilityInfo, param).then(res => {
        if (res.code === 0) {
          this.postAbilityInfo = res.data.postAbilityInfo
          this.postAbilityApplyInfo = res.data.postAbilityApplyInfo
          if (this.postAbilityApplyInfo.eduLevelConfirm === null) this.postAbilityApplyInfo.eduLevelConfirm = 0
          if (this.postAbilityApplyInfo.workExpConfirm === null) this.postAbilityApplyInfo.workExpConfirm = 0
          if (this.postAbilityApplyInfo.proKnowledgeConfirm === null) this.postAbilityApplyInfo.proKnowledgeConfirm = 0
          if (this.postAbilityApplyInfo.titleReqConfirm === null) this.postAbilityApplyInfo.titleReqConfirm = 0
          if (this.postAbilityApplyInfo.qualificationConfirm === null) this.postAbilityApplyInfo.qualificationConfirm = 0
          if (this.postAbilityApplyInfo.otherReqConfirm === null) this.postAbilityApplyInfo.otherReqConfirm = 0
          this.processingFileList(res.data.fileList)
          this.disabled = this.postAbilityApplyInfo.status !== 1
        }
      })
    },
    processingFileList (fileList) {
      if (fileList && fileList.length > 0) {
        this.fileAppendix = []
        this.fileList = []
        fileList.forEach(item => {
          const file = {
            uid: item.id,
            fileUrl: item.fileUrl,
            fileName: item.fileName
          }
          this.fileAppendix.push(file)

          const showFile = {
            uid: item.id,
            name: item.fileName,
            status: 'done',
            url: this.viewUrl + item.fileUrl
          }
          this.fileList.push(showFile)
        })
      }
    },
    handleRemove (info) {
      if (this.disabled === false) {
        this.fileAppendix = this.fileAppendix.filter(file => file.uid !== info.uid)
        this.fileList = this.fileList.filter(r => r.uid !== info.uid)
      }
    },
    handleChange (info) {
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          const fileUrl = info.file.response.data.fileUrl
          const fileName = info.file.response.data.fileName
          info.file.thumbUrl = this.viewUrl + fileUrl
          info.file.url = this.viewUrl + fileName
          this.fileAppendix.push({ uid: info.file.uid, fileUrl: fileUrl, fileName: fileName, staffFileType: this.staffFileType })
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    }
  }
}
</script>
