<template>
  <div>
    <a-tag v-for="(file, fileIdx) in fileList" :key="fileIdx" style="margin-top: 5px;margin-bottom: 5px">
      <a :href="`${downLoadById}?id=${file.id}`">{{ file.fileName }}</a>
    </a-tag>
  </div>
</template>

<script>
import { sysCommonApi } from '@/api/common'
export default {
  name: 'FileList',
  props: {
    fileList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      viewUrl: sysCommonApi.view,
      downLoad: sysCommonApi.downLoad,
      downLoadById: sysCommonApi.downLoadById
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
