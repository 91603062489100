<template>
  <a-modal id="review-form" v-bind="fullModal" v-on="fullModal">
    <CheckTaskBatchOriginalRecord :batch-id="batchId" />

    <a-drawer v-bind="reviewDrawer" v-on="reviewDrawer">
      <div>
        <a-row align="middle">
          <a-col :span="4" class="mxtable-title">复核</a-col>
        </a-row>
        <table class="mxtable">
          <tr>
            <th rowspan="5">复核事项</th>
            <td class="tdLabel">复核时限</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td class="tdLabel">复核内容</td>
            <td colspan="3">
              <p>（1） 分析人员是否持证上岗；</p>
              <p>（2） 仪器设各是否检定/校准并在有效期内：</p>
              <p>（3） 分析方法选择是否满足采測分离相关技术要求、是否通过资质认定；</p>
              <p>（4） 环境条件、实验试剂质量是否符合分析方法要求；</p>
              <p>（5） 确认样品标签清晰完整，核定样品编码等信息；</p>
              <p>（6） 样品分析的各环节是否符合标准规范和采测分离相关技术要求；</p>
              <p>（7） 样品分析是否在水样保存有效时间内完成；</p>
              <p>（8） 原始记录的填写是否真实、准确、完整、规范：</p>
              <p>（9） 数据计算和处理过程是否正确，有效数字修约与计量单位填报是否符合标准规范和采测分离相关技术要求，分析结果填报是否正确、完整；</p>
              <p>（10） 内部质量控制措施超否完整，质控结果是否合格；</p>
              <p>（11） 分析人员对异常数据的处置是否合规。</p>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">复核结果</td>
            <td colspan="3">
              <a-radio-group v-model="reviewData.reviewStatus">
                <a-radio :value="checkOriginalRecordReviewStatusEnum.pass">通过</a-radio>
                <a-radio :value="checkOriginalRecordReviewStatusEnum.rejected">驳回</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">复核意见</td>
            <td colspan="3">
              <a-textarea v-model="reviewData.reviewNote"></a-textarea>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">附件上传</td>
            <td colspan="3">
              <div class="clearfix" style="margin-top: 5px">
                <a-upload
                  accept="image/*"
                  :action="uploadUrl"
                  :headers="tokenHeader"
                  :multiple="false"
                  :showUploadList="true"
                  listType="picture-card"
                  :fileList="fileList"
                  @preview="handlePreview"
                  @change="(e) => handleChange(e)"
                >
                  <div>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">上传图片</div>
                    <div class="ant-upload-text">限 <span style="font-weight: 700px; color: #ff3b25">1</span> 张</div>
                  </div>
                </a-upload>
              </div>
            </td>
          </tr>
        </table>
        <div class="review-btn">
          <a-button type="primary" @click="submitReview">提交</a-button>
        </div>
      </div>
      <a-modal v-bind="reviewCheckModal">
        <a-input v-model="reviewData.checkPwd" placeholder="请输入登录密码进行认证" type="password" />
        <template #footer>
          <a-button type="primary" @click="reviewCheckModal.ok">提交</a-button>
        </template>
      </a-modal>
    </a-drawer>
    <template #footer>
      <!-- <div style="display: flex; justify-content: space-between">
        <div>
          <a-button type="primary" @click="atlasPreview">图谱预览</a-button>
        </div> -->
      <div style="text-align: right">
        <a-button @click="fullModal.cancel">关闭</a-button>
        <a-button type="primary" @click="fullModal.showReview">复核</a-button>
      </div>
      <!-- </div> -->
    </template>

    <AtlasPreview ref="atlasPreviewRef"></AtlasPreview>
  </a-modal>
</template>

<script>
import { throttle } from 'lodash'

import CheckTaskBatchOriginalRecord from '@/pages/cloud-quote/check-task/modules/originalRecord/Batch.vue'
import AtlasPreview from '@/pages/cloud-quote/check-task/modules/schedule/moudles/batch-schedule/AtlasPreview.vue'
import fullModal from '@/pages/common/mixins/modal/full'
import { checkOriginalRecordReviewStatusEnum } from '@/api/check/checkOriginalRecordConstant'
import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'
import { sysCommonApi } from '@/api/common'
export default {
  mixins: [fullModal],

  props: {
    batchId: {
      type: Number,
      required: true
    },

    authMark: {
      type: Boolean,
      required: false,
      default: false
    },

    checkUser: {
      type: String || null,
      required: false,
      default: null
    }
  },

  components: {
    CheckTaskBatchOriginalRecord,
    AtlasPreview
  },

  data() {
    return {
      checkOriginalRecordReviewStatusEnum,
      uploadUrl: sysCommonApi.uploadFile,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      templateData: null,
      fileList: [],
      drawerVisible: false,
      user: {},

      // 复核数据
      reviewData: {
        id: this.batchId,
        checkUser: this.checkUser,
        checkPwd: null,
        reviewStatus: null,
        reviewNote: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      reviewDrawer: {
        visible: false,
        width: 600,
        getContainer: false,
        closable: false,
        maskClosable: true,
        mask: true,
        wrapStyle: { position: 'absolute' },
        close: () => {
          this.reviewDrawer.visible = false
        }
      },

      reviewCheckModal: {
        title: '请先进行认证',
        centered: true,
        closed: false,
        width: 600,
        visible: false,
        show: () => {
          this.reviewCheckModal.visible = true
        },
        cancel: () => {
          this.reviewCheckModal.visible = false
        },
        ok: () => {
          if (this.reviewData.checkPwd) {
            this.review()
          } else {
            this.$message.error('请输入认证密码！')
            return false
          }
        }
      },

      fullModal: {
        title: '原始记录复核',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },
        // show: () => {
        //   this.fullModal.visible = true
        //   console.log('show')
        // },
        showReview: () => {
          this.reviewDrawer.visible = true
        }
      }
    }
  },

  mounted() {
    this.review = throttle(this.review, 6000, { leading: true, trailing: false })
  },
  methods: {
    // 提交复审请求，如果需要认证则跳转到认证窗体
    submitReview() {
      if (this.checkReviewData()) {
        if (this.authMark) {
          this.reviewCheckModal.show()
        } else {
          this.review()
        }
      }
    },
    // 复核检测原始记录表
    review() {
      if (this.fileList.length) {
        let fileUrl = []
        this.fileList.forEach((item) => {
          fileUrl.push(item.url)
        })
        this.reviewData.fileUrl = fileUrl.join(',')
      }
      checkBatchApi.review(this.reviewData).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
          this.fullModal.cancel()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 校验复核数据
    checkReviewData() {
      if (this.reviewData.reviewStatus) {
        return true
      } else {
        this.$message.error('请选择复核结果！')
        return false
      }
    },
    handlePreview(file) {
      this.previewImage = file.thumbUrl || sysCommonApi.view + '/' + file.url
      this.previewVisible = true
    },
    handleChange(e) {
      let { file, fileList } = e
      this.fileList = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.view + '/' + file.response.data.fileUrl
          file.url = '/' + file.response.data.fileUrl
          this.fileList.forEach((item) => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },
    // 展示当前组件
    show() {
      this.fullModal.show()
    },
    atlasPreview() {
      this.$refs.atlasPreviewRef.init(this.batchId)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/table/index2';

.review-form {
  position: absolute;
  bottom: 100px;
  width: 100%;
  background: #ff0000;
  z-idnex: 1;
}

.mxtable td p {
  margin-bottom: 0.5em;
}

.review-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
