<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="1050"
    :maskClosable="false"
    :destroyOnClose="true"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false">
      <a-table
        ref="table"
        size="small"
        :loading="table.loading"
        :columns="table.columns"
        :dataSource="table.dataSource"
        :rowKey="table.rowKey"
        :pagination="table.pagination"
        @change="tableChange"
        :rowSelection="table.rowSelection"
      >
        <template slot="cateIds" slot-scope="text, record">{{ getName(text, record) }}</template>
        <template slot="subitemPrice" slot-scope="text, record">
          <template v-if="record.editable">
            <a-input
              style="margin: -5px 0"
              v-model="record.subitemPrice"
            />
          </template>
          <template v-else>
            {{ text }}
            <a-icon v-if="record.subitemPrice>record.price" type="arrow-up" style="color: rgb(207, 19, 34);"/>
            <a-icon v-if="record.subitemPrice<record.price" type="arrow-down" style="color: rgb(63, 134, 0);"/>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/pages/cloud-quote/item/modules/ItemForm'
import { itemApi, subItemApi, itemCategoryApi, Get, Post, Del } from '@/api/quote'
import { subcontractorApi } from '@/api/project'

var vm = {}
export default {
  name: 'Form',
  components: {
    STable,
    CreateForm
  },
  data () {
    vm = this
    return {
      title: '添加检测项',
      visible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        itemId: null
      },
      item: {},
      categoryList: [],
      subcontractorList: [],
      table: {
        loading: false,
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '分包商', dataIndex: 'sctrName' },
          { title: '名称', dataIndex: 'name' },
          { title: '所属分类', dataIndex: 'cateIds', scopedSlots: { customRender: 'cateIds' } },
          { title: '单价', dataIndex: 'price', width: '200px' },
          { title: '分包商单价', dataIndex: 'subitemPrice', width: '200px', scopedSlots: { customRender: 'subitemPrice' } }
          // ,{ title: '分包', dataIndex: 'type', customRender: (text) => { if (text === 1) { return '是' } else { return '否' } } }
        ],
        pagination: {
          show: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          }
        },
        rowSelection: {
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) => {
            this.table.selectedRowKeys = selectedRowKeys
            this.table.selectedRows = selectedRows
            // console.log('onChange', 'selectedRowKeys', this.table.selectedRowKeys, 'selectedRows: ', this.table.selectedRows)
          }
        }
      }
    }
  },
  mounted () {
    this.listSubcontractor()
    itemCategoryApi.list({ pageSize: 500 }).then(result => {
      this.categoryList = result.data
    })
  },
  methods: {
    add (item) {
      this.visible = true
      this.queryParam.itemId = item.itemId
      this.item = item
      this.loadData()
    },
    tableChange (pagination, filters, sorter) {
      this.table.pagination.current = pagination.current
      this.table.pagination.pageSize = pagination.pageSize
      this.loadData()
    },
    loadData () {
      var self = this
      self.table.loading = true
      const page = {
        pageNo: self.table.pagination.current,
        pageSize: self.table.pagination.pageSize
      }
      subItemApi.list(Object.assign(this.queryParam, page)).then(res => {
        if (res.code === 0) {
          self.table.loading = false
          self.table.dataSource = res.data.data
          self.table.pagination.current = res.data.pageNo
          self.table.pagination.pageSize = res.data.pageSize
          self.table.pagination.total = res.data.totalCount
        } else {
          this.$message.error('查询失败')
        }
      })
    },
    getName (text, record) {
      const cateName = []
      const cateIdsArr = record.cateIds.split(',')
      for (let i = 0; i < cateIdsArr.length; i++) {
        const cate = this.categoryList.filter(item => item.id === parseInt(cateIdsArr[i]))[0]
        if (cate) {
          cateName.push(cate.name)
        }
      }
      record.cateName = cateName.join(' | ')
      return record.cateName
    },
    listSubcontractor () {
      subcontractorApi.list({ page: false }).then(res => {
        if (res.code === 0) {
          this.subcontractorList = res.data
        } else {
          this.$message.error('查询失败')
          this.subcontractorList = []
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sctrChange (e, record) {
      const self = this
      self.table.pagination.current = 1
      self.loadData()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk (e) {
      e.preventDefault()
      const self = this
      if (self.table.selectedRowKeys.length < 1) {
        self.$message.error('请选择分包商')
      } else {
        self.item.sctr = self.table.selectedRows[0]
        self.$emit('itemSubOk', self.item)
        self.handleCancel()
      }
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .search {
    margin-bottom: 54px;
  }

  .fold {
    width: calc(100% - 216px);
    display: inline-block
  }

  .operator {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
