<template>
  <div>
    <a-textarea
      v-if="type=='textarea'"
      :id="this.inputId"
      v-model="localValue"
      :placeholder="placeholder"
      :readOnly="readOnly"
      @focus="()=>{focus=true}"
      @blur="()=>{focus=false}"
      :allowClear="allowClear"
      :rows="rows" />
    <a-input
      v-else
      :id="this.inputId"
      v-model="localValue"
      :placeholder="placeholder"
      :readOnly="readOnly"
      @focus="()=>{focus=true}"
      @blur="()=>{focus=false}"
      :allowClear="allowClear"
      :rows="rows"/>
    <div class="formula-tools" v-if="show" @mouseover="()=>{mouseover=true}" @mouseout="()=>{mouseover=false}">
      <table border="0" cellpadding="0" cellspacing="1" class="border">
        <template v-for="(temp, sidx) in symbolList">
          <tr :key="sidx">
            <td v-for="(obj, tidx) in temp" :key="tidx" @click="addSymbol(obj)">X{{ obj }}</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
import uuidv1 from 'uuid/v1'
export default {
  name: 'FormulaInput',
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      // input/textarea
      type: String,
      default: 'input'
    },
    allowClear: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    rows: {
      type: Number,
      required: false,
      default: 2
    }
  },
  data () {
    return {
      inputId: 'formulaInput',
      focus: false,
      mouseover: false,
      localValue: this.value,
      symbolList: [['⁰', '¹', '²', '³', '⁴', '⁵', '⁶', '⁷', '⁸', '⁹', '⁺', '⁻', '⁼'],
        ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉', '₊', '₋', '₌']]
    }
  },
  methods: {
    addSymbol (obj) {
      var elInput = document.getElementById(this.inputId) // 根据id选择器选中对象
      var startPos = elInput.selectionStart// input 第0个字符到选中的字符
      var endPos = elInput.selectionEnd// 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return
      var txt = elInput.value
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + obj + txt.substring(endPos)
      elInput.value = result// 赋值给input的value
      // 重新定义光标位置
      elInput.focus()
      elInput.selectionStart = startPos + obj.length
      elInput.selectionEnd = startPos + obj.length
      // this.form.message = result// 赋值给表单中的的字段
      this.$set(this, 'localValue', result)
    },
    formatter (e) {
      var value = e.target.value
      value = value.replace(/^\s+|\s+$/g, '').replace(/（/g, '(').replace(/）/g, ')')
      e.target.value = value
    }
  },
  watch: {
    value (v) {
      v = v.replace(/^\s+|\s+$/g, '').replace(/（/g, '(').replace(/）/g, ')')
      this.localValue = v
    },
    localValue (v) {
      v = v.replace(/^\s+|\s+$/g, '').replace(/（/g, '(').replace(/）/g, ')')
      this.$emit('update:value', v)
    }
  },
  computed: {
    show () {
      return this.focus || this.mouseover
    }
  },
  created () {
    this.inputId = 'formulaInput' + uuidv1() // 获取随机id
  }
}
</script>

<style lang="less">
  .formula-tools {
    position : absolute;
    /*display  : none;*/
    z-index  : 99;
    padding     : 10px 5px !important;
    border      : 1px solid #E6E6E6 !important;
    background: #fff;

    .border {
      background-color : #FFFFFF !important;
      width            : 198px !important;
    }

    .border td {
      width       : 23px !important;
      padding     : 10px 5px !important;
      line-height : 0px !important;
      border      : 1px solid #E6E6E6 !important;
      cursor      : hand;
      cursor : pointer;
    }
  }
</style>
