<template>
  <div class="check-reagent-raw-material-info-table">

    <customize-column v-model="contentTable.columns" cache-key-pre="check-reagent-raw-material-info-table">
      <template #button>
        <a-icon type="setting" class="setting" :style="settingStyle"></a-icon>
      </template>
    </customize-column>
    <s-table v-bind="contentTable" :rowSelection="customRowSelection" :scroll="scrollProp">

      <template #materialName="data,record">
        <a-tooltip>
          <template #title>
            <p>申请物资: {{ record.oldMaterialName }}</p>
            <p> 出库物资: <span :style="{color:record.oldMaterialId===record.materialId?'green':'red'}">{{ data }}</span></p>
          </template>
          {{ data }}
        </a-tooltip>
      </template>
      <template #shapeType="data,record">
        <span v-if="data===1">固体</span>
        <span v-if="data===2">液体</span>
        <span v-if="data===3">气体</span>
      </template>

      <template #volume="volume,record">
        <span class="num">
          {{ volume }}
        </span>/
        <span class="num">
          {{ record.sumVolume }}
        </span>{{ record.unit }}
      </template>

      <template #proportion="data,record">
        <span :class="[getProportionClass((record.volume/record.sumVolume*100)),'num']">{{ (record.volume / record.sumVolume * 100).toFixed(2) }}%</span>
      </template>

      <template #termOfValid="time">
        {{ time | momentFilter('YYYY-MM-DD') }}
      </template>

      <template #dateOfPur="time">
        {{ time | momentFilter('YYYY-MM-DD') }}
      </template>

      <template #storageTime="time">
        {{ time | momentFilter('YYYY-MM-DD') }}
      </template>

      <template #updateTime="time">
        {{ time | momentFilter('YYYY-MM-DD') }}
      </template>

      <template #action="text, record">
        <!-- v-if="isOverdue(record.termOfValid)" -->
        <a @click="handleDel(record.id)"><a-icon type="delete"></a-icon>作废</a>
      </template>
    </s-table>
  </div>
</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { checkReagentRawMaterialInfoApi } from '@/api/checkReagent/checkReagentApi'
import { CustomizeColumn } from '@/components/Columns'

export default {
  components: { sTable, CustomizeColumn },
  mixins: [componentTable],
  props: {
    selectable: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false }
  },

  data () {
    return {
      contentTable: {
        style: { padding: '16px', width: this.width, background: '#FFFFFF' },
        size: 'small',
        rowKey: 'id',
        showPagination: this.pagination ? 'auto' : false,
        bordered: true,
        columns: [
          { title: '名称', align: 'center', dataIndex: 'materialName', sorter: true, width: 50, scopedSlots: { customRender: 'materialName' } },
          { title: '形态', align: 'center', dataIndex: 'shapeType', width: 50, scopedSlots: { customRender: 'shapeType' } },
          { title: '浓度', align: 'center', dataIndex: 'purity', width: 50, sorter: true, scopedSlots: { customRender: 'purity' } },
          { title: '规格', align: 'center', dataIndex: 'speType', width: 50, sorter: true, scopedSlots: { customRender: 'speType' } },
          { title: '容量', align: 'center', dataIndex: 'volume', width: 50, scopedSlots: { customRender: 'volume' } },
          { title: '可用量', align: 'center', dataIndex: 'proportion', width: 50, scopedSlots: { customRender: 'proportion' } },
          { title: '有效期', align: 'center', dataIndex: 'termOfValid', width: 50, sorter: true, scopedSlots: { customRender: 'termOfValid' } },
          { title: '购置时间', align: 'center', dataIndex: 'dateOfPur', width: 50, scopedSlots: { customRender: 'dateOfPur' } },
          { title: '入库时间', align: 'center', dataIndex: 'storageTime', width: 50, scopedSlots: { customRender: 'storageTime' } },
          { title: '更新时间', align: 'center', dataIndex: 'updateTime', width: 50, scopedSlots: { customRender: 'updateTime' } },
          { title: '操作', align: 'action', dataIndex: 'action', width: 50, scopedSlots: { customRender: 'action' } }
        ]
      },
      settingStyle: {}
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.selectable ? this.rowSelection : null
    }
  },

  created () {
    this.$store.dispatch('code/select', 'material_unit_code')
    if (this.pagination === true) {
      this.contentTable.style = { width: this.width, background: '#FFFFFF' }
      this.settingStyle = { top: '12px' }
    }
  },

  methods: {
    loadData (param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      return checkReagentRawMaterialInfoApi.list(Object.assign({}, param, this.queryParam)).then(res => res)
    },

    getProportionClass (num) {
      return num > 60 ? 'green' : (num > 30 ? 'orange' : 'red')
    },

    getSelectData () {
      return this.rowSelection.selectedRows
    },

    isOverdue (termOfValid) {
      if (termOfValid) {
        const from = new Date(termOfValid)
        const now = new Date()
        return now >= from
      } else {
        return true
      }
    },

    handleDel (id) {
      checkReagentRawMaterialInfoApi.delete({ id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success('作废成功！')
          this.search()
        } else {
          this.$message.error('作废失败！')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.check-reagent-raw-material-info-table{
  position: relative;
  .setting {
    position: absolute;
    z-index: 9;
    right: 28px;
    top: 28px
  }

  .num {
    font-weight : 600;
    font-size   : 15px;
  }

  .green {
    color : darkgreen;
  }

  .yellow {
    color : yellow;
  }

  .orange {
    color : orange;
  }

  .red {
    color : darkred;
  }
}

</style>
