<template>
  <div>
    <s-table v-bind="feedBackTable">
      <template #sampleCount="text,record">
        <a @click="showSampleInfo(record)">{{ text }}</a>
      </template>
    </s-table>
    <a-modal v-bind="sampleInfoModal" v-on="sampleInfoModal" v-if="sampleInfoModal.visible">
      <SampleInfoTable v-bind="SampleInfoTable"></SampleInfoTable>
    </a-modal>
  </div>
</template>

<script>
// import { groupBy } from 'lodash'

import { tsTaskFeedBackApi } from '@/api/sample'
import { sampleColumn } from '@/pages/cloud-quote/sample-info/sampleTypeColumn'

import { STable } from '@/components'
import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'

export default {
  name: 'SiteFeedBackTable',

  components: { STable, SampleInfoTable },

  props: {
    projId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {

      sampleInfoModal: {
        ref: 'sampleInfoModal',
        title: '查看样品',
        visible: false,
        width: '80%',
        show: () => { this.sampleInfoModal.visible = true },
        ok: () => { this.sampleInfoModal.visible = false },
        cancel: () => { this.sampleInfoModal.visible = false }
      },

      SampleInfoTable: {
        ref: 'sampleTable',
        columns: sampleColumn,
        feedbackId: null,
        projId: this.projId
      },

      feedBackTable: {
        rowKey: 'id',
        ref: 'table',
        size: 'small',
        data: this.loadData,
        columns: [{
          dataIndex: 'siteName',
          title: '所在点位',
          scopedSlots: { customRender: 'siteName' }
        }, {
          dataIndex: 'day',
          title: '频次',
          scopedSlots: { customRender: 'day' }
        }, {
          dataIndex: 'cateName',
          title: '检测分类',
          scopedSlots: { customRender: 'cateName' }
        }, {
          dataIndex: 'feedbackUserName',
          title: '反馈人',
          scopedSlots: { customRender: 'feedbackUserName' }
        }, {
          dataIndex: 'feedbackTime',
          title: '反馈时间',
          scopedSlots: { customRender: 'feedbackTime' }
        }, {
          dataIndex: 'sampleCount',
          title: '样品数',
          scopedSlots: { customRender: 'sampleCount' }
        }]
      }
    }
  },

  watch: {},

  methods: {
    loadData (parameter) {
      return tsTaskFeedBackApi.list(Object.assign(parameter, { projId: this.projId })).then(res => {
        return res
      })
    },

    showSampleInfo (record) {
      if (!record.sampleCount) {
        this.$message.error('暂未采样！')
        return false
      }
      Object.assign(this.SampleInfoTable, { feedbackId: record.id })
      this.$nextTick(() => {
        this.sampleInfoModal.show()
      })
    }
  }
}
</script>

<style scoped>

</style>
