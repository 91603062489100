<template>
  <div>
    <a-card class="card-search" v-if="!view" size="small">
      <a-form :v-if="isRouterAlive">
        <a-row :gutter="rowProps.gutter">
          <a-col :span="18">
            <a-row v-bind="rowProps" v-if="isCate">
              <a-col v-bind="colProps">
                <a-form-item v-bind="formItemLayout" label="标准方法">
                  <a-input v-model.trim="queryParam.standardName" placeholder="" @pressEnter="search"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="colProps">
                <a-form-item v-bind="formItemLayout" label="因子">
                  <a-input v-model="queryParam.itemName" placeholder="" @pressEnter="search"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="colProps">
                <a-form-item label="配置设备" v-bind="formItemLayout">
                  <a-radio-group v-model="queryParam.hasDevice" >
                    <a-radio :value="1">是</a-radio>
                    <a-radio :value="0">否</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <template v-if="moreParam">
                <a-col v-bind="colProps">
                  <a-form-item v-bind="formItemLayout" label="常用">
                    <a-radio-group v-model="queryParam.defaultFlag">
                      <a-radio :value="1">是</a-radio>
                      <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </template>
            </a-row>
          </a-col>
          <a-col :span="6">
            <span class="table-page-search-submitButtons">
              <a-button-group>
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button @click="resetSearchForm">重置</a-button>
              </a-button-group>
              <a style="margin-left: 8px" @click="()=>{moreParam = !moreParam}" v-if="isCate">
                {{ moreParam ? '收起' : '更多' }}
                <a-icon :type="moreParam ? 'up' : 'down'"/>
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <a-card class="card-table" size="small">
      <template slot="extra" v-if="!view">
        <a-button icon="plus" type="primary" @click="add()" v-action:adminRole>明细</a-button>
        <div style="font-size: 12px; color: red" v-if="!$auth('adminRole')">提示:此数据来源于标准库,如需新增或修改请联系管理员进行操作。管理员:张工,手机号:13546153655</div>
      </template>
      <div style="position: absolute; top: 16px; left: 16px">
        <a-button type="primary" @click="toggleCate()">{{ isCate ? '一级分类' : '二级分类' }}</a-button>
      </div>
      <a-row :gutter="12">
        <a-col :span="4">
          <div :style="{maxHeight:(scrollProp.y+50)+'px',overflow:'auto'}">
            <category-tree v-show="isCate" @categoryClick="categoryClick" />
            <a-tree v-show="!isCate" :tree-data="cateTypeList" @select="selectCate" />
          </div>
        </a-col>
        <a-col :span="20">
          <s-table
            ref="table"
            :columns="columns"
            :rowSelection="customRowSelection"
            :data="loadData"
            :scroll="scrollProp"
            size="small">
            <template slot="itemName" slot-scope="text">
              {{ text }}
            </template>

            <template slot="cateType" slot-scope="text, record">
              <ellipsis :length="20">{{ getName(record) }}</ellipsis>
            </template>

            <template slot="limitValue" slot-scope="text, record">
              {{ text }}{{ record.unit }}
            </template>

            <template slot="standardId" slot-scope="text, record">
              <ellipsis :length="40">{{ getStandardMethodName(record) }}</ellipsis>
            </template>

            <template slot="jobType" slot-scope="text">
              {{ getJobTypeName(text) }}
            </template>

            <template slot="jcDevices" slot-scope="text">
              <ellipsis :length="30">{{ getDevListName(text) }}</ellipsis>
            </template>

            <template slot="defaultFlag" slot-scope="text">
              <template v-if="text==null ||text==0 ">
                <a-tag color="red">否</a-tag>
              </template>
              <template v-else>
                <a-tag color="green">是</a-tag>
              </template>
            </template>

            <template slot="templateName" slot-scope="text">
              <ellipsis :length="20">{{ text }}</ellipsis>
            </template>

            <template slot="action" slot-scope="text, record">
              <template v-if="isCate">
                <div class="editable-row-operations">
                  <a class="edit" @click="() => edit(record)">修改</a>
                  <a-divider type="vertical"/>
                  <a-popconfirm title="是否要作废此行？" @confirm="del(record)">
                    <a class="delete"  v-action:adminRole>作废</a>
                  </a-popconfirm>
                  <a-divider type="vertical"/>
                  <a class="edit" @click="() => copy(record)">应用</a>
                </div>
              </template>
            </template>
          </s-table>
        </a-col>
      </a-row>

    </a-card>
    <item-check-add-form ref="createModal" @ok="handleOk"/>
    <item-check-copy-form ref="copyModal" @ok="handleOk"/>

  </div>

</template>

<script>
import { STable } from '@/components'
import { Get, itemCategoryApi, itemCheckDetailApi, Post, resMaterialApi, resPostTypeApi } from '@/api/quote'
import { dictApi } from '@/api/system'
import { scrollTable, search } from '@/pages/common/mixins/list'
import { groupBy } from 'lodash'
import ItemCheckAddForm from './modules/ItemCheckAddForm'
import ItemCheckCopyForm from './modules/ItemCheckCopyForm'
import ACol from 'ant-design-vue/es/grid/Col'
import CategoryTree from '@/pages/common/CategoryTree'
import Ellipsis from '@/components/Ellipsis/Ellipsis.vue'

export default {
  name: 'ItemCheckDetailList',
  mixins: [search, scrollTable],
  components: {
    Ellipsis,
    ACol,
    STable,
    ItemCheckAddForm,
    ItemCheckCopyForm,
    CategoryTree
  },
  props: {
    standardId: { type: Number },
    methodId: { type: Number },
    view: { type: Boolean, default: false },
    itemName: { type: String, required: false, default: '' },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },
  computed: {
    customQueryParam () {
      return Object.assign({}, this.queryParam, { standardId: this.standardId, methodId: this.methodId, view: this.view })
    },
    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  watch: {
    '$route.query.id': {
      immediate: true,
      deep: true,
      handler (value) {
        this.queryParam.id = value
      }
    }
  },

  data () {
    return {
      otherHeight: 400,
      isRouterAlive: true,
      // 查询参数
      queryParam: {
        id: null,
        itemName: this.itemName,
        standardName: null,
        defaultFlag: null
      },
      cateTypeList: [],
      categoryList: [],
      jobTypeList: [],
      // 表头
      columns: [
        { title: '因子', dataIndex: 'itemName', width: 120, scopedSlots: { customRender: 'itemName' }, fixed: 'left' },
        { title: '所属分类', dataIndex: 'cateType', scopedSlots: { customRender: 'cateType' }, width: 180, fixed: 'left' },
        { title: '标准方法', dataIndex: 'standardId', scopedSlots: { customRender: 'standardId' }, width: 320, fixed: 'left' },
        { title: '设备', dataIndex: 'jcDevices', scopedSlots: { customRender: 'jcDevices' }, width: 220 },
        { title: '常用', dataIndex: 'defaultFlag', scopedSlots: { customRender: 'defaultFlag' }, width: 80 },
        { title: '检测岗位', dataIndex: 'jobType', scopedSlots: { customRender: 'jobType' }, width: 120 },
        { title: '检出限', dataIndex: 'limitValue', scopedSlots: { customRender: 'limitValue' }, width: 80 },
        { title: '检测模板', dataIndex: 'templateName', scopedSlots: { customRender: 'templateName' }, width: 200 },
        { title: '分值', dataIndex: 'score', scopedSlots: { customRender: 'score' }, width: 60 },
        { title: '操作', dataIndex: 'action', width: 150, scopedSlots: { customRender: 'action' } }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        // const param = JSON.parse(JSON.stringify(Object.assign(this.customQueryParam)))
        const param = Object.assign(this.customQueryParam, this.queryParam)
        for (const item in param) {
          if (typeof (param[item]) === 'string') {
            param[item] = param[item].trim()
          }
        }
        if (this.isCate) {
          return itemCheckDetailApi.list(Object.assign(parameter, param)).then(res => {
            const d = res.data
            const tmp = []
            for (let i = 0; i < d.length; i++) {
              const a = d[i]
              a.key = d[i].id
              tmp.push(a)
            }
            res.data = tmp
            return res
          })
        } else {
          return itemCheckDetailApi.listCategory(Object.assign(parameter, param)).then(res => {
            // const d = res.data
            // const tmp = []
            // for (let i = 0; i < d.length; i++) {
            //   const a = d[i]
            //   a.key = d[i].id
            //   tmp.push(a)
            // }
            // res.data = tmp
            res.data.pageNo = parameter.pageNo
            return res.data
          })
        }
      },
      devList: [],
      moreParam: false,
      rowSelection: {
        selectedRowKeys: [],
        selectedRows: [],
        onChange: (selectedRowKeys, selectedRows) => this.onSelectChange(selectedRowKeys, selectedRows)
      },
      isCate: true // true为二级，false为一级
    }
  },
  created () {
    Post(itemCategoryApi.getAll).then(result => {
      this.categoryList = result.data
    })
    Get(resPostTypeApi.listAll).then((res) => {
      if (res.code === 0) {
        this.jobTypeList = res.data
      }
    })
    this.loadCateType()
    // 该方法触发屏幕自适应
    Get(resMaterialApi.getMaterialSelect, {}).then(result => {
      const group = groupBy(result, item => item.cateType)
      this.devList = group[3] == null ? [] : group[3]
    })
  },
  methods: {
    toggleCate () {
      this.isCate = !this.isCate
      this.queryParam.cateType = null
      this.queryParam.cateId = null
      this.$refs.table.refresh(true)
    },
    categoryClick (param) {
      this.queryParam.cateType = null
      this.queryParam.cateId = null
      this.queryParam = Object.assign(this.queryParam, param)
      this.$refs.table.refresh(true)
    },
    selectCate (selectedKey, e) {
      this.queryParam.cateType = null
      this.queryParam.cateId = null
      this.queryParam = Object.assign(this.queryParam, { cateType: selectedKey })
      this.$refs.table.refresh(true)
    },
    getJobTypeName (text) {
      const name = []
      if (text) {
        const arr = text.toString().split(',')
        arr.forEach((item) => {
          const d = this.jobTypeList.filter(r => r.id.toString() === item.toString())
          if (d.length > 0) {
            name.push(d[0].name)
          }
        })
      }
      return name.join('、')
    },
    getDevListName (text) {
      const name = []
      if (text) {
        const arr = text.split(',')
        arr.forEach(item => {
          const d = this.devList.filter(r => r.id === parseInt(item))
          if (d.length > 0) {
            if (!d[0].speType) {
              name.push(d[0].materialName)
            } else {
              name.push(d[0].materialName + '(' + d[0].speType + ')')
            }
          }
        })
      }
      return name.join('、')
    },

    add () {
      this.$refs.createModal.add()
    },
    getStandardMethodName (record) {
      return record.methodName + '(' + record.standardCode + ')'
    },
    handleCateTypeChange (v) {
      this.queryParam.cateType = v
      this.$forceUpdate()
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
      this.$refs.table.refresh()
    },
    loadCateType () {
      dictApi.getItems({ dictCode: 'cate_type' }).then((res) => {
        if (res.code === 0) {
          const d = res.data
          const temp = []
          for (let i = 0; i < d.length; i++) {
            temp.push({ title: d[i].itemText, key: d[i].itemValue })
          }
          this.cateTypeList = temp
        }
      })
    },
    getName (record) {
      let name = ''
      const cateType = this.cateTypeList.find(r => r.key === record.cateType)
      if (cateType) {
        name = cateType.title
      }
      if (this.isCate) {
        const cate = this.categoryList.find(r => r.id === record.cateId)
        if (cate) {
          name = name + '|' + cate.name
        }
      } else {
        if (record.cateIdList && record.cateIdList.length > 0) {
          const cateListName = record.cateIdList.join(',')
          name = name + '|' + cateListName
        }
      }
      return name
    },

    handleChange (value, key, column, record) {
      record[column.dataIndex] = value
    },
    edit (row) {
      const temp = Object.assign({}, row)
      this.$refs.createModal.edit(temp)
    },
    copy (row) {
      const temp = Object.assign({}, row)
      this.$refs.copyModal.edit(temp)
    },
    // eslint-disable-next-line
    del (record) {
      Post(itemCheckDetailApi.del, { id: record.id }).then(res => {
        if (res.code === 0) {
          this.$message.success('操作成功')
          this.$refs.table.refresh()
        }
      })
    },
    resetSearchForm () {
      this.queryParam = {}
      this.$refs.table.refresh(true)
    },
    handleOk () {
      this.$refs.table.refresh()
    },

    // 表格行选中事件
    onSelectChange (selectedRowKeys, selectedRows) {
      this.rowSelection.selectedRowKeys.splice(0, this.rowSelection.selectedRowKeys.length, ...selectedRowKeys)
      this.rowSelection.selectedRows.splice(0, this.rowSelection.selectedRows.length, ...selectedRows)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$route.params && vm.$route.params.name) {
        vm.queryParam.standardName = vm.$route.params.name
        vm.reload()
      }
    })
  }
}
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
