<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;">
          复核信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ reagentInfo.createUserName }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ reagentInfo.recheckApplyTime }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr style="width: 200px">
          <td class="tdLabel">复核人</td>
          <td>
            <select-user-by-dept ref="devUser" :disabled="disabled" :triggerChange="true" @change="handleUserChange" rowType="radio" style="width: 50%"/>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">试剂信息</td>
          <td colspan="3">
            <a-table
              ref="table"
              key="id"
              :columns="columns"
              :dataSource="data"
              :pagination="false"
            >
              <template #sumVolume="data,record">
                <span :style="{color: getVolumeColor((record.volume/data *100 ))}" class="focus-num">{{ record.volume }}</span> /
                <span class="focus-num">{{ data }}</span>
                <span class="mar-l5">{{ record.unit }}</span><br/>
                剩余：
                <span :style="{color: getVolumeColor((record.volume/data *100 ))}" class="focus-num">{{ (record.volume / data * 100).toFixed(2) }}% </span>
              </template>
            </a-table>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { checkReagentApi } from '@/api/checkReagent/checkReagentApi'
import { sysReviewConfigUserControllerApi } from '@/api/autoConfigure/autoConfigureApi'

export default {
  name: 'ReagentRecheckModal',
  components: {
    SelectUserByDept,
    checkReagentApi
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      disabled: false,
      reagentInfo: {},
      data: [],
      columns: [
        { title: '试剂编号', dataIndex: 'reagentNo', align: 'left', scopedSlots: { customRender: 'reagentNo' } },
        { title: '试剂名称', dataIndex: 'name', align: 'left', scopedSlots: { customRender: 'name' } },
        { title: '类目名称', dataIndex: 'cateName', align: 'left', scopedSlots: { customRender: 'cateName' } },
        { title: '储备量', dataIndex: 'sumVolume', align: 'left', scopedSlots: { customRender: 'sumVolume' } },
        { title: '配置时间', dataIndex: 'configTime', align: 'left', scopedSlots: { customRender: 'configTime' } },
        { title: '配置人', dataIndex: 'createUserName', align: 'left', scopedSlots: { customRender: 'createUserName' } },
        { title: '有效截止日', dataIndex: 'validTime', align: 'left', scopedSlots: { customRender: 'validTime' } }
      ]
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.loadData()
        }
      }
    }
  },
  methods: {
    init (record) {
      this.reagentInfo = record
      this.reagentInfo.recheckApplyTime = new Date().format('yyyy-MM-dd HH:mm:ss')
      this.data = []
      this.data.push(record)
      this.selectUserList()
    },
    getVolumeColor (data) {
      return data > 50 ? 'green' : (data > 30 ? 'orange' : 'red')
    },
    loadData () {
      this.disabled = true
      checkReagentApi.getReagentInfo({ id: this.recordId }).then(res => {
        if (res.code === 0) {
          this.reagentInfo = res.data.reagentInfo
          this.data = []
          this.$refs.devUser.setUserList(res.data.userList)
          this.data.push(this.reagentInfo)
        }
      })
    },
    handleUserChange (userList) {
      if (userList.length > 0) {
        this.reagentInfo.recheckUser = userList[0].username
      }
    },
    // 获取自动试剂复核复核人员
    selectUserList() {
      sysReviewConfigUserControllerApi.selectUserList({ type: 'sj', id: this.reagentInfo.id }).then((res) => {
        // console.log(res)
        if (res.code === 0) {
          this.reagentInfo.recheckUser = res.data.reviewedCheckUser
          this.$refs.devUser.searchList(res.data.reviewedCheckUser)
        }
      })
    },
    handleSubmit () {
      if (!this.reagentInfo.recheckUser) {
        this.$message.error('复核人不能为空！')
        return
      }
      checkReagentApi.recheck(this.reagentInfo).then(res => {
        if (res.code === 0) {
          if (this.reagentInfo.status === '0') {
            this.$message.success('试剂配置复核流程申请成功！')
          } else {
            this.$message.success('试剂配置复核流程重新提交成功！')
          }
          this.$emit('ok')
        } else {
          if (this.reagentInfo.status === '0') {
            this.$message.error('试剂配置复核流程申请失败！')
          } else {
            this.$message.error('试剂配置复核流程重新提交失败！')
          }
        }
      })
    }
  }
}
</script>