// 检测任务状态字典枚举
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 试剂有效期单位枚举
export const commonValidDateUnitEnum = {
  // 天
  day: 'd',
  // 小时
  hour: 'h',
  // 分钟
  minute: 'm'
}

//  试剂有效期单位选择项
export const commonValidDateUnitOptions = [
  { label: '天', value: commonValidDateUnitEnum.day, color: 'blue' },
  { label: '小时', value: commonValidDateUnitEnum.hour, color: 'blue' },
  { label: '分钟', value: commonValidDateUnitEnum.minute, color: 'blue' }
]

export const commonValidDateUnitMap = tranOptionsToMap(commonValidDateUnitOptions)
export const commonValidDateUnitDataMap = tranOptionsToDataMap(commonValidDateUnitOptions)
