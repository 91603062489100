<template>
  <div>
    <a-tabs v-model="paperRotation" @change="resetImg">
      <a-tab-pane key="1" tab="纵向">
      </a-tab-pane>
      <a-tab-pane key="2" tab="横向">
      </a-tab-pane>
    </a-tabs>
    <div size="A4" :style="{width: paperRotation === '1' ? contentWidth : contentHeight, height: paperRotation === '1' ? contentHeight : contentWidth}" @mousedown="canvasDown($event)">
      <qriously @mousedown.stop="() => {}" v-if="id.indexOf('qrcode') >= 0" value="" :size="50" :style="{position: 'relative', top: imgY + offsetY +'px',left: imgX + offsetX +'px'}" />
      <img @mousedown.stop="() => {}" v-else :src="viewUrl + '/' + imgUrl" width="100" :style="{position: 'relative', top: imgY + offsetY +'px',left: imgX + offsetX +'px'}">
    </div>
    <div style="width: 100%;text-align: right" v-if="!readOnly">
      <a-button type="primary" @click="submit">确定</a-button>
    </div>
    <!--        <canvas :id="id" :width="paperRotation === '1' ? contentWidth : contentHeight" :height="paperRotation === '1' ? contentHeight : contentWidth" style="background-color: #fff;border: 1px solid #d9d9d9;" @mousedown="canvasDown($event)"></canvas>
        <div :style="{position: 'absolute',left: paperRotation === '1' ? '200px' : '280px',top: paperRotation === '1' ? '260px' : '200px',fontSize: '60px',width: '62px',fontWeight: 'bolder',color: '#ccc'}">文件</div>
        <div :style="{position: 'absolute',left: '40px',top: paperRotation === '1' ? '620px' : '450px',color: '#ff7272'}" v-if="!readOnly">提示：区域内单击鼠标标注盖章位置！</div>
        <img :src="viewUrl + '/' + imgUrl" width="100" :style="{position: 'absolute', top: imgY + offsetY +'px',left: imgX + offsetX +'px',display: imgVisiable ? 'block' : 'none'}">

        <div style="width: 100%;text-align: center" v-if="!readOnly">
          <a-button type="primary" @click="submit">确定</a-button>
    &lt;!&ndash;      <a-button style="margin-left: 30px;" @cancel="cancel">取消</a-button>&ndash;&gt;
        </div>-->

<!--    <div class="book">-->
<!--      <div class="page">
        <div class="subpage">
          <div>1</div>
        </div>
      </div>-->
<!--      <div class="page">-->
<!--        <div class="subpage">Page 2/2</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <page size="A4"></page>-->
  </div>
</template>

<script>
import { sysCommonApi } from '@/api/common'

export default {
  name: 'CanvasPositionModal',
  props: {
    contentWidth: {
      type: String,
      // default: `140mm`
      default: `${210 * 2}px`
    },
    contentHeight: {
      type: String,
      // default: `198mm`
      default: `${297 * 2}px`
    },
    id: {
      type: String,
      default: 'canvas'
    },
    imgUrl: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: '500|360'
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paperRotation: '1',
      viewUrl: sysCommonApi.view,
      // context: {},
      imgX: null,
      imgY: null,
      // imgVisiable: true,
      // offsetX: 22, // 设置签章位置展示偏移值
      // offsetY: 37
      offsetX: 0, // 设置签章位置展示偏移值
      offsetY: 0
    }
  },
  mounted () {
    // const canvas = document.querySelector('#' + this.id)
    // this.context = canvas.getContext('2d')
  },
  watch: {
    position: {
      handler (newValue) {
        if (newValue !== null && newValue !== 'custom') {
          const pos = newValue.split('|')
          if (pos.length === 2) {
            this.imgX = parseInt(pos[0])
            this.imgY = parseInt(pos[1])
            // this.imgVisiable = true
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    resetImg () {
      if (!this.readOnly && (this.paperRotation === '1' && (this.imgX > this.contentWidth || this.imgY > this.contentHeight) || (this.paperRotation === '2' && (this.imgX > this.contentHeight || this.imgY > this.contentWidth)))) {
        // this.imgX = null
        // this.imgY = null
        // this.imgVisiable = false
        this.imgX = 500
        this.imgY = 350
      }
    },
    canvasDown (evt) {
      if (!this.readOnly) {
        var oldX = evt.offsetX
        var oldY = evt.offsetY
        this.imgX = oldX
        this.imgY = oldY
        // this.imgVisiable = true
      }
    },
    submit () {
      if (this.imgX !== null) {
        const pos = this.imgX + '|' + this.imgY
        this.$emit('canvasDown', pos)
      } else {
        this.$message.warning('请标注签章位置')
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
/*body {
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  !*background-image:url(http://pic33.nipic.com/20130920/3420027_132600061366_2.jpg);*!
  background-repeat:no-repeat;
}
.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 256mm;
  outline: 2cm #FFEAEA solid;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}*/
body {
  background: rgb(204,204,204);
}
div[size="A4"] {
  background: white;
  /*width: 21cm;*/
  /*height: 29.7cm;*/
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
/*@media print {*/
/*  body, page[size="A4"] {*/
/*    margin: 0;*/
/*    box-shadow: 0;*/
/*  }*/
/*}*/
</style>
