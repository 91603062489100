<template>
  <div class="check-task-project-card-table">
    <template v-if="dataSource.length">
      <div :style="tableStyle">
        <a-row v-bind="rowProps">
          <a-col v-for="(item,index) in dataSource" :key="index" :span="6">

            <a-card v-bind="projectCard" :headStyle="getProjectColor(item)">
              <template #title>
                <div class="item">
                  <p class="title">
                    <slot v-bind="item" name="title">
                      <span>{{ item.projNo }}</span>
                    </slot>
                    <span :style="{float:'right',fontSize:'12px'}">
                      <span :style="{marginRight:'5px'}">◉</span>{{ getProjectProcessText(item) }}
                    </span>
                  </p>
                  <span class="sub-title" title="检测负责人">{{ item.detectionLeaderName || '-' }}</span>
                </div>
              </template>
              <div class="card-content">
                <ul>
                  <li>
                    <span class="th">{{ item.startTime ? '开始时间' : '预计开始' }}</span>
                    <p class="td">{{ item.startTime || item.beginTime || '-' }}</p>
                  </li>
                  <li>
                    <span class="th">
                      {{ item.doneTime && item.doneNum && !item.doubtNum ? '完成时间' : '预计完成' }}
                    </span>
                    <p class="td">
                      {{ item.doneTime && item.doneNum && !item.doubtNum ? item.doneTime : item.endTime }} </p>
                  </li>
                  <li :style="{width:'100%'}">
                    <span class="th">检测人员</span>
                    <p class="td">
                      <template v-if="item.checkUserNames">
                        <a-tag v-for="(cItem,cIndex) in item.checkUserNames.split(',')" :key="cIndex">{{ cItem }}</a-tag>
                      </template>
                    </p>
                  </li>
                </ul>
                <slot v-bind="item" name="detail">
                  <a-row>
                    <a-col :span="5" class="detail-title">待开始</a-col>
                    <a-col :span="5" class="detail-title">进行中</a-col>
                    <a-col :span="5" class="detail-title">已完成</a-col>
                    <a-col :span="5" class="detail-title">已审核</a-col>
                    <a-col :span="4" class="detail-title">数据存疑</a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="5" class="detail-text">{{ item.pendingNum || '0' }}</a-col>
                    <a-col :span="5" class="detail-text">{{ item.processNum || '0' }}</a-col>
                    <a-col :span="5" class="detail-text">{{ item.doneNum || '0' }}</a-col>
                    <a-col :span="5" class="detail-text">{{ item.passNum || '0' }}</a-col>
                    <a-col :span="4" class="detail-text">{{ item.doubtNum || '0' }}
                    </a-col>
                  </a-row>
                </slot>

              </div>

              <div class="card-footer">
                <slot v-bind="item" name="footer">

                </slot>

              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div :style="{margin: '16px 0',float:'right'}">
        <a-pagination v-bind="projectPagination" v-on="projectPagination"/>
      </div>
    </template>

    <div v-else class="data">
      <div class="no-data">
        <a-icon type="frown-o"/>
        暂无数据
      </div>
    </div>

  </div>
</template>

<script>
import { scrollTable } from '@/pages/common/mixins/list'
import { checkTaskApi } from '@/api/check/checkTaskApi'

export default {
  mixins: [scrollTable],

  props: {
    defaultOtherHeight: { type: Number, required: false, default: 0 }
  },

  data () {
    return {
      dataSource: [],

      otherHeight: this.defaultOtherHeight || 320,

      rowProps: { gutter: 24 },

      projectCard: {
        hoverable: true,
        class: 'project-view-card',
        style: { marginTop: '24px' },
        bodyStyle: { padding: 0 }
      },

      // 项目卡片分页配置
      projectPagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        total: 0,
        pageSize: 10,
        size: 'small',
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },

        showSizeChange: (current, pageSize) => {
          this.projectPagination.pageSize = pageSize
          this.$nextTick(() => {
            this.search()
          })
        },

        change: (pageNo) => {
          this.projectPagination.current = pageNo
          this.$nextTick(() => {
            this.search()
          })
        }
      }
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    tableStyle () {
      return { overflowY: 'auto', overflowX: 'hidden', padding: '0 5px', height: `${this.scrollProp.y}px` }
    }
  },

  mounted () {
    this.search()
  },

  methods: {

    // 卡片背景色
    getProjectColor (item) {
      const { processNum, pendingNum, doubtNum, doneNum } = item
      if (processNum || (pendingNum && doneNum)) {
        return { backgroundImage: 'linear-gradient( 135deg, #F8D800 60%, #FDEB71 100%)' }
      } else if (pendingNum) {
        return { backgroundImage: 'linear-gradient( 135deg, #ABABAB 60%, #F2F2F2 100%)' }
      } else if (doubtNum) {
        return { backgroundImage: 'linear-gradient( 135deg, #EA5455 60%, #FEB692 100%)' }
      } else if (doneNum && !doubtNum) {
        return { backgroundImage: 'linear-gradient( 135deg, #3C8CE7 60%, #00EAFF 100%)' }
      } else {
        return { backgroundImage: 'linear-gradient( 135deg, #3C8CE7 60%, #00EAFF 100%)' }
      }
    },

    // 项目进度汉化
    getProjectProcessText (item) {
      const { processNum, pendingNum, doubtNum, doneNum } = item
      return processNum || (pendingNum && doneNum) ? '进行中' : pendingNum ? '待开始' : doubtNum ? '数据存疑' : '已完成'
    },

    // 查询数据
    search () {
      this.loadData(this.queryParam)
    },

    // 重载
    reload () {
      this.projectPagination.current = 1
      this.loadData(this.queryParam)
    },

    // 加载数据
    loadData (param) {
      const defaultParam = {
        pageNo: this.projectPagination.current,
        pageSize: this.projectPagination.pageSize
      }

      checkTaskApi.projectList(Object.assign(defaultParam, param)).then(res => {
        Object.assign(this.projectPagination, {
          current: res.pageNo,
          total: res.totalCount,
          pageSize: res.pageSize
        })
        this.dataSource.splice(0, this.dataSource.length, ...res.data)
      })
    }

  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.check-task-project-card-table {
  .scrollStyle;
}
</style>
