<template>
  <div>
    <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
      报告外协审核
    </div>
    <div style="width: 100%;height: 100%;">
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              申请信息
            </div>
          </a-col>
          <a-col span="7">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              申请人：{{defaultData.userName}}
            </div>
          </a-col>
          <a-col span="14">
            <div class="ahmux-sub-title-detail" style="width: 100%;">
              申请时间：{{defaultData.applyTime}}
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-form v-bind="dataForm">
            <table class="mxtable">
              <tr>
                <td class="tdLabel" style="padding: 0 5px;">项目</td>
                <td colspan="3">
                  <SearchProjectView
                    v-if="defaultData.relProjName"
                    :value="defaultData.relProjName"></SearchProjectView>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <ReportTaskDetailFlow :projId="defaultData.projId" v-if="defaultData.projId"></ReportTaskDetailFlow>
                </td>
              </tr>
              <tr>
                <td class="tdLabel" style="padding: 0 5px;"><span style="color: red;margin-right: 5px;">*</span>评估类型</td>
                <td>
                  <a-form-item label="" style="margin: 0;">
                    <a-select v-model="defaultData.evaluationType" disabled>
                      <a-select-option v-for="vo in evaluationTypeList" :value="vo.itemValue" :key="vo.itemValue">{{ vo.itemText }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </td>
                <td class="tdLabel" style="padding: 0 5px;"></td>
                <td> </td>
              </tr>
              <tr v-if="defaultData.sendMode!='1'">
                <td class="tdLabel" style="padding: 0 5px;">说明</td>
                <td colspan="3" style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <a-textarea v-model="defaultData.remark" disabled></a-textarea>
                  </a-form-item>
                </td>
              </tr>
              <tr>
                <td class="tdLabel" style="padding: 0 5px;">附件</td>
                <td colspan="3" style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <j-upload v-model="defaultData.fileupload" disabled></j-upload>
                  </a-form-item>
                </td>
              </tr>
            </table>
          </a-form>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import ReportSendDetail from '@/pages/cloud-quote/report/send/modules/ReportSendDetail'
import JUpload from '@/components/j/JUpload'
import { commDictApi } from '@/api/common'
import ReportBaseInfo from '../../../send/modules/ReportBaseInfo'
import ReportTaskDetailFlow from '../../../../../flow/module/components/search-project/ReportTaskDetailFlow'
import SearchProjectView from '../../../../../flow/module/components/search-project/SearchProjectView'
import { reportTaskApi } from '../../../../../../api/sample'
export default {
  name: 'CoordinationMgr',
  components: { SearchProjectView, ReportTaskDetailFlow, ReportBaseInfo, JUpload, ReportSendDetail },
  props: {
    recordId: {
      type: Number,
      default: null
    },
    // 流程操作节点信息
    activeTask: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      defaultData: {},

      evaluationTypeList: null
    }
  },
  methods: {
    getEvaluationType () {
      commDictApi.list({ dictCode: 'evaluation_type' }).then(result => {
        if (result.code === 0) {
          this.evaluationTypeList = result.data
        }
      })
    },

    init () {
      reportTaskApi.findCoordinationOne({ id: this.recordId }).then((res) => {
        if (res.code === 0) {
          this.defaultData = res.data
        }
      })
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        this.getEvaluationType()
        if (val !== undefined && val != null) {
          this.init()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
