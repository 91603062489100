<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { computed, getCurrentInstance, ref, reactive, watchEffect, nextTick } from 'vue'
import { DatePicker, Select } from 'ant-design-vue'
import { groupBy } from 'lodash'
import { getAction } from '@/api/common'
import { resDevApi } from '@/api/quote'
import ResDevTable from '@/pages/cloud-quote/res/res-dev/modules/ResDevTable.vue'
import { useFullModal } from '@/pages/common/mixins/modal/full'
import { useDate } from '@/pages/common/compositions/date/useDate'

const ASelectOption = Select.Option
const { proxy } = getCurrentInstance()
const { moment } = useDate()

const props = defineProps({
  modelValue: { required: true, type: Array },
  selectCheckGuide: { required: false, type: Object, default: undefined },
  // defaultUse: { required: false, type: String, default: '' },
  // defaultUseEnd: { required: false, type: String, default: '' },
  deaultDevList: { required: false, type: Array }
})

const emit = defineEmits(['update:modelValue'])
const selectDeviceInfos = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modalValue', value)
  }
})

// 查询设备信息
const deviceTypeMap = ref({})
const deviceInfos = ref([])
const queryDeviceInfo = (guideInfo) => {
  // 未配置设备时
  if (!guideInfo.jcDevices) {
    deviceInfos.value.splice(0, deviceInfos.value.length)
    deviceTypeMap.value = {}
    selectDeviceInfos.value.splice(0, selectDeviceInfos.value.length)
    return false
  }

  getAction(resDevApi.list, { cateIds: guideInfo.jcDevices.split(','), useStatusList: ['0','1','2'], page: false }).then((res) => {
    // console.log(props.deaultDevList)

    const data = res.data
    deviceInfos.value.splice(0, deviceInfos.value.length, ...(data || []))
    deviceTypeMap.value = groupBy(data, 'cateId')
    // 已经存在的设备不要重新加载
    const deviceKeys = Object.keys(deviceTypeMap.value)
    const existSelectDeviceInfos = selectDeviceInfos.value.filter((device) => deviceKeys.includes(device.cateId))
    // console.log(existSelectDeviceInfos)
    existSelectDeviceInfos.length && selectDeviceInfos.value.splice(0, selectDeviceInfos.value.length, ...existSelectDeviceInfos)
    // console.log(selectDeviceInfos.value)
    handleDeaultDevs()
  })
}

const selectDevice = (device, selectData) => {
  if (!Object.prototype.hasOwnProperty.call(device, 'usageInfos')) {
    proxy.$set(device, 'usageInfos', [])
  }
  device.usageInfos.splice(
    0,
    device.usageInfos.length,
    ...selectData.map((item) => ({
      devId: item.id,
      devName: item.devName,
      devType: item.type,
      fixedAssetsId: item.fixedAssetsId,
      speType: item.speType,
      type: '2',
      locationId: item.locationId,
      locationName: item.locationName,
      useStatus: item.useStatus
      // useDate: moment(item.useDate ?? props.defaultUse),
      // useDateEnd: moment(item.useDateEnd ?? props.defaultUseEnd)
    }))
  )
}
const timer = ref(null)
const handleDeaultDevs = () => {
  // if (Object.keys(deviceTypeMap.value).length > 0) {
  // 加载数据新填充的设备信息
  const devTypes = selectDeviceInfos.value.map((item) => item.cateId)
  Object.values(deviceTypeMap.value).forEach((devices) => {
    const device = devices[0]
    if (!devTypes.includes(device.cateId)) {
      // if (devices.length === 1) {
      const devUse = { cateId: device.cateId, cateName: device.cateName, usageInfos: [] }
      selectDeviceInfos.value.push(devUse)
      // nextTick(() => {
      setTimeout(() => {
        if (props.deaultDevList && props.deaultDevList.length > 0) {
          let selectDevs = []
          let deaultDevs = []
          props.deaultDevList.forEach((item) => {
            if (item.cateId === device.cateId) {
              deaultDevs.push(...item.usageInfos)
            }
          })
          if (deaultDevs.length > 0) {
            selectDevs = devices.filter((item) => {
              let typeIndex = deaultDevs.findIndex((item2) => parseInt(item2.devType) === parseInt(item.type) && parseInt(item2.devId) === parseInt(item.id))
              return typeIndex >= 0
            })
            selectDevice(devUse, selectDevs)
          } else {
            selectDevice(devUse, [device])
          }
        } else {
          selectDevice(devUse, [device])
        }
      }, 1000)
      // })
      // } else {
      //   selectDeviceInfos.value.push({ cateId: device.cateId, cateName:device.cateName, usageInfos: [] })
      // }
    }
  })
  // } else {
  //   timer.value = setTimeout(() => {
  //     handleDeaultDevs()
  //   }, 1000)
  // }
}

const handleDevChange = (device, value) => {
  nextTick(() => {
    const selectData = deviceTypeMap.value[device.cateId].filter((item) => value.includes(item.id))
    selectDevice(device, selectData)
  })
}

watchEffect(() => {
  props.selectCheckGuide && queryDeviceInfo(props.selectCheckGuide)
})

const selectDeviceData = reactive({
  dataSource: [],
  isSetData: true,
  currentResDev: null,
  rowSelect: {
    type: 'checkbox',
    selectedRows: []
  },
  pagination: true
})
const showSetup = (device, ...dataSource) => {
  selectDeviceData.dataSource.splice(0, selectDeviceData.dataSource.length, ...dataSource)
  selectDeviceData.currentResDev = device
}

const okFun = () => {
  const selectData = selectDeviceData.rowSelect.selectedRows
  if (!selectData || !selectData.length) {
    this.$message.error('您还未选择！')
    return false
  }
  const resDevData = selectDeviceData.currentResDev
  selectDevice(resDevData, selectData)
  selectData.splice(0, selectData.length)
  modalAction.cancel()
}
const { modalProps, modalAction } = useFullModal({ width: '80%', title: '请选择使用设备' }, okFun, null, null, showSetup, null)

const { baseFormCard, moreCurrentDatePickerProps } = useFormProps()
</script>

<template>
  <a-card title="仪器设备" v-bind="baseFormCard">
    <template #extra>
      <template v-if="!selectCheckGuide">
        <span class="red">请先选择检测方法</span>
      </template>
      <template v-else-if="!selectDeviceInfos || !selectDeviceInfos.length">
        <span class="red">暂未配置检测设备</span>
      </template>
    </template>
    <ul-table :data-source="selectDeviceInfos" :hidden-title="true" :hidden-wrap="true" :row-attrs="{ class: 'average' }">
      <template #default="{ data: device, index }">
        <div class="del-action">
          <a-tooltip>
            <template #title> 如果未使用到此种设备，点我删除</template>
            <a @click="() => selectDeviceInfos.splice(index, 1)">
              <a-icon type="close-circle" />
            </a>
          </a-tooltip>
        </div>

        <ul-table-column :data="device" data-index="cateName" title="配置设备类目"></ul-table-column>

        <ul-table-column title="使用设备" v-if="!device.usageInfos || !device.usageInfos.length">
          <template v-if="deviceTypeMap[device.cateId] && deviceTypeMap[device.cateId].length">
            <a-select allowClear mode="multiple" placeholder="请选择使用的设备" style="width: 300px" @blur="(val) => handleDevChange(device, val)">
              <a-select-option v-for="item in deviceTypeMap[device.cateId]" :key="item.id">【{{ item.fixedAssetsId }}】{{ item.devName }}</a-select-option>
            </a-select>
            <a style="margin-left: 5px" @click="modalAction.show(deviceTypeMap[device.cateId], device)">更多</a>
          </template>
        </ul-table-column>

        <div v-else style="flex: 3">
          <div v-for="(item, _index) in device.usageInfos" :key="`devName_${_index}`" class="flex item">
            <div :style="{ flex: 3 }">
              <a-tag color="blue">
                <a-icon :style="{ marginRight: '10px' }" type="check-circle" />
                {{ item.devName }}
              </a-tag>
            </div>
            <div :key="`devNo_${_index}`" style="flex: 2">
              内部编号：<span :style="{ color: 'green', margin: '0 10px 0 5px', flex: 1 }">{{ item.fixedAssetsId }}</span>
            </div>
            <!-- <div :key="`devTime_${_index}`" style="flex: 3">
              <span :style="{ margin: '0 10px' }">使用时间：</span>
              <date-picker
                v-model="item.useDate"
                v-bind="moreCurrentDatePickerProps"
                :style="{width: '180px'}"
              />
            </div> -->
            <div :key="`devAction_${_index}`" :style="{ flex: 1 }">
              <a @click="device.usageInfos.splice(_index, 1)" style="color: red">移除</a>
            </div>
          </div>
        </div>
      </template>
    </ul-table>

    <!-- 设备使用选择-->
    <a-modal v-bind="modalProps" v-on="modalAction">
      <res-dev-table v-bind="selectDeviceData"></res-dev-table>
    </a-modal>
  </a-card>
</template>

<style lang="less" scoped>
@import '~@/assets/less/common/form.less';
@import '~@/assets/less/common/snippet.less';
.color();
.ul-form();
</style>
