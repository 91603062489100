'use strict'

/**
 * 样品入库申请单: 相关API
 */

import { delAction, getAction, postDataAction } from '@/api/common'

const sampleInfoStorageApplyPre = '/ms-api/sample-storage-apply'

export const sampleInfoStorageApplyApi = {
  list: (param) => getAction(`${sampleInfoStorageApplyPre}/list`, param),
  checkList: (param) => getAction(`${sampleInfoStorageApplyPre}/check-list`, param),
  findById: (id) => getAction(`${sampleInfoStorageApplyPre}/findById/${id}`),
  storage: (data) => postDataAction(`${sampleInfoStorageApplyPre}/storage`, JSON.stringify(data)),
  back: (id) => delAction(`${sampleInfoStorageApplyPre}/back/${id}`)
}
