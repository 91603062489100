<template>
  <div :style="{width: this.width}" class="check-reagent-table">
    <template v-if="listType === '0'">
      <check-reagent-table ref="checkReagent" v-bind="$props">
        <template #name="{ row: solutionRecord }">
          <a @click="turnToCate(solutionRecord)">{{ solutionRecord.name }}</a>
        </template>
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="{text:reagentText,row:reagentRow,index:reagentIndex,expanded:reagentExpanded}">
          <slot v-bind="{text:reagentText,row:reagentRow,index:reagentIndex,expanded:reagentExpanded}" :name="slot"/>
        </template>
      </check-reagent-table>
    </template>
    <template v-if="listType === '1'">
      <check-reagent-cate-table ref="checkReagentCate" v-bind="customTable" :columns="CheckReagentCateSelectActionColumn">
        <template #expandedRowRender="{text,row:record,index,expanded}">
          <check-reagent-table v-if="expanded" :cateId="text.id" :key="text.id+loadKey" v-bind="$props">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="{text:reagentText,row:reagentRow,index:reagentIndex,expanded:reagentExpanded}">
              <slot v-bind="{text:reagentText,row:reagentRow,index:reagentIndex,expanded:reagentExpanded}" :name="slot"/>
            </template>
          </check-reagent-table>
        </template>
        <template #action="{index,row}">
          <a @click="add(index,row)">
            制液
            <!-- <a-divider type="vertical" /> -->
          </a>
          <!-- <a @click="deleteCate([row])">作废</a> -->
        </template>
      </check-reagent-cate-table>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import sTable from '@/components/Table'
import { withComponentList } from '@/pages/common/mixins/list'
import { checkReagentDefaultColumn as defaultColumn } from './columns'
import CheckReagentTable from './index'
import { CheckReagentCateTable } from '@/pages/cloud-quote/check-reagent/cate/modules'
import { CheckReagentCateSelectActionColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'

export default {
  name: 'CheckReagentCateLayoutTable',

  components: { sTable, CheckReagentTable, CheckReagentCateTable },

  mixins: [withComponentList],

  props: {
    width: { type: [String, Number], required: false, default: '100%' },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    pagination: { type: Boolean, required: false, default: true },
    columns: { type: Array, required: false, default: () => (defaultColumn) },
    selectable: { type: Boolean, default: false }
  },

  data () {
    return {
      CheckReagentCateSelectActionColumn,
      scrollProp: { x: '100%', y: document.documentElement.clientHeight - 445 },
      contentTable: {
        columns: this.columns,
        bordered: false,
        style: { width: '100%' }
      },
      listType: localStorage.getItem('checkReagentListType') || '0',
      queryParam:{}
    }
  },

  computed: {

    customRowSelection () {
      return this.selectable ? this.rowSelection : null
    }
  },

  filters: {
    volumeColorFilter (data) {
      return data > 50 ? 'green' : (data > 30 ? 'orange' : 'red')
    },

    validTimeClassFilter (data) {
      return moment().isBefore(moment(data)) ? 'green' : 'red'
    },

    validTimeFilter (data) {
      return moment(data).fromNow()
    }
  },

  methods: {
    add(index,row){
      console.log(index,row);
      let cateId=row.id
      this.$emit('addReagent',cateId)
    },
    // 删除类目
    deleteCate (records) {
      const list = records?.filter(item => item.id)

      if (list && list.length) {
        const content = list.length === 1
? `请确认是否删除【${list[0].name}】类目,删除后将不可恢复！`
          : '请确认是否删除这批类目,删除后将不可恢复！'

        this.$confirm({
          title: '确认删除?',
          content,
          onOk: () => {
            checkReagentCateApi.delete({ ids: list.map(item => item.id) }).then(res => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.$emit('handleReset')
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    },
    turnToCate(record) {
      this.queryParam.id = record.cateId
      // this.queryParam.name = record.name
      this.$emit('handleChange',{target:{value:'1'}})
      // this.listType = '1'
      // localStorage.setItem('checkReagentListType', this.listType)
    },
    getVolumeColor (data) {
      return data > 50 ? 'green' : (data > 30 ? 'orange' : 'red')
    },

    getSelectData () {
      return this.rowSelection.selectedRows
    },

    handleSearch (queryParam) {
      if (this.listType === '0') {
        this.$refs.checkReagent.handleSearch(queryParam)
      } else {
        this.$refs.checkReagentCate.search()
      }
    },

    handleReset () {
      this.queryParam={}
      this.$nextTick(()=>{
        if (this.listType === '0') {
          this.$refs.checkReagent.handleReset()
        } else {
          this.$refs.checkReagentCate.reset()
        }
      })
    },

    handleReload () {
      if (this.listType === '0') {
        this.$refs.checkReagent.handleReload()
      } else {
        this.$refs.checkReagentCate.reload()
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '_index';
</style>
