<template>
  <a-modal v-bind="modalProps" v-on="modalProps">
    <a-form v-bind="dataForm">
      <a-row v-bind="rowProps">
        <a-col v-bind="colProps">
          <a-form-item label="实际领样人" v-bind="formItemLayout">
            <a-select v-bind="userSelectProp" v-decorator="decorators.pickupUser">
              <a-select-option v-for="usr in userList" :key="usr.username">{{ usr.realname }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-bind="colProps">
          <a-form-item label="实际出库时间" v-bind="formItemLayout">
            <a-date-picker v-decorator="decorators.outboundTime" v-bind="defaultDatePickerProps" :disabledDate="moreCurrentDate"></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col v-bind="colProps">
          <a-form-item label="备注信息" v-bind="formItemLayout">
            <a-textarea v-decorator="decorators.pickupNote"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #footer>
      <a-button @click="cancel">关闭</a-button>
      <a-button @click="handleSubmit" type="primary">出库</a-button>
    </template>
  </a-modal>
</template>

<script>
import mixinForm from '@/pages/common/mixins/form'
import { tsSampleinfoApi } from '@/api/sample'
import { getAction } from '@/api/common'
import { userApi } from '@/api/system'

export default {
  name: 'OutboundSampleForm',
  mixins: [mixinForm],

  data () {
    return {
      userList: [],
      currentColSize: 1,

      userSelectProp: {
        style: { width: '100%' },
        showSearch: true,
        placeholder: '选择领样人',
        filterOption: this.filterOption
      },

      sampleInfoCard: {
        title: '',
        style: {},
        headStyle: {},
        bodyStyle: {},
        bordered: true,
        type: '',
        hoverable: true
      },

      decorators: {
        pickupUser: ['pickupUser', { rules: [{ required: true, message: '请选择试剂领样人!  ' }] }],
        outboundTime: ['outboundTime', { initialValue: this.now() }],
        pickupNote: ['pickupNote']
      },

      modalProps: {
        mixWidth: 600,
        visible: false,
        closable: false,
        destroyOnClose: true,
        centered: false,
        wrapClassName: 'receive-sample-form',
        bodyStyle: {},
        title: '样品出库',
        ok: this.handleSubmit,
        cancel: this.cancel
      }
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },

  watch: {
    'modalProps.visible' (val) {
      if (val) {
        this.listUser()
      }
    }
  },

  methods: {

    listUser () {
      getAction(`${userApi.list}`, { status: 1, pageSize: 5000 }).then(result => {
        this.userList = result.data
      })
    },

    handleSubmit () {
      this.dataForm.form.validateFields((errors, values) => {
        if (!errors) {
          const resultList = this.dataList.map(item => { return Object.assign(item, values) })
          tsSampleinfoApi.outbound(resultList).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.$emit('success')
              this.cancel()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    init (data) {
      if (!data || !data.length) {
        this.$message.error('暂无样品需要出库')
        return false
      }
      this.modalProps.visible = true
      this.setFormDataByKey('pickupUser', data[0].pickupApplyUser || this.loginUser.username)
      this.dataList = data
    },

    cancel () {
      this.modalProps.visible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less">
.receive-sample-form {
  .ant-cascader-picker-label {
    width: auto;
  }
}
</style>
