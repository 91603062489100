<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          申请信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          负责人：{{ subtask.sbtLeaderName }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ subtask.createTime }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          <!--            部门：XX-->
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="4">基础信息</th>
          <td class="tdLabel">任务名称</td>
          <td colspan="5">{{ subtask.sbtTitle }}</td>
        </tr>
        <tr>
          <td class="tdLabel">项目名称</td>
          <td colspan="3">{{ subtask.projName }}
            <a v-if="subtask.projId" @click="downloadTask" style="margin-left: 10px" title="查看下单表"><a-icon type="eye"/></a>
          </td>
          <td class="tdLabel">项目编号</td>
          <td>{{ subtask.projNo }}</td>
        </tr>
        <tr>
          <td class="tdLabel">分包价格(元)</td>
          <td colspan="3">
            <b style="font-size: 20px; color: #ff0000;">
              {{ currency(subtask.sbtFinalAmount) }}
            </b>
            <div style="width: 125px;float: right;">
              <FileList :fileList="subtask.fileQuoteList"/>
            </div>
          </td>
          <td class="tdLabel">分包时间</td>
          <td>{{ subtask.sbtDate }}</td>
        </tr>
        <tr>
          <td class="tdLabel">分包项目</td>
          <td colspan="5">
            <a-card>
              <span style="white-space: pre-line;">
                {{ subtask.sbtItemContent }}
              </span>
            </a-card>
          </td>
        </tr>
        <tr>
          <th rowspan="4">分包机构</th>
          <td class="tdLabel">分包机构</td>
          <td> {{ subtask.subcontractorName }}</td>
          <td class="tdLabel">联系人</td>
          <td> {{ subtask.subcontractorContactsName }}</td>
          <td class="tdLabel">联系电话</td>
          <td>{{ subtask.subcontractorContactsPhone }}</td>
        </tr>
        <tr>
          <td class="tdLabel">账号信息</td>
          <td colspan="5">{{ subtask.subcontractorAccount }}</td>
        </tr>
        <tr>
          <td class="tdLabel">资质编号</td>
          <td> {{ subtask.subcontractorQualification }}</td>
          <td class="tdLabel">人员情况</td>
          <td> {{ subtask.sbtPerson }}</td>
          <td class="tdLabel">设备情况</td>
          <td> {{ subtask.sbtDevice }}</td>
        </tr>
        <tr>
          <td class="tdLabel">环境条件</td>
          <td>{{ subtask.sbtEnvironment }}</td>
          <td class="tdLabel">体系运行状况</td>
          <td>{{ subtask.sbtSystem }}</td>
          <td class="tdLabel">检测能力</td>
          <td>{{ subtask.sbtTestAbility }}</td>
        </tr>
        <tr>
          <td class="tdLabel">分包要求</td>
          <td colspan="5">{{ subtask.sbtCondition }}</td>
        </tr>
        <tr>
          <th rowspan="4">其他</th>
          <td class="tdLabel">附件</td>
          <td colspan="5">
            <FileList :fileList="subtask.fileList"/>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">备注</td>
          <td colspan="5">{{ subtask.sbtRemark }}</td>
        </tr>
        <tr>
          <td class="tdLabel">收款信息</td>
          <td colspan="5">
            <template v-if="orderData !== null">
              <a-tooltip>
                <template slot="title">
                  <p>合同额： <span style="font-weight: bolder;font-size: 16px;padding: 10px 0">{{ orderData.projContractAmount || 0 }}</span> 元 </p>
                  <p>已收款： <span style="color:green;font-weight: bolder;font-size: 16px;padding: 10px 0">{{ orderData.recptAmount || 0 }}</span> 元 </p>
                  <p>待确认： <span style="color:red;font-weight: bolder;font-size: 16px;padding: 10px 0">{{ orderData.recptAmountUnconfirm || 0 }}</span> 元 </p>
                </template>
                <a-badge status="error" text="未收款" v-if="orderData.recptType =='-1'"/>
                <a-badge status="error" text="部分收款" v-else-if="orderData.recptType =='0'"/>
                <a-badge status="success" text="完成收款" v-else-if="orderData.recptType=='1'"/>
              </a-tooltip>
            </template>
            <a-badge status="error" text="暂无收款信息" v-else/>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">合同信息</td>
          <td colspan="5">
            <a-badge status="success" text="有" v-if="orderData !== null && orderData.contractId"/>
            <a-badge status="error" text="暂无合同信息" v-else/>
          </td>
        </tr>
      </table>
    </a-row>
    <DownloadTaskPreview ref="downloadTask"></DownloadTaskPreview>
    <ProjectTaskDownloadModal ref="projectTaskDownloadModal"/>
  </div>
</template>

<script>

import CustomerModal from '@/pages/cloud-quote/customer/CustomerForm'
import CustomerUserModal from '@/pages/cloud-quote/customer-user/Form'
import AddressInput from '@/pages/common/map/AddressInput'
import UploadFile from '@/pages/common/UploadFile'
import FileList from '@/pages/common/file/FileList'
import JDate from '@/components/j/JDate'
import AddSubItem from './AddSubItem'
import { currency } from '@/utils/util'
import DownloadTaskPreview from '@/pages/cloud-quote/proj-task/DownloadPreview'
import ProjectTaskDownloadModal from '@/pages/cloud-quote/project/modules/ProjectTaskDownloadModal'
import { reportSendOrderApi } from '@/api/sample'

export default {
  name: 'SubTaskBaseForm',
  components: {
    AddSubItem,
    AddressInput,
    UploadFile,
    FileList,
    JDate,
    CustomerModal,
    CustomerUserModal,
    DownloadTaskPreview,
    ProjectTaskDownloadModal
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      orderData: null
    }
  },
  methods: {
    currency,
    downloadTask () {
      // this.$refs.downloadTask.show(this.subtask.projId)
      this.$refs.projectTaskDownloadModal.show(this.subtask.projId, this.subtask.projNo, this.subtask.projName)
    },
    loadAmountData () {
      reportSendOrderApi.findContract({ projId: this.subtask.projId }).then((res) => {
        if (res.code === 0) {
          this.orderData = res.data
        }
      })
    }
  },
  watch: {
    'subtask.projId': {
      handler (newValue, oldValue) {
        if (newValue && typeof newValue !== 'undefined') {
          this.loadAmountData()
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style  lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
