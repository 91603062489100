<template>
  <div>
    <a-card style="margin-top: 14px">
      <a-card :bordered="false" style="max-width: 1080px; margin: 16px auto;">
        <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
          {{ subtask.sbtTitle }}申请
        </div>
        <template v-if="subtask.sbtStatus=='0'">
          <sub-task-base-form ref="subTaskBaseFormRef" :subtask.sync="subtask" />
<!--          <div class="ant-pro-footer-toolbar" v-if="editEnable==true">-->
          <a-row justify="center" align="middle">
            <a-col style="text-align:center">
              <a-spin :spinning="btnSpinning">
                <a-button type="primary" style="margin: 10px" icon="save" @click="save(2)">提交</a-button>
              </a-spin>
            </a-col>
          </a-row>
<!--          </div>-->
        </template>
        <template v-else>
          <SubTaskBase :subtask.sync="subtask"/>
        </template>

        <template v-if="subtask.sbtStatus=='6'">
          <SubTaskApplySampleForm :subtask.sync="subtask" @ok="initInfo"/>
        </template>
        <template v-if="subtask.sbtStatus!='6'&&subtask.sbtSampleNum>0">
          <SubTaskApplySample :subtask.sync="subtask"/>
        </template>

<!--        样品出库-->
        <template v-if="subtask.sbtStatus=='7'">
          <SubTaskTransferSampleForm :subtask.sync="subtask" @ok="initInfo"/>
<!--          <sub-task-send-form :subtask.sync="subtask" @ok="initInfo" />-->
        </template>
        <template v-if="['1', '5', '8', '9', '10', '11', '12'].includes(subtask.sbtStatus)">
<!--          <sub-task-send :subtask.sync="subtask" />-->
          <SubTaskTransferSample :subtask.sync="subtask"/>
        </template>

<!--        分包流程移交节点删除，移交部分表单调整到样品出库节点填写-->
<!--        <template v-if="subtask.sbtStatus=='8'">-->
<!--          <SubTaskTransferSampleForm :subtask.sync="subtask" @ok="initInfo"/>-->
<!--        </template>-->
<!--        <template v-if="subtask.sbtStatus!='8'&&subtask.sbtTransferDate">-->
<!--          <SubTaskTransferSample :subtask.sync="subtask"/>-->
<!--        </template>-->

<!--        分包报告上传-->
<!-- subtask.sbtStatus=='9' -->
        <template v-if="['5', '9', '10', '11', '12'].includes(subtask.sbtStatus)">
          <SubTaskResultForm :subtask.sync="subtask" @ok="initInfo"/>
        </template>
<!--        subtask.sbtStatus!='9'&&subtask.fileReportList-->
        <template v-if="subtask.sbtStatus=='1'">
          <SubTaskResult :subtask.sync="subtask"/>
        </template>
<!--        <template v-for="(obj,idx) in checkDivList">-->
<!--          <SubTaskCheckForm :key="idx" v-bind="obj" :check.sync="checkList[idx]"></SubTaskCheckForm>-->
<!--        </template>-->
      </a-card>
    </a-card>
  </div>
</template>
<script>
import { projSubcontractTaskApi } from '@/api/project'
import SubTaskBase from './modules/SubTaskBase'
import SubTaskCheckForm from './modules/SubTaskCheckForm'
import SubTaskApplySample from './modules/SubTaskApplySample'
import SubTaskApplySampleForm from './modules/SubTaskApplySampleForm'
import SubTaskTransferSample from './modules/SubTaskTransferSample'
import SubTaskTransferSampleForm from './modules/SubTaskTransferSampleForm'
import SubTaskResult from './modules/SubTaskResult'
import SubTaskResultForm from './modules/SubTaskResultForm'
import moment from 'moment'
import DetailList from '@/components/tools/DetailList'
import SubTaskBaseForm from '@/pages/cloud-quote/subcontract/subcontract-task/modules/SubTaskBaseForm'

const DetailListItem = DetailList.Item

export default {
  name: 'SubtaskCheckMgr',
  components: {
    SubTaskBaseForm,
    SubTaskBase,
    SubTaskCheckForm,
    SubTaskApplySample,
    SubTaskApplySampleForm,
    SubTaskTransferSample,
    SubTaskTransferSampleForm,
    SubTaskResult,
    SubTaskResultForm,
    DetailList,
    DetailListItem
  },
  props: {
    processId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {

      editEnable: false,

      // 项目信息
      projectInfo: {},

      // 分包信息
      subtask: {},

      // 审核信息
      checkList: [],

      checkDivList: [
        { title: '技术意见',
          editable: false,
          sbtStatus: '2'
        },
        { title: '质量意见',
          editable: false,
          sbtStatus: '3'
        }
      ],
      btnSpinning: false

    }
  },
  methods: {
    moment,
    save (sbtStatus) {
      const self = this
      const formData = Object.assign({}, self.subtask)
      const saveApi = projSubcontractTaskApi.edit
      formData.sbtStatus = sbtStatus
      if (self.subtask.cateIdItemIdList) {
        formData.cateItemIds = self.subtask.cateIdItemIdList.join(',')
      }
      delete formData.subItemList
      delete formData.cateIdItemIdList
      delete formData.fileList
      delete formData.fileReportList
      delete formData.fileQuoteList
      this.btnSpinning = true
      saveApi(formData).then(result => {
        if (result.code === 0) {
          this.$message.success(`${formData.sbtTitle}操作成功`)
          // this.reload()
          this.$emit('reload')
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      }).finally(() => {
        this.btnSpinning = false
      })
    },
    getSubtask () {
      if (this.subtask.id) {
        projSubcontractTaskApi.get({ id: this.subtask.id }).then(result => {
          if (result.code === 0) {
            const subtask = result.data
            this.subtask = subtask
          }
        })
      }
    },
    listCheck () {
      if (this.subtask.id) {
        projSubcontractTaskApi.listCheck({ id: this.subtask.id }).then(result => {
          if (result.code === 0) {
            this.checkList = result.data
          }
        })
      }
    },
    validator () {
      return true
    },
    reload () {
      this.initInfo()
    },
    initInfo () {
      if (this.subtask.id) {
        this.getSubtask()
        this.listCheck()
      }
    }
  },
  watch: {
    processId (nv, ov) {
      this.subtask.id = this.processId
      this.initInfo()
    }
  },
  computed: {
  },
  filters: {
  },
  mounted () {
    this.subtask.id = this.processId
    this.initInfo()
  }
}
</script>

<style>
</style>

<style lang="less">
  .form-steps {
    max-width: 750px;
    margin: 16px auto;
  }
</style>
