<template>
  <a-modal id="check-form" v-bind="fullModal" v-on="fullModal">
    <preview-form-mix
      v-if="previewFormData.curveRecord.mixSolutionList && previewFormData.curveRecord.mixSolutionList.length"
      v-bind="previewFormData"
      v-on="previewFormData"
    ></preview-form-mix>
    <preview-form v-else v-bind="previewFormData" v-on="previewFormData"></preview-form>
    <a-drawer v-bind="checkDrawer" v-on="checkDrawer">
      <div>
        <a-row align="middle">
          <a-col :span="4" class="mxtable-title">审核</a-col>
        </a-row>
        <table class="mxtable">
          <tr>
            <th rowspan="4">审核事项</th>
            <td class="tdLabel">审核时限</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td class="tdLabel">审核内容</td>
            <td colspan="3">
              <p>（1）对复核进行审核：</p>
              <p>（2）数据的完整性；</p>
              <p>（3）程序与异常数据处置合规性；</p>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">审核结果</td>
            <td colspan="3">
              <a-radio-group v-model="checkData.checkStatus">
                <a-radio :value="checkOriginalRecordCheckStatusEnum.pass">通过</a-radio>
                <a-radio :value="checkOriginalRecordCheckStatusEnum.rejected">驳回</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">审核意见</td>
            <td colspan="3">
              <a-textarea v-model="checkData.checkNote"></a-textarea>
            </td>
          </tr>
        </table>
        <div class="check-btn">
          <a-button @click="submitCheck" type="primary">提交</a-button>
        </div>
      </div>
      <a-modal v-bind="checkCheckModal">
        <a-input placeholder="请输入登录密码进行认证" type="password" v-model="checkData.checkPwd" />
        <template #footer>
          <a-button @click="checkCheckModal.ok" type="primary">提交</a-button>
        </template>
      </a-modal>
    </a-drawer>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
      <a-button @click="fullModal.showCheck" type="primary">审核</a-button>
    </template>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import PreviewForm from '@/pages/cloud-quote/check-standard-curve-type/modules/form/PreviewForm'
import PreviewFormMix from '@/pages/cloud-quote/check-standard-curve-type/modules/form/PreviewFormMix'
import { checkOriginalRecordCheckStatusEnum } from '@/api/check/checkOriginalRecordConstant'
import { checkStandardCurveConfigApi } from '@/api/checkStandardCurve/checkStandardCurveApi'

export default {
  components: {
    PreviewForm,
    PreviewFormMix
  },
  mixins: [fullModal],
  props: {
    // ids 和 id 选择传递一个即可
    ids: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },

    id: {
      type: [String, Number],
      required: false,
      default: null
    },

    curveRecord: {
      type: Object,
      required: false,
      default: null
    },
    authMark: {
      type: Boolean,
      required: false,
      default: false
    },

    checkUser: {
      type: String || null,
      required: false,
      default: null
    }
  },

  data() {
    return {
      checkOriginalRecordCheckStatusEnum,

      drawerVisible: false,

      // 审核数据
      checkData: {
        checkUser: this.checkUser,
        checkPwd: null,
        checkStatus: null,
        checkNote: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      checkDrawer: {
        visible: false,
        width: 600,
        getContainer: false,
        closable: false,
        maskClosable: true,
        mask: true,
        wrapStyle: { position: 'absolute' },
        close: () => {
          this.checkDrawer.visible = false
        }
      },

      checkCheckModal: {
        title: '请先进行认证',
        centered: true,
        closed: false,
        width: 600,
        visible: false,
        show: () => {
          this.checkCheckModal.visible = true
        },
        cancel: () => {
          this.checkCheckModal.visible = false
        },
        ok: () => {
          if (this.checkData.checkPwd) {
            this.check()
          } else {
            this.$message.error('请输入认证密码！')
            return false
          }
        }
      },

      fullModal: {
        title: '标线配置记录审核',
        width: '960px',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },

        showCheck: () => {
          this.checkDrawer.visible = true
        }
      },
      previewFormData: {
        id: this.id,
        curveRecord: this.curveRecord
      }
    }
  },

  computed: {},

  methods: {
    // 提交审核
    submitCheck() {
      if (this.checkCheckData()) {
        this.check()
      }
    },

    // 审核检测原始记录表
    check() {
      if (!this.ids.length && !this.id) {
        this.$message.error('无有效的数据！')
        return false
      }

      const checkDataArr = (this.ids.length && this.ids.map((item) => Object.assign({ id: item }, this.checkData))) || []
      this.id && checkDataArr.push(Object.assign({ id: this.id }, this.checkData))

      checkStandardCurveConfigApi.check(checkDataArr).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
          this.fullModal.cancel()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 校验审核数据
    checkCheckData() {
      if (this.checkData.checkStatus) {
        return true
      } else {
        this.$message.error('请选择审核结果！')
        return false
      }
    },

    // 展示当前组件
    show() {
      this.fullModal.show()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/table/index2';

.check-form {
  position: absolute;
  bottom: 100px;
  width: 100%;
  background: #ff0000;
  z-idnex: 1;
}

.mxtable td p {
  margin-bottom: 0.5em;
}

.check-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
