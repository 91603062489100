'use strict'

/**
 * 样品处置管理API
 */

import { getAction, postDataAction, downloadGetAction } from '@/api/common'

const sampleInfoDestroyPre = '/ms-api/ts-sampleinfo-destroy'

export const sampleInfoDestroyApi = {
  list: (param) => getAction(`${sampleInfoDestroyPre}/list`, param),
  download: (param) => downloadGetAction(`${sampleInfoDestroyPre}/download`, param),
  apply: (data) => postDataAction(`${sampleInfoDestroyPre}/apply`, data),
  findDetail: (param) => getAction(`${sampleInfoDestroyPre}/find-detail`, param)
}
