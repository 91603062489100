import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 标液标定类型  字典枚举
export const checkStandardSolutionDemarcatesTypeEnum = {
  // 标定
  demarcate: '1',
  // 核定
  verify: '2'
}

//  标液标定类型 选择项
export const checkStandardSolutionDemarcatesTypeOptions = [
  { label: '标定', value: checkStandardSolutionDemarcatesTypeEnum.demarcate, reagentShow: true, color: 'blue' },
  { label: '核定', value: checkStandardSolutionDemarcatesTypeEnum.verify, reagentShow: true, color: 'green' }
]

export const checkStandardSolutionDemarcatesTypeMap = tranOptionsToMap(checkStandardSolutionDemarcatesTypeOptions)
export const checkStandardSolutionDemarcatesTypeDataMap = tranOptionsToDataMap(checkStandardSolutionDemarcatesTypeOptions)

export const checkStandardSolutionDemarcatesStatusEnum = {
  // 不标定
  notNeed:'0',
  // 有标定
  have:'1',
  // 未标定
  notHave:'2',
  // 标定过期
  overdue:'3'
}
export const checkStandardSolutionDemarcatesStatusOptions = [
  { label: '不标定', value: checkStandardSolutionDemarcatesStatusEnum.notNeed, reagentShow: true, color: 'blue' },
  { label: '有标定', value: checkStandardSolutionDemarcatesStatusEnum.have, reagentShow: true, color: 'green' },
  { label: '未标定', value: checkStandardSolutionDemarcatesStatusEnum.notHave, reagentShow: true, color: 'red' },
  { label: '标定过期', value: checkStandardSolutionDemarcatesStatusEnum.overdue, reagentShow: true, color: 'red' }
]
export const checkStandardSolutionDemarcatesStatusDataMap = tranOptionsToDataMap(checkStandardSolutionDemarcatesStatusOptions)
