<template>
  <a-card title="下单">
    <div class="detail-list">
      <a-row class="row" v-bind="listRow">
        <a-col v-bind="listCol">
          <div class="label">下单表：</div>
          <div class="content"><a @click="downloadTask" title="查看报价单"><a-icon type="eye" theme="twoTone"/>查看报价单</a></div>

        </a-col>
        <a-col v-bind="listCol">
          <div class="label">现场联系：</div>
          <div class="content">{{ info.taskOnsiteContact }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">联系电话：</div>
          <div class="content">{{ info.taskOnsitePhone }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">下单日期：</div>
          <div class="content">{{ info.taskDate }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">下单人：</div>
          <div class="content">{{ info.createUser }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">创建时间：</div>
          <div class="content">{{ info.createTime }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">备注：</div>
          <div class="content">
            {{ info.remark | nullReplace }}
          </div>
        </a-col>
      </a-row>
    </div>
    <ProjectTaskDownloadModal ref="projectTaskDownloadModal"/>
  </a-card>
</template>

<script>

import { projTaskApi } from '@/api/project'
import ProjectTaskDownloadModal from '@/pages/cloud-quote/project/modules/ProjectTaskDownloadModal'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'PVTaskView',
  components: {
    ProjectTaskDownloadModal
  },
  props: {
    projId: {
      type: Number,
      default: null
    },
    projInfo: {
      type: Object,
      default: () => { }
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      listRow: {
        gutter: [8, 12]
      },
      listCol: {
        xxl: { span: 8 },
        xl: { span: 8 },
        lg: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
        xs: { span: 8 }
      },
      itemCol: 3,
      info: {}
    }
  },
  methods: {
    getTaskByProjId () {
      if (this.projId) {
        projTaskApi.getTaskByProjId({ projId: this.projId }).then(result => {
          if (result.code === 0) {
            if (result.data) {
              this.info = result.data
            }
          }
        })
      }
    },
    downloadTask () {
      this.$refs.projectTaskDownloadModal.show(this.projInfo.id, this.projInfo.projNo, this.projInfo.projName)
    }
  },
  filters: {
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  watch: {
    projId: {
      handler (nv, ov) {
        this.getTaskByProjId()
      },
      immediate: true
    },
    data: {
      handler (nv, ov) {
        this.info = nv
      },
      immediate: true
    }
  }
}
</script>

<style lang="less">
  .detail-list{
    margin: 8px 44px;
    .row{
    }
    .label{
      color: rgba(0, 0, 0, 0.85);
      display: table-cell;
      line-height: 20px;
      margin-right: 8px;
      padding-bottom: 10px;
      white-space: nowrap;
    }
    .content{
      color: rgba(0, 0, 0, 0.65);
      display: table-cell;
      min-height: 22px;
      line-height: 22px;
      padding-bottom: 10px;
      width: 100%;
    }
  }
</style>
