<template>
  <div class="footer">
    <div class="copyright">
      <div style="float:left;"><s-breadcrumb v-if="currentLayout === 'topmenu'" /></div>
      <div style="text-align: center">
        <a @click="jumpVersion">Version: {{ sys_version }}</a> | Copyright &copy; 2020-2021 <a :href="configMap.COMPANY_URL || '#'" :target="configMap.COMPANY_URL ? '_blank' : ''">{{ configMap.COMPANY_NAME }}</a>&nbsp;&nbsp;&nbsp;&nbsp;技术支持：{{ configMap.TECH_SUPPORT }}</div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/tools/Breadcrumb'
import Vue from 'vue'
import { mapState } from 'vuex'
import { SYS_VERSION } from '@/store/mutation-types'

export default {
  name: 'GlobalFooter',
  components: {
    's-breadcrumb': Breadcrumb
  },
  computed: {
    ...mapState({
      currentLayout: state => state.app.layout,
      configMap: state => state.app.sys_config
    })
  },
  data () {
    return {
      sys_version: ''
    }
  },
  mounted () {
    this.sys_version = this.$store.state.app.sys_version || Vue.ls.get(SYS_VERSION)
  },
  methods: {
    jumpVersion () {
      this.$router.push({ path: '/sys_version' })
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  position: fixed;
  bottom: 0;
  border-top: #EAEAEA 1px solid;
  background: #fff;
  height: 30px;
  width: 100%;
  line-height: 28px;
  padding: 0 15px;

  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }

  .ant-breadcrumb {
    font-size: 12px;
    line-height: unset;
  }
}
</style>
