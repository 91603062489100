<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import { ref, reactive, computed, nextTick, watchEffect } from 'vue'
import UlTable, { UlTableRow, UlTableColumn } from '@/pages/common/ul-table/Table'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import { useDate } from '@/pages/common/compositions/date/useDate'
import { CheckStandardCurveTableModal } from '@/pages/cloud-quote/check-standard-curve/modules'
import { checkStandardCurveCheckStatusEnum } from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { message, DatePicker } from 'ant-design-vue'
import { qualityOptionDataMap } from '@/api/itemCheckDetail/itemCheckDetailConstant'
import {
  noActionCurveDrawColumns,
  actionColumns,
  baseCurveDrawColumns,
  curveDrawModels,
  remarkCurveDrawColumns
} from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import { groupBy } from 'lodash'

const props = defineProps({
  modelValue: { type: Array, required: true },
  curveVerifies: { type: Array, required: true },
  selectCheckGuide: { type: Object, required: false, default: undefined },
  defaultMakeDate: { type: String, required: false, default: undefined },
  columns: { type: Array, required: false, default: () => noActionCurveDrawColumns },
  itemList: { type: Array, required: false, default: () => [] },
  compositeItem: { type: String, required: false, default: undefined }
})

const emit = defineEmits(['update:modelValue', 'update:curveVerifies'])
const curveList = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const curveVerifyList = computed({
  get() {
    return props.curveVerifies
  },
  set(value) {
    emit('update:curveVerifies', value)
  }
})

const curveVerifyMap = computed(() => {
  return groupBy(curveVerifyList.value, 'curveId')
})

const { moment, now, timeFormat } = useDate()
const nowStr = timeFormat(now())
const selectCurveTableData = reactive({
  standardIds: [],
  methodIds: [],
  checkStatus: checkStandardCurveCheckStatusEnum.pass,
  validCheckTime: ''
  // validTime: nowStr,
  // validFlag: true
})

watchEffect(() => {
  props.defaultMakeDate && (selectCurveTableData.validCheckTime = props.defaultMakeDate)
})

// 根据曲线模板id，返回对应的曲线点位表列数据
const initSiteColumns = (siteId = 0) => {
  console.log('3333', siteId)
  if (siteId) {
    const columns = curveDrawModels.filter((item) => item.id === siteId)[0].columns
    const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns]))
    return allColumns
  } else {
    return noActionCurveDrawColumns
  }
}

const curveDrawTable = {
  rowKey: (item) => `${item.id}`,
  bordered: true,
  size: 'small',
  dataSource: [],
  columns: props.columns,
  pagination: false
}

const mixItemId = ref(0)
const drawList = ref([])
const clickTab = (curveInfo) => {
  if (curveInfo.drawList.length) {
    let currentDrawList = curveInfo.drawList.filter((item) => item.itemId === mixItemId.value) // 点位数据
    drawList.value.splice(0, drawList.value.length, ...currentDrawList)
  }
}

const handleDefaultColumns = () => {
  console.log(curveList.value)

  if (curveList.value && curveList.value.length) {
    let columns = initSiteColumns(curveList.value[0].siteModelId)
    curveDrawTable.columns.splice(0, curveDrawTable.columns.length, ...columns)
    if (curveList.value[0].mixSolutionList && curveList.value[0].mixSolutionList.length) {
      mixItemId.value = curveList.value[0].mixSolutionList[0].itemId
      clickTab(curveList.value[0])
    } else {
      drawList.value.splice(0, drawList.value.length, ...(curveList.value[0].drawList || []))
    }
  }
}
handleDefaultColumns()

const selectCurveRef = ref()
const handleShowSelectCurve = () => {
  if (!props.selectCheckGuide) {
    message.error('请先选择检测标准')
    return false
  }
  const { standardId, methodId } = props.selectCheckGuide
  selectCurveTableData.standardIds.splice(0, selectCurveTableData.standardIds.length, standardId)
  selectCurveTableData.methodIds.splice(0, selectCurveTableData.methodIds.length, methodId)
  selectCurveRef.value?.show()
}

const handleSelectCurveSuccess = (records) => {
  const existIds = curveList.value.map((item) => item.id)
  const notExistData = records?.filter((item) => !existIds.includes(item.id)) || []
  if (notExistData.length) {
    curveList.value.push.apply(curveList.value, notExistData)
    if (props.compositeItem) {
      let curveVerifies = []
      notExistData.forEach((item) => {
        let compositeItems = props.compositeItem.split(',')
        compositeItems.forEach((item2) => {
          let obj = {
            curveId: item.id,
            siteNo: 0,
            standardCurveAbsorbance: null,
            siteAbsorbance: null,
            relativeDeviation: null,
            makeDate: moment(props.defaultMakeDate ?? nowStr),
            note: null
          }
          let items = props.itemList.filter((item) => item.id === Number(item2))
          console.log(items)
          if (items.length > 0) {
            obj.itemId = Number(item2)
            obj.itemName = items[0].name
          }
          curveVerifies.push(obj)
        })
      })
      curveVerifyList.value.push.apply(curveVerifyList.value, curveVerifies)
    } else {
      curveVerifyList.value.push.apply(
        curveVerifyList.value,
        notExistData.map((item) => ({
          curveId: item.id,
          siteNo: 0,
          standardCurveAbsorbance: null,
          siteAbsorbance: null,
          relativeDeviation: null,
          makeDate: moment(props.defaultMakeDate ?? nowStr),
          note: null
        }))
      )
    }
    if (curveList.value[0].mixSolutionList && curveList.value[0].mixSolutionList.length) {
      mixItemId.value = curveList.value[0].mixSolutionList[0].itemId
      clickTab(curveList.value[0])
    } else {
      drawList.value.splice(0, drawList.value.length, ...(curveList.value[0].drawList || []))
    }
  }
  let curveColumns = initSiteColumns(records[0].siteModelId)
  curveDrawTable.columns.splice(0, curveDrawTable.columns.length, ...curveColumns)
  console.log(curveList.value, curveVerifyList.value)
}

const calcRD = (cIndex, curveId) => {
  const curveVerifyList = curveVerifyMap.value[curveId]
  const curveVerify = curveVerifyList[cIndex]
  if (curveVerify.standardCurveAbsorbance != null && !isNaN(curveVerify.standardCurveAbsorbance) && curveVerify.siteAbsorbance != null && !isNaN(curveVerify.siteAbsorbance)) {
    curveVerify.relativeDeviation = Math.floor(((curveVerify.standardCurveAbsorbance - curveVerify.siteAbsorbance) / curveVerify.standardCurveAbsorbance) * 10000) / 100
  }
}

// 移除时把曲线和点位都清空，避免下次新增时新的曲线会有多个点位，曲线和点位都应该只有一个
const delCurve = () => {
  curveList.value.splice(0, curveList.value.length)
  curveVerifyList.value.splice(0, curveVerifyList.value.length)
  console.log(curveList.value, curveVerifyList.value)
}

const map = { 1: '标准', 2: '企业' }
const qcRefSource = computed(() => (type) => map[type] || '')

const { baseFormCard, moreCurrentDatePickerProps } = useFormProps()
</script>

<template>
  <a-card title="标准曲线" v-bind="baseFormCard">
    <template #extra>
      <div class="fr">
        <template v-if="selectCheckGuide">
          <a-tooltip :getPopupContainer="() => $el">
            <template #title>
              数量： ≥ <span class="green">{{ selectCheckGuide.needCurveNum }}</span>
              <template v-if="selectCheckGuide.needCurveNumType !== 'fix'"> %</template>
              <br />
              参考：
              <a-tag>{{ qcRefSource(selectCheckGuide.needCurveRefSource) }}</a-tag>
            </template>
            <a-tag :color="qualityOptionDataMap[selectCheckGuide.needCurve]?.color">{{ qualityOptionDataMap[selectCheckGuide.needCurve]?.label }}</a-tag>
          </a-tooltip>
        </template>
        <!-- <a-tooltip>
          <template #title>
            有选中的曲线时不能再添加其它曲线
          </template>
          <a :style="{ marginLeft: '65px' }" :disabled="curveList.length" @click="handleShowSelectCurve">
            <a-icon type="plus"></a-icon>
          </a>
        </a-tooltip> -->
        <a :style="{ marginLeft: '65px' }" v-if="curveList.length === 0" @click="handleShowSelectCurve">
          <a-icon type="plus"></a-icon>
        </a>
      </div>
    </template>

    <template v-if="curveList.length">
      <!-- <template v-for="(curveInfo, index) in curveList"> -->
      <!-- :key="`curve_content_${index}`" -->
      <ul-table :data-source="curveList" row-component="div" :hidden-title="true" :row-attrs="{ class: 'average' }">
        <template #default="{ data: curveInfo }">
          <ul-table-row style="border: 0">
            <ul-table-column data-index="curveNo" :data="curveInfo" title="曲线编号" />
            <ul-table-column data-index="createUserName" :data="curveInfo" title="配置人" />
            <ul-table-column data-index="makeDate" :data="curveInfo" title="配置日期" />
            <ul-table-column title="有效期">
              <template v-if="!curveInfo.validTime">
                <span class="orange"> 暂未配置</span>
              </template>

              <template v-else>
                <span>{{ curveInfo.validTime }}</span>
              </template>
            </ul-table-column>
            <ul-table-column title="">
              <a @click="delCurve()" style="color: red">移除</a>
            </ul-table-column>
          </ul-table-row>

          <ul-table-row title="">
            <div v-if="curveInfo.mixSolutionList && curveInfo.mixSolutionList.length">
              <a-tabs style="background: #fff" v-model="mixItemId" @change="clickTab(curveInfo)">
                <a-tab-pane v-for="(v, i) in curveInfo.mixSolutionList" :tab="v.itemName" :key="v.itemId"></a-tab-pane>
              </a-tabs>
              <a-table :dataSource="drawList" v-bind="curveDrawTable">
                <template v-for="col in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text">
                  {{ text }}
                </template>
              </a-table>
            </div>

            <template v-else>
              <a-table :dataSource="curveInfo.drawList" v-bind="curveDrawTable">
                <template v-for="col in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text">
                  {{ text }}
                </template>
              </a-table>
            </template>
          </ul-table-row>

          <template v-for="(curveVerifyInfo, cIndex) in curveVerifyMap[curveInfo.id]">
            <ul-table-row :key="`curve_verify_info_${cIndex}`" class="curve-verify-info-row">
              <ul-table-row style="border: 0; margin: 0; padding: 0">
                <ul-table-column title="因子" v-if="curveVerifyInfo.itemName">
                  <a-input style="width: 200px" v-model="curveVerifyInfo.itemName" :disabled="true"></a-input>
                </ul-table-column>

                <ul-table-column title="点位号">
                  <a-input-number v-model="curveVerifyInfo.siteNo" :min="0"></a-input-number>
                </ul-table-column>

                <ul-table-column title="配置时间">
                  <date-picker v-model="curveVerifyInfo.makeDate" v-bind="moreCurrentDatePickerProps" :style="{ width: '180px' }" />
                </ul-table-column>

                <ul-table-column title="标准值(X)">
                  <a-input-number v-model="curveVerifyInfo.standardCurveAbsorbance" @change="calcRD(cIndex, curveInfo.id)"></a-input-number>
                </ul-table-column>

                <ul-table-column title="现测值(Y)">
                  <a-input-number v-model="curveVerifyInfo.siteAbsorbance" @change="calcRD(cIndex, curveInfo.id)"></a-input-number>
                </ul-table-column>

                <ul-table-column title="相对偏差"> <a-input-number v-model="curveVerifyInfo.relativeDeviation"></a-input-number> % </ul-table-column>
              </ul-table-row>

              <ul-table-row style="border: 0; margin: 10px 0 0; padding: 0">
                <ul-table-column :hidden-title="true" style="flex: 1">
                  <a-textarea v-model="curveVerifyInfo.note" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="备注信息"></a-textarea>
                </ul-table-column>
              </ul-table-row>
            </ul-table-row>
          </template>
        </template>
      </ul-table>
      <!-- </template> -->
    </template>
    <template v-else>
      <div class="data prompt">
        <div class="no-data">暂无数据</div>
      </div>
    </template>

    <!-- 标准曲线选择 -->
    <check-standard-curve-table-modal
      :query-param="selectCurveTableData"
      :item-list="itemList"
      ref="selectCurveRef"
      @success="handleSelectCurveSuccess"
    ></check-standard-curve-table-modal>
  </a-card>
</template>

<style lang="less">
.curve-verify-info-row {
  display: block !important;
}
</style>