import { render, staticRenderFns } from "./FlowProcessDetail.vue?vue&type=template&id=790d0dec&scoped=true&"
import script from "./FlowProcessDetail.vue?vue&type=script&lang=js&"
export * from "./FlowProcessDetail.vue?vue&type=script&lang=js&"
import style0 from "./FlowProcessDetail.vue?vue&type=style&index=0&id=790d0dec&prod&lang=less&scoped=true&"
import style1 from "./FlowProcessDetail.vue?vue&type=style&index=1&id=790d0dec&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790d0dec",
  null
  
)

export default component.exports