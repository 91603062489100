<template>
  <a-modal v-bind="manualEntryModal" v-on="manualEntryModal">
    <template #title>
      手动录入&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="cateName && siteName"
        >分类：<span style="font-weight: 600">{{ cateName }}</span
        >&nbsp;&nbsp;点位：<span style="font-weight: 600">{{ siteName }}</span></span
      >
    </template>
    <a-card v-for="(item, index) in cards" :key="index" :title="item.title">
      <hot-table
        :ref="'table_' + index"
        :settings="item.hotSetting"
        :colHeaders="item.hotSetting.columns?.map((item2) => item2.title)"
        language="zh-CN"
        style="width: 100%"
      ></hot-table>
    </a-card>
    <!-- <a-card>
      <hot-table ref="table" :settings="hotSetting" :colHeaders="hotSetting.columns.map((item) => item.title)" language="zh-CN" style="width: 100%"></hot-table>
    </a-card> -->
    <template #footer>
      <div style="text-align: right">
        <a-button :disabled="loading" @click="manualEntryModal.cancel()">取消</a-button>
        <a-button type="primary" :loading="loading" @click="manualEntryModal.ok()">确定</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { manualEntry } from '@/api/projectStandard/projectStandardApi'
import fullModal from '@/pages/common/mixins/modal/full'

import { HotTable } from '@handsontable/vue'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import { registerLanguageDictionary, zhCN } from 'handsontable/i18n'
import { groupBy, orderBy, unionBy, cloneDeep } from 'lodash'

registerAllModules()
registerLanguageDictionary(zhCN)

const fullModalData = fullModal.data().fullModal
export default {
  name: 'ManualEntryModal',
  components: { HotTable },
  data() {
    return {
      itemList: [],
      /** hot table 的配置信息 */
      hotSetting: {
        height: 'auto',
        width: '100%',
        stretchH: 'all',
        licenseKey: 'non-commercial-and-evaluation',
        className: 'htMiddle htCenter',
        currentHeaderClassName: 'ht__highlight',
        activeHeaderClassName: 'ht__active_highlight',
        // columnHeaderHeight: 25,
        allowRemoveCol: false,
        allowInsertCol: false,
        allowInsertRow: false,
        allowRemoveRow: false,

        rowHeaders: true,
        autoColumnSize: true,
        // autoRowSize: true,
        manualRowResize: true,
        columnSorting: true,
        columns: [
          { title: '分类', data: 'cateName', readOnly: true },
          { title: '点位', data: 'siteName', readOnly: true },
          { title: '检测项', data: 'itemName', readOnly: true },
          { title: '执行标准', data: 'manualStandard' },
          { title: '标准值', data: 'manualLimitVal' },
          { title: '单位', data: 'manualUnit' }
        ]
      },
      cards: [
        {
          title: '标准限值',
          standardType: 1,
          data: [],
          hotSetting: {
            height: 'auto',
            width: '100%',
            stretchH: 'all',
            licenseKey: 'non-commercial-and-evaluation',
            className: 'htMiddle htCenter',
            currentHeaderClassName: 'ht__highlight',
            activeHeaderClassName: 'ht__active_highlight',
            // columnHeaderHeight: 25,

            allowRemoveCol: false,
            allowInsertCol: false,
            allowInsertRow: false,
            allowRemoveRow: false,

            rowHeaders: true,
            autoColumnSize: true,
            // autoRowSize: true,
            manualRowResize: true,
            columnSorting: true,

            afterChange: (changes, source) => {
              console.log(changes, source)
              const self = this
              this.valueChange(changes, source, 1)
            }
          }
        },
        {
          title: '范围限值',
          standardType: 2,
          data: [],
          hotSetting: {
            height: 'auto',
            width: '100%',
            stretchH: 'all',
            licenseKey: 'non-commercial-and-evaluation',
            className: 'htMiddle htCenter',
            currentHeaderClassName: 'ht__highlight',
            activeHeaderClassName: 'ht__active_highlight',
            // columnHeaderHeight: 25,

            allowRemoveCol: false,
            allowInsertCol: false,
            allowInsertRow: false,
            allowRemoveRow: false,

            rowHeaders: true,
            autoColumnSize: true,
            // autoRowSize: true,
            manualRowResize: true,
            columnSorting: true,

            afterChange: (changes, source) => {
              console.log(changes, source)
              const self = this
              this.valueChange(changes, source, 2)
            }
          }
        },
        {
          title: '描述限值',
          standardType: 3,
          data: [],
          hotSetting: {
            height: 'auto',
            width: '100%',
            stretchH: 'all',
            licenseKey: 'non-commercial-and-evaluation',
            className: 'htMiddle htCenter',
            currentHeaderClassName: 'ht__highlight',
            activeHeaderClassName: 'ht__active_highlight',
            // columnHeaderHeight: 25,

            allowRemoveCol: false,
            allowInsertCol: false,
            allowInsertRow: false,
            allowRemoveRow: false,

            rowHeaders: true,
            autoColumnSize: true,
            // autoRowSize: true,
            manualRowResize: true,
            columnSorting: true,

            afterChange: (changes, source) => {
              console.log(changes, source)
              const self = this
              this.valueChange(changes, source, 3)
            }
          }
        }
      ],
      defaultColumns: [
        // { title: '分类', data: 'cateName', readOnly: true },
        // { title: '点位', data: 'siteName', readOnly: true },
        { title: '检测项', data: 'itemName', readOnly: true },
        { title: '执行标准', data: 'manualStandard' },
        { title: '选择限值类型', data: 'standardTypeName', editor: 'select', selectOptions: this.standardTypeSelectOptions }
      ],
      columns1: [
        { title: '标准值', data: 'manualLimitVal' },
        { title: '单位', data: 'manualUnit' }
      ],
      columns2: [
        { title: '最低标准值', data: 'manualLowerLimitVal' },
        { title: '最高标准值', data: 'manualUpperLimitVal' },
        { title: '单位', data: 'manualUnit' }
      ],
      columns3: [{ title: '选择标准值', data: 'manualLimitValName', editor: 'select', selectOptions: this.limitValTypesSelectOptions, renderer: this.manualLimitValRenderer }],

      emissionRateColumns1: [
        { title: '标准值', data: 'manualLimitVal' },
        { title: '单位', data: 'manualUnit' },
        { title: '标准值', data: 'manualEmissionRateLimitValue' },
        { title: '单位', data: 'manualEmissionRateUnit' }
      ],
      emissionRateNestedHeaders1: [
        [
          '检测项',
          '执行标准',
          '选择限值类型',
          {
            label: '排放浓度',
            colspan: 2
          },
          {
            label: '排放速率',
            colspan: 2
          }
        ],
        ['', '', '', '标准值', '单位', '标准值', '单位']
      ],
      emissionRateColumns2: [
        { title: '最低标准值', data: 'manualLowerLimitVal' },
        { title: '最高标准值', data: 'manualUpperLimitVal' },
        { title: '单位', data: 'manualUnit' },
        { title: '最低标准值', data: 'manualEmissionRateLowerLimitVal' },
        { title: '最高标准值', data: 'manualEmissionRateUpperLimitVal' },
        { title: '单位', data: 'manualEmissionRateUnit' }
      ],
      emissionRateNestedHeaders2: [
        [
          '检测项',
          '执行标准',
          '选择限值类型',
          {
            label: '排放浓度',
            colspan: 3
          },
          {
            label: '排放速率',
            colspan: 3
          }
        ],
        ['', '', '', '最低标准值', '最高标准值', '单位', '最低标准值', '最高标准值', '单位']
      ],
      emissionRateColumns3: [
        { title: '排放浓度 选择标准值', data: 'manualLimitValName', editor: 'select', selectOptions: this.limitValTypesSelectOptions, renderer: this.manualLimitValRenderer },
        {
          title: '排放速率 选择标准值',
          data: 'manualEmissionRateLimitValName',
          editor: 'select',
          selectOptions: this.limitValTypesSelectOptions,
          renderer: this.manualEmissionRateLimitValRenderer
        }
      ],

      manualEntryModal: Object.assign({}, fullModalData, {
        ref: 'manualEntryModal',
        // title: '手动录入',
        ok: () => {
          this.save()
        },
        cancel: () => {
          this.manualEntryModal.visible = false
          this.itemList = []
          this.cards.forEach((item) => {
            item.data = []
          })
        }
      }),
      standardTypes: [
        {
          label: '标准限值',
          value: 1
        },
        {
          label: '范围限值',
          value: 2
        },
        {
          label: '描述限值',
          value: 3
        }
      ],
      limitValTypes: [
        {
          label: '有',
          value: '1'
        },
        {
          label: '无',
          value: '0'
        }
      ],
      cateName: '',
      siteName: '',
      loading: false,
      emissionRate: 0
    }
  },
  methods: {
    init(itemList, emissionRate) {
      this.emissionRate = emissionRate
      this.cateName = itemList?.[0]?.cateName
      this.siteName = itemList?.[0]?.siteName
      this.loading = false
      this.manualEntryModal.visible = true
      this.itemList = cloneDeep(itemList)
      // console.log(this.itemList)
      this.cards.forEach((item) => {
        item.data.splice(0, item.data.length)
        let columns = []
        if (emissionRate) {
          if (item.standardType === 1) {
            columns = this.defaultColumns.concat(this.emissionRateColumns1)
            item.hotSetting.nestedHeaders = this.emissionRateNestedHeaders1
            item.hotSetting.afterGetColHeader = this.afterGetColHeader
          } else if (item.standardType === 2) {
            columns = this.defaultColumns.concat(this.emissionRateColumns2)
            item.hotSetting.nestedHeaders = this.emissionRateNestedHeaders2
            item.hotSetting.afterGetColHeader = this.afterGetColHeader
          } else if (item.standardType === 3) {
            columns = this.defaultColumns.concat(this.emissionRateColumns3)
            delete item.hotSetting.nestedHeaders
            delete item.hotSetting.afterGetColHeader
          }
        } else {
          if (item.standardType === 1) {
            columns = this.defaultColumns.concat(this.columns1)
          } else if (item.standardType === 2) {
            columns = this.defaultColumns.concat(this.columns2)
          } else if (item.standardType === 3) {
            columns = this.defaultColumns.concat(this.columns3)
          }
          delete item.hotSetting.nestedHeaders
          delete item.hotSetting.afterGetColHeader
        }
        item.hotSetting.columns = columns
      })
      this.itemList.forEach((item) => {
        // if (item.modeType !== 1) {
        //   item.manualStandard = item.standardName
        //   item.manualLimitVal = item.limitValType === '1' ? item.limitValEdit : item.limitValue
        //   item.manualUnit = item.limitValType === '1' ? item.unitEdit : item.unit
        // }

        let obj = {
          ...item
        }
        if (obj.itemName.toUpperCase().startsWith('PH') || obj.itemName.toUpperCase().startsWith('PH值') || obj.itemName.includes('臭氧')) {
          obj.standardType = 2
        }
        if (obj.modeType !== 1) {
          obj.manualStandard = obj.standardName
        }
        if (obj.standardType === 2) {
          if (obj.modeType !== 1) {
            let arr = []
            if (obj.limitValType === '1') {
              arr = obj.limitValEdit?.split('~') || []
            } else {
              arr = obj.limitValue?.split('~') || []
            }
            obj.manualLowerLimitVal = arr.length > 0 ? Number(arr[0]) : null
            obj.manualUpperLimitVal = arr.length > 1 ? Number(arr[1]) : null
            obj.manualUnit = obj.limitValType === '1' ? obj.unitEdit : obj.unit
            if (emissionRate) {
              let emissionRateArr = []
              if (obj.limitValType === '1') {
                emissionRateArr = obj.emissionRateLimitValEdit?.split('~') || []
              } else {
                emissionRateArr = obj.emissionRateLimitValue?.split('~') || []
              }
              obj.manualEmissionRateLowerLimitVal = emissionRateArr.length > 0 ? Number(emissionRateArr[0]) : null
              obj.manualEmissionRateUpperLimitVal = emissionRateArr.length > 1 ? Number(emissionRateArr[1]) : null
              obj.manualEmissionRateUnit = obj.limitValType === '1' ? obj.emissionRateUnitEdit : obj.emissionRateUnit
            }
          } else {
            let arr = obj.manualLimitVal?.split('~') || []
            obj.manualLowerLimitVal = arr.length > 0 ? Number(arr[0]) : null
            obj.manualUpperLimitVal = arr.length > 1 ? Number(arr[1]) : null
            if (emissionRate) {
              let emissionRateArr = obj.manualEmissionRateLimitValue?.split('~') || []
              obj.manualEmissionRateLowerLimitVal = emissionRateArr.length > 0 ? Number(emissionRateArr[0]) : null
              obj.manualEmissionRateUpperLimitVal = emissionRateArr.length > 1 ? Number(emissionRateArr[1]) : null
            }
          }
          let standardTypeItem = this.standardTypes.find((item2) => item2.value === obj.standardType)
          obj.standardTypeName = standardTypeItem.label
          this.cards[1].data.push(obj)
        } else if (obj.standardType === 3) {
          if (obj.modeType !== 1) {
            obj.manualLimitVal = obj.limitValType === '1' ? obj.limitValEdit : obj.limitValue
            if (emissionRate) {
              obj.manualEmissionRateLimitValue = obj.limitValType === '1' ? obj.emissionRateLimitValEdit : obj.emissionRateLimitValue
            }
          }
          let limitValTypeItem = this.limitValTypes.find((item2) => item2.value === obj.manualLimitVal)
          obj.manualLimitValName = limitValTypeItem.label
          let standardTypeItem = this.standardTypes.find((item2) => item2.value === obj.standardType)
          obj.standardTypeName = standardTypeItem.label
          this.cards[2].data.push(obj)
        } else {
          if (!obj.standardType) {
            obj.standardType = 1
            obj.standardType = 1
          }
          if (obj.modeType !== 1) {
            obj.manualLimitVal = obj.limitValType === '1' ? obj.limitValEdit : obj.limitValue
            obj.manualUnit = obj.limitValType === '1' ? obj.unitEdit : obj.unit
            if (emissionRate) {
              obj.manualEmissionRateLimitValue = obj.limitValType === '1' ? obj.emissionRateLimitValEdit : obj.emissionRateLimitValue
              obj.manualEmissionRateUnit = obj.limitValType === '1' ? obj.emissionRateUnitEdit : obj.emissionRateUnit
            }
          }
          let standardTypeItem = this.standardTypes.find((item2) => item2.value === obj.standardType)
          obj.standardTypeName = standardTypeItem.label
          this.cards[0].data.push(obj)
        }
      })
      for (let i = 0; i < this.cards.length; i++) {
        let item = this.cards[i]
        item.hotSetting.data = item.data
        // item.hotSetting.mergeCells = [
        //   { row: 0, col: 0, rowspan: item.data.length, colspan: 1 },
        //   { row: 0, col: 1, rowspan: item.data.length, colspan: 1 }
        // ]
        setTimeout(() => {
          this.$refs['table_' + i]?.[0].hotInstance.render()
        }, 1000)
      }

      // this.hotSetting.data = this.itemList
      // this.hotSetting.mergeCells = [
      //   { row: 0, col: 0, rowspan: this.itemList.length, colspan: 1 },
      //   { row: 0, col: 1, rowspan: this.itemList.length, colspan: 1 }
      // ]

      // if (emissionRate === 1) {
      //   this.hotSetting.columns = [
      //     { title: '分类', data: 'cateName', readOnly: true },
      //     { title: '点位', data: 'siteName', readOnly: true },
      //     { title: '检测项', data: 'itemName', readOnly: true },
      //     { title: '执行标准', data: 'manualStandard' },
      //     { title: '排放浓度标准值', data: 'manualLimitVal' },
      //     { title: '排放浓度单位', data: 'manualUnit' },
      //     { title: '排放速率标准值', data: 'manualLimitVal' },
      //     { title: '排放速率单位', data: 'manualUnit' }
      //   ]
      // }
      // setTimeout(() => {
      //   // this.$refs.table.hotInstance.loadData(this.itemList)
      //   this.$refs.table.hotInstance.render()
      // }, 1000)
    },
    afterGetColHeader(col, th) {
      setTimeout(() => {
        if (col === -1) {
          const theads = th.parentNode.parentNode // 获取当前表头的thead对象

          const trs = theads.getElementsByTagName('tr') // 获取所有行

          const trCols1 = trs[0].getElementsByTagName('th') // 获取第一行所有列

          const trCols2 = trs[1].getElementsByTagName('th') // 获取第二行所有列

          if (trCols1.length === trCols2.length) {
            // 行号表头将第一行的底部边框去除掉，符合合并单元格样式

            // 此处不能执行rowSpan属性，否则出现第二行合表头数据错位

            trCols1[0].style.borderBottom = 'none'

            for (let i = 1; i < trCols1.length; i++) {
              // 如果单元格不包含colSpan属性且不是隐藏的单元格，则表明需要合并行，否则，则表明不需要合并行

              if (!trCols1[i].getAttribute('colSpan') && trCols1[i].className !== 'hiddenHeader') {
                trCols1[i].rowSpan = 2

                trCols1[i].style.verticalAlign = 'middle'

                // trCols1[i].style.height = '50px'

                // 将第二行表格隐藏，并将第一行的底部边框去除

                trCols2[i].className = 'hiddenHeader'

                trCols1[i].style.borderBottom = 'none'
              }
            }
          }
        }
      }, 100)
    },
    areAllElementsNumeric(str) {
      return /^\d+$/.test(str)
    },
    save() {
      const self = this
      let params = []
      if (this.itemList.length) {
        this.loading = true
        let messageData = {}
        let emissionRateMessageData = {}
        this.cards.forEach((item1) => {
          item1.data?.forEach((item, index) => {
            let obj = {
              id: item.id,
              projId: item.projId,
              cateId: item.cateId,
              siteId: item.siteId,
              itemId: item.itemId,
              manualStandard: item.manualStandard,
              standardType: item.standardType,
              manualLimitVal: item.standardType === 2 ? item.manualLowerLimitVal + '~' + item.manualUpperLimitVal : item.manualLimitVal
              // manualUnit: item.manualUnit
            }

            if (item.standardType !== 3) {
              obj.manualUnit = item.manualUnit
            }
            if (this.emissionRate) {
              obj.manualEmissionRateLimitValue =
                item.standardType === 2 ? item.manualEmissionRateLowerLimitVal + '~' + item.manualEmissionRateUpperLimitVal : item.manualEmissionRateLimitValue
              if (item.standardType !== 3) {
                obj.manualEmissionRateUnit = item.manualEmissionRateUnit
              }
            }
            if (item1.standardType === 1 && !this.areAllElementsNumeric(item.manualLimitVal)) {
              if (messageData[item1.standardType]) {
                messageData[item1.standardType].push(index + 1)
              } else {
                messageData[item1.standardType] = []
                messageData[item1.standardType].push(index + 1)
              }
            } else if (item1.standardType === 2 && (!this.areAllElementsNumeric(item.manualLowerLimitVal) || !this.areAllElementsNumeric(item.manualUpperLimitVal))) {
              if (messageData[item1.standardType]) {
                messageData[item1.standardType].push(index + 1)
              } else {
                messageData[item1.standardType] = []
                messageData[item1.standardType].push(index + 1)
              }
            }

            if (this.emissionRate) {
              if (item1.standardType === 1 && !this.areAllElementsNumeric(item.manualEmissionRateLimitValue)) {
                if (emissionRateMessageData[item1.standardType]) {
                  emissionRateMessageData[item1.standardType].push(index + 1)
                } else {
                  emissionRateMessageData[item1.standardType] = []
                  emissionRateMessageData[item1.standardType].push(index + 1)
                }
              } else if (
                item1.standardType === 2 &&
                (!this.areAllElementsNumeric(item.manualEmissionRateLowerLimitVal) || !this.areAllElementsNumeric(item.manualEmissionRateUpperLimitVal))
              ) {
                if (emissionRateMessageData[item1.standardType]) {
                  emissionRateMessageData[item1.standardType].push(index + 1)
                } else {
                  emissionRateMessageData[item1.standardType] = []
                  emissionRateMessageData[item1.standardType].push(index + 1)
                }
              }
            }
            params.push(obj)
          })
        })
        console.log(messageData)
        console.log(emissionRateMessageData)
        let message = ''
        let emissionRateMessage = ''
        if (Object.keys(messageData).length > 0 || Object.keys(emissionRateMessageData).length > 0) {
          if (Object.keys(messageData).length > 0) {
            for (const k in messageData) {
              if (k === '1') {
                message += '标准限值中第'
                let str = messageData[k].join('、')
                message += str
                if (this.emissionRate) {
                  message += '行排放浓度标准值\n'
                } else {
                  message += '行标准值\n'
                }
              }
              if (k === '2') {
                message += '范围限值中第'
                let str = messageData[k].join('、')
                message += str
                if (this.emissionRate) {
                  message += '行排放浓度标准值\n'
                } else {
                  message += '行标准值\n'
                }
              }
            }
            message += '不是数字，\n'
          }
          if (Object.keys(emissionRateMessageData).length > 0) {
            for (const k in emissionRateMessageData) {
              if (k === '1') {
                emissionRateMessage += '标准限值中第'
                let str = emissionRateMessageData[k].join('、')
                emissionRateMessage += str
                emissionRateMessage += '行排放速率标准值\n'
              }
              if (k === '2') {
                emissionRateMessage += '范围限值中第'
                let str = emissionRateMessageData[k].join('、')
                emissionRateMessage += str
                emissionRateMessage += '行排放速率标准值\n'
              }
            }
            // this.itemList.forEach((item) => {})
            console.log(this.itemList, params)
            emissionRateMessage += '不是数字，\n'
            message += emissionRateMessage
          }
          console.log(this.itemList, params)
          message += '是否确认保存？'
          this.$confirm({
            title: '确认保存?',
            content: message,
            onOk() {
              self.saveManual(params)
            },
            onCancel() {
              self.loading = false
            }
          })
        } else {
          self.saveManual(params)
        }
      }
    },
    saveManual(params) {
      const self = this
      manualEntry
        .saveManual(params)
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            self.$message.success('手动录入成功')
            self.manualEntryModal.cancel()
            self.$emit('refreshData')
          }
        })
        .finally(() => {
          self.loading = false
        })
    },
    valueChange(changes, source, standardType) {
      if (changes !== null) {
        changes.forEach(([row, prop, oldValue, newValue]) => {
          if (prop === 'standardTypeName') {
            let standardTypeItem = this.standardTypes.find((item) => item.label === newValue)
            if (standardType === 1) {
              if (standardTypeItem.value === 2) {
                this.$set(this.cards[0].data[row], 'standardType', 2)
                let items = this.cards[0].data.splice(row, 1)
                items.forEach((item) => {
                  item.manualLimitVal = null
                  if (this.emissionRate) {
                    item.manualEmissionRateLimitValue = null
                  }
                })
                this.cards[1].data.push(...items)
                this.$refs['table_0']?.[0].hotInstance.loadData(this.cards[0].data)
                this.$refs['table_1']?.[0].hotInstance.loadData(this.cards[1].data)
              } else if (standardTypeItem.value === 3) {
                this.$set(this.cards[0].data[row], 'standardType', 3)
                let items = this.cards[0].data.splice(row, 1)
                items.forEach((item) => {
                  item.manualLimitVal = '1'
                  item.manualLimitValName = '有'
                  if (this.emissionRate) {
                    item.manualEmissionRateLimitValue = '1'
                    item.manualEmissionRateLimitValName = '有'
                  }
                })
                this.cards[2].data.push(...items)
                this.$refs['table_0']?.[0].hotInstance.loadData(this.cards[0].data)
                this.$refs['table_2']?.[0].hotInstance.loadData(this.cards[2].data)
              }
            } else if (standardType === 2) {
              if (standardTypeItem.value === 1) {
                this.$set(this.cards[1].data[row], 'standardType', 1)
                let items = this.cards[1].data.splice(row, 1)
                items.forEach((item) => {
                  delete item.manualLowerLimitVal
                  delete item.manualUpperLimitVal
                  delete item.manualEmissionRateLowerLimitVal
                  delete item.manualEmissionRateUpperLimitVal
                })
                this.cards[0].data.push(...items)
                this.$refs['table_0']?.[0].hotInstance.loadData(this.cards[0].data)
                this.$refs['table_1']?.[0].hotInstance.loadData(this.cards[1].data)
              } else if (standardTypeItem.value === 3) {
                this.$set(this.cards[1].data[row], 'standardType', 3)
                let items = this.cards[1].data.splice(row, 1)
                items.forEach((item) => {
                  delete item.manualLowerLimitVal
                  delete item.manualUpperLimitVal
                  item.manualLimitVal = '1'
                  item.manualLimitValName = '有'
                  if (this.emissionRate) {
                    item.manualEmissionRateLimitValue = '1'
                    item.manualEmissionRateLimitValName = '有'
                  }
                  delete item.manualEmissionRateLowerLimitVal
                  delete item.manualEmissionRateUpperLimitVal
                })
                this.cards[2].data.push(...items)
                this.$refs['table_1']?.[0].hotInstance.loadData(this.cards[1].data)
                this.$refs['table_2']?.[0].hotInstance.loadData(this.cards[2].data)
              }
            } else if (standardType === 3) {
              if (standardTypeItem.value === 1) {
                this.$set(this.cards[2].data[row], 'standardType', 1)
                let items = this.cards[2].data.splice(row, 1)
                items.forEach((item) => {
                  item.manualLimitVal = null
                  delete item.manualLimitValName
                  if (this.emissionRate) {
                    item.manualEmissionRateLimitValue = null
                  }
                  delete item.manualEmissionRateLimitValName
                })
                this.cards[0].data.push(...items)
                this.$refs['table_0']?.[0].hotInstance.loadData(this.cards[0].data)
                this.$refs['table_2']?.[0].hotInstance.loadData(this.cards[2].data)
              } else if (standardTypeItem.value === 2) {
                this.$set(this.cards[2].data[row], 'standardType', 2)
                let items = this.cards[2].data.splice(row, 1)
                items.forEach((item) => {
                  item.manualLimitVal = null
                  delete item.manualLimitValName
                  if (this.emissionRate) {
                    item.manualEmissionRateLimitValue = null
                  }
                  delete item.manualEmissionRateLimitValName
                })
                this.cards[1].data.push(...items)
                this.$refs['table_1']?.[0].hotInstance.loadData(this.cards[1].data)
                this.$refs['table_2']?.[0].hotInstance.loadData(this.cards[2].data)
              }
            }
          }
        })
      }
    },
    standardTypeSelectOptions(visualRow, visualColumn, prop) {
      return this.standardTypes.map((item) => item.label)
    },
    manualLimitValRenderer(instance, td, row, col, prop, value, cellProperties) {
      td.innerText = value
      td.className = 'htMiddle htCenter'
      let limitValTypeItem = this.limitValTypes.find((item) => item.label === value)
      if (limitValTypeItem) {
        this.$set(this.cards[2].data[row], 'manualLimitVal', limitValTypeItem.value)
      }
    },
    manualEmissionRateLimitValRenderer(instance, td, row, col, prop, value, cellProperties) {
      td.innerText = value
      td.className = 'htMiddle htCenter'
      let limitValTypeItem = this.limitValTypes.find((item) => item.label === value)
      if (limitValTypeItem) {
        this.$set(this.cards[2].data[row], 'manualEmissionRateLimitValue', limitValTypeItem.value)
      }
    },
    limitValTypesSelectOptions(visualRow, visualColumn, prop) {
      return this.limitValTypes.map((item) => item.label)
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/less/common/snippet.less';

.check-task-enter-hot-table {
  ::v-deep(.handsontable) {
    .color();
  }
}
</style>
<style lang="less">
.ant-modal-confirm-content {
  white-space: pre-line;
}
</style>