'use strict'

// 系统日志接口
import { getAction } from '@/api/common'

const sysLogPre = '/ms-api/sys-log'

export const sysLogApi = {
  details: (param) => getAction(`${sysLogPre}/details`, param)
}
