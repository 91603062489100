<template>
  <a-modal v-bind="searchFlowModal" v-on="searchFlowModal">
    <a-row v-bind="searchFlowRow">
      <a-col :md="6" :sm="24">
        <a-card :bordered="false">
          <a-directory-tree v-bind="flowTypeTree" v-on="flowTypeTree"></a-directory-tree>
        </a-card>
      </a-col>

      <a-col :md="18" :sm="24">
        <a-card hoverable>
          <!-- 查询区域 -->
          <div class="table-page-search-wrapper">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-input placeholder="请输入标题" v-model="queryParam.processInstanceName"></a-input>
              </a-col>
              <a-col :span="10">
                <a-range-picker
                  :popupStyle="{zIndex: 9999}"
                  v-model="dateRang"
                  @change="(moms, dateStrs)=>{ onDateChange(dateStrs) }"/>
              </a-col>
              <a-col :span="6">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                  <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </div>

          <!-- table区域-begin -->
          <div>
            <a-table
              v-bind="flowTable"
              :pagination="ipagination"
              :loading="loading"
              :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
              :dataSource="dataSource"
              @change="handleTableChange">
              <template slot="processStatus" slot-scope="text, record">
                <template v-if="record.endTime">
                  <a-tag color="#87d068">已完成</a-tag>
                </template>
                <template v-else-if="record.suspend">
                  <a-tag color="red">已挂起</a-tag>
                </template>
                <template v-else-if="!record.suspend">
                  <a-tag color="#2db7f5">进行中</a-tag>
                </template>
              </template>
            </a-table>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { groupBy } from 'lodash'

import { ListMixin } from '@/mixins/ListMixin'

import { flowApi } from '@/api/flow'
import { getAction } from '@/api/common'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal
export default {
  name: 'SearchFlowModal',

  mixins: [ListMixin],

  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    title: {
      type: String,
      default: '请选择流程',
      required: false
    }
  },

  data () {
    return {

      url: {
        // list: flowApi.pageUserProcess
        list: flowApi.pageProcess
      },

      queryParam: {
        processInstanceName: null,
        key: null,
        startTimeStr: null,
        endTimeStr: null
      },
      dateRang: [],

      loading: false,
      dataSource: [],

      flowTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        rowKey: 'processInstanceId',
        columns: [
          { title: '流程标题', align: 'center', dataIndex: 'processInstanceName', scopedSlots: { customRender: 'processInstanceName' } },
          { title: '提交日期', align: 'center', dataIndex: 'startTime', width: 180 },
          { title: '状态', align: 'center', dataIndex: 'processStatus', width: 120, scopedSlots: { customRender: 'processStatus' } }
        ]
      },

      flowTypeTree: {
        selectable: true,
        defaultExpandAll: false,
        selectedKeys: [],
        checkStrictly: true,
        select: this.onTreeSelect,
        dropdownStyle: { maxHeight: '200px', overflow: 'auto' },
        treeData: []
      },

      searchFlowRow: {
        gutter: 10,
        style: { background: '#ECECEC', padding: '10px', margin: '-10px' }
      },

      searchFlowModal: Object.assign({}, fullModalData, {
        width: this.modalWidth,
        visible: false,
        title: this.title,
        ok: this.handleSubmit,
        cancel: this.close,
        cancelText: '关闭',
        // style: { marginTop: '-70px' },
        // wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      })
    }
  },

  watch: {
    'searchFlowModal.visible': {
      handler (val) {
        if (val) {
          this.queryFlowTypeData()
        }
      }
    }
  },

  methods: {

    /// 查询流程类型
    queryFlowTypeData () {
      getAction(flowApi.list).then(res => {
        if (res.code === 0) {
          const temp = []
          res.data.forEach((item) => {
            item.title = item.name
            item.value = item.key
            delete item.icon
          })
          const dataByCategory = groupBy(res.data, item => item.category)
          for (const a in dataByCategory) {
            temp.push({
              title: a,
              selectable: false,
              value: a,
              key: a,
              children: dataByCategory[a]
            })
          }
          this.flowTypeTree.treeData = temp
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /// 流程类型树选中事件
    onTreeSelect (data) {
      if (data && data.length) {
        this.queryParam.key = data[0]
        this.searchQuery()
      }
    },

    searchReset () {
      this.queryParam = {}
      this.dateRang = []
      this.onClearSelected()
      this.loadData(1)
    },
    onDateChange (dateStrs) {
      this.queryParam.startTimeStr = dateStrs[0] !== '' ? dateStrs[0] + ' 00:00:00' : null
      this.queryParam.endTimeStr = dateStrs[1] !== '' ? dateStrs[1] + ' 23:59:59' : null
    },

    init (flowList) {
      if (flowList && flowList.length) {
        this.selectionRows.splice(0, this.selectionRows.length, ...flowList)
        this.selectedRowKeys.splice(0, this.selectionRows.length, ...(flowList.map(item => { return item.processInstanceId })))
      }
      this.$nextTick(() => {
        this.searchFlowModal.visible = true
      })
    },

    handleSubmit () {
      this.$emit('ok', this.selectionRows)
      this.close()
    },

    close () {
      this.searchFlowModal.false = true
      this.$emit('cancel')
    }
  }
}
</script>
