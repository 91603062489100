export default {
  data () {
    return {

      // 是否加载中
      loading: false,

      rowSelection: {
        selectedRowKeys: [],
        selectedRows: [],
        onChange: (selectedRowKeys, selectedRows) => this.onSelectChange(selectedRowKeys, selectedRows)
      },

      /// 数据表格配置项
      contentTable: {
        ref: 'table',
        rowKey: 'id',
        size: 'small',
        bordered: this.bordered || false,
        data: this.loadData,
        expandedRowKeys: [],
        columns: this.columns ?? [{ title: 'ID', dataIndex: 'id', scopedSlots: { customRender: 'id' } }],
        style: { background: '#FFFFFF' },
        bodyStyle: { background: '#D7DDE312' },
        showPagination: (!this.showPagination && this.showPagination !== undefined) || (!this.pagination && this.pagination !== undefined) ? false : 'auto',

        action: {
          loading: (value) => {
            this.loading = value
          },
          change: () => {

          }
        }
      }
    }
  },

  watch: {
    loading (value) {
      this.$emit('loading', value)
    }
  },

  methods: {

    /// 加载数据
    loadData () {
      '请实现自定义查询方法'
    },

    /// 表格行选中事件
    onSelectChange (selectedRowKeys, selectedRows) {
      this.rowSelection.selectedRowKeys.splice(0, this.rowSelection.selectedRowKeys.length, ...selectedRowKeys)
      this.rowSelection.selectedRows.splice(0, this.rowSelection.selectedRows.length, ...selectedRows)
    },

    // 清除选中
    clearSelect () {
      this.rowSelection.selectedRowKeys.splice(0, this.rowSelection.selectedRowKeys.length)
      this.rowSelection.selectedRows.splice(0, this.rowSelection.selectedRows.length)
    }
  }
}
