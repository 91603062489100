<template>
    <a-row v-bind="searchProjectRow">
      <a-col :md="24" :sm="24">
        <a-tabs defaultActiveKey="1" @change="callback">
          <a-tab-pane tab="市场项目" key="1">
            <a-card hoverable>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-input placeholder="请输入项目编号/名称" v-model="queryParam.projName"></a-input>
                  </a-col>
                  <a-col :span="6">
                    <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                      <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                      <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </div>

              <!-- table区域-begin -->
              <div>
                <a-table
                  v-bind="projectTable"
                  v-on="projectTable"
                  :pagination="ipagination"
                  :loading="loading"
                  :rowSelection="rowSelection"
                  :dataSource="dataSource"
                  @change="handleTableChange">
                  <template slot="projUserName" slot-scope="text, record">
                    <span v-if="searchType === 'projName'">{{record.projUserName}}</span>
                    <span v-else>{{record.projContact}}</span>
                  </template>
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane tab="通用项目" key="2" forceRender :disabled="commonProjDisable">
            <a-card hoverable>
              <!-- table区域-begin -->
              <div>
                <a-table
                  v-if="searchType === 'relProjName'"
                  v-bind="projectTable"
                  v-on="projectTable"
                  :pagination="commonIpagination"
                  :loading="loading"
                  :rowSelection="rowSelection"
                  :dataSource="commonProjectList">
                  <template slot="projUserName" slot-scope="text, record">
                    <span v-if="searchType === 'projName'">{{record.projUserName}}</span>
                    <span v-else>{{record.projContact}}</span>
                  </template>
                </a-table>
              </div>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
</template>

<script>
import { ListMixin } from '@/mixins/ListMixin'
import { projectApi } from '@/api/project'
import { commDictApi } from '@/api/common'
import Vue from 'vue'
import { DEFAULT_PAGE_SIZE } from '@/store/mutation-types'

export default {
  name: 'SearchSingleProjectModal',

  mixins: [ListMixin],

  props: {
    searchType: {
      type: String,
      default: 'projName',
      required: false
    }
  },

  data () {
    return {

      queryParam: {
        processInstanceName: null,
        key: null,
        startTime: null,
        endTime: null
      },

      loading: false,
      dataSource: [],

      projectTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '项目编号', align: 'center', dataIndex: 'projNo' },
          { title: '项目名称', align: 'center', dataIndex: 'projName' },
          { title: '负责人', align: 'center', dataIndex: 'projUserName', scopedSlots: { customRender: 'projUserName' } }
        ]
      },
      selectedRowKeys: [],
      rowSelection: {
        type: 'radio',
        onChange: this.onSelectChange
      },

      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },

      searchProjectRow: {
        gutter: 10,
        style: { background: '#ECECEC', padding: '10px', margin: '-10px' }
      },

      roles: this.$store.getters.roles,
      commonProjectList: [],
      commonIpagination: false,
      commonProjDisable: this.searchType === 'projName'
    }
  },
  created () {
    if (this.searchType === 'relProjName') this.listCommonProject()
  },

  methods: {

    callback: function (v) {

    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams()// 查询条件
      this.loading = true

      projectApi.listForFlow(params).then(res => {
        this.dataSource = res.data.data
        this.dataSource.forEach(item => {
          item.projType = '1'
          item.projId = item.id
        })
        this.ipagination.total = res.data.totalCount
        this.ipagination.current = res.data.pageNo
        this.ipagination.pageSize = res.data.pageSize
        this.loading = false
      })
    },

    // 查询通用项目列表
    listCommonProject () {
      commDictApi.list({ dictCode: 'commonProjectCode' }).then(result => {
        if (result.code === 0) {
          const d = result.data
          this.commonProjectList = []
          for (let i = 0; i < d.length; i++) {
            this.commonProjectList.push({ id: d[i].itemValue, projType: '2', projId: d[i].itemValue, projNo: d[i].itemValue, projName: d[i].itemText })
          }
        }
      })
    },

    onSelectChange (selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
    },

    searchQuery () {
      this.loadData(1)
    },

    searchReset () {
      this.queryParam = {}
      this.onClearSelected()
      this.loadData(1)
    },

    getSelected () {
      return this.selectionRows[0]
    }
  }
}
</script>
