<template>
  <a-modal :visible="versionVisible" :width="700" :title="versionTitle" @cancel="() => this.versionVisible = false">
    <div v-html="versionContent"></div>
    <template slot="footer">
      <a-button key="back" @click="setCookie">7天内不再提示</a-button>
      <a-button key="submit" type="primary" @click="() => this.versionVisible = false">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { versionApi } from '@/api/common'
import Cookies from 'js-cookie'

export default {
  data () {
    return {
      versionVisible: false,
      versionTitle: '',
      versionContent: '',
      versionCookieKey: 'pc_version_show',
      versionNumberKey: 'pc_version'
    }
  },
  created () {
    const k = Cookies.get(this.versionCookieKey)
    if (!k) {
      versionApi.last().then(res => {
        if (res.code === 0) {
          this.$store.dispatch('SysVersion', res.data.versionNumber)
          Cookies.set(this.versionNumberKey, res.data.versionNumber, { expires: 365 })
          this.versionTitle = `版本更新信息(${res.data.versionNumber})`
          // this.versionVisible = true
          this.versionContent = res.data.content.replace(/\\n/g, '')
        }
      })
    }
  },
  methods: {
    setCookie () {
      Cookies.set(this.versionCookieKey, '1', { expires: 7 })
      this.versionVisible = false
    }
  }
}
</script>

<style scoped>

</style>
