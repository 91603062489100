<template>

  <a-popover>
    <template #title>
      <div style="display:flex;flex-direction:row-reverse; gap:8px;padding: 5px 0;">
        <a-button size="small" type="primary" @click="handleOk">确定</a-button>
        <a-button size="small" @click="handleCancel">重置</a-button>
      </div>
    </template>
    <template #content>
      <s-table v-bind="contentTable" :rowSelection="rowSelection" :scroll="scrollProp" v-on="contentTable.action">
        <template #order="item,record,index">
          <template v-if="index===0">
            <a-icon size="small" type="down" class="action" @click="handleDown(record)"></a-icon>
          </template>
          <template v-else-if="index===backupList.length-1">
            <a-icon size="small" type="up" class="action" @click="handleUp(record)"></a-icon>
          </template>
          <template v-else>
            <a-icon size="small" type="down" class="action" @click="handleDown(record)"></a-icon>
            <a-divider type="vertical"/>
            <a-icon size="small" type="up" class="action" @click="handleUp(record)"></a-icon>
          </template>
        </template>
      </s-table>
    </template>

    <slot name="button">
      <a-button>{{ buttonName }}</a-button>
    </slot>
  </a-popover>

</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import columns from './columns'
import { cloneDeep, sortBy } from 'lodash'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  components: { sTable },
  mixins: [componentTable],

  props: {
    defaultOtherHeight: { type: Number, required: false, default: 0 },

    value: {
      type: Array,
      required: true,
      validator (value) {
        return value && value.reduce((acc, item) => acc && item.hasOwnProperty('dataIndex') && item.hasOwnProperty('title'), true)
      }
    },
    buttonName: { type: String, required: false, default: '自定义列' },
    cacheKeyPre: { type: String, required: false, default: null }
  },

  data () {
    return {
      backupList: cloneDeep(this.value || []),
      cacheKey: this.cacheKeyPre?.concat('_customize_column_cache') ?? null,
      sortKeys: [],
      isFirst: true,

      scrollProp: { x: 180 },
      contentTable: {
        class: 'customize-column-table',
        rowKey: 'dataIndex',
        style: { width: '300px' },
        bordered: false,
        columns: columns,
        showPagination: false
      }
    }
  },

  computed: {
    currentList () {
      const { sortKeys, backupList } = this
      const result = sortBy(backupList, (item) => sortKeys.findIndex(cItem => cItem === item.dataIndex))
      return result
    },

    // 本地缓存数据
    localCacheData () {
      return this.cacheKey ? this.$ls.get(this.cacheKey, null) : null
    },

    // 有效的缓存数据
    cacheData () {
      const { localCacheData, backupList } = this
      if (!localCacheData) return null
      const thisBackupIndex = backupList.map(item => item.dataIndex)
      return localCacheData.filter(item => thisBackupIndex.includes(item.dataIndex))
    },

    // 保存的数据
    saveKeys () {
      return null
    }
  },

  watch: {

    // 数据改变后自动响应
    currentList (newValue, oldValue) {
      this.$refs.table && this.search()
    },

    cacheData: {
      immediate: true,
      handler (newValue, oldValue) {
        this.handleCacheDataChange(newValue)
      }
    },

    'rowSelection.selectedRows': {
      immediate: false,
      handler (newValue, oldValue) {
        this.saveCacheData(newValue)
      }
    },

    sortKeys (newValue) {
      this.saveCacheData(this.rowSelection.selectedRows)
    }

  },

  methods: {

    // 保存缓存数据
    saveCacheData (cacheData) {
      const { cacheKey, sortKeys } = this
      if (!cacheKey) return null
      const result = sortBy(cacheData, (item) => sortKeys.findIndex(cItem => cItem === item.dataIndex))
      this.$ls.set(cacheKey, result)
    },

    // 上移
    handleUp (data) {
      const sortKeys = this.sortKeys
      const idx = sortKeys.findIndex(item => item === data.dataIndex)
      sortKeys.splice(idx, 1, ...sortKeys.splice(idx - 1, 1, sortKeys[idx]))
    },

    // 下移
    handleDown (data) {
      const sortKeys = this.sortKeys
      const idx = sortKeys.findIndex(item => item === data.dataIndex)
      sortKeys.splice(idx + 1, 1, ...sortKeys.splice(idx, 1, sortKeys[idx + 1]))
    },

    // 处理缓存数据变化
    handleCacheDataChange (cacheData) {
      // 存在缓存数据 按照缓存数据进行处理，如果无缓存数据，默认为提供的数据
      const data = cacheData ?? this.backupList
      // 勾选
      const keys = data.map(item => item.dataIndex)
      this.onSelectChange(keys, data)
      // 排序
      const { sortKeys, backupList } = this
      const notInArr = backupList.filter(item => !keys.includes(item.dataIndex))
      const sortData = data.concat(notInArr)
      sortKeys.splice(0, sortKeys.length, ...sortData.map(item => item.dataIndex))

      // 响应缓存变化
      if (this.isFirst) {
        this.handleOk()
        this.isFirst = false
      }
    },

    // 确定
    handleOk () {
      const result = sortBy(this.rowSelection.selectedRows, (item) => this.sortKeys.findIndex(cItem => cItem === item.dataIndex))
      this.$emit('change', result)
    },

    // 重置
    handleCancel () {
      const cacheKey = this.cacheKey
      this.$ls.set(cacheKey, this.backupList)
      this.cacheKey = null
      this.$nextTick(() => {
        this.cacheKey = cacheKey
      })
      setTimeout(() => {
        this.handleOk()
      }, 100)
    },

    // 加载数据
    loadData (param) {
      return new Promise((resolve, reject) => {
        const resData = { code: 0, msg: 'success', data: [...this.currentList] }
        resolve(resData)
      }).then(res => res)
    }
  }

}
</script>

<style lang="less" scoped>
@import '_index';
</style>
