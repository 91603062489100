<template>
  <a-modal v-bind="assignModal" v-on="assignModal">
    <!-- <select-user-by-dept
      ref="selectUserByDept"
      :disabled="false"
      :triggerChange="true"
      rowType="radio"
      @change="handleAssignUserChange" /> -->
    <a-form>
      <a-form-item label="检测员" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-select v-model="assignUserId" :filterOption="filterOption" placeholder="请选择检测员">
          <a-select-option v-for="usr in userList" :key="usr.id">{{ usr.name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'
import { resStaffApi, Get } from '@/api/quote'

export default {
  components: { SelectUserByDept },
  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      assignUser: null,
      assignUserId: null,
      assignModal: {
        title: '任务指派',
        width: 600,
        centered: true,
        visible: false,

        cancel: () => {
          this.assignModal.visible = false
          this.$emit('cancel')
        },

        ok: () => {
          if (!this.assignUserId) {
            this.$message.error('请先选择指派人！')
            return false
          }
          this.assignUser = this.userList.filter((item) => item.id === this.assignUserId)[0].username
          checkBatchApi.assign({ id: this.id, reviewUser: this.assignUser }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.assignModal.visible = false
              console.log(this.isAutoReview)
              if (this.isAutoReview) {
                this.$emit('autoReview', this.assignUser)
              } else {
                this.$emit('success', this.assignUser)
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      // 是否自动复核
      isAutoReview: false,
      userList: []
    }
  },

  computed: {
    loginUser() {
      return { ...this.$store.getters.userInfo }
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      Get(resStaffApi.list, { page: false, queryStatus: ['0', '3'], status: '0,3', preparatStatus: '0', job: 27 }).then((res) => {
        this.userList = res.data
      })
    },
    show() {
      this.assignModal.visible = true
    },

    handleAssignUserChange(userList) {
      const assignUser = userList?.[0]?.username
      if (assignUser === this.loginUser.username) {
        this.$message.error('不能指派给自己！')
        return false
      }
      this.assignUser = assignUser
    },
    searchAssignList(assignUserItem, isAutoReview) {
      this.getUserList()
      this.assignUser = assignUserItem.username
      this.assignUserId = assignUserItem.id
      this.isAutoReview = isAutoReview
      this.$nextTick(() => {
      this.show()
      })
    }
  }
}
</script>

<style scoped></style>
