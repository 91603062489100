<template>
  <div>
    <a-card type="inner" title="方法录入">
      <a-table v-bind="methodTable">
        <template #methodName="item, record">
          <template v-if="type === 'edit'">
            <!-- <a-input v-model="record.methodName" :style="{width:'80%',margin:'0 10px 0 0'}"/> -->
            <!-- <a @click="itemDetailListModal.action.show(record)">
              <a-icon type="search"></a-icon>
            </a> -->
            <a-select style="width: 100%" v-model="record.checkDetailId" @change="changeCheckDetail(record)" placeholder="请选择标准">
              <a-select-option v-for="(item, index) in checkDetailListGroup[`${record.cateId}_${record.itemId}`]" :key="item.id" :value="item.id">
                {{ item.methodName + '(' + item.standardCode + ')' }}
              </a-select-option>
            </a-select>
          </template>
          <template v-else>{{ item }}</template>
        </template>

        <template #deviceName="item, record">
          <template v-if="type === 'edit'">
            <a-input v-model="record.deviceName" />
          </template>
          <template v-else>{{ item }}</template>
        </template>

        <template #limitValue="item, record">
          <template v-if="type === 'edit'">
            <a-input disabled v-model="record.limitValue" />
          </template>
          <template v-else>{{ item }}</template>
        </template>
      </a-table>
    </a-card>
    <a-card type="inner" title="结果录入" style="margin-top: 24px">
      <hot-table ref="hotTableComponent" :settings="hotSetting" :colHeaders="hotSetting.columns.map((item) => item.title)" style="width: 100%"> </hot-table>
      <!-- <a-table v-bind="resultTable">
        <template #result="item, record">
          <template v-if="type === 'edit'">
            <a-input v-model="record.result" />
          </template>
          <template v-else>{{ item }}</template>
        </template>
      </a-table> -->
    </a-card>

    <a-modal v-bind="itemDetailListModal" v-on="itemDetailListModal.action">
      <item-check-detail-list v-bind="itemDetailListModal.data"></item-check-detail-list>
    </a-modal>
  </div>
</template>

<script>
import { projSubcontractItemMethodApi, projSubcontractTaskSampleApi } from '@/api/project'
import ItemCheckDetailList from '@/pages/cloud-quote/item-check-detail/ItemCheckDetailList'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import { itemCheckDetailApi } from '@/api/quote'
import { HotColumn, HotTable } from '@handsontable/vue'
import { registerAllModules } from 'handsontable/registry'
import 'handsontable/dist/handsontable.full.css'

registerAllModules()

export default {
  props: {
    taskId: { required: true, type: Number },
    projId: { required: false, type: Number },
    subcontractor: { required: false, type: Number },
    type: { required: false, type: String, default: 'edit' }
  },
  components: { ItemCheckDetailList, HotTable, HotColumn },

  data() {
    return {
      itemDetailListModal: Object.assign({}, fullModalData, {
        title: '检测明细',
        currentData: null,

        data: {
          rowSelect: { type: 'radio', selectedRows: [], selectedRowKeys: [] },
          itemName: null
        },

        action: {
          show: (data) => {
            this.itemDetailListModal.currentData = data
            this.itemDetailListModal.data.itemName = data.itemName
            this.itemDetailListModal.visible = true
          },
          ok: () => {
            const { selectedRows } = this.itemDetailListModal.data.rowSelect
            const data = selectedRows?.[0]
            console.log('data=>', data)
            if (data) {
              this.itemDetailListModal.currentData.methodName = data.methodName + '(' + data.standardCode + ')'
              if (data.limitValue) this.itemDetailListModal.currentData.limitValue = data.limitValue + data.unit
            }
            this.itemDetailListModal.action.cancel()
          },

          cancel: () => {
            this.itemDetailListModal.visible = false
          }
        }
      }),
      deviceInfos: [],

      methodTable: {
        dataSource: [],
        pagination: false,
        bordered: true,
        columns: [
          { title: '检测分类', dataIndex: 'cateName' },
          { title: '因子名称', dataIndex: 'itemName' },
          { title: '检测标准', dataIndex: 'methodName', scopedSlots: { customRender: 'methodName' } },
          { title: '输入仪器编号', dataIndex: 'deviceName', scopedSlots: { customRender: 'deviceName' } },
          { title: '检出限', dataIndex: 'limitValue', scopedSlots: { customRender: 'limitValue' } }
        ]
      },

      resultTable: {
        dataSource: [],
        pagination: false,
        bordered: true,
        columns: [
          { title: '样品编号', dataIndex: 'sampleId' },
          { title: '检测分类', dataIndex: 'cateName' },
          { title: '因子名称', dataIndex: 'itemName' },
          { title: '检测结果', dataIndex: 'result', scopedSlots: { customRender: 'result' } }
        ]
      },
      hotSetting: {
        height: 'auto',
        width: '100%',
        // rowHeights: 40,
        rowHeaders: true,
        autoColumnSize: true,
        autoRowSize: true,
        manualRowResize: true,
        columnSorting: true,
        stretchH: 'all',
        className: 'htMiddle htCenter',
        currentHeaderClassName: 'ht__highlight',
        activeHeaderClassName: 'ht__active_highlight',
        licenseKey: 'non-commercial-and-evaluation',
        columns: [
          { title: '样品编号', data: 'sampleId', readOnly: true },
          { title: '检测分类', data: 'cateName', readOnly: true },
          { title: '因子名称', data: 'itemName', readOnly: true },
          { title: '检测结果', data: 'result' }
        ],
        afterInit: () => {
          console.log('init')
        },
        afterChange: (changes) => {
          console.log(changes)
        }
      },
      resultTableDataSource: [],
      checkDetailListGroup: {}
    }
  },

  watch: {
    taskId: {
      immediate: true,
      handler(newValue, oldValue) {
        newValue && this.initSampleInfo(newValue)
        newValue && this.initItemList(newValue)
      }
    }
  },

  methods: {
    // 根据分包任务ID 获取对应的样品信息
    initSampleInfo(taskId) {
      const param = { taskId, projId: this.projId, subcontractor: this.subcontractor }
      projSubcontractTaskSampleApi.itemListNew(param).then((res) => {
        if (res.code === 0) {
          this.resultTableDataSource.splice(0, this.resultTableDataSource.length, ...res.data)
          this.$refs.hotTableComponent.hotInstance.loadData(this.resultTableDataSource)
        } else {
          this.$message.error(res.msg)
        }
      })
      // projSubcontractTaskSampleApi.itemList({ taskId, page: false }).then((res) => {
      //   if (res.code === 0) {
      //     // this.resultTableDataSource.splice(0, this.resultTableDataSource.length, ...res.data)
      //     // this.$refs.hotTableComponent.hotInstance.loadData(this.resultTableDataSource)
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },

    // 初始化
    initItemList(taskId) {
      projSubcontractItemMethodApi.getItemInfoByTaskId(taskId).then((res) => {
        if (res.code === 0) {
          this.methodTable.dataSource.splice(0, this.methodTable.dataSource.length, ...res.data)
          this.methodTable.dataSource.forEach(async (item) => {
            let checkDetailList = await this.getCheckDetailList(item.cateId, item.itemId)
            if (checkDetailList && checkDetailList.length > 0) {
              let checkDetail = checkDetailList[0]
              let checkDetailItems = checkDetailList.filter((item) => item.defaultFlag === 1)
              if (checkDetailItems.length > 0) {
                checkDetail = checkDetailItems[0]
              }
              this.$set(item, 'checkDetailId', checkDetail.id)
              this.$set(item, 'methodName', `${checkDetail.methodName}(${checkDetail.standardCode})`)
              if (checkDetail.limitValue || checkDetail.limitValue === '0' || checkDetail.unit) {
                if ((checkDetail.limitValue || checkDetail.limitValue === '0') && checkDetail.unit) {
                  this.$set(item, 'limitValue', checkDetail.limitValue + checkDetail.unit)
                } else if (checkDetail.limitValue || checkDetail.limitValue === '0') {
                  this.$set(item, 'limitValue', checkDetail.limitValue)
                } else if (checkDetail.unit) {
                  this.$set(item, 'limitValue', checkDetail.unit)
                }
              } else {
                this.$set(item, 'limitValue', null)
              }
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 保存数据
    save() {
      // 保存标准相关的数据
      const methodData = this.methodTable.dataSource
      let bool = methodData.every((item) => item.checkDetailId)
      if (!bool) {
        this.$message.error('方法录入中检测标准必须选择，请先到检测明细中配置相应的数据！')
        return
      }
      methodData.forEach((item) => {
        item.taskId = this.taskId
      })
      const resultTableData = []
      this.resultTableDataSource.forEach((item) => {
        let obj = {
          cateIds: item.cateId,
          cateName: item.cateName,
          itemIds: item.itemId,
          itemName: item.itemName,
          result: item.result,
          sampleId: item.sampleId,
          taskId: this.taskId
        }
        resultTableData.push(obj)
      })
      // console.log(methodData, this.resultTableDataSource, resultTableData)
      const methodSave = projSubcontractItemMethodApi.save(methodData)
      // 保存检测结果数据
      const resultSave = projSubcontractTaskSampleApi.saveItemResult(resultTableData)
      Promise.all([methodSave, resultSave]).then((res) => {
        const result = res.reduce((acc, item) => acc && item.code === 0, true)
        if (result) {
          this.$message.success('保存成功！')
          this.$emit('success')
        } else {
          const message = res
            .filter((item) => item.code)
            .map((item) => item.msg)
            .join()
          this.$message.error(message)
        }
      })
    },

    // 获取检测明细数据
    getCheckDetailList(cateId, itemId) {
      return itemCheckDetailApi.list({ page: false, cateId, itemId }).then((res) => {
        // console.log(res)
        if (res.code === 0) {
          this.$set(this.checkDetailListGroup, `${cateId}_${itemId}`, res.data)
          return res.data
        }
      })
    },
    changeCheckDetail(record) {
      let checkDetail = this.checkDetailListGroup[`${record.cateId}_${record.itemId}`].filter((item) => item.id === record.checkDetailId)[0]
      this.$set(record, 'methodName', `${checkDetail.methodName}(${checkDetail.standardCode})`)
      if (checkDetail.limitValue || checkDetail.limitValue === '0' || checkDetail.unit) {
        if ((checkDetail.limitValue || checkDetail.limitValue === '0') && checkDetail.unit) {
          this.$set(record, 'limitValue', checkDetail.limitValue + checkDetail.unit)
        } else if (checkDetail.limitValue || checkDetail.limitValue === '0') {
          this.$set(record, 'limitValue', checkDetail.limitValue)
        } else if (checkDetail.unit) {
          this.$set(record, 'limitValue', checkDetail.unit)
        }
      } else {
        this.$set(record, 'limitValue', null)
      }
    }
  }
}
</script>

<style scoped>
</style>
