'use strict'

const defaultColumn = [
  { title: 'ID', dataIndex: 'id', width: 200, align: 'left', scopedSlots: { customRender: 'id' } },
  { title: '称量物编号', dataIndex: 'materialNo', width: 200, align: 'left', scopedSlots: { customRender: 'materialNo' } },
  { title: '称量物名称', dataIndex: 'materialName', width: 200, align: 'left', scopedSlots: { customRender: 'materialName' } },
  { title: '称量量', dataIndex: 'weighNum', width: 200, align: 'left', scopedSlots: { customRender: 'weighNum' } },
  { title: '称量仪器', dataIndex: 'devName', width: 200, align: 'left', scopedSlots: { customRender: 'devName' } },
  { title: '称量人', dataIndex: 'weighUserName', width: 200, align: 'left', scopedSlots: { customRender: 'weighUserName' } },
  { title: '操作', dataIndex: 'action', width: 200, align: 'left', scopedSlots: { customRender: 'action' } }
]

const selectColumn = [
  { title: 'ID', dataIndex: 'id', width: 200, align: 'left', scopedSlots: { customRender: 'id' } },
  { title: '称量物编号', dataIndex: 'materialNo', width: 200, align: 'left', scopedSlots: { customRender: 'materialNo' } },
  { title: '称量物名称', dataIndex: 'materialName', width: 200, align: 'left', scopedSlots: { customRender: 'materialName' } },
  { title: '称量量', dataIndex: 'weighNum', width: 200, align: 'left', scopedSlots: { customRender: 'weighNum' } },
  { title: '称量仪器', dataIndex: 'devName', width: 200, align: 'left', scopedSlots: { customRender: 'devName' } },
  { title: '称量人', dataIndex: 'weighUserName', width: 200, align: 'left', scopedSlots: { customRender: 'weighUserName' } }
]

export {
  defaultColumn as checkReagentWeighingDefaultColumn,
  selectColumn as checkReagentWeighingSelectColumn
}
