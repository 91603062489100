<template>
  <div>
    <template v-if="value">
      <p v-for="(item,index) in value.split(',')" :key="index">
        <a @click="openView(item)">{{ item.split("^")[1] }}</a>
      </p>
    </template>
    <a-modal v-bind="viewModal" v-on="viewModal" v-if="viewModal.visible">
      <flow-process-detail :processIdProp="viewModal.processId" :showOperator="false"></flow-process-detail>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="viewModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
const fullModalData = fullModal.data().fullModal
export default {

  name: 'SearchFlowView',

  props: {
    value: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      viewModal: Object.assign({}, fullModalData,{
        ref: 'viewModal',
        visible: false,
        processId: null,
        title: null,
        width: '100%',
        centered: true,
        ok: () => { this.viewModal.visible = false },
        cancel: () => { this.viewModal.visible = false },
        show: () => { this.viewModal.visible = true },
        // cancelText: '关闭',
        // wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      })
    }
  },

  methods: {
    openView (item, args = item.split('^')) {
      this.viewModal.processId = args[0]
      this.viewModal.title = `预览： ${args[1]}`
      this.viewModal.show()
    }
  }
}
</script>

<style scoped>

</style>
