<template>
  <a-modal @ok="handleSubmit" @cancel.prevent="handleCancel" v-bind="modalProps">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="物资名称" v-bind="formItemLayout">
              <a-input v-model="queryParam.materialName" placeholder="请输入物资名称"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="规格型号" v-bind="formItemLayout">
              <a-input v-model="queryParam.speType" placeholder="请输入规格型号"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="actionColProps">
            <a-button-group>
              <a-button type="primary" @click="search">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <a-card v-bind="contentCard">
      <s-table v-bind="contentTable" :scroll="scrollProp" :rowSelection="rowSelection">
        <template #unit="text,record">
          {{ materialUnitCode[text] }}
        </template>
      </s-table>
    </a-card>

  </a-modal>
</template>

<script>
import { Get, resMaterialApi } from '@/api/quote'
import { STable } from '@/components'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import mixinList from '@/pages/common/mixins/list'

export default {
  name: 'MaterialForm',
  mixins: [mixinList],
  components: { Get, STable },

  props: {
    purFlag: { type: String, required: false, default: undefined }
  },

  data () {
    return {

      queryParam: {
        materialName: null,
        speType: null,
        type: undefined,
        cateId: undefined
      },
      // 前一表单的table数据，用来筛选重复
      tableData: [],
      contentTable: {
        columns: [
          { title: '物品类目', dataIndex: 'cateName', width: 250, scopedSlots: { customRender: 'cateName' } },
          { title: '物品名称', dataIndex: 'materialName', width: 250, scopedSlots: { customRender: 'goodsName' } },
          { title: '规格型号', dataIndex: 'speType', width: 150, scopedSlots: { customRender: 'speType' } },
          { title: '浓度', dataIndex: 'purity', width: 150, scopedSlots: { customRender: 'purity' } },
          { title: '单位', dataIndex: 'unit', width: 150, scopedSlots: { customRender: 'unit' } },
          { title: '库存数量', dataIndex: 'materialNum', width: 150, scopedSlots: { customRender: 'materialNum' } }
        ]
      },

      modalProps: Object.assign({}, fullModalData, {
        title: '新建领用申请',
        width: '70%'
      })
    }
  },

  computed: {
    materialUnitCode () {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },

  created () {
    this.$store.dispatch('code/select', 'material_unit_code')
  },

  methods: {

    add (tableData) {
      this.tableData = tableData
      this.rowSelection.selectedRowKeys = tableData?.map(item => item.id) ?? []
      this.modalProps.title = '选择物资'
      this.modalProps.visible = true
    },

    handleCancel () {
      this.modalProps.visible = false
    },

    handleSubmit () {
      const data = this.rowSelection.selectedRows.map(item => {
        item.key = item.id
        return item
      })
      this.$emit('updateData', [...data])
      this.handleCancel()
    },

    loadData (parameter) {
      if (this.purFlag && this.purFlag === '2') {
        this.queryParam.type = '9'
        this.queryParam.cateId = '9,11,27'
      } else {
        this.queryParam.type = undefined
        this.queryParam.cateId = undefined
      }
      return Get(resMaterialApi.list, Object.assign(parameter, this.queryParam)).then(res => res)
    }

  }
}
</script>
