<template>
  <a-table v-bind="tableContent">
    <template slot="num" slot-scope="data,record,index">
      <span :style="{fontWeight:'bolder'}">{{ index+1 }}</span>
    </template>

    <template slot="itemType" slot-scope="itemType,record">
      <a-tag v-if="itemType===0" color="blue">否</a-tag>
      <a-tooltip v-else>
        <template slot="title">
          <span>分包商</span>：<span style="font-weight: 700;margin-left: 5px">{{ record.sctrName }}</span>
        </template>
        <a-tag color="orange">是</a-tag>
      </a-tooltip>
    </template>

    <template #siteCheck="val">
      <a-tag v-if="val===0" color="blue">否</a-tag>
      <a-tag v-else-if="val===1" color="green">是</a-tag>
    </template>
  </a-table>
</template>
<script>
import { tsSampleItemApi } from '@/api/sample'
import { STable } from '@/components'

export default {
  name: 'SampleItemDetail',
  components: { STable },
  props: {
    sampleId: {
      type: String,
      require: true
    },
    page: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      tableContent: {
        style: { padding: '16px', width: this.width, background: '#FFFFFF' },
        ref: 'sampleItemTable',
        size: 'small',
        rowKey: (data) => { return data.sampleId + '-' + data.cateId + '-' + data.itemId },
        dataSource: [],
        pagination: false,
        bordered: true,
        columns: [{
          title: '序号',
          align: 'center',
          dataIndex: 'id',
          width: 50,
          scopedSlots: { customRender: 'num' }
        }, {
          title: '检测分类',
          align: 'center',
          dataIndex: 'cateName',
          width: 100,
          scopedSlots: { customRender: 'cateName' }
        }, {
          title: '检测项',
          align: 'center',
          dataIndex: 'itemName',
          width: 100,
          scopedSlots: { customRender: 'itemName' }
        }, {
          title: '分包',
          align: 'center',
          dataIndex: 'itemType',
          width: 100,
          scopedSlots: { customRender: 'itemType' }
        }, {
          title: '现场检测',
          align: 'center',
          dataIndex: 'siteCheck',
          width: 100,
          scopedSlots: { customRender: 'siteCheck' }
        }, /* {
          title: '周期数',
          align: 'center',
          dataIndex: 'numPeriod',
          width: 100,
          scopedSlots: { customRender: 'numPeriod' }
        }, {
          title: '频次数',
          align: 'center',
          dataIndex: 'numFrequency',
          width: 100,
          scopedSlots: { customRender: 'numFrequency' }
        }, */ {
          title: '采样标准',
          align: 'center',
          dataIndex: 'sampleStandardName',
          width: 200,
          scopedSlots: { customRender: 'sampleStandardName' }
        }, {
          title: '检测标准',
          align: 'center',
          dataIndex: 'testStandardName',
          width: 200,
          scopedSlots: { customRender: 'testStandardName' }
        }]
      }
    }
  },
  computed: {
    queryParam () {
      return { sampleId: this.sampleId, page: this.page }
    }
  },
  watch: {
    queryParam: {
      immediate: true,
      handler (newValue, oldValue) {
        this.loadData()
      }
    }
  },
  methods: {
    loadData () {
      return tsSampleItemApi.list(this.queryParam).then(res => {
        if (res.code === 0) {
          this.tableContent.dataSource = res.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
