<template>
  <div class="table">
    <s-table v-bind="contentTable" v-on="contentTable.action" :rowSelection="customRowSelection" :scroll="scrollProp">

      <template #createTime="item">
        {{ item | momentFilter('YYYY-MM-DD') }}
      </template>

      <template #content="item">
        <span v-html="item"></span>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { pcVersionDefaultColumn as defaultColumn } from './columns'
import { versionApi } from '@/api/common'

export default {
  components: { sTable },
  mixins: [componentTable],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    defaultOtherHeight: { type: Number, required: false, default: 0 },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {}
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        return versionApi.list(Object.assign({}, param, this.queryParam)).then(res => {
          res.data.forEach(item => { item.content = item?.content?.replace(/\\n/g, '') })
          return res
        })
      }
    }
  }

}
</script>

<style lang="less" scoped></style>
