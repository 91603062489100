<template>
  <a-modal v-bind="fullModal" v-on="fullModal">
    <a-card v-bind="baseInfoCard">
      <a-tabs v-bind="baseInfoTabs">
        <a-tab-pane tab="样品信息" key="1">
          <sample-info-detail v-bind="sampleInfoProps" v-if="fullModal.visible"></sample-info-detail>
        </a-tab-pane>
        <a-tab-pane tab="检测指南" key="2">
          <ExecuteStandardList :param="executeStandardProp"/>
        </a-tab-pane>
      </a-tabs>
    </a-card>

    <a-card v-bind="formCard">
      <template #extra>
        <span :style="{color:'red',fontSize:'12px'}">*计量单位支持输入后回车添加</span>
      </template>

      <a-form v-bind="formProps" :form="dataForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps" :span="6" :style="{height:'100%'}">
            <a-card :style="{margin:'0 16px 0'}" title="检测设备" type="inner" size="small">
              <div :key="index" class="device" v-for="(item,index) in deviceInfoProp.dataSource">
                <div class="device-info">
                  <el-image
                    :src="item.materialImg"
                    style="width: 100%; height: 100%;"
                    v-if="item.materialImg"></el-image>

                  <el-image
                    :preview-src-list="item.materialImgs||[]"
                    :src="item.materialImgs&&item.materialImgs[0]"
                    style="width: 100%; height: 100%"
                    v-else></el-image>

                  <div class="device-operation">
                    <a-popconfirm title="是否要删除吗？" @confirm="deviceInfoProp.dataSource.splice(index,1)">
                      <a-icon type="delete" class="device-operation-delete"/>
                    </a-popconfirm>
                  </div>

                </div>
                <p class="device-title" :title="item.materialName ">{{ item.fixedAssetsId }} {{ item.materialName }}</p>
              </div>
              <div class="device">
                <div @click="showSearchDevice" class="device-info">
                  <div class="device-operation-add">+</div>
                </div>
              </div>
            </a-card>

          </a-col>

          <a-col :sm="18" class="form-data">
            <a-card title="检测记录" type="inner" size="small">
              <dl>
                <dt>检测方法</dt>
                <dd>
                  <a-form-item>
                    <a-select :style="{width:'200px'}" @change="val=>checkMethodSelect.change(val,this.data)" v-bind="checkMethodSelect" v-model="data.methodId">
                      <a-select-option :key="data.methodId" v-for="(data) in data.jcList">
                        {{ data.methodName }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </dd>
              </dl>

              <dl>
                <dt>检出限</dt>
                <dd>
                  <a-form-item>
                    <a-input :style="{width:'200px'}" v-model="data.limitValue"></a-input>
                  </a-form-item>
                </dd>
              </dl>

              <dl>
                <dt>原始值</dt>
                <dd>
                  <a-form-item>
                    <a-input-number v-bind="Object.assign(numberStyle)" v-decorator="decorators.originalValue"></a-input-number>
                  </a-form-item>
                </dd>
              </dl>
              <dl>
                <dt>数据类型</dt>
                <dd>
                  <a-select :style="{width:'200px'}" v-model="data.resultType">
                    <a-select-option v-for="(item) in Object.keys(resultDataType)" :key="item">
                      {{ resultDataType[item] }}
                    </a-select-option>
                  </a-select>
                </dd>
              </dl>
              <dl>
                <dt>结果值</dt>
                <dd>
                  <a-form-item v-if="data.resultType!=='3'">
                    <a-input-number
                      v-bind="Object.assign(numberStyle)"
                      v-decorator="decorators.value"></a-input-number>
                  </a-form-item>
                  <span v-else>未检出</span>
                </dd>
              </dl>

              <dl>
                <dt>检测时间</dt>
                <dd>
                  <a-form-item>
                    <a-date-picker
                      :showTime="true"
                      :style="{width:'200px'}"
                      v-decorator="decorators.checkTime"
                      :disabledDate="disabledDate"
                      format="YYYY-MM-DD HH:mm:ss"/>
                  </a-form-item>

                </dd>
              </dl>

              <dl>
                <dt>检测用量</dt>
                <dd>
                  <a-form-item>
                    <a-input-number :min="1" v-bind="numberStyle" v-decorator="decorators.volume"></a-input-number>
                  </a-form-item>
                </dd>
              </dl>

              <dl>
                <dt>计量单位</dt>
                <dd>
                  <a-select v-bind="checkUnitSelect" @change="val=>checkUnitSelect.change(val)">
                    <a-select-option v-for="(data) in checkUnitSelect.dataSource" :key="data">{{ data }}
                    </a-select-option>
                  </a-select>
                </dd>
              </dl>

              <dl>
                <dt>备注</dt>
                <dd>
                  <a-form-item>
                    <a-textarea v-decorator="decorators.note" :style="{width:'400px'}"></a-textarea>
                  </a-form-item>
                </dd>
              </dl>

              <dl>
                <dt>图片</dt>
                <dd>
                  <a-tag color="orange" @click="showQrcode(data.checkTaskId)">二维码传图</a-tag>
                  <template v-if="fileList.length">
                    <div v-for="(photo,photoIndex) in fileList" :key="photoIndex" class="photos">
                      <span :style="{float:'left',width:'80%'}">{{ photo.name }}</span>
                      <span :style="{float:'left',marginRight: '5px'}"><a @click="handlePreview(photo)"><a-icon
                        type="eye"></a-icon></a></span>
                      <span :style="{float:'left'}">
                        <a-popconfirm title="是否要删除此图片吗？" @confirm="fileList.splice(photoIndex,1)">
                          <a><a-icon type="delete"></a-icon></a>
                        </a-popconfirm>
                      </span>
                    </div>
                  </template>
                </dd>
              </dl>
            </a-card>
          </a-col>

        </a-row>
      </a-form>
    </a-card>

    <!-- 外部组件 -->
    <picture-upload ref="pictureUploadModal" :file-length="3" modalTitlte="上传样品照片" @result="savePicture"/>

    <material-table v-bind="deviceInfoProp" v-on="deviceInfoProp" v-if="deviceInfoProp.visible"></material-table>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { FmUpload } from 'ahmux-form-designing'
import { randomString } from '@/utils/util'
import { cloneDeep, omit, pick } from 'lodash'
import fullModal from '@/pages/common/mixins/modal/full'

import { dictApi } from '@/api/system'
import { sysCommonApi } from '@/api/common'
import { customFunc } from '@/pages/cloud-quote/take-sample/modules/customForm/CustomFormFunc'
import { checkTaskResultApi } from '@/api/sample'
import { Get, jcListApi, resMaterialImgApi } from '@/api/quote'

import ExecuteStandardList from '@/pages/common/modal/ExecuteStandardList'
import SampleInfoDetail from '@/pages/cloud-quote/sample-info/modules/SampleInfoDetail'
import PictureUpload from '@/pages/common/img-qrcode/PictureUpload'
import MaterialTable from '@/pages/cloud-quote/res/res-materialout/modules/materialTable'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

export default {

  components: { SampleInfoDetail, ExecuteStandardList, PictureUpload, FmUpload, MaterialTable },

  props: {
    id: {
      type: Number
    }
  },

  mixins: [fullModal],

  data () {
    return {

      checkMethodSelect: {
        style: { width: '160px' },
        placeholder: '请选择检测方法',
        change: (val, record) => {
          const itemGuide = record.jcList.find(item => item.methodId === val)
          record.methodName = itemGuide.methodName
          record.standardNo = itemGuide.standardCode
          record.jcListId = itemGuide.jcListId
          record.limitValue = itemGuide.limitValue
          this.checkDevices = itemGuide.jcDevices
        }
      },
      // 检测标准参数
      executeStandardProp: {
        itemId: null,
        cateId: null
      },
      addPictureId: 0,

      viewUrl: sysCommonApi.view,

      checkDeviceList: [],
      resourceImagesCache: {},

      fileList: [],

      previewVisible: false,
      previewImage: null,

      accessoryUpload: {
        accept: '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,' +
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        action: sysCommonApi.uploadFile,
        multiple: true,
        showUploadList: true,
        listType: 'text',
        headers: { 'Access-Token': this.$store.getters.token },
        preview: this.previewFile,
        change: this.handleFileChange
      },

      numberStyle: {
        style: { width: '200px' }
      },

      checkUnitSelect: {
        defaultValue: [],
        mode: 'tags',
        tokenSeparators: [',', '，'],
        style: { width: '200px' },
        dataSource: [],
        placeholder: '请选择计量单位',
        change: (val) => {
          const notIncludeArr = val && val.length && val.filter(item => !this.checkUnitSelect.dataSource.includes(item)) || []
          notIncludeArr.length && this.checkUnitSelect.dataSource.splice(this.checkUnitSelect.dataSource.length, 0, ...notIncludeArr)
          this.data.volumeUnit = val && val.length && val.join(',') || null
        }
      },
      checkDevices: null,

      data: {
        id: null,
        projId: null,
        sampleId: null,
        checkTaskId: null,
        checkType: '0',
        resultType: '1',
        cateId: null,
        itemId: null,
        up: null,
        down: null,
        value: null,
        volume: null,
        volumeUnit: null,
        accessory: [],
        limitValue: null,
        standardNo: null,
        methodId: null,
        methodName: null,
        jcList: []
      },

      fullModal: {
        width: '60%',
        title: '结果录入',
        ok: this.handleSubmit,
        cancel: this.close
      },

      baseInfoCard: {
        title: '',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: { padding: '10px 24px 16px' },
        bordered: false,
        type: 'inner',
        hoverable: true
      },

      formCard: {
        title: '记录结果',
        style: { marginTop: '30px' },
        headStyle: {},
        bordered: false,
        type: 'inner',
        hoverable: true
      },

      baseInfoTabs: {
        defaultActiveTabKey: '1',
        size: 'default',
        tabBarStyle: { marginBottom: '24px', paddingLeft: '5px' }
      },

      CheckGuideProps: {
        projId: null,
        showAction: false,
        itemType: '2'
      },

      sampleInfoProps: {
        sampleId: null,
        visible: false
      },

      deviceInfoProp: {
        ref: 'deviceInfo',
        visible: false,
        cateType: 3,
        clearStatus: true,
        dataSource: [],
        show: () => {
          this.deviceInfoProp.visible = true
        },
        cancel: () => {
          this.deviceInfoProp.visible = false
        },
        updateData: this.handleDeviceInfo
      },

      dataForm: this.$form.createForm(this),

      formProps: {
        class: 'ant-advanced-search-form',
        layout: 'horizontal'
      },

      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 8 },
          sm: { span: 8 },
          xs: { span: 8 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
          sm: { span: 16 },
          xs: { span: 16 }
        }
      },

      formItemLayoutOne: {
        labelCol: {
          xxl: { span: 3 },
          xl: { span: 4 },
          lg: { span: 4 },
          md: { span: 4 },
          sm: { span: 4 },
          xs: { span: 4 }
        },
        wrapperCol: {
          xxl: { span: 21 },
          xl: { span: 20 },
          lg: { span: 20 },
          md: { span: 20 },
          sm: { span: 20 },
          xs: { span: 20 }
        }
      },

      rowProps: {
        type: 'flex',
        justify: 'start',
        gutter: { xs: 8, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 }
      },

      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 12,
        style: { textAlign: 'left' }
      },

      decorators: {
        originalValue: ['originalValue', { rules: [{ required: false, message: '请输入原始值' }] }],
        value: ['value', { rules: [{ required: false, message: '请输入结果值' }] }],
        checkTime: ['checkTime', { initialValue: this.moment(), rules: [{ required: false, message: '请选择检测时间' }] }],
        volume: ['volume', {}],
        note: ['note', {}]
      }
    }
  },

  computed: {
    resultDataType () {
      return { ...this.$store.state.code.cache.check_result_data_type }
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (val) {
        if (val) {
          this.data.id = val
          this.queryById(val)
          this.$nextTick(() => {
            this.show()
          })
        }
      }
    },

    'fullModal.visible' (val) {
      if (val) {
        this.checkCheckUnitDict()
      }
    }
  },

  created () {
    this.$store.dispatch('code/select', 'check_result_data_type')
  },

  methods: {
    moment,

    // 根据ID 查询检测数据
    queryById (id) {
      checkTaskResultApi.getById(id).then(res => {
        if (res.code === 0) {
          // 加载设备数据
          if (res.data.deviceIds) {
            this.queryDeviceInfo(res.data.deviceIds.split(','))
          }

          // 赋值数据
          const pickData = pick(res.data, Object.keys(this.data))
          Object.assign(this.data, pickData)
          this.sampleInfoProps.sampleId = res.data.sampleId
          this.fileList = res.data.accessory && JSON.parse(res.data.accessory) || []
          this.dataForm.$nextTick(() => {
            // this.deviceInfoProp.dataSource
            this.checkUnitSelect.defaultValue.splice(0, this.checkUnitSelect.defaultValue.length, ...(res.data.volumeUnit && res.data.volumeUnit.split(',') || []))
            this.dataForm.setFieldsValue({
              originalValue: res.data.originalValue,
              value: res.data.value,
              checkTime: this.moment(res.data.checkTime),
              volume: res.data.volume,
              note: res.data.note
            })
          })
          this.queryProjectItemGuide()
        } else {
          this.$message.error(`获取检测数据详情失败！${res.msg}`)
        }
      })
    },

    queryDeviceInfo (ids) {
      Get('/ms-api/res-dev/getByIds', { ids }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length) {
            res.data.forEach(item => {
              item.materialName = item.devName
              item.materialId = parseInt(item.type)
            })
            this.handleDeviceInfo(res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    async queryProjectItemGuide () {
      jcListApi.queryProjCheckGuide({ projId: this.data.projId, page: false }).then(res => {
        if (res.code === 0) {
          const itemGuide = res.data.length && res.data.find(item => item.cateId === this.data.cateId && item.itemId === this.data.itemId) || null
          if (itemGuide && itemGuide.id) {
            const jcList = [{ jcListId: itemGuide.id, standardCode: itemGuide.standardCode, methodId: itemGuide.methodId, methodName: itemGuide.methodName, limitValue: itemGuide.limitValue }]
            this.data.jcList.splice(0, this.data.jcList.length, ...(jcList || []))
            this.data.methodId = this.data.jcList[0].methodId
            this.data.standardNo = this.data.jcList[0].standardCode
            this.data.jcListId = this.data.jcList[0].jcListId
            this.data.methodName = this.data.jcList[0].methodName
            this.data.limitValue = this.data.jcList[0].limitValue
            this.checkDevices = this.data.jcList[0].jcDevices
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 选择检测设备
    showSearchDevice () {
      this.deviceInfoProp.show()
      const selectKey = this.deviceInfoProp.dataSource && this.deviceInfoProp.dataSource.map(item => item.id) || []
      this.$nextTick(() => {
        this.$refs[this.deviceInfoProp.ref].handleAdd({
          type: 'device',
          materialName: this.checkDevices || ''
        }, selectKey)
      })
    },

    // 处理选择设备事件，如果图片不存在，去找类目图片
    handleDeviceInfo (info) {
      this.deviceInfoProp.dataSource.splice(0, this.deviceInfoProp.dataSource.length, ...info)
      info.map(item => {
        if (!item.materialImg) {
          this.getMaterialImg(item.materialId).then((data) => {
            if (Object.prototype.hasOwnProperty.call(item, 'materialImgs')) {
              item.materialImgs.split(0, item.materialImgs.length, ...data)
            } else {
              this.$set(item, 'materialImgs', data)
            }
          })
        }
      })
    },

    // 获取类目图片
    async getMaterialImg (materialId) {
      await this.queryMaterialImg(materialId)
      return this.resourceImagesCache[materialId]
    },

    queryMaterialImg (materialId) {
      return resMaterialImgApi.list({ materialId: materialId, page: false }).then(res => {
        if (res.code === 0) {
          this.resourceImagesCache[materialId] = res.data.map(item => this.viewUrl + item.materialImg) || []
        } else {
          this.$message.error(`获取物资类目图片出错，错误信息：${res.msg}`)
        }
      })
    },

    // 二维码传图
    showQrcode (id) {
      this.addPictureId = id
      this.$refs.pictureUploadModal.show('二维码', `checkTaskResult-${id}-${randomString(6)}`)
    },

    // 处理保存图片
    savePicture (data) {
      this.fileList.splice(0, this.fileList.length, ...data)
    },

    handlePreview (file) {
      this.previewImage = file.thumbUrl || sysCommonApi.view + '/' + file.url
      this.previewVisible = true
    },

    handleFileChange ({ file, fileList }) {
      this.fileList = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.preview + '?filePath=' + file.response.data.fileUrl
          file.url = file.response.data.fileUrl
          fileList.forEach(item => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },

    handleSubmit () {
      this.dataForm.validateFields((errors, values) => {
        if (!errors) {
          if (this.fileList && this.fileList.length) {
            let accessory = cloneDeep(this.fileList)
            accessory = accessory.filter(it => !it.error)
            for (let i = 0; i < accessory.length; i++) {
              accessory[i] = omit(accessory[i], ['lastModified', 'lastModifiedDate', 'originFileObj', 'originFileObj', 'thumbUrl', 'percent', 'response'])
            }
            this.data.accessory = JSON.stringify(accessory)
          } else {
            this.data.accessory = null
          }

          const deviceIds = this.deviceInfoProp.dataSource.map(item => item.id).join(',')
          const deviceNos = this.deviceInfoProp.dataSource.map(item => item.fixedAssetsId).join(',')
          const deviceNames = this.deviceInfoProp.dataSource.map(item => item.materialName).join(',')
          checkTaskResultApi.save(Object.assign(this.data, values, { deviceIds, deviceNos, deviceNames })).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.addSampleStatusDictDetail()
              this.$emit('success')
              this.close()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    previewFile (file) {
      window.open(sysCommonApi.preview + '?filePath=' + file.url, file.name, null, false)
    },

    // 准备计量单位字典
    checkCheckUnitDict () {
      // 字典是否存在，不存在保存一个
      dictApi.checkCode({ code: 'checkCustomizeUnit' }).then(res => {
        if (!res) {
          this.addCheckUnitDict()
        }
      })

      customFunc._self = this
      customFunc.getDictCode((data) => {
        this.checkUnitSelect.dataSource = data && data.length && data.map(item => item.label) || []
      }, ['checkCustomizeUnit'])
    },

    // 添加计量单位字典
    addCheckUnitDict () {
      const dictData = {
        dictName: '检测计量单位',
        dictCode: 'checkCustomizeUnit',
        dictDesc: '检测自定义计量单位'
      }

      dictApi.add(dictData).then(res => {
        if (res.code !== 0) {
          this.$message.error(`保存检测计量单位字典发生错误：${res.msg}`)
        }
      })
    },

    // 添加计量单位字典详情
    addSampleStatusDictDetail () {
      const checkUnitArr = this.checkUnitSelect.dataSource.map((item, index) => {
        return {
          dictCode: 'checkCustomizeUnit',
          status: 1,
          itemText: item,
          itemValue: index + 1,
          sort: index + 1
        }
      })
      this.$store.commit('code/update', checkUnitArr)
      this.$store.commit('code/addAll', checkUnitArr)
    },

    init (data) {
      this.CheckGuideProps.projId = data.projId
      this.sampleInfoProps.sampleId = data.sampleId
      this.data.projId = data.projId
      this.data.sampleId = data.sampleId
      this.data.checkTaskId = data.id
      this.data.cateId = data.cateId
      this.data.itemId = data.itemId
      this.executeStandardProp.cateId = data.cateId
      this.executeStandardProp.itemId = data.itemId
      this.$nextTick(() => {
        this.show()
        this.queryProjectItemGuide()
      })
    },

    show () {
      this.fullModal.visible = true
    },

    close () {
      this.fullModal.visible = false
      this.$emit('cancel')
    },

    handleVolumeUnitChange (val) {
      this.data.volumeUnit = val
    },

    disabledDate (current) {
      return current && current >= this.moment()
    }
  }

}
</script>

<style lang="less" scoped>

  /deep/ .ant-form .ant-form-item {
    margin-bottom: 0px !important;
  }

  .full-modal-wrapper {
    font: 12px/1.5 "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;

    dl:last-child {
      border-bottom: 0;
    }

    dl {
      clear: both;
      padding: 10px 0px 0PX;
      color: #666666;

      dt {
        color: #999999;
        overflow: hidden;
      }
    }

    .form-data {
      dl {
        font: 12px/1.5 "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;

        position: relative;
        margin: 0;
        display: flex;
        border-bottom: 1px solid #F2F2F2;

        dt {
          color: #999999;
          overflow: hidden;
          min-width: 78px;

          &::after {
            content: '：';
          }
        }

        dd {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }
    }

  }

  .device {
    margin: 0 auto 8px;
    width: 100px;
    height: 128px;
    position: relative;

    &-info {
      width: 100px;
      height: 100px;
      border: 1px solid #AAAAAA;
      background: #C9C9C9;
    }

    &-info:hover {
      .device-operation {
        display: block;
      }
    }

    &-operation {
      height: 100px;
      width: 100px;
      background: #BBBBBB;
      position: absolute;
      display: none;
      top: 0;
      z-index: 1;

      &-delete {
        font-size: 24px;
        color: #FFFFFF;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      &-add {
        font-size: 54px;
        text-align: center;
        line-height: 86px;
        color: #FFFFFF;
      }
    }

    &-title {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

</style>
