<template>
  <div>
    <div>
      <!--      <a-row style="text-align: center;background-color: rgba(255,223,209,1);line-height:54px;font-size: 18px;font-family:PingFang SC;font-weight:400;">-->
      <!--        {{ projectInfo.quoteTitle }}   <a-tag style="margin-left: 15px;font-size: 16px;" v-if="quoteTypeMap[projectInfo.quoteType]" color="blue">{{ quoteTypeMap[projectInfo.quoteType] ? quoteTypeMap[projectInfo.quoteType].itemText : projectInfo.quoteType }}</a-tag>-->
      <!--      </a-row>-->
      <a-row style="margin-top:20px;" align="middle">
        <a-col :span="2" style="line-height: 48px;text-align: center;background:#1890FF;border-radius:0px 8px 0px 0px;font-size:16px;font-weight:bold;color:#FFFFFF;">基本信息</a-col>
        <a-col :span="16" style="line-height: 48px;font-size:14px;color:#333333;padding-left: 20px;">单号：{{ projectInfo.quoteSn }}</a-col>
        <a-col :span="6" style="line-height: 48px;"><div style="float: left;">报价金额：</div><div style="float: left;font-size:22px;color: #FF5722">￥{{ projectInfo.quoteFinalAmount }}</div></a-col>
      </a-row>
      <a-row>
        <table class="mxtable">
          <tr>
            <th rowspan="5">项目信息</th>
            <td class="tdLabel">项目名称</td>
            <td colspan="3">{{ projectInfo.projName }}</td>
            <td class="tdLabel">项目编号</td>
            <td>{{ projectInfo.projNo }}</td>
          </tr>
          <tr>
            <td class="tdLabel">项目等级</td>
            <td>
              <template v-if="projectInfo.projLevel=='1'">普通</template>
              <template v-if="projectInfo.projLevel=='2'">重要</template>
              <template v-if="projectInfo.projLevel=='3'">加急</template>
            </td>
            <td class="tdLabel">报告类型</td>
            <td>{{ reportTypeMap[projectInfo.projReportType] }}</td>
            <td class="tdLabel">项目日期</td>
            <td>{{ projectInfo.projDate }}</td>
          </tr>
          <tr>
            <td class="tdLabel">样品来源</td>
            <td>{{ sampleSourceMap[projectInfo.projSampleSource] }}</td>
            <td class="tdLabel">负责人</td>
            <td> {{ projectInfo.projUserId }}</td>
            <td class="tdLabel">联系电话</td>
            <td> {{ projectInfo.projContactPhone }}</td>
          </tr>
          <tr>
            <td class="tdLabel">上传项目文件</td>
            <td colspan="5">
              <FileList :fileList="projectInfo.fileList"/>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">填写项目描述</td>
            <td colspan="5">{{ projectInfo.projDescription }}</td>
          </tr>
          <tr>
            <th rowspan="3">客户信息</th>
            <td class="tdLabel">委托单位</td>
            <td>{{ projectInfo.entrustCustomerName }}</td>
            <td class="tdLabel">联系人</td>
            <td>{{ projectInfo.entrustContactsName }}</td>
            <td class="tdLabel">联系电话</td>
            <td>{{ projectInfo.entrustContactsPhone }}</td>
          </tr>
          <tr>
            <td class="tdLabel">受检单位</td>
            <td>{{ projectInfo.customerName }}</td>
            <td class="tdLabel">联系人</td>
            <td>{{ projectInfo.customerContactsName }}</td>
            <td class="tdLabel">联系电话</td>
            <td>{{ projectInfo.customerContactsPhone }}</td>
          </tr>
          <tr>
            <td class="tdLabel">地址</td>
            <td colspan="3">
              {{ projectInfo.projWholeAddress }}
            </td>
            <td colspan="2"></td>
          </tr>
        </table>
      </a-row>
    </div>
  </div>
</template>

<script>
import { projectApi, projContractApi } from '@/api/project'
import FileList from '@/pages/common/file/FileList'
export default {
  name: 'ProjectBaseView',
  components: {
    FileList
  },
  props: {
    projId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      projectInfo: {},
      contarctFileList: [],
      projLevelList: [{ id: '1', name: '普通' }, { id: '2', name: '重要' }, { id: '3', name: '加急' }],
      invoiceList: [{ id: '0', name: '不开' }, { id: '1', name: '专票' }, { id: '2', name: '普票' }],
      reportReceiveList: [{ id: '1', name: '邮寄' }, { id: '2', name: '自取' }, { id: '9', name: '其他' }]
    }
  },
  methods: {
    // 查询项目信息
    getProject (projId) {
      if (projId) {
        projectApi.get({ id: projId }).then(result => {
          if (result.code === 0) {
            if (result.data) {
              this.$set(this, 'projectInfo', result.data)
            }
          }
        })
      }
    }
  },
  watch: {
    projId: {
      handler (nv, ov) {
        this.getProject(nv)
      },
      immediate: true
    }
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    }
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
    this.$store.dispatch('code/select', 'quote_type')
  }
}
</script>

<style lang="less">
  .modal-container {
    height: 100%;
    background-color: #e4e7ed4d;

    .ant-modal {
      height: 100%;
      overflow: hidden;
      padding-bottom: 0px;

      .ant-modal-content {
        height: 100%;
        overflow: hidden;

        .ant-modal-body {
          overflow: auto;
          padding: 20px;
          height: ~"calc(100% - 108px)";
        }

        .ant-modal-body::-webkit-scrollbar {
          display: none;
        }

        .ant-modal-footer {
          text-align: center;
        }
      }
    }
  }

  .mxtable1 {
    width: 95%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
  }
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      min-width: 12%;
      max-width: 22%;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
  .JDate>div>input {
    color:rgb(228, 0, 1)
  }
  // .placeInput::placeholder{
  //   color:rgb(228, 0, 1,.3)
  // }
</style>
<style scoped>

</style>
