<template>
  <a-modal
    :centered="true"
    title="人员信息详情"
    v-model="visible"
    :visible="visible"
    width="100%"
    wrapClassName="modal-container"
    :destroyOnClose="true"
  >
    <div class="utabs" style="height: 100%">
      <a-tabs
        style="height: 100%;padding: 0;margin: 0;overflow: hidden;"
        tabPosition="left"
      >
        <a-tab-pane
          key="base"
          :closable="false"
          tab="基本信息"
          style="height: 100%;margin-left: 0;overflow-y: auto">
          <a-card title="基本信息" :headStyle="headStyle">
            <div style="display: flex;width: 100%;">
              <div style="flex: auto;color: rgba(0, 0, 0, 0.45);line-height: 22px; margin-left: 20px;">
                <detail-list size="default" :col="itemCol" class="detail-layout">
                  <detail-list-item term="姓名">{{ record.name }}</detail-list-item>
                  <detail-list-item term="性别">{{ record.sexName }}</detail-list-item>
                  <detail-list-item term="出生日期">{{ record.borth && moment(record.borth).format('YYYY-MM-DD') || '-' }}</detail-list-item>
                  <detail-list-item term="籍贯">{{ record.nativePlace | nullReplace }}</detail-list-item>
                  <detail-list-item term="民族">{{ record.nation | nullReplace }}</detail-list-item>
                  <detail-list-item term="身份证号">{{ record.cardId }}</detail-list-item>
                  <detail-list-item term="办公电话"><a>{{ record.officeTel | nullReplace }}</a></detail-list-item>
                  <detail-list-item term="家庭电话">{{ record.homeTel | nullReplace }}</detail-list-item>
                  <detail-list-item term="手机号码">{{ record.phone | nullReplace }}</detail-list-item>
                  <detail-list-item term="电子邮件">{{ record.email | nullReplace }}</detail-list-item>
                  <detail-list-item term="最高学历">{{ record.eduLevel | nullReplace }}</detail-list-item>
                  <detail-list-item term="毕业院校及专业">{{ record.university | nullReplace }}</detail-list-item>
                  <detail-list-item term="毕业时间">{{ record.gradTime && moment(record.gradTime).format('YYYY-MM-DD') || '-' }}</detail-list-item>
                  <detail-list-item term="外语水平">{{ record.languages | nullReplace }}</detail-list-item>
                  <detail-list-item term="所在部门">{{ record.deptName | nullReplace }}</detail-list-item>
                  <detail-list-item term="岗位">{{ record.selectedJob }}</detail-list-item>
                  <detail-list-item term="是否在职">{{ record.statusName }}</detail-list-item>
                  <detail-list-item term="入职时间">{{ record.entryTime && moment(record.entryTime).format('YYYY-MM-DD') || '-' }}</detail-list-item>
                  <detail-list-item term="离职时间">{{ record.quitTime && moment(record.quitTime).format('YYYY-MM-DD') || '-' }}</detail-list-item>
                  <detail-list-item term="在编情况">{{ record.preparatStatus === '0' ? '是' : record.preparatStatus === '1' ? '否' : '-' }}</detail-list-item>
                </detail-list>
                <detail-list size="default" :col="itemCol2" class="detail-layout">
                  <detail-list-item term="家庭住址">{{ record.address | nullReplace }}</detail-list-item>
                  <detail-list-item term="科研成果">{{ record.scResearch | nullReplace }}</detail-list-item>
                  <detail-list-item term="获奖及荣誉">{{ record.honor | nullReplace }}</detail-list-item>
                  <detail-list-item term="备注">{{ record.remark | nullReplace }}</detail-list-item>
                </detail-list>
              </div>
              <div style="flex: 0 1 auto;margin: 0 2%;min-width: 242px;text-align: right;">
                <div class="avatar-upload">
                  <img
                    class="defaults-img"
                    v-if="imageUrl"
                    :src="imageUrl"
                    alt="avatar" />
                  <div v-else class="defaults-icon">
                    <a-icon type="user" />
                  </div>
                </div>
                <div style="margin-top: 10px; margin-left: 20px; text-align: left">
                  <span style="font-weight: bold">员工编号：</span><span>{{ record.staffNum | nullReplace }}</span>
                </div>
              </div>
            </div>
          </a-card>
        </a-tab-pane>
        <a-tab-pane
          key="ex"
          :closable="false"
          tab="经历背景"
          style="height: 100%;margin-left: 0;overflow-y: auto">
          <a-card
            :title="item.title"
            v-for="item in itemList"
            :key="item.type"
            style="margin-top: 20px"
            :headStyle="headStyle">
            <a-table
              :columns="item.columns"
              :dataSource="item.data"
              :pagination="false"
              :loading="item.loading"
            >
              <template v-for="(col, i) in ['totalTime', 'company', 'content']" :slot="col" slot-scope="text, itemRecord">
                <template v-if="item.columns[i].key === 'totalTime'">{{ itemRecord.startTime }} 至 {{ itemRecord.endTime }}</template>
                <template v-else>{{ text }}</template>
              </template>
            </a-table>
          </a-card>
        </a-tab-pane>
        <a-tab-pane
          key="appendix"
          :closable="false"
          tab="档案附件"
          style="height: 100%;margin-left: 0;overflow-y: auto">
          <a-card
            title="人事合同"
            :bordered="true"
            style="margin-top: 20px"
            :headStyle="headStyle">
            <a-table
              :columns="columns"
              :dataSource="personnelContractList"
              :pagination="false"
              :loading="loading">
              <template slot="index" slot-scope="text, record, index">
                {{ index + 1 }}
              </template>
              <template slot="contractPeriod" slot-scope="text, record">
                {{ getMomentDiff(record) }}
              </template>
              <template slot="fileName" slot-scope="text, record">
                <a @click="showFile(record.fileUrl)">{{ record.fileName }}</a>
              </template>
            </a-table>
          </a-card>
          <a-card
            title="档案附件"
            :bordered="true"
            style="margin-top: 20px"
            :headStyle="headStyle">
            <!--          <div style="width: 100%;height: 50px">-->
            <!--            <a-button style="float: right;margin: 0 10px" @click="handleCancel">批量下载</a-button>-->
            <!--          </div>-->
            <a-table
              style="margin-bottom: 10px;"
              ref="fileTable"
              :columns="fileColumns"
              :dataSource="fileTypeList"
              :rowKey="record => record.id"
              :bordered="true"
              :pagination="false">
              <template slot="serial" slot-scope="text, record, index">
                {{ index + 1 }}
              </template>
              <template slot="itemText" slot-scope="text, record">
                <span v-if="record.itemDesc && record.itemDesc.indexOf('必填') >= 0" style="color: #f5222d;font-size: 16px;">*</span>
                {{ text }}
              </template>
              <template slot="uploadFile" slot-scope="text, record">
                <FileDisplayView :fileArray="fileList.filter(item => item.staffFileType === record.itemValue)"></FileDisplayView>
              </template>
            </a-table>
          </a-card>
        </a-tab-pane>
      </a-tabs>
    </div>
    <template slot="footer" class="ant-confirm-temp">
      <a-button :style="{marginRight: '8px'}" @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { resStaffApi, Get } from '@/api/quote'
import { commDictApi, sysCommonApi } from '@/api/common'
import { sysUserRefAgreementApi } from '@/api/system'
import moment from 'moment'
import FileDisplayView from '@/pages/cloud-quote/res/res-staff/modules/FileDisplayView'
import DetailList from '@/components/tools/DetailList'
import { itemList, fileColumns, fileTypeDictCode } from '@/pages/cloud-quote/res/res-staff/modules/StaffColumn'
const DetailListItem = DetailList.Item
export default {
  name: 'StaffModel',
  components: {
    FileDisplayView,
    DetailList,
    DetailListItem
  },
  data () {
    return {
      headStyle: {
        background: '#fafafa',
        color: '#1890ff'
      },
      visible: false,
      zIndex: 3000,
      record: {},
      itemCol: 3,
      itemCol2: 1,

      // 头像
      imageUrl: '',
      viewUrl: sysCommonApi.view,
      fileList: [],

      itemList,
      fileColumns,
      stationList: [],
      fileTypeList: [],
      fileTypeDictCode,
      columns: [
        { title: '序号', dataIndex: 'index', key: 'index', width: '50px', scopedSlots: { customRender: 'index' } },
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name',
          width: '150px',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '合同起始',
          dataIndex: 'startTime',
          key: 'startTime',
          width: '150px',
          scopedSlots: { customRender: 'startTime' }
        },
        {
          title: '合同结束',
          dataIndex: 'endTime',
          key: 'endTime',
          width: '150px',
          scopedSlots: { customRender: 'endTime' }
        },
        {
          title: '合同期限',
          dataIndex: 'contractPeriod',
          key: 'contractPeriod',
          width: '200px',
          scopedSlots: { customRender: 'contractPeriod' }
        },
        {
          title: '合同金额（元）',
          dataIndex: 'contractAmount',
          key: 'contractAmount',
          width: '150px',
          scopedSlots: { customRender: 'contractAmount' }
        },
        {
          title: '合同附件',
          dataIndex: 'fileName',
          key: 'fileName',
          width: '200px',
          scopedSlots: { customRender: 'fileName' }
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
          width: '200px',
          scopedSlots: { customRender: 'remarks' }
        }
      ],
      loading: false,
      personnelContractList: []
    }
  },
  filters: {
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  methods: {
    moment,
    show (record, stationList) {
      this.visible = true
      this.fileList = []
      this.personnelContractList = []
      this.imageUrl = ''
      this.selectedJob = ''
      for (const i in this.itemList) {
        this.itemList[i].data = []
      }
      record.sexName = record.sex === '0' ? '男' : '女'
      if (record.status === '0') {
        record.statusName = '在职'
      } else if (record.status === '1') { // 0:在职 1:离职 2:废弃 3:待入职
        record.statusName = '离职'
      } else if (record.status === '2') {
        record.statusName = '废弃'
      } else if (record.status === '3') {
        record.statusName = '待入职'
      }
      // record.statusName = record.status === '0' ? '在职' : '离职'
      record.borth = record.borth !== null && record.borth.length > 10 ? record.borth.substr(0, 10) : record.borth
      record.gradTime = record.gradTime !== null && record.gradTime.length > 10 ? record.gradTime.substr(0, 10) : record.gradTime
      record.entryTime = record.entryTime !== null && record.entryTime.length > 10 ? record.entryTime.substr(0, 10) : record.entryTime
      record.quitTime = record.quitTime !== null && record.quitTime.length > 10 ? record.quitTime.substr(0, 10) : record.quitTime
      this.imageUrl = record.imgUrl == null || record.imgUrl === '' ? '' : this.viewUrl + record.imgUrl

      // 获取工作经历、教育背景等相关信息
      Get(resStaffApi.staffList, record).then(res => {
        if (res.code === 0) {
          this.fileList = res.data.fileList
          const it = res.data.items
          if (typeof (it) !== 'undefined') {
            for (const i in it) {
              it[i].type !== null && this.itemList[parseInt(it[i].type) - 1].data.push(
                {
                  type: it[i].type,
                  staffId: it[i].staffId,
                  company: it[i].company,
                  content: it[i].content,
                  startTime: it[i].startTime.length > 10 ? it[i].startTime.substr(0, 10) : it[i].startTime,
                  endTime: it[i].endTime.length > 10 ? it[i].endTime.substr(0, 10) : it[i].endTime,
                  key: i,
                  totalTime: [moment(it[i].startTime, 'YYYY-MM-DD'), moment(it[i].endTime, 'YYYY-MM-DD')],
                  editable: false
                }
              )
            }
          }
        }
      })

      // 获取职位信息
      for (const j in stationList) {
        if (stationList[j].id === record.job) {
          record.selectedJob = stationList[j].stationName
        }
      }
      this.record = record
      this.initFileTypeList()
      this.getPersonnelContractList(record.id)
    },
    initFileTypeList () {
      commDictApi.list({ dictCode: this.fileTypeDictCode }).then(res => {
        this.fileTypeList = res.data
      })
    },
    getPersonnelContractList (id) {
      sysUserRefAgreementApi.selectInfoByStaffId(id).then((res) => {
        if (res.code === 0) {
          res.data.forEach(item => {
            item.startTime = item.startTime.substr(0, 10)
            item.endTime = item.endTime.substr(0, 10)
          })
          this.personnelContractList = res.data
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    showFile (url) {
      window.open(sysCommonApi.viewFile + url)
    },
    getMomentDiff (record) {
      let period = ''
      if (record.startTime && record.endTime) {
        const startTime = moment(record.startTime)
        const endTime = moment(record.endTime).add(1, 'days')
        const years = endTime.diff(startTime, 'years')
        const months = endTime.diff(startTime, 'months')
        const days = endTime.diff(startTime, 'days')
        if (years >= 1) {
          period = years + ' 年'
          const newStartY = startTime.add(years, 'years')
          const newMonth = endTime.diff(newStartY, 'months')
          if (newMonth >= 1) {
            period += '，' + newMonth + ' 个月'
            const newStartM = startTime.add(newMonth, 'months')
            const newDay = endTime.diff(newStartM, 'days')
            if (newDay >= 1) {
              period += '，零 ' + newDay + ' 天'
            }
          } else {
            const newDay = endTime.diff(newStartY, 'days')
            if (newDay >= 1) {
              period += '，零 ' + newDay + ' 天'
            }
          }
        } else if (months >= 1) {
          period = months + ' 个月'
          const newStartM = startTime.add(months, 'months')
          const newDay = endTime.diff(newStartM, 'days')
          if (newDay >= 1) {
            period += '，零 ' + newDay + ' 天'
          }
        } else if (days >= 1) {
          period = days + ' 天'
        }
      }
      return period
    }
  }
}
</script>

<style lang="less" scoped>
  .modal-container {
    height: 100%;

    .ant-modal {
      height: 100%;
      overflow: hidden;
      padding-bottom: 0px;

      .ant-modal-content {
        height: 100%;
        overflow: hidden;

        .ant-modal-body {
          overflow: auto;
          padding: 5px;
          height: ~"calc(100% - 108px)";
        }

        .ant-modal-body::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .avatar-upload {
    width: 172px;
    height: 222px;
    border:1px solid #cccccc;
    text-align: center;
  }
  .defaults-img{
    width: 170px;
    height: 220px;
  }
  .defaults-icon{
    font-size: 34px;
    height: 220px;
    line-height: 220px;
    color: #ccc;}
  .ant-confirm-temp{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    borderTop: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #FFFFFF;
    textAlign: right;
  }

  .utabs {
    .archive {
      margin: 0px 5px;
      border: 1px solid #91d5ff;
      background-color: #e6f7ff;
      padding: 8px;
      border-radius: 4px;
    }

    /deep/ .ant-tabs-content {
      height : 100%;
    }
  }
</style>
