<template>
  <div class="utabs" style="height: 100%">
    <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
      <a-tab-pane :closable="false" :key="idx" :tab="obj.templateName" style="height: 100%" v-for="(obj,idx) of templateList">
<!--        <a-row class="archive">-->
<!--          当前归档：<a>项目报价文件</a>  归档日期：2020-09-22 17:43  <a>重新归档</a>-->
<!--        </a-row>-->
        <a-row style="height:100%;">
          <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
          <Watermark/>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { anonProjectApi } from '@/api/project'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import Watermark from '@/pages/common/watermark/Watermark'
export default {
  name: 'QuoteDownloadPreview',
  components: { Watermark },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      templateParam: {
        page: false,
        templateType: fileTemplateUreportTypeEnum.quote + ',' + fileTemplateUreportTypeEnum.contract
      },
      curIdx: 0,
      templateList: [],
      url: '',
      localParams: {}
    }
  },
  watch: {
    params: {
      handler (nv, ov) {
        if (nv && nv.quoteId) {
          this.localParams = nv
        }
      },
      immediate: true
    }
  },
  computed: {
    iframeSrc: function () {
      if (this.templateList && this.templateList.length > 0) {
        var template = this.templateList[this.curIdx]
        var fileName = '报价单'
        if (this.localParams.fileName) {
          fileName = this.localParams.fileName
        }
        return `${fileTemplateUreportApi.preview}?_t=1,2,3,4,5&_u=${template.templateFile}&quoteId=${this.localParams.quoteId}&projId=${this.localParams.projId}&_n=${fileName}`
      }
      return ''
    }
  },
  methods: {
    selectTemplate (idx) {
      this.curIdx = idx
    },
    listFileTemplate () {
      anonProjectApi.listFileTemplate(this.templateParam).then(result => {
        if (result.code === 0) {
          this.templateList = result.data
        } else {
          this.$message.error(result.msg)
        }
      })
    }
  },
  created () {
    this.curIdx = 0
    this.listFileTemplate()
  },
  beforeRouteEnter  (to, from, next) {
    next(vm => {
      var params = vm.$route.query
      if (params && params.quoteId) {
        // 更新参数
        vm.$set(vm.localParams, 'quoteId', params.quoteId)
        vm.$set(vm.localParams, 'projId', params.projId)
        vm.$set(vm.localParams, 'fileName', params.fileName)
      }
    })
  }
}
</script>

<style scoped lang="less">
  .utabs {
    .archive {
      margin: 0px 5px;
      border: 1px solid #91d5ff;
      background-color: #e6f7ff;
      padding: 8px;
      border-radius: 4px;
    }

    /deep/ .ant-tabs-content {
      height : 100%;
    }
  }
</style>
