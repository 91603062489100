<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="850"
    :maskClosable="false"
    :destroyOnClose="true"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-table
      ref="table"
      size="small"
      :columns="table.columns"
      :dataSource="table.dataSource"
      :pagination="table.pagination"
      :rowKey="table.rowkey">
      >
      <template slot="recptPlanType" slot-scope="text, record, index">
        <!--          <a-input-->
        <!--            v-if="record.editable"-->
        <!--            style="margin: -5px 0"-->
        <!--            v-model="table.dataSource[index].recptPlanType"-->
        <!--          />-->
        <a-select
          v-if="record.editable"
          v-model="table.dataSource[index].recptPlanType"
          @change="(nv, ov)=>{if(nv=='1'){table.dataSource[index].recptPlanDate=''}else{table.dataSource[index].projProgress=''}}"
        >
          <a-select-option :key="'1'">项目阶段</a-select-option>
          <a-select-option :key="'2'">截止时间</a-select-option>
        </a-select>
        <template v-else>
          <div v-if="text=='1'">项目阶段</div>
          <div v-else-if="text=='2'">截止时间</div>
          <div v-else>{{ text }}</div>
        </template>
      </template>
      <template slot="recptPlanDate" slot-scope="text, record, index">
        <a-form-item
          style="margin-bottom: 0px"
          v-if="record.editable"
          :validate-status="validatePlanDate(record)"
        >
          <a-select
            style="width: 100%"
            v-if="record.recptPlanType==1"
            v-model="table.dataSource[index].projProgress"
          >
            <a-select-option v-for="vo in progressList" :key="vo.itemValue">{{ vo.itemText }}</a-select-option>
          </a-select>
          <a-date-picker
            v-else-if="record.recptPlanType==2"
            @change="(date, dateStr)=>{$set(table.dataSource[index],'recptPlanDate',dateStr)}"
            :defaultValue="str2Moment(record.recptPlanDate)"
          />
        </a-form-item>
        <template v-else>{{ showPlanDate(record) }}</template>
      </template>
      <template slot="recptPlanAmount" slot-scope="text, record, index">
        <a-form-item
          style="margin-bottom: 0px"
          v-if="record.editable"
          :validate-status="table.dataSource[index].recptPlanAmount?'success':'error'">
          <a-input-number
            v-if="record.editable"
            :precision="2"
            :min="1"
            :max="9999999999"
            :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')"
            style="width: 100%"
            placeholder="请输入金额"
            title="请输入金额不能为空"
            v-model="table.dataSource[index].recptPlanAmount"/>
        </a-form-item>
        <template v-else>{{ text }}</template>
      </template>
      <template slot="recptPlanRemark" slot-scope="text, record, index">
        <a-form-item
          style="margin-bottom: 0px"
          v-if="record.editable"
          :validate-status="record.recptPlanRemark&&record.recptPlanRemark.length<=500?'success':'error'">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            title="备注不能超过过500字"
            v-model="table.dataSource[index].recptPlanRemark">
            <a-icon type="info-circle" slot="suffixIcon"/>
          </a-input>
        </a-form-item>
        <template v-else>{{ text }}</template>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <div class="row-button">
          <a v-if="record.editable" @click="saveRow(record, index)" v-action:proj-receipt-plan:add><a-icon type="save"/>保存</a>

          <a v-else @click="editRow(index)" v-action:proj-receipt-plan:edit><a-icon type="edit"/>修改</a>

          <a-popconfirm title="是否要作废此行？" @confirm="delRow(record, index)">
            <a v-action:proj-receipt-plan:edit><a-icon type="delete"/>作废</a>
          </a-popconfirm>

        </div>
<!--        <div class="editable-row-operations">-->
<!--          <a-button-->
<!--            v-if="record.editable"-->
<!--            style="margin-right: 5px"-->
<!--            size="small"-->
<!--            @click="saveRow(record, index)"-->
<!--            icon="save"-->
<!--            title="保存"-->
<!--            v-action:proj-receipt-plan:add></a-button>-->
<!--          <a-button-->
<!--            v-else-->
<!--            size="small"-->
<!--            style="margin-right: 5px"-->
<!--            @click="editRow(index)"-->
<!--            icon="edit"-->
<!--            title="编辑"-->
<!--            v-action:proj-receipt-plan:edit></a-button>-->
<!--          <a-popconfirm title="是否要作废此行？" @confirm="delRow(record, index)">-->
<!--            <a-button size="small" @click="handleDel(record)" icon="delete" title="作废" v-action:proj-receipt-plan:edit></a-button>-->
<!--          </a-popconfirm>-->
<!--        </div>-->
      </template>
    </a-table>
    <a-button @click="addRow" style="width: 100%; margin-top: 5px; margin-bottom: 8px" type="dashed" icon="plus">新增</a-button>
  </a-modal>
</template>

<script>
import { commDictApi } from '@/api/common'
import { projReceiptApi, projReceiptPlanApi } from '@/api/project'
import moment from 'moment'
var vm = {}
export default {
  name: 'Form',
  components: {
  },
  props: {
  },
  data () {
    vm = this
    return {
      title: '收款计划',
      visible: false,
      projId: 127,
      orgId: null,
      progressList: [],
      table: {
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '计划类型', dataIndex: 'recptPlanType', scopedSlots: { customRender: 'recptPlanType' } },
          { title: '计划收款日期', dataIndex: 'recptPlanDate', width: '130px', scopedSlots: { customRender: 'recptPlanDate' } },
          { title: '计划收款金额', dataIndex: 'recptPlanAmount', scopedSlots: { customRender: 'recptPlanAmount' } },
          { title: '备注', dataIndex: 'recptPlanRemark', scopedSlots: { customRender: 'recptPlanRemark' } },
          { title: '操作', dataIndex: 'action', width: '160px', scopedSlots: { customRender: 'action' } }
        ],
        pagination: false
      }
    }
  },
  methods: {
    moment,
    add (record) {
      this.visible = true
      this.orgId = record.orgId
      this.projId = record.id
      this.listProjProgress()
    },
    edit (record) {
      this.info = record
      this.visible = true
      this.orgId = record.orgId
      this.projId = record.id
      this.listProjProgress()
      this.listPlanByProjId()
    },
    handleOk (e) {
      e.preventDefault()
      const self = this
      self.visible = false
    },
    handleCancel () {
      this.visible = false
    },
    listProjProgress () {
      commDictApi.list({ dictCode: 'proj_progress' }).then(result => {
        if (result.code === 0) {
          this.progressList = result.data
        }
      })
    },
    listPlanByProjId () {
      projReceiptPlanApi.listPlanByProjId({ projId: this.projId }).then(result => {
        if (result.code === 0) {
          this.table.dataSource = result.data
        }
      })
    },
    str2Moment (timeStr) {
      if (timeStr) {
        if (typeof timeStr === 'string') {
          return moment(timeStr, 'YYYY-MM-DD')
        } else if (typeof timeStr === 'object') {
          var res = []
          var isNull = true
          for (var idx in timeStr) {
            if (timeStr[idx]) {
              res.push(moment(timeStr[idx], 'YYYY-MM-DD'))
              isNull = false
            } else {
              res.push(null)
            }
          }
          return !isNull ? res : null
        }
      }
      return null
    },
    addRow () {
      this.table.dataSource.push({ orgId: this.orgId, projId: this.projId, recptPlanType: '2', editable: true })
    },
    saveRow (record, idx) {
      var self = this
      var saveApi = projReceiptPlanApi.add
      if (record.id) {
        // id不为空，表示修改
        saveApi = projReceiptPlanApi.edit
      }
      saveApi(record).then(result => {
        if (result.code === 0) {
          result.data.editable = false
          self.$set(self.table.dataSource, idx, result.data)
          this.$message.success(`${self.title}成功`)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    editRow (idx) {
      this.rowEditable(idx, true)
    },
    delRow (record, idx) {
      const self = this
      if (record.id) {
        projReceiptPlanApi.del(record.id).then(result => {
          if (result.code === 0) {
            self.table.dataSource.splice(idx, 1)
            self.$message.success(`作废成功`)
          } else {
            self.$message.error(`作废失败`)
          }
        })
      } else {
        self.table.dataSource.splice(idx, 1)
      }
    },
    rowEditable (idx, editable) {
      this.$set(this.table.dataSource[idx], 'editable', editable)
    },
    tableEdit (key) {
      const newData = this.table.dataSource
      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target.editable = true
        this.data = newData
      }
    },
    validatePlanDate (record) {
      if (record.recptPlanType == '1' && record.projProgress || record.recptPlanType == '2' && record.recptPlanDate) { // eslint-disable-line
        return 'success'
      } else {
        return 'error'
      }
    },
    showPlanDate (record) {
      if (record.recptPlanType === '1') {
        const obj = vm.progressList.filter(item => record.projProgress === item.itemValue)[0]
        return obj.itemText
      }
      return record.recptPlanDate
    }
  },
  filters: {
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
</style>
