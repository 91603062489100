import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 物资申请状态 字典枚举
export const resPurUseApplyStatusEnum = {

  // 待申请
  pending: '0',
  // 已申请
  applied: '1',
  // 已出库
  outBound: '2',
  // 部分出库
  partBound: '3'
}

//  物资申请状态 选择项
export const resPurUseApplyStatusOptions = [
  { label: '待申请', value: resPurUseApplyStatusEnum.pending, color: 'gray' },
  { label: '已申请', value: resPurUseApplyStatusEnum.applied, color: '#FF9600' },
  { label: '已出库', value: resPurUseApplyStatusEnum.outBound, color: 'blue' },
  { label: '部分出库', value: resPurUseApplyStatusEnum.partBound, color: 'orange' }
]

export const resPurUseApplyStatusMap = tranOptionsToMap(resPurUseApplyStatusOptions)
export const resPurUseApplyStatusDataMap = tranOptionsToDataMap(resPurUseApplyStatusOptions)
