import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 制样申请状态 字典枚举
export const makeSampleApplyStatusEnum = {
  // 已申请
  applyed: '0',
  // 已处理
  processed: '1',
  // 已退回
  backed: '2'
}

//  制样申请状态 选择项
export const makeSampleApplyStatusOptions = [
  { label: '已申请', value: makeSampleApplyStatusEnum.applyed, color: 'orange' },
  { label: '已处理', value: makeSampleApplyStatusEnum.processed, color: 'green' },
  { label: '已退回', value: makeSampleApplyStatusEnum.backed, color: 'red' }
]

export const makeSampleApplyStatusMap = tranOptionsToMap(makeSampleApplyStatusOptions)
export const makeSampleApplyStatusDataMap = tranOptionsToDataMap(makeSampleApplyStatusOptions)

// 制样申请紧急程度 字典枚举
export const makeSampleApplyLevelEnum = {
  // 普通
  normal: '1',
  // 加急
  urgent: '2'
}

//  制样申请紧急程度 选择项
export const makeSampleApplyLevelOptions = [
  { label: '普通', value: makeSampleApplyLevelEnum.normal, color: 'blue' },
  { label: '加急', value: makeSampleApplyLevelEnum.urgent, color: 'red' }
]

export const makeSampleApplyLevelMap = tranOptionsToMap(makeSampleApplyLevelOptions)
export const makeSampleApplyLevelDataMap = tranOptionsToDataMap(makeSampleApplyLevelOptions)

// 制样申请来源 字典枚举
export const makeSampleApplySourceEnum = {
  // 现场质控
  qualityControlSample: '1'
}

//  制样申请来源 选择项
export const makeSampleApplySourceOptions = [
  { label: '普通', value: makeSampleApplySourceEnum.qualityControlSample, color: 'blue' }
]

export const makeSampleApplySourceMap = tranOptionsToMap(makeSampleApplySourceOptions)
export const makeSampleApplySourceDataMap = tranOptionsToDataMap(makeSampleApplySourceOptions)
