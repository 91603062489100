<template>
  <div style="position: relative">
    <customize-column v-model="contentTable.columns" cache-key-pre="make-sample-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table :scroll="scrollProp" v-bind="contentTable">
      <template slot="expandedRowRender" slot-scope="record">
        <SampleItemTable
          :projId="record.projId"
          :projNo="record.projNo"
          v-bind="$attrs"
          v-on="$listeners"></SampleItemTable>
      </template>
      <template #makeTime="data, record">
        <template v-if="record.makeBeginTime">
          <span style="color: green">起：</span>{{ record.makeBeginTime | minuteFilter }}<br />
          <span style="color: red">止：</span>{{ record.makeEndTime | minuteFilter }}
        </template>
      </template>

      <template #itemNameStr="data, record"> {{ record.cateNameStr }}/{{ data }}</template>

      <template #volume="data, record">
        <span v-if="data">
          <span style="font-weight: bolder; font-size: 16px">{{ data }}</span> {{ record.volumeUnit }}
        </span>
        <span v-else>-</span>
      </template>

      <template #validity="data, record">
        <span v-if="data"
        ><span style="font-weight: bolder; font-size: 16px">{{ data }}</span> {{ record.validityUnit }}</span
        >
        <span v-else>-</span>
      </template>

      <template #status="status">
        <a-tag :color="makeSampleInfoStatusDataMap[status].color">{{ makeSampleInfoStatusDataMap[status].label }}</a-tag>
      </template>
    </s-table>
  </div>
</template>

<script>
import STable from '@/components/Table'
import list from '@/pages/common/mixins/list'
import mixinDate from '@/pages/common/mixins/date'

import { makeSampleApi } from '@/api/makeSample/makeSamplelApi'
import { sampleInfoTypeDataMap } from '@/api/sampleInfo/sampleInfoConstant'
import { makeSampleInfoStatusDataMap, makeSampleInfoStatusEnum, makeSampleInfoStatusMap } from '@/api/makeSample/makeSampleConstant'
import SampleItemTable from '@/pages/cloud-quote/make-sample/modules/sampleItemTable/index.vue'

import { CustomizeColumn } from '@/components/Columns'

export default {
  name: 'ProjectTable',
  inheritAttrs: false,
  mixins: [list, mixinDate],

  components: { STable, CustomizeColumn, SampleItemTable },

  data () {
    return {
      sampleInfoTypeDataMap,
      makeSampleInfoStatusEnum,
      makeSampleInfoStatusMap,
      makeSampleInfoStatusDataMap,
      contentTable: {
        rowKey: 'idTemp',
        bordered: true,
        columns: [
          { title: '项目编号', align: 'center', dataIndex: 'projNo', width: 160 },
          { title: '项目名称', align: 'center', dataIndex: 'projName', width: 300 },
          { title: '样品数量', align: 'center', dataIndex: 'num', width: 80 },
          { title: '最低有效期', align: 'center', dataIndex: 'makeBeginTime', width: 180 },
          { title: '当前状态', align: 'center', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
        ]
      }
    }
  },

  computed: {
    queryParam () {
      const { neType, temp } = this
      const result = Object.assign({}, { neType, temp }, this.$attrs.queryParam)
      return result
    }
  },

  methods: {
    // 根据查询条件获取数据
    loadData (param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      param.queryType = 'project'
      return makeSampleApi.list(Object.assign({}, param, this.queryParam)).then((res) => res)
    }
  }
}
</script>

<style lang="less" scoped>
.setting {
  position: absolute;
  z-index: 9;
  right: 16px;
  top: 14px;
}
</style>
