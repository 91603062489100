<template>
  <div>
    <template v-if="fileArray==null||fileArray.length==0">
      <div><span class="file-name-position" style="text-align: center;width: 80px;display: inline-block;color:#bcb0b0">无对应文件</span></div>
    </template>
    <template v-else>
      <template v-for="(file,index) in fileArray">
        <div class="custom-list-file" :key="index">
          <div :class="getCustomClass(file)" style="width: 80px;height: 80px">
            <a-dropdown :trigger="['contextmenu']">
              <div class="right-click-menu"></div>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="downLoadFile(file)">下载</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="viewFile(file)">预览</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div>
            <span
              class="file-name-position"
              style="text-align: center;width: 80px;display: inline-block;white-space:normal;word-break:break-all;word-wrap:break-word; ">
              {{ file.fileName }}
            </span>
          </div>
        </div>
      </template>
    </template>
    <a-modal :visible="previewVisible" :footer="null" @cancel="closeImg">
      <img alt="附件" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal :visible="previewPdfVisible" :footer="null" @cancel="closePdf" :width="pdfWidth">
      <iframe :src="previewUrl" style="width: 98%;height:700px;" ></iframe>
    </a-modal>
  </div>
</template>

<script>
import { sysCommonApi } from '@/api/common'
import { Get } from '@/api/quote'
export default {
  name: 'FileDisplayView',
  components: { sysCommonApi },
  props: {
    fileArray: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      previewUrl: '',
      previewImage: '',
      previewVisible: false,
      previewPdfVisible: false,
      pdfWidth: '70%'

    }
  },
  created () {
  },
  methods: {
    closePdf () {
      this.previewPdfVisible = false
    },
    closeImg () {
      this.previewVisible = false
    },
    downLoadFile (file) {
      window.location = sysCommonApi.downLoad + '?filePath=' + file.fileUrl + '&fileName=' + file.fileName + '&token=' + this.$store.getters.token
    },
    getCustomClass (file) {
      let result = 'custom-icon-error'
      const filename = file.fileName
      if (filename.lastIndexOf('.') < 0) {
        return 'custom-icon-folder'
      }
      const type = filename.substr(filename.lastIndexOf('.') + 1, filename.length).toLocaleLowerCase()
      switch (type) {
        case 'doc':
          result = 'custom-icon-doc'
          break
        case 'docx':
          result = 'custom-icon-docx'
          break
        case 'jpg':
          result = 'custom-icon-jpg'
          break
        case 'png':
          result = 'custom-icon-jpg'
          break
        case 'jpeg':
          result = 'custom-icon-jpg'
          break
        case 'pdf':
          result = 'custom-icon-pdf'
          break
        case 'txt':
          result = 'custom-icon-txt'
          break
        case 'xls':
          result = 'custom-icon-xls'
          break
        case 'xlsm':
          result = 'custom-icon-xlsm'
          break
        case 'xlsx':
          result = 'custom-icon-xlsx'
          break
        case 'xlt':
          result = 'custom-icon-xlt'
          break
      }
      return result
    },
    viewFile (file) {
      const filename = file.fileName
      const type = filename.substr(filename.lastIndexOf('.') + 1, filename.length).toLocaleLowerCase()
      /* if(type=='pdf'){
          this.previewPdfVisible = true
          this.previewUrl = sysCommonApi.preview + '?filePath='+file.fileUrl

        }else */ if (type === 'jpg' || type === 'png' || type === 'jpeg') {
        this.previewVisible = true
        this.previewImage = sysCommonApi.view + file.fileUrl
      } else {
        const projectFullUrl = window.location.origin
        const fileUrl = projectFullUrl + '/ms-api/common/download-by-id-path/id/' + file.id + '/type/6'
        /* this.$message.info("暂时只提供pdf以及图片在线预览，其他格式文件请自行下载") */
        Get(sysCommonApi.callUrlAndSign, { url: projectFullUrl + sysCommonApi.previewJson + '/id/' + file.id + '/type/6?fileUrl=' + fileUrl }).then(res => {
          window.open(res.data)
        })
      }
    }
  }
}
</script>
<style lang="less">
  .right-click-menu{
    width:100%;
    height:100%;
  }
</style>
