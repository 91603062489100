<script>
export default {
  name: 'CheckTaskVerifyTable'
}
</script>

<script setup>
import { defineProps, watchEffect, ref, computed, watch, defineExpose } from 'vue'
import { getCheckModelInfo, getCamelCaseField, fillDataByModelDefaultValue } from '@/pages/cloud-quote/check-task/modules/enter'
import CheckTaskVerifyHotTable from '@/pages/cloud-quote/check-task/modules/verify/VerifyHotTable.vue'
import { checkTaskEnterApi, checkTaskVerifyApi } from '@/api/check/checkTaskApi'
import { message } from 'ant-design-vue'
import { every, cloneDeep } from 'lodash'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

/**
 * @property {number} modelGroupId 检测模型组ID
 * @property {number} modelId 模型ID
 * @property {number} batchId 批次ID
 * @property {Array.<string>} sampleTypes 样品类型
 */
const props = defineProps({
  modelGroupId: { type: Number, required: true },
  modelId: { type: Number, required: true },
  batchId: { type: Number, required: true },
  sampleTypes: { type: Array, required: true },
  expressions: { type: Array, default: () => [] },
  sampleList: { type: Array, default: () => [] },
  taskList: { type: Array, default: () => [] },
  sampleKey: { type: String, default: '' }
})

const modelInfos = ref([])
watchEffect(() => {
  props.modelId &&
    getCheckModelInfo(props.modelId).then((res) => {
      modelInfos.value.splice(0, modelInfos.value.length, ...res)
    })
})

const hiddenColumns = computed(() => {
  return [...modelInfos.value.map((item, index) => (!item.isShow ? index + 1 : null)).filter((item) => !!item), modelInfos.value.length + 1]
})

const originalData = ref([])

/**
 * 查询检测录入的数据
 * @param modelGroupId {number} 检测模型组ID
 * @param batchId {number} 批次ID
 * @param modelId {number} 模型ID
 * @param sampleTypes {Array.<string>} 样品类型
 */
const getVerifyData = ({ modelGroupId, modelId, batchId, sampleTypes }) => {
  return checkTaskVerifyApi.getData(modelGroupId, modelId, batchId, sampleTypes).then((res) => {
    if (res.code === 0) {
      originalData.value.splice(0, originalData.value.length, ...(res.data || []))
    } else {
      message.error(`获取检测数据错误 :${res.msg}`)
    }
  })
}

watchEffect(() => {
  const { modelId, batchId, sampleTypes, modelGroupId } = props
  modelId && batchId && getVerifyData({ modelGroupId, modelId, batchId, sampleTypes })
})

/** 表格数据 */
const tableData = ref([])
const tableDataNum = ref(0)
// 临时表格数据
const tempData = ref([])
/**
 * 填充录入的数据
 * @param dataList 录入的原始数据
 */
const fillEnterData = (dataList) => {
  const result = fillDataByModelDefaultValue(dataList, modelInfos.value)
  if (
    props.sampleTypes.includes(sampleInfoTypeEnum.fieldBlank) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.transportBlank) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.allBlank) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.labBlank) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.fieldParallel) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.labParallel)
  ) {
    tableData.value.splice(0, tableData.value.length, ...result)
  } else if (
    props.sampleTypes.includes(sampleInfoTypeEnum.qualityControl) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.addStandardSample) ||
    props.sampleTypes.includes(sampleInfoTypeEnum.basisAddStandardSample)
  ) {
    tempData.value.splice(0, tempData.value.length, ...result)
    tableDataNum.value = result.length
    props.sampleList.length && tableDataNum.value > 0 && assignTableData()
  }
}

/** 处理表格数据的对象 */
watch(
  () => ({ modelInfos, originalData }),
  (newValue) => {
    const { modelInfos, originalData } = newValue
    modelInfos.value.length && originalData.value.length && fillEnterData(originalData.value)
  },
  { deep: true }
)

const assignTableData = (type) => {
  tableDataNum.value = 0
  let data = tempData.value.map((item) => {
    let itemList = props.sampleList.filter((item2) => item.sampleId === item2.sampleId)
    if (itemList.length > 0) {
      item.uncertainty = itemList[0].uncertainty
      item.uncertaintyUnit = itemList[0].uncertaintyUnit
    }
    return item
  })
  tableData.value = data
  tempData.value = []
}
watch(
  () => props.sampleList,
  (newValue) => {
    newValue.length && tableDataNum.value > 0 && assignTableData()
  },
  { deep: true }
)

/** hot table 列信息 */
const columns = computed(() => {
  const defaultColumns = [{ title: '样品编号', data: 'sampleId', index: 0, readOnly: true, hidden: false, labBlankAverageFlag: false, labBlankAverageFiled: null, type: 'text' }]
  const type = ['INT', 'BIGINT', 'TINYINT', 'SMALLINT', 'DECIMAL']
  const modelColumns = modelInfos.value.reduce((acc, item, index) => {
    let title = item.aliasName || item.fieldName
    if (item.notNull && item.editAble) {
      title = `<span class="red">${item.aliasName || item.fieldName}</span>`
    } else if (!item.editAble) {
      title = `<span class="gray">${item.aliasName || item.fieldName}</span>`
    }
    acc.push({
      title,
      index: index + 1,
      data: getCamelCaseField(item.field),
      type: type.includes(item.type) ? 'numeric' : 'text',
      readOnly: !item.editAble,
      hidden: !item.isShow,
      formula: item.formula,
      labBlankAverageFlag: item.blankAverageFlag,
      labBlankAverageField: item.blankAverageField
    })
    return acc
  }, [])
  return defaultColumns.concat(modelColumns,[
        {
          title: '因子ID',
          data: 'itemId',
          index: modelInfos.value.length + 1,
          readOnly: true,
          hidden: true,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        },
        {
          title: '因子名称',
          data: 'itemName',
          index: modelInfos.value.length + 2,
          readOnly: true,
          hidden: false,
          labBlankAverageFlag: false,
          labBlankAverageField: null,
          type: 'text'
        }
      ])
})

/** hot table 表头信息 */
const colHeaders = computed(() => {
  return ['样品编号'].concat(modelInfos.value.map((item) => item.fieldName),['因子名称'])
})

/**
 * 查询平行样品是否达标的数据，计算依据为平均值averageValue
 */
const getMeetingStandardsData = (dataItem, originItem) => {
  let item = Object.assign({}, originItem, dataItem)
  if (props.taskList && props.taskList.length) {
    let list = props.taskList.filter((item2) => item2.sampleId === item.sampleId)
    if (list.length) {
      item.standardValue = list[0].standardValue
    }
  }

  if ((item.averageValue || item.averageValue === 0) && (item.standardValue || item.standardValue === 0)) {
    if (item.itemName.toUpperCase() === 'PH' || item.itemName.toUpperCase() === 'PH值') {
      let values = item.standardValue.split(',')
      let lowerValue = values[0] // 下限值
      let upperValue = values[1] // 上限值
      if (item.averageValue > 7.0) {
        // 标准指数=(最终结果-7.0)/(上限值-7.0)
        item.meetingValues = (item.averageValue - 7.0) / (upperValue - 7.0)
      } else {
        // 标准指数=(7.0-最终结果)/(7.0-下限值)
        item.meetingValues = (7.0 - item.averageValue) / (7.0 - lowerValue)
      }
      // 标准指数>1不达标，否则达标
      if (item.meetingValues > 1) {
        item.meetingStandards = 0
      } else {
        item.meetingStandards = 1
      }
    } else if (item.itemName === '溶解氧') {
      let T = 20 // 水温
      let DOf = 468 / (31.6 + T) // 饱和溶解氧浓度
      if (item.averageValue > DOf) {
        // 标准指数=(最终结果-饱和溶解氧浓度)/(饱和溶解氧浓度-标准限值)
        item.meetingValues = (item.averageValue - DOf) / (DOf - item.standardValue)
      } else {
        // 标准指数=标准限值/最终结果
        item.meetingValues = item.standardValue / item.averageValue
      }
      // 标准指数>1不达标，否则达标
      if (item.meetingValues > 1) {
        item.meetingStandards = 0
      } else {
        item.meetingStandards = 1
      }
    } else {
      // 标准指数=最终结果/标准限值
      item.meetingValues = item.averageValue / item.standardValue
      // 标准指数>1不达标，否则达标
      if (item.meetingValues > 1) {
        item.meetingStandards = 0
      } else {
        item.meetingStandards = 1
      }
    }
    item.meetingValues = item.meetingValues.toFixed(2)
  } else if (item.averageValue || item.averageValue === 0) {
    item.meetingStandards = -1
    item.meetingValues = '无'
  }

  return item
}

const table = ref(null)
const submit = async () => {
  const data = table.value?.getData()
  const noPassed = every(data, ['passed', '不合格'])
  if (noPassed) {
    await message.error('检测到数据存在不合格情况，校验不通过！')
    throw new Error('检测到数据存在不合格情况，校验不通过！')
  }
  let params = data.map((item) => {
    let itemList = tableData.value.filter((item2) => item.sampleId === item2.sampleId)
    if (itemList.length > 0) {
      if (!item.uncertainty) {
        item.uncertainty = itemList[0].uncertainty
      }
      if (!item.uncertaintyUnit) {
        item.uncertaintyUnit = itemList[0].uncertaintyUnit
      }
      // 只有平行样品判定需要赋值是否达标相关字段meetingValues、meetingStandards
      if (props.sampleKey === 'parallelModelId') {
        let meetingStandardsData = getMeetingStandardsData(item, itemList[0])
        item.meetingStandards = meetingStandardsData.meetingStandards
        item.meetingValues = meetingStandardsData.meetingValues
      }
    }
    return item
  })
  console.log(params)
  if (params?.length) {
    return checkTaskEnterApi.save(props.modelId, params)
  }
}
defineExpose({
  submit
})
</script>

<template>
  <div class="check-task-verify-hot-table">
    <template v-if="tableData.length">
      <check-task-verify-hot-table
        ref="table"
        :col-headers="colHeaders"
        :columns="columns"
        :expressions="props.expressions"
        :hidden-columns="hiddenColumns"
        :table-data="tableData"
      />
    </template>
  </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.check-task-verify-hot-table {
  ::v-deep(.handsontable) {
    .color();
  }
}
</style>
