const baseCurveDrawColumns = [
  { title: '编号', width: 50, dataIndex: 'drawIndex', scopedSlots: { customRender: 'drawIndex' } },
  { title: '标准使用液加入量', width: 120, dataIndex: 'standardLiquid', scopedSlots: { customRender: 'standardLiquid' }, unit: 'mL' },
  { title: '标准物质加入量', width: 120, dataIndex: 'concentration', scopedSlots: { customRender: 'concentration' }, unit: 'μg' }
]

const moreCurveDrawColumns = [
  { title: '吸光度A₁', width: 100, dataIndex: 'absorbanceValue', scopedSlots: { customRender: 'absorbanceValue' } },
  { title: '吸光度A₂', width: 100, dataIndex: 'absorbanceValue2', scopedSlots: { customRender: 'absorbanceValue2' } },
  { title: '扣除空白平均吸光度A₁-A₂', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' } },
  { title: '备注', width: 120, dataIndex: 'remark', scopedSlots: { customRender: 'remark' } }
]

const curveDrawModels = [
  {
    id: 1,
    columns: [
      { title: '吸光度A₁', width: 100, dataIndex: 'absorbanceValue', scopedSlots: { customRender: 'absorbanceValue' }, unit: '' },
      { title: '吸光度A₂', width: 100, dataIndex: 'absorbanceValue2', scopedSlots: { customRender: 'absorbanceValue2' }, unit: '' },
      { title: '扣除空白平均吸光度A₁-A₂', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }]
  },
  {
    id: 2,
    columns: [
      { title: 'F含量', width: 100, dataIndex: 'absorbanceValue', scopedSlots: { customRender: 'absorbanceValue' }, unit: 'μg' },
      { title: '电压', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: 'mV' }]
  },
  {
    id: 3,
    columns: [
      { title: '测定浓度', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: 'mg/L' },
      { title: '吸光度', width: 120, dataIndex: 'absorbanceValue', scopedSlots: { customRender: 'absorbanceValue' }, unit: '' }
    ]
  },
  {
    id: 4,
    columns: [
      { title: '测定浓度', width: 120, dataIndex: 'purity', scopedSlots: { customRender: 'purity' }, unit: 'mg/L' },
      { title: '仪器峰面积', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }
    ]
  },
  {
    id: 5,
    columns: [
      { title: '吸光度A₂₂₀ₙₘ', width: 100, dataIndex: 'absorbanceValue', scopedSlots: { customRender: 'absorbanceValue' }, unit: '' },
      { title: '吸光度A₂₇₅ₙₘ', width: 100, dataIndex: 'absorbanceValue2', scopedSlots: { customRender: 'absorbanceValue2' }, unit: '' },
      { title: '校正吸光度Aₛ', width: 100, dataIndex: 'correctAbsorbance', scopedSlots: { customRender: 'correctAbsorbance' }, unit: '' },
      { title: '扣除空白校正吸光度Aₛ-A₀', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }]
  },
  {
    id: 6,
    columns: [
      { title: '吸光度A', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }]
  },
  {
    id: 7,
    columns: [
      { title: '色度', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }]
  },
  {
    id: 8,
    columns: [
      { title: '测定浓度', width: 120, dataIndex: 'purity', scopedSlots: { customRender: 'purity' }, unit: 'mg/L' },
      { title: '吸光度A₁', width: 100, dataIndex: 'absorbanceValue', scopedSlots: { customRender: 'absorbanceValue' }, unit: '' },
      { title: '吸光度A₂', width: 100, dataIndex: 'absorbanceValue2', scopedSlots: { customRender: 'absorbanceValue2' }, unit: '' },
      { title: '扣除空白平均吸光度A₁-A₂', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }]
  },
  // {
  //   id: 9,
  //   columns: [
  //     { title: '测定浓度', width: 120, dataIndex: 'purity', scopedSlots: { customRender: 'purity' }, unit: 'mg/L' },
  //     { title: '苯峰面积', width: 100, dataIndex: 'benArea', scopedSlots: { customRender: 'benArea' }, unit: 'μV*s' },
  //     { title: '甲苯峰面积', width: 100, dataIndex: 'jiabenArea', scopedSlots: { customRender: 'jiabenArea' }, unit: 'μV*s' },
  //     { title: '乙苯峰面积', width: 100, dataIndex: 'yibenArea', scopedSlots: { customRender: 'yibenArea' }, unit: 'μV*s' },
  //     { title: '对二甲苯峰面积', width: 100, dataIndex: 'duierjiabenArea', scopedSlots: { customRender: 'duierjiabenArea' }, unit: 'μV*s' },
  //     { title: '间二甲苯峰面积', width: 100, dataIndex: 'jianerjiabenArea', scopedSlots: { customRender: 'jianerjiabenArea' }, unit: 'μV*s' },
  //     { title: '邻二甲苯峰面积', width: 100, dataIndex: 'linerjiabenArea', scopedSlots: { customRender: 'linerjiabenArea' }, unit: 'μV*s' },
  //     { title: '异丙苯峰面积', width: 100, dataIndex: 'yibingbenArea', scopedSlots: { customRender: 'yibingbenArea' }, unit: 'μV*s' },
  //     { title: '苯乙烯峰面积', width: 100, dataIndex: 'benyixiArea', scopedSlots: { customRender: 'benyixiArea' }, unit: 'μV*s' }
  //   ]
  // }
  {
    id: 10,
    columns: [
      { title: '测定浓度', width: 120, dataIndex: 'purity', scopedSlots: { customRender: 'purity' }, unit: 'mg/L' },
      { title: 'cps', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }
    ]
  },
  {
    id: 11,
    columns: [
      { title: '测定浓度', width: 120, dataIndex: 'purity', scopedSlots: { customRender: 'purity' }, unit: 'mg/L' },
      { title: '峰面积比', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: '' }
    ]
  },
]

const remarkCurveDrawColumns = [
  { title: '备注', width: 120, dataIndex: 'remark', scopedSlots: { customRender: 'remark' } }
]

const actionColumns = [
  { title: '操作', width: 100, scopedSlots: { customRender: 'action' } }
]

const noActionCurveDrawColumns = [...baseCurveDrawColumns, ...moreCurveDrawColumns]

const defaultBaseCurveDrawColumns = [...baseCurveDrawColumns, ...actionColumns]

// 如果曲线类型中点位模板选择3，曲线点位信息显示这个
const defaultBaseCurveDrawColumns3 = [...baseCurveDrawColumns, { title: '测定浓度', width: 120, dataIndex: 'result', scopedSlots: { customRender: 'result' }, unit: 'mg/L' }, ...actionColumns]

// 如果曲线类型中点位模板选择4、8、9、10、11，曲线点位信息显示这个
const defaultBaseCurveDrawColumns4 = [...baseCurveDrawColumns, { title: '测定浓度', width: 120, dataIndex: 'purity', scopedSlots: { customRender: 'purity' }, unit: 'mg/L' }, ...actionColumns]

const defaultCurveDrawColumns = [...noActionCurveDrawColumns, ...actionColumns]

// 复合点位曲线图需要循环显示，在这里声明和点位模板对应的复合因子
// id和点位模板的一致
const compositeItems = [
  {
    id: 9,
    items: ['苯', '甲苯', '乙苯', '对二甲苯', '间二甲苯', '邻二甲苯', '异丙苯', '苯乙烯']
  },
  {
    id: 10,
    items: ['总烃', '甲烷']
  },
  {
    id: 11,
    items: ['氟离子', '氯离子', '亚硝酸根', '硝酸根', '溴离子', '磷酸根', '亚硫酸根', '硫酸根']
  },
  {
    id: 12,
    items: ['石油类', '动植物油（废水）']
  },
  {
    id: 13,
    items: ['碳酸根', '碳酸氢根', '碱度']
  }
]

export default defaultCurveDrawColumns
export { noActionCurveDrawColumns, defaultBaseCurveDrawColumns, defaultBaseCurveDrawColumns3, defaultBaseCurveDrawColumns4, baseCurveDrawColumns, curveDrawModels, remarkCurveDrawColumns, actionColumns, compositeItems }
