import { axios } from '@/utils/request'
import { filterNullObj } from '@/utils/util'
import Qs from 'qs'

export const sysCommonApi = {
  upload: '/ms-api/common/upload',
  uploadFile: '/ms-api/common/upload-file',
  downLoad: '/ms-api/common/download/',
  downLoadById: '/ms-api/common/download-by-id/',
  downLoadProjZip: '/ms-api/common/download-proj-zip/',
  downLoadProjLabelZip: '/ms-api/common/download-proj-label-zip/',
  downLoadCollectFileZip: '/ms-api/common/download-collect-file-zip/',
  downLoadProjSitePicZip: '/ms-api/common/download-proj-site-pic-zip/',
  view: '/ms-api/common/view',
  viewImg: '/ms-api/common/viewImg',
  viewVideo: '/ms-api/common/viewVideo',
  viewFile: '/ms-api/common/viewFile',
  projectFullUrl: 'http://ip.ahmux.com:8000',
  callUrlAndSign: '/ms-api/common/preview-url-sign',
  officeSignByPath: '/ms-api/common/office-sign-by-path',
  officePreviewUrl: 'http://ip.ahmux.com:1986/apps/editor/openPreview',
  previewJson: '/ms-api/common/preview-json'
}

// 字典接口
export const commDictApi = {
  list: (p) => getAction('/ms-api/comm-dict/list', p),
  get: (p) => getAction('/ms-api/comm-dict/get', p)
}

export const personApi = {
  groupDetail: (p) => getAction('/ms-api/person/group/detail', p),
  detailList: (p) => getAction('/ms-api/person/list-detail', p)
}

export const personGroupApi = {
  list: (p) => getAction('/ms-api/person-group/list', p),
  detailList: (p) => getAction('/ms-api/person-group/detail-list', p),
  add: (data) => postDataAction('/ms-api/person-group/add', data),
  delete: (data) => delAction(`/ms-api/person-group/del`, data)
}

// 临时图片
export const tempImageApi = {
  list: (p) => getAction('/ms-api/temp-image/list', p),
  del: (p) => delAction('/ms-api/temp-image/del', p)
}

export const baiduApi = {
  getLocationByLngLat: (p) => getAction('/ms-api/common/getLocationByLngLat', p)
}

export const adCodeApi = {
  list: (p) => getAction('/ms-api/district/list', p),
  get: (p) => getAction('/ms-api/district/get', p),
  getChildren: (p) => getAction('/ms-api/district/getChildren', p)
}

export const configApi = {
  list: () => getAction('/ms-api/sys-config/list'),
  get: () => getAction('/ms-api/sys-config/get'),
  save: (data) => postDataAction('/ms-api/sys-config/save', data),
  getByName: (param) => getAction('/ms-api/sys-config/getByName', param)
}

export const versionApi = {
  list: (param) => getAction('/ms-api/eims-version/list', param),
  last: () => getAction('/ms-api/eims-version/last'),
  delete: (param) => delAction('/ms-api/eims-version/del', param),
  add: (data) => postDataAction('/ms-api/eims-version/add', data),
  findById: (id) => getAction(`/ms-api/eims-version/findById/${id}`)
}

export function getAction (url, params) {
  return axios({
    url,
    method: 'get',
    params,
    paramsSerializer: function (params) {
      params = filterNullObj(params)
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
}

export function getAllAction (url, params) {
  return axios({
    url,
    method: 'get',
    params,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
}

export function downloadGetAction (url, params, responseType = 'blob') {
  return axios({
    url,
    method: 'get',
    responseType,
    params,
    paramsSerializer: function (params) {
      params = filterNullObj(params)
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
}

// 使用  @RequestBody 接收
export function downloadPostAction (url, data, responseType = 'blob') {
  return axios({
    url,
    method: 'post',
    responseType,
    data
  })
}

export function postActionOld (url, params) {
  return axios({
    url,
    method: 'post',
    params
  })
}

/**
 * 报价单临时使用
 * @param url
 * @param params
 * @returns {*}
 */
export function postAction (url, params) {
  return axios({
    url,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    transformRequest: [function (data, headers) {
      data = filterNullObj(data)
      return Qs.stringify(data, { arrayFormat: 'brackets' })
    }],
    data: params
  })
}

// 数组按照 repeat 进行处理
export function postRepeatAction (url, params) {
  return axios({
    url,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    transformRequest: [function (data, headers) {
      data = filterNullObj(data)
      return Qs.stringify(data, { arrayFormat: 'repeat' })
    }],
    data: params
  })
}

export function post2Action (url, params) {
  return axios({
    url,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    // transformRequest: [function(data, headers) {
    //   data = filterNullObj(data)
    //   return Qs.stringify(data, { arrayFormat: 'brackets' })
    // }],
    data: params
  })
}

// 使用  @RequestBody 接收
export function postDataAction (url, data) {
  return axios({
    url,
    method: 'post',
    data
  })
}

// 使用  @RequestBody 接收
export function putDataAction (url, data) {
  return axios({
    url,
    method: 'put',
    data
  })
}

// post method= {post}
export function httpAction (url, params, method) {
  return axios({
    url,
    method,
    params
  })
}

// put
export function putAction (url, params) {
  return axios({
    url,
    method: 'put',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    transformRequest: [function (data, headers) {
      data = filterNullObj(data)
      return Qs.stringify(data)
    }],
    data: params
  })
}

export function delAction (url, params) {
  return axios({
    url,
    method: 'delete',
    params
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile (url, params) {
  return axios({
    url,
    params,
    method: 'get',
    responseType: 'blob'
  })
}

export const personGroupTypeOptions = [
  { label: '采样组', value: '1' },
  { label: '检测组', value: '2' },
  { label: '报告组', value: '3' }
]


/**
* 计算列名
* @param {number} columnNumber
* @return {string}
*/
export const getColName = (columnNumber) => {
  // 构建字典，便于查询
  const num_set = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  // 存放结果
  let result = []
  // 初始化数据
  let num = columnNumber
  let index
  // 循环判断，并在结果列前插入，直到小于等于26再退出循环
  while (num > 26) {
      index = num % 26
      num = Math.floor(num / 26)
      if (index === 0) {
          result.unshift(num_set[25])
          num = num - 1
      } else {
          result.unshift(num_set[index - 1])
      }
  }
  // 将最后一个值插入
  result.unshift(num_set[num - 1])
  // 输出字符串结果
  return result.join('')
}