'use strict'

const defaultColumn = [
  { title: '样品编号', dataIndex: 'checkSampleId', sorter: true, width: 170, scopedSlots: { customRender: 'checkSampleId' } },
  { title: '检测批次', dataIndex: 'batchGroupId', sorter: true, width: 180, scopedSlots: { customRender: 'batchGroupId' } },
  { title: '样品类型', dataIndex: 'sampleType', sorter: true, width: 100, scopedSlots: { customRender: 'sampleType' } },
  { title: '任务编号', dataIndex: 'projNo', sorter: true, width: 100, scopedSlots: { customRender: 'projNo' } },
  { title: '任务名称', dataIndex: 'projName', width: 180, scopedSlots: { customRender: 'projName' } },
  { title: '检测内容', dataIndex: 'itemName', sorter: true, width: 200, scopedSlots: { customRender: 'itemName' } },
  { title: '检测方法', dataIndex: 'methodsName', width: 200, scopedSlots: { customRender: 'methodsName' } },// 显示的检测安排的数据
  { title: '检测员', dataIndex: 'checkUserName', sorter: true, width: 90, scopedSlots: { customRender: 'checkUserName' } },
  { title: '分包', dataIndex: 'itemType', width: 90, scopedSlots: { customRender: 'itemType' } },
  { title: '采样时间', dataIndex: 'sampleTime', width: 160, scopedSlots: { customRender: 'sampleTime' } },
  { title: '安排时间', dataIndex: 'scheduleTime', width: 160, scopedSlots: { customRender: 'scheduleTime' } },
  { title: '做样时间', dataIndex: 'actualTime', width: 160, scopedSlots: { customRender: 'actualTime' } },
  /* { title: '倒计时', dataIndex: 'countDownTime', width: 90, scopedSlots: { customRender: 'countDownTime' } },
  { title: '曲线控制', dataIndex: 'curveControl', width: 78, scopedSlots: { customRender: 'curveControl' } }, */
  { title: '原始记录', dataIndex: 'recordId', width: 78, scopedSlots: { customRender: 'recordId' } },
  { title: '样品状态', dataIndex: 'sampleStatus', sorter: true, width: 100, scopedSlots: { customRender: 'sampleStatus' } },
  { title: '数据状态', dataIndex: 'dataStatus', sorter: true, width: 100, scopedSlots: { customRender: 'dataStatus' } },
  { title: '状态', dataIndex: 'status', sorter: true, width: 78, scopedSlots: { customRender: 'status' } },
  { title: '操作', dataIndex: 'action', width: 180, scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as checkTaskDefaultColumn
}
