import { render, staticRenderFns } from "./SelectList.vue?vue&type=template&id=636eab74&scoped=true&"
import script from "./SelectList.vue?vue&type=script&lang=js&"
export * from "./SelectList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636eab74",
  null
  
)

export default component.exports