<template>
  <div>
    <a-card v-bind="sampleTaskCard">
      <div :style="{marginBottom:'16px'}">
        <span class="task-card_title"> {{ sampleTaskInfo.projName }}</span>
        <span class="task-card_sub-title">
          <a-icon type="calendar"/>
          {{ sampleTaskInfo.beginTime | momentFilter('YYYY-MM-DD') }}~{{ sampleTaskInfo.endTime | momentFilter('YYYY-MM-DD') }}
        </span>
      </div>

      <a-row>
        <a-col :span="22">
          <detail-list v-if="sampleTaskInfo" size="small" :col="3">
            <detail-list-item term="采样人员">
              <a-tag
                v-for="(user,index) in sampleTaskInfo.personUserNames&&sampleTaskInfo.personUserNames.split(',')"
                :key="`user_${index}`"
                color="green"> {{ user }}</a-tag>
            </detail-list-item>

            <detail-list-item term="交通工具">
              <template v-if="sampleTaskInfo.transMode === '1'">
                <a-tag
                  v-for="(item,index) in sampleTaskInfo.carNumbers&&sampleTaskInfo.carNumbers.split(',')||[]"
                  :key="'carDetail_'+index"
                  color="blue">{{ item }}</a-tag>
              </template>
              <template v-else>
                {{ transModeMap[sampleTaskInfo.transMode] }}
              </template>
            </detail-list-item>

            <detail-list-item term="物资状态">
              <a-tag v-if="sampleTaskInfo.purStatus==='0'">{{ sampleTaskInfo.purStatus | purStatusFilter }}</a-tag>
              <a-tag v-else-if="sampleTaskInfo.purStatus==='1'" color="#ff9600">{{ sampleTaskInfo.purStatus | purStatusFilter }}</a-tag>
              <a-tag v-else-if="sampleTaskInfo.purStatus==='2'" color="blue">{{ sampleTaskInfo.purStatus | purStatusFilter }}</a-tag>
              <a @click="showPurUse" v-if="sampleTaskInfo.purUseId">
                <a-icon type="eye"/>
              </a>
            </detail-list-item>

            <detail-list-item term="采样时间">
              <template v-if="sampleTaskInfo.startTime">
                {{ sampleTaskInfo.startTime |minuteFilter }}
              </template>

              <template v-else>
                <span class="text">暂未开始</span>
              </template>
              <template v-if="sampleTaskInfo.doneTime">
                ~ {{ sampleTaskInfo.doneTime |minuteFilter }}
              </template>
            </detail-list-item>

          </detail-list>
        </a-col>

        <a-col :span="2">
          <a-row>
            <a-col :xs="24" :sm="24">
              <div class="text">状态</div>
              <div class="heading" :style="{color:statusColorFilter(sampleTaskInfo.status)}">{{ sampleTaskInfo.status | statusFilter }}</div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>

    <a-card v-bind="siteFeedbackCard" v-if="projectId">
      <template #title>
        <span>点位反馈信息</span>
      </template>
      <SiteFeedBackTable :proj-id="projectId"></SiteFeedBackTable>
    </a-card>

    <!-- 领用申请详情-->
    <purUseIndex v-bind="purUseIndexModal" v-on="purUseIndexModal" v-if="purUseIndexModal.visible"/>

  </div>
</template>

<script>
import { tsTaskApi, taskStatusOptions } from '@/api/sample'
import { resPurUseApplyStatusDataMap } from '@/api/resMaterial/ResPurUseConstant'
import { tranOptionsToMap } from '@/utils/util'

import SiteFeedBackTable from '@/pages/cloud-quote/take-sample/modules/projectView/SiteFeedBackTable'
import DetailList from '@/components/tools/DetailList'
import purUseIndex from '@/pages/cloud-quote/res/res-puruse/modules/purUseIndex'

const DetailListItem = DetailList.Item
const taskStatusMap = tranOptionsToMap(taskStatusOptions)

export default {
  components: { purUseIndex, DetailList, DetailListItem, SiteFeedBackTable },

  props: {
    projId: {
      type: Number
    }
  },

  data () {
    return {
      projectId: this.projId,

      sampleTaskInfo: {},

      transModeMap: {},

      purUseIndexModal: {
        ref: 'purUseIndexModal',
        dataType: 1,
        visible: false,
        title: '申请记录',
        cancel: () => { this.purUseIndexModal.visible = false },
        show: () => { this.purUseIndexModal.visible = true }
      },

      sampleTaskCard: {
        title: '采样信息',
        type: 'inner',
        class: 'task-card'
      },

      siteFeedbackCard: {
        type: 'inner',
        class: 'feedback-card'
      }
    }
  },

  watch: {
    projectId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.querySampleTask(val)
        }
      }
    }
  },

  filters: {
    statusFilter (val) {
      return taskStatusMap[val || '0']
    },

    purStatusFilter (val) {
      return resPurUseApplyStatusDataMap[val || '0'].label
    }
  },

  created () {
    this.initTransModeMap()
  },

  methods: {

    statusColorFilter (val) {
      return ['purple', '#ff9600', 'yellow', 'blue', 'green'][parseInt(val || 0)]
    },

    /// 查询项目对应的采样安排信息
    querySampleTask (projId) {
      tsTaskApi.findOne({ projId }).then(res => {
        if (res.code === 0) {
          this.sampleTaskInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /// 加载交通工具字典
    initTransModeMap () {
      this.$store.dispatch('code/fetch', 'sampleTransMode').then(data => {
        this.transModeMap = data || {}
      })
    },

    // 显示申请详情
    showPurUse (projId) {
      this.purUseIndexModal.visible = true
      this.$nextTick(() => {
        this.$refs[this.purUseIndexModal.ref].handleDetail(this.sampleTaskInfo.purUseId)
      })
    }

  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$route.params && vm.$route.params.projId) {
        vm.projectId = parseInt(vm.$route.params.projId) || null
      }

      if (vm.$route.query && vm.$route.query.projId) {
        vm.projectId = parseInt(vm.$route.query.projId) || null
      }
    })
  }
}
</script>

<style lang="less" scoped>
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .task-card {
    margin-bottom: 24px;

    &_title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      color: rgba(0, 0, 0, .70);
    }

    &_sub-title {
      color: gray;
      margin-left: 10px;
    }

  }
</style>
