<template>
  <div>
    <a-card v-bind="searchDiv" @keyup.enter="search">
      <a-form>
        <template v-if="searchDiv.simple">
          <a-row :gutter="rowProps.gutter">
            <a-col v-bind="colProps">
              <a-form-item label="单号/名称" v-bind="formItemLayout">
                <a-input v-model="queryParam.projectTitle" placeholder=""/>
              </a-form-item>
            </a-col>
            <a-col v-bind="colProps">
              <a-form-item label="报价时间" v-bind="formItemLayout">
                <JDateRangePicker
                  v-model="dateRang"
                  :triggerChange="true"
                  @update="(v)=>dateRang=v"
                  @change="dateRangeChange"></JDateRangePicker>
              </a-form-item>
            </a-col>
            <a-col v-bind="colProps">
              <span class="table-page-search-submitButtons">
                <a-button-group>
                  <a-button type="primary" icon="search" @click="search">查询</a-button>
                  <a-button icon="reload" @click="resetSearch">重置</a-button>
                </a-button-group>
              </span>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row :gutter="rowProps.gutter">
            <a-col span="18">
              <a-row v-bind="rowProps">
                <a-col v-bind="colProps">
                  <a-form-item label="单号/名称" v-bind="formItemLayout">
                    <a-input v-model="queryParam.projectTitle" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col v-bind="colProps">
                  <a-form-item label="委托时间" v-bind="formItemLayout">
                    <JDateRangePicker
                      v-model="dateRang"
                      :triggerChange="true"
                      @update="(v)=>dateRang=v"
                      @change="dateRangeChange"></JDateRangePicker>
                  </a-form-item>
                </a-col>
                <a-col v-bind="colProps">
                  <a-form-item label="报价单类型" v-bind="formItemLayout">
                    <a-input-group>
                      <a-select v-model="queryParam.quoteType" :allowClear="true" style="width: 75%">
                        <a-select-option v-for="(v,k) in this.quoteTypeMap" :key="k">{{ v }}</a-select-option>
                      </a-select>
                      <a-select
                        v-model="queryParam.quoteMonth"
                        :allowClear="true"
                        style="width: 25%;"
                        placeholder="月份">
                        <a-select-option v-for="m of 12" :key="m" :value="m">
                          <span :key="m">{{ m }}月</span>
                        </a-select-option>
                      </a-select>
                    </a-input-group>
                  </a-form-item>
                </a-col>
                <template v-if="moreParam">
                  <a-col v-bind="colProps">
                    <a-form-item label="状态" v-bind="formItemLayout">
                      <a-select v-model="status" :allowClear="true" mode="multiple">
                        <a-select-option v-for="vo in this.quoteStatusList" :key="vo.id">{{ vo.text }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col v-bind="colProps">
                    <a-form-item label="报价人" v-bind="formItemLayout">
                      <a-input v-model="queryParam.createUser" placeholder="" @pressEnter="search"/>
                    </a-form-item>
                  </a-col>
                  <a-col v-bind="colProps">
                    <a-form-item label="客户单位" v-bind="formItemLayout">
                      <a-select
                        showSearch
                        :allowClear="true"
                        placeholder="请选择客户"
                        v-model="queryParam.customer"
                        @change="selectSearchChange"
                        optionFilterProp="children"
                        :filterOption="filterOption">
                        <a-select-option v-for="vo in customerList" :key="vo.id">{{ vo.csrName }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </template>
              </a-row>
            </a-col>
            <a-col span="6">
              <span class="table-page-search-submitButtons">
                <a-button-group>
                  <a-button type="primary" icon="search" @click="search">查询</a-button>
                  <a-button icon="reload" @click="resetSearch">重置</a-button>
                </a-button-group>
                <a @click="()=>{moreParam = !moreParam}" style="margin-left: 8px">
                  {{ moreParam ? '收起' : '更多' }}
                  <a-icon :type="moreParam ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </template>
      </a-form>
    </a-card>
  </div>
</template>
<script>
import { Get, customerApi } from '@/api/quote'
import moment from 'moment'
import JDateRangePicker from '@/components/j/JDateRangePicker'
export default {
  name: 'ProjSearchDiv',
  components: {
    JDateRangePicker
  },
  props: {
    queryParam: {
      type: Object,
      default: () => ({})
    },
    defaultParam: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: true
    },
    simple: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    },
    dateRang: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      searchDiv: {
        id: 'searchCard',
        show: true, // 是否显示菜单
        simple: false, // 简单菜单
        bordered: true
      },

      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 16, lg: 24, xl: 24, xxl: 48 }
      },
      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        xxl: { span: 8 },
        xl: { span: 8 },
        lg: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
        xs: { span: 12 },
        style: { textAlign: 'left' }
      },
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      customerList: [],
      quoteStatusList: [{ id: '0', text: '完善中' }, { id: '6', text: '报价中' }, { id: '1', text: '赢单' }, { id: '2', text: '提交审核' }, { id: '3', text: '方案审核' }, { id: '4', text: '部门审核' }, { id: '5', text: '经理审核' }, { id: '-2', text: '已废弃' }],
      // 方案监测月份
      quoteMonthList: [],

      moreParam: false,
      dateFormat: 'YYYY-MM-DD',
      status: []
    }
  },
  methods: {
    moment,
    resetSearch () {
      this.$emit('update:queryParam', Object.assign({}, this.defaultParam))
      this.search()
    },
    search () {
      let createTimeStart = ''
      let createTimeEnd = ''
      if (this.dateRang[0]) {
        createTimeStart = this.dateRang[0] + ' 00:00:00'
      } else {
        createTimeStart = ''
      }
      if (this.dateRang[1]) {
        createTimeEnd = this.dateRang[1] + ' 23:59:59'
      } else {
        createTimeEnd = ''
      }
      this.$set(this.queryParam, 'quoteDateStart', createTimeStart)
      this.$set(this.queryParam, 'quoteDateEnd', createTimeEnd)
      this.$emit('search', this.queryParam)
    },
    listCustomer () {
      const self = this
      customerApi.listCustomer({ page: false, authEnable: false }).then(result => {
        if (result.code === 0) {
          self.customerList = result.data
        }
      })
    },
    dateRangeChange (moms, dateStrs) {
    },
    selectSearchChange (value) {
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  computed: {
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    }
  },
  watch: {
    show (val) {
      Object.assign(this.searchDiv, {
        show: val
      })
    },
    simple (val) {
      Object.assign(this.searchDiv, {
        simple: val
      })
    },
    bordered (val) {
      Object.assign(this.searchDiv, {
        bordered: val
      })
    },
    status (nv, ov) {
      this.queryParam.status = nv.toString()
    },
    'queryParam.status' (nv, ov) {
      if (nv) {
        this.status = nv.split(',')
      } else {
        this.status = []
      }
    }
  },
  filters: {
  },
  mounted () {
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
    this.$store.dispatch('code/select', 'quote_type')
    // var time1 = moment().month(moment().month() - 2).startOf('month').format(this.dateFormat)
    // var time2 = moment().endOf('day').format(this.dateFormat)
    // this.dateRang = [time1, time2]
    this.listCustomer()

    // 初始化菜单
    Object.assign(this.searchDiv, {
      show: this.show,
      simple: this.simple,
      bordered: this.bordered
    })
  }
}
</script>
<style lang="less">
</style>
