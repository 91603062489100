<template>
  <div class="check-task-schedule animate__animated animate__fadeIn">
    <template v-for="(itemInfo, _index) in itemInfos">
      <a-card :key="`${itemInfo.cateId}_${itemInfo.itemId}_${_index}`" v-bind="baseCard" :title="`${itemInfo.cateName}/${itemInfo.itemName}`">
        <template v-if="checkGuideDetailCateMap[itemInfo.cateId + '_' + itemInfo.itemId]">
          <ul class="ul">
            <li
              v-for="(checkGuide, index) in checkGuideDetailCateMap[itemInfo.cateId + '_' + itemInfo.itemId]"
              :key="`checkGuide_${index}`"
              :class="[{ checked: selectCheckGuideMap[itemInfo.cateId + '_' + itemInfo.itemId] === checkGuide.id, addHeight: checkGuide.isShowSample }, 'li']"
            >
              <div>
                <span class="content">
                  <template v-if="checkGuide.defaultFlag === 1">
                    <a-tag color="green">常用</a-tag>
                  </template>
                  <template v-else>
                    <a-tag color="red">不常用</a-tag>
                  </template>
                </span>
              </div>
              <div>
                <a-icon
                  :type="checkGuide.isShowSample ? 'minus-square' : 'plus-square'"
                  style="cursor: pointer; color: rgba(0, 0, 0, 0.65)"
                  @click="triggerShowSample(checkGuide, index)"
                ></a-icon>
              </div>
              <div>
                <span class="title">检测标准:</span>
                <span class="content text-ellipsis" style="max-width: 480px; float: right; display: inline-block">
                  <a-tooltip>
                    <template #title> {{ checkGuide.standardName }} （{{ checkGuide.standardCode }}） </template>
                    {{ checkGuide.standardName }} （{{ checkGuide.standardCode }}）
                  </a-tooltip>
                </span>
              </div>

              <div>
                <span class="title">检测方法:</span>
                <span class="content text-ellipsis" style="max-width: 400px; float: right; display: inline-block">
                  <a-tooltip>
                    <template #title>
                      {{ checkGuide.methodName }}
                    </template>
                    {{ checkGuide.methodName }}
                  </a-tooltip>
                </span>
              </div>

              <div>
                <span class="title">检测模板：</span>
                <template v-if="checkGuide.templateName">
                  <span class="content">{{ checkGuide.templateName }}</span>
                </template>
                <template v-else>
                  <a-tag color="red">暂未配置检测原始记录模板</a-tag>
                </template>
              </div>

              <div>
                <span class="title">平行样：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needParallel].color">{{ qualityOptionDataMap[checkGuide.needParallel].label }}</a-tag>
                </span>
              </div>

              <div>
                <span class="title">空白样：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needBlank].color">{{ qualityOptionDataMap[checkGuide.needBlank].label }}</a-tag>
                </span>
              </div>

              <div>
                <span class="title">加标回收：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needMarkup].color">{{ qualityOptionDataMap[checkGuide.needMarkup].label }}</a-tag>
                </span>
              </div>

              <div>
                <span class="title">标准曲线：</span>
                <span class="content">
                  <a-tag :color="qualityOptionDataMap[checkGuide.needCurve].color">{{ qualityOptionDataMap[checkGuide.needCurve].label }}</a-tag>
                </span>
              </div>

              <div class="checkbox">
                <input
                  :checked="selectCheckGuideMap[itemInfo.cateId + '_' + itemInfo.itemId] === checkGuide.id"
                  :disabled="checkGuideDetailCateMap[itemInfo.cateId + '_' + itemInfo.itemId].length === 1"
                  :name="`checkGuide_${itemInfo.cateId}_${itemInfo.itemId}`"
                  type="radio"
                  @change="handleSelectGuide($event, checkGuide)"
                />
              </div>
              <div style="margin-left: 5px">
                <a-button @click="compositeItemShow(checkGuide)">复合批次</a-button>
              </div>
              <div :class="checkGuide.isShowSample ? 'blockSample' : 'noneSample'">
                <a-table :data-source="taskGroup[itemInfo.cateId + '_' + itemInfo.itemId]" :columns="columns" :pagination="false" :scroll="{ x: '100%', y: 400 }">
                  <template slot="associatedItems" slot-scope="text, record">
                    <div v-for="it in record.associatedItems" :key="it.itemId">
                      {{ it.itemName }}
                    </div>
                  </template>
                </a-table>
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="data prompt">
            <div class="red no-data">配置检测检测方法，请先配置检测方法后再试</div>
          </div>
        </template>
      </a-card>
    </template>
    <a-modal v-bind="compositeItemsModal" v-on="compositeItemsModal">
      <a-checkbox-group v-model="compositeItemsModal.data.checkedItemIds" class="date-group" style="width: 100%">
        <a-row :gutter="16">
          <a-col :span="8" v-for="(item, index) in compositeItemsModal.data.itemList" :key="index" class="date-col">
            <a-checkbox :value="item.itemId">
              <a-tag color="blue">{{ item.itemName }}</a-tag>
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import { groupBy, orderBy, uniqBy, cloneDeep } from 'lodash'
import { itemCheckDetailApi } from '@/api/quote'

import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { qualityOptionDataMap, qualityOptionEnum } from '@/api/itemCheckDetail/itemCheckDetailConstant'
import { sampleInfoStatusDataMap, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { tsSampleitemApi } from '@/api/sample'

export default {
  props: {
    itemInfos: { type: Array, required: true, default: () => [] },
    actualTaskList: { type: Array, required: true, default: () => [] }
  },

  data() {
    return {
      qualityOptionEnum,
      qualityOptionDataMap,
      sampleInfoTypeEnum,
      sampleInfoTypeDataMap,
      reagentCateMaterialTypeEnum,
      sampleInfoStatusDataMap,

      // 检测任务
      taskList: this.tasks || [],

      // 检测明细ID
      checkGuideDetailCateMap: {},
      selectCheckGuideMap: {},

      checkTaskDateRange: {
        showTime: { format: 'HH:mm' },
        format: 'YYYY-MM-DD HH:mm',
        placeholder: ['开始时间', '截止时间']
      },

      baseCard: {
        type: 'inner',
        size: 'mini',
        style: { marginBottom: '16px' }
      },
      taskGroup: {},
      columns: [
        { title: '样品编号', dataIndex: 'sampleId', width: '150px', scopedSlots: { customRender: 'sampleId' } },
        { title: '批次因子', dataIndex: 'itemName', width: '120px', scopedSlots: { customRender: 'itemName' } },
        { title: '复合因子', dataIndex: 'associatedItems', width: '120px', scopedSlots: { customRender: 'associatedItems' } }
      ],
      compositeItemsModal: {
        title: '',
        visible: false,
        width: '600px',
        // centered: true,
        maskClosable: false,
        destroyOnClose: true,
        data: {
          checkDetailId: null,
          cateId: null,
          itemId: null,
          itemList: [],
          checkedItemIds: []
        },
        ok: this.compositeItemsOk,
        cancel: this.compositeItemsCancel
      },
      defaultCompositeItemsStatus: false
    }
  },

  watch: {
    itemInfos: {
      immediate: true,
      handler(value) {
        value && this.queryCheckGuideDetail(value)
      }
    },
    actualTaskList: {
      immediate: true,
      handler(value) {
        console.log('sdsdsdsdsdsdsd')
        this.taskGroup = groupBy(value, (item) => item.cateId + '_' + item.itemId)
        this.defaultCompositeItems()
      }
    }
  },

  methods: {
    // 查询检测明细信息
    queryCheckGuideDetail(itemInfos) {
      console.log(itemInfos)
      let itemIds = uniqBy(itemInfos.map((item) => item.cateId + '-' + item.itemId))
      itemCheckDetailApi.listDetail({ page: false, items: itemIds }).then((res) => {
        if (res.code === 0) {
          const data = orderBy(res.data, 'defaultFlag', 'desc')
          this.checkGuideDetailCateMap = groupBy(data, (item) => item.cateId + '_' + item.itemId)
          this.selectCheckGuideMap = Object.values(this.checkGuideDetailCateMap).reduce((acc, items) => {
            const itemId = items[0].itemId
            const cateId = items[0].cateId
            const key = `${cateId}_${itemId}`

            let itemInfoItems = itemInfos.filter((item) => item.cateId + '_' + item.itemId === key)
            let methodsIds = []
            itemInfoItems.length &&
              itemInfoItems.forEach((item) => {
                if (item.methodsId) {
                  methodsIds.push(item.methodsId)
                }
              })
            if (methodsIds.length > 0) {
              let methodItems = items.filter((item) => methodsIds.includes(item.methodId))
              if (methodItems.length > 0) {
                acc[key] || (acc[key] = methodItems[0].id)
              } else {
                acc[key] || (acc[key] = items[0].id)
              }
            } else {
              acc[key] || (acc[key] = items[0].id)
            }
            return acc
          }, {})
          this.defaultCompositeItems()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 处理检测明细修改
    handleSelectGuide(event, checkGuide) {
      this.selectCheckGuideMap[checkGuide.cateId + '_' + checkGuide.itemId] = checkGuide.id
      // 切换因子下选中的检测明细时，需要把之前检测因子选中的复合因子的相关数据都清掉，包括检测明细数据和样品数据中的；并且关闭之前展开的样品数据
      this.checkGuideDetailCateMap[checkGuide.cateId + '_' + checkGuide.itemId].forEach((item) => {
        delete item.checkedItemIds
        delete item.notFirstTime
        if (item.id === checkGuide.id) {
          this.$set(item, 'isShowSample', true)
        } else {
          this.$set(item, 'isShowSample', false)
        }
      })
      this.taskGroup[checkGuide.cateId + '_' + checkGuide.itemId].forEach((item) => {
        // delete item.allAssociationItems
        delete item.associatedItems
      })
    },

    // 默认给各个样品添加复合因子
    async defaultCompositeItems() {
      if (Object.keys(this.taskGroup).length > 0 && Object.keys(this.checkGuideDetailCateMap).length > 0 && Object.keys(this.selectCheckGuideMap).length > 0) {
        for (let key in this.selectCheckGuideMap) {
          let checkGuides = this.checkGuideDetailCateMap[key]
          // 获取默认选中的检测明细数据
          let checkGuide = checkGuides.find((it) => it.id === this.selectCheckGuideMap[key])
          if (checkGuide) {
            let samples = this.taskGroup[key]
            let sampleIds = samples.map((item) => item.sampleId)
            // 查询当前检测明细关联的方法关联的因子与当前检测明细下的样品关联的因子的重合项（即复合因子）
            let itemList = await this.getSampleItemList(sampleIds, checkGuide.methodId)
            // 获取样品关联的全部因子
            let allAssociationItems = await this.getMethodItemList(sampleIds)

            // 默认把当前检测明细关联的方法关联的因子与当前检测明细下的样品关联的因子的重合项（即复合因子）的所有id添加到默认选中的检测明细中去，以便回显
            let checkedItemIds = []
            itemList.forEach((item) => {
              checkedItemIds.push(item.itemId)
            })
            checkGuide.checkedItemIds = checkedItemIds
            // checkGuide.compositeItemList = itemList
            // 默认展开选中的检测明细下的样品列表
            this.$set(checkGuide, 'isShowSample', true)

            samples.forEach((item) => {
              let findIndex = allAssociationItems.findIndex((item2) => item2.sampleId === item.sampleId)
              if (findIndex !== -1) {
                //  给样品赋值关联的全部因子
                item.allAssociationItems = allAssociationItems[findIndex].itemList
                //  给样品赋值复合因子中与样品关联的因子id相同的那部分
                let associatedItems = allAssociationItems[findIndex].itemList.filter((item3) => checkedItemIds.includes(String(item3.itemId)))
                item.associatedItems = associatedItems
              } else {
                item.allAssociationItems = []
                // item.associatedItems =[]
              }
            })
            // console.log(checkGuide,samples);
          }
        }
        this.defaultCompositeItemsStatus = true
      }
    },
    // 打开选择复合因子弹窗
    async compositeItemShow(checkGuide) {
      // console.log(checkGuide, this.selectCheckGuideMap, this.checkGuideDetailCateMap, this.actualTaskList)

      // 当前点击的复合因子按钮所在的检测明细数据必须是因子下选中的检测明细数据
      if (this.selectCheckGuideMap[checkGuide.cateId + '_' + checkGuide.itemId] == checkGuide.id) {
        this.compositeItemsModal.title = `${checkGuide.methodName}复合因子选择`
        this.compositeItemsModal.data.checkDetailId = checkGuide.id
        this.compositeItemsModal.data.cateId = checkGuide.cateId
        this.compositeItemsModal.data.itemId = checkGuide.itemId
        this.compositeItemsModal.data.checkedItemIds = checkGuide.checkedItemIds ? cloneDeep(checkGuide.checkedItemIds) : []
        // this.compositeItemsModal.data.itemList = checkGuide.compositeItemList ? checkGuide.compositeItemList : []
        this.compositeItemsModal.visible = true

        // 获取当前因子分类下样品的样品编号
        let samples = this.taskGroup[checkGuide.cateId + '_' + checkGuide.itemId]
        let sampleIds = samples.map((item) => item.sampleId)

        // // 查询当前检测明细关联的方法关联的因子与当前检测明细下的样品关联的因子的重合项
        // tsSampleitemApi.selectSameSampleInfoAndItem({ sampleIds: sampleIds, methodId: checkGuide.methodId }).then((res) => {
        //   console.log(res)
        //   if (res.code === 0) {
        //     this.compositeItemsModal.data.itemList = res.data
        //     // 如果当前检测明细不是首次打开，那就不会执行下面的赋值；如果是首次打开，则会执行下面的赋值
        //     if (!checkGuide.notFirstTime) {
        //       checkGuide.notFirstTime = true
        //       let itemIds = res.data.map((item) => item.itemId)
        //       this.compositeItemsModal.data.checkedItemIds = itemIds
        //     }
        //   }
        // })
        // // 获取样品关联的全部因子
        // tsSampleitemApi.selectItemBySample({ sampleIds }).then((res) => {
        //   console.log(res)
        //   if (res.code === 0) {
        //     let data = res.data
        //     samples.forEach((item) => {
        //       let findIndex = data.findIndex((item2) => item2.sampleId === item.sampleId)
        //       if (findIndex !== -1) {
        //         item.allAssociationItems = data[findIndex].itemList
        //       }
        //     })
        //   }
        // })

        // // 查询当前检测明细关联的方法关联的因子与当前检测明细下的样品关联的因子的重合项
        let itemList = await this.getSampleItemList(sampleIds, checkGuide.methodId)
        // // 获取样品关联的全部因子
        // let allAssociationItems = await this.getMethodItemList(sampleIds)
        this.compositeItemsModal.data.itemList = itemList
        // // 如果当前检测明细不是首次打开，那就不会执行下面的赋值；如果是首次打开，则会执行下面的赋值
        // if (!checkGuide.notFirstTime) {
        //   checkGuide.notFirstTime = true
        //   let itemIds = itemList.map((item) => item.itemId)
        //   this.compositeItemsModal.data.checkedItemIds = itemIds
        // }
        // //  给样品赋值关联的全部因子
        // samples.forEach((item) => {
        //   let findIndex = allAssociationItems.findIndex((item2) => item2.sampleId === item.sampleId)
        //   if (findIndex !== -1) {
        //     item.allAssociationItems = allAssociationItems[findIndex].itemList
        //   }
        // })
      } else {
        this.$message.warning('请先选择当前检测明细，然后再复合批次！')
      }
    },
    // 查询当前检测明细关联的方法关联的因子与当前检测明细下的样品关联的因子的重合项
    getSampleItemList(sampleIds, methodId) {
      return tsSampleitemApi.selectSameSampleInfoAndItem({ sampleIds, methodId }).then((res) => {
        console.log(res)
        if (res.code === 0) {
          return res.data
        }
      })
    },
    // 获取样品关联的全部因子
    getMethodItemList(sampleIds) {
      return tsSampleitemApi.selectItemBySample({ sampleIds }).then((res) => {
        console.log(res)
        if (res.code === 0) {
          return res.data
        }
      })
    },

    compositeItemsOk() {
      /**
       * 当this.compositeItemsModal.data.itemList有数据，说明当前检测明细关联的方法与当前检测明细下的样品中的部分因子有关联，
       * 也就是在当前检测明细关联的方法下，当前检测明细下的样品中的部分因子是复合因子，
       * 因此当this.compositeItemsModal.data.itemList有数据，执行复合因子最少选中一个的判断
       */
      if (
        this.compositeItemsModal.data.itemList &&
        this.compositeItemsModal.data.itemList.length &&
        (!this.compositeItemsModal.data.checkedItemIds || this.compositeItemsModal.data.checkedItemIds.length === 0)
      ) {
        this.$message.error('当前因子在当前检测方法下是复合因子，请最少选择一个复合因子！')
        return
      }
      // 此处把选中的复合因子添加到对应的检测明细中去，以便下次回显
      let checkGuide = this.checkGuideDetailCateMap[this.compositeItemsModal.data.cateId + '_' + this.compositeItemsModal.data.itemId].filter(
        (item) => item.id === this.compositeItemsModal.data.checkDetailId
      )[0]
      checkGuide.checkedItemIds = this.compositeItemsModal.data.checkedItemIds
      console.log('选中的因子id：', this.compositeItemsModal.data.checkedItemIds)
      // 此处应该对应不同的样品，赋值不同的复合因子
      let samples = this.taskGroup[this.compositeItemsModal.data.cateId + '_' + this.compositeItemsModal.data.itemId]
      for (let i = 0; i < samples.length; i++) {
        let sampleItem = samples[i]
        //  给样品赋值选中的复合因子中与样品关联的因子id相同的那部分
        let associatedItems = sampleItem.allAssociationItems.filter((item) => this.compositeItemsModal.data.checkedItemIds.includes(String(item.itemId)))
        sampleItem.associatedItems = associatedItems
      }
      this.compositeItemsModal.visible = false
      console.log('样品：', this.taskGroup[this.compositeItemsModal.data.cateId + '_' + this.compositeItemsModal.data.itemId])
      console.log('检测明细：', this.checkGuideDetailCateMap[this.compositeItemsModal.data.cateId + '_' + this.compositeItemsModal.data.itemId])
      this.compositeItemsModal.data = {
        checkDetailId: null,
        cateId: null,
        itemId: null,
        itemList: [],
        checkedItemIds: []
      }
    },
    compositeItemsCancel() {
      this.compositeItemsModal.visible = false
      this.compositeItemsModal.data = {
        checkDetailId: null,
        cateId: null,
        itemId: null,
        itemList: [],
        checkedItemIds: []
      }
    },

    triggerShowSample(checkGuide, index) {
      // checkGuide.isShowSample = !checkGuide.isShowSample
      if (this.selectCheckGuideMap[checkGuide.cateId + '_' + checkGuide.itemId] == checkGuide.id) {
        if (checkGuide.isShowSample) {
          this.$set(checkGuide, 'isShowSample', false)
        } else {
          this.$set(checkGuide, 'isShowSample', true)
        }
      } else {
        this.$message.warning('请先选择当前检测明细，然后再查看！')
      }
    },
    // getAssociatedItems(list) {
    //   return list.map((item) => item.name).join(' | ')
    // },

    // 获取配置的检测明细信息
    getSelectCheckGuideMap() {
      return this.selectCheckGuideMap
    },
    // 获取检测明细详细信息
    getCheckGuideDetailCateMap() {
      return this.checkGuideDetailCateMap
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
<style lang="less">
.addHeight {
  margin-bottom: 463px !important;
}
.blockSample {
  display: block;
  position: absolute;
  top: 63px;
  height: 400px;
}
.noneSample {
  display: none;
}
</style>
