'use strict'

const defaultColumn = [
  { title: '版本号', dataIndex: 'versionNumber', width: 150, align: 'left', scopedSlots: { customRender: 'versionNumber' } },
  { title: '更新时间', dataIndex: 'createTime', width: 120, align: 'left', scopedSlots: { customRender: 'createTime' } },
  { title: '更新内容', dataIndex: 'content', width: 500, align: 'left', scopedSlots: { customRender: 'content' } },
  { title: '操作', width: 120, align: 'left', scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as pcVersionDefaultColumn
}
