<template>
  <div>
    <generate-form
      :data="json"
      :value="formData"
      ref="generateForm">
    </generate-form>
  </div>
</template>

<script>
import { versionApi } from '@/api/common'

export default {
  props: {
    id: { type: Number, required: false }
  },
  data () {
    return {
      json: {
        'list': [
          {
            'type': 'card',
            'name': '卡片布局',
            'icon': 'icon-grid-',
            'isFold': false,
            'isShow': true,
            'viewMode': null,
            'data': {
              'list': [
                {
                  'data': {
                    'list': [],
                    'config': {
                      'size': 'mini',
                      'labelPosition': 'right',
                      'labelWidth': 100
                    }
                  },
                  'columns': [
                    {
                      'list': [
                        {
                          'name': '副标题',
                          'icon': 'icon-fuwenbenkuang',
                          'options': {
                            'renderInit': false,
                            'width': '100px',
                            'class': 'ahmux-sub-title',
                            'value': '版本更新'
                          },
                          'model': '',
                          'rules': [],
                          'type': 'text',
                          'key': '1576655765000_78222',
                          'isShow': true
                        }
                      ],
                      'span': 3
                    },
                    {
                      'list': [],
                      'span': 7
                    },
                    {
                      'list': [],
                      'span': 8
                    },
                    {
                      'list': [],
                      'span': 8
                    }
                  ],
                  'name': '栅格布局',
                  'icon': 'icon-grid-',
                  'options': {
                    'gutter': 0,
                    'xl': 8,
                    'justify': 'start',
                    'md': 12,
                    'sm': 24,
                    'xs': 24,
                    'lg': 12,
                    'align': 'middle',
                    'type': 'flex'
                  },
                  'rules': [],
                  'type': 'grid',
                  'key': '1583392000000_62384'
                },
                {
                  'data': {
                    'list': [],
                    'config': {
                      'size': 'mini',
                      'labelPosition': 'right',
                      'labelWidth': 0
                    }
                  },
                  'columns': [
                    {
                      'list': [
                        {
                          'name': '文字',
                          'icon': 'icon-fuwenbenkuang',
                          'options': {
                            'width': '100%',
                            'style': 'height:43px;',
                            'class': 'ahmux-label',
                            'value': '版本号'
                          },
                          'rules': [],
                          'type': 'text',
                          'key': '1580974784000_25866'
                        }
                      ],
                      'span': 2
                    },
                    {
                      'list': [
                        {
                          'type': 'input',
                          'name': '',
                          'model': 'versionNumber',
                          'isShow': true,
                          'icon': 'icon-input',
                          'viewMode': null,
                          'options': {
                            'width': '100%',
                            'labelWidth': '0',
                            'style': null,
                            'class': 'ahmux-input',
                            'defaultValue': '',
                            'required': true,
                            'dataType': 'string',
                            'pattern': '',
                            'placeholder': '请输入单行文本',
                            'disabled': false,
                            'clearable': false,
                            'minlength': 0,
                            'maxlength': 128,
                            'showWordLimit': true,
                            'dataBind': true,
                            'hidden': false,
                            'changeFunc': '',
                            'initFunc': null,
                            'renderInit': false
                          },
                          'key': '1612146298000_1379',
                          'rules': [
                            {
                              'type': 'string',
                              'message': '格式不正确'
                            },
                            {
                              'required': true,
                              'message': '必须填写'
                            }
                          ]
                        }
                      ],
                      'span': 10
                    },
                    {
                      'list': [
                        {
                          'name': '文字',
                          'icon': 'icon-fuwenbenkuang',
                          'options': {
                            'width': '100%',
                            'style': 'height:43px;',
                            'class': 'ahmux-label',
                            'value': '更新时间'
                          },
                          'rules': [],
                          'type': 'text',
                          'key': '1580975313000_14211'
                        }
                      ],
                      'span': 2
                    },
                    {
                      'list': [
                        {
                          'type': 'date',
                          'name': '',
                          'model': 'createTime',
                          'isShow': true,
                          'icon': 'icon-date',
                          'viewMode': null,
                          'options': {
                            'width': '100%',
                            'labelWidth': '0',
                            'style': null,
                            'class': 'ahmux-date',
                            'defaultValue': '',
                            'isCurrent': true,
                            'isForceFormatData': false,
                            'readonly': false,
                            'disabled': false,
                            'editable': true,
                            'clearable': true,
                            'placeholder': '',
                            'startPlaceholder': '',
                            'endPlaceholder': '',
                            'type': 'datetime',
                            'format': 'yyyy-MM-dd HH:mm:ss',
                            'timestamp': false,
                            'required': true,
                            'dataBind': true,
                            'hidden': false,
                            'changeFunc': '',
                            'initFunc': null,
                            'renderInit': false
                          },
                          'key': '1580974757000_34753',
                          'rules': [
                            {
                              'required': true,
                              'message': '必须填写'
                            }
                          ]
                        }
                      ],
                      'span': 10
                    }
                  ],
                  'name': '栅格布局',
                  'icon': 'icon-grid-',
                  'options': {
                    'gutter': 0,
                    'xl': 8,
                    'justify': 'start',
                    'md': 12,
                    'style': ' border-top: 5px solid #1890ff;',
                    'sm': 24,
                    'xs': 24,
                    'lg': 12,
                    'align': 'middle',
                    'type': 'flex',
                    'class': 'ahmux-row',
                    'colClass': 'ahmux-col'
                  },
                  'rules': [],
                  'type': 'grid',
                  'key': '1583391993000_82100'
                },
                {
                  'data': {
                    'list': [],
                    'config': {
                      'size': 'mini',
                      'labelPosition': 'right',
                      'labelWidth': 100
                    }
                  },
                  'columns': [
                    {
                      'list': [
                        {
                          'name': '文字',
                          'icon': 'icon-fuwenbenkuang',
                          'options': {
                            'width': '100%',
                            'style': 'height:78px;',
                            'class': 'ahmux-label',
                            'value': '更新内容'
                          },
                          'rules': [],
                          'type': 'text',
                          'key': '1580975031000_78864'
                        }
                      ],
                      'span': 2
                    },
                    {
                      'list': [
                        {
                          'type': 'editor',
                          'name': '',
                          'model': 'content',
                          'isShow': true,
                          'icon': 'icon-fuwenbenkuang',
                          'viewMode': null,
                          'options': {
                            'defaultValue': '',
                            'width': '',
                            'dataBind': true,
                            'hidden': false,
                            'initFunc': null,
                            'renderInit': false,
                            'required': true
                          },
                          'key': '1612146469000_46753',
                          'rules': [
                            {
                              'required': true,
                              'message': '必须填写'
                            }
                          ]
                        }
                      ],
                      'span': 22
                    }
                  ],
                  'name': '栅格布局',
                  'icon': 'icon-grid-',
                  'options': {
                    'gutter': 0,
                    'xl': 8,
                    'justify': 'start',
                    'md': 12,
                    'style': '    border-bottom: 2px solid #1890FF;\n',
                    'sm': 24,
                    'xs': 24,
                    'lg': 12,
                    'align': 'middle',
                    'type': 'flex',
                    'class': 'ahmux-row',
                    'colClass': 'ahmux-col'
                  },
                  'rules': [],
                  'type': 'grid',
                  'key': '1583391971000_48725'
                }
              ],
              'config': {
                'labelWidth': 100,
                'labelPosition': 'right',
                'size': 'mini'
              }
            },
            'options': {
              'header': '',
              'shadow': 'never',
              'style': null,
              'class': 'ahmux-card',
              'bodyStyle': 'padding: 0;',
              'initFunc': null,
              'renderInit': true
            },
            'key': '1583390334000_14112',
            'rules': []
          }
        ],
        'config': {
          'labelWidth': 0,
          'labelPosition': 'right',
          'size': 'mini'
        }
      },
      formData: {}
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (value) {
        value && this.findById(value)
      }
    }
  },

  methods: {
    submit  () {
      this.$refs.generateForm.getData().then(res => {
        versionApi.add(Object.assign(this.formData, res)).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
          }
        })
      }, (e) => {
        this.$message.error(e)
      })
    },

    // 查找修改数据信息
    findById (id) {
      versionApi.findById(id).then(res => {
        if (res.code === 0) {
          this.formData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  }
}
</script>

<style scoped>

</style>
