<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouterAlive"/>
    </div>
  </a-locale-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'

export default {
  mixins: [AppDeviceEnquire],
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      locale: zhCN,
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style lang="less">
@import '~@/assets/less/common/snippet';

.tableStyle() {
  .ant-table-small {
    .ant-table-content {
      .ant-table-body,
      .ant-table-scroll > .ant-table-body,
      .ant-table-scroll > .ant-table-fixed-left >,
      .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner,
      .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner {
        margin: 0;

        table {
          .ant-table-thead > tr > th {
            background: #F7F7F7 !important;
          }
        }
      }

      .ant-table-fixed-left, .ant-table-fixed-right {
        .ant-table-header {
          .ant-table-fixed {
            tr > th {
              background: #F7F7F7 !important;
            }
          }
        }

        .ant-table-body-outer {
          margin-bottom: -7px !important;

          .ant-table-body-inner {
            overflow-y: hidden !important;
          }
        }
      }

      .ant-table-scroll {
        .ant-table-header {
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
          overflow-x: hidden;
          overflow-y: scroll;

          ::-webkit-scrollbar {
            height: 0;
          }
        }

        table {
          .ant-table-thead > tr > th {
            background: #F7F7F7 !important;
          }
        }

        .ant-table-body {
          margin-top: 0px !important;
          overflow-y: scroll !important;
          overflow-x: auto !important;
        }
      }
    }
  }
}

.ant-drawer, .ant-modal {
  .tableStyle;
  .scrollStyle;

}

* {
  .scrollStyle;
}

body, th, td {
  .fontStyle;
}

#app, body {
  .fontStyle;

  .tableStyle;

  height: 100%;

  .ant-form .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-table-wrapper {
    .scrollStyle;

    table {
      table-layout: fixed;
      word-break: break-all;
    }

  }
}

.custom-icon-folder {
  background: url('./assets/file/folder_win10.png') no-repeat;
  background-size: 80px;
}

.custom-icon-doc {
  background: url('./assets/file/doc.png') no-repeat;
  background-size: 80px;
}

.custom-icon-docx {
  background: url('./assets/file/docx.png') no-repeat;
  background-size: 80px;
}

.custom-icon-txt {
  background: url('./assets/file/txt.png') no-repeat;
  background-size: 80px;
}

.custom-icon-jpg {
  background: url('./assets/file/jpg.png') no-repeat;
  background-size: 80px;
}

.custom-icon-xls {
  background: url('./assets/file/xls.png') no-repeat;
  background-size: 80px;
}

.custom-icon-xlsx {
  background: url('./assets/file/xlsx.png') no-repeat;
  background-size: 80px;
}

.custom-icon-pdf {
  background: url('./assets/file/pdf.png') no-repeat;
  background-size: 80px;
}

.custom-icon-error {
  background: url('./assets/file/error.png') no-repeat;
  background-size: 80px;
}

.custom-service-consultation {
  background: url('./assets/service/consultation.jpg') no-repeat;
  background-size: 200px;
  width: 200px;
  height: 170px;
}

.custom-list-file {
  float: left;
  display: inline-block;
  padding: 15px;
  max-height: 160px;
  position: relative;
}

.custom-list-file2 {
  padding: 5px;
  width: 100%;
  max-height: 160px;
}

.custom-icon-folder-min {
  background: url('./assets/file/folder_win10.png') no-repeat;
  background-size: 20px;
}

.custom-icon-doc-min {
  background: url('./assets/file/doc.png') no-repeat;
  background-size: 20px;
}

.custom-icon-docx-min {
  background: url('./assets/file/docx.png') no-repeat;
  background-size: 20px;
}

.custom-icon-txt-min {
  background: url('./assets/file/txt.png') no-repeat;
  background-size: 20px;
}

.custom-icon-jpg-min {
  background: url('./assets/file/jpg.png') no-repeat;
  background-size: 20px;
}

.custom-icon-xls-min {
  background: url('./assets/file/xls.png') no-repeat;
  background-size: 20px;
}

.custom-icon-xlsx {
  background: url('./assets/file/xlsx.png') no-repeat;
  background-size: 20px;
}

.custom-icon-pdf-min {
  background: url('./assets/file/pdf.png') no-repeat;
  background-size: 20px;
}

.custom-icon-error-min {
  background: url('./assets/file/error.png') no-repeat;
  background-size: 20px;
}

.custom-service-consultation {
  background: url('./assets/service/consultation.jpg') no-repeat;
  background-size: 200px;
  width: 200px;
  height: 170px;
}


.row-button {
  a {
    margin: 0px 5px;
  }

  .anticon {
    margin-right: 3px;
  }
}
</style>
