<template>
  <div>
    <a-table :dataSource="itemList" v-bind="table" v-on="table">
      <template slot="itemId" slot-scope="text, record">
        <template v-if="record.quoteGroup==1">
          <a-tooltip placement="top">
            <template slot="title">
              <div>
                检测项：
                <template v-for="(gi, gidx) in record.itemList">
                  <template v-if="gidx==0">{{ gi.itemName }}</template>
                  <template v-else> ,{{ gi.itemName }}</template>
                </template>
              </div>
            </template>
            <a-badge :count="record.itemList.length" :offset="[-5,5]" :overflowCount="99">
              <a-tag color="green">{{ record.itemName }}</a-tag>
            </a-badge>
          </a-tooltip>
        </template>
        <a-tag v-else-if="record.itemType==0" color="blue">
          {{ record.itemName }}
          <template v-if="record.testStandardName">{{ record.testStandardName }}</template>
        </a-tag>
        <a-tag v-else-if="record.itemType==1" color="red">
          *{{ record.itemName }}
          <template v-if="record.testStandardName">{{ record.testStandardName }}</template>
        </a-tag>
        <a-tag v-else>
          {{ record.itemName }}
          <template v-if="record.testStandardName">{{ record.testStandardName }}</template>
        </a-tag>
      </template>
      <template slot="itemDiscount" slot-scope="v, r">
        <a-tag v-if="v==1" color="red" title="参与折扣">
          折
        </a-tag>
      </template>
      <template slot="itemPrice" slot-scope="v, r">
        <span style="color: red">{{ currency(v) }}</span>
        <template v-if="r.subitemPrice">
          (<span style="color: red" :title="`分包价格:${r.subitemPrice}元`">{{ currency(r.subitemPrice) }}</span>)
        </template>
      </template>
      <template slot="totalPrice" slot-scope="v, r">
        <span style="color: red">{{ currency(v) }}</span>
        <template v-if="r.subitemTotalPrice">
          (<span style="color: red" :title="`分包价格:${r.subitemTotalPrice}元`">{{ currency(r.subitemTotalPrice) }}</span>)
        </template>
      </template>
      <template slot="expandedRowRender" slot-scope="r">
        <a-card>
          <a-table :dataSource="r.itemList" v-bind="table" v-on="table">
            <template slot="itemId" slot-scope="text, record">
              <a-tag v-if="record.itemType==0" color="blue">{{ record.itemName }}</a-tag>
              <a-tag v-else-if="record.itemType==1" color="red">*{{ record.itemName }}</a-tag>
              <template v-else-if="record.quoteGroup==1">
                <a-tooltip placement="top">
                  <template slot="title">
                    <div>
                      检测项：
                      <template v-for="(gi, gidx) in record.itemList">
                        <template v-if="gidx==0">{{ gi.itemName }}</template>
                        <template v-else> ,{{ gi.itemName }}</template>
                      </template>
                    </div>
                  </template>
                  <a-badge :count="record.itemList.length" :offset="[-5,5]" :overflowCount="99">
                    <a-tag color="green">{{ record.itemName }}</a-tag>
                  </a-badge>
                </a-tooltip>
              </template>
              <a-tag v-else>{{ record.itemName }}</a-tag>
            </template>
            <template slot="itemDiscount" slot-scope="v, r">
              <a-tag v-if="v==1" color="red" title="参与折扣">
                折
              </a-tag>
            </template>
          </a-table>
        </a-card>
      </template>
    </a-table>
  </div>
</template>

<script>
import { projTaskItemApi } from '@/api/project'
import { currency } from '@/utils/util'
export default {
  name: 'PlanContentItemCosts',
  components: {
  },
  props: {
    itemList: {
      type: Array,
      default: () => ([])
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      table: {
        ref: 'itemTable',
        size: 'small',
        bordered: true,
        loading: false,
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        expandIcon: (props) => {
          if (props.record.itemList && props.record.itemList.length > 0) {
            if (props.expanded) { // 有数据-展开时候图标
              return (
                <a style="color: 'black',margin-right:0px"
                  onClick={(e) => {
                    props.onExpand(props.record, e)
                  }}
                >
                  <a-icon type="down" />
                </a>)
            } else { // 有数据-未展开时候图标
              return (
                <a style="color: 'black' ,margin-right:0px"
                  onClick={(e) => {
                    props.onExpand(props.record, e)
                  }}
                >
                  <a-icon type="right" />
                </a>)
            }
          } else { // 无数据-图标
            return ''
          }
        },
        rowKey: (record) => {
          return record.id || 'gId' + record.quoteGroupId
        },
        columns: [
          { title: '序号', dataIndex: 'id', customRender: (v, r, i) => (i + 1), width: '45px', className: 'column-center' },
          { title: '检测项/分组', dataIndex: 'itemId', scopedSlots: { customRender: 'itemId' }, width: '200px', className: 'column-center' },
          { title: '频次', dataIndex: 'numFrequency', scopedSlots: { customRender: 'numFrequency' }, width: '60px', className: 'column-center' },
          { title: '天数', dataIndex: 'numPeriod', scopedSlots: { customRender: 'numPeriod' }, width: '60px', className: 'column-center' },
          { title: '折扣', dataIndex: 'itemDiscount', scopedSlots: { customRender: 'itemDiscount' }, width: '60px', className: 'column-center' },
          { title: '单价(元)', dataIndex: 'itemPrice', scopedSlots: { customRender: 'itemPrice' }, width: '120px', className: 'column-center' },
          { title: '费用小计(元)', dataIndex: 'totalPrice', scopedSlots: { customRender: 'totalPrice' }, width: '120px', className: 'column-center' }
        ],
        pagination: false
      }
    }
  },
  methods: {
    currency,
    saveRow (record) {
      // 打开编辑
      // 计算新的价格
      if (record.quoteGroup) {
        var parmList = []
        record.itemList.forEach(item => {
          item.numFrequency = record.numFrequency
          item.numPeriod = record.numPeriod
          var totalPrice = item.numFrequency * item.numPeriod * item.itemPrice
          var parm = Object.assign({}, item)
          parm.totalPrice = totalPrice
          parmList.push(parm)
        })
        projTaskItemApi.editBatch(parmList).then(result => {
          if (result.code === 0) {
            this.$message.success(`操作成功`)
            this.$set(record, 'editable', false)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        var totalPrice = record.numFrequency * record.numPeriod * record.itemPrice
        var parm = Object.assign({}, record)
        parm.totalPrice = totalPrice
        projTaskItemApi.edit(parm).then(result => {
          if (result.code === 0) {
            this.$message.success(`操作成功`)
            this.$set(record, 'totalPrice', totalPrice)
            this.$set(record, 'editable', false)
          } else {
            this.$message.error(result.msg)
          }
        })
      }
    },
    editRow (record) {
      this.$set(record, 'editable', true)
    }
  },
  mounted () {
  }
}
</script>
<style lang="less">
th.column-center,
td.column-center {
  text-align: center !important;
  padding: 10px;
}
</style>
