<template>
  <div>
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="综合查询">
              <a-input v-model.trim.lazy="queryParam.compositionName" placeholder="综合查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :style="{ textAlign: 'left', marginBottom: '15px' }">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <div class="fr">
        <a-button @click="formModal.add()">制液</a-button>
      </div>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <check-standard-solution-table v-bind="customTable" @selectedRows="handleRowSelected" />
    </a-card>

    <a-modal v-bind="formModal" v-on="formModal.action">
      <check-standard-solution-form v-bind="formModal.data" v-on="formModal.data"></check-standard-solution-form>
      <template #footer>
        <a-button @click="formModal.cancel()" :disabled="formModal.loading">关闭</a-button>
        <a-button type="primary" @click="formModal.onOk()" :loading="formModal.loading">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { withComponentList } from '@/pages/common/mixins/list'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import { CheckStandardSolutionForm, CheckStandardSolutionTable } from '@/pages/cloud-quote/check-standard-solution/modules'
import { checkStandardSolutionNoActionColumn } from '@/pages/cloud-quote/check-standard-solution/modules/columns'

export default {
  name: 'StandardSolutionList',
  mixins: [withComponentList],
  components: { CheckStandardSolutionTable, CheckStandardSolutionForm },
  props: Object.assign({}, CheckStandardSolutionTable.props, {
    columns: { type: Array, required: false, default: () => checkStandardSolutionNoActionColumn },
    solutionTypeId: { type: Number, required: false },
    makeDate: { type: String, required: false, default: () => '' }
  }),

  computed: {
    customTable() {
      return Object.assign(
        { ref: 'solutionTable', columns: this.columns, selectType: this.selectType },
        { ...this.$attrs },
        { solutionTypeId: this.solutionTypeId },
        { makeDate: this.makeDate },
        this.queryParam
      )
    }
  },
  data() {
    return {
      queryParam: {
        compositionName: null
      },
      formModal: Object.assign({}, fullModalData, {
        title: '标准使用液配置',
        width: '50%',
        loading: false,

        // 绑定表单数据
        data: {
          ref: 'form',
          id: '',
          solutionTypeId: null,
          editType: null,
          success: () => {
            // this.formModal.loading = false
            this.formModal.cancel()
            // this.reload()
            this.search()
          },
          updateSolutionLoading: (bool) => {
            this.formModal.loading = bool
          }
        },

        // 进入新增界面
        add: () => {
          this.formModal.data.id = null
          this.formModal.data.solutionTypeId = this.solutionTypeId
          this.formModal.data.editType = 'add'
          this.formModal.show()
        },

        // 进入修改界面
        edit: ({ id }) => {
          this.formModal.data.id = id
          this.formModal.data.solutionTypeId = this.solutionTypeId
          this.formModal.data.editType = 'edit'
          this.formModal.show()
        },
        show: () => {
          this.formModal.visible = true
        },

        onOk: () => {
          // this.formModal.loading = true
          this.$refs[this.formModal.data.ref].save()
        },

        cancel: () => {
          this.formModal.loading = false
          this.formModal.visible = false
        }
      }),

      contentCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: {},
        class: 'card-table'
      },
      selectedRows: []
    }
  },

  methods: {
    // 获取选中的数据
    getSelectData() {
      return [...this.selectedRows]
    },

    // 处理行选中事件
    handleRowSelected(data) {
      this.selectedRows.splice(0, this.selectedRows.length, ...data)
      this.$emit('selectedRows', data)
    },
    search() {
      this.$refs.solutionTable.search()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
