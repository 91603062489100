<template>
  <a-modal v-bind="modal" v-on="modal">
    <span style="font-size:12px;color: red">
      <a-row>注：点击选中分类或点位，使用拖拽进行排序；点位不能跨分类拖拽。</a-row>
      <a-row><a-icon type="apartment" />：分类；<a-icon type="environment" />：点位</a-row>
    </span>
    <a-tree
      ref="tree"
      :treeData="sortTreeList"
      :showLine="true"
      :draggable="true"
      @drop="onDropAnchor"
      @dragstart="onDragstart"
      defaultExpandAll>
      <span slot="title" slot-scope="{id, type, title}">
        <a-icon v-if="type=='cate'" type="apartment" />
        <a-icon v-if="type=='site'" type="environment" />
        &nbsp;
        <span v-if="dragSiteId==id" style="color: red">{{ title }}</span>
        <span v-else>{{ title }}</span>
      </span>
    </a-tree>
  </a-modal>
</template>

<script>
import { quoteApi } from '@/api/quote'
export default {
  name: 'PlanSiteSortNew',
  components: { },
  props: {
    quoteId: {
      type: Number,
      default: null
    },
    siteList: {
      type: Array,
      default: () => ([])
    },
    cateList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      modal: {
        title: '点位排序',
        visible: false,
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
        style: 'max-height:500px',
        cancel: this.cancel,
        okText: '保存排序',
        ok: this.handleOK
      },

      sortTreeList: [],
      dragSiteId: ''
    }
  },
  methods: {
    sort () {
      this.modal.visible = true
      this.getCateSiteSort()
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      const self = this
      var siteSort = self.getCateSiteSort()
      const siteSortStr = JSON.stringify(siteSort)
      quoteApi.siteSort({ id: self.quoteId, siteSortStr: siteSortStr }).then(result => {
        if (result.code === 0) {
          this.$message.success(`${self.modal.title}成功`)
          // this.updateSiteList(result.data)
          this.cancel()
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    getCateSiteSort () {
      const self = this
      const cateList = self.cateList
      var cateSiteSortList = []
      var sortTreeList = []
      if (cateList) {
        // 点位排序操作
        cateList.forEach((cate, cateIdx) => {
          var siteList = cate.siteList
          var children = []
          sortTreeList.push({ id: `cate_${cateIdx}`, type: 'cate', title: cate.name, pid: 'root', scopedSlots: { title: 'title' }, children: children })
          if (siteList) {
            siteList.forEach((site, siteIdx) => {
              cateSiteSortList.push({ id: site.siteSortId, cateSort: (parseInt(cateIdx) + 1), siteSort: (parseInt(siteIdx) + 1) })
              children.push({ id: `cate_${cateIdx}_site_${siteIdx}`, type: 'site', title: site.siteName, pid: cateIdx, siteId: site.id, scopedSlots: { title: 'title' } })
            })
          }
        })
      }
      self.$set(self, 'sortTreeList', sortTreeList)
      return cateSiteSortList
    },
    getSiteSort () {
      const self = this
      const siteList = self.siteList
      var siteSortList = []
      var sortTreeList = []
      if (siteList) {
        // 点位排序操作
        siteList.forEach((site, siteIdx) => {
          var cateList = site.cateList
          if (cateList) {
            cateList.forEach((cate, cateIdx) => {
              siteSortList.push({ id: cate.siteSortId, siteSort: (parseInt(siteIdx) + 1), cateSort: (parseInt(cateIdx) + 1) })
            })
            // sortTreeList.push({ id: '#site_' + siteIdx, key: 'site_' + siteIdx, title: site.siteName, pid: '0', siteId: site.id })
            sortTreeList.push({ id: '#site_' + siteIdx, title: site.siteName, pid: '0', siteId: site.id, scopedSlots: { title: 'title' } })
          }
        })
      }
      self.$set(self, 'sortTreeList', sortTreeList)
      return siteSortList
    },
    onDragstart (info) {
      this.$refs.tree.$refs.tree.setState({
        _dragNodesKeys: info.node.eventKey
      })
    },
    onDropAnchor (info) {
      var self = this
      if (info.dragNode.dataRef.pid === info.node.dataRef.pid) {
        const dragArr = info.dragNode.pos.split('-')
        const dragIdx = Number(dragArr[dragArr.length - 1])
        const dropArr = info.node.pos.split('-')
        var dropIdx = Number(dropArr[dropArr.length - 1])
        // -1-在dropIdx前面，0-在dropIdx下，1-在dropIdx后
        const dropPosition = info.dropPosition - Number(dropArr[dropArr.length - 1])
        if (dropPosition === 0 || dropPosition === 1) {
          // 当前不存在下级，0和1都放在目标后面，即 +1
          dropIdx = dropIdx + 1
        }
        // dropIdx 最小为0 即在最前面
        dropIdx = dropIdx < 0 ? 0 : dropIdx
        if (dragIdx !== dropIdx) {
          var dragList = []
          var dragTreeList = []
          if (info.dragNode.dataRef.type === 'cate') {
            dragList = self.cateList
            dragTreeList = self.sortTreeList
          } else if (info.dragNode.dataRef.type === 'site') {
            var cateIdx = dragArr[1]
            dragList = self.cateList[cateIdx].siteList
            dragTreeList = self.sortTreeList[cateIdx].children
          }
          var dragObj = dragList[dragIdx]
          var treeNode = dragTreeList[dragIdx]
          // 先删除拖拽对象
          dragList.splice(dragIdx, 1)
          dragTreeList.splice(dragIdx, 1)
          // 先删除拖拽对象导致数据变化，调整插入索引
          // 新的插入位置在删除位置之后，插入位置-1
          dropIdx = dropIdx > dragIdx ? dropIdx - 1 : dropIdx
          dragList.splice(dropIdx, 0, dragObj)
          dragTreeList.splice(dropIdx, 0, treeNode)
          // 拖拽的点位
          self.dragSiteId = treeNode.id
        }
      }
    },
    cancel () {
      this.modal.visible = false
    },
    updateSiteList (site) {
      this.$emit('updateSiteList', site)
    }
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
