<template>
  <div>
    <a-card style="margin-top: 14px;min-width:650px;">
      <a-card :bordered="false" style="max-width: 1080px; margin: 16px auto;">
        <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
          {{ sctr.sctrName }}评审
        </div>
        <SctrCheckView :sctr.sync="sctr" :check.sync="check" :addr.sync="addr"/>
      </a-card>
    </a-card>
  </div>
</template>
<script>
import { subcontractorApi } from '@/api/project'
import SctrCheckView from './modules/SctrCheckView'
import moment from 'moment'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item

export default {
  name: 'SctrCheckViewMgr',
  components: {
    SctrCheckView,
    DetailList,
    DetailListItem
  },
  props: {
    processId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {

      editEnable: false,

      spinning: false,

      // 项目信息
      sctr: {},
      check: {},

      // 定位信息
      addr: {
        status: '',
        tip: '',
        adCode: '',
        address: '',
        adFullName: '',
        wholeAddress: '',
        lng: '',
        lat: ''
      }

    }
  },
  methods: {
    moment,
    save (status) {
      const formData = Object.assign({}, this.check)
      if (status) {
        formData.checkStatus = status
      }
      var saveApi = subcontractorApi.addOrEditCheck
      this.spinning = true
      saveApi(formData).then(result => {
        if (result.code === 0) {
          this.$message.success(`${this.sctr.sctrName}保存成功`)
          this.check.id = result.data.id
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      }).finally(() => {
        this.spinning = false
        this.editEnable = false
      })
    },
    getSubcontractor () {
      if (this.sctr.id) {
        subcontractorApi.get({ id: this.sctr.id }).then(result => {
          if (result.code === 0) {
            const sctr = result.data
            if (sctr.sctrCmaFile) {
              var fileExt = sctr.sctrCmaFile.substring(sctr.sctrCmaFile.lastIndexOf('.'))
              sctr.sctrCmaFileList = [{ fileUrl: sctr.sctrCmaFile, fileName: `资质证书${fileExt}` }]
            }
            if (sctr.sctrCmaSchedule) {
              const fileExt = sctr.sctrCmaSchedule.substring(sctr.sctrCmaSchedule.lastIndexOf('.'))
              sctr.sctrCmaScheduleList = [{ fileUrl: sctr.sctrCmaSchedule, fileName: `资质附表${fileExt}` }]
            }
            if (sctr.sctrLicense) {
              const fileExt = sctr.sctrLicense.substring(sctr.sctrLicense.lastIndexOf('.'))
              sctr.sctrLicenseFileList = [{ fileUrl: sctr.sctrLicense, fileName: `营业执照${fileExt}` }]
            }
            this.sctr = sctr
            this.$set(this.addr, 'adCode', this.sctr.sctrAdcode)
            this.$set(this.addr, 'address', this.sctr.sctrAddress)
            this.$set(this.addr, 'lng', this.sctr.sctrLng)
            this.$set(this.addr, 'lat', this.sctr.sctrLat)
            this.getCheck()
          }
        })
      } else {
        this.sctr = {}
        this.addr = {}
      }
    },
    getCheck () {
      // 获取评审信息
      if (this.sctr.id) {
        subcontractorApi.getCheck({ sctrId: this.sctr.id }).then(res => {
          if (res.code === 0) {
            var check = res.data || { sctrId: this.sctr.id }
            this.check = check
          }
        })
      }
    },
    getCheckTemp () {
      // 获取评审信息
      if (this.processId) {
        subcontractorApi.getCheck({ id: this.processId }).then(res => {
          if (res.code === 0) {
            var check = res.data || { }
            this.sctr.id = check.sctrId
            this.check = check
            this.getSubcontractor()
          }
        })
      }
    },
    initInfo () {
      if (this.processId) {
        this.getCheckTemp()
      } else if (this.sctr.id) {
        this.getSubcontractor()
      }
    }
  },
  watch: {
  },
  computed: {
  },
  filters: {
  },
  mounted () {
    this.initInfo()
  },
  beforeRouteEnter  (to, from, next) {
    next(vm => {
      var paramsKey = 'mx_SctrCheckViewRouteParams'
      var params = vm.$route.params
      var lastParams = null
      // 取出上次的参数
      var lastParamsStr = window.localStorage.getItem(paramsKey)
      if (params.enterType === 'add' || params.enterType === 'edit') {
        vm.editEnable = true
      }
      if (!params.enterType) {
        // 如果本次参数是空使用上次的参数，即刷新页面时，取上次参数
        params = JSON.parse(lastParamsStr)
      }
      if (params) {
        // 更新参数
        window.localStorage.setItem(paramsKey, JSON.stringify(params))
        vm.$set(vm.sctr, 'id', params.id)
        vm.initInfo()
      }
    })
  }
}
</script>

<style>
</style>

<style lang="less">
  .form-steps {
    max-width: 750px;
    margin: 16px auto;
  }
</style>
