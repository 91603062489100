<template>
  <div>
    <a-card v-bind="checkTaskCard">
      <a-row>
        <a-col :span="8" class="total-border"><a>样品情况</a></a-col>
        <a-col :span="8" class="total-border"><a @click="checkTaskTabs.key='schedule'">安排情况</a></a-col>
        <a-col :span="8" class="total-border"><a @click="checkTaskTabs.key='check'">检测情况</a></a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-col :span="8" class="total-border">
            样品总数：<a :style="{fontSize:'16px',fontWeight:600}"> {{ checkTaskInfo.sampleNum || '-' }}</a>
          </a-col>

          <a-col :span="8" class="total-border">
            采样数：<a :style="{fontSize:'16px',fontWeight:600}"> {{ checkTaskInfo.sampleOwner || '-' }}</a>
          </a-col>

          <a-col :span="8" class="total-border">
            送样数：<a :style="{fontSize:'16px',fontWeight:600}"> {{ checkTaskInfo.sampleOther || '-' }}</a>
          </a-col>
        </a-col>

        <a-col :span="8">
          <a-col :span="8" class="total-border">
            总计：<a :style="{fontSize:'16px',fontWeight:600}"> {{ checkTaskInfo.taskSumNum || '-' }}</a>
          </a-col>

          <a-col :span="8" class="total-border">
            已分配：<a :style="{fontSize:'16px',fontWeight:600,color:'#52C41A'}"> {{ checkTaskInfo.taskNum || '-' }}</a>
          </a-col>

          <a-col :span="8" class="total-border">
            待分配：<a :style="{fontSize:'16px',fontWeight:600,color:'gray'}"> {{ checkTaskInfo.taskSumNum - checkTaskInfo.taskNum || '-' }}</a>
          </a-col>
        </a-col>

        <a-col :span="8">
          <a-col :span="8" class="total-border">
            待开始：<a :style="{fontSize:'16px',fontWeight:600,color:'gray'}"> {{ checkTaskInfo.peedingNum || '-' }}</a>
          </a-col>

          <a-col :span="8" class="total-border">
            进行中：<a :style="{fontSize:'16px',fontWeight:600,color:'#F5D121'}"> {{ checkTaskInfo.processNum || '-' }}</a>
          </a-col>

          <a-col :span="8" class="total-border">
            已完成：<a :style="{fontSize:'16px',fontWeight:600,color:'#52C41A'}"> {{ checkTaskInfo.doneNum || '-' }}</a>
          </a-col>
        </a-col>

      </a-row>

      <a-card v-bind="checkTaskDetailCard">
        <a-tabs v-bind="checkTaskTabs" v-model="checkTaskTabs.key">

          <a-tab-pane tab="安排信息" key="schedule">
            <SampleInfoTable v-bind="SampleInfoTable"/>
          </a-tab-pane>

          <a-tab-pane tab="数据检测" key="check">
            <CheckTaskTable :proj-id="projectId" :show-action="false"></CheckTaskTable>
          </a-tab-pane>

          <a-tab-pane tab="审核信息" key="approval">
            <detail-list size="small" v-if="Object.keys(checkTaskInfo).length">
              <detail-list-item term="审核人">
                {{ checkTaskInfo.approvalUser }}
              </detail-list-item>

              <detail-list-item term="审核时间">
                {{ checkTaskInfo.approvalTime }}
              </detail-list-item>

              <detail-list-item term="审核结果">
                <a-tag color="red" v-if="checkTaskInfo.dataStatus==='0'">{{ checkTaskInfo.dataStatus | dataStatusFilter }}</a-tag>
                <a-tooltip v-else-if="checkTaskInfo.dataStatus==='1'">
                  <template slot="title">
                    <div>由于采样或者检测未完成</div>
                    <div>需要继续审核</div>
                  </template>
                  <a-tag color="blue">{{ checkTaskInfo.dataStatus | dataStatusFilter }}</a-tag>
                </a-tooltip>
                <a-tag color="green" v-else-if="checkTaskInfo.dataStatus==='2'">{{ checkTaskInfo.dataStatus | dataStatusFilter }}</a-tag>
                <a-tag color="orange" v-else>{{ checkTaskInfo.dataStatus | dataStatusFilter }}</a-tag>
              </detail-list-item>

              <detail-list-item term="审核意见" :item-col="1">
                {{ checkTaskInfo.note }}
              </detail-list-item>
            </detail-list>
            <div class="no-data" v-else>
              <a-icon type="frown-o"/>
              暂无数据
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-card>

  </div>
</template>

<script>
import { checkApprovalApi, checkApprovalDataStatusOptions } from '@/api/sample'
import { applyOutboundColumn } from '@/pages/cloud-quote/sample-info/sampleTypeColumn'
import { tranOptionsToMap } from '@/utils/util'

import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'
import DetailList from '@/components/tools/DetailList'
import { CheckTaskTable } from '@/pages/cloud-quote/check-task/modules'
import { checkOrderAbleStatusEnum, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkOrderStatusEnum } from '@/api/check/checkOrderConstant'

const DetailListItem = DetailList.Item
const dataStatusMap = tranOptionsToMap(checkApprovalDataStatusOptions)

export default {
  components: { DetailList, DetailListItem, SampleInfoTable, CheckTaskTable },

  props: {
    projId: {
      type: Number
    }
  },

  data () {
    return {
      projectId: this.projId,

      checkTaskInfo: {},

      transModeMap: {},

      SampleInfoTable: {
        ref: 'sampleTable',
        columns: applyOutboundColumn,
        projId: this.projId,

        neType: sampleInfoTypeEnum.virtual,
        cancelCheck: 0,
        temp: false,
        statuses: checkOrderAbleStatusEnum,

        showCancelCheck: true,
        dataType: 'checkOrder',
        disabledOrderStatus: [checkOrderStatusEnum.processing, checkOrderStatusEnum.completed]
      },

      purUseIndexModal: {
        ref: 'purUseIndexModal',
        dataType: 1,
        visible: false,
        title: '申请记录',
        cancel: () => { this.purUseIndexModal.visible = false },
        show: () => { this.purUseIndexModal.visible = true }
      },

      checkTaskTabs: {
        size: 'small',
        key: 'schedule'
      },

      checkTaskCard: {
        title: '检测信息',
        type: 'inner',
        class: 'task-card'
      },

      checkTaskDetailCard: {
        type: 'inner',
        style: { marginTop: '24px' },
        bodyStyle: { padding: '5px 10px' }
      },

      siteFeedbackCard: {
        type: 'inner',
        class: 'feedback-card'
      }
    }
  },

  watch: {
    projectId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.queryCheckTask(val)
        }
      }
    }
  },

  filters: {
    dataStatusFilter (data) {
      return dataStatusMap[data || null]
    }
  },

  methods: {

    /// 查询项目对应的采样安排信息
    queryCheckTask (projId) {
      checkApprovalApi.list({ projId, page: false }).then(res => {
        if (res.code === 0) {
          this.checkTaskInfo = res.data && res.data.length && res.data[0] || {}
          console.log('this.checkTaskInfo=>', this.checkTaskInfo)
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$route.params && vm.$route.params.projId) {
        vm.projectId = parseInt(vm.$route.params.projId) || null
      }

      if (vm.$route.query && vm.$route.query.projId) {
        vm.projectId = parseInt(vm.$route.query.projId) || null
      }
    })
  }
}
</script>

<style lang="less" scoped>
.text {
  color: rgba(0, 0, 0, .45);
}

.no-data {
  color: rgba(0, 0, 0, .25);
  text-align: center;
  line-height: 64px;
  font-size: 16px;
}

.total-border {
  border: 1px solid #E8E8E8;
  text-align: center;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
}

.heading {
  color: rgba(0, 0, 0, .85);
  font-size: 20px;
}

.task-card {
  margin-bottom: 24px;

  &_title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, .70);
  }

  &_sub-title {
    color: gray;
    margin-left: 10px;
  }

}
</style>
