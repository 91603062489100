'use strict'
import mixinDate from '@/pages/common/mixins/date'

const fullWidthStyle = { width: '100%' }

export const colSpanMap = {
  1: { xxl: { span: 24 }, xl: { span: 24 }, md: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } },
  2: { xxl: { span: 12 }, xl: { span: 24 }, md: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } },
  3: { xxl: { span: 8 }, xl: { span: 12 }, md: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } },
  4: { xxl: { span: 6 }, xl: { span: 8 }, md: { span: 12 }, sm: { span: 24 }, xs: { span: 24 } }
}

export default {
  mixins: [mixinDate],

  data () {
    return {
      colSpanMap,
      fullWidthStyle,
      currentColSize: 3,

      dataForm: {
        layout: 'horizontal',
        form: this.$form.createForm(this)
      },

      decorators: {},

      /// 搜索表单配置
      baseFormCard: {
        type: 'inner',
        hoverable: true,
        size: 'small',
        style: { marginBottom: '16px' }
      },

      /// 行配置
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'top',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },

      /// 表单项配置
      formItemLayout: {
        labelCol: {
          xxl: { span: 5 },
          xl: { span: 5 },
          lg: { span: 5 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        },
        wrapperCol: {
          xxl: { span: 19 },
          xl: { span: 19 },
          lg: { span: 19 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        }
      }

    }
  },

  computed: {

    // 列配置
    colProps () {
      const defaultProps = { offset: 0, pull: 0, push: 0, style: { textAlign: 'left' } }
      return Object.assign({}, defaultProps, this.colSpanMap[this.currentColSize])
    },

    defaultDatePickerProps () {
      return {
        format: this.timeFormatStr,
        showTime: true,
        style: this.fullWidthStyle
      }
    },

    moreCurrentDatePickerProps () {
      return Object.assign({}, this.defaultDatePickerProps, {
        disabledDate: this.moreCurrentDate
      })
    },

    lessCurrentDatePickerProps () {
      return Object.assign({}, this.defaultDatePickerProps, {
        disabledDate: this.lessCurrentDate
      })
    }
  },

  methods: {

    // 时间限制 不能大于当前时间
    moreCurrentDate (current) {
      return current && current > this.moment()
    },

    // 时间限制 不能小于当前时间
    lessCurrentDate (current) {
      return current && current < this.moment()
    },

    // select 过滤选项
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    // Tree Select 过滤选项
    treeFilterOption (input, option) {
      return option.componentOptions.propsData.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    // 根据key获取表单数据
    getFormDataByKey (key) {
      return this.dataForm.form.getFieldValue(key)
    },

    // 根据key设置表单数据
    setFormDataByKey (key, value) {
      this.dataForm.form.$nextTick(() => {
        const result = {}
        result[key] = value
        this.dataForm.form.setFieldsValue(result)
      })
    },

    // 根据指定的对象设置表单数据
    setFormData (data) {
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue(data)
      })
    },

    // 根据装饰器的key设置对应的表单数据
    setFormDataByDecorators (data) {
      this.dataForm.form.$nextTick(() => {
        const decoratorKeys = Object.keys(this.decorators)
        const newData = Object.keys(data).reduce((acc, key) => {
          decoratorKeys.includes(key) && (acc[key] = data[key])
          return acc
        }, {})
        this.dataForm.form.setFieldsValue(newData)
      })
    }
  }
}
