'use strict'

// 检测数据 数据状态字典枚举
export const checkTaskLogTypeEnum = {
  // 安排
  schedule: '1',
  // 重新安排
  reSchedule: '2',
  // 开始
  start: '3',
  // 部分录入
  partEntry: '4',
  // 完善
  perfected: '5',
  // 提交审核
  submitApproval: '6',
  // 存疑
  doubt: '7',
  // 审核通过
  pass: '8',
  // 提交重审
  reSubmit: '9',
  // 确认无误
  noError: '10',
  // 完成
  done: '11',
  // 作废
  void: '12'
}

// 展现操作详情内容标识
export const hasDetailContentFlags = [checkTaskLogTypeEnum.partEntry, checkTaskLogTypeEnum.perfected, checkTaskLogTypeEnum.reSubmit]

//  检测数据 数据状态选择项
export const checkTaskLogTypeEnumOptions = [
  { label: '安排', value: checkTaskLogTypeEnum.schedule, color: '#1364E6' },
  { label: '重新安排', value: checkTaskLogTypeEnum.reSchedule, color: '#990001' },
  { label: '开始', value: checkTaskLogTypeEnum.start, color: '#999999' },
  { label: '部分录入', value: checkTaskLogTypeEnum.partEntry, color: '#999999' },
  { label: '完善', value: checkTaskLogTypeEnum.perfected, color: '#169926' },
  { label: '提交审核', value: checkTaskLogTypeEnum.submitApproval, color: '#1364E6' },
  { label: '存疑', value: checkTaskLogTypeEnum.doubt, color: '#990001' },
  { label: '审核通过', value: checkTaskLogTypeEnum.pass, color: '#169926' },
  { label: '提交重审', value: checkTaskLogTypeEnum.reSubmit, color: '#169926' },
  { label: '确认无误', value: checkTaskLogTypeEnum.noError, color: '#169926' },
  { label: '完成', value: checkTaskLogTypeEnum.done, color: '#169926' },
  { label: '作废', value: checkTaskLogTypeEnum.void, color: '#990001' }
]
