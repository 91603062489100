<template>
  <div class="sample-storage-apply-check-table">

    <customize-column v-model="contentTable.columns" cache-key-pre="sample-storage-apply-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>

    <s-table v-bind="contentTable" :rowSelection="customRowSelection" :scroll="scrollProp" v-on="contentTable.action">
      <template #status="item,record">
        <template v-if="item===sampleInfoStorageApplyStatusEnum.part">
          <a-tooltip :getPopupContainer="getCurrentDocument">
            <template #title>
              <p class="prompt">
                <span class="title">未收到样品</span>
                <span class="red focus-num">
                  <a-tag color="red" v-for="id in record.delIds.split(',')" :key="id">{{ id }}</a-tag>
                </span>
              </p>
            </template>
            <a-tag :color="sampleInfoStorageApplyStatusDataMap[item].color">{{ sampleInfoStorageApplyStatusDataMap[item].label }}</a-tag>
          </a-tooltip>
        </template>
        <template v-else-if="sampleInfoStorageApplyStatusDataMap[item]">
          <a-tag :color="sampleInfoStorageApplyStatusDataMap[item].color">{{ sampleInfoStorageApplyStatusDataMap[item].label }}</a-tag>
        </template>
      </template>

      <template #projName="item,record">
        <template v-if="[projectStatusEnum.tempAbandoned,projectStatusEnum.abandoned].includes(record.projStatus)">
          <a-tag :color="projectStatusDataMap[record.projStatus].color">【{{ projectStatusDataMap[record.projStatus].label }}】</a-tag>
        </template>
        【 <span class="green">{{ record.projNo }}</span>】{{ item }}
      </template>

      <template #sampleStatus="item,record">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            <p class="prompt">
              <span class="title">已入库</span>
              <span class="green focus-num">{{ record.inNum }}</span>
              <span class="title">未收到样品</span>
              <span class="red focus-num">{{ record.delNum }}</span>
              <span class="title">合计</span>
              <span class="gray focus-num">{{ record.sum }}</span>
            </p>
          </template>
          <span class="green focus-num">{{ record.inNum }}</span>
          <span class="mar-h5">/</span>
          <span class="red focus-num">{{ record.delNum }}</span>
          <span class="mar-h5">/</span>
          <span class="gray focus-num">{{ record.sum }}</span>
        </a-tooltip>
      </template>

      <template #scheduleStatus="item,record">
        <template v-if="[projectStatusEnum.tempAbandoned,projectStatusEnum.abandoned].includes(record.projStatus)">
          <a-tooltip>
            <template #title>该项目已经被
              <a-tag :color="projectStatusDataMap[record.projStatus].color">{{ projectStatusDataMap[record.projStatus].label }}</a-tag>
              ，暂不需要进行检测安排！</template>
            <a-tag color="gray">无需安排</a-tag>
          </a-tooltip>
        </template>
        <template v-else>
          <a-tooltip>
            <template #title>
              <p>总样品数：{{ record.inNum }}</p>
              <p>检测样品数：{{ record.checkNum }}</p>
              <p>安排数量：{{ record.scheduleNum }}</p>
            </template>
            <template v-if="record.scheduleNum === record.checkNum">
              <a-tag color="green">已安排</a-tag>
            </template>
            <template v-else-if="record.scheduleNum < record.checkNum && record.scheduleNum > 0">
              <a-tag color="blue">部分安排</a-tag>
            </template>
            <template v-else>
              <a-tag color="red">暂未安排</a-tag>
            </template>
          </a-tooltip>
        </template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { CustomizeColumn } from '@/components/Columns'
import { componentTable } from '@/pages/common/mixins/list'
import { sampleStorageApplyCheckDefaultColumn as defaultColumn } from './columns'
import { projectStatusDataMap, projectStatusEnum } from '@/api/project/projectConstants'

import { sampleInfoStorageApplyApi } from '@/api/sampleInfo/sampleInfoStorageApplyApi'
import { sampleInfoStorageApplyStatusDataMap, sampleInfoStorageApplyStatusEnum } from '@/api/sampleInfo/sampleInfoStorageApplyConstant'

export default {
  components: { sTable, CustomizeColumn },
  mixins: [componentTable],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    defaultOtherHeight: { type: Number, required: false, default: 0 },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      sampleInfoStorageApplyStatusDataMap,
      sampleInfoStorageApplyStatusEnum,
      projectStatusDataMap,
      projectStatusEnum
    }
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {

    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        return sampleInfoStorageApplyApi.checkList(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '_index';
</style>
