<template>
  <div>
    <s-table :rowSelection="selectType?rowSelection:null" :scroll="scrollProp" v-bind="contentTable">

      <template #curveNo="text,record">
        <a @click="detailModal.show(record)">{{ text }}</a>
      </template>

      <template #reviewStatus="text">
        <template v-if="checkStandardCurveReviewStatusDataMap[text]">
          <a-tag :color="checkStandardCurveReviewStatusDataMap[text].color">{{ checkStandardCurveReviewStatusDataMap[text].label }}</a-tag>
        </template>
        <template v-else></template>
      </template>

      <template #checkStatus="text">
        <template v-if="checkStandardCurveCheckStatusDataMap[text]">
          <a-tag :color="checkStandardCurveCheckStatusDataMap[text].color">{{ checkStandardCurveCheckStatusDataMap[text].label }}</a-tag>
        </template>
        <template v-else></template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" #[slot]="text,record,index">
        <slot :name="slot" v-bind="{text:text,row:record,index:index}"/>
      </template>

    </s-table>

    <!-- 详情信息 -->
    <check-standard-curve-detail v-bind="detailModal"/>

  </div>
</template>

<script>
import sTable from '@/components/Table'
import defaultColumns from '@/pages/cloud-quote/check-standard-curve/modules/columns'
import mixinList from '@/pages/common/mixins/list'
import { checkStandardCurveConfigApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import { CheckStandardCurveDetail } from '@/pages/cloud-quote/check-standard-curve/modules'

import { checkStandardCurveCheckStatusDataMap, checkStandardCurveCheckStatusEnum, checkStandardCurveReviewStatusDataMap, checkStandardCurveReviewStatusEnum } from '@/api/checkStandardCurve/checkStandardCurveConstant'

export default {
  mixins: [mixinList],
  components: { sTable, CheckStandardCurveDetail },

  props: {

    selectType: {
      type: String,
      required: false,
      default: null,
      validator (value) {
        return [null, '', undefined, 'radio', 'checkbox'].includes(value)
      }
    },

    queryParam: {
      type: Object,
      required: false,
      default: () => { return {} }
    },

    modalType: {
      type: String,
      required: false,
      default: 'fullModal'
    },

    modalWidth: {
      type: [Number, String],
      required: false,
      default: 800
    },

    modalTitle: {
      type: String,
      required: false,
      default: '标线配置'
    },

    columns: {
      type: Array,
      required: false,
      default: () => (defaultColumns)
    }
  },

  data () {
    return {

      checkStandardCurveReviewStatusEnum,
      checkStandardCurveReviewStatusDataMap,
      checkStandardCurveCheckStatusEnum,
      checkStandardCurveCheckStatusDataMap,

      detailModal: {
        ref: 'detail',
        id: null,
        solutionId: null,
        visible: false,
        templateFile: '',

        show: (record) => {
          this.detailModal.id = record.id
          this.detailModal.solutionId = record.solutionId
          this.detailModal.templateFile = record.fileTemplateName
          this.detailModal.visible = true
          this.$nextTick(() => {
            this.$refs[this.detailModal.ref].show()
          })
        }
      },

      contentTable: {
        columns: this.columns
      },

      rowSelection: {
        type: this.selectType
      }
    }
  },

  watch: {
    queryParam: {
      deep: true,
      immediate: false,
      handler (param) {
        this.loadData()
      }
    },

    'rowSelection.selectedRows': {
      handler (param) {
        this.$emit('selectedRows', param)
      }
    }
  },

  methods: {

    // 加载数据
    loadData (param) {
      return checkStandardCurveConfigApi.list(Object.assign({}, param, this.queryParam)).then(res => res)
    }
  }
}
</script>
