<script>
export default {
  name: 'CheckTaskWorkSpaceModal'
}
</script>

<script setup>
import { useFullModal } from '@/pages/common/mixins/modal/full'
const { modalProps, modalAction } = useFullModal({ title: '检测工作台' })
import { CheckTaskWorkspace } from '@/pages/cloud-quote/check-task/modules'
import { ref } from 'vue'

const batchId = ref()
const show = (data) => {
  batchId.value = data.id
  modalAction.show()
}

defineExpose({ show })
</script>

<template>
  <a-modal v-bind="modalProps" v-on="modalAction">
    <check-task-workspace :batchId="batchId"></check-task-workspace>
  </a-modal>
</template>

<style scoped>

</style>
