<template>
  <a-modal v-bind="modal" v-on="modal">
    <a-row>
      <div id="footer" style="height: 500px;">
        <el-amap ref="map" vid="amap" :amap-manager="amapManager" v-bind="mapOptions">
          <div style="position: absolute;top: 25px;left: 20px;">
            <a-auto-complete
              style="width: 400px"
              optionLabelProp="text"
              placeholder="搜索..."
              @search="searchTips"
              @select="handleSelect">
              <a-input>
                <a-icon slot="suffix" type="search" class="certain-category-icon" />
              </a-input>
              <template slot="dataSource">
                <!--value 不是number-->
                <a-select-option v-for="(obj, idx) in tipList" :key="idx" :value="`${idx}`" :text="obj.name" :info="obj">
                  {{ obj.name }} - {{ obj.district }}
                </a-select-option>
              </template>
            </a-auto-complete>
          </div>
          <el-amap-marker
            v-for="(marker, index) in markers"
            :position="marker.position"
            :content="marker.content"
            :events="marker.events"
            visible="true"
            :key="index"
            :vid="index"></el-amap-marker>
          <el-amap-info-window v-if="windowShow&&infoWindow.position.length==2" :position="infoWindow.position" :visible="infoWindow.visible" :offset="infoWindow.offset">
            <p v-if="curMarkerInfo.name">{{ curMarkerInfo.name }}</p>
            <p>地址：{{ curMarkerInfo.address }}</p>
          </el-amap-info-window>
          <div class="map-layer">
            <a-select style="width: 120px" v-model="mapLayerType" @change="handleLayerChange">
              <a-select-option value="defaultlayer">标准图层</a-select-option>
              <a-select-option value="satellite">卫星图</a-select-option>
              <a-select-option value="satelliteFloor">卫星楼层图</a-select-option>
            </a-select>
          </div>
          <div v-if="curPosition.lng" style="position: absolute;left: 100px;bottom: 18px;float: left;font-size: x-small;">
            {{ curPosition.lng }}，{{ curPosition.lat }}
          </div>
        </el-amap>
      </div>
    </a-row>
    <a-row>
      坐标：<span v-if="curMarkerInfo.lng">lng = {{ curMarkerInfo.lng }} lat = {{ curMarkerInfo.lat }}</span>
      地址：<span v-if="curMarkerInfo.address">{{ curMarkerInfo.address }}</span>
    </a-row>
  </a-modal>
</template>

<script>
import { AMapManager } from 'vue-amap'
import ARow from 'ant-design-vue/es/grid/Row'

export default {
  name: 'MapLocation',
  components: { ARow },
  props: {
    windowShow: {
      type: Boolean,
      default: false
    },
    addr: {
      type: Object,
      default: () => ({
        adCode: '',
        address: '',
        lng: '',
        lat: ''
      })
    }
  },
  data () {
    const self = this
    const amapManager = new AMapManager()
    return {
      modal: {
        title: '地图点位',
        visible: false,
        centered: true,
        closable: false,
        width: '60%',
        height: '60%',
        // wrapClassName: 'modal-container',
        destroyOnClose: true,
        cancel: this.close,
        ok: this.handleOK
      },
      amapManager,
      mapLayerType: 'defaultlayer',
      layers: {
        satellite: new AMap.TileLayer.Satellite(),
        bulidings:
        // 楼块图层
          new AMap.Buildings({
            zooms: [16, 18],
            zIndex: 10,
            heightFactor: 2 // 2倍于默认高度，3D下有效
          })
      },
      map: {},
      keywords: '',
      searchOption: {
        // city: '上海',
        // citylimit: true
      },
      mapOptions: {
        zoom: 12,
        // 不设置center 自动切换到用户IP所在城市
        // center: [117.24665, 31.810439],
        pitch: 60,
        rotation: -35,
        // 隐藏默认楼块
        features: ['bg', 'road', 'point'],
        mapStyle: 'amap://styles/normal',
        viewMode: '2D',
        loaded: false,
        events: {
          init: (map) => {
            if (this.mapLayerType === 'satelliteFloor') {
              const satellite = this.layers.satellite
              const buildings = this.layers.bulidings
              satellite.setMap(map)
              buildings.setMap(map)
            } else {
              if (this.mapLayerType === 'satellite') {
                this.mapOptions.viewMode = '2D'
                const satellite = this.layers.satellite
                satellite.setMap(map)
              }
              // this.initPathSimplifier()
            }
            // 逆地址查询
            map.plugin(['AMap.Geocoder'])
            self.map = map
          },
          mousemove (e) {
            self.curPosition = e.lnglat
          },
          click (e) {
            console.log(e)
            // if (self.geocoder) {
            //   self.geocoder = new AMap.Geocoder({
            //     // city: "010", //城市设为北京，默认：“全国”
            //     radius: 500 // 范围，默认：500
            //   })
            // }
            self.curMarkerInfo = {
              lng: e.lnglat.lng,
              lat: e.lnglat.lat
              // ,name: info.name
              // ,address: info.address
              // ,adcode: info.adcode
            }
            self.regeoCode([e.lnglat.lng, e.lnglat.lat])
            // self.addMarker(self.curMarkerInfo)
          }
        },
        plugin: [{ pName: 'Scale',
          // 比例尺
          events: {
            init (instance) {
              // console.log(instance)
            }
          }
        }]
      },
      curPosition: {},
      markers: [],
      infoWindow: {
        visible: true,
        position: [],
        offset: [0, -32]
      },
      makerPosition: [],
      tipList: [],
      curMarkerInfo: {}
    }
  },
  methods: {
    open () {
      this.modal.visible = true
      if (this.addr.lng) {
        this.curMarkerInfo = {
          // name: this.addr..name,
          address: this.addr.address,
          lng: this.addr.lng,
          lat: this.addr.lat,
          adcode: this.addr.adcode
        }
        this.addMarker(this.curMarkerInfo)
      }
    },
    close () {
      this.modal.visible = false
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      this.$emit('ok', this.curMarkerInfo)
      this.close()
    },
    searchTips (keywords) {
      var self = this
      var autoOptions = {
        city: '全国'
      }
      var autoComplete = new AMap.Autocomplete(autoOptions)
      autoComplete.search(keywords, function (status, result) {
        // 搜索成功时，result即是对应的匹配数据
        console.log(status, result)
        if (result.info === 'OK') {
          self.tipList = result.tips
        }
      })
    },
    handleSelect (v, opt) {
      var info = opt.data.attrs.info
      this.curMarkerInfo = {
        name: info.name,
        address: info.address,
        lng: info.location.lng,
        lat: info.location.lat,
        adcode: info.adcode
      }
      this.addMarker(this.curMarkerInfo)
    },
    addMarker (curMarkerInfo) {
      var self = this
      var position = [curMarkerInfo.lng, curMarkerInfo.lat]
      self.mapOptions.center = position
      self.markers = [{
        position: position,
        content: '<img src="/map/marker8.png" width="25" height="34" alt=""/>',
        zIndex: 1000,
        type: '2',
        offset: [-12, -12],
        // , label: { content: '999999999', offset: [ -20, -24 ] }
        events: {
          click: () => {
            self.infoWindow.visible = false
            self.$nextTick(() => {
              self.infoWindow.visible = true
            })
          }
        }
      }]
      self.infoWindow.position = position
      self.infoWindow.visible = false
      self.$nextTick(() => {
        self.infoWindow.visible = true
      })
    },
    regeoCode (lnglat) {
      var self = this
      var geocoder = new AMap.Geocoder({
        // city: "010", //城市设为北京，默认：“全国”
        radius: 500 // 范围，默认：500
      })
      geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          var addressComponent = result.regeocode.addressComponent
          var addrPer = addressComponent.province + addressComponent.city + addressComponent.district
          var address = result.regeocode.formattedAddress.replace(addrPer, '')
          self.curMarkerInfo.adcode = addressComponent.adcode
          // self.curMarkerInfo.name = addressComponent.adcode
          self.curMarkerInfo.addrPer = addrPer
          self.curMarkerInfo.address = address
          self.addMarker(self.curMarkerInfo)
        } else {
          console.log('根据经纬度查询地址失败')
        }
      })
    },
    handleLayerChange () {
      if (this.mapLayerType === 'satelliteFloor') {
        this.mapOptions.viewMode = '3D'
      } else {
        this.mapOptions.viewMode = '2D'
      }
      this.$refs.map.createMap()
    }
  }
}
</script>

<style lang="less">
.map-layer{
  position: absolute;
  right: 15px;
  bottom: 18px;
  float: right
}
</style>
<style scoped>
</style>
