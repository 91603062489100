<template>
  <div class="check-task-list">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="安排时间" v-bind="formItemLayout">
              <a-range-picker v-bind="dateRangeProps" v-model="taskBeginQueryRanges" @change="handleCheckOrderTimeChange" />
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="检测员" v-bind="formItemLayout">
              <!-- <select-user-by-dept :disabled="false" :triggerChange="true" @change="(userList)=>handleCheckUserChange(userList)"/> -->
              <a-select v-model="queryParam.checkUsers" mode="multiple" :filterOption="filterOption" placeholder="请选择检测员">
                <a-select-option v-for="usr in userList" :key="usr.name">{{ usr.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="任务状态" v-bind="formItemLayout">
              <a-select v-model="queryParam.projStatuses" placeholder="任务状态查询" v-bind="dataStatusSelect" @change="search">
                <a-select-option v-for="(data, key) in projectStatusDataMap" :key="key" :value="key">
                  {{ data.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="actionColProps">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="api" @click="quickSearchDrawer.action.show">精准查询</a-button>
              <a-button icon="reload" @click="resetForm">重置</a-button>
            </a-button-group>
            <a style="margin-left: 8px" @click="toggleSearchStatus = !toggleSearchStatus">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
            </a>
          </a-col>
        </a-row>

        <a-row v-show="toggleSearchStatus" v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="数据状态" v-bind="formItemLayout">
              <a-select v-model="queryParam.dataStatuses" placeholder="数据状态查询" v-bind="dataStatusSelect" @change="search">
                <a-select-option v-for="(data, key) in checkTaskResultDataStatusDataMap" :key="key" :value="key">
                  {{ data.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="样品状态" v-bind="formItemLayout">
              <a-select v-model="queryParam.sampleStatuses" placeholder="样品状态查询" v-bind="dataStatusSelect" @change="search">
                <a-select-option v-for="(item, index) in sampleInfoStatusOptions" v-if="item.value !== sampleInfoStatusEnum.doneSample" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="样品编号" v-bind="formItemLayout">
              <a-input v-model.trim.lazy="queryParam.sampleBlurId" placeholder="样品编号模糊查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-show="toggleSearchStatus" v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="任务编号" v-bind="formItemLayout">
              <a-input v-model.trim.lazy="queryParam.projNo" placeholder="任务编号查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="做样时间" v-bind="formItemLayout">
              <a-range-picker v-bind="dateRangeProps" @change="handleSampleTimeChange" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <a-dropdown-button v-bind="actionButton">
        批量
        <a-menu slot="overlay">
          <a-menu-item key="1" v-action:check-task:feedback @click="batchStart">
            <a-tooltip title="请先勾选你要开始的任务，然后点击此按钮！"> 批量开始 </a-tooltip>
          </a-menu-item>

          <a-menu-item v-action:sample-info:outbound @click="outboundSample"> 样品出库 </a-menu-item>
          <a-menu-item v-action:check-task:start @click="handleBatchDelete"> 批量作废 </a-menu-item>
        </a-menu>
      </a-dropdown-button>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <a-row :gutter="16">
        <a-col :span="4">
          <div :style="{ maxHeight: getMaxHeight(), overflow: 'auto' }">
            <a-input-search placeholder="..." style="margin-bottom: 8px" @change="handleTreeSearchChange" />
            <a-tree v-bind="itemTreeProps" v-on="itemTreeProps.action">
              <template #title="{ title }">
                <span v-if="searchValue && title.indexOf(searchValue) > -1">
                  {{ title.substr(0, title.indexOf(searchValue)) }}
                  <span style="color: #4cff00">{{ searchValue }}</span>
                  {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
                </span>
                <span v-else>{{ title }}</span>
              </template>
            </a-tree>

            <a-tree v-bind="batchTreeProps" v-on="batchTreeProps.action">
              <a-tree-node v-for="item in batchTreeProps.database" :key="item.key" :selectable="false">
                <template #title>
                  <span v-if="searchValue && item.title.indexOf(searchValue) > -1">
                    {{ item.title.substr(0, item.title.indexOf(searchValue)) }}
                    <span style="color: #4cff00">{{ searchValue }}</span>
                    {{ item.title.substr(item.title.indexOf(searchValue) + searchValue.length) }}
                  </span>
                  <span v-else>{{ item.title }}</span>
                </template>

                <template v-if="item.children">
                  <a-tree-node v-for="cItem in item.children" :key="cItem.key" :isLeaf="true">
                    <template #title>
                      <template v-if="cItem.dataStatus === checkTaskResultDataStatusEnum.pending">
                        <a-tooltip>
                          <template #title> 当前数据还未录入 </template>
                          <span v-if="searchValue && cItem.title.indexOf(searchValue) > -1">
                            {{ cItem.title.substr(0, cItem.title.indexOf(searchValue)) }}
                            <span style="color: #4cff00">{{ searchValue }}</span>
                            {{ cItem.title.substr(cItem.title.indexOf(searchValue) + searchValue.length) }}
                          </span>
                          <span v-else :style="{ color: 'red' }">{{ cItem.title }}</span>
                        </a-tooltip>

                        <a-tooltip>
                          <template #title> 调整批次信息 </template>
                          <span :style="{ marginLeft: '12px' }">
                            <a @click="enterSampleRecordByBatch($event, cItem)"><a-icon type="form"></a-icon></a>
                          </span>
                        </a-tooltip>
                      </template>

                      <template v-else-if="checkTaskResultRedoDataStatus.includes(cItem.dataStatus)">
                        <template v-if="cItem.dataStatus === checkTaskResultDataStatusEnum.pending">
                          <span v-if="searchValue && cItem.title.indexOf(searchValue) > -1">
                            {{ cItem.title.substr(0, cItem.title.indexOf(searchValue)) }}
                            <span style="color: #4cff00">{{ searchValue }}</span>
                            {{ cItem.title.substr(cItem.title.indexOf(searchValue) + searchValue.length) }}
                          </span>
                          <span v-else :style="{ color: 'orange' }">{{ cItem.title }}</span>

                          <a-tooltip>
                            <template #title> 调整批次信息 </template>
                            <span :style="{ marginLeft: '12px' }">
                              <a @click="enterSampleRecordByBatch($event, cItem)"><a-icon type="form"></a-icon></a>
                            </span>
                          </a-tooltip>
                        </template>

                        <template v-else>
                          <span v-if="searchValue && cItem.title.indexOf(searchValue) > -1">
                            {{ cItem.title.substr(0, cItem.title.indexOf(searchValue)) }}
                            <span style="color: #4cff00">{{ searchValue }}</span>
                            {{ cItem.title.substr(cItem.title.indexOf(searchValue) + searchValue.length) }}
                          </span>
                          <span v-else :style="{ color: 'green' }">{{ cItem.title }}</span>
                        </template>
                      </template>

                      <template v-else>
                        <span v-if="searchValue && cItem.title.indexOf(searchValue) > -1">
                          {{ cItem.title.substr(0, cItem.title.indexOf(searchValue)) }}
                          <span style="color: #4cff00">{{ searchValue }}</span>
                          {{ cItem.title.substr(cItem.title.indexOf(searchValue) + searchValue.length) }}
                        </span>
                        <span v-else>{{ cItem.title }}</span>
                      </template>

                      <span :style="{ marginLeft: '12px' }">
                        <a
                          @click="
                            (event) => {
                              checkTaskScheduleDetailDrawer.action.show(cItem.key)
                              event.stopPropagation()
                            }
                          "
                        >
                          <a-icon type="profile"></a-icon>
                        </a>
                      </span>
                    </template>
                  </a-tree-node>
                </template>
              </a-tree-node>
            </a-tree>
          </div>
        </a-col>

        <a-col :span="20">
          <check-task-table v-bind="tableProps" :mustHaveCateAndItem="mustHaveCateAndItem">
            <template #expandedRowRender="{ text: record, expanded }">
              <check-result-table
                v-if="expanded"
                :key="record.id + loadKey"
                :checkTaskStatus="record.status"
                :showPagination="false"
                :taskOrRefTaskId="record.id"
                @success="reset"
              />
            </template>

            <template #batchGroupId="{ row: record }">
              <a @click="searchSameBatch($event, record.batchGroupId)">{{ record.batchGroupNo }}</a>
            </template>

            <template #action="{ row: record }">
              <template v-if="loginUser.username === record.checkUser">
                <template v-if="record.status === checkTaskStatusEnum.scheduled && record.sampleStatus === sampleInfoStatusEnum.outbound">
                  <a v-action:check-task:start @click.prevent="start(record)"> 开始 </a>
                </template>

                <template
                  v-if="
                    record.status === checkTaskStatusEnum.processing &&
                    [checkTaskResultDataStatusEnum.pendReview, checkTaskResultDataStatusEnum.reviewResubmit].includes(record.dataStatus)
                  "
                >
                  <a-divider type="vertical" />
                  <a v-action:check-task:done @click.prevent="done(record)">完成</a>
                </template>

                <template v-if="record.sampleStatus === sampleInfoStatusEnum.storaged">
                  <a-tooltip>
                    <template #title>点击发情领样申请</template>
                    <a-divider type="vertical" />
                    <a @click="applyOutbound(record.sampleId)">领样</a>
                  </a-tooltip>
                </template>

                <template
                  v-if="
                    (![sampleInfoTypeEnum.normal, sampleInfoTypeEnum.password].includes(record.sampleType) && record.status !== checkTaskStatusEnum.completed) ||
                    ['-1', '2'].includes(record.projStatus)
                  "
                >
                  <a-divider type="vertical" />
                  <a v-action:check-task:start @click.prevent="handleDeleteTask([record])"> 作废</a>
                </template>
              </template>

              <template v-if="record.recordId">
                <a-divider type="vertical" />
                <a @click="showOriginalRecord(record)">查看记录</a>
                <template
                  v-if="
                    loginUser.username === record.checkUser &&
                    [
                      checkTaskResultDataStatusEnum.broken,
                      checkTaskResultDataStatusEnum.perfected,
                      checkTaskResultDataStatusEnum.doubt,
                      checkTaskResultDataStatusEnum.ReviewReject
                    ].includes(record.dataStatus)
                  "
                >
                  <a-divider type="vertical" />
                  <a @click="checkOriginalRecordModal.edit(record.batchId, record.recordId)">修改记录</a>
                </template>
              </template>
            </template>
          </check-task-table>
        </a-col>
      </a-row>
    </a-card>

    <!-- 出库 -->
    <outbound-sample-form v-if="componentShow.outboundForm" ref="outboundForm" @cancel="componentShow.outboundForm = false" @success="search" />

    <!-- 检测日志 -->
    <a-drawer v-if="logDrawer.visible" v-bind="logDrawer" v-on="logDrawer">
      <check-task-log :check-task-id="logDrawer.checkTaskId"></check-task-log>
    </a-drawer>

    <!-- 检测准备主界面 -->
    <a-modal v-bind="checkTaskScheduleModal" v-on="checkTaskScheduleModal.action">
      <check-task-schedule v-bind="checkTaskScheduleModal.data" v-on="checkTaskScheduleModal.dataAction"></check-task-schedule>
      <template #footer>
        <a-button @click="checkTaskScheduleModal.action.cancel">关闭</a-button>
        <a-button @click="checkTaskScheduleModal.action.min">最小化</a-button>
        <a-button type="primary" @click="checkTaskScheduleModal.action.ok">确定</a-button>
      </template>
    </a-modal>
    <div v-if="checkTaskScheduleModal.showTag" class="tag animate__animated animate__shakeY animate__delay-1s" style="bottom: 54px">
      <span @click="checkTaskScheduleModal.action.max">批次调整</span>
    </div>

    <!-- 检测批次明细 -->
    <a-drawer v-bind="checkTaskScheduleDetailDrawer" v-on="checkTaskScheduleDetailDrawer.action">
      <check-task-schedule-detail v-bind="checkTaskScheduleDetailDrawer.data" v-on="checkTaskScheduleDetailDrawer.dataAction" />
    </a-drawer>

    <!-- 快速查询 -->
    <a-drawer v-bind="quickSearchDrawer" v-on="quickSearchDrawer.action" style="background: #fff">
      <check-task-quick-search v-bind="quickSearchDrawer.data" @toDetail="handleQuerySearch" />
    </a-drawer>
  </div>
</template>

<script>
import { withComponentList } from '@/pages/common/mixins/list'
import { cloneDeep, debounce, groupBy, uniqBy } from 'lodash'
import { fullModalData } from '@/pages/common/mixins/modal/full'

import { CheckResultTable, CheckTaskQuickSearch, CheckTaskSchedule, CheckTaskScheduleDetail, CheckTaskTable } from '@/pages/cloud-quote/check-task/modules'
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import OutboundSampleForm from '@/pages/cloud-quote/sample-info/modules/OutboundSampleForm'
import OriginalRecord from '@/pages/cloud-quote/check-task/modules/originalRecord'

import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import { labQcTypeEnum, sampleInfoStatusEnum, sampleInfoStatusOptions, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { projectStatusDataMap } from '@/api/project/projectConstants'
import { checkTaskResultDataStatusDataMap, checkTaskResultDataStatusEnum, checkTaskResultRedoDataStatus } from '@/api/check/checkTaskResultConstant'

import { checkTaskApi } from '@/api/check/checkTaskApi'
import { checkOriginalRecordApi } from '@/api/check/checkOriginalRecordApi'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { tsSampleinfoApi } from '@/api/sample'
import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'
import mixinDate from '@/pages/common/mixins/date'
import { resStaffApi, Get } from '@/api/quote'

export default {
  mixins: [withComponentList, mixinDate],
  components: { CheckTaskTable, SelectUserByDept, OutboundSampleForm, CheckResultTable, OriginalRecord, CheckTaskSchedule, CheckTaskScheduleDetail, CheckTaskQuickSearch },

  props: {
    statuses: { type: Array, required: false, default: () => [] },
    dataStatuses: { type: Array, required: false, default: () => ['0'] },
    projId: { type: Number, required: false, default: null },
    status: { type: String, required: false, default: null },
    rowSelect: { type: Object, required: false, default: () => ({ selectedRows: [], selectedRowKeys: [] }) },
    batchId: { type: Number, required: false, default: null },
    mustHaveCateAndItem: { type: Boolean, required: false, default: false } // 必须有因子参数，这是新建批次中选择样品时的条件，其它页面不需要遵循此条件
  },

  data() {
    return {
      checkTaskStatusEnum,
      sampleInfoStatusEnum,
      sampleInfoStatusOptions,
      sampleInfoTypeEnum,
      projectStatusDataMap,
      checkTaskResultRedoDataStatus,
      checkTaskResultDataStatusEnum,
      checkTaskResultDataStatusDataMap,

      dataStatusSelect: {
        mode: 'multiple',
        style: { width: '100%' },
        allowClear: true
      },

      queryParam: {
        status: null,

        // 通用字段
        sampleBlurId: null,
        sampleStatuses: ['3'],
        projId: null,
        itemId: null,
        cateId: null,
        itemIds: [],
        cateAndItemStrs: [],
        itemName: null,
        cateName: null,
        batchNo: null,
        batchId: null,
        batchGroupId: null,
        statuses: [],
        sampleBeginTime: null,
        sampleEndTime: null,
        dataStatuses: ['0'],
        projStatuses: [],
        checkUsers: [],
        taskBeginQueryBeginTime: this.timeFormat(this.moment().startOf('isoWeek')),
        taskBeginQueryEndTime: this.timeFormat(this.moment().endOf('isoWeek')),
        joinOrQuery: {
          projIds: null,
          sampleIds: null
        }
      },

      // 安排时间
      taskBeginQueryRanges: [this.moment().startOf('isoWeek'), this.moment().endOf('isoWeek')],
      // 检测员
      userList: [],

      searchValue: '',
      // 批次树
      batchTreeDataList: [],
      batchTreeProps: {
        database: [],
        expandedKeys: [],
        selectedKeys: [],
        autoExpandParent: true,

        action: {
          select: (key, item) => {
            this.queryParam.batchGroupId = null
            const { selected } = item
            if (selected) {
              this.queryParam.batchGroupId = key[0]
              this.batchTreeProps.selectedKeys.splice(0, this.batchTreeProps.selectedKeys.length, ...key)
            } else {
              this.batchTreeProps.selectedKeys.splice(0, this.batchTreeProps.selectedKeys.length)
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            this.batchTreeProps.expandedKeys.splice(0, this.batchTreeProps.expandedKeys.length, ...(expandedKeys || []))
            this.itemTreeProps.autoExpandParent = false
          },

          clear: () => {
            this.batchTreeProps.selectedKeys.splice(0, this.batchTreeProps.selectedKeys.length)
            this.batchTreeProps.expandedKeys.splice(0, this.batchTreeProps.expandedKeys.length)
          }
        }
      },

      // 因子树
      itemTreeDataList: [],
      // 暂存
      allItemTreeData:[],
      itemTreeProps: {
        treeData: [],
        checkable: false,
        expandedKeys: [],
        selectedKeys: [],
        // checkedKeys: [],
        autoExpandParent: true,

        action: {
          // check: (checkedKeys, item) => {
          //   console.log(checkedKeys, item);
          //   this.itemTreeProps.checkedKeys.splice(0, this.itemTreeProps.checkedKeys.length, ...checkedKeys)
          //   this.queryParam.cateAndItemStrs.splice(0, this.queryParam.cateAndItemStrs.length)
          //   const { checkedNodes } = item
          //   if (checkedNodes.length) {
          //     const itemCheckedNodes = checkedNodes.filter(item => !item.data.props.dataRef.children)
          //     this.queryParam.cateAndItemStrs.splice(0, this.queryParam.cateAndItemStrs.length, ...(itemCheckedNodes.map(checkItem => checkItem.key)))
          //   }
          //   this.$nextTick(() => {
          //     this.search()
          //   })
          // },
          // 把多选改为单选
          select: (selectedKey, e) => {
            console.log(selectedKey, e)
            this.queryParam.cateAndItemStrs.splice(0, this.queryParam.cateAndItemStrs.length)
            this.itemTreeProps.selectedKeys.splice(0, this.itemTreeProps.selectedKeys.length)

            if (!e.node.dataRef.children) {
              this.queryParam.cateAndItemStrs.push(...selectedKey)
              this.itemTreeProps.selectedKeys.push(...selectedKey)
            }
            this.$nextTick(() => {
              this.search()
            })
          },

          expand: (expandedKeys) => {
            console.log(expandedKeys)
            this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length, ...(expandedKeys || []))
            this.itemTreeProps.autoExpandParent = false
          },

          clear: () => {
            // this.itemTreeProps.checkedKeys.splice(0, this.itemTreeProps.checkedKeys.length)
            this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length)
            this.itemTreeProps.selectedKeys.splice(0, this.itemTreeProps.selectedKeys.length)
          }
        }
      },

      // 检测批次组检测
      checkTaskScheduleModal: Object.assign({}, fullModalData, {
        visible: false,
        title: '检测准备',
        showTag: false,
        mask: false,
        destroyOnClose: false,
        add: () => {
          const { selectedRows: enableEnterRows } = this.rowSelection
          if (!enableEnterRows || !enableEnterRows.length) {
            this.$message.error(`还未选择任务,请先选择任务后再试!`)
            return false
          }
          const userSelectRows = enableEnterRows.filter((item) => item.checkUser === this.loginUser.username)
          if (!userSelectRows.length) {
            this.$message.error('未找到您的检测任务！')
            return false
          }
          const selectedRows = userSelectRows.filter((item) => !labQcTypeEnum.includes(item.sampleType))
          if (!selectedRows.length) {
            this.$message.error('未找到您需要检测的样品，请选择原样样品！')
            return false
          }

          this.checkTaskScheduleModal.data.tasks.splice(0, this.checkTaskScheduleModal.data.tasks.length, ...selectedRows)
          const batchGroups = groupBy(
            selectedRows.filter((item) => !!item.batchGroupId),
            'batchGroupId'
          )
          if (batchGroups && Object.keys(batchGroups).length > 1) {
            this.$message.error('当前选择的任务包含多个批次，请重新选择后再试！')
            return false
          }

          // 选择的任务中包含已存在的批次，此时视为编辑
          if (batchGroups && Object.keys(batchGroups).length === 1) {
            const batchGroupId = parseInt(Object.keys(batchGroups)[0])
            this.checkTaskScheduleModal.edit(batchGroupId)
            return true
          }

          this.checkTaskScheduleModal.visible = true
          this.checkTaskScheduleModal.showTag = false
        },

        edit: (batchGroupId) => {
          this.checkTaskScheduleModal.data.batchGroupId = batchGroupId
          this.checkTaskScheduleModal.visible = true
          this.checkTaskScheduleModal.showTag = false
        },

        action: {
          cancel: () => {
            this.checkTaskScheduleModal.data.tasks.splice(0, this.checkTaskScheduleModal.data.tasks.length)
            this.checkTaskScheduleModal.data.batchGroupId = null
            this.checkTaskScheduleModal.visible = false
            this.clearSelect()
            this.reload()
          },

          ok: () => {
            this.$refs[this.checkTaskScheduleModal.data.ref].submit()
          },
          min: () => {
            this.checkTaskScheduleModal.visible = false
            this.checkTaskScheduleModal.showTag = true
          },
          max: () => {
            this.checkTaskScheduleModal.visible = true
            this.checkTaskScheduleModal.showTag = false
          }
        },

        data: {
          tasks: [],
          batchGroupId: null,
          ref: 'checkTaskSchedule'
        },

        dataAction: {
          cancel: () => {
            this.checkTaskScheduleModal.action.cancel()
          },

          success: () => {
            this.checkTaskScheduleModal.action.cancel()
          }
        }
      }),

      // 检测批次明细
      checkTaskScheduleDetailDrawer: {
        visible: false,
        width: 1080,
        closable: false,
        bodyStyle: { height: '100%' },

        data: {
          batchGroupId: null
        },

        action: {
          show: (batchGroupId) => {
            Object.assign(this.checkTaskScheduleDetailDrawer.data, { batchGroupId })
            this.checkTaskScheduleDetailDrawer.visible = true
          },
          close: () => {
            this.checkTaskScheduleDetailDrawer.visible = false
          }
        },

        dataAction: {}
      },

      componentShow: {
        outboundForm: false
      },

      // 检测日志
      logDrawer: {
        title: '操作记录',
        visible: false,
        size: 'small',
        width: 400,
        checkTaskId: null,
        show: (checkTaskId) => {
          checkTaskId && (this.logDrawer.checkTaskId = checkTaskId)
          this.logDrawer.visible = true
        },
        close: () => {
          this.logDrawer.visible = false
        }
      },

      // 快速查询
      quickSearchDrawer: {
        visible: false,
        width: 1080,
        closable: false,
        bodyStyle: { height: '100%', background: '#fff' },
        data: {},

        action: {
          show: (projId) => {
            this.quickSearchDrawer.visible = true
          },
          close: () => {
            this.quickSearchDrawer.visible = false
          }
        }
      }
    }
  },

  computed: {
    loginUser() {
      return { ...this.$store.getters.userInfo }
    },

    tableProps() {
      return Object.assign(this.customTable, { rowSelect: this.customRowSelection })
    },

    customRowSelection() {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  watch: {
    batchId: {
      immediate: true,
      handler(value) {
        this.queryParam.batchId = value
        value &&
          this.$nextTick(() => {
            this.search()
          })
      }
    },

    status: {
      immediate: true,
      handler(value) {
        this.queryParam.status = value
        value &&
          this.$nextTick(() => {
            this.search()
          })
      }
    },

    statuses: {
      immediate: true,
      handler(value) {
        this.queryParam.statuses.splice(0, this.queryParam.statuses.length, ...(value || []))
        value &&
          this.$nextTick(() => {
            this.search()
          })
      }
    },

    dataStatuses: {
      immediate: true,
      handler(value) {
        this.queryParam.dataStatuses.splice(0, this.queryParam.dataStatuses.length, ...(value || []))
        value &&
          this.$nextTick(() => {
            this.search()
          })
      }
    },

    projId: {
      immediate: true,
      handler(value) {
        this.queryParam.projId = value
        this.$nextTick(() => {
          this.search()
        })
      }
    }
  },
  created() {
    // 获取检测员
    Get(resStaffApi.list, { page: false, queryStatus: ['0', '3'], status: '0,3', preparatStatus: '0', job: 27 }).then((res) => {
      this.userList = res.data
    })
  },
  mounted() {
    this.initItemTree()
    this.handleTreeSearchChange = debounce(this.handleTreeSearchChange, 500, { leading: false, trailing: true })
  },

  methods: {
    // 计算可以显示的最大高度
    getMaxHeight() {
      return document.documentElement.clientHeight - 400 + 'px'
    },

    // 初始化树
    initItemTree() {
      let queryParam = { ...this.$attrs }
      delete queryParam.queryType
      checkTaskApi.checkTaskItemTree(queryParam).then((res) => {
        if (res.code === 0) {
          this.fillTreeData(res.data['item'])
          const cateTop = { key: '0', title: '检测因子', selectable: false, children: res.data['item'] || [], scopedSlots: { title: 'title' } }
          this.itemTreeProps.treeData.splice(0, this.itemTreeProps.treeData.length, ...[cateTop])
          this.allItemTreeData=cloneDeep(this.itemTreeProps.treeData)
          this.itemTreeProps.action.expand(['0'])
          this.itemTreeDataList.splice(0, this.itemTreeDataList.length, ...this.getTreeDataTiled([cateTop]))
          const batchTop = { key: '0', title: '批次', selectable: false, children: res.data['batch'] || [] }
          this.batchTreeProps.database.splice(0, this.batchTreeProps.database.length, ...[batchTop])
          this.batchTreeDataList.splice(0, this.batchTreeDataList.length, ...this.getTreeDataTiled([batchTop]))
          if (res.data['item'] && res.data['item'][0] && res.data['item'][0].children && res.data['item'][0].children[0]) {
            this.queryParam.cateAndItemStrs.splice(0, this.queryParam.cateAndItemStrs.length, res.data['item'][0].children[0].key)
            this.itemTreeProps.selectedKeys.splice(0, this.itemTreeProps.selectedKeys.length, res.data['item'][0].children[0].key)
            this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length, '0', res.data['item'][0].key)
            // this.itemTreeProps.action.expand(['0', res.data['item'][0].key])
            this.search()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 树查询
    handleTreeSearchChange(e) {
      const value = e.target.value
      this.searchValue = value
      if (value) {
        let data = this.getItemTreeKey(value,this.allItemTreeData)
        if (data&& data.length>0) {
          this.itemTreeProps.treeData.splice(0,this.itemTreeProps.treeData.length,...data)
        } else {
          let cateTop=[{ key: '0', title: '检测因子', selectable: false, children: [], scopedSlots: { title: 'title' } }]
          this.itemTreeProps.treeData.splice(0,this.itemTreeProps.treeData.length,...[cateTop])
        }
        const itemExpandedKeys = this.itemTreeDataList
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return this.getParentKey(item.key, this.itemTreeProps.treeData)
            }
            return null
          })
          .filter((item, i, self) => item && self.indexOf(item) === i)
        this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length, ...(itemExpandedKeys || []))
        this.itemTreeProps.autoExpandParent = true

        const batchExpandedKeys = this.batchTreeDataList
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return this.getParentKey(item.key, this.batchTreeProps.database)
            }
            return null
          })
          .filter((item, i, self) => item && self.indexOf(item) === i)
        this.batchTreeProps.expandedKeys.splice(0, this.batchTreeProps.expandedKeys.length, ...(batchExpandedKeys || []))
        this.batchTreeProps.autoExpandParent = true
      } else {
        this.itemTreeProps.treeData.splice(0,this.itemTreeProps.treeData.length,...this.allItemTreeData)
        this.itemTreeProps.expandedKeys.splice(0, this.itemTreeProps.expandedKeys.length)
        this.itemTreeProps.action.expand(['0'])
        this.itemTreeProps.autoExpandParent = false
        this.batchTreeProps.expandedKeys.splice(0, this.batchTreeProps.expandedKeys.length)
        this.batchTreeProps.autoExpandParent = false
      }
    },
    getItemTreeKey(value, tree) {
      let arr=[]
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.children) {
          let childrenArr = this.getItemTreeKey(value, node.children)
          if (childrenArr&&childrenArr.length>0) {
            let item=cloneDeep(node)
            item.children=childrenArr
            arr.push(item)
          }
          // if (node.children.some((item) => item.title.indexOf(value) > -1)) {
          //   parentKey = node.key
          // } else if (this.getItemTreeKey(value, node.children)) {
          //   parentKey = this.getItemTreeKey(value, node.children)
          // }
        }else{
          if (node.title.indexOf(value) > -1) {
            let item=cloneDeep(node)
            arr.push(item)
          }
        }
      }
      return arr
    },
    getParentKey(key, tree) {
      let parentKey
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children)
          }
        }
      }
      return parentKey
    },
    // 填充树结构数据
    fillTreeData(treeData) {
      return treeData?.forEach((item) => {
        Object.assign(item, { scopedSlots: { title: 'title' } })
        if (item.children) {
          this.fillTreeData(item.children)
        }
      })
    },

    // 树结构平铺list
    getTreeDataTiled(treeData) {
      return treeData?.reduce((acc, item) => {
        acc.push(item)
        if (item.children) {
          acc.push.apply(acc, this.getTreeDataTiled(item.children))
        }
        return acc
      }, [])
    },

    // 处理检测员改变事件
    handleCheckUserChange(userList) {
      const res = userList.map((item) => item.username)
      this.queryParam.checkUsers.splice(0, this.queryParam.checkUsers.length, ...res)
    },

    // 重置树
    resetTree() {
      this.batchTreeProps.action.clear()
      this.itemTreeProps.action.clear()
      this.initItemTree()
    },

    // 重置查询
    resetForm() {
      this.resetTree()
      this.reset()
    },

    // 查找相同批次的任务
    searchSameBatch(event, batchGroupId) {
      event.stopPropagation()
      const defaultParam = this.$options.data.bind(this)().queryParam
      Object.assign(this.queryParam, defaultParam)
      this.queryParam.batchGroupId = batchGroupId
      this.$nextTick(() => {
        this.search()
      })
    },

    // 处理批量作废
    handleBatchDelete({ data = null }) {
      if (!this.rowSelection.selectedRows.length) {
        this.$message.error('请先选择一条数据')
        return false
      }
      const loginUserName = this.loginUser.username
      const checkRows = this.rowSelection.selectedRows.filter((record) => {
        return (
          ((![sampleInfoTypeEnum.normal, sampleInfoTypeEnum.password].includes(record.sampleType) && record.status !== checkTaskStatusEnum.completed) ||
            ['-1', '2'].includes(record.projStatus)) &&
          record.checkUser === loginUserName
        )
      })
      this.rowSelection.selectedRowKeys = checkRows.map((item) => item.id)
      if (!checkRows.length) {
        this.$message.error('暂无符合条件的样品需要删除！')
        return false
      }
      this.handleDeleteTask(checkRows)
    },

    // 作废检测任务
    handleDeleteTask(records) {
      this.$confirm({
        title: '确定要作废该检测任务吗?',
        content: '作废后，对应的检测数据会丢失，请谨慎操作！',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const ids = records.map((item) => ({ id: item.id }))
          checkTaskApi.batchDelete(ids).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 开始任务
    start(record) {
      this.$confirm({
        title: '您确认要开始进行该检测任务吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          checkTaskApi.start({ id: record.id }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.search()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 批量开始
    batchStart() {
      const enableStartRow = this.rowSelection.selectedRows.filter((item) => item.status === checkTaskStatusEnum.scheduled)
      if (!enableStartRow.length) {
        this.$message.error('暂无需要开始的任务！')
        return false
      }

      const selectedRows = enableStartRow.filter((item) => item.checkUser === this.loginUser.username)
      if (!selectedRows.length) {
        this.$message.error('未找到您的检测任务！')
        return false
      }
      this.$confirm({
        title: '确认批量开始吗？',
        onOk: () => {
          checkTaskApi
            .batchStart(
              selectedRows.map((item) => {
                return { id: item.id }
              })
            )
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.clearSelect()
                this.reset()
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      })
    },

    // 完成任务
    done(record) {
      let content = '您确定要完成该任务吗?'
      if (record.dataStatus === '0') {
        content = '您还没有录入过数据，确定要继续吗？'
      }
      this.$confirm({
        title: '操作确认',
        content: content,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          checkTaskApi.done({ id: record.id }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.search()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 申请领样
    applyOutbound(sampleId) {
      this.$confirm({
        title: '您确认要申请领样吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          return tsSampleinfoApi.applyOutbound([{ id: sampleId }]).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.search()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 样品出库
    outboundSample({ data = null }) {
      let result = null
      if (!this.rowSelection.selectedRows.length) {
        this.$message.error('请先选择一条数据')
        return false
      }
      const checkRows = this.rowSelection.selectedRows.filter((item) => item.sampleStatus === sampleInfoStatusEnum.pickupApply)
      this.rowSelection.selectedRowKeys = checkRows.map((item) => item.id)
      if (!checkRows.length) {
        this.$message.error('暂无符合条件的样品需要出库')
        return false
      }
      const sampleInfos = uniqBy(checkRows, 'sampleId')
      result = sampleInfos.map((item) => {
        return { id: item.sampleId, pickupApplyUser: item.pickupApplyUser }
      })

      this.componentShow.outboundForm = true
      this.$nextTick(() => {
        this.$refs.outboundForm.init(result)
      })
    },

    // 查看原始记录表信息
    showOriginalRecord(record) {
      const { recordId: id, batchId } = record
      checkOriginalRecordApi.findDetail(id).then((res) => {
        if (res.code === 0) {
          const data = res.data
          const url = `${fileTemplateUreportApi.preview}?_u=${data.fileTemplateName}&id=${id}&templateId=${data.fileTemplateId}&batchId=${batchId}`
          window.open(url, '_blank')
        } else {
          this.$message.error('暂未查询到检测原始表')
        }
      })
    },

    // 查询检测批次信息
    findBatchInfoById(id) {
      return checkBatchApi.findBydId(id).then((res) => {
        if (res.code !== 0) {
          this.$message.error(`获取检测批次信息错误：${res.msg}`)
        }
        return res
      })
    },

    // 根据批次号录入原始记录表
    enterSampleRecordByBatch(event, item) {
      // 阻止冒泡
      event && event.stopPropagation()

      const { key, checkUser, checkUserName } = item
      const loginUser = this.loginUser.username
      if (checkUser !== loginUser) {
        this.$message.error(`该批次由${checkUserName} 进行检测，你选择你自己的任务！`)
        return false
      }
      this.checkTaskScheduleModal.edit(key)
    },

    handleQuerySearch(data) {
      this.resetQueryParam()
      this.queryParam.joinOrQuery.projIds = data.projIds.join(',')
      this.queryParam.joinOrQuery.sampleIds = data.sampleIds.join(',')
      this.$nextTick(() => {
        this.search()
      })
    },

    // 样品查询时间
    handleSampleTimeChange(value) {
      if (value != null) {
        this.queryParam.sampleBeginTime = this.timeFormat(value[0])
        this.queryParam.sampleEndTime = this.timeFormat(value[1])
      }
    },

    // 检测任务安排时间
    handleCheckOrderTimeChange(value) {
      if (value != null) {
        this.queryParam.taskBeginQueryBeginTime = this.timeFormat(value[0])
        this.queryParam.taskBeginQueryEndTime = this.timeFormat(value[1])
      }
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.xx-list {
}
</style>
