import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 样品申请单状态 字典枚举
export const sampleInfoStorageApplyStatusEnum = {

  // 已提交
  pending: '0',
  // 部分入库
  part: '1',
  // 已入库
  deal: '2',
  // 退回
  back: '3'
}

//  样品申请单状态 选择项
export const sampleInfoStorageApplyStatusOptions = [
  { label: '已提交', value: sampleInfoStorageApplyStatusEnum.pending, color: 'purple' },
  { label: '部分入库', value: sampleInfoStorageApplyStatusEnum.part, color: 'blue' },
  { label: '已入库', value: sampleInfoStorageApplyStatusEnum.deal, color: 'green' },
  { label: '退回', value: sampleInfoStorageApplyStatusEnum.back, color: 'red' }
]

export const sampleInfoStorageApplyStatusMap = tranOptionsToMap(sampleInfoStorageApplyStatusOptions)
export const sampleInfoStorageApplyStatusDataMap = tranOptionsToDataMap(sampleInfoStorageApplyStatusOptions)
