<template>
  <div>
    <template v-if="projId">
      <a-table v-bind="table">
        <template slot="xh" slot-scope="v, r, idx">
          {{ idx+1 }}
        </template>
        <template slot="recptPlanType" slot-scope="v, r, idx">
            <span v-if="v=='1'">项目阶段</span>
            <span v-else-if="v=='2'">截止时间</span>
            <span v-else>{{ v }}</span>
        </template>
        <template slot="recptPlanDate" slot-scope="v, r, idx">
          <template v-if="r.recptPlanType=='1'">
            {{ projProgressMap[r.projProgress] }}
          </template>
          <template v-else>
            {{ v }}
          </template>
        </template>
        <template slot="recptPlanAmount" slot-scope="v, r, idx">
          <div class="heading">¥ {{ v.toFixed(2) }}</div>
        </template>
      </a-table>
    </template>
  </div>
</template>

<script>

import { projReceiptPlanApi } from '@/api/project'

export default {

  name: 'ProjectReceiptPlan',
  components: { },
  props: {
    projId: {
      type: String,
      required: true,
      default: null
    }
  },

  data () {
    return {
      table: {
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '序号', dataIndex: 'xh', width: '80px', scopedSlots: { customRender: 'xh' } },
          { title: '计划类型', dataIndex: 'recptPlanType', width: '130px', scopedSlots: { customRender: 'recptPlanType' } },
          { title: '收款日期', dataIndex: 'recptPlanDate', width: '130px', scopedSlots: { customRender: 'recptPlanDate' } },
          { title: '收款金额(元)', dataIndex: 'recptPlanAmount', width: '260px', scopedSlots: { customRender: 'recptPlanAmount' } },
          { title: '备注', dataIndex: 'recptPlanRemark', scopedSlots: { customRender: 'recptPlanRemark' } }
        ],
        pagination: false
      }
    }
  },
  methods: {
    listPlanByProjId () {
      var projId = this.projId
      if (projId) {
        projReceiptPlanApi.listPlanByProjId({ projId: projId }).then(result => {
          if (result.code === 0) {
            this.table.dataSource = result.data
          }
        })
      } else {
        this.table.dataSource = []
      }
    }
  },
  watch: {
    projId: {
      immediate: true,
      handler (nv, ov) {
        this.listPlanByProjId()
      }
    }
  },
  computed: {
    projProgressMap () {
      return { ...this.$store.state.code.cache.proj_progress }
    }
  },
  mounted () {
    this.$store.dispatch('code/select', 'proj_progress')
  }
}
</script>

<style scoped>

</style>
