import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 制样申请状态 字典枚举
export const fileTemplateUreportTypeEnum = {
  // 采样模板
  sample: '0',
  // 分析模板
  checked: '1',
  // 标液模板
  solution: '2',
  // 统计模板
  stat: '3',
  // 报告模板
  report: '4',
  // 曲线模板
  standardCurveLine: '5',
  // 方案报价模板
  quote: '7',
  // 合同评审模板
  contractReview: '8',
  // 分包评审模板
  subcontractReview: '9',
  // 项目下单表模板
  projectTask: '10',
  // 样品流转单模板
  sampleFlow: '11',
  // 样品交接单模板
  sampleHandover: '12',
  // 合同模板
  contract: '13',
  // 考勤打卡模板
  attendance: '14',
  // 项目一览表模板
  projectList: '15',
  // 采样明细明细表模板
  sampleScheduleDetail: '16',
  // 标液标定模板
  demarcate: '17',
  // 检测室统计
  checkTaskStat: '18',
  // 其他
  other: '99'
}

//  制样申请状态 选择项
export const fileTemplateUreportTypeOptions = [
  { label: '采样模板', value: fileTemplateUreportTypeEnum.sample, color: 'blue' },
  { label: '分析模板', value: fileTemplateUreportTypeEnum.checked, color: 'green' },
  { label: '标液模板', value: fileTemplateUreportTypeEnum.solution, color: 'yellow' },
  { label: '统计模板', value: fileTemplateUreportTypeEnum.stat, color: 'orange' },
  { label: '报告模板', value: fileTemplateUreportTypeEnum.report, color: 'purple' },
  { label: '标准曲线', value: fileTemplateUreportTypeEnum.standardCurveLine, color: 'red' },
  { label: '方案模板', value: fileTemplateUreportTypeEnum.quote, color: 'blue' },
  { label: '合同评审', value: fileTemplateUreportTypeEnum.contractReview, color: 'green' },
  { label: '分包评审', value: fileTemplateUreportTypeEnum.subcontractReview, color: 'yellow' },
  { label: '项目下单', value: fileTemplateUreportTypeEnum.projectTask, color: 'orange' },
  { label: '样品流转', value: fileTemplateUreportTypeEnum.sampleFlow, color: 'purple' },
  { label: '样品交接', value: fileTemplateUreportTypeEnum.sampleHandover, color: 'red' },
  { label: '合同模板', value: fileTemplateUreportTypeEnum.contract, color: 'blue' },
  { label: '考勤模板', value: fileTemplateUreportTypeEnum.attendance, color: 'green' },
  { label: '项目一览表', value: fileTemplateUreportTypeEnum.projectList, color: 'green' },
  { label: '采样安排明细表', value: fileTemplateUreportTypeEnum.sampleScheduleDetail, color: 'blue' },
  { label: '标定模板', value: fileTemplateUreportTypeEnum.demarcate, color: 'orange' },
  { label: '检测室统计', value: fileTemplateUreportTypeEnum.checkTaskStat, color: 'blue' },
  { label: '其他模板', value: fileTemplateUreportTypeEnum.other, color: 'yellow' }
]

export const fileTemplateUreportTypeMap = tranOptionsToMap(fileTemplateUreportTypeOptions)
export const fileTemplateUreportTypeDataMap = tranOptionsToDataMap(fileTemplateUreportTypeOptions)

export const fileTemplateReportTypeEnum = {
  // 其他
  other: '1',
  // 检测结果
  checkResult: '2',
  // 头部
  header: '3'
}
// 报告文件模板类型
export const fileTemplateReportTypeOptions = [
  { label: '其他', value: fileTemplateReportTypeEnum.other, color: 'blue' },
  { label: '检测结果', value: fileTemplateReportTypeEnum.checkResult, color: 'green' },
  { label: '首页', value: fileTemplateReportTypeEnum.header, color: 'orange' }
]

export const fileTemplateReportTypeMap = tranOptionsToMap(fileTemplateReportTypeOptions)
export const fileTemplateReportTypeDataMap = tranOptionsToDataMap(fileTemplateReportTypeOptions)

export const templateSiteEnum = {
  // 聚合
  aggregation: '1',
  // 不聚合
  separate: '2'
}

//  点位聚合选择项
export const templateSiteOptions = [
  { label: '聚合', value: templateSiteEnum.aggregation, color: 'blue' },
  { label: '不聚合', value: templateSiteEnum.separate, color: 'blue' }
]

export const itemDirectionEnum = {
  // 竖向
  portrait: '1',
  // 横向
  landscape: '2'
}

// 因子排列选择项
export const itemDirectionOptions = [
  { label: '竖向', value: itemDirectionEnum.portrait, color: 'blue' },
  { label: '横向', value: itemDirectionEnum.landscape, color: 'blue' }
]

// export const commonValidDateUnitMap = tranOptionsToMap(commonValidDateUnitOptions)
// export const commonValidDateUnitDataMap = tranOptionsToDataMap(commonValidDateUnitOptions)
