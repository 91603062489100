'use strict'

const defaultColumn = [
  { title: '批次号', dataIndex: 'batchNo', width: 130, sorter: true, align: 'left', scopedSlots: { customRender: 'batchNo' } },
  { title: '检测项', dataIndex: 'itemName', width: 180, align: 'left', sorter: true, scopedSlots: { customRender: 'itemName' } },
  { title: '检测员', dataIndex: 'jcName', width: 100, align: 'left', sorter: true, scopedSlots: { customRender: 'jcName' } },
  { title: '批次时间', dataIndex: 'batchTime', width: 130, sorter: true, align: 'left', scopedSlots: { customRender: 'batchTime' } },
  { title: '更新时间', dataIndex: 'makeBeginTime', width: 130, sorter: true, align: 'left', scopedSlots: { customRender: 'makeBeginTime' } },
  { title: '项目数', dataIndex: 'projInfo', width: 90, align: 'center', scopedSlots: { customRender: 'projInfo' } },
  { title: '任务进度', dataIndex: 'taskStat', width: 180, align: 'center', scopedSlots: { customRender: 'taskStat' } },
  { title: '准备工作', dataIndex: 'batchId', width: 100, align: 'left', scopedSlots: { customRender: 'batchId' } },
  { title: '数据录入', dataIndex: 'dataStatus', width: 130, align: 'left', scopedSlots: { customRender: 'dataStatus' } },
  { title: '有效性判定', dataIndex: 'verifyPass', width: 120, align: 'left', scopedSlots: { customRender: 'verifyPass' } },
  { title: '复核', dataIndex: 'reviewStatus', width: 80, sorter: true, align: 'left', scopedSlots: { customRender: 'reviewStatus' } },
  { title: '审核', dataIndex: 'checkStatus', width: 80, sorter: true, align: 'left', scopedSlots: { customRender: 'checkStatus' } },
  { title: '操作', dataIndex: 'action', width: 140, align: 'left', scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as checkTaskBatchInfoDefaultColumn
}
