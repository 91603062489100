<template>
  <a-modal
    title="预览项目下单表"
    width="870px"
    :destroyOnClose="true"
    :visible="visible"
    @cancel="handleCancel"
    :footer="null"
  >
    <div style="width: 750px;height: 700px; margin: 0 auto">
      <iframe :src="url" frameborder="0" style="width: 750px; height: 600px;" />
      <form id="myForm" method="get">
        <input type="hidden" id="projId" name="projId" />
        <input type="hidden" id="type" name="type" />
        <a-button
          v-action:proj-task:download-word
          style="width: 45%; margin-top: 10px;"
          type="primary"
          class="login-button"
          @click="downFile('word')"
        >下载项目下单表(word)
        </a-button>
        <a-button
          v-action:proj-task:download-pdf
          style="width: 45%; margin-top: 10px; float: right"
          type="primary"
          class="login-button"
          @click="downFile('pdf')"
        >下载项目下单表(pdf)
        </a-button>
      </form>
    </div>
  </a-modal>
</template>

<script>
// import { commonApi } from '@/api/quote'
import { projTaskApi } from '@/api/project'

export default {
  name: 'DownloadPreview',
  data () {
    return {
      visible: false,
      projId: 0,
      url: ''
    }
  },
  watch: {
    projId: {
      handler (newValue) {
        this.url = projTaskApi.download + '?type=html&projId=' + newValue
      },
      immediate: true
    }
  },
  methods: {
    show (projId) {
      this.projId = projId
      // this.projId = 23
      this.visible = true
    },
    downFile (type) {
      document.getElementById('myForm').action = projTaskApi.download
      document.getElementById('projId').value = this.projId
      document.getElementById('type').value = type
      document.getElementById('myForm').submit()
    },
    handleCancel () {
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>
