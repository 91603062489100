<template>
  <div :style="{width:this.width}" class="table">
    <s-table v-bind="contentTable" :scroll="scrollProp" :rowSelection="customRowSelection">
      <template #jzcnt="text,record">{{ getJzCnt(text, record) }}</template>
      <template #jdcnt="text,record">{{ getJdCnt(text, record) }}</template>

      <template slot="useStatus" slot-scope="text">
        <a-tag v-if="!text" color="#ff9600">{{ text | statusFilter }}</a-tag>
        <a-tag v-else-if="text==='1'" color="green">{{ text | statusFilter }}</a-tag>
        <a-tag v-else-if="text==='2'" color="blue">{{ text | statusFilter }}</a-tag>
        <a-tag v-else-if="text==='3'" color="purple">{{ text | statusFilter }}</a-tag>
        <a-tag v-else-if="text==='4'" color="red">{{ text | statusFilter }}</a-tag>
        <a-tag v-else-if="text==='5'" color="yellow">{{ text | statusFilter }}</a-tag>
        <a-tag v-else color="red">{{ text | statusFilter }}</a-tag>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index">
        <slot v-bind="{text:text,row:record,index:index}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import mixinList from '@/pages/common/mixins/list'
import { Get, resDevApi } from '@/api/quote'

const statusMap = {
  0: { s: 'error', text: '未维护' },
  1: { s: 'success', text: '正常使用' },
  2: { s: 'processing', text: '降级使用' },
  3: { s: 'error', text: '停用' },
  4: { s: 'default', text: '报废' },
  5: { s: 'default', text: '外借' }
}
const defaultColumn = [
  { title: '固定资产编号', dataIndex: 'fixedAssetsId', scopedSlots: { customRender: 'fixedAssetsId' } },
  { title: '设备名称', dataIndex: 'devName', scopedSlots: { customRender: 'devName' } },
  { title: '出厂编号', dataIndex: 'exitNbr', scopedSlots: { customRender: 'exitNbr' } },
  { title: '规格型号', dataIndex: 'speType', scopedSlots: { customRender: 'speType' } },
  { title: '检定记录', dataIndex: 'jdcnt', scopedSlots: { customRender: 'jdcnt' } },
  { title: '校准记录', dataIndex: 'jzcnt', scopedSlots: { customRender: 'jzcnt' } },
  { title: '设备状态', dataIndex: 'useStatus', scopedSlots: { customRender: 'useStatus' } }
]

export default {
  components: { sTable },
  mixins: [mixinList],

  props: {
    width: { type: [String, Number], required: false, default: '100%' },
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: false },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {

      scrollProp: { x: '100%', y: document.documentElement.clientHeight - 445 },
      contentTable: {
        columns: this.columns || defaultColumn,
        bordered: true,
        showPagination: this.showPagination ? 'auto' : false
      }
    }
  },

  computed: {
    queryParam () {
      return { ...this.$props }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  filters: {
    statusFilter (type) {
      return (type && statusMap[type]?.text) ?? ''
    }
  },

  methods: {
    getJdCnt (text, record) {
      if (record['cbtOrNot'] === 0) {
        return '无需检定'
      }
      if (text == null) {
        return '0条记录'
      } else {
        return text + '条记录'
      }
    },

    getJzCnt (text, record) {
      if (record['scrapOrNot'] === 0) {
        return '无需校准'
      }
      if (text == null) {
        return '0条记录'
      } else {
        return text + '条记录'
      }
    },

    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.pagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        return Get(resDevApi.list, Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }
  }

}
</script>
