const columns = [
  // { title: '编号', dataIndex: 'id', width: '60px' },
  { title: '姓名', dataIndex: 'name', width: '150px', scopedSlots: { customRender: 'name' } },
  { title: '性别', dataIndex: 'sex', width: '40px', customRender: v => v === '1' ? '女' : '男' },
  { title: '手机号码', dataIndex: 'phone', width: '90px' },
  { title: '岗位', dataIndex: 'job', width: '100px', scopedSlots: { customRender: 'jobRender' } },
  { title: '在职状态', dataIndex: 'status', width: '90px', scopedSlots: { customRender: 'statusRender' } },
  { title: '在编', dataIndex: 'preparatStatus', width: '90px', scopedSlots: { customRender: 'preparatStatusRender' } },
  { title: '岗位能力确认状态', dataIndex: 'postAbilityStatus', width: '100px', scopedSlots: { customRender: 'postAbilityStatusRender' } },
  { title: '授权状态', dataIndex: 'authorizationStatus', width: '80px', scopedSlots: { customRender: 'authorizationStatusRender' } },
  { title: '系统用户状态', dataIndex: 'userStatus', width: '140px', scopedSlots: { customRender: 'userRender' } },
  { title: '档案状态', dataIndex: 'appendixStatus', width: '100px', scopedSlots: { customRender: 'appendixStatus' } },
  { title: '操作', dataIndex: 'action', width: '250px', scopedSlots: { customRender: 'opRender' } }
]

const itemList = [
  {
 title: '工作经历',
    type: '1',
    loading: false,
    columns: [
      { title: '时间', dataIndex: 'totalTime', key: 'totalTime', width: '30%', scopedSlots: { customRender: 'totalTime' } },
      { title: '单位', dataIndex: 'company', key: 'company', width: '25%', scopedSlots: { customRender: 'company' } },
      { title: '岗位/职务', dataIndex: 'content', key: 'content', width: '25%', scopedSlots: { customRender: 'content' } },
      { title: '操作', key: 'action', scopedSlots: { customRender: 'operation' } }
    ],
    data: []
  },
  {
 title: '教育背景',
    type: '2',
    loading: false,
    columns: [
      { title: '时间', dataIndex: 'totalTime', key: 'totalTime', width: '30%', scopedSlots: { customRender: 'totalTime' } },
      { title: '学校', dataIndex: 'company', key: 'company', width: '25%', scopedSlots: { customRender: 'company' } },
      { title: '专业', dataIndex: 'content', key: 'content', width: '25%', scopedSlots: { customRender: 'content' } },
      { title: '操作', key: 'action', scopedSlots: { customRender: 'operation' } }
    ],
    data: []
  },
  {
 title: '进修情况',
    type: '3',
    loading: false,
    columns: [
      { title: '时间', dataIndex: 'totalTime', key: 'totalTime', width: '30%', scopedSlots: { customRender: 'totalTime' } },
      { title: '学校', dataIndex: 'company', key: 'company', width: '25%', scopedSlots: { customRender: 'company' } },
      { title: '专业', dataIndex: 'content', key: 'content', width: '25%', scopedSlots: { customRender: 'content' } },
      { title: '操作', key: 'action', scopedSlots: { customRender: 'operation' } }
    ],
    data: []
  },
  {
 title: '专业培训',
    type: '4',
    loading: false,
    columns: [
      { title: '时间', dataIndex: 'totalTime', key: 'totalTime', width: '30%', scopedSlots: { customRender: 'totalTime' } },
      { title: '组织方', dataIndex: 'company', key: 'company', width: '25%', scopedSlots: { customRender: 'company' } },
      { title: '培训内容', dataIndex: 'content', key: 'content', width: '25%', scopedSlots: { customRender: 'content' } },
      { title: '操作', key: 'action', scopedSlots: { customRender: 'operation' } }
    ],
    data: []
  }]

const fileColumns = [
  { title: '序号', dataIndex: 'serial', width: '10%', scopedSlots: { customRender: 'serial' } },
  { title: '附件类型', dataIndex: 'itemText', width: '30%', scopedSlots: { customRender: 'itemText' } },
  { title: '已上传附件', dataIndex: 'uploadFile', width: '60%', scopedSlots: { customRender: 'uploadFile' } }
]

const fileTypeDictCode = 'staff_file_type'

export { columns, itemList, fileColumns, fileTypeDictCode }
