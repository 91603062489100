<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;">
          确认信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ cbtInfo.applyUser }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ cbtInfo.applyDate }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel">确认人</th>
          <td colspan="2">
            <select-user-by-dept ref="devUser" :disabled="disabled" :triggerChange="true" @change="handleUserChange" rowType="radio" style="width: 50%"/>
          </td>
        </tr>
        <tr>
          <th class="tdLabel" rowspan="2">仪器设备基本信息</th>
          <td rowspan="1">仪器名称：{{ devInfo.devName }}</td>
          <td rowspan="1">规格型号：{{ devInfo.speType }}</td>
        </tr>
        <tr>
          <td rowspan="1">仪器编号：{{ devInfo.exitNbr }}</td>
          <td rowspan="1">固定资产编号：{{ devInfo.fixedAssetsId }}</td>
        </tr>
        <tr>
          <th class="tdLabel" rowspan="2">溯源证书基本信息</th>
          <td rowspan="1">溯源机构：{{ cbtInfo.cbtDepName }}</td>
          <td rowspan="1">授权证书编号：{{ cbtInfo.cbtAuthNbr }}</td>
        </tr>
        <tr>
          <td rowspan="1">溯源日期：{{ formatDate(cbtInfo.actualCbtDate) }}</td>
          <td rowspan="1">溯源证书编号：{{ cbtInfo.cbtCertNbr }}</td>
        </tr>
        <tr>
          <th class="tdLabel">检定/校准结果</th>
          <td colspan="3">
            <a-table
              ref="table"
              key="id"
              :columns="columns"
              :dataSource="resultList"
              :pagination="false"
            >
            </a-table>
          </td>
        </tr>
        <tr>
          <th class="tdLabel">结果评价及确认依据</th>
          <td colspan="2">{{ cbtInfo.conclusion }}</td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { Get, Post, resCbtApi } from '@/api/quote'

export default {
  name: 'EvalConfirmModal',
  components: {
    SelectUserByDept,
    Get,
    Post,
    resCbtApi
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },
  data () {
    return {
      disabled: false,
      cbtInfo: {},
      devInfo: {},
      resultList: [],
      data: [],
      columns: [
        { title: '检定/校准项目', dataIndex: 'cbtProj', scopedSlots: { customRender: 'cbtProj' } },
        { title: '检定/校准结果', dataIndex: 'cbtResult', scopedSlots: { customRender: 'cbtResult' } },
        { title: '使用要求', dataIndex: 'cbtUseRequire', scopedSlots: { customRender: 'cbtUseRequire' } }
      ]
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.loadData()
        }
      }
    }
  },
  methods: {
    init (record, devInfo) {
      this.cbtInfo = record
      this.devInfo = devInfo
      this.cbtInfo.applyUser = this.loginUser.username
      this.cbtInfo.applyDate = new Date().format('yyyy-MM-dd HH:mm:ss')
      if (record.cbtConfirmer) {
        Get(resCbtApi.getUserById, { id: record.cbtConfirmer }).then(res => {
          const user = {
            userId: res.data.id,
            username: res.data.username,
            name: res.data.realname
          }
          this.$refs.devUser.setUserList([user])
        })
      }
      Get(resCbtApi.getResult, { id: record.id }).then(res => {
        this.resultList = res.data
      })
    },
    loadData () {
      this.disabled = true
      Get(resCbtApi.getEvalConfirmInfo, { id: this.recordId }).then(res => {
        if (res.code === 0) {
          this.cbtInfo = res.data.cbtInfo
          this.devInfo = res.data.devInfo
          this.resultList = res.data.resultList
          this.$refs.devUser.setUserList(res.data.userList)
        }
      })
    },
    handleUserChange (userList) {
      if (userList.length > 0) {
        this.cbtInfo.cbtConfirmerName = userList[0].username
      }
    },
    formatDate (date) {
      if (date) return date.split(' ')[0]
    },
    isNullOrEmpty (item) {
      return item === undefined || item === null || item === ''
    },
    handleSubmit () {
      if (!this.cbtInfo.cbtConfirmerName) {
        this.$message.error('确认人不能为空！')
        return
      }
      if (this.isNullOrEmpty(this.cbtInfo.depName) || this.isNullOrEmpty(this.cbtInfo.cbtAuthNbr) ||
         this.isNullOrEmpty(this.cbtInfo.actualCbtDate) || this.isNullOrEmpty(this.cbtInfo.cbtCertNbr) ||
         this.resultList.length === 0) {
        const self = this
        this.$confirm({
          title: '注意',
          content: `检测到当前证书信息或检定/校准结果信息不完善，是否提交申请？`,
          okType: 'danger',
          onOk () {
            self.postSubmit()
          },
          onCancel () {
            self.$message.error('取消申请！')
          }
        })
      } else {
        this.postSubmit()
      }
    },
    postSubmit () {
      Post(resCbtApi.evalConfirm, this.cbtInfo).then(res => {
        if (res.code === 0) {
          if (this.cbtInfo.evalConfirmStatus === 0) {
            this.$message.success('仪器设备溯源结果评价及确认流程申请成功！')
          } else {
            this.$message.success('仪器设备溯源结果评价及确认流程重新提交成功！')
          }
          this.$emit('ok')
        } else {
          if (this.cbtInfo.evalConfirmStatus === 0) {
            this.$message.error('仪器设备溯源结果评价及确认流程申请失败！')
          } else {
            this.$message.error('仪器设备溯源结果评价及确认流程重新提交失败！')
          }
        }
      })
    }
  }
}
</script>