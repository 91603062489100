<template>
  <div style="height: 100%">
    <a-card v-bind="queryPramCard">
      <a-row>
        <div class="table-page-search-submitButtons">
          <div style="float: left">
            <template v-for="bt in dateBtnList.list">
              <a-button
                :key="bt.key"
                :type="dateBtnList.checked == bt.key ? 'primary':'default'"
                @click="handleDateBtnClick(bt.key)"
                style="margin-left: 10px">{{ bt.text }}</a-button>
            </template>
            <a-range-picker
              :format="dateFormatStr"
              @change="handleDateChange"
              style="margin-left: 10px;margin-right: 10px"
              v-model="dateRange"/>
          </div>

          <template v-if="type === '1'">
            <select-user-by-dept
              :disabled="false"
              :triggerChange="true"
              @change="handleUserChange"
              style="width: 300px;float: left"/>

            <a-input v-model.trim.lazy="queryParam.projNoStr" placeholder="任务编号模糊查询" style="width: 200px;margin-left: 10px" />

            <a-select v-model="queryParam.status" style="width: 120px; margin-left: 10px">
              <a-select-option value="0">待开始</a-select-option>
              <a-select-option value="1">进行中</a-select-option>
              <a-select-option value="2">已完成</a-select-option>
            </a-select>
          </template>

          <span class="table-page-search-submitButtons" style="margin-left: 20px;">
            <a-button-group>
              <a-button type="primary" icon="search" @click="handleClick">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
          </span>
        </div>
      </a-row>
    </a-card>

    <a-card class="ureport-card" type="inner">
      <a-tabs :activeKey="type" @change="showMine">
        <a-tab-pane key="1"><span slot="tab">安排明细一览表</span></a-tab-pane>
        <a-tab-pane key="2"><span slot="tab">项目完成情况</span></a-tab-pane>
      </a-tabs>
      <iframe :src="ureportUrl" class="iframe" :style="iframeStyle"/>
    </a-card>
    <!--    <ureport-view v-bind="taskDetailData"/>-->
  </div>
</template>

<script>
import date from '@/pages/common/mixins/date'
import { UreportView } from '@/pages/components'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import SelectUserByDept from '@/pages/common/SelectUserByDept'

const ureportMap = {
  1: 'DB:项目实验室人员任务明细.ureport.xml',
  2: 'DB:项目实验室完成情况.ureport.xml'
}
export default {
  name: 'Index',
  components: { UreportView, SelectUserByDept },
  mixins: [date],
  props: {
    taskDetailData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      // 自定义时间范围
      dateRange: [],

      // 查询时间按钮集合
      dateBtnList: {
        checked: 'W',
        list: [{
          key: 'W',
          text: '本周'
        }, {
          key: 'M',
          text: '本月'
        }, {
          key: 'Q',
          text: '本季'
        }, {
          key: 'Y',
          text: '本年'
        }]
      },
      queryParam: {
        beginDate: null,
        endDate: null,
        userNames: null,
        status: '0'
      },
      departTree: [],
      selectedKeys: [],
      defaultExpandedKeys: [],

      type: '1',

      queryPramCard: {
        title: '',
        style: { marginBottom: '16px' }
      },
      ureportUrl: '',
      iframeStyle: {}
    }
  },
  mounted () {
    this.$nextTick(() => { // 页面渲染完成后的回调I
      this.iframeStyle = { height: document.body.clientHeight - 280 + 'px', width: '100%', border: 'unset' }
      // 定义iframe 所需要的事件
      // this.defineIframeFun()
    })
  },

  created () {
    this.handleDateBtnClick('W')
  },
  methods: {
    // 定义Iframe 方法
    defineIframeFun () {
      window.toProjectView = this.toProjectView
      window.toTsSample = this.toTsSample
    },

    // 跳转项目视图界面
    toProjectView (projId) {
      this.$router.push({ name: 'bo-proj-details', params: { type: 'project', id: projId } })
    },

    // 跳转到采样环节
    toTsSample (projId) {
      this.$router.push({ name: 'ts-sample', params: { projId }, props: (route) => ({ projId }) })
    },
    // 时间按钮切换事件
    handleDateBtnClick (key) {
      this.dateBtnList.checked = key
      let start, end
      if (key === 'W') {
        start = this.moment().startOf('isoWeek')
        end = this.moment().endOf('isoWeek')
      } else if (key === 'M') {
        start = this.moment().startOf('month')
        end = this.moment().endOf('month')
      } else if (key === 'Q') {
        start = this.moment().startOf('quarter')
        end = this.moment().endOf('quarter')
      } else if (key === 'Y') {
        start = this.moment().startOf('year')
        end = this.moment().endOf('year')
      }
      this.dateRange = [start, end]
      this.queryParam.beginDate = this.dateFormat(start) + ' 00:00:00'
      this.queryParam.endDate = this.dateFormat(end) + ' 23:59:59'
      this.handleClick()
    },

    handleUserChange (userList) {
      this.queryParam.userNames = userList.map(item => item.username).join(',')
    },

    handleStatusChange (r) {
      this.queryParam.status = r
    },

    showMine (type) {
      this.type = type
      this.handleClick()
    },

    // 自定义日期改变事件
    handleDateChange (moms, dateStrs) {
      this.dateBtnList.checked = null
      this.queryParam.beginDate = dateStrs[0] === '' ? null : dateStrs[0] + ' 00:00:00'
      this.queryParam.endDate = dateStrs[1] === '' ? null : dateStrs[1] + ' 00:00:00'
    },

    handleClick () {
      let paramStr = ''
      for (const item in this.queryParam) {
        if (this.queryParam[item] != null) {
          paramStr += '&' + item + '=' + this.queryParam[item]
        }
      }
      this.ureportUrl = fileTemplateUreportApi.preview + '?_u=' + ureportMap[this.type] + paramStr
    },

    reset () {
      this.queryParam = { beginDate: null, endDate: null, userNames: null, status: null }
      this.handleDateBtnClick('W')
    }
  }
}
</script>

<style lang="less" scoped>
.ureport-card {
  marginTop: 16px;
  height: calc(100% - 80px)
}
</style>
