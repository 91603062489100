import { userApi } from '@/api/system'

import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*', redirect: '/404', hidden: true
}

const muxRouter = {
  path: '/mux',
  component: () => import('@/layouts/RouteView'),
  redirect: '',
  name: 'account',
  hidden: true,
  meta: { title: '路由', icon: '', keepAlive: true },
  children: [
    {
      path: '/check-model',
      name: 'check-model-list',
      component: () => import('@/pages/cloud-quote/check-model'),
      meta: { title: '检测模型', keepAlive: true }
    },
    {
      path: '/check-approval/list',
      name: 'check-approval-list',
      component: () => import('@/pages/cloud-quote/check-approval/List'),
      meta: { title: '数据审核', keepAlive: true }
    },
    {
      path: '/check-standard-solution',
      name: 'check-standard-solution-view',
      component: () => import('@/pages/cloud-quote/check-standard-solution'),
      meta: { title: '标液配置', keepAlive: true }
    },
    {
      path: '/take-sample/projView',
      name: 'take-sample-project-view',
      component: () => import('@/pages/cloud-quote/take-sample/modules/projectView'),
      meta: { title: '采样项目视图', keepAlive: true }
    },
    {
      path: '/report/template',
      name: 'report-template-list',
      component: () => import('@/pages/cloud-quote/report/template/List'),
      meta: { title: '报告模板', keepAlive: true }
    },
    {
      path: '/check-task/subcontract-entry',
      name: 'check-task-subcontract-entry',
      component: () => import('@/pages/cloud-quote/check-task/modules/subcontractEntry'),
      meta: { title: '分包录入', keepAlive: true }
    },
    {
      path: '/check-task/projView',
      name: 'check-task-project-view',
      component: () => import('@/pages/cloud-quote/check-task/modules/projectView'),
      meta: { title: '检测项目视图', keepAlive: true }
    },
    {
      path: '/report-task/projView',
      name: 'report-task-project-view',
      component: () => import('@/pages/cloud-quote/report/task/moudles/projectView'),
      meta: { title: '报告项目视图', keepAlive: true }
    },
    {
      path: '/flow-process-detail',
      name: 'flow-process-detail',
      component: () => import(/* webpackChunkName: "flow" */ '@/pages/flow/FlowProcessDetail'),
      meta: { title: '流程视图', keepAlive: true }
    },
    {
      path: '/flow-task',
      name: 'flow-task',
      component: () => import(/* webpackChunkName: "flow" */ '@/pages/flow/FlowTask'),
      meta: { title: '待办流程', keepAlive: true }
    },
    {
      path: '/account/center',
      name: 'center',
      component: () => import(/* webpackChunkName: "account" */ '@/pages/account/center/Index'),
      meta: { title: '个人中心', keepAlive: true, permission: ['user'] }
    },
    {
      path: '/account/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/Index'),
      meta: { title: '个人设置', hideHeader: true, permission: ['user'] },
      redirect: '/account/settings/base',
      hideChildrenInMenu: true,
      children: [
        {
          path: '/account/settings/base',
          name: 'BaseSettings',
          component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/BaseSetting'),
          meta: { title: '基本设置', hidden: true, permission: ['user'] }
        },
        {
          path: '/account/settings/signature',
          name: 'SignatureSettings',
          component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/SignatureSettings'),
          meta: { title: '签章管理', hidden: true, permission: ['user'] }
        },
        {
          path: '/account/settings/security',
          name: 'SecuritySettings',
          component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/Security'),
          meta: { title: '安全设置', hidden: true, keepAlive: true, permission: ['user'] }
        },
        {
          path: '/account/settings/custom',
          name: 'CustomSettings',
          component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/Custom'),
          meta: { title: '个性化设置', hidden: true, keepAlive: true, permission: ['user'] }
        },
        {
          path: '/account/settings/binding',
          name: 'BindingSettings',
          component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/Binding'),
          meta: { title: '账户绑定', hidden: true, keepAlive: true, permission: ['user'] }
        },
        {
          path: '/account/settings/notification',
          name: 'NotificationSettings',
          component: () => import(/* webpackChunkName: "account" */ '@/pages/account/settings/Notification'),
          meta: { title: '新消息通知', hidden: true, keepAlive: true, permission: ['user'] }
        }
      ]
    },
    {
      path: '/common/proj-details',
      name: 'bo-proj-details',
      component: () => import(/* webpackChunkName: "proj" */ '@/pages/cloud-quote/proj-worktable/ProjDetailsMgr'),
      meta: { title: '项目视图', keepAlive: true }
    },
    {
      path: '/cloud-quote/map/map-location',
      name: 'proj-map-location',
      component: () => import('@/pages/cloud-quote/map/MapLocation'),
      meta: { title: '定位管理', keepAlive: true }
    },
    {
      path: '/flow/flow-start',
      name: 'flow-start',
      component: () => import('@/pages/flow/FlowStart'),
      meta: { title: '发起流程', keepAlive: true }
    },
    {
      path: '/service/proj-progress',
      name: 'proj-progress',
      component: () => import(/* webpackChunkName: "enterprise" */ '@/pages/cloud-quote/enterprise-services/project/ProjProgress'),
      meta: { title: '项目进度视图', keepAlive: true }
    },

    {
      path: '/common/item-guide',
      name: 'proj-item-guide',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/common/CheckGuide'),
      meta: { title: '项目检测指南', keepAlive: true }
    },
    {
      path: '/file/project-result',
      name: 'file-project-result',
      component: () => import(/* webpackChunkName: "file" */ '@/pages/cloud-quote/file/ProjectResult'),
      meta: { title: '项目视图页', keepAlive: true }
    },
    {
      path: '/file/project-file-list',
      name: 'project-file-list',
      component: () => import(/* webpackChunkName: "file" */ '@/pages/cloud-quote/file/ProjectFileList'),
      meta: { title: '项目文件', keepAlive: true }
    },
    {
      path: '/file/document-result',
      name: 'file-document-result',
      component: () => import(/* webpackChunkName: "file" */ '@/pages/cloud-quote/file/DocumentResult'),
      meta: { title: '文档视图页', keepAlive: true }
    },
    {
      path: '/res/res-dev-cbt',
      name: 'res-dev-cbt',
      component: () => import(/* webpackChunkName: "res" */ '@/pages/cloud-quote/res/res-cbt/cbtList'),
      meta: { title: '设备检定/校准', keepAlive: true }
    },
    {
      path: '/res/res-devrepair',
      name: 'res-dev-repair',
      component: () => import(/* webpackChunkName: "res" */ '@/pages/cloud-quote/res/res-devrepair/devrepairList'),
      meta: { title: '设备维修', keepAlive: true }
    },
    {
      path: '/res/res-materialin',
      name: 'res-material-in',
      component: () => import(/* webpackChunkName: "res" */ '@/pages/cloud-quote/res/res-materialin/materialInTable'),
      meta: { title: '物资采购入库', keepAlive: true }
    },
    {
      path: '/res/res-puruse',
      name: 'res-pur-use',
      component: () => import(/* webpackChunkName: "res" */ '@/pages/cloud-quote/res/res-puruse/puruseList'),
      meta: { title: '物资领用归还', keepAlive: true }
    },

    {
      path: '/res/res-materialout',
      name: 'res-material-out',
      component: () => import(/* webpackChunkName: "res" */ '@/pages/cloud-quote/res/res-materialout/materialOutTable'),
      meta: { title: '物资出入库', keepAlive: true }
    },
    {
      path: '/res/res-materialindgr',
      name: 'res-material-indgr',
      component: () => import('@/pages/cloud-quote/res/res-materialindgr/materialindgrList'),
      meta: { title: '危险物资入库', keepAlive: true }
    },
    {
      path: '/res/res-materialoutdgr',
      name: 'res-material-outdgr',
      component: () => import('@/pages/cloud-quote/res/res-materialoutdgr/materialoutdgrList'),
      meta: { title: '危险物资出库', keepAlive: true }
    },
    {
      path: '/res/res-stock',
      name: 'res-stock-jump',
      component: () => import('@/pages/cloud-quote/res/res-stock/stockList'),
      meta: { title: '物资库存管理', keepAlive: true }
    },
    {
      path: '/res/res-pur',
      name: 'res-pur-jump',
      component: () => import('@/pages/cloud-quote/res/res-pur/purList'),
      meta: { title: '物资采购', keepAlive: true }
    },
    {
      path: '/cloud-quote/plan-quote-mgr',
      name: 'plan-quote-mgr',
      component: () => import('@/pages/cloud-quote/bj-plan-quote/PlanQuoteMgr'),
      meta: { title: '监测方案', keepAlive: true }
    },
    {
      path: '/cloud-quote/plan-quote-view-mgr',
      name: 'plan-quote-view-mgr',
      component: () => import('@/pages/cloud-quote/bj-plan-quote/PlanQuoteViewMgr'),
      meta: { title: '方案报价信息', keepAlive: true }
    },
    {
      path: '/cloud-quote/quote-cost-mgr',
      name: 'quote-cost-mgr',
      component: () => import('@/pages/cloud-quote/bj-plan-quote/QuoteCostMgr'),
      meta: { title: '方案报价详情', keepAlive: true }
    },
    {
      path: '/cloud-quote/project-form-mgr',
      name: 'project-form-mgr',
      component: () => import('@/pages/cloud-quote/project/ProjectFormMgr'),
      meta: { title: '项目立项', keepAlive: true }
    },
    {
      path: '/cloud-quote/subtask-form-mgr',
      name: 'subtask-form-mgr',
      component: () => import('@/pages/cloud-quote/subcontract/subcontract-task/SubtaskFormMgr'),
      meta: { title: '监测分包', keepAlive: true }
    },
    {
      path: '/cloud-quote/sctr-form-mgr',
      name: 'sctr-form-mgr',
      component: () => import('@/pages/cloud-quote/subcontract/subcontractor/SctrFormMgr'),
      meta: { title: '分包机构', keepAlive: true }
    },
    {
      path: '/cloud-quote/sctr-check-mgr',
      name: 'sctr-check-mgr',
      component: () => import('@/pages/cloud-quote/subcontract/subcontractor/SctrCheckMgr'),
      meta: { title: '分包机构评审', keepAlive: true }
    },
    {
      path: '/cloud-quote/contract-form-mgr',
      name: 'contract-form-mgr',
      component: () => import('@/pages/cloud-quote/proj-contract/ContractFormMgr'),
      meta: { title: '合同评审', keepAlive: true }
    },
    {
      path: '/sys_version',
      name: 'sys-version',
      component: () => import('@/pages/common/version/modules/detail'),
      meta: { title: '版本信息', keepAlive: true }
    },
    {
      path: '/version/list',
      name: 'sys-version-list',
      component: () => import('@/pages/common/version/List'),
      meta: { title: '更新日志', keepAlive: true }
    },
    {
      path: '/sampling',
      name: 'sampling',
      component: () => import('@/pages/sampling/AchievementsConfigure'),
      meta: { title: '采样绩效配置', keepAlive: true }
    },
    {
      path: '/monitor',
      name: 'monitor',
      component: () => import('@/pages/monitor/monitor'),
      meta: { title: '监控中心', keepAlive: true }
    },
    {
      path: '/procedure-doc-list',
      name: 'procedure-doc-list',
      component: () => import(/* webpackChunkName: "file-system-manual" */ '@/pages/file-system-manual/procedure-docs/ProcedureDocList'),
      meta: { title: '程序文件流程', keepAlive: true }
    },
    {
      path: '/take-sample-stat',
      name: 'take-sample-stat',
      component: () => import('@/pages/cloud-quote/stat/take-sample'),
      meta: { title: '采样统计', keepAlive: true }
    },
    {
      path: '/stat/report-task',
      name: 'report-task-stat',
      component: () => import('@/pages/cloud-quote/stat/report-task'),
      meta: { title: '报告统计', keepAlive: true }
    },
    {
      path: '/sample-info-stat',
      name: 'sample-info-stat',
      component: () => import('@/pages/cloud-quote/stat/sample-info'),
      meta: { title: '样品统计', keepAlive: true }
    },
    {
      path: '/curve-type-detail',
      name: 'curve-type-detail',
      component: () => import('@/pages/cloud-quote/check-standard-curve-type/modules/record/curveTypeDetail'),
      meta: { title: '曲线配置明细', keepAlive: true }
    },
    {
      path: '/curve-config-detail',
      name: 'curve-config-detail',
      component: () => import('@/pages/cloud-quote/check-standard-curve-type/modules/record/curveConfigDetail'),
      meta: { title: '因子曲线配置', keepAlive: true }
    },
    {
      path: '/curve-verify-detail',
      name: 'curve-verify-detail',
      component: () => import('@/pages/cloud-quote/check-standard-curve-type/modules/record/curveVerifyDetail'),
      meta: { title: '因子曲线校准', keepAlive: true }
    },
    {
      path: '/standard-solution-detail',
      name: 'standard-solution-detail',
      component: () => import('@/pages/cloud-quote/check-standard-solution/modules/record/standardSolutionDetail'),
      meta: { title: '标液配置明细', keepAlive: true }
    },
    {
      path: '/solution-config-detail',
      name: 'solution-config-detail',
      component: () => import('@/pages/cloud-quote/check-standard-solution/modules/record/standardSolutionConfigDetail'),
      meta: { title: '因子标液配置', keepAlive: true }
    },
    {
      path: '/solution-demarcate-detail',
      name: 'solution-demarcate-detail',
      component: () => import('@/pages/cloud-quote/check-standard-solution/modules/record/solutionDemarcatesDetail'),
      meta: { title: '因子标液标定', keepAlive: true }
    },
    {
      path: '/report-file-list',
      name: 'report-file-list',
      component: () => import(/* webpackChunkName: "file-system-manual" */ '@/pages/file-template-ureport/report/ReportFileList'),
      meta: { title: '报告文件配置', keepAlive: true }
    },
    {
      path: '/proj_delay',
      name: 'proj_delay',
      component: () => import(/* webpackChunkName: "file-system-manual" */ '@/pages/cloud-quote/proj_delay/List'),
      meta: { title: '项目延期管理', keepAlive: true }
    },
    {
      path: '/cloud-quote/wage-performance',
      name: 'report-wage-performance',
      component: () => import('@/pages/cloud-quote/wage-performance'),
      meta: { title: '员工绩效统计表', keepAlive: true }
    },
    {
      path: '/cloud-quote/flow-statistics/flow-ureport',
      name: 'flow-ureport',
      component: () => import('@/pages/cloud-quote/flow-statistics/FlowUreport'),
      meta: { title: '流程统计表', keepAlive: true }
    }
  ]
}

/**
 * 获取路由菜单信息
 *
 * 1. 调用 getRouterByUser() 访问后端接口获得路由结构数组
 *    @see https://github.com/sendya/ant-design-pro-vue/blob/feature/dynamic-menu/public/dynamic-menu.json
 * 2. 调用
 * @returns {Promise<any>}
 */
export const generatorDynamicRouter = () => {
  return new Promise((resolve, reject) => {
    userApi.menu().then(res => {
      if (res.code === 0) {
        const routers = generator(res.data)
        // 插入测试地址
        if (routers[0].children) {
          routers[0].children.push(muxRouter)
        }
        routers.push(notFoundRouter)
        resolve(routers)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// function startsWith (str, prefix) {
//   return str.slice(0, prefix.length) === prefix
// }

/**
 * 格式化 后端 结构信息并递归生成层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    const currentRouter = {
      path: item.path,
      // 路由名称，建议唯一
      name: item.name || '',
      // 该路由对应页面的 组件
      // component: constantRouterComponents[item.component],
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: { title: item.meta ? item.meta.title : '', icon: item.meta ? item.meta.icon : undefined, permission: item.meta ? item.meta.permission : null },
      props: true
    }
    if (item.component) {
      switch (item.component) {
        case 'BasicLayout':
          currentRouter.component = BasicLayout
          break
        case 'RouteView':
          currentRouter.component = RouteView
          break
        case 'PageView':
          currentRouter.component = PageView
          break
        case 'BlankLayout':
          currentRouter.component = BlankLayout
          break
        default:
          currentRouter.component = () => import('@/pages/' + item.component)
      }
    }

    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    currentRouter.path = currentRouter.path.replace('//', '/')
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}
