<template>
  <div class="table-list">
    <customize-column v-model="table.columns" cache-key-pre="business-quote-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <a-table :dataSource="dataSource" v-bind="table" v-on="table">
      <template slot="projectTitle" slot-scope="v, r">
        <a-tag :color="quoteTypeVCMap[r.quoteType]?quoteTypeVCMap[r.quoteType].color:''">
          {{ quoteTypeVCMap[r.quoteType]?quoteTypeVCMap[r.quoteType].value:r.quoteType }}
        </a-tag>
        <a-tag v-if="r.quoteType!='BJ'&&r.quoteMonth" color="#2db7f5">{{ r.quoteMonth }}月</a-tag>
        <a @click="planQuoteView(r)">{{ r.projectTitle }}</a>
      </template>

      <template slot="entrustCustomerName" slot-scope="v, r">
        <template v-if="r.entrustCustomer==r.customer">
          <a-row v-if="r.entrustCustomerName">委托/受检：<span>{{ r.entrustCustomerName }}</span></a-row>
        </template>
        <template v-else>
          <a-row v-if="r.entrustCustomerName">委托：<span>{{ r.entrustCustomerName }}</span></a-row>
          <a-row v-if="r.customerName">受检：<span>{{ r.customerName }}</span></a-row>
        </template>
      </template>

      <template slot="sampleSource" slot-scope="v, r">
        {{ sampleSourceMap[v] }}
      </template>
      <template slot="reportType" slot-scope="v, r">
        {{ reportTypeMap[v] }}
      </template>
      <template slot="status" slot-scope="v, r">
        <a-tag v-if="r.status=='0'" color="orange">完善中</a-tag>
        <a-tag v-if="r.status=='6'" color="red">报价中</a-tag>
        <a-tag v-if="r.status=='1'" color="green" @click="gotoQuoteCheckFlowDetail(r)">赢单</a-tag>
        <a-tag v-if="r.status=='2'" color="pink" @click="gotoQuoteCheckFlowDetail(r)">提交审核</a-tag>
        <a-tag v-if="r.status=='3'" color="cyan" @click="gotoQuoteCheckFlowDetail(r)">方案审核</a-tag>
        <a-tag v-if="r.status=='4'" color="blue" @click="gotoQuoteCheckFlowDetail(r)">部门审核</a-tag>
        <a-tag v-if="r.status=='5'" color="purple" @click="gotoQuoteCheckFlowDetail(r)">经理审核</a-tag>
        <a-tag v-if="r.status=='-2'">已废弃</a-tag>
      </template>
      <template slot="quoteContactsName" slot-scope="v, r">
        <a @click="quoteContactsChange(r)">{{ r.quoteContactsName }}</a>
      </template>
      <template slot="quoteTaxesAmount" slot-scope="v, r">
        <a-row v-if="r.quoteTaxesAmount">税后：<span style="color: red">{{ currency(r.quoteTaxesAmount) }}</span></a-row>
        <a-row v-if="r.quoteTaxesAmount">最终：<span style="color: red">{{ currency(r.quoteFinalAmount) }}</span></a-row>
      </template>
      <template slot="action" slot-scope="v, r">
        <div class="row-button">
          <a v-if="r.status == 0" @click="() => editPlan(r)" v-action:quote:edit><a-icon type="edit" />完善</a>
          <a v-if="r.status == 6" @click="() => editQuote(r)" v-action:quote:edit><a-icon type="edit" />报价</a>
          <a-dropdown>
            <a class="ant-dropdown-link" @click.stop="(event)=>{event.preventDefault()}">更多
              <a-icon type="down"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="1" v-action:quote:preview>
                <a @click="() => copyQuote(r)"><a-icon type="copy" />复制方案</a>
              </a-menu-item>
              <!--              <a-menu-item key="2" v-action:quote:preview>-->
              <!--                <a @click="() => planDownload(r)"><a-icon type="eye" />下载方案</a>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item key="3" v-action:quote:preview>-->
              <!--                <a @click="() => quoteDownload(r)"><a-icon type="eye" />下载报价</a>-->
              <!--              </a-menu-item>-->
              <a-menu-item key="3" v-action:quote:preview>
                <a @click="() => downloadModal.show(r)"><a-icon type="eye" />下载报价</a>
              </a-menu-item>
              <a-menu-item key="4" v-if="r.status == 1 || r.status == 2 || r.status == 3 || r.status == 4 || r.status == 5 || r.status == 6" v-action:quote:back>
                <a @click="goBack(r)"><a-icon type="undo"/>退回</a>
              </a-menu-item>
              <a-menu-item key="5" v-if="r.status != 0&&r.status != -2" v-action:quote:abandon>
                <a @click="abandon(r)" v-action:quote:abandon><a-icon type="delete"/>废弃</a>
              </a-menu-item>
              <a-menu-item key="6" v-if="r.status == 0||r.status == -2" v-action:quote:del>
                <a @click="() => del(r)" v-action:quote:del><a-icon type="delete"/>作废</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </template>
    </a-table>
    <CopyQuote ref="copyQuote" @ok="search"></CopyQuote>
    <QuoteContactsChange ref="quoteContactsChange" @ok="contactsChangeOk"/>
    <PlanDownload ref="planDownload"></PlanDownload>
    <QuoteDownload ref="quoteDownload"></QuoteDownload>

    <!-- 报价下载预览 -->
    <a-modal v-bind="downloadModal" v-on="downloadModal">
      <QuoteDownloadPreview v-bind="downloadModal.data" v-on="downloadModal.data"></QuoteDownloadPreview>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="downloadModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import PlanDownload from '@/pages/cloud-quote/bj-plan-quote/modules/PlanDownload'
import QuoteDownload from '@/pages/cloud-quote/bj-plan-quote/modules/QuoteDownload'
import QuoteContactsChange from '@/pages/cloud-quote/bj-plan-quote/modules/QuoteContactsChange'
import CopyQuote from '@/pages/cloud-quote/bj-plan-quote/modules/CopyQuote'
import QuoteDownloadPreview from '@/pages/cloud-quote/bj-plan-quote/modules/QuoteDownloadPreview'
import { CustomizeColumn } from '@/components/Columns'
import { quoteApi } from '@/api/quote'
import { projectApi, projectFlowKey } from '@/api/project'
import { currency } from '@/utils/util'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'QuoteTable',
  components: {
    CopyQuote,
    PlanDownload,
    QuoteDownload,
    QuoteContactsChange,
    QuoteDownloadPreview,
    CustomizeColumn
  },
  props: {
    dataSource: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dateFormat: 'YYYY-MM-DD',
      colorArr: ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple'],
      table: {
        size: 'small',
        loading: false,
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '单号', width: '130px', dataIndex: 'quoteSn', scopedSlots: { customRender: 'quoteSn' } },
          { title: '项目名称', dataIndex: 'projectTitle', width: '300px', scopedSlots: { customRender: 'projectTitle' } },
          { title: '客户单位', dataIndex: 'entrustCustomerName', width: '200px', scopedSlots: { customRender: 'entrustCustomerName' } },
          { title: '日期', dataIndex: 'quoteDate', width: '90px', scopedSlots: { customRender: 'quoteDate' } },
          { title: '样品来源', dataIndex: 'sampleSource', width: '80px', scopedSlots: { customRender: 'sampleSource' } },
          { title: '报告类型', dataIndex: 'reportType', width: '80px', scopedSlots: { customRender: 'reportType' } },
          { title: '报价金额(元)', dataIndex: 'quoteTaxesAmount', width: '150px', scopedSlots: { customRender: 'quoteTaxesAmount' } },
          { title: '状态', dataIndex: 'status', width: '80px', scopedSlots: { customRender: 'status' } },
          { title: '负责人', dataIndex: 'quoteContactsName', width: '90px', scopedSlots: { customRender: 'quoteContactsName' } },
          { title: '创建人', dataIndex: 'createUserName', width: '90px' },
          { title: '更新日期', dataIndex: 'updateTime', width: '150px' },
          { title: '操作', dataIndex: 'action', width: '240px', scopedSlots: { customRender: 'action' } }
        ],
        pagination: false
      },

      downloadModal: Object.assign({}, fullModalData, {
        title: '报价下载',
        data: {
          params: {
            quoteId: null,
            projId: null,
            fileName: null
          }
        },
        show: (r) => {
          var params = { quoteId: r.id, projId: r.projId, fileName: `${r.quoteSn}-${r.projectTitle}` }
          this.downloadModal.data.params = params
          this.downloadModal.visible = true
        },
        cancel: () => {
          this.downloadModal.visible = false
        }
      })
    }
  },
  methods: {
    currency,
    editPlan (r) {
      this.$router.push({ name: 'plan-quote-mgr', params: { enterType: 'edit', busId: r.busId, quoteId: r.id } })
    },
    editQuote (r) {
      this.$router.push({ name: 'quote-cost-mgr', params: { enterType: 'edit', busId: r.busId, quoteId: r.id } })
    },
    planDownload (r) {
      this.$refs.planDownload.show(r.id, r.status)
    },
    quoteDownload (r) {
      this.$refs.quoteDownload.show(r.id, r.status)
    },
    quoteContactChange (r) {
      this.$refs.quoteContactChange.change(r)
    },
    planQuoteView (r) {
      this.$router.push({ name: 'plan-quote-view-mgr', query: { enterType: 'view', quoteId: r.id } })
    },
    gotoQuoteCheckFlowDetail (r) {
      projectApi.listProcess({ key: projectFlowKey.quoteCheckFlow, mxId: r.id }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            var obj = res.data[0]
            this.$router.push({ name: 'flow-process-detail', params: { processId: obj.processInstanceId } })
          } else {
            this.$message.warn(`未找到相关流程`)
          }
        }
      })
    },
    copyQuote (r) {
      this.$refs.copyQuote.copy(r)
    },
    goBack (r) {
      const self = this
      this.$confirm({
        title: '警告',
        content: `真的要退回 [${r.quoteSn}] 吗?`,
        okText: '退回',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          quoteApi.goBack({ id: r.id }).then(result => {
            if (result.code === 0) {
              self.$message.success(`操作成功`)
              self.$emit('ok', null)
            } else {
              self.$message.error(result.msg)
            }
          })
        },
        onCancel () {
        }
      })
    },
    abandon (row) {
      const self = this
      this.$confirm({
        title: '警告',
        content: `真的要废弃 [${row.quoteSn}] 吗?`,
        okText: '废弃',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          quoteApi.abandon({ id: row.id }).then(result => {
            if (result.code === 0) {
              self.$message.success(`操作成功`)
              self.$emit('ok', null)
            } else {
              self.$message.error(result.msg)
            }
          })
        },
        onCancel () {
        }
      })
    },
    del (row) {
      const self = this
      this.$confirm({
        title: '警告',
        content: `真的要作废 [${row.quoteSn}] 吗?`,
        okText: '作废',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          quoteApi.del({ ids: [row.id] }).then(result => {
            if (result.code === 0) {
              self.$message.success(`操作成功`)
              self.$emit('ok', null)
            } else {
              self.$message.error(result.msg)
            }
          })
        },
        onCancel () {
        }
      })
    },
    search () {
      this.$emit('ok', null)
    },
    contactsChangeOk () {
      this.$emit('ok', null)
    },
    formatCodeMap (map = {}) {
      var colors = ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple']
      var idx = 0
      var clen = colors.length
      var newMap = {}
      for (const k in map) {
        newMap[k] = { value: map[k], color: colors[idx % clen] }
        idx = idx + 1
      }
      return newMap
    }
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    },
    quoteTypeVCMap () {
      return this.formatCodeMap({ ...this.$store.state.code.cache.quote_type })
    }
  },
  watch: {
  },
  filters: {
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
    this.$store.dispatch('code/select', 'quote_type')
  }
}
</script>
<style lang="less">
.table-list{
  position: relative;
  .setting {
    position: absolute;
    z-index: 9;
    right: 16px;
    top: 14px;
  }
}
</style>
