<script>
export default {
  name: 'CheckTaskBatchOriginalRecord',
  inheritAttrs: false
}
</script>

<script setup>
import { ref, reactive, computed, watchEffect, watch } from 'vue'
import { fileTemplateUreportApi, ureportExportToServerApi } from '@/api/fileTemplate'
import { checkTaskBatchApi, checkTaskApi } from '@/api/check/checkTaskApi'
import { sysCommonApi } from '@/api/common'
import { message } from 'ant-design-vue'
import { toItemDetail } from '@/pages/cloud-quote/check-task/modules/enter'

const props = defineProps({
  batchId: { type: Number, required: true },
  projId: { type: Number, required: false }
})

const emit = defineEmits(['cancel'])

const curIdx = ref(0)

const templateList = ref([])

const baseForm = reactive({
  batchId: props.batchId,
  fileTemplateId: null,
  fileTemplateName: null
})

const batchInfo = reactive({
  id: props.batchId,
  checkDetailId: null,
  cateId: null,
  itemId: null,
  cateName: null,
  itemName: null,
  standardCode: null,
  standardName: null
})

const viewType = ref('recordSheet')

const viewTypeList = ref([
  { label: '记录表', value: 'recordSheet' },
  { label: '图谱', value: 'atlas' }
])

const atlasList = ref([])

const goto = toItemDetail()

const formSrc = computed(() => {
  if (baseForm.fileTemplateName) {
    let projId = props.projId
    if (templateList.value && templateList.value.length > 0) {
      var template = templateList.value[curIdx.value]
      if (template.projId || template.projId === 0) {
        projId = template.projId
      }
    }
    return `${fileTemplateUreportApi.preview}?_u=${baseForm.fileTemplateName}&batchId=${props.batchId}&projId=${projId}`
  }
  return null
})
const atlasFormSrc = computed(() => {
  if (atlasList.value && atlasList.value.length > 0) {
    if (templateList.value && templateList.value.length > 0 && curIdx.value !== 0) {
      let template = templateList.value[curIdx.value]
      let atlasItem = atlasList.value.find((item) => item.projNo === template.projNo)
      if (atlasItem && atlasItem.signAtlasFileUrl) {
        return `${sysCommonApi.viewFile}/${atlasItem.signAtlasFileUrl}`
      }
      return null
    }
    return null
  }
  return null
})

const getBatchInfo = (batchId) => {
  checkTaskBatchApi.getDetailInfoById(batchId).then((res) => {
    if (res.code === 0) {
      if (res.data) {
        const {
          templateId: fileTemplateId,
          templateFile: fileTemplateName,
          checkDetailId,
          cateId,
          itemId,
          itemName,
          cateName,
          standardCode,
          standardName,
          checkTaskBatchAtlas
        } = res.data
        Object.assign(batchInfo, { checkDetailId, cateId, itemId, itemName, cateName, standardCode, standardName })
        if (!fileTemplateName) {
          message.error('暂未配置检测原始记录表！')
          emit('cancel')
          return false
        }
        Object.assign(baseForm, { fileTemplateId, fileTemplateName })
        atlasList.value.splice(0, atlasList.value.length, ...(checkTaskBatchAtlas || []))
      } else {
        message.error(`获取批次信息出错！`)
      }
    } else {
      message.error(res.msg)
    }
  })
}

const getBatchProject = (batchId) => {
  templateList.value.splice(0, templateList.value.length, { projId: undefined, projNo: '全部批次', projName: '全部批次' })
  checkTaskApi.getProjIdByBatchId({ batchId: batchId }).then((res) => {
    if (res.code === 0) {
      if (res.data) {
        templateList.value.push(...res.data)
      } else {
        message.error(`获取批次信息出错！`)
      }
    } else {
      message.error(res.msg)
    }
  })
}

watchEffect(() => {
  if (props.batchId) {
    getBatchInfo(props.batchId)
  }
})

watch(
  () => props.batchId,
  (value) => {
    if (value) {
      getBatchProject(value)
    }
  },
  {
    immediate: true,
    deep: true
  }
)

// 下载空白记录表
const downloadBlankRecord = () => {
  if (baseForm.fileTemplateId && baseForm.fileTemplateName) {
    let projId = props.projId
    if (templateList.value && templateList.value.length > 0) {
      var template = templateList.value[curIdx.value]
      if (template.projId || template.projId === 0) {
        projId = template.projId
      }
    }
    // window.location
    let url = `${ureportExportToServerApi.downloadWordByColor}?_u=${baseForm.fileTemplateName}&batchId=${props.batchId}&projId=${projId}`
    window.open(url, '_blank')
  } else {
    message.error('暂未配置原始记录表')
  }
}

defineExpose({ downloadBlankRecord })
</script>

<template>
  <!-- class="check-original-record" -->
  <div class="utabs" style="height: 100%">
    <!-- <div class="form-div"> -->
    <a-tabs v-model="curIdx" size="small" style="height: 100%; padding: 0; margin: 0; overflow: hidden" tabPosition="left" type="line">
      <a-tab-pane :closable="false" :key="idx" :tab="obj.projNo" style="height: 100%" v-for="(obj, idx) of templateList">
        <a-tabs v-model="viewType" size="small" style="height: 100%; padding: 0; margin: 0; overflow: hidden"  type="line">
          <a-tab-pane :closable="false" :key="viewTypeItem.value" :tab="viewTypeItem.label" style="height: 100%" v-for="viewTypeItem of viewTypeList">
            <template v-if="viewType === 'recordSheet'">
              <template v-if="baseForm.fileTemplateId">
                <a-row style="height: 100%">
                  <iframe :src="formSrc" style="width: 100%; height: 100%; border: none"></iframe>
                </a-row>
              </template>
              <template v-else>
                <div style="text-align: center">
                  暂未配置原始记录表，立即前往
                  <a-button type="primary" @click="goto(batchInfo.checkDetailId)">配置</a-button>
                </div>
              </template>
            </template>
            <template v-if="viewType === 'atlas'">
              <template v-if="atlasList.length">
                <div v-if="curIdx === 0" style="text-align: center">查看图谱需要先选择项目</div>

                <a-row v-else style="height: 100%">
                  <iframe v-if="atlasFormSrc" :src="atlasFormSrc" style="width: 100%; height: 100%; border: none"></iframe>
                  <div v-else style="text-align: center">当前项目无图谱数据，请先在准备页面添加图谱数据</div>
                </a-row>
              </template>
              <template v-else>
                <div style="text-align: center">当前批次无图谱数据，请先在准备页面添加图谱数据</div>
              </template>
            </template>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
    </a-tabs>
    <!-- </div> -->
  </div>
</template>

<style lang="less" scoped>
@import '_index';

iframe {
  table {
    table-layout: auto;
  }
}
.utabs {
  .archive {
    margin: 0px 5px;
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
  }
}
</style>
