<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>

<script setup>
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { message, Select } from 'ant-design-vue'
import { computed, watchEffect, watch, onMounted, ref, nextTick } from 'vue'
import { qualityOptionDataMap } from '@/api/itemCheckDetail/itemCheckDetailConstant'
import { sampleInfoTypeEnum, sampleInfoTypeDataMap } from '@/api/sampleInfo/sampleInfoConstant'
import { markConfigApi } from '@/api/checkReagent/markConfigApi'
import { checkStandardSolutionApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'
import { transform, orderBy } from 'lodash'
import { useDate } from '@/pages/common/compositions/date/useDate'

const ASelectOption = Select.Option

const props = defineProps({
  selectCheckGuide: { required: false, type: Object, default: undefined },
  modelValue: { required: true, type: Array },
  taskInfo: { required: true, type: Object },
  sampleInfoList: { required: true, type: Array },
  checkDetailId: { type: Number, required: false },
  markConfig: { required: false, type: Object, default: undefined },
  defaultMakeDate: { type: String, required: false, default: undefined },
  qualityControlStatus: { type: Boolean, required: false, default: false },
  itemList: { required: false, type: Array, default: () => [] },
  compositeItem: { type: String, required: false, default: undefined },
  sampleInfoStatus: { type: Boolean, required: false, default: false },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue'])
const addStandardList = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const { moment } = useDate()

const createRandomNumber = (id, addStandardList) => {
  addStandardList.forEach(async (item) => {
    if (!item.addScalar && item.addScalar !== 0) {
      const res = await markConfigApi.createRandomNumber(id)
      if (res.code === 0 && res.data) {
        item.addScalar = res.data
      } else {
        item.addScalar = 0
      }
    }
  })
}

const standardSolutionList = ref([])
const allStandardSolutionList = ref([])

// 添加质控样
const addAddStandard = async () => {
  const [sampleInfo] = props.sampleInfoList
  if (!sampleInfo) {
    message.error('暂无需要检测的样品!')
    return false
  }

  const { itemId, cateId, itemName, cateName } = props.taskInfo
  const { id, checkSampleId } = sampleInfo
  let addScalar
  if (props.checkDetailId) {
    addScalar = await markConfigApi.createRandomNumber(props.checkDetailId).then((res) => {
      if (res.code === 0 && res.data) {
        return res.data
      } else {
        return 0
      }
    })
  } else {
    addScalar = 0
  }
  let reagentType = null
  let reagentTypeName = null
  let solutionList = []
  if (props.markConfig.markReagentType) {
    reagentType = props.markConfig.markReagentType
    reagentTypeName = props.markConfig.markReagentName
  }
  if (props.markConfig.markReagentType && standardSolutionList.value && standardSolutionList.value.length > 0) {
    let goodSolutionList = standardSolutionList.value.filter(
      (item) => Number(item.solutionTypeId) === Number(props.markConfig.markReagentType) && item.uncertainty && item.uncertaintyUnit
    )
    if (goodSolutionList.length > 0) {
      let obj = {
        standardSolutionId: goodSolutionList[0].id,
        standardSolutionName: goodSolutionList[0].name,
        uncertainty: goodSolutionList[0].uncertainty,
        uncertaintyUnit: goodSolutionList[0].uncertaintyUnit
      }
      solutionList.push(obj)
    }
  }
  if (props.selectCheckGuide.compositeItemMarkup === '2') {
    let compositeItems = props.compositeItem.split(',')
    for (let ai = 0; ai < compositeItems.length; ai++) {
      let csi = compositeItems[ai]
      addStandardList.value.push({
        id,
        checkSampleId,
        itemId,
        type: sampleInfoTypeEnum.addStandardSample,
        cateId,
        itemName,
        cateName,
        editable: true,
        addScalar,
        reagentType,
        reagentTypeName,
        compositeItem: csi,
        solutionList
      })
    }
  } else {
    addStandardList.value.push({
      id,
      checkSampleId,
      itemId,
      type: sampleInfoTypeEnum.addStandardSample,
      cateId,
      itemName,
      cateName,
      editable: true,
      addScalar,
      reagentType,
      reagentTypeName,
      compositeItem: props.compositeItem,
      solutionList
    })
  }
}

const firstHandle = ref(false)

const handleWatchEle = () => {
  if (
    props.firstFlag !== 1 &&
    props.qualityControlStatus &&
    props.sampleInfoStatus &&
    props.selectCheckGuide &&
    (props.selectCheckGuide.needMarkup === '1' || props.selectCheckGuide.needMarkup === '2')
  ) {
    firstHandle.value = true

    addStandardList.value = addStandardList.value.filter((item) => !item.editable)
    if (addStandardList.value.length === 0) {
      addAddStandard()
    }
  }
}

const map = { 1: '标准', 2: '企业' }
const qcRefSource = computed(() => (type) => map[type] || '')

watchEffect(() => {
  props.checkDetailId && props.modelValue && createRandomNumber(props.checkDetailId, props.modelValue)
  // props.firstFlag !== 1 && props.qualityControlStatus && props.sampleInfoStatus && props.selectCheckGuide && handleWatchEle()
})
watch(
  () => props.firstFlag,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.qualityControlStatus,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.sampleInfoStatus,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => props.selectCheckGuide,
  (value) => {
    if (firstHandle.value === false) {
      handleWatchEle()
    }
  },
  { deep: true, immediate: true }
)

const getStandardSolutionList = (reagentType) => {
  let queryParam = {
    pageNo: 1,
    pageSize: 10000,
    page: true,
    solutionTypeId: reagentType,
    compositionName: '',
    makeDateBegin: '',
    makeDateEnd: '',
    queryType: 0
  }
  checkStandardSolutionApi.list(queryParam).then((res) => {
    if (res.data && res.data.length > 0) {
      allStandardSolutionList.value = orderBy(res.data, ['updateTime', 'id'], ['desc', 'desc'])
      handleStandardSolutionList()
      setTimeout(() => {
        assignSolutionList()
      }, 2000)
      // assignSolutionList()
    } else {
      standardSolutionList.value.splice(0, standardSolutionList.value.length)
      allStandardSolutionList.value.splice(0, allStandardSolutionList.value.length)
    }
  })
}

const assignSolutionList = () => {
  if (standardSolutionList.value && standardSolutionList.value.length > 0) {
    if (addStandardList.value && addStandardList.value.length > 0) {
      let obj = {}
      let goodSolutionList = standardSolutionList.value.filter((item) => item.uncertainty && item.uncertaintyUnit)
      if (goodSolutionList.length) {
        obj = {
          standardSolutionId: goodSolutionList[0].id,
          standardSolutionName: goodSolutionList[0].name,
          uncertainty: goodSolutionList[0].uncertainty,
          uncertaintyUnit: goodSolutionList[0].uncertaintyUnit
        }
        // }else{
      }
      let SolutionIds = standardSolutionList.value.map((item) => item.id)
      addStandardList.value.forEach((item) => {
        if (item.solutionList && item.solutionList.length) {
          if (!SolutionIds.includes(item.solutionList[0].standardSolutionId)) {
            item.solutionList.splice(0, item.solutionList.length)
            if (Object.keys(obj).length) {
              item.solutionList.push(obj)
            }
          }
        } else {
          if (Object.keys(obj).length) {
            item.solutionList.push(obj)
          }
        }
      })
    } else {
      setTimeout(() => {
        assignSolutionList()
      }, 2000)
    }
  }
}
const handleSolutionChange = (index, id) => {
  const selectData = standardSolutionList.value.filter((item) => item.id === id)
  let list = selectData.map((item) => {
    return {
      standardSolutionId: item.id,
      standardSolutionName: item.name,
      uncertainty: item.uncertainty,
      uncertaintyUnit: item.uncertaintyUnit
    }
  })
  let solutionList = addStandardList.value[index].solutionList
  solutionList.splice(0, solutionList.length, ...list)
}

watch(
  () => props.markConfig,
  (value) => {
    handleCheckedSolution()
    if (value && value.markReagentType) {
      getStandardSolutionList(value.markReagentType)
    } else {
      standardSolutionList.value.splice(0, standardSolutionList.value.length)
      allStandardSolutionList.value.splice(0, allStandardSolutionList.value.length)
    }
  },
  { deep: true }
)

const handleCheckedSolution = () => {
  if (addStandardList.value && addStandardList.value.length) {
    if (props.markConfig) {
      addStandardList.value.forEach((item) => {
        if (props.markConfig.markReagentType) {
          if (!item.reagentType || item.reagentType !== props.markConfig.markReagentType) {
            item.reagentType = props.markConfig.markReagentType
            item.reagentTypeName = props.markConfig.markReagentName
            item.solutionList.splice(0, item.solutionList.length)
          }
        } else {
          item.reagentType = null
          item.reagentTypeName = null
          item.solutionList.splice(0, item.solutionList.length)
        }
      })
    }
  } else {
    setTimeout(() => {
      handleCheckedSolution()
    }, 1000)
  }
}

watch(
  () => props.defaultMakeDate,
  (value) => {
    handleStandardSolutionList()
    changeSolutionList()
  },
  { deep: true }
)
const handleStandardSolutionList = () => {
  if (allStandardSolutionList.value && allStandardSolutionList.value.length > 0 && props.defaultMakeDate) {
    let data = allStandardSolutionList.value.filter((item) => moment(item.validTime) >= moment(props.defaultMakeDate) && item.uncertainty && item.uncertaintyUnit)
    standardSolutionList.value.splice(0, standardSolutionList.value.length, ...data)
  }
}

const changeSolutionList = () => {
  addStandardList.value.forEach((item) => {
    let list = standardSolutionList.value.filter((item2) => item.standardSolutionId === item2.id)
    if (list.length === 0) {
      if (standardSolutionList.value.length > 0) {
        let obj = {
          standardSolutionId: standardSolutionList.value[0].id,
          standardSolutionName: standardSolutionList.value[0].name,
          uncertainty: standardSolutionList.value[0].uncertainty,
          uncertaintyUnit: standardSolutionList.value[0].uncertaintyUnit
        }
        item.solutionList.splice(0, item.solutionList.length, obj)
      } else {
        item.solutionList.splice(0, item.solutionList.length)
      }
    }
  })
}

const getSolutionNo = (id) => {
  let solutionList = allStandardSolutionList.value.filter((item) => item.id === Number(id))
  if (solutionList.length > 0) {
    return solutionList[0].solutionNo
  }
  return ''
}

const getItemName = (itemId) => {
  // console.log(itemId, props.itemList)
  let items = props.itemList.filter((item) => item.id === Number(itemId))
  console.log(items)
  if (items.length > 0) {
    return items[0].name
  }
  return ''
}
</script>

<template>
  <ul-table :data-source="addStandardList" title="加标回收">
    <template #extra v-if="props.firstFlag !== 1">
      <template v-if="selectCheckGuide">
        <a-tooltip :getPopupContainer="() => $el">
          <template #title>
            数量： ≥ <span class="green">{{ selectCheckGuide.needMarkupNum }}</span>
            <template v-if="selectCheckGuide.needMarkupNumType !== 'fix'"> %</template>
            <br />
            参考：
            <a-tag>{{ qcRefSource(selectCheckGuide.needMarkupRefSource) }}</a-tag>
          </template>
          <a-tag :color="qualityOptionDataMap[selectCheckGuide.needMarkup].color">{{ qualityOptionDataMap[selectCheckGuide.needMarkup].label }}</a-tag>
        </a-tooltip>
      </template>
      <a @click="addAddStandard">
        <a-icon type="plus"></a-icon>
      </a>
    </template>

    <template #default="{ data: sampleInfo, index }">
      <template v-if="addStandardList.length">
        <ul-table-column :title="sampleInfo.editable ? '加标' : '编号'">
          <template v-if="sampleInfo.editable">
            <a-select v-model="sampleInfo.id">
              <a-select-option v-for="item in sampleInfoList" :key="item.id">{{ item.checkSampleId || item.id }}</a-select-option>
            </a-select>
          </template>
          <template v-else>{{ sampleInfo.checkSampleId || sampleInfo.id }}</template>
        </ul-table-column>

        <ul-table-column title="加标类型">
          <a-select v-model="sampleInfo.type" :disabled="!sampleInfo.editable">
            <a-select-option :key="sampleInfoTypeDataMap[sampleInfoTypeEnum.addStandardSample].value">{{
              sampleInfoTypeDataMap[sampleInfoTypeEnum.addStandardSample].label
            }}</a-select-option>
            <a-select-option :key="sampleInfoTypeDataMap[sampleInfoTypeEnum.basisAddStandardSample].value">{{
              sampleInfoTypeDataMap[sampleInfoTypeEnum.basisAddStandardSample].label
            }}</a-select-option>
          </a-select>
        </ul-table-column>

        <ul-table-column title="加标量">
          <a-input v-model="sampleInfo.addScalar" :style="{ width: '120px' }"></a-input>
        </ul-table-column>

        <!-- 如果当前批次是复合批次，那这里显示的就是复合因子 -->
        <ul-table-column data-index="compositeItem" :data="sampleInfo" title="检测项" class="compositeItem">
          <div v-for="item in sampleInfo.compositeItem.split(',')" :key="item">{{ getItemName(item) }}</div>
        </ul-table-column>
        <ul-table-column data-index="reagentTypeName" :data="sampleInfo" title="标液类目">
          <template v-if="sampleInfo.reagentType">{{ sampleInfo.reagentTypeName }}</template>
          <template v-else>
            <span style="color: red">当前检测方法无关联的标液类目</span>
          </template>
        </ul-table-column>
        <template v-if="sampleInfo.reagentType">
          <ul-table-column title="标液" v-if="!sampleInfo.solutionList || sampleInfo.solutionList.length === 0">
            <template v-if="standardSolutionList && standardSolutionList.length">
              <a-select allowClear placeholder="请选择标液" style="width: 300px" @change="(val) => handleSolutionChange(index, val)">
                <a-select-option v-for="item in standardSolutionList" :key="item.id">【{{ item.solutionNo }}】{{ item.name }}</a-select-option>
              </a-select>
            </template>
            <template v-else><span style="color: red">当前检测方法关联的标液类目下没有（符合条件的）标液数据</span></template>
          </ul-table-column>
          <div style="flex: 6" v-else>
            <div v-for="(item, _index) in sampleInfo.solutionList" :key="`standardSolutionName_${_index}`" class="flex item">
              <div :style="{ flex: 2 }">
                <a-tag color="blue">
                  <a-icon :style="{ marginRight: '10px' }" type="check-circle" />
                  {{ item.standardSolutionName }}
                </a-tag>
              </div>
              <div :key="`solutionNo_${_index}`" style="flex: 2">
                使用液编号：<span :style="{ color: 'green', margin: '0 10px 0 5px', flex: 1 }">{{ getSolutionNo(item.standardSolutionId) }}</span>
              </div>
              <div :key="`solutionUncertainty_${_index}`" style="flex: 2">
                不确定度：<span :style="{ color: 'green', margin: '0 10px 0 5px', flex: 1 }" v-if="item.uncertainty">{{ item.uncertainty }}{{ item.uncertaintyUnit }}</span
                ><span style="color: red" v-else>当前所选标液中没有不确定度数据</span>
              </div>
              <div :key="`solutionAction_${_index}`" :style="{ flex: 1 }">
                <a @click="sampleInfo.solutionList.splice(_index, 1)" style="color: red">移除</a>
              </div>
            </div>
          </div>
        </template>

        <ul-table-column v-if="sampleInfo.editable" title="">
          <template #default>
            <a @click="addStandardList.splice(index, 1)" style="color: red">移除</a>
          </template>
        </ul-table-column>
      </template>

      <template v-else>
        <div class="data prompt">
          <div class="no-data">暂无数据</div>
        </div>
      </template>
    </template>
  </ul-table>
</template>
<style lang="less">
.compositeItem {
  display: flex;
  .title-row {
    margin: 0;
  }
}
</style>