import { getAction } from '@/api/common'
import { flowApi } from '@/api/flow'
import { userApi } from '@/api/system'
import { resCarApi } from '@/api/quote'
import { tsSampleinfoApi } from '@/api/sample'

export const customFormFunc = {
  _self: this,

  getItemData: function (resolve) {
    resolve(this._self.items ? [...this._self.items] : [])
  },
  getAllUser (resolve) {
    getAction(userApi.list, { pageNo: 1, pageSize: 10000 }).then(res => {
      const options = []
      for (const i in res.data) {
        options.push({ value: res.data[i].username, label: res.data[i].username })
      }
      resolve(options)
    })
  },
  // getCity (resolve) {
  //   getAction('/city.json').then(res => {
  //     resolve(res)
  //   })
  // },
  getChuchai (resolve) {
    getAction(flowApi.userChuchaiProcess).then(res => {
      const options = []
      for (const i in res.data) {
        options.push({ value: res.data[i].processInstanceName, label: res.data[i].processInstanceName })
      }
      resolve(options)
    })
  },

  getUserProcess (resolve) {
    const params = {
      column: 'createTime',
      order: 'desc',
      pageNo: 1,
      pageSize: 100
    }
    getAction(flowApi.pageUserProcessWait, params).then((res) => {
      const options = []
      for (const i in res.data) {
        options.push({ value: res.data[i].processInstanceName, label: res.data[i].processInstanceName })
      }
      resolve(options)
    })
  },

  getDictCode: function (resolve, args) {
    if (!this._self.$store.state.code.cache[args[0]]) {
      this._self.$store.dispatch('code/fetch', args[0]).then(data => {
        resolve(this.generateDictCode(data))
      })
    } else {
      resolve(this.generateDictCode(this._self.$store.state.code.cache[args[0]]))
    }
  },

  initPicture: function (resolve) {
    if (resolve.dataModel && resolve.dataModel.length > 0) {
      try {
        if (typeof resolve.dataModel === 'string') {
          const options = JSON.parse(resolve.dataModel)
          options.forEach(item => {
            if (item.url.indexOf('/ms-api/common/view/') < 0) {
              item.url = '/ms-api/common/view/' + item.url
            }
          })

          if (this._self.hasOwnProperty('models')) {
            this._self.models[resolve.widget.model] = options
          } else {
            resolve.dataModel = options
          }
        }
      } catch (e) {
        resolve.dataModel = []
      }
    } else {
      resolve.dataModel = []
    }
  },

  initFiles: function (resolve) {
    if (resolve.dataModel && resolve.dataModel.length > 0) {
      try {
        const options = JSON.parse(resolve.dataModel)
        resolve.dataModel = options
      } catch (e) {
        resolve.dataModel = []
      }
    }
  },

  beforeInitPicture: function (model, widget) {
    const data = this._self.models[widget.model]
    if (data) {
      if (Object.prototype.toString.call(data) === '[object String]') {
        try {
          const options = JSON.parse(this._self.models[widget.model])
          options.forEach(item => {
            if (item.url.indexOf('/ms-api/common/view/') < 0) {
              item.url = '/ms-api/common/view/' + item.url
            }
          })
          this._self.models[widget.model] = options
        } catch (e) {
          this._self.models[widget.model] = []
        }
      }
    }
  },

  pictureChange (data) {
    switch (Object.prototype.toString.call(data)) {
      case '[object Array]':
        data.forEach(item => {
          delete item.lastModified
          delete item.lastModifiedDate
          delete item.percent
          delete item.status
        })
        break
      case '[object Object]':
        delete data.lastModified
        delete data.lastModifiedDate
        delete data.percent
        delete data.status
        break
    }
  },

  changePicture: function (resolve) {
    const options = []
    resolve.forEach(item => {
      const { key: name, url } = item
      options.push({ name, url })
    })
    console.log(options)
    resolve.splice(0, resolve.length, ...options)
  },

  changeTrain: function (resolve) {
    const type = ['inside', 'outside'].filter(it => it !== resolve)
    const filterModelJSON = this._self.$refs.generateForm.data.list
    filterModelJSON.forEach(item => {
      if ('card'.includes(item.type)) {
        if (resolve === item.name) {
          item.isShow = true
        } else if (type.includes(item.name)) {
          item.isShow = false
        }
      }
    })
  },
  getVehList: function (resolve) {
    getAction(resCarApi.listAll).then((res) => {
      const options = []
      for (const i in res.data) {
        options.push({ value: res.data[i].plateNbr, label: res.data[i].plateNbr })
      }
      resolve(options)
    })
  },
  initTableData: function (model, widget) {
    widget.model && (model.models[widget.model] = [{}])
    // console.log(widget.model, model.models[widget.model], model)
  },

  generateDictCode: (data) => {
    return data ? Object.keys(data).map(key => { return { value: key, label: data[key] } }) : []
  },

  initSampleInfo: function (model, widget) {
    tsSampleinfoApi.list({ ids: this._self.models.sampleIds, page: false }).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this._self.models.itemNames = res.data.itemNameStr
          this._self.models.sampleType = res.data[0].type
        }
      } else {
        this._self.error(`获取样品信息错误：${res.msg}`)
      }
    })
  }
}
