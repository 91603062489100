import { ref, computed } from 'vue'
import { labQcTypeEnum, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { uniq, groupBy, orderBy, uniqBy } from 'lodash'
import { checkTaskApi } from '@/api/check/checkTaskApi'
import { message } from 'ant-design-vue'

export const useTaskInfo = (fatherBatchId) => {
  const taskList = ref([])
  const parallelList = ref([])
  const blankList = ref([])
  const qualityControlList = ref([])
  const addStandardList = ref([])
  const qualityControlStatus = ref(false)
  const taskInfo = computed(() => {
    return taskList.value?.[0]
  })

  const sampleIds = computed(() => {
    const sampleIds = taskList.value.filter(item => !labQcTypeEnum.includes(item.sampleType)).map(item => item.sampleId)
    return uniq(sampleIds)
  })

  // 查询检测员环节的质控样任务
  const queryQcTaskLists = (sampleIds) => {
    checkTaskApi.list({ sampleRefIds: sampleIds, sampleTypes: labQcTypeEnum, page: false }).then(async res => {
      if (res.code === 0) {
        const checkTaskList = res.data || []
        const checkTaskTypeGroup = groupBy(orderBy(checkTaskList, 'sampleId'), 'sampleType')
        const [{ itemId: defaultItemId }] = taskList.value

        checkTaskTypeGroup[sampleInfoTypeEnum.labParallel]?.forEach(item => {
          const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, batchId, compositeItem } = item
          if (fatherBatchId == batchId && (compositeItem || itemId === defaultItemId)) {
            parallelList.value.push({ id, checkSampleId, itemId, cateId, itemName, cateName, compositeItem, editable: false })
          }
        })
        parallelList.value = uniqBy(parallelList.value, 'id')

        checkTaskTypeGroup[sampleInfoTypeEnum.labBlank]?.forEach(item => {
          const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, batchId, compositeItem } = item
          if (fatherBatchId == batchId && (compositeItem || itemId === defaultItemId)) {
            blankList.value.push({ id, checkSampleId, itemId, cateId, itemName, cateName, compositeItem, editable: false })
          }
        })
        blankList.value = uniqBy(blankList.value, 'id')

        checkTaskTypeGroup[sampleInfoTypeEnum.qualityControl]?.forEach(item => {
          const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, reagentType, reagentTypeName, standardSolutionId, standardSolutionName, uncertainty, uncertaintyUnit, batchId, compositeItem } = item
          if (fatherBatchId == batchId && (compositeItem || itemId === defaultItemId)) {
            let obj3 = { id, checkSampleId, itemId, cateId, itemName, cateName, editable: false, reagentType, reagentTypeName, compositeItem, solutionList: [] }
            if (reagentType && standardSolutionId && uncertainty && uncertaintyUnit) {
              let itemobj3 = { standardSolutionId, standardSolutionName, uncertainty, uncertaintyUnit }
              obj3.solutionList.push(itemobj3)
            }
            qualityControlList.value.push(obj3)
          }
        })
        qualityControlList.value = uniqBy(qualityControlList.value, 'id')

        // checkTaskTypeGroup[sampleInfoTypeEnum.qualityControl]?.forEach(item => {
        //   const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName } = item
        //   if (itemId === defaultItemId) {
        //     qualityControlList.value.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: false })
        //   }
        // })

        checkTaskTypeGroup[sampleInfoTypeEnum.addStandardSample]?.forEach(item => {
          const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, addScalar, reagentType, reagentTypeName, standardSolutionId, standardSolutionName, uncertainty, uncertaintyUnit, batchId, compositeItem } = item
          if (fatherBatchId == batchId && (compositeItem || itemId === defaultItemId)) {
            let obj1 = { id, checkSampleId, type: sampleInfoTypeEnum.addStandardSample, itemId, cateId, itemName, cateName, addScalar, editable: false, reagentType, reagentTypeName, compositeItem, solutionList: [] }
            if (reagentType && standardSolutionId && uncertainty && uncertaintyUnit) {
              let itemobj1 = { standardSolutionId, standardSolutionName, uncertainty, uncertaintyUnit }
              obj1.solutionList.push(itemobj1)
            }
            addStandardList.value.push(obj1)
          }
        })

        checkTaskTypeGroup[sampleInfoTypeEnum.basisAddStandardSample]?.forEach(item => {
          const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, addScalar, reagentType, reagentTypeName, standardSolutionId, standardSolutionName, uncertainty, uncertaintyUnit, batchId, compositeItem } = item
          if (fatherBatchId == batchId && (compositeItem || itemId === defaultItemId)) {
            let obj2 = { id, checkSampleId, itemId, type: sampleInfoTypeEnum.basisAddStandardSample, cateId, itemName, cateName, addScalar, editable: false, reagentType, reagentTypeName, compositeItem, solutionList: [] }
            if (reagentType && standardSolutionId && uncertainty && uncertaintyUnit) {
              let itemobj2 = { standardSolutionId, standardSolutionName, uncertainty, uncertaintyUnit }
              obj2.solutionList.push(itemobj2)
            }
            addStandardList.value.push(obj2)
          }
        })
        addStandardList.value = uniqBy(addStandardList.value, 'id')

        // checkTaskTypeGroup[sampleInfoTypeEnum.addStandardSample]?.forEach(item => {
        //   const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, addScalar } = item
        //   if (itemId === defaultItemId) {
        //     addStandardList.value.push({ id, checkSampleId, type: sampleInfoTypeEnum.addStandardSample, itemId, cateId, itemName, cateName, addScalar, editable: false })
        //   }
        // })

        // checkTaskTypeGroup[sampleInfoTypeEnum.basisAddStandardSample]?.forEach(item => {
        //   const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName, addScalar } = item
        //   if (itemId === defaultItemId) {
        //     addStandardList.value.push({ id, checkSampleId, itemId, type: sampleInfoTypeEnum.basisAddStandardSample, cateId, itemName, cateName, addScalar, editable: false })
        //   }
        // })
        qualityControlStatus.value = true
      } else {
        await message.error(`获取质控样错误信息:${res.msg}`)
      }
    })
  }
  return {
    sampleIds,
    taskList,
    taskInfo,
    parallelList,
    blankList,
    qualityControlList,
    addStandardList,
    queryQcTaskLists,
    qualityControlStatus
  }
}
