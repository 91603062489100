'use strict'

/**
 * 加标配置 相关API
 */

import { getAction, delAction, postAction, postDataAction } from '@/api/common'

export const markConfigApi = {
  selectList: (param) => getAction(`/ms-api/method/selectList`, param),
  list: (param) => getAction(`/ms-api/jcMethodMarkConfig/queryAll`, param),
  queryInfoById: (id) => getAction(`/ms-api/jcMethodMarkConfig/queryInfoById/${id}`),
  add: (data) => postDataAction(`/ms-api/jcMethodMarkConfig/add`, data),
  delete: (data) => postAction(`/ms-api/jcMethodMarkConfig/delete`, data),
  createRandomNumber: (id) => getAction(`/ms-api/jcMethodMarkConfig/createRandomNumber/${id}`)
}
