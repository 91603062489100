<template>
  <a-modal v-bind="siteModal" v-on="siteModal" class="EditPointPosition">
    <div class="card-blue">
      <table class="ahmux-table">
        <tr>
          <th class="tdLabel" style="width: 80px">监测位置</th>
          <th class="tdLabel" style="width: 200px">标准名称</th>
          <th class="tdLabel" style="width: 200px">所选属性名称</th>
          <th class="tdLabel" style="width: 400px">因子选择</th>
          <th class="tdLabel" style="width: 100px">因子操作按钮</th>
        </tr>

        <tr v-for="(stand, standIdx) in siteData.standItem.children" :key="standIdx">
          <td v-if="standIdx == 0" :rowspan="siteData.standItem.children.length + 2">
            {{ siteData.standItem.siteName }}
          </td>
          <td>
            {{ stand.standardName }}
            <a-icon
              style="color: #ff0000; position: relative; top: -5px; cursor: pointer"
              size="small"
              type="close-circle"
              v-if="stand.isCopy == 1"
              @click="delCopyStand(standIdx, stand.standardId)"
            />

            <div class="siteBtnGroup">
              <a-button type="primary" class="siteBtn" ghost @click="copyStandard(standIdx, stand.standardId)">复制</a-button>
              <a-button type="primary" class="siteBtn" ghost @click="searchAttribute(standIdx, stand.standardId)">编辑</a-button>
            </div>
          </td>
          <td>
            {{ stand.attributeName }}
          </td>
          <td>
            <div class="itemLimitValue" v-for="(item, itemIdx) in stand.children" :key="item.itemId">
              <!-- <span class="itemLimitName">{{ item.itemName }}</span> -->
              <a-tag v-if="item.type == 1" color="red">*{{ item.itemName }}</a-tag>
              <a-tag v-else color="blue">{{ item.itemName }}</a-tag>

              <a-icon class="itemLimitIcon" size="small" type="close-circle" @click="delItem(standIdx, itemIdx, item.siteItemLimitValueId)" />
            </div>
          </td>
          <td>
            <a-button type="primary" class="siteBtn" ghost @click="showItemLimitModal(standIdx, stand.standardId)">添加</a-button>
            <a-button type="primary" class="siteBtn" ghost @click="delItemLimies(standIdx, stand.standardId)">删除</a-button>
          </td>
        </tr>

        <tr>
          <td>未配置标准值</td>
          <td></td>
          <td>
            <div class="itemLimitValue" v-for="(isNotNull, isNotNullIdx) in siteData.itemIsNotNull.children" :key="isNotNullIdx">
              <a-tag color="blue">{{ isNotNull.itemName }}</a-tag>
              <!-- <span class="itemLimitName">{{ isNotNull.itemName }}</span> -->
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>无标准</td>
          <td></td>
          <td>
            <div class="itemLimitValue" v-for="(isNull, isNullIdx) in siteData.itemIsNull.children" :key="isNullIdx">
              <a-tag color="red">*{{ isNull.itemName }}</a-tag>
              <!-- <span class="itemLimitName">{{ isNull.itemName }}</span> -->
            </div>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
    <a-modal title="选择标准" :visible="attributeVisible" @ok="attributeOk" @cancel="attributeCancel">
      <div v-if="Object.keys(attributeData).length > 0">
        <div style="margin-left: 5px; font-size: 14px; color: #606266">{{ attributeData.standardName }}</div>
        <div class="down-tree">
          <el-tree
            :data="attributeData.children"
            :props="attributeDataProps"
            node-key="attributeId"
            :check-strictly="true"
            highlight-current
            show-checkbox
            check-on-click-node
            ref="tree"
            @check-change="AttributeNodeClick"
            :default-expanded-keys="attributeDefaultExpandedKeys"
          >
          </el-tree>
          <!-- default-expand-all -->
        </div>
      </div>
      <div v-else style="height: 200px; text-align: center; font-size: 14px; color: #909399">正在加载中...</div>
    </a-modal>
    <a-modal title="选择因子" width="800px" :visible="ItemLimitVisible" @ok="ItemLimitOk" @cancel="ItemLimitCancel">
      <!-- <el-table ref="itemLimitTale" :data="siteData.itemIsNotNull.children" v-bind="tableContent" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column label="检测项" prop="itemName" min-width="200" />
      </el-table> -->
      <div class="itemLimitDiv">
        <div style="margin-bottom: 10px">
          <a-checkbox v-model="searchCheckAll" :indeterminate="searchIndeterminate" @change="onSearchCheckAllChange">
            <span style="font-weight: bolder">全选</span>
          </a-checkbox>
        </div>
        <a-card style="height: calc(100% - 46px); overflow-y: auto">
          <a-checkbox-group v-model="selectedKeys" style="width: 100%" @change="checkGroupChange">
            <a-row>
              <a-col class="item-col" :span="6" v-for="(obj, idx) in siteData.itemIsNotNull.children" :key="idx">
                <a-checkbox :value="obj.itemId">
                  <a-tag class="short-tag" color="blue" :title="obj.itemName">{{ obj.itemName }}</a-tag>
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-card>
      </div>
    </a-modal>
  </a-modal>
</template>
<script>
import { projStandardSaveLimitApi, projSiteEdit, getAttributeApi, siteItemLimitDelete, affirmLimitValue } from '@/api/projectStandard/projectStandardApi'

var vm = {}
export default {
  name: 'EditPointPosition',

  data() {
    vm = this
    return {
      // type: 'add',
      title: '编辑因子信息',
      siteModal: {
        title: '编辑因子信息',
        visible: false,
        // maskClosable: false,
        destroyOnClose: true,
        centered: true,
        cancel: this.handleCancel,
        ok: this.handleOK,
        width: '1100px'
      },
      visible: false,
      attributeVisible: false,
      ItemLimitVisible: false,

      projId: '',
      cateId: '',
      siteId: '',
      siteData: {
        standItem: {
          children: []
        },
        itemIsNull: {
          children: []
        },
        itemIsNotNull: {
          children: []
        }
      },
      // 要删除的因子的siteItemLimitValueId
      ids: [],
      attributeFrom: { standIdx: '', standardId: '', headerId: '', checkedId: '', attributeName: '', attributeId: '' },
      itemLimitFrom: {
        standIdx: '',
        standardId: ''
      },
      standardId: '',

      itemLimitSelection: [],
      attributeData: {},
      attributeDataProps: {
        children: 'children',
        label: 'attributeName'
      },
      attributeDefaultExpandedKeys: [],
      tableContent: {
        border: true,
        size: 'mini',
        maxHeight: 400,
        stripe: true,
        rowKey: (data) => {
          return data.itemId + (data.id || data.attrValueId || '')
        },
        bordered: true
      },
      editLoading: false,

      searchCheckAll: false,
      searchIndeterminate: false,
      selectedKeys: []
    }
  },
  methods: {
    getSite(siteObj) {
      this.projId = siteObj.projId
      this.cateId = siteObj.cateId
      this.siteId = siteObj.siteId
      projSiteEdit.listProjectStandard({ projId: this.projId, cateId: this.cateId, siteId: this.siteId }).then((res) => {
        if (res.code === 0) {
          this.siteModal.title = '编辑因子信息'
          this.siteModal.visible = true
          this.processingData(res.data)
          this.siteData = res.data
        } else {
          this.$message.error(`${res.msg}`)
        }
      })
    },
    // 处理数据
    async processingData(data) {
      if (data && data.standItem && data.standItem.children && data.standItem.children.length) {
        for (let i = 0; i < data.standItem.children.length; i++) {
          let item = data.standItem.children[i]
          if (item.attributeId) {
            let attribute = await this.getAttribute(item.standardId)
            if (attribute && attribute.children && Array.isArray(attribute.children)) {
              // let ss = this.getParentId(attribute.children, item.attributeId)
              // if (ss && ss.length) {
              //   ss.reverse()
              //   let attributeName = ''
              //   ss.forEach((item2, index2) => {
              //     if (index2 === 0) {
              //       let names = item2.attributeName.split(' ')
              //       attributeName += names[0]
              //     } else {
              //       attributeName += '-' + item2.attributeName
              //     }
              //   })
              //   this.$set(item, 'attributeName', attributeName)
              // }
              let attributeName = this.generateAttributeName(attribute.children, item.attributeId)
              this.$set(item, 'attributeName', attributeName)
            }
          }
        }
      }
    },
    // 生成所选属性名称
    generateAttributeName(list, attributeId) {
      let attributeName = ''
      let ss = this.getParentId(list, attributeId)
      if (ss && ss.length) {
        ss.reverse()
        ss.forEach((item2, index2) => {
          if (index2 === 0) {
            let names = item2.attributeName.split(' ')
            attributeName += names[0]
          } else {
            attributeName += '-' + item2.attributeName
          }
        })
      }
      return attributeName
    },
    getParentId(list, id) {
      for (let i in list) {
        if (list[i].attributeId === id) {
          return [list[i]]
        }
        if (list[i].children && Array.isArray(list[i].children)) {
          let node = this.getParentId(list[i].children, id)
          if (node !== undefined) {
            return node.concat(list[i])
          }
        }
      }
    },

    delItem(standIdx, itemIdx, siteItemLimitValueId) {
      this.siteData.standItem.children[standIdx].children[itemIdx].type = null
      const items = this.siteData.standItem.children[standIdx].children.splice(itemIdx, 1)
      this.siteData.itemIsNotNull.children.push(items[0])
      if (siteItemLimitValueId && siteItemLimitValueId != null) {
        this.ids.push(siteItemLimitValueId)
      }
      if (this.siteData.standItem.children[standIdx].children.length == 0) {
        this.siteData.standItem.children[standIdx].type = 1
      }
    },
    handleOK(e) {
      // 提交表单
      const isNotCheckAttr = this.siteData.standItem.children.findIndex((item, index) => {
        return item.type == 2
      })
      if (isNotCheckAttr != -1) {
        this.$alert(`第${isNotCheckAttr + 1}行 ${this.siteData.standItem.children[isNotCheckAttr].standardName}未选择标准，请点击编辑`, '点位提示', {
          confirmButtonText: '确定'
        })
        return false
      }
      const data = []
      this.siteData.standItem.children.forEach((attr) => {
        if (attr.children && attr.children.length > 0 && (attr.type == 3 || (attr.isCopy && attr.isCopy == 2 && attr.type == 1))) {
          const items = []
          attr.children.forEach((item) => {
            items.push(item.itemId)
          })

          data.push({
            projId: this.projId,
            cateId: this.cateId,
            siteId: this.siteId,
            standardId: attr.standardId,
            headerId: attr.headerId,
            attributeId: attr.attributeId,
            type: '2',
            items: items
          })
        }
      })
      if (data.length == 0 && this.ids.length == 0) {
        this.siteModal.visible = false
        return false
      }
      this.$confirm({
        title: '确认提交更改吗？',
        onOk: () => {
          this.editLoading = true
          let res1 = null
          let res2 = null
          if (data.length > 0) {
            res1 = projStandardSaveLimitApi.listProjectStandard(data)
          }
          if (this.ids.length > 0) {
            res2 = siteItemLimitDelete.listProjectStandard(this.ids)
          }
          if (data.length > 0 && this.ids.length > 0 && res1 && res2) {
            Promise.all([res1, res2]).then((res) => {
              const result = res.reduce((acc, item) => acc && item.code === 0, true)
              if (result) {
                this.isSuccess()
              } else {
                this.editLoading = false
                const message = res
                  .filter((item) => item.code)
                  .map((item) => item.msg)
                  .join()
                this.$message.error(message)
              }
            })
          } else if (data.length > 0) {
            res1.then((res) => {
              if (res.code === 0) {
                this.$message.success(`添加因子成功`)
                this.isSuccess()
              } else {
                this.editLoading = false
                this.$message.error(`添加因子出错：${res.msg}`)
              }
            })
          } else if (this.ids.length > 0) {
            res2.then((res) => {
              if (res.code === 0) {
                this.$message.success(`删除因子成功`)
                this.isSuccess()
              } else {
                this.editLoading = false
                this.$message.error(`删除因子出错：${res.msg}`)
              }
            })
          }
        },
        onCancel() {
          // this.editLoading = false
        }
      })
    },
    handleCancel() {
      // this.siteData = {}
      this.ids = []
      this.siteModal.visible = false
    },
    isSuccess() {
      this.editLoading = false
      this.$message.success('编辑点位成功！')
      this.ids = []
      this.siteModal.visible = false
      this.$emit('updatePointPosition')
    },

    // 复制标准
    copyStandard(standIdx, standardId) {
      // 被复制isCopy=2
      this.siteData.standItem.children[standIdx].isCopy = 2
      const stand = this.siteData.standItem.children[standIdx]
      const obj = {
        attributeId: null,
        attributeName: null,
        cateId: stand.cateId,
        cateName: stand.cateName,
        childFlag: stand.childFlag,
        children: null,
        headerId: null,
        headerName: null,
        parentId: stand.parentId,
        projId: stand.projId,
        siteId: stand.siteId,
        siteName: stand.siteName,
        standardId: stand.standardId,
        standardIds: stand.standardIds,
        standardName: stand.standardName,
        type: '1',
        isCopy: 1 // 复制出的数据isCopy=1
      }
      this.siteData.standItem.children.splice(standIdx + 1, 0, obj)
    },

    // 删除复制出的标准
    delCopyStand(standIdx, standardId) {
      if (this.siteData.standItem.children[standIdx].children && this.siteData.standItem.children[standIdx].children.length > 0) {
        this.siteData.standItem.children[standIdx].children.forEach((item) => {
          item.type = null
          this.siteData.itemIsNotNull.children.push(item)
          if (item.siteItemLimitValueId && item.siteItemLimitValueId != null) {
            this.ids.push(item.siteItemLimitValueId)
          }
        })
      }

      this.siteData.standItem.children.splice(standIdx, 1)
    },

    // 查询标准
    async searchAttribute(standIdx, standardId) {
      this.attributeVisible = true
      this.attributeData = {}
      this.attributeDefaultExpandedKeys = []
      this.attributeFrom.standIdx = standIdx
      this.attributeFrom.standardId = standardId
      let data = await this.getAttribute(standardId)
      if (data) {
        data?.children?.map((item) => {
          this.attributeDefaultExpandedKeys.push(item.attributeId)
          this.getLeafKeys(item)
        })
        this.siteData.standItem.children.forEach((item) => {
          if (item.type != 2) {
            const headerId = item.headerId
            const attributeId = item.attributeId
            const header = data?.children?.find((v) => v.attributeId == headerId)
            if (header) {
              this.judgeAttribute(header, attributeId)
            }
          }
        })
        this.attributeData = data
      }
    },
    getAttribute(standardId) {
      return getAttributeApi.listProjectStandard({ standardId }).then((res) => {
        if (res.code === 0) {
          return res.data
        } else {
          this.$message.error(`查询标准信息出错：${res.msg}`)
        }
      })
    },
    getLeafKeys(node) {
      // 如果当前 node 节点不包含 children 属性，则是最后一级节点
      if (node.children && Array.isArray(node.children)) {
        if (!node.children.length == 0) {
          // 除了最后一级所有节点disabled为true
          node.disabled = true
        }
        node.children.forEach((item) => this.getLeafKeys(item))
      }
    },
    // 判断标准是否已被选择过
    judgeAttribute(node, attributeId) {
      if (node.attributeId == attributeId) {
        node.disabled = true
      } else {
        if (node.children && Array.isArray(node.children)) {
          node.children.forEach((item) => this.judgeAttribute(item, attributeId))
        }
      }
    },
    // 点击选择项目标准树状图
    AttributeNodeClick(item, checked, self) {
      if (checked === true) {
        this.attributeFrom.checkedId = item.attributeId
        this.attributeFrom.attributeName = item.attributeName
        this.attributeFrom.attributeId = this.attributeFrom.checkedId
        this.attributeFrom.headerId = item.headerId
        this.$refs.tree.setCheckedKeys([item.attributeId])
      } else {
        if (this.attributeFrom.checkedId == item.attributeId) {
          this.$refs.tree.setCheckedKeys([item.attributeId])
        }
      }
    },

    // 暂存选中的标准
    attributeOk(e) {
      if (this.attributeFrom.attributeId && this.attributeFrom.attributeId != '') {
        this.siteData.standItem.children[this.attributeFrom.standIdx].type = 3
        this.siteData.standItem.children[this.attributeFrom.standIdx].headerId = this.attributeFrom.headerId
        this.siteData.standItem.children[this.attributeFrom.standIdx].attributeId = this.attributeFrom.attributeId
        if (this.siteData.standItem.children[this.attributeFrom.standIdx].children && this.siteData.standItem.children[this.attributeFrom.standIdx].children.length > 0) {
          this.siteData.standItem.children[this.attributeFrom.standIdx].children.forEach((item) => {
            item.standardId = this.siteData.standItem.children[this.attributeFrom.standIdx].standardId
            item.attributeId = this.attributeFrom.attributeId
          })
          this.judgeItemLimit(this.attributeFrom.standIdx, this.attributeFrom.standardId)
        }
        if (this.attributeData && this.attributeData.children && Array.isArray(this.attributeData.children)) {
          // let ss = this.getParentId(this.attributeData.children, this.attributeFrom.attributeId)
          // if (ss && ss.length) {
          //   ss.reverse()
          //   let attributeName = ''
          //   ss.forEach((item2, index2) => {
          //     if (index2 === 0) {
          //       let names = item2.attributeName.split(' ')
          //       attributeName += names[0]
          //     } else {
          //       attributeName += '-' + item2.attributeName
          //     }
          //   })
          //   this.$set(this.siteData.standItem.children[this.attributeFrom.standIdx], 'attributeName', attributeName)
          // }
          let attributeName = this.generateAttributeName(this.attributeData.children, this.attributeFrom.attributeId)
          this.$set(this.siteData.standItem.children[this.attributeFrom.standIdx], 'attributeName', attributeName)
        }
      }
      this.$refs.tree.setCheckedKeys([])
      // 如果当前行未选择因子，则调用showItemLimitModal方法打开选择因子对话框
      if (!this.siteData.standItem.children[this.attributeFrom.standIdx].children || this.siteData.standItem.children[this.attributeFrom.standIdx].children.length === 0) {
        this.showItemLimitModal(this.attributeFrom.standIdx, this.attributeFrom.standardId)
      }

      this.attributeData = {}
      this.attributeFrom.standIdx = ''
      this.attributeFrom.standardId = ''
      this.attributeFrom.checkedId = ''
      this.attributeFrom.headerId = ''
      this.attributeFrom.attributeId = ''
      this.attributeFrom.attributeName = ''
      this.attributeVisible = false
    },
    // 取消/关闭标准对话框
    attributeCancel(e) {
      this.$refs.tree.setCheckedKeys([])
      this.attributeData = {}
      this.attributeFrom.standIdx = ''
      this.attributeFrom.standardId = ''
      this.attributeFrom.checkedId = ''
      this.attributeFrom.headerId = ''
      this.attributeFrom.attributeId = ''
      this.attributeFrom.attributeName = ''
      this.attributeVisible = false
    },

    // 判断标准下选中的因子是否有标准值
    judgeItemLimit(standIdx, standardId) {
      const obj = this.siteData.standItem.children[standIdx].children
      this.siteData.standItem.children[standIdx].children.forEach((item) => {
        item.type = null
      })
      affirmLimitValue.listProjectStandard(obj).then((res) => {
        // console.log(res)
        if (res.code === 0) {
          const data = res.data
          data.forEach((item) => {
            if (item.type == 1) {
              const fIndex = this.siteData.standItem.children[standIdx].children.findIndex((v) => {
                return v.itemId === item.itemId
              })
              if (fIndex != -1) {
                this.siteData.standItem.children[standIdx].children[fIndex].type = item.type
              }
            }
          })
        } else {
          this.$message.error(`查询标准信息出错：${res.msg}`)
        }
      })
    },

    // 删除标准下的所有因子
    delItemLimies(standIdx, standardId) {
      this.siteData.standItem.children[standIdx].children.forEach((item) => {
        item.type = null
        this.siteData.itemIsNotNull.children.push(item)
        if (item.siteItemLimitValueId && item.siteItemLimitValueId != null) {
          this.ids.push(item.siteItemLimitValueId)
        }
      })
      this.siteData.standItem.children[standIdx].children = []
      this.siteData.standItem.children[standIdx].type = 1
    },

    // 打开检测项对话框
    showItemLimitModal(standIdx, standardId) {
      this.ItemLimitVisible = true
      this.itemLimitFrom.standIdx = standIdx
      this.itemLimitFrom.standardId = standardId
      this.selectedKeys = []
      this.siteData.itemIsNotNull.children.forEach((obj) => {
        this.selectedKeys.push(obj.itemId)
      })
      this.searchCheckAll = false
      this.searchIndeterminate = false
      if (this.siteData.itemIsNotNull.children.length) {
        this.checkGroupChange(this.selectedKeys)
      }

      this.itemLimitSelection = []

      // this.$nextTick(() => {
      //   this.$refs.itemLimitTale.toggleAllSelection()
      // })
    },
    // 选择检测项
    handleSelectionChange(val) {
      console.log(this.siteData.itemIsNotNull.children, val)
      this.itemLimitSelection = val
    },
    onSearchCheckAllChange(e) {
      let checked = e.target.checked
      this.siteData.itemIsNotNull.children.forEach((obj) => {
        let key = obj.itemId
        if (checked) {
          // checked = true  将未选中的因子，勾选
          if (!this.selectedKeys.includes(key)) {
            this.selectedKeys.push(key)
          }
        } else {
          // checked = false  将已选中的因子，移除
          if (this.selectedKeys.includes(key)) {
            let idx = this.selectedKeys.indexOf(key)
            this.selectedKeys.splice(idx, 1)
          }
        }
      })
      this.searchIndeterminate = false
    },
    checkGroupChange(selectedKeys) {
      this.searchCheckAll = selectedKeys.length === this.siteData.itemIsNotNull.children.length
      this.searchIndeterminate = !!selectedKeys.length && selectedKeys.length < this.siteData.itemIsNotNull.children.length
    },
    // 暂存选中的检测项
    ItemLimitOk(e) {
      const items = []
      if (this.selectedKeys.length) {
        this.siteData.itemIsNotNull.children.forEach((item) => {
          if (this.selectedKeys.indexOf(item.itemId) !== -1) {
            this.itemLimitSelection.push(item)
          }
        })
      }

      this.itemLimitSelection.forEach((item) => {
        if (this.siteData.standItem.children[this.itemLimitFrom.standIdx].type == 1) {
          this.siteData.standItem.children[this.itemLimitFrom.standIdx].type = 2
        }
        if (!this.siteData.standItem.children[this.itemLimitFrom.standIdx].children || this.siteData.standItem.children[this.itemLimitFrom.standIdx].children.length == 0) {
          this.siteData.standItem.children[this.itemLimitFrom.standIdx].children = []
        }
        this.siteData.standItem.children[this.itemLimitFrom.standIdx].children.push(item)
        if (item.siteItemLimitValueId && item.siteItemLimitValueId != 'null') {
          const index = this.ids.indexOf(item.siteItemLimitValueId)
          this.ids.splice(index, 1)
        }
        if (this.siteData.itemIsNotNull.children.length > 0) {
          const isNotNullIndex = this.siteData.itemIsNotNull.children.findIndex((v) => {
            return v.itemId === item.itemId
          })
          if (isNotNullIndex != -1) {
            this.siteData.itemIsNotNull.children.splice(isNotNullIndex, 1)
          }
        }
      })
      if (this.siteData.standItem.children[this.itemLimitFrom.standIdx].type == 3) {
        this.siteData.standItem.children[this.itemLimitFrom.standIdx].children.forEach((item) => {
          item.standardId = this.siteData.standItem.children[this.itemLimitFrom.standIdx].standardId
          item.attributeId = this.siteData.standItem.children[this.itemLimitFrom.standIdx].attributeId
        })
        this.judgeItemLimit(this.itemLimitFrom.standIdx, this.itemLimitFrom.standardId)
      }
      // 如果当前行未选择过标准属性，则调用searchAttribute方法打开选择标准属性对话框
      if (this.siteData.standItem.children[this.itemLimitFrom.standIdx].type != 3) {
        this.searchAttribute(this.itemLimitFrom.standIdx, this.itemLimitFrom.standardId)
      }

      this.itemLimitFrom.standIdx = ''
      this.itemLimitFrom.standardId = ''
      this.itemLimitSelection = []
      this.searchCheckAll = false
      this.selectedKeys = []
      this.ItemLimitVisible = false
    },
    // 取消/关闭检测项对话框
    ItemLimitCancel(e) {
      this.itemLimitFrom.standIdx = ''
      this.itemLimitFrom.standardId = ''
      this.itemLimitSelection = []
      this.searchCheckAll = false
      this.selectedKeys = []
      this.ItemLimitVisible = false
    }
  },
  watch: {}
}
</script>

<style lang="less">
.ahmux-table {
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #caedff;
  }
}

.card-blue .card-bg-color {
  background-color: #e6f7ff;
}
.card-blue .card-border {
  border: 1px solid #91d5ff;
}

.itemLimitValue {
  display: inline-block;
  position: relative;
  margin: 7px 5px;
  .itemLimitName {
    height: 22px;
    padding: 0 7px;
    border: 1px solid #91d5ff;
    box-sizing: border-box;
    background: #e6e7ff;
    border-radius: 4px;
    font-size: 12px;
    color: #1890ff;
  }
  .itemLimitIcon {
    position: absolute;
    top: -8px;
    right: 0px;
    cursor: pointer;
    color: #ff0000;
  }
}

.siteBtn {
  margin: 4px 0;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  height: initial;
}

.down-tree {
  height: 300px;
  display: block;
  overflow-y: scroll;
}
.itemLimitDiv {
  .item-col {
    width: 130px;
    line-height: 0px;
    margin: 5px 0px;
  }
  .ant-tag {
    width: 125px;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }
  .ant-tag.short-tag {
    width: 88px;
  }
}
</style>
