<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          样品出库
        </div>
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="4">出库信息</th>
          <td class="tdLabel">出库人</td>
          <td>{{ subtask.sbtTransferor }}</td>
          <td class="tdLabel">出库日期</td>
          <td>{{ subtask.sbtTransferDate }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="tdLabel">接收人</td>
          <td>{{subtask.sbtReceiver}}</td>
          <td class="tdLabel">联系电话</td>
          <td>{{subtask.sbtReceiverPhone}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="tdLabel">移交方式</td>
          <td>{{ sbtTransferModeMap[subtask.sbtTransferMode] }}</td>
          <td class="tdLabel">快递公司</td>
          <td>{{expressCompanyMap[subtask.sbtExpressCompany]}}</td>
          <td class="tdLabel">快递单号</td>
          <td>{{ subtask.sbtExpressNumber }}</td>
        </tr>
        <tr>
          <td class="tdLabel">附件信息</td>
          <td colspan="5">
            <UploadFile
              :fileList="subtask.sbtExpressImg ? JSON.parse(subtask.sbtExpressImg) : []"
              list-type="picture-card"
              accept="image/*"
            />
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import UploadFile from '@/pages/common/upload/UploadFile'

export default {
  name: 'SubTaskTransferSample',
  components: {
    UploadFile
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
    'subtask.id': {
      handler (nv, ov) {
        if (nv) {
        }
      },
      immediate: true
    }
  },
  computed: {
    expressCompanyMap () {
      return { ...this.$store.state.code.cache.expressCompany }
    },
    sbtTransferModeMap () {
      return { ...this.$store.state.code.cache.sbtTransferMode }
    }
  },
  mounted () {
  },
  created () {
    this.$store.dispatch('code/select', 'expressCompany')
    this.$store.dispatch('code/select', 'sbtTransferMode')
  }
}
</script>
<style lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
