<template>
  <div>
    <a-row>
      <a-col span="7">
        <div class="ahmux-sub-title" style="width: 150px;">
          有效性评价信息
        </div>
      </a-col>
    </a-row>

    <a-row>
      <table class="mxtable">
        <tr>
          <th>申请人</th>
          <td>
            <select-user-by-dept ref="user" :disabled="disabled" :triggerChange="true" @change="handleUserChange" rowType="radio"/>
          </td>
          <th>申请时间</th>
          <td>
            <a-input v-model="effectivenessInfo.applyDate" :disabled="true"></a-input>
          </td>
        </tr>
        <tr>
          <th>培训类别</th>
          <td>
            <a-input v-model="effectivenessInfo.trainingCategory" :disabled="true"></a-input>
          </td>
          <th>培训时间</th>
          <td>
            <a-input v-model="effectivenessInfo.trainingTime" :disabled="true"></a-input>
          </td>
        </tr>
        <tr>
          <th>参加培训人员</th>
          <td colspan="3">
            <a-textarea v-model="effectivenessInfo.trainingCategory" :disabled="true"></a-textarea>
          </td>
        </tr>
        <tr>
          <th>培训内容</th>
          <td colspan="3">
            <a-textarea v-model="effectivenessInfo.trainingContent" :disabled="true"></a-textarea>
          </td>
        </tr>
        <tr>
          <th>考核方式</th>
          <td>
            <a-select
              v-model="effectivenessInfo.evaluationMode"
              :disabled="true"
              style="width: 100%">
              <a-select-option :key="0">考察</a-select-option>
              <a-select-option :key="1" >考试</a-select-option>
              <a-select-option :key="2" >取证</a-select-option>
              <a-select-option :key="3" >其他</a-select-option>
            </a-select>
          </td>
          <th>考核时间</th>
          <td>
            <a-input v-model="effectivenessInfo.evaluationTime" :disabled="true"></a-input>
          </td>
        </tr>
        <tr>
          <th>考核结果</th>
          <td colspan="3">
            <a-textarea v-model="effectivenessInfo.evaluationResult" :disabled="true"></a-textarea>
          </td>
        </tr>
        <tr v-if="disabled === true">
          <th><span style="color: red">* </span>培训评价</th>
          <td colspan="3">
            <a-textarea
              placeholder="请输入培训评价"
              v-model="effectivenessInfo.trainingEffectiveness"
              :disabled="effectivenessInfo.ratifyUser !== null || effectivenessInfo.ratifyUser !== ''"
              style="height: 150px"
            ></a-textarea>
          </td>
        </tr>
        <tr v-if="disabled === true">
          <th>备注</th>
          <td colspan="3">
            <a-textarea
              placeholder="请输入备注"
              v-model="effectivenessInfo.remark"
              :disabled="effectivenessInfo.ratifyUser !== null || effectivenessInfo.ratifyUser !== ''"
              style="height: 150px"
            ></a-textarea>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { userApi } from '@/api/system'
import { Get, Post, resStaffTrainingApi } from '@/api/quote'

export default {
  name: 'StaffTrainingEffectivenessModal',
  components: {
    SelectUserByDept
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },
  data () {
    return {
      disabled: false,
      effectivenessInfo: {}
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.loadData()
        }
      }
    }
  },
  methods: {
    init (trainingId) {
      this.getEffectivenessInfo(trainingId, false)
    },
    handleUserChange (userList) {
      if (userList.length > 0) {
        this.effectivenessInfo.applyUser = userList[0].username
      }
    },
    setUserList () {
      const userList = []
      const user = {
        userId: this.loginUser.id,
        name: this.loginUser.realname
      }
      userList.push(user)
      this.$refs.user.setUserList(userList)
      this.effectivenessInfo.applyUser = this.loginUser.username
    },
    loadData () {
      this.getEffectivenessInfo(this.recordId, true)
    },
    getEffectivenessInfo (trainingId, disabled) {
      Get(resStaffTrainingApi.getEffectivenessInfo, { trainingId: trainingId || this.recordId }).then(res => {
        if (res.code === 0) {
          this.disabled = disabled
          this.effectivenessInfo = res.data
          this.getCategory(this.effectivenessInfo)
          if (this.effectivenessInfo.applyUser === null) {
            this.setUserList()
          } else {
            Get(userApi.getUserList, { username: this.effectivenessInfo.applyUser }).then(res => {
              if (res.data && res.data.length > 0) {
                const userList = res.data.map(({ id, realname }) => ({ userId: id, name: realname }))
                this.$refs.user.setUserList(userList)
              }
            })
          }
          this.effectivenessInfo.applyDate = new Date().format('yyyy-MM-dd HH:mm:ss')
        }
      })
    },
    getCategory (info) {
      if (info.trainingCategory) {
        if (info.trainingCategory.indexOf(',') === -1) {
          info.trainingCategory = this.getCategoryName(info.trainingCategory)
        } else {
          const arr = []
          info.trainingCategory.split(',').forEach(item => {
            const str = this.getCategoryName(item)
            arr.push(str)
          })
          info.trainingCategory = arr.join(',')
        }
      }
    },
    getCategoryName (category) {
      if (category === '0') return '岗前培训'
      if (category === '1') return '岗位培训'
      if (category === '2') return '待岗培训'
    },
    handleSubmit () {
      Post(resStaffTrainingApi.effectiveness, this.effectivenessInfo).then(res => {
        if (res.code === 0) {
          this.$message.success('提交培训有效性评价成功！')
          this.$emit('ok')
        } else {
          this.$message.error('提交培训有效性评价失败！')
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    }
  }
}
</script>
