<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;line-height: 48px;">
          样品信息
        </div>
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
      <a-col span="7">
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel" rowspan="2">样品信息</th>
          <td class="tdLabel">样品数量</td>
          <td colspan="5"><span style="color: red;font-size: 16px;">{{ sampleList.length }}</span></td>
        </tr>
        <tr>
          <td class="tdLabel">样品列表</td>
          <td colspan="5">
            <a-card>
              <a-row :gutter="[4,4]">
                <template v-for="(item,idx) in sampleList">
                  <a-col span="12" :key="idx" style="margin: 4px 0px">
                    <a-tag color="blue">{{ item.sampleId }} {{ item.itemName }}</a-tag>
                    <a @click="sampleList.splice(idx,1)">
                      <a-icon type="delete"></a-icon>
                    </a>
                  </a-col>
                </template>
              </a-row>
            </a-card>
            <template v-if="sampleList&&sampleList.length>0">
              <a-row>
                <a-col :span="12">
                  <a-button style="width: 100%; margin-top: 5px; margin-bottom: 8px" type="dashed" icon="plus" @click="selectSampleModal.show">选择样品</a-button>
                </a-col>
                <a-col :span="12">
                  <a-button style="width: 100%; margin-top: 5px; margin-bottom: 8px" type="primary" icon="save" @click="saveSample">保存</a-button>
                </a-col>
              </a-row>
              </template>
            <template v-else>
              <a-spin :spinning="btnSpinning">
                <a-button style="width: 100%; margin-top: 5px; margin-bottom: 8px" type="dashed" icon="plus" @click="selectSampleModal.show">选择样品</a-button>
              </a-spin>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <div style="color: red">说明：1、录入并确认样品移交信息；2、点击保存按钮进行保存移交信息；3、然后进行流程确认操作；
            </div>
          </td>
        </tr>
      </table>
    </a-row>
    <!-- 分包样品选择 -->
    <a-modal v-bind="selectSampleModal" v-on="selectSampleModal">
      <SelectSampleList :ref="selectSampleModal.refContent" :subtaskId="subtask.id" :itemIds="itemIds" :projId="subtask.projId"/>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="selectSampleModal.cancel()">关闭</a-button>
          <a-button type="primary" @click="selectSampleModal.ok()">确定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { projSubcontractTaskApi } from '@/api/project'
import SelectSampleList from './SelectSampleList'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'SubTaskApplySampleForm',
  components: {
    SelectSampleList
  },
  props: {
    subtask: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      btnSpinning: false,
      // 分包项ID
      itemIds: [],
      sampleList: [],

      selectSampleModal: Object.assign({}, fullModalData, {
        title: '样品选择',
        ref: 'selectSampleModal',
        refContent: 'selectSampleModalContent',
        data: {
          params: {
          }
        },
        show: (r) => {
          this.selectSampleModal.visible = true
        },
        ok: () => {
          this.$nextTick(() => {
            var list = this.$refs[this.selectSampleModal.refContent].getSelected()
            if (list && list.length > 0) {
              this.sampleList = list
              this.selectSampleModal.visible = false
              this.$message.success(`共选择样品 ${list.length} 个`)
            } else {
              this.$message.warning('没有选择样品！')
            }
          })
        },
        cancel: () => {
          this.selectSampleModal.visible = false
        }
      })

    }
  },
  methods: {
    saveSample () {
      if (this.sampleList && this.sampleList.length > 0) {
        this.btnSpinning = true
        projSubcontractTaskApi.saveSample(this.sampleList).then(res => {
          if (res.code === 0) {
            this.$message.success(`操作成功`)
            this.$emit('ok')
          } else {
            this.$message.error(`操作失败`)
          }
        }).catch(error => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        }).finally(() => {
          this.btnSpinning = false
        })
      } else {
        this.$message.warning(`没有选择分包样品`)
      }
    },
    listSample () {
      if (this.subtask.id) {
        projSubcontractTaskApi.listSample({ taskId: this.subtask.id }).then(res => {
          if (res.code === 0) {
            this.sampleList = res.data || []
          }
        })
      } else {
        this.sampleList = []
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    initInfo () {
    }
  },
  watch: {
    'subtask.cateIdItemIdList': {
      handler (nv, ov) {
        if (nv) {
          var itemIds = []
          nv.forEach((it, idx) => {
            var arr = it.split('_')
            itemIds.push(arr[1])
          })
          this.itemIds = itemIds
        } else {
          this.itemIds = []
        }
      },
      immediate: true
    },
    'subtask.id': {
      handler (nv, ov) {
        if (nv) {
          this.listSample()
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  mounted () {
    this.initInfo()
  },
  created () {
  }
}
</script>
<style lang="less">
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    margin-top: -1px;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style lang="less" scoped>
</style>
