export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '休息一会儿吧', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj (obj) {
  if (!(typeof obj === 'object')) {
    return
  }

  for (var key in obj) {
    if (obj.hasOwnProperty(key) &&
      (obj[key] == null || obj[key] === undefined || obj[key] === '')) {
      delete obj[key]
    }
  }
  return obj
}

export function filterNullObj (obj) {
  if (!(typeof obj === 'object')) {
    return
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key) &&
      (obj[key] == null || obj[key] === undefined)) {
      delete obj[key]
    }
  }
  return obj
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate (value, fmt) {
  var regPos = /^\d+(\.\d+)?$/
  if (regPos.test(value)) {
    // 如果是数字
    const getDate = new Date(value)
    const o = {
      'M+': getDate.getMonth() + 1,
      'd+': getDate.getDate(),
      'h+': getDate.getHours(),
      'm+': getDate.getMinutes(),
      's+': getDate.getSeconds(),
      'q+': Math.floor((getDate.getMonth() + 3) / 3),
      'S': getDate.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  } else {
    // TODO
    value = value.trim()
    return value.substr(0, fmt.length)
  }
}

export function formatTimeDiff (time) {
  if (time) {
    // 时间差
    // 苹果手机端日期格式不支持 “-” 日期分割符
    time = time.replace(/-/g, '/')
    let diffValue = new Date(time).getTime() - new Date().getTime()
    const minute = 1000 * 60
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    let surf = '后'
    if (diffValue < 0) {
      surf = '前'
      diffValue = -diffValue
    }
    const monthC = diffValue / month
    const weekC = diffValue / (7 * day)
    const dayC = diffValue / day
    const hourC = diffValue / hour
    const minC = diffValue / minute

    if (monthC >= 1) {
      return `${parseInt(monthC)}个月${surf}`
    } else if (weekC >= 1) {
      return `${parseInt(weekC)}周${surf}`
    } else if (dayC >= 1) {
      return `${parseInt(dayC)}天${surf}`
    } else if (hourC >= 1) {
      return `${parseInt(hourC)}小时${surf}`
    } else if (minC >= 1) {
      return `${parseInt(minC)}分钟${surf}`
    } else {
      return '刚刚'
    }
  }
  return ''
}

export function timeDiff (time1, time2) {
  var text = ''
  if (time1 && time2) {
    // 时间差
    // 苹果手机端日期格式不支持 “-” 日期分割符
    time1 = time1.replace(/-/g, '/')
    time2 = time2.replace(/-/g, '/')
    let diffValue = new Date(time1).getTime() - new Date(time2).getTime()
    diffValue = Math.abs(diffValue)
    const minute = 1000 * 60
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    const monthC = diffValue / month
    const dayC = (diffValue % month) / day
    const hourC = ((diffValue % month) % day) / hour
    const minC = (((diffValue % month) % day) % hour) / minute
    // 时间格式化为  n个月m天/n天m小时/n小时m分钟
    if (monthC >= 1) {
      text += `${parseInt(monthC)}个月`
      if (dayC >= 1) {
        text += `${parseInt(dayC)}天`
      }
    } else if (dayC >= 1) {
      text += `${parseInt(dayC)}天`
      if (hourC >= 1) {
        text += `${parseInt(hourC)}小时`
      }
    } else if (hourC >= 1) {
      text += `${parseInt(hourC)}小时`
      if (minC >= 1) {
        text += `${parseInt(minC)}分钟`
      }
    } else if (minC >= 1) {
      text += `${parseInt(minC)}分钟`
    } else {
      text += `1分钟`
    }
    return text
  }
  return ''
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject (obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber () {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  if (arguments.length === 1) {
    const [length] = arguments
    // 生成指定长度的随机数字，首位一定不是 0
    const nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)))
    return parseInt(nums.join(''))
  } else if (arguments.length >= 2) {
    const [min, max] = arguments
    return random(min, max)
  } else {
    return Number.NaN
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString (length, chats) {
  if (!length) length = 1
  if (!chats) chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
  let str = ''
  for (let i = 0; i < length; i++) {
    const num = randomNumber(0, chats.length - 1)
    str += chats[num]
  }
  return str
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID () {
  const chats = '0123456789abcdef'
  return randomString(32, chats)
}

/**
 * 下划线转驼峰
 * @param string
 * @returns {*}
 */
export function underLine2CamelCase (string) {
  return string.replace(/_([a-z])/g, function (all, letter) {
    return letter.toUpperCase()
  })
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

/**
 * 将标准的LIST options 转换成map value:label
 */
export function tranOptionsToMap (options = []) {
  return options.reduce((acc, item) => {
    acc[item.value] = item.label
    return acc
  }, {})
}

/**
 * 将标准的LIST options 转换成map value:data
 */
export function tranOptionsToDataMap (options = []) {
  return options.reduce((acc, item) => {
    acc[item.value] = item
    return acc
  }, {})
}

/**
 * 对象KEY ，VALUE互换
 */
export function swapObject (data) {
  return Object.keys(data).reduce((init, key) => {
    init[data[key]] = key
    return init
  }, {})
}

/**
 * 格式化金额
 * @param value
 * @param currency 货币符号
 * @param decimals 保留小数位
 * @returns {string}
 */
export function currency (value, currency, decimals) {
  const digitsRE = /(\d{3})(?=\d)/g
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : '￥'
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  var i = _int.length % 3
  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  var _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  var sign = value < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}

export const optionalChaining = (obj, ...rest) => {
  let tmp = obj
  for (const key in rest) {
    const name = rest[key]
    tmp = tmp?.[name]
  }
  return tmp || ''
}

export function removeSpecialSymbol (value) {
  // var res = value.replace(/[`~!@#$%^&*+=?:"{}|,./;'\\[\]·~！@#￥%……&*+={}|？：【】；，。]/g, '').replace(/\s/g, '')
  // 取消 ".·" 的限制
  return value.replace(/[`~!@$%^&*+=?:"{}|,;'\\[\]~！@￥%……&*+={}|？：【】；，。]/g, '').replace(/\s/g, '')
}

export const formatCodeVCMap = (map = {}) => {
  const colors = ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple']
  let idx = 0
  const clen = colors.length
  const newMap = {}
  for (const k in map) {
    newMap[k] = { value: map[k], color: colors[idx % clen] }
    idx = idx + 1
  }
  return newMap
}

export function trim (str) {
  if (str) {
    str = str.replace(/(^\s*)|(\s*$)/g, '')
  }
  return str
}

export function ltrim (str) {
  if (str) {
    str = str.replace(/(^\s*)/g, '')
  }
  return str
}

export function rtrim (str) {
  if (str) {
    str = str.replace(/(\s*$)/g, '')
  }
  return str
}

/**
 * 文件 UID生成方法
 * @returns {string} 文件的唯一标识
 */
export function uidGenerator () {
  return '-' + parseInt(Math.random() * 10000 + 1 + '', 10)
}

/**
 * 根据文件地址获取文件名称
 * @param path 文件地址
 * @returns {string} 文件名称
 */
export function getFileName (path) {
  if (path.lastIndexOf('\\') >= 0) {
    const reg = new RegExp('\\\\', 'g')
    path = path.replace(reg, '/')
  }
  return path.substring(path.lastIndexOf('/') + 1)
}

export default {
  timeFix,
  welcome,
  filterObj,
  filterNullObj,
  formatDate,
  cloneObject,
  randomNumber,
  randomString,
  randomUUID,
  underLine2CamelCase,
  triggerWindowResizeEvent,
  handleScrollHeader,
  removeLoadingAnimate,
  tranOptionsToMap,
  tranOptionsToDataMap,
  currency,
  optionalChaining,
  removeSpecialSymbol,
  formatTimeDiff,
  formatCodeVCMap,
  trim,
  ltrim,
  rtrim,
  uidGenerator,
  getFileName
}
