<template>
  <a-modal v-bind="fullModal" v-on="fullModal">
    <iframe :src="formSrc" v-bind="form" v-if="formSrc"></iframe>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import fullModal from '@/pages/common/mixins/modal/full'
import { fileTemplateUreportApi } from '@/api/fileTemplate'

export default {
  mixins: [fullModal],

  props: {
    // ids 和 id 选择传递一个即可
    ids: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    id: {
      type: String,
      required: false,
      default: null
    },

    solutionId: {
      type: String,
      required: false,
      default: ''
    },

    templateFile: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      drawerVisible: false,

      checkPwd: null,

      // 复核数据
      reviewData: {
        reviewStatus: null,
        reviewNote: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      fullModal: {
        title: '标线配置详情',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },

        showReview: () => {
          this.reviewDrawer.visible = true
        }
      }
    }
  },

  computed: {
    formSrc () {
      if (this.id) {
        return `${fileTemplateUreportApi.preview}?_u=${this.templateFile}&id=${this.id}&solutionId=${this.solutionId}`
      } else if (this.ids.length) {
        return `${fileTemplateUreportApi.preview}?_u=${this.templateFile}&ids=${this.ids.join(',')}&solutionId=${this.solutionId}`
      }
      return null
    }
  },

  methods: {

    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>
