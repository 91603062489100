<template>
  <div class="check-task-batch-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-task-batch-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table v-bind="contentTable" v-on="contentTable.action" :rowSelection="customRowSelection" :scroll="scrollProp">
      <template #batchNo="item, record">
        <a-tooltip>
          <template #title>
            <p>检测员：{{ record.createUserName }}</p>
            <p>
              检测项：
              <a-tag color="blue">{{ record.cateName }} / {{ record.itemName }}</a-tag>
            </p>
          </template>
          {{ item }}
        </a-tooltip>
      </template>

      <template #itemName="item, record">
        <a-tag color="blue">{{ record.cateName }}/{{ item }}</a-tag>
      </template>

      <template #createUserName="item, record">
        <a-tooltip :overlayStyle="{ minWidth: 'max-content' }">
          <template #title>
            <user-base-info :username="record.createUser"></user-base-info>
          </template>
          {{ item }}
        </a-tooltip>
      </template>

      <template #makeBeginTime="item">
        {{ item | nullFilter }}
      </template>
      <template #batchTime="item">
        {{ moment(item).add(10, 'm').format('YYYY-MM-DD HH:mm:ss') | nullFilter }}
      </template>

      <template #projInfo="_, record">
        <template v-if="record.projNoStr">
          <a-tooltip>
            <template #title>
              <a-tag color="blue" v-for="item in record.projNoStr.split(',')" :key="item">{{ item }}</a-tag>
            </template>
            <a-badge :count="record.projNoStr.split(',').length" :number-style="{ backgroundColor: '#52c41a' }" />
          </a-tooltip>
        </template>
      </template>

      <template #taskStat="_, record">
        <a-tooltip>
          <template #title>
            完成数：{{ record.taskDoneNum ?? 0 }} <br />
            总任务数：{{ record.taskSum }}
          </template>
          <a-progress :percent="Math.floor(((record.taskDoneNum / record.taskSum) * 100).toFixed(2))" :style="{ width: '90%' }"></a-progress>
        </a-tooltip>
      </template>

      <template #verifyPass="text">
        <template v-if="text">
          <a-tag color="green">通过</a-tag>
        </template>
        <template v-else>
          <a-tag color="gray">待验证</a-tag>
        </template>
      </template>

      <template #batchId="item, record">
        <template v-if="record.id">
          <a-tooltip :overlayStyle="{ minWidth: 'max-content' }">
            <template #title>
              <p>
                检测标准：
                <a-tag color="blue">{{ record.standardName }} 【{{ record.standardCode }}】</a-tag>
              </p>
              <p>
                检测方法：
                <a-tag color="blue">{{ record.methodName }}</a-tag>
              </p>
            </template>
            <a-tag color="blue">已准备</a-tag>
          </a-tooltip>
        </template>
        <template v-else>
          <a-tag color="gray">待准备</a-tag>
        </template>
      </template>

      <template #dataStatus="item, record">
        <template v-if="record.resultSum">
          <template v-if="record.pendingResultNum || record.brokenResultNum">
            <template v-if="record.pendingResultNum">
              待录入：
              <a-tag color="red" @click="resultTableDrawer.action.show(record.id)">{{ record.pendingResultNum }}</a-tag>
            </template>

            <template v-if="record.brokenResultNum">
              待完善：
              <a-tag color="red" @click="resultTableDrawer.action.show(record.id)">{{ record.brokenResultNum }}</a-tag>
            </template>
          </template>

          <template v-else>
            <a-tag color="green" @click="resultTableDrawer.action.show(record.id)">已录入</a-tag>
            <a-tag color="green" @click="resultTableDrawer.action.show(record.id)">{{ record.resultSum }}</a-tag>
          </template>
        </template>

        <template v-else>
          <a-tag color="gray">未录入</a-tag>
        </template>
      </template>

      <template #record="item, record">
        <template v-if="record.recordId">
          <a-tag color="green" @click="showOriginalRecord(record.recordId, record.id)">已填写</a-tag>
        </template>
        <template v-else>
          <a-tag>待填写</a-tag>
        </template>
      </template>

      <template #reviewStatus="text">
        <a-tag :color="checkOriginalRecordReviewStatusDataMap[text].color">{{ checkOriginalRecordReviewStatusDataMap[text].label }}</a-tag>
      </template>

      <template #checkStatus="text">
        <a-tag :color="checkOriginalRecordCheckStatusDataMap[text].color">{{ checkOriginalRecordCheckStatusDataMap[text].label }}</a-tag>
      </template>

      <template v-for="(_, slot) of $scopedSlots" #[slot]="text, record, index, expanded">
        <slot v-bind="{ text: text, row: record, index: index, expanded }" :name="slot" />
      </template>
    </s-table>

    <a-drawer v-if="resultTableDrawer.visible" v-bind="resultTableDrawer" v-on="resultTableDrawer.action">
      <check-result-table v-bind="resultTableDrawer.data" />
    </a-drawer>
  </div>
</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { checkTaskBatchInfoDefaultColumn as defaultColumn } from './columns'

import {
  checkOriginalRecordCheckStatusDataMap,
  checkOriginalRecordCheckStatusEnum,
  checkOriginalRecordReviewStatusDataMap,
  checkOriginalRecordReviewStatusEnum
} from '@/api/check/checkOriginalRecordConstant'

import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'

import CheckResultTable from '@/pages/cloud-quote/check-task/modules/checkResultTable'
import { CustomizeColumn } from '@/components/Columns'
import { checkTaskResultSimpleColumn } from '@/pages/cloud-quote/check-task/modules/checkResultTable/columns'
import { checkOriginalRecordApi } from '@/api/check/checkOriginalRecordApi'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import UserBaseInfo from '@/pages/cloud-quote/person/UserBaseInfo'
import moment from 'moment'

export default {
  components: { UserBaseInfo, sTable, CustomizeColumn, CheckResultTable },
  mixins: [componentTable],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    defaultOtherHeight: { type: Number, required: false, default: 0 },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: {
      type: Array,
      required: false,
      default: () => {
        return defaultColumn
      }
    },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data() {
    return {
      checkOriginalRecordReviewStatusEnum,
      checkOriginalRecordReviewStatusDataMap,
      checkOriginalRecordCheckStatusEnum,
      checkOriginalRecordCheckStatusDataMap,
      defaultSorderParam: {
        sortField: 'MAKE_BEGIN_TIME',
        sortOrder: 'desc'
      },
      resultTableDrawer: {
        visible: false,
        width: 1080,
        closable: false,
        bodyStyle: { height: '100%' },
        data: {
          batchId: null,
          checkTaskStatus: '-1',
          columns: checkTaskResultSimpleColumn,
          showPagination: false
        },

        action: {
          show: (batchId) => {
            this.resultTableDrawer.data.batchId = batchId
            this.resultTableDrawer.visible = true
          },

          close: () => {
            this.resultTableDrawer.visible = false
          }
        }
      }
    }
  },

  computed: {
    queryParam() {
      return { ...this.$attrs }
    },

    customRowSelection() {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {
    moment,
    // 加载数据测试
    loadData(param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then((res) => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then((res) => res)
        }
      } else {
        if (param.sortField) {
          param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        }
        return checkBatchApi.list(Object.assign(this.defaultSorderParam, param, this.queryParam)).then((res) => res)
      }
    },

    // 查看原始记录表信息
    showOriginalRecord(id, batchId) {
      checkOriginalRecordApi.findDetail(id).then((res) => {
        if (res.code === 0) {
          const data = res.data
          const url = `${fileTemplateUreportApi.preview}?_u=${data.fileTemplateName}&id=${id}&templateId=${data.fileTemplateId}&batchId=${batchId}`
          window.open(url, '_blank')
        } else {
          this.$message.error('暂未查询到检测原始表')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
