<template>
  <div>
    <a-form>
      <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
        {{ this.purList.purPlanName }}
      </div>
      <div style="width: 100%;height: 100%;">
        <a-card :bordered="false" :bodyStyle="{'padding': '0'}">
          <a-row>
            <a-col span="3">
              <div class="ahmux-sub-title" style="width: 100px;">
                申请信息
              </div>
            </a-col>
            <a-col span="7">
              <div class="ahmux-sub-title-detail" style="width: 100%;">
                申请人：{{ this.purList.userName }}
              </div>
            </a-col>
            <a-col span="7">
              <div class="ahmux-sub-title-detail" style="width: 100%;">
                采购计划时间：{{ this.purList.dateOfApply }}
              </div>
            </a-col>
          </a-row>
          <a-row>
            <table class="mxtable">
              <tr>
                <td class="tdLabel">采购编号</td>
                <td>{{ this.purList.purNbr }}</td>
                <td class="tdLabel">期望采购日期</td>
                <td>{{ this.purList.dateOfExpPur }}</td>
              </tr>
              <tr>
                <td class="tdLabel">采购原因</td>
                <td>{{ this.purList.purReason }}</td>
                <td class="tdLabel">采购描述</td>
                <td>{{ this.purList.purDesc }}</td>
              </tr>
            </table>
          </a-row>
        </a-card>
        <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">
          <a-row>
            <a-col span="3">
              <div class="ahmux-sub-title" style="width: 100px;">
                商品信息
              </div>
            </a-col>
            <div style="color: red;float: right;margin-right: 30px;">总金额：<span title="总金额" style="font-size: 30px;"> ￥{{ this.purList.purPrice }} </span></div>
          </a-row>
          <a-row>
            <a-table
              ref="table"
              :columns="columns"
              :dataSource="goodsList"
              :pagination="false"
              class="mxtable"
              style="margin-bottom: 30px;"
            >
              <template v-for="(col, i) in ['materialName', 'speType','tecReq','goodsNum','unit','goodsMoney','goodsDesc']" :slot="col" slot-scope="text">
                <template v-if="col === 'unit'">
                  {{ materialUnitCode[text] }}
                </template>
                <template v-else-if="col === 'goodsMoney'">
                  <span title="预计单价" style="color: red;" :key="col+i"> ￥{{ text }} </span>
                </template>
                <template v-else-if="col === 'goodsDesc'">
                  <div style="max-height: 22px; overflow: auto" :key="col+i">{{ text }}</div>
                </template>
                <template v-else>
                  {{ text }}
                </template>
              </template>
            </a-table>
          </a-row>
        </a-card>
      </div>
    </a-form>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import { STable } from '@/components'
import { resPurPlanApi, Get } from '@/api/quote'
export default {
  name: 'PurplanIndex',
  props: {
    recordId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageView,
    STable
  },
  data () {
    return {
      visible: false,
      purList: [],
      goodsList: [],
      columns: [
        {
          title: '物品类别',
          dataIndex: 'materialName',
          key: 'materialName',
          width: '200px',
          scopedSlots: { customRender: 'materialName' }

        },
        {
          title: '规格型号',
          dataIndex: 'speType',
          key: 'speType',
          width: '110px',
          scopedSlots: { customRender: 'speType' }
        },

        {
          title: '技术要求',
          dataIndex: 'tecReq',
          key: 'tecReq',
          width: '150px',
          scopedSlots: { customRender: 'tecReq' }
        },
        {
          title: '数量',
          width: '70px',
          dataIndex: 'goodsNum',
          key: 'goodsNum',
          scopedSlots: { customRender: 'goodsNum' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: '70px',
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '预计单价',
          dataIndex: 'goodsMoney',
          key: 'goodsMoney',
          width: '70px',
          scopedSlots: { customRender: 'goodsMoney' }
        },
        {
          title: '备注',
          key: 'goodsDesc',
          dataIndex: 'goodsDesc',
          width: '150px',
          scopedSlots: { customRender: 'goodsDesc' }
        }
      ]
    }
  },

  computed: {
    materialUnitCode () {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },
  created () {
    this.initDictData()
  },
  watch: {
    recordId: {
      handler (newValue, oldValue) {
        if (newValue && newValue > 0) {
          this.handleDetail(newValue)
        }
      },
      immediate: true
    }
  },
  methods: {
    initDictData () {
      // 根据字典Code, 初始化字典数组
      this.$store.dispatch('code/select', 'material_unit_code')
    },
    handleCancel () {
      this.visible = false
    },
    handleDetail (id) {
      this.purList = []
      this.goodsList = []
      Get(`${resPurPlanApi.getGoodsById}/${id}`, {}, {}).then(result => {
        this.goodsList = result.data
      })
      Get(`${resPurPlanApi.list}`, { pageSize: 1, id: id }, {}).then(result => {
        this.purList = result.data[0]
      })
      this.visible = true
    }
  }

}
</script>

<style lang="less" scoped>
  .title {
    color: rgba(0,0,0,.85);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }
</style>
