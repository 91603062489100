<template>
  <a-modal v-bind="downloadModal" v-on="downloadModal">
    <ProjectTaskDownloadPreview v-bind="downloadModal.data" v-on="downloadModal.data" :cateList.sync="cateList" :type.sync="type"/>
    <template #footer>
      <div style="text-align: right">
        <a-button @click="downloadModal.cancel()">关闭</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import ProjectTaskDownloadPreview from './ProjectTaskDownloadPreview'
import fullModal from '@/pages/common/mixins/modal/full'
import { itemCategoryApi } from '@/api/quote'

const fullModalData = fullModal.data().fullModal
export default {
  name: 'ProjectTaskDownloadModal',
  components: {
    ProjectTaskDownloadPreview
  },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      downloadModal: Object.assign({}, fullModalData, {
        title: '下单表下载',
        data: {
          params: {
            projId: null,
            fileName: null
          }
        },
        show: (projId, projNo, projName) => {
          var params = { projId: projId, fileName: `${projNo}-${projName}下单表` }
          this.downloadModal.data.params = params
          this.downloadModal.visible = true
        },
        cancel: () => {
          this.downloadModal.visible = false
          this.cateList = []
          this.type = null
        }
      }),
      cateList: [],
      type: null
    }
  },
  methods: {
    show (projId, projNo, projName) {
      this.downloadModal.show(projId, projNo, projName)
      this.getCate(projId)
    },
    getCate (projId) {
      itemCategoryApi.getCate({ projId }).then((res) => {
        console.log(res)
        this.cateList = res
      })
    }
  }
}
</script>
