<template>
  <div>
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="综合查询">
              <a-input v-model.trim.lazy="queryParam.compositionName" placeholder="综合查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :style="{ textAlign: 'left',marginBottom:'15px' }">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>

        <a-row v-show="toggleSearchStatus" v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="ID">
              <a-input v-model.trim.lazy="queryParam.compositionName" placeholder="ID查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <div class="fr">
        <a-button @click="formModal.add()">配置</a-button>
      </div>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <check-reagent-table v-bind="customTable" v-on="customTable.action">

        <template #action="{index,row}">
          <a @click="deleteData(row)">作废</a>
        </template>
      </check-reagent-table>
    </a-card>

    <a-modal v-bind="formModal" v-on="formModal.action">
      <check-reagent-form v-bind="formModal.data" v-on="formModal.formAction"></check-reagent-form>
      <template #footer>
        <a-button @click="formModal.action.cancel()" :disabled="formModal.loading">取消</a-button>
        <a-button type="primary" @click="formModal.action.ok()" :loading="formModal.loading">确定</a-button>
      </template>
    </a-modal>

  </div>
</template>

<script>
import { withComponentList } from '@/pages/common/mixins/list'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import CheckReagentTable from '@/pages/cloud-quote/check-reagent/modules/table'

import { checkReagentApi } from '@/api/checkReagent/checkReagentApi'

export default {
  mixins: [withComponentList],
  components: { CheckReagentTable },
  props: Object.assign({}, CheckReagentTable.props),
  data () {
    return {
      queryParam: {
        compositionName: null
      },

      formModal: Object.assign({}, fullModalData, {
        title: '试剂配置',
        width: '50%',
        loading: false,

        // modal 事件
        action: {
          cancel: () => {
            this.formModal.loading = false
            this.formModal.cancel()
          },
          ok: () => {
            // this.formModal.loading = true
            this.$refs[this.formModal.data.ref].submit()
          }
        },

        // 绑定表单数据
        data: {
          ref: 'form'
        },

        // 绑定表单事件
        formAction: {
          success: () => {
            this.formModal.loading = false
            this.formModal.cancel()
            this.reload()
          },
          updateReagentLoading:(bool)=>{
            this.formModal.loading = bool
          }
        },

        // 进入新增界面
        add: () => {
          this.formModal.data.id = null
          this.formModal.show()
        },

        // 进入修改界面
        edit: ({ id }) => {
          this.formModal.data.id = id
          this.formModal.show()
        }

      }),

      contentCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: {},
        class: 'card-table'
      }
    }
  },
  computed: {
    customTable () {
      const isSetData = false
      const pagination = true
      const dataSource = []
      return { ref: 'table', ...this.$attrs, isSetData, pagination, dataSource, ...this.queryParam, ...this.$props }
    }
  },

  methods: {
    getSelectData () {
      return this.$refs[this.customTable.ref].getSelectData()
    },
    // 删除数据
    deleteData (data) {
      this.$confirm({
        title: '确认删除吗?',
        content: '您确认要删除这条数据吗？',
        onOk: () => {
          checkReagentApi.delete({ id: data.id }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
