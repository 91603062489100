import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 检测安排状态字典枚举
export const checkOrderStatusEnum = {
  // 待排单
  ready: '-1',
  // 已排单
  scheduled: '0',
  // 进行中
  processing: '1',
  // 已完成
  completed: '2'
}

//  检测安排状态选择项
export const checkOrderStatusOptions = [
  { label: '待排单', value: checkOrderStatusEnum.ready, color: 'gray' },
  { label: '已排单', value: checkOrderStatusEnum.scheduled, color: 'blue' },
  { label: '进行中', value: checkOrderStatusEnum.processing, color: 'orange' },
  { label: '已完成', value: checkOrderStatusEnum.completed, color: 'green' }
]

export const checkOrderStatusMap = tranOptionsToMap(checkOrderStatusOptions)
export const checkOrderStatusDataMap = tranOptionsToDataMap(checkOrderStatusOptions)
