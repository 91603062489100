<template>
  <div class="check-task-enter-hot-table">
    <hot-table ref="table" :colHeaders="true" :rowHeaders="true" :settings="hotSettings" language="zh-CN"></hot-table>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import HyperFormula from 'hyperformula'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import { registerLanguageDictionary, zhCN } from 'handsontable/i18n'

registerAllModules()
registerLanguageDictionary(zhCN)

export default {
  name: 'EnterHotTableVue',
  components: { HotTable },
  props: {
    columns: { type: Array, required: true },
    hiddenColumns: { type: Array, default: () => [] },
    colHeaders: { type: Array, required: true },
    tableData: { type: Array, default: () => [] },
    expressions: { type: Array, default: () => [] },
    tableKey: { type: String, default: '' }
  },

  data() {
    return {
      hotInstance: null,
      hyperFormula: null,
      resultData: [],
      editIndexes: []
    }
  },

  computed: {
    /** hot table 的配置信息 */
    hotSettings() {
      return {
        height: 'auto',
        stretchH: 'all',
        licenseKey: 'non-commercial-and-evaluation',
        columns: this.columns,
        allowRemoveCol: false,
        allowInsertCol: false,
        allowInsertRow: false,
        allowRemoveRow: false,
        multiColumnSorting: true,
        colHeaders: this.colHeaders,
        formulas: {
          engine: HyperFormula,
          sheetId: 1,
          sheetName: 'sheet'
        },
        hiddenColumns: {
          copyPasteEnabled: true,
          indicators: false,
          columns: this.hiddenColumns
        },
        afterChange: (changes, source) => {
          // console.log(changes, source)
          if (changes !== null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              // console.log(row, prop, oldValue, newValue)
              if (!this.editIndexes.includes(row)) {
                this.editIndexes.push(row)
              }
            })
            // console.log(this.dataArr[this.dateIdx].list)
          }
        }
      }
    },

    /** 实验室需要进行空白的字段 */
    labBlankAverageColumns() {
      return this.tableKey === 'labBlank' ? this.columns.filter((item) => item.labBlankAverageFlag) : []
    },

    /** 实验室需要进行空白的字段对应关系 */
    labBlankAverageColumnMap() {
      return this.labBlankAverageColumns.reduce((acc, item) => ({ ...acc, [item.data]: item }), {})
    },

    /** 实验室需要进行空白的字段 */
    labBlankAverageColumnKeys() {
      return this.labBlankAverageColumns.map((item) => item.data)
    },

    /** 公式计算的字段 */
    formulaColumns() {
      return this.columns.filter((item) => !!item.formula)
    },

    /** 公式计算的字段对应的KEY */
    formulaColumnKeys() {
      return this.formulaColumns.map((item) => item.data)
    },

    /** 公式计算的字段对应关系 */
    formulaColumnMap() {
      return this.formulaColumns.reduce((acc, item) => ({ ...acc, [item.data]: item }), {})
    }
  },

  watch: {
    expressions: {
      deep: true,
      handler(value) {
        // console.log(value)
        this.loadNamedExpression(value)
      }
    }
  },

  mounted() {
    this.hotInstance = this.$refs.table.hotInstance
    this.hyperFormula = this.hotInstance.getPlugin('formulas')
    this.labBlankAverageColumnKeys.length && this.hotInstance.addHook('afterChange', this.handleAfterChange)
    this.expressions.length && this.loadNamedExpression(this.expressions)
    this.tableData.length && this.loadData(this.tableData)
    this.initLabBlankAverage()
  },

  methods: {
    /**
     * 加载表格数据
     * @param data {*[]} 需要加载的数据
     */
    loadData(data) {
      if (this.hotInstance) {
        this.hotInstance.loadData(data)
        this.hotInstance.render()
        // 存在公式计算的字段，则给对应的第一个数据设置默认的公式
        const fillData = this.formulaColumns.length
          ? data.map((item, index) => {
              if (index === 0) {
                return Object.keys(item).reduce((data, key) => (this.formulaColumnKeys.includes(key) ? { ...data, [key]: this.formulaColumnMap[key].formula } : data), item)
              }
              return item
            })
          : data
        this.$nextTick(() => {
          this.hotInstance.loadData(fillData)
          // 存在需要进行公式计算的字段，进行列填充
          this.formulaColumns.forEach((item) => {
            const length = this.labBlankAverageColumnKeys.length ? this.tableData.length - 1 : this.tableData.length
            const fillRangeData = this.hyperFormula.engine.getFillRangeData(
              { start: { sheet: 0, row: 0, col: item.index }, end: { sheet: 0, row: 0, col: item.index } },
              { start: { sheet: 0, row: 1, col: item.index }, end: { sheet: 0, row: length - 1, col: item.index } }
            )
            fillRangeData.length && this.hyperFormula.engine.setCellContents({ sheet: 0, col: item.index, row: 1 }, fillRangeData)
          })
          this.hyperFormula.engine.doesSheetExist('sheet')
          this.hotInstance.render()
        })
      }
    },

    /**
     * 加载命名表达式
     * @param expressions 需要加载的命名表达式
     */
    loadNamedExpression(expressions) {
      // console.log('expressions', expressions)
      try {
        if (this.hyperFormula) {
          const hfInstance = this.hyperFormula.engine
          const existExpressions = hfInstance.listNamedExpressions()
          // console.log(existExpressions)
          existExpressions.forEach((item) => hfInstance.removeNamedExpression(item))
          expressions.forEach((item) => hfInstance.addNamedExpression(item.key, item.value))
          this.hotInstance.render()
        }
      } catch (err) {
        console.log(err)
        // this.$message.error(err.message)
      }
    },

    /** 获取表格中的数据  */
    getData() {
      const data = this.hotInstance.getData()
      const actualData = data.reduce((acc, item) => [...acc, this.columns.reduce((subAcc, subItem, subIdx) => ({ ...subAcc, [subItem.data]: item[subIdx] }), {})], [])
      // 如果需要进行平均计算的，则最后一条数据是平均数，不是需要的数据
      this.labBlankAverageColumnKeys.length && actualData.splice(actualData.length - 1, 1)
      this.resultData.splice(0, this.resultData.length, ...actualData)
      return actualData
    },

    /** 初始化空白平均数据 */
    initLabBlankAverage() {
      this.labBlankAverageColumns.forEach((item) => {
        const propDataArr = this.hotInstance.getDataAtProp(item.data)
        const key = item.labBlankAverageField
        const value = this.hyperFormula.engine.getCellValue({ sheet: 0, col: item.index, row: propDataArr.length - 1 })
        if (!Object.getPrototypeOf(value).constructor.name.includes('Error')) {
          if (typeof value !== 'object') {
            this.changeExpression(key, value)
          } else {
            this.changeExpression(key, value.value)
          }
        } else {
          this.changeExpression(key, null)
        }
      })
    },

    /**
     * 处理表格数据改变事件
     * @param changes {Array<[]>} 改变的数据
     */
    handleAfterChange(changes) {
      if (changes?.length) {
        changes.forEach((change) => {
          const [, prop] = change
          if (this.labBlankAverageColumnKeys.includes(prop)) {
            const column = this.labBlankAverageColumnMap[prop]
            const dataAtProp = this.hotInstance.getDataAtProp(prop)
            const fieldName = column.labBlankAverageField
            const value = dataAtProp[dataAtProp.length - 1]
            this.changeExpression(fieldName, value)
          }
        })
        this.hotInstance.render()
      }
    },

    /**
     * 设置表达式
     * @param key {String} 对应的常量名称
     * @param value {String} 对应的值
     */
    changeExpression(key, value) {
      if (key) {
        const existIdx = this.expressions.findIndex((item) => item.key === key)
        if (existIdx > -1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.expressions[existIdx].value = value
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.expressions.push({ key, value })
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/less/common/snippet.less';

.check-task-enter-hot-table {
  ::v-deep(.handsontable) {
    .color();
  }
}
</style>
