<template>
  <div>
    <a-input-search
      v-model="model.standardId"
      placeholder="请先选择标准"
      disabled
      @search="onSearchDepUser">
      <a-button slot="enterButton" :disabled="disabled">选择标准</a-button>
    </a-input-search>
    <select-standard-modal
      ref="selectModal"
      :modal-width="modalWidth"
      :check-type="checkType"
      @ok="onSearchDepUserCallBack" />
  </div>
</template>

<script>
import SelectStandardModal from './modal/SelectStandardModal'
export default {
  name: 'SelectStandard',
  components: { SelectStandardModal },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    checkType: {
      type: String,
      require: false,
      default: 'checkbox'
    }
  },
  data () {
    return {
      selectedStandardList: [],
      model: {  }
    }
  },
  mounted () {
    this.model.standardId = this.value
  },
  watch: {
    value (val) {
      this.model.standardId = val
    }
  },
  methods: {
    init () {
      this.model.standardId = ''
      this.selectedStandardList = []
    },
    onSearchDepUser () {
      this.$refs.selectModal.showModal(this.selectedStandardList)
    },
    setStandardList (selectedStandardList){
      this.onSearchDepUserCallBack(selectedStandardList)
    },
    onSearchDepUserCallBack (selectedStandardList) {
      this.selectedStandardList = selectedStandardList
      let standardIds = ''
      for (let i = 0; i < selectedStandardList.length; i++) {
        if (i == selectedStandardList.length - 1) {
          standardIds = standardIds + selectedStandardList[i].name
        } else {
          standardIds = standardIds + selectedStandardList[i].name + ','
        }
      }
      this.model.standardId = standardIds
      if (this.triggerChange) {
        this.$emit('change', selectedStandardList)
      } else {
        this.$emit('input', selectedStandardList)
      }
    }
  }
}
</script>

<style scoped>

</style>
