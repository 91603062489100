import { colSpanMap } from '@/pages/common/mixins/form/form'
import { computed, getCurrentInstance, nextTick, ref } from 'vue'
import { timeFormat } from '@/pages/common/mixins/date'
import { now } from '@/pages/common/compositions/date/useDate'
import { Form } from 'ant-design-vue'

/**
 * 创建表单布局
 * @return {{colProps: *, currentColSize: *, rowProps: {gutter: {xl: number, md: number, sm: number, xs: number, lg: number, xxl: number}, justify: string, type: string, align: string}, formItemLayout: {wrapperCol: {xl: {span: number}, md: {span: number}, sm: {span: number}, lg: {span: number}, xs: {span: number}, xxl: {span: number}}, labelCol: {xl: {span: number}, md: {span: number}, sm: {span: number}, lg: {span: number}, xs: {span: number}, xxl: {span: number}}}}}
 */
export const createBaseFormLayout = () => {
  /** 当前列数 */
  const currentColSize = ref(2)
  const rowProps = { type: 'flex', justify: 'start', align: 'bottom', gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 } }
  const defaultProps = { offset: 0, pull: 0, push: 0, style: { textAlign: 'left' } }
  const colProps = computed(() => {
    return Object.assign({}, defaultProps, colSpanMap[currentColSize.value])
  })
  const formItemLayout = {
    labelCol: {
      xxl: { span: 6 },
      xl: { span: 7 },
      lg: { span: 5 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    },
    wrapperCol: {
      xxl: { span: 18 },
      xl: { span: 17 },
      lg: { span: 19 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    }
  }

  return {
    rowProps,
    colProps,
    formItemLayout,
    currentColSize
  }
}

/**
 * 是否大于当前时间
 * @param current {moment.Moment} 当前时间
 * @return {boolean} 是否大于当前时间
 */
const moreCurrentDate = (current) => {
  return current && current > now()
}

/**
 *  是否小于当前时间
 * @param current {moment.Moment} 当前时间
 * @return {boolean} 是否小于当前时间
 */
const lessCurrentDate = (current) => {
  return current && current < now()
}

/**
 * 创建表单属性钩子
 * @return {{colProps: *, currentColSize: *, baseFormCard: {size: string, hoverable: boolean, style: {marginBottom: string}, type: string}, treeFilterOption: (function(*, *): boolean), rowProps: {gutter: {xl: number, md: number, sm: number, xs: number, lg: number, xxl: number}, justify: string, type: string, align: string}, filterOption: (function(*, *): boolean), fullWidthStyle: {width: string}, moreCurrentDatePickerProps: {showTime: boolean, format: string, style: {width: string}} & {disabledDate: (function(moment.Moment): *)}, lessCurrentDatePickerProps: {showTime: boolean, format: string, style: {width: string}} & {disabledDate: (function(moment.Moment): *)}, formItemLayout: {wrapperCol: {xl: {span: number}, md: {span: number}, sm: {span: number}, lg: {span: number}, xs: {span: number}, xxl: {span: number}}, labelCol: {xl: {span: number}, md: {span: number}, sm: {span: number}, lg: {span: number}, xs: {span: number}, xxl: {span: number}}}, defaultDatePickerProps: {showTime: boolean, format: string, style: {width: string}}}}
 */
export const useFormProps = () => {
  const fullWidthStyle = { width: '100%' }
  const { rowProps, colProps, currentColSize, formItemLayout } = createBaseFormLayout()

  const baseFormCard = { type: 'inner', hoverable: true, size: 'small', style: { marginBottom: '16px' } }

  const defaultDatePickerProps = {
    format: timeFormat,
    showTime: true,
    style: fullWidthStyle
  }

  /** 限制时间小于当前时间的配置 */
  const moreCurrentDatePickerProps = Object.assign({}, defaultDatePickerProps, {
    disabledDate: moreCurrentDate
  })

  /** 限制时间大于当前时间的配置 */
  const lessCurrentDatePickerProps = Object.assign({}, defaultDatePickerProps, {
    disabledDate: lessCurrentDate
  })

  /**
   *  select 过滤选项
   * @param input 输入的内容
   * @param option 选项
   * @return {boolean} 是否匹配
   */
  const filterOption = (input, option) => {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  /**
   * Tree Select 过滤选项
   * @param input 输入的内容
   * @param option 选项
   * @return {boolean} 是否匹配
   */
  const treeFilterOption = (input, option) => {
    return option.componentOptions.propsData.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return {
    fullWidthStyle,
    rowProps,
    colProps,
    currentColSize,
    formItemLayout,
    baseFormCard,
    defaultDatePickerProps,
    moreCurrentDatePickerProps,
    lessCurrentDatePickerProps,
    filterOption,
    treeFilterOption
  }
}

/**
 * 表单的 hook
 * @param decorators {object} 表单装饰器
 * @param formOptions  {object} 表单配置
 * @return {{form: *, setFormData: Function, setFormDataByKey: Function, getFormDataByKey: (function(string): *), setFormDataByDecorators: Function}}
 */
export const useForm = (decorators = {}, formOptions = {}) => {
  const { proxy: instance } = getCurrentInstance()
  const form = Form.createForm(instance)

  /**
   * 根据key获取表单数据
   * @param key {string} key
   * @return {*} 表单数据
   */
  const getFormDataByKey = (key) => {
    return form.getFieldValue(key)
  }

  /**
   * 根据key设置表单数据
   * @param key {string} key
   * @param value {*} 表单数据
   */
  const setFormDataByKey = (key, value) => {
    nextTick(() => {
      const result = {}
      result[key] = value
      form.setFieldsValue(result)
    })
  }

  /**
   * 根据指定的对象设置表单数据
   * @param data {object} 表单数据
   */
  const setFormData = (data) => {
    nextTick(() => {
      form.setFieldsValue(data)
    })
  }

  /**
   * 根据装饰器的key设置对应的表单数据
   * @param data {object} 表单数据
   */
  const setFormDataByDecorators = (data) => {
    nextTick(() => {
      const decoratorKeys = Object.keys(decorators)
      const newData = Object.keys(data).reduce((acc, key) => {
        decoratorKeys.includes(key) && (acc[key] = data[key])
        return acc
      }, {})
      form.setFieldsValue(newData)
    })
  }
  return {
    form,
    getFormDataByKey,
    setFormDataByKey,
    setFormData,
    setFormDataByDecorators
  }
}

/**
 * 外部表单的 hook
 * @param handleSuccess 成功回调
 * @param handleError 失败回调
 * @return {{formAction: {success: (function(*): *), error: (function(*): *)}}} 表单的回调
 */
export const useOutForm = (handleSuccess = null, handleError = null) => {
  const formAction = {
    success: (value) => handleSuccess?.(value),
    error: (value) => handleError?.(value)
  }
  return {
    formAction
  }
}
