/**
 * 创建操作按钮配置
 * @return {{actionButton: {style: {margin: string}, class: string}, actionCard: {bodyStyle: {padding: string}, hoverable: boolean, bordered: boolean, title: string, type: string, headStyle: {}, class: string}, contentCard: {bodyStyle: {padding: string}, hoverable: boolean, style: {marginTop: string}, bordered: boolean, title: string, type: string, headStyle: {}, class: string}}}
 */
export const useActionProps = () => {
  const actionCard = {
    title: '',
    bordered: true,
    hoverable: false,
    type: 'inner',
    headStyle: {},
    bodyStyle: { padding: '16px' },
    class: 'card-action'
  }

  const actionButton = {
    style: { margin: '0 0 0 12px' },
    class: 'fr'
  }

  const contentCard = {
    title: '',
    bordered: true,
    hoverable: false,
    type: 'inner',
    style: { marginTop: '0px' },
    headStyle: {},
    bodyStyle: { padding: '16px' },
    class: 'card-table'
  }

  return { actionCard, actionButton, contentCard }
}
