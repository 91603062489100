<template>
  <a-modal v-bind="searchStaffModal" v-on="searchStaffModal">
    <a-row v-bind="searchStaffRow">

      <a-col :md="24" :sm="24">
        <a-card hoverable>
          <!-- 查询区域 -->
          <div class="table-page-search-wrapper">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-input placeholder="请输入姓名" v-model="queryParam.name"></a-input>
              </a-col>
              <a-col :span="6">
                <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                  <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                  <a-button @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </div>

          <!-- table区域-begin -->
          <div>
            <a-table
              v-bind="flowTable"
              v-on="flowTable"
              :pagination="pagination"
              :loading="loading"
              :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
              :dataSource="dataSource">
              <template slot="jobRender" slot-scope="text,record">
                {{ stationFilter(text) }}
              </template>
              <template slot="statusRender" slot-scope="text, record">
                <template v-if="record.status === '0'">
                  <a-tag color="green">在职</a-tag>
                </template>
                <template v-else-if="record.status === '1'">
                  <a-tag color="gray">离职</a-tag>
                </template>
                <template v-else-if="record.status === '2'">
                  <a-tag color="red">废弃</a-tag>
                </template>
                <template v-else-if="record.status === '3'">
                  <a-tag color="orange">待入职</a-tag>
                  <template v-if="record.processInstanceId !== null">
                    <a-tag color="orange">流程审核中</a-tag>
                  </template>
                </template>
              </template>
            </a-table>
            <a-alert v-if="alertMessage" :message="alertMessage" banner />
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { groupBy } from 'lodash'

import { ListMixin } from '@/mixins/ListMixin'

import { resStaffApi, resStaffStationApi, Get } from '@/api/quote'
import { getAction } from '@/api/common'

export default {
  name: 'SearchStaffModal',

  mixins: [ListMixin],

  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    title: {
      type: String,
      default: '请选择人员',
      required: false
    }
  },

  data () {
    return {

      url: {
        list: resStaffApi.list
      },

      queryParam: {
        status: '3'
        // key: null,
        // startTime: null,
        // endTime: null
      },

      loading: false,
      dataSource: [],

      flowTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        rowKey: 'id',
        columns: [
          { title: '姓名', dataIndex: 'name' },
          { title: '性别', dataIndex: 'sex', customRender: v => v === '1' ? '女' : '男' },
          { title: '手机号码', dataIndex: 'phone' },
          { title: '岗位', dataIndex: 'job', scopedSlots: { customRender: 'jobRender' } },
          { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'statusRender' } }
        ]
      },

      searchStaffRow: {
        gutter: 10,
        style: { background: '#ECECEC', padding: '10px', margin: '-10px' }
      },

      searchStaffModal: {
        width: this.modalWidth,
        visible: false,
        title: this.title,
        ok: this.handleSubmit,
        cancel: this.close,
        cancelText: '关闭',
        style: { marginTop: '-70px' },
        wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      },
      alertMessage: '',
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        total: 0
      }
    }
  },
  // watch: {
  //   'searchStaffModal.visible': {
  //     handler (val) {
  //       if (val) {
  //         this.queryFlowTypeData()
  //       }
  //     }
  //   }
  // },
  mounted () {
    this.initStationList()
  },
  methods: {

    loadData (arg) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.pagination.current = 1
      }
      const params = this.getQueryParams()// 查询条件
      this.loading = true
      getAction(this.url.list, params).then((res) => {
        this.dataSource = res.data.data
        this.pagination.total = res.totalCount
        this.pagination.current = res.pageNo
        this.pagination.pageSize = res.pageSize
        this.loading = false
      })
    },

    onSelectChange (selectedRowKeys, selectionRows) {
      this.alertMessage = ''
      this.selectedRowKeys = selectedRowKeys.slice(selectedRowKeys.length - 1)
      this.selectionRows = selectionRows.filter(item => this.selectedRowKeys.includes(item.id))
      this.selectionRows.forEach(item => {
        if (item.status === '3' && item.processInstanceId !== null) {
          this.alertMessage = '该人员已提交入职审核，请重新选择'
          this.selectedRowKeys = []
          this.selectionRows = []
        }
      })
    },
    searchQuery () {
      this.loadData(1)
    },

    searchReset () {
      this.queryParam = { status: '3' }
      this.onClearSelected()
      this.loadData(1)
    },

    init (staffList) {
      if (staffList && staffList.length) {
        this.selectionRows.splice(0, this.selectionRows.length, ...staffList)
        this.selectedRowKeys.splice(0, this.selectionRows.length, ...(staffList.map(item => { return item.id })))
      }
      this.$nextTick(() => {
        this.searchStaffModal.visible = true
      })
    },
    initStationList () {
      Get(resStaffStationApi.listAll, { }).then((res) => {
        if (res.code === 0) {
          this.stationList = res.data
        } else {
          console.log(res.message)
        }
      })
    },

    handleSubmit () {
      this.$emit('ok', this.selectionRows)
      this.close()
    },

    close () {
      this.searchStaffModal.false = true
      this.$emit('cancel')
    },
    stationFilter (job) {
      if (job === null) return '-'
      const station = this.stationList.filter(it => it.id === job)
      if (station !== null && station.length > 0) return station[0]['stationName']; else return '-'
    }
  }
}
</script>
