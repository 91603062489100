<template>
  <a-modal v-bind="assignModal" v-on="assignModal">
    <select-user-by-dept :disabled="false" :triggerChange="true" @change="handleAssignUserChange" rowType="radio"/>
  </a-modal>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { checkStandardCurveApi } from '@/api/checkStandardCurve/checkStandardCurveApi'

export default {
  components: { SelectUserByDept },
  props: {
    ids: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    id: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      assignUser: null,
      assignModal: {
        title: '任务指派',
        width: 600,
        centered: true,
        visible: false,

        cancel: () => {
          this.assignModal.visible = false
          this.$emit('cancel')
        },

        ok: () => {
          if (!this.assignUser) {
            this.$message.error('请先选择指派人！')
            return false
          }

          if (!this.ids.length && !this.id) {
            this.$message.error('无有效的数据！')
            return false
          }

          const assignDataArr = this.ids.length && this.ids.map(item => Object.assign({}, { id: item, reviewUser: this.assignUser })) || []
          this.id && assignDataArr.push({ id: this.id, reviewUser: this.assignUser })

          checkStandardCurveApi.assign(assignDataArr).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.assignModal.visible = false
              this.$emit('success', this.assignUser)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      }
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },

  methods: {
    show () {
      this.assignModal.visible = true
    },

    handleAssignUserChange (userList) {
      const assignUser = userList?.[0]?.username
      if (assignUser === this.loginUser.username) {
        this.$message.error('不能指派给自己！')
        return false
      }
      this.assignUser = assignUser
    }
  }
}
</script>

<style scoped>

</style>
