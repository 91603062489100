<template>
  <a-modal v-bind="siteModal" v-on="siteModal" class="EditPointPosition">
    <el-table ref="itemLimitTale" :data="siteList" v-bind="tableContent" @row-click="rowClick">
      <el-table-column type="selection" width="100">
        <template slot-scope="scope">
          <el-radio :label="scope.row.siteId" v-model="newSiteId"><span></span></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="分类" prop="cateName" min-width="200" />
      <el-table-column label="点位" prop="siteName" min-width="200" />
    </el-table>
  </a-modal>
</template>
<script>
import { standardCopy } from '@/api/projectStandard/projectStandardApi'

var vm = {}
export default {
  name: 'CopyPointPosition',

  data() {
    vm = this
    return {
      // type: 'add',
      title: '复制点位',
      siteModal: {
        title: '复制点位信息',
        visible: false,
        // maskClosable: false,
        destroyOnClose: true,
        centered: true,
        cancel: this.handleCancel,
        ok: this.handleOK,
        width: '1000px',
      },
      visible: false,

      // 可复制的点位
      siteList: [],
      projId: '',
      cateId: '',
      // 现有点位id
      siteId: '',

      // 要复制的点位id
      newSiteId: '',

      tableContent: {
        border: true,
        size: 'mini',
        maxHeight: 400,
        stripe: true,
        rowKey: (data) => {
          return data.itemId + (data.id || data.attrValueId || '')
        },
        bordered: true,
      },
    }
  },
  methods: {
    getCate(siteObj, siteList) {
      this.projId = siteObj.projId
      this.cateId = siteObj.cateId
      this.siteId = siteObj.siteId
      this.siteList = siteList
      this.siteModal.visible = true
    },

    // 选择要复制的点位
    rowClick(row) {
      this.newSiteId = row.siteId
    },

    handleOK(e) {
      if (this.newSiteId == '') {
        this.$message.error(`未选择点位，请先选择点位`)
        return false
      }
      this.$confirm({
        title: '确认提交吗？',
        onOk: () => {
          standardCopy
            .Copy({
              projId: this.projId,
              cateId: this.cateId,
              siteIdCopy: this.siteId,
              siteId: this.newSiteId,
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(`复制点位成功`)
                this.newSiteId = ''
                this.siteModal.visible = false
                this.$emit('copyPointPosition')
              } else {
                this.$message.error(`复制点位出错：${res.msg}`)
              }
            })
        },
        onCancel() {},
      })
    },
    handleCancel() {
      this.newSiteId = ''
      this.siteModal.visible = false
    },
  },
  watch: {},
}
</script>
