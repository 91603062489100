<template>
  <div class="check-task-schedule">
    <a-card title="基本信息" v-bind="baseCard">
      <ul class="ul">
        <li>
          <div>
            <span class="title">检测员：</span>
            <span class="content">{{ loginUser.realname }}</span>
          </div>

          <div>
            <span class="title">检测内容：</span>
            <span class="content">{{ itemInfo.cateName }}/{{ itemInfo.itemName }}</span>
          </div>

          <div>
            <span class="title">温度：</span>
            <span class="content">
              <a-input-number v-model="baseForm.wd"></a-input-number>
            </span> ℃
          </div>

          <div>
            <span class="title">湿度：</span>
            <span class="content">
              <a-input-number v-model="baseForm.sd" :max="100" :min="0"></a-input-number>
            </span> %
          </div>
        </li>
      </ul>

      <sample-list v-model="sampleInfoList" :taskList="taskList" @success="sampleInfoListHandler"/>

    </a-card>

    <a-card title="检测方法" v-bind="baseCard">
      <ul class="ul">
        <li v-for="(checkGuide,index) in checkGuideDetailList" :key="`checkGuide_${index}`" :class="[{'checked':selectCheckGuide.id===checkGuide.id}]">
          <div>
            <span class="content">
              <template v-if="checkGuide.defaultFlag===1">
                <a-tag color="green">常用</a-tag>
              </template>
              <template v-else>
                <a-tag color="red">不常用</a-tag>
              </template>
            </span>
          </div>
          <div>
            <span class="title">检测标准:</span>
            <span class="content">{{ checkGuide.standardName }}   （{{ checkGuide.standardCode }}）</span>
          </div>

          <div>
            <span class="title">检测方法:</span>
            <span class="content">{{ checkGuide.methodName }}</span>
          </div>

          <div>
            <span class="title">检测模板：</span>
            <template v-if="checkGuide.templateName">
              <span class="content">{{ checkGuide.templateName }}</span>
            </template>
            <template v-else>
              <a-tag color="red">暂未配置检测原始记录模板</a-tag>
            </template>
          </div>

          <div>
            <span class="title">平行样：</span>
            <span class="content">
              <a-tag :color="qualityOptionDataMap[checkGuide.needParallel].color">{{ qualityOptionDataMap[checkGuide.needParallel].label }}</a-tag>
            </span>
          </div>

          <div>
            <span class="title">空白样：</span>
            <span class="content">
              <a-tag :color="qualityOptionDataMap[checkGuide.needBlank].color">{{ qualityOptionDataMap[checkGuide.needBlank].label }}</a-tag>
            </span>
          </div>

          <div>
            <span class="title">加标回收：</span>
            <span class="content">
              <a-tag :color="qualityOptionDataMap[checkGuide.needMarkup].color">{{ qualityOptionDataMap[checkGuide.needMarkup].label }}</a-tag>
            </span>
          </div>

          <div>
            <span class="title">标准曲线：</span>
            <span class="content">
              <a-tag :color="qualityOptionDataMap[checkGuide.needCurve].color">{{ qualityOptionDataMap[checkGuide.needCurve].label }}</a-tag>
            </span>
          </div>

          <div class="checkbox">
            <input
              :checked="selectCheckGuide.id===checkGuide.id"
              :disabled="checkGuideDetailList.length===1"
              name="checkGuide"
              type="radio"
              @change="handleSelectGuide($event,checkGuide)"/>
          </div>

        </li>
      </ul>
    </a-card>

    <a-card title="仪器设备" v-bind="baseCard">
      <template #extra>
        <template v-if="!selectCheckGuide">
          <span class="fr red">
            请先选择检测方法
          </span>
        </template>
        <template v-else-if="!selectDeviceInfos||!selectDeviceInfos.length">
          <span class="fr red">
            暂未配置检测设备
          </span>
        </template>
      </template>

      <ul class="ul">
        <li v-for="(device,index) in selectDeviceInfos" :key="`deviceInfo_${index}`" class="average">
          <div class="del-action">
            <a-tooltip>
              <template #title>
                如果未使用到此种设备，点我删除
              </template>
              <a @click="()=>selectDeviceInfos.splice(index,1)">
                <a-icon type="close-circle"/>
              </a>
            </a-tooltip>
          </div>
          <div>
            <span class="title">配置设备：</span>
            <span class="content">{{ device.devName }}</span>
          </div>

          <div v-if="!device.usageInfos||!device.usageInfos.length">
            <span class="title">使用设备：</span>
            <span class="content">
              <template v-if="deviceTypeMap[device.type] && deviceTypeMap[device.type].length">
                <a-select
                  allowClear
                  mode="multiple"
                  placeholder="请选择使用的设备"
                  style="width:300px"
                  @change="val =>handleDevChange(device,val)">
                  <a-select-option v-for="item in deviceTypeMap[device.type]" :key="item.id">【{{ item.fixedAssetsId }}】{{ item.devName }}</a-select-option>
                </a-select>
                <a style="margin-left: 5px" @click="resDevSelectModal.show(deviceTypeMap[device.type],device)">更多</a>
              </template>
            </span>
          </div>

          <div v-else style="flex:2">
            <div v-for="(item,_index) in device.usageInfos" :key="`devName_${_index}`" class="flex item">
              <div :style="{flex:3}">
                <a-tag color="blue">
                  <a-icon :style="{marginRight:'10px'}" type="check-circle"/>
                  {{ item.devName }}
                </a-tag>
              </div>
              <div :key="`devNo_${_index}`" style="flex:2"> 内部编号：<span :style="{color:'green',margin:'0 10px 0 5px',flex:1}">{{ item.fixedAssetsId }}</span></div>
              <div :key="`devTime_${_index}`" style="flex:3">
                <span :style="{margin:'0 10px'}">使用时间：</span>
                <a-date-picker
                  v-model="item.useDate"
                  :disabledDate="(current)=> current && current > moment()"
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime/>
              </div>
              <div :key="`devAction_${_index}`" :style="{flex:1}">
                <a @click="device.usageInfos.splice(_index,1)">移除</a>
              </div>
            </div>
          </div>

        </li>
      </ul>

    </a-card>

    <a-card title="试剂" v-bind="baseCard">

      <template #extra>
        <span :style="{margin:'0 0 0 20px'}" class="fr red">
          <a @click="reagentCateSelectTableModal.show">添加</a>
        </span>
        <template v-if="!selectCheckGuide">
          <span class="fr red">
            请先选择检测方法
          </span>
        </template>
        <template v-else-if="!checkGuideReagentList||!checkGuideReagentList.length">
          <span class="fr red">
            暂未配置使用试剂
          </span>
        </template>
      </template>

      <ul class="ul">
        <li v-for="(reagent,index) in checkGuideReagentList" :key="`checkGuideReagent_${index}`" class="average">
          <div class="del-action">
            <a-tooltip>
              <template #title>
                如果未使用到此种试剂，点我删除
              </template>
              <a @click="()=>checkGuideReagentList.splice(index,1)">
                <a-icon type="close-circle"/>
              </a>
            </a-tooltip>
          </div>

          <div>
            <span class="title">配置试剂：</span>
            <span class="content">{{ reagent.reagentCateName }}</span>
          </div>

          <div>
            <span class="title">预计用量：</span>
            <span class="content">
              <template v-if="reagent.num">
                <span class="green">{{ reagent.num }} </span> {{ reagent.unit }}
              </template>
              <template v-else>
                <span class="red">待定</span>
              </template>
            </span>
          </div>

          <div v-if="!reagent.cateUsageInfos||!reagent.cateUsageInfos.length">
            <span class="title">使用试剂：</span>
            <span class="content">
              <a @click="checkReagentSelectModal.show(reagent.reagentCateId,reagent)">请选择使用的试剂</a>
            </span>
          </div>

          <div v-else style="flex:2">
            <div v-for="(item,_index) in reagent.cateUsageInfos" :key="`actualNum_${_index}`" class="flex item">
              <div :style="{flex:2}">
                <a-tag color="blue">
                  <a-icon :style="{marginRight:'10px'}" type="check-circle"/>
                  {{ item.name }}
                </a-tag>
              </div>
              <div :style="{flex:1}"> 可用：<span :style="{color:'green',margin:'0 10px 0 5px'}">{{ item.volume }}</span>{{ item.unit }}</div>
              <div :style="{flex:2}">
                <span :style="{margin:'0 10px'}">本次使用：</span>
                <a-input-number
                  v-model="item.useVolume"
                  :max="item.volume"
                  :min="0"
                  :style="{width:'65px'}"
                  size="small"></a-input-number>
                {{ item.unit }}
              </div>
              <div :style="{flex:1}">
                <a @click="reagent.cateUsageInfos.splice(_index,1)">移除</a>
              </div>
            </div>
          </div>

        </li>
      </ul>
    </a-card>

    <a-card title="质控措施" v-bind="baseCard">

      <!--平行样-->
      <a-row :gutter="24" :style="{marginTop:0}" class="title-row">
        <a-col :span="10">
          <span class="title">平行样</span>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="2">
          <template v-if="selectCheckGuide">
            <a-tooltip :getPopupContainer="()=>$el">
              <template #title>
                数量： ≥ <span class="green">{{ selectCheckGuide.needParallelNum }}</span>
                <template v-if="selectCheckGuide.needParallelNumType!=='fix'"> %</template>
                <br/>
                参考：
                <a-tag>{{ selectCheckGuide.needParallelRefSource | qcRefSource }}</a-tag>
              </template>
              <a-tag :color="qualityOptionDataMap[selectCheckGuide.needParallel].color">{{ qualityOptionDataMap[selectCheckGuide.needParallel].label }}</a-tag>
            </a-tooltip>
          </template>
          <a :style="{marginLeft:'65px'}" @click="addParallel">
            <a-icon type="plus"></a-icon>
          </a>
        </a-col>
      </a-row>

      <div class="content-row">
        <template v-if="parallelList.length">
          <ul class="ul">
            <li v-for="(sampleInfo,index) in parallelList" :key="`parallel_${index}`" class="average">
              <div>
                <span class="title">
                  <template v-if="sampleInfo.editable">
                    平行：
                  </template>
                  <template v-else>
                    编号：
                  </template>
                </span>
                <span class="content">
                  <template v-if="sampleInfo.editable">
                    <a-select v-model="sampleInfo.id">
                      <a-select-option v-for="(item) in sampleInfoList" :key="item.id">{{ item.checkSampleId || item.id }}</a-select-option>
                    </a-select>
                  </template>
                  <template v-else>{{ sampleInfo.checkSampleId || sampleInfo.id }}</template>
                </span>
              </div>

              <div>
                <span class="title">分类:</span>
                <span class="content">
                  {{ sampleInfo.cateName }}
                </span>
              </div>

              <div>
                <span class="title">检测项:</span>
                <span class="content">
                  {{ sampleInfo.itemName }}
                </span>
              </div>

              <div v-if="sampleInfo.editable">
                <a @click="parallelList.splice(index,1)">移除</a>
              </div>
            </li>
          </ul>
        </template>

        <template v-else>
          <div class="data prompt">
            <div class="no-data">暂无数据</div>
          </div>
        </template>
      </div>

      <!--空白样-->
      <a-row :gutter="24" class="title-row">
        <a-col :span="10">
          <span class="title">空白样</span>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="2">
          <template v-if="selectCheckGuide">
            <a-tooltip :getPopupContainer="()=>$el">
              <template #title>
                数量： ≥ <span class="green">{{ selectCheckGuide.needBlankNum }}</span>
                <template v-if="selectCheckGuide.needBlankNumType!=='fix'"> %</template>
                <br/>
                参考：
                <a-tag>{{ selectCheckGuide.needBlankRefSource | qcRefSource }}</a-tag>
              </template>
              <a-tag :color="qualityOptionDataMap[selectCheckGuide.needBlank].color">{{ qualityOptionDataMap[selectCheckGuide.needBlank].label }}</a-tag>
            </a-tooltip>
          </template>
          <a :style="{marginLeft:'65px'}" @click="addBlank">
            <a-icon type="plus"></a-icon>
          </a>
        </a-col>
      </a-row>

      <div class="content-row">
        <template v-if="blankList.length">
          <ul class="ul">
            <li v-for="(sampleInfo,index) in blankList" :key="`blank_${index}`" class="average">
              <div>
                <span class="title">
                  <template v-if="sampleInfo.editable">
                    空白样{{ index + 1 }}
                  </template>
                  <template v-else>
                    {{ sampleInfo.checkSampleId }}
                  </template>
                </span>
              </div>

              <div>
                <span class="title">分类:</span>
                <span class="content">
                  {{ sampleInfo.cateName }}
                </span>
              </div>

              <div>
                <span class="title">检测项:</span>
                <span class="content">
                  {{ sampleInfo.itemName }}
                </span>
              </div>

              <div v-if="sampleInfo.editable">
                <a @click="blankList.splice(index,1)">移除</a>
              </div>
            </li>
          </ul>
        </template>

        <template v-else>
          <div class="data prompt">
            <div class="no-data">暂无数据</div>
          </div>
        </template>
      </div>

      <!--质控样-->
      <a-row :gutter="24" class="title-row">
        <a-col :span="10">
          <span class="title">质控样</span>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="2">
          <template v-if="selectCheckGuide">

            <a-tooltip :getPopupContainer="()=>$el">
              <template #title>
                数量： ≥ <span class="green">{{ selectCheckGuide.needQcNum }}</span>
                <template v-if="selectCheckGuide.needQcNumType!=='fix'"> %</template>
                <br/>
                参考：
                <a-tag>{{ selectCheckGuide.needQcRefSource | qcRefSource }}</a-tag>
              </template>
              <a-tag :color="qualityOptionDataMap[selectCheckGuide.needQc].color">{{ qualityOptionDataMap[selectCheckGuide.needQc].label }}</a-tag>
            </a-tooltip>
          </template>
          <a :style="{marginLeft:'65px'}" @click="addQualityControl">
            <a-icon type="plus"></a-icon>
          </a>
        </a-col>
      </a-row>

      <div class="content-row">
        <template v-if="qualityControlList.length">
          <ul class="ul">
            <li v-for="(sampleInfo,index) in qualityControlList" :key="`blank_${index}`" class="average">
              <div>
                <span class="title">
                  <template v-if="sampleInfo.editable">
                    质控样{{ index + 1 }}
                  </template>
                  <template v-else>
                    {{ sampleInfo.checkSampleId }}
                  </template>
                </span>
              </div>

              <div>
                <span class="title">分类:</span>
                <span class="content">
                  {{ sampleInfo.cateName }}
                </span>
              </div>

              <div>
                <span class="title">检测项:</span>
                <span class="content">
                  {{ sampleInfo.itemName }}
                </span>
              </div>

              <div v-if="sampleInfo.editable">
                <a @click="qualityControlList.splice(index,1)">移除</a>
              </div>
            </li>
          </ul>
        </template>

        <template v-else>
          <div class="data prompt">
            <div class="no-data">暂无数据</div>
          </div>
        </template>
      </div>

      <!--加标样-->
      <a-row :gutter="24" class="title-row">
        <a-col :span="10">
          <span class="title">加标回收</span>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="2">
          <template v-if="selectCheckGuide">

            <a-tooltip :getPopupContainer="()=>$el">
              <template #title>
                数量： ≥ <span class="green">{{ selectCheckGuide.needMarkupNum }}</span>
                <template v-if="selectCheckGuide.needMarkupNumType!=='fix'"> %</template>
                <br/>
                参考：
                <a-tag>{{ selectCheckGuide.needMarkupRefSource | qcRefSource }}</a-tag>
              </template>
              <a-tag :color="qualityOptionDataMap[selectCheckGuide.needMarkup].color">{{ qualityOptionDataMap[selectCheckGuide.needMarkup].label }}</a-tag>
            </a-tooltip>
          </template>
          <a :style="{marginLeft:'65px'}" @click="addAddStandard">
            <a-icon type="plus"></a-icon>
          </a>
        </a-col>
      </a-row>

      <div class="content-row">
        <template v-if="addStandardList.length">
          <ul class="ul">
            <li v-for="(sampleInfo,index) in addStandardList" :key="`blank_${index}`" class="average">
              <div>
                <span class="title">
                  <template v-if="sampleInfo.editable">
                    加标样{{ index + 1 }}
                  </template>
                  <template v-else>
                    {{ sampleInfo.checkSampleId }}
                  </template>
                </span>
              </div>

              <div>
                <span class="title">加标类型:</span>
                <span class="content">
                  <a-select v-model="sampleInfo.type" :disabled="!sampleInfo.editable">
                    <a-select-option :key="sampleInfoTypeDataMap[sampleInfoTypeEnum.addStandardSample].value">{{ sampleInfoTypeDataMap[sampleInfoTypeEnum.addStandardSample].label }}</a-select-option>
                    <a-select-option :key="sampleInfoTypeDataMap[sampleInfoTypeEnum.basisAddStandardSample].value">{{ sampleInfoTypeDataMap[sampleInfoTypeEnum.basisAddStandardSample].label }}</a-select-option>
                  </a-select>
                </span>
              </div>

              <div>
                <span class="title">分类:</span>
                <span class="content">
                  {{ sampleInfo.cateName }}
                </span>
              </div>

              <div>
                <span class="title">检测项:</span>
                <span class="content">
                  {{ sampleInfo.itemName }}
                </span>
              </div>

              <div v-if="sampleInfo.editable">
                <a @click="addStandardList.splice(index,1)">移除</a>
              </div>
            </li>
          </ul>
        </template>

        <template v-else>
          <div class="data prompt">
            <div class="no-data">暂无数据</div>
          </div>
        </template>
      </div>

    </a-card>

    <!--标准曲线-->
    <a-card title="标准曲线" v-bind="baseCard">
      <template #extra>
        <div class="fr">
          <template v-if="selectCheckGuide">
            <a-tooltip :getPopupContainer="()=>$el">
              <template #title>
                数量： ≥ <span class="green">{{ selectCheckGuide.needCurveNum }}</span>
                <template v-if="selectCheckGuide.needCurveNumType!=='fix'"> %</template>
                <br/>
                参考：
                <a-tag>{{ selectCheckGuide.needCurveRefSource | qcRefSource }}</a-tag>
              </template>
              <a-tag :color="qualityOptionDataMap[selectCheckGuide.needCurve].color">{{ qualityOptionDataMap[selectCheckGuide.needCurve].label }}</a-tag>
            </a-tooltip>
          </template>
          <a :style="{marginLeft:'65px'}" @click="curveTableSelectModal.show">
            <a-icon type="plus"></a-icon>
          </a>
        </div>
      </template>

      <template v-if="curveList.length">
        <template v-for="(curveInfo,index) in curveList">
          <a-row :key="`curve_title_${index}`" :gutter="24" class="title-row">
            <ul class="ul" style="border:none">
              <li class="average" style="border:none;padding: 0;margin: 0;">
                <div>
                  <span class="title">曲线编号:</span>
                  <span class="content">
                    {{ curveInfo.curveNo }}
                  </span>
                </div>

                <div>
                  <span class="title">配置人:</span>
                  <span class="content">
                    {{ curveInfo.createUserName }}
                  </span>
                </div>

                <div>
                  <span class="title">配置日期:</span>
                  <span class="content">
                    {{ curveInfo.makeDate || curveInfo.createTime }}
                  </span>
                </div>

                <div>
                  <span class="title">有效期:</span>
                  <span class="content">
                    <template v-if="!curveInfo.validTime">
                      <span class="orange"> 暂未配置</span>
                    </template>

                    <template v-else>
                      <span>{{ curveInfo.validTime }}</span>
                    </template>
                  </span>

                </div>

                <div>
                  <a @click="curveList.splice(index,1)">移除</a>
                </div>
              </li>
            </ul>
          </a-row>

          <div :key="`curve_content_${index}`" class="content-row">
            <ul class="ul">
              <template v-for="(curveVerifyInfo,cIndex) of curveVerifyMap[curveInfo.id]">
                <li :key="`curve_site_${cIndex}`" class="average">
                  <a-table :dataSource="curveInfo.drawList" v-bind="curveDrawTable">
                    <template v-for="(col) in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text">
                      {{ text }}
                    </template>
                  </a-table>
                </li>
                <li :key="`curve_verify1_${cIndex}`" class="average">
                  <div>
                    <span class="title">点位号:</span>
                    <span class="content">
                      <a-input-number v-model="curveVerifyInfo.siteNo" :min="0"></a-input-number>
                    </span>
                  </div>

                  <div>
                    <span class="title">配置时间:</span>
                    <span class="content">
                      <a-date-picker
                        v-model="curveVerifyInfo.makeDate"
                        :disabledDate="(current)=> current && current > moment()"
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime/>
                    </span>
                  </div>

                  <div>
                    <span class="title">标准值(X):</span>
                    <span class="content">
                      <a-input-number v-model="curveVerifyInfo.standardCurveAbsorbance" @change="calcRD(cIndex, curveInfo.id)"></a-input-number>
                    </span>
                  </div>

                  <div>
                    <span class="title">现测值(Y):</span>
                    <span class="content">
                      <a-input-number v-model="curveVerifyInfo.siteAbsorbance" @change="calcRD(cIndex, curveInfo.id)"></a-input-number>
                    </span>
                  </div>

                  <div>
                    <span class="title">相对偏差:</span>
                    <span class="content">
                      <a-input-number v-model="curveVerifyInfo.relativeDeviation"></a-input-number>  %
                    </span>
                  </div>

                </li>
                <li :key="`curve_verify2_${cIndex}`" class="average">
                  <div>
                    <span class="content">
                      <a-textarea v-model="curveVerifyInfo.note" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="备注信息"></a-textarea>
                    </span>
                  </div>
                </li>

              </template>
            </ul>
          </div>
        </template>

      </template>
      <template v-else>
        <div class="data prompt">
          <div class="no-data">暂无数据</div>
        </div>
      </template>

    </a-card>

    <a-card title="图谱" v-bind="baseCard">
      <j-upload v-model="baseForm.atlas"/>
    </a-card>

    <!-- 设备使用选择-->
    <a-modal v-bind="resDevSelectModal">
      <res-dev-table v-bind="resDevSelectModal.data" v-on="resDevSelectModal.tableAction"></res-dev-table>
      <template #footer>
        <a-button @click="resDevSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="resDevSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 试剂使用选择-->
    <a-modal v-bind="checkReagentSelectModal">
      <check-reagent-list v-bind="checkReagentSelectModal.data" v-on="checkReagentSelectModal.tableAction"></check-reagent-list>
      <template #footer>
        <a-button @click="checkReagentSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="checkReagentSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 标准曲线选择 -->
    <check-standard-curve-table-modal v-bind="curveTableSelectModal" v-on="curveTableSelectModal"></check-standard-curve-table-modal>

    <!-- 新增检测原始表 -->
    <a-modal v-if="checkOriginalRecordModal.visible" v-bind="checkOriginalRecordModal" v-on="checkOriginalRecordModal.action">
      <original-record v-bind="checkOriginalRecordModal.data" v-on="checkOriginalRecordModal.dataAction"></original-record>
    </a-modal>

    <!-- 试剂类型选择 -->
    <a-modal v-bind="reagentCateSelectTableModal" v-on="reagentCateSelectTableModal.action">
      <check-reagent-cate-list v-bind="reagentCateSelectTableModal.data" v-on="reagentCateSelectTableModal.dataAction"/>
    </a-modal>

  </div>
</template>

<script>

import { groupBy, orderBy, throttle } from 'lodash'
import mixinDate from '@/pages/common/mixins/date'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import { checkReagentSelectColumn } from '@/pages/cloud-quote/check-reagent/modules/table/columns'
import { CheckReagentCateSelectColumn as checkReagentCateColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'

import { getAction } from '@/api/common'
import { tsSampleinfoApi } from '@/api/sample'
import { checkTaskApi, checkTaskBatchApi } from '@/api/check/checkTaskApi'
import { itemCheckDetailApi, resDevApi } from '@/api/quote'

import { itemCheckDetailReagentApi } from '@/api/standard/itemCheckDetailApi'

import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { qualityOptionDataMap, qualityOptionEnum } from '@/api/itemCheckDetail/itemCheckDetailConstant'
import { labQcTypeEnum, sampleInfoStatusEnum, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

import { CheckReagentTable } from '@/pages/cloud-quote/check-reagent/modules'
import CheckReagentCateList from '@/pages/cloud-quote/check-reagent/cate/List'
import CheckReagentList from '@/pages/cloud-quote/check-reagent/SelectList'
import ResDevTable from '@/pages/cloud-quote/res/res-dev/modules/ResDevTable'
import { CheckStandardCurveTableModal } from '@/pages/cloud-quote/check-standard-curve/modules'
import OriginalRecord from '@/pages/cloud-quote/check-task/modules/originalRecord/index'
import JUpload from '@/components/j/JUpload'
import SampleList from '@/pages/cloud-quote/check-task/modules/schedule/SampleList'

import { checkStandardCurveCheckStatusEnum } from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { noActionCurveDrawColumns } from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import Moment from 'moment'

export default {
  name: 'Index',
  components: { CheckReagentTable, CheckReagentList, ResDevTable, CheckStandardCurveTableModal, OriginalRecord, JUpload, CheckReagentCateList, SampleList },
  mixins: [mixinDate],

  props: {
    taskList: { type: Array, required: true },
    groupId: { type: Number, required: true },
    id: { type: Number, required: false, default: null },
    makeBeginTime: { type: Object, required: false, default: null },
    makeEndTime: { type: Object, required: false, default: null },
    checkDetailId: { type: Number, required: false, default: null },
    wd: { type: Number, required: false, default: null },
    sd: { type: Number, required: false, default: null },
    autoEnterOriginRecord: { type: Boolean, required: false, default: true },
    columns: { type: Array, required: false, default: () => (noActionCurveDrawColumns) }
  },

  data () {
    return {
      qualityOptionEnum,
      qualityOptionDataMap,
      sampleInfoTypeEnum,
      sampleInfoTypeDataMap,
      reagentCateMaterialTypeEnum,
      checkStandardCurveCheckStatusEnum,
      noActionCurveDrawColumns,
      // 样品信息
      sampleInfoList: [],

      // 检测明细ID
      checkGuideDetailList: [],

      // 设备信息
      deviceInfos: [],

      deviceTypeMap: {},

      selectDeviceInfos: [],

      // 选中的检测明细
      selectCheckGuide: null,

      // 对应配置的试剂
      checkGuideReagentList: [],

      // 平行样
      parallelList: [],

      // 空白样
      blankList: [],

      // 质控样
      qualityControlList: [],

      // 加标样
      addStandardList: [],

      // 标准曲线
      curveList: [],

      // 曲线单点校验
      curveVerifyList: [],

      baseForm: {
        id: this.id,
        groupId: this.groupId,
        makeBeginTime: this.makeBeginTime,
        checkDetailId: this.checkDetailId,
        makeEndTime: this.makeEndTime,
        wd: this.wd,
        sd: this.sd,
        atlas: null
      },

      checkTaskDateRange: {
        showTime: { format: 'HH:mm' },
        format: 'YYYY-MM-DD HH:mm',
        placeholder: ['开始时间', '截止时间']
      },

      resDevSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        destroyOnClose: true,
        closable: false,
        title: '请选择使用设备',
        currentResDev: null,
        show: (dataSource, row) => {
          this.resDevSelectModal.data.dataSource.splice(0, this.resDevSelectModal.data.dataSource.length, ...dataSource)
          this.resDevSelectModal.currentResDev = row
          this.resDevSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.resDevSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.resDevSelectModal.data.rowSelect.selectedRows
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const resDevData = this.resDevSelectModal.currentResDev
            this.selectDevice(resDevData, selectData)
            this.resDevSelectModal.data.rowSelect.selectedRows.splice(0, this.resDevSelectModal.data.rowSelect.selectedRows.length)
            this.resDevSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'resDevTable',
          dataSource: [],
          isSetData: true,
          rowSelect: {
            type: 'checkbox',
            selectedRows: []
          },
          pagination: true
        },

        tableAction: {}

      },

      checkReagentSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        title: '请选择使用的试剂',
        currentMaterialData: null,
        bodyStyle: { background: '#F0F2F5' },
        destroyOnClose: true,

        show: (materialId, row) => {
          this.checkReagentSelectModal.data.cateId = materialId
          this.checkReagentSelectModal.data.validCheckTime = this.dateFormat(this.moment(this.baseForm.makeBeginTime))
          this.checkReagentSelectModal.currentMaterialData = row
          this.checkReagentSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.checkReagentSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const materialData = this.checkReagentSelectModal.currentMaterialData
            if (!Object.prototype.hasOwnProperty.call(materialData, 'cateUsageInfos')) {
              this.$set(materialData, 'cateUsageInfos', [])
            }
            materialData.cateUsageInfos.splice(0, materialData.cateUsageInfos.length, ...selectData.map(item => ({
              id: item.id,
              name: item.name,
              type: this.reagentCateMaterialTypeEnum.cate,
              sumVolume: item.sumVolume,
              volume: item.volume,
              unit: item.unit,
              useVolume: item.volume > materialData.num ? materialData.num : item.volume
            })))
            this.checkReagentSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentSelectTable',
          cateId: null,
          selectable: true,
          pagination: true,
          validCheckTime: null,
          columns: checkReagentSelectColumn
        },

        tableAction: {}

      },

      reagentCateSelectTableModal: Object.assign({}, fullModalData, {
        title: '请选择试剂类型',
        width: '80%',

        show: () => {
          this.reagentCateSelectTableModal.visible = true
        },
        action: {
          cancel: () => { this.reagentCateSelectTableModal.visible = false },
          ok: () => {
            const selectData = this.reagentCateSelectTableModal.data.rowSelect.selectedRows
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            const guideData = this.checkGuideReagentList?.[0]
            const hasExistIds = this.checkGuideReagentList.map(item => item.reagentCateId)
            const noExistData = selectData.filter(item => !hasExistIds.includes(item.id))
            noExistData.length && this.checkGuideReagentList.push.apply(this.checkGuideReagentList, noExistData.map(item => ({
              id: guideData?.id,
              methodId: guideData?.detailId,
              standardId: guideData?.standardId,
              reagentCateId: item.id,
              reagentCateName: item.name,
              num: 0,
              unit: item.unit
            })))
            this.reagentCateSelectTableModal.action.cancel()
          }
        },

        data: { ref: 'reagentCateSelectAndQueryTable', showPagination: true, rowSelect: { type: 'checkbox', selectedRows: [] }, columns: checkReagentCateColumn },

        dataAction: {}
      }),

      curveTableSelectModal: {
        ref: 'curveTableSelectModal',
        queryParam: {},
        success: (records) => {
          const existIds = this.curveList.map(item => item.id)
          const notExistData = records?.filter(item => !existIds.includes(item.id)) || []
          if (notExistData.length) {
            this.curveList.push.apply(this.curveList, notExistData)
            this.curveVerifyList.push.apply(this.curveVerifyList, notExistData.map(item => ({
              curveId: item.id,
              siteNo: 0,
              standardCurveAbsorbance: null,
              siteAbsorbance: null,
              relativeDeviation: null,
              makeDate: this.baseForm.makeBeginTime,
              note: null
            })))
          }
        },

        show: () => {
          const standardIds = this.checkGuideDetailList.map(item => item.standardId)
          const methodIds = this.checkGuideDetailList.map(item => item.methodId)
          const checkStatus = this.checkStandardCurveCheckStatusEnum.pass
          const validTime = this.timeFormat(Moment(new Date(), 'YYYY-MM-DD HH:mm:ss'))
          this.curveTableSelectModal.queryParam = { standardIds, methodIds, checkStatus, validTime }
          this.$refs[this.curveTableSelectModal.ref].show()
        }

      },

      checkOriginalRecordModal: Object.assign({}, fullModalData, {
        title: '检测原始记录表',
        data: {
          ref: 'checkOriginalRecord',
          batchId: null
        },

        dataAction: {
          success: () => {
            this.checkOriginalRecordModal.cancel()
            this.$emit('success', this.baseForm.id)
          },

          cancel: () => {
            this.checkOriginalRecordModal.cancel()
          }

        },

        show: (batchId) => {
          this.checkOriginalRecordModal.data.batchId = batchId
          this.$nextTick(() => {
            this.checkOriginalRecordModal.visible = true
          })
        },

        action: {
          ok: () => {
            this.$refs[this.checkOriginalRecordModal.data.ref].save()
          },

          cancel: () => {
            this.checkOriginalRecordModal.visible = false
            this.$emit('cancel', this.baseForm.id)
          }
        }
      }),

      baseCard: {
        type: 'inner',
        size: 'mini',
        style: { marginBottom: '16px' }
      },

      curveDrawTable: {
        rowKey: item => (`${item.id}`),
        bordered: true,
        size: 'small',
        dataSource: [],
        columns: this.columns,
        pagination: false
      }
    }
  },

  computed: {
    sampleIds () {
      return this.taskList.filter(item => !labQcTypeEnum.includes(item.sampleType)).map(item => item.sampleId)
    },

    actualTaskList () {
      // 获取实际提交的任务，如果样品被移除 认为样品不在此批次内，故需要计算实际提交的任务
      const sampleIds = this.sampleInfoList.map(item => item.id)
      return this.taskList.filter(item => sampleIds.includes(item.sampleId))
    },

    itemInfo () {
      return this.actualTaskList.map(item => ({ cateId: item.cateId, cateName: item.cateName, itemId: item.itemId, itemName: item.itemName }))?.[0] ?? {}
    },

    loginUser () {
      return { ...this.$store.getters.userInfo }
    },

    curveVerifyMap () {
      return groupBy(this.curveVerifyList, 'curveId')
    },

    makeTime: {
      set (val) {
        if (val != null) {
          this.baseForm.makeBeginTime = val[0]
          this.baseForm.makeEndTime = val[1]
        }
      },
      get () {
        const { baseForm: { makeBeginTime, makeEndTime } } = this
        return [this.moment(makeBeginTime), this.moment(makeEndTime)]
      }
    },

    /** 空白样最少数量 */
    qcMinBlankSampleNum () {
      const { needBlankNumType, needBlank, needBlankNum } = this.selectCheckGuide
      return this.calcQcSampleNum(needBlankNumType, needBlank, needBlankNum)
    },

    /** 平行样最少数量 */
    qcMinParallelSampleNum () {
      const { needParallelNumType, needParallel, needParallelNum } = this.selectCheckGuide
      return this.calcQcSampleNum(needParallelNumType, needParallel, needParallelNum)
    },

    /** 质控样最少数量 */
    qcMinQCSampleNum () {
      const { needQcNumType, needQc, needQcNum } = this.selectCheckGuide
      return this.calcQcSampleNum(needQcNumType, needQc, needQcNum)
    },

    /** 加标回收样最少数量 */
    qcMinMarkupNum () {
      const { needMarkupNumType, needMarkup, needMarkupNum } = this.selectCheckGuide
      return this.calcQcSampleNum(needMarkupNumType, needMarkup, needMarkupNum)
    },

    /** 标准曲线最少数量 */
    qcMinCurveSampleNum () {
      const { needCurveNumType, needCurve, needCurveNum } = this.selectCheckGuide
      return this.calcQcSampleNum(needCurveNumType, needCurve, needCurveNum)
    }
  },

  watch: {
    sampleIds: {
      immediate: true,
      handler (sampleIds) {
        sampleIds && sampleIds.length && this.querySampleInfos(sampleIds)
        sampleIds && sampleIds.length && this.queryQcTaskLists(sampleIds)
      }
    },

    itemInfo: {
      immediate: true,
      handler (itemInfo) {
        itemInfo && Object.keys(itemInfo).length && this.queryCheckGuideDetail(itemInfo)
      }
    },

    sampleInfoList (list) {
      list && list.length && this.validatorSample(list)
    },

    makeTime (makeTime) {
      makeTime && makeTime.length && this.computeValidityDate(this.sampleInfoList)
    },

    selectCheckGuide (guideInfo) {
      guideInfo && this.queryDeviceInfo(guideInfo)
      guideInfo && this.queryCheckGuideRegent(guideInfo)
      // guideInfo && this.handleAutoQC()
    },

    id: {
      immediate: true,
      handler (value) {
        value && this.querySaveInfo(value)
      }
    }

  },

  filters: {
    qcRefSource (type) {
      const map = { 1: '标准', 2: '企业' }
      return map[type] || ''
    }
  },

  mounted () {
    this.submit = throttle(this.submit, 6000, { leading: true, trailing: false })
  },

  methods: {

    calcRD (cIndex, curveId) {
      const curveVerifyList = this.curveVerifyMap[curveId]
      const curveVerify = curveVerifyList[cIndex]
      if (curveVerify.standardCurveAbsorbance != null && !isNaN(curveVerify.standardCurveAbsorbance) && curveVerify.siteAbsorbance != null && !isNaN(curveVerify.siteAbsorbance)) {
        curveVerify.relativeDeviation = Math.floor((curveVerify.standardCurveAbsorbance - curveVerify.siteAbsorbance) / curveVerify.standardCurveAbsorbance * 10000) / 100
      }
    },

    // 处理样品出库事件
    sampleInfoListHandler (successIds) {
      const taskList = this.taskList.filter(item => successIds.includes(item.sampleIds))
      taskList.forEach(item => { item.sampleStatus = sampleInfoStatusEnum.outbound })
    },

    // 查询已保存的数据
    querySaveInfo (id) {
      checkTaskBatchApi.getDetailInfoById(id).then(res => {
        if (res.code === 0) {
          const data = res.data
          const deviceInfoList = data?.deviceInfoList ?? []
          const reagentList = data?.reagentList ?? []
          this.curveList = data?.curveList ?? []
          const curveVerifyList = data?.curveVerifyList ?? []
          curveVerifyList.forEach(verify => {
            verify.makeDate = this.moment(verify.makeDate)
          })
          this.curveVerifyList = curveVerifyList
          deviceInfoList.length && this.handleSavedDeviceInfoList(deviceInfoList)
          reagentList.length && this.handleSavedReagentList(reagentList)
          this.baseForm.wd = this.baseForm.wd ?? data.wd
          this.baseForm.sd = this.baseForm.sd ?? data.sd
          this.baseForm.makeBeginTime = this.baseForm.makeBeginTime ?? data.makeBeginTime
          this.baseForm.makeEndTime = this.baseForm.makeEndTime ?? data.makeEndTime
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 处理已经保存的试剂信息
    handleSavedReagentList (reagentList) {
      // 转换数据结构
      const savedReagentList = reagentList.map(item => {
        const result = { id: null, methodId: null, standardId: null, num: 0, reagentCateId: item.cateId, reagentCateName: item.cateName, unit: item.unit }
        result.cateUsageInfos = item.cateUsageInfos?.map(usage => {
          return { id: usage.id, name: item.name, type: usage.type, sumVolume: usage.sumVolume, volume: usage.volume, unit: usage.unit, useVolume: usage.useVolume }
        }) ?? []
        return result
      })
      // 已经存在的数据需要修改实际使用的试剂使用信息
      const saveReagentCateIds = savedReagentList.map(item => item.reagentCateId)
      const needRedoReagentList = this.checkGuideReagentList.filter(item => saveReagentCateIds.includes(item.reagentCateId))
      needRedoReagentList.forEach(item => {
        const existReagent = reagentList.find(cItem => item.reagentCateId === cItem.reagentCateId)
        item.cateUsageInfos = existReagent.cateUsageInfos
      })

      // 加入不存在的试剂配置信息
      const existReagentIds = this.checkGuideReagentList.map(item => item.reagentCateId)
      const notExistReagentList = savedReagentList.filter(item => !existReagentIds.includes(item.reagentCateId))
      this.checkGuideReagentList.push.apply(this.checkGuideReagentList, notExistReagentList)
    },

    // 查询配置的试剂
    queryCheckGuideRegent (guideInfo) {
      const { methodId, standardId } = guideInfo

      itemCheckDetailReagentApi.list({ methodId, standardId, page: false }).then(res => {
        if (res.code === 0) {
          // 已经存在的试剂需要修改对应的配置中方法和预计用量信息
          const reagentIds = res.data.map(item => item.reagentCateId)
          const needRedoReagentList = this.checkGuideReagentList.filter(item => reagentIds.includes(item.reagentCateId))
          const noNeedRedoReagentList = this.checkGuideReagentList.filter(item => !reagentIds.includes(item.reagentCateId))

          // 加入未配置的试剂信息
          const existReagentIds = this.checkGuideReagentList.map(item => item.reagentCateId)
          const notExistReagentList = res.data.filter(item => !existReagentIds.includes(item.reagentCateId))

          needRedoReagentList.forEach(item => {
            const existData = res.data.find(cItem => cItem.reagentCateId === item.reagentCateId)
            item.id = existData?.id
            item.mehtodId = existData?.methodId
            item.standardId = existData?.standardId
            item.num = existData?.num
          })
          needRedoReagentList.length && this.checkGuideReagentList.splice(0, this.checkGuideReagentList.length, ...needRedoReagentList)

          this.checkGuideReagentList.push.apply(this.checkGuideReagentList, noNeedRedoReagentList)
          this.checkGuideReagentList.push.apply(this.checkGuideReagentList, notExistReagentList)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 处理已经保存设备信息
    handleSavedDeviceInfoList (deviceInfoList) {
      const devInfos = deviceInfoList.map(item => {
        const usageInfos = item.usageInfos?.map(usage => {
          usage.useDate = this.moment(usage.useDate)
          return usage
        })
        const { devName, type } = item
        return { devName, type, usageInfos }
      })
      const savedDevTypes = devInfos.map(item => item.type)
      const notExistDevs = this.selectDeviceInfos.filter(item => !savedDevTypes.includes(item.type))
      notExistDevs.push.apply(notExistDevs, devInfos)
      this.selectDeviceInfos.splice(0, this.selectDeviceInfos.length, ...notExistDevs)
    },

    // 查询设备信息
    queryDeviceInfo (guideInfo) {
      // 未配置设备时
      if (!guideInfo.jcDevices) {
        this.deviceInfos.splice(0, this.deviceInfos.length)
        this.deviceTypeMap = {}
        this.selectDeviceInfos.splice(0, this.selectDeviceInfos.length)
        return false
      }

      getAction(resDevApi.list, { cateIds: guideInfo.jcDevices.split(','), pageSize: 1000000 }).then(res => {
        const data = res.data
        this.deviceInfos.splice(0, this.deviceInfos.length, ...(data || []))
        this.deviceTypeMap = groupBy(data, 'type')

        // 已经存在的设备不要重新加载
        const deviceKeys = Object.keys(this.deviceTypeMap)
        const existSelectDeviceInfos = this.selectDeviceInfos.filter(device => deviceKeys.includes(device.type))
        existSelectDeviceInfos.length && this.selectDeviceInfos.splice(0, this.selectDeviceInfos.length, ...existSelectDeviceInfos)

        // 加载数据新填充的设备信息
        const devTypes = this.selectDeviceInfos.map(item => item.type)
        Object.values(this.deviceTypeMap).forEach(devices => {
          const device = devices[0]
          if (!devTypes.includes(device.type)) {
            if (devices.length === 1) {
              const devUse = { devName: device.devName, type: device.type, usageInfos: [] }
              this.selectDeviceInfos.push(devUse)
              this.$nextTick(() => {
                this.selectDevice(devUse, devices)
              })
            } else {
              this.selectDeviceInfos.push({ devName: device.devName, type: device.type, usageInfos: [] })
            }
          }
        })
      })
    },

    // 添加平行样
    addParallel () {
      const [sampleInfo] = this.sampleInfoList
      if (!sampleInfo) {
        this.$message.error('暂无需要检测的样品!')
        return false
      }
      const [taskInfo] = this.taskList
      const { itemId, cateId, itemName, cateName } = taskInfo
      const { id, checkSampleId } = sampleInfo
      this.parallelList.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: true })
    },

    /** 移除平行样 */
    removeParallel () {
      const index = this.parallelList.findIndex(item => item.editable)
      if (index > -1) this.parallelList.splice(index, 1)
    },

    // 添加空白样
    addBlank () {
      const [sampleInfo] = this.sampleInfoList
      if (!sampleInfo) {
        this.$message.error('暂无需要检测的样品!')
        return false
      }
      const [taskInfo] = this.taskList
      const { itemId, cateId, itemName, cateName } = taskInfo
      const { id, checkSampleId } = sampleInfo
      this.blankList.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: true })
    },

    /** 移除空白样 */
    removeBlank () {
      const index = this.blankList.findIndex(item => item.editable)
      if (index > -1) this.blankList.splice(index, 1)
    },

    // 添加质控样
    addQualityControl () {
      const [sampleInfo] = this.sampleInfoList
      if (!sampleInfo) {
        this.$message.error('暂无需要检测的样品!')
        return false
      }
      const [taskInfo] = this.taskList
      const { itemId, cateId, itemName, cateName } = taskInfo
      const { id, checkSampleId } = sampleInfo
      this.qualityControlList.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: true })
    },

    /** 移除质控样 */
    removeQualityControl () {
      const index = this.qualityControlList.findIndex(item => item.editable)
      if (index > -1) this.qualityControlList.splice(index, 1)
    },

    // 添加加标回收
    addAddStandard () {
      const [sampleInfo] = this.sampleInfoList
      if (!sampleInfo) {
        this.$message.error('暂无需要检测的样品!')
        return false
      }
      const [taskInfo] = this.taskList
      const { itemId, cateId, itemName, cateName } = taskInfo
      const { id, checkSampleId } = sampleInfo
      this.addStandardList.push({ id, checkSampleId, itemId, type: sampleInfoTypeEnum.addStandardSample, cateId, itemName, cateName, editable: true })
    },

    /** 移除加标样 */
    removeAddStandard () {
      const index = this.addStandardList.findIndex(item => item.editable)
      if (index > -1) this.addStandardList.splice(index, 1)
    },

    // 查询检测明细信息
    queryCheckGuideDetail (itemInfo) {
      const itemInfoStr = itemInfo.cateId + '-' + itemInfo.itemId
      itemCheckDetailApi.listDetail({ page: false, items: [itemInfoStr] }).then(res => {
        if (res.code === 0) {
          const data = orderBy(res.data, 'defaultFlag', 'desc')
          this.checkGuideDetailList.splice(0, this.checkGuideDetailList.length, ...data)
          const defaultData = data.find(item => { return item.id === this.baseForm.checkDetailId })
          this.selectCheckGuide = defaultData || data?.[0]
          this.baseForm.checkDetailId = this.selectCheckGuide?.id
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 处理检测明细修改
    handleSelectGuide (event, checkGuide) {
      this.selectCheckGuide = checkGuide
      this.baseForm.checkDetailId = checkGuide.id
    },

    // 查询样品信息
    querySampleInfos (sampleIds) {
      tsSampleinfoApi.list({ ids: sampleIds, page: false }).then(res => {
        if (res.code === 0) {
          const sampleInfoList = res.data || []
          this.computeValidityDate(sampleInfoList)
          this.sampleInfoList.splice(0, this.sampleInfoList.length, ...(orderBy(sampleInfoList, 'type')))
        } else {
          this.$message.error(`获取样品信息错误信息:${res.msg}`)
        }
      })
    },

    // 查询检测员环节的质控样任务
    queryQcTaskLists (sampleIds) {
      checkTaskApi.list({ sampleRefIds: sampleIds, sampleTypes: labQcTypeEnum, page: false }).then(res => {
        if (res.code === 0) {
          const checkTaskList = res.data || []
          const checkTaskTypeGroup = groupBy(checkTaskList, 'sampleType')
          const [{ itemId: defaultItemId }] = this.taskList

          // eslint-disable-next-line no-unused-expressions
          checkTaskTypeGroup[sampleInfoTypeEnum.labParallel]?.forEach(item => {
            const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName } = item
            if (itemId === defaultItemId) {
              this.parallelList.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: false })
            }
          })

          // eslint-disable-next-line no-unused-expressions
          checkTaskTypeGroup[sampleInfoTypeEnum.labBlank]?.forEach(item => {
            const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName } = item
            if (itemId === defaultItemId) {
              this.blankList.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: false })
            }
          })

          // eslint-disable-next-line no-unused-expressions
          checkTaskTypeGroup[sampleInfoTypeEnum.qualityControl]?.forEach(item => {
            const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName } = item
            if (itemId === defaultItemId) {
              this.qualityControlList.push({ id, checkSampleId, itemId, cateId, itemName, cateName, editable: false })
            }
          })

          // eslint-disable-next-line no-unused-expressions
          checkTaskTypeGroup[sampleInfoTypeEnum.addStandardSample]?.forEach(item => {
            const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName } = item
            if (itemId === defaultItemId) {
              this.addStandardList.push({ id, checkSampleId, type: sampleInfoTypeEnum.addStandardSample, itemId, cateId, itemName, cateName, editable: false })
            }
          })

          // eslint-disable-next-line no-unused-expressions
          checkTaskTypeGroup[sampleInfoTypeEnum.basisAddStandardSample]?.forEach(item => {
            const { sampleId: id, checkSampleId, itemId, cateId, itemName, cateName } = item
            if (itemId === defaultItemId) {
              this.addStandardList.push({ id, checkSampleId, itemId, type: sampleInfoTypeEnum.basisAddStandardSample, cateId, itemName, cateName, editable: false })
            }
          })
        } else {
          this.$message.error(`获取质控样错误信息:${res.msg}`)
        }
      })
    },

    // 校验检测明细
    validatorSample (sampleInfoList) {
      const noConfigList = sampleInfoList.filter(item => !item.validityDate)
      const expiredList = sampleInfoList.filter(item => item.expired)
      noConfigList.length && this.$message.error(`${noConfigList.map(item => item.checkSampleId).join(',')}样品暂未获取到有效期,你注意样品是否过期!`)
      expiredList.length && this.$message.error(`${expiredList.map(item => item.checkSampleId).join(',')}样品已经有效期,你注意样品是否过期!`)
    },

    // 计算样品有效期
    computeValidityDate (sampleInfoList) {
      const transUnit = (unit) => {
        switch (unit) {
          case '天':
            return 'd'
          case '小时':
            return 'h'
          case '分钟':
            return 'm'
          default:
            return unit
        }
      }
      sampleInfoList.forEach(item => {
        const { sampleBeginTime, validity, validityUnit: validityUnitData } = item
        const now = this.baseForm.makeBeginTime
        if (validity && validityUnitData) {
          const validityUnit = transUnit(validityUnitData)
          const beginTime = this.moment(sampleBeginTime)
          const validityDate = beginTime.add(validity, validityUnit)
          item.validityDate = validityDate
          item.expired = validityDate.isBefore(now)
        } else {
          item.validityDate = null
        }
      })
    },

    handleDevChange (device, value) {
      this.$nextTick(() => {
        const selectData = this.deviceTypeMap[device.type].filter(item => value.includes(item.id))
        this.selectDevice(device, selectData)
      })
    },

    selectDevice (device, selectData) {
      if (!Object.prototype.hasOwnProperty.call(device, 'usageInfos')) {
        this.$set(device, 'usageInfos', [])
      }
      device.usageInfos.splice(0, device.usageInfos.length, ...selectData.map(item => ({
        devId: item.id,
        devName: item.devName,
        fixedAssetsId: item.fixedAssetsId,
        speType: item.speType,
        type: '2',
        locationId: item.locationId,
        locationName: item.locationName,
        useStatus: item.useStatus,
        useDate: this.baseForm.makeBeginTime,
        useDateEnd: this.baseForm.makeEndTime
      })))
    },

    /**
     * 自动添加质控样品
     * @param guideInfo 检测明细配置
     */
    handleAutoQC (guideInfo) {
      // 空白样
      const needAddBlankNum = this.qcMinBlankSampleNum - this.blankList.length
      if (needAddBlankNum > 0) {
        for (let i = 1; i <= needAddBlankNum; i++) {
          this.addBlank()
        }
      } else if (needAddBlankNum < 0) {
        for (let i = 1; i <= Math.abs(needAddBlankNum); i++) {
          this.removeBlank()
        }
      }

      // 平行样
      const needAddParallelNum = this.qcMinParallelSampleNum - this.parallelList.length
      if (needAddParallelNum > 0) {
        for (let i = 1; i <= needAddParallelNum; i++) {
          this.addParallel()
        }
      } else if (needAddParallelNum < 0) {
        for (let i = 1; i <= Math.abs(needAddParallelNum); i++) {
          this.removeParallel()
        }
      }

      // 质控样
      const needAddQcNum = this.qcMinQCSampleNum - this.qualityControlList.length
      if (needAddQcNum > 0) {
        for (let i = 1; i <= needAddQcNum; i++) {
          this.addQualityControl()
        }
      } else if (needAddQcNum < 0) {
        for (let i = 1; i <= Math.abs(needAddQcNum); i++) {
          this.removeQualityControl()
        }
      }

      // 加标回收
      const needAddMarkupNum = this.qcMinMarkupNum - this.addStandardList.length
      if (needAddMarkupNum > 0) {
        for (let i = 1; i <= needAddMarkupNum; i++) {
          this.addAddStandard()
        }
      } else if (needAddMarkupNum < 0) {
        for (let i = 1; i <= Math.abs(needAddMarkupNum); i++) {
          this.removeAddStandard()
        }
      }
    },

    /**
     * 计算质控样品数量
     * @param numType 数量类型 fix 固定数量
     * @param isNeed 是否需要对应的质控样品
     * @param num 配置的数量
     * @returns {*|number} 实际的数量
     */
    calcQcSampleNum (numType, isNeed, num) {
      if (this.selectCheckGuide != null) {
        if (!isNeed) return 0
        if (numType === 'fix') {
          return num
        } else {
          const sampleSum = this.sampleIds.length
          return Math.ceil(sampleSum * num / 100)
        }
      }
      return 0
    },

    submit () {
      const sampleInfoList = this.sampleInfoList
      if (!sampleInfoList.length) {
        this.$message.error('暂无需要检测的样品!')
        return false
      }

      const taskList = this.actualTaskList
      if (!taskList.length) {
        this.$message.error('暂无需要检测的任务!')
        return false
      }

      const selectCheckGuide = this.selectCheckGuide

      if (!selectCheckGuide) {
        this.$message.error(`请先选择检测方法!`)
        return false
      }

      // eslint-disable-next-line no-unused-vars
      const notConfigDevice = this.selectDeviceInfos.find(item => !item.usageInfos || !item.usageInfos.length)
      // if (notConfigDevice) {
      //  this.$message.error(`${notConfigDevice.devName} 实际使用的设备还未选择!`)
      //  return false
      // }

      // eslint-disable-next-line no-unused-vars
      const notConfigReagent = this.checkGuideReagentList.find(item => !item.cateUsageInfos || !item.cateUsageInfos.length)
      // if (notConfigReagent) {
      //  this.$message.error(`${notConfigReagent.reagentCateName} 实际使用的试剂还未选择!`)
      //  return false
      // }

      const blankList = this.blankList
      // if (selectCheckGuide.needBlank === qualityOptionEnum.must && !blankList.length) {
      //  this.$message.error(`依据标准必须要做空白样,你还未制作,请先制作!`)
      //  return false
      // }

      const qualityControlList = this.qualityControlList

      const addStandardList = this.addStandardList
      // if (selectCheckGuide.needMarkup === qualityOptionEnum.must && !addStandardList.length) {
      //  this.$message.error(`依据标准必须要做加标回收,你还未制作,请先制作!`)
      //  return false
      // }

      const parallelList = this.parallelList
      // if (selectCheckGuide.needParallel === qualityOptionEnum.must && !parallelList.length) {
      //  this.$message.error(`依据标准必须要做平行样,你还未制作,请先制作!`)
      //  return false
      // }

      const curveList = this.curveList
      // if (selectCheckGuide.needCurve === qualityOptionEnum.must && !curveList.length) {
      //  this.$message.error(`依据标准必须要做曲线,你还未制作,请先制作!`)
      //  return false
      // }
      const curveIds = this.curveList.map(item => item.id)
      const curveVerifyList = this.curveVerifyList.filter(item => curveIds.includes(item.curveId))
      // eslint-disable-next-line no-unused-vars
      const notConfigCurveVerifyData = curveVerifyList.find(item => { return !item.siteNo || !item.standardCurveAbsorbance || !item.siteAbsorbance || !item.relativeDeviation })
      // if (notConfigCurveVerifyData) {
      //  this.$message.error(`请完善曲线的单点检验信息!`)
      //  return false
      // }

      const data = {
        cateId: this.itemInfo.cateId,
        itemId: this.itemInfo.itemId,
        taskList,
        deviceInfoList: this.selectDeviceInfos,
        reagentList: this.checkGuideReagentList,
        parallelList,
        blankList,
        curveList,
        qualityControlList,
        addStandardList,
        curveVerifyList
      }
      checkTaskBatchApi.save(Object.assign({}, this.baseForm, data)).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.baseForm.id = res.data
          if (this.selectCheckGuide.templateId && this.autoEnterOriginRecord) {
            this.$nextTick(() => {
              setTimeout(() => { this.checkOriginalRecordModal.show(res.data) }, 500)
            })
          } else {
            this.$emit('success', res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
