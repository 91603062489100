<template>
  <div>
    <!-- 搜索区域 -->
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item label="样品编号" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="样品编号模糊查询" v-model.trim.lazy="queryParam.sampleBlurId"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="项目编号" v-bind="formItemLayout">
              <a-input @pressEnter="search" placeholder="项目编号" v-model.trim.lazy="queryParam.projNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="状态" v-bind="formItemLayout">
              <a-radio-group v-model="queryParam.status" @change="search">
                <a-radio :value="null">全部</a-radio>
                <a-radio v-for="item in Object.keys(sampleDestroyStatusMap)" :key="item" :value="item">{{ sampleDestroyStatusMap[item] }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :style="{ textAlign: 'left',marginBottom:'15px' }" v-bind="colProps">
            <a-button-group>
              <a-button @click="search" icon="search" type="primary">查询</a-button>
              <a-button @click="reset" icon="reload">重置</a-button>
            </a-button-group>
            <a @click="toggleSearchStatus = !toggleSearchStatus" style="margin-left: 8px">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
            </a>
          </a-col>
        </a-row>
        <a-row v-bind="rowProps" v-show="toggleSearchStatus">
          <a-col v-bind="colProps" :span="18" :style="{marginLeft:'-9px'}">

            <a-form-item label="处置时间" v-bind="largeFormItemLayout">
              <div class="table-page-search-submitButtons">
                <template v-for="bt in dateBtnList.list">
                  <a-button :key="bt.key" :type="dateBtnList.checked == bt.key ? 'primary':'default'" @click="handleDateBtnClick(bt.key)" style="margin-right: 10px">{{ bt.text }}</a-button>
                </template>
                <a-range-picker
                  v-if="dateBtnList.checked==='C'"
                  showTime
                  @change="handleDealRangeChange"
                  @ok="search"
                  format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
              </div>

            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <a-button-group class="fr">
        <a-tooltip>
          <template #title>
            <div>1.未审核通过的数据无法记录</div>
            <div>2.数据来源：根据当前查询的数据</div>
          </template>
          <a-button @click="previewModal.show" icon="export" type="primary">记录</a-button>
        </a-tooltip>

      </a-button-group>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <s-table :rowSelection="rowSelection" :scroll="scrollProp" v-bind="contentTable">
        <template #dealReason="reason">
          {{ sampleInfoDealReason[reason] }}
        </template>

        <template #status="status">
          <a-tag :color="sampleDestroyStatusDataMap[status].color">{{ sampleDestroyStatusMap[status] }}</a-tag>
        </template>

        <template #action="data">
          <a @click="viewModal.show(data)">预览</a>
        </template>
      </s-table>
    </a-card>

    <a-modal v-bind="previewModal" v-if="previewModal.visible" @cancel="previewModal.cancel">
      <template v-if="previewModal.data&&previewModal.data.length===0">
        <div class="load">
          <div class="content" :style="{fontSize:'16px',fontWeight:600,color:'red'}" tip="">暂无符合条件的数据</div>
        </div>
      </template>

      <div v-else-if="previewModal.data">
        <iframe :src="previewModal.data" frameborder="0" height="600" v-if="previewModal.data" width="100%"></iframe>
      </div>

      <template v-else>
        <div class="load">
          <a-spin class="content" size="large" tip="页面渲染中..."></a-spin>
        </div>
      </template>
      <template #footer>
        <a-button :style="{marginRight:'8px'}" @click="previewModal.download" type="primary">下载</a-button>
        <a-button @click="previewModal.cancel"> 关闭</a-button>
      </template>
    </a-modal>

    <a-modal v-bind="viewModal" v-if="viewModal.visible" v-on="viewModal">
      <destroy-view :id="viewModal.id"></destroy-view>
      <template #footer>
        <a-button @click="viewModal.cancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { sampleDestroyStatusOptions } from '@/api/sampleInfo/sampleInfoDestroyConstant'
import { sampleInfoDestroyApi } from '@/api/sampleInfo/sampleInfoDestroyApi'

import date from '@/pages/common/mixins/date'
import list from '@/pages/common/mixins/list'
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'
import { STable } from '@/components'
import DestroyView from '@/pages/cloud-quote/sample-info/modules/destroy/view'

const sampleDestroyStatusMap = tranOptionsToMap(sampleDestroyStatusOptions)
const sampleDestroyStatusDataMap = tranOptionsToDataMap(sampleDestroyStatusOptions)

export default {

  components: { STable, DestroyView },

  mixins: [list, date],

  data () {
    return {
      sampleDestroyStatusMap,
      sampleDestroyStatusDataMap,

      /// 表单项配置
      largeFormItemLayout: {
        labelCol: {
          xxl: { span: 2 },
          xl: { span: 2 },
          lg: { span: 3 },
          md: { span: 3 },
          sm: { span: 3 },
          xs: { span: 3 }
        },
        wrapperCol: {
          xxl: { span: 22 },
          xl: { span: 22 },
          lg: { span: 21 },
          md: { span: 21 },
          sm: { span: 21 },
          xs: { span: 21 }
        }
      },

      previewModal: {
        title: '预览',
        width: '65%',
        centered: true,
        visible: false,
        data: null,
        destroyOnClose: true,
        show: () => {
          this.preview()
          this.previewModal.visible = true
        },
        download: () => {
          this.download()
        },
        cancel: () => {
          if (this.previewModal.data) {
            window.URL.revokeObjectURL(this.previewModal.data)
            this.previewModal.data = null
          }
          this.previewModal.visible = false
        }
      },

      queryParam: {
        sampleBlurId: null,
        projNo: null,
        dealBeginTime: null,
        dealEndTime: null,
        status: null
      },

      dateBtnList: {
        checked: 'A',
        list: [{
          key: 'A',
          text: '全部'
        }, {
          key: 'W',
          text: '本周'
        }, {
          key: 'M',
          text: '本月'
        }, {
          key: 'Q',
          text: '本季'
        }, {
          key: 'Y',
          text: '本年'
        }, {
          key: 'C',
          text: '自定义'
        }]
      },

      contentTable: {
        columns: [
          { title: '申请人', dataIndex: 'applyUserName', scopedSlots: { customRender: 'applyUserName' } },
          { title: '申请时间', dataIndex: 'applyTime', scopedSlots: { customRender: 'applyTime' } },
          { title: '处置原因', dataIndex: 'dealReason', scopedSlots: { customRender: 'dealReason' } },
          { title: '处置人', dataIndex: 'dealUserName', scopedSlots: { customRender: 'dealUserName' } },
          { title: '处置时间', dataIndex: 'dealTime', scopedSlots: { customRender: 'dealTime' } },
          { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' } },
          { title: '审核人', dataIndex: 'approvalUserName', scopedSlots: { customRender: 'approvalUserName' } },
          { title: '操作', scopedSlots: { customRender: 'action' } }]
      },

      viewModal: {
        title: '预览',
        visible: false,
        id: null,
        width: '60%',
        show: (data) => {
          this.viewModal.id = data.id
          this.viewModal.visible = true
        },
        cancel: () => {
          this.viewModal.visible = false
        }
      }
    }
  },

  computed: {
    sampleInfoDealReason () {
      return { ...this.$store.state.code.cache.sampleInfoDealReason }
    }
  },

  created () {
    this.$store.dispatch('code/select', 'sampleInfoDealReason')
  },

  methods: {
    loadData (param) {
      return sampleInfoDestroyApi.list(Object.assign(param, this.queryParam)).then(res => {
        return res
      })
    },

    // 时间按钮切换事件
    handleDateBtnClick (key) {
      this.dateBtnList.checked = key
      let start, end
      if (key === 'W') {
        start = this.moment().startOf('isoWeek')
        end = this.moment().endOf('isoWeek')
      } else if (key === 'M') {
        start = this.moment().startOf('month')
        end = this.moment().endOf('month')
      } else if (key === 'Q') {
        start = this.moment().startOf('quarter')
        end = this.moment().endOf('quarter')
      } else if (key === 'Y') {
        start = this.moment().startOf('year')
        end = this.moment().endOf('year')
      } else if (key === 'Y') {
        start = this.moment().startOf('year')
        end = this.moment().endOf('year')
      } else if (key === 'A') {
        this.queryParam.dealBeginTime = null
        this.queryParam.dealEndTime = null
        this.search()
        return
      } else {
        return
      }
      this.dateRange = [start, end]
      this.queryParam.dealBeginTime = this.dateFormat(start) + ' 00:00:00'
      this.queryParam.dealEndTime = this.dateFormat(end) + ' 23:59:59'
      this.search()
    },

    handleDealRangeChange (dates, dateStrs) {
      this.queryParam.dealBeginTime = dateStrs[0]
      this.queryParam.dealEndTime = dateStrs[1]
    },

    preview () {
      sampleInfoDestroyApi.download(Object.assign({ downloadType: 'svg' }, this.queryParam)).then(data => {
        if (data.size === 0) {
          this.previewModal.data = []
        } else {
          const blob = new Blob([data], { type: 'text/html' })
          this.previewModal.data = window.URL.createObjectURL(blob)
        }
      })
    },

    download () {
      sampleInfoDestroyApi.download(Object.assign({ downloadType: 'doc' }, this.queryParam)).then(data => {
        if (data.size === 0) {
          this.$message.error('暂无数据')
        } else {
          const blob = new Blob([data])
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = '样品处置记录.doc'
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href)
        }
      })
    }
  }
}
</script>

<style scoped>
  @import "index.less";
</style>
