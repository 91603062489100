<template>
  <div style="width: 100%;margin: 0 auto">
    <iframe id="iframe" :src="iframeSrc" frameborder="0" style="width: 100%;margin: 0 auto" @load="iFrameHeight"/>
  </div>
</template>

<script>
import { anonProjectApi, projectApi } from '@/api/project'
import { fileTemplateUreportApi } from '@/api/fileTemplate'

export default {
  name: 'PlanContentPreview',
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      visible: false,
      url: '',
      templateFile: '',
      templateParam: {
        page: false,
        templateCode: 'template_quote_QPV01'
      },
      templateList: []
    }
  },
  methods: {
    iFrameHeight () {
      var ifm = document.getElementById('iframe')
      var subWeb = document.frames ? document.frames['iframe'].document : ifm.contentDocument
      if (ifm != null && subWeb != null) {
        ifm.height = subWeb.body.scrollHeight
      }
    },
    listFileTemplate () {
      anonProjectApi.listFileTemplate(this.templateParam).then(result => {
        if (result.code === 0) {
          this.templateList = result.data
        } else {
          this.$message.error(result.msg)
        }
      })
    }
  },
  computed: {
    iframeSrc: function () {
      if (this.templateList && this.templateList.length > 0) {
        var template = this.templateList[0]
        return `${fileTemplateUreportApi.preview}?_t=0&_u=${template.templateFile}&quoteId=${this.params.quoteId}&projId=${this.params.projId}`
      }
      return ''
    }
  },
  mounted () {
    // this.iFrameHeight()
  },
  created () {
    this.listFileTemplate()
  }
}
</script>
