<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  methods: {
    getVolumeColor(data) {
      return data > 50 ? 'green' : data > 30 ? 'orange' : 'red'
    }
  }
}
</script>

<script setup>
import { ref, getCurrentInstance, computed, reactive, watch, watchEffect, onMounted, set } from 'vue'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import UlTable, { UlTableColumn } from '@/pages/common/ul-table/Table'
import { useDate } from '@/pages/common/compositions/date/useDate'
import { message } from 'ant-design-vue'
import { checkStandardSolutionDemarcatesStatusDataMap } from '@/api/checkStandardSolution/checkStandardSolutionDemarcatesConstant'

const { proxy } = getCurrentInstance()

const props = defineProps({
  modelValue: { type: Array, required: true },
  defaultMakeDate: { type: String, required: false, default: undefined },
  selectCheckGuide: { type: Object, required: false, default: undefined },
  solutionReason: { type: String, required: false, default: '' }
})
const { moment } = useDate()

const emit = defineEmits(['update:modelValue'])
const checkSolutionTypeList = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
const checkedIndex = ref(0)
const getSolution = (record) => {
  const solution = {
    solutionId: record.id,
    solutionNo: record.solutionNo,
    solutionName: record.name,
    volume: record.volume,
    sumVolume: record.sumVolume,
    makeTime: record.makeTime,
    validTime: record.validTime,
    purity: record.purity
  }
  if (record.demarcateEnable) {
    if (record.reviewStatus === '1' && record.checkStatus === '2') {
      if (record.demarcateList && record.demarcateList.length > 0) {
        const demarcateList = record.demarcateList
        let demarcate = demarcateList[0]
        for (let i = 1; i < demarcateList.length; i++) {
          if (moment(demarcateList[i].demarcateTime) > moment(demarcate.demarcateTime)) {
            demarcate = demarcateList[i]
          }
        }
        if (demarcate.demarcateResult && demarcate.verifyResult && demarcate.checkResult) {
          solution.solutionDemarcatesStatus = '1'
          solution.solutionDemarcatesId = demarcate.id
        } else {
          solution.solutionDemarcatesStatus = '3'
          solution.solutionDemarcatesId = null
        }
      } else {
        solution.solutionDemarcatesStatus = '2'
        solution.solutionDemarcatesId = null
      }
    } else {
      solution.solutionDemarcatesStatus = '2'
      solution.solutionDemarcatesId = null
    }
  } else {
    solution.solutionDemarcatesId = null
    solution.solutionDemarcatesStatus = '0'
  }
  return solution
}
const handleCheckedSolution = () => {
  if (checkSolutionTypeList.value && checkSolutionTypeList.value.length > 0) {
    checkSolutionTypeList.value.forEach((item1, index1) => {
      item1.checked = false
      const solutions = item1.childrens.filter((item) => item.batchIsTure)
      if (solutions.length > 0) {
        item1.checked = true
        checkedIndex.value = item1.solutionTypeId
        const checkedSolutionList = solutions.map((item) => {
          return getSolution(item)
        })
        item1.solutionList = checkedSolutionList
      } else {
        const checkedSolutionList = []
        for (let i = 0; i < item1.childrens.length; i++) {
          const record = item1.childrens[i]
          if (moment(record.validTime) > moment(props.defaultMakeDate)) {
            if (record.demarcateEnable) {
              if (record.reviewStatus === '1' && record.checkStatus === '2') {
                if (record.demarcateList && record.demarcateList.length > 0) {
                  const demarcateList = record.demarcateList
                  let demarcate = demarcateList[0]
                  for (let i = 1; i < demarcateList.length; i++) {
                    if (moment(demarcateList[i].demarcateTime) > moment(demarcate.demarcateTime)) {
                      demarcate = demarcateList[i]
                    }
                  }
                  if (demarcate.demarcateResult && demarcate.verifyResult && demarcate.checkResult) {
                    const solution = {
                      solutionId: record.id,
                      solutionNo: record.solutionNo,
                      solutionName: record.name,
                      volume: record.volume,
                      sumVolume: record.sumVolume,
                      makeTime: record.makeTime,
                      validTime: record.validTime,
                      purity: record.purity,
                      solutionDemarcatesStatus: '1',
                      solutionDemarcatesId: demarcate.id
                    }
                    checkedSolutionList.push(solution)
                    break
                  }
                }
              }
            } else {
              const solution = {
                solutionId: record.id,
                solutionNo: record.solutionNo,
                solutionName: record.name,
                volume: record.volume,
                sumVolume: record.sumVolume,
                makeTime: record.makeTime,
                validTime: record.validTime,
                purity: record.purity,
                solutionDemarcatesId: null,
                solutionDemarcatesStatus: '0'
              }
              checkedSolutionList.push(solution)
              break
            }
          }
        }
        if (checkedSolutionList.length === 0) {
          const record = item1.childrens[0]
          const solution = getSolution(record)
          checkedSolutionList.push(solution)
        }
        item1.solutionList = checkedSolutionList
      }
    })
    let findIndex = checkSolutionTypeList.value.findIndex((item) => item.checked === true)
    if (findIndex === -1) {
      checkSolutionTypeList.value[0].checked = true
      checkedIndex.value = checkSolutionTypeList.value[0].solutionTypeId
    }
  }
}
onMounted(() => {
  handleCheckedSolution()
})

const handleSolutionChange = (index, id) => {
  const selectData = checkSolutionTypeList.value[index].childrens.filter((item) => item.id === id)
  const record = selectData[0]
  if (moment(record.validTime) < moment(props.defaultMakeDate)) {
    message.error('标液已过期，请重新配置')
  } else {
    const checkedSolutionList = selectData.map((item) => {
      return getSolution(item)
    })
    checkSolutionTypeList.value[index].solutionList = checkedSolutionList
  }
}

const delSolution = (index, _index) => {
  checkSolutionTypeList.value[index].solutionList.splice(_index, 1)
}

const handleSolutionTypeChange = (index) => {
  checkSolutionTypeList.value.forEach((item, index1) => {
    if (index === index1) {
      set(item, 'checked', true)
      checkedIndex.value = item.solutionTypeId
    } else {
      set(item, 'checked', false)
    }
  })
}

const { baseFormCard } = useFormProps()
</script>

<template>
  <a-card title="标液" v-bind="baseFormCard">
    <template #extra>
      <template v-if="!selectCheckGuide">
        <span class="red">请先选择检测方法</span>
      </template>
      <template v-else-if="!checkSolutionTypeList || !checkSolutionTypeList.length">
        <span class="red">{{ solutionReason || '暂未配置使用标液' }}</span>
      </template>
    </template>
    <ul-table :data-source="checkSolutionTypeList" :hidden-title="true" :hiddenWrap="true" :row-attrs="{ class: 'average' }">
      <template #default="{ data: standardSolution, index }">
        <div class="del-action">
          <a-tooltip>
            <template #title>如果未使用到此种标液，点我删除</template>
            <a @click="() => checkSolutionTypeList.splice(index, 1)">
              <a-icon type="close-circle" />
            </a>
          </a-tooltip>
        </div>
        <!-- flex:initial; -->
        <ul-table-column :data="standardSolution" class="checkedRadioColumn">
          <input
            :checked="checkedIndex === standardSolution.solutionTypeId"
            :disabled="checkSolutionTypeList.length === 1"
            name="checkSolutionType"
            type="radio"
            @change="handleSolutionTypeChange(index)"
          />
        </ul-table-column>
        <ul-table-column :data="standardSolution" data-index="solutionTypeName" title="标液类目"></ul-table-column>

        <ul-table-column title="使用标液" v-if="standardSolution.solutionList.length === 0">
          <template v-if="standardSolution.childrens && standardSolution.childrens.length">
            <a-select allowClear placeholder="请选择使用的标液" style="width: 300px" @change="(val) => handleSolutionChange(index, val)">
              <a-select-option v-for="item in standardSolution.childrens" :key="item.id">【{{ item.solutionNo }}】{{ item.name }}</a-select-option>
            </a-select>
          </template>
        </ul-table-column>

        <div v-else style="flex: 3">
          <div v-for="(item, _index) in standardSolution.solutionList" :key="`solutionName_${_index}`" class="flex item">
            <div :style="{ flex: 3 }">
              <a-tag color="blue">
                <a-icon :style="{ marginRight: '10px' }" type="check-circle" />
                {{ item.solutionName }}
              </a-tag>
            </div>
            <div :key="`solutionNo_${_index}`" style="flex: 2">
              使用液编号：<span :style="{ color: 'green', margin: '0 10px 0 5px', flex: 1 }">{{ item.solutionNo }}</span>
            </div>
            <div :key="`purity_${_index}`" style="flex: 2">浓度：{{ item.purity }}</div>
            <div :key="`validTime_${_index}`" style="flex: 3" class="makeTimeClass">
              <!--  -->
              <span>时效：</span>
              <span style="display: inline-block">
                <span>配置时间：{{ item.makeTime }}</span>
                <br />
                <span>有效时间：{{ item.validTime }}</span>
              </span>
            </div>
            <div :key="`solutionDemarcatesStatus_${_index}`" style="flex: 2">
              标定：<a-tag :color="checkStandardSolutionDemarcatesStatusDataMap[item.solutionDemarcatesStatus]?.color">{{
                checkStandardSolutionDemarcatesStatusDataMap[item.solutionDemarcatesStatus]?.label
              }}</a-tag>
            </div>
            <!-- <div :key="`solutionVolume_${_index}`" style="flex: 3">
              <span :style="{ margin: '0 10px' }">本次使用：</span>
              <a-input-number
                v-model="standardSolution.solutionVolume"
                :max="standardSolution.volume"
                :min="0"
                :style="{ width: '65px' }"
                size="small"></a-input-number>mL
            </div> -->
            <div :key="`solutionAction_${_index}`" :style="{ flex: 1 }">
              <a @click="delSolution(index, _index)" style="color: red">移除</a>
            </div>
          </div>
        </div>
      </template>
    </ul-table>
  </a-card>
</template>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';
.color();
.inlineBlock {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="less">
.makeTimeClass {
  display: flex;
  align-items: center;
  .title-row {
    margin: 0 !important;
  }
}
.checkedRadioColumn {
  flex: initial !important;
  width: 60px;
}
</style>
