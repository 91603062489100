import Vue from 'vue'
import { dictApi, dictItemApi } from '@/api/system'

const getDetail = (code) => {
  const detail = {}
  code.forEach(data => {
    detail[data.itemValue] = data.itemText
  })
  return detail
}

export default {
  namespaced: true,

  state: {
    cache: {}
  },

  mutations: {
    update (state, code) {
      if (code && code.length) {
        Vue.set(state.cache, code[0].dictCode, getDetail(code))
      }
    },

    updateDB (state, code) {
      if (code && code.length) {
        dictApi.dataList({ dictCode: code[0].dictCode, page: false }).then(res => {
          if (res.data.length) {
            code.forEach(item => {
              item.dictId = res.data[0].id
            })
            dictItemApi.batchUpdate(code)
          }
        })
      }
    },

    addAll (state, code) {
      if (code && code.length) {
        dictItemApi.deleteByCode({ dictCode: code[0].dictCode }).then(res => {
          if (res.code === 0) {
            dictApi.dataList({ dictCode: code[0].dictCode }).then(res => {
              if (res.data.length) {
                code.forEach(item => {
                  item.dictId = res.data[0].id
                })
                dictItemApi.batchUpdate(code)
              }
            })
          }
        })
      }
    }

  },

  actions: {
    select (store, codeKey) {
      if (!store.state.cache[codeKey]) {
        if (localStorage.getItem(codeKey)) {
          const cacheData = JSON.parse(localStorage.getItem(codeKey))
          store.commit('update', cacheData)
        } else {
          dictApi.getItems({ dictCode: codeKey }).then((res) => {
            if (res.code === 0) {
              store.commit('update', res.data)
              Vue.ls.set(codeKey, res.data)
            }
          })
        }
      }
    },

    fetch (store, codeKey) {
      if (!store.state.cache[codeKey]) {
        const cacheData = Vue.ls.get(codeKey)

        if (cacheData && cacheData.length) {
          store.commit('update', cacheData)
          return new Promise(resolve => {
            resolve(getDetail(cacheData))
          })
        } else {
          return new Promise(resolve => {
            dictApi.getItems({ dictCode: codeKey }).then((res) => {
              if (res.code === 0) {
                Vue.ls.set(codeKey, res.data)
                resolve(getDetail(res.data))
              }
            })
          })
        }
      } else {
        return new Promise(resolve => {
          resolve(store.state.cache[codeKey])
        })
      }
    }
  }
}
