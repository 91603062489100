import moment from 'moment'
import { dateFormat as dateFormatStr, minuteFormat, shortTimeFormat, timeFormat, timeFormat as timeFormatStr } from '@/pages/common/mixins/date'
import { computed } from 'vue'

/**
 * 当前时间
 * @returns {moment.Moment}
 */
export const now = () => {
  return moment()
}

/**
 * 日期相关的 hook
 * @return {{weekChinese: string[], dateFormat: (function(moment.Moment, string=): *), now: (function(): moment.Moment), timeFormat: (function(moment.Moment, string=): *), getMomentRangeByKey: (function(string, moment.Moment=): [moment.Moment,moment.Moment]), dateRangeProps: {showTime: {format: string}, format: string, style: {width: string}, placeholder: string[]}, moment: *, timeMomentProps: {showTime: boolean, format: string, style: {width: string}}}}
 */
export const useDate = () => {
  /** 星期中文 */
  const weekChinese = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']

  /** 时间配置 */
  const timeMomentProps = {
    style: { width: '100%' },
    format: timeFormatStr,
    showTime: true
  }

  /** 日期配置 */
  const dateRangeProps = {
    showTime: { format: minuteFormat },
    format: shortTimeFormat,
    style: { width: '100%' },
    placeholder: ['开始时间', '截止时间']
  }

  /**
   * 格式化日期
   * @param moment {moment.Moment} 日期对象
   * @param formatStr {string} 可选 日期格式
   * @returns {string} 日期的字符串格式
   */
  const dateFormat = (moment, formatStr = dateFormatStr) => {
    return moment && moment.format(formatStr)
  }

  /**
   * 格式化时间
   * @param moment {moment.Moment} 时间对象
   * @param formatStr {string} 可选 时间格式
   * @returns {string} 时间的字符串格式
   */
  const timeFormat = (moment, formatStr = timeFormatStr) => {
    return moment && moment.format(formatStr)
  }

  /**
   * 通过对应的KEY 获取指定时间的开始和结束范围
   * @param key {string} 时间表示符
   * @param date {moment.Moment} 需要获取的时间
   */
  const getMomentRangeByKey = (key, date = now()) => {
    let start, end
    const startMoment = moment(date)
    const endMoment = moment(date)
    switch (key) {
      case 'W':
        start = startMoment.startOf('isoWeek')
        end = endMoment.endOf('isoWeek')
        break
      case 'M':
        start = startMoment.startOf('month')
        end = endMoment.endOf('month')
        break
      case 'Q':
        start = startMoment.startOf('quarter')
        end = endMoment.endOf('quarter')
        break
      case 'Y':
        start = startMoment.startOf('year')
        end = endMoment.endOf('year')
        break
    }
    return [start, end]
  }

  return {
    moment,
    weekChinese,
    timeMomentProps,
    dateRangeProps,
    now,
    dateFormat,
    timeFormat,
    getMomentRangeByKey
  }
}

/**
 * 创建时间范围钩子
 * @param begin {string} 开始时间
 * @param end {string} 结束时间
 * @param format {string} 可选 时间格式
 * @return {*|moment.Moment[]|*[]}
 */
export const useDateRangeCompute = (begin, end, format = timeFormat) => {
  return computed({
    get () {
      return begin && end ? [moment(begin), moment(end)] : []
    },
    set (value) {
      const [_begin, _end] = value
      _begin && (begin = _begin.format(format))
      _end && (end = _end.format(format))
    }
  })
}
