<template>
  <div>
    <a-tabs :tabPosition="mode" v-if="standardList.length > 0" :activeKey="activeKey" @change="activeKeyChange">
      <template v-for="s in standardList">
        <a-tab-pane :tab="getTabName(s)" :key="s.id">
          <standard-item-factor :model="s" @limitData="(v) => limitData(v)"></standard-item-factor>
        </a-tab-pane>
      </template>
    </a-tabs>
    <div v-else style="text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.45)">暂无数据</div>
  </div>
</template>
<script>
import { standardItemApi, Get, standardItemFactorApi, itemCategoryApi } from '@/api/quote'
import StandardItemFactor from '@/pages/common/modal/StandardItemFactor'

export default {
  name: 'ExecuteStandardList',
  components: {
    StandardItemFactor
  },
  props: {
    param: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      mode: 'top',
      standardList: [],
      saveList: [],
      activeKey: ''
    }
  },

  methods: {
    activeKeyChange(activeKey) {
      this.activeKey = activeKey
    },
    limitData(v) {
      this.$emit('limitData', v)
    },
    getTabName(s) {
      return s.standardCode + '-' + s.standardName
    },
    loadSaveData() {
      return new Promise((resolve, reject) => {
        standardItemFactorApi.listProjectLimitValue({ projId: this.param.projId, cateId: this.param.cateId, itemId: this.param.itemId }).then((res) => {
          if (res.code === 0) {
            resolve(res.data)
          } else {
            resolve([])
          }
        })
      })
    },
    loadStandardList: async function (param) {
      param.page = false
      if (this.param.projId != undefined) {
        this.saveList = await this.loadSaveData()
      }
      if (param.cateId) {
        itemCategoryApi.list({ id: param.cateId }).then((result) => {
          if (result.code === 0) {
            if (result.data && result.data.length) {
              let queryParam = Object.assign({}, param)
              queryParam.cateType = result.data[0].cateType
              delete queryParam.cateId
              Get(standardItemApi.list, queryParam).then((res) => {
                if (res.code === 0) {
                  if (res.data.length > 0) {
                    this.activeKey = res.data[0].id
                  }
                  if (param.projId) {
                    this.standardList = res.data.reduce((array, item) => {
                      const savedData = this.saveList.filter((r) => r.standardItemId == item.id)
                      array.push({ ...item, projId: param.projId, savedData: savedData })
                      return array
                    }, [])
                    if (this.saveList.length > 0) {
                      this.activeKey = this.saveList[0].standardItemId
                    }
                  } else {
                    this.standardList = res.data
                  }
                }
              })
            }
          }
        })
      }
    },
    handleSubmit() {},
    close() {
      this.visible = false
    }
  },

  watch: {
    param: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.itemId) {
          this.loadStandardList(newVal)
        }
      }
    },
    activeKey: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.param.projId) {
          this.$emit('activeKeyChange', newVal)
        }
      }
    }
  }
}
</script>
<style lang="less"></style>
