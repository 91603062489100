<template>

  <div class="frequency-detail-item">
    <div class="frequency-detail-item-left">
      <span>
        {{ sample.sampleId }}
      </span>
      <a-tag :color="sampleInfoTypeDataMap[sample.sampleType].color">{{ sampleInfoTypeDataMap[sample.sampleType].label }}</a-tag>
    </div>
    <div class="frequency-detail-item-right">
      <div class="row">
        <div class="item">
          <span class="item-title">任务总数</span>
          <template v-if="sample.details.length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.details" :key="index">{{ item.cateName }}/{{ item.itemName }}</p>
              </template>
              <span class="content num gary">{{ sample.details.length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">排单情况</span>
          <a-tooltip>
            <template #title>
              已排： <span class="num mar-h5 green">{{ sample.details.filter(item => item.orderStatus !== '-1').length }}</span><br/>
              待排： <span class="num mar-h5 gray">{{ sample.details.filter(item => item.orderStatus === '-1').length }}</span>
            </template>
            <span class="item-content">
              <span class="num mar-h5 green">{{ sample.details.filter(item => item.orderStatus !== '-1').length }}</span>/
              <span class="num mar-h5 gray">{{ sample.details.filter(item => item.orderStatus === '-1').length }}</span>
            </span>
          </a-tooltip>

        </div>

        <div class="item">
          <span class="item-title">待开始</span>
          <template v-if="sample.taskStatusGroup[checkTaskStatusEnum.scheduled]&&sample.taskStatusGroup[checkTaskStatusEnum.scheduled].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.taskStatusGroup[checkTaskStatusEnum.scheduled]" :key="index">{{ item.cateName }}/{{ item.itemName }}</p>
              </template>
              <span class="content num gary">{{ sample.taskStatusGroup[checkTaskStatusEnum.scheduled].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">进行中</span>
          <template v-if="sample.taskStatusGroup[checkTaskStatusEnum.processing]&&sample.taskStatusGroup[checkTaskStatusEnum.processing].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.taskStatusGroup[checkTaskStatusEnum.processing]" :key="index">{{ item.cateName }}/{{ item.itemName }}</p>
              </template>
              <span class="content num orange">{{ sample.taskStatusGroup[checkTaskStatusEnum.processing].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">已完成</span>
          <template v-if="sample.taskStatusGroup[checkTaskStatusEnum.completed]&&sample.taskStatusGroup[checkTaskStatusEnum.completed].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.taskStatusGroup[checkTaskStatusEnum.completed]" :key="index">{{ item.cateName }}/{{ item.itemName }}</p>
              </template>
              <span class="content num green">{{ sample.taskStatusGroup[checkTaskStatusEnum.completed].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

      </div>
      <div class="row">
        <div class="item">
          <span class="item-title">待录入</span>
          <template v-if="sample.dataStatusGroup[checkTaskResultDataStatusEnum.pending]&&sample.dataStatusGroup[checkTaskResultDataStatusEnum.pending].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.dataStatusGroup[checkTaskResultDataStatusEnum.pending]" :key="index">{{ item.cateName }}/{{ item.itemName }}</p>
              </template>
              <span class="content num green">{{ sample.dataStatusGroup[checkTaskResultDataStatusEnum.pending].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">已录入</span>
          <template v-if="sample.dataStatusGroup[checkTaskResultDataStatusEnum.perfected]&&sample.dataStatusGroup[checkTaskResultDataStatusEnum.perfected].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.dataStatusGroup[checkTaskResultDataStatusEnum.perfected]" :key="index">{{ item.cateName }}/{{ item.itemName }}:
                  <a-tag v-if="item.resultType==='3'" color="blue">{{ resultDataType[item.resultType] }}</a-tag>
                  <a-tag v-else-if="item.resultType==='2'" color="blue">{{ item.resultValue }}L</a-tag>
                  <a-tag v-else color="blue">{{ item.resultValue }}</a-tag>
                </p>
              </template>
              <span class="content num green">{{ sample.dataStatusGroup[checkTaskResultDataStatusEnum.perfected].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">待复核</span>
          <template v-if="sample.dataStatusGroup[checkTaskResultDataStatusEnum.pendReview]&&sample.dataStatusGroup[checkTaskResultDataStatusEnum.pendReview].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.dataStatusGroup[checkTaskResultDataStatusEnum.pendReview]" :key="index">{{ item.cateName }}/{{ item.itemName }}:
                  <a-tag v-if="item.resultType==='3'" color="blue">{{ resultDataType[item.resultType] }}</a-tag>
                  <a-tag v-else-if="item.resultType==='2'" color="blue">{{ item.resultValue }}L</a-tag>
                  <a-tag v-else color="blue">{{ item.resultValue }}</a-tag>
                </p>
              </template>
              <span class="content num green">{{ sample.dataStatusGroup[checkTaskResultDataStatusEnum.pendReview].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">待审核</span>
          <template v-if="sample.dataStatusGroup[checkTaskResultDataStatusEnum.done]&&sample.dataStatusGroup[checkTaskResultDataStatusEnum.done].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.dataStatusGroup[checkTaskResultDataStatusEnum.done]" :key="index">{{ item.cateName }}/{{ item.itemName }}:
                  <a-tag v-if="item.resultType==='3'" color="blue">{{ resultDataType[item.resultType] }}</a-tag>
                  <a-tag v-else-if="item.resultType==='2'" color="blue">{{ item.resultValue }}L</a-tag>
                  <a-tag v-else color="blue">{{ item.resultValue }}</a-tag>
                </p>
              </template>
              <span class="content num green">{{ sample.dataStatusGroup[checkTaskResultDataStatusEnum.done].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>

        <div class="item">
          <span class="item-title">已审核</span>
          <template v-if="sample.dataStatusGroup[checkTaskResultDataStatusEnum.checkPass]&&sample.dataStatusGroup[checkTaskResultDataStatusEnum.checkPass].length">
            <a-tooltip>
              <template #title>
                <p v-for="(item,index) in sample.dataStatusGroup[checkTaskResultDataStatusEnum.checkPass]" :key="index">{{ item.cateName }}/{{ item.itemName }}:
                  <a-tag v-if="item.resultType==='3'" color="blue">{{ resultDataType[item.resultType] }}</a-tag>
                  <a-tag v-else-if="item.resultType==='2'" color="blue">{{ item.resultValue }}L</a-tag>
                  <a-tag v-else color="blue">{{ item.resultValue }}</a-tag>
                </p>
              </template>
              <span class="content num green">{{ sample.dataStatusGroup[checkTaskResultDataStatusEnum.checkPass].length }}</span>
            </a-tooltip>
          </template>
          <span v-else class="content num gray">0</span>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import { checkTaskResultDataStatusEnum } from '@/api/check/checkTaskResultConstant'
import { sampleInfoTypeDataMap } from '@/api/sampleInfo/sampleInfoConstant'

export default {
  props: { sample: { type: Object, required: true } },

  data () {
    return {
      checkTaskStatusEnum,
      checkTaskResultDataStatusEnum,
      sampleInfoTypeDataMap,

      checkSampleItemList: [],

      loading: true,

      sampleCard: {
        title: '按检测因子'
      },

      itemCard: {
        title: '按因子'
      }
    }
  },

  computed: {
    resultDataType () {
      return { ...this.$store.state.code.cache.check_result_data_type }
    }

  }

}
</script>

<style lang="less" scoped>
@import "detail";
</style>
