'use strict'

const defaultColumn = [
  { title: '序号', align: 'center', dataIndex: 'id', width: 50, scopedSlots: { customRender: 'num' } },
  { title: '样品类型', align: 'center', dataIndex: 'sampleType', width: 100, scopedSlots: { customRender: 'sampleType' } },
  { title: '数据状态', align: 'center', dataIndex: 'dataStatus', width: 80, scopedSlots: { customRender: 'dataStatus' } },
  { title: '检测项', key: 'items', width: 180, align: 'center', scopedSlots: { customRender: 'items' } },
  { title: '检测设备', dataIndex: 'deviceNames', key: 'deviceNames', width: 180, scopedSlots: { customRender: 'deviceNames' } },
  { title: '检测时间', dataIndex: 'checkTime', key: 'checkTime', width: 150, align: 'center', scopedSlots: { customRender: 'checkTime' } },
  { title: '原始值', dataIndex: 'originalValue', key: 'originalValue', width: 100, align: 'center', scopedSlots: { customRender: 'originalValue' } },
  { title: '结果值', dataIndex: 'value', key: 'value', width: 100, align: 'center', scopedSlots: { customRender: 'value' } },
  { title: '检测用量', dataIndex: 'volume', key: 'volume', width: 80, align: 'center', scopedSlots: { customRender: 'volume' } },
  { title: '计量单位', dataIndex: 'volumeUnit', key: 'volumeUnit', width: 80, align: 'center', scopedSlots: { customRender: 'volumeUnit' } },
  { title: '照片', dataIndex: 'accessory', key: 'accessory', width: 160, align: 'center', scopedSlots: { customRender: 'accessory' } },
  { title: '录入时间', dataIndex: 'createTime', key: 'createTime', width: 150, align: 'center', scopedSlots: { customRender: 'createTime' } },
  { title: '操作', dataIndex: 'action', key: 'action', width: 180, align: 'center', scopedSlots: { customRender: 'action' } }

]

const simpleColumn = [
  { title: '序号', align: 'center', dataIndex: 'id', width: 50, scopedSlots: { customRender: 'num' } },
  { title: '样品编号', key: 'sampleId', width: 180, align: 'center', scopedSlots: { customRender: 'sampleId' } },
  { title: '样品类型', align: 'center', dataIndex: 'sampleType', width: 100, scopedSlots: { customRender: 'sampleType' } },
  { title: '数据状态', align: 'center', dataIndex: 'dataStatus', width: 80, scopedSlots: { customRender: 'dataStatus' } },
  { title: '检测时间', dataIndex: 'checkTime', key: 'checkTime', width: 150, align: 'center', scopedSlots: { customRender: 'checkTime' } },
  { title: '结果值', dataIndex: 'value', key: 'value', width: 100, align: 'center', scopedSlots: { customRender: 'value' } }
]

export {
  defaultColumn as checkTaskResultDefaultColumn,
  simpleColumn as checkTaskResultSimpleColumn
}
