<template>
  <div>

    <a-form>
      <a-card title="领用计划详情" :bordered="true" style="margin: 20px;border: 1px solid #5cb3fb;">

        <detail-list >
          <detail-list-item term="领用批次号">{{this.purList.purUseNbr}}</detail-list-item>
          <detail-list-item term="领用批次名称">{{this.purList.purUseNbr}}</detail-list-item>
          <detail-list-item term="申请人姓名">{{this.purList.userName}}</detail-list-item>
          <detail-list-item term="领用申请日期">{{this.purList.dateOfPurUse}}</detail-list-item>
          <detail-list-item term="领用原因">{{this.purList.purUseReason}}</detail-list-item>
          <detail-list-item term="备注">{{this.purList.purUseDesc}}</detail-list-item>
        </detail-list>
      </a-card>
      <a-card title="物资信息" :bordered="true" style="margin: 20px;border: 1px solid #5cb3fb;">
        <a-table
          ref="table"
          :columns="columns"
          :dataSource="data"
          :pagination="false"
        >
          >
          <template v-for="(col, i) in ['cateId', 'materialName', 'speType','unit','materialNum','numOfUse']" :slot="col" slot-scope="text, record">

            <template v-if="col === 'cateId'">
              <!-- <a-tree-select
                style="width:100%"
                :value="text"
                :disabled="true"
                :placeholder=columns[i].title
                @change="e => handleChange(e, record.id,record.key, col)"
                :treeData="categoryList"
              />-->
              {{getName(text,record)}}
            </template>
            <template  v-else-if="col==='unit'">
              {{getUnit(text,record)}}
            </template>
            <template v-else>
              {{text}}
            </template>
          </template>
          <template slot="operation" slot-scope="text, record">
            <span >
              <a-popconfirm title="是否要删除此行?" @confirm="remove(record)">
                <a>移除</a>
              </a-popconfirm>
            </span>
          </template>
        </a-table>
      </a-card>
    <!-- <a-card title="设备信息" :bordered="true" style="margin: 20px;">
      <a-table
        ref="table"
        :columns="devColumns"
        :dataSource="devData"
        :pagination="false"
      >
        >
        <template v-for="(col, i) in ['cateId', 'materialName', 'speType','unit','materialNum','numOfUse']" :slot="col" slot-scope="text, record">

          <template v-if="col === 'cateId'">
            <a-tree-select
              style="width:100%"
              :value="text"
              :disabled="true"
              :placeholder=columns[i].title
              @change="e => handleChange(e, record.id,record.key, col)"
              :treeData="categoryList"
            />
          </template>

          <template  v-else-if="col==='numOfUse'">
            <a-input-number
              :key="col"
              style="margin: -5px 0"
              :value="text"
              :placeholder="columns[i].title"
              :min="1"
              :disabled="true"
              @change="e =>handleChange(e, record.id,record.key, col)"
            >
            </a-input-number>
          </template>
          <template  v-else-if="col==='unit'">
            <a-select
              :key="col"
              style="margin: -5px 0"
              :disabled="true"
              :value="text"
              :placeholder="columns[i].title"
              @change="e => handleChange(e.target.value, record.materialName, col)"
            >
              <a-select-option v-for="vo in dictOptions" :key="vo.itemValue">{{ vo.itemText }}</a-select-option>
            </a-select>
          </template>
          <template v-else>
            <a-input
              :key="col"
              style="margin: -5px 0"
              :disabled="true"
              :value="text"
              :placeholder="columns[i].title"
              @change="e => handleChange(e.target.value, record.id,record.key, col)"
            />
          </template>
        </template>
        <template slot="operation" slot-scope="text, record">
       <span >
         <a-popconfirm title="是否要删除此行?" @confirm="remove(record)">
           <a>移除</a>
         </a-popconfirm>
         </span>
        </template>
      </a-table>
    </a-card>-->
    </a-form>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import { STable } from '@/components'
import DetailList from '@/components/tools/DetailList'
import { Get, resPurUseApi } from '@/api/quote'
const DetailListItem = DetailList.Item
export default {
  name: 'purPlanIndexForFlow',
  props: {
    dictOptions: {
      type: Array,
      default: () => []
    },
    userName: {
      type: String,
      default: ''
    },
    locationList: {
      type: Array,
      default: () => []
    },
    categoryList: {
      type: Array,
      default: () => []
    },
    cateList: {
      type: Array,
      default: () => []
    },
    recordId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageView,
    DetailList,
    DetailListItem,
    STable
  },
  data () {
    return {
      visible: false,
      data: [],
      // devData:[],
      purList: [],
      goodsList: [],
      title: '领用申请',
      // devColumns:[
      //   {
      //     title: '内部编号',
      //     dataIndex: 'fixedAssetsId',
      //     key: 'fixedAssetsId',
      //     width: '250px',
      //     scopedSlots: {customRender: 'fixedAssetsId'}
      //   },
      //   {
      //     title: '设备类别',
      //     dataIndex: 'cateId',
      //     key: 'cateId',
      //     width: '250px',
      //     scopedSlots: {customRender: 'cateId'}
      //   },
      //   {
      //     title: '设备名称',
      //     dataIndex: 'devName',
      //     key:'devName',
      //     width:'250px',
      //     scopedSlots: {customRender: 'devName'}
      //   },
      //   {
      //     title: '型号',
      //     dataIndex: 'speType',
      //     key: 'speType',
      //     width:'150px',
      //     scopedSlots: {customRender: 'speType'}
      //   },
      //   {
      //     title: '放置位置',
      //     dataIndex: 'locationId',
      //     key: 'locationId',
      //     width:'150px',
      //     scopedSlots: {customRender: 'locationId'}
      //   }
      // ],
      columns: [
        {
          title: '物品类别',
          dataIndex: 'cateId',
          key: 'cateId',
          width: '250px',
          scopedSlots: { customRender: 'cateId' }
        },
        {
          title: '物品名称',
          dataIndex: 'materialName',
          key: 'materialName',
          width: '250px',
          scopedSlots: { customRender: 'materialName' }
        },
        {
          title: '规格型号',
          dataIndex: 'speType',
          key: 'speType',
          width: '150px',
          scopedSlots: { customRender: 'speType' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: '150px',
          scopedSlots: { customRender: 'unit' }
        },
        // {
        //   title: '总数量',
        //   width:'150px',
        //   dataIndex: 'materialNum',
        //   key: 'materialNum',
        //   scopedSlots: {customRender: 'materialNum'}
        //  },
        {
          title: '使用数量',
          width: '150px',
          dataIndex: 'numOfUse',
          key: 'numOfUse',
          scopedSlots: { customRender: 'numOfUse' }
        }
        // {
        //    title: '操作',
        //    key: 'action',
        //    scopedSlots: { customRender: 'operation' }
        //  }
      ]
    }
  },
  watch: {
    recordId: {
      handler (newValue, oldValue) {
        if (newValue && newValue > 0) {
          this.handleDetail(newValue)
        }
      },
      immediate: true
    }
  },
  methods: {
    getName (text, record) {
      const cate = this.cateList.filter(item => item.id.toString() === record.cateId)[0]
      if (cate) {
        record.cateName = cate.name
      } else {
        record.cateName = '-'
      }
      return record.cateName
    },
    getUnit (text, record) {
      const cate = this.dictOptions.filter(item => item.itemValue.toString() === record.unit)[0]
      if (cate) {
        record.cateName = cate.itemText
      } else {
        record.cateName = '-'
      }
      return record.cateName
    },
    handleCancel () {
      this.visible = false
    },
    handleDetail (id) {
      this.purList = []
      this.data = []
      Get(`${resPurUseApi.list}`, { pageSize: 1, id: id }, {}).then(result => {
        this.purList = result.data[0]
      })
      Get(`${resPurUseApi.getGoodsById}/${id}`, {}).then(result => {
        this.data = result.data
      })
      // Get(`${resPurUseApi.getDevByid}/${id}`, {}).then(result => {
      //   this.devData = result.data
      // })

      this.visible = true
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'processing': '进行中',
        'success': '完成',
        'failed': '失败'
      }
      return statusMap[status]
    }
  }

}
</script>

<style lang="less" scoped>
  .title {
    color: rgba(0,0,0,.85);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }
</style>
