<template>
  <div class="log-details">
    <table border="1" align="center" width="550" class="table">
      <tr>
        <th>字段</th>
        <th>操作前</th>
        <th>操作后</th>
      </tr>
      <template v-for="(cItem,cIndex) in data.details">
        <template v-if="Object.keys(cItem.jsonBefore||{}).length">
          <template v-for="(dItem,dIndex) in Object.keys(cItem.jsonBefore) ">
            <tr :key="`${cIndex}_${dIndex}`">
              <td>{{ dItem }}</td>
              <td>{{ cItem.jsonBefore[dItem] }}</td>
              <td>{{ cItem.jsonAfter[dItem] }}</td>
            </tr>
          </template>
        </template>

        <template v-else-if="Object.keys(cItem.jsonAfter||{}).length">
          <template v-for="(dItem,dIndex) in Object.keys(cItem.jsonAfter) ">
            <tr :key="`${cIndex}_${dIndex}`">
              <td>{{ dItem }}</td>
              <td>{{ cItem.jsonBefore[dItem] }}</td>
              <td>{{ cItem.jsonAfter[dItem] }}</td>
            </tr>
          </template>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      data: {}
    }
  },
  watch: {
    detail: {
      immediate: true,
      handler (val) {
        val && this.dealDetailData(val)
      }
    }
  },
  methods: {
    dealDetailData (data = {}) {
      const cloneData = JSON.parse(JSON.stringify(data))
      // 将jsonBefore 和jsonAfter 转换成对象
      this.dealData(cloneData)
      this.data = cloneData
    },

    dealData (data) {
      if (data.jsonBefore) {
        data.jsonBefore = JSON.parse(data.jsonBefore)
      }

      if (data.jsonAfter) {
        data.jsonAfter = JSON.parse(data.jsonAfter)
      }

      if (data.details) {
        data.details.forEach(item => this.dealData(item))
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import "_index.less";
</style>
