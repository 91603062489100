<template>
  <div>
    <template v-if="projId">
      <div>
        <span style="margin-left: 20px">报价金额：<b style="font-size: 16px; color: #ff0000;">{{ formatCurrency(project.quoteFinalAmount) }}</b> 元</span>
        <span style="margin-left: 20px">合同金额：<b style="font-size: 16px; color: #ff0000;">{{ formatCurrency(project.contractAmount) }}</b> 元</span>
<!--        <span style="margin-left: 20px">已收款金额：<b style="font-size: 20px; color: #ff0000;">{{ formatCurrency(156456) }}</b> 元</span>-->
      </div>
    </template>
  </div>
</template>

<script>

import { currency } from '@/utils/util'
import { projectApi } from '@/api/project'

export default {

  name: 'ProjectReceiptInfo',
  components: { },
  props: {
    projId: {
      type: String,
      required: true,
      default: null
    }
  },

  data () {
    return {
      project: {}
    }
  },
  methods: {
    getProject () {
      var projId = this.projId
      if (projId) {
        projectApi.get({ id: projId }).then(result => {
          if (result.code === 0) {
            this.project = result.data
          }
        })
      } else {
        this.project = {}
      }
    },
    formatCurrency (value) {
      if (value) {
        return currency(value)
      } else {
        return '￥0.00'
      }
    }
  },
  watch: {
    projId: {
      immediate: true,
      handler (nv, ov) {
        this.getProject()
      }
    }
  },
  computed: {
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
