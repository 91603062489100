<template>
  <div>
    <a-row>
      <a-col span="3">
        <div class="ahmux-sub-title" style="width: 100px;">
          确认信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ cbtInfo.applyUser }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ cbtInfo.applyDate }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th class="tdLabel">确认人</th>
          <td colspan="2">
            <select-user-by-dept ref="devUser" :disabled="disabled" :triggerChange="true" @change="handleUserChange" rowType="radio"/>
          </td>
        </tr>
        <tr>
          <th>证书编号：</th>
          <td>{{ cbtInfo.cbtCertNbr }}</td>
          <th>仪器名称：</th>
          <td>{{ devInfo.devName }}</td>
          <th>有效期：</th>
          <td>{{ getTermOfValid() }}</td>
        </tr>
        <tr>
          <th>规格型号：</th>
          <td>{{ devInfo.speType }}</td>
          <th>测量范围：</th>
          <td colspan="3">{{ devInfo.meaRange }}</td>
        </tr>
        <tr>
          <th>不确定度或准确度等级或最大允许误差：</th>
          <td>{{ cbtInfo.conclusion }}</td>
          <th>编号：</th>
          <td>{{ devInfo.exitNbr }}</td>
          <th>固定资产编号：</th>
          <td>{{ devInfo.fixedAssetsId }}</td>
        </tr>
        <tr>
          <th>校准计划要求参数：</th>
          <td colspan="5">{{ devInfo.cbtParamIdx }}</td>
        </tr>
        <template v-if="confirmStatus !== 0">
          <tr>
            <th colspan="3">确认问题</th>
            <th colspan="3">确认结果</th>
          </tr>
          <tr>
            <td colspan="3">1.校准参数是否符合使用要求：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueOne" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <td colspan="3">2.给出的测量范围是否符合使用要求：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueTwo" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <td colspan="3">3.校准结果与上一周期比较是否超出允许偏差范围：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueThree" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <td colspan="3">4.证书信息是否正确、完全：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueFour" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <td colspan="3">5.有无其他对检测工作正常开展造成不良影响的不符合项目：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueFive" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <td colspan="3">6.仪器设备验证：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueSix" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <td colspan="3">7.其他：</td>
            <td colspan="3">
              <a-input v-model="issueList.issueSeven" placeholder="请输入确认结果"></a-input>
            </td>
          </tr>
          <tr>
            <th colspan="3" class="tdLabel">结论</th>
            <td colspan="3">
              <a-input v-model="issueList.conclusion" placeholder="请输入结论"></a-input>
            </td>
          </tr>
        </template>
      </table>
    </a-row>
  </div>
</template>

<script>
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { Get, Post, resCbtApi } from '@/api/quote'

export default {
  name: 'CbtConfirmModal',
  components: {
    SelectUserByDept,
    Get,
    Post,
    resCbtApi
  },
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },
  data () {
    return {
      disabled: false,
      cbtInfo: {},
      devInfo: {},
      issueList: {
        issueOne: '是',
        issueTwo: '是',
        issueThree: '未超出',
        issueFour: '是',
        issueFive: '无',
        issueSix: '符合',
        issueSeven: '符合',
        conclusion: '可正常使用'
      },
      confirmStatus: undefined
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.loadData()
        }
      }
    }
  },
  methods: {
    init (record, devInfo) {
      this.issueList = {}
      this.confirmStatus = 0
      this.cbtInfo = record
      this.devInfo = devInfo
      this.cbtInfo.applyUser = this.loginUser.username
      this.cbtInfo.applyDate = new Date().format('yyyy-MM-dd HH:mm:ss')
      if (record.cbtConfirmer) {
        Get(resCbtApi.getUserById, { id: record.cbtConfirmer }).then(res => {
          const user = {
            userId: res.data.id,
            username: res.data.username,
            name: res.data.realname
          }
          this.$refs.devUser.setUserList([user])
        })
      }
      Get(resCbtApi.getResult, { id: record.id }).then(res => {
        this.resultList = res.data
      })
    },
    getTermOfValid () {
      if (this.cbtInfo.termOfValid) {
        return this.cbtInfo.termOfValid.split(' ')[0]
      } else if (this.devInfo.termOfValid) {
        return this.devInfo.termOfValid.split(' ')[0]
      }
    },
    loadData () {
      this.disabled = true
      Get(resCbtApi.getCbtConfirmInfo, { id: this.recordId }).then(res => {
        if (res.code === 0) {
          this.cbtInfo = res.data.cbtInfo
          this.confirmStatus = parseInt(res.data.confirmStatus)
          if (res.data.issueList) {
            this.issueList = res.data.issueList
          }
          this.devInfo = res.data.devInfo
          this.$refs.devUser.setUserList(res.data.userList)
        }
      })
    },
    handleUserChange (userList) {
      if (userList.length > 0) {
        this.cbtInfo.cbtConfirmerName = userList[0].username
      }
    },
    isNullOrEmpty (item) {
      return item === undefined || item === null || item === ''
    },
    handleSubmit () {
      if (!this.cbtInfo.cbtConfirmerName) {
        this.$message.error('确认人不能为空！')
        return
      }
      this.postSubmit()
    },
    postSubmit () {
      Post(resCbtApi.cbtConfirm, this.cbtInfo).then(res => {
        if (res.code === 0) {
          if (this.cbtInfo.cbtConfirmStatus === 0) {
            this.$message.success('仪器设备校准/检定确认流程申请成功！')
          } else {
            this.$message.success('仪器设备校准/检定确认流程重新提交成功！')
          }
          this.$emit('ok')
        } else {
          if (this.cbtInfo.cbtConfirmStatus === 0) {
            this.$message.error('仪器设备校准/检定确认流程申请失败！')
          } else {
            this.$message.error('仪器设备校准/检定确认流程重新提交失败！')
          }
        }
      })
    }
  }
}
</script>
