<template>
  <a-modal
    :title="title"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOK"
    :destroyOnClose="true"
  >
    <a-form :form="form" layout="inline">
      <a-row>
        <a-form-item label="当前负责人">
          {{ quote.quoteContactsName }}
        </a-form-item>
      </a-row>
      <a-row>
        <a-form-item label="变更负责人">
          <a-select
            style="width: 260px"
            showSearch
            optionFilterProp="children"
            @change="projUserIdChange"
            :filterOption="filterOption"
            v-decorator="[
              'projUserId',
              {rules: [{ required: true, message: '请选择负责人'}]}
            ]"
          >
            <a-select-option v-for="vo in this.userList" :key="vo.username">{{ vo.realname }}({{ vo.username }})</a-select-option>
          </a-select>
        </a-form-item>
      </a-row>
    </a-form>
  </a-modal>
</template>
<script>
import { quoteApi, Get } from '@/api/quote'
import { userApi } from '@/api/system'
var vm = {}
export default {
  name: 'QuoteContactsChange',
  components: { },
  props: {
    siteList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    vm = this
    return {
      title: '负责人变更',
      visible: false,
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      quote: {},
      userList: [],
      form: this.$form.createForm(this),
      newContacts: {
        quoteContacts: null,
        quoteContactsName: null,
        quoteContactsPhone: null
      }

    }
  },
  methods: {
    change (quote) {
      const self = this
      self.quote = quote
      self.listUser()
      self.visible = true
    },
    listUser () {
      const self = this
      Get(`${userApi.list}`, { status: 1, pageSize: 5000 }).then(result => {
        self.userList = result.data
      })
    },
    projUserIdChange (value) {
      const user = this.userList.filter(item => value === item.username)[0]
      if (user) {
        this.$set(this.newContacts, 'quoteContacts', user.username)
        this.$set(this.newContacts, 'quoteContactsName', user.realname)
        this.$set(this.newContacts, 'quoteContactsPhone', user.phone)
      } else {
        this.$set(this.newContacts, 'quoteContacts', '')
        this.$set(this.newContacts, 'quoteContactsName', '')
        this.$set(this.newContacts, 'quoteContactsPhone', '')
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      const self = this
      self.form.validateFields((error, values) => {
        if (error == null) {
          const params = {
            id: self.quote.id,
            quoteContacts: self.newContacts.quoteContacts,
            quoteContactsName: self.newContacts.quoteContactsName,
            quoteContactsPhone: self.newContacts.quoteContactsPhone,
            oldQuoteContacts: self.quote.quoteContacts,
            oldQuoteContactsName: self.quote.quoteContactsName,
            oldQuoteContactsPhone: self.quote.quoteContactsPhone
          }
          quoteApi.editContact(params).then(result => {
            if (result.code === 0) {
              this.$message.success(`${self.title}成功`)
              // this.updateSite(result.data)
              this.$emit('ok', null)
              this.handleCancel()
            } else {
              this.$message.error(result.msg)
            }
          }).catch(error => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
    }
  },
  watch: {
  }
}
</script>
