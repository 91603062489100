<template>
  <a-modal
    style="margin-top: -100px;"
    title="下单"
    width="60%"
    :visible="visible"
    :destroyOnClose="true"
    :maskClosable="false"
    @ok="handleOK"
    @cancel="handleCancel"
  >
    <div>
      <a-card title="项目信息">
        <a-row style="margin: 8px 0px;">
          <a-col :span="24">
            <label title="项目名称" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">项目名称：</label>{{ project.projName }}
          </a-col>
        </a-row>
        <a-row style="margin: 8px 0px;">
          <a-col :xs="24" :sm="12">
            <label title="项目编号" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">项目编号：</label>{{ project.projNo }}
          </a-col>
          <a-col :span="24" :sm="12">
            <label title="报告类型" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">报告类型：</label>{{ reportTypeMap[project.projReportType] }}
          </a-col>
        </a-row>
        <a-row style="margin: 8px 0px;">
          <a-col :span="24" :sm="12">
            <label title="委托单位" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">委托单位：</label>{{ project.entrustCustomerName }}
          </a-col>
          <a-col :span="24" :sm="12">
            <label title="受检单位" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">受检单位：</label>{{ project.customerName }}
          </a-col>
        </a-row>
        <a-row style="margin: 8px 0px;">
          <a-col :span="24" :sm="12">
            <label title="项目负责人" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">项目负责人：</label>{{ project.projContact }}
          </a-col>
          <a-col :xs="24" :sm="12">
            <label title="联系电话" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">联系电话：</label>{{ project.projContactPhone }}
          </a-col>
        </a-row>
        <a-row style="margin: 8px 0px;">
          <a-col :xs="24" :sm="12">
            <label title="项目地址" style="color: rgba(0, 0, 0, 0.85);font-size: 14px;">项目地址：</label>{{ project.projAddress }}
          </a-col>
        </a-row>
      </a-card>
      <a-card title="其他信息">
        <a-form :form="form">
          <a-row>
            <a-col :span="12">
              <a-form-item v-bind="formItemLayout" label="现场负责人">
                <!--              <a-input style="width: 95%" placeholder="现场负责人" v-decorator="[ 'taskOnsiteContactId', {initialValue: this.task.taskOnsiteContactId, rules: [{ required: true, message: '现场负责人', whitespace: true},{ max: 50, message: '内容过长不得超过50'}]} ]" />-->
                <a-select
                  style="width: 95%"
                  showSearch
                  placeholder="现场负责人"
                  optionFilterProp="children"
                  @change="taskOnsiteContactChange"
                  :filterOption="filterOption"
                  v-decorator="[
                  'taskOnsiteContactId',
                  {initialValue: task.taskOnsiteContactId, rules: [{ required: true, message: '请选择现场负责人'}]}
                ]">
                  <a-icon type="plus-square" @click="showAddCustomerUser" slot="suffixIcon" />
                  <a-select-option v-for="vo in this.customerUserList" :key="vo.id">{{ vo.csrUserName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item v-bind="formItemLayout" label="联系电话">
                <a-input style="width: 95%" placeholder="联系电话" v-decorator="[ 'taskOnsitePhone', {initialValue: this.task.taskOnsitePhone, rules: [{ required: true, message: '联系电话', whitespace: true},{ max: 50, message: '内容过长不得超过50'}]} ]" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item>
                <a-textarea style="width: 95%" placeholder="备注" :autosize="{ minRows: 2, maxRows: 8 }" v-decorator="['remark', {initialValue: this.task.remark, rules: [{ max: 200, message: '内容过长不得超过200'}]}]"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
      <a-card title="检测项点位/检测项" class="card-not-padding">
        <div>
          <!--        <div class="total-desc">-->
          <!--          共计：<span>{{ task.siteList.length }}个</span>点位，-->
          <!--        </div>-->
          <a-card class="card-not-padding" v-for="(site,siteIdx) in project.siteList" :key="site.id">
            <template slot="title">
              {{ siteIdx+1 }}、<a-icon type="environment" theme="twoTone" title="检测点" @click="editSite(siteIdx,site)"/>
              {{ site.siteName }}
              <a-icon type="edit" theme="twoTone" title="编辑点位" @click="editSite(siteIdx,site)" style="cursor: pointer;font-size: 14px;margin-left:10px"/>
            </template>
            <a-card :key="cateIdx" v-for="(category, cateIdx) in site.itemCategoryList" class="card-not-padding">
              <template slot="title">
                <a-icon type="experiment" theme="twoTone" title="检测项" @click="editSite(siteIdx,site)"/>
                {{ category.name }}
              </template>
              <div slot="extra">
                <a-button size="small" @click="selectStandard(siteIdx, cateIdx)">选择标准</a-button>
                <!--              <a-button size="small" @click="itemSub(record, siteIdx, cateIdx)" style="margin-left: 5px">分包</a-button>-->
              </div>
              <a-table
                ref="`itemTable_${siteIdx}_${cateIdx}`"
                bordered
                class="standardTable"
                :columns="table.columns"
                :dataSource="category.itemList"
                :pagination="false"
                size="small"
              >
                <template slot="itemType" slot-scope="text, record, itemIdx">
                  <template v-if="text==1">是</template>
                  <template v-else-if="text==0">否</template>
                  <template v-else>{{ text }}</template>
                </template>
                <!--              <template slot="sctrName" slot-scope="text, record, itemIdx">-->
                <!--                <a-tag v-if="record.sctrId" closable :visible="true" @close="delItemSub(record)">{{ text }}</a-tag>-->
                <!--                <a style="margin-right: 5px" @click="itemSub(record, siteIdx, cateIdx, itemIdx)"><a-icon type="edit" theme="twoTone"/>分包</a>-->
                <!--              </template>-->
                <template slot="standard" slot-scope="text, record, itemIdx">
                  <a-tag closable v-for="(standard, standardIdx) in record.standardList" :key="standardIdx" :visible="true" @close="deleteStandard(record, standard, standardIdx)">{{ standard.standardCode }}-{{ standard.standardName }}</a-tag>
                  <a style="margin-right: 5px" @click="selectStandard(siteIdx, cateIdx, itemIdx, record)"><a-icon type="edit" theme="twoTone" title="编辑标准"/>选择</a>
                </template>
                <template slot="action" slot-scope="text, record, itemIdx">
                  <template v-if="record.editable">
                    <a @click="saveRow(record, siteIdx, cateIdx, itemIdx)">保存</a>
                  </template>
                  <template v-else>
                    <a @click="editRow(siteIdx, cateIdx, itemIdx)">编辑</a>
                  </template>
                </template>
              </a-table>
            </a-card>
          </a-card>
        </div>
      </a-card>
      <SiteForm ref="siteForm" @updateSiteInfo="updateSiteInfo"></SiteForm>
      <ItemSubList ref="itemSubList" @itemSubOk="itemSubOk"></ItemSubList>
      <SelectStandard ref="standard" @selectStandardOk="selectStandardOk"></SelectStandard>
      <CustomerUserModal ref="customerUserModal" @ok="customerUserOk" :customerList="customerList"></CustomerUserModal>
      <SelectStandardLimitValue ref="executeStandardForm" @ok="updateItemStandard"></SelectStandardLimitValue>
    </div>
  </a-modal>
</template>

<script>
import { standardItemApi, customerUserApi, Get } from '@/api/quote'
import { projectApi, projTaskItemApi, projTaskApi } from '@/api/project'
import SiteForm from './SiteForm'
import ItemSubList from '@/pages/cloud-quote/item-sub/ItemSubList'
import SelectStandard from './SelectStandard.vue'
import CustomerUserModal from '@/pages/cloud-quote/customer-user/Form'
import SelectStandardLimitValue from './SelectStandardLimitValue'

var vm = {}
export default {
  name: 'TaskFormModal',
  components: {
    SiteForm,
    ItemSubList,
    SelectStandard,
    SelectStandardLimitValue,
    CustomerUserModal
  },
  props: {
  },
  data () {
    vm = this
    window.vm = this
    return {
      visible: false,
      addType: 'add',
      project: {},
      task: {},
      standard: [],
      customerList: [],
      customerUserList: [],
      formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      form: this.$form.createForm(this),
      table: {
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '序号', dataIndex: 'id', customRender: (v, r, i) => (i + 1), width: '45px', className: 'column-center' },
          { title: '检测项', dataIndex: 'itemName', width: '200px', className: 'column-center' },
          { title: '频次', dataIndex: 'numFrequency', width: '45px', className: 'column-center' },
          { title: '周期', dataIndex: 'numPeriod', width: '45px', className: 'column-center' },
          { title: '分包项', dataIndex: 'itemType', width: '60px', className: 'column-center', scopedSlots: { customRender: 'itemType' } },
          // { title: '分包', dataIndex: 'sctrName', className: 'column-center', scopedSlots: { customRender: 'sctrName' } },
          { title: '执行标准', dataIndex: 'standard', className: 'column-center', scopedSlots: { customRender: 'standard' } }
        ],
        pagination: false
      }
    }
  },
  methods: {
    add (_record) {
      this.visible = true
      this.addType = 'add'
      this.task.projId = _record.id
      this.project = _record
      this.getProj()
      this.customerList = [{ id: this.project.customer, csrName: this.project.customerName }]
      this.listCustomerUser(this.project.customer, true)
    },
    edit (_record) {
      this.visible = true
      this.addType = 'edit'
      this.task.projId = _record.id
      this.project = _record
      this.customerList = [{ id: this.project.customer, csrName: this.project.customerName }]
      this.getProj()
      this.getTaskByProjId()
      this.listCustomerUser(this.project.customer)
    },
    handleOK () {
      this.submit()
    },
    submit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          const self = this
          self.task.quoteId = this.project.quoteId
          let saveApi = projTaskApi.add
          if (self.task.id) {
            saveApi = projTaskApi.edit
          }
          saveApi(Object.assign(self.task, values)).then(result => {
            if (result.code === 0) {
              this.$message.success(`操作成功`)
              this.handleCancel()
              this.$emit('refresh', { addType: this.addType })
              // if (this.addType === 'add') {
              //   this.$emit('projPlan', this.record)
              // } else {
              //   this.$emit('refresh', null)
              // }
            } else {
              this.$message.error(result.msg)
            }
          })
        }
      })
    },
    getProj () {
      projectApi.get({ id: this.project.id, itemType: '1' }).then(result => {
        if (result.code === 0) {
          if (result.data) {
            this.project = result.data
          }
        }
      })
    },
    getTaskByProjId () {
      projTaskApi.getTaskByProjId({ projId: this.task.projId }).then(result => {
        if (result.code === 0) {
          if (result.data) {
            this.task = result.data
          }
        }
      })
    },
    listCustomerUser (csrId, userChange) {
      Get(`${customerUserApi.list}`, { csrId: csrId, page: false, authEnable: false }).then(result => {
        if (result.code === 0) {
          this.customerUserList = result.data
          if (userChange && this.customerUserList && this.customerUserList.length > 0) {
            const csrUser = this.customerUserList[0]
            this.task.taskOnsiteContactId = csrUser.id
            this.task.taskOnsiteContact = csrUser.csrUserName
            this.task.taskOnsitePhone = csrUser.csrUserTelephone
          }
        }
      })
    },
    getItemLen (row) {
      var self = this
      var len = 0
      const siteList = self.project.siteList
      if (siteList) {
        for (var siteIdx in siteList) {
          const site = siteList[siteIdx]
          if (site.id === row.siteId) {
            const cateList = siteList[siteIdx].itemCategoryList
            if (cateList) {
              for (var cateIdx in cateList) {
                const cate = cateList[cateIdx]
                if (cate.id === row.cateId) {
                  len = cate.itemList.length
                  return len
                }
              }
            }
          }
        }
      }
      return len
    },
    getStandardByItemId (itemId) {
      const st = this.standard[itemId]
      if (st) {
        return st
      }
      standardItemApi.getStandardByItemId({ itemId: itemId }).then(result => {
        if (result.code === 0) {
          // this.standard[itemId] = result.data
          this.$set(this.standard, itemId, result.data)
        }
      })
    },
    itemSub (record, siteIdx, cateIdx, itemIdx) {
      this.$refs.itemSubList.add({ itemId: record.itemId, siteIdx: siteIdx, cateIdx: cateIdx, itemIdx: itemIdx })
    },
    itemSubOk (res) {
      var self = this
      var itemParam = {
        projId: self.project.id,
        quoteId: self.project.quoteId,
        sctrId: res.sctr.sctrId,
        sctrName: res.sctr.sctrName,
        subitemPrice: res.sctr.subitemPrice,
        subitemCostPrice: res.sctr.subitemCostPrice,
        subitemRealPrice: res.sctr.subitemPrice
      }
      if (res.itemIdx || res.itemIdx === 0) {
        var itemObj = self.project.siteList[res.siteIdx].itemCategoryList[res.cateIdx].itemList[res.itemIdx]
        itemParam.itemId = itemObj.itemId
      } else {
        var cateObj = self.project.siteList[res.siteIdx].itemCategoryList[res.cateIdx]
        itemParam.cateId = cateObj.id
      }
      self.updateItemSub(itemParam)
    },
    delItemSub (record) {
      var self = this
      self.updateItemSub({
        projId: self.project.id,
        quoteId: self.project.quoteId,
        itemId: record.itemId
      })
    },
    updateItemSub (itemParam) {
      var self = this
      projTaskItemApi.updateItemSub(itemParam).then(result => {
        if (result.code === 0) {
          self.getProj()
          self.$message.success(`分包设置成功`)
        } else {
          self.$message.error(result.msg)
        }
      }).catch(error => {
        self.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    selectStandard (siteIdx, cateIdx, itemIdx, record) {
      var self = this
      // this.$refs.standard.add({ siteIdx: siteIdx, cateIdx: cateIdx, itemIdx: itemIdx, itemId: itemId })
      var info = { siteIdx: siteIdx, cateIdx: cateIdx, itemIdx: itemIdx, projId: record.projId, taskItemId: record.id, itemId: record.itemId, cateId: record.cateId, standardList: record.standardList }
      self.$refs.executeStandardForm.view(info, record.itemName)
    },
    updateItemStandard (info) {
      var self = this
      var item = self.project.siteList[info.siteIdx].itemCategoryList[info.cateIdx].itemList[info.itemIdx]
      item.standardList = info.standardList
    },
    deleteStandard (record, standard, standardIdx) {
      var self = this
      // var standardList = record.standardList
      projTaskItemApi.delStandard({ id: standard.id }).then(result => {
        if (result.code === 0) {
          record.standardList.splice(standardIdx, 1)
          self.$message.success(`执行标准删除成功`)
        } else {
          self.$message.error(result.msg)
        }
      }).catch(error => {
        self.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    selectStandardOk (res) {
      var self = this
      const item = res.item
      const cate = self.project.siteList[item.siteIdx].itemCategoryList[item.cateIdx]
      var standardList = []
      if (item.itemId) {
        var itemStandardList = res.standardList
        for (var j in itemStandardList) {
          itemStandardList[j].projId = self.project.id
          itemStandardList[j].quoteId = self.project.quoteId
          itemStandardList[j].siteId = cate.siteId
          itemStandardList[j].cateId = cate.id
          itemStandardList[j].itemId = item.itemId
        }
        standardList = standardList.concat(itemStandardList)
        self.$set(self.project.siteList[item.siteIdx].itemCategoryList[item.cateIdx].itemList[item.itemIdx], 'standardList', itemStandardList)
      } else {
        const itemList = cate.itemList
        for (var idx in itemList) {
          var itemObj = itemList[idx]
          // eslint-disable-next-line no-redeclare
          var itemStandardList = JSON.parse(JSON.stringify(res.standardList))
          for (var j in itemStandardList) {
            itemStandardList[j].projId = self.project.id
            itemStandardList[j].quoteId = self.project.quoteId
            itemStandardList[j].siteId = itemObj.siteId
            itemStandardList[j].cateId = itemObj.cateId
            itemStandardList[j].itemId = itemObj.itemId
          }
          standardList = standardList.concat(itemStandardList)
          itemObj.standardList = itemStandardList
          self.$set(itemObj, 'standardList', itemStandardList)
        }
      }
      self.updateStandard({
        projId: self.project.id,
        quoteId: self.project.quoteId,
        siteId: cate.siteId,
        cateId: cate.id,
        itemId: item.itemId,
        standardListStr: JSON.stringify(standardList)
      })
    },
    editSite (siteIdx, site) {
      this.siteIdx = siteIdx
      this.$refs.siteForm.edit(site)
    },
    updateSiteInfo (site) {
      this.$set(this.project.siteList[this.siteIdx], 'siteName', site.siteName)
    },
    editRow (siteIdx, cateIdx, itemIdx) {
      // 打开编辑
      this.$set(this.project.siteList[siteIdx].itemCategoryList[cateIdx].itemList[itemIdx], 'editable', true)
    },
    saveRow (record, siteIdx, cateIdx, itemIdx) {
      // 判断点位是否已经保存   未保存时先保存点位
      var param = Object.assign({}, record, { sampleStandardObj: null, testStandardObj: null })
      projTaskItemApi.edit(param).then(result => {
        if (result.code === 0) {
          record.editable = false
          this.$set(this.project.siteList[siteIdx].itemCategoryList[cateIdx].itemList, itemIdx, record)
          this.$message.success(`执行标准设置成功`)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    updateStandard (params) {
      projTaskItemApi.updateStandard(params).then(result => {
        if (result.code === 0) {
          this.$message.success(`执行标准设置成功`)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    showAddCustomerUser () {
      this.$refs.customerUserModal.addByCsrId(this.project.customer)
    },
    customerUserOk (record) {
      this.customerUserList.push(record)
    },
    taskOnsiteContactChange (value) {
      const csrUser = this.customerUserList.filter(item => value === item.id)[0]
      if (csrUser) {
        this.task.taskOnsiteContact = csrUser.csrUserName
        this.task.taskOnsitePhone = csrUser.csrUserTelephone
      }
    },
    handleCancel () {
      this.visible = false
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  watch: {
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    }
  },
  filters: {
    siteNameFilter (id) {
      const site = vm.siteList.filter(it => id === it.id)[0]
      return site ? site.siteName : `检测位置信息有误!`
    },
    siteMapFilter (id) {
      const site = vm.siteList.filter(it => id === it.id)[0]
      if (site && site.siteLng && site.siteLat) {
        return 'twoTone'
      } else {
        return 'outlined'
      }
    },
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
    this.$store.dispatch('code/select', 'quote_type')
  }
}
</script>
<style>
  th.column-center,
  td.column-center {
    text-align: center !important;
    padding: 10px;
  }
</style>
<style lang="less" scoped>
  .card{
    margin: 10px 4px;
    /deep/ .ant-card-body {
      padding: 0px;
    }
  }

  .card-not-padding{
    margin: 10px 4px;
    /deep/ .ant-card-body {
      padding: 0px;
    }
    /deep/ .ant-card-head-title {
      padding: 10px 0;
    }
  }
</style>
