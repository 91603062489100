'use strict'

const defaultColumn = [
  { title: '任务编号', dataIndex: 'projNo', sorter: true, width: 100, scopedSlots: { customRender: 'projNo' } },
  { title: '任务名称', dataIndex: 'projName', width: 180, scopedSlots: { customRender: 'projName' } },
  { title: '受检单位', dataIndex: 'csrName', width: 180, sorter: true, scopedSlots: { customRender: 'csrName' } },
  { title: '项目地址', dataIndex: 'projAddress', width: 200, scopedSlots: { customRender: 'projAddress' } },
  { title: '项目负责人', dataIndex: 'projLeaderName', width: 100, sorter: true, scopedSlots: { customRender: 'projLeaderName' } },
  { title: '预计时间', dataIndex: 'projScheduledStart', width: 180, scopedSlots: { customRender: 'scheduledTime' } },
  { title: '已接样品', dataIndex: 'sampleNum', width: 100, sorter: true, scopedSlots: { customRender: 'sampleNum' } },
  { title: '接样人', dataIndex: 'sampleUserNames', width: 150, scopedSlots: { customRender: 'sampleUserNames' } },
  { title: '送样人', dataIndex: 'sampleGiverNames', width: 150, scopedSlots: { customRender: 'sampleGiverNames' } },
  { title: '联系电话', dataIndex: 'sampleGiverTels', width: 150, scopedSlots: { customRender: 'sampleGiverTels' } },
  { title: '操作', dataIndex: 'action', width: 100, scopedSlots: { customRender: 'action' } }
]

export {
  defaultColumn as receiveSampleProjectColumn
}
