<template>
  <div class="check-standard-solution-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-standard-solution-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>

    <s-table :rowSelection="selectType ? rowSelection : null" :scroll="scrollProp" v-bind="contentTable">
      <template #id="text, record">
        <a @click="detailModal.show(record)">{{ text }}</a>
      </template>

      <template #methodName="text, record">
        <a @click="$emit('handleMethodClick', record)">{{ text }}</a>
      </template>

      <template #solutionName="text, record">
        <template v-if="text"> {{ text }}<span v-if="record.solutionUseVolume">（用量：record.solutionUseVolume）</span> </template>
        <template v-else>-</template>
      </template>

      <template #solventName="text, record">
        <template v-if="text"> {{ text }}<span v-if="record.solventUseVolume">（用量：record.solventUseVolume）</span> </template>
        <template v-else>-</template>
      </template>

      <template #validTime="data">
        <a-tooltip>
          <template #title>{{ data }}</template>
          <span :class="data | validTimeClassFilter">
            {{ data | validTimeFilter }}
          </span>
        </a-tooltip>
      </template>

      <template #reviewStatus="text">
        <template v-if="checkStandardCurveReviewStatusDataMap[text]">
          <a-tag :color="checkStandardCurveReviewStatusDataMap[text].color">{{ checkStandardCurveReviewStatusDataMap[text].label }}</a-tag>
        </template>
        <template v-else>-</template>
      </template>

      <template #checkUserName="text">
        <template v-if="text">
          {{ text }}
        </template>
        <template v-else>-</template>
      </template>
      <template #checkStatus="text">
        <template v-if="checkStandardCurveCheckStatusDataMap[text]">
          <a-tag :color="checkStandardCurveCheckStatusDataMap[text].color">{{ checkStandardCurveCheckStatusDataMap[text].label }}</a-tag>
        </template>
        <template v-else>-</template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text, record, index">
        <slot :name="slot" v-bind="{ text: text, row: record, index: index }" />
      </template>
    </s-table>

    <!-- 曲线配置预览表单-->
    <a-modal v-bind="detailModal" v-if="detailModal.visible" v-on="detailModal">
      <preview-form-mix
        v-if="detailModal.data.curveRecord.mixSolutionList && detailModal.data.curveRecord.mixSolutionList.length"
        v-bind="detailModal.data"
        v-on="detailModal.data"
      ></preview-form-mix>
      <preview-form v-else v-bind="detailModal.data" v-on="detailModal.data"></preview-form>
      <template #footer>
        <a-button @click="detailModal.cancel()">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import sTable from '@/components/Table'
import defaultColumns from '@/pages/cloud-quote/check-standard-curve-type/modules/columns'
import mixinList from '@/pages/common/mixins/list'
import { checkStandardCurveConfigApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import PreviewForm from '@/pages/cloud-quote/check-standard-curve-type/modules/form/PreviewForm'
import PreviewFormMix from '@/pages/cloud-quote/check-standard-curve-type/modules/form/PreviewFormMix'
import { CustomizeColumn } from '@/components/Columns'

import {
  checkStandardCurveCheckStatusDataMap,
  checkStandardCurveCheckStatusEnum,
  checkStandardCurveReviewStatusDataMap,
  checkStandardCurveReviewStatusEnum
} from '@/api/checkStandardCurve/checkStandardCurveConstant'
import moment from 'moment'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal

export default {
  mixins: [mixinList],
  components: { sTable, CustomizeColumn, PreviewForm, PreviewFormMix },

  props: {
    selectType: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return [null, '', undefined, 'radio', 'checkbox'].includes(value)
      }
    },

    queryParam: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },

    modalType: {
      type: String,
      required: false,
      default: 'fullModal'
    },

    modalWidth: {
      type: [Number, String],
      required: false,
      default: 800
    },

    modalTitle: {
      type: String,
      required: false,
      default: '标线配置'
    },

    columns: {
      type: Array,
      required: false,
      default: () => defaultColumns
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    defaultOtherHeight: {
      type: Number,
      required: false,
      default: 360
    }
  },

  data() {
    return {
      checkStandardCurveReviewStatusEnum,
      checkStandardCurveReviewStatusDataMap,
      checkStandardCurveCheckStatusEnum,
      checkStandardCurveCheckStatusDataMap,

      detailModal: Object.assign({}, fullModalData, {
        title: '详细信息',
        width: '960px',
        visible: false,
        data: {
          ref: 'detail',
          id: null,
          // curveTypeId: null,
          curveRecord: null
        },
        show: (record) => {
          this.detailModal.data.id = record.id
          this.detailModal.data.curveRecord = record
          this.detailModal.visible = true
        },
        cancel: () => {
          this.detailModal.visible = false
        }
      }),

      contentTable: {
        rowKey: this.rowKey,
        columns: this.columns
      },

      rowSelection: {
        type: this.selectType
      }
    }
  },
  filters: {
    validTimeClassFilter(data) {
      return moment().isBefore(moment(data)) ? 'green' : 'red'
    },

    validTimeFilter(data) {
      return moment(data).fromNow()
    }
  },
  watch: {
    queryParam: {
      deep: true,
      immediate: false,
      handler(param) {
        this.loadData(param)
      }
    },

    'rowSelection.selectedRows': {
      handler(param) {
        this.$emit('selectedRows', param)
      }
    }
  },

  methods: {
    // 加载数据
    loadData(param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      return checkStandardCurveConfigApi.list(Object.assign({}, param, this.queryParam)).then((res) => res)
    }
  }
}
</script>

<style lang="less" scoped>
.check-standard-solution-table {
  position: relative;

  .red {
    color: red;
  }
  .green {
    color: green;
  }

  .setting {
    position: absolute;
    z-index: 9;
    right: 12px;
    top: 9px;
  }
}
</style>
