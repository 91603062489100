/**
 * 质量控制相关的API维护
 */
import { getAction, postDataAction } from '@/api/common'

const qualityControlPre = '/ms-api/quality-control'
const standardPre = '/ms-api/jc-standard'
const standardAttrPre = '/ms-api/attribute'
const projItemStandardPre = '/ms-api/proj_item_standard'

export const qualityControlApi = {
  list: (param) => getAction(`${qualityControlPre}/list`, param),
  findSetting: (param) => getAction(`${qualityControlPre}/list-setting`, param),
  makePlan: (data) => postDataAction(`${qualityControlPre}/make-plan`, data),
  saveSampleLink: (data) => postDataAction(`${qualityControlPre}/save-sample-link`, data),
  savePasswordSample: (data) => postDataAction(`${qualityControlPre}/save-password-sample`, data),
  backConfig: (data) => postDataAction(`${qualityControlPre}/back-config`, data)
}

export const projItemStandardApi = {
  save: (data) => postDataAction(`${projItemStandardPre}/save`, data),
  listProjectStandard: (param) => getAction(`${projItemStandardPre}/list`, param)
}

export const projectStandardApi = {
  listProjectStandard: (param) => getAction(`${standardPre}/list-quality`, param)
}

export const standardAttrApi = {
  listAttribute: (param) => getAction(`${standardAttrPre}/list-with-value`, param),
  listAttributeValue: (data) => postDataAction(`${standardAttrPre}/query-limit-value`, data)
}

export const standardAttrValueTypeOptions = [
  { label: '标准值', value: '1' },
  { label: '范围值', value: '2' },
  { label: '描述值', value: '3' }
]
