<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="850"
    :maskClosable="false"
    :destroyOnClose="true"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card title="收款计划">
      <a-table
        ref="table"
        size="small"
        :columns="table.columns"
        :dataSource="table.dataSource"
        :pagination="table.pagination"
        :rowKey="table.rowkey">
        >
        <template slot="xh" slot-scope="text, record, index">
          {{ index+1 }}
        </template>
        <template slot="recptPlanType" slot-scope="text">
          <div v-if="text=='1'">项目阶段</div>
          <div v-else-if="text=='2'">截止时间</div>
          <div v-else>{{ text }}</div>
        </template>
        <template slot="recptPlanDate" slot-scope="text, record">
          {{ showPlanDate(record) }}
        </template>
      </a-table>
    </a-card>
    <a-card title="新增收款">
      <a-form :form="form">
        <a-row class="form-row">
          <a-col :span="12">
            <a-form-item label="项目名称" v-bind="formItemLayout">
              {{ projInfo.projName }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="合同金额(元)" v-bind="formItemLayout">
              {{ projInfo.projContractAmount }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :span="12">
            <a-form-item label="已收金额(元)" v-bind="formItemLayout">
              {{ projInfo.recptTotalAmount }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="坏账金额(元)" v-bind="formItemLayout">
              {{ projInfo.recptBadAmount }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :span="12">
            <a-form-item label="收款类型" v-bind="formItemLayout">
              <a-radio-group name="radioGroup" v-decorator="[ 'recptType', {initialValue: recptInfo.recptType?recptInfo.recptType:'1', rules: [{ required: true, message: '请选择收款类型！'}]}]">
                <a-radio :value="'1'">收款</a-radio>
                <a-radio :value="'2'">坏账</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="收款日期" v-bind="formItemLayout">
              <a-date-picker @change="(date, dateStr)=>{recptInfo.recptDate=dateStr}" v-decorator="[ 'recptDateM', {initialValue: str2Moment(recptInfo.recptDate), rules: [{ required: true, message: '请选择收款日期'}]}]"></a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :span="12">
            <a-form-item label="收款金额" v-bind="formItemLayout">
              <a-input-number
                :precision="2"
                :min="1"
                :max="9999999999"
                :formatter="value => `￥${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')"
                style="width: 100%"
                placeholder="请输入金额"
                title="请输入金额不能为空"
                v-decorator="[ 'recptAmount', {initialValue: recptInfo.recptAmount, rules: [{ required: true, message: '请选择输入收款金额！'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否开票" v-bind="formItemLayout">
              <a-radio-group name="radioGroup" v-decorator="[ 'recptInvoice', {initialValue: recptInfo.recptInvoice?recptInfo.recptInvoice:'0', rules: [{ required: true, message: '请选择是否开票！'}]}]">
                <a-radio :value="'0'">不开发票</a-radio>
                <a-radio :value="'1'">申请开票</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-item>
              <a-textarea
                placeholder="收款备注"
                :autosize="{ minRows: 4, maxRows: 8 }"
                v-decorator="['recptRemark', {initialValue: recptInfo.recptRemark,
                                                      rules: [
                                                        {max: 255, message: '备注长度不得超过255！'}
                                                      ]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </a-modal>
</template>

<script>
import { commDictApi } from '@/api/common'
import { projectApi, projReceiptApi, projReceiptPlanApi } from '@/api/project'
import moment from 'moment'
import ACol from 'ant-design-vue/es/grid/Col'
import ARow from 'ant-design-vue/es/grid/Row'
var vm = {}
export default {
  name: 'ReceiptForm',
  components: {
    ARow,
    ACol
  },
  props: {
  },
  data () {
    vm = this
    return {
      title: '申请收款',
      visible: false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      saveApi: projReceiptApi.add,
      projInfo: {},
      recptInfo: {},
      table: {
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '序号', dataIndex: 'id', width: '30px', scopedSlots: { customRender: 'xh' } },
          { title: '计划类型', dataIndex: 'recptPlanType', scopedSlots: { customRender: 'recptPlanType' } },
          { title: '计划收款日期', dataIndex: 'recptPlanDate', width: '130px', scopedSlots: { customRender: 'recptPlanDate' } },
          { title: '计划收款金额', dataIndex: 'recptPlanAmount', scopedSlots: { customRender: 'recptPlanAmount' } },
          { title: '备注', dataIndex: 'recptPlanRemark', scopedSlots: { customRender: 'recptPlanRemark' } }
        ],
        pagination: false
      },
      form: this.$form.createForm(this)
    }
  },
  methods: {
    moment,
    add (proj) {
      this.visible = true
      this.projInfo = proj
      this.recptInfo.projId = proj.id
      this.recptInfo.orgId = proj.orgId
      this.recptInfo.projProgress = proj.projProgress
      this.recptInfo.recptDate = moment().format('YYYY-MM-DD')
      this.saveApi = projReceiptApi.add
      this.listPlanByProjId()
    },
    edit (receipt) {
      this.visible = true
      this.recptInfo = receipt
      this.saveApi = projReceiptApi.edit
      this.listPlanByProjId()
      this.getProject()
    },
    handleOk (e) {
      e.preventDefault()
      const self = this
      this.form.validateFields((error, values) => {
        const formData = Object.assign(this.recptInfo, values)
        if (error == null) {
          this.saveApi(formData).then(result => {
            if (result.code === 0) {
              this.$message.success(`${self.title}添加成功`)
              this.handleCancel()
              this.$emit('ok', null)
            } else {
              this.$message.error(result.msg)
            }
          }).catch(error => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    getProject () {
      projectApi.get({ id: this.recptInfo.projId }).then(result => {
        if (result.code === 0) {
          this.projInfo = result.data
        }
      })
    },
    listPlanByProjId () {
      projReceiptPlanApi.listPlanByProjId({ projId: this.recptInfo.projId }).then(result => {
        if (result.code === 0) {
          this.table.dataSource = result.data
        }
      })
    },
    str2Moment (timeStr) {
      if (timeStr) {
        if (typeof timeStr === 'string') {
          return moment(timeStr, 'YYYY-MM-DD')
        } else if (typeof timeStr === 'object') {
          var res = []
          var isNull = true
          for (var idx in timeStr) {
            if (timeStr[idx]) {
              res.push(moment(timeStr[idx], 'YYYY-MM-DD'))
              isNull = false
            } else {
              res.push(null)
            }
          }
          return !isNull ? res : null
        }
      }
      return null
    },
    showPlanDate (record) {
      if (record.recptPlanType === '1') {
        return projectApi.getProgressName(record.projProgress)
      }
      return record.recptPlanDate
    }
  },
  mounted () {
    projectApi.loadProgressList()
  },
  filters: {
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
