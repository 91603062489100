<template>
  <div class="make-sample-storage">
    <a-table :rowSelection="rowSelection" :scroll="scrollProp" v-bind="contentTable">

      <template #projName="data,record">
        <span>【{{ record.projNo }}】{{ record.projName }}</span>
      </template>

      <template #type="type">
        <a-tag :color="sampleInfoTypeDataMap[type].color">{{ sampleInfoTypeDataMap[type].label }}</a-tag>
      </template>

      <template #projName="data,record">
        【{{ record.projNo }}】{{ data }}
      </template>

      <template #makeTime="data,record">
        <template v-if="record.makeBeginTime">
          <span style="color: green">起：</span>{{ record.makeBeginTime | minuteFilter }}<br/>
          <span style="color: red">止：</span>{{ record.makeEndTime | minuteFilter }}
        </template>
      </template>

      <template #itemNameStr="data,record">
        {{ record.cateNameStr }}/{{ data }}
      </template>

      <template #volume="data,record">
        <span v-if="data">
          <span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.volumeUnit }}
        </span>
        <span v-else>-</span>
      </template>

      <template #validity="data,record">
        <span v-if="data"><span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.validityUnit }}</span>
        <span v-else>-</span>
      </template>

      <template #status="status">
        <a-tag :color="makeSampleInfoStatusDataMap[status].color">{{ makeSampleInfoStatusDataMap[status].label }}</a-tag>
      </template>

    </a-table>
  </div>
</template>

<script>
import { makeSampleApi, sampleTypeSetting } from '@/api/makeSample/makeSamplelApi'
import { makeSampleInfoStatusEnum, makeSampleInfoStatusDataMap } from '@/api/makeSample/makeSampleConstant'
import { sampleInfoTypeDataMap } from '@/api/sampleInfo/sampleInfoConstant'

import STable from '@/components/Table'
import list from '@/pages/common/mixins/list'
export default {
  components: { STable },

  mixins: [list],

  props: {
    projId: {
      type: Number,
      required: false
    },

    statuses: {
      type: Array,
      required: false,
      default: () => { return [makeSampleInfoStatusEnum.outbound] }
    },

    types: {
      type: Array,
      required: false,
      default: () => { return sampleTypeSetting.sampleOutBound }
    }
  },

  data () {
    return {
      sampleTypeSetting,
      makeSampleInfoStatusEnum,
      sampleInfoTypeDataMap,
      makeSampleInfoStatusDataMap,

      contentTable: {
        rowKey: 'idTemp',
        bordered: true,
        pagination: false,

        dataSource: [],

        columns: [
          { title: '样品编号', align: 'center', dataIndex: 'id', width: 160 },
          { title: '样品类型', align: 'center', dataIndex: 'type', width: 100, scopedSlots: { customRender: 'type' } },
          { title: '项目名称', align: 'center', dataIndex: 'projName', scopedSlots: { customRender: 'projName' } },
          { title: '检测项', align: 'center', dataIndex: 'itemNameStr', width: 200, scopedSlots: { customRender: 'itemNameStr' } },
          { title: '制样人', align: 'center', dataIndex: 'makeUserName', width: 100 },
          { title: '制样时间', align: 'center', dataIndex: 'makeTime', width: 180, scopedSlots: { customRender: 'makeTime' } },
          { title: '存储介质', align: 'center', dataIndex: 'storage', width: 120 },
          { title: '份数', align: 'center', dataIndex: 'num', width: 80 },
          { title: '体积/重量', align: 'center', dataIndex: 'volume', width: 90, scopedSlots: { customRender: 'volume' } },
          { title: '有效期', align: 'center', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
          { title: '当前状态', align: 'center', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
        ]
      }
    }
  },

  computed: {
    queryParam () {
      const { projId, statuses, types } = this
      return { projId, statuses, types }
    }
  },

  watch: {
    queryParam: {
      immediate: true,
      handler (val) {
        this.loadData(val)
      }
    }
  },

  methods: {
    loadData (param) {
      return makeSampleApi.list(Object.assign({ page: false }, param)).then(res => {
        if (res.code === 0) {
          this.contentTable.dataSource.splice(0, this.contentTable.dataSource.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    save () {
      const saveData = this.rowSelection.selectedRows
      if (!saveData.length) {
        this.$message.error('请先选择需要入库的样品！')
        return false
      }

      makeSampleApi.storage(saveData).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "_idnex";
</style>
