<template>
  <div class="check-reagent-cate-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-reagent-cate-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>

    <s-table v-bind="contentTable" :rowSelection="customRowSelection" :scroll="scrollProp" v-on="contentTable.action">
      <template #expandedRowRender="record, index, indent, expanded">
        <check-reagent-cate-material-table v-if="expanded" :cateId="record.id" :key="record.id+loadKey" width="80%"></check-reagent-cate-material-table>
      </template>
      <template #grade="data">
        <a-tag :color="reagentCateGradeDataMap[data].color">{{ reagentCateGradeDataMap[data].label }}</a-tag>
      </template>

      <template #needMake="data">
        <a-tag :color="reagentCateMakeTypeDataMap[data].color">{{ reagentCateMakeTypeDataMap[data].label }}</a-tag>
      </template>

      <template #solution="data">
        <a-tag :color="reagentCateSolutionTypeDataMap[data].color">{{ reagentCateSolutionTypeDataMap[data].label }}</a-tag>
      </template>

      <template #solutionType="data">
        <template v-if="checkSolutionTypeDataMap[data]">
          <a-tag :color="checkSolutionTypeDataMap[data].color">{{ checkSolutionTypeDataMap[data].label }}</a-tag>
        </template>
      </template>

      <template #speType="item,data">
        {{ item ? item : '-' }} {{ data.unit }}
      </template>

      <template #validDate="data,record">
        {{ data }}{{ reagentCateValidDateUnitDataMap[record.validDateUnit].label }}
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot :name="slot" v-bind="{text:text,row:record,index:index,expanded}"/>
      </template>

    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { checkSolutionTypeDataMap } from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import { reagentCateGradeDataMap, reagentCateMakeTypeDataMap, reagentCateSolutionTypeDataMap, reagentCateValidDateUnitDataMap } from '@/api/checkReagent/checkReagentConstant'
import { CheckReagentCateDefaultColumn as defaultColumn } from '@/pages/cloud-quote/check-reagent/cate/modules/table/colunm'
import { checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'

import CheckReagentCateMaterialTable from '@/pages/cloud-quote/check-reagent/cate/modules/material/table'
import { CustomizeColumn } from '@/components/Columns'

export default {
  components: { sTable, CustomizeColumn, CheckReagentCateMaterialTable },
  mixins: [componentTable],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      reagentCateGradeDataMap,
      reagentCateMakeTypeDataMap,
      reagentCateSolutionTypeDataMap,
      reagentCateValidDateUnitDataMap,
      checkSolutionTypeDataMap
    }
  },

  computed: {
    queryParam () {
      return Object.assign({}, this.$attrs)
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  watch: {
    'rowSelection.selectedRows' (newValue) {
      this.$emit('selectedRows', newValue)
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
        return checkReagentCateApi.list(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }

  }

}
</script>

<style lang="less" scoped>
.check-reagent-cate-table{
 position: relative;

  .setting {
    position: absolute;
    z-index: 9;
    right: 12px;
    top: 9px;
  }
}
</style>
