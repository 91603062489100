import { render, staticRenderFns } from "./MinimizableModal.vue?vue&type=template&id=0885846a&scoped=true&"
import script from "./MinimizableModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./MinimizableModal.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./MinimizableModal.vue?vue&type=style&index=0&id=0885846a&prod&scoped=true&lang=less&"
import style1 from "./MinimizableModal.vue?vue&type=style&index=1&id=0885846a&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0885846a",
  null
  
)

export default component.exports