<template>
  <div>

    <!--  查询区域 -->
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">

        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="方法名称">
              <a-input v-model.trim="queryParam.methodName" placeholder="请输入方法名称" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="标准编号">
              <a-input v-model.trim="queryParam.standardCode" placeholder="请输入标准编号" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="检测项名称">
              <a-input v-model.trim="queryParam.itemNames" placeholder="请输入检测项名称" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col :style="{marginBottom:'15px'}" v-bind="colProps">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
          </a-col>

        </a-row>
      </a-form>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
<!--      <method-curve-table v-bind="methodTableProps" />-->
      <s-table v-bind="contentTable" :scroll="scrollProp" :rowSelection="customRowSelection">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index">
          <slot v-bind="{text:text,row:record,index:index}" :name="slot"/>
        </template>
      </s-table>
    </a-card>
  </div>
</template>

<script>
import sTable from '@/components/Table'
// import MethodCurveTable from '@/pages/cloud-quote/check-standard-curve-type/modules/MethodCurveTable'
import mixinList from '@/pages/common/mixins/list'
import { checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'

// const defaultColumn = [
//   { title: '标准', dataIndex: 'standardName', width: 200, scopedSlots: { customRender: 'standardName' } },
//   { title: '标准编号', dataIndex: 'standardCode', width: 150, scopedSlots: { customRender: 'standardCode' } },
//   { title: '方法名称', dataIndex: 'methodName', width: 150, scopedSlots: { customRender: 'methodName' } },
//   { title: '因子', dataIndex: 'itemNames', width: 150, scopedSlots: { customRender: 'itemNames' } }
// ]
export default {
  name: 'index',
  mixins: [mixinList],
  components: {
    sTable //, MethodCurveTable
  },
  props: {
    // width: { type: [String, Number], required: false, default: '100%' },
    // isSetData: { type: Boolean, required: false, default: false },
    // dataSource: { type: Array, required: false, default: () => { return [] } },
    // pagination: { type: Boolean, required: false, default: true },
    // columns: { type: Array, required: false, default: () => (defaultColumn) },
    // methodName: String,
    // standardCode: String,
    // configCode: Number,
    // itemNames: String,
    // rowKey: { type: String, default: 'id' }
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },
  data () {
    return {
      queryParam: {
        methodName: null,
        standardCode: null,
        itemNames: null
      },
      customTable: {
        ref: 'table',
        rowKey: 'standardMethodId'
      },
      scrollProp: { x: '100%', y: document.documentElement.clientHeight - 445 },
      contentTable: {
        rowKey: 'standardMethodId',
        columns: [
          { title: '标准', dataIndex: 'standardName', width: 200, scopedSlots: { customRender: 'standardName' } },
          { title: '标准编号', dataIndex: 'standardCode', width: 150, scopedSlots: { customRender: 'standardCode' } },
          { title: '方法名称', dataIndex: 'methodName', width: 150, scopedSlots: { customRender: 'methodName' } },
          { title: '因子', dataIndex: 'itemNames', width: 150, scopedSlots: { customRender: 'itemNames' } }
        ],
        bordered: true,
        style: { width: '100%' },
        // showPagination: this.pagination ? 'auto' : false
      },
      selectedRows: []
    }
  },
  watch: {
    standardCode: {
      immediate: true,
      handler (value) {
        this.queryParam.standardCode = value
        value && this.$nextTick(() => {
          this.search()
        })
      }
    },

    methodName: {
      immediate: true,
      handler (value) {
        this.queryParam.methodName = value
        value && this.$nextTick(() => {
          this.search()
        })
      }
    },
    itemNames: {
      immediate: true,
      handler (value) {
        this.queryParam.itemNames = value
        value && this.$nextTick(() => {
          this.search()
        })
      }
    },
    'rowSelection.selectedRows' (newValue) {
      // this.$emit('selectedRows', newValue)
      this.selectedRows = newValue
    }
  },
  computed: {
    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  //   methodTableProps: {
  //     get () {
  //       return { ...this.customTable, ...this.queryParam }
  //     }
  //   }
  },
  methods: {
    loadData (param) {
      // 此处为了获取没有关联过曲线类型的方法，新增了个接口
      return checkStandardCurveTypeApi.notCurveTypeListStandardMethod(Object.assign({}, param, this.queryParam)).then((res) => res)
      // return checkStandardCurveTypeApi.listStandardMethod(Object.assign({}, param, this.queryParam)).then(res => res)
    },
    // 获取选中的数据
    getSelectData () {
      return [...this.selectedRows]
    }
    // 查询
    // search () {
    //   this.$refs[this.customTable.ref].search()
    // },

    // 重置
    // reset () {
    //   // this.resetQueryParam()
    //   const defaultData = this.$options.data.bind(this)()
    //   Object.assign(this.queryParam, defaultData.queryParam)
    //   this.$nextTick(() => {
    //     this.search()
    //   })
    // },

    // resetQueryParam () {
    //   const defaultData = this.$options.data.bind(this)()
    //   Object.assign(this.queryParam, defaultData.queryParam)
    // },

    // 重载
    // reload () {
    //   this.$refs[this.customTable.ref].reload()
    // },
  }
}
</script>

<style scoped>

</style>
