import { sysModelFieldApi } from '@/api/quote'
import { checkFieldApi } from '@/api/system'
import { message } from 'ant-design-vue'
import { groupBy, orderBy } from 'lodash'
import { sysCheckModelApi } from '@/api/checkModel'
import { useRouter } from 'vue-router/composables'

/** 字段驼峰缓存对象 */
const camelCaseFieldMap = {}

/**
 * 查询检测模型组信息
 * @param modelGroupId 检测模型组ID
 * @return {Promise<Object>} 查询结果
 */
export const getCheckModelGroupInfo = async (modelGroupId) => {
  return await sysCheckModelApi.findById(modelGroupId).then((res) => {
    if (res.code === 0) {
      return res.data
    } else {
      message.error(` :${res.msg}`)
    }
  })
}

/**
 * 查询模型信息
 * @param modelId 模型ID
 * @return {Promise} 查询结果
 */
export const getCheckModelInfo = async (modelId) => {
  return await Promise.all([sysModelFieldApi.getByModelId(modelId), checkFieldApi.list({ modelId, page: false })]).then(([res, checkRes]) => {
    if (res.code === 0) {
      const resData = orderBy(res.data || [], ['sort'])
      const checkData = checkRes.data
      const checkGroupData = groupBy(checkData, 'fieldId')
      resData.forEach((item) => {
        checkGroupData[item.fieldId] && Object.assign(item, ...checkGroupData[item.fieldId])
      })
      return resData || []
    } else {
      message.error(`获取模型信息出错 :${res.msg}`)
    }
  })
}

/**
 * 根据字段获取对应的驼峰形式
 * @param field 原始字段形式
 * @return {String} 驼峰
 */
export const getCamelCaseField = (field) => camelCaseFieldMap[field] || (camelCaseFieldMap[field] = field.replace(/_(\w)/g, (all, letter) => letter.toUpperCase()))

/**
 * 修改表达式的值
 * @param key 表达式的KEY
 * @param value 表达式的值
 * @param expressions {array} 表示式集合
 */
export const changeExpression = (key, value, expressions) => {
  if (key) {
    const existIdx = expressions.findIndex((item) => item.key === key)
    if (existIdx > -1) {
      expressions[existIdx].value = value
    } else {
      expressions.push({ key, value })
    }
  }
}

/**
 * 跳转到检测明细
 * @return {Promise} 跳转结果
 */
export const toItemDetail = () => {
  const router = useRouter()
  return async (id) => {
    await router.open({ path: '/cloud-quote/item-check-detail', query: { id: id + '' } })
  }
}

/**
 * 根据模型信息填充数据
 * @param dataInfos {array} 数据列表
 * @param modelInfos {array} 模型信息
 * @return {array} 填充后的数据
 */
export const fillDataByModelDefaultValue = (dataInfos, modelInfos) => {
  return dataInfos.reduce((acc, item) => {
    const fillData = modelInfos.reduce((subAcc, subItem) => {
      const { dvalue: defValue, field } = subItem
      const camelCaseField = getCamelCaseField(field)
      // 设置默认值
      if (defValue && !item[camelCaseField]) {
        subAcc[camelCaseField] = defValue
      }
      return subAcc
    }, item)
    acc.push(fillData)
    return acc
  }, [])
}
