<template>
  <a-modal v-bind="siteModal" v-on="siteModal" class="EditPointPosition">
    <el-table
      ref="usePointPositionTale"
      :data="siteList"
      v-bind="tableContent"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="100"> </el-table-column>
      <el-table-column label="分类" prop="cateName" min-width="200" />
      <el-table-column label="点位" prop="siteName" min-width="200" />
    </el-table>
  </a-modal>
</template>
<script>
import { standardUse } from '@/api/projectStandard/projectStandardApi'

var vm = {}
export default {
  name: 'UsePointPosition',

  data() {
    vm = this
    return {
      // type: 'add',
      title: '应用点位',
      siteModal: {
        title: '应用点位信息',
        visible: false,
        // maskClosable: false,
        destroyOnClose: true,
        centered: true,
        cancel: this.handleCancel,
        ok: this.handleOK,
        width: '1000px',
      },
      visible: false,

      // 可被应用的点位
      siteList: [],
      projId: '',
      cateId: '',
      // 现有点位id
      siteId: '',

      // 要被应用的点位ids
      siteIdCopy: [],

      tableContent: {
        border: true,
        size: 'mini',
        maxHeight: 400,
        stripe: true,
        rowKey: (data) => {
          return data.id
        },
        bordered: true,
      },
    }
  },
  methods: {
    getCate(siteObj, siteList) {
      this.projId = siteObj.projId
      this.cateId = siteObj.cateId
      this.siteId = siteObj.siteId
      this.siteList = siteList
      this.siteModal.visible = true
    },

    handleSelectionChange(val) {
      this.siteIdCopy = []
      val.forEach((item) => {
        this.siteIdCopy.push(item.siteId)
      })
    },

    handleOK(e) {
      if (this.siteIdCopy.length==0) {
        this.$message.error(`未选择点位，请先选择点位`)
        return false
      }
      this.$confirm({
        title: '确认提交吗？',
        onOk: () => {
          standardUse
            .Post({
              projId: this.projId,
              cateId: this.cateId,
              siteId: this.siteId,
              siteIdCopy: this.siteIdCopy,
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(`应用点位成功`)
                this.siteIdCopy = []
                this.siteModal.visible = false
                this.$emit('usePointPosition')
              } else {
                this.$message.error(`应用点位出错：${res.msg}`)
              }
            })
        },
        onCancel() {},
      })
    },
    handleCancel() {
      this.siteIdCopy = []
      this.siteModal.visible = false
    },
  },
  watch: {},
}
</script>
