<template>
  <div class="check-original-record">
    <div class="form-div">
      <iframe :src="formSrc" v-bind="form"></iframe>
    </div>
    <!-- 指派任务 -->
    <assign v-if="assignShow" :id="reviewId" @cancel="()=>{this.assignShow=false;this.$emit('success')}" @success="(val)=>{this.review(val);this.assignShow=false;}" ref="assign"/>

    <!-- 复核任务 -->
    <review
      v-if="reviewShow"
      ref="review"
      :batch-id="batchId"
      :authMark="true"
      :checkUser="checkUser"
      :id="reviewId"
      @cancel="()=>{this.reviewShow=false}"
      @success="handleReviewSuccess"/>

    <!-- 标液配置 -->
    <check-standard-solution-table-modal v-bind="solutionTableModal" v-on="solutionTableModal"/>

    <!-- 标线配置 -->
    <check-standard-curve-table-modal v-bind="curveTableModal" v-on="curveTableModal"/>
  </div>
</template>

<script>
import { uniq } from 'lodash'
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { checkOriginalRecordApi } from '@/api/check/checkOriginalRecordApi'
import { checkTaskBatchApi } from '@/api/check/checkTaskApi'

import Assign from '@/pages/cloud-quote/check-task/modules/originalRecordReview/Assign'
import Review from '@/pages/cloud-quote/check-task/modules/originalRecordReview/Review'
import { CheckStandardSolutionTableModal } from '@/pages/cloud-quote/check-standard-solution/modules'
import { CheckStandardCurveTableModal } from '@/pages/cloud-quote/check-standard-curve/modules'
import { checkOriginalRecordReviewStatusEnum } from '@/api/check/checkOriginalRecordConstant'

export default {
  name: 'OriginalRecord',

  components: { Assign, Review, CheckStandardSolutionTableModal, CheckStandardCurveTableModal },

  props: ['batchId'],
  data () {
    return {
      loading: true,
      assignShow: false,
      reviewShow: false,
      reviewId: null,
      checkUser: null,

      baseForm: {
        id: null,
        batchId: this.batchId,
        fileTemplateId: null,
        fileTemplateName: null,
        methodId: null,
        jcListId: null
      },

      batchInfo: {
        cateName: null,
        itemName: null,
        standardCode: null,
        standardName: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      solutionTableModal: {
        ref: 'solutionTableModal',

        success: (records) => {
          this.checkSolutionId = records.length && records[0].id
        },

        show: () => {
          this.$refs[this.solutionTableModal.ref].show()
        }

      },

      curveTableModal: {
        ref: 'curveTableModal',

        success: (records) => {
          this.checkCurveId = records.length && records[0].id
          this.checkSolutionId = records.length && records[0].solutionId
        },

        show: () => {
          this.$refs[this.curveTableModal.ref].show()
        }

      }
    }
  },

  computed: {
    formSrc () {
      if (this.baseForm.id) {
        return `${fileTemplateUreportApi.form}?_u=${this.baseForm.fileTemplateName}&batchId=${this.batchId}&id=${this.baseForm.id}&templateId=${this.baseForm.fileTemplateId}&edit=true`
      } else if (this.baseForm.fileTemplateId) {
        return `${fileTemplateUreportApi.form}?_u=${this.baseForm.fileTemplateName}&batchId=${this.batchId}&templateId=${this.baseForm.fileTemplateId}&edit=true`
      } else {
        return null
      }
    },

    sampleIds () {
      return uniq(this.checkTaskList.map(item => item.sampleId))
    }
  },

  watch: {
    batchId: {
      immediate: true,
      handler (newValue) {
        newValue && this.getBatchInfo(newValue)
      }
    }
  },

  methods: {

    // 获取批次信息

    getBatchInfo (batchId) {
      checkTaskBatchApi.getDetailInfoById(batchId).then(res => {
        if (res.code === 0) {
          if (res.data) {
            const { recordId: id, templateId: fileTemplateId, templateFile: fileTemplateName, methodId, checkDetailId: jcListId, itemName, cateName, standardCode, standardName } = res.data
            if (!fileTemplateName) {
              this.$message.error('暂未配置检测原始记录表！')
              this.$emit('cancel')
              return false
            }
            Object.assign(this.baseForm, { id, fileTemplateId, fileTemplateName, methodId, jcListId })
            Object.assign(this.batchInfo, { itemName, cateName, standardCode, standardName })
          } else {
            this.$message.error(`获取批次信息出错！`)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 获取原始记录表数据
    getData () {
      const contentWindow = this.$refs[this.form.ref]?.contentWindow
      // 当原始记录表无法加载的时候，会导致直接调用 iframe 中方法异常报错
      if (contentWindow.hasOwnProperty('getData')) {
        return contentWindow.getData()
      } else {
        this.$message.error('请配置对应检测方法的原始记录表！')
        return null
      }
    },

    // 显示复核弹窗
    showReviewModal (id) {
      this.$confirm({
        title: '提示',
        content: '是否立即开始复核？',
        onOk: () => {
          this.assign(id)
        },
        onCancel: () => {
          this.$emit('success')
        }

      })
    },

    // 指派
    assign (id) {
      this.assignId = id
      this.assignShow = true
      this.$nextTick(() => {
        this.$refs.assign.show()
      })
    },

    // 复核
    review (checkUser) {
      this.checkUser = checkUser
      this.reviewShow = true
      this.$nextTick(() => {
        this.$refs.review.show()
      })
    },

    handleReviewSuccess () {
      this.reviewShow = false
      this.$emit('success')
    },

    refresh () {
      this.$refs[this.form.ref].contentWindow.location.reload()
    },

    // 保存原始记录表
    save () {
      const data = this.getData()
      if (data) {
        const result = Object.assign({}, this.baseForm, { content: JSON.stringify(data) })
        checkOriginalRecordApi.save(result).then(res => {
          if (res.code === 0) {
            if (res.data.res) {
              this.$message.success(res.msg)
              if (res.data.canReview) {
                this.reviewId = res.data.id
                this.showReviewModal(this.reviewId)
              } else {
                this.$emit('success')
              }
            } else {
              this.$message.error('保存失败！')
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.error('暂无数据需要提交')
      }
    },
    done () {
      const data = this.getData()
      if (data) {
        const result = Object.assign({ reviewStatus: checkOriginalRecordReviewStatusEnum.toReview }, this.baseForm, { content: JSON.stringify(data) })
        checkOriginalRecordApi.save(result).then(res => {
          if (res.code === 0) {
            if (res.data.res) {
              this.$message.success(res.msg)
              if (res.data.canReview) {
                this.reviewId = res.data.id
                this.showReviewModal(this.reviewId)
              } else {
                this.$emit('success')
              }
            } else {
              this.$message.error('保存失败！')
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.error('暂无数据需要提交')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
