<template>
  <a-modal v-bind="fullModal">
    <a-form :form="form">
      <a-card v-bind="baseFormCard">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="领用编号" v-bind="formItemLayout">
              <a-input v-decorator="['purUseNbr', {}]" :disabled="true" placeholder="自动生成，无需输入" />
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="领用申请名称" v-bind="formItemLayout">
              <a-input v-decorator="['purUseName', {}]" />
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="关联项目" v-bind="formItemLayout">
              <a-select
                v-decorator="['projId', {}]"
                :filterOption="filterOption"
                mode="multiple"
                optionFilterProp="children"
                placeholder="选择关联项目"
                style="width: 100%"
                @change="handleChangeProjId"
              >
                <a-select-option v-for="pr in this.projList" :key="pr.id">{{ pr.projNo + '_' + pr.projName }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="领用类型" v-bind="formItemLayout">
              <a-radio-group v-model="purFlag" :disabled="purFlagDisabled" @change="handleChangeRadio">
                <a-radio :value="'0'"> 普通领用 </a-radio>
                <template v-if="purFlag == '1' || isAppend == true">
                  <a-radio :value="'1'"> 采样领用 </a-radio>
                </template>
                <a-radio :value="'2'"> 实验室物品领用 </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card v-bind="baseFormCard">
        <a-button style="margin-bottom: 1%" type="primary" @click="handleAdd">新增物资</a-button>
        <a-form-item>
          <a-table key="id" ref="table" :columns="columns" :dataSource="data" :pagination="false">
            <template v-for="(col, i) in ['cateName', 'materialName', 'speType', 'unit', 'purity', 'materialNum', 'numOfUse', 'ids']" :slot="col" slot-scope="text, record">
              <template v-if="col === 'cateId'">
                {{ getName(text, record) }}
              </template>
              <template v-else-if="col === 'ids'">
                <div
                  :key="i"
                  :style="{ maxWidth: '600px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', wordWrap: 'break-word', wordBreak: 'break-all' }"
                  :title="getCount(text)"
                >
                  <template v-if="record.outStatus === 0">
                    <template v-if="text == ''">
                      {{ '暂无数据' }}
                    </template>
                    <template v-else>
                      <a-tag
                        v-for="tag in text.split(',')"
                        :key="tag"
                        :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
                        @close="(e) => closeTag(e, record, tag)"
                      >
                        {{ tag }}
                      </a-tag>
                      <br />
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="text == ''">
                      {{ '暂无数据' }}
                    </template>
                    <template v-else>
                      <a-tag
                        v-for="tag in text.split(',')"
                        :key="tag"
                        :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
                        @close="(e) => closeTag(e, record, tag)"
                      >
                        {{ tag }}
                      </a-tag>
                      <br />
                    </template>
                  </template>
                </div>
              </template>
              <template v-else-if="col === 'numOfUse'">
                <a-input-number
                  :key="col"
                  :defaultValue="1"
                  :min="1"
                  :value="text"
                  style="margin: -5px 0"
                  @change="(e) => handleChange(e, record.id, record.key, col)"
                ></a-input-number>
              </template>
              <template v-else-if="col === 'unit'">
                {{ materialUnitCode[text] }}
              </template>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <template slot="operation" slot-scope="text, record">
              <template v-if="record.outStatus == 1">
                <span> 已出库 </span>
              </template>
              <template v-else-if="record.outStatus == 2">
                <span> 已复用 </span>
              </template>
              <template v-else>
                <span>
                  <a-popconfirm title="是否要作废此行?" @confirm="remove(record)">
                    <a>移除</a>
                  </a-popconfirm>
                </span>
                <template v-if="record.cateType == 3">
                  <a-divider type="vertical" />
                  <span>
                    <a @click="handleDev(record, record.cateId)">指定设备</a>
                  </span>
                </template>
              </template>
            </template>
          </a-table>
        </a-form-item>
      </a-card>

      <a-card v-bind="baseFormCard">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="领用人" v-bind="formItemLayout">
              <select-user-by-dept ref="devUser" :disabled="false" :triggerChange="true" @change="handleUserChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="领用申请日期" v-bind="formItemLayout">
              <a-date-picker v-decorator="decorators.dateOfPurUse" format="YYYY-MM-DD HH:mm:ss" style="width: 100%" @change="handlePurUseDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="领用原因" v-bind="formItemLayout">
              <a-textarea v-decorator="decorators.purUseReason" :autosize="true" placeholder="请在这里输入领用原因" />
            </a-form-item>
          </a-col>
          <a-col v-bind="colProps">
            <a-form-item label="备注" v-bind="formItemLayout">
              <a-textarea v-decorator="decorators.purUseDesc" :autosize="true" placeholder="请在这里输入备注" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form>

    <template #footer>
      <a-spin :spinning="spinLoading">
        <a-button :style="{ marginRight: '8px' }" @click="handleCancel">关闭</a-button>
        <a-button v-if="purFlag == '2'" :style="{ marginRight: '8px' }" type="primary" @click="handleStartFlow">
          <span v-if="status == '2'">重新提交流程</span>
          <span v-else>发起流程</span>
        </a-button>
        <a-button v-else :style="{ marginRight: '8px' }" type="primary" @click="handleSubmit">提交</a-button>
      </a-spin>
    </template>
    <material-form ref="materialForm" :pur-flag="purFlag" @updateData="updateData"></material-form>
    <material-table ref="mTable" :categoryList="categoryList" @updateDataDev="updateDataDev"></material-table>

    <a-modal
      :maskClosable="false"
      :bodyStyle="{ height: '600px', overflow: 'auto' }"
      :visible="applyVisible"
      title="实验室物品申领"
      width="1200px"
      @cancel="handleApplyCancel"
      @ok="handleApplyOk"
    >
      <res-lab-material-apply-modal ref="apply" @changeStatus="(status) => (applyLoading = status)" @ok="handlePost"></res-lab-material-apply-modal>
      <template #footer>
        <a-spin :spinning="applyLoading">
          <a-button :style="{ marginRight: '8px' }" @click="handleApplyCancel">取消</a-button>
          <a-button type="primary" @click="handleApplyOk">确定</a-button>
        </a-spin>
      </template>
    </a-modal>
  </a-modal>
</template>

<script>
import { throttle } from 'lodash'
import { Get, Post, resdevCategoryApi, resLocationApi, resMaterialApi, resPurUseApi } from '@/api/quote'
import ATextarea from 'ant-design-vue/es/input/TextArea'
import ACol from 'ant-design-vue/es/grid/Col'
import { STable } from '@/components'
import MaterialForm from './materialForm'
import { postDataAction } from '@/api/common'
import MaterialTable from './materialTable'
import SelectUserByDept from '@/pages/common/SelectUserByDept'
import fullModal from '@/pages/common/mixins/modal/full'
import mixinForm from '@/pages/common/mixins/form'
import mixinDate from '@/pages/common/mixins/date'
import ResLabMaterialApplyModal from './ResLabMaterialApplyModal'
import { getCateName } from '@/pages/cloud-quote/res/res-puruse/modules/materialReturn/common'
import { checkReagentRawMaterialInfoApi } from '@/api/checkReagent/checkReagentApi'

export default {
  components: { ACol, ATextarea, Get, SelectUserByDept, STable, MaterialForm, MaterialTable, ResLabMaterialApplyModal },
  props: {
    userNameProp: { type: String, default: '' },
    userIdProp: { type: String, default: '' },
    purUseReason: { type: String, default: '' },
    isReagent: { type: Boolean, default: false },
    tableData: [],
    queryArray: []
  },
  mixins: [fullModal, mixinForm, mixinDate],
  data() {
    return {
      userId: null,
      userName: null,
      userList: [],
      purFlag: '0',
      hisData: [],
      projList: [],
      categoryList: [],
      locationList: [],
      projId: [],
      value: 1,
      cateTemp: '',
      materialTemp: '',
      materialList: [],
      speTypeList: [],
      materialChange: [],
      selectedRowKeys: [],
      selectedRows: [],
      data: [],
      status: 1,
      dateOfPurUse: undefined,
      purOutStatus: undefined,
      purReturnStatus: undefined,
      purInsertStatus: undefined,

      columns: [
        { title: '物品类别', dataIndex: 'cateName', key: 'cateName', width: '250px', scopedSlots: { customRender: 'cateName' } },
        { title: '物品名称', dataIndex: 'materialName', key: 'materialName', width: '250px', scopedSlots: { customRender: 'materialName' } },
        { title: '规格型号', dataIndex: 'speType', key: 'speType', width: '150px', scopedSlots: { customRender: 'speType' } },
        { title: '浓度', dataIndex: 'purity', key: 'purity', width: '150px', scopedSlots: { customRender: 'purity' } },
        { title: '使用数量', width: '150px', dataIndex: 'numOfUse', key: 'numOfUse', scopedSlots: { customRender: 'numOfUse' } },
        { title: '单位', dataIndex: 'unit', key: 'unit', width: '150px', scopedSlots: { customRender: 'unit' } },
        { title: '指定设备', width: '300px', dataIndex: 'ids', key: 'ids', scopedSlots: { customRender: 'ids' } },
        { title: '库存数量', width: '100px', dataIndex: 'materialNum', key: 'materialNum', scopedSlots: { customRender: 'materialNum' } },
        { title: '操作', key: 'action', width: '150px', scopedSlots: { customRender: 'operation' } }
      ],

      height: '100%',
      width: '100%',
      id: null,
      fullModal: {
        title: '新建领用申请'
      },
      form: this.$form.createForm(this),
      type: 0,
      defaultChecked: false,
      decorators: {
        dateOfPurUse: ['dateOfPurUse', { rules: [{ required: true, message: '请选择领用日期!  ' }] }],
        userName: ['userName', { initialValue: this.userName }],
        purUseReason: [
          'purUseReason',
          {
            initialValue: this.purUseReason,
            rules: [{ required: true, message: '请输入领用原因!' }]
          }
        ],
        purUseDesc: ['purUseDesc', { rules: [{ required: false }] }]
      },
      purFlagDisabled: false,
      applyVisible: false,
      isAppend: false,
      spinLoading: false,
      applyLoading: false
    }
  },

  created() {
    Get(resPurUseApi.getProjSelect, {}).then((result) => {
      this.projList = result.data
    })
    Get(resdevCategoryApi.list, { pageSize: 500 }).then((result) => {
      this.categoryList = result.data
    })

    Get(resLocationApi.list, { pageSize: 500 }).then((result) => {
      this.locationList = result.data
    })
    this.initDictData()
  },

  computed: {
    loginUser() {
      return { ...this.$store.getters.userInfo }
    },
    getUserName() {
      return this.userNameProp || this.loginUser.realname
    },
    getUserId() {
      return this.userIdProp || this.loginUser.username
    },

    materialUnitCode() {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },

  mounted() {
    this.submit = throttle(this.submit, 3000, { leading: true, trailing: false })
  },

  methods: {
    initAdmin(adminId) {
      Get(`${resPurUseApi.getPurUser}/${adminId}`, {}).then((result) => {
        this.$refs.devUser.setUserList(result.data)
      })
    },
    handleUserChange(userList) {
      if (userList.length > 0) {
        this.userName = userList[0].name
        if (userList[0].username) {
          this.userId = userList[0].username
        }
      }
      const pName = this.userName + this.dateOfPurUse + '领用申请流程'
      const v = {
        purUseName: pName
      }
      this.form.$nextTick(() => {
        this.form.setFieldsValue(v)
      })
    },
    initDictData() {
      // 根据字典Code, 初始化字典数组
      this.$store.dispatch('code/select', 'material_unit_code')
    },
    getCount(text) {
      var textArr = text.split(',')
      return textArr
    },
    closeTag(e, record, text) {
      const d = this.data.filter((item) => record.id === item.id)[0]
      if (d) {
        const idArr = d.ids.split(',')
        d.ids = ''
        idArr.splice(idArr.indexOf(text), 1)
        for (let i = 0; i < idArr.length; i++) {
          d.ids += idArr[i] + ','
        }
        d.ids = d.ids.substr(0, d.ids.length - 1)
        d.numOfUse = d.numOfUse > 0 ? d.numOfUse - 1 : 0
      }
    },
    handleDev(record, cateId) {
      const keyArr = []
      if (record.ids != null) {
        const idArr = record.ids.split(',')
        for (let i = 0; i < idArr.length; i++) {
          idArr[i] = idArr[i].split('_')[0]
          keyArr[i] = idArr[i]
        }
      }
      // 查看当前项目id
      const {
        form: { validateFields }
      } = this
      validateFields((errors, record) => {
        if (record['projId']) this.projId = record['projId']
      })
      // 如果有项目id则关联对应人的已领取的物资
      let userName
      if (this.projId && this.projId.length > 0) userName = this.userName
      this.$refs.mTable.handleAdd(record, keyArr, cateId, userName, this.projId)
    },
    getData(dataJson) {
      // 根据id查询所有物资
      postDataAction(resMaterialApi.getDataByIds, { dataJson: dataJson })
        .then((result) => {
          if (result.code === 0) {
            const d = result.data
            const tmp = []
            for (let i = 0; i < d.length; i++) {
              const a = d[i]
              a.key = d[i].cateId + '_' + d[i].materialName + '_' + d[i].speType
              a.ids = ''
              tmp.push(a)
            }
            result.data = tmp
            this.data = tmp
            const dataMap = result.other
            const projId = dataMap['projId'] // 项目Id
            const purId = dataMap['purId'] // 领用ID
            const purFlag = dataMap['purFlag'] // 领用标识
            this.projId = []
            if (projId) {
              projId.split(',').forEach((item) => {
                this.projId.push(parseInt(item))
              })
            }
            this.purFlag = purFlag
            if (purId) {
              this.append(purId, projId, false)
            } else {
              this.add(false, this.projId, this.purFlag)
            }
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((error) => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
      // 保存数据的时候讲项目编号也存起来
    },

    async getName(text, record) {
      const cateName = await getCateName([record.cateId])
      cateName && (record.cateName = cateName || '-')
      return cateName
    },

    updateData(ds) {
      for (let i = 0; i < ds.length; i++) {
        const d = this.data.filter((item) => ds[i].id === item.id)[0]
        if (d) {
        } else {
          ds[i].numOfUse = 1
          ds[i].ids = ''
          this.data.push(ds[i])
        }
      }
    },
    updateDataDev(ds, record) {
      if (ds.length > 0) {
        const material = ds[0]
        const a = this.data.filter((item) => item.id === record.id)[0]
        if (a) a.ids = ''
        // 判断选择物资状态，如果全部为复用物资，则修改对应状态
        let flag = true
        let purOutStatus
        let userName
        ds.forEach((item) => {
          if (!item.userName) {
            flag = false
          } else {
            purOutStatus = item.purOutStatus
            userName = item.userName
          }
        })
        // 先获取是否存在这个物资
        for (let i = 0; i < ds.length; i++) {
          const d = this.data.filter((item) => item.id === ds[i].materialId)[0]
          // 如果匹配，证明已存在该类型的物资 需要将id追加到ids中
          if (d) {
            const idArr = d.ids.split(',')
            for (let i = 0; i < idArr.length; i++) {
              idArr[i] = idArr[i].split('_')[0]
            }
            if (idArr.indexOf(ds[i].id.toString().split('_')[0]) >= 0) {
              // 如果已经存在此类， 不处理
            } else {
              if (d.ids) {
                if (ds[i].fixedAssetsId) {
                  d.ids += ',' + ds[i].id + '_' + ds[i].fixedAssetsId + '_' + ds[i].materialName + '_' + ds[i].speType
                } else {
                  d.ids += ',' + ds[i].id + '_' + ds[i].materialName + '_' + ds[i].speType
                }
              } else {
                if (ds[i].fixedAssetsId) {
                  d.ids += ds[i].id + '_' + ds[i].fixedAssetsId + '_' + ds[i].materialName + '_' + ds[i].speType
                } else {
                  d.ids += +ds[i].id + '_' + ds[i].materialName + '_' + ds[i].speType
                }
              }
            }
          } else {
            // 类型没有匹配，将数据追加到选择行
            const id = record.id

            const n = this.data.filter((item) => id === item.id)[0]
            if (n) {
              if (n.ids) {
                if (ds[i].fixedAssetsId) {
                  n.ids += ',' + ds[i].id + '_' + ds[i].fixedAssetsId + '_' + ds[i].materialName + '_' + ds[i].speType
                } else {
                  n.ids += ',' + ds[i].id + '_' + ds[i].materialName + '_' + ds[i].speType
                }
              } else {
                if (ds[i].fixedAssetsId) {
                  n.ids += ds[i].id + '_' + ds[i].fixedAssetsId + '_' + ds[i].materialName + '_' + ds[i].speType
                } else {
                  n.ids += +ds[i].id + '_' + ds[i].materialName + '_' + ds[i].speType
                }
              }
            }
          }
        }
        if (flag) {
          material.purOutStatus = purOutStatus
          material.userName = userName
        }
        this.data.push(material)
        this.remove(material)
      }
    },

    newPur() {
      const length = this.data.length
      this.data.push({
        key: length === 0 ? '1' : (parseInt(length) + 10000).toString(),
        editable: true,
        numOfUse: 1,
        isNew: true
      })
    },
    remove(record) {
      if (typeof key === 'undefined') {
        const newData = this.data.filter((item) => item.key !== record.key)
        this.data = newData
      } else {
        const newData = this.data.filter((item) => item.key !== record.key)
        this.data = newData
      }
    },

    handleChangeProjId(value, option) {
      // 取消了所有的项目id
      if (value && value.length === 0) {
        this.data.forEach((item) => {
          item.ids = ''
        })
      }
    },

    handleChangeRadio(e) {
      this.purFlag = e.target.value
    },

    handleAdd() {
      const tableData = this.$refs.table.dataSource
      this.$refs.materialForm.add(tableData)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    append(purId, projId, isClean) {
      this.isAppend = true
      this.fullModal.title = '追加领用申请'
      this.fullModal.visible = true
      this.id = purId
      this.url = resPurUseApi.append
      this.projId = []
      this.purFlagDisabled = true
      if (projId) {
        projId.split(',').forEach((item) => {
          this.projId.push(parseInt(item))
        })
      }
      let record = []
      if (isClean) {
        this.data = []
      }
      // 查出来的是历史领用的物资数据
      Get(`${resPurUseApi.getPurById}/${purId}`, {}, {}).then((res) => {
        record = res.data
        if (record.projId) {
          record.projId.split(',').forEach((item) => {
            const id = parseInt(item)
            if (this.projId.indexOf(id) === -1) {
              this.projId.push(id)
            }
          })
        }
        this.purFlag = record.purFlag
        this.status = record.purUseStatus
        this.userName = record.userName
        this.userId = record.userId
        this.dateOfPurUse = record.purUseName.replace(record.userName, '').replace('领用申请流程', '')
        this.purOutStatus = record.purOutStatus
        this.purReturnStatus = record.purReturnStatus
        this.purInsertStatus = record.purInsertStatus
        this.initAppendUser(this.userId)
        this.form.$nextTick(() => {
          const v = {
            purUseNbr: record['purUseNbr'],
            dateOfPurUse: record['dateOfPurUse'] == null ? this.moment() : this.moment(record['dateOfPurUse'], 'YYYY-MM-DD HH:mm:ss'),
            projId: this.projId,
            purUseReason: record['purUseReason'],
            purUseDesc: record['purUseDesc']
          }
          this.form.setFieldsValue(v)
        })
      })

      Get(`${resPurUseApi.getGoodsById}/${purId}`, {}, {}).then((res) => {
        const d = res.data
        const tmp = []
        for (let i = 0; i < d.length; i++) {
          const a = d[i]
          a.key = i
          tmp.push(a)
        }
        res.data = tmp
        this.hisData = tmp
        this.data = this.data.concat(this.hisData)
        // 追加物资判断是否是复用的
        this.data.forEach((item) => {
          if (item.outStatus === 2) {
            item.purOutStatus = '1'
            item.userName = record.userName
          }
        })
      })
    },

    initAppendUser(userId) {
      Get(`${resPurUseApi.getPurUser}/${userId}`, {}).then((result) => {
        this.$refs.devUser.setUserList(result.data)
      })
    },

    add(clean, projId, purFlag) {
      this.isAppend = false
      this.fullModal.title = '新建领用申请'
      this.fullModal.visible = true
      this.id = null
      this.url = resPurUseApi.add
      if (purFlag && purFlag === '1') {
        this.purFlagDisabled = true
      } else {
        this.purFlagDisabled = false
        this.purFlag = this.isReagent === true ? '2' : '0'
      }
      if (projId) {
        this.projId = projId
      } else {
        this.projId = []
      }
      setTimeout(() => {
        this.initAdmin(this.userId)
      }, 500)
      if (clean) {
        this.data = []
      }
      this.status = 1
      this.purOutStatus = undefined
      this.purReturnStatus = undefined
      this.purInsertStatus = undefined
      const nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hour: nowDate.getHours(),
        min: nowDate.getMinutes(),
        second: nowDate.getSeconds()
      }
      this.form.$nextTick(() => {
        const d = date.year + '' + date.month + '' + date.date + '' + date.hour + '' + date.min + '' + date.second
        this.dateOfPurUse = d
        const pNbr = `MX${d}LYSQ`
        this.userName = this.getUserName
        this.userId = this.getUserId
        const v = {
          purUseNbr: pNbr,
          dateOfPurUse: this.moment(),
          purUseReason: this.purUseReason,
          purUseDesc: '',
          projId: this.projId
        }
        this.form.setFieldsValue(v)
      })
    },

    edit(record) {
      this.isAppend = false
      Get(`${resPurUseApi.getGoodsById}/${record.id}`, {}, {}).then((res) => {
        const d = res.data
        const tmp = []
        for (let i = 0; i < d.length; i++) {
          const a = d[i]
          a.key = d[i].cateId + '_' + d[i].materialName + '_' + d[i].speType
          tmp.push(a)
        }
        res.data = tmp
        this.data = tmp
      })
      this.fullModal.visible = true
      this.fullModal.title = '编辑领用信息'
      this.id = record.id
      this.url = resPurUseApi.edit
      this.status = record.purUseStatus
      this.userName = record.userName
      this.userId = record.userId
      this.dateOfPurUse = record.purUseName.replace(record.userName, '').replace('领用申请流程', '')
      this.purOutStatus = record.purOutStatus
      this.purReturnStatus = record.purReturnStatus
      this.purInsertStatus = record.purInsertStatus
      this.purFlagDisabled = true
      this.purFlag = record.purFlag
      this.projId = []
      if (record.projId) {
        record.projId.split(',').forEach((item) => {
          this.projId.push(parseInt(item))
        })
      }
      this.form.$nextTick(() => {
        const v = {
          purUseNbr: record['purUseNbr'],
          purUseName: record['purUseName'],
          dateOfPurUse: record['dateOfPurUse'] == null ? this.moment() : this.moment(record['dateOfPurUse'], 'YYYY-MM-DD HH:mm:ss'),
          projId: this.projId,
          purUseReason: record['purUseReason'],
          purUseDesc: record['purUseDesc']
        }
        this.form.setFieldsValue(v)
      })
    },

    // 通过原料管理中类别新增领用，会自带物资id
    async addSelectMaterial(materialId) {
      let res = await Get(resMaterialApi.list, { pageNo: 1, pageSize: 10000, page: true, type: '9', cateId: '9,11,27' }).then((res) => res)
      let data = res.data.filter((item) => item.id === materialId)
      if (data.length > 0) {
        data.forEach((item) => {
          item.key = item.id
        })
        for (let i = 0; i < data.length; i++) {
          const d = this.data.filter((item) => data[i].id === item.id)[0]
          if (d) {
          } else {
            let numOfUse = await this.selectUseNumByMaterial(materialId)
            data[i].numOfUse = numOfUse ? numOfUse : 1
            data[i].ids = ''
            this.data.push(data[i])
          }
        }
      }
    },

    // 通过原料管理中类别新增领用，获取相同物资下之前的领用数量
    selectUseNumByMaterial(materialId) {
      return checkReagentRawMaterialInfoApi.selectUseNumByMaterialId({ materialId }).then((res) => {
        if (res.code === 0) {
          return Number(res.data)
        }
      })
    },

    handleChange(value, id, key, column) {
      const newData = [...this.data]
      if (typeof key === 'undefined') {
        const target = newData.filter((item) => id === item.id)[0]
        if (target) {
          target[column] = value
          this.data = newData
        }
      } else {
        const target = newData.filter((item) => key === item.key)[0]
        if (target) {
          target[column] = value
          this.data = newData
        }
      }
      if (column === 'cateId') {
        this.cateTemp = value
        Post(`${resPurUseApi.getMatersByCateId}/${value}`, {})
          .then((result) => {
            this.materialList = result
          })
          .catch((error) => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
      }
      if (column === 'materialName') {
        this.materialTemp = value
        Post(`${resPurUseApi.getSpeTypeByMaterialName}/${value}`, {})
          .then((result) => {
            this.speTypeList = result
          })
          .catch((error) => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
      }
      if (column === 'speType') {
        const v = {
          materialName: this.materialTemp,
          cateId: this.cateTemp,
          speType: value
        }
        Post(`${resPurUseApi.getMaterialInfo}`, v)
          .then((result) => {
            const target = newData.filter((item) => key === item.key)[0]
            target['unit'] = result['unit']
            target['materialNum'] = result['materialNum']
            this.data = newData
          })
          .catch((error) => {
            this.$message.error(`服务器发生错误！错误消息为${error}`)
          })
      }
    },

    handlePurUseDateChange(moment) {
      const dateStr = moment.format('YYYY-MM-DD HH:mm:ss')
      const date = new Date(dateStr)
      const d = '' + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      this.dateOfPurUse = dateStr
      const v = {}
      if (!this.id) {
        const pNbr = `MX${d}LYSQ`
        v.purUseNbr = pNbr
      }
      const pName = this.userName + d + '领用申请流程'
      v.purUseName = pName
      this.form.$nextTick(() => {
        this.form.setFieldsValue(v)
      })
    },

    handleCancel() {
      this.fullModal.visible = false
    },

    handleSave(e) {
      this.submit(e, 3)
    },
    handleSubmit(e) {
      this.submit(e, 1)
    },

    submit(e, status, apply) {
      this.spinLoading = true
      const {
        form: { validateFields }
      } = this
      e.preventDefault()
      const tableData = this.$refs.table.dataSource
      if (tableData.length === 0) {
        this.$message.error(`请选择领用物资!`)
        this.spinLoading = false
        return
      }
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].numOfUse === null || tableData[i].numOfUse === undefined) {
          this.$message.error(`请完善` + tableData[i].materialName + `物资使用数量!`)
          this.spinLoading = false
          return
        }
      }
      validateFields((errors, record) => {
        let proId
        if (record['projId']) {
          proId = record['projId'].join(',')
        }
        const v = {
          purUseNbr: record['purUseNbr'],
          purUseName: record['purUseName'],
          dateOfPurUse: record['dateOfPurUse'] == null ? null : record['dateOfPurUse'].format('YYYY-MM-DD HH:mm:ss'),
          purUseReason: record['purUseReason'],
          purUseDesc: record['purUseDesc'],
          projId: proId,
          userName: this.userName,
          userId: this.userId,
          id: this.id,
          goodsArray: tableData,
          purFlag: this.purFlag,
          purUseStatus: status,
          reagentFlag: this.isReagent,
          purOutStatus: this.purOutStatus,
          purReturnStatus: this.purReturnStatus,
          purInsertStatus: this.purInsertStatus
        }
        if (!errors) {
          if (apply) {
            this.applyVisible = true
            this.$nextTick(() => {
              this.$refs.apply.init(v)
            })
          } else {
            this.handlePost(v)
          }
        } else {
          this.spinLoading = false
        }
      })
    },
    handlePost(record) {
      postDataAction(this.url, record)
        .then((result) => {
          if (result.code === 0) {
            if (record.apply) {
              if (this.status === '2') {
                this.$message.success(`重新发起实验室物品申领流程成功`)
              } else {
                this.$message.success(`新建实验室物品申领流程成功`)
              }
            } else {
              this.$message.success(`${this.fullModal.title}成功`)
            }
            this.handleCancel()
            this.$emit('ok', result.data)
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((error) => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
        .finally(() => {
          this.spinLoading = false
          this.applyLoading = false
        })
    },
    handleStartFlow(e) {
      this.submit(e, 1, true)
    },
    handleApplyOk() {
      this.applyLoading = true
      this.$refs.apply.handleSubmit()
    },
    handleApplyCancel() {
      this.applyVisible = false
      this.spinLoading = false
      this.applyLoading = false
    }
  }
}
</script>
