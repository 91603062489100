<script>
export default {
  name: 'SampleInfoRowView',
  inheritAttrs: false
}
</script>

<script setup>
import { sampleInfoStatusDataMap } from '@/api/sampleInfo/sampleInfoConstant'
import { computed, defineEmits, defineProps } from 'vue'
import { UlTableColumn, UlTableRow } from '@/pages/common/ul-table/Table'

const emit = defineEmits(['remove'])
const props = defineProps({
  data: { required: true, type: Object },
  firstFlag: { type: Number, require: false, default: 0 }
})

const data = computed(() => props.data)
const removeSample = (data) => {
  emit('remove', data)
}
</script>

<template>
  <ul-table-row class="average">
    <ul-table-column :key="`column_${data.id}_id`" :data="data" title="样品编号">
      {{ data.checkSampleId || data.id }}
    </ul-table-column>
    <!-- 如果有复合因子，就显示以下的字段 -->
    <ul-table-column v-if="data.compositeItem" :key="`column_${data.id}_compositeItem`" :data="data" title="因子">
      {{ data.compositeItemName.join('，') }}
    </ul-table-column>
    <ul-table-column :key="`column_${data.id}_status`" :data="data" title="样品状态">
      <template v-if="sampleInfoStatusDataMap[data.status]">
        <a-tag :color="sampleInfoStatusDataMap[data.status].color">
          {{ sampleInfoStatusDataMap[data.status].label }}
        </a-tag>
      </template>
      <template v-else>
        <a-tag color="red">无效的样品状态</a-tag>
      </template>
    </ul-table-column>

    <ul-table-column :key="`column_${data.id}_sampleTime`" :data="data" data-index="sampleBeginTime" title="采样时间" />

    <ul-table-column :key="`column_${data.id}_batchTime`" :data="data" data-index="batchTime" title="安排时间" />
    <!-- <ul-table-column
      :key="`column_${data.id}_pickupTime`"
      :data="data"
      data-index="pickupApplyTime"
      title="安排时间"/> -->

    <ul-table-column :key="`column_${data.id}_validate`" :data="data" title="有效期">
      <a-tooltip>
        <template #title>
          有效期:
          <template v-if="data.validity && data.validityUnit"> {{ data.validity }}{{ data.validityUnit }} </template>
          <template v-else>
            <span class="red">未设置</span>
          </template>
        </template>

        <template v-if="!data.validityDate">
          <a-tag color="orange">暂未配置</a-tag>
        </template>
        <template v-else-if="data.expired">
          <span class="red"> {{ data.validityDate | momentFilter('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
        <template v-else>
          <span class="green">{{ data.validityDate | momentFilter('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>
      </a-tooltip>
    </ul-table-column>

    <ul-table-column :key="`column_${data.id}_action`" :style="{ flex: 0.2 }" v-if="props.firstFlag !== 1">
      <a class="fr" @click="removeSample(data)" style="color: red">移除</a>
    </ul-table-column>
  </ul-table-row>
</template>
