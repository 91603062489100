<template>
  <div class="project-sample-info-table">
    <s-table :rowSelection="customRowSelection" :scroll="scrollProp" v-bind="contentTable" v-on="contentTable.action">

      <template #projLeader="item,record">
        <a-tooltip>
          <template #title>
            项目负责人：{{ record.projLeaderName }} <br/>
            采样负责人：{{ record.sampleLeaderName }}
          </template>
          {{ record.sampleLeaderName }}
        </a-tooltip>
      </template>

      <template #projStatus="item">
        <a-tag :color="projectStatusDataMap[item].color">{{ projectStatusDataMap[item].label }}</a-tag>
      </template>

      <template #projSampleProgress="item,record">
        {{ item }}
        <template v-if="record.projSampleSource===sampleInfoSourceEnum.sampling && taskStatusOptionsDataMap[record.taskStatus]">
          <a-tag :color="taskStatusOptionsDataMap[record.taskStatus].color">{{ taskStatusOptionsDataMap[record.taskStatus].label }}</a-tag>
        </template>
        <template v-else> --</template>
      </template>

      <template #sampleInfoHandoverStat="item,record">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            待采样：{{ record.tempSampleNum || 0 }}<br/>
            待交样：{{ record.doneSampleNum || 0 }}<br/>
            预计总样品：{{ record.sampleSum || 0 }}
          </template>
          <span class="red focus-num mar-h5">{{ record.tempSampleNum || 0 }}</span>/
          <span class="yellow focus-num mar-h5">{{ record.doneSampleNum || 0 }}</span>/
          <span class="green focus-num mar-h5">{{ record.sampleSum || 0 }}</span>
        </a-tooltip>
      </template>

      <template #sampleInfoStat="item,record">
        <a-tooltip :getPopupContainer="getCurrentDocument">
          <template #title>
            在库：{{ record.storagedNum || 0 }}<br/>
            已出库：{{ record.outboundNum || 0 }}<br/>
            已销毁：{{ record.destoryedNum || 0 }}<br/>
            留样：{{ record.reservedNum || 0 }}
          </template>
          <span class="green focus-num mar-h5">{{ record.storagedNum || 0 }}</span>/
          <span class="blue focus-num mar-h5">{{ record.outboundNum || 0 }}</span>/
          <span class="orange focus-num mar-h5">{{ record.destoryedNum || 0 }}</span>/
          <span class="blue focus-num mar-h5">{{ record.reservedNum || 0 }}</span>
        </a-tooltip>
      </template>

      <template #projSampleSource="item">
        <a-tag :color="sampleInfoSourceDataMap[item].color">{{ sampleInfoSourceDataMap[item].label }}</a-tag>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot :name="slot" v-bind="{text:text,row:record,index:index,expanded}"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import mixinList from '@/pages/common/mixins/list'
import { projectSampleInfoTableDefaultColumn as defaultColumn } from './columns'
import { projectStatusDataMap } from '@/api/project/projectConstants'
import { sampleInfoSourceDataMap, sampleInfoSourceEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { taskStatusOptionsDataMap, tsSampleinfoApi } from '@/api/sample'

export default {
  components: { sTable },
  mixins: [mixinList],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    defaultOtherHeight: { type: Number, required: false, default: 0 },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      projectStatusDataMap,
      sampleInfoSourceEnum,
      sampleInfoSourceDataMap,
      taskStatusOptionsDataMap,

      contentTable: {
        rowKey: 'projId'
      }

    }
  },

  created () {
    this.$store.dispatch('code/select', 'sample_source')
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    },

    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        return tsSampleinfoApi.projectList(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }
  }

}

</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";

.project-sample-info-table {

  .data();
  .margin();
  .color();

}
</style>
