<template>
  <div style="margin-bottom: 50px;">
    <div class="ahmux-title" style="width: 100%; margin-bottom: 18px;">
      报告寄发申请
    </div>
    <report-base-info :orderData="orderData" v-if="orderData.projId"></report-base-info>
    <div style="width: 100%;height: 100%;">
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              收件信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel">领取方式</td>
              <td>
                <a-tag v-if="sendData.sendMode==='1'" color="blue">{{ reportReceiveDict[sendData.sendMode] }}</a-tag>
                <a-tag v-else-if="sendData.sendMode==='2'" color="green">{{ reportReceiveDict[sendData.sendMode] }}</a-tag>
                <a-tag v-else-if="sendData.sendMode==='9'" color="orange">{{ reportReceiveDict[sendData.sendMode] }}</a-tag>
              </td>
              <td class="tdLabel">寄发份数</td>
              <td>{{ sendData.receiveNum }}份</td>
            </tr>
            <tr v-if="sendData.sendMode!='9'">
              <td class="tdLabel">收件人</td>
              <td>{{ sendData.receiveUser }}</td>
              <td class="tdLabel">联系电话</td>
              <td>{{ sendData.receiveTel }}</td>
            </tr>
            <tr v-if="sendData.sendMode==='1'">
              <td class="tdLabel">收件地址</td>
              <td colspan="3">{{ sendData.receiveAddress }}</td>
            </tr>
            <tr v-if="sendData.sendMode==='9'">
              <td class="tdLabel">发送方式</td>
              <td colspan="3">{{ reportSendScanMode[sendData.scanSendMode] }}</td>
            </tr>
            <tr>
              <td class="tdLabel">备注</td>
              <td colspan="3">{{ sendData.sendNote }}</td>
            </tr>
          </table>
        </a-row>
      </a-card>
    </div>
    <div style="width: 100%;height: 100%;" v-if="isEditForm">
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}" v-if="sendData.sendMode=='1'">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              快递信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-form v-bind="dataForm">
            <table class="mxtable">
              <tr>
                <td class="tdLabel" style="padding: 0 5px;"><span style="color: red;margin-right: 5px;">*</span>快递单号</td>
                <td style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <a-input placeholder="请输入快递单号" v-decorator="decorators.packageNumber"></a-input>
                  </a-form-item>
                </td>
                <td class="tdLabel" style="padding: 0 5px;"><span style="color: red;margin-right: 5px;">*</span>快递公司</td>
                <td style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <a-input placeholder="请输入快递公司" v-decorator="decorators.packageCompany"></a-input>
                  </a-form-item>
                </td>
              </tr>
              <tr>
                <td class="tdLabel" style="padding: 0 5px;">备注信息</td>
                <td colspan="3" style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <a-textarea placeholder="备注信息" v-decorator="decorators.feedbackNote"></a-textarea>
                  </a-form-item>
                </td>
              </tr>
              <tr>
                <td class="tdLabel" style="padding: 0 5px;">附件</td>
                <td colspan="3" style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <j-upload v-model="defaultData.accessory"></j-upload>
                  </a-form-item>
                </td>
              </tr>
            </table>
          </a-form>
        </a-row>
      </a-card>
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}" v-else>
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              备注信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-form v-bind="dataForm">
            <table class="mxtable">
              <tr>
                <td class="tdLabel" style="padding: 0 5px;">备注信息</td>
                <td colspan="3" style="padding: 0 5px;">
                  <a-form-item label="" style="margin: 0;">
                    <a-textarea placeholder="备注信息" v-decorator="decorators.feedbackNote"></a-textarea>
                  </a-form-item>
                </td>
              </tr>
            </table>
          </a-form>
        </a-row>
      </a-card>
    </div>
    <div style="width: 100%;height: 100%;" v-else>
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}" v-if="sendData.sendMode=='1'&&sendData.packageNumber">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              快递信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel">快递单号</td>
              <td>{{sendData.packageNumber}}</td>
              <td class="tdLabel">快递公司</td>
              <td>{{ sendData.packageCompany }}</td>
            </tr>
            <tr>
              <td class="tdLabel">备注信息</td>
              <td colspan="3">{{ sendData.feedbackNote }}</td>
            </tr>
            <tr>
              <td class="tdLabel">附件</td>
              <td colspan="3"><j-upload v-model="sendData.accessory"></j-upload></td>
            </tr>
          </table>
        </a-row>
      </a-card>
      <a-card :bordered="false" :bodyStyle="{'padding': '0','marginTop': '20px'}" v-else-if="sendData.feedbackNote">
        <a-row>
          <a-col span="3">
            <div class="ahmux-sub-title" style="width: 100px;">
              备注信息
            </div>
          </a-col>
        </a-row>
        <a-row>
          <table class="mxtable">
            <tr>
              <td class="tdLabel">备注信息</td>
              <td colspan="3">{{ sendData.feedbackNote }}</td>
            </tr>
          </table>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import { reportSendOrderApi } from '@/api/sample'
import JUpload from '@/components/j/JUpload'
import moment from 'moment'
import { reportTypeApi } from '@/api/quote'
import ReportBaseInfo from './ReportBaseInfo'
import { reportReceiveDict } from '../reportSendDict'
import { dictApi } from '@/api/system'

var vm = {}
export default {
  name: 'ReportSendMgr',
  components: { ReportBaseInfo, JUpload },
  props: {
    recordId: {
      type: Number,
      default: null
    },
    // 流程操作节点信息
    activeTask: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    vm = this
    return {
      reportType: this.$store.state.code.cache.proj_report_type,
      defaultData: {
        id: this.recordId,
        accessory: null
      },

      orderData: {},
      sendData: {},

      dataForm: {
        class: 'check-form',
        form: this.$form.createForm(this)
      },

      decorators: {
        feedbackNote: ['feedbackNote', { initialValue: '' }],
        packageNumber: ['packageNumber', { initialValue: '', rules: [{ required: true, message: '请输入快递单号' }] }],
        packageCompany: ['packageCompany', { initialValue: '', rules: [{ required: true, message: '请输入快递公司' }] }]
      },

      // reportReceiveDict: { '1': '邮寄', '2': '自取', '9': '其他' },
      reportReceiveDict,
      isEditForm: false,
      reportSendScanMode: {}
    }
  },
  created () {
    this.initDictData()
    if (typeof this.reportType === 'undefined') {
      this.getReportType()
    }
  },
  methods: {
    moment,
    initDictData () {
      this.reportSendScanMode = {}
      // 根据字典Code, 初始化字典数组
      dictApi.getItems({ dictCode: 'reportSendScanMode' }).then((res) => {
        if (res.code === 0) {
          res.data.forEach(item => {
            this.reportSendScanMode[item.itemValue] = item.itemText
          })
        }
      })
    },
    getReportType () {
      reportTypeApi.listReportType().then((res) => {
        if (res.code === 0) {
          const reportType = {}
          res.data.forEach(item => {
            reportType[item.itemValue] = item.itemText
          })
          this.reportType = reportType
        }
      })
    },

    init () {
      reportSendOrderApi.findOne({ id: this.recordId }).then((res) => {
        if (res.code === 0) {
          this.sendData = res.data
        }
      })
    },

    loadOrderData () {
      reportSendOrderApi.findReportOrder({ projId: this.sendData.projId }).then((res) => {
        if (res.code === 0) {
          this.orderData = res.data
        }
      })
    }
  },
  filters: {
    reportTypeFilter (type) {
      if (typeof vm.reportType !== 'undefined') {
        return vm.reportType[type]
      }
      return ''
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null) {
          this.init()
        }
        // val && this.getShowDataByProjId(val)
      }
    },
    sendData: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val != null && val.hasOwnProperty('projId')) {
          this.loadOrderData()
        }
      }
    },
    activeTask: {
      immediate: true,
      handler (val) {
        if (this.activeTask.endTime === null && this.activeTask.name === '邮寄反馈') {
          this.isEditForm = true
        }
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
