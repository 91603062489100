<template>
  <div class="destroy-form">
    <enhanced-generate-form :data="generateFormProps.jsonData" :remote="remote" ref="generateForm" v-if="generateFormProps.visible" v-model="models">
      <template slot="dealUser">
        <SelectUserByDept :value="models.dealUserName"></SelectUserByDept>
      </template>

      <template #sampleDestroyItemName="{model}">
        <a-tooltip>
          <template #title>{{ model.itemNameStr }}</template>
          <div class="ahmux-text-ellipsis" :style="{width:'100%',float:'left'}">{{ model.itemNameStr }}</div>
        </a-tooltip>
      </template>

      <template #sampleDestroyProjName="{model}">
        <a-tooltip>
          <template #title>{{ model.projName }}</template>

          <div class="ahmux-text-ellipsis" :style="{width:'100%',float:'left'}">
            <template v-if="model.projStatus==='-1'">
              <a-tag color="red" :style="{marginBottom:'0px'}">已终止</a-tag>
            </template>
            <template v-else-if="model.projStatus==='2'">
              <a-tag color="red" :style="{marginBottom:'0px'}">临时终止</a-tag>
            </template>
            {{ model.itemNameStr }}
          </div>
        </a-tooltip>
      </template>

      <template #sampleDestroySampleType="{model}">
        <a-tag :color="sampleInfoTypeDataMap[model.type]&&sampleInfoTypeDataMap[model.type].color||'red'">
          {{ model.type === sampleInfoTypeEnum.password ? sampleInfoTypeDataMap[sampleInfoTypeEnum.normal].label : sampleInfoTypeDataMap[model.type] && sampleInfoTypeDataMap[model.type].label || '未知' }}
        </a-tag>
      </template>

    </enhanced-generate-form>
    <template v-else>
      <div class="load">
        <a-spin class="content" size="large" tip="页面渲染中..."></a-spin>
      </div>
    </template>
  </div>
</template>

<script>
import date from '@/pages/common/mixins/date'
import { GenerateForm, hoc } from 'ahmux-form-designing'
import { Message } from 'element-ui'
import { customFormFunc } from '@/pages/flow/module/CustomFormFunc'
import { tsSampleinfoApi } from '@/api/sample'

import SelectUserByDept from '@/pages/common/SelectUserByDept'
import { getAction } from '@/api/common'
import { modelApi } from '@/api/flow'

import { sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { sampleInfoDeleteApi } from '@/api/sampleInfo/sampleInfoDeleteApi'

const EnhancedGenerateForm = hoc(GenerateForm, {})
export default {

  components: { EnhancedGenerateForm, SelectUserByDept },

  mixins: [date],

  props: {
    sampleList: {
      type: Array,
      required: true,
      default: null
    }
  },

  data () {
    return {
      sampleInfoTypeDataMap,
      sampleInfoTypeEnum,
      // 自定义表单数据
      customData: {},

      scrollTop: 0,

      flowKey: 'ts_sample_info_delete',

      models: {
        sampleList: this.sampleList,
        applyUser: null,
        applyUserName: null,
        itemNames: null,
        applyTime: null,
        dealUser: null
      },

      remoteFuncs: {},

      // 表单生成组件类型参数
      generateFormProps: {
        url: null,
        visible: false,
        jsonData: null
      }
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    },

    // 自定义表单远程方法 ，外部组件传递的远程方法优先级高于内部自定义
    remote () {
      customFormFunc._self = this
      return Object.assign({}, customFormFunc, this.remoteFuncs)
    }
  },

  watch: {

    // 自定义表单数据 解析工作
    customData: {
      handler (data) {
        if (data) {
          this.generateFormProps.jsonData = JSON.parse(data.json)
          this.generateFormProps.visible = true
        }
      }
    }
  },

  created () {
    this.findCustomForm()
    this.initDefaultData()
  },

  mounted () {
    // 修复浏览器滚动条位置可能导致elementUI弹出层定位偏移问题
    this.scrollTop = window.document.body.scrollTop
    window.document.body.scrollTop = 0
  },

  destroyed () {
    // 重置浏览器滚动条的位置
    window.document.body.scrollTop = this.scrollTop
  },

  methods: {

    // 样品信息
    findSampleInfo (id) {
      tsSampleinfoApi.findOne({ id: id }).then(res => {
        if (res.code === 0) {
          this.models.itemNames = res.data.itemNameStr
        } else {
          this.$message.error(`获取样品信息错误：${res.msg}`)
        }
      })
    },

    // 初始化默认数据
    initDefaultData () {
      Object.assign(this.models, {
        applyUser: this.loginUser.username,
        applyUserName: this.loginUser.realname,
        applyTime: this.timeFormat(this.moment()),
        dealUser: this.loginUser.username,
        dealUserName: this.loginUser.realname
      })
    },

    // 查询分组信息
    findCustomForm () {
      getAction(modelApi.getCustomForm, { processId: this.flowKey }).then(res => {
        if (res.code === 0) {
          this.customData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    save () {
      this.$refs.generateForm.$children[0].getData().then(data => {
        const sampleIds = this.models.sampleList.filter(item => item.id).map(item => { return item.id })
        delete this.models.sampleList
        sampleInfoDeleteApi.apply(Object.assign({ sampleIds }, this.models)).then(res => {
          if (res.code === 0) {
            Message.success(res.msg)
            this.$emit('success')
          } else {
            Message.error(res.msg)
          }
        }, (e) => {
          Message.error(e.response.data.msg)
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "index.less";

</style>
