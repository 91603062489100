<template>
  <div class="check-task-log">
    <template v-if="searchData.length">
      <a-timeline v-bind="logTimeLine">
        <a-timeline-item :color="getColor(item)" :key="index" v-for="(item,index) in searchData">

          <div class="item-detail">
            <dl>
              <dt>操作人</dt>
              <dd>{{ item.userRealName }}</dd>
            </dl>
            <dl>
              <dt>操作时间</dt>
              <dd>{{ item.time|minuteFilter }}</dd>
            </dl>

            <dl>
              <dt>操作</dt>
              <dd>
                <div :class="'flag-'+item.action">{{ checkTaskLogTypeMap[item.action] }}</div>
              </dd>
            </dl>

            <dl v-if="hasDetailContentFlags.includes(item.action)">
              <dt>操作内容</dt>
              <div :style="{margin:'0 5%'}">
                <template v-if="showContent(item)">
                  <div>
                    <dl v-if="showContent(item).detail.hasOwnProperty('methodName')">
                      <dt>检测方法</dt>
                      <dd>{{ showContent(item).detail.methodName }}</dd>
                    </dl>

                    <dl v-if="showContent(item).detail.hasOwnProperty('deviceNames')">
                      <dt>检测设备</dt>
                      <dd>{{ showContent(item).detail.deviceNames }}</dd>
                    </dl>

                    <dl v-if="showContent(item).detail.hasOwnProperty('limitValue')">
                      <dt>检出限</dt>
                      <dd>{{ showContent(item).detail.limitValue }}</dd>
                    </dl>

                    <dl v-if="showContent(item).detail.hasOwnProperty('originalValue')">
                      <dt>原始值</dt>
                      <dd>{{ showContent(item).detail.originalValue }}</dd>
                    </dl>

                    <dl v-if="showContent(item).detail.hasOwnProperty('value')">
                      <dt>结果值</dt>
                      <dd>{{ showContent(item).detail.value }}</dd>
                    </dl>
                  </div>
                </template>
              </div>
            </dl>
          </div>

        </a-timeline-item>
      </a-timeline>
    </template>
    <template v-else>
      <div style="text-align: center;font-size: 14px; color: rgba(0, 0, 0, 0.45);">
        暂无数据
      </div>
    </template>

  </div>
</template>

<script>
import { checkTaskLogApi } from '@/api/check/checkTaskLogApi'
import { checkTaskLogTypeEnum, checkTaskLogTypeEnumOptions, hasDetailContentFlags } from '@/api/check/checkTaskLogConstant'
import { tranOptionsToMap, tranOptionsToDataMap } from '@/utils/util'

const checkTaskLogTypeMap = tranOptionsToMap(checkTaskLogTypeEnumOptions)
const checkTaskLogTypeDataMap = tranOptionsToDataMap(checkTaskLogTypeEnumOptions)

export default {
  name: 'SampleDateLine',

  props: {
    checkTaskId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      checkTaskLogTypeMap,
      checkTaskLogTypeDataMap,
      checkTaskLogTypeEnum,
      hasDetailContentFlags,
      /// 查询数据
      searchData: [],

      contentCache: {},

      logTimeLine: {
        mode: 'left',
        reverse: false
      },

      /// 默认的查询参数
      defaultQueryParam: {
        page: false
      },

      problemDetailModal: {
        title: '预览',
        visible: false,
        formRef: 'dealProblemRef',
        destroyOnClose: true,
        width: 800,
        id: null,
        show: (id) => {
          this.problemDetailModal.id = id
          this.problemDetailModal.visible = true
        },
        cancel: () => { this.problemDetailModal.visible = false }
      }
    }
  },

  watch: {
    checkTaskId: {
      immediate: true,
      handler (val) {
        this.loadData(val)
      }
    }
  },

  methods: {
    /// 加载数据
    loadData (val) {
      checkTaskLogApi.list(Object.assign({ checkTaskId: val }, this.defaultQueryParam)).then(res => {
        if (res.code === 0) {
          this.searchData.splice(0, this.searchData.length, ...(res.data || []))
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    showContent (item) {
      var cacheContent = this.contentCache[item.id]
      if (cacheContent) return cacheContent
      const data = JSON.parse(item.content)
      if (data.hasOwnProperty('new')) {
        const newData = data.new
        const oldData = data.old
        this.contentCache[item.id] = this.compareDataDiff(newData, oldData)
        return this.contentCache[item.id]
      }
    },

    compareDataDiff (newData, oldData) {
      // type 1.新增， 2.修改
      const result = { type: oldData ? '2' : '1', detail: {} }
      // 方法、设备、检出限、原始值、结果值
      const filedList = ['methodName', 'deviceNames', 'limitValue', 'originalValue', 'value']
      filedList.forEach(item => {
        if (result.type === '1' && newData[item]) {
          result.detail[item] = item === 'value' ? this.dealDataValue(newData.value, newData.resultType) : newData[item]
        } else if (newData[item] && newData[item] !== oldData[item]) {
          if (item === 'value') {
            const oldContent = this.dealDataValue(oldData.value, oldData.resultType)
            const newContent = this.dealDataValue(newData.value, newData.resultType)
            result.detail[item] = `${oldContent}调整为${newContent}`
          } else {
            result.detail[item] = `${oldData[item]}调整为${newData[item]}`
          }
        }
      })

      if (result.type === '2' && newData.resultType !== oldData.resultType) {
        const oldContent = this.dealDataValue(oldData.value, oldData.resultType)
        const newContent = this.dealDataValue(newData.value, newData.resultType)
        result.detail['value'] = `${oldContent}调整为${newContent}`
      }

      return result
    },

    dealDataValue (value, type) {
      if (type === '3') {
        return '未检出'
      } else {
        return value + (type === '2' ? 'L' : '')
      }
    },

    getColor (item) {
      return checkTaskLogTypeDataMap[item.action].color
    }

  }

}
</script>

<style lang="less" scoped>
  @import "index";
</style>
