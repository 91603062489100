<template>
  <a-modal :title="title" :visible="visible" :width="modalWidth" cancelText="关闭" style="margin-top: -70px" wrapClassName="ant-modal-cust-warp" @cancel="close" @ok="handleSubmit">
    <a-row :gutter="10" class="SelectCheckBoxRow" style="background-color: #ececec; padding: 10px; margin: -10px">
      <a-col :md="4" :sm="24">
        <a-card :bordered="false">
          <!--检测项一级分类-->
          <a-directory-tree
            v-if="cateTypeTree.length > 0"
            :checkStrictly="true"
            :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }"
            :selectedKeys="selectedKeys"
            :treeData="cateTypeTree"
            selectable
            @select="onSelect"
          />
        </a-card>
      </a-col>
      <a-col :md="20" :sm="24">
        <a-card v-bind="actionCard" hoverable>
          <a-input-search
            v-model="queryParam.itemName"
            :placeholder="`请输入${typeTitle}名称`"
            :style="{ width: '200px', marginBottom: '8px' }"
            enterButton
            @search="onSearch"
          ></a-input-search>
          <a-button icon="redo" style="margin-left: 20px" @click="searchReset(1)">重置</a-button>
          <div :style="{ height: '460px', 'overflow-y': 'auto' }" ref="autoCheckBoxWarp">
            <a-checkbox-group v-model="selectedRowKeys" style="width: 100%" @change="changeCheckBox">
              <template v-for="(cateType, typeIdx) in cateTypeTree">
                <template v-if="cateType.key !== 'all'">
                  <div v-if="selectedKeys[0] === cateType.key || selectedKeys[0] === 'all'" :key="typeIdx">
                    <div style="margin-top: 10px">
                      <span style="font-weight: bolder">{{ cateType.title }}</span>
                      <a-divider style="margin: 8px 0px" />
                    </div>
                    <a-row v-if="cateTypeItems[cateType.key]">
                      <a-col class="item-col" :span="6" v-for="(obj, idx) in cateTypeItems[cateType.key]" :key="idx">
                        <a-checkbox :value="obj.id">
                          <a-tag :id="obj.id" :title="obj.name">{{ obj.name }}</a-tag>
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </template>
            </a-checkbox-group>
          </div>
        </a-card>

        <a-card v-bind="contentCard" hoverable>
          <a-button slot="extra" @click="clearAll">清空</a-button>
          <div style="min-height: 20px; height: auto">
            <template v-for="cateType in cateTypeTree">
              <div v-if="selectedItemList.filter((r) => r.cateType == cateType.key).length > 0" :key="cateType.key">
                <span>{{ cateType.title }}：</span>
                <template v-for="v in selectedItemList">
                  <a-tag v-if="v.cateType == cateType.key" :key="v.id" :afterClose="() => delSelectedItem(v.id)" :closable="closable">{{ v.name }}</a-tag>
                </template>
              </div>
            </template>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { filterObj } from '@/utils/util'
import { Get, itemApi } from '@/api/quote'
import { dictApi } from '@/api/system'
import { forIn, groupBy, orderBy, uniqBy } from 'lodash'

export default {
  name: 'SelectCheckBoxModal',
  components: {},
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },
    typeTitle: {
      type: String,
      default: '采样项'
    }
  },
  data() {
    return {
      closable: true,
      lastRowKeyLength: 0,
      selectedItemList: [],
      actionCard: {
        title: '选择' + this.typeTitle,
        style: {},
        headStyle: {},
        bodyStyle: { background: '#D7DDE312' },
        bordered: true,
        type: ''
      },
      contentCard: {
        title: '已选项',
        style: { marginTop: '10px' },
        headStyle: {},
        bodyStyle: {},
        bordered: false,
        type: 'inner'
      },
      scrollTrigger: {},
      selectedKeys: [],
      currentCateType: '',

      queryParam: {
        itemName: ''
      },
      title: '选择项',
      selectedRowKeys: [],
      selectedRows: [],
      cateTypeTree: [],
      visible: false,
      list: [],
      cateTypeItems: {}
    }
  },
  created() {
    // 该方法触发屏幕自适应
    this.resetScreenSize()
      this.loadData()

  },
  methods: {
    // 触发屏幕自适应
    resetScreenSize() {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.scrollTrigger = { x: 800 }
      } else {
        this.scrollTrigger = {}
      }
    },
    showModal(selectedItemList) {
      this.visible = true
      this.selectedRowKeys = []
      this.lastRowKeyLength = selectedItemList.length
      this.selectedItemList = selectedItemList
      this.initRowkeyBySelectedItemList(selectedItemList)
      this.queryCateTypeTree()
      this.loadData()
    },
    initRowkeyBySelectedItemList(selectedItemList) {
      for (let i = 0; i < this.selectedItemList.length; i++) {
        this.selectedRowKeys.push(selectedItemList[i].id)
      }
    },

    // 获取分类
    queryCateTypeTree() {
      this.cateTypeTree = []
      dictApi.getItems({ dictCode: 'cate_type' }).then((res) => {
        if (res.code === 0) {
          const d = res.data
          const temp = [{ title: '全部', key: 'all' }]
          for (let i = 0; i < d.length; i++) {
            temp.push({ title: d[i].itemText, key: d[i].itemValue })
          }
          this.cateTypeTree = temp
          if (this.list.length > 0) {
            this.onSelect(['all'])
          }
        }
      })
    },
    // 获取所有因子
    loadData(arg) {
      this.list = []
      itemApi
        .listItem({ page: false, hasClause: true })
        .then((res) => {
          if (res.code === 0) {
            this.list = res.data
            this.cateTypeItems = groupBy(res.data, (item) => item.cateType)
            if (this.cateTypeTree.length > 0) {
              this.onSelect(['all'])
            }
          } else {
            this.$message.error('查询失败')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击树节点,筛选出对应的因子
    onSelect(selectedKeys) {
      if (selectedKeys.length > 0) {
        this.selectedKeys = selectedKeys
        this.currentCateType = selectedKeys[0].toString()
        this.$refs.autoCheckBoxWarp.scrollTop = 0
        this.onSearch(this.queryParam.itemName)
      }
    },
    // 选择因子时调用
    changeCheckBox(e) {
      if (this.list.length > 0) {
        this.selectedItemList = this.list.filter((item) => this.selectedRowKeys.includes(item.id))
      }
    },
    // 查询
    onSearch(input) {
      if (input) {
        let listGroup = groupBy(this.list, (item) => item.cateType)
        if (this.selectedKeys.length === 0 || this.selectedKeys[0] === 'all') {
          for (const key in listGroup) {
            this.cateTypeItems[key] = listGroup[key].filter((item) => item.name.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        } else {
          this.cateTypeItems[this.selectedKeys[0]] = listGroup[this.selectedKeys[0]].filter((item) => item.name.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }
      } else {
        this.cateTypeItems = groupBy(this.list, (item) => item.cateType)
      }
    },
    // 重置
    searchReset() {
      this.queryParam = { itemName: '' }
      this.cateTypeItems = groupBy(this.list, (item) => item.cateType)
    },
    // 清空选中的因子
    clearAll() {
      this.selectedItemList = []
      this.selectedRowKeys = []
      this.lastRowKeyLength = 0
    },
    // 删除选中的因子
    delSelectedItem(id) {
      this.selectedItemList = this.selectedItemList.filter((r) => r.id !== id)
      this.selectedRowKeys = this.selectedRowKeys.filter((r) => r !== id)
      this.lastRowKeyLength = this.selectedRowKeys.length
    },
    close() {
      this.selectedRowKeys = []
      this.selectedKeys = []
      this.queryParam = { itemName: '' }
      this.visible = false
    },
    handleSubmit() {
      this.$emit('ok', this.selectedItemList)
      this.close()
    },
    searchList(itemIds){
      this.selectedItemList=this.list.filter(item=>itemIds.includes(item.id))
      this.selectedRowKeys.splice(0,this.selectedRowKeys.length,...itemIds)
      this.$emit('ok', this.selectedItemList)
    }
  }
}
</script>

<style scoped>
.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  float: left;
  margin-right: 4px;
  margin-top: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 20px 0 10px;
}

.ant-select-selection__choice__content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  -webkit-transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.anticon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style lang="less">
.SelectCheckBoxRow {
  .item-col {
    width: 120px;
    line-height: 0px;
    margin: 2px 0px;
    .ant-tag {
      width: 80px;
      text-align: center;
      position: relative;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: bottom;
    }
  }
}
</style>