import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'
// 检测任务线下完成状态 字典枚举
export const checkTaskOfflineStatusEnum = {

  // 进行中
  part: '0',

  //  全部完成
  done: '1',

  // 审核通过
  pass: '2',

  // 审核驳回
  rejected: '3'
}

//  检测任务状态选择项
export const checkTaskOfflineStatusOptions = [
  { label: '进行中', value: checkTaskOfflineStatusEnum.part, color: 'orange' },
  { label: '已完成', value: checkTaskOfflineStatusEnum.done, color: 'green' },
  { label: '审核通过', value: checkTaskOfflineStatusEnum.pass, color: '#87D068' },
  { label: '审核驳回', value: checkTaskOfflineStatusEnum.rejected, color: 'red' }
]
export const checkTaskOfflineStatusMap = tranOptionsToMap(checkTaskOfflineStatusOptions)
export const checkTaskOfflineStatusDataMap = tranOptionsToDataMap(checkTaskOfflineStatusOptions)
