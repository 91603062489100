<template>
  <div class="utabs">
    <a-spin :spinning="templateLoad" tip="模板文件加载中，请稍后...">
      <!-- 单个模板 -->
      <template v-if="templateList.length===1">
        <a-row style="height:100%;">
          <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
          <Watermark/>
        </a-row>
      </template>

      <!-- 多个模板 -->
      <template v-else-if="templateList.length>1">
        <a-tabs v-model="curIdx" size="small" style="height: 100%;padding: 0;margin: 0;overflow: hidden;" tabPosition="left" type="line">
          <a-tab-pane v-for="(obj,idx) of templateList" :key="idx" :closable="false" :tab="obj.templateName" style="height: 100%">
            <a-row style="height:100%;">
              <iframe :src="iframeSrc" frameborder="0" style="width: 100%;height:100%;margin: 0 auto"/>
              <Watermark/>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </template>

      <template v-else>
        <div class="no-data">
          暂无模板文件
        </div>
      </template>
    </a-spin>
  </div>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import Watermark from '@/pages/common/watermark/Watermark'

export default {
  name: 'UreportView',
  components: {
    Watermark
  },

  props: {
    templateParams: {
      type: Object,
      required: true,
      default: () => ({})
    },
    templateExportStr: { type: String, default: '1,2,3,4,5,6,7,8,9' }
  },

  data () {
    return {
      curIdx: 1,
      templateList: [],
      templateLoad: true
    }
  },

  computed: {
    currentTemplate () {
      switch (this.templateList.length) {
        case 0:
          return null
        case 1:
          return this.templateList[0]
        default:
          return this.templateList[this.curIdx]
      }
    },

    iframeSrc () {
      let otherParam = ''
      if (this.$attrs && Object.keys(this.$attrs).length) {
        Object.keys(this.$attrs).forEach((key) => {
          const value = this.$attrs[key]
          if (value != null && value !== '') {
            if (Object.prototype.toString.call(value) === '[object Array]') {
              if (value.length) {
                otherParam += `&${key}=${value.join(',')}`
              }
            } else {
              otherParam += `&${key}=${value}`
            }
          }
        })
      }
      return this.currentTemplate && `${fileTemplateUreportApi.preview}?_t=${this.templateExportStr}&_u=${this.currentTemplate?.templateFile}${otherParam}`
    }
  },

  watch: {
    templateParams: {
      handler (val) {
        this.init()
        this.getTemplateList(val)
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    /**
     * 初始化
     */
    init () {
      this.templateLoad = true
      this.templateList.splice(0, this.templateList.length)
      this.curIdx = 1
    },

    /**
     *  获取模板列表
     * @param param 查询信息
     */
    getTemplateList (param) {
      fileTemplateUreportApi.list(Object.assign({ page: false }, param)).then(res => {
        if (res.code === 0) {
          this.templateList.splice(0, this.templateList.length, ...(res.data || []))
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.templateLoad = false
      })
    }
  }
}
</script>

<style lang="less" scoped>

.utabs {
  height: 100%;

  /deep/ .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }

  .no-data {
    font-size: 24px;
    font-weight: 600;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    color: #B82727;
  }

  .archive {
    margin: 0 5px;
    border: 1px solid #91D5FF;
    background-color: #E6F7FF;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
    padding-left: 8px;
  }

  /deep/ .ant-tabs-bar {
    background: #FFFFFF;
  }
}
</style>
