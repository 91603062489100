<template>
  <div class="password-sample-form">

    <a-card v-bind="sampleCard">
      <template #extra>
        <a-button :style="{marginRight:'10px'}" @click="handlePasswordParallel" type="primary">密码平行</a-button>
        <a-button @click="handlePasswordStandard" type="primary">密码标样</a-button>
      </template>
      <sample-info-table @selectedRows="handleSelectRowsChange" v-bind="sampleInfoData" :type="sampleInfoTypeEnum.normal"></sample-info-table>
    </a-card>
    <div>

    </div>

    <a-card v-bind="passwordParallelCard" v-if="passwordParallelList.length">
      <ul>
        <li :key="index" class="sample-item" v-for="(item,index) in passwordParallelList">
          <a-row :gutter="16">
            <a-col :span="8">{{ item.id }}</a-col>
            <a-col :span="14">
              <span class="one-row">
                <a-tooltip>
                  <template #title>
                    {{ item.itemNameStr }}
                  </template>
                  {{ item.itemNameStr }}
                </a-tooltip>
              </span>
            </a-col>
            <a-col :span="2">
              <a-tooltip>
                <template #title>点我移除该样品</template>
                <a-icon @click="handleRemoveSample(passwordParallelList,index)" type="close-circle"/>
              </a-tooltip>
            </a-col>
          </a-row>
        </li>
      </ul>
    </a-card>

    <a-card v-bind="passwordStandardCard" v-if="passwordStandardList.length">
      <ul>
        <li :key="index" class="sample-item" v-for="(item,index) in passwordStandardList">
          <a-row :gutter="16">
            <a-col :span="8">{{ item.id }}</a-col>
            <a-col :span="14">
              <span class="one-row">
                <a-tooltip>
                  <template #title>
                    {{ item.itemNameStr }}
                  </template>
                  {{ item.itemNameStr }}
                </a-tooltip>
              </span>
            </a-col>
            <a-col :span="2">
              <a-tooltip>
                <template #title>点我移除该样品</template>
                <a-icon @click="handleRemoveSample(passwordParallelList,index)" type="close-circle"/>
              </a-tooltip>
            </a-col>
          </a-row>
        </li>
      </ul>
    </a-card>

  </div>
</template>

<script>
import { unionBy } from 'lodash'

import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { tsSampleinfoApi } from '@/api/sample'
import { qualityControlApi } from '@/api/quality/qualityControlApi'
import { passwordSampleControlColumn } from '@/pages/cloud-quote/sample-info/sampleTypeColumn'

import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'

export default {
  components: { SampleInfoTable },
  props: {
    projId: {
      type: Number,
      required: true
    },

    refId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {

      sampleInfoTypeEnum,

      sampleInfoList: [],

      passwordStandardList: [],

      passwordParallelList: [],

      selectedRows: [],

      passwordStandardCard: {
        title: '密码标样',
        type: 'inner',
        style: { marginTop: '16px' }
      },

      sampleCard: {
        title: '已采集的样品',
        type: 'inner'
      },

      passwordParallelCard: {
        title: '密码平行',
        type: 'inner',
        style: { marginTop: '16px' }
      },

      sampleInfoData: {
        projId: this.projId,
        pagination: false,
        columns: passwordSampleControlColumn
      }
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (val) {
        // val && this.findSampleInfo(val)
      }

    }
  },

  methods: {
    findSampleInfo (projId) {
      tsSampleinfoApi.list({ projId, page: false }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.sampleInfoList.splice(0, this.sampleInfoList.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handleSelectRowsChange (data) {
      this.selectedRows.splice(0, this.selectedRows.length, ...data)
    },

    checkSelectRow () {
      if (!this.selectedRows.length) {
        this.$message.error('请先勾选你要添加的样品！')
        return false
      }
      return true
    },

    handlePasswordStandard () {
      if (this.checkSelectRow()) {
        this.passwordStandardList.splice(0, this.passwordStandardList.length, ...unionBy(this.passwordStandardList, this.selectedRows, 'id'))
      }
    },

    handlePasswordParallel () {
      if (this.checkSelectRow()) {
        this.passwordParallelList.splice(0, this.passwordParallelList.length, ...unionBy(this.passwordParallelList, this.selectedRows, 'id'))
      }
    },

    handleRemoveSample (data, index) {
      data.splice(index, 1)
    },

    save () {
      if (!this.passwordParallelList.length && !this.passwordStandardList.length) {
        this.$message.error('您还未进行密码样的配置')
        return false
      }

      const data = { passwordParallels: this.passwordParallelList, passwordStandards: this.passwordStandardList, id: this.refId }

      qualityControlApi.savePasswordSample(data).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "_index";
</style>
