/**
 * 新增修改完成调用 modalFormOk方法 编辑弹框组件ref定义为modalForm
 * 高级查询按钮调用 superQuery方法  高级查询组件ref定义为superQueryModal
 * data中url定义 list为查询列表  delete为删除单条记录  deleteBatch为批量删除
 */
import { filterObj } from '@/utils/util'
import { delAction, getAction } from '@/api/common'
import Vue from 'vue'
import { ACCESS_TOKEN, DEFAULT_PAGE_SIZE } from '@/store/mutation-types'

export const ListMixin = {
  data () {
    return {
      // token header
      tokenHeader: { 'Access-Token': Vue.ls.get(ACCESS_TOKEN) },
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {},
      /* 数据源 */
      dataSource: [],
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: Vue.ls.get(DEFAULT_PAGE_SIZE),
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      /* 排序参数 */
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      /* 筛选参数 */
      filters: {},
      /* table加载状态 */
      loading: false,
      /* table选中keys */
      selectedRowKeys: [],
      /* table选中records */
      selectionRows: [],
      /* 查询折叠 */
      toggleSearchStatus: false,
      /* 高级查询条件生效状态 */
      superQueryFlag: false
    }
  },
  created () {
    this.loadData()
    // 初始化字典配置 在自己页面定义
    this.initDictConfig()
  },
  methods: {
    loadData (arg) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      const params = this.getQueryParams()// 查询条件
      this.loading = true
      getAction(this.url.list, params).then((res) => {
        // debugger
        this.dataSource = res.data
        this.ipagination.total = res.totalCount
        this.ipagination.current = res.pageNo
        this.ipagination.pageSize = res.pageSize
        this.loading = false
      })
    },
    initDictConfig () {
      // console.log('initDictConfig')
    },
    getQueryParams () {
      const param = JSON.parse(JSON.stringify(Object.assign(this.queryParam, this.isorter, this.filters)))
      for (var item in param) {
        if (typeof (param[item]) === 'string') {
          param[item] = param[item].trim()
        }
      }
      param.pageNo = this.ipagination.current
      param.pageSize = this.ipagination.pageSize
      return filterObj(param)
    },
    onSelectChange (selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
    },
    onClearSelected () {
      this.selectedRowKeys = []
      this.selectionRows = []
    },
    searchQuery () {
      this.loadData(1)
    },
    searchReset () {
      this.queryParam = {}
      this.loadData(1)
    },
    batchDel: function () {
      if (!this.url.delete) {
        this.$message.error('请设置url.delete!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
      } else {
        const that = this
        this.$confirm({
          title: '确认作废',
          content: '是否作废选中数据?',
          onOk: function () {
            delAction(that.url.delete, { ids: that.selectedRowKeys }).then((result) => {
              if (result.code === 0) {
                that.$message.success(result.msg)
                that.loadData()
                that.onClearSelected()
              } else {
                that.$message.warning(result.msg)
              }
            })
          }
        })
      }
    },
    handleDelete: function (id) {
      if (!this.url.delete) {
        this.$message.error('请设置url.delete!')
        return
      }
      const that = this
      // const ids = [ id ]
      delAction(that.url.delete, { ids: [id] }).then((result) => {
        if (result.code === 0) {
          that.$message.success(result.msg)
          that.loadData()
        } else {
          that.$message.warning(result.msg)
        }
      })
    },
    handleEdit: function (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleAdd: function () {
      this.$refs.modalForm.add()
      this.$refs.modalForm.title = '新增'
    },
    handleTableChange (pagination, filters, sorter) {
      // 分页、排序、筛选变化时触发
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = sorter.order === 'ascend' ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    handleToggleSearch () {
      this.toggleSearchStatus = !this.toggleSearchStatus
    },
    modalFormOk () {
      // 新增/修改 成功时，重载列表
      this.loadData()
    },
    handleDetail: function (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '详情'
      this.$refs.modalForm.disableSubmit = true
    }
  }

}
