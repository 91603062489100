<template>
  <div class="check-result-project-detail">
    <a-skeleton :active="true" :loading="loading"/>
    <a-collapse v-if="!loading">
      <a-collapse-panel v-for="(site,siteId) of groupCheckSampleItemBySite(siteCheckSampleItemList)" :key="`site_${siteId}`">
        <template #header>{{ site.siteName }}</template>
        <div class="wrapper">
          <div v-for="(period,numPeriod) of groupCheckSampleItemByNumPeriod(site.details)" :key="`site_period_${numPeriod}`" class="period">
            <div class="period-title">第【{{ numPeriod }}】天</div>
            <div class="period-content">
              <div v-for="(frequency,numFrequency) of groupCheckSampleItemByNumFrequency(period.details)" :key="`site_period_frequency_${numFrequency}`" class="frequency">
                <div class="frequency-title">D{{ numPeriod }}-{{ numFrequency }}</div>
                <div class="frequency-detail">
                  <project-detail-sample-detail v-for="(sample,sampleId) of groupCheckSampleItemBySampleId(frequency.details,true,true)" :key="`site_period_frequency_sample_${sampleId}`" :sample="sample"></project-detail-sample-detail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-collapse-panel>

      <a-collapse-panel v-if="blankCheckSampleItemList.length">
        <template #header>空白样</template>
        <div class="wrapper">
          <div v-for="(sampleTypeInfo,sampleType) of groupCheckSampleItemBySampleType(blankCheckSampleItemList,true,true)" :key="`blank_${sampleType}`" class="period">
            <div class="period-title">{{ sampleInfoTypeDataMap[sampleType].label }}</div>
            <div class="period-content">
              <project-detail-sample-detail v-for="(sample,sampleIndex) in groupCheckSampleItemBySampleId(sampleTypeInfo.details,true,true)" :key="`blank_sample_${sampleIndex}`" :sample="sample"></project-detail-sample-detail>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>

    <!--    <a-card v-bind="sampleCard">

    </a-card>
    <a-card v-bind="itemCard">

    </a-card>-->
  </div>
</template>

<script>
import { checkSampleItemApi } from '@/api/check/checkTaskApi'
import { checkTaskStatusEnum } from '@/api/check/checkTaskConstant'
import { checkTaskResultDataStatusEnum } from '@/api/check/checkTaskResultConstant'
import { needLabTypeEnum, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import ProjectDetailSampleDetail from '@/pages/cloud-quote/check-task/modules/checkResult/ProjectDetailSampleDetail'
import { groupBy } from 'lodash'

export default {
  props: { projId: { type: Number, required: true } },

  components: { ProjectDetailSampleDetail },

  data () {
    return {
      checkTaskStatusEnum,
      checkTaskResultDataStatusEnum,
      sampleInfoTypeDataMap,

      checkSampleItemList: [],

      loading: true,

      sampleCard: {
        title: '按检测因子'
      },

      itemCard: {
        title: '按因子'
      }
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (newValue, oldValue) {
        newValue && this.initData(newValue)
      }

    }
  },

  computed: {
    resultDataType () {
      return { ...this.$store.state.code.cache.check_result_data_type }
    },

    siteCheckSampleItemList () {
      const blankSampleTypes = [sampleInfoTypeEnum.allBlank, sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank]
      return this.checkSampleItemList.filter(item => !blankSampleTypes.includes(item.sampleType))
    },

    blankCheckSampleItemList () {
      const blankSampleTypes = [sampleInfoTypeEnum.allBlank, sampleInfoTypeEnum.transportBlank, sampleInfoTypeEnum.fieldBlank]
      return this.checkSampleItemList.filter(item => blankSampleTypes.includes(item.sampleType))
    }
  },

  methods: {
    // 初始化数据
    initData (projId) {
      this.loading = true
      this.querySampleItemById(projId).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.checkSampleItemList.splice(0, this.checkSampleItemList.length, ...(res.data || []))
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询项目对应的检测结果信息
    querySampleItemById (projId) {
      return checkSampleItemApi.list({ projId, sampleTypes: needLabTypeEnum, sampleTemp: false, page: false }).then(res => res)
    },

    // 检测因子按照点位进行分组
    groupCheckSampleItemBySite (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.siteId] || (acc[sample.siteId] = { siteId: sample.siteId, siteName: sample.siteName, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 检测因子按照天数进行分组
    groupCheckSampleItemByNumPeriod (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.numPeriod] || (acc[sample.numPeriod] = { numPeriod: sample.numPeriod, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 检测因子按照频次进行分组
    groupCheckSampleItemByNumFrequency (list) {
      return list.reduce((acc, sample) => {
        (acc[sample.numFrequency] || (acc[sample.numFrequency] = { numFrequency: sample.numFrequency, details: [] })).details.push(sample)
        return acc
      }, {})
    },

    // 检测因子按照样品ID进行分组
    groupCheckSampleItemBySampleId (list, taskStatusGroup = false, dataStatusGroup = false) {
      const result = list.reduce((acc, sample) => {
        (acc[sample.sampleId] || (acc[sample.sampleId] = { sampleId: sample.sampleId, sampleType: sample.sampleType, details: [] })).details.push(sample)
        return acc
      }, {})
      if (taskStatusGroup) {
        Object.values(result).forEach(item => {
          item.taskStatusGroup = groupBy(item.details, 'orderStatus')
        })
      }
      if (dataStatusGroup) {
        Object.values(result).forEach(item => {
          item.dataStatusGroup = groupBy(item.details, 'dataStatus')
        })
      }
      return result
    },

    // 检测因子按照分类进行分组
    groupCheckSampleItemBySampleType (list) {
      const result = list.reduce((acc, sample) => {
        (acc[sample.sampleType] || (acc[sample.sampleType] = { sampleType: sample.sampleType, details: [] })).details.push(sample)
        return acc
      }, {})
      return result
    }
  }
}
</script>

<style lang="less" scoped>
@import "detail";
</style>
