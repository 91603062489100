<template>
  <div class="check-reagent-weighing-list">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="ID">
              <a-input v-model.trim.lazy="queryParam.id" placeholder="ID查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="ID">
              <a-input v-model.trim.lazy="queryParam.materialId" placeholder="编号查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :style="{ textAlign: 'left',marginBottom:'15px' }">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
            <!--            <a style="margin-left: 8px" @click="toggleSearchStatus = !toggleSearchStatus">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
            </a>-->
          </a-col>
        </a-row>

      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard"></a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <check-reagent-weighing-table v-bind="tableProps"/>
    </a-card>

  </div>
</template>

<script>
import { withComponentList } from '@/pages/common/mixins/list'

import { CheckReagentWeighingTable } from '@/pages/cloud-quote/check-reagent/weighing/modules'
import { checkReagentWeighingDefaultColumn as defaultColumn } from '@/pages/cloud-quote/check-reagent/weighing/modules/table/columns'

export default {
  mixins: [withComponentList],
  components: { CheckReagentWeighingTable },

  props: {
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      queryParam: {
        id: null,
        materialId: null
      },

      contentCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: {},
        class: 'card-table'
      }
    }
  },
  computed: {
    tableProps () {
      const { rowSelect, showPagination, columns } = this
      return Object.assign({ rowSelect, showPagination, columns }, this.$attrs, this.customTable)
    }
  },

  methods: {

  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";

.check-reagent-weighting-list {

}
</style>
