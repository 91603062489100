/**
 * 检测管理相关的API维护
 */
import { delAction, getAction, postAction, postDataAction, postRepeatAction } from './common'
import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'
import { Get } from '@/api/quote'

const tsTaskPre = '/ms-api/ts-task'
const tsFeedBackPre = '/ms-api/ts-feedback'
const tsSampleInfoPre = '/ms-api/ts-sampleinfo'
const tsSampleInfoStatPre = '/ms-api/ts-sampleinfo-stat'
const tsSampleItem = '/ms-api/ts-sampleitem'
const checkTask = '/ms-api/check-task'
const checkOrder = '/ms-api/check-order'
const checkTaskResult = '/ms-api/check-task/result'
const checkApproval = '/ms-api/check-task/approval'
const checkTaskApproval = '/ms-api/check-approval'
const reportTask = '/ms-api/report-task'
const reportSend = '/ms-api/report-send'
const reportTaskTemplate = '/ms-api/report-task-template'
const reportDownloadLog = '/ms-api/report-download-log'
const fileSignature = '/ms-api/file-signature'
const tsProblem = '/ms-api/ts-problem'
const scheduleResourcePre = '/ms-api/schedule-res'
const scheduleResourceTypePre = '/ms-api/schedule-res-type'
const tsLog = '/ms-api/ts-log'
const pointLayoutPre = '/ms-api/proj-point-layout'

export const tsTaskApi = {
  schedule: (data) => postDataAction(`${tsTaskPre}/schedule`, data),
  batchSchedule: (data) => postDataAction(`${tsTaskPre}/batch-schedule`, data),
  start: (data) => postDataAction(`${tsTaskPre}/start`, data),
  batchStart: (data) => postDataAction(`${tsTaskPre}/batch-start`, data),
  offlineDone: (data) => postDataAction(`${tsTaskPre}/offline-done`, data),
  done: (data) => postDataAction(`${tsTaskPre}/done`, data),
  update: (data) => postDataAction(`${tsTaskPre}/update`, data),
  list: (param) => getAction(`${tsTaskPre}/list`, param),
  taskListAll: (param) => postDataAction(`${tsTaskPre}/taskListAll`, param),
  taskListAllNoPage: (param) => postDataAction(`${tsTaskPre}/taskListAllNoPage`, param),
  statByStatus: (param) => getAction(`${tsTaskPre}/status-stat`, param),
  statBySiteCompleteStatus: (param) => getAction(`${tsTaskPre}/site-complete-status-stat`, param),
  findOne: (param) => getAction(`${tsTaskPre}/find-one`, param),
  findItemCascader: (data) => postDataAction(`${tsTaskPre}/item/cascader`, data),
  queryResource: (data) => postDataAction(`${tsTaskPre}/resource`, data),
  downloadSampleRecord: '/ms-api/proj-download/downloadSampleRecord',
  downloadSampleHandover: '/ms-api/proj-download/downloadSampleHandoverNew',
  downloadSampleRecordByUreport: '/ms-api/ureport/preview',
  downloadSampleRecordByUreport2: (param) => getAction(`/ms-api/ureport/preview`, param),
  regenerateSample: (param) => getAction(`${tsTaskPre}/regenerate-sample-info`, param),
  aotureprot: (param) => getAction(`${tsTaskPre}/aotu-reprot`, param),
  returnBack: (param) => getAction(`${tsTaskPre}/returnBack`, param),
}
export const sampleMediumApplyApi = {
  add: '/ms-api/res_sample_medium_apply_record/add',
  list: '/ms-api/res_sample_medium_apply_record/list',
  getApplyAllInfo:(id) => getAction(`/ms-api/res_sample_medium_apply_record/getApplyAllInfo/${id}`),
  save: (data) => postDataAction('/ms-api/proj_sample_medium_record/save', data),
}
export const tsProblemApi = {
  list: (param) => getAction(`${tsProblem}/list`, param),
  count: (param) => getAction(`${tsProblem}/count`, param),
  save: (data) => postDataAction(`${tsProblem}/save`, JSON.stringify(data)),
  deal: (data) => postDataAction(`${tsProblem}/deal`, JSON.stringify(data))
}

export const tsTaskPersonApi = {
  find: (param) => postDataAction(`${tsTaskPre}/person/select`, JSON.stringify(param)),
  list: (param) => getAction(`${tsTaskPre}/person/list`, param),
  personTaskList: (param) => getAction(`${tsTaskPre}/person/person-task-list`, param),
  queryOptionalPersonList: (param) => getAction(`${tsTaskPre}/person/optional-list`, param),
  detail: (data) => postDataAction(`${tsTaskPre}/person/detail`, JSON.stringify(data))
}

export const tsTaskCarApi = {
  detail: (param) => postDataAction(`${tsTaskPre}/car/detail`, JSON.stringify(param)),
  queryOptionalCarList: (param) => getAction(`${tsTaskPre}/car/optional-list`, param),
  queryCarTaskList: (param) => getAction(`${tsTaskPre}/car/car-task-list`, param)
}

export const tsTaskFeedBackApi = {
  save: (data) => postDataAction(`${tsFeedBackPre}/save`, data),
  commonSave: (data) => postDataAction(`${tsFeedBackPre}/commonSave`, data),
  list: (param) => getAction(`${tsFeedBackPre}/list`, param),
  findDetail: (param) => getAction(`${tsFeedBackPre}/findDetail`, param),
  listByProjId: (param) => getAction(`${tsFeedBackPre}/listFeedbackByProjId`, param)
}

export const tsSampleinfoApi = {
  list: (param) => getAction(`${tsSampleInfoPre}/list`, param),
  statCount: (param) => getAction(`${tsSampleInfoPre}/stat-count`, param),
  postList: (param) => postRepeatAction(`${tsSampleInfoPre}/list`, param),
  projectList: (param) => getAction(`${tsSampleInfoPre}/project-list`, param),
  save: (data) => postDataAction(`${tsSampleInfoPre}/save`, JSON.stringify(data)),
  batchSave: (data) => postDataAction(`${tsSampleInfoPre}/batch-save`, JSON.stringify(data)),
  batchReceive: (data) => postDataAction(`${tsSampleInfoPre}/batch-receive`, JSON.stringify(data)),
  destroy: (data) => postDataAction(`${tsSampleInfoPre}/destroy`, JSON.stringify(data)),
  cancelCheck: (data) => postDataAction(`${tsSampleInfoPre}/cancelCheck`, JSON.stringify(data)),
  outbound: (data) => postDataAction(`${tsSampleInfoPre}/outbound`, JSON.stringify(data)),
  reserved: (data) => postDataAction(`${tsSampleInfoPre}/reserved`, JSON.stringify(data)),
  storage: (data) => postDataAction(`${tsSampleInfoPre}/storage`, JSON.stringify(data)),
  changeLocation: (data) => postDataAction(`${tsSampleInfoPre}/changeLocation`, JSON.stringify(data)),
  storageApply: (data) => postDataAction(`${tsSampleInfoPre}/storageApply`, JSON.stringify(data)),
  applyOutbound: (data) => postDataAction(`${tsSampleInfoPre}/applyOutbound`, JSON.stringify(data)),
  statusStat: (param) => getAction(`${tsSampleInfoStatPre}/status-stat`, param),
  sourceStat: (param) => getAction(`${tsSampleInfoStatPre}/source-stat`, param),
  sampledStatusStat: (param) => getAction(`${tsSampleInfoStatPre}/sampled-status-stat`, param),
  siteStat: (param) => getAction(`${tsSampleInfoStatPre}/site-stat`, param),
  cateStat: (param) => getAction(`${tsSampleInfoStatPre}/cate-stat`, param),
  periodStat: (param) => getAction(`${tsSampleInfoStatPre}/period-stat`, param),
  baseWholeStat: (param) => getAction(`${tsSampleInfoStatPre}/base-whole-stat`, param),
  itemTypeStat: (param) => getAction(`${tsSampleInfoStatPre}/item-type-stat`, param),
  findOne: (param) => getAction(`${tsSampleInfoPre}/findOne`, param),
  getItemAliasMap: () => getAction(`${tsSampleInfoPre}/item-alias-map`),
  getSampleDay: (param) => getAction(`${tsSampleInfoPre}/get-sample-day`, param),
  getSampleDayByCategory: (param) => getAction(`${tsSampleInfoPre}/get-sample-day-by-category`, param),
  deleteById: (id) => delAction(`${tsSampleInfoPre}/deleteById/${id}`),
  listReceiveSampleProject: (param) => getAction(`${tsSampleInfoPre}/list-receive-sample-project`, param)
}

export const tsSampleItemApi = {
  list: (param) => getAction(`${tsSampleItem}/list`, param),
  listBatch: (param) => postDataAction(`${tsSampleItem}/list-batch`, param)
}

export const checkTaskPersonApi = {
  find: (param) => postDataAction(`${checkTask}/person/select`, JSON.stringify(param))
}

export const checkTaskResultApi = {
  save: (data) => postDataAction(`${checkTaskResult}/save`, data),
  void: (data) => postDataAction(`${checkTaskResult}/void`, data),
  batchSave: (data) => postDataAction(`${checkTaskResult}/batch-save`, data),
  list: (param) => getAction(`${checkTaskResult}/list`, param),
  listAll: (param) => getAction(`${checkTaskResult}/list-all`, param),
  existData: (param) => getAction(`${checkTaskResult}/exist-data`, param),
  getById: (id) => getAction(`${checkTaskResult}/getById`, { id }),
  requestApproval: (param) => postDataAction(`${checkTaskResult}/requestApproval`, param),
  saveAllData: (data) => postDataAction(`${checkTaskResult}/save-all-data`, data),
  submitApproval: (data) => postDataAction(`${checkTaskResult}/submit-approval`, data),
  batchSubmitApproval: (data) => postDataAction(`${checkTaskResult}/batch-submit-approval`, data)
}

export const checkOrderApi = {
  list: (param) => postRepeatAction(`${checkOrder}/list`, param),
  projectList: (param) => getAction(`${checkOrder}/project-list`, param),
  projectStat: (param) => getAction(`${checkOrder}/project-stat`, param),
  save: (data) => postDataAction(`${checkTask}/save`, JSON.stringify(data)),
  saveAndApply: (data) => postDataAction(`${checkTask}/saveAndApply`, JSON.stringify(data)),
  detail: (param) => postDataAction(`${checkTask}/person/detail`, JSON.stringify(param))
}

export const checkApprovalApi = {
  list: (param) => getAction(`${checkApproval}/list`, param),
  save: (data) => postDataAction(`${checkTaskApproval}/save`, data)
}

export const reportTaskApi = {
  list: (param) => getAction(`${reportTask}/list`, param),
  save: (data) => postDataAction(`${reportTask}/save`, JSON.stringify(data)),
  start: (data) => postDataAction(`${reportTask}/start`, JSON.stringify(data)),
  done: (data) => postDataAction(`${reportTask}/done`, JSON.stringify(data)),
  check: (data) => postDataAction(`${reportTask}/check`, JSON.stringify(data)),
  approval: (data) => postDataAction(`${reportTask}/approval`, JSON.stringify(data)),
  getOne: (param) => getAction(`${reportTask}/getOne`, param),
  changeReportUser: (data) => postDataAction(`${reportTask}/change-report-user`, JSON.stringify(data)),
  coordination: (data) => postDataAction(`${reportTask}/coordination-apply`, JSON.stringify(data)),
  findCoordinationOne: (param) => getAction(`${reportTask}/findCoordinationOne`, param),
  getFileAppendixByTaskId: (param) => getAction(`${reportTask}/report-file-appendix`, param),
  download: `/ms-api/proj-download/downloadReport`,
  downLoadReport: `${reportTask}/download-report/`, // 盖章及水印接口
  generate: '/ureport/preview?_u=DB:R01检测报告.ureport.xml',
  getChangeUserInfo: (param) => getAction(`${reportTask}/change-user-info`, param),
  getUndoneProcessByTaskId: (param) => getAction(`${reportTask}/get-undone-process-by-taskid`, param)
}

export const reportOrderApi = {
  list: (param) => getAction(`${reportTask}/order/list`, param),
  listTemplate: (param) => getAction(`${reportTask}/order/list-template`, param),
  statusStat: (param) => getAction(`${reportTask}/order/status-stat`, param)
}

export const reportTaskPersonApi = {
  find: (param) => postDataAction(`${reportTask}/person/select`, JSON.stringify(param))
}

export const reportSendOrderApi = {
  list: (param) => getAction(`${reportSend}/order/list`, param),
  findReportOrder: (param) => getAction(`${reportSend}/order/find-report-order`, param),
  findOne: (param) => getAction(`${reportSend}/findOne`, param),
  mail: (data) => postDataAction(`${reportSend}/mail`, JSON.stringify(data)),
  send: (data) => postDataAction(`${reportSend}/send`, JSON.stringify(data)),
  sendApply: (data) => postDataAction(`${reportSend}/send-apply`, JSON.stringify(data)),
  saveByFlow: (data) => postDataAction(`${reportSend}/save-by-flow`, JSON.stringify(data)),
  recoveryApply: (data) => postDataAction(`${reportSend}/recovery-apply`, JSON.stringify(data)),
  findRecoveryOne: (param) => getAction(`${reportSend}/find-recovery-one`, param),
  findRecoveryOneStatus: (param) => getAction(`${reportSend}/find-recovery-one-status`, param),
  statTableHeader: (param) => getAction(`${reportSend}/order/statTableHeader`, param),
  findContract: (param) => getAction(`${reportSend}/order/find-contract`, param)
}

export const reportTaskTemplateApi = {
  list: (param) => getAction(`${reportTaskTemplate}/list`, param),
  save: (param) => postDataAction(`${reportTaskTemplate}/save`, param),
  delete: (id) => postAction(`${reportTaskTemplate}/delete/${id}`),
  done: (data) => postDataAction(`${reportTaskTemplate}/done`, JSON.stringify(data)),
  deleteFile: (param) => postAction(`${reportTaskTemplate}/delete-file`, param),
  computeMeetingValues: (param) => getAction(`${reportTaskTemplate}/computeMeetingValues`, param)
}

export const reportDownloadLogApi = {
  list: (param) => getAction(`${reportDownloadLog}/list`, param),
  save: (param) => postDataAction(`${reportDownloadLog}/save`, param),
  count: (param) => getAction(`${reportDownloadLog}/count`, param)
}

export const fileSignatureApi = {
  list: (param) => getAction(`${fileSignature}/list`, param),
  // save: (param) => postDataAction(`${reportDownloadLog}/save`, param),
  findOne: (param) => getAction(`${fileSignature}/findOne`, param),
  signatureApply: (data) => postDataAction(`${fileSignature}/signature-apply`, JSON.stringify(data))
}

export const scheduleResourceApi = {
  list: (param) => getAction(`${scheduleResourcePre}/list`, param),
  reuse: (data) => postDataAction(`${scheduleResourcePre}/reuse`, data)
}

export const scheduleResourceTypeApi = {
  list: (param) => getAction(`${scheduleResourceTypePre}/list`, param),
  initResourceType: (data) => postDataAction(`${scheduleResourceTypePre}/resource`, data)
}

export const tsLogApi = {
  list: (param) => getAction(`${tsLog}/list`, param)
}

export const pointLayoutApi = {
  listByProjId: (param) => getAction(`${pointLayoutPre}/list-by-proj-id`, param),
  save: (data) => postDataAction(`${pointLayoutPre}/save`, JSON.stringify(data)),
  listLocationMap: (param) => getAction(`${pointLayoutPre}/list-location-map`, param)
}

/**
 * 新增批次时复合批次相关的接口
 */
export const tsSampleitemApi = {
  selectSameSampleInfoAndItem: (param) => Get(`/ms-api/ts-sampleitem/selectSameSampleInfoAndItem`, param), // 查询新增批次选中的样品关联的因子和方法管理中方法关联的因子的重合项
  selectItemBySample: (param) => Get(`/ms-api/ts-sampleitem/selectItemBySample`, param) // 查询新增批次选中的样品关联的因子
}

// 可安排的项目
export const taskStatusMap = {
  pending: '0', // 待安排
  scheduled: '1', // 已安排
  processing: '2', // 进行中
  feedbackd: '3', // 已反馈
  done: '4', // 已完成
  scheduledAble: ['0', '1'], // 可安排
  reScheduleAble: ['1', '2', '3'], // 可重新安排
  appendAble: ['1', '2'], // 可追加的
  feedbackAble: [],
  downloadAble: ['3', '4'],
  viewAble: ['3', '4'],
  started: ['2', '3', '4'],
  sampleRecordViewable: ['1', '2', '3', '4']
}

export const taskStatusOptions = [
  { label: '待安排', value: taskStatusMap.pending, color: 'purple' },
  { label: '已安排', value: taskStatusMap.scheduled, color: '#FF9600' },
  { label: '进行中', value: taskStatusMap.processing, color: 'orange' },
  { label: '已反馈', value: taskStatusMap.feedbackd, color: 'blue' },
  { label: '已完成', value: taskStatusMap.done, color: 'green' }
]

export const taskStatusOptionsMap = tranOptionsToMap(taskStatusOptions)
export const taskStatusOptionsDataMap = tranOptionsToDataMap(taskStatusOptions)

export const checkApprovalDataStatusOptions = [
  { label: '待审', value: '-1' },
  { label: '数据存疑', value: '0' },
  { label: '部分通过', value: '1' },
  { label: '审查完成', value: '2' }
]

export const checkApprovalSampleStatusOptions = [
  { label: '未开始', value: '0' },
  { label: '进行中', value: '1' },
  { label: '已完成', value: '2' }
]

export const tsLogTypeOptions = [
  { label: '安排', value: '1' },
  { label: '重排', value: '2' },
  { label: '开始', value: '3' },
  { label: '反馈', value: '4' },
  { label: '完成', value: '5' },
  { label: '反馈问题', value: '6' },
  { label: '处理问题', value: '7' }
]

export const tsProblemStatusOptions = [
  { label: '待处理', value: '0' },
  { label: '已读', value: '1' },
  { label: '已处理', value: '2' }
]
