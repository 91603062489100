<template>
  <div class="table">
    <s-table
      v-bind="contentTable"
      :rowSelection="customRowSelection"
      :scroll="scrollProp"
      v-on="contentTable.action">
      <template #weighNum="item,record">
        <a-tag color="blue">{{ item }}{{ record.unit }}</a-tag>
      </template>
      <template v-for="(_, slot) of $scopedSlots" #[slot]="text,record,index,expanded">
        <slot v-bind="{text:text,row:record,index:index,expanded}" :name="slot"/>
      </template>
    </s-table>
  </div>

</template>

<script>
import sTable from '@/components/Table'
import mixinList from '@/pages/common/mixins/list'
import { checkReagentWeighingDefaultColumn as defaultColumn } from './columns'
import { checkReagentWeighingApi } from '@/api/checkReagent/checkReagentApi'

export default {
  components: { sTable },
  mixins: [mixinList],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: { type: Array, required: false, default: () => { return [] } },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: { type: Array, required: false, default: () => { return defaultColumn } },
    rowSelect: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {}
  },

  computed: {
    queryParam () {
      return { ...this.$attrs }
    },

    customRowSelection () {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {
    // 加载数据
    loadData (param) {
      if (this.isSetData) {
        if (this.showPagination) {
          const { pageNo, pageSize } = param
          const dataSource = this.dataSource
          const totalCount = dataSource.length || 0
          const totalPage = Math.ceil(totalCount / pageSize)
          const startIdx = (pageNo - 1) * pageSize
          const endIdx = pageNo * pageSize
          const data = dataSource.slice(startIdx, endIdx)
          return new Promise((resolve, reject) => {
            const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
            resolve(resData)
          }).then(res => res)
        } else {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
            resolve(resData)
          }).then(res => res)
        }
      } else {
        return checkReagentWeighingApi.list(Object.assign({}, param, this.queryParam)).then(res => res)
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '_index';
</style>
