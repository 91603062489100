<script>
export default {
  name: 'SampleListView',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
</script>
<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import UlTable from '@/pages/common/ul-table/Table'
import SampleInfoRowView from '@/pages/cloud-quote/check-task/modules/schedule/moudles/sample-list/Row.vue'

const props = defineProps({
  modelValue: { type: Array, required: true, default: () => [] },
  removeIds: { type: Array, required: false, default: () => [] },
  firstFlag: { type: Number, require: false, default: 0 }
})

const emit = defineEmits(['update:modelValue'])

const sampleInfoList = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})

const removeIds = computed({
  get() {
    return props.removeIds
  },
  set(value) {
    emit('update:removeIds', value)
  }
})

const removeSample = (sampleInfo) => {
  let removeInfoList = sampleInfoList.value.splice(
    sampleInfoList.value.findIndex((item) => item.id === sampleInfo.id),
    1
  )
  let ids = removeInfoList.map((item) => item.id)
  removeIds.value.push(...ids)
}
</script>

<template>
  <transition-group enter-active-class="animate__animated animate__bounceInUp" leave-active-class="animate__animated animate__bounceOutDown">
    <ul-table
      v-if="sampleInfoList.length"
      :key="`sampleInfo_list_${sampleInfoList.length}`"
      :data-source="sampleInfoList"
      :hidden-title="true"
      :row-props="{ leaveActiveClass: 'animate__animated animate__bounceOutRight' }"
      class="hidden"
      row-component="transition"
    >
      <template #default="{ data }">
        <sample-info-row-view :data="data" @remove="removeSample" :firstFlag="props.firstFlag" />
      </template>
    </ul-table>
  </transition-group>
</template>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet.less';

.color();
.margin();
.float();

.hidden {
  ::v-deep(.content-row) {
    border: none;
  }
}
</style>
