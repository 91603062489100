/**
 * 质量控制样品相关的API维护
 */
import { getAction } from '@/api/common'

const qualityControlSamplePre = '/ms-api/quality-control/sample'

export const qualityControlSampleApi = {
  list: (param) => getAction(`${qualityControlSamplePre}/list`, param),
  queryDetails: (param) => getAction(`${qualityControlSamplePre}/details`, param),
  qeuryQualityByProId: (param) => getAction(`${qualityControlSamplePre}/qeuryQualityByProId`, param)
}
