<script>
import VcInputNumber from 'ant-design-vue/es/vc-input-number/src/index'
import { Icon, InputNumber } from 'ant-design-vue'
import { getOptionProps, hasProp } from 'ant-design-vue/es/_util/props-util'
import classNames from 'classnames'

const isValidProps = value => value !== undefined && value !== null
const numberReg = /^[-+]?([1-9]\d*(\.\d*)*|0\.\d*)$/
const isNumber = (numStr) => {
  return numberReg.test(numStr)
}

const MyVcInputNumber = {
  extends: VcInputNumber,
  methods: {
    getValidValue: function getValidValue (value) {
      const min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.min
      const max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.max

      let val = parseFloat(value, 10)
      if (isNaN(val)) {
        return value
      }
      if (val < min) {
        val = min
      }
      if (val > max) {
        val = max
      }
      return !isNumber(value) ? val : value
    },

    setValue: function setValue (v, callback) {
      const newValue = this.isNotCompleteNumber(parseFloat(v, 10)) ? undefined : parseFloat(v, 10)
      const changed = newValue !== this.sValue || '' + newValue !== '' + this.inputValue
      if (!hasProp(this, 'value')) {
        this.setState({
          sValue: newValue,
          inputValue: this.toPrecisionAsStep(v)
        }, callback)
      } else {
        // always set input value same as value
        this.setState({
          inputValue: this.toPrecisionAsStep(this.sValue)
        }, callback)
      }
      if (changed) {
        this.$emit('change', isNumber(v) ? v : newValue)
      }
    },

    toNumber (num) {
      const { precision, autoFocus } = this.$props
      const { focused = autoFocus } = this
      // num.length > 16 => This is to prevent input of large numbers
      const numberIsTooLarge = num && num.length > 16 && focused
      if (this.isNotCompleteNumber(num) || numberIsTooLarge) {
        return num
      }
      if (isValidProps(precision)) {
        return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
      }
      const number = Number(num)
      return !isNumber(num) ? number : num
    }
  }
}

export default {
  name: 'JInputNumber',
  extends: InputNumber,
  render () {
    const { size, ...others } = getOptionProps(this)
    const inputNumberClass = classNames({
      [`${this.prefixCls}-lg`]: size === 'large',
      [`${this.prefixCls}-sm`]: size === 'small'
    })
    const upIcon = <Icon type="up" class={`${this.prefixCls}-handler-up-inner`}/>
    const downIcon = <Icon type="down" class={`${this.prefixCls}-handler-down-inner`}/>

    const vcInputNumberProps = {
      props: {
        upHandler: upIcon,
        downHandler: downIcon,
        ...others
      },
      class: inputNumberClass,
      ref: 'inputNumberRef',
      on: this.$listeners
    }
    return <MyVcInputNumber {...vcInputNumberProps} />
  }

}
</script>
