<template>
  <a-modal
    :confirm-loading="loading"
    :destroyOnClose="true"
    :bodyStyle="{ color: '#F0F2F5' }"
    :visible="visible"
    :width="1350"
    :title="model.id ? '编辑检测明细' : '新增检测明细s'"
    @cancel="handleCancel"
    @ok="handleSubmit"
  >
    <a-form :form="form">
      <a-card v-bind="baseCard" title="基本信息">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="标准方法" v-show="!view">
          <a-row :gutter="8">
            <a-col :span="18">
              <a-select
                v-decorator="[
                  'standardMethodId',
                  {
                    initialValue: model.standardId == undefined ? undefined : model.standardId + '_' + model.methodId,
                    rules: [{ required: true, message: '请选择标准方法!' }, { validator: validateType }]
                  }
                ]"
                :filterOption="filterOption"
                placeholder="请选择标准方法"
                showSearch
                style="width: 100%"
                @change="handleStandardMethodChange"
              >
                <a-select-option
                  v-for="m in standardMethodList"
                  :key="m.standardId + '_' + m.methodId"
                  :title="getStandardMethodName(m)"
                  :value="m.standardId + '_' + m.methodId"
                  >{{ getStandardMethodName(m) }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col :span="6">
              <a-button type="primary" @click="addMethod()" style="margin-left: 20px" icon="plus">标准方法</a-button>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="一级分类">
          <a-select
            v-decorator="['cateType', { initialValue: model.cateType, rules: [{ required: true, message: '请选择一级分类!' }] }]"
            :filterOption="filterOption"
            placeholder="请选择一级分类"
            showSearch
            style="width: 100%"
            @change="handleCateTypeChange"
          >
            <a-select-option v-for="m in cateTypeList" :key="m.key" :value="m.key">{{ m.title }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="二级分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            showSearch
            style="width: 100%"
            :filterOption="filterOption"
            placeholder="请先选择一级分类"
            @change="handleCategoryChange"
            v-decorator="['cateId', { initialValue: model.cateId, rules: [{ required: true, message: '请选择二级分类!' }] }]"
          >
            <a-select-option v-for="m in categoryList" :value="m.id" :key="m.id">{{ m.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="因子">
          <a-select
            showSearch
            style="width: 100%"
            :filterOption="filterOption"
            placeholder="请先选择二级分类"
            @change="handleItemChange"
            v-decorator="['itemId', { initialValue: model.itemId, rules: [{ required: true, message: '请选择因子!' }, { validator: validateExist }] }]"
          >
            <a-select-option v-for="m in itemList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="设备">
          <a-select
            v-decorator="['jcDevices', { initialValue: getDevList(model.jcDevices), rules: [] }]"
            :filterOption="filterOption"
            mode="multiple"
            placeholder="请选择设备"
            style="width: 100%"
          >
            <a-select-option v-for="v in devList" :key="v.id.toString()" :value="v.id.toString()">{{ v.materialName }}-{{ v.speType }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="常用">
          <a-radio-group v-decorator="['defaultFlag', { initialValue: model.defaultFlag == null ? 1 : model.defaultFlag, rules: [{ required: true }] }]" name="defaultFlag">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="应用到" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="!isEdit">
          <a-select showSearch style="width: 100%" mode="multiple" :filterOption="filterOption" placeholder="请先选择因子" v-decorator="['copyCateId', { rules: [] }]">
            <a-select-option v-for="m in copyCategoryList" :value="m.id" :key="m.id">{{ m.name }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-card>

      <a-card v-bind="baseCard" title="检测信息" :style="{ marginTop: '16px' }">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="检测岗位">
          <a-select disabled v-decorator="['jobType', { initialValue: getDevList(model.jobType), rules: [] }]" :filterOption="filterOption" mode="multiple" style="width: 100%">
            <a-select-option v-for="j in jobTypeList" :key="j.id.toString()" :value="j.id.toString()">{{ j.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="检出限">
          <a-input style="width: 40%" v-decorator="['limitValue', { initialValue: model.limitValue, rules: [{ validator: validateNumber }] }]" placeholder="请输入检出限" />
          <a-select
            style="width: 20%; margin-left: 20px"
            v-decorator="['unit', { initialValue: model.unit == null ? '/' : model.unit, rules: [] }]"
            :filterOption="filterOption"
            placeholder="请选择单位"
          >
            <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
          </a-select>
          <!-- <a-input style="width: 20%; margin-left: 20px" placeholder="请填写单位" v-decorator="['unit', { initialValue: model.unit, rules: [] }]" /> -->
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="检测模板" style="width: 100%">
          <a-select v-decorator="['templateId', { initialValue: model.templateId }]" :filterOption="filterOption" placeholder="请选择检测记录模板" showSearch>
            <a-select-option v-for="m in templateFiles" :key="m.id" :value="m.id">{{ m.templateName }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="检测模型" style="width: 100%">
          <a-select v-decorator="['modelId', { initialValue: model.modelId }]" :filterOption="filterOption" placeholder="请选择检测记录模板" showSearch>
            <a-select-option v-for="m in modelList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="分值">
          <a-input-number v-decorator="['score', { initialValue: model.score }]"></a-input-number>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol2" label="检出限的保留位数">
          <a style="display: block">
            <a-icon type="plus" @click="addDecimal" />
          </a>
          <div class="decimal-item" v-for="(item, index) in decimalsSectionList" :key="index">
            <div>
              范围：
              <div style="display: inline-block; width: 146px">
                <template v-if="index !== 0">
                  <a-input-number v-model="item.numMin" disabled></a-input-number>
                  <a-select v-model="item.symbolMin" disabled :style="{ width: '55px' }">
                    <a-select-option value="<"><</a-select-option>
                    <a-select-option value="≤">≤</a-select-option>
                  </a-select>
                </template>
                <template v-else>
                  <div
                    style="
                      border: 1px solid #d9d9d9;
                      border-radius: 2px;
                      padding: 0 11px;
                      background-color: #f5f5f5;
                      opacity: 1;
                      cursor: not-allowed;
                      color: rgba(0, 0, 0, 0.25);
                      font-size: 16px;
                    "
                  >
                    -∞
                  </div>
                </template>
              </div>
              <span style="display: inline-block; margin: 0 5px">样品结果</span>
              <div style="display: inline-block; width: 146px">
                <template v-if="index !== decimalsSectionList.length - 1">
                  <a-select v-model="item.symbolMax" :style="{ width: '55px' }" @change="(v) => changeSymbolMax(v, item, index)">
                    <a-select-option value="<"><</a-select-option>
                    <a-select-option value="≤">≤</a-select-option>
                  </a-select>
                  <a-input-number v-model="item.numMax" @change="(v) => changeNumMax(v, item, index)"></a-input-number>
                </template>
                <template v-else>
                  <div
                    style="
                      border: 1px solid #d9d9d9;
                      border-radius: 2px;
                      padding: 0 11px;
                      background-color: #f5f5f5;
                      opacity: 1;
                      cursor: not-allowed;
                      color: rgba(0, 0, 0, 0.25);
                      font-size: 16px;
                    "
                  >
                    +∞
                  </div>
                </template>
              </div>
            </div>

            <div style="margin-left: 5px">
              小数位数：
              <a-input-number :min="0" :max="10" :precision="0" v-model="item.decimalPlace"></a-input-number>
            </div>
            <div style="margin-left: 5px; margin-right: 3px">
              科学计数法：
              <a-switch v-model="item.switchChecked" @change="(checked) => changeSwitchChecked(item, index, checked)" />
            </div>
            <div style="margin-left: 5px; margin-right: 3px">
              修约方式：
              <a-radio-group v-model="item.modeOfAmendment">
                <a-radio value="1">四舍五入</a-radio>
                <a-radio value="2">四舍六入五成双</a-radio>
              </a-radio-group>
            </div>
            <a-icon v-if="index !== 0 && index !== decimalsSectionList.length - 1" type="close-circle" style="color: red; cursor: pointer" @click="delDecimal(index)" />
          </div>
        </a-form-item>
      </a-card>

      <a-card v-bind="baseCard" title="质控信息" :style="{ marginTop: '16px' }" :bodyStyle="{ paddingLeft: '5px' }">
        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="空白样">
          <div class="control-item">
            <a-radio-group v-decorator="['needBlank', { initialValue: model.needBlank == null ? '1' : model.needBlank, rules: [{ required: true }] }]" name="defaultFlag">
              <a-radio value="1">必做</a-radio>
              <a-radio value="2">选做</a-radio>
              <a-radio value="3">不做</a-radio>
            </a-radio-group>
            <div>
              <a-radio-group v-model="model.needBlankNumType">
                <a-radio value="fix">固定数量</a-radio>
                <a-radio value="percent">百分比</a-radio>
              </a-radio-group>
              <a-input-number v-model="model.needBlankNum"></a-input-number>
            </div>
            <div>
              参考来源：
              <a-select v-model="model.needBlankRefSource" :style="{ width: '120px' }">
                <a-select-option value="1">标准</a-select-option>
                <a-select-option value="2">企业</a-select-option>
              </a-select>
            </div>
            <div>
              单位：
              <a-select
                style="width: 80px"
                v-decorator="['needBlankNumUnit', { initialValue: model.needBlankNumUnit == null ? '/' : model.needBlankNumUnit, rules: [] }]"
                :filterOption="filterOption"
                placeholder="请选择单位"
              >
                <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
              </a-select>
            </div>

            <div>
              因子复合：
              <a-radio-group v-model="model.compositeItemBlank">
                <a-radio value="1">是</a-radio>
                <a-radio value="2">否</a-radio>
              </a-radio-group>
            </div>
          </div>
        </a-form-item>

        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="平行样">
          <div class="control-item">
            <a-radio-group v-decorator="['needParallel', { initialValue: model.needParallel == null ? '1' : model.needParallel, rules: [{ required: true }] }]" name="needParallel">
              <a-radio value="1">必做</a-radio>
              <a-radio value="2">选做</a-radio>
              <a-radio value="3">不做</a-radio>
            </a-radio-group>
            <div>
              <a-radio-group v-model="model.needParallelNumType">
                <a-radio value="fix">固定数量</a-radio>
                <a-radio value="percent">百分比</a-radio>
              </a-radio-group>
              <a-input-number v-model="model.needParallelNum"></a-input-number>
            </div>
            <div>
              参考来源：
              <a-select v-model="model.needParallelRefSource" :style="{ width: '120px' }">
                <a-select-option value="1">标准</a-select-option>
                <a-select-option value="2">企业</a-select-option>
              </a-select>
            </div>
            <div>
              单位：
              <a-select
                style="width: 80px"
                v-decorator="['needParallelNumUnit', { initialValue: model.needParallelNumUnit == null ? '/' : model.needParallelNumUnit, rules: [] }]"
                :filterOption="filterOption"
                placeholder="请选择单位"
              >
                <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
              </a-select>
            </div>
            <div>
              因子复合：
              <a-radio-group v-model="model.compositeItemParallel">
                <a-radio value="1">是</a-radio>
                <a-radio value="2">否</a-radio>
              </a-radio-group>
            </div>
          </div>
        </a-form-item>

        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="质控样">
          <div class="control-item">
            <a-radio-group v-decorator="['needQc', { initialValue: model.needQc == null ? '1' : model.needQc, rules: [{ required: true }] }]" name="needQc">
              <a-radio value="1">必做</a-radio>
              <a-radio value="2">选做</a-radio>
              <a-radio value="3">不做</a-radio>
            </a-radio-group>
            <div>
              <a-radio-group v-model="model.needQcNumType">
                <a-radio value="fix">固定数量</a-radio>
                <a-radio value="percent">百分比</a-radio>
              </a-radio-group>
              <a-input-number v-model="model.needQcNum"></a-input-number>
            </div>
            <div>
              参考来源：
              <a-select v-model="model.needQcRefSource" :style="{ width: '120px' }">
                <a-select-option value="1">标准</a-select-option>
                <a-select-option value="2">企业</a-select-option>
              </a-select>
            </div>
            <div>
              单位：
              <a-select
                style="width: 80px"
                v-decorator="['needQcNumUnit', { initialValue: model.needQcNumUnit == null ? '/' : model.needQcNumUnit, rules: [] }]"
                :filterOption="filterOption"
                placeholder="请选择单位"
              >
                <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
              </a-select>
            </div>
            <div>
              因子复合：
              <a-radio-group v-model="model.compositeItemQc">
                <a-radio value="1">是</a-radio>
                <a-radio value="2">否</a-radio>
              </a-radio-group>
            </div>
          </div>
        </a-form-item>

        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="加标回收">
          <div class="control-item">
            <a-radio-group v-decorator="['needMarkup', { initialValue: model.needMarkup == null ? '1' : model.needMarkup, rules: [{ required: true }] }]" name="needMarkup">
              <a-radio value="1">必做</a-radio>
              <a-radio value="2">选做</a-radio>
              <a-radio value="3">不做</a-radio>
            </a-radio-group>
            <div>
              <a-radio-group v-model="model.needMarkupNumType">
                <a-radio value="fix">固定数量</a-radio>
                <a-radio value="percent">百分比</a-radio>
              </a-radio-group>
              <a-input-number v-model="model.needMarkupNum"></a-input-number>
            </div>
            <div>
              参考来源：
              <a-select v-model="model.needMarkupRefSource" :style="{ width: '120px' }">
                <a-select-option value="1">标准</a-select-option>
                <a-select-option value="2">企业</a-select-option>
              </a-select>
            </div>
            <div>
              单位：
              <a-select
                style="width: 80px"
                v-decorator="['needMarkupNumUnit', { initialValue: model.needMarkupNumUnit == null ? '/' : model.needMarkupNumUnit, rules: [] }]"
                :filterOption="filterOption"
                placeholder="请选择单位"
              >
                <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
              </a-select>
            </div>
            <div>
              因子复合：
              <a-radio-group v-model="model.compositeItemMarkup">
                <a-radio value="1">是</a-radio>
                <a-radio value="2">否</a-radio>
              </a-radio-group>
            </div>
          </div>
        </a-form-item>
        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="加标合格范围">
          <div class="control-item">
            <div>
              <a-input-number v-decorator="['markupMin', { initialValue: model.markupMin }]"></a-input-number>
              <span :style="{ fontSize: '16px', margin: '0 10px' }">≤ X ≤</span>
              <a-input-number v-decorator="['markupMax', { initialValue: model.markupMax }]"></a-input-number>
            </div>
            <div>
              <span :style="{ fontSize: '16px', margin: '0 10px' }">加标最小值：</span>
              <a-input-number v-decorator="['minValue', { initialValue: model.minValue }]"></a-input-number>
              <span :style="{ fontSize: '16px', margin: '0 10px' }">加标最大值：</span>
              <a-input-number v-decorator="['maxValue', { initialValue: model.maxValue }]"></a-input-number>
            </div>
            <div>
              单位：
              <a-select
                style="width: 80px"
                v-decorator="['valueUnit', { initialValue: model.valueUnit == null ? '/' : model.valueUnit, rules: [] }]"
                :filterOption="filterOption"
                placeholder="请选择单位"
              >
                <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
              </a-select>
            </div>
          </div>
        </a-form-item>

        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="标准曲线">
          <div class="control-item">
            <a-radio-group v-decorator="['needCurve', { initialValue: model.needCurve == null ? '1' : model.needCurve, rules: [{ required: true }] }]" name="needCurve">
              <a-radio value="1">必做</a-radio>
              <a-radio value="2">选做</a-radio>
              <a-radio value="3">不做</a-radio>
            </a-radio-group>
            <div>
              <a-radio-group v-model="model.needCurveNumType">
                <a-radio value="fix">固定数量</a-radio>
                <a-radio value="percent">百分比</a-radio>
              </a-radio-group>
              <a-input-number v-model="model.needCurveNum"></a-input-number>
            </div>
            <div>
              参考来源：
              <a-select v-model="model.needCurveRefSource" :style="{ width: '120px' }">
                <a-select-option value="1">标准</a-select-option>
                <a-select-option value="2">企业</a-select-option>
              </a-select>
            </div>
            <div>
              单位：
              <a-select
                style="width: 80px"
                v-decorator="['needCurveNumUnit', { initialValue: model.needCurveNumUnit == null ? '/' : model.needCurveNumUnit, rules: [] }]"
                :filterOption="filterOption"
                placeholder="请选择单位"
              >
                <a-select-option v-for="m in checkCustomizeUnitList" :key="m.id" :value="m.id">{{ m.name }}</a-select-option>
              </a-select>
            </div>
          </div>
        </a-form-item>
      </a-card>
      <a-card v-bind="baseCard" title="图谱信息" :style="{ marginTop: '16px' }" :bodyStyle="{ paddingLeft: '5px' }">
        <a-form-item :labelCol="labelCol2" :wrapperCol="wrapperCol2" label="图谱">
          <div class="control-item">
            <a-radio-group v-decorator="['atlasStatus', { initialValue: model.atlasStatus == null ? '1' : model.atlasStatus, rules: [{ required: true }] }]" name="defaultFlag">
              <a-radio value="1">必做</a-radio>
              <a-radio value="3">不做</a-radio>
            </a-radio-group>
          </div>
        </a-form-item>
      </a-card>
    </a-form>
    <method-form ref="methodForm" @ok="handleAddOk"></method-form>
  </a-modal>
</template>

<script>
import { Get, itemApi, itemCategoryApi, itemCheckDetailApi, jcMethodApi, Post, resMaterialApi, resPostTypeApi } from '@/api/quote'
import { fileTemplateUreportApi } from '@/api/fileTemplate'

import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import ATextarea from 'ant-design-vue/es/input/TextArea'
import SelectItem from '@/pages/common/SelectItem'
import ARow from 'ant-design-vue/es/grid/Row'
import ACol from 'ant-design-vue/es/grid/Col'
import MethodForm from '@/pages/common/method/MethodForm'
import { groupBy, cloneDeep } from 'lodash'
import { dictApi, dictItemApi } from '@/api/system'
import { MessageBox } from 'element-ui'
import { fullModalData } from '@/pages/common/mixins/modal/full'
import { sysCheckModelApi } from '@/api/checkModel'

export default {
  components: { ACol, ARow, ATextarea, SelectItem, MethodForm },
  data() {
    return {
      baseCard: {
        type: 'inner',
        size: 'small'
      },
      // bodyStyle:{
      // backgroundColor:pink
      // },
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 16
      },
      labelCol2: {
        span: 3
      },
      wrapperCol2: {
        span: 21
      },
      model: {},
      visible: false,
      loading: false,
      isEdit: false,
      form: this.$form.createForm(this),
      type: 0,
      defaultChecked: false,
      devList: [],
      cateTypeList: [],
      jobTypeList: [],
      itemList: [],
      standardMethodList: [],
      templateFiles: [],
      modelList: [],
      allCategoryList: [],
      copyCategoryList: [],
      categoryList: [],
      fullModalData,
      // 检测计量单位
      checkCustomizeUnitList: [],
      decimalsSectionList: []
    }
  },
  props: {
    view: { type: Boolean, default: false }
  },
  created() {
    this.getTemplateList()
    this.getModelList()
    Get(resPostTypeApi.listAll).then((res) => {
      if (res.code === 0) {
        this.jobTypeList = res.data
      }
    })
    this.loadCateType()
    this.loadAllCategory()
    // 该方法触发屏幕自适应
    Get(resMaterialApi.getMaterialSelect, {}).then((result) => {
      const group = groupBy(result, (item) => item.cateType)
      this.devList = group[3] == null ? [] : group[3]
    })
    this.checkCustomizeUnitList = []
    // 检测计量单位
    Get(dictItemApi.list, { pageSize: 500, dictCode: 'checkCustomizeUnit' }).then((res) => {
      const d = res.data
      if (d) {
        for (let i = 0; i < d.length; i++) {
          if (d[i].status === 1) {
            this.checkCustomizeUnitList.push({ id: d[i].itemValue, name: d[i].itemText })
          }
        }
      }
    })
  },
  methods: {
    loadAllCategory() {
      Post(itemCategoryApi.getAll).then((result) => {
        this.allCategoryList = result.data
      })
    },
    loadCategory(v) {
      this.categoryList = this.allCategoryList.filter((r) => r.cateType === v)
    },
    loadCateType() {
      dictApi.getItems({ dictCode: 'cate_type' }).then((res) => {
        if (res.code === 0) {
          const d = res.data
          const temp = []
          for (let i = 0; i < d.length; i++) {
            temp.push({ title: d[i].itemText, key: d[i].itemValue })
          }
          this.cateTypeList = temp
        }
      })
    },
    handleAddOk() {
      this.loadStandMethodList()
    },
    addMethod() {
      this.$refs.methodForm.add()
    },
    loadStandMethodList() {
      Get(jcMethodApi.listByModel, {}).then((result) => {
        if (result.code === 0) {
          this.standardMethodList = result.data
        }
      })
    },
    getTemplateList() {
      fileTemplateUreportApi.list({ page: false, templateType: fileTemplateUreportTypeEnum.checked }).then((res) => {
        if (res.code === 0) {
          this.templateFiles.splice(0, this.templateFiles.length, ...res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    getModelList() {
      sysCheckModelApi.list({ page: false }).then((res) => {
        this.modelList.splice(0, this.modelList.length, ...(res.data || []))
      })
    },

    getListByStr(text) {
      if (text) {
        if (text.lastIndexOf(',') === text.length - 1) {
          text = text.substring(0, text.length - 1)
        }
        return text.split(',')
      } else {
        return []
      }
    },
    validateType(rule, value, callback) {
      const form = this.form
      const cateType = form.getFieldValue('cateType')
      const itemId = form.getFieldValue('itemId')
      if (cateType && itemId) {
        form.validateFields(['itemId'], { force: true })
      }
      callback()
    },
    validateExist(rule, value, callback) {
      const form = this.form
      const standardMethodId = form.getFieldValue('standardMethodId')
      const cateId = form.getFieldValue('cateId')
      const itemId = form.getFieldValue('itemId')
      if (cateId && itemId && (standardMethodId || this.view)) {
        this.validateItemId(callback)
      } else {
        callback()
      }
    },

    validateNumber(rule, value, callback) {
      const reg = /^\d+(?=\.?\d+$|$)/
      if (!value || reg.test(value)) {
        callback()
      } else {
        // eslint-disable-next-line n/no-callback-literal
        callback('检出限必须为数字')
      }
    },

    validateItemId(callback) {
      const form = this.form
      const cateId = form.getFieldValue('cateId')
      const itemId = form.getFieldValue('itemId')
      const standardMethodId = form.getFieldValue('standardMethodId')
      const standardId = standardMethodId.split('_')[0]
      const methodId = standardMethodId.split('_')[1]
      itemCheckDetailApi.list({ cateId, itemId, standardId, methodId }).then((res) => {
        const d = res.data
        if (this.model.id) {
          if (d.filter((r) => r.id !== this.model.id).length > 0) {
            // 编辑的属性名跟其他属性重复
            // eslint-disable-next-line n/no-callback-literal
            callback('已维护该采样明细')
          } else {
            callback()
          }
        } else if (d.length > 0) {
          // eslint-disable-next-line n/no-callback-literal
          callback('已维护该采样明细')
        } else {
          callback()
        }
      })
    },
    getStandardMethodName(record) {
      if (record.standardName.trim().indexOf(record.standardCode.trim()) !== -1) {
        return record.standardName + '-' + record.methodName
      } else {
        return record.standardCode + '(' + record.standardName + ')-' + record.methodName
      }
    },
    loadItemList(value) {
      return itemApi.list({ cateIds: value, pageSize: 1000 }).then((res) => {
        this.itemList = res.data
        return res.data
      })
    },
    handleCategoryChange(v) {
      this.itemList = []
      this.form.setFieldsValue({ itemId: null })
      this.loadItemList(v)
    },
    loadCopyCategory(cateIds) {
      this.copyCategoryList = this.allCategoryList.filter((r) => cateIds.includes(r.id))
    },
    async handleItemChange(v) {
      const self = this
      let itemList = self.itemList
      if (self.itemList.length === 0 && self.model.cateId) {
        itemList = await self.loadItemList(self.model.cateId)
      }
      this.copyCategoryList = []
      const cateId = self.form.getFieldValue('cateId')
      const d = itemList.filter((r) => r.id === v)
      if (d.length > 0) {
        const cateIds = d[0].cateIds.replace(cateId, '')
        if (cateIds.length !== 0) {
          this.loadCopyCategory(cateIds)
        }
      }
    },
    handleCateTypeChange(v) {
      this.itemList = []
      this.form.setFieldsValue({ itemId: null, cateId: null })
      this.loadCategory(v)
    },
    handleStandardMethodChange(v) {
      // const array = v.toString().split(',')
    },
    getDevListName(text) {
      const name = []
      if (text) {
        const arr = text.split(',')
        arr.forEach((item) => {
          const d = this.devList.filter((r) => r.id === item)
          if (d.length > 0) {
            if (d[0].speType === undefined) {
              name.push(d[0].materialName)
            } else {
              name.push(d[0].materialName + '(' + d[0].speType + ')')
            }
          }
        })
      }
      return name.join('、')
    },

    getDevList(text) {
      if (text) {
        return text.split(',')
      }
      return []
    },

    editData(record) {
      record.edited = true
      this.$forceUpdate()
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    add() {
      this.edit({})
    },
    edit(row) {
      console.log('修改', row)
      this.loadStandMethodList()
      this.isEdit = false
      this.visible = true
      this.itemList = []
      this.model = {}
      this.decimalsSectionList = []
      this.model = cloneDeep(row)

      this.model.compositeItemBlank = this.model.compositeItemBlank || '1'
      this.model.compositeItemParallel = this.model.compositeItemParallel || '1'
      this.model.compositeItemQc = this.model.compositeItemQc || '1'
      this.model.compositeItemMarkup = this.model.compositeItemMarkup || '1'

      if (row && row.decimalsSectionList && row.decimalsSectionList.length > 0) {
        row.decimalsSectionList.forEach((item) => {
          if (item.scientificNotation && item.scientificNotation === 'Y') {
            item.switchChecked = true
          } else {
            item.scientificNotation = 'N'
            item.switchChecked = false
          }
          if (!item.modeOfAmendment) {
            item.modeOfAmendment = '2'
          }
        })
        this.decimalsSectionList = cloneDeep(row.decimalsSectionList)
      } else {
        let arr = [
          {
            numMin: null,
            symbolMin: null,
            symbolMax: '≤',
            numMax: null,
            decimalPlace: null,
            switchChecked: false,
            scientificNotation: 'N',
            modeOfAmendment: '2'
          },
          {
            numMin: null,
            symbolMin: '<',
            symbolMax: '≤',
            numMax: null,
            decimalPlace: null,
            switchChecked: false,
            scientificNotation: 'N',
            modeOfAmendment: '2'
          },
          {
            numMin: null,
            symbolMin: '<',
            symbolMax: null,
            numMax: null,
            decimalPlace: null,
            switchChecked: false,
            scientificNotation: 'N',
            modeOfAmendment: '2'
          }
        ]
        this.decimalsSectionList.push(...arr)
      }
      if (row.id !== undefined) {
        this.isEdit = true
      }
    },
    saveOrUpdate(formData) {
      this.loading = true
      itemCheckDetailApi
        .edit(formData)
        .then((res) => {
          this.model = {}
          this.visible = false
          this.$emit('ok', 'ref2_' + formData.standardId + '_' + formData.methodId)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const self = this
          const formData = Object.assign(values)

          const standardMethodId = formData.standardMethodId
          formData.standardId = standardMethodId.toString().split('_')[0]
          formData.methodId = standardMethodId.toString().split('_')[1]
          formData.id = this.model.id
          if (formData.jcDevices && formData.jcDevices.length > 0) {
            formData.jcDevices = formData.jcDevices.join(',')
          } else {
            formData.jcDevices = ''
          }
          if (formData.jobType && formData.jobType.length > 0) {
            formData.jobType = formData.jobType.join(',')
          } else {
            formData.jobType = ''
          }
          if (
            ((formData.minValue || formData.minValue === 0) && !formData.maxValue && formData.maxValue !== 0) ||
            (!formData.minValue && formData.minValue !== 0 && (formData.maxValue || formData.maxValue === 0))
          ) {
            this.$message.error(`加标最小值和加标最大值应同时填写！`)
            return
          } else if (!formData.minValue && !formData.maxValue && formData.minValue !== 0 && formData.maxValue !== 0) {
            formData.minValue = 0
            formData.maxValue = 0
          } else if (formData.minValue && formData.maxValue && formData.minValue >= formData.maxValue) {
            this.$message.error(`加标最小值应小于加标最大值！`)
            return
          }
          let bool = this.validateDecimals()
          if (bool === false) {
            return
          }
          const copyCateId = formData.copyCateId?.join(',')
          if (copyCateId) {
            MessageBox.confirm('应用到所选分类，确定将覆盖原有明细?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              const data = Object.assign({}, this.model, formData, { copyCateId })
              data.decimalsSectionList = this.decimalsSectionList
              self.saveOrUpdate(data)
            })
          } else {
            const data = Object.assign({}, this.model, formData, { copyCateId: null })
            data.decimalsSectionList = this.decimalsSectionList
            self.saveOrUpdate(data)
          }
        }
      })
    },
    validateDecimals() {
      for (let i = 0; i < this.decimalsSectionList.length; i++) {
        let item = this.decimalsSectionList[i]
        if (i === 0) {
          if (!item.symbolMax || (!item.numMax && item.numMax !== 0) || (!item.decimalPlace && item.decimalPlace !== 0)) {
            this.$message.error('请完善检出限的保留位数的数据！')
            return false
          }
        } else if (i === this.decimalsSectionList.length - 1) {
          if ((!item.numMin && item.numMin !== 0) || !item.symbolMin || (!item.decimalPlace && item.decimalPlace !== 0)) {
            this.$message.error('请完善检出限的保留位数的数据！')
            return false
          }
        } else {
          if ((!item.numMin && item.numMin !== 0) || !item.symbolMin || !item.symbolMax || (!item.numMax && item.numMax !== 0) || (!item.decimalPlace && item.decimalPlace !== 0)) {
            this.$message.error('请完善检出限的保留位数的数据！')
            return false
          }
        }
      }
      for (let i = 0; i < this.decimalsSectionList.length; i++) {
        let item = this.decimalsSectionList[i]
        if (i !== 0 && i !== this.decimalsSectionList.length - 1) {
          if (item.numMin >= item.numMax) {
            this.$message.error('同一范围的最小值应小于最大值！')
            return false
          }
        }
        if (i !== this.decimalsSectionList.length - 1) {
          if (item.numMax > this.decimalsSectionList[i + 1].numMin) {
            this.$message.error('上一个范围的最大值不能大于下一个范围的最小值！')
            return false
          }
          if (item.numMax === this.decimalsSectionList[i + 1].numMin) {
            if (item.symbolMax === this.decimalsSectionList[i + 1].symbolMin) {
              this.$message.error('上一个范围的最大值和下一个范围的最小值相等时，上一个范围的最大值的判断符号不能和下一个范围的最小值的判断符号一样！')
              return false
            }
          }
        }
      }
      return true
    },
    handleCancel() {
      this.visible = false
      this.model = {}
    },
    addDecimal() {
      let arrItem = {
        numMin: null,
        symbolMin: '<',
        symbolMax: '≤',
        numMax: null,
        decimalPlace: null,
        switchChecked: false,
        scientificNotation: 'N',
        modeOfAmendment: '2'
      }
      let prevItem = this.decimalsSectionList[this.decimalsSectionList.length - 2]
      if (prevItem.numMax) {
        arrItem.numMin = prevItem.numMax
      }
      if (prevItem.symbolMax === '<') {
        arrItem.symbolMin = '≤'
        this.$set(this.decimalsSectionList[this.decimalsSectionList.length - 1], 'symbolMin', '≤')
      }
      this.$set(this.decimalsSectionList[this.decimalsSectionList.length - 1], 'numMin', null)
      this.$set(this.decimalsSectionList[this.decimalsSectionList.length - 1], 'decimalPlace', null)
      this.$nextTick(() => {
        this.decimalsSectionList.splice(this.decimalsSectionList.length - 1, 0, arrItem)
      })
    },
    delDecimal(index) {
      this.decimalsSectionList.splice(index, 1)
      let prevItem = this.decimalsSectionList[index - 1]
      this.$set(this.decimalsSectionList[index], 'numMin', prevItem.numMax)
      this.$set(this.decimalsSectionList[index], 'decimalPlace', null)
      if (prevItem.symbolMax === '<') {
        this.$set(this.decimalsSectionList[index], 'symbolMin', '≤')
      } else {
        this.$set(this.decimalsSectionList[index], 'symbolMin', '<')
      }
    },
    changeSymbolMax(v, item, index) {
      if (v === '<') {
        this.$set(this.decimalsSectionList[index + 1], 'symbolMin', '≤')
      } else {
        this.$set(this.decimalsSectionList[index + 1], 'symbolMin', '<')
      }
    },
    changeNumMax(v, item, index) {
      if (v || v === 0) {
        this.$set(this.decimalsSectionList[index + 1], 'numMin', v)
      } else {
        this.$set(this.decimalsSectionList[index + 1], 'numMin', null)
      }
    },
    changeSwitchChecked(item, index, checked) {
      if (checked) {
        this.$set(this.decimalsSectionList[index], 'scientificNotation', 'Y')
      } else {
        this.$set(this.decimalsSectionList[index], 'scientificNotation', 'N')
      }
    }
  },
  watch: {
    'model.cateType': {
      handler(newValue, oldValue) {
        if (newValue && newValue !== '') {
          this.handleCateTypeChange(newValue)
        }
      },
      immediate: true
    },
    'model.cateId': {
      handler(newValue, oldValue) {
        if (newValue) {
          this.handleCategoryChange(newValue)
        }
      },
      immediate: true
    },
    'model.itemId': {
      handler(newValue, oldValue) {
        if (newValue) {
          this.handleItemChange(newValue)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.control-item {
  display: inline-flex;
  justify-content: space-between;
  justify-items: center;
  gap: 35px;
}
.decimal-item {
  display: inline-flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
