<template>
  <div class="check-standard-solution-type-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-standard-solution-type-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table
      :rowSelection="selectType?rowSelection:null"
      :scroll="scrollProp"
      v-bind="contentTable"
      v-on="contentTable.action">
      <template #validDate="data,record">
        <span class="green">{{ data }}</span> {{ commonValidDateUnitDataMap[record.validDateUnit].label }}
      </template>

      <template #validVolume="data,record">
        <template v-if="data">
          <span class="green">{{ data }}</span>&nbsp;ml
        </template>
        <span v-else>-</span>
      </template>

      <template #parentReagentCateName="data,record">
        <template v-if="record.parentReagentCateSolutionType">
          <a-tag :color="checkSolutionTypeDataMap[record.parentReagentCateSolutionType].color">
            {{ checkSolutionTypeDataMap[record.parentReagentCateSolutionType].label }}
          </a-tag>
        </template>
      </template>

      <template v-for="(_, slot) of $scopedSlots" #[slot]="text,record,index,expanded">
        <slot :name="slot" v-bind="{text:text,row:record,index:index,expanded}"/>
      </template>
    </s-table>
  </div>
</template>

<script>
import sTable from '@/components/Table'
import { componentTable } from '@/pages/common/mixins/list'
import { checkStandardSolutionTypeDefaultColumn as defaultColumn } from '@/pages/cloud-quote/check-standard-solution-type/modules/columns'
import { checkSolutionTypeDataMap } from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import { commonValidDateUnitDataMap } from '@/api/commonConstant'
import { CustomizeColumn } from '@/components/Columns'

import { checkStandardSolutionTypeApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'

export default {
  mixins: [componentTable],
  components: { sTable, CustomizeColumn },

  props: {
    selectType: { type: String, required: false, default: null, validator (value) { return [null, '', undefined, 'radio', 'checkbox'].includes(value) } },
    columns: { type: Array, required: false, default: () => (defaultColumn) }
  },

  data () {
    return {
      commonValidDateUnitDataMap,
      checkSolutionTypeDataMap,

      contentTable: {
        columns: this.columns
      },

      rowSelection: {
        type: this.selectType
      }
    }
  },

  watch: {
    'rowSelection.selectedRows': {
      handler (param) {
        this.$emit('selectedRows', param)
      }
    }
  },

  methods: {

    // 加载数据
    loadData (param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      const params = Object.assign({}, param, this.$attrs)
      if (params.solutionTypeId) {
        params.id = params.solutionTypeId
        delete params.solutionTypeId
      }
      return checkStandardSolutionTypeApi.list(params).then(res => res)
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";

.check-standard-solution-type-table {
  .color();
  .feature();

  .setting {
    position: absolute;
    z-index: 9;
    right: 28px;
    top: 28px
  }
}
</style>
