'use strict'

/**
 * 标线配置 相关API
 */

import { getAction, postDataAction } from '@/api/common'

const checkStandardCurvePre = '/ms-api/check-standard-curve'
const checkStandardCurveTypePre = '/ms-api/check-standard-curve-type'
const checkStandardCurveConfigPre = '/ms-api/check-standard-curve-config'
const checkStandardCurveVerifyPre = '/ms-api/check-standard-curve-Verify'

export const checkStandardCurveApi = {
  list: (param) => getAction(`${checkStandardCurvePre}/list`, param),
  save: (data) => postDataAction(`${checkStandardCurvePre}/save`, JSON.stringify(data)),
  assign: (data) => postDataAction(`${checkStandardCurvePre}/assign`, JSON.stringify(data)),
  review: (data) => postDataAction(`${checkStandardCurvePre}/review`, data),
  check: (data) => postDataAction(`${checkStandardCurvePre}/check`, data),
  claim: (data) => postDataAction(`${checkStandardCurvePre}/claim`, data)
}

export const checkStandardCurveTypeApi = {
  list: (param) => getAction(`${checkStandardCurveTypePre}/list`, param),
  save: (data) => postDataAction(`${checkStandardCurveTypePre}/save`, JSON.stringify(data)),
  detailCurveType: (param) => getAction(`${checkStandardCurveTypePre}/curve-type-detail`, param),
  curveTypeSite: (param) => getAction(`${checkStandardCurveTypePre}/curve-type-site`, param),
  listStandardMethod: (param) => getAction(`${checkStandardCurveTypePre}/list-standard-method`, param),
  notCurveTypeListStandardMethod: (param) => getAction(`${checkStandardCurveTypePre}/not-curve-type-list-standard-method`, param)
}

export const checkStandardCurveConfigApi = {
  list: (param) => getAction(`${checkStandardCurveConfigPre}/list`, param),
  save: (data) => postDataAction(`${checkStandardCurveConfigPre}/save`, JSON.stringify(data)),
  assign: (data) => postDataAction(`${checkStandardCurveConfigPre}/assign`, JSON.stringify(data)),
  review: (data) => postDataAction(`${checkStandardCurveConfigPre}/review`, data),
  check: (data) => postDataAction(`${checkStandardCurveConfigPre}/check`, data),
  autoCheck: (data) => postDataAction(`${checkStandardCurveConfigPre}/autoCheck`, data),
  claim: (data) => postDataAction(`${checkStandardCurveConfigPre}/claim`, data),
  curveSite: (param) => getAction(`${checkStandardCurveConfigPre}/curve-site`, param),
  solutionList: (param) => getAction(`${checkStandardCurveConfigPre}/solution-list`, param),
  formulaCalc: (param) => postDataAction(`${checkStandardCurveConfigPre}/formula-calc`, param),
  delete: (id) => postDataAction(`${checkStandardCurveConfigPre}/delete/${id}`)
}

export const checkStandardCurveVerifyApi = {
  list: (param) => getAction(`${checkStandardCurveVerifyPre}/list`, param),
  save: (data) => postDataAction(`${checkStandardCurveVerifyPre}/save`, JSON.stringify(data))
}
