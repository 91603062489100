<template>
  <table class="sample-info-table">
    <tr>
      <th>项目名称</th>
      <td>{{ checkTask.projName }}</td>
      <th>报告类型</th>
      <td>
        <a-tag color="blue"> {{ checkTask.projReportType|reportTypeFilter }}</a-tag>
      </td>
    </tr>
    <tr>
      <th>委托单位</th>
      <td colspan="3">{{ checkTask.csrName }}</td>
    </tr>
    <tr>
      <th>委托日期</th>
      <td>{{ moment(checkTask.projScheduledStart).format('YYYY-MM-DD') }}</td>
      <th>检测点位</th>
      <td><span style="font-weight: 700;margin: 0 5px 0 3px">{{ checkTask.siteSum }}</span>个</td>
    </tr>

    <tr>
      <th>项目负责人</th>
      <td>{{ checkTask.projUserName }}</td>
      <th>检测负责人</th>
      <td>{{ checkTask.detectionLeaderName }}</td>
    </tr>

    <tr>
      <th>报告人</th>
      <td>{{ checkTask.reportUserName }}</td>
      <th>开始时间</th>
      <td>{{ checkTask.startTime }}</td>
    </tr>

    <tr>
      <th>完成时间</th>
      <td colspan="3">{{ checkTask.endTime }}</td>
    </tr>

<!--    <tr v-if="checkTask.accessory">-->
<!--      <th>报告文件</th>-->
<!--      <td colspan="3">-->
<!--        <div :key="`accessory_`+index" v-for="(item,index) in JSON.parse(checkTask.accessory)">-->
<!--          <a @dblclick="previewFile(item)" title="双击查看报告文件">{{ item.name }}</a>-->
<!--        </div>-->
<!--      </td>-->
<!--    </tr>-->

    <tr v-if="parseInt(checkTask.checkStatus)">
      <th>审核人</th>
      <td>{{ checkTask.checkUser }}</td>
      <th>审核结果</th>
      <td>
        <span style="margin-right: 10px">与 {{ checkTask.checkTime }}</span>
        <a-tag color="green" v-if="checkTask.checkStatus==='1'">通过</a-tag>
        <a-tag color="red" v-if="checkTask.checkStatus==='2'">不通过</a-tag>
      </td>
    </tr>

    <tr v-if="parseInt(checkTask.checkStatus)">
      <th>审核意见</th>
      <td colspan="3">{{ checkTask.checkIdea }}</td>
    </tr>

  </table>
</template>

<script>
import moment from 'moment'
import { reportTaskApi } from '@/api/sample'
import { Get } from '@/api/quote'
import { sysCommonApi } from '@/api/common'

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

var vm = {}
export default {
  name: 'ReportTaskDetail',
  props: {
    id: {
      type: Number,
      default: null
    },

    visible: {
      type: Boolean,
      default: false
    }
  },

  filters: {
    reportTypeFilter (type) {
      return vm.reportType[type]
    }
  },
  data () {
    vm = this
    return {
      reportType: this.$store.state.code.cache.proj_report_type,
      checkTask: {}
    }
  },
  computed: {
    queryParam () {
      return { id: this.id }
    }
  },

  watch: {
    queryParam: {
      immediate: true,
      handler (newValue, oldValue) {
        this.$nextTick(() => {
          this.loadData(newValue)
        })
      }
    }
  },

  methods: {
    moment,
    loadData (param) {
      reportTaskApi.getOne(param).then(res => {
        if (res.code === 0) {
          this.checkTask = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    previewFile (file) {
      if (!file) {
        this.$message.error('暂无报告文件')
        return false
      }
      const isProduction = process.env.NODE_ENV === 'production'
      if (!isProduction) {
        const filename = file.name
        const type = filename.substr(filename.lastIndexOf('.') + 1, filename.length).toLocaleLowerCase()
        if (type === 'pdf') {
          this.previewPdfVisible = true
          this.previewUrl = file.fileUrl || file.url
        } else if (type === 'jpg' || type === 'png' || type === 'jpeg') {
          this.previewVisible = true
          this.previewImage = file.fileUrl
        } else if (type === 'doc' || type === 'docx' || type === 'xls' || type === 'xlsx') {
          const projectFullUrl = window.location.origin
          const fileUrl = projectFullUrl + `/ms-api/common/download-by-id?id=1&type=2&filename=${file.name}&filepath=${file.fileUrl || file.url}`
          const url = projectFullUrl + sysCommonApi.previewJson + '/id/1/type/4?fileUrl=' + encodeURI(fileUrl)
          Get(sysCommonApi.callUrlAndSign, { url: url }).then(res => {
            window.open(res.data)
          })
        } else {
          this.$message.info('不支持此类文件预览')
        }
      } else {
        window.location = sysCommonApi.downLoad + '?filePath=' + (file.url || file.fileUrl) + '&fileName=' + file.name + '&token=' + this.$store.getters.token
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/table/index";

.sample-info-table {
    width: 100%;

    tr {
        th {
            width: 15%;
            text-align: right;
        }

        td {
            width: 35%;
            text-align: left;
        }
    }

}
</style>
