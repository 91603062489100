<template>
  <div>
    <a-row>
      <a-col span="7">
        <div class="ahmux-sub-title" style="width: 150px;">
          授权信息
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请人：{{ authorizationInfo.name }}
        </div>
      </a-col>
      <a-col span="7">
        <div class="ahmux-sub-title-detail" style="width: 100%;">
          申请时间：{{ authorizationInfo.createTime }}
        </div>
      </a-col>
    </a-row>
    <a-row>
      <table class="mxtable">
        <tr>
          <th>姓名</th>
          <td>{{ authorizationInfo.name }}</td>
          <th>培训范围</th>
          <td>
            <a-input v-model="authorizationInfo.trainingScope" :disabled="disabled"></a-input>
          </td>
        </tr>
        <tr>
          <th>所属部门</th>
          <td>{{ authorizationInfo.deptName }}</td>
          <th>考试/考核情况</th>
          <td>
            <a-input v-model="authorizationInfo.examResult" :disabled="disabled"></a-input>
          </td>
        </tr>
        <tr>
          <th colspan="1">授权检测方法领域
            （如：滴定、气相色谱法、
            液相色谱法、物理、化学、微生物等）：</th>
          <td colspan="3">
            <a-radio-group v-if="!disabled" v-model="methodIdsOption" style="margin: 10px;" @change="() =>{methodIds=[];authorizationInfo.methods=null}">
              <a-radio :value="1">
                勾选
              </a-radio>
              <a-radio :value="2">
                手写
              </a-radio>
            </a-radio-group>
            <a-input v-if="methodIdsOption === 2 || authorizationInfo.authMethods" v-model="authorizationInfo.authMethods" :disabled="disabled"></a-input>
            <a-select
              v-else
              mode="multiple"
              allowClear
              v-model="methodIds"
              placeholder="请选择授权检测方法"
              style="width: 100%"
              :filterOption="filterOption"
              @select="handleSelect"
              @deselect="handleDeselect"
              :disabled="disabled"
            >
              <a-select-option v-for="item in methodList" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </td>
        </tr>
        <tr>
          <th colspan="1">授权操作仪器范围
            （填仪器名称）</th>
          <td colspan="3">
            <a-radio-group v-if="!disabled" v-model="devIdsOption" style="margin: 10px;" @change="() =>{devIds=[];authorizationInfo.dev=null}">
              <a-radio :value="1">
                勾选
              </a-radio>
              <a-radio :value="2">
                手写
              </a-radio>
            </a-radio-group>
            <a-input v-if="devIdsOption === 2 || authorizationInfo.authDev" v-model="authorizationInfo.authDev" :disabled="disabled"></a-input>
            <a-select
              v-else
              mode="multiple"
              allowClear
              v-model="devIds"
              placeholder="请选择仪器名称"
              style="width: 100%"
              :filterOption="filterOption"
              @select="handleSelectDev"
              @deselect="handleDeselectDev"
              :disabled="disabled"
            >
              <a-select-option v-for="item in devList" :key="item.id" :value="item.id">
                {{ getMaterialName(item) }}
              </a-select-option>
            </a-select>
          </td>
        </tr>
        <tr>
          <th colspan="1">备注</th>
          <td colspan="3">
            <a-input v-model="authorizationInfo.remark" :disabled="disabled"></a-input>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import { Get, resStaffApi } from '@/api/quote'

export default {
  name: 'StaffAuthorizationForm',
  props: {
    recordId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      disabled: false,
      authorizationInfo: {
        authMethods: null,
        authDev: null
      },
      methodList: [],
      devList: [],
      checkMethodsList: [],
      methodIds: [],
      devIds: [],
      methodIdsOption: 1,
      devIdsOption: 1
    }
  },
  watch: {
    recordId: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== null) {
          this.loadData()
        }
      }
    }
  },
  created () {
    this.getMethodAndDevList()
  },
  methods: {
    getMethodAndDevList () {
      Get(resStaffApi.getMethodAndDevList).then(res => {
        if (res.code === 0) {
          this.methodList = res.data.methodList
          this.devList = res.data.devList
        }
      })
    },
    loadData () {
      setTimeout(() => {
        Get(resStaffApi.getAuthorizationInfo, { id: this.recordId }).then(res => {
          if (res.code === 0) {
            this.authorizationInfo = res.data.authorizationInfo
            this.methodIds = res.data.methodIds || []
            this.handleChangeDev()
            this.disabled = this.authorizationInfo.status !== 1
          }
        })
      }, 100)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleSelect (value, option) {
      if (this.methodIds.indexOf(value) === -1) {
        this.methodIds.push(value)
      }
      this.handleChangeDev()
    },
    handleDeselect (value, option) {
      /* if (this.methodIds.indexOf(value) !== -1) {
        const index = this.methodIds.indexOf(value)
        this.methodIds.splice(index, 1)
      }
      this.handleChangeDev() */
    },
    handleChangeDev () {
      const methodList = this.methodList.filter(it => this.methodIds.indexOf(it.id) !== -1)
      this.devIds = []
      if (methodList && methodList.length > 0) {
        methodList.forEach(item => {
          if (item.devTypeId.indexOf(',') === -1) {
            this.devIds.push(parseInt(item.devTypeId))
          } else {
            item.devTypeId.split(',').forEach(id => {
              this.devIds.push(parseInt(id))
            })
          }
        })
      }
    },
    getMaterialName (item) {
      if (item.speType) return item.materialName + '(' + item.speType + ')'
      else return item.materialName
    },
    handleSelectDev (value, option) {
      if (this.devIds.indexOf(value) === -1) {
        this.devIds.push(value)
      }
      this.handleChangeMethod()
    },
    handleDeselectDev (value, option) {
      /* if (this.devIds.indexOf(value) !== -1) {
        const index = this.devIds.indexOf(value)
        this.devIds.splice(index, 1)
      }
      this.handleChangeMethod() */
    },
    handleChangeMethod () {
      this.methodIds = []
      this.methodList.forEach(item => {
        if (item.devTypeId.indexOf(',') === -1) {
          if (this.devIds.indexOf(parseInt(item.devTypeId)) !== -1) {
            this.methodIds.push(item.id)
          }
        } else {
          item.devTypeId.split(',').forEach(id => {
            if (this.devIds.indexOf(parseInt(id)) !== -1) {
              this.methodIds.push(item.id)
            }
          })
        }
      })
    }
  }
}
</script>
