<template>
  <a-modal v-bind="modal" v-on="modal">
    <a-row justify="center" align="top" style="height: 430px">
      <a-col :span="9" :offset="1">
        <a-form :form="form">
          <a-row>
            <a-form-item label="业务员" v-bind="formItemLayout"> {{ getRealName() }} </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="客户名称" v-bind="formItemLayout">
              <a-input
                placeholder="请输入客户名称"
                v-decorator="[
                  'csrName',
                  {
                    initialValue: this.customerInfo.csrName,
                    rules: [{ required: true, message: '请录入客户名称!' }, { validator: this.validateCsrName }, { max: 30, message: '最大长度不能大于30个字符' }]
                  }
                ]"
              >
                <template slot="addonAfter">
                  <a @click="openQxb">启信宝</a>
                </template>
              </a-input>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="法人代表" v-bind="formItemLayout">
              <a-input placeholder v-decorator="['csrContact', { initialValue: this.customerInfo.csrContact, rules: [{ max: 50, message: '最大长度不能大于50个字符' }] }]" />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="客户类型" v-bind="formItemLayout">
              <a-select placeholder="客户类型" v-decorator="['csrType', { initialValue: this.customerInfo.csrType, rules: [{ required: true, message: '客户类型不能为空！' }] }]">
                <a-select-option v-for="(v, k) in this.customerTypeMap" :key="k">{{ v }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-row>
          <!--          <a-row>-->
          <!--            <a-form-item label="资质编号" v-bind="formItemLayout">-->
          <!--              <a-input-->
          <!--                placeholder-->
          <!--                v-decorator="[ 'csrQualification',-->
          <!--                               { initialValue:this.customerInfo.csrQualification,-->
          <!--                                 rules: [ { max:100, message:'最大长度不能大于100个字符'} ]-->
          <!--                               }]"/>-->
          <!--            </a-form-item>-->
          <!--          </a-row>-->
          <!--          <a-row>-->
          <!--            <a-form-item label="法人代表" v-bind="formItemLayout">-->
          <!--              <a-input-->
          <!--                placeholder-->
          <!--                v-decorator="[ 'csrContact',-->
          <!--                               { initialValue:this.customerInfo.csrContact,-->
          <!--                                 rules: [ { max:50, message:'最大长度不能大于50个字符'} ]-->
          <!--                               }]"/>-->
          <!--            </a-form-item>-->
          <!--          </a-row>-->
          <a-row>
            <a-form-item label="联系方式" v-bind="formItemLayout">
              <a-input placeholder v-decorator="['csrTelephone', { initialValue: this.customerInfo.csrTelephone, rules: [{ max: 15, message: '最大长度不能大于15个字符' }] }]" />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="联系地址" v-bind="formItemLayout">
              <AddressInput :addr.sync="addr"></AddressInput>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="工商地址" v-bind="formItemLayout">
              <a-input placeholder v-decorator="['businessAddress', { initialValue: this.customerInfo.businessAddress }]" />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="税号" v-bind="formItemLayout">
              <a-input placeholder v-decorator="['invoiceNo', { initialValue: this.customerInfo.invoiceNo, rules: [{ max: 20, message: '最大长度不能大于20个字符' }] }]" />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="开户银行" v-bind="formItemLayout">
              <a-input placeholder v-decorator="['invoiceBank', { initialValue: this.customerInfo.invoiceBank, rules: [{ max: 255, message: '最大长度不能大于255个字符' }] }]" />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="银行账户" v-bind="formItemLayout">
              <a-input
                placeholder
                v-decorator="['invoiceAccount', { initialValue: this.customerInfo.invoiceAccount, rules: [{ max: 50, message: '最大长度不能大于50个字符' }] }]"
              />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="状态" v-bind="formItemLayout">
              <a-switch
                checkedChildren="启用"
                unCheckedChildren="禁用"
                :checked="customerStateValue"
                @click="changState" />
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="营业执照" v-bind="formItemLayout">
              <a-upload
                accept="image/*"
                :action="uploadUrl"
                listType="picture"
                :fileList="fileList"
                :headers="tokenHeader"
                class="upload-list-inline"
                :remove="handleRemove"
                @change="handleChange"
              >
                <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
            </a-form-item>
          </a-row>
        </a-form>
      </a-col>
      <a-col :span="13" :offset="1">
        <div style="height: 500px">
          <el-amap
            ref="map"
            vid="csrMap"
            :amap-manager="amapManager"
            v-bind="mapOptions">
            <el-amap-marker
              v-for="(marker, index) in markers"
              :position="marker.position"
              :content="marker.content"
              :events="marker.events"
              :key="index"
              visible="true"
              :vid="index"
            ></el-amap-marker>
            <div class="map-layer">
              <a-select style="width: 120px" v-model="mapLayerType" @change="handleLayerChange">
                <a-select-option value="defaultlayer">标准图层</a-select-option>
                <a-select-option value="satellite">卫星图</a-select-option>
                <a-select-option value="satelliteFloor">卫星楼层图</a-select-option>
              </a-select>
            </div>
            <div v-if="curPosition.lng" style="position: absolute; left: 100px; bottom: 18px; float: left; font-size: x-small">{{ curPosition.lng }}，{{ curPosition.lat }}</div>
          </el-amap>
        </div>
      </a-col>
    </a-row>
    <!--    <AdCodeSelect ref="adCodeSelect" @ok="setAdCode"></AdCodeSelect>-->
  </a-modal>
</template>

<script>
import { Get, Post, customerApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import ARow from 'ant-design-vue/es/grid/Row'
import ACol from 'ant-design-vue/es/grid/Col'
import AFormItem from 'ant-design-vue/es/form/FormItem'
import AdCodeSelect from '@/pages/common/adcode/AdCodeSelect'
import AddressInput from '@/pages/common/map/AddressInput'
import { AMapManager } from 'vue-amap'
import Vue from 'vue'

export default {
  name: 'CustomerForm',
  components: { AFormItem, ACol, ARow, AdCodeSelect, AddressInput },
  props: {
    // Edit or Add
    oprationType: {
      type: [String],
      default: 'Edit'
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const amapManager = new AMapManager()
    return {
      // TODO token header IE仅10+支持设置headers
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.upload,
      viewUrl: sysCommonApi.view,
      fileList: [],
      csrYyzz: '',
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      form: this.$form.createForm(this),
      addAttr: {
        addType: 1 // 1、客户单位列表打开，2、其他页面打开，需要返后新增数据
      },
      customerInfo: {},
      addr: {
        adCode: '',
        address: '',
        lng: '',
        lat: ''
      },
      // 当前客户的状态
      customerStateValue: true,
      customerId: null,
      searchResult: [], // 自动完成区搜索结果
      BMap: {}, // BMap对象
      map: {}, // 地图对象
      zoom: 12.8, // 放大比例

      currentMarkerInfo: {
        show: false,
        point: {},
        title: '',
        address: ''
      },
      markerPoints: [], // marker集合

      infoWindowOffset: {},
      modal: {
        title: '新增客户',
        visible: false,
        centered: true,
        closable: false,
        width: '100%',
        wrapClassName: 'modal-container',
        destroyOnClose: true,
        cancel: this.close,
        ok: this.handleOK,
        okText: '保存',
        confirmLoading: false
      },
      amapManager,
      mapLayerType: 'defaultlayer',
      layers: {
        satellite: new AMap.TileLayer.Satellite(),
        bulidings:
          // 楼块图层
          new AMap.Buildings({
            zooms: [16, 18],
            zIndex: 10,
            heightFactor: 2 // 2倍于默认高度，3D下有效
          })
      },
      map: {},
      keywords: '',
      searchOption: {
        // city: '上海',
        // citylimit: true
      },
      mapOptions: {
        zoom: 12,
        // 不设置center 自动切换到用户IP所在城市
        // center: [117.24665, 31.810439],
        pitch: 60,
        rotation: -35,
        // 隐藏默认楼块
        features: ['bg', 'road', 'point'],
        mapStyle: 'amap://styles/normal',
        viewMode: '2D',
        loaded: false,
        events: {
          init: (map) => {
            if (this.mapLayerType === 'satelliteFloor') {
              const satellite = this.layers.satellite
              const buildings = this.layers.bulidings
              satellite.setMap(map)
              buildings.setMap(map)
            } else {
              if (this.mapLayerType === 'satellite') {
                this.mapOptions.viewMode = '2D'
                const satellite = this.layers.satellite
                satellite.setMap(map)
              }
              // this.initPathSimplifier()
            }
            self.map = map
          },
          mousemove (e) {
            self.curPosition = e.lnglat
          }
        },
        plugin: [
          {
            pName: 'Scale',
            // 比例尺
            events: {
              init (instance) {}
            }
          }
        ]
      },
      markers: [],
      curPosition: []
    }
  },
  mounted () {
    this.customerStateValue = true
    if (this.customerInfo.id) {
      this.customerStateValue = this.customerInfo.csrState === 1
    }
    this.customerId = this.customerInfo.id
    this.csrYyzz = this.customerInfo.csrYyzz
    if (typeof this.csrYyzz !== 'undefined' && this.csrYyzz != null && this.csrYyzz !== '') {
      this.fileList = [
        {
          uid: 'ahmux',
          name: 'ahmux',
          status: 'done',
          url: this.viewUrl + this.csrYyzz
        }
      ]
    }
  },
  methods: {
    add (addAttr) {
      this.modal.visible = true
      this.modal.title = '新增客户'
      this.customerInfo = { csrType: '' }
      if (this.customerTypeList) {
        const obj = this.customerTypeList[0]
        this.customerInfo.csrType = obj.itemValue
      }
      if (addAttr) {
        this.addAttr = addAttr
      }
      this.addr = { adCode: '', address: '', lng: '', lat: '' }
    },
    edit (record) {
      this.modal.visible = true
      this.modal.title = '客户编辑'
      this.customerInfo = record
      this.addr = { adCode: record.csrAdCode, address: record.csrAddr, lng: record.csrLng, lat: record.csrLat }
    },
    validateCsrName (rule, value, callback) {
      const params = {
        id: this.customerInfo.id,
        csrName: value
      }
      customerApi.checkCsrName(params).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            callback()
          } else {
            callback(new Error('用户账号已存在！'))
          }
        }
      })
    },
    handleOK (e) {
      // 提交表单
      e.preventDefault()
      const self = this
      this.form.validateFields((error, values) => {
        if (error == null) {
          self.modal.confirmLoading = true
          const newValues = Object.assign({}, self.customerInfo, values)
          newValues.csrAdCode = self.addr.adCode
          newValues.csrAddr = self.addr.address
          newValues.csrLng = self.addr.lng
          newValues.csrLat = self.addr.lat
          newValues.csrState = this.customerStateValue ? 1 : 0
          Post(customerApi.save, newValues)
            .then((result) => {
              if (result.code === 0) {
                const messageTip = this.customerInfo.id ? '修改客户信息成功' : '新增客户信息成功'
                this.$message.success(messageTip)
                if (this.addAttr && this.addAttr.addType === 2) {
                  this.addAttr.result = result.data
                  this.$emit('ok', this.addAttr)
                } else {
                  this.$emit('ok', true)
                }
                this.close()
              } else {
                this.$message.error('操作失败')
              }
            })
            .catch((error) => {
              this.$message.error(`服务器发生错误！错误消息为${error}`)
            })
            .finally(() => {
              self.modal.confirmLoading = false
            })
        }
      })
    },
    close () {
      this.modal.visible = false
    },

    listCustomer () {
      const self = this
      customerApi.listCustomer({ page: false, authEnable: false }).then((result) => {
        if (result.code === 0) {
          self.customerList = result.data
        }
      })
    },
    changeEntrust (value) {
      const csr = this.customerList.filter((item) => value === item.id)[0]
      this.$set(this.info, 'entrustCustomer', csr.id)
      this.$set(this.addr, 'adCode', csr.csrAdCode)
      this.$set(this.addr, 'address', csr.csrAddr)
      this.$set(this.addr, 'lng', csr.csrLng)
      this.$set(this.addr, 'lat', csr.csrLat)
      this.listCustomerUser()
    },
    showAddCustomer (csrType) {
      this.$refs.customerModalForm.add({ addType: 2, csrType })
    },
    addCustomerRes (obj) {
      const record = obj.result
      this.customerList.push(record)
    },
    listCustomerUser () {
      const self = this
      const csrIds = []
      if (self.info.entrustCustomer) {
        csrIds.push(self.info.entrustCustomer)
      }
      if (csrIds.length > 0) {
        const csrIdsStr = csrIds.toString()
        Get(`${customerUserApi.list}`, { csrIds: csrIdsStr, page: false, authEnable: false }).then((result) => {
          if (result.code === 0) {
            const customerUserList = result.data
            this.customerUserList = customerUserList
            this.updateCustomerUser(this.customerUserList[0])
          }
        })
      } else {
        this.customerUserList = []
      }
    },
    updateCustomerUser (csrUser) {
      if (csrUser) {
        this.$set(this.info, 'entrustContacts', csrUser.id)
        this.$set(this.info, 'entrustContactsName', csrUser.csrUserName)
        this.$set(this.info, 'entrustContactsPhone', csrUser.csrUserTelephone)
      } else {
        this.$set(this.info, 'entrustContacts', '')
        this.$set(this.info, 'entrustContactsName', '')
        this.$set(this.info, 'entrustContactsPhone', '')
      }
    },
    changeCustomerUser (value, op) {
      const self = this
      const csrUser = self.customerUserList.filter((item) => value === item.id)[0]
      this.updateCustomerUser(csrUser)
    },
    showAddCustomerUser () {
      if (this.info.entrustCustomer) {
        this.$refs.customerUserModal.addByCsrId(this.info.entrustCustomer)
      } else {
        this.$message.warning(`请先选择客户单位`)
      }
    },

    openQxb () {
      const csrName = this.form.getFieldValue('csrName')
      // this.customerInfo.csrName = csrName
      window.open(`https://www.qixin.com/search?key=${csrName}`)
    },
    handleLayerChange () {
      if (this.mapLayerType === 'satelliteFloor') {
        this.mapOptions.viewMode = '3D'
      } else {
        this.mapOptions.viewMode = '2D'
      }
      this.$refs.map.createMap()
    },
    changState () {
      this.customerStateValue = !this.customerStateValue
    },
    getRealName () {
      if (this.customerInfo.id) {
        return this.customerInfo.createRealName
      } else {
        return this.$store.getters.realname
      }
    },
    handleRemove () {
      this.csrYyzz = ''
      this.fileList = []
    },
    handleChange (info) {
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          info.file.thumbUrl = this.viewUrl + info.file.response.data
          info.file.url = this.viewUrl + info.file.response.data
          this.csrYyzz = info.file.response.data
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    addMarker (lnglat) {
      const self = this
      self.markers = [
        {
          position: lnglat,
          content: '<img src="/map/marker8.png" width="25" height="34" alt=""/>',
          zIndex: 1000,
          type: '2',
          offset: [-12, -12],
          // , label: { content: '999999999', offset: [ -20, -24 ] }
          events: {
            click: () => {
              self.infoWindow.visible = false
              self.$nextTick(() => {
                self.infoWindow.visible = true
              })
            }
          }
        }
      ]
    }
  },
  computed: {
    customerTypeMap () {
      return { ...this.$store.state.code.cache.customerType }
    },
    customerTypeList () {
      return [...Vue.ls.get('customerType')]
    }
  },
  watch: {
    addr: {
      handler (nv, ov) {
        if (nv.lng && nv.lat) {
          this.addMarker([nv.lng, nv.lat])
          this.mapOptions.center = [nv.lng, nv.lat]
        } else {
          this.markers = []
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.$store.dispatch('code/select', 'customerType')
  }
}
</script>

<style lang="less">
.bm-view {
  height: 420px;
}
.modal-container {
  height: 100%;

  .ant-modal {
    height: 100%;
    overflow: hidden;
    padding-bottom: 0px;

    .ant-modal-content {
      height: 100%;
      overflow: hidden;

      .ant-modal-body {
        overflow: auto;
        padding: 5px;
        height: ~'calc(100% - 108px)';
      }

      .ant-modal-body::-webkit-scrollbar {
        display: none;
      }

      .ant-modal-footer {
        text-align: center;
      }
    }
  }
}
</style>
<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 90px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-list-item-name {
  display: none;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
