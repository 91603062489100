import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 检测任务状态字典枚举
export const checkTaskStatusEnum = {
  // 待开始
  scheduled: '0',
  // 进行中
  processing: '1',
  // 已完成
  completed: '2'
}

//  检测任务状态选择项
export const checkTaskStatusOptions = [
  { label: '待开始', value: checkTaskStatusEnum.scheduled, color: 'purple' },
  { label: '进行中', value: checkTaskStatusEnum.processing, color: 'orange' },
  { label: '已完成', value: checkTaskStatusEnum.completed, color: '#87D068' }
]

export const checkTaskStatusMap = tranOptionsToMap(checkTaskStatusOptions)
export const checkTaskStatusDataMap = tranOptionsToDataMap(checkTaskStatusOptions)

// 检测模型扩充字段的数据类型枚举类
export const checkModelDataTypeEnum = {
  normal: '1',
  basic: '2',
  formula: '3'
}

//  检测模型扩充字段的数据类型选项
export const checkModelDataTypeOptions = [
  { label: '默认', value: checkModelDataTypeEnum.normal, color: 'purple' },
  { label: '普样', value: checkModelDataTypeEnum.basic, color: 'orange' },
  { label: '常量', value: checkModelDataTypeEnum.formula, color: '#87D068' }
]

export const checkModelDataTypeMap = tranOptionsToMap(checkModelDataTypeOptions)
export const checkModelDataTypeDataMap = tranOptionsToDataMap(checkModelDataTypeOptions)
