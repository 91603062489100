import { getAction, postAction, postDataAction } from '@/api/common'

export const flowApi = {
  page: '/ms-api/flow/page', // 流程定义管理分页数据
  pageProcess: '/ms-api/flow/page-process', // 流程实例管理分页数据
  pageUserProcess: '/ms-api/flow/page-user-process', // 流程实例管理分页数据
  pageUserProcessWait: '/ms-api/flow/page-user-process-wait', // 流程实例管理分页数据
  pageUserProcessComplete: '/ms-api/flow/page-user-process-complete', // 流程实例管理分页数据
  pageProcessCopyMe: '/ms-api/flow/page-process-copy-me', // 流程实例管理分页数据
  list: '/ms-api/flow/list', // 发起流程时可见的流程列表
  process: '/ms-api/flow/process', // 当前流程的详细信息
  deploy: '/ms-api/flow/deploy', // 部署一个流程
  startByKey: '/ms-api/flow/start', // 开启一个流程by Key
  agree: '/ms-api/flow/agree', // 审批实例
  backToStep: '/ms-api/flow/back-to-step', // 回退实例
  active: '/ms-api/flow/active', // 激活流程实例
  suspend: '/ms-api/flow/suspend', // 挂起流程实例
  pdActive: '/ms-api/flow/pd-active', // 激活流程定义
  pdSuspend: '/ms-api/flow/pd-suspend', // 挂起流程定义
  del: '/ms-api/sys-model/del-process', // 逻辑删除流程定义
  delPhysical: '/ms-api/sys-model/del-process-physical', // 物理删除流程定义
  pdDel: '/ms-api/flow/pd-del', // 删除流程定义
  img: '/ms-api/flow/img', // 流程图
  pdImg: '/ms-api/flow/pd-img', // 流程图
  pdImgByKey: '/ms-api/flow/pdImgByKey', // 流程图
  userChuchaiProcess: '/ms-api/flow/user-chuchai-process', // 获取用户出差流程
  saveByModel: (data) => postAction('/ms-api/sys-model/modelDataDealForFlow', data), // 保存流程 自定义模型
  queryByModel: (param) => getAction('/ms-api/sys-model/getFlowData', param), // 查询流程详情 自定义模型
  // carbonCopy: (param) => postAction('/ms-api/flow/carbonCopy', param), // 流程手动抄送
  carbonCopy: '/ms-api/flow/carbonCopy', // 流程手动抄送
  flowCategory: () => getAction('/ms-api/flow/flow-category'), // 流程分类
  flowCategoryPage: () => getAction('/ms-api/flow/flow-category-page'), // 流程分类
  updateFlow: (param) => postAction('/ms-api/flow/update-flow', param), // 流程基本信息更新
  updateFlowCategory: (param) => postAction('/ms-api/flow/update-flow-category', param), // 流程分类更新
  replyCopy: '/ms-api/flow/replyCopy', // 回复抄送
  delegate: '/ms-api/flow/flow-delegate', // 流程流转
  resolveTask: '/ms-api/flow/resolve-task', // 流程流转回复
  processByKey: (p) => getAction('/ms-api/flow/process-by-key', p),
  reassign: '/ms-api/flow/flow-reassign', // 流程节点任务重分配
  saveFapiao: (data) => postDataAction(`/ms-api/mx-flow/save-fapiao`, data),
  saveFapiaoData: (data) => postDataAction(`/ms-api/mx-flow/save-fapiao-data`, data),
  getFapiao: (data) => getAction(`/ms-api/mx-flow/get-fapiao`, data)
}

// 流程查询-根据processKey,mxId
export const mxFlowApi = {
  listProcess: (p) => getAction('/ms-api/mx-flow/listProcess', p)
}

// 流程key
export const flowKey = {
  fbsCheckKey: 'jx_fbs_check'
}

export const modelerApi = {
  login: '/cfm/app/authentication',
  editor: '/flowable-api/#/editor/'
}

export const modelApi = {
  page: '/ms-api/flow-model/page',
  del: '/ms-api/flow-model/del',
  findByKey: '/ms-api/flow-model/key',
  deploy: '/ms-api/flow-model/deploy',
  addProcessCustom: '/ms-api/flow-model/add_process_custom',
  getCustomForm: '/ms-api/flow-model/get_custom_form',
  delCustomForm: '/ms-api/flow-model/del_custom_form',
  getCustomList: () => getAction('/ms-api/flow-model/get_custom_list')
}

export const flowTypeApi = [
  { id: '通用流程', name: '通用流程' },
  { id: '业务流程', name: '业务流程' },
  { id: '测试流程', name: '测试流程' }
]
