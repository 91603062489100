<template>
  <div class="check-task-table">
    <customize-column v-model="contentTable.columns" cache-key-pre="check-task-table">
      <template #button>
        <a-icon class="setting" type="setting"></a-icon>
      </template>
    </customize-column>
    <s-table :rowSelection="customRowSelection" :scroll="scrollProp" v-bind="contentTable" v-on="contentTable.action">
      <template #checkSampleId="data, record">
        {{ data || record.sampleId }}
        <template v-if="[projectStatusEnum.abandoned, projectStatusEnum.tempAbandoned].includes(record.projStatus)">
          <a-tag :color="projectStatusDataMap[record.projStatus].color">{{ projectStatusDataMap[record.projStatus].label }}</a-tag>
        </template>
      </template>

      <template #sampleType="data">
        <a-tag :color="sampleInfoTypeDataMap[data].color">
          {{ data === sampleInfoTypeEnum.password ? sampleInfoTypeDataMap[sampleInfoTypeEnum.normal].label : sampleInfoTypeDataMap[data].label }}
        </a-tag>
      </template>

      <template #itemName="item, record"> {{ record.cateName }}/{{ record.itemName }} </template>

      <template #methodsName="item, record">
        {{ item ? item : '-' }}
      </template>

      <template #itemType="item, record">
        <template v-if="record.itemType === 1">
          <a-tag color="red">分包</a-tag>
        </template>
        <template v-else>
          <a-tag color="green">正常</a-tag>
        </template>
      </template>

      <template #scheduleTime="item, record">
        <span style="color: green">起：</span>{{ record.beginTime | minuteFilter(shortTimeFormatStr) }}<br />
        <span style="color: red">止：</span>{{ record.endTime | minuteFilter(shortTimeFormatStr) }}
      </template>

      <template #actualTime="item, record">
        <a-tooltip>
          <template #title>
            <h4 style="color: #fff0f6">截止时间</h4>
            <span style="color: green">起：</span>{{ record.beginTime | minuteFilter(shortTimeFormatStr) }}<br />
            <span style="color: red">止：</span>{{ record.endTime | minuteFilter(shortTimeFormatStr) }}
          </template>
          <template v-if="record.startTime"> <span style="color: green">起：</span>{{ record.startTime | minuteFilter(shortTimeFormatStr) }}<br /> </template>

          <template v-if="record.doneTime"> <span style="color: red">止：</span>{{ record.doneTime | minuteFilter(shortTimeFormatStr) }} </template>
          <template v-if="!record.doneTime && !record.startTime">
            <span>-</span>
          </template>
        </a-tooltip>
      </template>

      <template #countDownTime="item, record">
        <span v-if="!record.endTime">暂无</span>
        <span v-else-if="record.doneTime">已完成</span>
        <a-badge v-else-if="now().isAfter(moment(record.endTime))" :text="record | endStatusFilter" status="error"></a-badge>
        <a-badge v-else :text="record | endStatusFilter" status="success"></a-badge>
      </template>

      <template #curveControl="data">
        <a-switch :checked="data" :disabled="true"></a-switch>
      </template>

      <template #recordId="data">
        <a-tag v-if="data" color="green">已填写</a-tag>
        <a-tag v-else color="gray">无</a-tag>
      </template>

      <template #status="data">
        <a-tag :color="checkTaskStatusDataMap[data].color">
          {{ checkTaskStatusDataMap[data].label }}
        </a-tag>
      </template>

      <template #dataStatus="data, record">
        <check-task-result-status :data="record" :show-void="false" />
      </template>

      <template #sampleStatus="data">
        <a-tooltip>
          <template slot="title">
            <p style="color: red; font-weight: 700">样品出库后，方可开始</p>
          </template>
          <a-tag :color="sampleInfoStatusDataMap[data].color"> {{ sampleInfoStatusDataMap[data].label }}</a-tag>
        </a-tooltip>
      </template>

      <template #sampleTime="date, record">
        <span style="color: green">起：</span>{{ record.sampleBeginTime | momentFilter(shortTimeFormatStr) }}<br />
        <span style="color: red">止：</span>{{ record.sampleEndTime | momentFilter(shortTimeFormatStr) }}
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text, record, index, expanded">
        <slot :name="slot" v-bind="{ text: text, row: record, index: index, expanded }" />
      </template>
    </s-table>
  </div>
</template>

<script>
import sTable from '@/components/Table'
import { CustomizeColumn } from '@/components/Columns'
import CheckTaskResultStatus from '@/pages/cloud-quote/check-task/modules/checkTaskResultStatus'

import mixinDate from '@/pages/common/mixins/date'
import { componentTable } from '@/pages/common/mixins/list'
import { checkTaskDefaultColumn as defaultColumn } from './columns'

import { sampleInfoStatusDataMap, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { checkTaskStatusDataMap } from '@/api/check/checkTaskConstant'
import { projectStatusDataMap, projectStatusEnum } from '@/api/project/projectConstants'

import { checkTaskApi } from '@/api/check/checkTaskApi'

export default {
  components: { sTable, CustomizeColumn, CheckTaskResultStatus },
  mixins: [componentTable, mixinDate],

  props: {
    bordered: { type: Boolean, required: false, default: false },
    isSetData: { type: Boolean, required: false, default: false },
    dataSource: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    showPagination: { type: [String, Boolean], required: false, default: true },
    columns: {
      type: Array,
      required: false,
      default: () => {
        return defaultColumn
      }
    },
    rowSelect: { type: Object, required: false, default: () => ({}) },
    mustHaveCateAndItem: { type: Boolean, required: false, default: false } // 必须有因子参数，这是新建批次中选择样品时的条件，其它页面不需要遵循此条件
  },

  data() {
    return {
      sampleInfoTypeDataMap,
      sampleInfoTypeEnum,
      sampleInfoStatusDataMap,
      checkTaskStatusDataMap,
      projectStatusDataMap,
      projectStatusEnum,

      contentTable: {
        columns: this.columns,
        bordered: this.bordered,
        showPagination: this.showPagination ? 'auto' : false
      }
    }
  },

  computed: {
    queryParam() {
      return { ...this.$attrs }
    },

    customRowSelection() {
      return this.rowSelect && Object.keys(this.rowSelect).length ? Object.assign(this.rowSelection, this.rowSelect) : null
    }
  },

  methods: {
    // 加载数据
    loadData(param) {
      // mustHaveCateAndItem是必须有因子参数的意思，这是新建批次中选择样品时的条件，其它页面不需要遵循此条件
      if (!this.mustHaveCateAndItem || (this.mustHaveCateAndItem && this.queryParam.cateAndItemStrs && this.queryParam.cateAndItemStrs.length > 0)) {
        if (this.isSetData) {
          if (this.showPagination) {
            const { pageNo, pageSize } = param
            const dataSource = this.dataSource
            const totalCount = dataSource.length || 0
            const totalPage = Math.ceil(totalCount / pageSize)
            const startIdx = (pageNo - 1) * pageSize
            const endIdx = pageNo * pageSize
            const data = dataSource.slice(startIdx, endIdx)
            return new Promise((resolve, reject) => {
              const resData = { pageNo, pageSize, totalCount, totalPage, data: [...data] }
              resolve(resData)
            }).then((res) => res)
          } else {
            return new Promise((resolve, reject) => {
              const resData = { code: 0, msg: 'success', data: [...this.dataSource] }
              resolve(resData)
            }).then((res) => res)
          }
        } else {
          param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
          return checkTaskApi.list(Object.assign({}, param, this.queryParam)).then((res) => res)
        }
      } else {
        this.$message.warning('请先选择因子！')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
