<template>
  <div style="position: relative;">
    <customize-column v-model="contentTable.columns" :cache-key-pre="cacheKeyPre">
      <template #button>
        <a-icon class="setting" type="setting"></a-icon>
      </template>
    </customize-column>

    <s-table :rowSelection="rowSelection" :scroll="scrollProp" v-bind="contentTable">
      <template slot="expandedRowRender" slot-scope="record">
        <a-row>
          <a-col :span="16">
            <template v-if="record.applyId">
              <MakeSampleDetail :sampleRefId="record.idTemp"></MakeSampleDetail>
            </template>
            <template v-else>
              <SampleItemDetail :sampleId="record.id"></SampleItemDetail>
            </template>
          </a-col>
          <a-col v-if="record.photos" :span="8">
            <pictureShow :height="picHeight" :photos="JSON.parse(record.photos)"></pictureShow>
          </a-col>
        </a-row>
      </template>

      <template #photo="item,record">
        <el-popover placement="top" title="" trigger="hover">
          <el-image slot="reference" :src="getImg(record)" fit="container" style="max-height: 50px;max-width: 60px">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
          <el-image :src="getImg(record)" style="max-height: 600px;max-width: 400px"></el-image>
        </el-popover>
      </template>

      <template #projName="data,record">
        {{ data }}
        <template v-if="record.projStatus==='-1'">
          <a-tag color="red">已终止</a-tag>
        </template>
        <template v-else-if="record.projStatus==='2'">
          <a-tag color="red">临时终止</a-tag>
        </template>
      </template>

      <template #id="id">
        <a @click="logDrawer.show(id)"> {{ id }}</a>
      </template>

      <template #checkSampleId="id,record">
        <a @click="logDrawer.show(record.id)"> {{ id || record.id }}</a>
      </template>

      <template #itemNameStr="data">
        <ellipsis :length="20">{{ data }}</ellipsis>
      </template>

      <template #type="data">
        <a-tag :color="sampleInfoTypeDataMap[data]&&sampleInfoTypeDataMap[data].color||'red'">
          {{ data === sampleInfoTypeEnum.password ? sampleInfoTypeDataMap[sampleInfoTypeEnum.normal].label : sampleInfoTypeDataMap[data] && sampleInfoTypeDataMap[data].label || '未知' }}
        </a-tag>
      </template>

      <template #sampleUserName="data,record">
        {{ data || record.sampleGiverName || '-' }}
      </template>

      <template #num="data">
        <span v-if="data"><span style="font-weight: 600;font-size: 16px">{{ data }}</span> 份</span>
        <span v-else>-</span>
      </template>

      <template #volume="data,record">
        <span v-if="data">
          <span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.volumeUnit }}
        </span>
        <span v-else>-</span>
      </template>

      <template #validity="data,record">
        <span v-if="data"><span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.validityUnit }}</span>
        <span v-else>-</span>
      </template>

      <template #reservedTime="data,record">
        <span v-if="data"><span style="font-weight: bolder;font-size: 16px">{{ data }}</span> {{ record.reservedTimeUnit }}</span>
        <span v-else>-</span>
      </template>

      <template #itemType="data">
        <a-tag :color="sampleInfoSubcontractDataMap[data].color">
          {{ sampleInfoSubcontractDataMap[data].label }}
        </a-tag>
      </template>

      <template #sampleTime="date,record">
        <span style="color: green">起：</span>{{ record.sampleBeginTime |momentFilter(showTimeFormat) }}<br/>
        <span style="color: red">止：</span>{{ record.sampleEndTime|momentFilter(showTimeFormat) }}
      </template>

      <template #endTime="date,record">
        <span style="color: green">起：</span>{{ record.checkBeginTime |momentFilter(showTimeFormat) }}<br/>
        <span style="color: red">止：</span>{{ record.checkEndTime |momentFilter(showTimeFormat) }}
      </template>

      <div slot="reserved" slot-scope="data">
        <a-tag v-if="data" color="green">已留样</a-tag>
        <a-tag v-else color="blue">未留样</a-tag>
      </div>

      <template #orderStatus="data">
        <a-tag :color="checkOrderStatusDataMap[data].color">
          {{ checkOrderStatusDataMap[data].label }}
        </a-tag>
      </template>

      <template #status="data,record">
        <template v-if="record.applyId">
          <a-tag :color="makeSampleInfoStatusDataMap[data].color"> {{ makeSampleInfoStatusDataMap[data].label }}</a-tag>
        </template>
        <template v-else>
          <a-tag :color="sampleInfoStatusDataMap[data].color"> {{ sampleInfoStatusDataMap[data].label }}</a-tag>
        </template>
      </template>

      <template #source="data">
        <a-tag :color="sampleInfoSourceDataMap[data].color">
          {{ sampleInfoSourceDataMap[data].label }}
        </a-tag>
      </template>

      <template #numPeriod="item">
        {{ item ? `第${item}天` : '-' }}
      </template>

      <template #numFrequency="item">
        {{ item ? `第${item}次` : '-' }}
      </template>

      <template #action="data,record">

        <a v-if="record.status===sampleInfoStatusEnum.storageApply" v-action:sample-info:storage class="row-button" @click="storageSample({data:record})">
          入库
        </a>

        <a v-if="record.status===sampleInfoStatusEnum.pickupApply" v-action:sample-info:outbound class="row-button" @click="outboundSample({data:record})">
          出库
        </a>

        <a
          v-if="(record.status === sampleInfoStatusEnum.storaged ||
            record.status === sampleInfoStatusEnum.pickupApply)&&!record.reserved"
          v-action:sample-info:reserved
          class="row-button"
          @click="reservedSample({data:record})">
          留样
        </a>

        <a
          v-if="record.status!==sampleInfoStatusEnum.doneSample&&
            record.status!==sampleInfoStatusEnum.storageApply&&
            record.status!==sampleInfoStatusEnum.destroyed&&
            record.destroyApplyStatus===sampleDestroyApplyStatusEnum.pending"
          v-action:sample-info:destroy
          class="row-button"
          @click="destroySample([record])">
          销毁
        </a>

        <a-dropdown>
          <a class="ant-dropdown-link" @click.stop="(event)=>{event.preventDefault()}">标签
            <a-icon type="down"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="1">
              <a-popconfirm title="是否要打印该样品标签？" @confirm="print(record)">
                <a>采样标签</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item key="2">
              <a-popconfirm title="是否要打印该样品的转码标签？" @confirm="printCheckSampleId(record)">
                <a>转码标签</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

      </template>

      <template #checkScheduleAction="data,record">
        <a v-if="record.orderStatus===checkOrderStatusEnum.scheduled" class="row-button" @click="handleReSchedule(record)">
          重排
        </a>
        <a v-if="!record.cancelCheck&&showCancelCheck" class="row-button" @click="handleCancelCheck([record])">
          取消检测
        </a>
      </template>

      <template #removeAction="data,record,index">
        <a class="row-button" @click="removeRecord(record,index)">
          移除
        </a>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="text,record,index,expanded">
        <slot :name="slot" v-bind="{text:text,row:record,index:index,expanded}"/>
      </template>
    </s-table>

    <!-- 接样 -->
    <receive-sample-form v-if="componentShow.receive" ref="receive" @cancel="componentShow.receive=false" @success="reload"/>

    <!-- 留样 -->
    <reserved-sample-form v-if="componentShow.reservedForm" ref="reservedForm" @cancel="componentShow.reservedForm=false" @success="reload"/>

    <!-- 入库 -->
    <storage-sample-form v-if="componentShow.storageForm" ref="storageForm" @cancel="componentShow.storageForm=false" @success="reload"/>

    <!-- 修改位置 -->
    <change-location-form v-if="componentShow.locationForm" ref="locationForm" @cancel="componentShow.locationForm=false" @success="reload"/>

    <!-- 出库 -->
    <outbound-sample-form v-if="componentShow.outboundForm" ref="outboundForm" @cancel="componentShow.outboundForm=false" @success="reload"/>

    <!-- 样品销毁 -->
    <a-modal v-if="destroyModal.visible" v-bind="destroyModal" v-on="destroyModal">
      <sample-info-destroy-form :ref="destroyModal.destroyRef" :sample-list="destroyModal.sampleList" @success="destroyModal.success"></sample-info-destroy-form>
    </a-modal>

    <!-- 样品作废申请 -->
    <a-modal v-if="deleteModal.visible" v-bind="deleteModal" v-on="deleteModal">
      <sample-info-delete-form :ref="deleteModal.destroyRef" :sample-list="deleteModal.sampleList" @success="deleteModal.success"/>
    </a-modal>

    <!-- 样品关联分包任务 -->
    <a-modal v-if="subcontractModal.visible" v-bind="subcontractModal" v-on="subcontractModal">
      <sample-subcontract-form :ref="subcontractModal.subcontractRef" :sample-infos="subcontractModal.sampleInfos" @success="subcontractModal.success"/>
    </a-modal>

    <!-- 检测日志 -->
    <a-drawer v-if="logDrawer.visible" v-bind="logDrawer" v-on="logDrawer">
      <sample-info-log :sample-id="logDrawer.sampleId"></sample-info-log>
    </a-drawer>

  </div>
</template>

<script>
import { componentTable } from '@/pages/common/mixins/list'

import { sysCommonApi } from '@/api/common'
import { sampleColumn } from '@/pages/cloud-quote/sample-info/sampleTypeColumn'
import { checkOrderApi, tsSampleinfoApi } from '@/api/sample'
import { sampleInfoSourceDataMap, sampleInfoSourceEnum, sampleInfoStatusDataMap, sampleInfoStatusEnum, sampleInfoSubcontractDataMap, sampleInfoSubcontractEnum, sampleInfoTypeDataMap, sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'
import { sampleDestroyApplyStatusEnum } from '@/api/sampleInfo/sampleInfoDestroyConstant'
import { checkOrderStatusDataMap, checkOrderStatusEnum } from '@/api/check/checkOrderConstant'
import { makeSampleInfoStatusDataMap } from '@/api/makeSample/makeSampleConstant'
import { STable } from '@/components'
import { MakeSampleDetail } from '@/pages/cloud-quote/make-sample/modules'
import SampleItemDetail from '@/pages/cloud-quote/sample-info/modules/SampleItemDetail'
import ReservedSampleForm from '@/pages/cloud-quote/sample-info/modules/ReservedSampleForm'
import StorageSampleForm from '@/pages/cloud-quote/sample-info/modules/StorageSampleForm'
import OutboundSampleForm from '@/pages/cloud-quote/sample-info/modules/OutboundSampleForm'
import ChangeLocationForm from '@/pages/cloud-quote/sample-info/modules/ChangeLocationForm'
import pictureShow from '@/pages/cloud-quote/sample-info/modules/pictureShow'
import ReceiveSampleForm from '@/pages/cloud-quote/sample-info/modules/receive/Index'
import SampleInfoDestroyForm from '@/pages/cloud-quote/sample-info/modules/destroy/form'
import SampleSubcontractForm from '@/pages/cloud-quote/sample-info/modules/subcontract'
import sampleInfoLog from '@/pages/cloud-quote/sample-info/modules/log'
import { CustomizeColumn } from '@/components/Columns'
import SampleInfoDeleteForm from '@/pages/cloud-quote/sample-info/modules/delete/form'
import { printHelper } from '@/utils/printUtil'
import Ellipsis from '@/components/Ellipsis/Ellipsis.vue'

const showTimeFormat = 'YYYY-MM-DD HH:mm'

export default {
  name: 'SampleInfoTable',

  components: {
    Ellipsis,
    ChangeLocationForm,
    STable,
    MakeSampleDetail,
    SampleItemDetail,
    ReceiveSampleForm,
    ReservedSampleForm,
    StorageSampleForm,
    OutboundSampleForm,
    pictureShow,
    SampleInfoDestroyForm,
    SampleSubcontractForm,
    sampleInfoLog,
    CustomizeColumn,
    SampleInfoDeleteForm
  },

  mixins: [componentTable],

  props: {
    columns: { type: Array, required: false, default: () => { return sampleColumn } },
    extraData: { type: Array, required: false, default: () => ([]) },
    picHeight: { type: String, required: false, default: '200px' },
    dataType: { type: String, required: false, default: 'sampleInfo' },
    showCancelCheck: { type: Boolean, required: false, default: false },
    pagination: { type: Boolean, required: false, default: true },
    disabledOrderStatus: { type: Array, required: false, default: () => { return [] } },
    disabledFun: { type: Function, required: false, default: () => { return false } },
    cacheKeyPre: { type: String, required: false, default: 'sample-info-table' },

    neType: { type: String, required: false, default: sampleInfoTypeEnum.virtual },
    temp: { type: Boolean, required: false, default: false },
    local: { type: Boolean, required: false, default: false },
    defaultOtherHeight: { type: Number, required: false, default: 0 }

  },

  data () {
    return {

      sampleInfoStatusEnum,
      sampleInfoSourceEnum,
      sampleInfoStatusDataMap,
      sampleInfoSourceDataMap,
      sampleInfoSubcontractDataMap,
      sampleInfoSubcontractEnum,
      checkOrderStatusEnum,
      checkOrderStatusDataMap,
      sampleDestroyApplyStatusEnum,
      sampleInfoTypeDataMap,
      sampleInfoTypeEnum,
      makeSampleInfoStatusDataMap,

      notFound: '/notFound.jpg',
      viewUrl: sysCommonApi.viewImg,
      logDrawer: {
        title: '操作记录',
        visible: false,
        size: 'small',
        width: 400,
        sampleId: null,
        show: (sampleId) => {
          sampleId && (this.logDrawer.sampleId = sampleId)
          this.$nextTick(() => {
            this.logDrawer.visible = true
          })
        },
        close: () => { this.logDrawer.visible = false }
      },

      // 销毁弹窗
      destroyModal: {
        title: '样品销毁流程',
        visible: false,
        centered: true,
        closable: false,
        destroyRef: 'sampleInfoDestroy',
        sampleList: null,
        width: '60%',
        cancel: () => { this.destroyModal.visible = false },
        ok: () => {
          this.$refs[this.destroyModal.destroyRef].save()
        },

        success: () => {
          this.destroyModal.cancel()
          this.reload()
        },

        show: (sampleList) => {
          this.destroyModal.sampleList = sampleList
          this.destroyModal.visible = true
        }
      },

      // 样品作废弹窗
      deleteModal: {
        title: '样品销毁流程',
        visible: false,
        centered: true,
        closable: false,
        destroyRef: 'sampleInfoDestroy',
        sampleList: null,
        width: '60%',
        cancel: () => { this.deleteModal.visible = false },
        ok: () => {
          this.$refs[this.deleteModal.destroyRef].save()
        },

        success: () => {
          this.deleteModal.cancel()
          this.reload()
        },

        show: (sampleList) => {
          this.deleteModal.sampleList = sampleList
          this.deleteModal.visible = true
        }
      },

      // 分包弹窗
      subcontractModal: {
        title: '分包任务',
        visible: false,
        centered: true,
        closable: false,
        subcontractRef: 'sampleInfoSubcontract',
        sampleInfos: [],
        width: '60%',
        cancel: () => { this.subcontractModal.visible = false },

        ok: () => {
          this.$refs[this.subcontractModal.subcontractRef].save()
        },

        success: () => {
          this.subcontractModal.cancel()
          this.reload()
        },

        show: (sampleInfos) => {
          this.subcontractModal.sampleInfos.splice(0, this.subcontractModal.sampleInfos.length, ...sampleInfos)
          this.subcontractModal.visible = true
        }
      },

      rowSelection: {
        getCheckboxProps: record => ({
          props: {
            disabled: this.disabledOrderStatus.includes(record.orderStatus) || this.disabledFun(record),
            orderStatus: record.orderStatus
          }
        })
      },

      showTimeFormat: showTimeFormat,

      componentShow: {
        receive: false,
        reservedForm: false,
        storageForm: false,
        locationForm: false,
        outboundForm: false
      },

      picView: sysCommonApi.view

    }
  },

  computed: {
    queryParam () {
      const { neType, temp } = this
      const result = Object.assign({}, { neType, temp }, this.$attrs)
      return result
    }
  },

  watch: {

    'rowSelection.selectedRows' (newValue, oldValue) {
      this.$emit('selectedRows', newValue)
    },

    extraData: {
      immediate: false,
      handler (newValue) {
        this.reload()
      }
    },

    columns: {
      immediate: true,
      handler (newValue, oldValue) {
        this.contentTable.columns = newValue
      },
      deep: true
    }
  },

  methods: {

    getImg (record) {
      if (record.photos) {
        return this.viewUrl + '/' + JSON.parse(record.photos)?.[0]?.url
      } else if (record.storageImg) {
        return this.viewUrl + '/' + record.storageImg
      } else {
        return this.notFound
      }
    },

    print (record) {
      try {
        const name = record.itemIdStr
        printHelper('sample', [record.id, record.id, name, record.sampleBeginTime, record.sampleUserName || record.sampleGiverName])
      } catch (e) {
        this.$message.error('打印失败，请检查打印助手和设备是否正常连接')
        console.log('打印失败，请检查打印助手和设备是否正常连接', e)
      }
    },

    printCheckSampleId (record) {
      if (!record.checkSampleId) {
        this.$message.error('暂无样品转码编号！')
        return false
      }
      try {
        const name = record.itemIdStr
        printHelper('sample', [record.id, record.checkSampleId, name, record.sampleBeginTime, record.sampleUserName || record.sampleGiverName])
      } catch (e) {
        this.$message.error('打印失败，请检查打印助手和设备是否正常连接')
        console.log('打印失败，请检查打印助手和设备是否正常连接', e)
      }
    },

    init (data) {
      this.queryParam.tsTaskId = data.tsTaskId
      this.visible = true
    },

    loadData (parameter) {
      parameter.sortField = parameter.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      if (this.dataType === 'sampleInfo') {
        if (this.local) {
          return new Promise((resolve, reject) => {
            const resData = { code: 0, msg: 'success', data: [...this.extraData] }
            resolve(resData)
          }).then(res => res)
        } else {
          return tsSampleinfoApi.postList(Object.assign(parameter, this.queryParam)).then(res => {
            return { ...res, data: res.data.concat(this.extraData) }
          })
        }
      } else if (this.dataType === 'checkOrder') {
        return checkOrderApi.list(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      } else {
        this.$message.error('暂未实现该类型的数据查询方法')
      }
    },

    reload () {
      if (this.$refs.table) {
        this.rowSelection.selectedRows.splice(0, this.rowSelection.selectedRows.length)
        this.rowSelection.selectedRowKeys.splice(0, this.rowSelection.selectedRowKeys.length)
        this.$refs.table.refresh(false)
        this.$emit('reload')
      }
    },

    // 接样
    receiveSample () {
      this.componentShow.receive = true
      this.$nextTick(() => {
        this.$refs.receive.init()
      })
    },

    subcontractSample () {
      if (!this.rowSelection.selectedRows.length) {
        this.$message.error('请先选择一条数据')
        return false
      }
      const checkRows = this.rowSelection.selectedRows.filter(item => item.itemType === sampleInfoSubcontractEnum.yes)
      this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
      if (checkRows && checkRows.length) {
        this.subcontractModal.show(checkRows)
      } else {
        this.$message.error('暂无符合条件的样品需要分包')
        return false
      }
    },

    changeLocation ({ data = null }) {
      let result = null
      if (data == null) {
        if (!this.rowSelection.selectedRows.length) {
          this.$message.error('请先选择一条数据')
          return false
        }
        const checkRows = this.rowSelection.selectedRows.filter(item => (item.status !== sampleInfoStatusEnum.storageApply))
        this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
        if (checkRows && checkRows.length) {
          result = checkRows.map(item => { return { id: item.id } })
        } else {
          this.$message.error('暂无符合条件的样品可以修改位置')
          return false
        }
      } else {
        result = [data].map(item => { return { id: item.id } })
      }
      this.componentShow.locationForm = true
      this.$nextTick(() => {
        this.$refs.locationForm.init(result)
      })
    },

    // 申请入库
    applyStorage ({ data = null, storageApplyTime, storageApplyUser }) {
      this.$confirm({
        title: '您确认要申请吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          let result = null
          if (data == null) {
            if (!this.rowSelection.selectedRows.length) {
              this.$message.error('请先选择一条数据')
              return false
            }
            // 完成采样 或者 是现场质控样品,可以申请入库
            const checkRows = this.rowSelection.selectedRows.filter(item => (item.status === sampleInfoStatusEnum.doneSample || item.applyId))
            this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
            if (checkRows && checkRows.length) {
              result = checkRows.map(item => { return { id: item.id, type: item.type, projId: item.projId, storageApplyUser, storageApplyTime } })
            } else {
              this.$message.error('暂无符合条件的样品')
              return false
            }
          } else {
            result = [data].map(item => { return { id: item.id, type: item.type, projId: item.projId, storageApplyUser, storageApplyTime } })
          }
          return tsSampleinfoApi.storageApply(result).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 入库
    storageSample ({ data = null }) {
      let result = null
      if (data == null) {
        if (!this.rowSelection.selectedRows.length) {
          this.$message.error('请先选择一条数据')
          return false
        }
        const checkRows = this.rowSelection.selectedRows.filter(item => (item.status === sampleInfoStatusEnum.storageApply))
        this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
        if (checkRows && checkRows.length) {
          result = checkRows.map(item => { return { id: item.id } })
        } else {
          this.$message.error('暂无符合条件的样品需要入库')
          return false
        }
      } else {
        result = [data].map(item => { return { id: item.id } })
      }
      this.componentShow.storageForm = true
      this.$nextTick(() => {
        this.$refs.storageForm.init(result)
      })
    },

    // 申请出库
    applyOutbound ({ data = null }) {
      this.$confirm({
        title: '您确认要申请领样吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          let result = null
          if (data == null) {
            if (!this.rowSelection.selectedRows.length) {
              this.$message.error('请先选择一条数据')
              return false
            }
            const checkRows = this.rowSelection.selectedRows.filter(item => item.status === sampleInfoStatusEnum.storaged)
            this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
            if (checkRows && checkRows.length) {
              result = checkRows.map(item => { return { id: item.id } })
            } else {
              this.$message.error('暂无符合条件的样品')
              return false
            }
          } else {
            result = [data].map(item => { return { id: item.id } })
          }
          return tsSampleinfoApi.applyOutbound(result).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 出库
    outboundSample ({ data = null }) {
      let result = null
      if (data == null) {
        if (!this.rowSelection.selectedRows.length) {
          this.$message.error('请先选择一条数据')
          return false
        }
        const checkRows = this.rowSelection.selectedRows.filter(item => (item.status === sampleInfoStatusEnum.pickupApply))
        this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
        if (checkRows && checkRows.length) {
          result = checkRows.map(item => { return { id: item.id, pickupApplyUser: item.pickupApplyUser } })
        } else {
          this.$message.error('暂无符合条件的样品需要出库')
          return false
        }
      } else {
        result = [data].map(item => { return { id: item.id, pickupApplyUser: item.pickupApplyUser } })
      }
      this.componentShow.outboundForm = true
      this.$nextTick(() => {
        this.$refs.outboundForm.init(result)
      })
    },

    // 留样
    reservedSample ({ data = null }) {
      let result = null
      if (data == null) {
        if (!this.rowSelection.selectedRows.length) {
          this.$message.error('请先选择一条数据')
          return false
        }
        const checkRows = this.rowSelection.selectedRows.filter(item => (item.status === sampleInfoStatusEnum.storaged || item.status === sampleInfoStatusEnum.pickupApply))
        this.rowSelection.selectedRowKeys = checkRows.map(item => item.id)
        if (checkRows && checkRows.length) {
          result = checkRows.map(item => { return { id: item.id } })
        } else {
          this.$message.error('暂无符合条件的样品需要留样')
          return false
        }
      } else {
        result = [data].map(item => { return { id: item.id } })
      }
      this.componentShow.reservedForm = true
      this.$nextTick(() => {
        this.$refs.reservedForm.init(result)
      })
    },

    // 销毁
    destroySample (dataList) {
      const noInStatuses = [sampleInfoStatusEnum.doneSample, sampleInfoStatusEnum.storageApply, sampleInfoStatusEnum.destroyed]
      const resultList = dataList.filter(item => !noInStatuses.includes(item.status) && item.destroyApplyStatus === sampleDestroyApplyStatusEnum.pending)
      if (resultList.length) {
        this.destroyModal.show(resultList)
      } else {
        this.$message.error('暂无需要申请销毁的样品！')
        return false
      }
    },

    // 销毁
    deleteSample (dataList) {
      if (dataList.length) {
        this.deleteModal.show(dataList)
      } else {
        this.$message.error('暂无需要申请作废的样品！')
        return false
      }
    },

    // 处理重新安排
    handleReSchedule (record) {
      this.$emit('reScheduleData', record)
    },

    removeRecord (data, index) {
      this.$refs[this.contentTable.ref].localDataSource.splice(index, 1)
      const rowIndex = this.rowSelection.selectedRows.findIndex(item => item.id === data.id)
      if (rowIndex > -1) {
        this.rowSelection.selectedRows.splice(rowIndex, 1)
        this.rowSelection.selectedRowKeys.splice(rowIndex, 1)
      }
    },

    getData () {
      return this.$refs[this.contentTable.ref].localDataSource
    },

    handleCancelCheck (dataList) {
      this.$confirm({
        title: '您确认该样品不再检测吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          tsSampleinfoApi.cancelCheck(dataList).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    getRowSelection () {
      return this.rowSelection
    }
  }

}
</script>

<style lang="less" scoped>

.setting {
  position: absolute;
  z-index: 9;
  right: 12px;
  top: 12px
}
</style>
