'use strict'

import Vue from 'vue'
import form from './form'
import table from './table'
import cateLayoutTable from './table/CateLayoutTable'

Vue.component('check-reagent-form', form)
export {
  table as CheckReagentTable,
  cateLayoutTable as CheckReagentCateLayoutTable
}
