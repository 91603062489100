<template>
  <a-card title="图谱" v-bind="baseFormCard">
    <template #extra v-if="firstFlag !== 1">
      <template v-if="selectCheckGuide && selectCheckGuide.atlasStatus">
        <a-tag :color="qualityOptionDataMap[selectCheckGuide.atlasStatus]?.color">{{ qualityOptionDataMap[selectCheckGuide.atlasStatus]?.label }}</a-tag>
      </template>
    </template>

    <template v-if="(firstFlag === 1 && atlasList.length) || (firstFlag !== 1 && selectCheckGuide && selectCheckGuide.atlasStatus && selectCheckGuide.atlasStatus === '1')">
      <a-table row-key="id" v-bind="contentTable" :data-source="atlasList" :pagination="false">
        <template slot="atlasFileUrl" slot-scope="text, record">
          <template v-if="record.editable">
            <a-upload accept=".pdf" :action="uploadUrl" :headers="tokenHeader" :showUploadList="false" @change="(info) => handleChange(info, record)">
              <div v-if="record.atlasFileUrl" style="cursor: pointer">{{ record.atlasName }}.pdf</div>
              <a-button v-else> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </template>
          <template v-else
            ><a v-if="record.atlasFileUrl" @click="downLoadFile(record)">{{ record.atlasName }}.pdf</a></template
          >
        </template>
        <template slot="action" slot-scope="text, record, index">
          <template v-if="record.editable">
            <a @click="saveAtlas(record)" style="color: red">保存</a>
            <a-divider type="vertical" />
            <a @click="cancelAtlas(record)" style="color: red">取消</a>
          </template>
          <template v-else>
            <template v-if="record.id">
              <a @click="toggleAtlas(record)" style="color: red">编辑</a>
            </template>
            <template v-else>
              <a @click="toggleAtlas(record)" style="color: red">新增</a>
            </template>
          </template>
        </template>
      </a-table>
    </template>
  </a-card>
</template>

<script>
import { sysCommonApi } from '@/api/common'
import { useFormProps } from '@/pages/common/compositions/form/useForm'
import { qualityOptionDataMap } from '@/api/itemCheckDetail/itemCheckDetailConstant'
import moment from 'moment'
const { baseFormCard } = useFormProps()

export default {
  name: 'Atlas',
  inheritAttrs: false,
  components: {},
  props: {
    defaultMakeDate: { type: String, required: false, default: undefined },
    selectCheckGuide: { type: Object, required: false, default: undefined },
    atlasList: { type: Array, required: true },
    delFiles: { type: Array, required: false, default: () => [] },
    firstFlag: { type: Number, require: false, default: 0 }
  },
  data() {
    return {
      qualityOptionDataMap,
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      contentTable: {
        style: { padding: '16px', width: '100%', background: '#FFFFFF' },
        size: 'small',
        bordered: true,
        columns: [
          { title: '序号', align: 'center', dataIndex: 'no', width: '50' },
          { title: '项目号', align: 'center', dataIndex: 'projNo' },
          { title: '图谱名称', align: 'center', dataIndex: 'atlasName' },
          { title: '图谱上传', align: 'center', dataIndex: 'atlasFileUrl', scopedSlots: { customRender: 'atlasFileUrl' } },
          { title: '操作', align: 'action', dataIndex: 'action', width: '50', scopedSlots: { customRender: 'action' } }
        ]
      },
      oldAtlas: {},
      baseFormCard: baseFormCard
    }
  },
  watch: {
    defaultMakeDate(nv) {
      if (nv) {
        this.atlasList.forEach((item) => {
          let name1 = item.atlasName.split('图谱-')[0]
          let atlasName = name1 + '图谱-' + moment(nv).format('YYMMDD')
          this.$set(item, 'atlasName', atlasName)
        })
      }
    }
  },
  methods: {
    moment,
    handleChange(info, record) {
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          const fileUrl = info.file.response.data.fileUrl
          // const fileName = info.file.response.data.fileName
          this.$set(record, 'atlasFileUrl', fileUrl)
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },

    saveAtlas(record) {
      if ((this.oldAtlas.atlasFileUrl && record.atlasFileUrl && this.oldAtlas.atlasFileUrl !== record.atlasFileUrl) || (this.oldAtlas.atlasFileUrl && !record.atlasFileUrl)) {
        if (this.delFiles.indexOf(this.oldAtlas.atlasFileUrl) === -1) {
          this.delFiles.push(this.oldAtlas.atlasFileUrl)
        }
      }
      this.oldAtlas = {}
      this.$set(record, 'editable', false)
    },

    cancelAtlas(record) {
      if ((this.oldAtlas.atlasFileUrl && record.atlasFileUrl && this.oldAtlas.atlasFileUrl !== record.atlasFileUrl) || (!this.oldAtlas.atlasFileUrl && record.atlasFileUrl)) {
        if (this.delFiles.indexOf(record.atlasFileUrl) === -1) {
          this.delFiles.push(record.atlasFileUrl)
        }
      }
      for (const key in record) {
        record[key] = this.oldAtlas[key]
      }
      this.oldAtlas = {}
      this.$set(record, 'editable', false)
    },
    toggleAtlas(record) {
      this.oldAtlas = {}
      for (const key in record) {
        this.oldAtlas[key] = record[key]
      }
      this.$set(record, 'editable', true)
    },

    downLoadFile(file) {
      window.location = sysCommonApi.downLoad + '?filePath=' + file.atlasFileUrl + '&fileName=' + file.atlasName + '.pdf&token=' + this.$store.getters.token
    }
  }
}
</script>
<style lang="less">
</style>