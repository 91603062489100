<template>
  <div class="selectUser">
    <a-input-search v-model="selectedDeptUsers" :placeholder="placeholder" disabled @search="onSearchDepUser">
      <a-button slot="enterButton" :disabled="disabled">选择用户</a-button>
    </a-input-search>
    <select-user-by-dept-modal
      ref="selectModal"
      :modal-width="modalWidth"
      :rowType="rowType"
      @ok="onSearchDepUserCallBack" />
  </div>
</template>

<script>
import SelectUserByDeptModal from './modal/SelectUserByDeptModal'

export default {
  name: 'SelectUserByDept',
  components: { SelectUserByDeptModal },
  model: {
    prop: 'value',
    event: 'changeModel'
  },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    value: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false,
      default: '请选择用户'
    },
    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // 列表选择框类型 checkbox or radio
    rowType: {
      type: String,
      required: false,
      default: 'checkbox'
    }
  },
  data () {
    return {
      userList: [],
      selectedDeptUsers: ''
    }
  },
  mounted () {
    this.selectedDeptUsers = this.value
  },
  watch: {
    value (val) {
      this.selectedDeptUsers = val
    }
  },
  methods: {
    init () {
      this.selectedDeptUsers = ''
      this.userList = []
    },
    // 通过组织机构筛选选择用户
    onSearchDepUser () {
      this.$refs.selectModal.showModal(this.userList)
    },
    setUserList (userList) {
      this.userList = userList
      this.onSearchDepUserCallBack(userList)
    },
    onSearchDepUserCallBack (userList) {
      this.userList = userList
      let userNames = ''
      for (let i = 0; i < userList.length; i++) {
        if (i === userList.length - 1) {
          userNames = userNames + userList[i].name
        } else {
          userNames = userNames + userList[i].name + ','
        }
      }
      this.selectedDeptUsers = userNames
      if (this.triggerChange) {
        if (this.rowType === 'radio') {
          this.$emit('changeModel', userList?.[0]?.username)
        }
        this.$emit('change', userList)
      } else {
        if (this.rowType === 'radio') {
          this.$emit('changeModel', userList?.[0]?.username)
        }
        this.$emit('input', userList)
      }
    },
    searchList(userName){
      this.$refs.selectModal.searchList(userName)
    }
  }
}
</script>

<style lang="less">
.selectUser{
  .ant-input-disabled {
    background-color: unset;
    color: unset;
  }
}
</style>
