<template>
  <a-card style="margin-top: 24px" :bordered="false" title="客户信息">
    <detail-list :col="3" size="small" style="margin-left: 44px;">
      <detail-list-item term="客户名称">{{ info.csrName | nullReplace }} <a-icon type="chrome" v-if="info.csrYyzz" @click="openImg(info.csrYyzz)" style="cursor: pointer; color: #096dd9" title="营业执照" /></detail-list-item>
      <detail-list-item term="法人代表">{{ info.csrContact | nullReplace }}</detail-list-item>
      <detail-list-item term="联系方式">{{ info.csrTelephone | nullReplace }}</detail-list-item>
      <detail-list-item term="人员数量">{{ info.userNum | nullReplace }} <a-icon type="team" style="color: #1890ff;cursor: pointer;" @click="goCustomerUser" :title="`人员数量:${info.userNum}` "/></detail-list-item>
      <detail-list-item term="状态">
        <template v-if="info.csrState==1"><a-icon type="check-square" theme="twoTone"/>启用</template>
        <template v-else-if="info.csrState==0"><a-icon type="close-square" theme="twoTone" twoToneColor="#c41d7f"/>禁用</template>
        <template v-else>{{ info.csrState }}</template>
      </detail-list-item>
      <detail-list-item term="联系地址">{{ info.csrAddr | nullReplace }}<a @click="showLocation" v-if="info.csrAddr"><a-icon type="environment"/></a></detail-list-item>
    </detail-list>
    <MapLocation ref="mapLocation"></MapLocation>
    <a-modal :visible="previewVisible" :footer="null" @cancel="closeImg">
      <img alt="营业执照" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-card>
</template>

<script>
import { Get, customerApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import DetailList from '@/components/tools/DetailList'
import MapLocation from '@/pages/common/map/MapLocation'
const DetailListItem = DetailList.Item

export default {
  name: 'CustomerView',
  components: { DetailList, DetailListItem, MapLocation },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      title: '客户详情',
      info: {},
      previewVisible: false,
      previewImage: null
    }
  },
  methods: {
    get () {
      if (this.id) {
        var url = customerApi.get + '/' + this.id
        Get(url, { }).then(result => {
          if (result.code === 0) {
            this.info = result.data
          }
        })
      }
    },
    goCustomerUser () {
      if (this.info.id) {
        this.$router.push({ name: 'bo-custom-user', params: { csrId: this.info.id } })
      }
    },
    showLocation () {
      this.$refs.mapLocation.open()
    },
    openImg (url) {
      if (url !== '') {
        this.previewVisible = true
        this.previewImage = sysCommonApi.view + url
      }
    },
    closeImg () {
      this.previewVisible = false
    }
  },
  filters: {
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  watch: {
    id: {
      handler (newVal, oldVal) {
        this.get()
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
  .bm-view {
    height: 420px;
  }
</style>
<style scoped>
</style>
