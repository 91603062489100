<template>
  <div>
    <a-modal v-model="visible" :centered="true" :closable="false" :destroyOnClose="true" width="90%" wrapClassName="modal-container">
      <a-form>
        <page-view :title="title">
          <a-card :bordered="true" style="margin: 20px;" title="领用计划详情">

            <detail-list>
              <detail-list-item term="领用批次号">{{ this.purList.purUseNbr }}</detail-list-item>
              <detail-list-item term="领用批次名称">{{ this.purList.purUseNbr }}</detail-list-item>
              <detail-list-item term="申请人姓名">{{ this.purList.userName }}</detail-list-item>
              <detail-list-item term="领用申请日期">{{ this.purList.dateOfPurUse }}</detail-list-item>
              <detail-list-item term="领用原因">{{ this.purList.purUseReason }}</detail-list-item>
              <detail-list-item term="备注">{{ this.purList.purUseDesc }}</detail-list-item>
              <detail-list-item term="关联项目">{{ this.purList.projName }}</detail-list-item>
            </detail-list>
          </a-card>
          <a-card :bordered="true" style="margin: 20px;" title="物资信息">
            <a-table ref="table" :columns="columns" :dataSource="data" :pagination="false">
              <template v-for="(col, i) in ['cateId', 'materialName', 'speType','unit','materialNum','numOfUse']" :slot="col" slot-scope="text, record">

                <template v-if="col === 'cateId'">
                  {{ getName(text, record) }}
                </template>

                <template v-else-if="col==='unit'">
                  {{ materialUnitCode[text] }}
                </template>
                <template v-else>
                  {{ text }}
                </template>
              </template>
              <template slot="operation" slot-scope="text, record">
                <span>
                  <a-popconfirm title="是否要作废此行?" @confirm="remove(record)">
                    <a>移除</a>
                  </a-popconfirm>
                </span>
              </template>
            </a-table>
          </a-card>
        </page-view>
      </a-form>
      <template slot="footer" :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#FFFFFF',
          textAlign: 'right',
        }">
        <a-button :style="{marginRight: '8px'}" @click="handleCancel">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import { STable } from '@/components'
import DetailList from '@/components/tools/DetailList'
import { Get, resPurUseApi } from '@/api/quote'
import { getCateName, getProjectName } from '@/pages/cloud-quote/res/res-puruse/modules/materialReturn/common'

const DetailListItem = DetailList.Item
export default {
  name: 'PurPlanIndex',
  props: {
    locationList: { type: Array, default: () => [] },
    categoryList: { type: Array, default: () => [] },
    recordId: { type: Number, default: 0 }
  },
  components: {
    PageView,
    DetailList,
    DetailListItem,
    STable
  },
  data () {
    return {
      cateList: [],
      visible: false,
      data: [],
      purList: {},
      goodsList: [],
      title: '领用申请',
      /*  devColumns:[
        {
          title: '内部编号',
          dataIndex: 'fixedAssetsId',
          key: 'fixedAssetsId',
          width: '250px',
          scopedSlots: {customRender: 'fixedAssetsId'}
        },
        {
          title: '设备类别',
          dataIndex: 'cateId',
          key: 'cateId',
          width: '250px',
          scopedSlots: {customRender: 'cateId'}
        },
        {
          title: '设备名称',
          dataIndex: 'devName',
          key:'devName',
          width:'250px',
          scopedSlots: {customRender: 'devName'}
        },
        {
          title: '型号',
          dataIndex: 'speType',
          key: 'speType',
          width:'150px',
          scopedSlots: {customRender: 'speType'}
        },
        {
          title: '放置位置',
          dataIndex: 'locationId',
          key: 'locationId',
          width:'150px',
          scopedSlots: {customRender: 'locationId'}
        }
      ], */
      columns: [
        {
          title: '物品类别',
          dataIndex: 'cateId',
          key: 'cateId',
          width: '250px',
          scopedSlots: { customRender: 'cateId' }
        },
        {
          title: '物品名称',
          dataIndex: 'materialName',
          key: 'materialName',
          width: '250px',
          scopedSlots: { customRender: 'materialName' }
        },
        {
          title: '规格型号',
          dataIndex: 'speType',
          key: 'speType',
          width: '150px',
          scopedSlots: { customRender: 'speType' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: '150px',
          scopedSlots: { customRender: 'unit' }
        },
        /* {
          title: '总数量',
          width:'150px',
          dataIndex: 'materialNum',
          key: 'materialNum',
          scopedSlots: {customRender: 'materialNum'}
        }, */{
          title: '使用数量',
          width: '150px',
          dataIndex: 'numOfUse',
          key: 'numOfUse',
          scopedSlots: { customRender: 'numOfUse' }
        }

        /* {
           title: '操作',
           key: 'action',
           scopedSlots: { customRender: 'operation' }
         } */
      ]
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    },
    materialUnitCode () {
      return { ...this.$store.state.code.cache.material_unit_code }
    }
  },

  watch: {
    recordId: {
      handler (newValue, oldValue) {
        if (newValue && newValue > 0) {
          this.handleDetail(newValue)
        }
      },
      immediate: true
    }

  },

  created () {
    this.initDictData()
  },

  methods: {
    initDictData () {
      // 根据字典Code, 初始化字典数组
      this.$store.dispatch('code/select', 'material_unit_code')
    },

    async getName (text, record) {
      const cateName = await getCateName([record.cateId])
      cateName && (record.cateName = cateName || '-')
      return cateName
    },

    handleCancel () {
      this.visible = false
    },
    handleDetail (id) {
      Get(`${resPurUseApi.list}`, { pageSize: 1, id: id }, {}).then(async result => {
        Object.assign(this.purList, result.data[0])
        // 获取到purList之后 修改项目名称
        pIds && (this.purList.projName = await getProjectName(pIds))
        const pIds = this.purList.projId
      })

      Get(`${resPurUseApi.getGoodsById}/${id}`, {}).then(result => {
        this.data = result.data
      })
      this.visible = true
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'processing': '进行中',
        'success': '完成',
        'failed': '失败'
      }
      return statusMap[status]
    }
  }

}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
