<template>
  <div>
    <a-tabs v-model="activeKey">
      <a-tab-pane v-for="(item, index) in list" :key="index" :tab="item.testItem">
        <div class="content-row" v-if="item.equationData">
          <div style="margin: 10px 20px">y = {{ item.equationData.slope }}x + {{ item.equationData.intercept }}</div>
          <div style="margin: 10px 20px">R² = {{ item.equationData.correlationCoefficient }}</div>
        </div>

        <v-chart v-bind="customChart">
          <v-view>
            <v-tooltip />
            <v-axis v-bind="axis1Opts" />
            <v-axis v-bind="axis2Opts" />
            <v-legend v-bind="legend" />
            <v-line position="concentration*result" quickType="dashLine" />
            <v-point position="concentration*result" shape="circle" />
          </v-view>
          <!-- 线性趋势 -->
          <v-view :data="dv2">
            <v-line position="concentration*result" tooltip="false"></v-line>
          </v-view>
        </v-chart>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
const DataSet = require('@antv/data-set')
const scale = [{ dataKey: 'concentration' }, { dataKey: 'result', min: 0 }]
export default {
  name: 'CompositeCurvePreview',
  props: {
    list: {
      type: Array,
      required: false,
      default: []
    }
  },
  data() {
    return {
      activeKey: 0,
      // customChart: {
      //   forceFit: true,
      //   height: 400,
      //   data: this.curveSiteData,
      //   scale: scale
      // },
      // axis1Opts: {
      //   dataKey: 'concentration',
      //   // show: true,
      //   title: {
      //     text: this.axisData.concentration
      //   }
      // },
      // axis2Opts: {
      //   dataKey: 'result',
      //   title: {
      //     text: this.axisData.result
      //     // },
      //     // grid: {
      //     //   type: 'polygon',
      //     //   lineStyle: {
      //     //     lineDash: null
      //     //   }
      //   }
      // },
      dv2: [],
      legend: {
        dataKey: 'concentration',
        marker: 'circle',
        position: 'bottom',
        offset: '30'
      }
    }
  },
  computed: {
    customChart() {
      if (this.list && this.list.length) {
        let item = this.list[this.activeKey]
        return {
          forceFit: true,
          height: 400,
          data: item.curveSiteData,
          scale: scale
        }
      }
      return {}
    },
    axis1Opts() {
      if (this.list && this.list.length) {
        let item = this.list[this.activeKey]
        return {
          dataKey: 'concentration',
          // show: true,
          title: {
            text: item.axisData.concentration
          }
        }
      }
      return {}
    },
    axis2Opts() {
      if (this.list && this.list.length) {
        let item = this.list[this.activeKey]
        return {
          dataKey: 'result',
          title: {
            text: item.axisData.result
            // },
            // grid: {
            //   type: 'polygon',
            //   lineStyle: {
            //     lineDash: null
            //   }
          }
        }
      }
      return {}
    }
  },
  watch: {
    activeKey: {
      immediate: true,
      handler(nv, ov) {
        console.log(nv, ov)
        console.log(this.list);
        const dv2 = new DataSet.View().source(this.list[nv].curveSiteData)
        dv2.transform({
          type: 'regression',
          method: 'linear',
          fields: ['concentration', 'result'],
          bandwidth: 0.1,
          as: ['concentration', 'result']
        })
        console.log(dv2.rows);
        // this.$data.dv1 = dv1.rows
        this.dv2.splice(0, this.dv2.length, ...dv2.rows)
      }
    }
  },
  mounted() {
    // const ds = new DataSet()
    // const dv1 = ds.createView().source(data);
    // dv1.transform({
    //   type: 'map',
    //   callback: function callback(row) {
    //     if (typeof row.Deaths === 'string') {
    //       row.Deaths = row.Deaths.replace(',', '');
    //     }
    //     row.Deaths = parseInt(row.Deaths);
    //     row.death = row.Deaths;
    //     row.year = row.Year;
    //     return row;
    //   }
    // });
    // const dv2 = new DataSet.View().source(this.curveSiteData)
    // dv2.transform({
    //   type: 'regression',
    //   method: 'linear',
    //   fields: ['concentration', 'result'],
    //   bandwidth: 0.1,
    //   as: ['concentration', 'result']
    // })
    // // this.$data.dv1 = dv1.rows
    // this.$data.dv2 = dv2.rows
  },
  methods: {}
}
</script>

<style scoped>
.content-row {
  border: 1px dashed #e8e8e8;
  margin-bottom: 10px;
  padding: 10px 20px;
}
</style>
