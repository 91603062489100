const noActionColumn = [
  { title: '使用液编号', dataIndex: 'solutionNo', sorter: true, width: 120, align: 'left', scopedSlots: { customRender: 'solutionNo' } },
  { title: '使用液名称', dataIndex: 'name', align: 'left', sorter: true, scopedSlots: { customRender: 'name' } },
  { title: '储备量', dataIndex: 'sumVolume', align: 'left', scopedSlots: { customRender: 'sumVolume' } },
  { title: '是否标定', dataIndex: 'demarcateEnable', align: 'left', scopedSlots: { customRender: 'demarcateEnable' } },
  { title: '上级液编号', dataIndex: 'usageReagentNos', align: 'left', sorter: true, scopedSlots: { customRender: 'usageReagent' } },
  { title: '配置人', dataIndex: 'createUserName', width: 80, align: 'left', scopedSlots: { customRender: 'createUserName' } },
  { title: '时效', dataIndex: 'makeTime', sorter: true, align: 'left', scopedSlots: { customRender: 'makeTime' } },
  { title: '有效性', dataIndex: 'makeStatus', width: 80, align: 'left', scopedSlots: { customRender: 'makeStatus' } },
  { title: '复核人', dataIndex: 'reviewUserName', width: 80, align: 'left', scopedSlots: { customRender: 'reviewUserName' } },
  { title: '复核结果', dataIndex: 'reviewStatus', width: 100, align: 'left', sorter: true, scopedSlots: { customRender: 'reviewStatus' } },
  { title: '审核人', dataIndex: 'checkUserName', width: 80, align: 'left', scopedSlots: { customRender: 'checkUserName' } },
  { title: '审核结果', dataIndex: 'checkStatus', width: 100, align: 'left', sorter: true, scopedSlots: { customRender: 'checkStatus' } }
]

const defaultColumn = [...noActionColumn,
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

export {
  noActionColumn as checkStandardSolutionNoActionColumn,
  defaultColumn as checkStandardSolutionDefaultColumn
}

export default defaultColumn
