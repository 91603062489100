<template>
  <a-card :bordered="false" title="项目收款">
    <div class="detail-list">
      <a-row class="row" v-bind="listRow">
        <a-col v-bind="listCol">
          <div class="label">报价金额：</div>
          <div class="content"><span style="color: red">{{ currency(projInfo.quoteFinalAmount) }}</span></div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">合同金额：</div>
          <div class="content"><span style="color: red">{{ currency(projInfo.contractAmount) }}</span></div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">状态：</div>
          <div class="content">
            <a-tag v-if="projInfo.recptType=='0'" color="orange">部分收款</a-tag>
            <a-tag v-else-if="projInfo.recptType=='1'" color="green">完成收款</a-tag>
            <a-tag v-else color="red">未收款</a-tag>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">已确认收款：</div>
          <div class="content"><span style="color: red">{{ currency(projInfo.recptAmount) }}</span></div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">待确认收款：</div>
          <div class="content"><span style="color: red">{{ currency(projInfo.recptAmountUnconfirm) }}</span></div>
        </a-col>
      </a-row>
    </div>
    <ReceiptList :inParam="{projId:projId}"></ReceiptList>
  </a-card>
</template>

<script>

import { projectApi, projectFlowKey } from '@/api/project'
import ReceiptList from '@/pages/cloud-quote/proj-receipt/ReceiptList'
import { currency } from '@/utils/util'

export default {
  name: 'PVProjectView',
  components: {
    ReceiptList
  },
  props: {
    projId: {
      type: Number,
      default: null
    },
    projInfo: {
      type: Object,
      default: () => { }
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      listRow: {
        gutter: [8, 12]
      },
      listCol: {
        xxl: { span: 8 },
        xl: { span: 8 },
        lg: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
        xs: { span: 8 }
      },
      itemCol: 2,
      info: {}
    }
  },
  methods: {
    currency,
    get () {
      if (this.projId) {
        // projectApi.get({ id: this.id }).then(result => {
        //   if (result.code === 0) {
        //     this.info = result.data
        //   }
        // })
      }
    }
  },
  filters: {
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  computed: {
  },
  watch: {
    projId: {
      handler (nv, ov) {
        this.get()
      },
      immediate: true
    },
    data: {
      handler (nv, ov) {
        this.info = nv
      },
      immediate: true
    }
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
  }
}
</script>

<style lang="less">
  .detail-list{
    margin: 8px 44px;
    .row{
    }
    .label{
      color: rgba(0, 0, 0, 0.85);
      display: table-cell;
      line-height: 20px;
      margin-right: 8px;
      padding-bottom: 10px;
      white-space: nowrap;
    }
    .content{
      color: rgba(0, 0, 0, 0.65);
      display: table-cell;
      min-height: 22px;
      line-height: 22px;
      padding-bottom: 10px;
      width: 100%;
    }
  }
</style>
