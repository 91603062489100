<template>
  <div>
    <QuoteSearchDiv :queryParam.sync="queryParam" :defaultParam="defaultParam" :show="show" :simple="simple" @search="search"/>
    <a-card :bordered="true" class="card-table">
      <div>
        <s-table v-bind="table" v-on="table" :scroll="scrollProp">
          <template slot="projectTitle" slot-scope="v, r">
            <a-tag v-if="r.quoteType=='BJ'"color="pink">自行检测 </a-tag>
            <a-tag v-else-if="r.quoteType=='YD'" color="red">月度单</a-tag>
            <a-tag v-else-if="r.quoteType=='JD'" color="orange">季度单</a-tag>
            <a-tag v-else-if="r.quoteType=='BN'" color="cyan">半年度</a-tag>
            <a-tag v-else-if="r.quoteType=='ND'" color="blue">年度单</a-tag>
            <a-tag v-else color="red">{{ r.quoteType }}</a-tag>
            <a-tag v-if="r.quoteType!='BJ'&&r.quoteMonth" color="#2db7f5">{{ r.quoteMonth }}月</a-tag>
            {{ r.projectTitle }}
          </template>

          <template slot="sampleSource" slot-scope="v, r">
            {{ sampleSourceMap[v] }}
          </template>
          <template slot="reportType" slot-scope="v, r">
            {{ reportTypeMap[v] }}
          </template>
          <template slot="status" slot-scope="v, r">
            <a-tag v-if="r.status=='0'" color="orange">完善中</a-tag>
            <a-tag v-if="r.status=='6'" color="red">报价中</a-tag>
            <a-tag v-if="r.status=='1'" color="green">赢单</a-tag>
            <a-tag v-if="r.status=='2'" color="pink">提交审核</a-tag>
            <a-tag v-if="r.status=='3'" color="cyan">方案审核</a-tag>
            <a-tag v-if="r.status=='4'" color="blue">部门审核</a-tag>
            <a-tag v-if="r.status=='5'" color="purple">经理审核</a-tag>
            <a-tag v-if="r.status=='-2'" color="red">已废弃</a-tag>
          </template>
          <template slot="quoteContactsName" slot-scope="v, r">
            {{ r.quoteContactsName }}
          </template>
        </s-table>
        <Watermark/>
      </div>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { quoteApi, Get } from '@/api/quote'
import QuoteSearchDiv from './QuoteSearchDiv'
import { search, scrollTable } from '@/pages/common/mixins/list'
import { currency } from '@/utils/util'

export default {
  name: 'SelectQuoteList',
  components: {
    STable,
    QuoteSearchDiv
  },
  mixins: [search, scrollTable],
  props: {
    showSearchDiv: {
      type: Boolean,
      default: true
    },
    inParam: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: true
    },
    simple: {
      type: Boolean,
      default: false
    },
    checkType: {
      //	多选/单选，checkbox or radio
      type: String,
      default: 'checkbox'
    }
  },
  data () {
    return {
      // 查询参数
      moreParam: false,
      defaultParam: { },
      queryParam: { },
      table: {
        ref: 'table',
        size: 'small',
        loading: false,
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '单号', width: '130px', dataIndex: 'quoteSn', scopedSlots: { customRender: 'quoteSn' } },
          { title: '项目名称', dataIndex: 'projectTitle', width: '200px', scopedSlots: { customRender: 'projectTitle' } },
          { title: '状态', dataIndex: 'status', width: '80px', scopedSlots: { customRender: 'status' } },
          { title: '负责人', dataIndex: 'quoteContactsName', width: '90px' }
        ],
        // 加载数据方法 必须为 Promise 对象
        data: parameter => {
          var params = Object.assign(parameter, this.queryParam)
          return quoteApi.list(params).then(res => {
            if (res.code === 0) {
              return res.data
            } else {
              this.$message.error('查询失败:' + res.msg)
              return null
            }
          })
        },
        rowSelection: {
          type: this.checkType,
          onChange: (selectedRowKeys, selectedRows) => {
            this.table.selectedRowKeys = selectedRowKeys
            this.table.selectedRows = selectedRows
          }
        }
      }
    }
  },
  methods: {
    currency,
    search (bool = true) {
      // 如果参数为 true, 则强制刷新到第一页
      this.$refs.table.refresh(bool)
    },
    queueSearch (bool = true) {
      var self = this
      if (self.$refs.table.localLoading) {
        // 表正在请求数据，等待执行
        setTimeout(function () {
          self.queueSearch(bool)
        }, 200)
      } else {
        self.search(bool)
      }
    },
    getSelected () {
      var res = { keys: this.table.selectedRowKeys, rows: this.table.selectedRows }
      return res
    }
  },
  watch: {
    inParam: function (newVal, oldVal) {
      const self = this
      if (newVal !== oldVal) {
        Object.assign(this.queryParam, this.inParam)
        self.$nextTick(function () {
          self.search()
        })
      }
    }
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    },
    quoteTypeMap () {
      return { ...this.$store.state.code.cache.quote_type }
    }
  },
  mounted () {
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
    this.$store.dispatch('code/select', 'quote_type')
  }
}
</script>
<style lang="less">
</style>
