<template>
  <div class="logo">
    <router-link :to="{ name: 'dashboard' }">
      <img v-if="configMap['index_logo']" :src="this.viewUrl + configMap['index_logo']" :alt="title" />
      <img v-else src="~@/assets/logo.png" :alt="title" />
      <!-- <div style="width: 120px; height: 40px; display: inline-block">
        <img
          v-if="configMap['index_name_logo']"
          :src="this.viewUrl + configMap['index_name_logo']"
          style="height: 100%; width: inherit; margin-left: 15px"
        />
        <img v-else src="~@/assets/system_name.png" style="height: 100%; width: inherit; margin-left: 15px" />
      </div> -->
    </router-link>
  </div>
</template>
  
<script>
import { sysCommonApi } from '@/api/common'

export default {
  name: 'Logo',
  data() {
    return {
      viewUrl: sysCommonApi.view,
    }
  },
  props: {
    title: {
      type: String,
      default: '环境检测业务管理系统',
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    configMap() {
      return { ...this.$store.getters.sys_config }
    },
  },
}
</script>
<style scoped>
</style>