<template>
  <a-card :bordered="false" title="项目信息">
    <div class="detail-list">
      <a-row class="row" v-bind="listRow">
        <a-col v-bind="listCol">
          <div class="label">项目名称：</div>
          <div class="content">
            <a @click="gotoFlowDetail(info)"><a-icon type="share-alt" style="margin-right: 4px"/>{{ info.projNo }}
              <template v-if="info.projNumber">/ {{ info.projNumber }}</template></a>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">项目名称：</div>
          <div class="content">{{ info.projName }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">状态：</div>
          <div class="content">
            <div v-if="info.projStatus === '0'">进行中</div>
            <div v-else-if="info.projStatus === '1'">完结</div>
            <div v-else-if="info.projStatus === '2'">临时终止</div>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">订单金额：</div>
          <div class="content"><span style="color: red">{{ currency(info.quoteFinalAmount) }}</span></div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">报告类型：</div>
          <div class="content">{{ reportTypeMap[info.projReportType] }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">评审状态：</div>
          <div class="content">
            <a-tag v-if="!info.auditStatus||info.auditStatus=='0'" color="orange">未评审</a-tag>
            <a-tag v-if="info.auditStatus=='2'" color="cyan" @click="gotoProjectCheckFlowDetail(info)">评审中</a-tag>
            <a-tag v-if="info.auditStatus=='1'" color="blue" @click="gotoProjectCheckFlowDetail(info)">已评审</a-tag>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">样品来源：</div>
          <div class="content">
            <a-tag v-if="info.projSampleSource==='1'" color="cyan">{{ sampleSourceMap[info.projSampleSource] }}</a-tag>
            <a-tag v-else-if="info.projSampleSource==='2'" color="blue">{{ sampleSourceMap[info.projSampleSource] }}</a-tag>
            <a-tag v-else>{{ sampleSourceMap[info.projSampleSource] }}</a-tag>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">负责人：</div>
          <div class="content">{{ info.projContact | nullReplace }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">联系电话：</div>
          <div class="content">{{ info.projContactPhone | nullReplace }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">委托单位：</div>
          <div class="content">{{ info.entrustCustomerName | nullReplace }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">项目描述：</div>
          <div class="content">{{ info.projDescription | nullReplace }}</div>
        </a-col>
        <a-col :span="24">
          <div class="label">项目文件：</div>
          <div class="content"><FileList :fileList="info.fileList"/></div>
        </a-col>
      </a-row>
    </div>
  </a-card>
</template>

<script>

import { projectApi, projectFlowKey } from '@/api/project'
import FileList from '@/pages/common/file/FileList'
import { currency } from '@/utils/util'

export default {
  name: 'PVProjectView',
  components: {
    FileList
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      listRow: {
        gutter: [8, 12]
      },
      listCol: {
        xxl: { span: 8 },
        xl: { span: 8 },
        lg: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
        xs: { span: 8 }
      },
      itemCol: 3,
      info: {}
    }
  },
  methods: {
    currency,
    get () {
      if (this.id) {
        projectApi.get({ id: this.id }).then(result => {
          if (result.code === 0) {
            this.info = result.data
          }
        })
      }
    },
    gotoFlowDetail (r) {
      projectApi.listProcess({ key: projectFlowKey.projectFlow, mxId: r.id }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            var obj = res.data[0]
            this.$router.push({ name: 'flow-process-detail', params: { processId: obj.processInstanceId } })
          } else {
            this.$message.warn(`未找到相关流程`)
          }
        }
      })
    },
    gotoProjectCheckFlowDetail (r) {
      projectApi.listProcess({ key: projectFlowKey.projectCheckFlow, mxId: r.auditId }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            var obj = res.data[0]
            this.$router.push({ name: 'flow-process-detail', params: { processId: obj.processInstanceId } })
          } else {
            this.$message.warn(`未找到相关流程`)
          }
        }
      })
    }
  },
  filters: {
    nullReplace (val) {
      if (!val) {
        return '-'
      }
      return val
    }
  },
  computed: {
    reportTypeMap () {
      return { ...this.$store.state.code.cache.proj_report_type }
    },
    sampleSourceMap () {
      return { ...this.$store.state.code.cache.sample_source }
    }
  },
  watch: {
    id: {
      handler (newVal, oldVal) {
        this.get()
      },
      immediate: true
    },
    data: {
      handler (newVal, oldVal) {
        this.info = newVal
      },
      immediate: true
    }
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
  }
}
</script>

<style lang="less">
  .detail-list{
    margin: 8px 44px;
    .row{
    }
    .label{
      color: rgba(0, 0, 0, 0.85);
      display: table-cell;
      line-height: 20px;
      margin-right: 8px;
      padding-bottom: 10px;
      white-space: nowrap;
    }
    .content{
      color: rgba(0, 0, 0, 0.65);
      display: table-cell;
      min-height: 22px;
      line-height: 22px;
      padding-bottom: 10px;
      width: 100%;
    }
  }
</style>
