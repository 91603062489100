<template>
  <div style="position: relative">
    <customize-column v-model="contentTable.columns" cache-key-pre="make-sample-table">
      <template #button>
        <a-icon type="setting" class="setting"></a-icon>
      </template>
    </customize-column>
    <s-table :rowSelection="rowSelection" :scroll="scrollProp" v-bind="contentTable">
      <template #projName="data, record">
        <span>【{{ record.projNo }}】{{ record.projName }}</span>
      </template>

      <template #type="type">
        <a-tag :color="sampleInfoTypeDataMap[type].color">{{ sampleInfoTypeDataMap[type].label }}</a-tag>
      </template>

      <template #projName="data, record"> 【{{ record.projNo }}】{{ data }} </template>

      <template #makeTime="data, record">
        <template v-if="record.makeBeginTime">
          <span style="color: green">起：</span>{{ record.makeBeginTime | minuteFilter }}<br />
          <span style="color: red">止：</span>{{ record.makeEndTime | minuteFilter }}
        </template>
      </template>

      <template #itemNameStr="data, record"> {{ record.cateNameStr }}/{{ data }} </template>

      <template #volume="data, record">
        <span v-if="data">
          <span style="font-weight: bolder; font-size: 16px">{{ data }}</span> {{ record.volumeUnit }}
        </span>
        <span v-else>-</span>
      </template>

      <template #validity="data, record">
        <span
          v-if="data"
        ><span style="font-weight: bolder; font-size: 16px">{{ data }}</span> {{ record.validityUnit }}</span
        >
        <span v-else>-</span>
      </template>

      <template #status="status">
        <a-tag :color="makeSampleInfoStatusDataMap[status].color">{{
          makeSampleInfoStatusDataMap[status].label
        }}</a-tag>
      </template>

      <template #action="text, record">
        <a @click="show(record)">
          查看申请
          <a-divider type="vertical" />
        </a>

        <a @click="makeSingleSample(record)" v-if="record.status === makeSampleInfoStatusEnum.pending">
          制样
          <a-divider type="vertical" />
        </a>

        <a @click="outBoundSingleSample(record)" v-if="record.status === makeSampleInfoStatusEnum.pickupApply">
          出库
          <a-divider type="vertical" />
        </a>
      </template>
    </s-table>
  </div>
</template>

<script>
import STable from '@/components/Table'
import list from '@/pages/common/mixins/list'
import mixinDate from '@/pages/common/mixins/date'

import { makeSampleApi } from '@/api/makeSample/makeSamplelApi'
import { sampleInfoTypeDataMap } from '@/api/sampleInfo/sampleInfoConstant'
import {
  makeSampleInfoStatusDataMap,
  makeSampleInfoStatusEnum,
  makeSampleInfoStatusMap
} from '@/api/makeSample/makeSampleConstant'

import { CustomizeColumn } from '@/components/Columns'

export default {
  name: 'SampleItemTable',
  inheritAttrs: false,
  mixins: [list, mixinDate],

  components: { STable, CustomizeColumn },
  props: {
    projNo: {
      type: String,
      default: ''
    },
    rowSelection: {
      type: Object,
      default: null
    },
    scrollProp: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      sampleInfoTypeDataMap,
      makeSampleInfoStatusEnum,
      makeSampleInfoStatusMap,
      makeSampleInfoStatusDataMap,
      contentTable: {
        rowKey: 'idTemp',
        bordered: true,

        columns: [
          { title: '样品编号', align: 'center', sorter: true, dataIndex: 'id', width: 160 },
          {
            title: '样品类型',
            align: 'center',
            sorter: true,
            dataIndex: 'type',
            width: 100,
            scopedSlots: { customRender: 'type' }
          },
          {
            title: '项目名称',
            align: 'center',
            sorter: true,
            dataIndex: 'projName',
            scopedSlots: { customRender: 'projName' }
          },
          {
            title: '检测项',
            align: 'center',
            dataIndex: 'itemNameStr',
            width: 200,
            scopedSlots: { customRender: 'itemNameStr' }
          },
          { title: '制样人', align: 'center', sorter: true, dataIndex: 'makeUserName', width: 100 },
          {
            title: '制样时间',
            align: 'center',
            sorter: true,
            dataIndex: 'makeBeginTime',
            width: 180,
            scopedSlots: { customRender: 'makeTime' }
          },
          { title: '存储介质', align: 'center', dataIndex: 'storage', width: 120 },
          { title: '份数', align: 'center', dataIndex: 'num', width: 80 },
          {
            title: '体积/重量',
            align: 'center',
            dataIndex: 'volume',
            width: 90,
            scopedSlots: { customRender: 'volume' }
          },
          {
            title: '有效期',
            align: 'center',
            dataIndex: 'validity',
            width: 80,
            scopedSlots: { customRender: 'validity' }
          },
          {
            title: '当前状态',
            align: 'center',
            dataIndex: 'status',
            width: 80,
            scopedSlots: { customRender: 'status' }
          },
          { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } }
        ]
      }
    }
  },

  methods: {
    // 根据查询条件获取数据
    loadData (param) {
      param.sortField = param.sortField?.replace(/([A-Z])/g, '_$1')?.toUpperCase()
      return makeSampleApi.listDetailsByProjNo(Object.assign({}, param, { projNo: this.projNo })).then((res) => res)
    },
    show (record) {
      this.$emit('show', record)
    },
    makeSingleSample (record) {
      this.$emit('makeSingleSample', record)
    },
    outBoundSingleSample (record) {
      this.$emit('outBoundSingleSample', record)
    }
  }
}
</script>

<style lang="less" scoped>
.setting {
  position: absolute;
  z-index: 9;
  right: 16px;
  top: 14px
}
</style>
