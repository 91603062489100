<template>
  <a-popover
    v-model="visible"
    trigger="click"
    placement="bottomRight"
    overlayClassName="header-notice-wrapper"
    :autoAdjustOverflow="true"
    :arrowPointAtCenter="true"
    :overlayStyle="{ width: '300px', top: '50px' }"
  >
    <div class="msg-dev" slot="content">
      <a-spin :spinning="loadding">
        <a-tabs>
          <a-tab-pane tab="待立项" key="1" class="tab-pane">
            <div class="msg-body-div">
              <a-list>
                <a-list-item v-for="(obj,idx) in projectList" :key="idx">
                  <a-list-item-meta>
                    <a-avatar :style="{'background': '#1890ff','borderRadius': '5px','marginRight': '5px'}" slot="avatar" src="/flow/jx_res_purplan.png" onerror="this.src='/flow/jx_universal_doc.png'"/>
                    <a slot="title" @click="gotoProject('unproject')">{{ obj.title }}</a>
                    <span slot="description">{{ formatTimeDiff(obj.time) }}</span>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </a-tab-pane>
          <a-tab-pane tab="消息" key="2" class="tab-pane">
            <div class="read-Btn-div">
              <span><a style="float: right" @click="readAlMsg">一键已读</a></span>
            </div>
            <div class="msg-body-div" style="height: 372px">
              <a-list>
                <a-list-item v-for="(obj,idx) in msgList" :key="idx">
                  <a-list-item-meta>
                    <a-avatar :style="{'background': '#1890ff','borderRadius': '5px','marginRight': '5px'}" slot="avatar" src="/flow/jx_res_purplan.png" onerror="this.src='/flow/jx_universal_doc.png'"/>
                    <a slot="title" @click="gotoMsg(obj)">
                      <a-badge :dot="obj.read!='1'">
                        {{ obj.title }}
                      </a-badge>
                    </a>
                    <span slot="description">
                      {{ obj.text }}<br/>
                      {{ formatTimeDiff(obj.time) }}
                    </span>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </a-tab-pane>
          <a-tab-pane tab="待处理" key="3" class="tab-pane">
            <div class="msg-body-div">
              <a-list>
                <a-list-item v-for="(obj,idx) in flowMsgList" :key="idx">
                  <a-list-item-meta>
                    <a-avatar :style="{'background': obj.iconColor,'borderRadius': '5px','marginRight': '5px'}" slot="avatar" :src="getIcon(obj.icon)" onerror="this.src='/flow/jx_universal_doc.png'"/>
                    <a slot="title" @click="gotoFlowDetail(obj)">{{ obj.title }}</a>
                    <span slot="description">{{ formatTimeDiff(obj.time) }}</span>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
    <span @click="fetchNotice" class="header-notice">
      <a-badge :count="msgNum">
        <a-icon style="font-size: 16px; padding: 4px" type="bell" />
        <app-audio ref="appAudio" :src="['/static/audio/notification.mp3']"></app-audio>
      </a-badge>
    </span>
  </a-popover>
</template>

<script>
import { msgPushLogApi } from '@/api/msg'
import AppAudio from '@/pages/common/Audiojs'
import { formatTimeDiff } from '@/utils/util'
export default {
  name: 'UserNoticeIcon',
  components: {
    AppAudio
  },
  data () {
    return {
      flowMsgList: [],
      projectList: [],
      msgList: [],
      loadding: false,
      visible: false
    }
  },
  methods: {
    formatTimeDiff,
    fetchNotice () {
      // if (!this.visible) {
      //   this.loadding = true
      //   setTimeout(() => {
      //     this.loadding = false
      //   }, 1000)
      // } else {
      //   this.loadding = false
      // }
      // this.visible = !this.visible
    },
    fetchLastNotice (msg) {
      // 最新的消息
      if (msg) {
        if (msg.type === 'FLOW-TODO') {
          this.flowMsgList = msg.data
        }
        if (msg.type === 'PROJECT') {
          this.projectList = msg.data
        }
        if (msg.type === 'MSG') {
          this.msgList = msg.data
        }
        this.tipTonePlay()
      }
    },
    gotoProject (flag) {
      var params = { enterType: 'search' }
      var paramsMap = {
        'unproject': { projProgress: 'quote' },
        'untask': { taskStatus: '0' },
        'unchecked': { auditStatus: '0' },
        'checking': { auditStatus: '2' },
        'checked': { auditStatus: '1' }
      }
      this.$router.push({ name: 'project', params: Object.assign(params, paramsMap[flag]) })
    },
    gotoFlowDetail (obj) {
      if (this.$route.name === 'flow-process-detail') {
        this.$router.go(-1)
        setTimeout(() => {
          this.$router.push({ name: 'flow-process-detail', params: { processId: obj.businessId } })
        }, 50)
      } else {
        this.$router.push({ name: 'flow-process-detail', params: { processId: obj.businessId } })
      }
    },
    gotoMsg (obj) {
      if (obj.read !== '1') {
        this.readMsg([obj.id])
      }
      var paramsMap = {
        'sampling-plan': { routeName: 'ts-sample' },
        'sampling-qc': { routeName: 'quality-control' },
        'sampling-start': { routeName: 'ts-sample' },
        'sampling-review': { routeName: 'ts-sample' },
        'sampling-check': { routeName: 'ts-sample' },
        'sampling-receive': { routeName: 'sample-info' },
        'test-plan': { routeName: 'check-order' },
        'test-start': { routeName: 'check-task' },
        'test-result-entry': { routeName: 'check-task' },
        'test-review': { routeName: 'check-review-list' },
        'test-check': { routeName: 'check-approval' },
        'report-plan': { routeName: 'report-order' },
        'report-start': { routeName: 'report-task' },
        'report-review': { routeName: 'report-task' },
        'report-check': { routeName: 'report-task' },
        'report-send': { routeName: 'report-send' }
      }
      var paramsObj = paramsMap[obj.attribute.data.nodeCode]
      if (paramsObj && paramsObj.routeName) {
        var projId = null
        if (obj.attribute.data.relId) {
          projId = Number(obj.attribute.data.relId)
        }
        if (this.$route.name === paramsObj.routeName) {
          this.$router.go(-1)
          setTimeout(() => {
            this.$router.push({ name: paramsObj.routeName, params: { projId }, props: (route) => ({ projId }) })
          }, 50)
        } else {
          this.$router.push({ name: paramsObj.routeName, params: { projId }, props: (route) => ({ projId }) })
        }
      }
    },
    readAlMsg () {
      var idList = []
      this.msgList.forEach(msg => {
        if (msg.id && msg.read !== '1') {
          idList.push(msg.id)
        }
      })
      this.readMsg(idList)
    },
    readMsg (idList) {
      if (idList && idList.length > 0) {
        msgPushLogApi.read(JSON.stringify(idList)).then(res => {
          if (res.code === 0) {
            var msgIdMap = {}
            this.msgList.forEach(msg => {
              msgIdMap[msg.id] = msg
            })
            idList.forEach(id => {
              var msg = msgIdMap[id]
              if (msg) {
                msg.read = '1'
              }
            })
          }
        })
      }
    },
    getIcon (icon) {
      if (icon) {
        return icon.startsWith('/') ? icon : '/' + icon
      }
      return '/flow/jx_universal_doc.png'
    },
    tipTonePlay () {
      this.$refs.appAudio.play()
    }
  },
  computed: {
    msgNum () {
      var num1 = this.flowMsgList.length
      var num2 = this.projectList.length
      var num3 = this.msgList.length
      return num1 + num2 + num3
    }
  }
}
</script>

<style lang="less">
  .header-notice-wrapper {
    top: 50px !important;
  }
  .header-notice{
    display: inline-block;
    transition: all 0.3s;
    span {
      vertical-align: initial;
    }
  }
  .msg-dev {
    .tab-pane{
      height: 400px;
      overflow-y: auto;
    }
    .msg-body-div{
      height: 400px;
      overflow-y: auto;
      padding: 0px 5px;
    }
    .ant-tabs-nav .ant-tabs-tab {
      margin: unset;
    }
    .ant-list-item {
      padding: 8px 0;
    }
    .read-Btn-div{
      width: 100%;
      padding-bottom: 8px;
      padding-right: 8px;
      height: 28px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
</style>
