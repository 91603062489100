import { axios } from '@/utils/request'
import { getAction, postAction, postDataAction, delAction } from './common'

const itemBasePre = '/ms-api/item-base'
const itemPre = '/ms-api/item'
const itemClausePre = '/ms-api/item-clause'

// 检测项API
export const itemBaseApi = {
  list: (p) => getAction(`${itemBasePre}/list`, p),
  get: (p) => getAction(`${itemBasePre}/get`, p),
  add: (p) => postDataAction(`${itemBasePre}/add`, p),
  edit: (p) => postDataAction(`${itemBasePre}/edit`, p),
  dels: (p) => delAction(`${itemBasePre}/dels`, p)
}

export const itemApi = {
  list: (p) => getAction(`${itemPre}/list`, p),
  listItem: (p) => getAction(`${itemPre}/listItem`, p),
  get: (p) => getAction(`${itemPre}/get`, p),
  add: (p) => postAction(`${itemPre}/add`, p),
  edit: (p) => postAction(`${itemPre}/edit`, p),
  dels: (p) => delAction(`${itemPre}/dels`, p),
  listByCateType: '/ms-api/item/list-by-cate-type',
  listByIds: (p) => postDataAction('/ms-api/item/listByIds', p),
  del: '/ms-api/item/del',
  treeStandardMethod: (p) => getAction(`${itemPre}/treeStandardMethod`, p),
  checkCode: (p) => getAction(`${itemPre}/checkCode`, p),
  getCode: (p) => getAction(`${itemPre}/getCode`, p)
}

export const itemClauseApi = {
  list: (p) => getAction(`${itemClausePre}/list`, p),
  get: (p) => getAction(`${itemClausePre}/get`, p),
  add: (p) => postDataAction(`${itemClausePre}/add`, p),
  edit: (p) => postDataAction(`${itemClausePre}/edit`, p),
  dels: (p) => delAction(`${itemClausePre}/dels`, p),
  validateName: (p) => getAction(`${itemClausePre}/validateName`, p)
}

export const itemGroupApi = {
  list: (p) => getAction('/ms-api/item-group/list', p),
  add: (p) => postAction('/ms-api/item-group/add', p),
  edit: (p) => postAction('/ms-api/item-group/edit', p),
  dels: (p) => delAction('/ms-api/item-group/dels', p)
}

export const subItemApi = {
  list: (p) => getAction('/ms-api/subitem/list', p),
  add: (p) => postAction('/ms-api/subitem/add', p),
  edit: (p) => postAction('/ms-api/subitem/edit', p),
  del: (id) => delAction('/ms-api/subitem/del/' + id, {}),
  dels: (p) => delAction('/ms-api/subitem/dels', p)
}

// mpnApi
export const mpnApi = {
  selectListByItemId: (p) => getAction(`/ms-api/mpnConfigController/selectListByItemId/${p}`),  
  addOrUpdate:(p) => postAction('/ms-api/mpnConfigController/addOrUpdate', p),      //新增 
  deleted:(p) => delAction(`/ms-api/mpnConfigController/deleted/${p}`), 
}

export const itemDevApi = {
  listByItemId: '/ms-api/item-dev/get',
  add: '/ms-api/item-dev/add',
  edit: '/ms-api/item-dev/edit',
  del: '/ms-api/item-dev/del'
}

export const itemCategoryApi = {
  list: (p) => getAction('/ms-api/category/list', p),
  treeList: (p) => getAction('/ms-api/category/tree-list', p),
  add: (p) => postAction('/ms-api/category/add', p),
  edit: (p) => postAction('/ms-api/category/edit', p),
  del: '/ms-api/category/del',
  getItems: '/ms-api/category/get-items',
  getAll: '/ms-api/category/getAll',
  listCateType: (p) => getAction('/ms-api/category/listCateType', p),
  timeList: (p) => getAction('/ms-api/category-config/list', p),
  timeAddOrEdit: (p) => postAction('/ms-api/category-config/addOrEdit', p),
  timeDel: (p) => delAction('/ms-api/category-config/del', p),
  getCate: (p) => getAction('/ms-api/sampling-plan/getCate', p),
  copyAddOrEdit: (p) => postDataAction('/ms-api/category-config-copy/addOrEdit', p),
  templateAddOrEdit: (p) => postDataAction('/ms-api/check-category-template/addOrEdit', p),
  findTemplateByCateId: (p) => getAction('/ms-api/check-category-template/findByCateId', p),
  findTemplateByProjId: (p) => getAction('/ms-api/check-category-template/findByProjId', p),
  listByCateId: (p) => getAction('/ms-api/report_check_data/listByCateId', p),

}
export const reportVerificationApi = {
  eightMajorIonsCheckResult: (p) => getAction('/ms-api/ts-result-check-out/eightMajorIonsCheckResult', p),
  pooledAnalysisEightIons: (p) => getAction('/ms-api/ts-result-check-out/pooledAnalysisEightIons', p),
  pooledAnalysisItemResultSize: (p) => getAction('/ms-api/ts-result-check-out/pooledAnalysisItemResultSize', p),
  soilItemResultSize: (p) => getAction('/ms-api/ts-result-check-out/pooledAnalysisSoilItemResultSize', p),
  airItemResultSize: (p) => getAction('/ms-api/ts-result-check-out/pooledAnalysisAirItemResultSize', p),

}
// 报告校验
export const reportCheckApi={
  saveOrUpdate: (p) => postDataAction('/ms-api/report_check_data/saveOrUpdate', p),
  list: (p) => getAction('/ms-api/report_check_data/list', p),
  del: (p) => delAction(`/ms-api/report_check_data/del/${p}`),
}
// 商机
export const businessApi = {
  list: (p) => getAction('/ms-api/business/list', p),
  listIncludeQuote: (p) => getAction('/ms-api/business/listIncludeQuote', p),
  statTableHeader: (p) => getAction('/ms-api/business/statTableHeader', p),
  add: (p) => postAction('/ms-api/business/add', p),
  edit: (p) => postAction('/ms-api/business/edit', p),
  validateTitle: (p) => getAction('/ms-api/business/validateTitle', p),
  del: (p) => delAction('/ms-api/business/del', p),
  dels: (p) => delAction('/ms-api/business/dels', p),
  getAll: (p) => getAction('/ms-api/business/getAll', p)
}

export const businessFollowApi = {
  list: (p) => getAction('/ms-api/business-follow/list', p),
  listPage: (p) => getAction('/ms-api/business-follow/list-page', p),
  add: (p) => postAction('/ms-api/business-follow/add', p),
  del: (p) => delAction('/ms-api/business-follow/del', p)
}

export const quoteApi = {
  // list: '/ms-api/quote/list',
  list: (p) => getAction('/ms-api/quote/list', p),
  listMainQuote: (p) => getAction('/ms-api/quote/listMainQuote', p),
  add: (p) => postAction('/ms-api/quote/add', p),
  edit: (p) => postAction('/ms-api/quote/edit', p),
  editContact: (p) => postAction('/ms-api/quote/editContact', p),
  del: (p) => delAction('/ms-api/quote/del', p),
  abandon: (p) => postAction('/ms-api/quote/abandon', p),
  goBack: (p) => postAction('/ms-api/quote/goBack', p), // 原退回
  goBackFlow: (p) => postAction('/ms-api/quote/goBackFlow', p), // 现退回，开启流程
  check: (p) => postAction('/ms-api/quote/check', p),
  copyQuote: (p) => postAction('/ms-api/quote/copyQuote', p),
  getQuote: (p) => getAction('/ms-api/quote/get', p),
  win: '/ms-api/quote/win',
  get: '/ms-api/quote/get',
  checkSn: '/ms-api/quote/check-sn',
  log: '/ms-api/quote/log',
  copySite: (p) => postDataAction('/ms-api/quote/copySite', p),
  siteSort: (p) => postAction('/ms-api/quote/siteSort', p),
  listQuoteMonth: (p) => getAction('/ms-api/quote/listQuoteMonth', p),
  statTableHeader: (p) => getAction('/ms-api/quote/statTableHeader', p)
}

export const quoteItemApi = {
  list: '/ms-api/quote-item/list',
  add: '/ms-api/quote-item/add',
  edit: '/ms-api/quote-item/edit',
  del: '/ms-api/quote-item/del'
}

export const quoteCompileCostsApi = {
  list: '/ms-api/quote-compile-costs/listAll',
  add: '/ms-api/quote-compile-costs/add',
  edit: '/ms-api/quote-compile-costs/edit',
  del: '/ms-api/quote-compile-costs/del'
}

export const quoteOtherCostsApi = {
  list: '/ms-api/quote-other-costs/listAll',
  add: '/ms-api/quote-other-costs/add',
  edit: '/ms-api/quote-other-costs/edit',
  del: '/ms-api/quote-other-costs/del'
}

// 字典
export const dicApi = {
  // 查询所有的编制费用字典表
  listAllCompileCosts: '/ms-api/dic/list-all/compile-costs',
  // 查询所有其他费用字典表
  listAllOtherCosts: '/ms-api/dic/list-all/other-costs',
  // 查询所有的编制费用字典表
  listCompileCosts: '/ms-api/dic/list/compile-costs',
  // 查询其它费用字典表
  listOtherCosts: '/ms-api/dic/list/other-costs',
  // 保存或更新编制费用字典项
  saveCompileCosts: '/ms-api/dic/save/compile-costs',
  // 保存或更新其它费用字典项
  saveOtherCosts: '/ms-api/dic/save/other-costs',
  // 删除编制费用字典项
  delCompileCosts: '/ms-api/dic/del/compile-costs',
  // 删除指定报价单的其他费用字典项
  delOtherCosts: '/ms-api/dic/del/other-costs'
}

// 客户
export const customerApi = {
  // 查询客户列表
  list: '/ms-api/customer/list',
  listCustomer: (p) => getAction('/ms-api/customer/list', p),
  get: '/ms-api/customer/get',
  // 保存或更新客户信息
  save: '/ms-api/customer/save',
  checkCsrName: (p) => getAction('/ms-api/customer/checkCsrName', p),
  // 根据ID主键删除客户信息
  del: '/ms-api/customer/del',
  all: (p) => getAction('/ms-api/customer/all', p)
}

// 客户人员信息
export const customerUserApi = {
  // 查询客户人员列表
  list: '/ms-api/customer-user/list',
  // 查询客户人员信息
  get: '/ms-api/customer-user/get',
  // 保存或更新客户人员信息
  add: '/ms-api/customer-user/add',
  // 保存或更新客户人员信息
  edit: '/ms-api/customer-user/edit',
  // 根据ID主键删除客户人员信息
  del: '/ms-api/customer-user/del',

  dataList: function (param) {
    return getAction(this.list, param)
  },

  update: function (param) {
    return postAction(this.edit, param)
  }
}

export const customerFollowApi = {
  list: (p) => getAction('/ms-api/customer-follow/list', p),
  add: (p) => postAction('/ms-api/customer-follow/add', p)
}

export const devApi = {
  list: '/ms-api/dev/list',
  add: '/ms-api/dev/add',
  edit: '/ms-api/dev/edit',
  del: '/ms-api/dev/del'
}

export const resMonitorDevApi = {
  getDevByType: '/ms-api/res-monitordev/getDevByType',
  getMonitorList: '/ms-api/res-monitordev/list',
  add: '/ms-api/res-monitordev/add',
  del: '/ms-api/res-monitordev/del',
  edit: '/ms-api/res-monitordev/edit',
  updatePylById: '/ms-api/res-monitordev/updatePylById',
  getDevCountByType: '/ms-api/res-monitordev/getDevCountByType',
  getTempData: '/ms-api/res-monitordev/getTempData'
}

export const resDevApi = {
  list: '/ms-api/res-dev/list',
  add: '/ms-api/res-dev/add',
  edit: '/ms-api/res-dev/edit',
  del: '/ms-api/res-dev/del',
  getByid: '/ms-api/res-dev/getByid',
  getDevsByTypeId: (p) => getAction('/ms-api/res-dev/getDevsByTypeId', p),
  getDevsByTypes: (p) => getAction('/ms-api/res-dev/getDevsByTypes', p),
  getDevList: (p) => getAction('/ms-api/res-dev/list', p),
  getAdminUser: '/ms-api/res-dev/getAdminUser',
  getAllDevInfos: '/ms-api/res-dev/getAllDevInfos',
  getDevImg: '/ms-api/res-dev/getDevImg',
  getDevListByIds: '/ms-api/res-dev/getDevListByIds',
  scrapDev: '/ms-api/res-dev/scrapDev',
  getDevUseRecord: '/ms-api/res-dev/getDevUseRecord',
  getLibDevUseRecord: '/ms-api/res-dev/getLibDevLibUseRecord',
  downLoadUseRecord: '/ms-api/res-dev/downLoadUseRecord',
  downLoadUseRecordReport: '/ms-api/ureport/preview?_u=DB:现场仪器设备使用记录.ureport.xml',
  downLoadLibUseRecordReport: '/ms-api/ureport/preview?_u=DB:实验室仪器设备使用记录.ureport.xml',
  downLoadRecord: '/ms-api/ureport/preview?_u=DB:仪器设备进出登录表.ureport.xml',
  getDevLog: '/ms-api/res-dev/getDevLog',
  resDevUseRecordAdd: (p) => postAction('/ms-api/resDevUseRecord/add', p), // 设备使用记录新增/编辑
  resDevUseRecordDelete: (p) => delAction('/ms-api/resDevUseRecord/delete', p) // 设备使用记录删除
}

export const devCategoryApi = {
  devs: '/ms-api/dev-category/devs',
  list: '/ms-api/dev-category/list',
  add: '/ms-api/dev-category/add',
  edit: '/ms-api/dev-category/edit',
  del: '/ms-api/dev-category/del'
}

export const resManufApi = {
  list: '/ms-api/res-manuf/list',
  add: '/ms-api/res-manuf/add',
  edit: '/ms-api/res-manuf/edit',
  del: '/ms-api/res-manuf/del'
}

export const resSupplierApi = {
  list: '/ms-api/res-supplier/list',
  add: '/ms-api/res-supplier/add',
  edit: '/ms-api/res-supplier/edit',
  del: '/ms-api/res-supplier/del'
}

export const resLocationApi = {
  list: '/ms-api/res-location/list',
  add: '/ms-api/res-location/add',
  edit: '/ms-api/res-location/edit',
  del: '/ms-api/res-location/del',
  selectList: '/ms-api/res-location/selectList',
  batchDel: '/ms-api/res-location/batchDel'
}

export const resLocationConfig = {
  add: '/ms-api/res-locationAmbientTemperature/add',
  findByLocationId: (id) => getAction(`/ms-api/res-locationAmbientTemperature/findByLocationId/${id}`)
}

export const resCbtApi = {
  list: '/ms-api/res-cbt/list',
  add: '/ms-api/res-cbt/add',
  edit: '/ms-api/res-cbt/edit',
  del: '/ms-api/res-cbt/del',
  delBatch: '/ms-api/res-cbt/delBatch',
  getAppend: '/ms-api/res-cbt/getAppend',
  getResult: '/ms-api/res-cbt/getResult',
  getUserById: '/ms-api/res-cbt/getUserById',
  evalConfirm: '/ms-api/res-cbt/evalConfirm',
  getEvalConfirmInfo: '/ms-api/res-cbt/getEvalConfirmInfo',
  cbtConfirm: '/ms-api/res-cbt/cbtConfirm',
  getCbtConfirmInfo: '/ms-api/res-cbt/getCbtConfirmInfo',
  saveByFlow: (data) => postDataAction(`/ms-api/res-cbt/saveByFlow`, JSON.stringify(data)),
  getCopyDevList: '/ms-api/res-cbt/getCopyDevList'
}
// 期间核查
export const resInterCheckApi = {
  list: (param) => getAction('/ms-api/res-intermediate-check/list', param),
  saveOrUpdate: (param) => postDataAction('/ms-api/res-intermediate-check/saveOrUpdate', param), 
  delete: (param) => delAction(`/ms-api/res-intermediate-check/del/${param}`),
  deleteFile: (param) => postAction('/ms-api/report-task-template/delete-file', param),
}
// 维护保养
export const resMaintenanceApi = {
  list: (param) => getAction('/ms-api/res_maintenance_contents/list', param),
  saveOrUpdate: (param) => postDataAction('/ms-api/res_maintenance_contents/saveOrUpdate', param), 
  delete: (param) => delAction(`/ms-api/res_maintenance_contents/del/${param}`),
 
}
export const resCbtDepApi = {
  list: '/ms-api/res-cbtdep/list',
  add: '/ms-api/res-cbtdep/add',
  edit: '/ms-api/res-cbtdep/edit',
  del: '/ms-api/res-cbtdep/del'
}

export const resDevRepairApi = {
  list: '/ms-api/res-devrepair/list',
  add: '/ms-api/res-devrepair/add',
  edit: '/ms-api/res-devrepair/edit',
  del: '/ms-api/res-devrepair/del'
}
export const resStandardSampleApi = {
  edit: '/ms-api/res-standard-sample/edit',
  getSampleByMaterialId: '/ms-api/res-standard-sample/getSampleByMaterialId'
}

export const resMaterialApi = {
  list: '/ms-api/res-material/list',
  listDetail: '/ms-api/res-material/list-detail',
  add: '/ms-api/res-material/add',
  edit: '/ms-api/res-material/edit',
  del: '/ms-api/res-material/del',
  batchAdd: '/ms-api/res-material/batchAdd',
  getMaterialInList: '/ms-api/res-material/getMaterialInList',
  getMaterialOutList: '/ms-api/res-material/getMaterialOutList',
  getBatchNbrSelect: '/ms-api/res-material/getBatchNbrSelect',
  getMaterialImg: '/ms-api/res-material/getMaterialImg',
  getMaterialOper: '/ms-api/res-material/getMaterialOper',
  getMaterialVideo: '/ms-api/res-material/getMaterialVideo',
  addMaterial: '/ms-api/res-material/add-material',
  editMaterial: '/ms-api/res-material/edit-material',
  getMaterialAppendix: '/ms-api/res-material/getMaterialAppendix',
  getMaterialCountImg: '/ms-api/res-material/getMaterialCountImg',
  getMaterialAndDev: '/ms-api/res-material/getMaterialAndDev',
  getMaterialAndDevCascade: (param) => getAction('/ms-api/res-material/getMaterialAndDevCascade', param),
  technicalInfoByCateId: (param) => getAction('/ms-api/res-material/technicalInfoByCateId', param),
  getMaterialByBatchNbr: '/ms-api/res-material/getMaterialByBatchNbr',
  addCount: '/ms-api/res-material/addCount',
  getStockByMaterialId: '/ms-api/res-material/getStockByMaterialId',
  getDevByMaterialId: '/ms-api/res-material/getDevByMaterialId',
  editStock: '/ms-api/res-material/editStock',
  editStockBatch: '/ms-api/res-material/editStockBatch',
  selectOne: '/ms-api/res-material/selectOne',
  addByExcel: '/ms-api/res-material/addByExcel',
  getMaterialSelect: '/ms-api/res-material/getMaterialCateSelect',
  getDataByIds: '/ms-api/res-material/getDataByIds',
  devList: '/ms-api/res-material/devList',
  getMaterialDetail: '/ms-api/res-material/getMaterialDetail',
  getRkDetails: '/ms-api/res-material/getRkDetails',
  selectOneById: '/ms-api/res-material/selectOneById',
  getCateByMaterial: '/ms-api/res-material/getCateByMaterial',
  getMaterialOutCount: '/ms-api/res-material/getMaterialOutCount',
  getMaterialByCateId: '/ms-api/res-material/getMaterialByCateId',
  getMaterialCategory: '/ms-api/res-material/getMaterialCategory'
}

export const resMaterialImgApi = {
  list: (param) => getAction('/ms-api/res-material-img/list', param)
}

export const resMaterialDgrApi = {
  list: '/ms-api/res-materialdgr/list',
  add: '/ms-api/res-materialdgr/add',
  edit: '/ms-api/res-materialdgr/edit',
  del: '/ms-api/res-materialdgr/del',
  batchAdd: '/ms-api/res-materialdgr/batchAdd',
  getMaterialInList: '/ms-api/res-materialdgr/getMaterialInList',
  getMaterialOutList: '/ms-api/res-materialdgr/getMaterialOutList',
  getBatchNbrSelect: '/ms-api/res-materialdgr/getBatchNbrSelect',
  getMaterialImg: '/ms-api/res-materialdgr/getMaterialImg',
  getMaterialAndDev: '/ms-api/res-materialdgr/getMaterialAndDev',
  getMaterialAndDevCascade: (param) => getAction('/ms-api/res-materialdgr/getMaterialAndDevCascade', param),
  getMaterialByBatchNbr: '/ms-api/res-materialdgr/getMaterialByBatchNbr',
  addCount: '/ms-api/res-materialdgr/addCount',
  getStockByMaterialId: '/ms-api/res-materialdgr/getStockByMaterialId',
  editStock: '/ms-api/res-materialdgr/editStock',
  editStockBatch: '/ms-api/res-materialdgr/editStockBatch',
  selectOne: '/ms-api/res-materialdgr/selectOne',
  addByExcel: '/ms-api/res-materialdgr/addByExcel'
}

export const resDevRegisterApi = {
  list: '/ms-api/res-devregister/list',
  downLoadRegister: '/ms-api/res-devregister/downLoadRegister'
}

export const resMaterialOutApi = {
  list: '/ms-api/res-materialout/list',
  add: '/ms-api/res-materialout/add',
  getMaterialInList: '/ms-api/res-materialout/getMaterialInList',
  getMaterialOutList: '/ms-api/res-materialout/getMaterialOutList',
  getBatchNbrSelect: '/ms-api/res-materialout/getBatchNbrSelect',
  getMaterialImg: '/ms-api/res-materialout/getMaterialImg',
  getMaterialAndDev: '/ms-api/res-materialout/getMaterialAndDev',
  getMaterialByBatchNbr: '/ms-api/res-materialout/getMaterialByBatchNbr',
  getMaterialList: '/ms-api/res-materialout/getMaterialList',
  getMaterialIdsByOutId: '/ms-api/res-materialout/getMaterialIdsByOutId',
  getMaterialListByIds: '/ms-api/res-materialout/getMaterialListByIds',
  syncData: '/ms-api/res-materialout/syncData'
}

export const itemTestDataApi = {
  index: '/ms-api/item-test-data/index',
  getRegionList: '/ms-api/item-test-data/getRegionList',
  getXzqhList: '/ms-api/item-test-data/getXzqhList',
  getSiteList: '/ms-api/item-test-data/getSiteList',
  getCityTree: '/ms-api/item-test-data/getCityTree',
  getAllSite: '/ms-api/item-test-data/getAllSite'
}

export const itemAqiDataApi = {
  getAqiBySiteCode: '/ms-api/item-aqi-data/getAqiBySiteCode',
  getAqiDetail: '/ms-api/item-aqi-data/getAqiDetail'
}

export const resMaterialOutDgrApi = {
  list: '/ms-api/res-materialoutdgr/list',
  add: '/ms-api/res-materialoutdgr/add',
  getMaterialInList: '/ms-api/res-materialoutdgr/getMaterialInList',
  getMaterialOutList: '/ms-api/res-materialoutdgr/getMaterialOutList',
  getBatchNbrSelect: '/ms-api/res-materialoutdgr/getBatchNbrSelect',
  getMaterialImg: '/ms-api/res-materialoutdgr/getMaterialImg',
  getMaterialAndDev: '/ms-api/res-materialoutdgr/getMaterialAndDev',
  getMaterialByBatchNbr: '/ms-api/res-materialoutdgr/getMaterialByBatchNbr',
  getMaterialList: '/ms-api/res-materialoutdgr/getMaterialList',
  getMaterialIdsByOutId: '/ms-api/res-materialoutdgr/getMaterialIdsByOutId',
  getMaterialListByIds: '/ms-api/res-materialoutdgr/getMaterialListByIds'
}

export const resMaterialReturnApi = {
  getMaterialByPurUseId: '/ms-api/res-materialreturn/getMaterialByPurUseId',
  add: '/ms-api/res-materialreturn/add',
  list: '/ms-api/res-materialreturn/list'
}

export const resMaterialReturnDgrApi = {
  getMaterialByPurUseId: '/ms-api/res-materialreturndgr/getMaterialByPurUseId',
  add: '/ms-api/res-materialreturndgr/add',
  list: '/ms-api/res-materialreturndgr/list'
}
export const sysModelApi = {
  list: '/ms-api/sys-model/list',
  add: '/ms-api/sys-model/add',
  edit: '/ms-api/sys-model/edit',
  batchDel: '/ms-api/sys-model/del',
  getModelJson: '/ms-api/getModelJson',
  autoCreateModel: '/ms-api/sys-model/autoCreateModel',
  autoCreateModelForFlow: '/ms-api/sys-model/autoCreateModelForFlow',
  selectList: (param) => getAction(sysModelApi.list, param)
}

export const sysModelFieldApi = {
  list: '/ms-api/sys-model-field/list',
  add: '/ms-api/sys-model-field/add',
  edit: '/ms-api/sys-model-field/edit',
  batchDel: '/ms-api/sys-model-field/del',
  syncData: '/ms-api/sys-model-field/syncData',
  getByModelId: (modelId) => getAction(sysModelFieldApi.list, { modelId, page: false })
}

export const resCarApi = {
  list: '/ms-api/res-car/list',
  add: '/ms-api/res-car/add',
  edit: '/ms-api/res-car/edit',
  del: '/ms-api/res-car/del',
  getImgList: '/ms-api/res-car/getImgList',
  getDriverList: '/ms-api/res-car/getDriverList',
  batchDel: '/ms-api/res-car/batchDel',
  listAll: '/ms-api/res-car/listAll',
  asDel: (p) => postDataAction(`/ms-api/res-car-as/del`, p),
  aiDel: (p) => postDataAction(`/ms-api/res-car-ai/del`, p)
}

export const resVehLocationApi = {
  list: '/ms-api/res-veh-location/list',
  add: '/ms-api/res-veh-location/add',
  edit: '/ms-api/res-veh-location/edit',
  del: '/ms-api/res-veh-location/del',
  batchDel: '/ms-api/res-veh-location/batchDel'

}
export const itemSiteApi = {
  list: '/ms-api/item-site/list',
  edit: '/ms-api/item-site/edit'
}
export const resDriverApi = {
  list: '/ms-api/res-driver/list',
  add: '/ms-api/res-driver/add',
  edit: '/ms-api/res-driver/edit',
  del: '/ms-api/res-driver/del',
  getUsers: '/ms-api/res-driver/getUsers',
  getDriverList: '/ms-api/res-driver/getDriverList'
}

export const resPurApi = {
  list: '/ms-api/res-pur/list',
  add: '/ms-api/res-pur/add',
  edit: '/ms-api/res-pur/edit',
  savePayInfo: '/ms-api/res-pur/savePayInfo',
  del: '/ms-api/res-pur/del',
  getGoodsById: '/ms-api/res-pur/getGoods',
  getUserId: '/ms-api/res-pur/getUserId',
  getBack: '/ms-api/res-pur/getBack',
}

export const resPurCopyApi={
  selectProcureSetConfig: (param) => getAction('/ms-api/res-purCopy/selectProcureSetConfig', param),
  purCopyList: '/ms-api/res-purCopy/list',
  purCopyAdd: '/ms-api/res-purCopy/add',
  purCopyEdit: '/ms-api/res-purCopy/edit',
  purCopyDel: '/ms-api/res-purCopy/del',
  purCopyGetGoodsById: '/ms-api/res-purCopy/getGoods',
  purCopyGetUserId: '/ms-api/res-purCopy/getUserId',
  purCopyGetBack: '/ms-api/res-purCopy/getBack',

  purplanCopyList: '/ms-api/res-purplanCopy/list',
  purplanCopyAdd: '/ms-api/res-purplanCopy/add',
  purplanCopyEdit: '/ms-api/res-purplanCopy/edit',
  purplanCopyDel: '/ms-api/res-purplanCopy/del',
  purplanCopyBatchDel: '/ms-api/res-purplanCopy/batchDel',
  purplanCopyGetGoodsById: '/ms-api/res-purplanCopy/getGoods',
  purplanCopyGetUserId: '/ms-api/res-purplanCopy/getUserId',
  purplanCopyGetBack: '/ms-api/res-purplanCopy/getBack',
  purplanCopyUpdateCtnToUse: '/ms-api/res-purplanCopy/updateCtnToUse',

  materialCopyList: '/ms-api/res-material/list',
  materialCopyAdd: '/ms-api/res-material/add',
  materialCopyBatchAdd: '/ms-api/res-material/batchAdd',
  materialCopyGetMaterialInList: '/ms-api/res-material/getMaterialInList',
  materialCopyGetMaterialByBatchNbr: '/ms-api/res-material/getMaterialByBatchNbr',
  materialCopyAddCount: '/ms-api/res-material/addCount',
  materialCopyAddByExcel: '/ms-api/res-material/addByExcel',
  materialCopyGetMaterialDetail: '/ms-api/res-material/getMaterialDetail',
  materialCopyGetRkDetails: '/ms-api/res-material/getRkDetails',
  materialCopySelectOneById: '/ms-api/res-material/selectOneById',
  materialCopyGetMaterialCategory: '/ms-api/res-material/getMaterialCategory',

  puruseCopyGetPurUser: '/ms-api/res-puruse/getPurUser'
}

export const resPurPlanApi = {
  list: '/ms-api/res-purplan/list',
  add: '/ms-api/res-purplan/add',
  edit: '/ms-api/res-purplan/edit',
  del: '/ms-api/res-purplan/del',
  batchDel: '/ms-api/res-purplan/batchDel',
  getGoodsById: '/ms-api/res-purplan/getGoods',
  getUserId: '/ms-api/res-purplan/getUserId',
  getBack: '/ms-api/res-purplan/getBack',
  updateCtnToUse: '/ms-api/res-purplan/updateCtnToUse'
}

export const resPurPlanDgrApi = {
  list: '/ms-api/res-purplandgr/list',
  add: '/ms-api/res-purplandgr/add',
  edit: '/ms-api/res-purplandgr/edit',
  del: '/ms-api/res-purplandgr/del',
  getGoodsById: '/ms-api/res-purplandgr/getGoods',
  getUserId: '/ms-api/res-purplandgr/getUserId',
  getBack: '/ms-api/res-purplandgr/getBack',
  updateCtnToUse: '/ms-api/res-purplandgr/updateCtnToUse'
}

export const resPurUseApi = {
  getPurUser: '/ms-api/res-puruse/getPurUser',
  list: '/ms-api/res-puruse/list',
  add: '/ms-api/res-puruse/add',
  edit: '/ms-api/res-puruse/edit',
  del: '/ms-api/res-puruse/del',
  getGoodsById: '/ms-api/res-puruse/getGoods',
  getUserId: '/ms-api/res-puruse/getUserId',
  getBack: '/ms-api/res-puruse/getBack',
  updateCtnToUse: '/ms-api/res-puruse/updateCtnToUse',
  getMaterialList: '/ms-api/res-puruse/getMaterialList',
  getMatersByCateId: '/ms-api/res-puruse/getMatersByCateId',
  getSpeTypeByMaterialName: '/ms-api/res-puruse/getSpeTypeByMaterialName',
  getMaterialInfo: '/ms-api/res-puruse/getMaterialInfo',
  getPurUseById: '/ms-api/res-puruse/getPurUseById',
  returnMaterial: '/ms-api/res-puruse/returnMaterial',
  resMaterialXh: '/ms-api/res-puruse/resMaterialXh',
  getMaterialIdsByPurUseId: '/ms-api/res-puruse/getMaterialIdsByPurUseId',
  getDevList: '/ms-api/res-puruse/getDevList',
  getDevByid: '/ms-api/res-puruse/getDevByid',
  getOutMaterial: '/ms-api/res-puruse/getOutMaterial',
  getReturnMaterial: '/ms-api/res-puruse/getReturnMaterial',
  materialReturn: '/ms-api/res-puruse/materialReturn',
  materialInsert: '/ms-api/res-puruse/materialInsert',
  getInsertMaterial: '/ms-api/res-puruse/getInsertMaterial',
  getProjSelect: '/ms-api/res-puruse/getProjSelect',
  materialBatchReturn: '/ms-api/res-puruse/materialBatchReturn',
  materialBatchInsert: '/ms-api/res-puruse/materialBatchInsert',
  listNoAuth: '/ms-api/res-puruse/listNoAuth',
  getPurById: '/ms-api/res-puruse/getPurById',
  append: '/ms-api/res-puruse/append',
  getOutGoods: '/ms-api/res-puruse/getOutGoods',
  getReturnSelect: '/ms-api/res-puruse/getReturnSelect',
  getOutMaterialByUserId: '/ms-api/res-puruse/getOutMaterialByUserId',
  setNeedLess: '/ms-api/res-puruse/setNeedLess',
  startTransfer: '/ms-api/res-puruse/startTransfer',
  getTransferInfo: '/ms-api/res-puruse/getTransferInfo',
  startLabMaterialApply: '/ms-api/res-puruse/startLabMaterialApply',
  getLabMaterialApplyInfo: '/ms-api/res-puruse/getLabMaterialApplyInfo',
  getReturnMaterialByPurUseId: '/ms-api/res-puruse/getReturnMaterialByPurUseId',
  getReturnMaterialDetail: '/ms-api/res-puruse/getReturnMaterialDetail',
  getInsertMaterialByPurUseId: '/ms-api/res-puruse/getInsertMaterialByPurUseId',
  getInsertMaterialDetail: '/ms-api/res-puruse/getInsertMaterialDetail',
  getInsertSelect: '/ms-api/res-puruse/getInsertSelect',
  getMaterialByPurUseId: '/ms-api/res-puruse/getMaterialByPurUseId',
  goodsBatchReturn: '/ms-api/res-puruse/goodsBatchReturn',
  goodsBatchInsert: '/ms-api/res-puruse/goodsBatchInsert'
}

export const resPurUseDgrApi = {
  list: '/ms-api/res-purusedgr/list',
  add: '/ms-api/res-purusedgr/add',
  edit: '/ms-api/res-purusedgr/edit',
  del: '/ms-api/res-purusedgr/del',
  getGoodsById: '/ms-api/res-purusedgr/getGoods',
  getUserId: '/ms-api/res-purusedgr/getUserId',
  getBack: '/ms-api/res-purusedgr/getBack',
  updateCtnToUse: '/ms-api/res-purusedgr/updateCtnToUse',
  getMaterialList: '/ms-api/res-purusedgr/getMaterialList',
  getMatersByCateId: '/ms-api/res-purusedgr/getMatersByCateId',
  getSpeTypeByMaterialName: '/ms-api/res-purusedgr/getSpeTypeByMaterialName',
  getMaterialInfo: '/ms-api/res-purusedgr/getMaterialInfo',
  getPurUseById: '/ms-api/res-purusedgr/getPurUseById',
  returnMaterial: '/ms-api/res-purusedgr/returnMaterial',
  resMaterialXh: '/ms-api/res-purusedgr/resMaterialXh',
  getMaterialIdsByPurUseId: '/ms-api/res-purusedgr/getMaterialIdsByPurUseId',
  getDevList: '/ms-api/res-purusedgr/getDevList',
  getDevByid: '/ms-api/res-purusedgr/getDevByid'
}

export const resdevCategoryApi = {
  devs: '/ms-api/res-devcategory/devs',
  list: '/ms-api/res-devcategory/list',
  add: '/ms-api/res-devcategory/add',
  edit: '/ms-api/res-devcategory/edit',
  del: '/ms-api/res-devcategory/del',
  selectList: '/ms-api/res-devcategory/selectList',
  batchDel: '/ms-api/res-location/batchDel',
  editChild: '/ms-api/res-devcategory/editChild',
  addChild: '/ms-api/res-devcategory/addChild',
  selectChildList: '/ms-api/res-devcategory/selectChildList',
  selectAllChildList: '/ms-api/res-devcategory/selectAllChildList',
  delChild: '/ms-api/res-devcategory/delChild',
  getMaterialCategory: '/ms-api/res-devcategory/getMaterialCategory',
  devBatchDel: '/ms-api/res-devcategory/batchDel', // 批量删除cateAdd=1的数据
  delChildBatch: '/ms-api/res-devcategory/delChildBatch' // 批量删除cateAdd=0的数据
}

export const resdevCategorychildApi = {
  list: '/ms-api/res-devcategorychild/list',
  add: '/ms-api/res-devcategorychild/add',
  edit: '/ms-api/res-devcategorychild/edit',
  del: '/ms-api/res-devcategorychild/del',
  // checkAlias: '/ms-api/res-devcategorychild/checkAlias',
  checkAlias: (param) => getAction('/ms-api/res-devcategorychild/checkAlias', param),
}

export const resPostTypeApi = {
  list: '/ms-api/res-post-type/list',
  listAll: '/ms-api/res-post-type/list-all',
  add: '/ms-api/res-post-type/add',
  edit: '/ms-api/res-post-type/edit',
  del: '/ms-api/res-post-type/del',
  selectList: '/ms-api/res-post-type/selectList',
  config: '/ms-api/res-post-type/config',
  getItemInfo: '/ms-api/res-post-type/getItemInfo',
  getUserByPostTypeId: '/ms-api/res-post-type/getUserByPostTypeId',
  getItemList: '/ms-api/res-post-type/getItemList'
}

const resPostReportPre = '/ms-api/res-post-report'
export const resPostReportApi = {
  list: (p) => getAction(`${resPostReportPre}/list`, p),
  save: (p) => postDataAction(`${resPostReportPre}/save`, p),
  delete: (param) => delAction(`${resPostReportPre}/delete`, param),
  personDetail: (p) => getAction(`${resPostReportPre}/personDetail`, p)
}

export const SamplingAdCodesApi = {
  queryGroupList: (p) => getAction(`/ms-api/tGroupAddress/queryGroupList`, p), // 查询采样小组
  add: (p) => postDataAction(`/ms-api/tGroupAddress/add`, p), // 新增或修改采样小组地区
  queryListByWeight: (p) => getAction(`/ms-api/tGroupAddress/queryListByWeight/${p}`), // 根据项目ID查询权重
  queryWeightById: (p) => getAction(`/ms-api/tGroupAddress/queryWeightById/${p}`), // 根据分组ID查询权重
  addGroupWight: (p) => postAction(`/ms-api/tGroupAddress/addGroupWight`, p) // 新增或修改采样小组权重
}

export const resStaffApi = {
  list: '/ms-api/res-staff/list',
  getOne: (p) => getAction('/ms-api/res-staff/get-one', p),
  add: '/ms-api/res-staff/add',
  edit: '/ms-api/res-staff/edit',
  del: '/ms-api/res-staff/del',
  batchDel: '/ms-api/res-staff/batchDel',
  staffList: '/ms-api/res-staff/staff-list',
  getAppendixListByStaffId: '/ms-api/res-staff/get-appendix-list',
  saveAppendix: '/ms-api/res-staff/save-appendix',
  editUser: '/ms-api/res-staff/edit-user',
  checkUser: '/ms-api/res-staff/check-user',
  getPersonStat: '/ms-api/res-staff/getPersonStat',
  getPersonThetaData: '/ms-api/res-staff/getPersonThetaData',
  discardStaff: '/ms-api/res-staff/discard-staff',
  getListQuitPerson: '/ms-api/res-staff/getListQuitPerson',
  getListPerson: '/ms-api/res-staff/getListPerson',
  getUserNotStaff: '/ms-api/res-staff/get-user-not-staff',
  updateStaffId: '/ms-api/res-staff/update-staff-id',
  postAbilityConfirm: '/ms-api/res-staff/postAbilityConfirm',
  getPostAbilityInfo: '/ms-api/res-staff/getPostAbilityInfo',
  saveByFlow: (data) => postDataAction(`/ms-api/res-staff/saveByFlow`, JSON.stringify(data)),
  getMethodAndDevList: '/ms-api/res-staff/getMethodAndDevList',
  authorization: '/ms-api/res-staff/authorization',
  getAuthorizationInfo: '/ms-api/res-staff/getAuthorizationInfo',
  saveAuthorizationByFlow: (data) => postDataAction(`/ms-api/res-staff/saveAuthorizationByFlow`, JSON.stringify(data)),
  generatingStaffNum: '/ms-api/res-staff/generatingStaffNum',
  validateStaffNum: '/ms-api/res-staff/validateStaffNum'
}

export const resStaffStationApi = {
  list: '/ms-api/res-staff-station/list',
  listAll: '/ms-api/res-staff-station/station-list',
  add: '/ms-api/res-staff-station/add',
  edit: '/ms-api/res-staff-station/edit',
  del: '/ms-api/res-staff-station/del',
  batchDel: '/ms-api/res-staff-station/batchDel',
  fileList: '/ms-api/res-staff-station/file-list',
  roles: '/ms-api/res-staff-station/roles'
}

export const resStaffTrainingApi = {
  list: '/ms-api/res-staff-training/list',
  add: '/ms-api/res-staff-training/add',
  edit: '/ms-api/res-staff-training/edit',
  del: '/ms-api/res-staff-training/del',
  apply: '/ms-api/res-staff-training/apply',
  getApplyInfo: '/ms-api/res-staff-training/getApplyInfo',
  listByStaff: '/ms-api/res-staff-training/listByStaff',
  signIn: '/ms-api/res-staff-training/signIn',
  changeStatus: '/ms-api/res-staff-training/changeStatus',
  evaluationList: '/ms-api/res-staff-training/evaluationList',
  uploadEvaluationFile: '/ms-api/res-staff-training/uploadEvaluationFile',
  updateEvaluation: '/ms-api/res-staff-training/updateEvaluation',
  getTrainingSummary: '/ms-api/res-staff-training/getTrainingSummary',
  summary: '/ms-api/res-staff-training/summary',
  getEffectivenessInfo: '/ms-api/res-staff-training/getEffectivenessInfo',
  effectiveness: '/ms-api/res-staff-training/effectiveness',
  saveByFlow: (data) => postDataAction(`/ms-api/res-staff-training/saveByFlow`, JSON.stringify(data)),
  signInList: '/ms-api/res-staff-training/signInList',
  summaryList: '/ms-api/res-staff-training/summaryList',
  licenseList: '/ms-api/res-staff-training/licenseList',
  issue: '/ms-api/res-staff-training/issue'
}

export const dashboardApi = {
  lastLog: '/ms-api/dashboard/last-log',
  menuList: '/ms-api/dashboard/menu-list',
  getPersonal: '/ms-api/dashboard/personal',
  add: '/ms-api/dashboard/add',
  edit: '/ms-api/dashboard/edit',
  lastLogListLog: (p) => postDataAction('/ms-api/dashboard/lastLogList-log', p)
}

export const timeLimitApi = {
  show: '/ms-api/time-limit/show',
  add: '/ms-api/time-limit/add',
  edit: '/ms-api/time-limit/edit',
  del: '/ms-api/time-limit/del',
  delByBgCateId: '/ms-api/time-limit/delByBgCateId'
}

export const jcStandardApi = {
  getAppendixListById: '/ms-api/jc-standard/appendix_list',
  list: '/ms-api/jc-standard/list',
  pageList: '/ms-api/jc-standard/page-list',
  add: '/ms-api/jc-standard/add',
  edit: '/ms-api/jc-standard/edit',
  del: '/ms-api/jc-standard/del',
  delByBgCateId: '/ms-api/jc-standard/delById',
  checkStandardId: (p) => getAction('/ms-api/jc-standard/check-standard-id', p),
  getStandardTree: (p) => getAction('/ms-api/jc-standard/get-standard-tree', p)
}

export const standardItemApi = {
  list: '/ms-api/standard-item/list', // 这个接口的请求参数cateId暂时不用，改为使用cateType，mc于2024-04-26 09:52写下
  add: (p) => postDataAction('/ms-api/standard-item/add', p),
  edit: (p) => postAction('/ms-api/standard-item/edit', p),
  delete: '/ms-api/standard-item/del',
  detectionMethod: (p) => getAction('/ms-api/standard-item/detectionMethod', p)
  /*  listByModel: '/ms-api/standard-item/get',
    add: '/ms-api/standard-item/add',
    list: '/ms-api/standard-item/list',
    itemDefaultList: '/ms-api/standard-item/item-default-list',
    addDefaultStandard: '/ms-api/standard-item/add-default-standard',
    edit: '/ms-api/standard-item/edit',
    del: '/ms-api/standard-item/del',
    itemGuide: '/ms-api/standard-item/item-guide',
    guideDownload: '/ms-api/standard-item/guide-download',
    setItemDefaultStandardId: '/ms-api/standard-item/default-standard-setting',
    getStandardByItemId: (p) => getAction('/ms-api/standard-item/getStandardByItemId', p),
    getStandardByItemIds: (p) => getAction('/ms-api/standard-item/getStandardByItemIds', p) */
  /*  listByModel: '/ms-api/standard-item/get',
    add: '/ms-api/standard-item/add',
    list: '/ms-api/standard-item/list',
    itemDefaultList: '/ms-api/standard-item/item-default-list',
    addDefaultStandard: '/ms-api/standard-item/add-default-standard',
    edit: '/ms-api/standard-item/edit',
    del: '/ms-api/standard-item/del',
    itemGuide: '/ms-api/standard-item/item-guide',
    guideDownload: '/ms-api/standard-item/guide-download',
    setItemDefaultStandardId: '/ms-api/standard-item/default-standard-setting',
    getStandardByItemId: (p) => getAction('/ms-api/standard-item/getStandardByItemId', p),
    getStandardByItemIds: (p) => getAction('/ms-api/standard-item/getStandardByItemIds', p) */
}
export const handlebookApi = {
  collectionChildren: '/ms-api/handle-book/collection_children',
  editNameByModel: '/ms-api/handle-book/colletion-file-name-edit',
  collectionTopLevel: '/ms-api/handle-book/collection-top-level',
  changeCollectionFileParent: '/ms-api/handle-book/file-parent-change',
  delCollectionFile: '/ms-api/handle-book/colletion-file-delete',
  addCollectionFile: '/ms-api/handle-book/colletion-file-add',
  searchCollectionFileByModel: '/ms-api/handle-book/search-list-file',
  parentListByFileId: '/ms-api/handle-book/parent-list'
}

export const documentApi = {
  list: '/ms-api/document/list',
  documentOperations: '/ms-api/document/document-operation',
  userDocumentList: '/ms-api/document/user-document-list',
  addExamin: '/ms-api/document/examin-add',
  add: '/ms-api/document/add',
  edit: '/ms-api/document/edit',
  grantUser: '/ms-api/document/grant-user',
  userCheckList: '/ms-api/document/user-check-list',
  userCollectList: '/ms-api/document/user-collect-list',
  userSignList: '/ms-api/document/user-sign-list',
  sign: '/ms-api/document/sign',
  collect: '/ms-api/document/collect',
  documentTypeCount: '/ms-api/document/document-type-count',
  fileTypeCount: '/ms-api/document/file-type-count',
  documentStatistics: '/ms-api/document/document-statistics',
  collectionChildren: '/ms-api/document/collection_children',
  editNameByModel: '/ms-api/document/colletion-file-name-edit',
  collectionTopLevel: '/ms-api/document/collection-top-level',
  changeCollectionFileParent: '/ms-api/document/file-parent-change',
  delCollectionFile: '/ms-api/document/colletion-file-delete',
  addCollectionFile: '/ms-api/document/colletion-file-add',
  searchCollectionFileByModel: '/ms-api/document/search-list-file',
  parentListByFileId: '/ms-api/document/parent-list',
  similarDocumentList: '/ms-api/document/similar-list',
  documentInfos: '/ms-api/document/document-info-by-id'
}

export const standardItemDevApi = {
  listByItemId: '/ms-api/standard-item-dev/get',
  listByIds: '/ms-api/standard-item-dev/item-dev-list',
  add: '/ms-api/standard-item-dev/add',
  batchAdd: '/ms-api/standard-item-dev/batch-add',
  batchDel: '/ms-api/standard-item-dev/batch-del',
  edit: '/ms-api/standard-item-dev/edit',
  del: '/ms-api/standard-item-dev/del'
}

export const jcMethodApi = {
  add: '/ms-api/method/add',
  edit: '/ms-api/method/edit',
  del: '/ms-api/method/del',
  listByModel: '/ms-api/method/list-by-model',
  listMethodByModel: '/ms-api/method/list-method-by-model',
  listAll: '/ms-api/method/list-all',
  checkName: (p) => getAction('/ms-api/method/check-name', p),
  editStandardMethod: (p) => postAction('/ms-api/method/edit-standard-method', p),
  queryMethodFormula: (p) => getAction('/ms-api/method/list-method-formula', p),
  setMethodFormula: (p) => postDataAction('/ms-api/method/set-method-formula', p),
  checkAdd: (p) => postAction('/ms-api/method/check-add', p),
  findById: (id) => getAction(`/ms-api/method/find-by-id/${id}`),
  findDetail: (param) => getAction(`/ms-api/jc-method-detail/find-one`, param)
}

export const jcListApi = {
  addStandardItemList: (p) => postAction('/ms-api/jc-list/add-item-list', p),
  listByStandard: (p) => getAction('/ms-api/jc-list/list-by-standard', p),
  pageList: (p) => getAction('/ms-api/jc-list/page-list', p),
  listCateField: (p) => getAction('/ms-api/jc-list/list-cate-field', p),
  listCateTypeField: (p) => getAction('/ms-api/jc-list/list-catetype-field', p),
  listCateModel: (p) => postAction('/ms-api/jc-list/list-model', p),
  listCateTypeModel: (p) => postAction('/ms-api/jc-list/cate-type-model', p),
  delByModel: (p) => getAction('/ms-api/jc-list/del', p),
  projItemGuide: '/ms-api/jc-list/proj_item_guide',
  projCheckGuide: '/ms-api/jc-list/proj_check_guide',
  projCatelist: (p) => getAction('/ms-api/jc-list/proj-cate-list', p),
  saveProjectItemList: '/ms-api/jc-list/add-proj-item-list',
  devList: '/ms-api/jc-list/dev-list',
  edit: (p) => postAction('/ms-api/jc-list/edit', p),
  projItemGroup: '/ms-api/jc-list/proj-item-group',
  projItemGroupList: (p) => getAction('/ms-api/jc-list/proj-item-group', p),
  materialList: (p) => getAction('/ms-api/jc-list/proj-guide-matertial', p),
  queryProjItemGuide: (p) => getAction('/ms-api/jc-list/proj_item_guide', p),
  queryProjCheckGuide: (p) => getAction('/ms-api/jc-list/proj_check_guide', p),
  queryProjPostInfo: (p) => Get('/ms-api/jc-list/projs-item-jobList', p)
}

export const jcListGroupApi = {
  list: (p) => getAction('/ms-api/jc-group/list', p),
  edit: (p) => postDataAction('/ms-api/jc-group/edit', p),
  del: '/ms-api/jc-group/del'
}

export const itemSampleDetailApi = {
  list: (p) => getAction('/ms-api/item-sample-detail/list', p),
  edit: (p) => postDataAction('/ms-api/item-sample-detail/edit', p),
  copy: (p) => postDataAction('/ms-api/item-sample-detail/copy', p),
  itemShow: (p) => postDataAction('/ms-api/item-sample-detail/itemShow', p),
  del: '/ms-api/item-sample-detail/del'
}

export const itemCheckDetailApi = {
  list: (p) => getAction('/ms-api/item-check-detail/list', p), // 二级分类下
  listCategory: (p) => getAction('/ms-api/item-check-detail/listCategory', p), // 一级分类下
  listDetail: (p) => getAction('/ms-api/item-check-detail/listDetail', p),
  edit: (p) => postDataAction('/ms-api/item-check-detail/edit', p),
  copy: (p) => postDataAction('/ms-api/item-check-detail/copy', p),
  itemShow: (p) => postDataAction('/ms-api/item-check-detail/itemShow', p),
  del: '/ms-api/item-check-detail/del'
}

export const myQualificationsApi = {
  list: (p) => getAction('/ms-api/qulifi-docu/list', p), // 资质列表 二级分类下
  selectCateType: (p) => getAction('/ms-api/qulifi-docu/selectCateType', p), // 资质列表 一级分类下
  noList: (p) => getAction('/ms-api/qulifi-docu/noList', p), // 查看未关联的因子
  insert: (p) => postDataAction('/ms-api/qulifi-docu/insert', p), // 新增资质
  updateDeviceNo: (p) => getAction('/ms-api/qulifi-docu/updateDeviceNo', p), // 修改常用设备编号
  delete: (p) => getAction('/ms-api/qulifi-docu/delete', p), // 资质列表 删除or作废
  lookDocuments: (p) => getAction('/ms-api/qulifi-docu/lookDocuments', p), // 查看资质文件
  deleteDocuments: (p) => getAction('/ms-api/qulifi-docu/deleteDocuments', p), // 删除或作废资质文件
  uploadDocuments: (p) => postDataAction('/ms-api/qulifi-docu/uploadDocuments', p), // 上传资质文件
  findQulifiId: (p) => getAction('/ms-api/qulifi-docu/findQulifiId', p),
  findDuty: (p) => getAction('/ms-api/qulifi-docu/findDuty', p),
  insertComp: (p) => postDataAction('/ms-api/qulifi-docu/insertComp', p), // 新增公司信息
  lookComp: (p) => getAction('/ms-api/qulifi-docu/lookComp', p), // 查看公司信息
  deleteComp: (p) => getAction('/ms-api/qulifi-docu/deleteComp', p), // 删除或作废公司信息
  insertPerson: (p) => postDataAction('/ms-api/qulifi-docu/insertPerson', p), // 新增人员信息
  lookPerson: (p) => getAction('/ms-api/qulifi-docu/lookPerson', p), // 查看人员信息
  deletePerson: (p) => getAction('/ms-api/qulifi-docu/deletePerson', p), // 删除或作废人员信息
  getStationName: (p) => getAction('/ms-api/qulifi-docu/getStationName', p), // 获取人员类型
  getResponsibility: (p) => getAction('/ms-api/qulifi-docu/getResponsibility', p), // 获取负责领域
  getLandInfo: (p) => getAction('/ms-api/qulifi-docu/getLandInfo', p), // 查询场地信息
  insertLandInfo: (p) => postDataAction('/ms-api/qulifi-docu/insertLandInfo', p), // 添加或修改场地信息
  deleteLandInfo: (p) => getAction('/ms-api/qulifi-docu/deleteLandInfo', p), // 删除或作废场地信息
  sortedList: (p) => getAction('/ms-api/qulifi-docu/sortedList', p), // 归类显示资质列表
  findProofByItemId: (p) => getAction('/ms-api/qulifi-docu/findProofByItemId', p), // 根据因子编号查找附表信息
  findProofBytotal: (p) => getAction('/ms-api/qulifi-docu/findProofBytotal', p), // 点击数量显示的列表
  getCheckMethod: (p) => getAction('/ms-api/qulifi-docu/getCheckMethod', p) // 检测任务准备获取检测方法
}

export const attributeApi = {
  list: '/ms-api/attribute/list',
  validateList: '/ms-api/attribute/validate-list',
  // query: (p) => getAction('/ms-api/attribute/query', p),
  add: '/ms-api/attribute/add',
  delete: '/ms-api/attribute/delete',
  edit: '/ms-api/attribute/edit',
  listWithValue: '/ms-api/attribute/list-with-value',
  listCombine: '/ms-api/attribute/list-attribute-combine',
  addCombineRelation: (p) => postDataAction('/ms-api/attribute/add-combine-relation', p),
  addCombine: (p) => postDataAction('/ms-api/attribute/add-combine', p),
  addSingleCombine: (p) => postAction('/ms-api/attribute/add-single-combine', p),
  attributeTree: (p) => getAction('/ms-api/attribute/attribute_tree', p),
  queryItemLimitValue: (p) => getAction('/ms-api/attribute/query-item-limit-value', p),
  upsertLimitValue: (p) => postDataAction('/ms-api/attribute/add-limit-value', p)
}

export const standardItemFactorApi = {
  list: (p) => getAction('/ms-api/standard_item_factor/list', p),
  add: (p) => postAction('/ms-api/standard_item_factor/add', p),
  edit: (p) => postAction('/ms-api/standard_item_factor/edit', p),
  delete: (p) => postAction('/ms-api/standard_item_factor/delete', p),
  listWithValue: '/ms-api/standard_item_factor/list-with-value',
  listCombine: '/ms-api/standard_item_factor/list-attribute-combine',
  addCombine: (p) => postDataAction('/ms-api/standard_item_factor/add-combine', p),
  addProjectLimitValue: (p) => postDataAction('/ms-api/standard_item_factor/add-project-limit-value', p),
  listProjectLimitValue: (p) => getAction('/ms-api/standard_item_factor/list-project-limit-value', p),
  listAttributeDetail: (p) => getAction('/ms-api/standard_item_factor/list-attribute-detail', p)

}

export const attributeValueApi = {
  list: '/ms-api/attribute-value/list',
  delete: '/ms-api/attribute-value/delete',
  query: (p) => getAction('/ms-api/attribute-value/query', p),
  add: '/ms-api/attribute-value/add',
  edit: '/ms-api/attribute-value/edit',
  listAttributeCombine: '/ms-api/attribute-value/list-value-by-combine'
}

// 方法管理的关联因子相关接口
export const itemCheckDetailCompositeApi = {
  add: (p) => postDataAction('/ms-api/itemCheckDetailComposite/add', p), // 新增或修改方法关联的因子
  selectListByMetchId: (p) => getAction('/ms-api/itemCheckDetailComposite/selectListByMetchId', p), // 查询方法关联的因子
  deleted: (p) => delAction('/ms-api/itemCheckDetailComposite/deleted', p) // 删除方法与因子的关联关系
}

export const reportTypeApi = {
  list: [{ id: 101, name: '体系认证' },
    { id: 102, name: '企业自测' },
    { id: 103, name: '监理监测' },
    { id: 104, name: '监督监测' },
    { id: 105, name: '比对监测' },
    { id: 106, name: '排污许可' },
    { id: 107, name: '验收监测' },
    { id: 201, name: '执法监测' },
    { id: 202, name: '应急监测' },
    { id: 203, name: '特急监测' },
    { id: 204, name: '调查监测' },
    { id: 205, name: '验收监测报告' },
    { id: 300, name: '环评监测' }],
  newList: [],
  listReportType: () => getAction('/ms-api/comm-dict/list', { dictCode: 'proj_report_type' })
}

export const enterpriseServiceApi = {
  list: '/ms-api/enterprise-service/list'
}

// 标准分类
export const GBTypeApi = {
  list: [{ id: 1, name: '采样标准' },
    { id: 2, name: '检测标准' }]
}

export const commonApi = {
  downloadQuote: '/ms-api/download/quote'
}

export const performance = {
  getThis: (p) => getAction('/ms-api/performance/getThis', p),
  refresh: (p) => getAction('/ms-api/performance/refresh', p)
}

export const systemAuthorizationApi = {
  authKey: (p) => postAction('/ms-api/auth/authKey', p)
}

export function Get (url, params) {
  return axios({
    url,
    method: 'get',
    params
  })
}

export function Post (url, params) {
  return axios({
    url,
    method: 'post',
    params
  })
}

export function Download (url, params) {
  return axios({
    url,
    method: 'post',
    params,
    responseType: 'blob'
  })
}

export function Del (url, params) {
  return axios({
    url,
    method: 'delete',
    params
  })
}
