<template>
  <div class="standard">
    <a-card v-bind="standardSearchCard">
      <template v-if="!projectStandardList.length">
        <div class="no-data">
          <a-icon type="frown-o"/>
          暂未匹配到标准
        </div>
      </template>
      <a-tabs v-show="projectStandardList.length">
        <a-tab-pane :key="`standard_${item.id}`" :tab="item.name" v-for="item in projectStandardList">
          <template v-if="standardAttrMap[item.id]&&standardAttrMap[item.id].length">
            <div :key="`standardAttr_${aItem.id}`" class="standard-attr" v-for="(aItem) in standardAttrMap[item.id]">
              <span class="standard-attr-title"> {{ aItem.attributeName }}</span>
              <a-radio-group
                :value="checkStandardAttrMap[item.id]&&checkStandardAttrMap[item.id][aItem.id]"
                @change="(event)=>{handleCheckStandardAttr(item.id,aItem.id,event)}"
                class="standard-attr-details">
                <template v-for="(dItem) in aItem.valueList">
                  <a-radio :key="`standardAttrValue_${dItem.id}`" :value="dItem.id" class="standard-attr-detail">
                    {{ dItem.valName }}
                  </a-radio>
                </template>
              </a-radio-group>
            </div>
          </template>

          <template v-else>
            <div class="no-data">
              <a-icon type="frown-o"/>
              标准下暂未查询到相关属性信息
            </div>
          </template>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <a-card v-bind="itemDetailCard">
      <template>
        <a-row :gutter="32">
          <a-col :span="12">
            <a-card title="未匹配项">
              <el-table :data="unMatchedItemList" v-bind="tableContent">
                <el-table-column label="检测项" prop="itemName" sortable/>
                <el-table-column label="二级分类" prop="cateName" sortable/>
                <el-table-column label="质量标准" prop="standardName" :width="200" sortable>
                  <template #default="{row:data}">
                    <template v-if="data.standardName">  【{{ data.standardCode }}】{{ data.standardName }}</template>
                  </template>
                </el-table-column>
                <el-table-column label="受控" :width="300">
                  <template #default="{row:data,$index}">
                    <template v-if="data.attributeList&&data.attributeList.length>1">
                      <span v-for="(item,index) in data.attributeList" :key="`${$index}_${index}`" :style="{width:'100%',display:'inline-block'}">
                        {{ index+1 }}、{{ item.attributeName }}【{{ item.valName }}】
                      </span>
                    </template>

                    <template v-else-if="data.attributeList&&data.attributeList.length===1">
                      {{ data.attributeList[0].attributeName }}【{{ data.attributeList[0].valName }}】
                    </template>

                  </template>
                </el-table-column>

                <el-table-column label="操作">
                  <template #default="{row:data,$index:index}">
                    <a v-if="data.attrValueId" @click="handleMatchItem([data])">选择</a>
                  </template>
                </el-table-column>
              </el-table>
            </a-card>

          </a-col>

          <a-col :gutter="24" :span="12">
            <a-card title="已匹配项">

              <template #extra>
                <a v-if="matchedItemList.length" @click="applyMatchItem">应用</a>
              </template>

              <el-table :data="matchedItemList" v-bind="tableContent">
                <el-table-column label="检测项" prop="itemName" sortable/>
                <el-table-column label="二级分类" prop="cateName" sortable/>

                <el-table-column label="质量标准" prop="standardName" sortable>
                  <template #default="{row:data}">
                    <template v-if="data.standardName">  【{{ data.standardCode }}】{{ data.standardName }}</template>
                  </template>
                </el-table-column>

                <el-table-column label="受控" :width="300">
                  <template #default="{row:data,$index}">
                    <template v-if="data.attributeList&&data.attributeList.length>1">
                      <span v-for="(item,index) in data.attributeList" :key="`${$index}_${index}`" :style="{width:'100%',display:'inline-block'}">
                        {{ index+1 }}、{{ item.attributeName }}【{{ item.valName }}】
                      </span>
                    </template>

                    <template v-else-if="data.attributeList&&data.attributeList.length===1">
                      {{ data.attributeList[0].attributeName }}【{{ data.attributeList[0].valName }}】
                    </template>

                  </template>
                </el-table-column>
                <el-table-column label="限值">
                  <template #default="{row:data}">
                    {{ data.factorName }}:
                    <template v-if="data.type==='1'">{{ data.limitValue }}{{ data.unit||'' }}</template>
                    <template v-else-if="data.type==='2'">{{ data.lowerLimitValue }}{{ data.unit||'' }}-{{ data.upperLimitValue }}{{ data.unit||'' }}</template>
                    <template v-else>{{ data.describeValue }}</template>
                  </template>
                </el-table-column>
                <el-table-column label="操作" :width="60">
                  <template #default="{row:data,$index:index}">
                    <a @click="handleCancelMatchItem(data,index)">移除</a>
                  </template>
                </el-table-column>
              </el-table>
            </a-card>
          </a-col>
        </a-row>

      </template>
    </a-card>
    <a-card v-bind="configPreview">
      <template #extra>
        <template v-if="showSaveBtn&&applyItemList.length">
          <a @click="save">保存</a>
        </template>
      </template>
      <el-table :data="applyItemList" v-bind="tableContent">
        <el-table-column label="检测项" prop="itemName" :width="200" sortable />
        <el-table-column label="二级分类" prop="cateName" :width="300" sortable/>

        <el-table-column label="质量标准" prop="standardName" sortable>
          <template #default="{row:data}">
            <template v-if="data.standardName">  【{{ data.standardCode }}】{{ data.standardName }}</template>
          </template>
        </el-table-column>

        <el-table-column label="受控">
          <template #default="{row:data,$index}">
            <template v-if="data.attributeList&&data.attributeList.length>1">
              <span v-for="(item,index) in data.attributeList" :key="`${$index}_${index}`" :style="{width:'100%',display:'inline-block'}">
                {{ index+1 }}、{{ item.attributeName }}【{{ item.valName }}】
              </span>
            </template>

            <template v-if="data.attributeList&&data.attributeList.length===1">
              {{ data.attributeList[0].attributeName }}【{{ data.attributeList[0].valName }}】
            </template>

          </template>
        </el-table-column>
        <el-table-column label="限值" :width="200">
          <template #default="{row:data}">
            {{ data.factorName }}:
            <template v-if="data.type==='1'">{{ data.limitValue }}{{ data.unit||'' }}</template>
            <template v-else-if="data.type==='2'">{{ data.lowerLimitValue }}{{ data.unit||'' }}-{{ data.upperLimitValue }}{{ data.unit||'' }}</template>
            <template v-else>{{ data.describeValue }}</template>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="100">
          <template #default="{row:data,$index:index}">
            <a @click="handleCancelApplyMatchItem(data,index)">移除</a>
          </template>
        </el-table-column>
      </el-table>

    </a-card>

  </div>
</template>

<script>
import { groupBy } from 'lodash'
import { tranOptionsToMap } from '@/utils/util'

import { standardItemFactorApi } from '@/api/quote'
import { projectStandardApi, projItemStandardApi, standardAttrApi, standardAttrValueTypeOptions } from '@/api/quality/qualityControlApi'

const standAttrValueTypeMap = tranOptionsToMap(standardAttrValueTypeOptions)

export default {
  props: {
    projId: {
      type: Number,
      required: true
    },

    showSaveBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {

      // 加载默认的项目配置
      loadingDefaultProjectStandard: false,

      // 项目标准信息
      projectStandardList: [],

      // 已选择的标准属性
      checkStandardAttrMap: {},

      // 属性因素详细信息缓存
      attrFactorDetailCacheMap: {},

      // 标准属性集合
      standardAttrMap: {},

      // 未匹配的检测项
      unMatchedItemList: [],

      // 未匹配的检测项
      originalUnMatchItemList: [],

      // 未匹配的检测项
      matchedItemList: [],

      // 未匹配的检测项
      applyItemList: [],

      standAttrValueTypeMap,

      tableContent: {
        border: true,
        size: 'mini',
        maxHeight: 400,
        stripe: true,
        rowKey: (data) => {
          return data.itemId + (data.id || data.attrValueId || '')
        },
        bordered: true
      },

      standardSearchCard: {
        title: '质控标准 ',
        type: 'inner',
        style: { marginBottom: '16px' }
      },

      itemDetailCard: {
        title: '',
        type: 'inner',
        style: { marginBottom: '16px' }

      },

      configPreview: {
        title: '配置预览',
        type: 'inner',
        style: { marginBottom: '16px' }
      }

    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (val) {
        this.searchProjectStandard(val)
        this.searchProjectTaskItem(val)
      }

    },

    projectStandardList: {
      handler (val) {
        val.length && this.searchStandardAttrs(val.map(item => item.id))
      }

    }
  },

  methods: {

    // 查询项目检测项信息
    searchProjectTaskItem (projId) {
      projItemStandardApi.listProjectStandard({ projId, page: false }).then(res => {
        if (res.code === 0) {
          const saveData = res.data.filter(item => item.id)
          const unSaveData = res.data.filter(item => !item.id)
          unSaveData.length && this.originalUnMatchItemList.splice(0, this.originalUnMatchItemList.length, ...unSaveData)
          unSaveData.length && this.unMatchedItemList.splice(0, this.unMatchedItemList.length, ...unSaveData)
          if (saveData.length) {
            saveData.forEach(item => { item.attributeList = item.attributeList && JSON.parse(item.attributeList) || [] })
            this.applyItemList.splice(0, this.applyItemList, ...saveData)
          }
        } else {
          this.$message.error(`查询项目检测项信息出错：${res.msg}`)
        }
      })
    },

    // 查询项目的标准
    searchProjectStandard (projId) {
      projectStandardApi.listProjectStandard({ projId }).then(res => {
        if (res.code === 0) {
          this.projectStandardList.splice(0, this.projectStandardList.length, ...res.data)
        } else {
          this.$message.error(`获取项目对应的标准信息错误：${res.msg}`)
        }
      }).finally(() => {
        this.loadingDefaultProjectStandard = true
      })
    },

    // 查询标准属性信息
    searchStandardAttrs (standardIds) {
      // 如果标准已经加载完毕，无须查询
      const needSearchIds = standardIds.filter(item => !this.standardAttrMap.hasOwnProperty(item))

      // 查询属性赋值
      needSearchIds.length && standardAttrApi.listAttribute({ standardIds: needSearchIds }).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            this.standardAttrMap = Object.assign({}, this.standardAttrMap, groupBy(res.data, 'standardId'))
          } else {
            this.$message.error(`获取标准对应的属性错误：${res.msg}`)
          }
        }
      })
    },

    // 处理标准属性勾选事件
    handleCheckStandardAttr (standardId, attrId, { target }) {
      (this.checkStandardAttrMap[standardId] || (this.checkStandardAttrMap[standardId] = {}))[attrId] = target.value
      this.checkStandardAttrMap = Object.assign({}, this.checkStandardAttrMap)
      this.searchStandardAttrValue(standardId)
    },

    // 根据标准和勾选属性查询检测项限值
    async searchStandardAttrValue (standardId) {
      const attributeList = Object.keys(this.checkStandardAttrMap[standardId]).map(item => {
        return { attributeId: item, valueId: this.checkStandardAttrMap[standardId][item] }
      })

      const result = await standardAttrApi.listAttributeValue({ projId: this.projId, standardId, attributeList }).then(res => {
        if (res.code === 0) {
          return res.data
        } else {
          this.$message.error(`查询属性限值错误：${res.msg}`)
          return []
        }
      })

      if (result.length) {
        const needSearchFactorId = result.map(item => parseInt(item.factorId)).filter(item => !this.attrFactorDetailCacheMap.hasOwnProperty(item))
        needSearchFactorId.length && await this.searchAttrFactorDetails(needSearchFactorId)
        const matchItemIds = this.originalUnMatchItemList.map(item => item.itemId)
        const needhandleRes = []
        result.forEach(item => {
          if (matchItemIds.includes(item.itemId)) {
            needhandleRes.push(item)
          }
        })
        needhandleRes.length && this.handleSearchAttrValue(needhandleRes, standardId)
      }
    },

    // 查询因素详细信息
    searchAttrFactorDetails (factorIds) {
      return standardItemFactorApi.list({ ids: factorIds }).then(res => {
        if (res.code === 0) {
          res.data.length && (this.attrFactorDetailCacheMap = Object.assign({}, this.attrFactorDetailCacheMap, groupBy(res.data, 'id')))
        } else {
          this.$message.error(`查询因素详细信息出错${res.msg}`)
        }
      })
    },

    // 处理查询的限定值
    handleSearchAttrValue (oldItemList, standardId) {
      const standardItem = this.projectStandardList.find(item => item.id === standardId)

      // 给数据填上必要的属性
      const itemList = oldItemList.map(item => {
        // 属性和受控名称
        item.attributeList.forEach(aItem => {
          const standardAttrList = this.standardAttrMap[standardId]
          const attribute = standardAttrList.find(cItem => cItem.id === aItem.attributeId)
          const valueData = attribute.valueList.find(cItem => cItem.id === aItem.valueId)
          aItem.attributeName = attribute.attributeName
          aItem.valName = valueData.valName
        })

        // 赋值原始数据信息 检测项名称，二级分类名称
        const originalItem = this.originalUnMatchItemList.find(cItem => cItem.itemId === item.itemId) || {}
        return Object.assign({}, originalItem, {
          type: item.type,
          attrValueId: item.id,
          limitValue: item.limitValue,
          lowerLimitValue: item.lowerLimitValue,
          upperLimitValue: item.upperLimitValue,
          factorId: item.factorId,
          standardId: standardId,
          standardName: standardItem.name,
          standardCode: standardItem.standardId,
          attributeList: item.attributeList,
          unit: this.attrFactorDetailCacheMap[item.factorId] && this.attrFactorDetailCacheMap[item.factorId].length && this.attrFactorDetailCacheMap[item.factorId][0].unit,
          factorName: this.attrFactorDetailCacheMap[item.factorId] && this.attrFactorDetailCacheMap[item.factorId].length && this.attrFactorDetailCacheMap[item.factorId][0].name
        })
      })

      // 根据后台返回的数量，如果是多个或者没有，视为未匹配，唯一一个自动匹配
      const itemGroup = groupBy(itemList, 'itemId')
      const matchItem = []
      const unMatchItem = []

      Object.keys(itemGroup).forEach(itemId => {
        if (itemGroup[itemId].length === 1) {
          matchItem.push.apply(matchItem, itemGroup[itemId])
        } else {
          unMatchItem.push.apply(unMatchItem, itemGroup[itemId])
        }
      })
      matchItem.length && this.handleMatchItem(matchItem)
      unMatchItem.length && this.handleUnMatchItem(unMatchItem)
    },

    // 匹配检测项
    handleMatchItem (itemList) {
      // 移除未匹配
      const newUnMatchItemList = []
      const matchItemIds = itemList.map(item => item.itemId)
      this.unMatchedItemList.forEach((cItem) => {
        if (!matchItemIds.includes(cItem.itemId)) {
          newUnMatchItemList.push(cItem)
        }
      })
      this.unMatchedItemList.splice(0, this.unMatchedItemList.length)
      newUnMatchItemList.length && this.unMatchedItemList.push.apply(this.unMatchedItemList, newUnMatchItemList)

      // 移除已匹配中移除
      const newMatchItemList = []
      this.matchedItemList.forEach((cItem) => {
        if (!matchItemIds.includes(cItem.itemId)) {
          newMatchItemList.push(cItem)
        }
      })

      this.matchedItemList.splice(0, this.matchedItemList.length)
      newMatchItemList.length && this.matchedItemList.push.apply(this.matchedItemList, newMatchItemList)
      this.matchedItemList.push.apply(this.matchedItemList, itemList)
    },

    // 未匹配检测项
    handleUnMatchItem (itemList) {
      // 如果已匹配，需要过滤掉数据
      const matchItemIds = this.matchedItemList.map(item => item.itemId)
      const unMatchItems = itemList.filter(item => !matchItemIds.includes(item.itemId))
      if (unMatchItems.length) {
        // 将过期的未匹配数据移除
        const newUnMatchItemList = []
        const unMatchItemIds = unMatchItems.map(item => item.itemId)
        this.unMatchedItemList.forEach(item => {
          if (!unMatchItemIds.includes(item.itemId)) {
            newUnMatchItemList.push(item)
          }
        })
        this.unMatchedItemList.splice(0, this.unMatchedItemList.length)
        newUnMatchItemList.length && this.unMatchedItemList.push.apply(this.unMatchedItemList, newUnMatchItemList)
        this.unMatchedItemList.push.apply(this.unMatchedItemList, unMatchItems)
      }
    },

    // 取消已匹配的检测项
    handleCancelMatchItem (cancelMatchData, index) {
      this.matchedItemList.splice(index, 1)
      // 原始数据如果没有，加上去
      const originalUnMatchItem = this.originalUnMatchItemList.find(item => item.itemId === cancelMatchData.itemId)
      !originalUnMatchItem && this.originalUnMatchItemList.push(cancelMatchData)
      this.handleUnMatchItem([cancelMatchData])
    },

    // 取消配置预览的检测项
    handleCancelApplyMatchItem (matchData, index) {
      this.handleMatchItem([matchData])
      this.applyItemList.splice(index, 1)
    },

    // 应用已匹配
    applyMatchItem () {
      this.applyItemList.push.apply(this.applyItemList, this.matchedItemList)
      this.matchedItemList.splice(0, this.matchedItemList.length)
    },

    // 保存入库
    save () {
      if (!this.applyItemList.length) {
        this.$message.error('暂无可保存数据')
        return false
      }

      this.$confirm({
        title: '确认提交更改吗？',
        onOk: () => {
          const saveData = this.applyItemList.map(item => Object.assign({}, item, { attributeList: item.attributeList && JSON.stringify(item.attributeList) || null }))
          projItemStandardApi.save(saveData).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel () {}
      })
    }

  }
}
</script>

<style lang="less" scoped>
  @import './_standard.less';
</style>
