<template>
  <div>
    <a-card v-bind="reportTaskCard">
      <!-- 报告基本信息 -->
      <detail-list size="small" v-if="Object.keys(reportOrderInfo).length" :col="3">
        <detail-list-item term="项目名称">
          {{ reportOrderInfo.projName }}
        </detail-list-item>

        <detail-list-item term="报告类型">
          <a-tag color="blue">
            {{ reportType[reportOrderInfo.projReportType] || '未识别' }}
          </a-tag>
        </detail-list-item>

        <detail-list-item v-if="reportOrderInfo.reportStartTime" term="实际时间">
          {{ reportOrderInfo.reportStartTime }} ~ {{ reportOrderInfo.reportDoneTime }}
        </detail-list-item>

        <detail-list-item v-else term="预计时间">
          {{ reportOrderInfo.reportBeginTime }} ~ {{ reportOrderInfo.reportEndTime }}
        </detail-list-item>

        <detail-list-item term="报告负责人">
          {{ reportOrderInfo.reportLeaderName || '-' }}
        </detail-list-item>

        <detail-list-item term="委托单位">
          {{ reportOrderInfo.csrName }}
        </detail-list-item>

        <detail-list-item term="委托日期">
          {{ reportOrderInfo.projScheduledStart | momentFilter('YYYY-MM-DD') }}
        </detail-list-item>
      </detail-list>

      <a-card v-bind="checkTaskDetailCard">
        <a-tabs v-bind="checkTaskTabs" v-model="checkTaskTabs.key">

          <a-tab-pane tab="安排信息" key="schedule">
            <detail-list size="small" v-if="Object.keys(reportOrderInfo).length" :col="3">
              <detail-list-item term="报告人">
                {{ reportTaskInfo.reportUserName || '暂未安排' }}
              </detail-list-item>

              <detail-list-item term="安排状态">
                <a-tag color="orange" v-if="reportOrderInfo.status===null">待排</a-tag>
                <a-tag color="green" v-else>已排</a-tag>
              </detail-list-item>

              <detail-list-item term="预计时间">
                <template v-if="reportOrderInfo.reportBeginTime">
                  {{ reportOrderInfo.reportBeginTime | momentFilter('YYYY-MM-DD') }}
                </template>

                <template v-if="reportOrderInfo.reportEndTime" :style="{marginLeft:'20px'}">
                  ~{{ reportOrderInfo.reportEndTime | momentFilter('YYYY-MM-DD') }}
                </template>
              </detail-list-item>

            </detail-list>

            <div class="no-data" v-else>
              <a-icon type="frown-o"/>
              暂无数据
            </div>
          </a-tab-pane>

          <a-tab-pane tab="报告信息" key="report">
            <detail-list size="small" v-if="Object.keys( reportTaskInfo).length" :col="3">
              <detail-list-item term="报告人">
                {{ reportTaskInfo.reportUserName || '暂未安排' }}
              </detail-list-item>

              <detail-list-item term="当前进度">
                <a-tag v-if="reportTaskInfo.status==='0'" color="purple">
                  {{ statusDict[reportTaskInfo.status] }}
                </a-tag>
                <a-tag v-else-if="reportTaskInfo.status==='1'" color="orange">
                  {{ statusDict[reportTaskInfo.status] }}
                </a-tag>
                <a-tag v-else-if="reportTaskInfo.status==='2'" color="green">
                  {{ statusDict[reportTaskInfo.status] }}
                </a-tag>
                <a-tag v-else-if="reportTaskInfo.status==='3'" color="red">
                  {{ statusDict[reportTaskInfo.status] }}
                </a-tag>
                <a-tag v-else-if="reportTaskInfo.status==='4'" color="red">
                  {{ statusDict[reportTaskInfo.status] }}
                </a-tag>
              </detail-list-item>

              <detail-list-item term="实际时间">
                <template v-if="reportTaskInfo.startTime">
                  {{ reportTaskInfo.startTime }}
                </template>

                <template v-if="reportTaskInfo.doneTime">
                  ~{{ reportTaskInfo.doneTime }}
                </template>
              </detail-list-item>

              <template v-if="reportTaskInfo.checkStatus">
                <detail-list-item term="审核人">
                  {{ reportTaskInfo.checkUser || '暂未安排' }}
                </detail-list-item>

                <detail-list-item term="审核时间">
                  {{ reportTaskInfo.checkTime }}
                </detail-list-item>

                <detail-list-item term="审核结果">
                  <a-tag v-if="reportTaskInfo.checkStatus==='0'" color="purple">
                    {{ checkStatusDict[reportTaskInfo.checkStatus] }}
                  </a-tag>
                  <a-tag v-else-if="reportTaskInfo.checkStatus==='1'" color="green">
                    {{ checkStatusDict[reportTaskInfo.checkStatus] }}
                  </a-tag>
                  <a-tag v-else-if="reportTaskInfo.checkStatus==='2'" color="red">
                    {{ checkStatusDict[reportTaskInfo.checkStatus] }}
                  </a-tag>
                  <span v-else>-</span>
                </detail-list-item>
              </template>

              <template v-if="reportTaskInfo.approvalStatus">
                <detail-list-item term="审批人">
                  {{ reportTaskInfo.approvalUser || '暂未安排' }}
                </detail-list-item>

                <detail-list-item term="审批时间">
                  {{ reportTaskInfo.approvalTime }}
                </detail-list-item>

                <detail-list-item term="审核结果">
                  <a-tag v-if="reportTaskInfo.approvalStatus==='0'" color="purple">
                    {{ approvalStatusDict[reportTaskInfo.approvalStatus] }}
                  </a-tag>
                  <a-tag v-else-if="reportTaskInfo.approvalStatus==='1'" color="green">
                    {{ approvalStatusDict[reportTaskInfo.approvalStatus] }}
                  </a-tag>
                  <a-tag v-else-if="reportTaskInfo.approvalStatus==='2'" color="red">
                    {{ approvalStatusDict[reportTaskInfo.approvalStatus] }}
                  </a-tag>
                  <span v-else>-</span>
                </detail-list-item>
              </template>

              <detail-list-item term="报告文件" :item-col="1">
                <template v-if="reportTaskInfo.accessory">

                  <a v-for="(item,index ) in JSON.parse(reportTaskInfo.accessory) " :key="index" @click="reportView(reportTaskInfo)">
                    {{ item.name }}
                  </a><br/>
                </template>
              </detail-list-item>

            </detail-list>

            <div class="no-data" v-else>
              <a-icon type="frown-o"/>
              暂无数据
            </div>
          </a-tab-pane>

          <a-tab-pane tab="派发信息" key="send">

            <detail-list size="small" v-if="Object.keys( reportSendInfo).length" :col="3">
              <detail-list-item term="报告人">
                {{ reportTaskInfo.reportUserName || '暂未安排' }}
              </detail-list-item>

              <detail-list-item term="领取方式">
                <a-tag v-if="reportSendOrderInfo.projReportReceive==='1'" color="blue">
                  {{ reportReceiveDict[reportSendOrderInfo.projReportReceive] }}
                </a-tag>

                <a-tag v-else-if="reportSendOrderInfo.projReportReceive==='2'" color="green">
                  {{ reportReceiveDict[reportSendOrderInfo.projReportReceive] }}
                </a-tag>

                <a-tag v-else-if="reportSendOrderInfo.projReportReceive==='9'" color="orange">
                  {{ reportReceiveDict[reportSendOrderInfo.projReportReceive] }}
                </a-tag>
              </detail-list-item>

              <detail-list-item term="当前进度">
                <a-tag v-if="reportSendOrderInfo.status==='0'" color="purple">
                  {{ sendStatusDict[reportSendOrderInfo.status] }}
                </a-tag>
                <a-tag v-else-if="reportSendOrderInfo.status==='1'" color="green">
                  {{ sendStatusDict[reportSendOrderInfo.status] }}
                </a-tag>
                <a-tag v-else-if="reportSendOrderInfo.status==='2'" color="green">
                  {{ sendStatusDict[reportSendOrderInfo.status] }}
                </a-tag>
              </detail-list-item>

              <template v-if="parseInt(reportSendOrderInfo.status)">
                <detail-list-item term="派发人">
                  {{ reportSendInfo.sendUser }}
                </detail-list-item>

                <detail-list-item term="派发时间">
                  {{ (reportSendInfo.sendTime || reportSendInfo.createTime) | momentFilter('YYYY-MM-DD') }}
                </detail-list-item>

                <detail-list-item term="接收人">
                  {{ reportSendInfo.receiveUser }}
                </detail-list-item>

                <detail-list-item term="接收人电话">
                  {{ reportSendInfo.receiveTel }}
                </detail-list-item>

                <detail-list-item term="接收人地址">
                  {{ reportSendInfo.receiveAddress || '-' }}
                </detail-list-item>

                <detail-list-item term="接收人地址" :item-col="1">
                  {{ reportSendInfo.sendNote || '-' }}
                </detail-list-item>
              </template>

            </detail-list>

            <div class="no-data" v-else>
              <a-icon type="frown-o"/>
              暂无数据
            </div>

          </a-tab-pane>

        </a-tabs>
      </a-card>
    </a-card>

    <a-modal v-bind="modalProps" :visible="previewVisible" @cancel="cancelPreview">
      <div v-for="(item,index) in this.accessoryData" :key="`accessory_`+index">
        <a @dblclick="previewFile(item)" title="双击查看报告文件">{{ item.name }}</a>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reportTaskApi, reportOrderApi, reportSendOrderApi } from '@/api/sample'
import { applyOutboundColumn } from '@/pages/cloud-quote/sample-info/sampleTypeColumn'
import { sysCommonApi } from '@/api/common'

import { SampleInfoTable } from '@/pages/cloud-quote/sample-info/modules'
import DetailList from '@/components/tools/DetailList'
import { CheckTaskTable } from '@/pages/cloud-quote/check-task/modules'

const DetailListItem = DetailList.Item

export default {
  components: { DetailList, DetailListItem, SampleInfoTable, CheckTaskTable },

  props: {
    projId: {
      type: Number
    }
  },

  data () {
    return {
      projectId: this.projId,

      /// 报告安排信息
      reportOrderInfo: {},

      /// 报告任务信息
      reportTaskInfo: {},

      /// 报告派发任务信息
      reportSendOrderInfo: {},

      /// 报告派发信息
      reportSendInfo: {},

      accessoryData: [],

      previewVisible: false,

      modalProps: {
        width: 600,
        visible: false,
        closable: true,
        destroyOnClose: true,
        centered: false,
        wrapClassName: 'done-modal',
        bodyStyle: {},
        title: '报告预览',
        footer: null
      },

      statusDict: { '0': '未开始', '1': '进行中', '2': '已完成', '3': '审核驳回', '4': '审批驳回' },

      checkStatusDict: { '0': '待审核', '1': '审核通过', '2': '审核驳回' },

      approvalStatusDict: { '0': '待审批', '1': '审批通过', '2': '审批驳回' },

      sendStatusDict: { '0': '待发', '1': '已邮寄', '2': '已取' },

      reportReceiveDict: { '1': '邮寄', '2': '自取', '9': '其他' },

      SampleInfoTable: {
        ref: 'sampleTable',
        columns: applyOutboundColumn,
        source: '',
        dataType: 'checkOrder',
        statuses: ['2', '3', '4'],
        disabledOrderStatus: ['1', '2']
      },

      purUseIndexModal: {
        ref: 'purUseIndexModal',
        dataType: 1,
        visible: false,
        title: '申请记录',
        cancel: () => { this.purUseIndexModal.visible = false },
        show: () => { this.purUseIndexModal.visible = true }
      },

      checkTaskTabs: {
        size: 'small',
        key: 'schedule'
      },

      reportTaskCard: {
        title: '报告信息',
        type: 'inner',
        class: 'task-card'
      },

      checkTaskDetailCard: {
        type: 'inner',
        style: { marginTop: '24px' },
        bodyStyle: { padding: '10px' }
      },

      siteFeedbackCard: {
        type: 'inner',
        class: 'feedback-card'
      }
    }
  },

  computed: {
    reportType () {
      return { ...this.$store.state.code.cache.proj_report_type }
    }
  },

  watch: {
    projectId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.queryReportOrder(val)
          this.queryReportTask(val)
          this.queryReportSend(val)
        }
      }
    }
  },

  filters: {},

  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
  },

  methods: {

    /// 报告预览
    reportView (record) {
      const accessory = JSON.parse(record.accessory)
      this.accessoryData = accessory
      this.$nextTick(() => {
        this.previewVisible = true
      })
    },

    /// 取消预览
    cancelPreview () {
      this.previewVisible = false
      this.accessoryData = []
      this.previewUrl = null
    },

    /// 预览文件
    previewFile (file) {
      const isProduction = process.env.NODE_ENV === 'production'
      if (isProduction) {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + sysCommonApi.projectFullUrl + file.url, file.name, null, false)
      } else {
        window.location = sysCommonApi.downLoad + '?filePath=' + file.url + '&fileName=' + file.name + '&token=' + this.$store.getters.token
      }
    },

    /// 查询项目对应的报告安排信息
    queryReportOrder (projId) {
      reportOrderApi.list({ projId, forceStatus: false, page: false }).then(res => {
        if (res.code === 0) {
          this.reportOrderInfo = res.data && res.data.length && res.data[0] || {}
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /// 查询项目对应的报告编制信息
    queryReportTask (projId) {
      reportTaskApi.getOne({ projId, page: false }).then(res => {
        if (res.code === 0) {
          this.reportTaskInfo = res.data || {}
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /// 查询项目对应的报告派发信息
    queryReportSend (projId) {
      reportSendOrderApi.list({ projId, page: false }).then(res => {
        if (res.code === 0) {
          this.reportSendOrderInfo = res.data && res.data.length && res.data[0] || {}
        } else {
          this.$message.error(res.msg)
        }
      })

      reportSendOrderApi.findOne({ projId }).then(res => {
        if (res.code === 0) {
          this.reportSendInfo = res.data || {}
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$route.params && vm.$route.params.projId) {
        vm.projectId = parseInt(vm.$route.params.projId) || null
      }

      if (vm.$route.query && vm.$route.query.projId) {
        vm.projectId = parseInt(vm.$route.query.projId) || null
      }
    })
  }
}
</script>

<style lang="less" scoped>
.text {
  color: rgba(0, 0, 0, .45);
}

.no-data {
  color: rgba(0, 0, 0, .25);
  text-align: center;
  line-height: 64px;
  font-size: 16px;
}

.total-border {
  border: 1px solid #E8E8E8;
  text-align: center;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
}

.heading {
  color: rgba(0, 0, 0, .85);
  font-size: 20px;
}

.task-card {
  margin-bottom: 24px;

  &_title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, .70);
  }

  &_sub-title {
    color: gray;
    margin-left: 10px;
  }

}
</style>
