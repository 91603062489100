<template>
  <div class="utabs" style="height: 100%">
    <a-tabs v-model="curIdx" size="small" style="height: 100%; padding: 0; margin: 0; overflow: hidden" tabPosition="left" type="line">
      <a-tab-pane :closable="false" :key="idx" :tab="obj.templateName" style="height: 100%" v-for="(obj, idx) of templateList">
        <!--        <a-row class="archive">-->
        <!--          当前归档：<a>项目报价文件</a>  归档日期：2020-09-22 17:43  <a>重新归档</a>-->
        <!--        </a-row>-->
        <a-row style="height: 100%">
          <iframe :src="iframeSrc" frameborder="0" style="width: 100%; height: 100%; margin: 0 auto" />
          <Watermark />
        </a-row>
      </a-tab-pane>
      <a-tab-pane :closable="false" :key="templateList.length" tab="评价标准" style="height: 100%">
        <a-row style="height: 100%">
          <iframe :src="evaluationCriterionIframeSrc" frameborder="0" style="width: 100%; height: 100%; margin: 0 auto" />
          <Watermark />
        </a-row>
      </a-tab-pane>
      <a-tab-pane :closable="false" :key="templateList.length + 1" tab="采样时间安排" style="height: 100%">
        <a-row style="height: 100%">
          <a-card>
            <div class="table-page-search-submitButtons">
              <a-date-picker
                format="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }"
                style="width: 300px; margin-left: 10px; margin-right: 10px"
                v-model="queryParam.dateTime"
                @change="handleDateChange"
              />
              <a-button-group class="table-page-search-submitButtons">
                <a-button type="primary" icon="search" @click="search">查询</a-button>
                <a-button icon="reload" @click="reset">重置</a-button>
                <a-button type="primary" @click="edit">修改</a-button>
              </a-button-group>
            </div>
          </a-card>
          <iframe :src="samplingScheduleIframeSrc" frameborder="0" style="width: 100%; height: 100%; margin: 0 auto" />
          <Watermark />
        </a-row>
      </a-tab-pane>
      <a-tab-pane :closable="false" :key="templateList.length + 2" tab="检测方法" style="height: 100%">
        <a-row style="height: 100%">
          <iframe :src="testMethodIframeSrc" frameborder="0" style="width: 100%; height: 100%; margin: 0 auto" />
          <Watermark />
        </a-row>
      </a-tab-pane>
    </a-tabs>
    <category-form ref="CategoryModal" @ok="loadCate" v-bind="$attrs" />
  </div>
</template>

<script>
import { fileTemplateUreportApi } from '@/api/fileTemplate'
import { fileTemplateUreportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import Watermark from '@/pages/common/watermark/Watermark'
import CategoryForm from './CategoryForm'
import moment from 'moment'

export default {
  name: 'ProjectTaskDownloadPreview',
  inheritAttrs: false,
  components: {
    Watermark,
    CategoryForm
  },
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: Number,
      default: () => null
    }
  },
  data() {
    return {
      templateParam: {
        page: false,
        templateType: fileTemplateUreportTypeEnum.projectTask
      },
      curIdx: 0,
      templateList: [],
      url: '',
      queryParam: {
        dateTime: null
      },
      queryParamStr: '',
      evaluationCriterionIframeSrc: `${fileTemplateUreportApi.preview}?_u=DB:执行标准.ureport.xml&projId=${this.params.projId}`,
      testMethodIframeSrc: `${fileTemplateUreportApi.preview}?_u=DB:检测方法.ureport.xml&projId=${this.params.projId}`,
      cateMap: ''
    }
  },
  watch: {
    params: {
      immediate: true,
      handler(newValue, oldValue) {
        console.log(newValue, oldValue)
      }
    }
  },
  computed: {
    iframeSrc: function () {
      if (this.templateList && this.templateList.length > 0) {
        if (this.curIdx !== this.templateList.length && this.curIdx !== this.templateList.length + 1 && this.curIdx !== this.templateList.length + 2) {
          var template = this.templateList[this.curIdx]
          return `${fileTemplateUreportApi.preview}?_t=1,2,3,4,5&_u=${template.templateFile}&templateId=${template.id}&projId=${this.params.projId}&_n=${this.params.fileName}`
        }
      }
      return ''
    },
    samplingScheduleIframeSrc() {
      if (this.type) {
        return `${fileTemplateUreportApi.preview}?_u=DB:采样时间安排计划.ureport.xml&projId=${this.params.projId}` + this.queryParamStr + '&type=' + this.type
      } else {
        return `${fileTemplateUreportApi.preview}?_u=DB:采样时间安排计划.ureport.xml&projId=${this.params.projId}` + this.queryParamStr
      }
    }
  },
  methods: {
    moment,
    selectTemplate(idx) {
      this.curIdx = idx
    },
    listFileTemplate() {
      fileTemplateUreportApi.list(this.templateParam).then((result) => {
        if (result.code === 0) {
          this.templateList = result.data
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    handleDateChange(value) {
      // console.log(value)
      this.search()
    },
    // 查询
    search() {
      var queryParamStr = ''
      if (this.queryParam.dateTime) {
        const dateTime = this.queryParam.dateTime
        queryParamStr = `&hour=${dateTime.format('HH')}&minute=${dateTime.format('mm')}&year=${dateTime.format('YYYY')}&month=${dateTime.format('MM')}&day=${dateTime.format('DD')}`
        console.log(queryParamStr)
      }
      this.queryParamStr = queryParamStr
    },
    // 重置
    reset() {
      this.queryParam.dateTime = null
      this.cateMap = ''
      this.type = null
      this.search()
    },
    edit() {
      this.$refs.CategoryModal.show()
    },
    loadCate(cateMap) {
      this.cateMap = cateMap
      this.type = 1
    }
  },
  created() {
    this.curIdx = 0
    this.listFileTemplate()
  }
}
</script>

<style scoped lang="less">
.utabs {
  .archive {
    margin: 0px 5px;
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
  }
}
</style>
