<template>
  <div>
    <a-modal v-bind="modal" v-on="modal">
      <a-form>
        <a-row :gutter="rowProps.gutter">
          <a-col :span="16">
            <a-form-item label="商机名称" v-bind="formItemLayout">
              <a-input v-model="queryParam.title" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <span class="table-page-search-submitButtons">
              <a-button-group>
                <a-button type="primary" icon="search" @click="search">查询</a-button>
              </a-button-group>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-card>
        <div slot="extra">
          <a-button type="primary" icon="plus" @click="add" v-action:business:add>商机登记</a-button>
        </div>
        <s-table v-bind="table" v-on="table">
          <template slot="opRender" slot-scope="v, r">
            <div class="row-button">
              <a @click="() => edit(r)" v-action:business:edit><a-icon type="edit"/>修改</a>
              <a @click="() => del(r)" v-action:business:del><a-icon type="delete"/>作废</a>
              <a @click="() => addPlan(r)" v-action:quote:edit><a-icon type="plus"/>编制方案</a>
            </div>
          </template>
        </s-table>
      </a-card>
      <BusinessForm ref="businessForm" @ok="search"></BusinessForm>
    </a-modal>
  </div>
</template>
<script>
import { businessApi, customerApi } from '@/api/quote'
import { projectApi } from '@/api/project'
import { STable } from '@/components'
import BusinessForm from './BusinessForm'
import QuoteTable from './QuoteTable'
import moment from 'moment'
import JDateRangePicker from '@/components/j/JDateRangePicker'
import { search, scrollTable } from '@/pages/common/mixins/list'
import ACol from 'ant-design-vue/es/grid/Col'

export default {
  name: 'BusinessListVue',
  components: {
    ACol,
    STable,
    BusinessForm,
    JDateRangePicker,
    QuoteTable
  },
  mixins: [search, scrollTable],
  props: {
    inParam: { type: Object, default: () => {} },
    limitTime: { type: Boolean, default: true, required: false }
  },
  data () {
    return {
      dateFormat: 'YYYY-MM-DD',
      sourceList: [{ id: '1', name: '电话咨询' }, { id: '2', name: '邮件咨询' }, { id: '3', name: '预约上门' }, { id: '4', name: '互联网' }, { id: '5', name: '转介绍' }],
      dateRang: [],
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },

      modal: {
        title: '选择商机',
        visible: false,
        width: '860px',
        centered: true,
        maskClosable: false,
        destroyOnClose: true,
        ok: this.ok,
        cancel: this.cancel
      },

      moreParam: false,
      queryParam: {
      },
      customerList: [],

      table: {
        ref: 'table',
        size: 'small',
        loading: false,
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        scroll: {
          y: '500px'
        },
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '商机名称', width: '130px', dataIndex: 'title' },
          { title: '客户单位', width: '130px', dataIndex: 'entrustCustomerName' },
          { title: '委托人', width: '80px', dataIndex: 'entrustContactsName' }
        ],
        data: parameter => {
          return businessApi.listIncludeQuote(Object.assign(parameter, this.queryParam)).then(res => {
            if (res.code === 0) {
              return res.data
            } else {
              this.$message.error('查询失败')
              return null
            }
          }).catch(err => {
            console.log(err)
          })
        },
        rowSelection: {
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) => {
            this.table.selectedRowKeys = selectedRowKeys
            this.table.selectedRows = selectedRows
          }
        }
      }
    }
  },
  methods: {
    moment,
    open () {
      this.modal.visible = true
    },
    resetSearchForm () {
      this.queryParam = {
      }
      this.search()
    },
    search () {
      if (this.dateRang[0]) {
        this.queryParam.createTimeStart = this.dateRang[0] + ' 00:00:00'
      } else {
        this.queryParam.createTimeStart = ''
      }
      if (this.dateRang[1]) {
        this.queryParam.createTimeEnd = this.dateRang[1] + ' 23:59:59'
      } else {
        this.queryParam.createTimeEnd = ''
      }
      this.$refs.table.refresh(true)
    },
    add () {
      this.$refs.businessForm.add()
    },
    edit (_record) {
      this.$refs.businessForm.edit(_record)
    },
    addPlan (r) {
      this.$router.push({ name: 'plan-quote-mgr', params: { enterType: 'add', busId: r.id } })
    },
    del (_record) {
      const $self = this
      this.$confirm({
        title: '确认作废?',
        content: `请确认是否作废此[${_record.title}],作废后将不可恢复`,
        onOk () {
          businessApi.del({ id: _record.id }).then(result => {
            if (result.code === 0) {
              $self.$message.success(`作废[${_record.title}]成功`)
              $self.search()
            } else {
              $self.$message.error(`作废[${_record.title}]失败`)
            }
          })
        },
        onCancel () {}
      })
    },
    listCustomer () {
      customerApi.listCustomer({ page: false, authEnable: false }).then(result => {
        if (result.code === 0) {
          this.customerList = result.data
        }
      })
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    ok () {
      if (this.table.selectedRows.length > 0) {
        var obj = this.table.selectedRows[0]
        this.$emit('ok', obj)
        this.cancel()
      } else {
        this.$message.error('还没选择分包商!')
      }
    },
    cancel () {
      this.modal.visible = false
    }
  },
  watch: {
  },
  filters: {
    fiterProgressName (v) {
      return projectApi.getProgressName(v)
    }
  },
  created () {
    this.$store.dispatch('code/select', 'proj_report_type')
    this.$store.dispatch('code/select', 'sample_source')
    this.$store.dispatch('code/select', 'quote_type')

    // 暂不清楚为什么要加上时间的限制，防止数据量过大？ 添加 prop 默认限制，复制方案时，传递false
    // 另外BUG：created 创建的时间限制只有在点击查询的时候才生效，默认第一次自动查询不会调用search 方法，导致第一次进去可以显示所有的商机，点击查询后，数量变少
    if (this.limitTime) {
      const time1 = moment().month(moment().month() - 2).startOf('month').format(this.dateFormat)
      const time2 = moment().endOf('day').format(this.dateFormat)
      this.dateRang = [time1, time2]
    }
    this.listCustomer()
  }
}
</script>
<style>
  .expand-icon-hide .ant-table-row-expand-icon {
    display: none;
  }
</style>

<style>
  .row-button{
    margin: 0px 5px;
  }
</style>

<style lang="less">

</style>
