<template>
  <div>
    <a-card>
      <a-timeline>
        <a-timeline-item v-for="(obj,idx) in logList" :key="idx">
          <p style="font-weight: bold">{{ obj.nodeName }}</p>
          <p><span><a-icon type="user" title="业务员"/>业务员：{{ obj.relHandlerName }}</span><span style="margin-left: 20px"><a-icon type="clock-circle" title="完成时间"/>完成时间：{{ obj.msgTime }}</span></p>
          <p><a-icon type="history" title="工作用时"/>工作用时：{{ obj.nodeWorkTime }}</p>
          <p><a-icon type="calendar" title="订单用时"/>订单用时：{{ obj.taskWorkTime }}</p>
          <p>{{ obj.msgText }}</p>
        </a-timeline-item>
      </a-timeline>
    </a-card>
  </div>
</template>

<script>
import { msgGenerateLogApi } from '@/api/msg'
import { anonProjectApi } from '@/api/project'
import { timeDiff } from '@/utils/util'
export default {
  name: 'ProjectMsgLog',
  data () {
    return {
      project: {},
      logList: []
    }
  },
  methods: {
    timeDiff,
    init (projId) {
      this.getProject(projId)
      this.listProjectLog(projId)
    },
    getProject (projId) {
      if (projId) {
        anonProjectApi.getProject({ id: projId }).then(res => {
          if (res.code === 0) {
            this.project = res.data || { }
            this.markLogList(this.logList)
          }
        })
      }
    },
    listProjectLog (projId) {
      if (projId) {
        anonProjectApi.listProjectMsgLog({ projId: projId }).then(res => {
          if (res.code === 0) {
            var logList = res.data || []
            this.markLogList(logList)
            this.logList = logList
          }
        })
      }
    },
    markLogList (logList) {
      const taskCreateTime = this.project.taskCreateTime
      const len = logList.length
      if (len === 0) {
        return
      }
      let lastTime = logList[0].msgTime
      for (var idx = len - 1; idx >= 0; idx--) {
        var obj = logList[idx]
        obj.nodeWorkTime = this.timeDiff(lastTime, obj.msgTime)
        obj.taskWorkTime = this.timeDiff(obj.msgTime, taskCreateTime)
        lastTime = obj.msgTime
      }
    }
  },
  mounted () {
  },
  created () {
  },
  beforeRouteEnter  (to, from, next) {
    next(vm => {
      var params = vm.$route.query
      if (params && params.projId) {
        vm.init(params.projId)
      }
    })
  }
}
</script>

<style lang="less">

</style>
