import { tranOptionsToDataMap, tranOptionsToMap } from '@/utils/util'

// 标液类型 字典枚举
export const checkSolutionTypeEnum = {
  // 一般试剂
  normal: '0',
  // 标准溶液
  standard: '1',
  // 标准贮备液
  reserve: '2',
  // 标准中间液
  middle: '3',
  // 溶剂
  solvent: '4',
  // 使用液
  use: '5'
}

//  标液类型 选择项
export const checkSolutionTypeOptions = [
  { label: '一般试剂', value: checkSolutionTypeEnum.normal, reagentShow: true, color: 'blue' },
  { label: '溶剂', value: checkSolutionTypeEnum.solvent, reagentShow: true, color: 'red' },
  { label: '标准溶液', value: checkSolutionTypeEnum.standard, reagentShow: true, color: 'green' },
  { label: '标准贮备液', value: checkSolutionTypeEnum.reserve, reagentShow: true, color: 'blue' },
  { label: '标准中间液', value: checkSolutionTypeEnum.middle, reagentShow: true, color: 'orange' },
  { label: '标准使用液', value: checkSolutionTypeEnum.use, reagentShow: false, color: 'green' }
]

export const checkSolutionTypeMap = tranOptionsToMap(checkSolutionTypeOptions)
export const checkSolutionTypeDataMap = tranOptionsToDataMap(checkSolutionTypeOptions)

// 标液配置 复核状态 字典枚举
export const checkStandardSolutionReviewStatusEnum = {
  // 待复核
  pending: '0',
  // 通过
  pass: '1',
  // 驳回
  rejected: '2'
}

//  标液配置 复核状态 选择项
export const checkStandardSolutionReviewStatusOptions = [
  { label: '待复核', value: checkStandardSolutionReviewStatusEnum.pending, color: 'gray' },
  { label: '通过', value: checkStandardSolutionReviewStatusEnum.pass, color: 'green' },
  { label: '驳回', value: checkStandardSolutionReviewStatusEnum.rejected, color: 'red' }
]

export const checkStandardSolutionReviewStatusMap = tranOptionsToMap(checkStandardSolutionReviewStatusOptions)
export const checkStandardSolutionReviewStatusDataMap = tranOptionsToDataMap(checkStandardSolutionReviewStatusOptions)

// 标液配置 审核状态 字典枚举
export const checkStandardSolutionCheckStatusEnum = {
  // 待审核
  pending: '0',
  // 通过
  pass: '2',
  // 驳回
  rejected: '3'
}

//  标液配置 审核状态 选择项
export const checkStandardSolutionCheckStatusOptions = [
  { label: '待审核', value: checkStandardSolutionCheckStatusEnum.pending, color: 'gray' },
  { label: '通过', value: checkStandardSolutionCheckStatusEnum.pass, color: 'green' },
  { label: '驳回', value: checkStandardSolutionCheckStatusEnum.rejected, color: 'red' }
]

export const checkStandardSolutionCheckStatusMap = tranOptionsToMap(checkStandardSolutionCheckStatusOptions)
export const checkStandardSolutionCheckStatusDataMap = tranOptionsToDataMap(checkStandardSolutionCheckStatusOptions)

// 标液使用类型 字典枚举
export const checkStandardSolutionUsageTypeEnum = {
  // 配置曲线
  curve: '0',
  // 标液标定
  demarcate: '1',
  // 标液核定
  verify: '2'
}

//  标液使用类型 选择项
export const checkStandardSolutionUsageTypeOptions = [
  { label: '配置曲线', value: checkStandardSolutionUsageTypeEnum.curve, color: 'blue' },
  { label: '标液标定', value: checkStandardSolutionUsageTypeEnum.demarcate, color: 'green' },
  { label: '标液核定', value: checkStandardSolutionUsageTypeEnum.verify, color: 'red' }
]

export const checkStandardSolutionUsageTypeMap = tranOptionsToMap(checkStandardSolutionUsageTypeOptions)
export const checkStandardSolutionUsageTypeDataMap = tranOptionsToDataMap(checkStandardSolutionUsageTypeOptions)
