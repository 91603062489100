<template>
  <div>
<!--    {{staffInfo}}-->
    <template v-if="staffInfo">
      <a-table
        v-bind="flowTable"
        v-on="flowTable"
        :pagination="false"
        :customRow="onTableclick"
        :dataSource="dataSource">
        <template slot="jobRender" slot-scope="text,record">
          {{ stationFilter(text) }}
        </template>
      </a-table>
    </template>
<!--    <a-modal v-bind="viewModal" v-on="viewModal" v-if="viewModal.visible">-->
<!--      <flow-process-detail :processIdProp="viewModal.processId" :showOperator="false"></flow-process-detail>-->
      <staff-model ref="staffmodel" @ok="$refs.table.refresh(true)"></staff-model>
<!--    </a-modal>-->
  </div>
</template>

<script>
import { resStaffApi, resStaffStationApi, Get } from '@/api/quote'
import StaffModel from '@/pages/cloud-quote/res/res-staff/modules/StaffModel'
export default {

  name: 'SearchStaffView',
  components: { StaffModel },

  props: {
    value: {
      type: Number,
      required: 0
    }
  },

  data () {
    return {
      viewModal: {
        ref: 'viewModal',
        visible: false,
        processId: null,
        title: null,
        width: '100%',
        centered: true,
        ok: () => { this.viewModal.visible = false },
        cancel: () => { this.viewModal.visible = false },
        show: () => { this.viewModal.visible = true },
        cancelText: '关闭',
        wrapClassName: 'ant-modal-cust-warp',
        zIndex: 3000
      },
      flowTable: {
        ref: 'table',
        bordered: true,
        size: 'small',
        rowKey: 'id',
        columns: [
          { title: '姓名', dataIndex: 'name' },
          { title: '性别', dataIndex: 'sex', customRender: v => v === '1' ? '女' : '男' },
          { title: '手机号码', dataIndex: 'phone' },
          { title: '岗位', dataIndex: 'job', scopedSlots: { customRender: 'jobRender' } }
        ]
        // customRow: { (record) => {
        //   return {
        //     on: { // 事件
        //       click: (event) => {},       // 点击行
        //       dblclick: (event) => {}
        //     },
        //   }
        // }}
      // }
      },
      staffInfo: {},
      dataSource: [],
      stationList: []
    }
  },

  methods: {
    openView (item, args = item.split('^')) {
      this.viewModal.processId = args[0]
      this.viewModal.title = `预览： ${args[1]}`
      this.viewModal.show()
    },
    onTableclick (record, index) {
      return {
        on: {
          click: () => {
            setTimeout(() => {
              console.log('22222', this.$refs.staffmodel)
              this.$refs.staffmodel.show(record, this.stationList)
            }, 100)
          }
        }
      }
    },
    getStaffInfo () {
      this.dataSource = []
      const staffId = this.value
      if (staffId) {
        resStaffApi.getOne({ id: staffId }).then(result => {
          if (result.code === 0) {
            this.staffInfo = result.data
            this.dataSource.push(this.staffInfo)
          }
        })
      } else {
        this.staffInfo = {}
      }
    },
    stationFilter (job) {
      if (job === null) return '-'
      const station = this.stationList.filter(it => it.id === job)
      if (station !== null && station.length > 0) return station[0]['stationName']; else return '-'
    },
    initStationList () {
      Get(resStaffStationApi.listAll, { }).then((res) => {
        if (res.code === 0) {
          this.stationList = res.data
        } else {
          console.log(res.message)
        }
      })
    }
  },
  created () {
    this.initStationList()
  },
  watch: {
    value: {
      immediate: true,
      handler (nv, ov) {
        this.getStaffInfo()
      }
    }
  }
}
</script>

<style scoped>

</style>
