<template>
  <div>
    <ProjView ref="projView" :data="projInfo"></ProjView>
    <a-card style="margin-top: 24px" :bordered="false" title="流程进度">
      <template slot="extra">
        <a-button @click="projectMsgLogModal.open">详情</a-button>
      </template>
      <a-steps size="small" :direction="isMobile() && 'vertical' || 'horizontal'" :current="currentProgress" status="process" labelPlacement="vertical">
        <template v-for="vo in this.progressList">
          <a-step
            :key="vo.itemValue"
            :title="vo.itemText">

            <a slot="icon" @click="handleStepClick(vo)">
              <a-icon :type="vo.itemValue | progressIcon" />
            </a>

            <span slot="description">
              <div style="float: left">
                <a-icon type="user" :style="{color:'#1890ff',marginLeft:'10px'}"/>
                {{ getProjLeader(vo.itemValue) }}<br/>
                {{ getProjTime(vo.itemValue) }}
              </div>
            </span>
          </a-step>
        </template>

        <!--        <a-step title="报价">-->
        <!--          <a-icon type="calculator" slot="icon"/>-->
        <!--          <span slot="description"><div style="float: left">报价人：admin<br>2019-06-04 18:37:40</div></span>-->
        <!--        </a-step>-->
        <!--        <a-step title="立项">-->
        <!--          <a-icon type="file-text" slot="icon"/>-->
        <!--          <span slot="description">立项人：admin<br>2019-06-04 18:37:40</span>-->
        <!--        </a-step>-->
        <!--        <a-step title="下单">-->
        <!--          <a-icon type="form" slot="icon" @click="addTask"/>-->
        <!--          <template slot="description">-->
        <!--            <div style="text-align: left">-->
        <!--              立项人：admin<br>2019-06-04 18:37:40-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </a-step>-->
        <!--        <a-step title="检测">-->
        <!--          <a-icon type="security-scan" slot="icon"/>-->
        <!--        </a-step>-->
        <!--        <a-step title="报告">-->
        <!--          <a-icon type="snippets" slot="icon"/>-->
        <!--        </a-step>-->
        <!--        <a-step title="回款">-->
        <!--          <a-icon type="property-safety" slot="icon"/>-->
        <!--        </a-step>-->
        <!--        <a-step title="结束">-->
        <!--          <a-icon type="check-circle" slot="icon"/>-->
        <!--        </a-step>-->
      </a-steps>
    </a-card>

    <!-- 报价 -->
    <template v-if="projProgress==='quote'" >
      <div style="margin-top: 24px;">
        <PVQuoteView :id="projInfo.quoteId"/>
      </div>
<!--      <TaskForm ref="taskForm" ></TaskForm>-->
<!--      <CsrView :id="projInfo.customer"></CsrView>-->
    </template>

    <!-- 立项 -->
    <template v-else-if="projProgress==='project'" >
      <div style="margin-top: 24px;">
        <PVProjectView :id="projInfo.id"/>
      </div>
    </template>

    <!-- 下单 -->
    <template v-else-if="projProgress==='task'" >
      <div style="margin-top: 24px;">
        <PVTaskView :projId="projInfo.id" :projInfo="projInfo"/>
      </div>
    </template>

    <!-- 采样 -->
    <template v-else-if="projProgress==='sampling'" >
      <SampleProjView :proj-id="projectId" :style="{marginTop:'24px'}"></SampleProjView>
    </template>

    <!-- 检测 -->
    <template v-else-if="projProgress==='detection'" >
      <CheckProjView :proj-id="projectId" :style="{marginTop:'24px'}"></CheckProjView>
    </template>

    <!-- 报告 -->
    <template v-else-if="projProgress==='report'" >
      <report-proj-view :proj-id="projectId" :style="{marginTop:'24px'}"/>
    </template>

    <!-- 收款 -->
    <template v-else-if="projProgress==='receipt'" >
      <div style="margin-top: 24px;">
        <PVReceiptView :projId="projInfo.id" :projInfo="projInfo"/>
      </div>
    </template>

    <!-- 完结 -->
<!--    <template v-else-if="projProgress==='end'" >-->
<!--      <a-card :bordered="false" title="完结">-->
<!--        <div class="no-data"><a-icon type="frown-o"/>开发中，敬请期待</div>-->
<!--      </a-card>-->
<!--    </template>-->

<!--    <a-card style="margin-top: 24px" :bordered="false" title="日志记录">-->
<!--      <div class="no-data"><a-icon type="frown-o"/>暂无数据</div>-->
<!--    </a-card>-->

    <!-- 配置信息表单 -->
    <a-modal v-bind="projectMsgLogModal" v-on="projectMsgLogModal">
      <ProjectMsgLog :ref="projectMsgLogModal.bodyRef" @msg="projectMsgLogModal.bodyMsg"/>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="projectMsgLogModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { PageView } from '@/layouts'
import DetailList from '@/components/tools/DetailList'
import ProjView from '@/pages/cloud-quote/project/View'
import CsrView from '@/pages/cloud-quote/customer/View'
import TaskForm from '@/pages/cloud-quote/proj-task/TaskFormModal'
import { projectApi, projPlanApi } from '@/api/project'
import { commDictApi } from '@/api/common'
import PVQuoteView from '@/pages/cloud-quote/proj-worktable/modules/PVQuoteView'
import PVProjectView from '@/pages/cloud-quote/proj-worktable/modules/PVProjectView'
import PVTaskView from '@/pages/cloud-quote/proj-worktable/modules/PVTaskView'
import PVReceiptView from '@/pages/cloud-quote/proj-worktable/modules/PVReceiptView'
import SampleProjView from '@/pages/cloud-quote/take-sample/modules/projectView'
import CheckProjView from '@/pages/cloud-quote/check-task/modules/projectView'
import ReportProjView from '@/pages/cloud-quote/report/task/moudles/projectView'
import ProjectMsgLog from '@/pages/cloud-quote/project/modules/ProjectMsgLog'
import fullModal from '@/pages/common/mixins/modal/full'
const fullModalData = fullModal.data().fullModal

const DetailListItem = DetailList.Item

export default {
  name: 'ProjDetailsMgr',

  components: {
    PageView,
    DetailList,
    DetailListItem,
    ProjView,
    PVQuoteView,
    PVProjectView,
    PVTaskView,
    PVReceiptView,
    CsrView,
    TaskForm,
    SampleProjView,
    CheckProjView,
    ReportProjView,
    ProjectMsgLog
  },

  mixins: [mixinDevice],

  props: {
    projId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      projInfo: {},

      // 显示的项目进程，默认是项目的当前进程
      projProgress: null,

      // 承接项目ID
      projectId: this.projId,

      progressList: [],

      projPlanList: [],

      projPlanMap: {},
      projectMsgLogModal: Object.assign({}, fullModalData, {
        ref: 'projectMsgLogModal',
        bodyRef: 'projectMsgLogModalBody',
        title: '项目进度日志',
        visible: false,
        width: '860px',
        centered: true,
        maskClosable: false,
        destroyOnClose: true,
        open: () => {
          this.projectMsgLogModal.visible = true
          this.$nextTick(() => {
            this.$refs[this.projectMsgLogModal.bodyRef].init(this.projectId)
          })
        },
        ok: () => {
          this.$nextTick(() => {
            this.$refs[this.projectMsgLogModal.bodyRef].save()
          })
        },
        cancel: () => {
          this.projectMsgLogModal.visible = false
        },
        bodyMsg: (msg) => {
          if (msg === 'ok') {
            this.search()
            this.projectMsgLogModal.cancel()
          } else if (msg === 'fail') {
            this.projectMsgLogModal.confirmLoading = false
          }
        }
      })
    }
  },

  computed: {
    currentProgress () {
      const index = this.progressList.findIndex(item => item.itemValue === this.projInfo.projProgress)
      return index > -1 ? index : 0
    }
  },

  filters: {
    statusFilter (status) {
      const statusMap = {
        'agree': '成功',
        'reject': '驳回'
      }
      return statusMap[status]
    },
    statusTypeFilter (type) {
      const statusTypeMap = {
        'agree': 'success',
        'reject': 'error'
      }
      return statusTypeMap[type]
    },
    progressIcon (code) {
      const iconMap = {
        'quote': 'calculator',
        'project': 'file-text',
        'task': 'form',
        'sampling': 'gold',
        'detection': 'experiment',
        'report': 'snippets',
        'receipt': 'property-safety',
        'end': 'check-circle'
      }
      return iconMap[code]
    }
  },

  watch: {
    projectId: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.getProject()
          this.getProjPlan()
        }
      }
    },

    projPlanList (val) {
      this.projPlanMap = val.length && val.reduce((init, val) => {
        return (init[val.projProgress] = val) && init
      }, {}) || {}
    }
  },

  mounted () {
    this.listProjProgress()
  },

  methods: {
    getProject () {
      if (this.projectId) {
        projectApi.get({ id: this.projectId }).then(result => {
          if (result.code === 0) {
            console.log('result.data=>', result.data)
            this.projInfo = result.data
            this.projProgress = result.data.projProgress
          }
        })
      }
    },
    listProjProgress () {
      commDictApi.list({ dictCode: 'proj_progress' }).then(result => {
        if (result.code === 0) {
          this.progressList = result.data
        }
      })
    },
    curProgressXh () {
    },
    addTask () {
      this.$refs.taskForm.add(this.projInfo.id, this.projInfo.quoteId)
    },

    getProjPlan () {
      projPlanApi.getByProjId({ projId: this.projectId }).then(res => {
        if (res.code === 0) {
          this.projPlanList.splice(0, this.projPlanList.length, ...res.data)
        } else {
          this.$message.error(`获取项目计划出错，错误信息${res.msg}`)
        }
      })
    },

    getProjLeader (projProgress) {
      return this.projPlanMap[projProgress] &&
        (this.projPlanMap[projProgress].projLeaderName || this.projPlanMap[projProgress].projLeader) ||
        '暂未配置'
    },

    getProjTime (projProgress) {
      return this.projPlanMap[projProgress] &&
        (this.projPlanMap[projProgress].projActualEnd || this.projPlanMap[projProgress].projActualStart ||
          this.projPlanMap[projProgress].projScheduledStart || this.projPlanMap[projProgress].projScheduledEnd) ||
        '--'
    },

    handleStepClick (item) {
      this.projProgress = item.itemValue
    }
  },

  beforeRouteEnter  (to, from, next) {
    next(vm => {
      if (vm.$route.params && vm.$route.params.id) {
        vm.projectId = parseInt(vm.$route.params.id)
      }

      if (vm.$route.query && vm.$route.query.id) {
        vm.projectId = parseInt(vm.$route.query.id) || null
      }
    })
  }
}
</script>

<style lang="less" scoped>

  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .no-data {
    color: rgba(0, 0, 0, .25);
    text-align: center;
    line-height: 64px;
    font-size: 16px;

    i {
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: 3px;
    }
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .text {

    }
    .status-list {
      text-align: left;
    }
  }
</style>
