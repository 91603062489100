<template>
  <a-modal id="review-form" v-bind="fullModal">
    <div class="utabs" style="height: 100%">
      <template v-if="atlasList.length">
        <a-tabs v-model="curIdx" size="small" style="height: 100%; padding: 0; margin: 0; overflow: hidden" tabPosition="left" type="line">
          <a-tab-pane :closable="false" :key="idx" :tab="obj.atlasName" style="height: 100%" v-for="(obj, idx) of atlasList">
            <template v-if="obj.atlasFileUrl">
              <a-row style="height: 100%">
                <iframe :src="formSrc" style="width: 100%; height: 100%; border: none"></iframe>
              </a-row>
            </template>
          </a-tab-pane>
        </a-tabs>
      </template>
      <template v-else>
        <div style="padding-top: 20px; text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.45)">暂无数据</div>
      </template>
    </div>
    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { sysCommonApi } from '@/api/common'
import { checkTaskBatchApi, checkTaskApi } from '@/api/check/checkTaskApi'
import fullModal from '@/pages/common/mixins/modal/full'

export default {
  name: 'AtlasPreview',
  inheritAttrs: false,
  mixins: [fullModal],

  data() {
    return {
      fullModal: {
        title: '图谱预览',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.curIdx = 0
        },
        show: () => {
          this.fullModal.visible = true
          console.log('show')
        }
      },
      atlasList: [],
      curIdx: 0
    }
  },
  computed: {
    formSrc() {
      if (this.atlasList && this.atlasList.length > 0) {
        const template = this.atlasList[this.curIdx]
        if (template.atlasFileUrl) {
          return `${sysCommonApi.viewFile}/${template.atlasFileUrl}`
        }
      }
      return null
    }
  },
  methods: {
    init(batchId) {
      checkTaskBatchApi.getDetailInfoById(batchId).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            this.atlasList = res.data.checkTaskBatchAtlas || []
            let haveUrlIndex = this.atlasList.findIndex((item) => item.atlasFileUrl)
            if (haveUrlIndex !== -1) {
              this.curIdx = haveUrlIndex
            }
            this.fullModal.show()
          } else {
            message.error(`获取批次信息出错！`)
          }
        } else {
          message.error(res.msg)
        }
      })
    },
    downLoadFile(file) {
      window.location = sysCommonApi.downLoad + '?filePath=' + file.atlasFileUrl + '&fileName=' + file.atlasName + '.pdf&token=' + this.$store.getters.token
    }
  }
}
</script>
<style lang="less" scoped>
iframe {
  table {
    table-layout: auto;
  }
}
.utabs {
  .archive {
    margin: 0px 5px;
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 8px;
    border-radius: 4px;
  }

  /deep/ .ant-tabs-content {
    height: 100%;
  }
}
</style>