<template>
  <a-modal v-bind="siteModal" v-on="siteModal">
    <a-form :form="form">
      <a-form-item label="检测位置" v-bind="formItemLayout">
        <a-input
          type="text"
          placeholder="检测位置"
          v-decorator="['siteName', {initialValue: info.siteName,
                                     rules: [
                                       {required: true, message: '检测位置不能为空！'},
                                       {max: 100, message: '检测位置长度过长！'}
                                     ]}]"></a-input>
      </a-form-item>
      <a-form-item label="经纬度" v-bind="formItemLayout">
        <a-input-group>
          <a-col :span="11">
            <a-form-item>
              <a-input
                type="text"
                placeholder="经度"
                v-decorator="['siteLng', {initialValue: info.siteLng,
                                          rules: [
                                            {max: 18, message: '长度不能超过18位！' }
                                          ]}]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="11">
            <a-form-item>
              <a-input
                type="text"
                placeholder="纬度"
                v-decorator="['siteLat', {initialValue: info.siteLat,
                                          rules: [
                                            {max: 18, message: '长度不能超过18位！' }
                                          ]}]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-icon style="cursor: pointer;" type="environment" theme="twoTone" @click="mapLocation"/>
          </a-col>
        </a-input-group>
      </a-form-item>
      <a-form-item label="备注" v-bind="formItemLayout">
        <a-textarea
          :rows="4"
          placeholder="备注"
          v-decorator="['siteRemark', {initialValue: info.siteRemark,
                                     rules: [
                                       {max: 500, message: '备注长度过长！'}
                                     ]}]"></a-textarea>
      </a-form-item>
    </a-form>
    <MapLocation ref="mapLocation" :addr="addr" @ok="updateAddr"></MapLocation>
  </a-modal>
</template>
<script>
import { projCheckSiteApi } from '@/api/project'
import MapLocation from '@/pages/common/map/MapLocation'

var vm = {}
export default {
  name: 'SiteForm',
  components: { MapLocation },
  props: {
    siteList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    vm = this
    return {
      type: 'add',
      title: '添加检测位置',
      siteModal: {
        title: '添加检测位置',
        visible: false,
        maskClosable: false,
        destroyOnClose: true,
        centered: true,
        cancel: this.handleCancel,
        ok: this.handleOK
      },
      visible: false,
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      info: {},
      projId: null,
      form: this.$form.createForm(this),
      selectSiteStatus: 'success',
      addr: {
        status: '',
        tip: '',
        adCode: '',
        address: '',
        adFullName: '',
        wholeAddress: '',
        lng: '',
        lat: ''
      }
    }
  },
  methods: {
    add (projId) {
      this.type = 'add'
      this.siteModal.title = '添加检测位置'
      this.siteModal.visible = true
    },
    edit (site) {
      this.info = site
      this.initAddr()
      this.type = 'edit'
      this.siteModal.title = '修改检测位置'
      this.siteModal.visible = true
    },
    editName (site) {
      this.info = site
      this.initAddr()
      this.type = 'editName'
      this.siteModal.title = '修改检测位置'
      this.siteModal.visible = true
    },
    handleOK (e) { // 提交表单
      e.preventDefault()
      const self = this
      var saveApi = projCheckSiteApi.add
      if (self.type === 'add') {
        saveApi = projCheckSiteApi.add
      } else {
        saveApi = projCheckSiteApi.edit
      }
      this.form.validateFields((error, values) => {
        const formData = Object.assign(values, {
          id: self.info.id,
          cateIdx: self.info.cateIdx,
          siteIdx: self.info.siteIdx
        })
        if (self.type === 'editName') {
          self.updateSite(formData)
          self.handleCancel()
        } else {
          if (error == null) {
            saveApi(formData).then(result => {
              if (result.code === 0) {
                self.$message.success(`${self.title}成功`)
                self.updateSite(formData)
                self.handleCancel()
              } else {
                self.$message.error(result.msg)
              }
            }).catch(error => {
              self.$message.error(`服务器发生错误！错误消息为${error}`)
            })
          }
        }
      })
    },
    mapLocation () {
      this.$refs.mapLocation.open()
    },
    initAddr () {
      this.$set(this.addr, 'lng', this.info.siteLng)
      this.$set(this.addr, 'lat', this.info.siteLat)
    },
    updateAddr (info) {
      this.$set(this.addr, 'lng', String(info.lng || ''))
      this.$set(this.addr, 'lat', String(info.lat || ''))
      this.$set(this.info, 'siteLng', String(info.lng || ''))
      this.$set(this.info, 'siteLat', String(info.lat || ''))
    },
    handleCancel () {
      this.siteModal.visible = false
    },
    updateSite (site) {
      this.$emit('updateSiteInfo', site)
    }
  },
  watch: {
  }
}
</script>
