<!--
使用该组件时传入参数请使用".sync"进行修饰以便于数据更新，例如：：
<AddressInput :addr.sync="addr" ></AddressInput>
ss
-->
<template>
  <div>
    <a-input-group>
      <a-row>
        <a-col span="8">
          <a-cascader
            style="width: 100%"
            placeholder=""
            allowClear
            v-model="adCodeValue"
            :options="adCodeList"
            @change="adCodeOnChange"
            :loadData="adCodeLoadData"
            :fieldNames="{label: 'adName', value: 'adCode', children: 'children'}">
            <template slot="displayRender" slot-scope="labels, selectedOptions">
              <span :title="adFullName">{{ adFullName_ }}</span>
            </template>
          </a-cascader>
        </a-col>
        <a-col span="16">
          <a-input placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息" v-model="addr.address">
            <template slot="suffix">
              <a-tooltip>
                <!--                <a-tooltip :title="addr.lng?`${addr.adFullName}${addr.address}\n经纬度:${addr.lng},${addr.lat}`:'未选取经纬度'">-->
                <template slot="title">
                  <span>{{ addr.adFullName }}{{ addr.address }}</span><br/>
                  <span v-if="addr.lng">
                    经纬度:{{ addr.lng }},{{ addr.lat }}
                  </span>
                  <span v-else>
                    未选取经纬度
                  </span>
                </template>
                <a-icon type="environment" :theme="addr.lng?'twoTone':''" @click="mapLocation"/>
              </a-tooltip>
            </template>
          </a-input>
        </a-col>
      </a-row>
    </a-input-group>
    <MapLocation ref="mapLocation" :addr="addr" :windowShow="true" @ok="updateAddr"></MapLocation>
  </div>
</template>

<script>

import { adCodeApi } from '@/api/common'
import MapLocation from './MapLocation'

var vm = {}

export default {
  name: 'AddressInput',
  components: { MapLocation },
  props: {
    addr: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    vm = this
    window.vm = vm
    return {
      title: '行政区划',
      showTooltip: false,
      adFullName: '',
      adFullName_: '',
      //  区划完整名称Map
      adFullMap: {},
      //  区划完整名称Map 带'/'名称
      adFull_Map: {},
      adCodeValue: [],
      adCodeList: [],
      localAddr: {
        status: '',
        tip: '',
        adCode: '',
        address: '',
        adFullName: '',
        wholeAddress: '',
        lng: '',
        lat: ''
      }
    }
  },
  created () {
    this.listAdCode('0')
  },
  mounted () {
    if (this.addr.adCode) {
      this.initAdCode(this.addr.adCode)
    }
  },
  methods: {
    listAdCode (adPcode, adCodeObj) {
      adCodeApi.list({ adPcode: adPcode }).then(result => {
        if (result.code === 0) {
          var tempAdCodeList = result.data
          if (tempAdCodeList) {
            for (var i = 0; i < tempAdCodeList.length; i++) {
              if (tempAdCodeList[i].adType < '3') {
                tempAdCodeList[i].isLeaf = false
              }
            }
          }
          if (adCodeObj) {
            adCodeObj.children = tempAdCodeList || []
          } else {
            this.adCodeList = tempAdCodeList || []
          }
        }
        if (adCodeObj) {
          adCodeObj.loading = false
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    },
    adCodeLoadData (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      this.listAdCode(targetOption['adCode'], targetOption)
    },
    adCodeOnChange (value, selectedOptions) {
      if (value.length > 0) {
        var obj = selectedOptions[selectedOptions.length - 1]
        var adCode = obj.adCode
        var adFullName = obj.adFullName
        this.$set(this.addr, 'adCode', adCode)
        this.$set(this.addr, 'adFullName', adFullName)
        var adFullName_ = ''
        selectedOptions.forEach((obj, idx) => {
          if (idx === 0) {
            adFullName_ = `${obj.adName}`
          } else {
            adFullName_ = adFullName_ + ` / ${obj.adName}`
          }
        })
        this.adFullName_ = adFullName_
        this.adFullName = adFullName
        this.adFull_Map[adCode] = adFullName_
        this.adFullMap[adCode] = adFullName
      } else {
        this.$set(this.addr, 'adCode', '')
        this.$set(this.addr, 'adFullName', '')
        this.adFullName_ = ''
        this.adFullName = ''
      }
    },
    updateAddr (info) {
      this.$set(this.addr, 'adCode', info.adcode)
      this.$set(this.addr, 'address', info.address)
      this.$set(this.addr, 'lng', info.lng)
      this.$set(this.addr, 'lat', info.lat)
      this.initAdCode(info.adcode)
    },
    initAdCode (adCode) {
      var self = this
      self.adFullName_ = ''
      if (self.adFull_Map[adCode]) {
        self.adFullName_ = self.adFull_Map[adCode]
        self.adFullName = self.adFullMap[adCode]
        this.$set(this.addr, 'adFullName', self.adFullName)
      } else {
        adCodeApi.list({ adCode: adCode }).then(result => {
          if (result.code === 0) {
            var adFullName_ = ''
            var adFullName = ''
            var tempAdCodeList = result.data
            if (tempAdCodeList) {
              var obj = tempAdCodeList[0]
              adFullName_ = `${obj.adProvince}`
              adFullName = obj.adFullName
              if (obj.adType === '2') {
                adFullName_ = `${obj.adProvince} / ${obj.adCity}`
              } else if (obj.adType === '3') {
                adFullName_ = `${obj.adProvince} / ${obj.adCity} / ${obj.adName}`
              }
            }
            self.adFullName_ = adFullName_
            self.adFullName = adFullName
            this.$set(this.addr, 'adFullName', adFullName)
            self.adFull_Map[adCode] = adFullName_
            self.adFullMap[adCode] = adFullName
          }
        })
      }
    },
    mapLocation () {
      this.$refs.mapLocation.open()
    }
  },
  watch: {
    addr: {
      handler (nv, ov) {
        if (this.addr.adCode) {
          this.initAdCode(this.addr.adCode)
        }
        var adFullName = nv.adFullName || ''
        var address = nv.address || ''
        var wholeAddress = `${adFullName}${address}`
        this.$set(this.addr, 'wholeAddress', wholeAddress)
        if (!nv.adCode || !nv.address) {
          this.$set(this.addr, 'status', 'error')
          this.$set(this.addr, 'tip', '请添加地址信息')
        } else if (!nv.lng || !nv.lat) {
          this.$set(this.addr, 'status', 'warning')
          this.$set(this.addr, 'tip', '未选择经纬度')
        } else {
          this.$set(this.addr, 'status', 'success')
          this.$set(this.addr, 'tip', '')
        }
        this.$emit('update:addr', nv)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
