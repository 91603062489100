'use strict'

import Vue from 'vue'
import form from './form'
import table from './table'
import cateSelectTable from './table/CateSelectTable'
import cateSelectAndQueryTable from './table/CateSelectAndQueryTable'
import materialSelect from './materialSelect'
import materialTable from './material/table'

// 此三个组件存在循环引用 此处注册全局组件
Vue.component('check-reagent-cate-material-select', materialSelect)
Vue.component('check-reagent-cate-form', form)
Vue.component('check-reagent-cate-select-and-query-table', cateSelectAndQueryTable)

export {
  table as CheckReagentCateTable,
  cateSelectTable as CheckReagentCateSelectTable,
  materialTable as CheckReagentCateMaterialTable
}
