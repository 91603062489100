'use strict'

/**
 * 标液配置 相关API
 */

import { getAction, postDataAction } from '@/api/common'

const checkStandardSolutionPre = '/ms-api/check-standard-solution'
const checkStandardSolutionTypePre = '/ms-api/check-standard-solution-type'

export const checkStandardSolutionApi = {
  list: (param) => getAction(`${checkStandardSolutionPre}/list`, param),
  save: (data) => postDataAction(`${checkStandardSolutionPre}/save`, JSON.stringify(data)),
  assign: (data) => postDataAction(`${checkStandardSolutionPre}/assign`, JSON.stringify(data)),
  review: (data) => postDataAction(`${checkStandardSolutionPre}/review`, data),
  check: (data) => postDataAction(`${checkStandardSolutionPre}/check`, data),
  autoCheck: (data) => postDataAction(`${checkStandardSolutionPre}/autoCheck`, data),
  claim: (data) => postDataAction(`${checkStandardSolutionPre}/claim`, data),
  delete: (id) => postDataAction(`${checkStandardSolutionPre}/delete/${id}`)
}

export const checkStandardSolutionTypeApi = {
  list: (param) => getAction(`${checkStandardSolutionTypePre}/list`, param),
  save: (data) => postDataAction(`${checkStandardSolutionTypePre}/save`, JSON.stringify(data)),
  delete: (id) => postDataAction(`${checkStandardSolutionTypePre}/delete/${id}`),
  findDetailById: (id) => getAction(`${checkStandardSolutionTypePre}/detail/${id}`)
}
