<template>
  <div class="check-standard-solution-form">
    <a-form v-bind="dataForm">
      <a-card v-bind="baseFormCard" title="制配信息">
        <div class="title-row">
          <span class="title">基本信息</span>
        </div>
        <div class="content-row focus">
          <ul class="ul">
            <li class="li average">
              <a-form-item v-bind="formItemLayout" label="名称">
                <a-input v-decorator="decorators.name"></a-input>
              </a-form-item>
              <a-form-item v-bind="formItemLayout" label="不确定度">
                <a-input-group compact style="width: 180px">
                  <a-input-number v-decorator="decorators.uncertainty" style="width: 50%"></a-input-number>
                  <a-select v-decorator="decorators.uncertaintyUnit" placeholder="请选择不确定度单位" style="width: 50%">
                    <a-select-option v-for="v in uncertaintyUnitList" :key="v" :value="v">{{ v }}</a-select-option>
                  </a-select>
                </a-input-group>
              </a-form-item>
              <!-- <a-form-item v-bind="formItemLayout" label="不确定度">
                <a-input-group compact style="width:180px;">
                  <a-input-number v-model="uncertainty" style="width: 50%"></a-input-number>
                  <a-select v-model="uncertaintyUnit" placeholder="请选择不确定度单位" style="width: 50%">
                    <a-select-option v-for="v in uncertaintyUnitList" :key="v" :value="v">{{ v }}</a-select-option>
                  </a-select>
                </a-input-group>
              </a-form-item> -->
              <a-form-item v-bind="formItemLayout" label="制配时间">
                <a-date-picker
                  v-bind="moreCurrentDatePickerProps"
                  format="YYYY-MM-DD HH:mm"
                  v-decorator="decorators.makeTime"
                  :style="{ width: '180px' }"
                  @change="setValidTime"
                  @ok="selectWdSd"
                ></a-date-picker>
              </a-form-item>

              <a-form-item v-bind="formItemLayout" label="有效期止">
                <a-date-picker v-bind="defaultDatePickerProps" format="YYYY-MM-DD HH:mm" v-decorator="decorators.validTime" :disabled="true"></a-date-picker>
              </a-form-item>
            </li>
          </ul>
        </div>

        <div class="title-row">
          <span class="title">配置环境</span>
        </div>
        <div class="content-row focus">
          <ul class="ul">
            <li class="li average" style="">
              <a-form-item v-bind="formItemLayout" label="房间">
                <a-tree-select
                  v-model="locationId"
                  show-search
                  tree-node-filter-prop="title"
                  :replaceFields="replaceFields"
                  style="width: 150px"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择房间"
                  allow-clear
                  tree-default-expand-all
                  :tree-data="treeData"
                  @change="changeLocation"
                >
                </a-tree-select>
              </a-form-item>
              <a-form-item v-bind="formItemLayout" label="温度">
                <a-input-number :min="-273" v-decorator="decorators.wd"></a-input-number>
                ℃
              </a-form-item>
              <a-form-item v-bind="formItemLayout" label="湿度">
                <a-input-number :min="0" v-decorator="decorators.sd"></a-input-number>
                %RH
              </a-form-item>
              <div style="flex: 1"></div>
            </li>
          </ul>
        </div>

        <div class="title-row">
          <span class="title">使用试剂</span>
          <span class="title fr">
            总计采样量：
            <span class="focus-num" :class="[solutionSumVolume === solutionTypeInfo.volume ? 'green' : 'red']">{{ solutionSumVolume }}</span>
            / <span class="green focus-num">{{ solutionTypeInfo.volume }}</span>
            {{ solutionTypeInfo.parentReagentCateUnit || 'mL' }}
            <a
              :class="[dataForm.data.solventList.length ? 'green' : 'red']"
              :style="{ marginLeft: '10px' }"
              @click="checkReagentSelectModal.show(solutionTypeInfo.parentReagentCateId, 'solution')"
              >选择溶液</a
            >
          </span>
        </div>
        <div class="content-row focus">
          <ul class="ul">
            <template v-if="dataForm.data.solutionList.length">
              <li class="li average" v-for="(item, index) in dataForm.data.solutionList" :key="`solution_${item.id}`">
                <div>
                  <span class="title"> 溶液： </span>
                  <span class="content">
                    {{ item.name }}
                  </span>
                </div>
                <div>
                  <span class="title"> 可用量： </span>
                  <span class="content">
                    {{ item.volume }}
                    {{ solutionTypeInfo.parentReagentCateUnit || 'mL' }}
                  </span>
                </div>
                <div>
                  <span class="title"> 取样量： </span>
                  <span class="content">
                    <j-input-number
                      v-model="item.useVolume"
                      :step="1"
                      :min="0"
                      :max="item.volume < solutionTypeInfo.volume ? item.volume : solutionTypeInfo.volume"
                    ></j-input-number>
                    {{ solutionTypeInfo.parentReagentCateUnit || 'mL' }}
                  </span>
                </div>
                <a @click="() => dataForm.data.solutionList.splice(index, 1)">移除</a>
              </li>
            </template>
            <template v-else>
              <div class="prompt w-full">
                <span v-if="isEmptySolution" class="empty-data" style="width: 100%; display: inline-block">暂无可用的溶液，请先配置</span>
                <span v-else class="no-data" style="width: 100%; display: inline-block">请选择实际使用的溶液</span>
              </div>
            </template>
          </ul>
        </div>
        <div v-if="isLessThanSolutionVolume" class="empty-data">溶液可用量少于需取样量，请重新选择溶液或配置更多溶液</div>

        <div class="title-row" v-if="solutionTypeInfo.solventId">
          <span class="title">使用稀释液</span>
          <span class="title fr">
            总计定容体积：<span class="focus-num" :class="[solventSumVolume === solutionTypeInfo.constantVolume ? 'green' : 'red']">{{ solventSumVolume }}</span> /
            <span class="focus-num">{{ solutionTypeInfo.constantVolume }}</span>
            mL
            <a
              :class="[dataForm.data.solventList.length ? 'green' : 'red']"
              :style="{ marginLeft: '10px' }"
              @click="checkReagentSelectModal.show(solutionTypeInfo.solventId, 'solvent')"
              >选择稀释液</a
            >
          </span>
        </div>
        <div class="content-row focus" v-if="solutionTypeInfo.solventId">
          <ul class="ul">
            <template v-if="dataForm.data.solventList.length">
              <li class="li average" v-for="(item, index) in dataForm.data.solventList" :key="`solvent_${item.id}`">
                <div>
                  <span class="title"> 稀释液： </span>
                  <span class="content">
                    {{ item.name }}
                  </span>
                </div>
                <div>
                  <span class="title"> 可用量： </span>
                  <span class="content">
                    {{ item.volume }}
                    mL
                  </span>
                </div>
                <div>
                  <span class="title"> 使用量： </span>
                  <span class="content">
                    <j-input-number
                      v-model="item.useVolume"
                      :step="1"
                      :min="0"
                      :max="item.volume < solutionTypeInfo.constantVolume ? item.volume : solutionTypeInfo.constantVolume"
                    ></j-input-number>
                    mL
                  </span>
                </div>
                <a @click="() => dataForm.data.solventList.splice(index, 1)">移除</a>
              </li>
            </template>
            <template v-else>
              <div class="w-full prompt">
                <span v-if="isEmptySolvent" class="empty-data" style="width: 100%; display: inline-block">暂无可用的稀释液，请先配置</span>
                <span v-else class="no-data" style="width: 100%; display: inline-block">请选择实际使用的稀释液</span>
              </div>
            </template>
          </ul>
        </div>
        <div v-if="isLessThanSolventVolume" class="empty-data">稀释液可用量少于需使用量，请重新选择稀释液或配置更多稀释液</div>
      </a-card>

      <a-card v-bind="baseFormCard" title="制配过程">
        <a-row :gutter="24" class="title-row focus" :style="{ marginTop: 0 }">
          <a-col :span="24">
            <span class="title">{{ solutionTypeInfo.name }}</span>
            <span class="fr">
              <a-tag :color="checkSolutionTypeDataMap[checkSolutionTypeEnum.use].color">
                {{ checkSolutionTypeDataMap[checkSolutionTypeEnum.use].label }}
              </a-tag>
            </span>
          </a-col>
        </a-row>
        <div class="content-row">
          <ul class="ul">
            <li class="li average">
              <div>
                <span class="title"> 上级液： </span>
                <span class="content">
                  {{ solutionTypeInfo.parentReagentCateName }}
                </span>
              </div>

              <div>
                <span class="title"> 取样量： </span>
                <span class="content">
                  <a-tooltip>
                    <template #title>
                      此处取样量表示：
                      <span class="red">配置过程中使用多少上级液</span>
                    </template>
                    {{ solutionTypeInfo.volume }}
                    {{ solutionTypeInfo.parentReagentCateUnit || 'mL' }}
                  </a-tooltip>
                </span>
              </div>

              <div>
                <span class="title"> 定容体积： </span>
                <span class="content">
                  {{ solutionTypeInfo.constantVolume }}
                  mL
                </span>
              </div>

              <div>
                <span class="title"> 稀释液： </span>
                <span class="content">
                  {{ solutionTypeInfo.solventName }}
                </span>
              </div>

              <div>
                <span class="title"> 存储条件： </span>
                <span class="content">
                  {{ solutionTypeInfo.storageCondition }}
                </span>
              </div>
            </li>
          </ul>
        </div>

        <template v-for="(item, index) in solutionTypeInfo.detailList">
          <a-row :gutter="24" class="title-row" :style="{ marginTop: '32px' }" :key="`detail_title_${index}`">
            <a-col :span="24">
              <span class="title">
                {{ item.reagentCateName }}
                <span class="fr">
                  <a-tag :color="checkSolutionTypeDataMap[item.solutionType].color">
                    {{ checkSolutionTypeDataMap[item.solutionType].label }}
                  </a-tag>
                </span>
              </span>
            </a-col>
          </a-row>

          <div class="content-row" :key="`detail_content_${index}`">
            <ul class="ul">
              <li class="li average">
                <div v-if="item.parentReagentCateId">
                  <span class="title">
                    <template v-if="item.parentType !== reagentCateMaterialTypeEnum.cate"> 原料： </template>
                    <template v-else> 上级液： </template>
                  </span>
                  <span class="content">
                    {{ item.parentReagentCateName }}
                  </span>
                </div>

                <div>
                  <span class="title"> 取样量： </span>
                  <span class="content">
                    {{ item.volume }}
                    {{ item.parentReagentCateUnit || 'mL' }}
                  </span>
                </div>

                <div>
                  <span class="title"> 定容体积： </span>
                  <span class="content">
                    {{ item.constantVolume }}
                    {{ item.parentReagentCateUnit || 'mL' }}
                  </span>
                </div>

                <div>
                  <span class="title"> 稀释液： </span>
                  <span class="content">
                    {{ item.solventName }}
                  </span>
                </div>

                <div>
                  <span class="title"> 存储条件： </span>
                  {{ item.storageCondition }}
                </div>
              </li>
            </ul>
          </div>
        </template>
      </a-card>
    </a-form>

    <!-- 试剂使用选择-->
    <a-modal v-bind="checkReagentSelectModal">
      <check-reagent-list v-bind="checkReagentSelectModal.data" v-on="checkReagentSelectModal.tableAction"></check-reagent-list>
      <template #footer>
        <a-button @click="checkReagentSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="checkReagentSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import mixinForm from '@/pages/common/mixins/form'
import { commonValidDateUnitDataMap } from '@/api/commonConstant'
import { checkSolutionTypeDataMap, checkSolutionTypeEnum } from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import { reagentCateMaterialTypeEnum } from '@/api/checkReagent/checkReagentConstant'
import { checkStandardSolutionApi, checkStandardSolutionTypeApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'
import { CheckReagentCateMaterialTable } from '@/pages/cloud-quote/check-reagent/cate/modules'
import CheckReagentList from '@/pages/cloud-quote/check-reagent/SelectList'
import { checkReagentSelectColumn } from '@/pages/cloud-quote/check-reagent/modules/table/columns'
import JInputNumber from '@/components/j/JInputNumber'
import { throttle } from 'lodash'
import { autoConfigureApi, sysReviewConfigUserControllerApi } from '@/api/autoConfigure/autoConfigureApi'
import { checkReagentApi } from '@/api/checkReagent/checkReagentApi'
import { resLocationApi, Get } from '@/api/quote'
import { checkTaskBatchApi } from '@/api/check/checkTaskApi'

export default {
  mixins: [mixinForm],
  props: ['id', 'solutionTypeId'],
  components: { CheckReagentCateMaterialTable, CheckReagentList, JInputNumber },

  data() {
    return {
      commonValidDateUnitDataMap,
      checkSolutionTypeEnum,
      reagentCateMaterialTypeEnum,
      checkSolutionTypeDataMap,

      loadDetail: false,

      solutionTypeInfo: {},

      dataForm: {
        data: {
          id: null,
          solutionNo: null,
          name: null,
          wd: null,
          sd: null,
          usageReagentId: null,
          sumVolume: null,
          volume: null,
          solutionList: [],
          solventList: [],
          solutionTypeId: this.solutionTypeId
        }
      },

      decorators: {
        name: ['name', { rules: [{ required: true, message: '请输入标准使用液名称！' }] }],
        makeTime: ['makeTime', { initialValue: this.now(), rules: [{ required: true, message: '请选择制配时间！' }] }],
        validTime: ['validTime', { rules: [{ required: true }] }],
        sumVolume: ['sumVolume', { rules: [{ required: true, message: '请输入制配量！' }] }],
        wd: ['wd', { rules: [{ required: true, message: '请输入制配时温度！' }] }],
        sd: ['sd', { rules: [{ required: true, message: '请输入制配时湿度！' }] }],
        uncertainty: ['uncertainty', { initialValue: 0, rules: [{ required: true, message: '请输入不确定度！' }] }],
        uncertaintyUnit: ['uncertaintyUnit', { initialValue: '%', rules: [{ required: true, message: '请选择不确定度单位！' }] }]
      },

      // 贮备液
      stockSolutionList: [],

      // 中间液
      middleSolutionList: [],

      checkReagentSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '请选择使用的溶液',
        bodyStyle: { background: '#F0F2F5' },
        selectType: 'solution',

        show: (materialId, selectType) => {
          this.checkReagentSelectModal.selectType = selectType
          this.checkReagentSelectModal.data.cateId = materialId
          this.checkReagentSelectModal.data.validCheckTime = this.timeFormat(this.dataForm.form.getFieldValue('makeTime'))
          this.checkReagentSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.checkReagentSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            if (this.checkReagentSelectModal.selectType === 'solution') {
              this.isEmptySolution = false
              this.dataForm.data.solutionList.splice(0, this.dataForm.data.solutionList.length, ...selectData.map((item) => Object.assign({ useVolume: 0 }, item)))
              if (this.dataForm.data.solutionList.length === 1) {
                const solution = this.dataForm.data.solutionList[0]
                if (solution.sumVolume >= this.solutionTypeInfo.volume) {
                  solution.useVolume = this.solutionTypeInfo.volume
                }
              }
              let totalVolume = this.dataForm.data.solutionList.reduce((a, { volume }) => a + volume, 0)
              if (totalVolume < this.solutionTypeInfo.volume) {
                this.isLessThanSolutionVolume = true
              }
            } else {
              this.isEmptySolvent = false
              this.dataForm.data.solventList.splice(0, this.dataForm.data.solventList.length, ...selectData.map((item) => Object.assign({ useVolume: 0 }, item)))

              if (this.dataForm.data.solventList.length === 1) {
                const solvent = this.dataForm.data.solventList[0]
                if (solvent.sumVolume >= this.solutionTypeInfo.constantVolume) {
                  solvent.useVolume = this.solutionTypeInfo.constantVolume
                }
              }
              let totalVolume = this.dataForm.data.solventList.reduce((a, { volume }) => a + volume, 0)
              if (totalVolume < this.solutionTypeInfo.constantVolume) {
                this.isLessThanSolventVolume = true
              }
            }
            this.checkReagentSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentSelectTable',
          cateId: null,
          selectable: true,
          pagination: true,
          validCheckTime: null,
          columns: checkReagentSelectColumn
        },

        tableAction: {},

        // 新增的标液Id
        newId: null,
        // 自动标液复审核审核人员、复核人员
        assignUser: {}
      },
      // 默认情况下溶液、稀释液数据是否为空
      isEmptySolution: false,
      isEmptySolvent: false,
      // 溶液、稀释液可用量是否小于使用量
      isLessThanSolutionVolume: false,
      isLessThanSolventVolume: false,
      treeData: [],
      locationId: null,
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      // 不确定度
      uncertainty: 0,
      // 不确定度类型
      uncertaintyUnitList: [],
      uncertaintyUnit: '%'
    }
  },

  computed: {
    solutionSumVolume() {
      return this.dataForm.data.solutionList.reduce((acc, item) => acc + (parseFloat(item.useVolume) || 0), 0)
    },

    solventSumVolume() {
      return this.dataForm.data.solventList.reduce((acc, item) => acc + (parseFloat(item.useVolume) || 0), 0)
    }
  },

  watch: {
    solutionTypeId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadDetail = true
          this.findDetailById(value)
        }
      }
    },
    'dataForm.data.parentReagentCateId': {
      immediate: true,
      handler(value) {
        value && !this.loadDetail && this.queryParentReagentCateInfo(value)
      }
    }
  },
  created() {
    this.getLocation()
  },
  mounted() {
    this.save = throttle(this.save, 6000, { leading: true, trailing: false })
  },

  methods: {
    // 检测室、房间
    getLocation() {
      Get(resLocationApi.list).then((res) => {
        if (res.code === 0) {
          this.treeData = res.data
          this.locationId = String(this.getDefaultLocation(this.treeData)) || '90'
          this.handleDevAndLocation(this.timeFormat(this.dataForm.form.getFieldValue('makeTime')))
        }
      })
    },
    getDefaultLocation(treeData) {
      let locations = treeData.filter((item) => item.name === '化学分析室')
      if (locations.length) {
        return locations[0].id
      } else {
        for (const key in treeData) {
          if (treeData[key].children && treeData[key].children.length) {
            let locationId = this.getDefaultLocation(treeData[key].children)
            if (locationId) {
              return locationId
            }
          }
        }
      }
    },
    changeLocation(value) {
      console.log(this.timeFormat(this.dataForm.form.getFieldValue('makeTime')))
      this.handleDevAndLocation(this.timeFormat(this.dataForm.form.getFieldValue('makeTime')))
    },
    // 根据房间以及制备时间获取温湿度
    handleDevAndLocation(startTime) {
      if (this.locationId && startTime) {
        const time = startTime.substr(0, 13) + ':00'
        checkTaskBatchApi.selectLocation({ locationId: this.locationId, time }).then((res) => {
          if (res.code === 0 && res.data) {
            const wd = res.data.ambient
            const sd = res.data.temperature
            // Object.assign(this.batchInfo, { wd, sd })
            let obj = {}
            if (wd) {
              obj.wd = wd
            }
            if (sd) {
              obj.sd = sd
            }
            if (wd || sd) {
              this.dataForm.form.$nextTick(() => {
                this.dataForm.form.setFieldsValue(obj)
              })
            }
          }
        })
      }
    },
    // 根据试剂类目配置获取有效期止
    getValidTime(current = this.now()) {
      const { validDateUnit, validDate } = this.solutionTypeInfo
      return this.moment(current).add(validDate, validDateUnit)
    },

    // 根据试剂类目配置设置有效期止
    async setValidTime(current) {
      const validTime = this.getValidTime(current)
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue({ validTime })
      })

      if (this.dataForm.data.solutionList && this.dataForm.data.solutionList.length) {
        for (let i = 0; i < this.dataForm.data.solutionList.length; i++) {
          let item = this.dataForm.data.solutionList[i]
          let solution = await this.getSolutionOne(item.id)
          if (solution) {
            let correctTime = current.isSameOrAfter(solution.configTime) && current.isSameOrBefore(solution.validTime)
            if (correctTime === false) {
              this.$message.error(`第${i + 1}条‘使用试剂’的制备时间不在范围之内，请对照该‘使用试剂’的配置时间和有效截止日`)
            }
          }
        }
      }

      if (this.dataForm.data.solventList && this.dataForm.data.solventList.length) {
        for (let i2 = 0; i2 < this.dataForm.data.solventList.length; i2++) {
          let item = this.dataForm.data.solventList[i2]
          let solvent = await this.getSolutionOne(item.id)
          if (solvent) {
            let correctSolventTime = current.isSameOrAfter(solvent.configTime) && current.isSameOrBefore(solvent.validTime)
            if (correctSolventTime === false) {
              this.$message.error(`第${i2 + 1}条‘使用稀释液’的制备时间不在范围之内，请对照该‘使用稀释液’的配置时间和有效截止日`)
            }
          }
        }
      }
    },
    getSolutionOne(id) {
      return checkReagentApi.findById(id).then((res) => {
        console.log(res)
        return res.data
      })
    },
    // 制备时间改变，获取新的温度湿度
    selectWdSd(e) {
      this.handleDevAndLocation(this.timeFormat(this.dataForm.form.getFieldValue('makeTime')))
    },

    // 获取对应标液类型配置明细
    findDetailById(id) {
      this.uncertaintyUnitList = ['%']
      checkStandardSolutionTypeApi.findDetailById(id).then((res) => {
        if (res.code === 0) {
          this.solutionTypeInfo = res.data
          let unit = this.getUnit(res.data.purity)
          // this.uncertaintyUnit = unit
          this.uncertaintyUnitList.unshift(unit)
          this.dataForm.form.$nextTick(() => {
            this.dataForm.form.setFieldsValue({ uncertaintyUnit: unit })
          })
          const configTime = this.getFormDataByKey('makeTime')
          this.setValidTime(configTime)
          this.dataForm.form.$nextTick(() => {
            const decoratorKeys = Object.keys(this.decorators)
            const newData = Object.keys(res.data).reduce((acc, key) => {
              decoratorKeys.includes(key) && typeof this.getFormDataByKey(key) === 'undefined' && (acc[key] = res.data[key])
              return acc
            }, {})
            this.dataForm.form.setFieldsValue(newData)
          })
          console.log(this.timeFormat(this.dataForm.form.getFieldValue('makeTime')))
          this.defaultSolutionList(res.data.parentReagentCateId)
          this.defaultSolventList(res.data.solventId)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getUnit(val) {
      let vals = val.split(/[0-9]+/g)
      let unit = vals[vals.length - 1]
      return unit
    },

    // 默认选中溶液
    defaultSolutionList(cateId) {
      this.isEmptySolution = false
      this.isLessThanSolutionVolume = false

      checkReagentApi
        .list({
          page: false,
          cateId,
          validCheckTime: this.timeFormat(this.dataForm.form.getFieldValue('makeTime'))
        })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            if (res.data.length > 0) {
              let selectData = []
              selectData.push(res.data[0])
              this.dataForm.data.solutionList.splice(0, this.dataForm.data.solutionList.length, ...selectData.map((item) => Object.assign({ useVolume: 0 }, item)))
              if (this.dataForm.data.solutionList.length === 1) {
                const solution = this.dataForm.data.solutionList[0]
                if (solution.sumVolume >= this.solutionTypeInfo.volume) {
                  solution.useVolume = this.solutionTypeInfo.volume
                }
              }
              let totalVolume = this.dataForm.data.solutionList.reduce((a, { volume }) => a + volume, 0)
              if (totalVolume < this.solutionTypeInfo.volume) {
                this.isLessThanSolutionVolume = true
              }
            } else {
              this.isEmptySolution = true
            }
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 默认选中稀释液
    defaultSolventList(cateId) {
      this.isEmptySolvent = false
      this.isLessThanSolventVolume = false
      checkReagentApi
        .list({
          page: false,
          cateId,
          validCheckTime: this.timeFormat(this.dataForm.form.getFieldValue('makeTime'))
        })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            if (res.data.length > 0) {
              let selectData = []
              selectData.push(res.data[0])
              this.dataForm.data.solventList.splice(0, this.dataForm.data.solventList.length, ...selectData.map((item) => Object.assign({ useVolume: 0 }, item)))

              if (this.dataForm.data.solventList.length === 1) {
                const solvent = this.dataForm.data.solventList[0]
                if (solvent.sumVolume >= this.solutionTypeInfo.constantVolume) {
                  solvent.useVolume = this.solutionTypeInfo.constantVolume
                }
              }
              let totalVolume = this.dataForm.data.solventList.reduce((a, { volume }) => a + volume, 0)
              if (totalVolume < this.solutionTypeInfo.constantVolume) {
                this.isLessThanSolventVolume = true
              }
            } else {
              this.isEmptySolvent = true
            }
          } else {
            this.$message.error(res.msg)
          }
        })
    },

    save() {
      this.newId = null
      this.assignUser = {}
      this.dataForm.form.validateFields(async (errors, values) => {
        if (!errors) {
          if (!this.dataForm.data.solutionList) {
            this.$message.error('请选择溶液！')
            return false
          }

          if (!this.dataForm.data.solventList) {
            this.$message.error('请选择稀释液！')
            return false
          }

          for (let i = 0; i < this.dataForm.data.solutionList.length; i++) {
            let item = this.dataForm.data.solutionList[i]
            let solution = await this.getSolutionOne(item.id)
            if (solution) {
              let correctTime = values.makeTime.isSameOrAfter(solution.configTime) && values.makeTime.isSameOrBefore(solution.validTime)
              if (correctTime === false) {
                this.$message.error(`第${i + 1}条‘使用试剂’的制备时间不在范围之内，请对照该‘使用试剂’的配置时间和有效截止日`)
                return
              }
            }
          }

          for (let i2 = 0; i2 < this.dataForm.data.solventList.length; i2++) {
            let item = this.dataForm.data.solventList[i2]
            let solvent = await this.getSolutionOne(item.id)
            if (solvent) {
              let correctSolventTime = values.makeTime.isSameOrAfter(solvent.configTime) && values.makeTime.isSameOrBefore(solvent.validTime)
              if (correctSolventTime === false) {
                this.$message.error(`第${i2 + 1}条‘使用稀释液’的制备时间不在范围之内，请对照该‘使用稀释液’的配置时间和有效截止日`)
                return
              }
            }
          }

          if (this.solutionSumVolume > this.solutionTypeInfo.volume) {
            this.$message.error('溶液使用过量！')
            return false
          } else if (this.solutionSumVolume < this.solutionTypeInfo.volume) {
            this.$message.error('溶液使用不足！')
            return false
          }

          if (this.solutionTypeInfo.solventId) {
            if (this.solventSumVolume > this.solutionTypeInfo.constantVolume) {
              this.$message.error('稀释液过量！')
              return false
            } else if (this.solventSumVolume < this.solutionTypeInfo.constantVolume) {
              this.$message.error('稀释液不足！')
              return false
            }
          }
          this.$emit('updateSolutionLoading', true)
          const data = Object.assign({}, this.dataForm.data, values)
          data.solutionList = data.solutionList?.map((item) => {
            item.useVolumeStr = item.useVolume || 0
            return item
          })
          data.solventList = data.solventList?.map((item) => {
            item.useVolumeStr = item.useVolume || 0
            return item
          })
          // if (this.uncertainty || this.uncertainty===0) {
          //   data.uncertainty = this.uncertainty
          //   data.uncertaintyUnit = this.uncertaintyUnit
          // }
          checkStandardSolutionApi
            .save(data)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.newId = res.data
                this.getStandardSolutionReviewConfig()
                // this.$emit('success')
              } else {
                this.$message.error(res.msg)
                this.$emit('error')
                this.$emit('updateSolutionLoading', false)
              }
            })
            .catch((err) => {
              this.$emit('updateSolutionLoading', false)
            })
        } else {
          this.$emit('updateSolutionLoading', false)
        }
      })
    },
    // 判断是否为自动标液复审核
    getStandardSolutionReviewConfig() {
      autoConfigureApi
        .selectConfigByType({ type: 'standard_solution_review_config' })
        .then((res) => {
          // 如果是1，自动标液复审核
          if (res == 1) {
            this.$message.warning('正在自动复审核，请勿进行其它操作！')
            this.selectUserList()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 获取自动标液复审核 复核人员
    selectUserList() {
      sysReviewConfigUserControllerApi
        .selectUserList({ type: 'by', id: this.newId })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.assignUser = res.data
            this.autoAssignReviewedCheckUser()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动指派复核人
    autoAssignReviewedCheckUser() {
      let assignDataArr = [{ id: this.newId, reviewUser: this.assignUser.reviewedCheckUser }]
      checkStandardSolutionApi
        .assign(assignDataArr)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('标液配置自动指派复核人成功')
            this.autoReview()
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动复核
    autoReview() {
      const reviewDataArr = [{ id: this.newId, reviewNote: null, reviewStatus: '1', reviewUser: this.assignUser.reviewedCheckUser }]

      checkStandardSolutionApi
        .review({ models: reviewDataArr, checkUser: null, checkPwd: null })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('标液配置自动复核成功')
            this.selectUserListReviewedUser()
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 获取自动标液复审核 审核人员
    selectUserListReviewedUser() {
      sysReviewConfigUserControllerApi
        .selectUserList({ type: 'by', id: this.newId })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.assignUser = res.data
            this.autoCheck()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动审核
    autoCheck() {
      const checkDataArr = [{ id: this.newId, checkNote: null, checkPwd: null, checkStatus: '2', checkUser: this.assignUser.reviewedUser }]

      checkStandardSolutionApi
        .autoCheck(checkDataArr)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('标液配置自动审核成功')
            this.newId = null
            this.assignUser = {}
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    }
  }
}
</script>

<style scoped lang="less">
@import './_index';
</style>
