import '@/assets/less/common/form.less'
import { isArray, isObject } from 'lodash'

export const UlTableColumn = {
  name: 'UlTableColumn',
  props: {
    dataIndex: { required: false, type: String },
    title: { required: false, type: String, default: '' },
    hiddenTitle: { required: false, type: Boolean, default: false },
    showDelimiter: { required: false, type: Boolean, default: true },
    data: { required: false, type: [Object, String] }
  },
  methods: {
    renderTitle (h) {
      const componentTitle = this.showDelimiter && this.title ? `${this.title}：` : this.title
      const title = h('span', { class: 'title' }, this.$slots.title ?? componentTitle)
      return h('span', { class: 'title-row' }, [title])
    },

    renderContent (h) {
      const text = isObject(this.data) ? this.data[this.dataIndex] : this.data
      return h('span', { class: 'content' }, this.$slots.default ? this.$slots.default : text)
    }
  },

  render (h) {
    const className = this.$attrs.class ?? ``
    const title = this.hiddenTitle ? null : this.renderTitle(h)
    return (
      <div class={className}>
        {title}
        {this.renderContent(h)}
      </div>
    )
  }
}

export const UlTableRow = {
  name: 'UlTableRow',
  props: {
    index: { required: false, type: Number },
    data: { required: false, type: [Array, Object, String] }
  },

  render () {
    const className = this.$attrs.class ? `li ${this.$attrs.class}` : 'li'
    return <li class={className}>{this.$scopedSlots.default?.({ data: this.data }) ?? this.$slots.default}</li>
  }
}

export default {
  name: 'UlTable',
  props: {
    title: { type: String, required: false, default: '' },
    dataSource: { type: [Array, Object], required: false, default: () => [] },
    rowComponent: { required: false, type: [Object, String], default: () => UlTableRow },
    rowProps: { required: false, type: Object, default: () => ({}) },
    rowAttrs: { required: false, type: Object, default: () => ({}) },
    hiddenTitle: { required: false, type: Boolean, default: false },
    hiddenRow: { required: false, type: Boolean, default: false },
    hiddenWrap: { required: false, type: Boolean, default: false }
  },

  methods: {
    renderTitle (h) {
      if (this.hiddenTitle) {
        return
      }
      const title = h('span', { class: 'title' }, this.$slots.title ?? this.title)
      const extra = this.$slots.extra ? h('div', { class: 'fr' }, this.$slots.extra) : null
      return h('div', { class: 'title-row' }, [title, extra])
    },

    renderContent (h) {
      if (this.$scopedSlots.content || this.$slots.content) {
        return h('ul', { class: 'ul' }, this.$scopedSlots?.content(this.dataSource) ?? this.$slots.content)
      } else {
        const dataSource = isArray(this.dataSource) ? this.dataSource : [this.dataSource]
        const children = !this.hiddenRow
          ? dataSource.map((data, index) => {
              return h(this.rowComponent, { ...this.rowAttrs, props: Object.assign({ data, index }, this.rowProps) }, this.$scopedSlots.default?.({ data, index }) ?? this.$slots.default)
            })
          : []
        return h('ul', { class: 'ul' }, children)
      }
    }
  },

  render (h) {
    const className = this.$attrs.class ? `ul-form ${this.$attrs.class}` : 'ul-form'
    const content = this.hiddenWrap ? this.renderContent(h) : <div class="content-row">{this.renderContent(h)}</div>
    return (
      <div class={className}>
        {this.renderTitle(h)}
        {content}
      </div>
    )
  }
}
