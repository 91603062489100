<template>
  <div>
    <!-- 安排明细区域 -->
    <a-card id="order" v-bind="orderCard">
      <template #extra>
        <a-button type="primary" @click="autoMatch">自动匹配</a-button>
        <a-button type="primary" style="margin-left: 8px" @click="resetMethod">重置</a-button>
      </template>
      <a-form>
        <a-row v-bind="rowProps">
          <a-col :span="24" v-bind="colProps">
            <a-table v-bind="orderDetailProps">
              <template #analysisStandardTemplate="text, record">
                <span>{{ getStandardName(record) }}</span>
              </template>
              <template #methodTemplate="text, record">
                <a-select v-model="record.methodId" @change="(v) => handleCheckMethodChange(v, record, checkGuideDetailCateMap[record.cateId + '_' + record.itemId])">
                  <a-select-option v-for="method in checkGuideDetailCateMap[record.cateId + '_' + record.itemId]" :key="method.methodId">
                    <span :title="method.methodName + '(' + method.standardCode + ')'">{{ method.methodName + '(' + method.standardCode + ')' }}</span>
                    <!-- <ellipsis :length="10">{{ method.methodName + '(' + method.standardCode + ')' }}</ellipsis> -->
                  </a-select-option>
                </a-select>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { itemCheckDetailApi, myQualificationsApi } from '@/api/quote'
import { projectApi, itemCateMethodApi } from '@/api/project'
import { groupBy, orderBy, unionBy } from 'lodash'

export default {
  name: 'CheckTaskSchedule',

  props: {
    projId: {
      required: false,
      type: Number
    }
  },

  data() {
    return {
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },

      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 6,
        style: { textAlign: 'left' }
      },

      orderCard: {
        title: '安排明细',
        style: { margin: '0px' },
        headStyle: {},
        bodyStyle: {},
        bordered: false,
        type: 'inner',
        class: 'card-table'
      },

      orderDetailProps: {
        class: 'table-person-task',
        rowKey: (r, index) => {
          return r.cateId + '_' + r.itemId + '_' + index
        },
        size: 'small',
        bordered: true,
        dataSource: [],
        pagination: false,
        columns: [
          // 表格中不再显示日期数据，只需要显示如下几列
          { title: '分类', dataIndex: 'cateName', width: 150 },
          { title: '检测项', dataIndex: 'itemName', width: 180 },

          { title: '方法数量', dataIndex: 'methodNumber', width: 150, scopedSlots: { customRender: 'methodNumber' } },
          { title: '标准', dataIndex: 'analysisStandardName', width: 250, scopedSlots: { customRender: 'analysisStandardTemplate' } },
          { title: '检测方法', dataIndex: 'methodName', width: 250, scopedSlots: { customRender: 'methodTemplate' } }
        ]
      },

      // 检测方法数据
      checkGuideDetailCateMap: {},
      project: {},
      selectMetchods: []
    }
  },

  watch: {
    projId: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getProj(val)
        }
      }
    }
  },
  methods: {
    // 保存检测方法
    save() {
      if (this.orderDetailProps.dataSource && this.orderDetailProps.dataSource.length) {
        let saveData = []
        this.orderDetailProps.dataSource.forEach((item) => {
          if (item.methodId) {
            let obj = {
              projId: this.project.id,
              quoteId: this.project.quoteId,
              cateId: item.cateId,
              itemId: item.itemId,
              methodId: item.methodId
            }
            // if (this.selectMetchods.length) {
            //   let sms = this.selectMetchods.filter((it) => obj.projId === it.projId && obj.cateId === it.cateId && obj.itemId === it.itemId)
            //   if (sms && sms.length) {
            //     obj.id = sms[0].id
            //   }
            // }
            if (item.selectMethodId) {
              obj.id = item.selectMethodId
            }

            saveData.push(obj)
          }
        })
        console.log('saveData:', saveData)
        itemCateMethodApi.add(saveData).then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.error('您还未进行工作安排')
        return false
      }
    },

    // 获取检测方法
    queryCheckGuideDetail() {
      if (this.orderDetailProps.dataSource && this.orderDetailProps.dataSource.length) {
        let items = unionBy(this.orderDetailProps.dataSource.map((item) => item.cateId + '-' + item.itemId))
        itemCheckDetailApi.listDetail({ page: false, items }).then((res) => {
          if (res.code === 0) {
            if (res.data) {
              const data = orderBy(res.data, 'defaultFlag', 'desc')
              this.checkGuideDetailCateMap = groupBy(data, (item) => item.cateId + '_' + item.itemId)
              this.orderDetailProps.dataSource.forEach((item) => {
                let methodNumber =
                  this.checkGuideDetailCateMap[item.cateId + '_' + item.itemId] && this.checkGuideDetailCateMap[item.cateId + '_' + item.itemId].length
                    ? this.checkGuideDetailCateMap[item.cateId + '_' + item.itemId].length
                    : 0
                // console.log(methodNumber)
                this.$set(item, 'methodNumber', methodNumber)
              })
            } else {
              message.warning('任务无对应的检测方法，请先配置检测方法！')
            }
          } else {
            message.error(res.msg)
          }
        })
      }
    },
    handleCheckMethodChange(methodId, record, methods) {
      let methodItem = methods.filter((item) => item.methodId === methodId)
      // console.log(methods, methodItem)
      this.$set(record, 'methodId', methodItem[0].methodId)
      this.$set(record, 'methodName', methodItem[0].methodName)
    },
    // 自动匹配检测方法
    async autoMatch() {
      for (let i = 0; i < this.orderDetailProps.dataSource.length; i++) {
        let item = this.orderDetailProps.dataSource[i]
        if (!item.methodId) {
          // console.log('1111111111111111111111111')
          let methods = await this.getCheckMethod(item.cateId, item.itemId)
          methods = methods.filter((it) => item.cateId === it.cateId && item.itemId === it.itemId)
          // console.log(methods)
          if (methods && methods.length > 0) {
            // console.log(this.checkGuideDetailCateMap[item.cateId + '_' + item.itemId])
            let guideMethodIds = this.checkGuideDetailCateMap[item.cateId + '_' + item.itemId].map((it) => it.methodId)
            let ms = methods.filter((it) => guideMethodIds.indexOf(it.methodId) !== -1)
            // console.log(ms)
            if (ms.length) {
              this.$set(item, 'methodId', ms[0].methodId)
              this.$set(item, 'methodName', ms[0].methodName)
            }
          }
        }
      }
    },
    // 重置
    resetMethod() {
      for (let i = 0; i < this.orderDetailProps.dataSource.length; i++) {
        let item = this.orderDetailProps.dataSource[i]
        this.$set(item, 'methodId', null)
        this.$set(item, 'methodName', null)
      }
    },
    getCheckMethod(cateId, itemId) {
      return myQualificationsApi.getCheckMethod({ cateId, itemId }).then((res) => {
        // itemCheckDetailApi.listDetail({ page: false, items: [itemInfoStr] }).then((res) => {
        if (res.code === 0) {
          return res.data.dataList
        } else {
          message.error(res.msg)
        }
      })
    },
    getProj(projId) {
      this.project = {}
      this.orderDetailProps.dataSource = []
      this.checkGuideDetailCateMap = {}
      this.selectMetchods = []
      projectApi.get({ id: projId, itemType: '1' }).then((result) => {
        if (result.code === 0) {
          if (result.data) {
            let list = []
            this.project = result.data
            this.project?.cateList?.forEach((cateItem) => {
              cateItem.siteList?.forEach((siteItem) => {
                siteItem.itemList?.forEach((item) => {
                  let findIndex = list.findIndex((it) => it.cateId === item.cateId && it.itemId === item.itemId)
                  if (findIndex === -1) {
                    list.push(item)
                  }
                })
              })
            })
            console.log(list)
            this.orderDetailProps.dataSource.splice(0, this.orderDetailProps.dataSource.length, ...list)
            this.queryCheckGuideDetail()
            this.selectMetchod()
          }
        }
      })
    },
    selectMetchod() {
      itemCateMethodApi.selectMetchod({ projId: this.projId }).then((res) => {
        if (res.code === 0) {
          this.selectMetchods = res.data
          if (this.selectMetchods && this.selectMetchods.length) {
            this.orderDetailProps.dataSource.forEach((item) => {
              let sms = this.selectMetchods.filter((it) => this.project.id === it.projId && item.cateId === it.cateId && item.itemId === it.itemId)
              if (sms.length) {
                this.$set(item, 'methodId', sms[0].methodId)
                this.$set(item, 'selectMethodId', sms[0].id)
              }
            })
          }
        }
      })
    },
    getStandardName(record) {
      let standardName = ''
      let methods = this.checkGuideDetailCateMap[record.cateId + '_' + record.itemId]?.filter((item) => item.methodId === record.methodId) || []
      if ((methods, methods.length)) {
        standardName = methods[0].standardName
      }
      return standardName
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet';

.margin();

.float();

.task-detail-div {
  border-top: 1px solid;
  padding: 15px 5px 10px;
}

.task-detail-div-table {
  border-collapse: collapse;
  margin: 0 auto;
}

.task-detail-div-table td,
.task-detail-div-table th {
  border: 1px solid #cad9ea;
  color: #666666;
  height: 30px;
}

.task-detail-div-table thead th {
  background-color: #cce8eb;
  text-align: center;
  width: 100px;
}

.task-detail-div-table tr:nth-child(odd) {
  background: #ffffff;
}

.task-detail-div-table tr:nth-child(even) {
  background: #f5fafa;
}

.ant-advanced-search-form {
  padding: 24px 24px 0 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  .table-person-task {
    margin-bottom: 24px;

    /deep/ .ant-table-fixed-left {
      .ant-table-body-inner {
        padding: 0;
      }
    }

    /deep/ .ant-checkbox-wrapper {
      .ant-checkbox + span {
        padding: 0 3px 0 5px;
      }
    }

    /deep/ .ant-table-placeholder {
      color: red;
      font-weight: bolder;
    }

    /deep/ .ant-table-placeholder {
      color: red;
      font-weight: bolder;
    }

    /deep/ .ant-table-thead > tr > th.off-day {
      color: red !important;
    }

    /deep/ .has-task {
      color: red !important;

      /deep/ .ant-checkbox-inner {
        border: 1px solid red;
      }
    }

    /deep/ .has-check {
      color: orange !important;

      /deep/ .ant-checkbox-inner {
        border: 1px solid orange;
      }
    }
  }

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  /deep/ .ant-select-selection-selected-value {
    .action {
      display: none;
    }
  }

  /deep/ .ant-table-filter-dropdown-link.clear {
    clear: none;
  }
}
</style>
