<template>
  <a-modal v-bind="applyModal">
    <material-single-list v-bind="materialTable" :showOperation="false" @selectedRows="handleMaterialRowSelected"/>
    <ts-schedule-resources v-bind="scheduleResources" @cancel="handleScheduleResource"></ts-schedule-resources>
    <template #footer>
      <a-button @click="applyModal.cancel">关闭</a-button>
      <a-button type="primary" @click="save">发起申请</a-button>
    </template>
  </a-modal>
</template>

<script>
import TsScheduleResources from '@/pages/cloud-quote/take-sample/modules/TsScheduleResources'
import MaterialSingleList from '@/pages/cloud-quote/res/res-materialsingle/materialSingleList'
import fullModal from '@/pages/common/mixins/modal/full'
import { materialSingleSelectColumn } from '@/pages/cloud-quote/res/res-materialsingle/modules/columns'

const fullModalData = fullModal.data().fullModal

export default {
  components: { TsScheduleResources, MaterialSingleList },

  data () {
    return {
      selectMaterialData: [],

      applyModal: Object.assign({}, fullModalData, {
        visible: false,
        title: '申请领用',
        bodyStyle: { background: '#F0F2F5' },

        cancel: () => {
          this.applyModal.visible = false
        }

      }),

      materialTable: {
        ref: 'materialTable',
        cateTypes: [2, 5, 6, 7],
        rowSelectionType: 'checkBox',
        columns: materialSingleSelectColumn
      },

      scheduleResources: {
        ref: 'tsScheduleResource',
        rowSelectionType: 'checkBox',

        show: () => {
          const { username: userId, realname: userName } = this.loginUser
          const purUseReason = '因需要制配试剂,特此申请原料!'
          this.$refs[this.scheduleResources.ref].init(this.materialData, userName, userId, purUseReason, true)
        }

      }
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    },

    materialData () {
      const ids = this.selectMaterialData.map(item => ({ id: item.id, type: 'number', num: 1 }))
      return { material: ids, projId: null, purId: null, purFlag: '0' }
    }
  },

  methods: {

    handleMaterialRowSelected (data) {
      this.selectMaterialData.splice(0, this.selectMaterialData.length, ...data)
    },

    show () {
      this.applyModal.visible = true
    },

    handleScheduleResource () {
      this.applyModal.cancel()
      this.$emit('success')
    },

    save () {
      if (!this.selectMaterialData.length) {
        this.$message.error('请先选择原料！')
        return false
      }
      this.scheduleResources.show()
    }
  }
}
</script>

<style scoped>

</style>
