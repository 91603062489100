<template>
  <div>
    <a-input-search
      :class="clazz"
      v-model="selectStaff"
      :placeholder="placeholder"
      disabled
      @search="onSearchStaff">
      <a-button slot="enterButton" :disabled="disabled">选择</a-button>
    </a-input-search>

    <search-staff-modal
      v-if="SearchStaffModal.visible"
      v-bind="SearchStaffModal"
      v-on="SearchStaffModal"/>
  </div>
</template>

<script>
import SearchStaffModal from '@/pages/flow/module/components/search-staff/SearchStaffModal'

export default {
  name: 'SearchStaffEdit',
  components: { SearchStaffModal },
  props: {
    modalWidth: {
      type: Number,
      default: 1250,
      required: false
    },

    clazz: {
      type: String,
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: '请先选择人员'
    },

    triggerChange: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      staffList: [],

      selectStaff: '',

      SearchStaffModal: {
        visible: false,
        ref: 'SearchStaffModal',
        ok: (data) => { this.change(data) },
        show: () => { this.SearchStaffModal.visible = true },
        cancel: () => { this.SearchStaffModal.visible = false }
      }
    }
  },

  mounted () {
    this.selectStaff = this.value
  },

  watch: {
    staffList: {
      immediate: true,
      handler (val) {
        this.selectStaff = val.length > 0 ? this.staffList[0].name : ''
      }
    }
  },
  methods: {

    change (data) {
      this.staffList.splice(0, this.staffList.length, ...data)
      this.$emit('change', data.length && data[0])
    },

    // 通过组织机构筛选选择用户
    onSearchStaff () {
      this.SearchStaffModal.show()
      this.$nextTick(() => {
        this.$refs[this.SearchStaffModal.ref].init(this.staffList)
      })
    }
  }
}
</script>

<style scoped>

</style>
