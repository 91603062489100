<template>
  <a-modal v-bind="modal" v-on="modal">
    <a-card :bordered="false">
      <a-form>
        <a-row>
          <a-form-item label="方案名称" v-bind="formItemLayout">
            {{ quote.projectTitle }}
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="目标商机" v-bind="formItemLayout">
            <a-input v-model="newQuote.busTitle" readonly="readonly" @click="selectBusiness" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="新方案名称" v-bind="formItemLayout">
            <div :class="`required ${notNull(newQuote.projectTitle)}`">
              <a-input v-model="newQuote.projectTitle" />
            </div>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="委托时间" v-bind="formItemLayout">
            <div :class="`required ${notNull(newQuote.quoteDate)}`">
              <JDate style="width: 100%" v-model="newQuote.quoteDate" placeholder="委托时间" :disabledDateObj="{ isDisabledDate: true, days: 0 }"></JDate>
            </div>
          </a-form-item>
        </a-row>
      </a-form>
    </a-card>
    <business-list-vue ref="businessListModal" :limit-time="false" @ok="updateBusId" />
  </a-modal>
</template>

<script>
import { quoteApi } from '@/api/quote'
import JDate from '@/components/j/JDate'

export default {
  name: 'CopyQuote',
  components: {
    JDate
  },
  data() {
    return {
      modal: {
        title: '复制方案',
        visible: false,
        width: '560px',
        centered: true,
        maskClosable: false,
        destroyOnClose: true,
        ok: this.ok,
        cancel: this.cancel
      },

      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      quote: {},
      newQuote: {}
    }
  },
  methods: {
    copy(r) {
      this.quote = r

      this.$set(this.newQuote, 'oldId', r.id)
      this.$set(this.newQuote, 'busId', r.busId)
      this.$set(this.newQuote, 'busTitle', r.busTitle)
      this.$set(this.newQuote, 'projectTitle', '复制方案-' + r.projectTitle)
      this.$set(this.newQuote, 'quoteDate', r.quoteDate)
      this.modal.visible = true
    },
    ok(e) {
      e.preventDefault()
      const self = this
      var parms = { id: this.newQuote.oldId, projectTitle: this.newQuote.projectTitle, busId: this.newQuote.busId, quoteDate: this.newQuote.quoteDate }
      quoteApi
        .copyQuote(parms)
        .then((result) => {
          if (result.code === 0) {
            self.$message.success(result.msg)
            if (result.data) {
              self.$message.warning(result.data)
            }
            self.$emit('ok', null)
            self.cancel()
          }
        })
        .catch((error) => {
          self.$message.error(`服务器发生错误！错误消息为${error}`)
        })
    },
    selectBusiness() {
      this.$refs.businessListModal.open()
    },
    updateBusId(obj) {
      this.$set(this.newQuote, 'busId', obj.id)
      this.$set(this.newQuote, 'busTitle', obj.title)
    },
    notNull(v) {
      return v ? '' : 'has-error'
    },
    cancel() {
      this.modal.visible = false
    }
  },
  watch: {}
}
</script>

<style scoped>
</style>
