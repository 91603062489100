<template>
  <a-modal v-bind="paymentFormModal" v-on="paymentFormModal">
    <a-form :form="form">
      <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <!--<a-select v-model="record.type" @change="changeCategory">&lt;!&ndash; v-decorator="['type',{initialValue:'1',rules:[{required: true}]}]"&ndash;&gt;
&lt;!&ndash;          <a-select-option v-for="(vo, index) in typeList" :key="index" :value="vo.id">{{ vo.name }}</a-select-option>&ndash;&gt;
          <a-select-option value="1">分包商</a-select-option>
          <a-select-option value="2">商家</a-select-option>
          <a-select-option value="3">内部人员</a-select-option>
          <a-select-option value="4">外部人员</a-select-option>
        </a-select>-->
<!--        <a-radio-group v-model="record.type" button-style="solid" @change="changeType">-->
<!--          <a-radio-button :value="1">-->
<!--            分包商-->
<!--          </a-radio-button>-->
<!--          <a-radio-button :value="2">-->
<!--            商家-->
<!--          </a-radio-button>-->
<!--          <a-radio-button :value="3">-->
<!--            内部人员-->
<!--          </a-radio-button>-->
<!--          <a-radio-button :value="4">-->
<!--            外部人员-->
<!--          </a-radio-button>-->
<!--        </a-radio-group>-->
        <a-radio-group v-model="record.type" @change="changeType">
          <a-radio :value="1">
            分包商
          </a-radio>
          <a-radio :value="2">
            商家
          </a-radio>
          <a-radio :value="3">
            内部人员
          </a-radio>
          <a-radio :value="4">
            外部人员
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <template v-if="record.type === 4">
        <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-model="record.name"/>
        </a-form-item>
      </template>
      <template v-else>
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <!--        <div :class="`required ${record.type === '1'}`" v-if="record.type === '1'">-->
          <!--          <a-input v-model="record.name" :title="record.name" @click="selectSubcontractor" readonly="readonly"/>-->
          <!--        </div>-->
          <!--        <a-input v-decorator="['name', {rules: [{required: true}]}]" />-->
          <a-input v-model="record.name" :title="record.name" @click="selectSubcontractor" readonly="readonly" />
        </a-form-item>
      </template>
      <a-form-item label="开户行" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-model="record.bank" />
      </a-form-item>
      <a-form-item label="卡号" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-model="record.bankNbr" />
      </a-form-item>
    </a-form>
    <subcontractor-list ref="subcontractorList" @ok="updateSubcontractor"></subcontractor-list>
    <business-list ref="businessList" @ok="updateBusiness"></business-list>
    <staff-list ref="staffList" @ok="updateStaff"></staff-list>
  </a-modal>
</template>

<script>
import { paymentApi } from '@/api/payment'
import SubcontractorList from '@/pages/flow/module/components/search-payment/SubcontractorList'
import BusinessList from '@/pages/flow/module/components/search-payment/BusinessList'
import StaffList from '@/pages/flow/module/components/search-payment/StaffList'
export default {
  name: 'PaymentForm',
  components: { StaffList, BusinessList, SubcontractorList },
  data () {
    return {
      // title: '新增',
      // visible: true,
      record: {},
      paymentFormModal: {
        width: 850,
        // centered: true,
        // closable: false,
        // destroyOnClose: true,
        // maskClosable: false,
        visible: false,
        title: '新增',
        ok: this.handleSubmit,
        cancel: this.handleCancel,
        zIndex: 3001
      },
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      disabled: true,
      typeList: [
        { id: '1', name: '分包商' },
        { id: '2', name: '商家' },
        { id: '3', name: '内部人员' },
        { id: '4', name: '外部人员' }],
      dataType: 'edit',
      subcontractList: [],
      value11: '1'
    }
  },
  created () {
    // this.queryCategoryList()
  },
  methods: {
    // queryCategoryList () {
    //   flowApi.flowCategory().then(result => {
    //     if (result.code === 0) {
    //       this.categoryList = result.data
    //     } else {
    //       this.$message.error(result.msg)
    //     }
    //   }).catch(error => {
    //     this.$message.error(`服务器发生错误！错误消息为${error}`)
    //   })
    // },
    querySubcontractList () {
      // flowApi.flowCategory().then(result => {
      //   if (result.code === 0) {
      //     this.subcontractList = result.data
      //   } else {
      //     this.$message.error(result.msg)
      //   }
      // }).catch(error => {
      //   this.$message.error(`服务器发生错误！错误消息为${error}`)
      // })
    },
    selectSubcontractor () {
      var param = { id: this.record.refId }
      if (this.record.type === '1') {
        this.$refs.subcontractorList.open(param)
      } else if (this.record.type === '2') {
        this.$refs.businessList.open(param)
      } else if (this.record.type === '3') {
        this.$refs.staffList.open(param)
      }
    },
    updateSubcontractor (obj) {
      this.$set(this.record, 'name', obj.sctrName)
      this.$set(this.record, 'refId', obj.id)
    },
    updateBusiness (obj) {
      this.$set(this.record, 'name', obj.name)
      this.$set(this.record, 'refId', obj.id)
      if (obj.bank !== '' && obj.bankNbr !== '') {
        this.$set(this.record, 'bank', obj.bank)
        this.$set(this.record, 'bankNbr', obj.bankNbr)
      }
    },
    updateStaff (obj) {
      this.$set(this.record, 'name', obj.name)
      this.$set(this.record, 'refId', obj.id)
    },
    add (type) {
      this.paymentFormModal.visible = true
      this.record = { type: parseInt(type) }
    },
    // edit (record) {
    //   this.visible = true
    //   this.fileList = []
    //   this.fileAppendix = []
    //   if (record.subName === null) {
    //     this.dataType = 'add'
    //     record.name.length > 6 ? record.subName = record.name.substr(0, 6) : record.subName = record.name
    //   }
    //   this.record = record
    //   this.form.$nextTick(() => {
    //     this.fileUrl = record.icon // !== null && record.icon.indexOf('/') === 0 ? this.viewUrl + record.icon : record.icon
    //     this.fileList.push({ uid: record.id, name: record.name, status: 'done', url: this.fileUrl })
    //     this.fileAppendix.push({ uid: record.id, fileUrl: record.icon, fileName: record.name })
    //     this.isShowValue = record.isShow === '1'
    //     this.shortMessageValue = record.shortMessage === '1'
    //     this.pushWechartValue = record.pushWechart === '1'
    //
    //     this.form.setFieldsValue(record)
    //   })
    // },
    handleCancel () {
      this.paymentFormModal.visible = false
    },
    changeCategory (value) {
      this.record.type = value
    },
    changeType (e) {
      this.record.type = parseInt(e.target.value)
    },
    handleSubmit () {
      const self = this
      if (self.record.type === '') {
        self.$message.warning('请选择类型')
        return false
      }
      if (self.record.name === '') {
        self.$message.warning('请填写名称/姓名')
        return false
      }
      if (self.record.bank === '') {
        self.$message.warning('请填写开户银行')
        return false
      }
      if (self.record.bankNbr === '') {
        self.$message.warning('请填写银行账号')
        return false
      }
      // const { form: { validateFields } } = this
      // validateFields((errors, values) => {
      //   values['type'] = this.record.type
      //   values['refId'] = this.record.refId
      //   values['name'] = this.record.name
      //   if (!errors) {
      paymentApi.save(self.record).then(result => {
        if (result.code === 0) {
          self.paymentFormModal.visible = false
          self.record = {}
          self.$emit('ok', {})
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
      //   }
      // })
    }
  }
}
</script>
