<template>
  <div>
    <a-table v-bind="sampleTable">

      <template slot="id" slot-scope="id">
        {{ id }}
      </template>

      <template slot="items" slot-scope="item, record">
        <el-cascader :key="'cascader'" @change="val => itemChange(val, record)" v-bind="itemCascader2" v-if="record.editable" v-model="record.items"></el-cascader>
        <el-cascader :key="'cascaderShow'" v-bind="itemCascader" v-else-if="!record.isSaved" v-model="record.items"></el-cascader>
        <template v-else>
          <span>{{ record.itemNameStr }}</span>
        </template>
      </template>

      <template slot="sampleTime" slot-scope="sampleTime, record">
        <a-range-picker
          :defaultValue="[record.sampleBeginTime,record.sampleEndTime]"
          :format="showTimeFormat"
          :placeholder="['开始时间', '结束时间']"
          :showTime="{ format: 'HH:mm' }"
          :style="{width:'100%'}"
          @change="val => handleSampleTimeChange(val, record)"
          v-if="record.editable"/>
        <template v-else>
          <span style="color: green">起：</span>{{ record.sampleBeginTime |momentFilter(showTimeFormat) }}<br/>
          <span style="color: red">止：</span>{{ record.sampleEndTime|momentFilter(showTimeFormat) }}
        </template>
      </template>

      <template slot="num" slot-scope="num, record">
        <div v-if="record.editable">
          <a-input-number :min="1" :style="{width:'100%'}" v-model="record.num"/>
        </div>
        <template v-else>{{ num }}份</template>
      </template>

      <template slot="storage" slot-scope="storage, record">
        <div v-if="record.editable">
          <a-input v-model="record.storage"/>
        </div>
        <template v-else>{{ storage }}</template>
      </template>

      <template slot="volume" slot-scope="volume, record">
        <div v-if="record.editable">
          <a-input-number :min="0" :style="{width:'40%'}" v-model="record.volume"/>
          <j-dict-select-tag :value="record.volumeUnit" @change="v => handleVolumeUnitChange(v, record)" v-bind="volumeDictProp"/>
        </div>
        <template v-else>{{ record.volume }}{{ record.volumeUnit }}</template>
      </template>

      <template slot="validity" slot-scope="validity, record">
        <div v-if="record.editable">
          <a-input-number :min="1" :style="tableColStyles.validityNumber" v-model="record.validity"/>
          <a-radio-group :style="{width:'63%'}" v-model="record.validityUnit">
            <a-radio value="天">天</a-radio>
            <a-radio value="小时">小时</a-radio>
          </a-radio-group>
        </div>
        <template v-else>{{ record.validity }}{{ record.validityUnit }}</template>
      </template>

      <template #appearanceStatus="appearanceStatus, record">
        <span v-if="!record.editable">{{ record.appearanceStatus }}</span>
        <div v-else-if="record.editable">
          <a-select @change="val=>appearanceStatusSelect.change(val,record)" v-bind="appearanceStatusSelect" v-model="record.appearanceStatusArr">
            <a-select-option :key="data" v-for="(data) in appearanceStatusSelect.dataSource">{{ data }}</a-select-option>
          </a-select>
        </div>
      </template>

      <template slot="photos" slot-scope="photos, record">
        <template v-if="!record.isSaved">
          <a-tag @click="showQrcode(record.id)" color="orange" v-if="record.id&&record.editable&&(!photos||photos.length<3)">二维码传图</a-tag>
          <template v-if="photos.length">
            <div :key="photoIndex" class="photos" v-for="(photo,photoIndex) in photos">
              <span :style="{float:'left',width:'80%'}">{{ photo.name }}</span>
              <span :style="{float:'left',marginRight: '5px'}"><a @click="handlePreview(photo)"><a-icon type="eye"></a-icon></a></span>
              <span :style="{float:'left'}">
                <a-popconfirm @confirm="photos.splice(photoIndex,1)" title="是否要作废此图片吗？">
                  <a><a-icon type="delete"></a-icon></a>
                </a-popconfirm>
              </span>
            </div>
          </template>
        </template>
        <template v-else></template>
      </template>

      <template slot="operation" slot-scope="operation, record">
        <template v-if="record.editable">
          <span v-if="record.isNew">
            <a @click="saveSampleRow(record)">添加</a>
            <a-divider type="vertical"/>
            <a-popconfirm @confirm="removeSample(record.key)" title="是否要作废此行？">
              <a>作废</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a @click="saveSampleRow(record)">保存</a>
            <a-divider type="vertical"/>
            <a @click="sampleCancel(record.key)">取消</a>
          </span>
        </template>
        <span v-else-if="!record.isSaved">
          <a-popconfirm @confirm="print(record)" title="是否要打印该样品标签？">
            <a>打印标签</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a @click="toggleSample( record.key)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm @confirm="removeSample(record.key)" title="是否要作废此行？">
            <a>作废</a>
          </a-popconfirm>
        </span>
        <!-- 送样后被退回 -->
        <template v-else-if="record.status === sampleInfoStatusEnum.doneSample">
          <a-tag color="red">已被退回</a-tag>
          <a-divider type="vertical"/>
          <a-popconfirm @confirm="deleteSample(record.id)" title="是否要作废该样品？">
            <a>作废</a>
          </a-popconfirm>

        </template>
      </template>
    </a-table>

    <a-button :style="tableColStyles.addSampleButton" @click="addSampleInfo" icon="plus" type="dashed">新增样品</a-button>
    <picture-upload :file-length="3" @result="savePicture" modalTitlte="上传样品照片" ref="pictureUploadModal"/>

    <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
      <img :src="previewImage" alt="example" style="width: 100%"/>
    </a-modal>
  </div>
</template>

<script>

import moment from 'moment'
import { cloneDeep, omit } from 'lodash'
import { randomString } from '@/utils/util'
import { tsSampleinfoApi } from '@/api/sample'
import { resMaterialApi } from '@/api/quote'
import { sysCommonApi } from '@/api/common'
import JDictSelectTag from '@/pages/common/dict/JDictSelectTag'
import Vue from 'vue'
import { Cascader } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import PictureUpload from '@/pages/common/img-qrcode/PictureUpload'
import { printHelper } from '@/utils/printUtil'
import { sampleInfoStatusEnum } from '@/api/sampleInfo/sampleInfoConstant'

Vue.use(Cascader)

moment.prototype.toISOString = function () {
  if (this._f) {
    return this.format(this._f)
  } else {
    return this.format('YYYY-MM-DD HH:mm:ss')
  }
}

const showTimeFormat = 'YYYY-MM-DD HH:mm'

export default {
  name: 'ReceiveSampleForm',
  components: { JDictSelectTag, PictureUpload },
  props: {
    projId: { type: Number, required: true },
    projNo: { type: String, required: true },
    projTaskId: { type: Number, required: true },
    siteId: { type: Number, required: true },
    itemInfo: { type: Object, required: true },
    groupInfo: { type: Object, required: false, default: () => { return {} } },
    needGroup: { type: Boolean, required: true, default: false },
    cateId: { type: Number, required: true },
    itemAliasMap: { type: Object, required: true },
    numFrequency: { type: Number, required: true, default: 1 },
    numPeriod: { type: Number, required: true, default: 1 },
    siteNum: { type: Number, required: false, default: 1 },
    cateSiteNum: { type: Number, required: false, default: 1 },
    sampleStatusDictList: { type: Array, required: true },
    sampleConfig: { type: String, required: true },
    projInfo: { type: Object, required: false, default: () => ({}) }
  },

  data () {
    return {
      nowDate: this.now(),

      addPictureId: null,

      isGroup: this.needGroup,

      saveSampleList: [],

      itemCascader: {
        style: { width: '100%' },
        disabled: true,
        size: 'small',
        props: { label: 'name', value: 'id', children: 'children', multiple: true },
        options: []
      },

      itemCascader2: {
        style: { width: '100%' },
        props: { label: 'name', value: 'id', children: 'children', expandTrigger: 'hover', multiple: true },
        options: [],
        size: 'small',
        placeholder: '请选择检测项',
        collapseTags: true
      },

      storageCascader: {
        style: { width: '100%', maxHeight: '800px' },
        props: { label: 'name', value: 'name', children: 'childrens', expandTrigger: 'hover', multiple: true },
        options: [],
        size: 'small',
        placeholder: '请选择存储介质',
        collapseTags: true
      },

      storageCascader2: {
        style: { width: '100%' },
        disabled: true,
        size: 'small',
        props: { label: 'name', value: 'name', children: 'childrens', multiple: true },
        options: []
      },

      appearanceStatusSelect: {
        mode: 'tags',
        style: { width: '90%' },
        tokenSeparators: [',', '，'],
        dataSource: this.sampleStatusDictList,
        placeholder: '请选择样品状态',
        change: (val, record) => {
          const notIncludeArr = val && val.length && val.filter(item => !this.appearanceStatusSelect.dataSource.includes(item)) || []
          notIncludeArr.length && this.appearanceStatusSelect.dataSource.splice(this.appearanceStatusSelect.dataSource.length, 0, ...notIncludeArr)
          record.appearanceStatus = val && val.length && val.join(',') || null
        }
      },

      taskInfo: {
        projId: this.projId,
        projNo: this.projNo,
        projTaskId: this.projTaskId,
        siteId: this.siteId
      },

      siteCount: 1,

      showTimeFormat: showTimeFormat,

      timeFormat: 'YYYY-MM-DD HH:mm:ss',

      sampleTable: {
        pagination: false,
        size: 'small',
        rowKey: (item) => { return item.key || item.id },
        bordered: true,
        dataSource: [],
        columns: [
          { title: '样品编号', dataIndex: 'id', key: 'id', width: 100, scopedSlots: { customRender: 'id' } },
          { title: '检测项', key: 'items', width: 150, scopedSlots: { customRender: 'items' } },
          { title: '采样时间', dataIndex: 'sampleTime', key: 'sampleTime', width: 250, scopedSlots: { customRender: 'sampleTime' } },
          { title: '份数', dataIndex: 'num', key: 'num', width: 80, scopedSlots: { customRender: 'num' } },
          { title: '存储介质', dataIndex: 'storage', key: 'storage', width: 100, scopedSlots: { customRender: 'storage' } },
          { title: '体积/重量', dataIndex: 'volume', key: 'volume', width: 150, scopedSlots: { customRender: 'volume' } },
          { title: '有效期', dataIndex: 'validity', key: 'validity', width: 200, scopedSlots: { customRender: 'validity' } },
          { title: '样品状态', dataIndex: 'appearanceStatus', key: 'appearanceStatus', width: 150, scopedSlots: { customRender: 'appearanceStatus' } },
          { title: '样品照片', dataIndex: 'photos', key: 'photos', width: 150, scopedSlots: { customRender: 'photos' } },
          { title: '操作', key: 'action', width: 150, scopedSlots: { customRender: 'operation' } }
        ]
      },

      previewVisible: false,
      previewImage: null,

      pictureUpload: {
        accept: 'image/*',
        action: sysCommonApi.uploadFile,
        multiple: true,
        showUploadList: true,
        listType: 'text',
        headers: { 'Access-Token': this.$store.getters.token },
        preview: this.handlePreview
      },

      volumeDictProp: {
        style: { width: '50%', marginLeft: '10px' },
        placeholder: '请选择单位',
        triggerChange: true,
        dictCode: 'dev_measure'
      },

      tableColStyles: {
        itemDeleteButton: { marginLeft: '5px' },
        validityNumber: { width: '30%', marginRight: '5px' },
        addSampleButton: { width: '100%', margin: '16px 0 8px' }
      },

      sampleInfoStatusEnum
    }
  },

  computed: {
    loginUser () {
      return { ...this.$store.getters.userInfo }
    }
  },

  watch: {
    itemInfo: {
      immediate: true,
      handler (val) {
        this.setItemCascader(val)
        this.handleItemInfo(val)
      }
    },

    groupInfo: {
      immediate: true,
      handler (val) {
        this.handleGroupInfo(val)
      }
    },

    projId: {
      handler (val) {
        // this.findStorageCascader()
      },
      immediate: true
    },

    'appearanceStatusSelect.dataSource': {
      deep: true,
      handler (val) {
        this.$emit('update:sampleStatusDictList', val)
      }
    }
  },

  methods: {

    moment,

    print (record) {
      let itemName = record.groupName || record.items.reduce((initName, item) => {
        return initName + ',' + this.itemInfo.children.find(child => child.id === item[1]).name
      }, '')
      itemName.startsWith(',') && (itemName = itemName.substring(1))
      try {
        printHelper('sample', [record.id, record.id, itemName, record.sampleBeginTime.format('YYYY-MM-DD HH:mm:ss'), this.loginUser.realname])
      } catch (e) {
        this.$message.error('打印失败，请检查打印助手和设备是否正常连接')
        console.log('打印失败，请检查打印助手和设备是否正常连接', e)
      }
    },

    savePicture (data) {
      const find = this.sampleTable.dataSource.find(item => item.id === this.addPictureId)
      find && find.photos.splice(0, find.photos.length, ...data)
    },

    showQrcode (id) {
      this.addPictureId = id
      this.$refs.pictureUploadModal.show('二维码', `sampleinfo-${id}-${randomString(6)}`)
    },

    handleSampleTimeChange (val, record) {
      record.sampleBeginTime = val[0]
      record.sampleEndTime = val[1]
      this.initId(record)
    },

    itemChange (value, record) {
      if ((record.items.length && record.items[0][0] !== value[0]) || record.items.length) {
        this.initId(record)
      }
    },

    initSaveSample () {
      const queryParam = { projId: this.projId, siteId: this.siteId, numPeriod: this.numPeriod, numFrequency: this.numFrequency, cateId: this.cateId, page: false }
      return tsSampleinfoApi.list(queryParam).then(res => {
        res.data && res.data.length && res.data.forEach(item => {
          item.editable = false
          item.isSaved = true
        })
        this.sampleTable.dataSource.push.apply(this.sampleTable.dataSource, (res.data || []))
        this.saveSampleList.splice(0, this.saveSampleList.length, ...(res.data || []))
      })
    },

    async initId (record) {
      const siteCount = await this.getSiteCount()
      record.id = this.sampleConfig.split('@').reduce((acc, data) => {
        let type = data
        let configExpand = ''
        if (data.indexOf('#') > -1) {
          const dataArr = data.split('#')
          type = dataArr[0]
          configExpand = dataArr[1]
        }
        const config = this.getConfig(type, record, siteCount, configExpand)
        return acc + config
      }, '')
    },

    getConfig (type, record, siteCount, configExpand) {
      switch (type) {
        case 'projNo':
          return this.projNo
        case 'projXh':
          return this.projInfo.projXh
        case 'projDate':
          configExpand = configExpand || 'YYMMDD'
          configExpand = configExpand.replaceAll('y', 'Y').replaceAll('d', 'D')
          return this.moment(this.projInfo.projDate).format(configExpand)
        case 'siteSerialNo':
          return this.siteNum
        case 'cateSiteSerialNo':
          return this.cateSiteNum
        case 'cateType':
          return this.itemAliasMap[record.items[0][0]]
        case 'numPeriod':
          return this.numPeriod
        case 'numFrequency':
          return this.numFrequency
        case 'variableSampleSerialNo':
          const _length = parseInt(configExpand)
          return _length ? (Array(_length).join('0') + parseInt(record.key)).slice(-_length) : parseInt(record.key)
        case 'sampleSerialNo':
          const length = parseInt(configExpand)
          return length ? (Array(length).join('0') + (parseInt(record.key) + siteCount)).slice(-length) : (parseInt(record.key) + siteCount)
        case 'sampleDate':
          configExpand = configExpand || 'YYMMDD'
          configExpand = configExpand.replaceAll('y', 'Y').replaceAll('d', 'D')
          return record.sampleBeginTime.format(configExpand)
        case 'itemName':
          const itemInfo = record.items[0]
          const itemDefine = this.itemCascader.options[0].children
          const item = itemDefine.find(item => item.id === itemInfo[1])
          return item.name
        case 'itemSampleCode':
          const itemCodeInfo = record.items[0]
          return this.itemCascader.options[0].children?.find(item => item.id === itemCodeInfo[1])?.itemSampleCode
        default:
          return type
      }
    },

    async getSiteCount () {
      if (this.saveSampleList.length) {
        return this.saveSampleList.length || 0
      } else {
        await this.initSaveSample()
        return this.saveSampleList.length || 0
      }
    },

    storageCascaderChange (value, record) {
      record.storage = value.map(item => item[item.length - 1]).join(',')
    },

    handlePreview (file) {
      this.previewImage = file.thumbUrl
      this.previewVisible = true
    },

    handleCancel () {
      this.previewVisible = false
    },

    now () {
      return this.moment(new Date(), this.timeFormat)
    },

    async handleGroupInfo (val) {
      if (this.isGroup) {
        await this.initSaveSample()
        if (!this.saveSampleList.length) {
          val.infoList && val.infoList.forEach(group => {
            this.addSampleInfo(group.itemList.map(item => { return [this.cateId, item.itemId] }), group.containerName, group.name)
          })
        }
      }
    },

    async handleItemInfo (val) {
      if (!this.isGroup) {
        await this.initSaveSample()
        if (!this.saveSampleList.length) {
          val.children && val.children.length && this.addSampleInfo(val.children.map(item => { return [val.id, item.id] }))
        }
      }
    },

    setItemCascader (val) {
      this.itemCascader.options.push(val)
      this.itemCascader2.options.push(val)
    },

    findStorageCascader () {
      resMaterialApi.getMaterialAndDevCascade({}).then(res => {
        if (res.code === 0) {
          this.storageCascader.options = res.data
          this.storageCascader2.options = res.data
        }
      })
    },

    handleFileChange ({ file, fileList }, record) {
      record.photos = fileList
      if (file.status === 'done') {
        if (file.response.code === 0) {
          this.$message.success(`${file.name} 文件上传成功`)
          file.thumbUrl = sysCommonApi.view + file.response.data.fileUrl
          file.url = file.response.data.fileUrl
          fileList.forEach(item => {
            if (item.uid === file.uid) {
              item.thumbUrl = file.thumbUrl
              item.url = file.url
            }
          })
        } else {
          this.$message.error(`文件上传失败: ${file.response.msg}.`)
          file.error = true
        }
      } else if (file.status === 'error') {
        this.$message.error(`文件上传失败: ${file.msg} `)
        file.error = true
      }
    },

    handleVolumeUnitChange (e, record) {
      record.volumeUnit = e
    },

    addSampleInfo (items, storage, groupName) {
      const length = this.sampleTable.dataSource.length
      const sampleInfo = {
        key: length === 0 ? '1' : (parseInt(this.sampleTable.dataSource[length - 1].key || 0) + 1).toString(),
        id: null,
        projId: this.taskInfo.projId,
        projTaskId: this.taskInfo.projTaskId,
        siteId: this.taskInfo.siteId,
        numFrequency: this.numFrequency,
        numPeriod: this.numPeriod,
        items: items || [],
        sampleBeginTime: this.now(),
        sampleEndTime: this.now().add('H', 1),
        num: 1,
        devCateId: '',
        devCateName: '',
        groupName: groupName || '',
        devId: '',
        devName: '',
        storage: storage || '',
        volume: null,
        volumeUnit: null,
        appearanceStatus: null,
        appearanceStatusArr: [],
        validity: 30,
        validityUnit: '天',
        editable: true,
        photos: [],
        isNew: true
      }
      this.sampleTable.dataSource.push(sampleInfo)
      this.$nextTick(() => {
        items && items.length && this.initId(sampleInfo)
      })
    },

    saveSampleRow (record) {
      const { items, sampleBeginTime, sampleEndTime, volume, volumeUnit } = record
      if (!items || !items.length || !sampleBeginTime || !sampleEndTime) {
        this.$message.error('监测项、采集时间不能为空！')
        return false
      }
      if (volume && !volumeUnit) {
        this.$message.error('请选择体积/重量单位')
        return false
      }
      record.editable = false
      record.isNew = false
      const map = this.sampleTable.dataSource.map(item => item.id)
      this.$emit('sampleIds', map)
    },

    toggleSample (key) {
      const target = this.sampleTable.dataSource.filter(item => item.key === key)[0]
      target.editable = !target.editable
    },

    // 移除样品
    removeSample (key) {
      this.sampleTable.dataSource = this.sampleTable.dataSource.filter(item => item.key !== key)
    },

    // 删除已经生成的样品
    deleteSample (id) {
      tsSampleinfoApi.deleteById(id).then(res => {
        if (res.code === 0) {
          this.sampleTable.dataSource = this.sampleTable.dataSource.filter(item => item.id !== id)
          this.$message.success(res.msg)
        } else {
          this.$message.error(` :${res.msg}`)
        }
      })
    },

    sampleCancel (key) {
      const target = this.sampleTable.dataSource.filter(item => item.key === key)[0]
      target.editable = false
    },

    storageFilter (inputValue, path) {
      return (path.some(option => (option.name).indexOf(inputValue) > -1))
    },

    getResult () {
      const result = cloneDeep(this.sampleTable.dataSource)
      // 获取本次添加的样品
      const data = result.filter(item => !item.isSaved)
      data.forEach(item => {
        const tempItems = []
        for (let i = 0; i < item.items.length; i++) {
          tempItems.push({
            cateId: item.items[i][0],
            itemId: item.items[i][1]
          })
        }
        item.items = tempItems
        if (item.photos && item.photos.length) {
          const photos = item.photos.filter(it => !it.error)
          for (let i = 0; i < photos.length; i++) {
            photos[i] = omit(photos[i], ['lastModified', 'lastModifiedDate', 'originFileObj', 'percent', 'response', 'thumbUrl'])
          }
          item.photos = JSON.stringify(photos)
        } else {
          item.photos = null
        }
      })
      // 以及被退回的样品
      const backSample = result.filter(item => item.status === sampleInfoStatusEnum.doneSample)
      return data.concat(backSample)
    }
  }
}
</script>

<style scoped>
.photos {
  color     : gray;
  font-size : 12px;
  padding   : 5px;
}
</style>
