<template>
  <div>
    <a-card :bordered="false" :bodyStyle="{ padding: '0' }">
      <a-row>
        <a-col span="3">
          <div class="ahmux-sub-title" style="width: 100px">付款信息</div>
        </a-col>
      </a-row>
      <a-row>
        <table class="mxtable">
          <tr>
            <td class="tdLabel">收款单位</td>
            <td>{{ purList.sctrName }}</td>
            <td class="tdLabel">开户行</td>
            <td>{{ purList.bankDeposit }}</td>
          </tr>
          <tr>
            <td class="tdLabel">银行账号</td>
            <td>{{ purList.bankAccount }}</td>
            <td class="tdLabel"></td>
            <td></td>
          </tr>
          <tr>
            <td class="tdLabel">合同</td>
            <td colspan="3">
              <FileList :fileList="purList.fileList" />
            </td>
          </tr>
        </table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import FileList from '@/pages/common/file/FileList'

export default {
  name: 'PayInfo',
  components: {
    FileList
  },
  props: {
    purList: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  }
}
</script>
