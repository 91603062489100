<template>
  <div class="log">
    <template v-if="resData.length">
      <a-timeline v-bind="logTimeLine">
        <a-timeline-item v-for="(item,index) in resData" :key="index">
          <div class="item-detail">
            <dl>
              <dt>操作人</dt>
              <dd>{{ item.userRealName }}</dd>
            </dl>
            <dl>
              <dt>操作时间</dt>
              <dd>{{ item.createTime|minuteFilter }}</dd>
            </dl>

            <dl>
              <dt>操作</dt>
              <dd>
                <a-tooltip>
                  <template #title>
                    双击查看详情
                  </template>
                  <div
                    class="flag"
                    :style="{background:sampleInfoLogTypeDataMap[item.operation].color}"
                    @dblclick="detailModal.show(item)"
                  >{{ item.operation }}</div>
                </a-tooltip>
              </dd>
            </dl>
          </div>
        </a-timeline-item>
      </a-timeline>
    </template>

    <template v-else>
      <div style="text-align: center;font-size: 14px; color: rgba(0, 0, 0, 0.45);">
        暂无数据
      </div>
    </template>

    <a-modal v-bind="detailModal" v-if="detailModal.visible" @cancel="detailModal.cancel">
      <log-detail :detail="detailModal.data"></log-detail>
      <template #footer>
        <a-button @click="detailModal.cancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { sysLogApi } from '@/api/log'
import { tranOptionsToMap, tranOptionsToDataMap } from '@/utils/util'

import LogDetail from '@/pages/cloud-quote/sample-info/modules/log/detail'
import { sampleInfoLogTypeOptions, sampleInfoLogTypeEnum } from '@/api/sampleInfo/sampleInfoLogConstant'

const sampleInfoLogTypeMap = tranOptionsToMap(sampleInfoLogTypeOptions)
const sampleInfoLogTypeDataMap = tranOptionsToDataMap(sampleInfoLogTypeOptions)

export default {

  components: { LogDetail },
  props: {
    sampleId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      sampleInfoLogTypeOptions,
      sampleInfoLogTypeEnum,
      sampleInfoLogTypeMap,
      sampleInfoLogTypeDataMap,

      resData: [],

      detailModal: {
        title: '修改详情',
        visible: false,
        data: null,
        width: 600,
        show: (data) => {
          this.detailModal.data = data
          this.detailModal.visible = true
        },
        cancel: (data) => {
          this.detailModal.visible = false
        }
      },

      logTimeLine: {
        mode: 'left',
        reverse: false
      }
    }
  },

  watch: {
    sampleId: {
      immediate: true,
      handler (val) {
        this.querySysLog(val)
      }

    }
  },

  methods: {
    querySysLog (sampleId = '') {
      const defaultParam = { tableName: 'ts_sample_info' }
      sysLogApi.details(Object.assign(defaultParam, { pk: sampleId })).then(res => {
        if (res.code === 0) {
          this.resData.splice(0, this.resData.length, ...(res.data || []))
        } else {
          this.$message.error(`获取系统日志出错，错误信息：${res.msg}`)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  @import "_index.less";
</style>
