const noActionCurveTypeColumns = [
  // { title: 'ID', dataIndex: 'id', width: 60, scopedSlots: { customRender: 'id' } },
  { title: '曲线组编号', dataIndex: 'curveNo', width: 150, sorter: true, scopedSlots: { customRender: 'id' } },
  { title: '配置人', dataIndex: 'createUserName', width: 100, sorter: true, scopedSlots: { customRender: 'createUserName' } },
  { title: '配置时间', dataIndex: 'makeDate', width: 150, sorter: true, scopedSlots: { customRender: 'makeDate' } },
  { title: '有效截止日', dataIndex: 'validTime', width: 100, sorter: true, scopedSlots: { customRender: 'validTime' } },
  { title: '复核人', dataIndex: 'reviewUserName', width: 100, scopedSlots: { customRender: 'reviewUserName' } },
  { title: '复核结果', dataIndex: 'reviewStatus', width: 100, scopedSlots: { customRender: 'reviewStatus' } },
  { title: '审核人', dataIndex: 'checkUserName', width: 100, scopedSlots: { customRender: 'checkUserName' } },
  { title: '审核结果', dataIndex: 'checkStatus', width: 100, scopedSlots: { customRender: 'checkStatus' } }
]

const noActionCurveListColumns = [
  // { title: 'ID', dataIndex: 'id', width: 60, scopedSlots: { customRender: 'id' } },
  { title: '曲线组编号', dataIndex: 'curveNo', width: 150, sorter: true, scopedSlots: { customRender: 'id' } },
  { title: '标准编号', dataIndex: 'standardCode', width: 150, sorter: true, scopedSlots: { customRender: 'standardCode' } },
  { title: '方法名称', dataIndex: 'methodName', width: 150, sorter: true, scopedSlots: { customRender: 'methodName' } },
  { title: '标准物质名称', dataIndex: 'solutionName', width: 150, scopedSlots: { customRender: 'solutionName' } },
  { title: '参比溶液名称', dataIndex: 'solventName', width: 150, scopedSlots: { customRender: 'solventName' } },
  { title: '设备编号', dataIndex: 'devNo', width: 150, sorter: true },
  { title: '配置人', dataIndex: 'createUserName', width: 90, scopedSlots: { customRender: 'createUserName' } },
  { title: '配置时间', dataIndex: 'makeDate', width: 150, sorter: true, scopedSlots: { customRender: 'makeDate' } },
  { title: '有效截止日', dataIndex: 'validTime', width: 110, scopedSlots: { customRender: 'validTime' } },
  { title: '复核人', dataIndex: 'reviewUserName', width: 90, scopedSlots: { customRender: 'reviewUserName' } },
  { title: '复核结果', dataIndex: 'reviewStatus', width: 80, sorter: true, scopedSlots: { customRender: 'reviewStatus' } },
  { title: '审核人', dataIndex: 'checkUserName', width: 90, scopedSlots: { customRender: 'checkUserName' } },
  { title: '审核结果', dataIndex: 'checkStatus', width: 80, sorter: true, scopedSlots: { customRender: 'checkStatus' } }
]

const defaultCurveTypeColumns = [...noActionCurveTypeColumns,
  { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } }
]
const defaultCurveListColumns = [...noActionCurveListColumns,
  { title: '操作', dataIndex: 'action', width: 200, scopedSlots: { customRender: 'action' } }
]

export default defaultCurveTypeColumns
export { noActionCurveTypeColumns, defaultCurveListColumns }
