<template>
  <div class="check-task-offline-form">
    <a-alert
      v-if="sampleStat&&
        (sampleStat.tempSampleNum||sampleStat.doneSampleNum||sampleStat.storageApplyNum||sampleStat.storagedNum||sampleStat.pickupApplyNum)"
      :style="{marginBottom:'12px'}"
      banner
      closable>

      <template #message>

        样品信息：当前还有
        <template v-if="sampleStat.tempSampleNum">
          <span class="red focus-num">{{ sampleStat.tempSampleNum }} </span>个样品待采样；
        </template>

        <template v-if="sampleStat.doneSampleNum">
          <span class="red focus-num">{{ sampleStat.doneSampleNum }}</span> 个样品待交样；
        </template>

        <template v-if="sampleStat.storageApplyNum">
          <span class="red focus-num">{{ sampleStat.storageApplyNum }} </span>个样品待入库；
        </template>

        <template v-if="sampleStat.storagedNum">
          <span class="red focus-num">{{ sampleStat.storagedNum }} </span>个样品未检测；
        </template>

        <template v-if="sampleStat.pickupApplyNum">
          <span class="red focus-num">{{ sampleStat.pickupApplyNum }} </span>个样品待出库；
        </template>

      </template>
    </a-alert>

    <a-tabs v-model="currentCateId" v-bind="tabsProps" @change="handleCateTabChange" @tabClick="handleCateTabClick">
      <a-tab-pane v-for="(cate) of formData.data" :key="cate.cateId" :closable="false" :tab="cate.cateName" style="height: 100%">
        <a-tabs v-model="currentTemplateFile" v-bind="subTabsProps" @change="handleTemplateTabChange" @tabClick="handleTemplateTabClick" v-if="currentCateId!='0'">
          <a-tab-pane v-for="(templateFile) of templateFileList" :key="`${templateFile.sourceFile}`" :closable="false" style="height: 100%">
            <a-checkbox slot="tab" :checked="templateFile.sourceFile === cate.templateFile">
              {{ templateFile.sourceFile }}
            </a-checkbox>
          </a-tab-pane>
        </a-tabs>
        <a-row style="height:100%;padding-bottom: 50px;">
          <iframe v-bind="iframeProps" @load="handleLoadIframe"/>
        </a-row>

      </a-tab-pane>
    </a-tabs>
    <a-card style="margin-top: 14px;" title="附件" type="inner">
      <j-upload v-model="formData.file"/>
    </a-card>
  </div>
</template>

<script>
import JUpload from '@/components/j/JUpload'

import { fileTemplateReportApi, fileTemplateUreportApi } from '@/api/fileTemplate'
import { projTaskItemApi } from '@/api/project'
import { checkTaskOfflineApiApi } from '@/api/check/checkTaskOfflineApi'
import { tsSampleinfoApi } from '@/api/sample'

import { fileTemplateReportTypeEnum } from '@/api/ureport/fileTemplateUreportConstant'
import { checkTaskOfflineStatusEnum } from '@/api/check/checkTaskOfflineConstant'
import { sampleInfoTypeEnum } from '@/api/sampleInfo/sampleInfoConstant'

export default {
  components: { JUpload },

  props: ['id', 'projId', 'type'],
  data () {
    return {
      // 检测分类集合
      cateList: [],
      // 当前选中的检测分类
      currentCateId: null,

      // 检测结果文件集合
      templateFileList: [],
      // 当前检测结果文件
      currentTemplateFile: null,

      // 样品统计信息
      sampleStat: null,

      tableNum: 3,

      formData: {
        id: null,
        projId: this.projId,
        // 报告模板ID
        reportTemplateId: null,
        // 报告模板关联ID
        reportTemplateRefId: null,
        file: null,
        data: {}
      },

      tabsProps: {
        size: 'small',
        style: { height: '80%', padding: '0', margin: '0', overflow: 'hidden', background: '#FFFFFF' },
        tabPosition: 'left',
        type: 'line'
      },

      subTabsProps: {
        size: 'small',
        style: { padding: '0', margin: '0', overflow: 'hidden' },
        type: 'line'
      },
      devMethod: { '0': { 'cateId': '0', 'cateName': '方法及仪器', 'templateFile': 'DB:R02检测报告_设备方法.ureport.xml', 'content': null } }
    }
  },

  computed: {
    iframeProps () {
      const templateFile = this.currentTemplateFile
      const cateId = this.currentCateId
      const url = this.type !== 'edit' ? fileTemplateUreportApi.preview : fileTemplateUreportApi.form
      const result = { ref: 'form', src: null, style: { width: '100%', height: '100%', margin: '0 auto', border: '0' } }
      if (templateFile && cateId) {
        const index = Object.values(this.formData.data).findIndex(it => it.cateId === cateId)
        result.src = `${url}?_u=${templateFile}&&projId=${this.projId}&templateId=${this.reportTemplateId}&templateFile=${templateFile}&cateId=${cateId}&tableNum=${this.tableNum}&tableSubNum=${index}`
      }
      return result
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue) {
          this.getDataById(newValue)
        } else {
          this.findReportTempleInfo()
        }
      }
    },
    projId: {
      immediate: true,
      handler (newValue, oldValue) {
        newValue && this.listCateItem(newValue)
        newValue && this.loadSampleInfoStat(newValue)
      }
    },

    'formData.reportTemplateId': {
      immediate: true,
      handler (newValue, oloValue) {
        newValue && this.listFileTemplate(newValue)
      }
    },

    'formData.reportTemplateRefId': {
      immediate: true,
      handler (newValue, oloValue) {
        newValue && this.checkResultFileData(newValue)
      }
    }
  },

  methods: {

    // 根据ID 获取数据
    getDataById (id) {
      checkTaskOfflineApiApi.findBydId(id).then(res => {
        if (res.code === 0) {
          const formKeys = Object.keys(this.formData)
          const newData = Object.keys(res.data || {}).reduce((acc, key) => {
            formKeys.includes(key) && (acc[key] = res.data[key])
            return acc
          }, {})
          Object.assign(this.formData, { data: {} }, newData)
           res.data?.content && this.$set(this.formData, 'data', JSON.parse(res.data?.content))
           this.currentTemplateFile = Object.values(this.formData.data)?.[0]?.templateFile
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 获取报告模板信息
    findReportTempleInfo () {
      fileTemplateReportApi.list({ useStatus: '1', page: false }).then(result => {
        if (result.code === 0) {
          if (result.data?.length) {
            this.formData.reportTemplateId = result.data[0].id
          } else {
            this.$message.error('暂未配置该报告模板，请先前往“报告文件模板”界面进行配置')
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },

    // 加载报告模板列表数据
    listFileTemplate (refId) {
      fileTemplateReportApi.subList({ page: false, rid: refId, templateType: fileTemplateReportTypeEnum.checkResult }).then(result => {
        if (result.code === 0) {
          const data = result.data?.[0] ?? {}
          this.formData.reportTemplateRefId = data.id
        } else {
          this.$message.error(result.msg)
        }
      })
    },

    // 加载检测结果模板列表数据
    checkResultFileData (refId) {
      fileTemplateReportApi.checkResultList({ page: false, rid: refId }).then(res => {
        if (res.code === 0) {
          if (res.data?.length) {
            this.templateFileList.splice(0, this.templateFileList.length, ...res.data)
            this.currentTemplateFile = this.currentTemplateFile ?? this.templateFileList[0].sourceFile
            // eslint-disable-next-line no-unused-expressions
            Object.values(this.formData.data)?.forEach(item => {
              // !item.templateFile && (item.templateFile = this.templateFileList[0].sourceFile)
              item.cateId !== '0' && (item.templateFile = this.templateFileList[0].sourceFile)
            })
          } else {
            this.$message.error('暂未配置该报告模板，请先前往“报告文件模板”界面进行配置')
          }
        }
      })
    },

    // 获取对应项目的检测项信息
    listCateItem (projId) {
      projTaskItemApi.listCateItem({ projId: projId }).then(res => {
        if (res.code === 0) {
          const data = res.data?.reduce((acc, item) => {
            const { cateId, cateName } = item
            acc[item.cateId] = { cateId, cateName, templateFile: this.currentTemplateFile, content: null }
            return acc
          }, {})
          if (!Object.keys(this.formData.data).length) {
            this.formData.data = Object.assign({}, data, this.devMethod, this.formData.data)
          }
          this.currentCateId = Object.keys(this.formData.data)?.[0]
          this.currentTemplateFile = Object.values(this.formData.data)?.[0]?.templateFile
          // this.currentCateId = res.data?.[0]?.cateId
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 获取样品统计信息
    loadSampleInfoStat (projId) {
      tsSampleinfoApi.projectList({ page: false, neType: sampleInfoTypeEnum.virtual, projId }).then(res => {
        if (res.code === 0) {
          const data = res.data?.[0]
          this.sampleStat = data
        } else {
          this.$message.error(`获取样品统计信息出错：${res.msg}`)
        }
      })
    },

    // 响应检测分类tab 点击事件
    handleCateTabClick () {
      const cate = Object.values(this.formData.data).filter(it => it.cateId === this.currentCateId)[0]
      const data = this.getData()
      cate.content = data
    },

    // 响应检测分类tab 改变事件
    handleCateTabChange () {
      const cate = Object.values(this.formData.data).filter(it => it.cateId === this.currentCateId)[0]
      this.currentTemplateFile = cate?.templateFile
    },

    // 响应模板文件tab 点击事件
    handleTemplateTabClick () {
      const cate = Object.values(this.formData.data).filter(it => it.cateId === this.currentCateId)[0]
      const data = this.getData()
      cate.content = data
    },

    // 响应模板文件tab 改变事件
    handleTemplateTabChange () {
      const cate = Object.values(this.formData.data).filter(it => it.cateId === this.currentCateId)[0]
      cate.templateFile = this.currentTemplateFile
    },

    // 获取原始记录表数据
    getData () {
      return this.$refs[this.iframeProps?.ref]?.contentWindow?.getData()
    },

    // 处理iframe 加载完成事件
    handleLoadIframe () {
      this.$refs[this.iframeProps.ref].contentWindow.getData = function () {
        const $ = this.jQuery
        const _self = this
        // 1.查找所有attr-name 属性的单元格，按照group-name属性以每行视为一条数据进行分组；
        // 没有分组属性的数据自动划分为 __noDef 组，且只有一条数据，所以此处会有覆盖问题，设计上应当避免此问题
        const group = { __noDef: [{}] }
        $('table tr').each(function () {
          const tds = $(this).children('td')
          const _groupMap = {}
          for (let i = 0; i < tds.length; i++) {
            const td = $(tds[i])
            const attrName = td.attr('attr-name')
            const groupName = td.attr('group-name')
            if (attrName) {
              if (groupName) {
                const groupDataList = (_groupMap[groupName] || (_groupMap[groupName] = [{}]))
                const firstData = groupDataList[0]
                if (firstData[attrName] !== undefined) {
                  groupDataList.push(Object.assign({}, { ...firstData }, { [attrName]: _self.getCellValue(td) }))
                } else {
                  groupDataList.forEach((item) => {
                    item[attrName] = _self.getCellValue(td)
                  })
                }
              } else {
                group.__noDef[0][attrName] = _self.getCellValue(td)
              }
            }
          }
          Object.keys(_groupMap).forEach(key => {
            const value = _groupMap[key]
            const result = (group[key] || (group[key] = []))
            if (value instanceof Array) {
              result.push.apply(result, _groupMap[key])
            } else {
              result.push(_groupMap[key])
            }
          })
        })

        // 2. 数据合并：如果两行数据属性完全不相同，这两行应当进行合并成为一条数据，考虑到性能问题，此处采用半数投票方式
        const result = {}
        Object.keys(group).forEach((key) => {
          if (key === '__noDef') {
            result['__noDef'] = group.__noDef
          }
          const groupValue = group[key]
          const groupLength = groupValue.length
          if (groupLength > 1) {
            // 此处需要考虑数据跨行进行合并的问题，跨行合并的来源主要有两个，一个是在表头，一个是列表中的数据存在合并行的问题导致的
            // 将所有的数据取出，字段个数相同的数据应当是相同行或者具有相同合并条件的数据，字段最小的视为表头
            // 按照数据字段的长度进行分组，由于数据合并后的数据即元数据为整数，则 以下应成立：
            // 最小的长度 能被其他的长度能够整除，并且其他行都可以整除最小长度的 --是否启用合并的依据
            // 同时数据合并的时候，当前数据缺少的属性从上一行进行获取，当前数据与上一行数据之间的索引相差表头长度  -- 合并数据时的依据
            // 特别注意： 此方法依据当前模板的特殊性，因而不适用其他方法，因根据具体情况进行分析

            // 按照字段长度分组的MAP
            const groupValueLengthMap = groupValue.reduce((acc, item, _idx) => {
              const itemKeyLength = Object.keys(item).length
              item.index = _idx;
              (acc[itemKeyLength] || (acc[itemKeyLength] = [])).push(item)
              return acc
            }, {})
            const groupValueLengthMapKeys = Object.keys(groupValueLengthMap).sort()

            // 表头信息
            const tableHeaderKey = groupValueLengthMapKeys[0]
            const tableHeaderValue = groupValueLengthMap[tableHeaderKey]
            const tableHeaderLength = tableHeaderValue.length

            // 其他字段的数据长度能否整除表头
            const canDivide = groupValueLengthMapKeys.reduce((acc, item) => {
              acc = acc && (groupValueLengthMap[item].length % tableHeaderLength === 0)
              return acc
            }, true)

            if (canDivide) {
              result[key] = []
              // 获取数据在其他key中的对应数据
              const getOtherKeyData = function (index) {
                let nextIndex = index - tableHeaderLength
                const _result = {}
                while (nextIndex > -1) {
                  const preData = groupValue.find(item => item.index === nextIndex)
                  const preDataKeys = Object.keys(preData)
                  const _resultKeys = Object.keys(_result) || []
                  preDataKeys.forEach(item => {
                    if (!_resultKeys.includes(item)) {
                      _result[item] = preData[item]
                    }
                  })
                  nextIndex = nextIndex - tableHeaderLength
                }
                return _result
              }

              // 合并数据 表头不参与
              groupValueLengthMapKeys.forEach(_key => {
                if (_key !== tableHeaderKey) {
                  const groupData = groupValueLengthMap[_key]
                  groupData.forEach((data, _index) => {
                    const otherData = getOtherKeyData(data.index)
                    result[key].push(Object.assign(otherData, data))
                  })
                }
              })
              result[key].sort(item => item.index)
            } else {
              result[key] = [groupValue[0]]
              for (let i = 1; i < groupValue.length; i++) {
                const _lastValue = result[key][result[key].length - 1]
                // 是否可以合并
                const _isMerge = _self.isMerge(_lastValue, groupValue[i])
                if (_isMerge) {
                  Object.assign(_lastValue, groupValue[i])
                } else {
                  result[key].push(groupValue[i])
                }
              }
            }
          } else {
            result[key] = groupValue
          }
        })
        return result
      }
    },

    save () {
      // 最后一个界面手触发保存数据
      this.handleCateTabClick()
      setTimeout(() => {
        const formData = { ...this.formData }
        formData.content = JSON.stringify(formData.data)
        const saveAllBool = Object.values(formData.data).reduce((acc, item) => acc && (!item.content), false)
        const sampleStat = this.sampleStat
        const sampleNotSaveBool = sampleStat.tempSampleNum || sampleStat.doneSampleNum || sampleStat.storageApplyNum || sampleStat.storagedNum || sampleStat.pickupApplyNum
        formData.status = !saveAllBool || sampleNotSaveBool ? checkTaskOfflineStatusEnum.part : checkTaskOfflineStatusEnum.done
        delete formData.data
        // return false
        checkTaskOfflineApiApi.save(formData).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
          }
        })
      }, 0)
    }

  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/common/snippet';

.check-task-offline-form {
  height: 100%;
  .color();
  .feature();

  /deep/ .ant-tabs-content {
    height: 100%;
  }
}
</style>
