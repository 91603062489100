<template>
  <a-modal
    :title="title"
    :width="960"
    :visible="visible"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="当前节点" :labelCol="labelCol" :wrapperCol="wrapperCol" >
        <b style="color: #1890ff" v-if="activityTask">{{activityTask.name}}</b>
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" >
        <span slot="label" class=" ant-form-item-required">用户</span>
        <select-user-by-dept style="width: 88px;float: left;margin-right: 10px;" :disabled="false" :triggerChange="true" @change="handleUserChange" />
        <b>{{sysUserStr}}</b>
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" >
        <span slot="label" class=" ant-form-item-required">意见/原因</span>
        <a-textarea placeholder="请输入意见/原因" rows="2" style="height: 80px;" v-model="agreeReason"/>
        <span v-if="type == 2" style="color: red">提示：流转/委派是将任务节点分给其他人处理，等其他人处理好之后，委派任务会自动回到委派人的任务中</span>
        <span v-else-if="type == 3" style="color: red">提示：转办是将任务节点转交给其他人处理，其他人处理好之后，流程进入下一个节点</span>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { postAction } from '@/api/common'
import { flowApi } from '@/api/flow'
import SelectUserByDept from '@/pages/common/SelectUserByDept'
export default {
  name: 'CarbonCopy',
  components: { SelectUserByDept },
  // prop: {
  //   processInstanceId: {
  //     type: String,
  //     default: ''
  //   }
  // },
  data () {
    return {
      title: '抄送',
      visible: false,
      processInstanceId: null,
      sysUserIds: [],
      sysUserStr: '',
      activityTask: null,
      agreeReason: '',
      api: '',
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      type: '1'
    }
  },
  methods: {
    handleUserChange (userList) {
      if (this.type === 2) {
        if (userList.length > 1) {
          this.$message.error(`请选择唯一用户!`)
          return
        }
        const username = this.$store.getters.nickname
        if (userList.length === 1 && username === userList[0].username) {
          this.$message.error(`请选择除本人外其他用户!`)
          return
        }
      }
      this.sysUserIds = userList.map(it => it.username)
      this.sysUserStr = this.sysUserIds.join('，')
    },
    edit (record, activityTask, value) {
      this.visible = true
      this.processInstanceId = record.processInstanceId
      this.activityTask = activityTask
      this.type = value
      if (value === 1) {
        this.title = '抄送'
        this.api = flowApi.carbonCopy
      } else if (value === 3) {
        this.title = activityTask.name + '转办'
        this.api = flowApi.reassign
      } else {
        this.title = activityTask.name + '流转'
        this.api = flowApi.delegate
      }
      // this.sysUserIds = []
      // this.sysUserStr = ''
      // this.agreeReason = ''
    },
    handleCancel () {
      this.visible = false
    },
    handleSubmit () {
      if (this.sysUserIds.length === 0) {
        this.$message.error('请选择系统用户')
        return
      }
      if (this.agreeReason === '' || this.agreeReason.length < 1) {
        this.$message.error('请填写' + this.title + '意见/原因')
        return
      }
      const formData = {}
      formData.processInstanceId = this.processInstanceId
      formData.userIds = this.sysUserIds.join(',')
      formData.reason = this.agreeReason
      formData.type = this.title
      if (this.type === 2 || this.type === 3) {
        formData.taskId = this.activityTask.id
      }
      postAction(this.api, formData).then(result => {
      // flowApi.carbonCopy(formData).then(result => {
        if (result.code === 0) {
          this.$message.success(`${this.title}成功`)
          this.sysUserIds = []
          this.sysUserStr = ''
          this.agreeReason = ''
          this.handleCancel()
          this.$emit('ok', result.data)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(error => {
        this.$message.error(`服务器发生错误！错误消息为${error}`)
      })
    }
  }
}
</script>
