'use strict'

/**
 * 检测原始记录表 相关API
 */

import { getAction, postAction, postDataAction } from '@/api/common'

const checkOriginalRecordPre = '/ms-api/check-original-record'

export const checkOriginalRecordApi = {
  list: (param) => getAction(`${checkOriginalRecordPre}/list`, param),
  save: (data) => postDataAction(`${checkOriginalRecordPre}/save`, JSON.stringify(data)),
  findTemplateInfo: (param) => getAction(`${checkOriginalRecordPre}/find-template-info`, param),
  findDetail: (id) => getAction(`${checkOriginalRecordPre}/findById/${id}`),
  claim: (data) => postDataAction(`${checkOriginalRecordPre}/claim`, data),
  assign: (data) => postDataAction(`${checkOriginalRecordPre}/assign`, data),
  review: (data) => postAction(`${checkOriginalRecordPre}/review`, data),
  check: (data) => postAction(`${checkOriginalRecordPre}/check`, data)
}
