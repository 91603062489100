import { scheduleResourceApi } from '@/api/sample'

export const customFunc = {
  _self: this,

  getItemData: function (resolve) {
    resolve(this._self.items ? [...this._self.items] : [])
  },

  initSampleAddress: function (model) {
    model.dataModel = this._self.siteName
  },

  pictureChange (data) {
    switch (Object.prototype.toString.call(data)) {
      case '[object Array]':
        data.forEach(item => {
          delete item.lastModified
          delete item.lastModifiedDate
          delete item.percent
          delete item.status
        })
        break
      case '[object Object]':
        delete data.lastModified
        delete data.lastModifiedDate
        delete data.percent
        delete data.status
        break
    }
  },

  getDevices: function (resolve, args) {
    const queryParam = { projId: (this._self.saveData && this._self.saveData.projId) || (this._self.models && this._self.models.projId), type: args[0], page: false }
    scheduleResourceApi.list(queryParam).then(res => {
      if (res.code === 0) {
        resolve(res.data)
      } else {
        resolve([])
        console.log(`获取设备资源错误，错误信息${res.msg}`)
      }
    })
  },

  getDictCode: function (resolve, args) {
    if (!this._self.$store.state.code.cache[args[0]]) {
      this._self.$store.dispatch('code/fetch', args[0]).then(data => {
        resolve(this.generateDictCode(data))
      })
    } else {
      resolve(this.generateDictCode(this._self.$store.state.code.cache[args[0]]))
    }
  },

  asyncGetDictCode: function (func, args) {
    if (!this._self.$store.state.code.cache[args[0]]) {
      return this._self.$store.dispatch('code/fetch', args[0]).then(data => {
        func(this.generateDictCode(data))
      })
    } else {
      return new Promise((resolve) => {
        resolve(func(this.generateDictCode(this._self.$store.state.code.cache[args[0]])))
      })
    }
  },

  generateDictCode: (data) => {
    return data ? Object.keys(data).map(key => { return { value: key, label: data[key] } }) : []
  }
}

export const customWathcer = {

  'models.sampleInfos': {
    deep: true,
    handler (data) {
      if (data && data.length) {
        data.forEach(item => {
          if (item.riverWidth && item.riverDeep && item.velocity) {
            item.flow = item.riverWidth * item.riverDeep * item.velocity
          }
        })
      }
    }
  },

  'models.phCalibrationTemperature': {
    handler (data) {
      if (data) {
        const res = {}
        if (data <= 12) {
          res.ph1 = 4.00
          res.ph2 = 6.92
          res.ph3 = 9.33
        } else if (data <= 17) {
          res.ph1 = 4.00
          res.ph2 = 6.90
          res.ph3 = 9.28
        } else if (data <= 22) {
          res.ph1 = 4.00
          res.ph2 = 6.88
          res.ph3 = 9.23
        } else if (data <= 27) {
          res.ph1 = 4.00
          res.ph2 = 6.86
          res.ph3 = 9.18
        } else if (data <= 32) {
          res.ph1 = 4.01
          res.ph2 = 6.85
          res.ph3 = 9.14
        } else if (data <= 37) {
          res.ph1 = 4.02
          res.ph2 = 6.84
          res.ph3 = 9.10
        } else if (data <= 42) {
          res.ph1 = 4.03
          res.ph2 = 6.84
          res.ph3 = 9.07
        } else if (data <= 47) {
          res.ph1 = 4.04
          res.ph2 = 6.83
          res.ph3 = 9.04
        } else {
          res.ph1 = 4.06
          res.ph2 = 6.83
          res.ph3 = 9.02
        }

        if (this.models.hasOwnProperty('ph1') && this.models.hasOwnProperty('ph2') && this.models.hasOwnProperty('ph3')) {
          Object.assign(this.models, res)
        }
      }
    }
  }
}

export default {
  customFunc,
  customWathcer
}
