<template>
  <a-card title="方案报价">
    <div class="detail-list">
      <a-row class="row" v-bind="listRow">
        <a-col v-bind="listCol">
          <div class="label">报价单号：</div>
          <div class="content">
            {{ nullReplace(info.quoteSn) }}
            <a v-action:quote:preview @click="downloadModal.show(info)" title="查看报价单"><a-icon type="eye" theme="twoTone"/></a>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">方案名称：</div>
          <div class="content">
            <a-tag v-if="info.quoteType=='BJ'"color="pink">自行检测 </a-tag>
            <a-tag v-else-if="info.quoteType=='YD'" color="red">月度单</a-tag>
            <a-tag v-else-if="info.quoteType=='JD'" color="orange">季度单</a-tag>
            <a-tag v-else-if="info.quoteType=='BN'" color="cyan">半年度</a-tag>
            <a-tag v-else-if="info.quoteType=='ND'" color="blue">年度单</a-tag>
            <a-tag v-else color="red">{{ info.quoteType }}</a-tag>
            <a-tag v-if="info.quoteType!='BJ'&&info.quoteMonth" color="#2db7f5">{{ info.quoteMonth }}月</a-tag>
            <a @click="planQuoteView(info)">{{ info.projectTitle }}</a>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">最终金额：</div>
          <div class="content">
            <span style="color: red">{{ currency(info.quoteFinalAmount) }}</span>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">负责人：</div>
          <div class="content">{{ info.quoteContactsName }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">创建日期：</div>
          <div class="content">{{ info.createTime }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">更新日期：</div>
          <div class="content">{{ info.updateTime }}</div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">状态：</div>
          <div class="content">
            <a-tag v-if="info.status=='0'" color="orange">完善中</a-tag>
            <a-tag v-if="info.status=='6'" color="red">报价中</a-tag>
            <a-tag v-if="info.status=='1'" color="green" @click="gotoQuoteCheckFlowDetail(info)">赢单</a-tag>
            <a-tag v-if="info.status=='2'" color="pink" @click="gotoQuoteCheckFlowDetail(info)">提交审核</a-tag>
            <a-tag v-if="info.status=='3'" color="cyan" @click="gotoQuoteCheckFlowDetail(info)">方案审核</a-tag>
            <a-tag v-if="info.status=='4'" color="blue" @click="gotoQuoteCheckFlowDetail(info)">部门审核</a-tag>
            <a-tag v-if="info.status=='5'" color="purple" @click="gotoQuoteCheckFlowDetail(info)">经理审核</a-tag>
            <a-tag v-if="info.status=='-2'" color="red">已废弃</a-tag>
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">项目所在地：</div>
          <div class="content">
            {{ info.projectAddress | nullReplace }}
          </div>
        </a-col>
        <a-col v-bind="listCol">
          <div class="label">方案简介：</div>
          <div class="content">
            {{ info.projectDesc | nullReplace }}
          </div>
        </a-col>
        <a-col :span="24">
          <div class="label">项目文件：</div>
          <div class="content"><FileList :fileList="info.fileList"/></div>
        </a-col>
      </a-row>
    </div>
    <!-- actions -->
    <!--    <template slot="extra">-->
    <!--      <a-button-group style="margin-right: 4px;">-->
    <!--        <a-button>操作</a-button>-->
    <!--        <a-button>操作</a-button>-->
    <!--        <a-button><a-icon type="ellipsis"/></a-button>-->
    <!--      </a-button-group>-->
    <!--      <a-button type="primary" >主操作</a-button>-->
    <!--    </template>-->

    <!-- 报价下载预览 -->
    <a-modal v-bind="downloadModal" v-on="downloadModal">
      <QuoteDownloadPreview v-bind="downloadModal.data" v-on="downloadModal.data"></QuoteDownloadPreview>
      <template #footer>
        <div style="text-align: center">
          <a-button @click="downloadModal.cancel()">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </a-card>
</template>

<script>

import { anonProjectApi, projectApi, projectFlowKey } from '@/api/project'
import { quoteApi } from '@/api/quote'
import QuoteDownloadPreview from '@/pages/cloud-quote/bj-plan-quote/modules/QuoteDownloadPreview'
import FileList from '@/pages/common/file/FileList'
import { currency } from '@/utils/util'
import fullModal from '@/pages/common/mixins/modal/full'

const fullModalData = fullModal.data().fullModal

export default {
  name: 'PVQuoteView',
  components: {
    QuoteDownloadPreview, FileList
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      listRow: {
        gutter: [8, 12]
      },
      listCol: {
        xxl: { span: 8 },
        xl: { span: 8 },
        lg: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
        xs: { span: 8 }
      },
      itemCol: 3,
      info: {},

      downloadModal: Object.assign({}, fullModalData, {
        title: '报价下载',
        data: {
          params: {
            quoteId: null,
            projId: null,
            fileName: null
          }
        },
        show: (r) => {
          var params = { quoteId: r.id, projId: r.projId, fileName: `${r.quoteSn}-${r.projectTitle}` }
          this.downloadModal.data.params = params
          this.downloadModal.visible = true
        },
        cancel: () => {
          this.downloadModal.visible = false
        }
      })
    }
  },
  methods: {
    currency,
    getQuote () {
      if (this.id) {
        quoteApi.getQuote({ id: this.id }).then(result => {
          if (result.code === 0) {
            this.info = result.data || {}
          }
        })
      }
    },
    gotoQuoteCheckFlowDetail (r) {
      projectApi.listProcess({ key: projectFlowKey.quoteCheckFlow, mxId: r.id }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            var obj = res.data[0]
            this.$router.push({ name: 'flow-process-detail', params: { processId: obj.processInstanceId } })
          } else {
            this.$message.warn(`未找到相关流程`)
          }
        }
      })
    },
    planQuoteView (r) {
      this.$router.push({ name: 'plan-quote-view-mgr', query: { quoteId: r.id } })
    },
    nullReplace (val) {
      if (val) {
        return val
      }
      return '-'
    }
  },
  watch: {
    id: {
      handler (nv, ov) {
        this.getQuote()
      },
      immediate: true
    },
    data: {
      handler (nv, ov) {
        this.info = nv
      },
      immediate: true
    }
  }
}
</script>

<style lang="less">
  .detail-list{
    margin: 8px 44px;
    .row{
    }
    .label{
      color: rgba(0, 0, 0, 0.85);
      display: table-cell;
      line-height: 20px;
      margin-right: 8px;
      padding-bottom: 10px;
      white-space: nowrap;
    }
    .content{
      color: rgba(0, 0, 0, 0.65);
      display: table-cell;
      min-height: 22px;
      line-height: 22px;
      padding-bottom: 10px;
      width: 100%;
    }
  }
</style>
