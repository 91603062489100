<template>
  <a-card title="报告编制费用" style="margin: 16px auto">
    <b style="font-size: 18px; color: #ff0000" slot="extra">小计：{{ amount.compileTotalAmount }} 元</b>
    <a-table bordered class="standardTable" :columns="columns" :dataSource="dataSource" :pagination="false" size="small">
      <template v-for="(col, index) in columns" v-if="col.scopedSlots" :slot="col.dataIndex" slot-scope="text, record">
        <template v-if="record.editable">
          <!--选择类别-->
          <a-input
            :style="col.dataIndex === 'dicName' ? 'width: 70%' : 'width: 100%'"
            :key="col.dataIndex"
            :value="text"
            :placeholder="col.title"
            @change="(e) => handleChange(e.target.value, record.key, col, record)"
          />
          <a-dropdown :key="col.dataIndex + index" v-if="col.dataIndex === 'dicName'">
            <a class="ant-dropdown-link"> 类别 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item v-for="(vo, j) in dataCosts" :key="j">
                <a @click="dicSelect(vo, record)">{{ vo.dicName }} (基数: {{ vo.dicRadix }}, 份数: {{ vo.dicCoefficient }})</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <!--具体编辑框-->
        </template>
        <template v-else>{{ text }}</template>
      </template>
      <template slot="action" v-if="editable" slot-scope="text, record, idx">
        <template v-if="record.editable">
          <span ref="editing" :idx="idx"></span>
          <a @click="saveRow(record, idx)">保存</a>
        </template>
        <template v-else><a @click="editRow(record, idx)">编辑</a></template>
        <a-divider type="vertical" />
        <a-popconfirm title="是否要作废此行？" @confirm="delRow(record, idx)">
          <a>作废</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-button v-if="editable" style="width: 100%; margin-top: 5px; margin-bottom: 8px" type="dashed" icon="plus" @click="addItemData">新增</a-button>
  </a-card>
</template>

<script>
import { Get, Post, Del, quoteCompileCostsApi, dicApi } from '@/api/quote'

export default {
  name: 'QuoteCompileCosts',
  props: {
    quoteInfo: {
      type: Object,
      default: () => ({})
    },
    amount: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columns: [
        { title: '类别', dataIndex: 'dicName', width: 160, scopedSlots: { customRender: 'dicName' } },
        { title: '计费基数内容', dataIndex: 'dicDesc', width: 250, scopedSlots: { customRender: 'dicDesc' } },
        { title: '基数', dataIndex: 'dicRadix', width: 90, scopedSlots: { customRender: 'dicRadix' }, className: 'column-center' },
        { title: '份数', dataIndex: 'dicCoefficient', width: 60, scopedSlots: { customRender: 'dicCoefficient' }, className: 'column-center' },
        { title: '合计', dataIndex: 'amount', width: 90, customRender: this.getCostsAmount, className: 'column-center' },
        { title: '操作', dataIndex: 'action', width: 80, scopedSlots: { customRender: 'action' }, className: 'column-center' }
      ],
      dataSource: [],
      dataCosts: [],
      firstLoadStatus: false
    }
  },
  methods: {
    handleChange(value, key, column, record) {
      record[column.dataIndex] = value
    },
    loadData() {
      const self = this
      Get(quoteCompileCostsApi.list, { quoteId: self.quoteInfo.id }).then((result) => {
        if (result.code === 0) {
          for (let i = 0; i < result.data.length; i++) {
            result.data[i].key = i
            result.data[i].editable = false
          }
          self.dataSource = result.data
          this.countAmount()
          if (!this.firstLoadStatus) {
            this.firstLoadStatus = true
          }
          self.createDefaultData()
        }
      })
    },
    editRow(row) {
      if (this.validateEditing()) {
        // 验证是否存在编辑中的数据
        return
      }
      row.editable = true
    },
    removeRow(row) {
      this.dataSource = this.dataSource.filter((item) => item.key !== row.key)
    },
    delRow(row) {
      const self = this
      if (row.id) {
        if (self.validateEditing()) {
          // 验证是否存在编辑中的数据
          return
        }
        this.$confirm({
          title: '警告',
          content: `真的要作废 ${row.dicName} 吗?`,
          okText: '作废',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            Del(quoteCompileCostsApi.del, { ids: [row.id] }).then((result) => {
              if (result.code === 0) {
                self.$message.success(`操作成功`)
                self.loadData()
              } else {
                self.$message.error(result.msg)
              }
            })
          },
          onCancel() {}
        })
      } else {
        self.removeRow(row)
      }
    },
    saveRow(row, idx) {
      const self = this
      var saveApi = quoteCompileCostsApi.add
      if (row.id) {
        saveApi = quoteCompileCostsApi.edit
      }
      if (row.dicId) {
        Post(saveApi, row).then((result) => {
          if (result.code === 0) {
            result.data.editable = false
            self.dataSource[idx] = result.data
            self.cancelEditRow(idx)
            this.$message.success(`操作成功`)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.$message.error('未选择类别！')
      }
    },
    validateEditing() {
      const editingInfo = this.getEditing()
      if (editingInfo) {
        const idx = editingInfo.idx
        const record = this.dataSource[idx]
        if (record && record.editable) {
          if (record.dicId) {
            this.saveRow(record, idx)
          } else {
            this.$message.error(`报告编制费用中存在未完成的的项！`)
            return true
          }
        }
      }
      return false
    },
    isEditing() {
      const editing = this.$refs.editing
      return !!editing
    },
    getEditing() {
      if (this.isEditing()) {
        const editing = this.$refs.editing
        const obj = editing
        const idx = obj.attributes.idx.value
        return { idx: idx }
      }
      return null
    },
    cancelEditRow(idx) {
      const self = this
      const row = self.dataSource[idx]
      row.editable = false
      this.$set(self.dataSource, idx, row)
    },
    addItemData() {
      if (this.validateEditing()) {
        // 验证是否存在编辑中的数据
        return
      }
      const length = this.dataSource.length
      this.dataSource.push({
        key: length === 0 ? 1 : parseInt(this.dataSource[length - 1].key) + 1,
        quoteId: this.quoteInfo.id,
        dicId: null,
        dicName: '',
        dicDesc: '',
        dicRadix: 0,
        dicCoefficient: 0,
        discount: 1.0,
        amount: 0,
        editable: true
      })
    },
    dicSelect(dic, row) {
      row.dicId = dic.id
      row.dicName = dic.dicName
      row.dicDesc = dic.dicDesc
      row.dicRadix = dic.dicRadix
      row.dicCoefficient = dic.dicCoefficient
    },
    loadCostsData() {
      const self = this
      Get(dicApi.listAllCompileCosts, {}).then((result) => {
        if (result.code === 0) {
          self.dataCosts = result.data
          self.createDefaultData()
        }
      })
    },
    getCostsAmount(text, row) {
      row.amount = (row.dicRadix * row.dicCoefficient * row.discount).toFixed(2)
      return row.amount
    },
    countAmount() {
      var list = this.dataSource
      let amount = 0
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          amount += parseFloat(list[i].amount)
        }
      }
      this.$set(this.amount, 'compileTotalAmount', amount.toFixed(2))
      return amount
    },
    // 当报告编制费用为空时，默认生成报告编制费
    async createDefaultData() {
      if (this.quoteInfo.sampleSource === '1' && this.firstLoadStatus && this.dataSource.length === 0 && this.dataCosts.length) {
        let costItems1 = this.dataCosts.filter((item) => item.dicName === '报告编制费')
        if (costItems1.length) {
          let costItem1 = costItems1[0]
          let obj1 = {
            key: 1,
            quoteId: this.quoteInfo.id,
            dicId: costItem1.id,
            dicName: costItem1.dicName,
            dicDesc: costItem1.dicDesc,
            dicRadix: costItem1.dicRadix,
            dicCoefficient: costItem1.dicCoefficient,
            discount: 1.0,
            amount: (costItem1.dicRadix * costItem1.dicCoefficient * 1.0).toFixed(2),
            editable: true
          }
          await Post(quoteCompileCostsApi.add, obj1).then((result) => {
            if (result.code === 0) {
              this.$message.success(`操作成功`)
            } else {
              this.$message.error(result.msg)
            }
          })
        }
        this.loadData()
      }
    }
  },
  watch: {
    dataSource: {
      handler(newValue) {
        this.countAmount()
      },
      deep: true
    },
    'quoteInfo.id': {
      handler(newValue) {
        if (typeof newValue !== 'undefined' && newValue !== null) {
          this.firstLoadStatus = false
          this.loadData()
          this.loadCostsData()
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
th.column-center,
td.column-center {
  text-align: center !important;
  padding: 10px;
}
</style>
