<template>
  <div>
    <a-table
      ref="table"
      size="small"
      :columns="table.columns"
      :dataSource="table.dataSource"
      :pagination="table.pagination"
      :loading="table.loading"
      rowKey="id">
      <template slot="recptType" slot-scope="text">
        <a-tag color="pink" v-if="text=='0'">部分收款</a-tag>
        <a-tag color="green" v-else-if="text=='1'">完成收款</a-tag>
        <a-tag color="pink" v-else>{{ text }}</a-tag>
      </template>
      <template slot="recptAmount" slot-scope="v, r">
        {{ currency(v) }}
      </template>
      <template slot="recptConfirm" slot-scope="text">
        <a-tag color="red" v-if="text=='0'">未确认</a-tag>
        <a-tag color="green" v-else-if="text=='1'">已确认</a-tag>
        <a-tag color="pink" v-else>{{ text }}</a-tag>
      </template>
      <template slot="recptInvoice" slot-scope="text">
        <a-tag color="red" v-if="text=='0'">未申请</a-tag>
        <a-tag color="orange" v-else-if="text=='1'">申请中</a-tag>
        <a-tag color="green" v-else-if="text=='2'">已开票</a-tag>
        <a-tag color="pink" v-else>{{ text }}</a-tag>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <div class="editable-row-operations">
<!--          <a-button-->
<!--            style="margin-right: 5px"-->
<!--            size="small"-->
<!--            v-if="record.recptInvoice=='0'"-->
<!--            @click="invoice(record)"-->
<!--            icon="audit"-->
<!--            title="申请发票"-->
<!--            v-action:proj-receipt:add>申请发票</a-button>-->
<!--          <a-button-->
<!--            size="small"-->
<!--            style="margin-right: 5px"-->
<!--            v-if="record.recptConfirm=='0'"-->
<!--            @click="edit(record)"-->
<!--            icon="edit"-->
<!--            title="编辑"-->
<!--            v-action:proj-receipt:edit>编辑</a-button>-->
<!--          <a-button-->
<!--            style="margin-right: 5px"-->
<!--            size="small"-->
<!--            v-if="record.recptConfirm=='0'"-->
<!--            @click="del(record)"-->
<!--            icon="delete"-->
<!--            title="作废"-->
<!--            v-action:proj-receipt:del>作废</a-button>-->
<!--          <a-button-->
<!--            style="margin-right: 5px"-->
<!--            size="small"-->
<!--            v-if="record.recptConfirm=='0'"-->
<!--            @click="receiptConfirm(record)"-->
<!--            icon="check"-->
<!--            title="收款确认"-->
<!--            v-action:proj-receipt:receiptConfirm>收款确认</a-button>-->
<!--          <a-button-->
<!--            style="margin-right: 5px"-->
<!--            size="small"-->
<!--            v-if="record.recptInvoice=='1'"-->
<!--            @click="invoiceConfirm(record)"-->
<!--            icon="check"-->
<!--            title="开票确认"-->
<!--            v-action:proj-receipt:invoiceConfirm>发票确认</a-button>-->
        </div>
      </template>
    </a-table>
    <ReceiptForm ref="receiptForm" @ok="loadData"></ReceiptForm>
  </div>
</template>

<script>
import { commDictApi } from '@/api/common'
import { projReceiptApi } from '@/api/project'
import { STable } from '@/components'
import Form from './Form.vue'
import ReceiptForm from './ReceiptForm.vue'
import { currency } from '@/utils/util'

var vm = {}
export default {
  name: 'ReceiptList',
  components: {
    STable,
    Form,
    ReceiptForm
  },
  props: {
    inParam: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    vm = this
    return {
      queryParam: {
      },
      table: {
        dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '收款类型', width: '130px', dataIndex: 'recptType', scopedSlots: { customRender: 'recptType' } },
          { title: '收款日期', width: '130px', dataIndex: 'recptDate' },
          { title: '收款金额(元)', width: '200px', dataIndex: 'recptAmount', scopedSlots: { customRender: 'recptAmount' } },
          { title: '收款申请人', width: '150px', dataIndex: 'applicantName' },
          { title: '申请时间', width: '200px', dataIndex: 'applyTime' },
          { title: '收款确认', width: '80px', dataIndex: 'recptConfirm', scopedSlots: { customRender: 'recptConfirm' } },
          { title: '收款确认人', width: '150px', dataIndex: 'confirmerName' },
          { title: '确认时间', width: '200px', dataIndex: 'confirmTime' },
          { title: '收款原因', dataIndex: 'recptReason' },
          { title: '收款备注', dataIndex: 'recptRemark' }
          // { title: '是否开发票', dataIndex: 'recptInvoice', scopedSlots: { customRender: 'recptInvoice' } },
          // { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } }
        ],
        loading: false,
        pagination: false
      },
      progressList: []
    }
  },
  methods: {
    currency,
    resetSearchForm () {
      this.queryParam = {}
      this.loadData()
    },
    loadData () {
      var self = this
      self.table.loading = true
      projReceiptApi.list(Object.assign(this.inParam, this.queryParam)).then(res => {
        self.table.loading = false
        self.table.dataSource = res.data
      })
    },
    edit (_record) {
      this.$refs.receiptForm.edit(_record)
    },
    del (_record) {
      const $self = this
      this.$confirm({
        title: '确认作废?',
        content: `请确认是否作废此记录,作废后将不可恢复`,
        onOk () {
          projReceiptApi.del(_record.id).then(result => {
            if (result.code === 0) {
              $self.$message.success(`作废记录成功`)
              $self.loadData()
            } else {
              $self.$message.error(`作废记录失败`)
            }
          })
        },
        onCancel () {}
      })
    },
    invoice (_record) {
    },
    invoiceConfirm (_record) {
      const $self = this
      this.$confirm({
        title: '确认开票',
        content: `请确认确认是否已经开票?`,
        onOk () {
          projReceiptApi.invoiceConfirm({ id: _record.id }).then(result => {
            if (result.code === 0) {
              $self.$message.success(`操作成功`)
              $self.loadData()
            } else {
              $self.$message.error(`操作失败`)
            }
          })
        },
        onCancel () {}
      })
    },
    receiptConfirm (_record) {
      const $self = this
      this.$confirm({
        title: '确认收款',
        content: `请核对收款信息后,确认已经收款?`,
        onOk () {
          projReceiptApi.receiptConfirm({ id: _record.id }).then(result => {
            if (result.code === 0) {
              $self.$message.success(`操作成功`)
              $self.loadData()
            } else {
              $self.$message.error(`操作失败`)
            }
          })
        },
        onCancel () {}
      })
    },
    listProjProgress () {
      commDictApi.list({ dictCode: 'proj_progress' }).then(result => {
        if (result.code === 0) {
          this.progressList = result.data
        }
      })
    },
    getProgressName (progress) {
      const obj = vm.progressList.filter(item => progress === item.itemValue)[0]
      if (obj) {
        return obj.itemText
      }
      return progress
    }
  },
  watch: {
    inParam: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  filters: {
  },
  mounted () {
    this.listProjProgress()
    this.loadData()
  }
}
</script>

<style scoped>

</style>
