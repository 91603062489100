import { checkBatchApi } from '@/api/checkBatch/checkBatchApi'
import { message } from 'ant-design-vue'

/**
 * 获取检测批次信息
 * @param id 检测批次ID
 * @return {Promise<object>} 批次信息
 */
export const getBatchInfo = async (id) => {
  return await checkBatchApi.findBydId(id).then((res) => {
    if (res.code === 0) {
      return res.data
    } else {
      message.error(`获取批次信息出错 :${res.msg}`)
      return {}
    }
  })
}
