import { sysCheckModelApi } from '@/api/checkModel'

const sysCheckModelMap = new Map()
/**
 * 根据ID 查询检测模型组信息
 * @param id {number} 检测模型组ID
 * @return {Promise<object>} 检测模型组信息
 */
export const findCheckModelById = async (id) => {
  return (
    (id &&
      (sysCheckModelMap.get(id) ||
        (await sysCheckModelApi.findById(id).then((res) => {
          if (res.code === 0) {
            console.log('res.data:', res.data)
            sysCheckModelMap.set(id, res.data)
            return res.data
          }
          return null
        })))) ||
    {}
  )
}
