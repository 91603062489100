// 项目样品详情
const projectDetailColumn = [
  { title: '样品编号', dataIndex: 'id', width: 180, scopedSlots: { customRender: 'id' } },
  { title: '类型', dataIndex: 'type', width: 80, scopedSlots: { customRender: 'type' } },
  { title: '采样点位', dataIndex: 'siteName', width: 120, scopedSlots: { customRender: 'siteName' } },
  { title: '天数', dataIndex: 'numPeriod', width: 120, scopedSlots: { customRender: 'numPeriod' } },
  { title: '频次', dataIndex: 'numFrequency', width: 120, scopedSlots: { customRender: 'numFrequency' } },
  { title: '采样人', dataIndex: 'sampleUserName', width: 100, scopedSlots: { customRender: 'sampleUserName' } },
  { title: '采样时间', dataIndex: 'sampleTime', width: 200, scopedSlots: { customRender: 'sampleTime' } },
  { title: '有效期', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
  { title: '当前状态', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
]

// 申请入库
const applyStorageColumn = [
  { title: '样品编号', dataIndex: 'id', width: 180, scopedSlots: { customRender: 'id' } },
  { title: '类型', dataIndex: 'type', width: 80, scopedSlots: { customRender: 'type' } },
  { title: '采样点位', dataIndex: 'siteName', width: 120, scopedSlots: { customRender: 'siteName' } },
  { title: '采/送样人', dataIndex: 'sampleUserName', width: 100, scopedSlots: { customRender: 'sampleUserName' } },
  { title: '采/制样时间', dataIndex: 'sampleTime', width: 200, scopedSlots: { customRender: 'sampleTime' } },
  { title: '存储介质', dataIndex: 'storageName', width: 150, scopedSlots: { customRender: 'storageName' } },
  { title: '份数', dataIndex: 'num', width: 80, scopedSlots: { customRender: 'num' } },
  { title: '体积/重量', dataIndex: 'volume', width: 90, scopedSlots: { customRender: 'volume' } },
  { title: '有效期', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
  { title: '当前状态', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
]

//  入库申请单入库
const sampleStorageApplyColumn = [
  { title: '样品编号', dataIndex: 'id', width: 160, scopedSlots: { customRender: 'id' } },
  { title: '类型', dataIndex: 'type', width: 80, scopedSlots: { customRender: 'type' } },
  { title: '采样点位', dataIndex: 'siteName', width: 120, scopedSlots: { customRender: 'siteName' } },
  { title: '采/送样人', dataIndex: 'sampleUserName', width: 80, scopedSlots: { customRender: 'sampleUserName' } },
  { title: '采/制样时间', dataIndex: 'sampleTime', width: 160, scopedSlots: { customRender: 'sampleTime' } },
  { title: '存储介质', dataIndex: 'storageName', width: 120, scopedSlots: { customRender: 'storageName' } },
  { title: '份数', dataIndex: 'num', width: 70, scopedSlots: { customRender: 'num' } },
  { title: '体积/重量', dataIndex: 'volume', width: 90, scopedSlots: { customRender: 'volume' } },
  { title: '存储位置', dataIndex: 'locationName', width: 150, scopedSlots: { customRender: 'locationName' } },
  { title: '有效期', dataIndex: 'validity', width: 200, scopedSlots: { customRender: 'validity' } },
  { title: '样品状态', dataIndex: 'appearanceStatus', width: 200, scopedSlots: { customRender: 'appearanceStatus' } },
  { title: '操作', dataIndex: 'action', width: 80, scopedSlots: { customRender: 'action' } }
]

// 密码质控
const passwordSampleControlColumn = [
  { title: '样品编号', dataIndex: 'id', width: 180, scopedSlots: { customRender: 'id' } },
  { title: '项目名称', dataIndex: 'projName', width: 180, scopedSlots: { customRender: 'projName' } },
  { title: '检测项', dataIndex: 'itemNameStr', width: 200, scopedSlots: { customRender: 'itemNameStr' } },
  { title: '类型', dataIndex: 'type', width: 80, scopedSlots: { customRender: 'type' } },
  { title: '采样点位', dataIndex: 'siteName', width: 120, scopedSlots: { customRender: 'siteName' } },
  { title: '采/送样人', dataIndex: 'sampleUserName', width: 100, scopedSlots: { customRender: 'sampleUserName' } },
  { title: '采/制样时间', dataIndex: 'sampleTime', width: 200, scopedSlots: { customRender: 'sampleTime' } },
  { title: '存储介质', dataIndex: 'storageName', width: 150, scopedSlots: { customRender: 'storageName' } },
  { title: '份数', dataIndex: 'num', width: 80, scopedSlots: { customRender: 'num' } },
  { title: '体积/重量', dataIndex: 'volume', width: 90, scopedSlots: { customRender: 'volume' } },
  { title: '有效期', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
  { title: '当前状态', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
]

// 申请出库
const applyOutboundColumn = [
  { title: '样品编号', dataIndex: 'checkSampleId', width: 170, sorter: true, scopedSlots: { customRender: 'checkSampleId' } },
  { title: '任务编号', dataIndex: 'projNo', sorter: true, width: 120 },
  { title: '检测项', dataIndex: 'itemNameStr', width: 200, scopedSlots: { customRender: 'itemNameStr' } },
  { title: '类型', dataIndex: 'type', width: 80, sorter: true, scopedSlots: { customRender: 'type' } },
  { title: '样品来源', dataIndex: 'source', width: 100, sorter: true, scopedSlots: { customRender: 'source' } },
  { title: '采样点位', dataIndex: 'siteName', width: 150, scopedSlots: { customRender: 'siteName' } },
  { title: '采/制样时间', dataIndex: 'sampleTime', width: 180, scopedSlots: { customRender: 'sampleTime' } },
  { title: '样品状态', dataIndex: 'status', width: 100, sorter: true, scopedSlots: { customRender: 'status' } },
  { title: '截止时间', dataIndex: 'endTime', width: 180, scopedSlots: { customRender: 'endTime' } },
  { title: '检测员', dataIndex: 'checkUserNameStr', width: 90, sorter: true, scopedSlots: { customRender: 'checkUserNameStr' } },
  { title: '任务状态', dataIndex: 'orderStatus', width: 100, scopedSlots: { customRender: 'orderStatus' } },
  { title: '操作', dataIndex: 'action', width: 150, scopedSlots: { customRender: 'checkScheduleAction' } }
]

// 基础字段
const baseColumn = [
  { title: '照片', dataIndex: 'photos', width: 80, scopedSlots: { customRender: 'photo' } },
  { title: '样品编号', dataIndex: 'id', width: 180, scopedSlots: { customRender: 'id' } },
  { title: '项目名称', dataIndex: 'projName', width: 180, scopedSlots: { customRender: 'projName' } },
  { title: '检测项', dataIndex: 'itemNameStr', width: 200, scopedSlots: { customRender: 'itemNameStr' } },
  { title: '类型', dataIndex: 'type', width: 80, scopedSlots: { customRender: 'type' } },
  { title: '样品来源', dataIndex: 'source', width: 80, scopedSlots: { customRender: 'source' } },
  { title: '分包样品', dataIndex: 'itemType', width: 80, scopedSlots: { customRender: 'itemType' } },
  { title: '采样点位', dataIndex: 'siteName', width: 120, scopedSlots: { customRender: 'siteName' } },
  { title: '采/送样人', dataIndex: 'sampleUserName', width: 100, scopedSlots: { customRender: 'sampleUserName' } },
  { title: '采/制样时间', dataIndex: 'sampleTime', width: 200, scopedSlots: { customRender: 'sampleTime' } },
  { title: '存储介质', dataIndex: 'storageName', width: 150, scopedSlots: { customRender: 'storageName' } },
  { title: '份数', dataIndex: 'num', width: 80, scopedSlots: { customRender: 'num' } },
  { title: '体积/重量', dataIndex: 'volume', width: 90, scopedSlots: { customRender: 'volume' } },
  { title: '有效期', dataIndex: 'validity', width: 80, scopedSlots: { customRender: 'validity' } },
  { title: '存储位置', dataIndex: 'locationName', width: 100, scopedSlots: { customRender: 'locationName' } },
  { title: '样品状态', dataIndex: 'appearanceStatus', width: 100, scopedSlots: { customRender: 'appearanceStatus' } },
  { title: '留样', dataIndex: 'reserved', width: 80, scopedSlots: { customRender: 'reserved' } },
  { title: '当前状态', dataIndex: 'status', width: 80, scopedSlots: { customRender: 'status' } }
]

// 默认字段
const sampleColumn = [...baseColumn, { title: '操作', dataIndex: 'action', width: 150, scopedSlots: { customRender: 'action' } }]

/* 待入库，入口申请后 */
const storageApplyColumn = [...baseColumn,
  { title: '入库申请人', dataIndex: 'storageApplyUserName', width: 120, scopedSlots: { customRender: 'storageApplyUserName' } },
  { title: '入库申请时间', dataIndex: 'storageApplyTime', width: 180, scopedSlots: { customRender: 'storageApplyTime' } },
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

const storageColumnTemp = [...storageApplyColumn]
storageColumnTemp.pop()

// 已入库
const storageColumn = [...storageColumnTemp,
  { title: '入库人', dataIndex: 'storageUserName', width: 100, scopedSlots: { customRender: 'storageUserName' } },
  { title: '入库时间', dataIndex: 'storageTime', width: 180, scopedSlots: { customRender: 'storageTime' } },
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

// 待出库，已申请出库
const pickupApplyColumn = [...baseColumn,
  { title: '领样申请人', dataIndex: 'pickupApplyUserName', width: 100, scopedSlots: { customRender: 'pickupApplyUserName' } },
  { title: '领样申请时间', dataIndex: 'pickupApplyTime', width: 180, scopedSlots: { customRender: 'pickupApplyTime' } },
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

// 已出库
const outboundColumnTemp = [...pickupApplyColumn]
outboundColumnTemp.pop()
const outboundColumn = [...outboundColumnTemp,
  { title: '出库人', dataIndex: 'outboundUserName', width: 100, scopedSlots: { customRender: 'outboundUserName' } },
  { title: '出库时间', dataIndex: 'outboundTime', width: 180, scopedSlots: { customRender: 'outboundTime' } },
  { title: '领样人', dataIndex: 'pickupUserName', width: 100, scopedSlots: { customRender: 'pickupUserName' } },
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

// 已销毁
const destroyColumn = [...baseColumn,
  { title: '销毁人', dataIndex: 'destroyUserName', width: 100, scopedSlots: { customRender: 'destroyUserName' } },
  { title: '销毁时间', dataIndex: 'destroyTime', width: 180, scopedSlots: { customRender: 'destroyTime' } },
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

// 已留样
const reservedColumn = [...baseColumn,
  { title: '留样管理员', dataIndex: 'reservedUserName', width: 100, scopedSlots: { customRender: 'reservedUserName' } },
  { title: '留样时长', dataIndex: 'reservedTime', width: 180, scopedSlots: { customRender: 'reservedTime' } },
  { title: '留样地点', dataIndex: 'reservedAddressName', width: 120, scopedSlots: { customRender: 'reservedAddressName' } },
  { title: '操作', dataIndex: 'action', width: 120, scopedSlots: { customRender: 'action' } }
]

export {
  baseColumn,
  sampleColumn,
  projectDetailColumn,
  applyStorageColumn,
  storageApplyColumn,
  sampleStorageApplyColumn,
  storageColumn,
  applyOutboundColumn,
  pickupApplyColumn,
  outboundColumn,
  destroyColumn,
  reservedColumn,
  passwordSampleControlColumn
}
