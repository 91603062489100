<template>
  <a-radio-group :disabled="disabled" :value="value" @change="handleInput" v-if="tagType=='radio'">
    <a-radio :key="key" :value="key" v-for="(item, key) of dictOptions">{{ item }}</a-radio>
  </a-radio-group>

  <a-select :disabled="disabled" :placeholder="placeholder" :value="value" @change="handleInput" v-else-if="tagType=='select'">
    <a-select-option value="">请选择</a-select-option>
    <a-select-option :key="key" :value="key" v-for="(item, key) of dictOptions">
      <span :title=" item " style="display: inline-block;width: 100%">
        {{ item }}
      </span>
    </a-select-option>
  </a-select>
</template>

<script>
import { dictApi } from '@/api/system'

/* eslint-disable */
export default {
    name: 'JDictSelectTag',
    props: {
        dictCode: String,
        placeholder: String,
        triggerChange: Boolean,
        disabled: Boolean,
        value: String,
        type: String
    },
    data () {
        return {
            tagType: ''
        }
    },
    computed: {
        dictOptions () {
            return { ...this.$store.state.code.cache[this.dictCode] }
        }
    },
    created () {
        if (!this.type || this.type === 'list') {
            this.tagType = 'select'
        } else {
            this.tagType = this.type
        }
        // 获取字典数据
        //this.initDictData()
        this.$store.dispatch('code/select', this.dictCode)

    },
    methods: {
        initDictData () {
            // 根据字典Code, 初始化字典数组
            dictApi.getItems({ dictCode: this.dictCode }).then((res) => {
                if (res.code === 0) {
                    this.dictOptions = res.data
                }
            })
        },
        handleInput (e) {
            let val
            if (this.tagType === 'radio') {
                val = e.target.value
            } else {
                val = e
            }
            if (this.triggerChange) {
                this.$emit('change', val)
            } else {
                this.$emit('input', val)
            }
        },
        setCurrentDictOptions (dictOptions) {
            this.dictOptions = dictOptions
        },
        getCurrentDictOptions () {
            return this.dictOptions
        }
    }
}
</script>

<style scoped></style>
