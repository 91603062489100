<template>
  <a-modal class="check-task-offline-check-form" v-bind="fullModal" v-on="fullModal">
    <check-task-offline-form v-bind="formProps"/>

    <a-drawer v-bind="checkDrawer" v-on="checkDrawer">
      <div>
        <a-row align="middle">
          <a-col :span="4" class="mxtable-title">线下审核</a-col>
        </a-row>
        <table class="mxtable">
          <tr>
            <th rowspan="3">审核事项</th>
          </tr>
          <tr>
            <td class="tdLabel">审核内容</td>
            <td colspan="3">
              <p v-for="(value,key) of offlineCheckPoints" :key="key">
                （{{ key }}）{{ value }};
              </p>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">审核结果</td>
            <td colspan="3">
              <a-radio-group v-model="checkData.status">
                <a-radio :value="checkTaskOfflineStatusEnum.pass">通过</a-radio>
                <a-radio :value="checkTaskOfflineStatusEnum.rejected">驳回</a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">审核意见</td>
            <td colspan="3">
              <a-textarea v-model="checkData.checkNote"></a-textarea>
            </td>
          </tr>
        </table>
        <div class="check-btn">
          <a-button type="primary" @click="submitCheck">提交</a-button>
        </div>
      </div>
    </a-drawer>

    <template #footer>
      <a-button @click="fullModal.cancel">关闭</a-button>
      <a-button @click="fullModal.showCheck" type="primary">审核</a-button>
    </template>
  </a-modal>
</template>

<script>
import { throttle } from 'lodash'
import fullModal from '@/pages/common/mixins/modal/full'

import { checkTaskOfflineApiApi } from '@/api/check/checkTaskOfflineApi'
import { checkTaskOfflineStatusEnum } from '@/api/check/checkTaskOfflineConstant'
import CheckTaskOfflineForm from '@/pages/cloud-quote/check-task/modules/offline/form'

export default {
  mixins: [fullModal],

  components: { CheckTaskOfflineForm },

  props: {
    id: { type: Number, required: true },
    projId: { type: Number, required: true }
  },

  data () {
    return {
      checkTaskOfflineStatusEnum,

      drawerVisible: false,

      // 审核数据
      checkData: {
        id: this.id,
        status: null,
        checkNote: null
      },

      form: {
        ref: 'form',
        frameborder: 0,
        width: '100%',
        height: '100%'
      },

      checkDrawer: {
        visible: false,
        width: 600,
        getContainer: false,
        closable: false,
        maskClosable: true,
        mask: true,
        wrapStyle: { position: 'absolute' },
        close: () => { this.checkDrawer.visible = false }
      },

      checkCheckModal: {
        title: '请先进行认证',
        centered: true,
        closed: false,
        width: 600,
        visible: false,
        show: () => { this.checkCheckModal.visible = true },
        cancel: () => { this.checkCheckModal.visible = false },
        ok: () => {
          if (this.checkData.checkPwd) {
            this.check()
          } else {
            this.$message.error('请输入认证密码！')
            return false
          }
        }
      },

      fullModal: {
        title: '线下完成审核',
        bodyStyle: { position: 'relative' },

        cancel: () => {
          this.fullModal.visible = false
          this.$emit('cancel')
        },

        showCheck: () => {
          this.checkDrawer.visible = true
        }
      }
    }
  },

  computed: {
    formProps () {
      const { id, projId } = this
      return { type: 'view', id, projId }
    },

    offlineCheckPoints () {
      return { ...this.$store.state.code.cache.check_task_offline_check_points }
    }
  },

  mounted () {
    this.check = throttle(this.check, 6000, { leading: true, trailing: false })
    this.$store.dispatch('code/select', 'check_task_offline_check_points')
  },

  methods: {

    initCheckPoints () {

    },

    // 提交审核
    submitCheck () {
      if (this.checkCheckData()) {
        this.check()
      }
    },

    // 审核检测原始记录表
    check () {
      checkTaskOfflineApiApi.check(this.checkData).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.$emit('success')
          this.fullModal.cancel()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 校验审核数据
    checkCheckData () {
      if (this.checkData.status) {
        return true
      } else {
        this.$message.error('请选择审核结果！')
        return false
      }
    },

    // 展示当前组件
    show () {
      this.fullModal.show()
    }
  }
}
</script>

<style lang="less" scoped>
@import '_index';
</style>
