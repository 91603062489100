'use strict'

/**
 * 检测任务线下完成: 相关API
 */

import { getAction, delAction, postDataAction, postAction } from '@/api/common'

const checkTaskOfflineApiPre = '/ms-api/check-task-offline'

export const checkTaskOfflineApiApi = {
  list: (param) => getAction(`${checkTaskOfflineApiPre}/list`, param),
  findBydId: (id) => getAction(`${checkTaskOfflineApiPre}/findById/${id}`),
  save: (data) => postDataAction(`${checkTaskOfflineApiPre}/save`, JSON.stringify(data)),
  deleteById: (id) => delAction(`${checkTaskOfflineApiPre}/del/${id}`),
  done: (id) => postAction(`${checkTaskOfflineApiPre}/done/${id}`),
  check: (data) => postDataAction(`${checkTaskOfflineApiPre}/check`, data)
}
