<template>
  <div>
    <a-row>
      <table class="mxtable">
        <tr>
          <td class="tdLabel">单位名称</td>
          <td colspan="3">
            {{ sctr.sctrName }}
          </td>
          <td class="tdLabel">编号</td>
          <td></td>
        </tr>
        <tr>
          <td class="tdLabel">公司地址</td>
          <td colspan="3">{{ sctr.sctrWholeAddress }}</td>
          <td class="tdLabel">邮政编码</td>
          <td>{{ sctr.sctrZipCode }}</td>
        </tr>
        <tr>
          <td class="tdLabel">企业联系人</td>
          <td>{{ sctr.sctrContacts }}</td>
          <td class="tdLabel">企业联系人电话</td>
          <td>{{ sctr.sctrContactsPhone }}</td>
          <td class="tdLabel">传真号码</td>
          <td>{{ sctr.sctrFax }}</td>
        </tr>
        <tr>
          <td class="tdLabel" rowspan="7">评价内容</td>
          <td class="tdLabel" colspan="2">服务和供应品质量</td>
          <td colspan="3">
            <a-radio-group :value="check.productQuality">
              <a-radio value="优">优(20)</a-radio>
              <a-radio value="良">良(15)</a-radio>
              <a-radio value="中">中(10)</a-radio>
              <a-radio value="差">差(5)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" colspan="2">对检测质量保障情况</td>
          <td colspan="3">
            <a-radio-group :value="check.qualityAssurance">
              <a-radio value="优">优(20)</a-radio>
              <a-radio value="良">良(15)</a-radio>
              <a-radio value="中">中(10)</a-radio>
              <a-radio value="差">差(5)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" colspan="2">服务和供应品质量稳定性</td>
          <td colspan="3">
            <a-radio-group :value="check.stability">
              <a-radio value="优">优(20)</a-radio>
              <a-radio value="良">良(15)</a-radio>
              <a-radio value="中">中(10)</a-radio>
              <a-radio value="差">差(5)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" colspan="2">服务满意度</td>
          <td colspan="3">
            <a-radio-group :value="check.satisfaction">
              <a-radio value="优">优(10)</a-radio>
              <a-radio value="良">良(8)</a-radio>
              <a-radio value="中">中(6)</a-radio>
              <a-radio value="差">差(4)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" colspan="2">合同遵守情况</td>
          <td colspan="3">
            <a-radio-group :value="check.contractPromise">
              <a-radio value="优">优(10)</a-radio>
              <a-radio value="良">良(8)</a-radio>
              <a-radio value="中">中(6)</a-radio>
              <a-radio value="差">差(4)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" colspan="2">交付及时性</td>
          <td colspan="3">
            <a-radio-group :value="check.timeliness">
              <a-radio value="优">优(10)</a-radio>
              <a-radio value="良">良(8)</a-radio>
              <a-radio value="中">中(6)</a-radio>
              <a-radio value="差">差(4)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" colspan="2">供方技术能力</td>
          <td colspan="3">
            <a-radio-group :value="check.ability">
              <a-radio value="优">优(10)</a-radio>
              <a-radio value="良">良(8)</a-radio>
              <a-radio value="中">中(6)</a-radio>
              <a-radio value="差">差(4)</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel" rowspan="3">供方质量保证体系见证材料</td>
          <td class="tdLabel">营业执照</td>
          <td colspan="4">
            <a-radio-group :value="check.hasLicense">
              <a-radio value="有">有</a-radio>
              <a-radio value="无">无</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">资质附表</td>
          <td colspan="5">
            <a-radio-group :value="check.hasCmaSchedule">
              <a-radio value="有">有</a-radio>
              <a-radio value="无">无</a-radio>
            </a-radio-group>
          </td>
        </tr>
        <tr>
          <td class="tdLabel">其他材料</td>
          <td colspan="5">
            {{ check.otherFile }}
          </td>
        </tr>
        <tr>
          <td class="tdLabel">评价结果</td>
          <td colspan="5">
            <a-radio-group :value="check.score">
              <a-radio value="优">优(85-100)</a-radio>
              <a-radio value="良">良(71-84)</a-radio>
              <a-radio value="中">中(51-70)</a-radio>
              <a-radio value="差">差(50及以下)</a-radio>
            </a-radio-group>
          </td>
        </tr>
      </table>
    </a-row>
  </div>
</template>

<script>
import AddressInput from '@/pages/common/map/AddressInput'
import UploadFile from '@/pages/common/upload/UploadFile'
import JDate from '@/components/j/JDate'

export default {
  name: 'SctrCheckView',
  components: {
    AddressInput,
    UploadFile,
    JDate
  },
  props: {
    sctr: {
      type: Object,
      default: () => ({})
    },
    check: {
      type: Object,
      default: () => ({})
    },
    addr: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      optionMap: { productQuality: { '优': 20, '良': 15, '中': 10, '差': 5 },
        qualityAssurance: { '优': 20, '良': 15, '中': 10, '差': 5 },
        stability: { '优': 20, '良': 15, '中': 10, '差': 5 },
        satisfaction: { '优': 10, '良': 8, '中': 6, '差': 4 },
        contractPromise: { '优': 10, '良': 8, '中': 6, '差': 4 },
        timeliness: { '优': 10, '良': 8, '中': 6, '差': 4 },
        ability: { '优': 10, '良': 8, '中': 6, '差': 4 } }
    }
  },
  methods: {

    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    uploadSingleFileOK (fileList, key) {
      if (fileList && fileList.newFileList && fileList.newFileList.length > 0) {
        this.$set(this.sctr, key, fileList.newFileList[0].fileUrl)
      } else {
        this.$set(this.sctr, key, '')
      }
    },

    openQxb () {
      const sctrName = this.sctr.sctrName
      // this.customerInfo.csrName = csrName
      window.open(`https://www.qixin.com/search?key=${sctrName}`)
    },

    initInfo () {
    },
    getScoreValue (key, key2) {
      return this.optionMap[key][key2] || 0
    }
  },
  computed: {
    totalScore () {
      var score = 0
      for (var key in this.optionMap) {
        score += this.getScoreValue(key, this.check[key])
      }
      return score
    }
  },
  watch: {
    totalScore (nv, ov) {
      var score = '优'
      if (nv >= 85) {
        score = '优'
      } else if (nv >= 71 && nv <= 85) {
        score = '良'
      } else if (nv >= 51 && nv <= 70) {
        score = '中'
      } else {
        score = '差'
      }
      this.check.score = score
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style lang="less">
.mxtable {
  width: 100%;
  border: 2px solid #1890FF;
  border-top: 5px solid #1890FF;
  border-collapse: unset;
  margin-top: -1px;

  .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
    background-color: #FEF0D0;
  }

  th {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    border: 1px dashed #909399;
  }

  td {
    padding: 5px;
    /*min-width: 12%;*/
    /*max-width: 22%;*/
    width: 125px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }

  .tdLabel {
    width: 75px;
    background: #F3F3F1;
    color: #333333;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    /*border: 1px dashed #909399;*/
    border-top: 1px dashed #909399;
    border-left: 1px dashed #909399;
  }
}
</style>
<style lang="less" scoped></style>
