'use strict'

/**
 * 检测任务批次: 相关API
 */

import { delAction, getAction, postAction, postDataAction } from '@/api/common'

const checkBatchPre = '/ms-api/check-task-batch'
const checkBatchCurvePre = '/ms-api/check-task-batch-curve'

export const checkBatchApi = {
  list: (param) => getAction(`${checkBatchPre}/list`, param),
  findBydId: (id) => getAction(`${checkBatchPre}/findDetailById/${id}`),
  save: (data) => postDataAction(`${checkBatchPre}/save`, JSON.stringify(data)),
  update: (data) => postDataAction(`${checkBatchPre}/update`, data),
  verify: (data) => postDataAction(`${checkBatchPre}/verify`, data),
  deleteById: (id) => delAction(`${checkBatchPre}/delete/${id}`),
  claim: (data) => postDataAction(`${checkBatchPre}/claim`, data),
  assign: (data) => postDataAction(`${checkBatchPre}/assign`, data),
  review: (data) => postAction(`${checkBatchPre}/review`, data),
  check: (data) => postAction(`${checkBatchPre}/check`, data),
  batchReview: (data) => postDataAction(`${checkBatchPre}/batchReview`, data),
  batchCheck: (data) => postDataAction(`${checkBatchPre}/batchCheck`, data),
  selectLocationByDevId : (param) => getAction(`${checkBatchPre}/selectLocationByDevId`, param),
  deleteCheckTaskBatch : (param) => delAction(`${checkBatchPre}/deleteCheckTaskBatch`, param),
  getReviewedCheckUser : (param) => postAction(`${checkBatchPre}/getReviewedCheckUser`, param), // 获取检测任务指派人
  getBatchAutoReview : (param) => postAction(`${checkBatchPre}/getBatchAutoReview`, param), // 获取检测任务是否自动复核，如果返回值是1 ，就是自动复核
  batchReview : (param) => postAction(`${checkBatchPre}/batchReview`, param), // 检测任务自动复核
  mergeaBatch : (param) => postDataAction(`${checkBatchPre}/mergeaBatch`, param), // 检测任务批次合并
  queryDateByBatchId : (id) => getAction(`${checkBatchPre}/queryDateByBatchId/${id}`) // 检测任务复合批次录入中查询多个检出限
}

/**
 * 检测批次曲线信息
 */
export const checkBatchCurveApi = {

  /**
   * 根据批次获取对应的曲线信息
   * @param batchId {Number} 批次ID
   * @return {Promise}
   */
  getCurveByBatchId: (batchId) => getAction(`${checkBatchCurvePre}/getByBatchId/${batchId}`)
}
