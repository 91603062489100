<template>
  <div class="upload-file">
    <a-upload
      :action="uploadUrl"
      :listType="listType"
      :fileList="localFileList"
      :headers="tokenHeader"
      :multiple="multiple"
      :accept="accept"
      :remove="handleFileRemove"
      @preview="handlePreview"
      @change="handleFileChange">
      <template v-if="multiple||(!multiple&&localFileList.length==0)">
        <template v-if="listType=='picture-card'">
          <div><a-icon type="upload"/><br/>{{ title }}</div>
        </template>
        <template v-else>
          <a-button><a-icon type="upload"/> {{ title }} </a-button>
        </template>
      </template>
    </a-upload>
    <a-modal v-bind="previewModal" v-on="previewModal">
      <img :alt="previewModal.file.name" style="width: 100%" :src="previewModal.file.url" />
      <a-row style="text-align: center"><a :href="previewModal.file.url">下载</a></a-row>
    </a-modal>
  </div>
</template>

<script>
import { sysCommonApi } from '@/api/common'

export default {
  name: 'UploadFile',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '点击上传文件'
    },
    fileList: {
      type: Array,
      default: () => ([])
    },
    listType: {
      type: String,
      default: 'text' // text, picture 和 picture-card
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      downLoad: sysCommonApi.downLoad,
      downLoadById: sysCommonApi.downLoadById,
      localFileList: [],
      oldFileIdList: [],
      delFileIdList: [],

      previewModal: {
        visible: false,
        footer: null,
        file: {},
        open: (file) => {
          this.previewModal.file.url = file.url || file.preview
          this.previewModal.file.name = file.name
          this.previewModal.visible = true
        },
        cancel: () => {
          this.previewModal.visible = false
        }
      }
    }
  },
  methods: {
    handlePreview (file) {
      if (this.listType === 'picture' || this.listType === 'picture-card') {
        this.previewModal.open(file)
      } else {
        window.open(file.url || file.preview, '_blank')
      }
    },
    handleFileRemove (file) {
      var self = this
      const index = self.localFileList.indexOf(file)
      const localFileList = self.localFileList.slice()
      localFileList.splice(index, 1)
      self.localFileList = localFileList
      self.makeNewFileList()
    },
    handleFileChange (info) {
      var self = this
      self.localFileList = info.fileList
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          var fileListTemp = info.fileList
          if (fileListTemp) {
            fileListTemp.forEach((file, idx) => {
              if (!fileListTemp[idx].data) {
                var filePath = fileListTemp[idx].response.data.fileUrl
                var fileName = fileListTemp[idx].response.data.fileName
                fileListTemp[idx].url = `${this.downLoad}?filePath=${filePath}&fileName=${fileName}`
                fileListTemp[idx].data = fileListTemp[idx].response.data
              }
              delete fileListTemp[idx].response
              delete fileListTemp[idx].originFileObj
              delete fileListTemp[idx].lastModifiedDate
            })
          }
          self.makeNewFileList()
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    makeNewFileList () {
      var newFileList = []
      var delFileIds = [] // 需要删除文件
      var tempFileIdList = [] // 剩余的老文件
      this.localFileList.forEach((file, idx) => {
        if (file.data && !file.data.id) {
          newFileList.push(file.data)
        } else {
          tempFileIdList.push(file.data.id)
        }
      })
      // 查询需要删除的文件ID
      this.oldFileIdList.forEach(fid => {
        if (tempFileIdList.indexOf(fid) === -1) {
          delFileIds.push(fid)
        }
      })
      var map = { newFileList: newFileList, newFileListStr: JSON.stringify(newFileList), delFileIdsStr: delFileIds.toString() }
      this.$emit('ok', map)
    },
    makeFileList () {
      const self = this
      var fileList = self.fileList
      var updateFileList = []
      var oldFileIdList = []
      if (fileList) {
        fileList.forEach((file, idx) => {
          updateFileList.push({
            uid: idx,
            name: file.fileName,
            status: 'done',
            url: `${this.downLoad}?filePath=${file.fileUrl}&fileName=${file.fileName}`,
            fileOld: true, // 上次的文件
            data: file
          })
          oldFileIdList.push(file.id)
        })
      }
      self.localFileList = updateFileList
      self.oldFileIdList = oldFileIdList
    }
  },
  computed: {
  },
  watch: {
    fileList (nv, ov) {
      this.makeFileList()
    }
  },
  filters: {
  },
  mounted () {
    this.makeFileList()
  },
  created () {
  }
}
</script>

<style lang="less">
  .upload-file {
    .ant-upload.ant-upload-select-picture-card{
      width: 90px;
      height: 90px;
    }
  }
</style>
