<template>
  <div>
    <a-modal v-bind="modal" v-on="modal">
      <a-card :bordered="false" class="card-table" @keyup.enter="search" style="margin-top: 0;">
        <a-form>
          <a-row :gutter="rowProps.gutter">
            <a-col span="18">
              <a-form-item label="名称" v-bind="formItemLayout">
                <a-input v-model="queryParam.name" placeholder=""/>
              </a-form-item>
            </a-col>
            <a-col span="6">
              <span class="table-page-search-submitButtons">
                <a-button-group>
                  <a-button type="primary" icon="search" @click="search">查询</a-button>
                </a-button-group>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <s-table v-bind="table" v-on="table">
        </s-table>
      </a-card>
    </a-modal>
  </div>
</template>

<script>

import { resManufApi, Get } from '@/api/quote'
import { STable } from '@/components'
import { currency } from '@/utils/util'
import ARow from 'ant-design-vue/es/grid/Row'

export default {
  name: 'BusinessList',
  components: {
    ARow,
    STable
  },
  data () {
    return {
      rowProps: {
        type: 'flex',
        justify: 'start',
        align: 'middle',
        gutter: { xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 60 }
      },
      colProps: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      formItemLayout: {
        labelCol: {
          xxl: { span: 6 },
          xl: { span: 6 },
          lg: { span: 6 },
          md: { span: 6 },
          sm: { span: 6 },
          xs: { span: 6 }
        },
        wrapperCol: {
          xxl: { span: 18 },
          xl: { span: 18 },
          lg: { span: 18 },
          md: { span: 18 },
          sm: { span: 18 },
          xs: { span: 18 }
        }
      },

      modal: {
        title: '商家选择',
        visible: false,
        centered: true,
        closable: false,
        width: '850px',
        destroyOnClose: true,
        maskClosable: false,
        cancel: this.close,
        ok: this.ok,
        okText: '确定',
        zIndex: 3002
      },

      moreParam: false,
      queryParam: {
      },
      url: {
        list: resManufApi.list
      },
      table: {
        ref: 'table',
        size: 'small',
        loading: false,
        // dataSource: [],
        selectedRowKeys: [],
        selectedRows: [],
        showPagination: true,
        scroll: {
          y: '500px'
        },
        rowKey: (record) => {
          return record.id
        },
        columns: [
          { title: '名称', dataIndex: 'name', width: '200px' },
          { title: '联系人', dataIndex: 'linkPerson', width: '80px' },
          { title: '联系电话', dataIndex: 'linkPhone', width: '100px' }
        ],
        data: parameter => {
          var params = Object.assign(parameter, this.queryParam)
          // var params = this.queryParam
          return Get(this.url.list, params).then(res => {
            return res
          })
        },
        rowSelection: {
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) => {
            this.table.selectedRowKeys = selectedRowKeys
            this.table.selectedRows = selectedRows
          }
        }
      }
    }
  },
  methods: {
    currency,
    open (param) {
      this.modal.visible = true
      this.table.selectedRowKeys = [param.id]
    },
    search () {
      this.$refs.table.refresh(true)
    },
    resetSearch () {
      this.queryParam = { }
      this.search()
    },
    ok () {
      if (this.table.selectedRows.length > 0) {
        var obj = this.table.selectedRows[0]
        this.$emit('ok', obj)
        this.close()
      } else {
        this.$message.error('还没选择商家!')
      }
    },
    close () {
      this.modal.visible = false
    }
  }
}
</script>

<style scoped>

</style>
