<template>
  <div class="check-reagent-list">
    <a-card v-bind="searchCard">
      <a-form v-bind="searchForm">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="综合查询">
              <a-input v-model.trim.lazy="queryParam.compositionName" placeholder="名称模糊查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps" :style="{ textAlign: 'left',marginBottom:'15px' }">
            <a-button-group>
              <a-button icon="search" type="primary" @click="search">查询</a-button>
              <a-button icon="reload" @click="reset">重置</a-button>
            </a-button-group>
          </a-col>
        </a-row>

        <a-row v-show="toggleSearchStatus" v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item v-bind="formItemLayout" label="ID">
              <a-input v-model.trim.lazy="queryParam.id" placeholder="ID查询" @pressEnter="search"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
    </a-card>

    <!-- 操作区域 -->
    <a-card v-bind="actionCard">
      <div class="fr">
        <template v-if="rowSelection.selectedRowKeys.length">
          <a-button class="mar-r16" v-action:check-reagent-cate:add @click="deleteCate(rowSelection.selectedRows)">
            <a-icon type="delete"/>
            批量作废
          </a-button>
        </template>

        <a-button v-action:check-reagent-cate:add type="primary" @click="cateFormModal.add">
          <a-icon type="plus"/>
          类目
        </a-button>
        <!-- <div style="font-size: 12px; color: red">提示：此数据来源于标准库，如需新增或修改请联系管理员进行操作。管理员：张工，手机号：13546153655</div> -->
      </div>
    </a-card>

    <!-- 展现区域 -->
    <a-card v-bind="contentCard">
      <check-reagent-cate-select-table v-bind="tableProps">
        <template #action="{index:idx,row:record}">
          <template v-if="record.id !==-1">
            <a @click="cateFormModal.edit(record)" v-action:check-reagent-cate:edit> 调整
              <a-divider type="vertical"/>
            </a>
            <a @click="cateFormModal.copy(record)" v-action:check-reagent-cate:add> 复制
              <a-divider type="vertical"/>
            </a>
            <a @click="deleteCate([record])" v-action:check-reagent-cate:delete> 作废 </a>
          </template>
        </template>
      </check-reagent-cate-select-table>
    </a-card>

    <a-modal v-if="cateFormModal.visible" v-bind="cateFormModal">
      <check-reagent-cate-form v-bind="cateFormModal.data" v-on="cateFormModal.data"></check-reagent-cate-form>
      <template #footer>
        <a-button @click="cateFormModal.cancel">取消</a-button>
        <a-button type="primary" @click="cateFormModal.submit">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { withComponentList } from '@/pages/common/mixins/list'
import fullModal from '@/pages/common/mixins/modal/full'

import { CheckReagentCateSelectTable } from '@/pages/cloud-quote/check-reagent/cate/modules'
import { checkReagentCateApi } from '@/api/checkReagent/checkReagentApi'

const fullModalData = fullModal.data().fullModal

export default {

  components: { CheckReagentCateSelectTable },
  mixins: [withComponentList],

  data () {
    return {

      cateFormModal: Object.assign({}, fullModalData, {
        visible: false,
        title: '类目维护',
        width: 1000,
        bodyStyle: { background: '#F0F2F5' },

        data: {
          ref: 'cateForm',
          id: 0,
          type: 'add',
          success: () => {
            this.reload()
            this.cateFormModal.cancel()
          },
          cancel: () => {
            this.cateFormModal.cancel()
          }
        },

        add: () => {
          this.cateFormModal.data.id = 0
          this.cateFormModal.data.type = 'add'
          this.cateFormModal.show()
        },

        edit: (record) => {
          this.cateFormModal.data.id = record.id
          this.cateFormModal.data.type = 'edit'
          this.cateFormModal.show()
        },

        copy: (record) => {
          this.cateFormModal.data.id = record.id
          this.cateFormModal.data.type = 'copy'
          this.cateFormModal.show()
        },

        show: () => {
          this.cateFormModal.visible = true
        },

        submit: () => {
          this.$refs[this.cateFormModal.data.ref].submit()
        },

        cancel: () => {
          this.cateFormModal.visible = false
        }
      }),

      contentCard: {
        title: '',
        bordered: true,
        hoverable: false,
        type: 'inner',
        style: { marginTop: '0px' },
        headStyle: {},
        bodyStyle: {},
        class: 'card-table'
      },

      categorySource: [],

      queryParam: {
        name: null,
        compositionName: null,
        cateId: null
      }
    }
  },

  computed: {
    tableProps () {
      return Object.assign({ rowSelect: this.rowSelection }, this.customTable)
    }
  },

  methods: {
    // 删除类目
    deleteCate (records) {
      const list = records?.filter(item => item.id)

      if (list && list.length) {
        const content = list.length === 1
? `请确认是否删除【${list[0].name}】类目,删除后将不可恢复！`
          : '请确认是否删除这批类目,删除后将不可恢复！'

        this.$confirm({
          title: '确认删除?',
          content,
          onOk: () => {
            checkReagentCateApi.delete({ ids: list.map(item => item.id) }).then(res => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.clearSelect()
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/common/snippet";

.check-reagent-list {
  .margin();
}

</style>
