'use strict'

const defaultColumn = [
  { title: '试剂编号', dataIndex: 'reagentNo', sorter: true, align: 'left', scopedSlots: { customRender: 'reagentNo' } },
  { title: '试剂名称', dataIndex: 'name', align: 'left', sorter: true, scopedSlots: { customRender: 'name' } },
  { title: '类目名称', dataIndex: 'cateName', align: 'left', sorter: true, scopedSlots: { customRender: 'cateName' } },
  { title: '浓度', dataIndex: 'purity', align: 'left', scopedSlots: { customRender: 'purity' } },
  { title: '储备量', dataIndex: 'sumVolume', align: 'left', scopedSlots: { customRender: 'sumVolume' } },
  { title: '配置时间', dataIndex: 'configTime', sorter: true, align: 'left', scopedSlots: { customRender: 'configTime' } },
  { title: '配置人', dataIndex: 'createUserName', align: 'left', sorter: true, scopedSlots: { customRender: 'createUserName' } },
  { title: '状态', dataIndex: 'status', align: 'left', sorter: true, scopedSlots: { customRender: 'status' } },
  { title: '复核时间', dataIndex: 'recheckTime', align: 'left', scopedSlots: { customRender: 'recheckTime' } },
  { title: '复核人', dataIndex: 'recheckUserName', align: 'left', scopedSlots: { customRender: 'recheckUser' } },
  { title: '有效截止日', dataIndex: 'validTime', align: 'left', scopedSlots: { customRender: 'validTime' } },
  { title: '操作', dataIndex: 'action', align: 'left', width: '13%', scopedSlots: { customRender: 'action' } }
]

const selectColumn = [
  { title: '试剂编号', dataIndex: 'reagentNo', align: 'left', scopedSlots: { customRender: 'reagentNo' } },
  { title: '试剂名称', dataIndex: 'name', align: 'left', scopedSlots: { customRender: 'name' } },
  { title: '类目名称', dataIndex: 'cateName', align: 'left', scopedSlots: { customRender: 'cateName' } },
  { title: '储备量', dataIndex: 'sumVolume', align: 'left', scopedSlots: { customRender: 'sumVolume' } },
  { title: '配置时间', dataIndex: 'configTime', align: 'left', scopedSlots: { customRender: 'configTime' } },
  { title: '配置人', dataIndex: 'createUserName', align: 'left', scopedSlots: { customRender: 'createUserName' } },
  { title: '状态', dataIndex: 'status', align: 'left', scopedSlots: { customRender: 'status' } },
  { title: '复核时间', dataIndex: 'recheckTime', align: 'left', scopedSlots: { customRender: 'recheckTime' } },
  { title: '复核人', dataIndex: 'recheckUser', align: 'left', scopedSlots: { customRender: 'recheckUser' } },
  { title: '有效截止日', dataIndex: 'validTime', align: 'left', scopedSlots: { customRender: 'originalValidTime' } }
]

export {
  defaultColumn as checkReagentDefaultColumn,
  selectColumn as checkReagentSelectColumn
}
