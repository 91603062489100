import '@/assets/less/common/form.less'
import { throttle } from 'lodash'
import { nextTick, reactive, ref, watch } from 'vue'
import { useSearchFormProps, useSearch } from '@/pages/common/compositions/list/useSearch'

/**
 *
 * 创建外部搜索属性
 * @param tableRef 表格组件
 */
export const useOutSearchProps = (tableRef = null) => {
  const { moreParam, currentColSize, searchCard, searchForm, rowProps, colProps, actionColProps, formItemLayout } = useSearchFormProps()

  /**
   * 更新表格高度
   */
  const defaultUpdateTableHeight = () => {
    tableRef instanceof Object && tableRef?.value?.updateTableHeight?.()
    tableRef instanceof Array && tableRef?.value.forEach(item => item?.updateTableHeight?.())
  }

  watch(() => moreParam.value, () => nextTick(() => defaultUpdateTableHeight()))

  return {
    moreParam,
    currentColSize,
    searchCard,
    searchForm,
    rowProps,
    colProps,
    actionColProps,
    formItemLayout,
    defaultUpdateTableHeight
  }
}

/**
 * 外部搜索的Hook
 * @param tableRef 表格组件
 * @param queryOriginalParam {object} 查询参数
 * @param handleLoading {function} 处理加载状态
 * @return {{clearSelect: function, rowSelection: array, search: function, reload: function, reset: function, customTableAction: (function(): {loading: function(*): void}), loading: *, resetQueryParam: function}}
 */
export const useOutSearch = (tableRef, queryOriginalParam = {}, handleLoading) => {
  /** 加载引用的KEY，每次加载过程中会自动切换，从而达到刷新组件的目的  */
  const loading = ref(false)
  const { loadKey, resetQueryParam } = useSearch(tableRef, queryOriginalParam)

  const customTableAction = () => ({
    loading: (value) => { loading.value = value }
  })

  const rowSelection = reactive({ selectedRows: [], selectedRowKeys: [] })
  watch(() => loading, (value) => { handleLoading?.(value) })

  /**
   * 查询方法
   */
  const search = throttle(() => {
    loadKey.value = !loadKey.value
    nextTick(() => {
      tableRef?.value instanceof Object && tableRef.value?.search?.()
      tableRef?.value instanceof Array && tableRef.value?.forEach(item => item?.search?.())
    })
  }, 50, { leading: false, trailing: true })

  /**
   * 重新加载
   */
  const reload = throttle(() => {
    loadKey.value = !loadKey.value
    tableRef?.value instanceof Object && tableRef.value?.reload?.()
    tableRef?.value instanceof Array && tableRef.value?.forEach(item => item?.reload?.())
  }, 50, { leading: false, trailing: true })

  /**
   * 重置查询
   */
  const reset = () => {
    resetQueryParam()
    nextTick(() => search())
  }

  /**
   * 清除列表选择
   */
  const clearSelect = throttle(() => {
    tableRef?.value instanceof Object && tableRef.value?.clearSelect?.()
    tableRef?.value instanceof Array && tableRef.value?.forEach(item => item?.clearSelect?.())
  }, 50, { leading: false, trailing: true })

  return {
    loading,
    customTableAction,
    rowSelection,
    resetQueryParam,
    search,
    reload,
    reset,
    clearSelect
  }
}
