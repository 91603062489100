<template>
  <div>
    <a-modal v-bind="projectModalProps">
      <a-card v-bind="baseFormCard">
        <a-row v-bind="rowProps">
          <a-col v-bind="colProps">
            <a-form-item label="项目编号" v-bind="formItemLayout">
              <a-input v-model="searchForm.projNo"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="项目名称" v-bind="formItemLayout">
              <a-input v-model="searchForm.projName"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="colProps">
            <a-form-item label="受检单位" v-bind="formItemLayout">
              <a-input v-model="searchForm.csrName"></a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="formItemLayout">
            <div style="display: flex;gap: 18px;margin-left: 50px;">
              <a-button type="primary" @click="$refs['projectTable'].search()">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-card>
      <a-card class="table-card">
        <receive-sample-project-table ref="projectTable" v-bind="searchForm">
          <template #action="{row:record}">
            <a @click="receive(record)">开始接样</a>
          </template>
        </receive-sample-project-table>
      </a-card>

      <template #footer>
        <a-button @click="cancel">关闭</a-button>
      </template>
    </a-modal>

    <a-modal v-bind="fullModal" v-on="fullModal">

      <a-card v-bind="sampleGiverInfoCard">

        <template #extra>
          快速筛选：
          天数：
          <a-input-number v-model="searchPeriod" @change="periodFrequencyChange"></a-input-number>
          <template v-if="searchPeriod">
            频次：
            <a-input-number v-model="searchFrequency" @change="periodFrequencyChange"></a-input-number>
          </template>
        </template>
        <a-form :style="{width:'100%'}" layout="inline">

          <a-col :span="8">
            <a-form-item label="送样人" v-bind="sampleGiverInfoCard.formItemProps">
              <a-select v-bind="sampleGiverSelect" v-on="sampleGiverSelect">
                <a-icon slot="suffixIcon" type="plus-square" @click="showAddCustomerUser"/>
                <a-select-option v-for="user in this.customerUserList" :key="user.id">{{ user.csrUserName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="联系电话" v-bind="sampleGiverInfoCard.formItemProps">
              <a-input v-model="sampleGiverInfo.sampleGiverTel">
                <template slot="suffix">
                  <a-tooltip>
                    <template slot="title">
                      点我更新联系电话
                    </template>
                    <a-popconfirm title="确定更新联系电话吗？" @confirm="updatePhone">
                      <a-icon :style="{color:'#1EBF1E',fontWeight:'600'}" type="reload"></a-icon>
                    </a-popconfirm>
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="送样时间" v-bind="sampleGiverInfoCard.formItemProps">
              <a-date-picker v-model="sampleGiverInfo.storageApplyTime" placeholder="送样时间" v-bind="moreCurrentDatePickerProps"/>
            </a-form-item>
          </a-col>

        </a-form>
      </a-card>
      <div class="card-container">
        <a-tabs :tabBarGutter="6" :tabBarStyle="{ height: getMaxHeight(),padding:'6px 0',margin:'-16px 0 -16px 0'}" tab-position="left">
          <a-tab-pane v-for="(cate,cateId) of cateSiteSortMap" :key="`cate_`+cateId" :tab="cate.cateName">
            <a-tabs :tabBarGutter="6" :tabBarStyle="{ height: getMaxHeight(),padding:'6px 0',margin:'-16px 0 -16px -12px'}" tab-position="left">
              <a-tab-pane v-for="(site) in cate.siteList" :key="`cate_`+cateId+`site_`+site.siteId" :tab="site.siteName">
                <a-tabs>
                  <template v-for="numPeriod in getArrayBySize(site.numPeriod)">
                    <template v-for="numFrequency in getArrayBySize(site.numFrequency)">
                      <a-tab-pane :key="`cate_`+cateId+`site_`+site.siteId+`D${numPeriod}-${numFrequency}`" :tab="`D${numPeriod}-${numFrequency}`">
                        <ReceiveSampleForm
                          v-if="itemGroup.hasOwnProperty(cateId)&& needGroup &&startReceiveFlag"
                          ref="receiveSampleForm"
                          :cate-id="parseInt(cateId)"
                          :cate-site-num="getBySiteSort(cateId,site.siteId)"
                          :groupInfo="itemGroup[cateId][site.siteId]"
                          :itemAliasMap="itemAliasMap"
                          :itemInfo="cate.siteMap[site.siteId].itemInfo"
                          :need-group="needGroup"
                          :num-frequency="parseInt(numFrequency)"
                          :num-period="parseInt(numPeriod)"
                          :proj-id="checkProject.projId"
                          :proj-no="checkProject.projNo"
                          :proj-task-id="site.taskId"
                          :projInfo="checkProject"
                          :sample-config="sampleConfig"
                          :sampleStatusDictList.sync="SampleStatusDictDetail"
                          :site-id="site.siteId"
                          :site-num="site.siteSort"></ReceiveSampleForm>

                        <ReceiveSampleForm
                          v-else-if="startReceiveFlag"
                          ref="receiveSampleForm"
                          :cate-id="parseInt(cateId)"
                          :cate-site-num="getBySiteSort(cateId,site.siteId)"
                          :itemAliasMap="itemAliasMap"
                          :itemInfo="cate.siteMap[site.siteId].itemInfo"
                          :need-group="needGroup"
                          :num-frequency="parseInt(numFrequency)"
                          :num-period="parseInt(numPeriod)"
                          :proj-id="checkProject.projId"
                          :proj-no="checkProject.projNo"
                          :proj-task-id="site.taskId"
                          :projInfo="checkProject"
                          :sample-config="sampleConfig"
                          :sampleStatusDictList.sync="SampleStatusDictDetail"
                          :site-id="site.siteId"
                          :site-num="site.siteSort"></ReceiveSampleForm>
                      </a-tab-pane>
                    </template>
                  </template>
                </a-tabs>
              </a-tab-pane>
            </a-tabs>
          </a-tab-pane>
        </a-tabs>
      </div>
      <template slot="footer">
        <a-button :style="{marginRight: '8px'}" @click="cancel">关闭</a-button>
        <a-button :style="{marginRight: '8px'}" @click="startReceiveFlag=false">返回</a-button>
        <a-button :style="{marginRight: '8px'}" type="primary" @click="handleSubmit">接样</a-button>
      </template>
    </a-modal>

    <CustomerUserModal ref="customerUserModal" :customerList="customerList" @ok="customerUserOk"></CustomerUserModal>
  </div>
</template>

<script>

import fullModal, { fullModalData } from '@/pages/common/mixins/modal/full'

import mixinForm from '@/pages/common/mixins/form'

import { orderBy, uniqBy } from 'lodash'
import { tsSampleinfoApi } from '@/api/sample'
import { projTaskItemApi } from '@/api/project'
import { customerUserApi, jcListApi } from '@/api/quote'
import { dictApi } from '@/api/system'
import { customFunc } from '@/pages/cloud-quote/take-sample/modules/customForm/CustomFormFunc'

import CustomerUserModal from '@/pages/cloud-quote/customer-user/Form'
import ReceiveSampleForm from '@/pages/cloud-quote/sample-info/modules/receive/ReceiveSampleForm'
import { configApi } from '@/api/common'
import ReceiveSampleProjectTable from './table'

export default {
  name: 'ReceiveSample',

  components: { ReceiveSampleForm, CustomerUserModal, ReceiveSampleProjectTable },

  mixins: [fullModal, mixinForm],

  data () {
    return {
      sampleConfig: null,
      currentColSize: 4,
      customerUserList: [],
      searchFrequency: null,
      searchPeriod: null,

      SampleStatusDictDetail: [],

      sampleGiverInfo: {
        sampleGiver: null,
        sampleGiverName: null,
        sampleGiverTel: null,
        storageApplyTime: null
      },

      projectLoadMsg: '正在查询中，请稍后...',
      searchForm: {
        projNo: '',
        projName: '',
        csrName: ''
      },

      needGroup: false,

      // 检测项别名
      itemAliasMap: {},

      // 开始接样标识
      startReceiveFlag: false,

      // 检测分类 => 点位 => 检测项
      projectMap: {},
      cateToSiteMap: {},
      cateToSiteBackUpMap: {},
      cateSiteSortMap: [],
      // 检测项分组信息
      itemGroup: {},
      checkProject: null,

      projectSelectProps: {
        style: { width: '90%' },
        placeholder: '支持项目编号、名称模糊搜索',
        allowClear: true,
        autoFocus: true,
        showSearch: true,
        filterOption (input, option) {
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      },

      projectModalProps: Object.assign({}, fullModalData, {
        visible: false,
        bodyStyle: { background: '#F0F2F5' },
        title: '请选择项目'
      }),

      fullModal: {
        bodyStyle: {},
        title: '接样',
        cancel: this.cancel
      },

      sampleGiverInfoCard: {
        hoverable: true,
        title: '送样人员信息',
        bordered: true,
        style: { marginBottom: '18px' },
        type: 'inner',
        formItemProps: {
          style: { width: '100%' },

          labelCol: {
            xxl: { span: 3, offset: 1 },
            xl: { span: 3, offset: 1 },
            lg: { span: 6 },
            md: { span: 6 },
            sm: { span: 6 },
            xs: { span: 6 }
          },

          wrapperCol: {
            xxl: { span: 20 },
            xl: { span: 20 },
            lg: { span: 18 },
            md: { span: 18 },
            sm: { span: 18 },
            xs: { span: 18 }
          }
        }
      },

      sampleGiverSelect: {
        allowClear: false,
        autoFocus: true,
        showSearch: true,
        optionFilterProp: 'children',
        defaultValue: null,
        change: (val) => {
          const checkUser = this.customerUserList.find(item => { return item.id === val }) || null
          this.sampleGiverInfo.sampleGiver = checkUser.id
          this.sampleGiverInfo.sampleGiverName = checkUser.csrUserName
          this.sampleGiverInfo.sampleGiverTel = checkUser.csrUserTelephone
        },
        filterOption (input, option) {
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      },

      sampleInfoCard: {
        hoverable: true,
        bordered: true,
        style: { marginBottom: '18px' },
        type: 'inner'
      },

      formItemLayout: {
        labelCol: {
          xxl: { span: 4 },
          xl: { span: 4 },
          lg: { span: 4 },
          md: { span: 4 },
          sm: { span: 4 },
          xs: { span: 4 }
        },
        wrapperCol: {
          xxl: { span: 20 },
          xl: { span: 20 },
          lg: { span: 20 },
          md: { span: 20 },
          sm: { span: 20 },
          xs: { span: 20 }
        }
      }
    }
  },

  computed: {
    customerList () {
      return this.checkProject && [{ id: this.checkProject.csrId, csrName: this.checkProject.csrName }] || []
    }
  },

  watch: {
    'projectModalProps.visible' (newVal) {
      if (newVal) {
        this.initData()
      }
    },

    checkProject (val) {
      if (val) {
        this.listCustomerUser(val.entrustId)
        const defaultSampleGiver = val.sampleGivers?.split(',')?.[0] ?? val.entrustContacts?.split(',')?.[0]
        const defaultSampleGiverName = val.sampleGiverNames?.split(',')?.[0] ?? val.entrustContactsName?.split(',')?.[0]
        const defaultSampleGiverTel = val.sampleGiverTels?.split(',')?.[0] ?? val.entrustContactsPhone?.split(',')?.[0]
        this.sampleGiverSelect.defaultValue = defaultSampleGiver ? parseInt(defaultSampleGiver) : null
        this.sampleGiverInfo.sampleGiver = defaultSampleGiver ? parseInt(defaultSampleGiver) : null
        this.sampleGiverInfo.sampleGiverName = defaultSampleGiverName
        this.sampleGiverInfo.sampleGiverTel = defaultSampleGiverTel
      }
    },

    startReceiveFlag (val) {
      if (val) {
        this.projectModalProps.visible = false
        this.fullModal.visible = true
        this.queryProjectReceiveSample()
      } else {
        this.projectModalProps.visible = true
        this.fullModal.visible = false
      }
    }

  },

  created () {
    this.initSampleNumberConfig()
    this.queryItemAliasMap()
    this.checkSampleStatusDict()
    this.sampleGiverInfo.storageApplyTime = this.now()
  },

  methods: {

    // 时间限制 不能大于当前时间
    moreCurrentDate (current) {
      return current && (current > this.moment() || current < this.moment(this.checkProject.projDate))
    },
    getArrayBySize (size) {
      return Array.from({ length: size }, (item, index) => index + 1)
    },

    // 加载样品编号配置
    initSampleNumberConfig () {
      configApi.getByName({ name: 'sampleNumberConfig' }).then(res => {
        if (res.code === 0) {
          const sampleConfig = res.data?.content
          if (sampleConfig == null) {
            this.$message.error('样品编号规则未配置，请先配置后再接样！')
          } else {
            this.sampleConfig = sampleConfig
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 查询客户信息
    listCustomerUser (csrId) {
      customerUserApi.dataList({ page: false, csrId: csrId }).then(result => {
        if (result.code === 0) {
          this.customerUserList = result.data
        }
      })
    },

    // 新增客户信息窗体
    showAddCustomerUser () {
      this.$refs.customerUserModal.addByCsrId(this.checkProject.csrId)
    },

    // 处理新增客户成功
    customerUserOk (data) {
      this.customerUserList.push(data)
    },

    updatePhone () {
      const checkUser = this.customerUserList.find(item => { return item.id === this.sampleGiverInfo.sampleGiver }) || null
      if (!checkUser) {
        this.$message.error('暂未获取到有效的客户数据')
      }

      checkUser.csrUserTelephone = this.sampleGiverInfo.sampleGiverTel
      customerUserApi.update(checkUser).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    initData () {
      Promise.all([this.queryItemAliasMap(), this.checkSampleStatusDict()])
    },

    // 查询检测项别名
    queryItemAliasMap () {
      return tsSampleinfoApi.getItemAliasMap().then(res => {
        if (res.code === 0) {
          Object.assign(this.itemAliasMap, res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 接样
    receive (record) {
      this.checkProject = record
      this.startReceiveFlag = true
    },

    // 获取项目待接样的信息
    queryProjectReceiveSample () {
      if (this.checkProject) {
        this.queryProjectTaskItem(this.checkProject.projId)
        if (this.needGroup) this.queryItemGroupInfo(this.checkProject.projId)
      } else {
        this.$message.error('暂无获取到项目信息，请稍后再试！')
      }
    },

    // 项目对应的检测项信息， 转换成 分类->点位->检测项
    queryProjectTaskItem (projId) {
      const cacheMap = this.projectMap[projId]
      if (cacheMap) {
        this.cateSiteSortMap = cacheMap.cateSiteSortMap
        this.cateToSiteMap = cacheMap.cateToSiteMap
        this.cateToSiteBackUpMap = cacheMap.cateToSiteBackUpMap
      } else {
        projTaskItemApi.detailList({ projId: projId, page: false }).then(res => {
          if (res.code === 0) {
            const data = orderBy(res.data, ['siteSort'], ['asc'])
            // 构建分类 => 点位集合
            const cateSiteSortMap = this.dealCateToSite(data)
            Object.values(cateSiteSortMap).forEach(item => {
              item.siteList = uniqBy(item.siteList, 'siteId')
            })
            this.cateSiteSortMap = cateSiteSortMap

            const numPeriodGroup = data.reduce((res, data) => {
              for (let numPeriod = 1; numPeriod <= data.numPeriod; numPeriod++) {
                (res[numPeriod] || (res[numPeriod] = [])).push(data)
              }
              return res
            }, {})

            Object.keys(numPeriodGroup).map(key => {
              const frequencyGroup = numPeriodGroup[key].reduce((res, data) => {
                for (let frequency = 1; frequency <= data.numFrequency; frequency++) {
                  (res[frequency] || (res[frequency] = [])).push(data)
                }
                return res
              }, {})

              Object.keys(frequencyGroup).forEach(fKey => {
                frequencyGroup[fKey] = this.dealCateToSite(frequencyGroup[fKey])
              })
              numPeriodGroup[key] = frequencyGroup
            })
            this.cateToSiteMap = numPeriodGroup
            this.cateToSiteBackUpMap = numPeriodGroup
            this.projectMap[projId] = {
              cateSiteSortMap,
              cateToSiteMap: numPeriodGroup,
              cateToSiteBackUpMap: numPeriodGroup
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    periodFrequencyChange () {
      const { searchPeriod, searchFrequency } = this
      if (searchPeriod) {
        const data = this.cateToSiteBackUpMap[searchPeriod]
        if (data) {
          const result = {}
          if (searchFrequency) {
            result[searchPeriod] = {}
            result[searchPeriod][searchFrequency] = data[searchFrequency]
          } else {
            result[searchPeriod] = data
          }
          this.cateToSiteMap = Object.assign({}, result)
        }
      }
    },

    dealCateToSite (data) {
      const cateResult = data && (
        data.reduce((cateRes, item) => {
          (cateRes[item.cateId] || (cateRes[item.cateId] = { cateId: item.cateId, cateName: item.cateName, siteList: [] })).siteList.push(item)
          return cateRes
        }, {})
      ) || {}

      // 构建 点位 => 检测项
      Object.keys(cateResult).forEach(item => {
        cateResult[item].siteMap = {}
        cateResult[item].siteList && cateResult[item].siteList.reduce((siteRes, siteData) => {
          (siteRes[siteData.siteId] ||
            (siteRes[siteData.siteId] =
              { siteId: siteData.siteId, siteNum: siteData.siteSort, projTaskId: siteData.taskId, siteName: siteData.siteName, itemInfo: { id: siteData.cateId, name: siteData.cateName, children: [] } })).itemInfo.children.push({
            id: siteData.itemId,
            itemSampleCode: siteData.itemSampleCode,
            name: siteData.itemName
          })
          return siteRes
        }, cateResult[item].siteMap)
      })
      return cateResult
    },

    // 项目对应的检测项分组信息
    queryItemGroupInfo (projId) {
      jcListApi.projItemGroupList({ projId: projId }).then(res => {
        if (res.code === 0) {
          this.itemGroup = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handleSubmit () {
      if (!this.sampleGiverInfo.sampleGiver) {
        this.$message.error('请维护送样人信息')
        return
      }
      const samples = this.$refs.receiveSampleForm.reduce((initArr, item) => {
        initArr.push.apply(initArr, item.getResult())
        return initArr
      }, [])

      if (samples && samples.length) {
        const noSaveSamples = samples.filter(item => item.editable || item.isNew)
        if (noSaveSamples && noSaveSamples.length) {
          this.$message.error('您还有样品信息未保存，请先保存后再试')
          return false
        }

        samples.forEach(item => {
          Object.assign(item, this.sampleGiverInfo)
        })

        tsSampleinfoApi.batchReceive({ models: samples, applyTime: this.sampleGiverInfo.storageApplyTime }).then(res => {
          if (res.code === 0) {
            this.addSampleStatusDictDetail()
            this.$message.success(res.msg)
            this.$emit('success')
            this.cancel()
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.info('暂无样品需要添加！')
      }
    },

    // 准备采样状态字典
    checkSampleStatusDict () {
      // 字典是否存在，不存在保存一个
      dictApi.checkCode({ code: 'appearanceStatusSelect' }).then(res => {
        if (!res) {
          this.addSampleStatusDict()
        }
      })

      customFunc._self = this
      return customFunc.asyncGetDictCode((data) => {
        this.SampleStatusDictDetail = data && data.length && data.map(item => item.label) || []
      }, ['appearanceStatusSelect'])
    },

    // 添加字典
    addSampleStatusDict () {
      const dictData = {
        dictName: '采样样品状态',
        dictCode: 'appearanceStatusSelect',
        dictDesc: '采样样品状态字典'
      }

      dictApi.add(dictData).then(res => {
        if (res.code !== 0) {
          this.$message.error(`保存采样样品状态字典发生错误：${res.msg}`)
        }
      })
    },

    addSampleStatusDictDetail () {
      const sampleStatusArr = this.SampleStatusDictDetail.map((item, index) => {
        return {
          dictCode: 'appearanceStatusSelect',
          status: 1,
          itemText: item,
          itemValue: index + 1,
          sort: index + 1
        }
      })
      this.$store.commit('code/update', sampleStatusArr)
      this.$store.commit('code/addAll', sampleStatusArr)
    },

    getBySiteSort (cateId, siteId) {
      return this.cateSiteSortMap[cateId]?.siteList.findIndex(item => item.siteId === siteId) + 1
    },

    // 初始化组件
    init () {
      this.$nextTick(() => {
        this.show()
      })
    },

    // 显示组件
    show () {
      this.projectModalProps.visible = true
    },

    // 取消按钮
    cancel () {
      this.projectModalProps.visible = false
      this.fullModal.visible = false
      this.$emit('cancel')
    },

    getMaxHeight () {
      return document.documentElement.clientHeight - 350 + 'px'
    },

    reset () {
      this.searchForm = this.$options.data.bind(this)().searchForm
      this.$nextTick(() => {
        this.$refs['projectTable'].search()
      })
    }

  }
}
</script>

<style lang="less" scoped>
.card-container {
  background: #FFFFFF;
  overflow: hidden;
  padding: 16px 0;
}

.table-card {
  margin-top: 24px;
  padding: 16px;
}

</style>
