<template>
  <div class="sample-link">

    <a-card v-bind="sampleStatCard" v-if="sampleStatCard.visible">
      共计 <span class="focus-num green">{{ sampleStat.siteCount }}</span>个点位，
      <span class="focus-num green">{{ sampleStat.cateCount }} </span>个分类，
      预计需要采集 <span class="focus-num green">{{ sampleStat.count }}</span> 个样品。
      <span @click="sampleStatCard.visible=false" class="fr close red">
        <a-icon type="close-circle"/>
      </span>
      <br/>
      <div :style="{marginLeft:'-30px'}">
        <span :key="index" :style="{padding:'0 30px',borderRight:'1px dashed #999'}" v-for="(item,index) in itemCascader.options">
          {{ item.name }}：
          <span class="focus-num green">
            {{ sampleStat.cateDetailCount && sampleStat.cateDetailCount[item.id] && sampleStat.cateDetailCount[item.id].count || '-' }}
          </span> 个
        </span>
      </div>

    </a-card>

    <a-card v-bind="fieldBlankCard" v-if="code&sampleLinkStepEnum.fieldBlank">
      <template #extra>
        <span :style="{marginRight:'10px'}" class="prompt"> 共计 <span class="focus-num green">{{ fieldBlankData.length || 0 }}</span> 因子需要进行现场空白</span>
        <a @click="saveData.fieldBlank.push({editable:true,items:[]})">新增</a>
      </template>

      <template v-if="saveData.fieldBlank.length">
        <span :key="index" class="sample-info" v-for="(item,index) in saveData.fieldBlank">
          <template v-if="item.editable">
            <span class="focus-num">{{ index + 1 }}.</span>
            <el-cascader :options="fieldBlankItemCascaderOptions" v-bind="itemCascader" v-model="item.items"></el-cascader>
            <span :style="{marginRight:'10px'}" @click="()=>{item.editable=false}" class="close green focus-num"><a-icon type="check"/></span>
            <span @click="saveData.fieldBlank.splice(index,1)" class="close red focus-num"><a-icon type="delete"/></span>
          </template>
          <template v-else>
            <span class="item-div">
              <span class="focus-num">{{ index + 1 }}.</span>{{ getItemName(item.items) }}
              <span @click="saveData.fieldBlank.splice(index,1)" class="close red focus-num"><a-icon type="delete"/></span>
            </span>
          </template>
        </span>

      </template>
      <template v-else>
        <div class="no-data">
          <span> <a-icon type="frown"/></span>
          暂无数据
        </div>
      </template>
    </a-card>

    <a-card v-bind="transportBlankCard" v-if="code&sampleLinkStepEnum.transportBlank">
      <template #extra>
        <span :style="{marginRight:'10px'}" class="prompt"> 共计 <span class="focus-num green">{{ transportBlankData.length || 0 }}</span> 因子需要进行运输空白</span>
        <a @click="saveData.transportBlank.push({editable:true,items:[]})">新增</a>
      </template>

      <template v-if="saveData.transportBlank.length">
        <span :key="index" class="sample-info" v-for="(item,index) in saveData.transportBlank">
          <template v-if="item.editable">
            <span class="focus-num">{{ index + 1 }}.</span>
            <el-cascader :options="transportBlankItemCascaderOptions" v-bind="itemCascader" v-model="item.items"></el-cascader>
            <span :style="{marginRight:'10px'}" @click="()=>{item.editable=false}" class="close green focus-num"><a-icon type="check"/></span>
            <span @click="saveData.transportBlank.splice(index,1)" class="close red focus-num"><a-icon type="delete"/></span>
          </template>
          <template v-else>
            <span class="item-div">
              <span class="focus-num">{{ index + 1 }}.</span>{{ getItemName(item.items) }}
              <span @click="saveData.transportBlank.splice(index,1)" class="close red focus-num"><a-icon type="delete"/></span>
            </span>
          </template>
        </span>

      </template>
      <template v-else>
        <div class="no-data">
          <span> <a-icon type="frown"/></span>
          暂无数据
        </div>
      </template>
    </a-card>

    <a-card v-bind="allBlankCard" v-if="code&sampleLinkStepEnum.allBlank">
      <template #extra>
        <span :style="{marginRight:'10px'}" class="prompt"> 共计 <span class="focus-num green">{{ allBlankData.length || 0 }}</span> 因子需要进行全程序空白</span>
        <a @click="saveData.allBlank.push({editable:true,items:[]})">新增</a>
      </template>

      <template v-if="saveData.allBlank.length">
        <span :key="index" class="sample-info" v-for="(item,index) in saveData.allBlank">
          <template v-if="item.editable">
            <span class="focus-num">{{ index + 1 }}.</span>
            <el-cascader :options="allBlankItemCascaderOptions" v-bind="itemCascader" v-model="item.items"></el-cascader>
            <span :style="{marginRight:'10px'}" @click="()=>{item.editable=false}" class="close green focus-num"><a-icon type="check"/></span>
            <span @click="saveData.allBlank.splice(index,1)" class="close red focus-num"><a-icon type="delete"/></span>
          </template>
          <template v-else>

            <span class="item-div">
              <span class="focus-num">{{ index + 1 }}.</span>{{ getItemName(item.items) }}
              <span @click="saveData.allBlank.splice(index,1)" class="close red focus-num"><a-icon type="delete"/></span>
            </span>
          </template>
        </span>

      </template>
      <template v-else>
        <div class="no-data">
          <span> <a-icon type="frown"/></span>
          暂无数据
        </div>
      </template>
    </a-card>

    <a-card v-bind="fieldParallelCard" v-if="code&sampleLinkStepEnum.fieldParallel">
      <template #extra>
        <template v-if="sampleStat.itemDetailCount">
          <span :key="key" class="prompt" v-for="(itemGroup,key) of sampleStat.itemDetailCount">
            <span :key="`${key}_${index}`" :style="{marginRight:'10px'}" class="prompt" v-for="(item,index) of itemGroup">
              <span class="red">{{ item.name }}</span> 预计 <span class="focus-num green">{{ item.count || 0 }}</span>个样品</span>
          </span>
        </template>
      </template>
      <a-table :dataSource="saveData.fieldParallel" v-bind="fieldParallelTable">
        <template #cateId="data,item">
          <el-cascader @change="(data)=>handleFPCateIdChange(data,item)" v-bind="itemCascader" v-model="item.items"></el-cascader>
        </template>

        <template #siteId="data,item">
          <template v-if="item.items.length">
            <a-select :style="{width:'80%'}" @change="(data)=>handleFPSiteIdChange(data,item)" placeholder="请选择点位" v-model="item.siteId">
              <a-select-option :key="index" :value="site.siteId" v-for="(site,index) in item.siteIds">{{ site.siteName }}</a-select-option>
            </a-select>
          </template>
          <template v-else>
            <span class="prompt">请先选择分类</span>
          </template>

        </template>

        <template #numPeriod="data,item">
          <template v-if="item.items.length">
            <a-select :style="{width:'80%'}" @change="(data)=>handleFPFrequencyIdChange(data,item)" placeholder="请选择频次" v-model="item.frequency">
              <a-select-option :key="index" :value="`${frequency.numPeriod}-${frequency.numFrequency}`" v-for="(frequency,index) in item.frequencyArr">
                D{{ frequency.numPeriod }}-{{ frequency.numFrequency }}
              </a-select-option>
            </a-select>
          </template>
          <template v-else>
            <span class="prompt">请先选择点位</span>
          </template>

        </template>

        <template #action="item,index">
          <a @click="saveData.fieldParallel.splice(index,1)">移除</a>
        </template>

      </a-table>
      <a-button :style="{width:'100%',margin:'10px 0'}" @click="addFieldParallel">添加平行样</a-button>

    </a-card>
  </div>
</template>

<script>
import { cloneDeep, countBy, groupBy, isEmpty, isEqual, maxBy, pick, sumBy, unionWith, uniqBy } from 'lodash'

import { jcListApi } from '@/api/quote'
import { projTaskItemApi } from '@/api/project'
import { qualityControlApi } from '@/api/quality/qualityControlApi'
import { qualityControlSampleApi } from '@/api/quality/qualityControlLinkApi'
import { qualityControlLinkSampleTypeMap, qualityControlLinkStepSampleTypeMap, sampleLinkStepDataMap, sampleLinkStepEnum } from '@/api/quality/qualityControlLinkConstant'

const baseCard = {
  type: 'inner',
  size: 'small',
  style: { marginBottom: '24px' }
}

export default {
  props: {

    code: {
      type: Number,
      required: true
    },

    id: {
      type: Number,
      required: true
    },

    projId: {
      type: Number,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      sampleLinkStepEnum,
      sampleLinkStepDataMap,
      // 空白样启用标记
      blankEnableSymbol: 1,

      transportBlankData: [],
      fieldBlankData: [],
      allBlankData: [],
      projItemGuide: [],

      sampleStat: {
        count: null,
        siteCount: null,
        cateCount: null,
        cateDetailCount: null,
        itemDetailCount: null
      },

      saveData: {
        fieldBlank: [],
        transportBlank: [],
        allBlank: [],
        fieldParallel: []
      },

      cateSiteMap: null,

      sampleStatCard: Object.assign({}, baseCard, {
        visible: true
      }),

      fieldBlankCard: Object.assign({}, baseCard, {
        title: '现场空白'
      }),

      transportBlankCard: Object.assign({}, baseCard, {
        title: '运输空白'
      }),

      allBlankCard: Object.assign({}, baseCard, {
        title: '全程序空白'
      }),

      fieldParallelCard: Object.assign({}, baseCard, {
        title: '现场平行'
      }),
      fieldParallelTable: {
        rowKey: 'index',
        bordered: true,
        size: 'small',
        pagination: false,
        columns: [
          { title: '分类', width: 300, dataIndex: 'cateId', scopedSlots: { customRender: 'cateId' } },
          { title: '点位', width: 200, dataIndex: 'siteId', scopedSlots: { customRender: 'siteId' } },
          { title: '频次', width: 150, dataIndex: 'numPeriod', scopedSlots: { customRender: 'numPeriod' } },
          { title: '操作', width: 100, scopedSlots: { customRender: 'action' } }
        ]
      },

      itemCascader: {
        style: { width: '80%', margin: '0 10px' },
        props: { label: 'name', value: 'id', children: 'children', expandTrigger: 'hover', multiple: true },
        options: [],
        size: 'small',
        placeholder: '请选择检测项',
        collapseTags: true
      }
    }
  },

  computed: {
    fieldBlankItemCascaderOptions () {
      // 组装 分类 因子 数据结构
      const itemCascaderData = this.fieldBlankData.reduce((result, data) => {
        (result[data.cateId] || (result[data.cateId] = { id: data.cateId, name: data.cateName, children: [] })).children.push({
          id: data.itemId,
          name: data.itemName
        })
        return result
      }, {})
      return Object.values(itemCascaderData)
    },

    allBlankItemCascaderOptions () {
      // 组装 分类 因子 数据结构
      const itemCascaderData = this.fieldBlankData.reduce((result, data) => {
        (result[data.cateId] || (result[data.cateId] = { id: data.cateId, name: data.cateName, children: [] })).children.push({
          id: data.itemId,
          name: data.itemName
        })
        return result
      }, {})
      return Object.values(itemCascaderData)
    },

    transportBlankItemCascaderOptions () {
      // 组装 分类 因子 数据结构
      const itemCascaderData = this.fieldBlankData.reduce((result, data) => {
        (result[data.cateId] || (result[data.cateId] = { id: data.cateId, name: data.cateName, children: [] })).children.push({
          id: data.itemId,
          name: data.itemName
        })
        return result
      }, {})
      return Object.values(itemCascaderData)
    }
  },

  watch: {
    projId: {
      immediate: true,
      handler (val) {
        val && this.findProjectItemDetail(val)
        val && this.projItemGroupList(val)
      }
    },

    type: {
      immediate: true,
      handler (val) {
        if (val === 'edit') {
          this.$nextTick(() => {
            this.querySampleLinkData()
          })
        }
      }
    }
  },

  methods: {

    // 添加平行样
    addFieldParallel () {
      this.saveData.fieldParallel.push({
        index: Date.now(),
        items: [],
        siteIds: [],
        frequencyArr: [],
        frequency: null,
        siteId: null,
        numPeriod: null,
        numFrequency: null
      })
    },

    // 获取项目对应的检测项明细信息
    findProjectItemDetail (projId) {
      projTaskItemApi.detailList({ projId: projId, page: false }).then(res => {
        if (res.data && res.data.length) {
          var siteCount = countBy(res.data, 'siteId')
          var cateCount = countBy(res.data, 'cateId')
          this.sampleStat.siteCount = Object.keys(siteCount).length
          this.sampleStat.cateCount = Object.keys(cateCount).length

          // 组装 分类 因子 数据结构
          const itemCascaderData = res.data.reduce((result, data) => {
            (result[data.cateId] || (result[data.cateId] = { id: data.cateId, name: data.cateName, children: [] })).children.push({
              id: data.itemId,
              name: data.itemName
            })
            return result
          }, {})
          const itemOptions = Object.values(itemCascaderData)
          this.itemCascader.options.splice(0, this.itemCascader.options.length, ...itemOptions)

          // 组装 分类 点位 频次之间的对应关系
          const cateGroup = groupBy(res.data, 'cateId')
          const cateSiteMap = Object.keys(cateGroup).map(key => {
            const siteGroup = groupBy(cateGroup[key], 'siteId')
            const siteArrTemp = Object.keys(siteGroup).reduce((res, siteKey) => {
              const siteInfo = siteGroup[siteKey][0]
              res.push({
                siteId: parseInt(siteKey),
                siteName: siteInfo.siteName,
                frequency: unionWith(siteGroup[siteKey].reduce((acc, item) => {
                  for (let i = 0; i < item.numPeriod; i++) {
                    for (let j = 0; j < item.numFrequency; j++) {
                      acc.push({ numPeriod: (i + 1), numFrequency: (j + 1) })
                    }
                  }
                  return acc
                }, []), isEqual)
              })
              return res
            }, [])
            return { cateId: parseInt(key), siteArr: siteArrTemp }
          })
          this.cateSiteMap = cateSiteMap

          // 填充平行样数据
          if (this.type === 'edit') {
            this.$nextTick(() => {
              this.setFieldParallelData()
            })
          }
        }
      })
    },

    setFieldParallelData () {
      const fieldParallel = this.saveData.fieldParallel
      if (fieldParallel.length && this.cateSiteMap) {
        fieldParallel.forEach(item => {
          item.cateId = item.items[0][0]
          const cateSiteMapElement = this.cateSiteMap.find(cateItem => cateItem.cateId === item.cateId)
          const siteArrElement = cateSiteMapElement && cateSiteMapElement.siteArr.find(siteItem => siteItem.siteId === item.siteId)
          this.$set(item, 'siteIds', cloneDeep(cateSiteMapElement && cateSiteMapElement.siteArr || []))
          this.$set(item, 'frequencyArr', cloneDeep(siteArrElement && siteArrElement.frequency || []))
          this.$set(item, 'frequency', item.numPeriod + '-' + item.numFrequency)
        })
      }
    },

    getItemName (itemIdArr) {
      return itemIdArr.reduce((res, itemIds) => {
        const itemData = this.projItemGuide.find(item => item.itemId === itemIds ?.[1] && item.cateId === itemIds?.[0])
        res.push(itemData?.cateName + '/' + itemData?.itemName)
        return res
      }, []).join(',')
    },

    projItemGroupList (projId) {
      jcListApi.queryProjItemGuide({ projId, page: false }).then(res => {
        if (res.code === 0) {
          this.projItemGuide.splice(0, this.projItemGuide.length, ...res.data)
          // 过滤只读项
          const saveSampleItem = uniqBy(res.data.filter(item => item.itemSample === this.blankEnableSymbol), 'itemId')
          this.allBlankData.splice(0, this.allBlankData.length, ...saveSampleItem.filter(item => item.allBlank === this.blankEnableSymbol))
          this.fieldBlankData.splice(0, this.fieldBlankData.length, ...saveSampleItem.filter(item => item.fieldBlank === this.blankEnableSymbol))
          this.transportBlankData.splice(0, this.transportBlankData.length, ...saveSampleItem.filter(item => item.transportBlank === this.blankEnableSymbol))
          var cateMap = groupBy(saveSampleItem, 'cateId')
          const result = Object.keys(cateMap).reduce((res, cateId) => {
            const items = cateMap[cateId]
            const groupMap = groupBy(items, 'groupId')
            res[cateId] = []
            Object.keys(groupMap).forEach(groupKey => {
              const groupData = groupMap[groupKey]
              if (groupKey === 'undefined') {
                // 未分组
                groupData.forEach(item => {
                  res[cateId].push({ name: item.itemName, isGroup: false, count: item.numFrequency * item.numPeriod })
                })
              } else {
                const numFrequency = maxBy(groupData, 'numFrequency').numFrequency
                const numPeriod = maxBy(groupData, 'numPeriod').numFrequency
                res[cateId].push({ name: groupData[0].groupName, isGroup: true, count: numFrequency * numPeriod })
              }
            })
            return res
          }, {})

          const cateDetailCount = Object.keys(result).reduce((res, item) => {
            res[item] = { cateId: parseInt(item), count: sumBy(Object.values(result[item]), 'count') }
            return res
          }, {})

          this.sampleStat.cateDetailCount = cateDetailCount
          this.sampleStat.itemDetailCount = result
          this.sampleStat.count = sumBy(Object.values(this.sampleStat.cateDetailCount), 'count')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 处理平行样分类ID发生变化事件
    handleFPCateIdChange (data, item) {
      item.cateId = data.length && data[0][0] || null
      const cateSiteMapElement = this.cateSiteMap.find(cateItem => cateItem.cateId === item.cateId)
      item.siteIds.splice(0, item.siteIds.length, ...(cateSiteMapElement && cateSiteMapElement.siteArr || []))
      this.handleFPSiteIdChange(cateSiteMapElement && cateSiteMapElement.siteArr[0].siteId || null, item)
    },

    // 处理平行样点位ID发生变化事件
    handleFPSiteIdChange (siteId, item) {
      item.siteId = siteId
      const cateSiteMapElement = this.cateSiteMap.find(cateItem => cateItem.cateId === item.cateId)
      const siteArrElement = cateSiteMapElement && cateSiteMapElement.siteArr.find(siteItem => siteItem.siteId === siteId)
      item.frequencyArr.splice(0, item.frequencyArr.length, ...(siteArrElement && siteArrElement.frequency || []))
      this.handleFPFrequencyIdChange(siteArrElement && (siteArrElement.frequency[0].numPeriod + '-' + siteArrElement.frequency[0].numFrequency), item)
    },

    // 处理平行样点位ID发生变化事件
    handleFPFrequencyIdChange (frequency, item) {
      const numPeriod = frequency && parseInt(frequency.split('-')[0])
      const numFrequency = frequency && parseInt(frequency.split('-')[1])
      item.numPeriod = numPeriod
      item.numFrequency = numFrequency
      item.frequency = frequency
    },

    checkSaveData () {
      const { fieldBlank, transportBlank, allBlank, fieldParallel } = this.saveData
      const allEmpty = [fieldBlank, transportBlank, allBlank, fieldParallel].reduce((acc, data) => {
        return acc && isEmpty(data)
      }, true)

      if (allEmpty) {
        this.$message.error('请设置质控措施！')
        return false
      }

      try {
        [fieldBlank, transportBlank, allBlank].forEach(item => {
          item.forEach(data => {
            if (isEmpty(data.items)) {
              this.$message.error('您还有数据未编辑完成！')
              throw new Error('您还有数据未编辑完成')
            }
          })
        })
      } catch (e) { return false }

      try {
        fieldParallel.forEach(data => {
          if (isEmpty(data.items) || !data.numPeriod || !data.siteId) {
            this.$message.error('您还有数据未编辑完成！')
            throw new Error('您还有数据未编辑完成')
          }
        })
      } catch (e) { return false }
      return true
    },

    querySampleLinkData () {
      qualityControlSampleApi.queryDetails({ qualityId: this.id, types: qualityControlLinkSampleTypeMap.sampleLink }).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            let now = Date.now()
            const groupData = groupBy(res.data, 'type')
            Object.keys(groupData).forEach(key => {
              groupData[key].forEach(item => {
                item.editable = false
                item.index = ++now
                item.items = item.items.map(cItem => [cItem.cateId, cItem.itemId])
              })

              if (key === qualityControlLinkStepSampleTypeMap.fieldBlank) {
                this.saveData.fieldBlank.splice(0, this.saveData.fieldBlank.length, ...groupData[key])
              } else if (key === qualityControlLinkStepSampleTypeMap.transportBlank) {
                this.saveData.transportBlank.splice(0, this.saveData.transportBlank.length, ...groupData[key])
              } else if (key === qualityControlLinkStepSampleTypeMap.allBlank) {
                this.saveData.allBlank.splice(0, this.saveData.allBlank.length, ...groupData[key])
              } else if (key === qualityControlLinkStepSampleTypeMap.fieldParallel) {
                this.saveData.fieldParallel.splice(0, this.saveData.fieldParallel.length, ...groupData[key])
              }
            })
            this.$nextTick(() => {
              this.setFieldParallelData()
            })
          }
        } else {
          this.$message.error(`获取历史配置详情错误：${res.msg}`)
        }
      })
    },

    // 保存现场环节数据
    save () {
      if (this.checkSaveData()) {
        var clone = cloneDeep(this.saveData)
        const result = ['fieldBlank', 'transportBlank', 'allBlank', 'fieldParallel'].reduce((res, key) => {
          var cloneElement = clone[key]
          cloneElement.forEach(item => {
            const newItems = item.items.reduce((data, val) => {
              data.push({ cateId: val[0], itemId: val[1] })
              return data
            }, [])
            item.items = newItems
          })
          res[key] = cloneElement
          return res
        }, {})
        result.fieldParallel = clone.fieldParallel.map(item => {
          return pick(item, ['numFrequency', 'numPeriod', 'siteId', 'items'])
        })

        qualityControlApi.saveSampleLink({ sampleLink: result, projId: this.projId, id: this.id }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }

  }
}
</script>

<style lang="less">
@import '_index';
</style>
