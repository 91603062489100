<template>
  <a-modal
    :width="900"
    class="mx-modal"
    :visible="visible"
    :maskClosable="false"
    :destroyOnClose="true"
    :centered="true"
    @ok="handleOK"
    @cancel="handleCancel">
    <a-row>
      <a-col :span="3" style="line-height: 48px;text-align: center;background:#1890FF;border-radius:0px 8px 0px 0px;font-size:16px;font-weight:bold;color:#FFFFFF;">商机登记</a-col>
      <a-col v-if="info.id" :span="6" class="ahmux-sub-title-detail" style="width: auto;">登记人：{{ info.createUser }}</a-col>
      <a-col v-if="info.id" :span="10" class="ahmux-sub-title-detail" style="width: auto;">登记时间：{{ info.createTime }}</a-col>
    </a-row>
    <a-row>
      <a-form :form="form">
        <table class="mxtable">
          <tr>
            <th rowspan="6">商机信息</th>
            <td class="tdLabel">客户单位</td>
            <td>
              <div :class="`required ${notNull(info.entrustCustomer)}`">
                <a-select
                  v-model="info.entrustCustomer"
                  style="width: 100%"
                  showSearch
                  placeholder="请选择客户单位"
                  @change="changeEntrust"
                  optionFilterProp="children"
                  :filterOption="filterOption">
                  <a-icon type="plus-square" @click="showAddCustomer(1)" slot="suffixIcon" />
                  <a-select-option v-for="vo in this.customerList" :key="vo.id">{{ vo.csrName }}</a-select-option>
                </a-select>
              </div>
            </td>
            <td class="tdLabel">联系人</td>
            <td>
              <div :class="`required ${notNull(info.entrustContacts)}`">
                <a-select
                  v-model="info.entrustContacts"
                  style="width: 100%"
                  showSearch
                  placeholder="委托单位联系人"
                  optionFilterProp="children"
                  @change="(value, option)=>changeCustomerUser(value, option)"
                  :filterOption="filterOption">
                  <a-icon type="plus-square" @click="showAddCustomerUser" slot="suffixIcon" />
                  <a-select-option v-for="vo in this.customerUserList" :key="vo.id">{{ vo.csrUserName }}</a-select-option>
                </a-select>
              </div>
            </td>
            <td class="tdLabel">联系电话</td>
            <td>
              <div :class="`required ${notNull(info.entrustContactsPhone)}`">
                <a-input placeholder="客户联系电话" v-model="info.entrustContactsPhone"/>
              </div>
            </td>
          </tr>
          <tr>
            <td class="tdLabel">商机名称</td>
            <td colspan="3">
              <div :class="`required ${notNull(info.title)}`">
                <a-input :disabled="true" placeholder="请输入商机名称" v-model="info.title"/>
              </div>
            </td>
            <td class="tdLabel">商机来源</td>
            <td>
              <div :class="`required ${notNull(info.source)}`">
                <a-select
                  style="width: 95%"
                  showSearch
                  placeholder="商机来源"
                  optionFilterProp="children"
                  :filterOption="filterOption"
                  v-model="info.source">
                  <a-select-option v-for="vo in this.businessSourceList" :key="vo.itemValue">{{ vo.itemText }}</a-select-option>
                </a-select>
              </div>
            </td>
          </tr>
          <tr>
            <!--            <td class="tdLabel">客户行业</td>-->
            <!--            <td>-->
            <!--              <div><a-input placeholder="客户行业" v-model="info.industry"/></div>-->
            <!--            </td>-->
            <td class="tdLabel">地址</td>
            <td colspan="4">
              <div :class="`required  has-${addr.status}`"><AddressInput ref="addressInput" :addr.sync="addr"></AddressInput></div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="tdLabel">负责人</td>
            <td>
              <div :class="`required ${notNull(info.head)}`">
                <a-select
                  v-model="info.head"
                  showSearch
                  placeholder="商机负责人"
                  optionFilterProp="children"
                  @change="projUserIdChange"
                  :filterOption="filterOption">
                  <a-select-option v-for="vo in this.userList" :key="vo.username">{{ vo.realname }}({{ vo.username }})</a-select-option>
                </a-select>
              </div>
            </td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td class="tdLabel">商机描述</td>
            <td colspan="5"><a-textarea
              v-model="info.remark"
              placeholder="请填写商机描述"
              :autosize="{ minRows: 4, maxRows: 8 }"/></td>
          </tr>
        </table>
      </a-form>
    </a-row>
    <CustomerModal ref="customerModalForm" @ok="addCustomerRes"></CustomerModal>
    <CustomerUserModal ref="customerUserModal" @ok="addCustomerUseRes" :customerList="customerList"></CustomerUserModal>
  </a-modal>
</template>

<script>
import { businessApi, customerApi, customerUserApi, Get } from '@/api/quote'
import { userApi } from '@/api/system'
import CustomerModal from '@/pages/cloud-quote/customer/CustomerForm'
import CustomerUserModal from '@/pages/cloud-quote/customer-user/Form'
import AddressInput from '@/pages/common/map/AddressInput'
import { removeSpecialSymbol } from '@/utils/util'
import Vue from 'vue'
var vm = null
export default {
  name: 'BusinessForm',
  components: {
    CustomerModal,
    CustomerUserModal,
    AddressInput
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    vm = this
    window.vm = vm
    return {
      info: {
      },

      // 定位信息
      addr: {
        status: '',
        tip: '',
        adCode: '',
        address: '',
        wholeAddress: '',
        lng: '',
        lat: ''
      },

      // 客户单位 客户联系人
      customerList: [],
      customerUserList: [],

      userList: [],
      fetching: false,
      visible: false,
      title: '登记商机',
      form: this.$form.createForm(this),
      formData: {},
      projLevelList: [{ id: '1', name: '普通' }, { id: '2', name: '重要' }, { id: '3', name: '加急' }]
    }
  },
  methods: {
    add () {
      this.visible = true
      const userInfo = this.$store.getters.userInfo
      this.info.head = userInfo.username
      this.info.headName = userInfo.realname
      this.info = { source: '1', 'head': userInfo.username, headName: userInfo.realname }
      this.initData()
    },
    edit (record) {
      this.visible = true
      this.title = '修改商机'
      this.info = Object.assign({}, record)
      this.initData()
    },
    initData () {
      this.listUser()
      this.listCustomer()
      this.listCustomerUser()
      this.$set(this.addr, 'adCode', this.info.busAdcode)
      this.$set(this.addr, 'address', this.info.busAddress)
      this.$set(this.addr, 'lng', this.info.busLng)
      this.$set(this.addr, 'lat', this.info.busLat)
    },
    handleCancel () {
      this.visible = false
    },
    async handleOK (e) { // 提交表单
      e.preventDefault()
      const self = this
      const formData = Object.assign({}, self.info)
      var saveApi = businessApi.add
      if (self.info.id) {
        saveApi = businessApi.edit
      }
      delete formData.quoteList
      if (await self.validate()) {
        formData.busAdcode = this.addr.adCode
        formData.busAddress = this.addr.address
        formData.busWholeAddress = this.addr.wholeAddress
        formData.busLng = this.addr.lng
        formData.busLat = this.addr.lat
        saveApi(formData).then(result => {
          if (result.code === 0) {
            this.$message.success(`${self.title}成功`)
            this.handleCancel()
            this.$emit('ok', null)
          } else {
            this.$message.error(result.msg)
          }
        }).catch(error => {
          this.$message.error(`服务器发生错误！错误消息为${error}`)
        })
      }
    },

    listCustomer () {
      const self = this
      customerApi.listCustomer({ page: false, authEnable: false }).then(result => {
        if (result.code === 0) {
          self.customerList = result.data
        }
      })
    },
    changeEntrust (value) {
      const csr = this.customerList.filter(item => value === item.id)[0]
      if (csr) {
        this.$set(this.info, 'title', csr.csrName)
        this.$set(this.info, 'entrustCustomer', csr.id)
        this.$set(this.addr, 'adCode', csr.csrAdCode)
        this.$set(this.addr, 'address', csr.csrAddr)
        this.$set(this.addr, 'lng', csr.csrLng)
        this.$set(this.addr, 'lat', csr.csrLat)
        this.listCustomerUser()
      }
    },
    showAddCustomer (csrType) {
      this.$refs.customerModalForm.add({ addType: 2, csrType: csrType })
    },
    addCustomerRes (obj) {
      var record = obj.result
      this.customerList.push(record)
      this.changeEntrust(record.id)
    },
    listCustomerUser () {
      var self = this
      var csrIds = []
      if (self.info.entrustCustomer) {
        csrIds.push(self.info.entrustCustomer)
      }
      if (csrIds.length > 0) {
        var csrIdsStr = csrIds.toString()
        Get(`${customerUserApi.list}`, { csrIds: csrIdsStr, page: false, authEnable: false }).then(result => {
          if (result.code === 0) {
            var customerUserList = result.data
            self.customerUserList = customerUserList || []
            if (!self.info.entrustContacts && self.customerUserList.length > 0) {
              self.updateCustomerUser(self.customerUserList[0])
            }
          }
        })
      } else {
        this.customerUserList = []
      }
    },
    updateCustomerUser (csrUser) {
      if (csrUser) {
        this.$set(this.info, 'entrustContacts', csrUser.id)
        this.$set(this.info, 'entrustContactsName', csrUser.csrUserName)
        this.$set(this.info, 'entrustContactsPhone', csrUser.csrUserTelephone)
      } else {
        this.$set(this.info, 'entrustContacts', '')
        this.$set(this.info, 'entrustContactsName', '')
        this.$set(this.info, 'entrustContactsPhone', '')
      }
    },
    changeCustomerUser (value, op) {
      var self = this
      const csrUser = self.customerUserList.filter(item => value === item.id)[0]
      this.updateCustomerUser(csrUser)
    },
    showAddCustomerUser () {
      if (this.info.entrustCustomer) {
        this.$refs.customerUserModal.addByCsrId(this.info.entrustCustomer)
      } else {
        this.$message.warning(`请先选择客户单位`)
      }
    },
    addCustomerUseRes (record) {
      this.customerUserList.push(record)
      this.updateCustomerUser(record)
    },
    projUserIdChange (value) {
      var self = this
      const user = self.userList.filter(item => value === item.username)[0]
      if (user) {
        self.$set(self.info, 'head', user.username)
        self.$set(self.info, 'headName', user.realname)
      }
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    listUser () {
      const self = this
      Get(`${userApi.list}`, { status: 1, pageSize: 5000 }).then(result => {
        self.userList = result.data
      })
    },
    notNull (v) {
      return v ? '' : 'has-error'
    },
    async validate () {
      var self = this
      var info = self.info
      if (!(info.title && info.title.length <= 100)) {
        self.$message.error(`商机名称不能为空,且长度不能超过100`)
        return false
      } else {
        var flag = await businessApi.validateTitle({ id: info.id, title: info.title }).then(res => {
          if (res.code == 0) {
            return true
          } else {
            return false
          }
        })
        if (!flag) {
          self.$message.error(`商机[${info.title}] 已经存在`)
          return false
        }
      }
      if (!info.source) {
        self.$message.error(`请选择商机来源`)
        return false
      }
      if (!(info.entrustCustomer)) {
        self.$message.error(`客户单位不能为空,且长度不能超过100`)
        return false
      }
      if (!(info.entrustContacts)) {
        self.$message.error(`客户联系人不能为空,且长度不能超过50`)
        return false
      }
      if (!(info.entrustContactsPhone && info.entrustContactsPhone.length <= 50)) {
        self.$message.error(`客户联系电话不能为空,且长度不能超过50`)
        return false
      }
      if (info.industry && info.industry.length > 50) {
        self.$message.error(`客户行业长度不能超过50`)
        return false
      }
      if (info.address && info.address.length > 200) {
        self.$message.error(`地址长度不能超过200`)
        return false
      }
      return true
    }
  },
  watch: {
    'info.title' (nv, ov) {
      if (nv) {
        this.$set(this.info, 'title', removeSpecialSymbol(nv))
      }
    }
  },
  computed: {
    businessSourceList () {
      return [...Vue.ls.get('business_source')]
    }
  },
  filters: {
  },
  created () {
    this.$store.dispatch('code/select', 'business_source')
  }
}
</script>

<style lang="less">
  .mx-modal .ant-modal-body{
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  /*.modal-container {*/
  /*  height: 100%;*/
  /*  background-color: #e4e7ed4d;*/

  /*  .ant-modal {*/
  /*    height: 100%;*/
  /*    overflow: hidden;*/
  /*    padding-bottom: 0px;*/

  /*    .ant-modal-content {*/
  /*      height: 100%;*/
  /*      overflow: hidden;*/

  /*      .ant-modal-body {*/
  /*        overflow: auto;*/
  /*        padding: 20px;*/
  /*        height: ~"calc(100% - 108px)";*/
  /*      }*/

  /*      .ant-modal-body::-webkit-scrollbar {*/
  /*        display: none;*/
  /*      }*/

  /*      .ant-modal-footer {*/
  /*        text-align: center;*/
  /*      }*/
  /*    }*/
  /*  }*/
  /*}*/

  .mxtable1 {
    width: 95%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
  }
  .mxtable{
    width: 100%;
    border: 2px solid #1890FF;
    border-top: 5px solid #1890FF;
    border-collapse: unset;
    .required .ant-input, .required .ant-select-selection, .required .ant-cascader-picker {
      background-color: #fef0d0;
    }
    th{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      border: 1px dashed #909399;
    }
    td{
      padding: 5px;
      /*min-width: 12%;*/
      /*max-width: 22%;*/
      width: 125px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
    .tdLabel{
      width: 75px;
      background: #F3F3F1;
      color:#333333;
      text-align: center;
      padding: 5px;
      font-size:14px;
      /*border: 1px dashed #909399;*/
      border-top: 1px dashed #909399;
      border-left: 1px dashed #909399;
    }
  }
</style>
<style scoped>
</style>
